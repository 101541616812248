import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../history";
import OrderDetails from "./OrderDetails";
import EditPaymentStatus from "./EditPaymentStatus";
import { AppProvider } from "@shopify/polaris";
import MainTrackings from "./MainTrackings";
import ListOfRemainingAmounts from "./ListOfRemainingAmounts";

function MainsSub() {
  return (
    <AppProvider>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/orders/:id" component={OrderDetails} />
          <Route
            path="/admin/orders/:id/editpaymentstatus"
            component={EditPaymentStatus}
          />
          <Route path="/admin/orders/:id/trackings/:type?" component={MainTrackings} />
          <Route
            path="/admin/orders/:id/remainingamounts"
            component={ListOfRemainingAmounts}
          />
        </Switch>
      </Router>
    </AppProvider>
  );
}
export default MainsSub;
