import history from '../../../../history';
import NavigationLayout from '../../../../components/NavigationLayout';
import { Router } from 'react-router-dom';
import { Card, Page } from '@shopify/polaris';
import { useState } from 'react';
import Cookies from 'js-cookie';
import SmilePlanCategoriesTable from './smile-plan-categories-table.component';
import axios from '../../../../axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SmilePlanTemplatesTable from './smile-plan-templates-table.component';

const SmilePlanCategoriesAndTemplates = () => {
  const [smilePlanCategories, setSmilePlanCategories] = useState([]);
  const [smilePlanTemplates, setSmilePlanTemplates] = useState([]);
  const [loadingSmilePlanCategories, setLoadingSmilePlanCategories] =
    useState(false);
  const [loadingSmilePlanTemplates, setLoadingSmilePlanTemplates] =
    useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const getSmilePlanCategories = async () => {
    setLoadingSmilePlanCategories(true);
    try {
      const response = await axios.get('admin/v1/smile-plan-categories', {
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
      });
      if (response.status === 200) {
        const { data } = response.data;
        setSmilePlanCategories(data);
      }
    } catch (e) {
      console.log('Response Error: GET - Smile Plan Categories', e);
    } finally {
      setLoadingSmilePlanCategories(false);
    }
  };

  const createSmilePlanCategory = async (data) => {
    setLoadingSmilePlanCategories(true);
    try {
      const response = await axios.post(
        'admin/v1/smile-plan-categories',
        data,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        }
      );
      if (response.status === 200) {
        const { data, success } = response.data;
        setSmilePlanCategories([...smilePlanCategories, data]);
        toast.success(success, 3000);
      }
    } catch (e) {
      console.log('Response Error: POST - Smile Plan Categories', e);
    } finally {
      setLoadingSmilePlanCategories(false);
    }
  };

  const updateSmilePlanCategory = async (data, id) => {
    setLoadingSmilePlanCategories(true);
    try {
      const response = await axios.put(
        `admin/v1/smile-plan-categories/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        }
      );
      if (response.status === 200) {
        const { data, success } = response.data;
        setSmilePlanCategories((prevSmilePlanCategories) => {
          return prevSmilePlanCategories.map((prevSmilePlanCategory) =>
            data.id === prevSmilePlanCategory.id ? data : prevSmilePlanCategory
          );
        });
        toast.info(success, 3000);
      }
    } catch (e) {
      console.log('Response Error: PUT - Smile Plan Categories', e);
    } finally {
      setLoadingSmilePlanCategories(false);
    }
  };

  const deleteSmilePlanCategory = async (id) => {
    setLoadingSmilePlanCategories(true);
    try {
      const response = await axios.delete(
        `admin/v1/smile-plan-categories/${id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        }
      );
      if (response.status === 200) {
        const { data, success } = response.data;
        const updatedSmilePlanCategories = smilePlanCategories.filter(
          (smilePlanCategory) => smilePlanCategory.id !== data
        );
        setSmilePlanCategories(updatedSmilePlanCategories);
        toast.success(success, 3000);
      }
    } catch (e) {
      console.log('Response Error: DELETE - Smile Plan Categories', e);
    } finally {
      setLoadingSmilePlanCategories(false);
    }
  };

  const getSmilePlanTemplates = async (id) => {
    setLoadingSmilePlanTemplates(true);
    try {
      const response = await axios.get(`admin/v1/smile-plan-templates/${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
      });
      if (response.status === 200) {
        const { data } = response.data;
        setSmilePlanTemplates(data);
      }
    } catch (e) {
      console.log('Response Error: GET - Smile Plan Templates', e);
    } finally {
      setLoadingSmilePlanTemplates(false);
    }
  };

  const saveSingleSmilePlanTemplate = async (data) => {
    const newData = { ...data, smile_plan_category_id: selectedOption.value };
    setLoadingSmilePlanTemplates(true);
    try {
      const response = await axios.post(
        'admin/v1/smile-plan-templates/',
        newData,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        }
      );
      if (response.status === 200) {
        const { data, success, method } = response.data;
        if (method === 'update') {
          setSmilePlanTemplates((prevSmilePlanTemplates) => {
            return prevSmilePlanTemplates.map((prevSmilePlanTemplate) =>
              data.id === prevSmilePlanTemplate.id
                ? data
                : prevSmilePlanTemplate
            );
          });
        }
        if (method === 'create') {
          setSmilePlanTemplates([...smilePlanTemplates, data]);
        }
        toast.success(success, 3000);
      }
    } catch (e) {
      console.log('Response Error: Post - Smile Plan Templates', e);
      toast.error(`Errors: ${e?.arabic}, ${e?.english}`, 3000);
    } finally {
      setLoadingSmilePlanTemplates(false);
    }
  };
  
  const deleteSingleSmilePlanTemplate = async (id) => {
    setLoadingSmilePlanTemplates(true);
    try {
      const response = await axios.delete(`admin/v1/smile-plan-templates/${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
      });
      if (response.status === 200) {
        const { data, success } = response.data;
        const updatedSmilePlanTemplates = smilePlanTemplates.filter(
          (smilePlanTemplate) => smilePlanTemplate.id !== data
        );
        setSmilePlanTemplates(updatedSmilePlanTemplates);
        toast.success(success, 3000);
      }
    } catch (e) {
      console.log('Response Error: DELETE - Smile Plan Templates', e);
    } finally {
      setLoadingSmilePlanTemplates(false);
    }
  };

  return (
    <NavigationLayout>
      <ToastContainer />
      <Router history={history}>
        <Page
          fullWidth
          breadcrumbs={[
            { content: 'Content Management', url: '/admin/content' },
          ]}
          title="Smile Plan Categories & Templates"
        >
          <Card sectioned>
            <SmilePlanCategoriesTable
              smilePlanCategories={smilePlanCategories}
              getSmilePlanCategories={getSmilePlanCategories}
              loadingSmilePlanCategories={loadingSmilePlanCategories}
              createSmilePlanCategory={createSmilePlanCategory}
              updateSmilePlanCategory={updateSmilePlanCategory}
              deleteSmilePlanCategory={deleteSmilePlanCategory}
            />
          </Card>
          <Card sectioned>
            <SmilePlanTemplatesTable
              smilePlanCategories={smilePlanCategories}
              getSmilePlanTemplates={getSmilePlanTemplates}
              smilePlanTemplates={smilePlanTemplates}
              loadingSmilePlanTemplates={loadingSmilePlanTemplates}
              saveSingleSmilePlanTemplate={saveSingleSmilePlanTemplate}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              deleteSingleSmilePlanTemplate={deleteSingleSmilePlanTemplate}
            />
          </Card>
        </Page>
      </Router>
    </NavigationLayout>
  );
};

export default SmilePlanCategoriesAndTemplates;
