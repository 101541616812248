import React, { useCallback, useState, useEffect } from "react";
import {
  Page,
  TextField,
  DropZone,
  Caption,
  Thumbnail,
  Stack,
  PageActions,
} from "@shopify/polaris";
import { Router, useParams } from "react-router-dom";
import history from "./history";
import {
  TextStyle,
  Modal,
} from "@shopify/polaris";
import axios from "./axios";
import NavigationLayout from "./components/NavigationLayout";
import Cookies from "js-cookie"

function BlogTopic() {
    const { id } = useParams();
    const [topicName, setTopicName] = useState({en: '', ar:''});
    const [topicTitle, setTopicTitle] = useState({en: '', ar:''});
    const [topicSubTitle, setTopicSubTitle] = useState({en: '', ar:''});
    const [topicBannerAlt, setTopicBannerAlt] = useState({en: '', ar:''});
    const [topicBannerUrl, setTopicBannerUrl] = useState({en: '', ar:''});
    const [topicResponsiveImgUrl, setTopicResponsiveImgUrl] = useState('');
    const [popupActive, setPopupActive] = useState(false);
    const [filesImage1, setFilesImage1] = useState([]);
    const [filesImage2, setFilesImage2] = useState([]);
    const [filesImageResponsive, setFilesImageResponsive] = useState([]);
    const [imageKeyImage1, setImageKeyImage1] = useState("");
    const [imageKeyImage2, setImageKeyImage2] = useState("");
    const [imageKeyImageResponsive, setImageKeyImageResponsive] = useState("");
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
    const [imagePreviewImage1, setImagePreviewImage1] = useState();
    const [imagePreviewImage2, setImagePreviewImage2] = useState();
    const [imagePreviewResponsive, setImagePreviewResponsive] = useState();
    let imageType = "";

    const handleDropZoneImage1 = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) =>
          setFilesImage1((filesImage1) => [...filesImage1, ...acceptedFiles]),
        []
    );
    const fileUploadImage1 = !filesImage1.length && <DropZone.FileUpload />;
    let uploadedFilesImage1 = filesImage1.length > 0 && (
      <Stack alignment="center">
        <Thumbnail
          size="small"
          alt={filesImage1[filesImage1.length - 1].name}
          source={
            validImageTypes.indexOf(filesImage1[filesImage1.length - 1].type) > 0
              ? window.URL.createObjectURL(filesImage1[filesImage1.length - 1])
              : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
          }
        />
        <div>
          {filesImage1[filesImage1.length - 1].name}{" "}
          <Caption>{filesImage1[filesImage1.length - 1].type} bytes</Caption>
        </div>
      </Stack>
    );

    const sendImage1 = useCallback(
      (filesImage1) => {
        setPopupActive(true);
        setImagePreviewImage1(
          validImageTypes.indexOf(filesImage1[filesImage1.length - 1].type) > 0
            ? window.URL.createObjectURL(filesImage1[filesImage1.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        );
        imageType = filesImage1[filesImage1.length - 1].type;
        let headersVar = null;
        let url2 = "";
        const form_data = new FormData();
        form_data.append("content_type", imageType);
        axios
          .post("/admin/v1/images/s3", form_data, {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
          })
          .then((res) => {
            setImageKeyImage1(res.data.key);
            url2 = res.data.url;
            headersVar = res.data.headers;
            axios
              .put(url2, filesImage1[filesImage1.length - 1], {
                headers: {
                  "x-amz-acl": "public-read-write",
                  "Content-Type": imageType,
                },
              })
              .then((res) => {
                setPopupActive(false);
              })
              .catch((err) => setPopupActive(false));
          })
          .catch((err) => setPopupActive(false));
      },
  
      [filesImage1]
    );

    const handleDropZoneImage2 = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) =>
          setFilesImage2((filesImage2) => [...filesImage2, ...acceptedFiles]),
        []
    );
    const fileUploadImage2 = !filesImage2.length && <DropZone.FileUpload />;
    let uploadedFilesImage2 = filesImage2.length > 0 && (
      <Stack alignment="center">
        <Thumbnail
          size="small"
          alt={filesImage2[filesImage2.length - 1].name}
          source={
            validImageTypes.indexOf(filesImage2[filesImage2.length - 1].type) > 0
              ? window.URL.createObjectURL(filesImage2[filesImage2.length - 1])
              : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
          }
        />
        <div>
          {filesImage2[filesImage2.length - 1].name}{" "}
          <Caption>{filesImage2[filesImage2.length - 1].type} bytes</Caption>
        </div>
      </Stack>
    );

    const sendImage2 = useCallback(
      (filesImage2) => {
        setPopupActive(true);
        setImagePreviewImage2(
          validImageTypes.indexOf(filesImage2[filesImage2.length - 1].type) > 0
            ? window.URL.createObjectURL(filesImage2[filesImage2.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        );
        imageType = filesImage2[filesImage2.length - 1].type;
        let headersVar = null;
        let url2 = "";
        const form_data = new FormData();
        form_data.append("content_type", imageType);
        axios
          .post("/admin/v1/images/s3", form_data, {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
          })
          .then((res) => {
            setImageKeyImage2(res.data.key);
            url2 = res.data.url;
            headersVar = res.data.headers;
            axios
              .put(url2, filesImage2[filesImage2.length - 1], {
                headers: {
                  "x-amz-acl": "public-read-write",
                  "Content-Type": imageType,
                },
              })
              .then((res) => {
                setPopupActive(false);
              })
              .catch((err) => setPopupActive(false));
          })
          .catch((err) => setPopupActive(false));
      },
  
      [filesImage2]
    );
 
    const handleDropZoneImageResponsive = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) =>
          setFilesImageResponsive((filesImageResponsive) => [...filesImageResponsive, ...acceptedFiles]),
        []
    );
    const fileUploadImageResponsive = !filesImageResponsive.length && <DropZone.FileUpload />;
    let uploadedFilesImageResponsive = filesImageResponsive.length > 0 && (
      <Stack alignment="center">
        <Thumbnail
          size="small"
          alt={filesImageResponsive[filesImageResponsive.length - 1].name}
          source={
            validImageTypes.indexOf(filesImageResponsive[filesImageResponsive.length - 1].type) > 0
              ? window.URL.createObjectURL(filesImageResponsive[filesImageResponsive.length - 1])
              : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
          }
        />
        <div>
          {filesImageResponsive[filesImageResponsive.length - 1].name}{" "}
          <Caption>{filesImageResponsive[filesImageResponsive.length - 1].type} bytes</Caption>
        </div>
      </Stack>
    );

    const sendImageResponsive = useCallback(
      (filesImageResponsive) => {
        setPopupActive(true);
        setImagePreviewResponsive(
          validImageTypes.indexOf(filesImageResponsive[filesImageResponsive.length - 1].type) > 0
            ? window.URL.createObjectURL(filesImageResponsive[filesImageResponsive.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        );
        imageType = filesImageResponsive[filesImageResponsive.length - 1].type;
        let headersVar = null;
        let url2 = "";
        const form_data = new FormData();
        form_data.append("content_type", imageType);
        axios
          .post("/admin/v1/images/s3", form_data, {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
          })
          .then((res) => {
            setImageKeyImageResponsive(res.data.key);
            url2 = res.data.url;
            headersVar = res.data.headers;
            axios
              .put(url2, filesImageResponsive[filesImageResponsive.length - 1], {
                headers: {
                  "x-amz-acl": "public-read-write",
                  "Content-Type": imageType,
                },
              })
              .then((res) => {
                setPopupActive(false);
              })
              .catch((err) => setPopupActive(false));
          })
          .catch((err) => setPopupActive(false));
      },
  
      [filesImageResponsive]
    );

    const fetchTopicById = async () => {
        await axios.get(`admin/v1/blog-topic/${id}`, {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
          }).then((result) => {
            let topicData = result.data.topic;
            setTopicName({en: topicData.name_en, ar: topicData.name_ar});
            setTopicTitle({en: topicData.title_en, ar: topicData.title_ar});
            setTopicSubTitle({en: topicData.subtitle_en, ar: topicData.subtitle_ar});
            setTopicBannerAlt({en: topicData.alt_en, ar: topicData.alt_ar});
            setTopicBannerUrl({en: topicData.banner_en_url, ar: topicData.banner_ar_url});
            setTopicResponsiveImgUrl(topicData.responsive_img_url);
          }).catch((err) => console.log(err));
    }

    const handleSubmit = () => {
        setPopupActive(true);
        const body = {
            name_en: topicName.en,
            name_ar: topicName.ar,
            title_en: topicTitle.en,
            title_ar: topicTitle.ar,
            subtitle_en: topicSubTitle.en,
            subtitle_ar: topicSubTitle.ar,
            banner_en: imageKeyImage1,
            banner_ar: imageKeyImage2,
            responsive_img: imageKeyImageResponsive,
            alt_en: topicBannerAlt.en,
            alt_ar: topicBannerAlt.ar,
        };
        let url = id ? `admin/v1/blog-topic/update/${id}` : 'admin/v1/blog-topic/create';
        axios.post(url, body, {
            headers: {
                Authorization: "Bearer " + Cookies.get("token"),
            },
        }).then((res) => {
            history.push('/admin/blogs');
        }).catch(err => {
            console.log(err)
        }).finally(f => {
            setPopupActive(false);
        });
    }

    const handleDelete = () => {
        setPopupActive(true);
        axios.delete(`admin/v1/blog-topic/delete/${id}`, {
            headers: {
                Authorization: "Bearer " + Cookies.get("token"),
            },
        }).then((res) => {
            history.push('/admin/blogs');
        }).catch(err => {
            console.log(err)
        }).finally(f => {
            setPopupActive(false);
        });
    }

    useEffect(() => {
        if(id){
            fetchTopicById();
        }
    }, [])

    return (
        <NavigationLayout>
            <Router history={history}>
                <Page breadcrumbs={[{ content: "All faqs", url: "/admin/blogs" }]}>
                    <br/>
                    <div style={{display:"flex", alignContent:"center", gap:"20px", width:"100%"}}>
                       <div style={{width:"50%", backgroundColor:"white", borderRadius:"10px", boxShadow:"1px 1px 5px lightgray", padding:"15px"}}>
                            <TextStyle variation="strong">Blog Topic (English)</TextStyle>
                            <div>
                                <br/>
                                <TextStyle variation="strong">Name</TextStyle>
                                <TextField
                                    placeholder="Enter name"
                                    type="text"
                                    value={topicName.en}
                                    onChange={(value) => setTopicName((prev) => { return {...prev, en: value}})}
                                    error={''}
                                />
                                <br/>
                                <TextStyle variation="strong">Title</TextStyle>
                                <TextField
                                    placeholder="Enter title"
                                    type="text"
                                    onChange={(value) => setTopicTitle((prev) => { return {...prev, en: value}})}
                                    value={topicTitle.en}
                                    error={''}
                                />
                                <br/>
                                <TextStyle variation="strong">Subtitle</TextStyle>
                                <TextField
                                    placeholder="Enter subtitle"
                                    type="text"
                                    onChange={(value) => setTopicSubTitle((prev) => { return {...prev, en: value}})}
                                    value={topicSubTitle.en}
                                    error={''}
                                />
                                <br/>
                                <TextStyle variation="strong">Banner</TextStyle>
                                <div style={{display: 'flex', columnGap: 5}}>
                                    <Thumbnail
                                        size="large"
                                        source={imagePreviewImage1 ? imagePreviewImage1 : topicBannerUrl.en}
                                    />
                                    <div style={{flex: 1}}>
                                        <DropZone onDrop={handleDropZoneImage1} onDropAccepted={sendImage1}>
                                            {uploadedFilesImage1}
                                            {fileUploadImage1}
                                        </DropZone>
                                    </div>
                                </div>
                            </div>
                            <br/>
                        </div>
                        <div style={{width:"50%", backgroundColor:"white", borderRadius:"10px", boxShadow:"1px 1px 5px lightgray", padding:"15px"}}>
                            <TextStyle variation="strong">Blog Topic (Arabic)</TextStyle>
                            <div>
                                <br/>
                                <TextStyle variation="strong">Name</TextStyle>
                                <TextField
                                    placeholder="Enter name"
                                    type="text"
                                    value={topicName.ar}
                                    onChange={(value) => setTopicName((prev) => { return {...prev, ar: value}})}
                                    error={''}
                                />
                                <br/>
                                <TextStyle variation="strong">Title</TextStyle>
                                <TextField
                                    placeholder="Enter title"
                                    type="text"
                                    onChange={(value) => setTopicTitle((prev) => { return {...prev, ar: value}})}
                                    value={topicTitle.ar}
                                    error={''}
                                />
                                <br/>
                                <TextStyle variation="strong">Subtitle</TextStyle>
                                <TextField
                                    placeholder="Enter subtitle"
                                    type="text"
                                    value={topicSubTitle.ar}
                                    onChange={(value) => setTopicSubTitle((prev) => { return {...prev, ar: value}})}
                                    error={''}
                                />
                                <br/>
                                <TextStyle variation="strong">Banner</TextStyle>
                                <div style={{display: 'flex', columnGap: 5}}>
                                    <Thumbnail
                                        size="large"
                                        source={imagePreviewImage2 ? imagePreviewImage2 : topicBannerUrl.ar}
                                    />
                                    <div style={{flex: 1}}>
                                        <DropZone onDrop={handleDropZoneImage2} onDropAccepted={sendImage2}>
                                            {uploadedFilesImage2}
                                            {fileUploadImage2}
                                        </DropZone>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div style={{margin: 'auto', display:"flex", flexDirection: 'column', gap:"20px", width:"50%", backgroundColor:"white", borderRadius:"10px", boxShadow:"1px 1px 5px lightgray", padding:"15px"}}>
                        <TextStyle variation="strong">Responsive image</TextStyle>
                        <div style={{display: 'flex', columnGap: 5}}>
                            <Thumbnail
                                size="large"
                                source={imagePreviewResponsive ? imagePreviewResponsive : topicResponsiveImgUrl}
                            />
                            <div style={{flex: 1}}>
                                <DropZone onDrop={handleDropZoneImageResponsive} onDropAccepted={sendImageResponsive}>
                                    {uploadedFilesImageResponsive}
                                    {fileUploadImageResponsive}
                                </DropZone>
                            </div>
                        </div>
                        <TextStyle variation="strong">Responsive image alt (English)</TextStyle>
                        <TextField
                            placeholder="English image alt"
                            type="text"
                            onChange={(value) => setTopicBannerAlt((prev) => { return {...prev, en: value}})}
                            value={topicBannerAlt.en}
                            error={''}
                        />
                        <TextStyle variation="strong">Responsive image alt (Arabic)</TextStyle>
                        <TextField
                            placeholder="Arabic image alt"
                            type="text"
                            value={topicBannerAlt.ar}
                            onChange={(value) => setTopicBannerAlt((prev) => { return {...prev, ar: value}})}
                            error={''}
                        />
                    </div>
                    <br />
                    <PageActions
                        primaryAction={{
                            content: 'Save',
                            onClick: handleSubmit,
                        }}
                        secondaryActions={{
                            destructive: true,
                            disabled: !id,
                            content: 'Delete',
                            onClick: handleDelete,
                        }}
                    />
                    <Modal open={popupActive} loading={true}></Modal>
                </Page>
            </Router>
        </NavigationLayout>
    );
}
export default BlogTopic;
