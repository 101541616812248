import React, { useState, useEffect, useContext } from "react";
import {
  DataTable,
  Button,
  Image,
  DisplayText,
  Card,
  TextStyle,
  ButtonGroup,
  Spinner,
} from "@shopify/polaris";
import emptyIcon from "../img/emptyList.svg";
import ShopLayout from "../components/ShopLayout";
import { ShopContext } from "../ShopContext";
import history from "../history";

export default function ShopProducts() {
  const {
    states: {
      shopItems,
    },
    actions: {
      getShopProducts,
    }
  } = useContext(ShopContext);

  const [items, setItems] = useState([]);

  const emtyState = shopItems.loading ? (
    <div className="spinnerContainer">
      <div className="vertical-center">
        <Spinner accessibilityLabel="Spinner example" size="large" />
      </div>
    </div>
  ) : (
    !shopItems.products.length &&
    <div className="message-no-result"
    >
      <div className="message-no-result-img">
        <Image src={emptyIcon}></Image>{" "}
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  );

  function handleEdit(id) {
    history.push(`/admin/shop/product/${id}/edit`);
  }

  const addNewProduct = () => {
    history.push('/admin/shop/add');
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return value.map((val) => `Filter by ${val}`).join(", ");
      case "productType":
        return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }

  useEffect(() => {
    getShopProducts();
  }, []);

  useEffect(() => {
    if(shopItems.products.length) {
      setItems(
        shopItems.products.map((item) => [
          item.id,
          JSON.parse(item.title).en,
          item.is_new,
          item.is_trending,
          item.tabby_product,
          item.archived,
          item.code,
          <ButtonGroup>
            <Button id={String(item.id)} onClick={() => handleEdit(item.id)}>
              Edit
            </Button>
          </ButtonGroup>,
        ])
      );
    }
  }, [shopItems.products])

  return (
    <ShopLayout>
      <div className='shop-products-wrapper'>
        <div className='button-wrapper'>
          <Button primary onClick={addNewProduct}>Add new</Button>
        </div>
        <Card>
          <DataTable
            columnContentTypes={[
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
            ]}
            headings={[
              <TextStyle variation="strong">ID</TextStyle>,
              <TextStyle variation="strong">Title</TextStyle>,
              <TextStyle variation="strong">New</TextStyle>,
              <TextStyle variation="strong">Trending</TextStyle>,
              <TextStyle variation="strong">Tabby Product</TextStyle>,
              <TextStyle variation="strong">Archived</TextStyle>,
              <TextStyle variation="strong">Code</TextStyle>,
              <TextStyle variation="strong"></TextStyle>,
            ]}
            rows={items}
            sortable={[true, false, false, false, false, false, false]}
            defaultSortDirection="descending"
            initialSortColumnIndex={0}
            verticalAlign="center"
          />
          {emtyState}
        </Card>
      </div>
    </ShopLayout>
  );
}

