import React, { useState, useEffect } from 'react';
import { Card, Layout } from '@shopify/polaris';
import Cookie from 'js-cookie';
import axios from '../../../axios';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Loading from '../../../components/Loading/loading';
import SmilePlanDashboard from './smile-plan-dashboard.component';
function YourSmilePlan({
  clinicName,
  planType,
  status,
  pageTitle,
  clinicStatus,
  userId,
}) {
  const history = useHistory();
  let { id } = useParams();
  const [smilePlans, setSmilePlans] = useState({
    tp: null,
    rtp: null,
    mca: null,
  });
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [activePlan, setActivePlan] = useState({
    plan: null,
    proceduresData: [],
  });

  const getPreferredSmilePlan = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `admin/v1/users/${id}/smile-plan/preferred`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get('token')}`,
          },
        }
      );
      if (response.status === 200) {
        let plansData = {};
        if (response.data.data) {
          setActivePlan({
            plan: response.data.data,
            proceduresData: response.data?.ipr_info?.procedures,
          });
          plansData.tp = {
            data: response.data.data,
            iprInfo: response?.data?.ipr_info,
          };
        }
        if (response?.data?.refinement_data) {
          plansData.rtp = {
            data: response?.data?.refinement_data,
            iprInfo: response?.data?.ipr_info_refinement,
          };
        }
        if (response?.data?.mca_data) {
          plansData.mca = {
            data: response?.data?.mca_data,
            iprInfo: response?.data?.ipr_info_mca,
          };
        }
        setSmilePlans(plansData);
        setCount(response.data.count);
      }
    } catch (error) {
      console.log('Error in preferred smile plan api', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = () => {
    let state = {
      clinicName: clinicName,
      planType: planType,
      status: status,
      pageTitle: pageTitle,
      clinicStatus: clinicStatus,
      userId: userId,
    };
    if (!smilePlans.tp) {
      history.push(`/admin/users/${id}/dashboard/yoursmileplan/create`, state);
    } else {
      state = {
        ...state,
        treatmentStage: activePlan.plan?.name,
        procedureIprSheet: activePlan?.proceduresData,
      };
      let url = `/admin/users/${id}/dashboard/yoursmileplan/${activePlan.plan?.id}/update`;
      if(activePlan.plan?.type != 'tp') {
        url = `/admin/users/${id}/dashboard/yoursmileplan/${activePlan.plan?.id}/refinement/edit`;
      }
      history.push(
        url,
        state
      );
    }
  };

  useEffect(() => {
    void getPreferredSmilePlan();
  }, []);

  if (loading && !id) {
    return <div></div>;
  }
  return (
    <Layout.Section>
      <Card
        sectioned
        title="TREATMENT PLANS"
        actions={[
          {
            content: `${
              !loading
                ? `${
                    count === undefined
                      ? 'Create plan'
                      : count > 1
                      ? 'Edit plans'
                      : 'Edit plan'
                  }`
                : ''
            }`,
            onClick: handleEdit,
            disabled: loading,
          },
        ]}
      >
        {loading ? (
          <Loading />
        ) : (
          <SmilePlanDashboard
            plans={smilePlans}
            loading={loading}
            setActivePlan={setActivePlan}
          />
        )}
      </Card>
    </Layout.Section>
  );
}
export default React.memo(YourSmilePlan);
