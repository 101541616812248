import { useState } from "react";
import {Card, Modal, Page, Tabs, TextContainer} from "@shopify/polaris";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TabComponent1 from "./SentTab";
import TabComponent2 from "./InTransitTab";
import TabComponent3 from "./DeliveredTab";
import axios from "../../axios";
import Cookie from "js-cookie";


function BatchTrackings() {
  const [activeTab, setActiveTab] = useState(0);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [data, setData] = useState({userId: null, batchId: null});
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const {userId, batchId } = data;

  const handleTabSelect = (selectedTabIndex) => {
    setActiveTab(selectedTabIndex);
  };
  
  const handleClose = () => {
    setOpenActionModal(false)
  }

  const batchesActions = async () => {
    try {
      setLoading(true);
      const query = `/admin/v1/users/${userId}/batch/${batchId}`;
      const body = {
        type: 'receive',
      };
      await axios.post(query, body, {
        headers: {
          Authorization: `Bearer ${Cookie.get('token')}`,
        },
      });
      toast.success(`Marked as received successfully`, 3000);
    } catch (e) {
      console.log('Error in mark as received api', e);
    } finally {
      setLoading(false);
      handleClose();
      setReload(!reload);
    }
  };
  
  const markAsReceived = async (patientId, dispatchId) => {
    setData({userId: patientId, batchId: dispatchId})
    setOpenActionModal(true);
  }

  const tabs = [
    {
      id: "tab1",
      content: "Sent",
      component: <TabComponent1 />,
    },
    {
      id: "tab2",
      content: "In Transit",
      component: <TabComponent2 markAsReceived={markAsReceived} reload={reload}/>,
    },
    {
      id: "tab3",
      content: "Delivered",
      component: <TabComponent3 />,
    },
  ];

  return (
    <Page
      fullWidth
      title="Batch Trackings List"
      breadcrumbs={[{ content: "List Of Reports", url: "/admin/reports" }]}
    >
      <ToastContainer />
      <Card sectioned>
        <Tabs tabs={tabs} selected={activeTab} onSelect={handleTabSelect} />
        <div>{tabs[activeTab].component}</div>
      </Card>
      <Modal
        open={openActionModal}
        onClose={handleClose}
        title="Action"
        primaryAction={{
          content: 'Yes',
          onAction: batchesActions,
          loading: loading,
        }}
        secondaryActions={[
          {
            content: 'No',
            onAction: handleClose,
            disabled: loading,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure you want to make this action?</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </Page>
  );
}

export default BatchTrackings;
