import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Cookie from "js-cookie";
import axios from "../axios";
import {
  Thumbnail,
  Page,
  Card,
  Badge,
  FormLayout,
  TextStyle,
  Modal,
} from "@shopify/polaris";
import LoadingScreen from "react-loading-screen";
import PreScreening from "../Users/DashboardComponents/PreScreening/PreScreening";

const LeadDetails = () => {
  const { id } = useParams();
  const [responseLeadDetails, setResponseLeadDetails] = useState("");
  const [popupActive, setPopupActive] = useState(true);
  const switchArchToTreat = (param) => {
    switch (param) {
      case 1:
        return "Top Arch";
      case 2:
        return "Bottom Arch";
      case 3:
        return "Both Arches";
      default:
        return "";
    }
  };
  const switchProblem = (param) => {
    switch (param) {
      case 1:
        return "Crowding";
      case 3:
        return "Spacing";
      default:
        return "";
    }
  };
  useEffect(() => {
    axios
      .get(`/admin/v1/user-leads?filter[id]=${id}`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setPopupActive(false);
        setResponseLeadDetails(result.data.data.data[0]);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <LoadingScreen
      loading={responseLeadDetails ? false : true}
      bgColor="#f6f6f7"
      spinnerColor="#737373"
      textColor="#676767"
      // logoSrc="/logo.png"
      // text="Loading"
    >
      <Page
        title={
          responseLeadDetails?.first_name &&
          responseLeadDetails?.last_name &&
          responseLeadDetails.first_name + " " + responseLeadDetails.last_name
        }
        breadcrumbs={[{ content: "List Of Doctors", url: "/admin/leads" }]}
        titleMetadata={
          responseLeadDetails?.status && (
            <Badge>{responseLeadDetails.status}</Badge>
          )
        }
      >
        <Card sectioned title="Profile">
          <FormLayout>
            {responseLeadDetails?.first_name && (
              <TextStyle>
                First Name: {responseLeadDetails.first_name}
              </TextStyle>
            )}
            {responseLeadDetails?.last_name && (
              <TextStyle>Last Name: {responseLeadDetails.last_name}</TextStyle>
            )}
            {responseLeadDetails?.email && (
              <TextStyle>Email: {responseLeadDetails.email}</TextStyle>
            )}
            {responseLeadDetails?.country && (
              <TextStyle>Country: {responseLeadDetails.country}</TextStyle>
            )}
            {responseLeadDetails?.arches_to_treat && (
              <TextStyle>
                Arch to treat:{" "}
                {switchArchToTreat(
                  parseInt(responseLeadDetails.arches_to_treat)
                )}
              </TextStyle>
            )}
            {responseLeadDetails?.teeth_description_ids && (
              <TextStyle>
                Problem:{" "}
                {switchProblem(
                  parseInt(responseLeadDetails.teeth_description_ids)
                )}
              </TextStyle>
            )}
          </FormLayout>
        </Card>
        <PreScreening/>
      </Page>
    </LoadingScreen>
  );
};

export default LeadDetails;
