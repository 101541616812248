import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../../../history";
import List from "./List";
import Add from "./Add";
import Edit from "./Edit";

function Images() {
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/admin/book-a-scan-countries/:id/cities/:idCity/clinics/:idClinic/images"
          component={List}
        />
        <Route
          path="/admin/book-a-scan-countries/:id/cities/:idCity/clinics/:idClinic/images/new"
          component={Add}
        />
        <Route
          path="/admin/book-a-scan-countries/:id/cities/:idCity/clinics/:idClinic/images/:idImage"
          component={Edit}
        />
      </Switch>
    </Router>
  );
}
export default Images;
