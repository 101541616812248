import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Page,
  Image,
  DisplayText,
  Loading,
  ChoiceList,
  Card,
  TextStyle,
  Modal,
  TextContainer,
  Toast,
  Icon, Spinner, Button,
} from "@shopify/polaris";
import { ExportMinor } from "@shopify/polaris-icons";

import axios from "../axios";
import history from "../history";

import "@zendeskgarden/react-pagination/dist/styles.css";
import { Pagination } from "@zendeskgarden/react-pagination";
import emptyIcon from "../img/emptyList.svg";
import ScanClinicReports from "./ScanClinicReports";
import ScanClinicPricing from "./ScanClinicPricing";

export default function ListOfReports() {
  const [clinicSelectOptions, setClinicSelectOptions] = useState([]);
  const [allScanClinicsPricing, setAllScanClinicsPricing] = useState([]);
  const [selectedClinics, setSelectedClinics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingClinics, setIsLoadingClinics] = useState(false);
  const [isLoadingClinicsPricing, setIsLoadingClinicsPricing] = useState(false);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [view, setView] = useState('')
  const handleRowClick = (e) => {
    if(e.currentTarget.id == "Survey Report"){
      history.push("/admin/reports/survey-report")
    }else if(e.currentTarget.id == "Impression Sales"){
      history.push("/admin/reports/impression-sales")
    }else if(e.currentTarget.id == "Overdue Payments"){
      history.push("/admin/reports/over-due-payemnts-report")
    }else if(e.currentTarget.id == "Completed Aligner Cases"){
      history.push("/admin/reports/completed-aligner-cases")
    } else if(e.currentTarget.id == "Clinic Reports") {
      history.push("/admin/reports/clinics-reports-invoices");
    } else if(e.currentTarget.id == "Payments Write-off List"){
      history.push("/admin/reports/written-off-report")
    } else if(e.currentTarget.id === "Product Requests List"){
      history.push("/admin/reports/product-requests")
    } else if(e.currentTarget.id == "pending-tps"){
      history.push("/admin/reports/pending-tps")
    } else if(e.currentTarget.id == "approved-tps"){
      history.push("/admin/reports/approved-tps")
    } else if(e.currentTarget.id == "designer-cases"){
      history.push("/admin/reports/designer-cases")
    } else {
      history.push("/admin/reports/redo-kits");
    }
  };
  
  const getClinics = async () => {
    try {
      const options = [{label: "All", value: null}];
      setIsLoadingClinics(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/clinics-ids`
      );
      if (response) {
        const { clinics } = response.data;
        clinics.map((clinicId) =>
          options.push({ label: clinicId.name.en, value: clinicId.id })
        );
        setClinicSelectOptions(options);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingClinics(false);
    }
  };
  
  const getClinicsScanPricing = async () => {
    try {
      setIsLoadingClinicsPricing(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/clinics-pricing`
      );
      if (response) {
        const { allClinicPricing } = response.data;
        setAllScanClinicsPricing(allClinicPricing);
        const clinicIds = [];
        for (const element of allClinicPricing) {
          clinicIds.push(element.clinic_id);
        }
        setSelectedClinics(clinicIds);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingClinicsPricing(false);
    }
  };

  const exportDealsWithNoTasks = () => {
    if(view){
      axios.get(`${process.env.REACT_APP_BASE_URL}/admin/deals-tasks-csv/${view}/1`)
      .then((response) => {
        if(response?.data?.success == false){
        } else {
            window.open(
            `${process.env.REACT_APP_BASE_URL}/admin/deals-tasks-csv/${view}/1`
          );
        }
      })
    }
  }
  const [items, setItems] = useState([
    [
      <div
        className="itemClick"
        id="Survey Report"
        onClick={(e) => handleRowClick(e)}
      >
        Survey Report
      </div>,
      <div
        className="itemClick"
        id="Survey Report"
        onClick={(e) => handleRowClick(e)}
      >
        Satisfaction Survey Results Dashboard
      </div>,
    ],
    [
      <div
        className="itemClick"
        id="Impression Sales"
        onClick={(e) => handleRowClick(e)}
      >
        Impression Sales Stages
      </div>,
      <div
        className="itemClick"
        id="Impression Sales"
        onClick={(e) => handleRowClick(e)}
      >
        Clients who purchased impression kits distributedover sales stages
      </div>,
    ],
    [
      <div
        className="itemClick"
        id="Redo Kits"
        onClick={(e) => handleRowClick(e)}
      >
        Redo Kits
      </div>,
      <div
        className="itemClick"
        id="Redo Kits"
        onClick={(e) => handleRowClick(e)}
      >
        Number of redo kits that have been sent
      </div>,
    ],
    [
      <div
        className="itemClick"
        id="Completed Aligner Cases"
        onClick={(e) => handleRowClick(e)}
      >
        Completed Aligner Cases
      </div>,
      <div
        className="itemExportClick"
        id="Completed Aligner Cases"
        onClick={(e) => handleRowClick(e)}
      >
        Export list of all completed aligner cases
      </div>,
    ],
    [
      <div
        className="itemClick"
        id="Overdue Payments"
        onClick={(e) => handleRowClick(e)}
      >
        Overdue Payments
      </div>,
      <div
        className="itemExportClick"
        id="Overdue Payments"
        onClick={(e) => handleRowClick(e)}
      >
        Export list of all overdue payments
      </div>,
    ],
    [
      <div
        className="itemClick"
        id="Payments Write-off List"
        onClick={(e) => handleRowClick(e)}
      >
        Payments Write-off List
      </div>,
      <div
        className="itemClick"
        id="Payments Write-off List"
        onClick={(e) => handleRowClick(e)}
      >
        View the write-off requests, approve and confirm them{" "}
      </div>,
    ],
    [
      <div
          className="itemClick"
          id="Product Requests List"
          onClick={(e) => handleRowClick(e)}
      >
        Product Requests List
      </div>,
      <div
          className="itemClick"
          id="Product Requests List"
          onClick={(e) => handleRowClick(e)}
      >
        View the product requests, request and approve them
      </div>,
    ],
    [
      <div
        className="itemExportClick"
        id="Clinic Reports"
        onClick={(e) => handleRowClick(e)}
      >
        Clinics Reports
      </div>,
      <div
        className="itemExportClick"
        id="Clinic Reports"
        onClick={(e) => handleRowClick(e)}
      >
        Clinics reports and invoices
      </div>,
    ],
    [
      <div
        className="itemExportClick"
        id="pending-tps"
        onClick={(e) => handleRowClick(e)}
      >
        TPs to be approved
      </div>,
      <div
        className="itemExportClick"
        id="pending-tps"
        onClick={(e) => handleRowClick(e)}
      >
        List of pending TPs
      </div>,
    ],
    [
      <div
        className="itemExportClick"
        id="approved-tps"
        onClick={(e) => handleRowClick(e)}
      >
        TPs to be activated
      </div>,
      <div
        className="itemExportClick"
        id="approved-tps"
        onClick={(e) => handleRowClick(e)}
      >
        List of TPs that have been approved and are awaiting activation
      </div>,
    ],
    [
      <div
        className="itemExportClick"
        id="designer-cases"
        onClick={(e) => handleRowClick(e)}
      >
        Designer Cases
      </div>,
      <div
        className="itemExportClick"
        id="designer-cases"
        onClick={(e) => handleRowClick(e)}
      >
        List of TPs for each designer
      </div>,
    ],
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [currentID, setCurrentID] = useState(0);
  let valueFilter = "";
  const [active, setActive] = useState(false);
  const [toastMessage, setToastMessage] = useState("Account Disactivated");
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content={toastMessage} onDismiss={toggleActive} />
  ) : null;
  useEffect(() => {
    getData();
    getClinics();
    getClinicsScanPricing();
  }, []);
  function getData() {
    //setIsLoading(false);
    // axios
    //   .get(
    //     `admin/regards?filter[${
    //       availability === "" ? "name" : availability
    //     }]=${queryValue}&page[size]=${perPage}&page[number]=${currentPage}`
    //   )
    //   .then((result) => {
    //     setIsLoading(false);
    //     setTotalPages(Math.ceil(result.data.meta.total / perPage));
    //     if (result.data.data.length === 0) {
    //       setIsListEmpty(true);
    //     } else {
    //       setIsListEmpty(false);
    //     }
    //     setItems(
    //       result.data.data.map((item) => [
    //         item.id,
    //         <div dir="auto">{item.name}</div>,
    //         <ButtonGroup>
    //           <Button id={String(item.id)} onClick={(e) => handleEdit(e)}>
    //             Edit
    //           </Button>
    //           <Button
    //             destructive
    //             id={String(item.id)}
    //             onClick={(e) => handleDelete(e)}
    //           >
    //             Delete
    //           </Button>
    //         </ButtonGroup>,
    //       ])
    //     );
    //   })
    //   .catch((err) => console.log(err));
  }

  function handleFiltersQueryChange(queryValue) {
    console.log(queryValue);
    setQueryValue(queryValue);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[{ label: "Name", value: "name" }]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
          // allowMultiple
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    []
  );
  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div className="message-no-result"
    >
      <div className="message-no-result-img">
        <Image src={emptyIcon}></Image>{" "}
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : null;

  const [activeDeleteMessage, setActiveDeleteMessage] = useState(false);

  return (
    <Page
      fullWidth
      title="List Of Reports"
      // primaryAction={{
      //   content: "Create Regard",
      //   url: "/admin/regards/create",
      // }}
    >
      {" "}
      {loadingMarkup}
      <Card>
        <DataTable
          columnContentTypes={["text", "text"]}
          headings={[
            <TextStyle variation="strong">Name</TextStyle>,
            <TextStyle variation="strong">Description</TextStyle>,
          ]}
          rows={items}
          sortable={[false, false, false, false, false, false, false]}
          defaultSortDirection="descending"
          verticalAlign="center"
          // initialSortColumnIndex={0}
          // onSort={handleSort}
        />
        {totalPages >= 2 && (
          <div id="pagination" style={{ marginTop: 20 }}>
            <Pagination
              totalPages={totalPages}
              pagePadding={3}
              currentPage={currentPage}
              onChange={handlePageChange}
            />
          </div>
        )}
        {emtyState}
      </Card>
      <Card title="Batch Trackings">
        <Card.Section>
        <Button primary onClick={() => history.push("/admin/reports/batch-trackings")}>View Batch Trackings</Button>
        </Card.Section>
      </Card>
      <Card title="Deals without associated tasks">
        <Card.Section>
        <div
         style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}
         id="Deals"
       >
         <input style={{padding:"8px", borderRadius:"5px", width:"250px"}} type="text" placeholder="Enter deals view id..." onChange={(e) => setView(e.target.value)}/>
         <button style={{border:"none", backgroundColor:"transparent", cursor:"pointer"}} color="base" onClick={exportDealsWithNoTasks}> <Icon source={ExportMinor} color="base"/></button>
       </div>
        </Card.Section>
      </Card>
      {toastMarkup}
      <Modal
        open={activeDeleteMessage}
        onClose={handleChangeDeleteMessage}
        title="Delete Salutation"
        primaryAction={{
          content: "No",
          onAction: handleNoDelete,
        }}
        secondaryActions={[
          {
            content: "Yes",
            onAction: () => handleYesDelete(currentID),
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure you want to delete this salutation ?</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
      {isLoadingClinics ? <div style={{ display: "flex", justifyContent: "center", width: "100%", backgroundColor: "white", padding:"15px", marginTop:"15px", marginBottom:"15px"  }}>
        <Spinner accessibilityLabel="Loading Clinics Pricing" size="large" />
      </div> : <ScanClinicReports clinicSelectOptions={clinicSelectOptions}/>  }
      { isLoadingClinics || isLoadingClinicsPricing ? <div style={{ display: "flex", justifyContent: "center", width: "100%", backgroundColor: "white", padding:"15px", marginTop:"15px", marginBottom:"15px"  }}>
        <Spinner accessibilityLabel="Loading Clinics Pricing" size="large" />
      </div> : <ScanClinicPricing allScanClinicsPricing={allScanClinicsPricing} clinicSelectOptions={clinicSelectOptions} selectedClinics={selectedClinics}/> }      
    </Page>
  );

  function findArrayElementById(array, id) {
    console.log("array=", array);
    console.log("id=", id);
    return array.find((element, index) => {
      return parseInt(element.id) === parseInt(id);
    });
  }
  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return value.map((val) => `Filter by ${val}`).join(", ");
      case "productType":
        return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }

  function handlePageChange(currentPage) {
    setCurrentPage(currentPage);
  }

  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element.id) === parseInt(id);
    });
  }

  function handleChangeDeleteMessage() {
    setActiveDeleteMessage(false);
  }

  function handleNoDelete() {
    setActiveDeleteMessage(!activeDeleteMessage);
  }
  function handleYesDelete(id) {
    setActiveDeleteMessage(!activeDeleteMessage);
    axios
      .delete(`/admin/regards/${id}`)
      .then((res) => {
        setToastMessage("This regard is successfully deleted");
        toggleActive();
        getData();
      })
      .catch((err) => console.log(err));
  }
}
