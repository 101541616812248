import React from "react";
const Notes = (props) => {
  return (
    <div>
      <div >
        <p className="notes">
          The IPR amounts indicated between the teeth are to be divided equally
          in terms of reduction unless otherwise indicated below
        </p>
        <p className="notes arabic-text-ipr"
          style={{
            textAlign: "right",
          }}
        >
          إنَّ مسافة البّرد تقسَّم بالتساوي بين الأسنان ما لمذكر خلاف ذلك أدناه <span dir="rtl">.</span>&rlm;
        </p>
      </div>
      <div
        className="row-flexing"
        style={{
          margin: "0% ",
          gap: "10px",
        }}
      >
        <div style={{ width: "70%" }}>
          <h4
            style={{
              marginBottom: "2px",
              marginTop: "2px",

            }}
          >
           Dear Doctor, kindly find here the guidelines with exceptions, if any
          </h4>
          <div className="comment-border">
            {props.Value.comments.map((comment, index) => (
              <div className="patient-comment" key={index}>
                {comment}
              </div>
            ))}
          </div>
        </div>
        <div style={{ width: "30%" }}>
          <h4
            style={{
              marginBottom: "2px",
              marginTop: "2px",
            }}
          >
            Other notes:
          </h4>
          <div className="comment-border" >
            <p>{props.Value.notes}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Notes;
