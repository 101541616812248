import React, { forwardRef, useEffect, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
} from '@mui/material';
import moment from 'moment';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlignersInProductionDialog = ({
  setOpen,
  open,
  markAsAlignersInProduction,
  selectedSmilePlanBatches,
}) => {
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (e) => {
    setDate(e.target.value);
  };

  const triggerMarkAlignersInProduction = () => {
    markAsAlignersInProduction(date);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Set Date for Aligners Production for {selectedSmilePlanBatches?.name}</DialogTitle>
      <DialogContent sx={{ minWidth: '600px' }}>
        <TextField
          type="date"
          value={date}
          onChange={onChange}
          defaultValue={moment().format('YYYY-MM-DD')}
          style={{ width: '100%' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={triggerMarkAlignersInProduction}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlignersInProductionDialog;
