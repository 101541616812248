import React, { useCallback, useEffect, useState } from "react";
import {
    Thumbnail,
    Caption,
    DropZone,
    Stack,
    Page,
    Card,
    Button,
} from "@shopify/polaris";
import axios from "../../axios";
import Cookie from "js-cookie";
import Cookies from "js-cookie";
import { CircularProgress } from "@material-ui/core";
import { useParams } from "react-router-dom";
import MediaItem from "./MediaItem";

function WhatsNewItem() {
    const { versionId } = useParams();
    let imageType = "";
    const [imageKeyImage1, setImageKeyImage1] = useState("");
    const [imageKeyImage2, setImageKeyImage2] = useState("");
    const [filesImage1, setFilesImage1] = useState([]);
    const [filesImage2, setFilesImage2] = useState([]);
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
    const [mediaDetails, setMediaDetails] = useState({
        loading: false,
        titleEn: '',
        titleAr: '',
        descriptionTitleEn: '',
        descriptionTitleAr: '',
        descriptionEn: '',
        descriptionAr: '',
    });
    const [appData, setAppData] = useState({
        loading: false,
        media: [],
    });

    const Item = ({ image_url, title, descriptionTitle, description}) => {
        return (
            <div style={{ padding: '15px', display: 'flex', columnGap: 20, width: '50%' }}>
                <div>
                    <img style={{ width: '80px', height: '80px' }} src={image_url} alt='thumbnail' />
                </div>
                <div style={{display: 'flex', flexDirection: 'column', rowGap: 2, alignItems: 'flex-start'}}>
                    <p><b>Title:</b> {(!title || title.length < 10) ? title : title.replace(title.substr(10), '...')}</p>
                    <p><b>Description Title:</b> {(!descriptionTitle || descriptionTitle.length < 10) ? descriptionTitle : descriptionTitle.replace(descriptionTitle.substr(10), '...')}</p>
                    <p><b>Description:</b> {(!description || description.length < 15) ? description : description.replace(description.substr(15), '...')}</p>
                </div>
            </div>
        )
    }

    const fetchMedia = () => {
        setAppData(prev => ({
            ...prev,
            loading: true,
        }));
        axios.get(`/admin/v1/mobile/${versionId}/whats-new-media`, {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
        }).then((res) => {
            setAppData(prev => ({
                ...prev,
                media: res.data.app_media,
            }));
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setAppData(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleSaveMedia = () => {
        setMediaDetails(prev => ({
            ...prev,
            loading: true,
        }));
        const bodyObj = {
            image_en: imageKeyImage1,
            image_ar: imageKeyImage2,
            title_en: mediaDetails.titleEn,
            description_title_en: mediaDetails.descriptionTitleEn,
            description_en: mediaDetails.descriptionEn,
            title_ar: mediaDetails.titleAr,
            description_title_ar: mediaDetails.descriptionTitleAr,
            description_ar: mediaDetails.descriptionAr,
            mobile_app_version_id: versionId,
        }
        axios.post('/admin/v1/mobile/store-whats-new-media', bodyObj, {
            headers: {
                Authorization: "Bearer " + Cookie.get("token"),
            },
        }).then(res => {
            setMediaDetails(prev => ({
                ...prev,
                titleEn: '',
                titleAr: '',
                descriptionTitleEn: '',
                descriptionTitleAr: '',
                descriptionEn: '',
                descriptionAr: '',
            }));
            setFilesImage1([]);
            setFilesImage2([]);
            fetchMedia();
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setMediaDetails(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleChange = (property, value) => {
        setMediaDetails(prev => ({
            ...prev,
            [property]: value,
        }));
    }

    const sendImage1 = useCallback(
        (filesImage1) => {
            imageType = filesImage1[filesImage1.length - 1].type;
            const form_data = new FormData();
            form_data.append("content_type", imageType);
            axios.post("/admin/v1/images/s3", form_data, {
                headers: {
                    Authorization: "Bearer " + Cookie.get("token"),
                },
            }).then((res) => {
                setImageKeyImage1(res.data.key);
                let url2 = res.data.url;
                axios.put(url2, filesImage1[filesImage1.length - 1], {
                    headers: {
                        "x-amz-acl": "public-read-write",
                        "Content-Type": imageType,
                    },
                }).then((res) => {
                }).catch((err) => {
                    console.log('err', err);
                });
            }).catch((err) => {
                console.log('err', err);
            });
        }, [filesImage1]
    );

    const sendImage2 = useCallback(
        (filesImage2) => {
            imageType = filesImage2[filesImage2.length - 1].type;
            const form_data = new FormData();
            form_data.append("content_type", imageType);
            axios.post("/admin/v1/images/s3", form_data, {
                headers: {
                    Authorization: "Bearer " + Cookie.get("token"),
                },
            }).then((res) => {
                setImageKeyImage2(res.data.key);
                let url2 = res.data.url;
                axios.put(url2, filesImage2[filesImage2.length - 1], {
                    headers: {
                        "x-amz-acl": "public-read-write",
                        "Content-Type": imageType,
                    },
                }).then((res) => {
                }).catch((err) => {
                    console.log('err', err);
                });
            }).catch((err) => {
                console.log('err', err);
            });
        },
        [filesImage2]
    );
    const handleDropZoneImage1 = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) =>
            setFilesImage1((filesImage1) => [...filesImage1, ...acceptedFiles]),
        []
    );
    const handleDropZoneImage2 = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) =>
            setFilesImage2((filesImage2) => [...filesImage2, ...acceptedFiles]),
        []
    );
    const fileUploadImage1 = !filesImage1.length && <DropZone.FileUpload />;
    let uploadedFilesImage1 = filesImage1.length > 0 && (
        <Stack alignment="center">
            <div>
                {filesImage1[filesImage1.length - 1].name}{" "}
                <Caption>{filesImage1[filesImage1.length - 1].type} bytes</Caption>
            </div>
        </Stack>
    );

    const fileUploadImage2 = !filesImage2.length && <DropZone.FileUpload />;
    let uploadedFilesImage2 = filesImage2.length > 0 && (
        <Stack alignment="center">
            <Thumbnail
                size="small"
                alt={filesImage2[filesImage2.length - 1].name}
                source={
                    validImageTypes.indexOf(filesImage2[filesImage2.length - 1].type) > 0
                        ? window.URL.createObjectURL(filesImage2[filesImage2.length - 1])
                        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
                }
            />
            <div>
                {filesImage2[filesImage2.length - 1].name}{" "}
                <Caption>{filesImage2[filesImage2.length - 1].type} bytes</Caption>
            </div>
        </Stack>
    );

    useEffect(() => {
        fetchMedia();
    }, [])

    return (
        <Page title="What's New" breadcrumbs={[
            {
                content: "Dashboard",
                url: "/admin/mobile-app",
            },
        ]}>
            <Card sectioned>
                <Card>
                    <div style={{ padding: '60px', display: "grid", gap: "15px", position: "relative" }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <MediaItem
                                title={mediaDetails.titleEn}
                                descriptionTitle={mediaDetails.descriptionTitleEn}
                                description={mediaDetails.descriptionEn}
                                lang='En'
                                handleChange={handleChange}
                                titleProp='titleEn'
                                descriptionTitleProp='descriptionTitleEn'
                                descriptionProp='descriptionEn'
                                onDrop={handleDropZoneImage1}
                                onDropAccepted={sendImage1}
                                uploadedFilesImage={uploadedFilesImage1}
                                fileUploadImage={fileUploadImage1}
                                imageUrl={
                                    validImageTypes.indexOf(filesImage1[filesImage1.length - 1]?.type) > 0 ?
                                    window.URL.createObjectURL(filesImage1[filesImage1.length - 1]) :
                                    'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304'
                                }
                            />
                            <MediaItem
                                title={mediaDetails.titleAr}
                                descriptionTitle={mediaDetails.descriptionTitleAr}
                                description={mediaDetails.descriptionAr}
                                lang='Ar'
                                handleChange={handleChange}
                                titleProp='titleAr'
                                descriptionTitleProp='descriptionTitleAr'
                                descriptionProp='descriptionAr'
                                onDrop={handleDropZoneImage2}
                                onDropAccepted={sendImage2}
                                uploadedFilesImage={uploadedFilesImage2}
                                fileUploadImage={fileUploadImage2}
                                imageUrl={
                                    validImageTypes.indexOf(filesImage2[filesImage2.length - 1]?.type) > 0 ?
                                    window.URL.createObjectURL(filesImage2[filesImage2.length - 1]) :
                                    'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304'
                                }

                            />
                        </div>
                        <div style={{textAlign: 'right'}}>
                            <Button onClick={handleSaveMedia} primary disabled={mediaDetails.loading} loading={mediaDetails.loading}>Save</Button>
                        </div>
                    </div>
                </Card>
                <div className='whats-new-media-list'>
                {
                    appData.loading ?
                        <CircularProgress color='primary' />
                    :
                    appData.media.map((file, index) => (
                        <Card
                            key={index}
                            sectioned
                            actions={[
                                {
                                    content: 'Edit',
                                    url: `/admin/mobile-app/${versionId}/whats-new/${file.id}`,
                                },
                            ]}
                        >
                             <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                <Item image_url={file.image_en_url} title={file.title_en} descriptionTitle={file.description_title_en} description={file.description_en} />
                                <Item image_url={file.image_ar_url} title={file.title_ar} descriptionTitle={file.description_title_ar} description={file.description_ar} />
                            </div>
                        </Card>
                    ))
                }
                </div>
            </Card>
        </Page>
    )
}
export default WhatsNewItem;
