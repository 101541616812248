import { Card, Page } from '@shopify/polaris';
import { useLocation, useParams } from 'react-router-dom';
import UserHeader from '../../../components/UserLayout/user-header';
import React, { useState } from 'react';
import axios from '../../../axios';
import Cookie from 'js-cookie';
import UserFreshsalesContactsList from './user-freshsales-contacts-list.component';

const UserFreshsalesContacts = () => {
  const { id } = useParams();
  const [freshsalesContacts, setFreshsalesContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  let linkState = {
    planType: '',
    clinicStatus: '',
    clinicName: '',
    status: '',
    userId: id,
    pageTitle: '',
  };
  if (location.state !== undefined) {
    const { planType, clinicStatus, clinicName, status, userId, pageTitle } =
      location.state;
    linkState = {
      planType,
      clinicStatus,
      clinicName,
      status,
      userId,
      pageTitle,
    };
  }
  const { planType, clinicStatus, clinicName, status, userId, pageTitle } =
    linkState;

  const fetchUserFreshsalesContacts = async (signal) => {
    try {
      setLoading(true);
      const response = await axios(
        `/admin/v1/users/${id}/freshsales-contacts`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get('token')}`,
          },
          signal,
        }
      );
      if (response.status === 200) {
        const { data } = response.data;
        setFreshsalesContacts(data);
      }
    } catch (error) {
      console.log('Error while fetching user freshsales contacts');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page fullWidth>
      <UserHeader
        pageTitle={pageTitle}
        status={status}
        userId={userId}
        clinicStatus={clinicStatus}
        clinicName={clinicName}
        planType={planType}
      />
      <br />
      <h1 className="page-header">User Freshsales Contacts</h1>
      <Card sectioned>
        <UserFreshsalesContactsList
          freshsalesContacts={freshsalesContacts}
          fetchUserFreshsalesContacts={fetchUserFreshsalesContacts}
          loading={loading}
        />
      </Card>
    </Page>
  );
};

export default UserFreshsalesContacts;
