import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../history";
import List from "./List";
import Create from "./Create";
import { AppProvider } from "@shopify/polaris";
import MainsSub from "./MainSub";
import MainDashboard from "../Users/MainDashboard";
import NavigationLayout from "../components/NavigationLayout";
import ManualPayments from "./ManualPayments";

function Orders() {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/orders" component={List} />
          <Route path="/admin/orders/create" component={Create} />
          <Route path="/admin/orders/manual/payments/:mail?" component={ManualPayments} />
          <Route path="/admin/orders/:id" component={MainsSub} />
          <Route path="/admin/users/:id/dashboard" component={MainDashboard} />
        </Switch>
      </Router>
    </NavigationLayout>
  );
}
export default Orders;
