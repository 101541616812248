import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
    '& tr': {
      backgroundColor: '#f8f8f8',
    },
  },
  header: {
    '& th': {
      fontWeight: '600 !important',
      fontSize: '14px !important',
    },
  },
  tableCell: {
    '& td, & div': {
      fontSize: '12px !important',
      fontWeight: '400 !important',
    },
  },
  stepsContainer: {
    display: 'flex',
    width: '80px',
    alignItems: 'center',
    columnGap: '5px',
    '& input': {
      textAlign: 'center',
    },
  },
  disabledColor: {
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: 'rgba(0, 0, 0, 0.5)',
    },
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '5px',
    '& svg': {
      cursor: 'pointer',
    },
  },
  tootTip: {
    fontSize: '12px'
  }
}));
