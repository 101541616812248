import React, { useCallback, useState, useEffect } from "react";
import {
  Modal,
  Thumbnail,
  Caption,
  DropZone,
  Stack,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  Card,
  PageActions,
  Toast,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";

import {useLocation, useParams} from "react-router-dom";
import UserHeader from "../../../components/UserLayout/user-header";

function EditZipFile() {
  let { id } = useParams();
  let imageType = "";

  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [imageZipFile, setImageZipFile] = useState("");
  const [imageKeyZipFile, setImageKeyZipFile] = useState("");
  const [imagePreviewZipFile, setImagePreviewZipFile] = useState("");

  const [filesZipFile, setFilesZipFile] = useState([]);
  const handleDropZoneZipFile = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesZipFile((filesZipFile) => [...filesZipFile, ...acceptedFiles]),
    []
  );
  const fileUploadZipFile = !filesZipFile.length && <DropZone.FileUpload />;
  let uploadedFilesZipFile = filesZipFile.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesZipFile[filesZipFile.length - 1].name}
        source={
          validImageTypes.indexOf(filesZipFile[filesZipFile.length - 1].type) >
          0
            ? window.URL.createObjectURL(filesZipFile[filesZipFile.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesZipFile[filesZipFile.length - 1].name}{" "}
        <Caption>{filesZipFile[filesZipFile.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImageZipFile = useCallback(
    (filesZipFile) => {
      setPopupActive(true);
      setImagePreviewZipFile(
        validImageTypes.indexOf(filesZipFile[filesZipFile.length - 1].type) > 0
          ? window.URL.createObjectURL(filesZipFile[filesZipFile.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesZipFile[filesZipFile.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeyZipFile(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesZipFile[filesZipFile.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesZipFile]
  );
  const [isUploadingAtive, setIsUploadingAtive] = useState(false);
  const [popupActive, setPopupActive] = useState(false);
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content="Image Deleted" onDismiss={toggleActive} />
  ) : null;

  useEffect(() => {
    axios
      .get("admin/v1/users/" + id + "/impressions&photos", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        result.data.data.step3 &&
          result.data.data.step3.zip_url &&
          setImagePreviewZipFile(result.data.data.step3.zip_url);
      })
      .catch((err) => console.log(err));
  }, []);
  const location = useLocation();
  let linkState =
    {planType: "", clinicStatus: "", clinicName: "", status: "", userId: id, pageTitle: ""};
  if (location.state !== undefined) {
    const {planType, clinicStatus, clinicName, status, userId, pageTitle} = location.state;
    linkState = {planType, clinicStatus, clinicName, status, userId, pageTitle}
  }
  const { planType, clinicStatus, clinicName, status, userId, pageTitle} = linkState;
  return (
    <Page>
        <UserHeader pageTitle={pageTitle} status={status} userId={userId} clinicStatus={clinicStatus} clinicName={clinicName} planType={planType}/>
        <br/>
        <h1 className="page-header">Edit 3d Scan File</h1>
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Zip File</TextStyle>
          <Thumbnail size="large" source={imagePreviewZipFile} />
          <DropZone
            onDrop={handleDropZoneZipFile}
            onDropAccepted={sendImageZipFile}
          >
            {uploadedFilesZipFile}
            {fileUploadZipFile}
          </DropZone>
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
        secondaryActions={[
          {
            content: "Delete",
            onClick: () =>
              axios
                .delete(
                  "admin/v1/users/" + id + "/impressions&photos/step2/delete",
                  {
                    headers: {
                      Authorization: "Bearer " + Cookie.get("token"),
                    },
                  }
                )
                .then((result) => {
                  toggleActive();
                  setImagePreviewZipFile("");
                })
                .catch((err) => console.log(err)),
          },
        ]}
      />
      {toastMarkup}
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );

  function handleSave() {
    const bodyObj = {
      zip: imageKeyZipFile,
    };
    axios
      .post("admin/v1/users/" + id + "/impressions&photos/step3", bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        history.push("/admin/users/" + id + "/dashboard");
      })
      .catch((err) => console.log(err));
  }
}
export default EditZipFile;
