import React, { useState, useEffect, useCallback } from "react";
import {
  InlineError,
  Checkbox,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Select,
  Card,
  PageActions,
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";
import history from "../history";
import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.
import Select2 from "react-dropdown-select";

function Add() {
  const [valueCode, setValueCode] = useState("");
  const [valueCount, setValueCount] = useState("");
  const [valueType, setValueType] = useState("Percentage Discount");
  const [valueAmount, setValueAmount] = useState("");
  const [valueProduct, setValueProduct] = useState("1_product");
  const [valueStartDate, setValueStartDate] = useState("");
  const [valueEndDate, setValueEndDate] = useState("");
  const [checked, setChecked] = useState(false);
  const [isMemberShip, setIsMemberShip] = useState(false);
  const [options, setOptions] = useState([]);
  const [clinicOptions, setClinicsOptions] = useState([]);
  const [clinics, setClinics] = useState([]);
  const [earlyBird, setEarlyBird]=useState('');
  const [earlyBirdClinics, setEarlyBirdClinics]=useState([]);
  const [corporate, setCorporate]=useState(false);


  const earlyBirdCouponOptions = [
    { label: "First 5 days", value: 1 },
    { label: "Between 5 and 10 days", value: 2 },
  ];
  function handleSelectProduct(valueProduct) {
    setValueProduct(valueProduct);
  }
  const [fieldRequiredCode, setFieldRequiredCode] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredCount, setFieldRequiredCount] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredAmount, setFieldRequiredAmount] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredSD, setFieldRequiredSD] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredED, setFieldRequiredED] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredProduct, setFieldRequiredProduct] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const list = [];

  let listOptionsProducts = [];
  let listOptionsPlans = [];
  const [checkedDiscountOnAligner, setCheckedDiscountOnAligner] =
    useState(false);
  const handleChangeDiscountOnAlignerChecked = useCallback(
    (newChecked) => setCheckedDiscountOnAligner(newChecked),
    []
  );
  const [discountOnAlignerValue, setDiscountOnAlignerValue] = useState("");
  const handleChangeDiscountOnAligner = useCallback(
    (newValue) => setDiscountOnAlignerValue(newValue),
    []
  );
  function handleSelectedEarlyBird(value) {
    setEarlyBird(parseInt(value));
  }
  const handleMemberShipCheckboxChange = (checked) => {
    setIsMemberShip(checked);
    if(checked == false){
      setClinics([]);
    }
  }
  useEffect(() => {
    getData();
  }, []);

  function getData() {
    axios
      .get("admin/v1/products", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        // listOptionsProducts.push();
        for (var i = 0; i < result.data.data.data.length; i++) {
          listOptionsProducts.push({
            key: i + result.data.data.data[i].title.en,
            label: result.data.data.data[i].title.en,
            value: String(result.data.data.data[i].id) + "_product",
          });
        }
        listOptionsProducts.push({
          label: "All Products",
          value: 'allProducts',
        });
        axios
          .get("/admin/v1/plans", {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          })
          .then((result) => {
            for (var i = 0; i < result.data.data.length; i++) {
              listOptionsPlans.push({
                key: i + result.data.data[i].title.en,
                label: result.data.data[i].title.en,
                value: String(result.data.data[i].id) + "_plan",
              });
            }

            for (var i = 0; i < listOptionsPlans.length; i++) {
              listOptionsProducts.push(listOptionsPlans[i]);
            }

            listOptionsProducts.push({
              label: "All Aligners",
              value: "Aligner",
            });
            setOptions(listOptionsProducts);
          })
          .catch((err) => console.log(err));
        //  setOptions(listOptionsProducts);
      })

      .catch((err) => console.log(err));

      axios
      .get("/admin/v1/clinics", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }).then((res) => {
          let clinicsArray = res.data.data.map((clinic) => ({value: clinic.id, label: clinic.name['en']}));
          setClinicsOptions(clinicsArray);
      }).catch((err) => console.log(err));
  }
  return (
    <Page
      title="Add Coupon"
      breadcrumbs={[{ content: "List Of Coupons", url: "/admin/coupons" }]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Code (Without Spaces)</TextStyle>
          <TextField value={valueCode} onChange={handleChangeCode} />
          {fieldRequiredCode}
          <TextStyle variation="strong">
            Count (Maximum Number Of Usage)
          </TextStyle>
          <TextField
            value={valueCount}
            onChange={handleChangeCount}
            type="number"
          />
          {fieldRequiredCount}
          <TextStyle variation="strong">Type</TextStyle>
          <TextField value={valueType} />
          <TextStyle variation="strong">Amount</TextStyle>
          <TextField
            value={valueAmount}
            onChange={handleChangeAmount}
            type="number"
          />
          {fieldRequiredAmount}
          <TextStyle variation="strong">Start Date</TextStyle>
          <TextField
            value={valueStartDate}
            onChange={handleChangeStartDate}
            type="date"
          />
          {fieldRequiredSD}
          <TextStyle variation="strong">End Date</TextStyle>
          <TextField
            value={valueEndDate}
            onChange={handleChangeEndDate}
            type="date"
          />
          {setFieldRequiredED}
          <TextStyle variation="strong">Product</TextStyle>
          <Select
            options={options}
            onChange={handleSelectProduct}
            value={valueProduct}
          />
        <TextStyle variation="strong">Early Bird</TextStyle>
          <Select
            options={earlyBirdCouponOptions}
            onChange={handleSelectedEarlyBird}
            value={earlyBird}
            placeholder="Please Choose"
          />
          <div>
            <TextStyle variation="strong">Early Bird Clinics</TextStyle>
            <Select2
              placeholder="Select Clinic"
              multi
              options={clinicOptions}
              onChange={setEarlyBirdClinics}
              values={earlyBirdClinics}
            />
          </div>
          <Checkbox
            label="Discount on aligner"
            checked={checkedDiscountOnAligner}
            onChange={handleChangeDiscountOnAlignerChecked}
          />

          {checkedDiscountOnAligner && (
            <TextField
              label="Discount Value"
              type="number"
              value={discountOnAlignerValue}
              onChange={handleChangeDiscountOnAligner}
            />
          )}
          <Checkbox
            label="Is Active"
            checked={checked}
            onChange={handleChangeCb}
          />
          <Checkbox
            label="Corporate Partnership"
            checked={corporate}
            onChange={setCorporate}
          />
          <Checkbox
            label="Clinic Membership Program"
            checked={isMemberShip}
            onChange={handleMemberShipCheckboxChange}
          />
          <TextStyle variation="strong">Membership Clinics</TextStyle>
          <Select2
            placeholder="Search clinics"
            multi
            isDisabled={isMemberShip == false}
            options={clinicOptions}
            values={clinics}
            onChange={setClinics}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
    </Page>
  );
  function handleChangeCode(valueCode) {
    setValueCode(valueCode);
    setFieldRequiredCode(<InlineError message="" fieldID="myFieldID" />);
  }
  function handleChangeCount(valueCount) {
    setValueCount(valueCount);
  }
  function handleChangeStartDate(valueStartDate) {
    setValueStartDate(valueStartDate);
  }
  function handleChangeEndDate(valueEndDate) {
    setValueEndDate(valueEndDate);
  }
  function handleChangeAmount(valueAmount) {
    setValueAmount(valueAmount);
  }
  function handleChangeProduct(valueProduct) {
    setValueProduct(valueProduct);
  }
  function handleChangeCb(checked) {
    setChecked(checked);
  }
  function handleSave() {
    if (
      valueCode === "" ||
      valueCount === "" ||
      valueAmount === "" ||
      valueStartDate === "" ||
      valueEndDate === ""
    ) {
      if (valueCode === "")
        setFieldRequiredCode(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueCount === "")
        setFieldRequiredCount(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueAmount === "") {
        setFieldRequiredAmount(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      }
      if (valueStartDate === "")
        setFieldRequiredSD(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueEndDate === "")
        setFieldRequiredED(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueProduct === "")
        setFieldRequiredProduct(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
    } else {
      let bodyObj = {};
      let couponClinicData = {};
      if (valueProduct === "Aligner") {
        bodyObj = {
          code: valueCode,
          amount: valueAmount,
          count: valueCount,
          product_id: null,
          plan_id: null,
          type: "Aligner",
          start_date: valueStartDate,
          end_date: valueEndDate,
          active: checked,
          early_bird: earlyBird,
          corporate:corporate,
          discount_on_aligner: checkedDiscountOnAligner ? 1 : 0,
          ...(discountOnAlignerValue && {
            discount_value: discountOnAlignerValue,
          }),
        };
      } else if(valueProduct === "allProducts" ) {
        bodyObj = {
          code: valueCode,
          amount: valueAmount,
          count: valueCount,
          product_id: null,
          plan_id: null,
          type: null,
          start_date: valueStartDate,
          end_date: valueEndDate,
          active: checked,
          corporate:corporate,
          discount_on_aligner: checkedDiscountOnAligner ? 1 : 0,
          ...(discountOnAlignerValue && {
            discount_value: discountOnAlignerValue,
          }),
        }
      } else {
        bodyObj = {
          code: valueCode,
          amount: valueAmount,
          count: valueCount,
          product_id: valueProduct.split("_")[0],
          plan_id: valueProduct.split("_")[0],
          type:null,
          start_date: valueStartDate,
          end_date: valueEndDate,
          active: checked,
          corporate:corporate,
          discount_on_aligner: checkedDiscountOnAligner ? 1 : 0,
          ...(discountOnAlignerValue && {
            discount_value: discountOnAlignerValue,
          }),
        };
      }

      if (valueProduct.split("_")[1] === "product") {
        bodyObj.plan_id=null;
      }
      if (valueProduct.split("_")[1] === "plan") {
        bodyObj.product_id=null;
        bodyObj.early_bird= earlyBird;
      }
      axios
        .post("/admin/v1/coupons", bodyObj, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          let earlyBirdData = {};
          couponClinicData = {
            coupon_id: res.data.data.id,
            clinics: clinics,
          }
          earlyBirdData = {
            coupon_id: res.data.data.id,
            earlyBird: earlyBirdClinics,
          }
          if(isMemberShip && clinics.length > 0){
            axios.post("/admin/v1/coupon-clinics", couponClinicData, {
              headers: {
                Authorization: "Bearer " + Cookie.get("token"),
              },
            }).catch((err) => console.log(err));
          }
          axios.post("/admin/v1/early-bird", earlyBirdData, {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          });
          history.push("/admin/coupons");
        })
        .catch((err) =>
          setFieldRequiredCode(
            <InlineError
              message="The code has already been taken."
              fieldID="myFieldID"
            />
          )
        );
    }
  }
}
export default Add;
