import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    parent: {
        display: "flex",
        height: "100%"
    },
    chatMenu: {
        width: "30%",
    },
    menuHeader: {
        height: "70px",
        background: 'url(https://assets1.freshconnect.io/images/CM_Header_BG_Graphic-6d296de4d5d7116b4be884a2e5331d14.svg) 240px 0 no-repeat',
        display: "flex",
        alignItems: "center",
        paddingLeft: "5%",
        boxShadow:"0 2px 4px 0 rgba(0,30,60,.08)"
    },
    menuHeaderTitle: {
        fontWeight: 500,
        textTransform: "capitalize",
        fontSize: "18px"
    },
    menuItemsParent: {
        // overflowY: "scroll",
        height: "calc(100vh - 140px)",
        position: "relative",
    },
    chatItemsParent:{
        height: "calc(100vh - 140px)",
        overflowY: "scroll",
    },
    menuItem: {
        padding: "0% 5%",
        height: "15%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
    },
    menuItemHeader: {
        display: "flex",
        justifyContent: "space-between"
    },
    chatName: {
        textOverflow: "ellipsis",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        maxWidth: "70%",
        overflow: "hidden"
    },
    chatLastMessageTime: {
        fontSize: "small"
    },
    menuItemBody: {
        display: "flex",
        justifyContent: "space-between"
    },
    chatLastMessage: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "90%",
        overflow: "hidden"
    },
    chatUnreadMessages: {
        fontSize: "small",
        width: "1.5em",
        height: "1.5em",
        borderRadius: "50%",
        backgroundColor: "#FF2C46",
        textAlign: "center",
        lineHeight: "1.5em",
        color: "white"
    },
    loadMoreChat: {
        margin: "auto",
        display: "block",
        cursor: "pointer",
        width: "fit-content",
        background: "none",
        border: "none",
        cursor: "pointer"
    },
    expandedChat: {
        width: "70%",
        height: "100%"
    },
    expandedChatMenu: {
        height: "70px",
        display: "flex",
        alignItems: "center",
        borderLeft: "1px solid #cbd3d3",
        padding: "0 5%",
        boxShadow:"0 2px 4px 0 rgba(0,30,60,.08)",
        justifyContent:"space-between",
    },
    chatTitle: {
        fontWeight: 500,
        textTransform: "capitalize",
        fontSize: "18px"
    },
    chatBody: {
        height: "calc(100vh - 140px)",
        borderLeft: "1px solid #cbd3d3",
    },
    emptyChatBody:{
        height: "calc(100vh - 140px)",
        borderLeft: "1px solid #cbd3d3",
        background: "url('/BasmaChatBackground.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionY: "bottom",
    },
    chatButtons:{
        display:"flex",
        gap:"5px"
    },
    cmsButton:{
        borderRadius: '5px', 
        backgroundColor: '#FF2C46', 
        color:'#fff',
        fontSize: "1.1em",
        padding: "3px",
        cursor:"pointer",
        '&:hover':{
          backgroundColor: '#FF2C46'
        }
    },
    loadingBox: {
        position: "absolute",
        zIndex: "10",
        top: "0",
        left: "0",
        right: "0",
        "&::before": {
          content: '""',
          position: "absolute",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          backgroundColor: "#a19a9a",
          opacity: "0.2",
        }
    },
    loading: {
    position: "absolute",
    top: "50%",
    bottom: "50%",
    right: "50%",
    left: "50%",
    color: '#ff2c46',
    },
}));

