import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  inputSize: {
    minWidth: '300px !important',
    '& input': {
      width: '100%',
    },
  },
}));
