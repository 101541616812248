import { Card, Modal, Page } from '@shopify/polaris';
import { toast, ToastContainer } from 'react-toastify';
import React, { useState } from 'react';
import axios from '../../axios';
import Cookies from 'js-cookie';
import { Box, Tab, Tabs } from '@mui/material';
import CustomTabPanel from '../ClinicReports/custom-tab-panel';
import { useStyles } from '../ClinicReports/clinic-reports-invoices-table.styles';
import ProductRequestsReportsTable from './product-requests-reports-table.component';
import { useHistory } from 'react-router-dom';
import ApproveProductRequestDialog from './approve-product-request-dialog.component';
import ProductRequestReasonDialog from './product-request-reason-dialog.component';

const ProductRequestsReport = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [checkDispatchLoading, setCheckDispatchLoading] = useState(false);
  const [dispatch, setDispatch] = useState(null);
  const [approvedProductRequestId, setApprovedProductRequestId] =
    useState(null);
  const [open, setOpen] = useState(false);
  const [openReasonDialog, setOpenReasonDialog] = useState(false);
  const [reasonSelected, setReasonSelected] = useState(false);
  const [productRequests, setProductRequests] = useState({
    requested: [],
    approved: [],
  });
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openUserProfile = (id) => {
    window.open(`/admin/users/${id}/dashboard`, '_blank', 'noreferrer');
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleCreateProductRequest = () => {
    history.push(`/admin/reports/product-requests/create`);
  };

  const getProductRequests = async (type = 'requested') => {
    try {
      setLoading(true);
      const response = await axios.get(
        `/admin/v1/product-requests?page=${page}&type=${type}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        }
      );
      if (response.status === 200) {
        const productRequestData = response?.data?.data?.data;
        setTotal(response?.data?.data?.total);
        setPerPage(response?.data?.data.per_page);
        if (productRequestData) {
          setProductRequests({
            ...productRequests,
            [type]: productRequestData,
          });
        }
      }
    } catch (error) {
      console.log('Error in product requests api', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckDispatches = async (userId, productRequestId) => {
    try {
      setApprovedProductRequestId(productRequestId);
      setCheckDispatchLoading(true);
      const response = await axios.get(
        `/admin/v1/product-requests/${userId}/dispatches`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.success === true) {
          const { dispatch: dispatchData } = response.data;
          setOpen(true);
          setDispatch(dispatchData);
        } else {
          const { message } = response.data;
          toast.error(message, 3000);
        }
      }
    } catch (error) {
      console.log('Error in product request dispatch api', error);
    } finally {
      setCheckDispatchLoading(false);
    }
  };

  const handOpenReasonDialog = (reason) => {
    setReasonSelected(reason);
    setOpenReasonDialog(true);
  };

  const handleApprove = async (dispatchId = null, newDelivery) => {
    try {
      setOpen(false);
      setCheckDispatchLoading(true);
      const response = await axios.post(
        `/admin/v1/product-requests/${approvedProductRequestId}/approve`,
        { dispatchId: dispatchId, newDelivery },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.success === true) {
          const { message } = response.data;
          toast.success(message, 3000);
          await getProductRequests();
        } else {
          const { message } = response.data;
          toast.error(message, 3000);
        }
      }
    } catch (error) {
      console.log('Error in product request approve api', error);
    } finally {
      setCheckDispatchLoading(false);
    }
  };

  return (
    <Page
      fullWidth
      title="Product Requests List"
      breadcrumbs={[
        {
          content: 'List Of Reports',
          url: '/admin/reports',
        },
      ]}
    >
      <ToastContainer />
      <Card
        sectioned
        actions={[
          {
            content: 'Create a Product Request',
            onAction: handleCreateProductRequest,
          },
        ]}
      >
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '16px' }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="inherit"
            TabIndicatorProps={{
              style: {
                backgroundColor: 'rgba(0, 128, 96, 1)',
              },
            }}
            aria-label="basic tabs example"
          >
            <Tab
              className={classes.tabs}
              label="Requested Product"
              {...a11yProps(0)}
            />
            <Tab
              className={classes.tabs}
              label="Approved Products"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <ProductRequestsReportsTable
            productRequests={productRequests}
            loading={loading}
            getProductRequests={getProductRequests}
            type="requested"
            handleCheckDispatches={handleCheckDispatches}
            page={page}
            setPage={setPage}
            total={total}
            perPage={perPage}
            handOpenReasonDialog={handOpenReasonDialog}
            openUserProfile={openUserProfile}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ProductRequestsReportsTable
            productRequests={productRequests}
            loading={loading}
            getProductRequests={getProductRequests}
            type="approved"
            page={page}
            setPage={setPage}
            total={total}
            perPage={perPage}
            handOpenReasonDialog={handOpenReasonDialog}
            openUserProfile={openUserProfile}
          />
        </CustomTabPanel>
        <ApproveProductRequestDialog
          open={open}
          setOpen={setOpen}
          dispatch={dispatch}
          handleApprove={handleApprove}
        />
        <ProductRequestReasonDialog
          open={openReasonDialog}
          setOpen={setOpenReasonDialog}
          reason={reasonSelected}
        />
        <Modal open={checkDispatchLoading} loading={true}></Modal>
      </Card>
    </Page>
  );
};

export default ProductRequestsReport;
