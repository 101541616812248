export function convertToCurrency(amount, currency) {
    if(!amount) {
        return '';
    }
    if(currency == 'USD') {
        return amount;
    }
    const conversionRates = {
        AED: 3.67,
        SAR: 3.75,
        QAR: 3.64,
        KWD: 0.30,
        OMR: 0.38,
        BHD: 0.38,
    };

    if (conversionRates.hasOwnProperty(currency)) {
        return amount * conversionRates[currency];
    } else {
        throw new Error(`Unsupported currency: ${currency}`);
    }
}