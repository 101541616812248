import {  FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import axios from "../../../axios";
import Cookies from 'js-cookie';
import SelectParentChildCheckbox from './SelectParentChildCheckbox';
import { Button } from '@shopify/polaris';
import "./TicketingFilter.css"
function TicketingFilter({setTickets, page, setLast}) {
    const [filter, setFilter] = useState({
        created: { from: "", to: "" }, resolved: { from: "", to: "" }, closed: { from: "", to: "" },
        status: [], urgency: [], source: [], type: [], search :""
    })
    const [teams, setTeams] = useState([]);
    const [assignedBy, setAssignedBy] = useState({});
    const [assignedTo, setAssignedTo] = useState({});

    const handleChangeFilter = (source, e) => {
        let val = e.target.value;
        setFilter((prev) => ({ ...prev, [source]: val }))
    }
    const handleChangeDate = (field, type, e) => {
        let val = e.target.value;
        setFilter((prev) => ({ ...prev, [field]: { ...prev[field], [type]: val } }))
    }
    useEffect(() => {
        getTickets()
    }, [page])
    const getTickets = async () => {
        let {created, resolved, closed, source, status, type, urgency, search} = filter;
        let res = await axios
            .post(
                `admin/v1/tickets?page=${page}`,{assigned_by:Object.keys(assignedBy),assigned_to:Object.keys(assignedTo),
                status, source, type, urgency, created, resolved, closed, search},
                {
                    headers: {
                        Authorization: "Bearer " + Cookies.get("token"),
                    },
                }
            )
        setTickets(res.data.tickets.data)
        setLast(res.data.tickets.last_page)
        let arr = [];
        res.data.teams.map(team=>{
            let children = team.members.map(member=>({label:member.user.full_name, value:member.user_id}))
            arr.push({label:team.team_name, name:team.team_name.replace(/\s/g, '-')+"-"+team.id,children})
        })
        setTeams(arr)
    }
    let statuses = [
        <MenuItem key={0} style={{ fontSize: '1.4rem' }} value={'open'}>Open</MenuItem>,
        <MenuItem key={1} style={{ fontSize: '1.4rem' }} value={'ongoing'}>Ongoing</MenuItem>,
        // <MenuItem key={2} style={{ fontSize: '1.4rem' }} value={'progress'}>In Progress</MenuItem>,
        // <MenuItem key={3} style={{ fontSize: '1.4rem' }} value={'pending'}>Awaiting Feedback</MenuItem>,
        <MenuItem key={4} style={{ fontSize: '1.4rem' }} value={'resolved'}>Resolved</MenuItem>,
        <MenuItem key={5} style={{ fontSize: '1.4rem' }} value={'closed'}>Closed</MenuItem>,
    ];
    let priorities = [
        <MenuItem key={0} style={{ fontSize: '1.4rem' }} value={'urgent'}>Urgent</MenuItem>,
        <MenuItem key={1} style={{ fontSize: '1.4rem' }} value={'high'}>High</MenuItem>,
        <MenuItem key={2} style={{ fontSize: '1.4rem' }} value={'medium'}>Medium</MenuItem>,
        <MenuItem key={3} style={{ fontSize: '1.4rem' }} value={'low'}>Low</MenuItem>,
    ];
    let sources = [
        <MenuItem key={0} style={{ fontSize: '1.4rem' }} value={'app'}>App</MenuItem>,
        <MenuItem key={1} style={{ fontSize: '1.4rem' }} value={'whatsApp'}>WhatsApp</MenuItem>,
        <MenuItem key={2} style={{ fontSize: '1.4rem' }} value={'chat'}>Chat</MenuItem>,
        <MenuItem key={3} style={{ fontSize: '1.4rem' }} value={'call'}>Call</MenuItem>,
        <MenuItem key={3} style={{ fontSize: '1.4rem' }} value={'social media'}>Social Media</MenuItem>,
    ];
    let types = [
        <MenuItem key={0} style={{ fontSize: '1.4rem' }} value={'broken-ak'}>Broken Aligner</MenuItem>,
        <MenuItem key={1} style={{ fontSize: '1.4rem' }} value={'lost-ak'}>Lost Aligner</MenuItem>,
        <MenuItem key={2} style={{ fontSize: '1.4rem' }} value={'broken-attachment'}>Fallen/Broken Attachment</MenuItem>,
        <MenuItem key={3} style={{ fontSize: '1.4rem' }} value={'fallen-buttons'}>Fallen Buttons</MenuItem>,
        <MenuItem key={4} style={{ fontSize: '1.4rem' }} value={'clinic-issue'}>Reporting Clinic Issue</MenuItem>,
        <MenuItem key={5} style={{ fontSize: '1.4rem' }} value={'app-inquiry'}>How to use app</MenuItem>,
        <MenuItem key={6} style={{ fontSize: '1.4rem' }} value={'broken-retainer'}>Broken Retainer</MenuItem>,
        <MenuItem key={7} style={{ fontSize: '1.4rem' }} value={'lost-retainer'}>Lost Retainer</MenuItem>,
        <MenuItem key={8} style={{ fontSize: '1.4rem' }} value={'received-deformed-ak'}>Received Deformed Aligners</MenuItem>,
        <MenuItem key={9} style={{ fontSize: '1.4rem' }} value={'ak-not-fitting'}>Aligners not Fitting</MenuItem>,
        <MenuItem key={10} style={{ fontSize: '1.4rem' }} value={'received-wrong-ak'}>Received Wrong Aligners</MenuItem>,
        <MenuItem key={11} style={{ fontSize: '1.4rem' }} value={'progress-pictures-tracking'}>Progress pictures tracking</MenuItem>,
        <MenuItem key={12} style={{ fontSize: '1.4rem' }} value={'tp-plan-adjustment'}>Treatment Plan adjustment</MenuItem>,
        <MenuItem key={13} style={{ fontSize: '1.4rem' }} value={'relapse'}>Relapse</MenuItem>,
        <MenuItem key={14} style={{ fontSize: '1.4rem' }} value={'not-satisfied-end'}>Not satisfied with end result</MenuItem>,
        <MenuItem key={15} style={{ fontSize: '1.4rem' }} value={'discontinued'}>Discontinued</MenuItem>,
        <MenuItem key={16} style={{ fontSize: '1.4rem' }} value={'special-request'}>Special request (attachments, pausing...)</MenuItem>,
        <MenuItem key={17} style={{ fontSize: '1.4rem' }} value={'delay-prod'}>Delay production</MenuItem>,
        <MenuItem key={18} style={{ fontSize: '1.4rem' }} value={'delay-delivery'}>Delay delivery</MenuItem>,
        <MenuItem key={19} style={{ fontSize: '1.4rem' }} value={'unclear-address'}>Unclear address</MenuItem>,
        <MenuItem key={20} style={{ fontSize: '1.4rem' }} value={'missing-ak'}>Request different photos (missing aligners)</MenuItem>,
        <MenuItem key={21} style={{ fontSize: '1.4rem' }} value={'excess-around-attachments'}>Excess around attachments</MenuItem>,
    ];
    return (
        <div className='ticketing-filter-parent'>
            <div style={{height: "90%",overflowY: "auto"}}>
            <div className='filter-content'>
                <div className='filter-title'><b>Filters</b></div>
                <div className='filter-body' style={{ }}>
                    <TextField placeholder='Search User' size='small' 
                    inputProps={{style: {fontSize: 16}}} 
                    value={filter.search} onChange={(e) => { handleChangeFilter('search', e) }}/>
                    <SelectParentChildCheckbox title={'Assigned By'} checkboxes={assignedBy} setCheckboxes={setAssignedBy} teams={teams}/>
                    <SelectParentChildCheckbox title={'Assigned To'} checkboxes={assignedTo} setCheckboxes={setAssignedTo}  teams={teams}/>
                    <div>
                        <label htmlFor="created-date"><b>Created at:</b></label>
                        <div id='created-date' style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                            <div style={{ display: "grid" }}>
                                <label htmlFor="start-date">From:</label>
                                <input
                                    id="start-date"
                                    style={{ padding: "4px", borderRadius: "5px" }}
                                    type="date"
                                    value={filter.created.from}
                                    onChange={(e) => { handleChangeDate('created', 'from', e) }}
                                />
                            </div>
                            <div style={{ display: "grid" }}>
                                <label htmlFor="end-date">To:</label>
                                <input
                                    id="end-date"
                                    style={{ padding: "4px", borderRadius: "5px" }}
                                    type="date"
                                    value={filter.created.to}
                                    onChange={(e) => { handleChangeDate('created', 'to', e) }}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="created-date"><b>Resolved at:</b></label>
                        <div id='created-date' style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                            <div style={{ display: "grid" }}>
                                <label htmlFor="start-date">From:</label>
                                <input
                                    id="start-date"
                                    style={{ padding: "4px", borderRadius: "5px" }}
                                    type="date"
                                    value={filter.resolved.from}
                                    onChange={(e) => { handleChangeDate('resolved', 'from', e) }}
                                />
                            </div>
                            <div style={{ display: "grid" }}>
                                <label htmlFor="end-date">To:</label>
                                <input
                                    id="end-date"
                                    style={{ padding: "4px", borderRadius: "5px" }}
                                    type="date"
                                    value={filter.resolved.to}
                                    onChange={(e) => { handleChangeDate('resolved', 'to', e) }}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="created-date"><b>Closed at:</b></label>
                        <div id='created-date' style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                            <div style={{ display: "grid" }}>
                                <label htmlFor="start-date">From:</label>
                                <input
                                    id="start-date"
                                    style={{ padding: "4px", borderRadius: "5px" }}
                                    type="date"
                                    value={filter.closed.from}
                                    onChange={(e) => { handleChangeDate('closed', 'from', e) }}
                                />
                            </div>
                            <div style={{ display: "grid" }}>
                                <label htmlFor="end-date">To:</label>
                                <input
                                    id="end-date"
                                    style={{ padding: "4px", borderRadius: "5px" }}
                                    type="date"
                                    value={filter.closed.to}
                                    onChange={(e) => { handleChangeDate('closed', 'to', e) }}
                                />
                            </div>
                        </div>
                    </div>

                    <FormControl fullWidth >
                        <label htmlFor='filter-status-select' >Status</label>
                        <Select style={{ fontSize: '1.4rem', width: "100%", marginTop: "0px" }} id='filter-status-select' onChange={(e) => { handleChangeFilter('status', e) }} value={filter.status} multiple >
                            {statuses}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth >
                        <label htmlFor='filter-urgency-select' >Urgency</label>
                        <Select style={{ fontSize: '1.4rem', width: "100%", marginTop: "0px" }} id="filter-urgency-select" onChange={(e) => { handleChangeFilter('urgency', e) }} value={filter.urgency} multiple>
                            {priorities}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth >
                        <label htmlFor='filter-source-select' >Source</label>
                        <Select style={{ fontSize: '1.4rem', width: "100%", marginTop: "0px" }} id="filter-source-select" onChange={(e) => { handleChangeFilter('source', e) }} value={filter.source} multiple>
                            {sources}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth >
                        <label htmlFor='filter-type-select' >Types</label>
                        <Select style={{ fontSize: '1.4rem', width: "100%", marginTop: "0px" }} id="filter-type-select" onChange={(e) => { handleChangeFilter('type', e) }} value={filter.type} multiple>
                            {types}
                        </Select>
                    </FormControl>

                </div>
            </div>
            </div>
            <div style={{width:'80%',margin:"5px auto 0px auto"}}>
                  <Button primary fullWidth onClick={getTickets}>Apply</Button>
                </div>
        </div>
    )
}

export default TicketingFilter