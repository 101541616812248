import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  Avatar,
  Button,
  ResourceItem,
  ResourceList,
  Thumbnail,
  Caption,
  InlineError,
  DropZone,
  Checkbox,
  Stack,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  ActionList,
  Popover,
  Select,
  Card,
  Tabs,
  PageActions,
} from "@shopify/polaris";
import axios from "./axios";
import Cookie from "js-cookie";
import history from "./history";
import Helmet from "react-helmet";
import { Editor } from "@tinymce/tinymce-react";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.
import "./assets/styles/Dashboard.css";

import LoadingOverlay from "react-loading-overlay";
import { Loading } from "@shopify/app-bridge/actions";

//import { Select } from '@shopify/app-bridge/actions/ResourcePicker';

function EditCurrency() {
  const [valueTitleEn, setValueTitleEn] = useState("");
  const [valueName, setValueName] = useState("");
  const [valueDescriptionEn, setValueDescriptionEn] = useState("");
  const [valuePaymentInterval, setValuePaymentInterval] = useState("");
  const [checked, setChecked] = useState(false);
  const [valueTitleAr, setValueTitleAr] = useState("");
  const [valueRate, setValueRate] = useState("");
  const [valueDescriptionAr, setValueDescriptionAr] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState("1");
  let listUsers = [];
  const [valueCurrency, setValueCurrency] = useState("");

  const [fieldRequiredTitleEn, setFieldRequiredTitleEn] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredName, setFieldRequiredName] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [
    fieldRequiredPaymentInterval,
    setFieldRequiredPaymentInterval,
  ] = useState(<InlineError message="" fieldID="myFieldID" />);
  const [fieldRequiredDescriptionEn, setFieldRequiredDescriptionEn] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredTitleAr, setFieldRequiredTitleAr] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredRate, setFieldRequiredRate] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredDescriptionAr, setFieldRequiredDescriptionAr] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );

  const [settingsResult, setSettingsResult] = useState({});

  useEffect(() => {
    axios
      .get("/admin/v1/settings", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setValueCurrency(Cookie.get("clickedCurrencyID"));
        setValueRate(
          result.data.data.currency_converter[Cookie.get("clickedCurrencyID")]
            .rate
        );
        setValueTitleEn(
          result.data.data.currency_converter[Cookie.get("clickedCurrencyID")]
            .en
        );
        setValueTitleAr(
          result.data.data.currency_converter[Cookie.get("clickedCurrencyID")]
            .ar
        );
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page
      title="Edit Currency"
      breadcrumbs={[{ content: "Settings", url: "/admin/settings" }]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Currency Code</TextStyle>
          <TextField
            value={valueCurrency}
            onChange={handleChangeCurrency}
            DescriptionEn="number"
            readOnly
          />
          <TextStyle variation="strong">Rate</TextStyle>
          <TextField
            value={valueRate}
            onChange={handleChangeRate}
            DescriptionEn="number"
          />
          <TextStyle variation="strong">Code En</TextStyle>
          <TextField value={valueTitleEn} onChange={handleChangeTitleEn} />
          <TextStyle variation="strong">Code Ar</TextStyle>
          <TextField value={valueTitleAr} onChange={handleChangeTitleAr} />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
        secondaryActions={[
          {
            content: "Delete",
            onClick: () =>
              axios
                .get("/admin/v1/settings", {
                  headers: {
                    Authorization: "Bearer " + Cookie.get("token"),
                  },
                })
                .then((result) => {
                  let settings = {};
                  settings = result.data.data;
                  delete settings["currency_converter"][
                    Cookie.get("clickedCurrencyID")
                  ];
                  // delete settings["currency_converter"]["USD"];
                  // delete settings["currency_converter"]["CHF"];
                  // delete settings["currency_converter"]["EUR"];
                  // delete settings["currency_converter"]["AED"];
                  // delete settings["currency_converter"]["EGP"];

                  const body = settings;
                  axios
                    .post("/admin/v1/settings", body, {
                      headers: {
                        Authorization: "Bearer " + Cookie.get("token"),
                      },
                    })
                    .then((res) => {
                      history.push("/admin/settings");
                      //setToastContent("Saved");
                      //toggleActive();
                    })
                    .catch((err) => console.log(err));
                })
                .catch((err) => console.log(err)),
          },
        ]}
      />
    </Page>
  );
  function handleChangeCurrency(valueCurrency) {
    setValueCurrency(valueCurrency);
  }
  function handleSelectUser(selectedUser) {
    setSelectedUser(selectedUser);
  }
  function handleChangeTitleEn(valueTitleEn) {
    setValueTitleEn(valueTitleEn);
  }
  function handleChangeName(valueName) {
    setValueName(valueName);
  }

  function handleChangePaymentInterval(valuePaymentInterval) {
    setValuePaymentInterval(valuePaymentInterval);
  }
  function handleChangeDescriptionEn(valueDescriptionEn) {
    setValueDescriptionEn(valueDescriptionEn);
  }
  function handleChangeTitleAr(valueTitleAr) {
    setValueTitleAr(valueTitleAr);
  }
  function handleChangeRate(valueRate) {
    setValueRate(valueRate);
  }
  function handleChangeDescriptionAr(valueDescriptionAr) {
    setValueDescriptionAr(valueDescriptionAr);
  }

  function handleChangeCb(checked) {
    setChecked(checked);
  }
  function handleSave() {
    let settings = {};
    axios
      .get("/admin/v1/settings", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        settings = result.data.data;
        console.log("settings=", settings);
        settings["currency_converter"][valueCurrency] = {
          rate: valueRate,
          en: valueTitleEn,
          ar: valueTitleAr,
        };
        const body = settings;
        axios
          .post("/admin/v1/settings", body, {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          })
          .then((res) => {
            console.log("settings=", res);
            history.push("/admin/settings");
            //setToastContent("Saved");
            //toggleActive();
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }
}
export default EditCurrency;
