import React, { useEffect } from "react";
import NavigationLayout from "../components/NavigationLayout";
import './AddNewJob/job.css'
import {Page} from "@shopify/polaris";
import history from "../history";
import { Box, Button, Typography } from '@material-ui/core';

function Careers() {
  useEffect(()=>{
    localStorage.removeItem('referrer');
  },[])
    return (
        <NavigationLayout>

            <Page
                title="Careers"
            >
                <Box className='clinic-flex-styling'>
                <Box className='clinic-card-styling'>
                        <Box className='top-line'></Box>
                        <Typography className='clinic-typo-styling clinic-title'
                         style={{marginBottom:'1rem'}}>Add New Jobs</Typography>
                        <Typography className='clinic-typo-styling clinic-address clinic-margin-bottom' style={{marginBottom:'1rem'}}>
                            
                        </Typography>
                        <Button className='clinic-typo-styling' 
                        style={{width:'fit-content', backgroundColor: '#fff',border:'0.1rem solid #C7C7C7', boxShadow: '0px 0px 5px #8888'}} 
                        onClick={()=> history.push({
                            pathname: '/admin/jobs/0',
                            state: { referrer: '/admin/careers' }})} >Add</Button>
                    </Box>

                    <Box className='clinic-card-styling'>
                        
                        <Box className='top-line'></Box>
                        <Typography className='clinic-typo-styling clinic-title'
                         style={{marginBottom:'1rem'}}>All Jobs</Typography>
                        <Typography className='clinic-typo-styling clinic-address clinic-margin-bottom' style={{marginBottom:'1rem'}}>
                        See all job posts
                        </Typography>
                        <Button className='clinic-typo-styling' 
                        style={{width:'fit-content', backgroundColor: '#fff',border:'0.1rem solid #C7C7C7', 
                        boxShadow: '0px 0px 5px #8888'}} onClick={()=> history.push(`/admin/alljobs`)} >View</Button>
                    </Box>
                    <Box className='clinic-card-styling'>
                        
                        <Box className='top-line'></Box>
                        <Typography className='clinic-typo-styling clinic-title'
                         style={{marginBottom:'1rem'}}> Manage Departments and Roles</Typography>
                        <Typography className='clinic-typo-styling clinic-address clinic-margin-bottom' style={{marginBottom:'1rem'}}>
                        {/* Manage Departments And Roles */}
                        </Typography>
                        <Button className='clinic-typo-styling' 
                        style={{width:'fit-content', backgroundColor: '#fff',border:'0.1rem solid #C7C7C7', 
                        boxShadow: '0px 0px 5px #8888'}} onClick={()=> history.push(`/admin/manage-departments`)} >View</Button>
                    </Box>
                    <Box className='clinic-card-styling'>
                        
                        <Box className='top-line'></Box>
                        <Typography className='clinic-typo-styling clinic-title'
                         style={{marginBottom:'1rem'}}> Manage CV Banks</Typography>
                        <Typography className='clinic-typo-styling clinic-address clinic-margin-bottom' style={{marginBottom:'1rem'}}>
                        {/* Manage Departments And Roles */}
                        </Typography>
                        <Button className='clinic-typo-styling' 
                        style={{width:'fit-content', backgroundColor: '#fff',border:'0.1rem solid #C7C7C7', 
                        boxShadow: '0px 0px 5px #8888'}} onClick={()=> history.push(`/admin/manage-cv-banks`)} >View</Button>
                    </Box>
                
               

            </Box>
                
         
              

            </Page>
        


        </NavigationLayout>
    );
}
export default Careers;
