import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../../history";
import Edit from "./Edit";
import Images from "./Images/Main";

function MainEdit() {
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/admin/book-a-scan-countries/:id/cities/:idCity/clinics/:idClinic"
          component={Edit}
        />
        <Route
          path="/admin/book-a-scan-countries/:id/cities/:idCity/clinics/:idClinic/images"
          component={Images}
        />
      </Switch>
    </Router>
  );
}
export default MainEdit;
