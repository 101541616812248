import Cookies from 'js-cookie'
import React from 'react'
import basmaLogo from "../../../img/doctorsLogo.png";
import eye from "../../../img/eye.png";
import warning from "../../../img/warning.png";
import redo from "../../../img/redo.png";
import phones from "../../../img/phone-2.png";
import googleStoreAr from "../../../img/google-play-ar.png";
import appStoreAr from "../../../img/app-store-ar.png";
import certificate from "../../../img/certificate.png";
import card from "../../../img/card.png";
import phone2 from "../../../img/2-phone.png";
import smile from "../../../img/smile.png";
import wtsp from "../../../img/wtsp.png";
import email from "../../../img/email.png";
import phone from "../../../img/phone.png";
import im3 from "../../../img/im3.svg";
import footer from "../../../img/tp-pdf-footer-ar.png";
import CheckIcon from '@mui/icons-material/Check';

const TreatmentPDFAr = ({
    user,
    selectedCaseTypes,
    procedures,
    id,
    cases,
    valueTreatmentDescription,
    valueLengthOfTreatment,
    selectedAlignerWearTime,
    valueNumberAlignersUpper,
    valueNumberAlignersLower,
    createdAt,
    name,
    checkedIsTeethImageVisible,
    type,
    beforeStepValueArray,
    firstAppointmentNumber,
    comments,
    optionsProceduresHashMap,
    checkedRequireRedo,
}) => {

    return (
        <div className="tp-pdf-ar">
            <div className="tp-pdf-sub-container tp-pdf-blue-container">
                <div className="tp-pdf-blue-container-1">
                    <h1 className="tp-pdf-blue-container-h1">!{user}&nbsp;مرحباً</h1>
                    <p className="tp-pdf-blue-container-p">متحمّسون&nbsp;لرؤية&nbsp;ابتسامتك&nbsp;الجديدة</p><span>!</span>
                </div>
                <div className="tp-pdf-blue-container-2">
                    <img src={basmaLogo} alt='Logo' width='150' height='62' />
                </div>
            </div>
            <div className="pdf-generated-container">
                <p className="pdf-generated-p"><span className="pdf-generated-txt">تاريخ&nbsp;البدء بالعلاج</span><span>:</span> {createdAt}</p>
            </div>
            <hr className="pdf-hr" />
            <div className="tp-pdf-sub-container">
                <div>
                    <p className="tp-pdf-question-title">ماذا&nbsp;في&nbsp;خطة&nbsp;العلاج؟</p>
                    <ul className="tp-pdf-question-points">
                        <div className='tp-pdf-question-point-container'><span className='tp-pdf-question-point'>•</span><p>ملخص&nbsp;العلاج</p></div>
                        <div className='tp-pdf-question-point-container'><span className='tp-pdf-question-point'>•</span><p>المراجعات&nbsp;الضرورية&nbsp;في&nbsp;العيادة</p></div>
                        <div className='tp-pdf-question-point-container'><span className='tp-pdf-question-point'>•</span><p>ما&nbsp;هي&nbsp;الإجراءات&nbsp;التي&nbsp;يجب&nbsp;عليّ&nbsp;القيام&nbsp;بها؟</p></div>
                        <div className='tp-pdf-question-point-container'><span className='tp-pdf-question-point'>•</span><p>مراجعات&nbsp;تقدّم&nbsp;العلاج&nbsp;عن&nbsp;بُعد</p></div>
                        <div className='tp-pdf-question-point-container'><span className='tp-pdf-question-point'>•</span><p>بعض&nbsp;الحالات&nbsp;التي&nbsp;عالجناها</p></div>
                    </ul>
                </div>
                {checkedRequireRedo ?
                    <div className="tp-pdf-redo-scan">
                        <p>تحتاج&nbsp;إلى&nbsp;إعادة&nbsp;إجراء مسح&nbsp;لأسنانك&nbsp;قبل&nbsp;البدء بالعلاج</p>
                        <img className="tp-pdf-redo-scan-img" src={redo} alt='Redo Scan' />
                    </div> :
                    null
                }
            </div>
            <div className="tp-pdf-sub-container">
                <div className="tp-pdf-time-container">
                    <div className="tp-pdf-time-title-container">
                        <p className="tp-pdf-time-title">مدّة&nbsp;العلاج</p>
                    </div>
                    <p className="tp-pdf-time-count">{valueLengthOfTreatment}</p>
                    <p className="tp-pdf-time-months">أشهر</p>
                    <p className="tp-pdf-time-desc">مدة&nbsp;كل&nbsp;خطوة&nbsp;هي&nbsp;{selectedAlignerWearTime == '2-week' ? 'أسبوعان' : (selectedAlignerWearTime == '1-week' ? 'أسبوع' : '10 أيام')}</p>
                    <p className="tp-pdf-time-desc">من&nbsp;20 إلى&nbsp;22 ساعة&nbsp;في&nbsp;اليوم</p>
                </div>
                <div className="tp-pdf-time-container">
                    <div className="tp-pdf-time-title-container">
                        <p className="tp-pdf-time-title">عدد&nbsp;المصففات</p>
                    </div>
                    <p className="tp-pdf-time-count">{Math.max(valueNumberAlignersUpper, valueNumberAlignersLower)}</p>
                    <p className="tp-pdf-time-months">مجموعة</p>
                    <p className="tp-pdf-time-desc">{valueNumberAlignersUpper} مصففات&nbsp;للفك&nbsp;العلوي</p>
                    <p className="tp-pdf-time-desc">{valueNumberAlignersLower} مصففات&nbsp;للفك&nbsp;السفلي</p>
                </div>
            </div>
            <div className="tp-pdf-sub-container-2">
                <p className="tp-pdf-desc-title"><span className="tp-pdf-desc-span">نوع&nbsp;العلاج<span>:</span></span> {name ? name : (type ? (type == 'tp' ? 'Initial Treatment' : (type == 'rtp' ? 'Refinement Treatment' : 'MCA Treatment')) : '')}</p>
                <p className="tp-pdf-desc-title"><span className="tp-pdf-desc-span">الحالة&nbsp;المعالجة<span>:</span></span> {selectedCaseTypes.map(c => c.label).join(', ')}</p>
                <div>
                    <p className="tp-pdf-desc-title"><span className="tp-pdf-desc-span">مراجعة&nbsp;صور&nbsp;أسنانك&nbsp;عن&nbsp;بعد<span>:</span></span> {valueLengthOfTreatment * 2}</p>
                    <p className="tp-pdf-details-p">مزيد&nbsp;من&nbsp;المعلومات&nbsp;أدناه</p>
                </div>
                {beforeStepValueArray?.length ? <div>
                    <p className="tp-pdf-desc-title"><span className="tp-pdf-desc-span">المراجعات&nbsp;في&nbsp;العيادة<span>:</span></span> {beforeStepValueArray?.length}</p>
                    <p className="tp-pdf-details-p">مزيد&nbsp;من&nbsp;المعلومات&nbsp;أدناه</p>
                </div> : ''}
                <hr className="pdf-hr" />
            </div>
            <div className="tp-pdf-sub-container-3">
                {valueTreatmentDescription ? <div className="tp-pdf-desc-paragraph">{valueTreatmentDescription}</div> : null}
                {checkedIsTeethImageVisible && (
                    <div className="tp-pdf-teeth-image-container">
                        <img className="tp-pdf-teeth-image" src={im3} />
                    </div>
                )}
                <div className="tp-pdf-desc-container">
                    <div className="tp-pdf-desc-sub-container">
                        <img src={eye} className="tp-pdf-desc-img" width="48" height="48" />
                        <hr className="tp-pdf-desc-hr" />
                        <p className="tp-pdf-desc-p">يمكنك&nbsp;مشاهدة&nbsp;النسخة&nbsp;الثلاثية&nbsp;الأبعاد لابتسامتك المستقبلية&nbsp;عبر&nbsp;تطبيق&nbsp;بسمة أو من خلال حسابك&nbsp;على&nbsp;موقعنا&nbsp;للاطلاع على النتيجة&nbsp;النهائية&nbsp;لعلاجك</p>
                    </div>
                    <div className="tp-pdf-desc-red-sub-container">
                        <img src={warning} className="tp-pdf-desc-img" />
                        <hr className="tp-pdf-desc-red-hr" />
                        <p className="tp-pdf-desc-p">إن عدم وضع تقويم الأسنان الشفاف وفق خطة&nbsp;علاجك<span>،</span> قد يؤخر علاجك&nbsp;حوالي شهرين أو 3 أشهر أو&nbsp;أكثر<span>.</span></p>
                    </div>
                </div>
            </div>
            {beforeStepValueArray?.length ?
                <div>
                    <div className="tp-pdf-procedures-table">
                        <h1 className="tp-pdf-table-title">المراجعات&nbsp;الضرورية&nbsp;في&nbsp;العيادة</h1>
                        <p className="tp-pdf-table-desc">تبعا&nbsp;لخطة&nbsp;علاجك<span>،</span> يجب&nbsp;عليك&nbsp;زيارة&nbsp;العيادة&nbsp;للقيام&nbsp;بالإجراءات&nbsp;التالية</p><span>:</span>
                        <table className="tp-pdf-table">
                            <tbody>
                                <tr className="tp-pdf-table-tr">
                                    <th className="tp-pdf-table-th">موعد</th>
                                    <th className="tp-pdf-table-th">الإجراءات</th>
                                    <th className="tp-pdf-table-th">قبل&nbsp;الخطوة</th>
                                </tr>
                                {
                                    firstAppointmentNumber == 2 && <tr className="tp-pdf-table-tr-data">
                                        <td className="tp-pdf-table-td tp-pdf-table-td-center">
                                            #1
                                        </td>
                                        <td className="tp-pdf-table-td tp-pdf-table-td-center">First Visit</td>
                                        <td className="tp-pdf-table-td tp-pdf-table-td-center">
                                            <CheckIcon style={{ fontSize: '25px' }} />
                                        </td>
                                    </tr>
                                }
                                {beforeStepValueArray.map((singleStepValue, idx) => {
                                    return (
                                        <tr className="tp-pdf-table-tr-data" key={idx}>
                                            <td className="tp-pdf-table-td tp-pdf-table-td-center">
                                                #{firstAppointmentNumber + idx}
                                            </td>
                                            <td className="tp-pdf-table-td tp-pdf-table-td-center">
                                                {
                                                    Object.keys(singleStepValue).filter(
                                                        (key) =>
                                                            singleStepValue[key] == 1 &&
                                                            optionsProceduresHashMap[key] !== undefined
                                                    )
                                                    .map((key) => (optionsProceduresHashMap[key])).join(', ')
                                                }
                                            </td>
                                            <td className="tp-pdf-table-td tp-pdf-table-td-center">
                                                {singleStepValue.before_step}
                                                {singleStepValue.position && singleStepValue.positions?.length > 1 && singleStepValue.position}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        {comments.dentalWork && <p className="tp-pdf-table-end-p">تشمل&nbsp;المراجعة&nbsp;الطبية&nbsp;لأسنانك&nbsp;ما&nbsp;يلي<span>:</span> {comments.dentalWork}</p>}
                        {comments.extraction && <p className="tp-pdf-table-end-p">قلع الأسنان<span>:</span> {comments.extraction}</p>}
                        <p className="tp-pdf-table-end-p2">سيتواصل&nbsp;معك&nbsp;فريقنا&nbsp;لتذكيرك&nbsp;بمواعيدك</p>
                    </div>
                    {
                        procedures.length ?
                        <>
                            <div className="tp-pdf-bg-ar-container"/>
                            <div className="tp-pdf-clip-path-container">
                                <div className="tp-pdf-inner-clip-path">
                                    {
                                        procedures.map((proc, key) => {
                                            return (
                                                <div key={key} className="tp-pdf-single-procedure">
                                                    <img src={proc.icon} className="tp-pdf-single-procedure-icon" />
                                                    <div className="tp-pdf-single-procedure-container">
                                                        {proc.labelAr}
                                                        {proc.descriptionAr}
                                                        {proc.img && <img src={proc.imgAr ? proc.imgAr : proc.img} className="tp-pdf-single-procedure-img" />}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </> :
                        null
                    }
                </div> :
                ''
            }
            <div className="tp-pdf-sub-remote-container">
                <img src={phones} className="tp-pdf-remote-img" />
                <div className="tp-pdf-remote-container">
                    <h1 className="tp-pdf-remote-h1">مراجعات تقدّم العلاج عن بُعد</h1>
                    <p className="tp-pdf-remote-p">بما أن اختصاصيي تقويم الأسنان لدينا&nbsp;سيتابعون&nbsp;حالتك عن&nbsp;بُعد<span>،</span> يتوجّب عليك تحميل صور أسنانك بعد الانتهاء من كل خطوة ليتمكّنوا من مراجعتها طيلة فترة علاجك<span>.</span> تساعد هذه الطريقة على ضمان حسن سير علاجك<span>.</span> حمّل تطبيق بسمة لمتابعة تقدّم علاجك<span>،</span> وتلقّى الإشعارات والتواصل مع فريق عملنا في أي&nbsp;وقت<span>.</span></p>
                    <div className="tp-pdf-remote-img-container">
                        <a href="https://play.google.com/store/apps/details?id=com.basmapatientapp" target="blank"><img src={googleStoreAr} className="google-play" /></a>
                        <a href="https://apps.apple.com/app/basma-com/id1643932915" target="blank"><img src={appStoreAr} className="app-store" /></a>
                    </div>
                </div>
            </div>
            <div className="tp-pdf-sub-remote-container">
                <img src={certificate} className="tp-pdf-certificate-img" />
                <div className="tp-pdf-certificate-container">
                    <h1 className="tp-pdf-certificate-h1">ابتسامة&nbsp;مضمونة</h1>
                    <hr className="tp-pdf-certificate-hr" />
                    <p className="tp-pdf-certificate-p">قد&nbsp;تتطلب&nbsp;بعض&nbsp;الحالات&nbsp;تحسينًا&nbsp;طفيفًا&nbsp;في&nbsp;النهاية<span>،</span> والذي&nbsp;قد&nbsp;يتضمن&nbsp;بضع&nbsp;خطوات&nbsp;إضافية&nbsp;لتقويم&nbsp;الأسنان<span>.</span> في&nbsp;حال&nbsp;كنت&nbsp;تلتزم&nbsp;بوضع&nbsp;التقويم&nbsp;وفق&nbsp;خطة&nbsp;علاجك<span>،</span> وتحمّل&nbsp;صور&nbsp;تقدّم&nbsp;أسنانك&nbsp;بعد&nbsp;كل&nbsp;خطوة<span>،</span> ولا&nbsp;تلاحظ&nbsp;النتيجة&nbsp;التي&nbsp;تريدها&nbsp;في&nbsp;نهاية&nbsp;العلاج<span>،</span> سنقدّم&nbsp;لك&nbsp;مصففات&nbsp;مجانية&nbsp;إضافية&nbsp;<span>)</span>وهي&nbsp;خطوات&nbsp;تضاف&nbsp;إلى&nbsp;خطة&nbsp;علاجك<span>(</span> لضمان&nbsp;حصولك&nbsp;على&nbsp;النتيجة&nbsp;التي&nbsp;تريد<span>.</span></p>
                </div>
            </div>
            {/* <div className="tp-pdf-bg-ar-container tp-pdf-case-bg-ar-container"/>
            <div className="tp-pdf-clip-path-container tp-pdf-cases-container">
                {
                    cases.map((c, key) => {
                        return (
                            <div key={key} className="tp-pdf-case-container">
                                <div className="tp-pdf-case-sub-container">
                                    {c.labelAr}
                                    <hr className="tp-pdf-case-hr" />
                                </div>
                                <div className="tp-pdf-case-imgs-container">
                                    {
                                        c.images.map((img, key) => {
                                            return (
                                                <div key={key} className="tp-pdf-case-img-container">
                                                    <img src={img.imgAr} className="tp-pdf-case-img" />
                                                    {img.titleAr}
                                                    {img.descriptionAr}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div> */}
            <p className="tp-pdf-next-steps-title">الخطوات&nbsp;التالية</p>
            <div className="tp-pdf-sub-container tp-pdf-next-steps-container">
                <div className="tp-pdf-next-steps-ol">
                    <p><img src={card} className="tp-pdf-next-steps-img" /><span>1 -</span>اختر&nbsp;طريقة&nbsp;الدفع&nbsp;التي&nbsp;تناسبك</p>
                    <p><img src={phone2} className="tp-pdf-next-steps-img" /><span>2 -</span>اطلب&nbsp;علاج&nbsp;تقويم&nbsp;الأسنان</p>
                    <p><img src={smile} className="tp-pdf-next-steps-img" /><span>3 -</span>ابدأ&nbsp;رحلتك&nbsp;نحو&nbsp;ابتسامة&nbsp;جديدة</p>
                </div>
            </div>
            <div className="tp-pdf-questions-container">
                <p className="tp-pdf-questions-title">هل&nbsp;لديك&nbsp;أسئلة؟</p>
                <p className="tp-pdf-questions-desc">فريقنا&nbsp;جاهز&nbsp;دائماً&nbsp;للمساعدة<span>!</span></p>
            </div>
            <div className="tp-pdf-social-media-container">
                <a href="mailto: care@basma.com" className="tp-pdf-social-media-txt"><img src={email} className="tp-pdf-social-media-img" />care@basma.com</a>
                <a href='https://wa.me/971545755078' rel="no follow" target='_blank' className="tp-pdf-social-media-txt"><span className="tp-pdf-initial-direction">+971 54 575 5078</span><img src={wtsp} className="tp-pdf-social-media-img" /></a>
            </div>
            <div className="tp-pdf-footer-bg-container tp-pdf-bg-container">
                <p className="tp-pdf-social-media-txt"><img src={phone} className="tp-pdf-social-media-img" /></p>
            </div>
            <div className="tp-pdf-footer-container">
                <div className="tp-pdf-footer-sub-container">
                    <span className="tp-pdf-footer-phone"><span className="tp-pdf-footer-phone-title">الامارات<span>:</span> </span><pre className='tp-pdf-initial-direction-pre'>+971 4 278 9914</pre></span>
                    <span className="tp-pdf-footer-phone"><span className="tp-pdf-footer-phone-title">السعودية<span>:</span> </span><span className='tp-pdf-initial-direction-pre'>+966 11 834 3423</span></span>
                    <span className="tp-pdf-footer-phone"><span className="tp-pdf-footer-phone-title">الكويت<span>:</span> </span><span className='tp-pdf-initial-direction-pre'>+965 2205 2637</span></span>
                    <span className="tp-pdf-footer-phone"><span className="tp-pdf-footer-phone-title">قطر<span>:</span> </span><span className='tp-pdf-initial-direction-pre'>+971 4 278 9914</span></span>
                    <span className="tp-pdf-footer-phone"><span className="tp-pdf-footer-phone-title">البحرين<span>:</span> </span><span className='tp-pdf-initial-direction-pre'>+973 1663 9174</span></span>
                    <span className="tp-pdf-footer-phone"><span className="tp-pdf-footer-phone-title">عمان<span>:</span> </span><span className='tp-pdf-initial-direction-pre'>+968 24 442694</span></span>
                    <span className="tp-pdf-footer-phone"><span className="tp-pdf-footer-phone-title">لبنان<span>:</span> </span><span className='tp-pdf-initial-direction-pre'>+961 81 144 653</span></span>
                </div>
                <img src={footer} className="tp-pdf-footer-img" />
                <p className="tp-pdf-footer-id">{id}</p>
            </div>
        </div>
    )
}

export default TreatmentPDFAr