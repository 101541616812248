import React, { useCallback, useState, useEffect } from "react";
import {
  Modal,
  Thumbnail,
  Caption,
  DropZone,
  Stack,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  Card,
  Tabs,
  Toast,
  PageActions,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";

import {useLocation, useParams} from "react-router-dom";
import UserHeader from "../../../components/UserLayout/user-header";

function EditImpressionsAndPhotosStep2() {
  const { id } = useParams();
  let imageType = "";

  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [imageFirstTestImpression, setImageFirstTestImpression] = useState("");
  const [
    imageKeyFirstTestImpression,
    setImageKeyFirstTestImpression,
  ] = useState("");
  const [
    imagePreviewFirstTestImpression,
    setImagePreviewFirstTestImpression,
  ] = useState("");

  const [filesFirstTestImpression, setFilesFirstTestImpression] = useState([]);
  const handleDropZoneFirstTestImpression = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesFirstTestImpression((filesFirstTestImpression) => [
        ...filesFirstTestImpression,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadFirstTestImpression = !filesFirstTestImpression.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesFirstTestImpression = filesFirstTestImpression.length >
    0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesFirstTestImpression[filesFirstTestImpression.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesFirstTestImpression[filesFirstTestImpression.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesFirstTestImpression[filesFirstTestImpression.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesFirstTestImpression[filesFirstTestImpression.length - 1].name}{" "}
        <Caption>
          {filesFirstTestImpression[filesFirstTestImpression.length - 1].type}{" "}
          bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageFirstTestImpression = useCallback(
    (filesFirstTestImpression) => {
      setPopupActive(true);
      setImagePreviewFirstTestImpression(
        validImageTypes.indexOf(
          filesFirstTestImpression[filesFirstTestImpression.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              filesFirstTestImpression[filesFirstTestImpression.length - 1]
            )
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType =
        filesFirstTestImpression[filesFirstTestImpression.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeyFirstTestImpression(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(
              url2,
              filesFirstTestImpression[filesFirstTestImpression.length - 1],
              {
                headers: {
                  "x-amz-acl": "public-read-write",
                  "Content-Type": imageType,
                },
              }
            )
            .then((res) => {
              console.log("res2==", res);
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesFirstTestImpression]
  );
  const [isUploadingAtive, setIsUploadingAtive] = useState(false);
  const [popupActive, setPopupActive] = useState(false);
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content="Image Deleted" onDismiss={toggleActive} />
  ) : null;

  useEffect(() => {
    axios
      .get("admin/v1/users/" + id + "/impressions&photos", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        result.data.data.step2 &&
          result.data.data.step2.first_test_impression_url &&
          setImagePreviewFirstTestImpression(
            result.data.data.step2.first_test_impression_url
          );
      })
      .catch((err) => console.log(err));
  }, []);
  const location = useLocation();
  let linkState =
    {planType: "", clinicStatus: "", clinicName: "", status: "", userId: id, pageTitle: ""};
  if (location.state !== undefined) {
    const {planType, clinicStatus, clinicName, status, userId, pageTitle} = location.state;
    linkState = {planType, clinicStatus, clinicName, status, userId, pageTitle}
  }
  const {planType, clinicStatus, clinicName, status, userId, pageTitle} = linkState;
  return (
    <Page>
        <UserHeader pageTitle={pageTitle} status={status} userId={userId} clinicStatus={clinicStatus} clinicName={clinicName} planType={planType}/>
        <br/>
        <h1 className="page-header">Edit impressions and photos - Step2</h1>
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">First test impression</TextStyle>
          <Thumbnail size="large" source={imagePreviewFirstTestImpression} />

          <DropZone
            onDrop={handleDropZoneFirstTestImpression}
            onDropAccepted={sendImageFirstTestImpression}
          >
            {uploadedFilesFirstTestImpression}
            {fileUploadFirstTestImpression}
          </DropZone>
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
        secondaryActions={[
          {
            content: "Delete",
            onClick: () =>
              axios
                .delete(
                  `admin/v1/users/${id}/impressions&photos/step2/delete`,
                  {
                    headers: {
                      Authorization: "Bearer " + Cookie.get("token"),
                    },
                  }
                )
                .then((result) => {
                  console.log("deleted");
                  toggleActive();
                  setImagePreviewFirstTestImpression("");
                  // history.push("/admin/users/" + id + "/dashboard");
                })
                .catch((err) => console.log(err)),
          },
        ]}
      />
      {toastMarkup}
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );

  function handleSave() {
    console.log("doneee");
    const bodyObj = {
      first_test_impression: imageKeyFirstTestImpression,
    };
    axios
      .post(`admin/v1/users/${id}/impressions&photos/step2`, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        console.log("result=", res);
        history.push("/admin/users/" + id + "/dashboard");
      })
      .catch((err) => console.log(err));
  }
}
export default EditImpressionsAndPhotosStep2;
