import { Card, Layout } from '@shopify/polaris'
import React from 'react'

const LegacyComponent = ({ goToLegacy }) => {
  return (
    <Layout.Section>
      <Card
        sectioned
        title="LEGACY"
        actions={[
          {
            content: "Show More",
            onClick: goToLegacy,
          },
        ]}
      />
    </Layout.Section>
  )
}

export default LegacyComponent