import React, { useState } from "react";
import { Box, Button as MUIButton, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const MenuActions = ({
  isPendingProcedure,
  item,
  classes,
  canUpdateProcStatus,
  updateProcStatus,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuActions = [
    { action: "Done Elsewhere", disabled: !canUpdateProcStatus },
  ];

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickAnchor = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClick = (item, action) => {
    updateProcStatus(item, action);
    handleMenuClose();
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "5px",
      }}
    >
      <span style={{ textAlign: isPendingProcedure ? "initial" : "center" }}>
        {item.status}
      </span>
      {isPendingProcedure && (
        <>
          <MUIButton
            className={classes.menuButton}
            disabled={!canUpdateProcStatus}
            onClick={handleClickAnchor}
          >
            <MoreVertIcon />
          </MUIButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            className={classes.menu}
            onClose={handleMenuClose}
          >
            {menuActions.map((action, key) => (
              <MenuItem
                onClick={() => handleOnClick(item, action.action)}
                key={key}
                disabled={action?.disabled}
                style={{ boxShadow: "inset 0 -1px 0 0 #DFE3E8" }}
              >
                <Box className={classes.menuWrapper}>{action.action}</Box>
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </div>
  );
};

export default MenuActions;
