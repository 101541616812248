import React from "react";
import { Switch, Route, Router, withRouter } from "react-router-dom";
import NavigationLayout from "../components/NavigationLayout";
import history from "../history";
import EditPreScreening from "../Users/DashboardComponents/PreScreening/EditPreScreening";
import LeadDetails from "./LeadDetails";
import LeadsOfLeads from "./ListOfLeads";

function Leads() {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/leads" component={LeadsOfLeads} />
          <Route path="/admin/leads/:id/editprescreening" component={EditPreScreening} />
          <Route path="/admin/leads/:id" component={LeadDetails} />
        </Switch>
      </Router>
    </NavigationLayout>
  );
}
export default Leads;
