import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../history";
import List from "./List";
import Add from "./Add";
import Edit from "./Edit";
import { AppProvider } from "@shopify/polaris";
import NavigationLayout from "../components/NavigationLayout";

function Products() {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/products" component={List} />
          {/* <Route path="/admin/products/add" component={Add} /> */}
          <Route exact path="/admin/products/:id(\d+)" component={Edit} />
        </Switch>
      </Router>
    </NavigationLayout>
  );
}
export default Products;
