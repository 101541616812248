import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  Avatar,
  Button,
  ResourceItem,
  ResourceList,
  Thumbnail,
  Caption,
  Layout,
  Toast,
  DropZone,
  Checkbox,
  Stack,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  ActionList,
  Popover,
  Select,
  Card,
  Tabs,
  PageActions,
} from "@shopify/polaris";
import axios from "../../axios";
import Cookie from "js-cookie";
import history from "../../history";
import Helmet from "react-helmet";
import { Editor } from "@tinymce/tinymce-react";

import { useParams } from "react-router-dom";

function EditAlignerKit() {
  let { id } = useParams();
  const [valueNumberAligners, setValueNumberAligners] = useState("");

  let imageType = "";
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [imageKeyFirst3dFile, setImageKeyFirst3dFile] = useState("");
  const [imagePreviewFirst3dFile, setImagePreviewFirst3dFile] = useState(
    Cookie.get("firstFile")
  );
  const [filesFirst3dFile, setFilesFirst3dFile] = useState([]);
  const handleDropZoneFirst3dFile = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesFirst3dFile((filesFirst3dFile) => [
        ...filesFirst3dFile,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadFirst3dFile = !filesFirst3dFile.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesFirst3dFile = filesFirst3dFile.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesFirst3dFile[filesFirst3dFile.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesFirst3dFile[filesFirst3dFile.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesFirst3dFile[filesFirst3dFile.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesFirst3dFile[filesFirst3dFile.length - 1].name}{" "}
        <Caption>
          {filesFirst3dFile[filesFirst3dFile.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageFirst3dFile = useCallback(
    (filesFirst3dFile) => {
      setImagePreviewFirst3dFile(
        validImageTypes.indexOf(
          filesFirst3dFile[filesFirst3dFile.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              filesFirst3dFile[filesFirst3dFile.length - 1]
            )
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesFirst3dFile[filesFirst3dFile.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeyFirst3dFile(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesFirst3dFile[filesFirst3dFile.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {})
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },

    [filesFirst3dFile]
  );

  const [imageKeySecond3dFile, setImageKeySecond3dFile] = useState("");
  const [imagePreviewSecond3dFile, setImagePreviewSecond3dFile] = useState(
    Cookie.get("secondFile")
  );
  const [filesSecond3dFile, setFilesSecond3dFile] = useState([]);
  const handleDropZoneSecond3dFile = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesSecond3dFile((filesSecond3dFile) => [
        ...filesSecond3dFile,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadSecond3dFile = !filesSecond3dFile.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesSecond3dFile = filesSecond3dFile.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesSecond3dFile[filesSecond3dFile.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesSecond3dFile[filesSecond3dFile.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesSecond3dFile[filesSecond3dFile.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesSecond3dFile[filesSecond3dFile.length - 1].name}{" "}
        <Caption>
          {filesSecond3dFile[filesSecond3dFile.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageSecond3dFile = useCallback(
    (filesSecond3dFile) => {
      setImagePreviewSecond3dFile(
        validImageTypes.indexOf(
          filesSecond3dFile[filesSecond3dFile.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              filesSecond3dFile[filesSecond3dFile.length - 1]
            )
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesSecond3dFile[filesSecond3dFile.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeySecond3dFile(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesSecond3dFile[filesSecond3dFile.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {})
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },

    [filesSecond3dFile]
  );

  const [imageKeyGuide, setImageKeyGuide] = useState("");
  const [imagePreviewGuide, setImagePreviewGuide] = useState(
    Cookie.get("guide")
  );
  const [filesGuide, setFilesGuide] = useState([]);
  const handleDropZoneGuide = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesGuide((filesGuide) => [...filesGuide, ...acceptedFiles]),
    []
  );
  const fileUploadGuide = !filesGuide.length && <DropZone.FileUpload />;
  let uploadedFilesGuide = filesGuide.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesGuide[filesGuide.length - 1].name}
        source={
          validImageTypes.indexOf(filesGuide[filesGuide.length - 1].type) > 0
            ? window.URL.createObjectURL(filesGuide[filesGuide.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesGuide[filesGuide.length - 1].name}{" "}
        <Caption>{filesGuide[filesGuide.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImageGuide = useCallback(
    (filesGuide) => {
      setImagePreviewGuide(
        validImageTypes.indexOf(filesGuide[filesGuide.length - 1].type) > 0
          ? window.URL.createObjectURL(filesGuide[filesGuide.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesGuide[filesGuide.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeyGuide(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesGuide[filesGuide.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {})
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },

    [filesGuide]
  );

  useEffect(() => {
    // getData();
  }, []);

  function getData() {
    axios
      .get("admin/v1/pages/" + id, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {})
      .catch((err) => console.log(err));
  }

  let arrayOfKeys = [
    imageKeyFirst3dFile,
    imageKeySecond3dFile,
    imageKeyGuide,
    valueNumberAligners,
  ];
  let arrayOfStrings = [
    "first_file",
    "second_file",
    "guide",
    "number_of_aligner",
  ];
  useEffect(() => {
    getData(0);
  }, []);

  function getData() {
    axios
      .get(`admin/v1/users/${id}/aligner-kit`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {})
      .catch((err) => console.log(err));
  }
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const toastMarkup = active ? (
    <Toast content="Image Deleted" onDismiss={toggleActive} />
  ) : null;
  useEffect(() => {
    axios
      .get("admin/v1/users/" + id + "/impressions&photos", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        // result.data.data.step2 &&
        //   result.data.data.step2.first_test_impression_url &&
        //   setImagePreviewFirstTestImpression(
        //     result.data.data.step2.first_test_impression_url
        //   );
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page
      title="Edit aligner kit"
      breadcrumbs={[
        {
          content: "Dashboard",
          url: `/admin/users/${id}/dashboard`,
        },
      ]}
    >
      <Layout>
        <Layout.Section>
          <Card title="First 3D File" sectioned>
            <FormLayout>
              <Thumbnail size="large" source={imagePreviewFirst3dFile} />
              <DropZone
                onDrop={handleDropZoneFirst3dFile}
                onDropAccepted={sendImageFirst3dFile}
              >
                {uploadedFilesFirst3dFile}
                {fileUploadFirst3dFile}
              </DropZone>
              <Button id="first_file" onClick={(e) => handleDelete(e)}>
                Delete
              </Button>
            </FormLayout>
          </Card>
        </Layout.Section>

        <Layout.Section>
          <Card title="Second 3D File" sectioned>
            <FormLayout>
              <Thumbnail size="large" source={imagePreviewSecond3dFile} />
              <DropZone
                onDrop={handleDropZoneSecond3dFile}
                onDropAccepted={sendImageSecond3dFile}
              >
                {uploadedFilesSecond3dFile}
                {fileUploadSecond3dFile}
              </DropZone>
              <Button id="second_file" onClick={(e) => handleDelete(e)}>
                Delete
              </Button>
            </FormLayout>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Card title="Guide" sectioned>
            <FormLayout>
              <Thumbnail size="large" source={imagePreviewGuide} />
              <DropZone
                onDrop={handleDropZoneGuide}
                onDropAccepted={sendImageGuide}
              >
                {uploadedFilesGuide}
                {fileUploadGuide}
              </DropZone>
              <Button id="guide" onClick={(e) => handleDelete(e)}>
                Delete
              </Button>
            </FormLayout>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Card title="Number of aligners" sectioned>
            <FormLayout>
              <TextField
                value={valueNumberAligners}
                onChange={handleChangeNumberAligners}
              />
            </FormLayout>
          </Card>
        </Layout.Section>
      </Layout>

      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
      {toastMarkup}
    </Page>
  );
  function handleDelete(e) {
    axios
      .delete(`admin/v1/users/${id}/delete`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        // toggleActive()
        history.push("/admin/users/" + id + "/dashboard");
      })
      .catch((err) => console.log(err));
  }

  function handleChangeNumberAligners(valueNumberAligners) {
    setValueNumberAligners(valueNumberAligners);
  }

  function handleSave() {
    let bodyObj = {};
    for (var i = 0; i < arrayOfKeys.length; i++) {
      if (arrayOfKeys[i] != "") {
        bodyObj[arrayOfStrings[i]] = arrayOfKeys[i];
      }
    }
    axios
      .post(`admin/v1/users/${id}/aligner-kit`, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        history.push("/admin/users/" + id + "/dashboard");
      })
      .catch((err) => console.log(err));
  }
}
export default EditAlignerKit;
