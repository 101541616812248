import React, { useCallback, useState, useEffect, useContext } from "react";
import {
  DataTable,
  Button,
  Page,
  Image,
  DisplayText,
  Link,
  Loading,
  Card,
  TextStyle,
  ButtonGroup,
  Popover,
  ActionList,
} from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../axios";
import history from "../history";
import emptyIcon from "../img/emptyList.svg";
import { CardActions,Button as ButtonMui, Typography } from "@material-ui/core";

export default function ListPlans() {
  // var json_str = Cookie.get('clickedItem');
  // var cookieResult = JSON.parse(json_str);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sortState, setSortState] = useState("ascending");
  const [items, setItems] = useState([]);
  let arrayOfObjects = [];
  let newArray = [];
  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );
  const [buttonTextPopupFilter, setButtonTextPopupFilter] = useState("Active");
  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      {buttonTextPopupFilter}
    </Button>
  );
  useEffect(() => {
    getData();
  }, [buttonTextPopupFilter]);
  function getData() {
    axios
      .get("/admin/v1/plans", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        if (result.data.data.length === 0) {
          setIsEmpty(true);
          // toggleIsEmpty()
        }
        setIsLoading(false);
        console.log("resultGet=", result);
        console.log("resultGetData=", result.data.data);
        arrayOfObjects = result.data.data.map((item) => [
          item.id,
          item.title.en,
          item.title.ar,
          item.sub_title.en,
          item.sub_title.ar,
          item.description.en,
          item.description.ar,
          item.payment_interval,
          item.treatment_type,
          item.others && item.others.total_price,
          item.others && item.others.price_in_local_currency,
          item.others && item.others.product_country,
          item.active,
        ]);
        let res = [];
        if (buttonTextPopupFilter === "All"){
          res = result.data.data;
        } 
        else{
          if(buttonTextPopupFilter === "Official"){
            res = result.data.data.filter((item) => item.custom === false);
        }else{
          if(buttonTextPopupFilter === "Custom"){
            res = result.data.data.filter((item) => item.custom === true);
        }else{
          res = result.data.data.filter((item) => item.active === true);
        } 
      }
    }

        setItems(
          res.map((item) => [
            item.id,
            item.treatment_type,
            item.title.en,
            item.sub_title.en,
            item.description.en,
            item.payment_interval,
            <ButtonGroup>
              <Button id={String(item.id)} onClick={(e) => handleEdit(e)}>
                Edit
              </Button>
              <Button id={String(item.id)} onClick={(e) => handleEditItems(e)}>
                Edit Items
              </Button>
            </ButtonGroup>,
          ])
        );
      })
      .catch((err) => console.log(err));
  }

  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    []
  );
  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isEmpty ? (
    <div className="message-no-result">
      <div className="message-no-result-img">
        <Image src={emptyIcon}></Image>{" "}
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : null;

  return (
    <Page
      fullWidth
      title="List Of Plans"
      primaryAction={{ content: "Create Plan", url: "/admin/plans/create" }}
    >
      {" "}
      {loadingMarkup}
      <Card sectioned>
        <Popover
          active={popoverActive}
          activator={activator}
          onClose={togglePopoverActive}
        >
          <ActionList
            items={[
              {
                content: "All",
                onAction: () => {
                  setButtonTextPopupFilter("All");
                  setPopoverActive(false);
                },
              },
              {
                content: "Active",
                onAction: () => {
                  setButtonTextPopupFilter("Active");
                  setPopoverActive(false);
                },
              },
              {
                content: "Official",
                onAction: () => {
                  setButtonTextPopupFilter("Official");
                  setPopoverActive(false);
                },
              },
              {
                content: "Custom",
                onAction: () => {
                  setButtonTextPopupFilter("Custom");
                  setPopoverActive(false);
                },
              }
            ]}
          />
        </Popover>
        <CardActions style={{  }}>
        <ButtonMui variant="contained" style={{backgroundColor:"rgb(1 216 162)",color:"white","padding":"1.5em 2em",}}
         onClick={()=>{window.open("/admin/plans/light")}}>
            <Typography variant="h6" >Light</Typography></ButtonMui>
        <ButtonMui variant="contained" style={{backgroundColor:"rgb(2 163 123)",color:"white","padding":"1.5em 2em",}}
         onClick={()=>{window.open("/admin/plans/medium")}}>
            <Typography variant="h6" >Medium</Typography></ButtonMui>
          <ButtonMui variant="contained" style={{backgroundColor:"rgb(0 110 82)",color:"white","padding":"1.5em 2em",}}
         onClick={()=>{window.open("/admin/plans/complex")}}>
            <Typography variant="h6" >Complex</Typography></ButtonMui>  
          </CardActions>
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "text", "text"]}
          headings={[
            <TextStyle variation="strong">Plan ID</TextStyle>,
            <TextStyle variation="strong">Complexity</TextStyle>,
            <TextStyle variation="strong">Title</TextStyle>,
            <TextStyle variation="strong">Subtitle</TextStyle>,
            <TextStyle variation="strong">Description</TextStyle>,
            <TextStyle variation="strong">Payment Interval</TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
          ]}
          rows={items}
          sortable={[false, false, false, false, false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={-1}
          onSort={handleSort}
        />
        {emtyState}
      </Card>
      {/* <Button id='12333' onClick={(e)=>handleEdit(e)}>aaa</Button> */}
    </Page>
  );
  function handleSort() {
    console.log(sortState);

    if (sortState === "ascending") {
      setSortState("descending");
      axios
        .get("/admin/v1/pages?sort=id", {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((result) => {
          console.log("resultGet=", result);
          console.log("resultGetData=", result.data.data);

          arrayOfObjects = result.data.data.map((item) => [
            item.id,
            item.alias,
            item.title.en,
            item.title.ar,
            item.created_at,
            item.updated_at,
          ]);
          setItems(
            result.data.data.map((item) => [
              item.id,
              item.alias,
              item.created_at,
              item.updated_at,
              <ButtonGroup>
                <Button id={String(item.id)} onClick={(e) => handleEdit(e)}>
                  Edit
                </Button>
              </ButtonGroup>,
            ])
          );
        })
        .catch((err) => console.log(err));
    } else {
      setSortState("descending");
      axios
        .get("/admin/v1/pages?sort=-id", {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((result) => {
          console.log("resultGet=", result);
          console.log("resultGetData=", result.data.data);

          arrayOfObjects = result.data.data.map((item) => [
            item.id,
            item.alias,
            item.title.en,
            item.title.ar,
            item.created_at,
            item.updated_at,
          ]);
          setItems(
            result.data.data.map((item) => [
              item.id,
              item.alias,
              item.created_at,
              item.updated_at,
              <ButtonGroup>
                <Button id={String(item.id)} onClick={(e) => handleEdit(e)}>
                  Edit
                </Button>
              </ButtonGroup>,
            ])
          );
        })
        .catch((err) => console.log(err));
    }
  }
  function handleEdit(e) {
    let currentID = e.currentTarget.id;
    var json_str = JSON.stringify(
      findArrayElementById(arrayOfObjects, currentID)
    );
    Cookie.set("id", findArrayElementById(arrayOfObjects, currentID)[0]);
    Cookie.set("clickedItem", json_str);
    history.push("/admin/plans/" + currentID);
  }
  function handleEditItems(e) {
    let currentID = e.currentTarget.id;
    var json_str = JSON.stringify(
      findArrayElementById(arrayOfObjects, currentID)
    );
    Cookie.set("id", findArrayElementById(arrayOfObjects, currentID)[0]);
    Cookie.set("clickedItem", json_str);
    history.push("/admin/plans/" + currentID + "/payments");
  }
  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element[0]) === parseInt(id);
    });
  }
  function swapArrayElements(a, x, y) {
    if (a.length === 1) return a;
    a.splice(y, 1, a.splice(x, 1, a[y])[0]);
    return a;
  }
}
