import classnames from "classnames";
import React, { useContext, useState } from 'react'
import {
    MobileHamburgerMajor,
    BlogMajor,
    InventoryMajor,
    RecentSearchesMajor,
    CapturePaymentMinor,
    SoftPackMajor,
  } from '@shopify/polaris-icons';
  import {
    Icon,
  } from "@shopify/polaris";
  import ChatIcon from '@mui/icons-material/Chat';
import CaseDiscussionChat from "../CaseDiscussionChat/CaseDiscussionChat";
import "./UserLayout.css"
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { UserContext } from "../../UserContext";
import GuestHeader from "./guest-header";

export default function GuestLayout ({ pageTitle, scrollSmoothly, activeId, children, renderSection, ontoggleNav, openSideNav }) {
    const user = useContext(UserContext);
    const mobile = window.matchMedia("(max-width: 768px)").matches;
    const linkColor = "#FF2C46";
    const queryParams = new URLSearchParams(window.location.search);
    const openChatParam = queryParams.get('openChat');
    const [openChat, setOpenChat] = useState({firstOpen:true,status:openChatParam === 'true'})
    const [previewImages, setPreviewImages] = useState({images:[],startIndex:0});

    function handleChatOpen(){
      setOpenChat((prev)=>{
        if(prev.status==false){
          return {...prev,status:true}
        }else{
          return {firstOpen:false,status:false}
        }
      });
    }
    return (
        <div className="HeaderStickyResp" style={{ transition:0.5,marginLeft:openSideNav? '195px':'55px', top:"55px", minWidth:"80%"}}>
            <GuestHeader pageTitle={pageTitle}/>
            <div>
              <div className={"basma-sidebar-chat"+(openChat.status?" expanded":"")}>
                <CaseDiscussionChat setOpenChat={setOpenChat} openChat={openChat} setPreviewImages={setPreviewImages}/>
              </div>
             <div className={"basma-chat-button"+(openChat.status?" expanded":"")} onClick={handleChatOpen}>
              <ChatIcon />
             </div>
            <div className={classnames({ blur: mobile && openSideNav })} />
            <div
                className={classnames(
                "sidenav",
                { sidenavOpen: openSideNav },
                { sidenavClose: !openSideNav }
                )}
            >
                <div style={{padding:'5px 20px', cursor:"pointer", display:"flex", flexDirection:"row"}} onClick={ontoggleNav}>{renderSection("USER SECTIONS", true)}<Icon
                    source={MobileHamburgerMajor}
                    onClick={ontoggleNav}
                    className="burgermenu"
                    style={{color:"white"}}
                  /></div>
                <br></br>
                <br></br>
                <a style={activeId == 'shipping' ? { color: linkColor, fontWeight:500} : {}} className="shipping" onClick={() => scrollSmoothly('shipping')}><span className="iconsDiv"><Icon source={InventoryMajor} color="base"/><span>{renderSection('Shipping')}</span></span></a>
                <a style={activeId == 'trackings' ? { color: linkColor, fontWeight:500} : {}} className="trackings" onClick={() => scrollSmoothly('trackings')}><span className="iconsDiv"><Icon source={SoftPackMajor} color="base"/><span>{renderSection('Trackings')}</span></span></a>
                <a style={activeId == 'payments' ? { color: linkColor, fontWeight:500} : {}} className="payments" onClick={() => scrollSmoothly('payments')}><span className="iconsDiv"><Icon source={CapturePaymentMinor} color="base"/><span>{renderSection('Transactions')}</span></span></a>
                <a style={activeId === 'tickets' ? { color: linkColor, fontWeight:500} : {}} className="tickets" onClick={() => scrollSmoothly('tickets')}><span className="iconsDiv"><Icon source={BlogMajor} color="base"/><span>{renderSection('Tickets')}</span></span></a>
                <a style={activeId == 'history' ? { color: linkColor, fontWeight:500} : {}} className="history" onClick={() => scrollSmoothly('history')}><span className="iconsDiv"><Icon source={RecentSearchesMajor} color="base"/><span>{renderSection('History')}</span></span></a>
            </div>

            <div
                style={{padding:"0px 20px"}}
                className={classnames(
                "main",
                { mainShrink: openSideNav },
                { mainExpand: !openSideNav },
                { noscroll: mobile && openSideNav }
                )}
            >
             {children}
            </div>
            </div>
            {previewImages.images.length ? <div  ><Lightbox startIndex={previewImages.startIndex} onClose={() => setPreviewImages({images:[],startIndex:0})} images={previewImages.images.map((val) => val.data !== null && process.env.REACT_APP_AWS_URL+val.data)}  ></Lightbox></div> : <></>}
            </div>
    )
}