import LinkUserSelect from "./link-user-select.component";
import { useRef } from "react";
import Axios from "axios";
import axios from "../../axios";
import Cookie from "js-cookie";

function LinkUserSelectContainer({
  loadingUser,
  setLoadingUser,
  setSelectedUser,
  linkedUser,
  selectedUser,
  handleUnLinkUser,
  handleLinkUser,
  asyncSelectRef,
}) {
  const cancelTokenRef = useRef(null);
  const getUserByName = async (inputValue, callback) => {
    if (!inputValue || inputValue.length < 3) {
      callback([]);
      return;
    }

    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel("Operation canceled by user.");
    }

    const cancelTokenSource = Axios.CancelToken.source();
    cancelTokenRef.current = cancelTokenSource;

    try {
      setLoadingUser(true);
      const response = await axios.get(
        `/admin/v1/users/search/?name=${inputValue}`,
        {
          cancelToken: cancelTokenSource.token,
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        },
      );

      if (response?.status === 200 && !cancelTokenSource.token.reason) {
        let usersFound = [];
        let result = response.data.data;
        if (result && result.length > 0) {
          usersFound = result.map((datum) => ({
            label: `${datum.first_name} ${datum.last_name} ( ID : ${datum.id} )`,
            value: datum.id,
          }));
        }
        callback(usersFound);
      }
    } catch (error) {
      console.log("Error get user by name api:", error);
      callback([]);
    } finally {
      setLoadingUser(false);
    }
  };

  return (
    <div style={{ marginBottom: "16px" }}>
      <LinkUserSelect
        loadingUser={loadingUser}
        getUserByName={getUserByName}
        setSelectedUser={setSelectedUser}
        linkedUser={linkedUser}
        selectedUser={selectedUser}
        handleLinkUser={handleLinkUser}
        handleUnLinkUser={handleUnLinkUser}
        asyncSelectRef={asyncSelectRef}
      />
    </div>
  );
}

export default LinkUserSelectContainer;
