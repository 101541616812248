import React, { useState, useEffect } from "react";
import { Router } from "react-router-dom";
import history from "./history";
import axios from "./axios";
import NavigationLayout from "./components/NavigationLayout";
import {
  Page,
  Icon,
} from "@shopify/polaris";
import {
    ChevronLeftMinor,
    ChevronRightMinor
  } from '@shopify/polaris-icons';
import Cookies from "js-cookie";

function Testimonials() {
    
    const [testimonials, setTestimonials] = useState([])
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(0)
  
    useEffect(() => {
        fetchTestimonials()
    }, [page])
    const fetchTestimonials = async () => {
        await axios
          .get(`admin/v1/testimonials?page=${page}`, {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
          })
          .then((result) => {
            setTestimonials(result?.data?.testimonials.data)
            setPerPage(result?.data?.testimonials.per_page)
            setTotal(result?.data?.testimonials?.total)
          })
          .catch((err) => console.log(err));
    }

    return (
        <NavigationLayout>
            <Router history={history}>
                <Page breadcrumbs={[{ content: "Content Management", url: "/admin/content" }]} title="List of testimonials">
                            <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap", textAlign:"center", justifyContent:"center", gap:"10px"}}>
                            {testimonials?.length > 0 ? testimonials?.map((val, key) => {
                                return <div style={{backgroundColor:"white", boxShadow:"1px 2px 5px gray", padding:"10px", borderRadius:"10px"}} key={key}>
                                    <div style={{display:"grid", gap:"10px", padding:"10px", width:"250px"}}>
                                    {val?.testimonials_file_url.split('.').pop() == 'mp4' ?<iframe frameBorder='0' src={val?.testimonials_file_url} width="100%" height="150px"/> : <img style={{width:"100%", height:"150px"}} src={val?.testimonials_file_url} alt={'testimonial file'} /> }                                     
                                        <div style={{display:"flex", flexDirection:"column"}}>
                                            <span><strong>email: </strong><div>{val?.email}</div></span>
                                            <span><strong>Full name: </strong><div>{val?.full_name}</div></span>
                                            <span><strong>Phone number: </strong><div>{val?.phone_number}</div></span>
                                        </div>
                                    </div>
                                </div>
                            }) : <div style={{padding:"10px"}}>No Testimonials found</div>}
                            </div>
                            <br/>
                            <div style={{display:"flex", gap:"20px", alignItems:"center", justifyContent:"center"}}>
                                <button style={{border:"none", cursor:"pointer", background:"transparent"}} onClick={() => setPage(page - 1)} disabled={page == 1}><Icon source={ChevronLeftMinor} color={page == 1 ? "base" : "primary"}/></button>
                                <span style={{fontWeight:"bold"}}>{page} / {total == 0 ? 1 : Math.ceil(total/perPage)}</span>
                                <button style={{border:"none", cursor:"pointer", background:"transparent"}} disabled={total <= 0 || page == Math.ceil(total/perPage)} onClick={() => setPage(page + 1)}><Icon source={ChevronRightMinor} color={page == Math.ceil(total/perPage) || total <= 0 ? "base" : "primary"}/></button>
                            </div>
                </Page>
            </Router>
        </NavigationLayout>
    );
}
export default Testimonials;
