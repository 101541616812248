import React, { useCallback, useState, useEffect } from "react";
import {
  Button,
  Modal,
  Thumbnail,
  Toast,
  Layout,
  Caption,
  DropZone,
  Stack,
  Page,
  AppProvider,
  FormLayout,
  Card,
  PageActions,
  TextStyle,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";

import {useLocation, useParams} from "react-router-dom";
import UserHeader from "../../../components/UserLayout/user-header";

function EditImpressionsAndPhotosStep4() {
  let { id } = useParams();
  let imageType = "";
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [date, setDate] = useState({
    x_ray_created_at: "",
    x_ray_updated_at: "",
    gum_score_created_at: "",
    gum_score_updated_at: ""
  });
  const {x_ray_created_at, x_ray_updated_at, gum_score_created_at, gum_score_updated_at} = date;
  const [step4UploadedBy, setstep4UploadedBy] = useState("");
  const [imageKeyXRay, setImageKeyXRay] = useState("");
  const [imagePreviewXRay, setImagePreviewXRay] = useState("");
  const [filesXRay, setFilesXRay] = useState([]);
  const handleDropZoneXRay = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesXRay((filesXRay) => [...filesXRay, ...acceptedFiles]),
    []
  );
  const fileUploadXRay = !filesXRay.length && <DropZone.FileUpload />;
  let uploadedFilesXRay = filesXRay.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesXRay[filesXRay.length - 1].name}
        source={
          validImageTypes.indexOf(filesXRay[filesXRay.length - 1].type) > 0
            ? window.URL.createObjectURL(filesXRay[filesXRay.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesXRay[filesXRay.length - 1].name}{" "}
        <Caption>{filesXRay[filesXRay.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImageXRay = useCallback(
    (filesXRay) => {
      setPopupActive(true);
      setImagePreviewXRay(
        validImageTypes.indexOf(filesXRay[filesXRay.length - 1].type) > 0
          ? window.URL.createObjectURL(filesXRay[filesXRay.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesXRay[filesXRay.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeyXRay(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesXRay[filesXRay.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesXRay]
  );

  const [imageKeyGumScore, setImageKeyGumScore] = useState("");
  const [imagePreviewGumScore, setImagePreviewGumScore] = useState("");
  const [filesGumScore, setFilesGumScore] = useState([]);
  const handleDropZoneGumScore = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesGumScore((filesGumScore) => [...filesGumScore, ...acceptedFiles]),
    []
  );
  const fileUploadGumScore = !filesGumScore.length && <DropZone.FileUpload />;
  let uploadedFilesGumScore = filesGumScore.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesGumScore[filesGumScore.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesGumScore[filesGumScore.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesGumScore[filesGumScore.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesGumScore[filesGumScore.length - 1].name}{" "}
        <Caption>{filesGumScore[filesGumScore.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImageGumScore = useCallback(
    (filesGumScore) => {
      setPopupActive(true);
      setImagePreviewGumScore(
        validImageTypes.indexOf(filesGumScore[filesGumScore.length - 1].type) >
          0
          ? window.URL.createObjectURL(filesGumScore[filesGumScore.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesGumScore[filesGumScore.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeyGumScore(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesGumScore[filesGumScore.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesGumScore]
  );

  useEffect(() => {
    // getData();
  }, []);

  function getData() {
    axios
      .get("admin/v1/pages/" + id, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {})
      .catch((err) => console.log(err));
  }

  let arrayOfKeys = [imageKeyXRay, imageKeyGumScore];
  let arrayOfStrings = ["x_ray", "gum_score"];

  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const toastMarkup = active ? (
    <Toast content="Image Deleted" onDismiss={toggleActive} />
  ) : null;
  const [isUploadingAtive, setIsUploadingAtive] = useState(false);
  const [popupActive, setPopupActive] = useState(false);
  useEffect(() => {
    axios
      .get("admin/v1/users/" + id + "/impressions&photos", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        result.data.data.step4 && 
          result.data.data.step4?.uploaded_by_user?.full_name &&
            setstep4UploadedBy(result.data.data.step4.uploaded_by_user.full_name);
        result.data.data.step4 &&
          result.data.data.step4.x_ray_url &&
          setImagePreviewXRay(result.data.data.step4.x_ray_url);
        result.data.data.step4 &&
          result.data.data.step4.gum_score_url &&
          setImagePreviewGumScore(result.data.data.step4.gum_score_url);
        const newDate = {
          x_ray_created_at: result.data.data.step4.x_ray_created_at,
          x_ray_updated_at: result.data.data.step4.x_ray_updated_at,
          gum_score_created_at: result.data.data.step4.gum_score_created_at,
          gum_score_updated_at: result.data.data.step4.gum_score_updated_at
        };
        setDate(newDate);
      })
      .catch((err) => console.log(err));
  }, []);
  const location = useLocation();
  let linkState =
    {planType: "", clinicStatus: "", clinicName: "", status: "", userId: id, pageTitle: ""};
  if (location.state !== undefined) {
    const {planType, clinicStatus, clinicName, status, userId, pageTitle} = location.state;
    linkState = {planType, clinicStatus, clinicName, status, userId, pageTitle}
  }
  const { planType, clinicStatus, clinicName, status, userId, pageTitle} = linkState;
  return (
    <Page>
        <UserHeader pageTitle={pageTitle} status={status} userId={userId} clinicStatus={clinicStatus} clinicName={clinicName} planType={planType}/>
        <br/>
        <h1 className="page-header">Edit impressions and photos - Step4</h1>
      <Layout>
        <Layout.Section>
          <Card title="X-Ray" sectioned>
            <FormLayout>
              <div style={{display: "flex"}}>
                <Thumbnail size="large" source={imagePreviewXRay}/>
                <div style={{marginLeft: "10px"}}>
                  {x_ray_created_at && (
                    <>
                      <TextStyle variation="strong">Created At:</TextStyle>
                      <p>{x_ray_created_at}</p>
                    </>
                  )
                  }
                  {x_ray_updated_at &&
                    <>
                      <TextStyle variation="strong">Updated At:</TextStyle>
                      <p>{x_ray_updated_at}</p>
                    </>
                  }
                </div>
              </div>         
              <DropZone
                onDrop={handleDropZoneXRay}
                onDropAccepted={sendImageXRay}
              >
                {uploadedFilesXRay}
                {fileUploadXRay}
              </DropZone>
              <Button id="x_ray" onClick={(e) => handleDelete(e)}>
                Delete
              </Button>
            </FormLayout>
          </Card>
        </Layout.Section>

        <Layout.Section>
          <Card title="Gum Score" sectioned>
            <FormLayout>
              <div style={{display: "flex"}}>
                <Thumbnail size="large" source={imagePreviewGumScore}/>
                <div style={{marginLeft: "10px"}}>
                  {gum_score_created_at && (
                    <>
                      <TextStyle variation="strong">Created At:</TextStyle>
                      <p>{gum_score_created_at}</p>
                    </>
                  )
                  }
                  {gum_score_updated_at &&
                    <>
                      <TextStyle variation="strong">Updated At:</TextStyle>
                      <p>{gum_score_updated_at}</p>
                    </>
                  }
                </div>
              </div>
              <DropZone
                onDrop={handleDropZoneGumScore}
                onDropAccepted={sendImageGumScore}
              >
                {uploadedFilesGumScore}
                {fileUploadGumScore}
              </DropZone>
              <Button id="gum_score" onClick={(e) => handleDelete(e)}>
                Delete
              </Button>
            </FormLayout>
          </Card>
        </Layout.Section>

        <Layout.Section>  
          <Card title="Uploaded By" sectioned>
            <TextStyle>{step4UploadedBy}</TextStyle>
          </Card>
        </Layout.Section>
      </Layout>

      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
      {toastMarkup}
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );

  function handleDelete(e) {
    const documentID = e.currentTarget.id;
    e.persist();
    axios
      .delete(
        `admin/v1/users/${id}/impressions&photos/step4/document/${documentID}/delete`,
        {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      )
      .then((result) => {
        toggleActive();
        toastImage(id);
      })
      .catch((err) => console.log(err));
  }
  function toastImage(param) {
    switch (param) {
      case "x_ray":
        setImagePreviewXRay("");
        break;
      case "gum_score":
        setImagePreviewGumScore("");
        break;
      default:
      // code block
    }
  }

  function handleSave() {
    let bodyObj = {};
    for (var i = 0; i < arrayOfKeys.length; i++) {
      if (arrayOfKeys[i] != "") {
        bodyObj[arrayOfStrings[i]] = arrayOfKeys[i];
      }
    }
    axios
      .post(`admin/v1/users/${id}/impressions&photos/step4`, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        history.push("/admin/users/" + id + "/dashboard");
      })
      .catch((err) => console.log(err));
  }
}
export default EditImpressionsAndPhotosStep4;
