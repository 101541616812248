import React, {
    useCallback,useState,useEffect,useContext,useRef,
  } from "react";
  import {
    Card,TextField,Button,TextStyle,Page,Toast,DataTable,ButtonGroup,
    Popover,ActionList,Modal,TextContainer,DisplayText,Image, Icon, 
  } from "@shopify/polaris";
  import {
    SearchMajor
  } from '@shopify/polaris-icons';
  import Pagination from '@mui/material/Pagination';
  import axios from "../axios";
  import history from "../history";
  import Cookie from "js-cookie";
  import "@zendeskgarden/react-pagination/dist/styles.css";
  import { ExportMinor, ResetMinor } from "@shopify/polaris-icons";
  import { UserContext } from "../UserContext";
  import EllipsisText from "react-ellipsis-text";
  import Select from "react-dropdown-select";
  import emptyIcon from "../img/emptyList.svg";
import Loading from "../components/Loading/loading";
import Axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
  
  export default function Payments() {
    const user = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true);
    const [isListEmpty, setIsListEmpty] = useState(false);
    const [popoverActive, setPopoverActive] = useState(false);
    const [popoverTreatmentActive, setPopoverTreatmentActive] = useState(false);
    const[perPage,setPerPage]=useState(20);
    const[max,setMax]=useState(1);
    const[page,setPage]=useState(1);
    const[loading,setLoading]=useState(false)
    const[search,setSearch]=useState("")
    const togglePopoverActive = useCallback(
      () => setPopoverActive((popoverActive) => !popoverActive),[]
    );
    const toggleTreatmentPopoverActive = useCallback(
        () => setPopoverTreatmentActive((popoverTreatmentActive) => !popoverTreatmentActive),[]
      );
    const [buttonTextPopupFilter, setButtonTextPopupFilter] = useState("All");
    const [buttonTreatmentPopupFilter, setButtonTreatmenrPopupFilter] = useState("All");
    const activator = (
      <Button onClick={togglePopoverActive} disclosure>{buttonTextPopupFilter}</Button>
    );
    const Treatmentactivator = (
        <Button onClick={toggleTreatmentPopoverActive} disclosure>{buttonTreatmentPopupFilter}</Button>
      );
    const [active, setActive] = useState(false);
    const [toastMessage, setToastMessage] = useState("Note Added Successfully");
    const toggleActive = useCallback(() => setActive((active) => !active), []);
    const toastMarkup = active ? (
      <Toast content={toastMessage} onDismiss={toggleActive} />
    ) : null;
  
    const [items, setItems] = useState([]);
    let arrayOfObjects = [];
  
    const node = useRef(null);
    const handleFocus = useCallback(() => {
      if (node.current == null) {
        return;
      }
      node.current.input.select();
      document.execCommand("copy");
    }, []);
    const [activeNote, setActiveNote] = useState(false);
    const handleChangeNote = useCallback(
      () => setActiveNote(!activeNote),[activeNote]
    );
    const [valueNote, setValueNote] = useState("");
    const [sorting, setSorting] = useState("&user_id=DESC");
    const [sortingDefaultIndex, setSortingDefaultIndex] = useState(0);
    const [optionsSalesOwner, setOptionsSalesOwner] = useState([
      { label: "Rayan Kalach", value: "Rayan Kalach" },
      { label: "Mashhadeyye", value: "Mashhadeyye" },
      { label: "Dori", value: "Dori" },
      { label: "Gaya K", value: "Gaya K" },
      { label: "Ayat Baalbaki", value: "Ayat Baalbaki" },
      { label: "Anthony T", value: "Anthony T" },
      { label: "Hussein Yehya", value: "Hussein Yehya" },
    ]);
    const [optionsStatus, setOptionsStatus] = useState([
      { label: "Delinquent", value: "Delinquent" },
      { label: "Overdue", value: "Overdue" },
      { label: "Good", value: "Good" },
      { label: "Doubtful", value: "Doubtful" },
    ]);
    const [selectedSalesOwner, setSelectedSalesOwner] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([ 
      { label: "Delinquent", value: "Delinquent" },
      { label: "Overdue", value: "Overdue" },
      { label: "Doubtful", value: "Doubtful" },
    ]);
    const [SearchValue, setSearchValue] = useState(false)
    
    useEffect(() => {
      const search = window.location.search;
      const queryParams = new URLSearchParams(search)
      const searchField = queryParams.get("filter[search]")
      const perPageField = queryParams.get("per_page")
      const pageField = queryParams.get("page")
      const userIdSorted = queryParams.get("user_id")
      const selectedStatusField = queryParams.get("client_status")
      const selectedSalesOwner = queryParams.get("filter[sales_owner]")
      if(searchField){
        setSearch(searchField)
      }
      if(perPageField){
        setPerPage(perPageField)
      }
      if(pageField){
        setPage(pageField)
      }
      if(userIdSorted){
        setSorting(userIdSorted)
      }
      if(selectedStatusField){
        const defaultSelectedArray = []
        const statusesArray = selectedStatusField.split(',')
        for(let i = 0; i < statusesArray.length; i++){
          defaultSelectedArray.push({label: statusesArray[i], value: statusesArray[i]})
        }
        setSelectedStatus(defaultSelectedArray)
      }
      if(selectedSalesOwner){
        const defaultSelectedArray = []
        const statusesArray = selectedSalesOwner.split(',')
        for(let i = 0; i < statusesArray.length; i++){
          defaultSelectedArray.push({label: statusesArray[i], value: statusesArray[i]})
        }
        setSelectedSalesOwner(defaultSelectedArray)
      }
    }, [])

    useEffect(()=>{
    let ourRequest = Axios.CancelToken.source() 
      if(page==1){
        getData(ourRequest);
      }
      else{
        setPage(1)
      }
      history.push(`?per_page=${perPage}&page=${page}&client_status=${Array.prototype.map
        .call(selectedStatus, function (item) {
          return item.label;
        })
        .join(",")}&treatment_status=${sorting}&filter[search]=${search}&filter[sales_owner]=${Array.prototype.map
        .call(selectedSalesOwner, function (item) {
          return item.label;
        })
        .join(",")}`)
    },[selectedSalesOwner,sorting,SearchValue, selectedStatus])
    useEffect(() => {
      let ourRequest = Axios.CancelToken.source() 
      getData(ourRequest);
      return () => {
        ourRequest.cancel() // <-- 3rd step
      }  
    }, [page]);
    function getData(ourRequest) {
      setIsListEmpty(false);
      setPopoverActive(false);
      setLoading(true)
      axios
        .get(
          `admin/cms_report?per_page=${perPage}&page=${page}&client_status=${Array.prototype.map
            .call(selectedStatus, function (item) {
              return item.label;
            })
            .join(",")}&treatment_status=${sorting}&filter[search]=${search}&filter[sales_owner]=${Array.prototype.map
            .call(selectedSalesOwner, function (item) {
              return item.label;
            })
            .join(",")}`,
          {
            cancelToken: ourRequest.token,
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          }
        )
        .then((result) => {
          if (result.data.users?.data.length === 0) {
            setIsListEmpty(true);
          }
          setIsLoading(false);
          setMax(result.data.users.last_page)
          arrayOfObjects = result.data.users.data;
          console.log(result.data)
            setItems(
              result.data.users.data.map((item) => [
                item.id,
                <div
                  style={{ cursor: "pointer" }}
                  id={item.id}
                  onClick={(e) => onUserClick(e)}
                >
                  {item.first_name + " " + item.last_name}
                </div>,
                <div>
                  {item.planorders[0]?.billing_address?.country}
                </div>,
                item.payment_status,
                item.client_status,
                "",//change it
                item.sales_owner?.full_name,
                
                <div
                style={{textAlign:"center" }}
                 >
                {item.number_of_payment}
                </div>,
                <div
                style={{textAlign:"center" }}
                 >
                    {item.number_of_payment-item.number_of_paid_payment}
                </div>,
                <div>{item.next_due_payment ? moment(item?.next_due_payment).format('YYYY-MM-DD') : ''}</div>,
                <div>{item?.payment_uncompleted && item?.payment_uncompleted.length > 0 ? item?.payment_uncompleted[0].days_over_due : ''}</div>,
                item.payment_notes ? (
                  <EllipsisText text={item.payment_notes} length={20} />
                ):"",
                <ButtonGroup>
                  <Button
                    id={String(item.id)}
                    onClick={(e) =>{
                      handleEditNote(e,item.payment_notes ? item.payment_notes : "")
                    }}
                  >
                  {item.payment_notes?"Edit Note":"Add Note"}  
                  </Button>
                  <Button
                    id={String(item.id)}
                    onClick={(e) => handleViewOverduesAndUpcomings(e, "All")}
                  >Payment details
                  </Button>
                </ButtonGroup>,
              ])
            );
          })
        .catch((err) => console.log(err))
        .finally(()=>{
          setLoading(false);
        });
    }
    const exportPayments=()=>{
      window.open(
        `${process.env.REACT_APP_BASE_URL}/admin/get_installment_reconciliation`
      );
  
    }

    const handleSearchClear = useCallback(() => {
      setSearch("")
    }, []);
    const handleSearchChange = useCallback((value) => {
      setSearch(value)
    }, []);
    function onUserClick(e) {
      e.ctrlKey
        ? window.open(
            "/admin/users/" + e.currentTarget.id + "/dashboard",
            "_blank"
          )
        : history.push("/admin/users/" + e.currentTarget.id + "/dashboard");
    }
    function handleEditNote(e, note) {
      user.setPaymentUserID(e.currentTarget.id);
      setValueNote(note);
      setActiveNote(true);
    }
    function handleViewOverduesAndUpcomings(e, clientValue) {
      let currentID = e.currentTarget.id;
      var json_str = JSON.stringify(
        findArrayElementById(arrayOfObjects, currentID)
      );
  
      console.log("json_str=", JSON.parse(json_str));
      user.setPaymentUserID(e.currentTarget.id);
      user.setPaymentUserName(
        findArrayElementById(arrayOfObjects, e.currentTarget.id).first_name +
          " " +
          findArrayElementById(arrayOfObjects, e.currentTarget.id).first_name
      );
      user.setPaymentObject(json_str);
      history.push(`/admin/payments/${e.currentTarget.id}`);
    }
    const loadingMarkup = isLoading ? <Loading /> : null;
    const emtyState = isListEmpty ? (
      <div className="message-no-result"
      >
        <div className="message-no-result-img">
          <Image src={emptyIcon}></Image>{" "}
        </div>
        <DisplayText size="small">No results found</DisplayText>
        <br />
      </div>
    ) : null;
    
    return (
      <Page
        fullWidth
        title="List Of Payments"
        secondaryActions={[
          {
            content: "Export",
            icon: ExportMinor,
            onAction: exportPayments,
          },
          {
            content: 'Reset filters',
            icon : ResetMinor,
            onAction:() => {
              setSearch('')
              setSelectedSalesOwner([])
              setSelectedStatus([])
            }
          }
        ]}
      >
        {" "}
        <Card sectioned>
       <div style={{position:"relative", display:"grid"}}>
      <form onSubmit={(e) => {e.preventDefault();setSearchValue(!SearchValue); setSelectedSalesOwner([])}}> <TextField placeholder="Search Users" clearButton value={search} onClearButtonClick={handleSearchClear} onChange={handleSearchChange}></TextField><span style={{position:"absolute", right:"0", top:"0", zIndex:"1412412412410"}}><Button disabled={!search} onClick={() => setSearchValue(!SearchValue)}><Icon
            source={SearchMajor}
            color="base"
          /></Button></span></form>
       </div>
        <div style={{display:"grid", gridTemplateColumns:"49.5% 49.5%", marginTop:"10px", gap:"1%"}}>
            <Select
              placeholder="Filter by sales owner"
              onChange={handleSelectSalesOwner}
              values={selectedSalesOwner.map((value) => value)}
              options={optionsSalesOwner}
              multi
          />
            <Select
              placeholder="Filter by status"
              onChange={handleSelectStatus}
              values={selectedStatus.map((value) => value)}
              options={optionsStatus}
              multi
          />
        </div>
           {loading ?
        <Loading/>:
        <div className="payment-wrapper">
            <DataTable
            columnContentTypes={["text","text","text","text","text","text","text","text",'text']}
            headings={[
              <TextStyle variation="strong">User<br></br>ID</TextStyle>,
              <TextStyle variation="strong">User<br></br>Name</TextStyle>,
              <TextStyle variation="strong">Country</TextStyle>,
              <TextStyle variation="strong">Payment<br></br>Status</TextStyle>,
              <TextStyle variation="strong">Client<br></br>Status</TextStyle>,
              <TextStyle variation="strong">Treatment<br></br>Status</TextStyle>,
              <TextStyle variation="strong">Sales<br></br>Owner</TextStyle>,
              <TextStyle variation="strong">Total<br></br>Payments</TextStyle>,
              <TextStyle variation="strong">Remaining<br></br>Payments</TextStyle>,
              <TextStyle variation="strong">Next<br></br>Due Payments</TextStyle>,
              <TextStyle variation="strong">Total<br></br>Days Overdue</TextStyle>,
              <TextStyle variation="strong">Note</TextStyle>,
              <TextStyle variation="strong"></TextStyle>,
            ]}
            rows={items}
            sortable={[true, false, false,false, false, false, false, false, false, false, true, false]}
            defaultSortDirection="descending"
            initialSortColumnIndex={sortingDefaultIndex}
            onSort={handleSort}
            verticalAlign="center"
          />
        </div>
  }
          {emtyState}
         {max >= 2 && (
          <div style={{ paddingTop: "10px", paddingBottom: "10px" ,margin:"auto"}}>
            <Pagination size='large' count={max}  boundaryCount={2}  style={{margin:"auto",width:"fit-content"}} showFirstButton showLastButton  onChange={(e,p)=>{setPage(p)}} page={Number(page)} />
          </div>
        )}
        </Card>
        {toastMarkup}
        <Modal
          open={activeNote}
          primaryAction={{
            content: "Submit",
            onAction:handleButtonSubmitNote
            
          }}
          secondaryActions={[
            {
              content:"Cancel",
              onAction:handleChangeNote,
            },
          ]}
          title="Notes"
        >
          <Modal.Section>
            <TextContainer>
              <TextField
                label="Note"
                onFocus={handleFocus}
                value={valueNote}
                onChange={(value) => {
                  setValueNote(value);
                }}
              />
            </TextContainer>
          </Modal.Section>
        </Modal>
      </Page>
    );
    
    function handleSelectSalesOwner(selectedSalesOwner) {
      setSelectedSalesOwner(selectedSalesOwner);
    }
    function handleSelectStatus(selectedSalesOwner) {
      setSelectedStatus(selectedSalesOwner);
    }
    function handleButtonSubmitNote(e) {
      let currentID = e.currentTarget.id;
      console.log(currentID);
      const bodyObj = {
        payment_notes: valueNote,
      };
      axios
        .post(`admin/v1/users/${user.paymentUserID}/payment-notes `, bodyObj, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setToastMessage("Note Added Successfully");
          setActiveNote(false);
          toggleActive();
          getData();
        })
        .catch((err) => console.log(err));
    }
  
    function handleSort(index) {
      if (index === 0) {
        if (sorting === "&user_id=DESC") {
          setSorting("&user_id=ASC");
        } else {
          setSorting("&user_id=DESC");
        }
      }
    }
  
    function findArrayElementById(array, id) {
      return array.find((element, index) => {
        return parseInt(element.id) === parseInt(id);
      });
    }
  }
  