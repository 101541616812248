import React from 'react'
import Close from '@mui/icons-material/Close';


function CaseDiscussionReply({ message,setNewMessage }) {
    if(!message){
        return (<></>)
    }
    return (
        <div style={{ borderLeft: "#8ed2dd 5px solid", backgroundColor: "#ececec", padding: "5px 0px 5px 5px", borderRadius: "5px" }}>
            <div style={{ display: "flex", flexDirection: "column", position: "relative", transition: "all" }}>
                <Close style={{ position: "absolute", right: "5px", cursor:"pointer"}} onClick={() => { setNewMessage((prev) => { return { ...prev, reply_to: null,reply:null } }) }} />
                <span style={{ color: "#8ed2dd" }}>{message.title}</span>
                <span style={{
                    color: "#5a5a5a",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "90%"
                }} dangerouslySetInnerHTML={{ __html: message.data.replace(/@(\w+(\.\w+)?)/g, '<span style="color: blue;">$&</span>').replace(/<br>/g," ") }}></span>
            </div>
        </div>
    )
}

export default CaseDiscussionReply