import React, { useState, useEffect, useRef } from "react";
import { collection, query, orderBy, onSnapshot, getDocs, doc, getDoc, where, addDoc, serverTimestamp, documentId, limit, startAfter, endBefore, getCountFromServer, getDocsFromCache, } from "firebase/firestore"
import "react-chat-elements/dist/main.css"
import moment from "moment";
import ExpandedCaseDiscussionChat from "./ExpandedCaseDiscussionChat";
import { auth, db } from "../../firebase";
import { convertDurationToDate } from "../../util/helpers";
import axios from "../../axios";
import Cookies from "js-cookie";
import { signInWithCustomToken } from "firebase/auth";
import { useStyles } from "./CaseDiscussionChatDashboardStyles";
import { CircularProgress } from "@material-ui/core";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

let pageSize = 5;

function CaseDiscussionChatDashboard() {
  const [Chat, setChat] = useState({ id: '', name: "", user_id:"",ind: -1 });
  const [admins, setAdmins] = useState(new Map());
  const [headerChat, setHeaderChat] = useState([]);
  const [chatIds, setChatIds] = useState({ chats: [], count: 0, fetch:false });
  const classes = useStyles();
  const [adminId, setAdminId] = useState(parseInt(Cookies.get('admin_id')));
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState('')
  const [buttonsLoading,setButtonLoading] = useState(false)
  const [previewImages, setPreviewImages] = useState({images:[],startIndex:0});
  const [hasMore, setHasMore] = useState(true);
  const chatToTop = () => {
    setHeaderChat((prev => {
      let arr = [...prev];
      let indexToRemove = arr.findIndex(chat => chat.chat.id === Chat.id);
      console.log('indexToRemove',indexToRemove)
      let newTopChat = arr.splice(indexToRemove,1);
      arr.unshift(newTopChat[0])
      return [...arr]
    }))
  };
  useEffect(()=>{
    console.log('ddd',headerChat)
  },[headerChat])


  const fetchLastMessagesBatched = async () => {
    const messagesRef = collection(db, 'Messages')
    const adminChatRef = collection(db, 'AdminChat')
    const userRef = collection(db, 'Users')
    const lastMessages = [];
    const chatsQuery =  query(
      collection(db, 'AdminChat'),
      where('userRefs', 'array-contains', adminId),
      orderBy('latestMessageTimestamp', 'desc'),
      limit(pageSize),
      ...(headerChat.length ? [startAfter(headerChat[headerChat.length -1].chat)] : [])
    );
    const chatSnapshots = await getDocs(chatsQuery);
    setHasMore(chatSnapshots.docs.length === pageSize);
    for(const chatSnapshot of chatSnapshots.docs) {
      const chatQuery = doc(adminChatRef, chatSnapshot.id)
      const userQuery = query(userRef, where('chat_id', '==', chatQuery), limit(1));
      const userSnapshot = await getDocs(userQuery);
      console.log(userSnapshot.docs,chatSnapshot)
      if (!userSnapshot.size) {
        return []
      }
      const messageQuery = query(messagesRef, where('chat_id', '==', chatSnapshot.id), orderBy('created_at', 'desc'), limit(1))
      let unreadChat = 0;
      let chatData = chatSnapshot.data();
      let found = false;
      if ('seen' in chatData && chatData.seen.length) {
        let seenChat = [...chatData.seen]
        let index = seenChat.findIndex(admin => admin.adminId == adminId)
        if (index >= 0) {
          found = true;
          let lastMessageSeen = seenChat[index].when;
          let newMessagesQuery = query(messagesRef, where('chat_id', '==', chatSnapshot.id), where('created_at', '>', lastMessageSeen))
          let count = await getCountFromServer(newMessagesQuery)
          unreadChat = count.data().count
        }
      }
      if(!found){
        let newMessagesQuery = query(messagesRef, where('chat_id', '==', chatSnapshot.id))
        let count = await getCountFromServer(newMessagesQuery)
        unreadChat = count.data().count
      }
      let obj = { user: userSnapshot.docs[0].data(), unreadMessages: unreadChat,  }
      const messageSnapshot = await getDocs(messageQuery);
      if (!messageSnapshot.empty) {
        const lastMessage = messageSnapshot.docs[0];
        obj.lastMessage = lastMessage.data();
        obj.chat = chatSnapshot;
        lastMessages.push(obj);
      }
    }
    return lastMessages;
  }
  useEffect(() => {
    let firebasePromise;
    let firebaseSignIn = false;
    if (!auth.currentUser && process.env.REACT_APP_FIREBASE_V2) {
      firebasePromise = axios.get(`/admin/v1/firebase/agentauth`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token")
        }
      })
      firebaseSignIn = true;
    } else {
      firebasePromise = Promise.resolve();
    }
    firebasePromise.then(async (res) => {
      let admin;
      if (firebaseSignIn) {
        let { token } = res.data
        admin = res.data.adminId;
        let res2 = await signInWithCustomToken(auth, token);
        setToken(token)
      } else {
        admin = parseInt(Cookies.get('admin_id'));
      }
      setAdminId(admin)
      let adminRef = "";
      let collectionRef = collection(db, 'Admins')
      let q = query(collectionRef, where('admin_id', '==', admin), limit(1))
      let snapshot = await getDocs(q)
      console.log(snapshot.docs)
      if (!snapshot.size) {
        setLoading(false)
        return;
      }
      let adminColec = collection(db, 'Admins')
      let adminDocs = await getDocs(adminColec);
      let admins = new Map()
      adminDocs.forEach(admin => {
        let adminData = admin.data()
        admins.set(adminData.admin_id, adminData.name)
        agents.push({ id: adminData.admin_id, display: adminData.name.replaceAll(" ", ".") })
      })
      setAdmins(admins)
      setAgents(agents)
      // setChatIds({ chats: adminData.chats.reverse(), count: 5,fetch: true })
    })
  }, [])
  useEffect(() => {
    handleLoadMore()
  }, [])

  const handleLoadMore = () => {
    setLoading(true)
    fetchLastMessagesBatched().then((res) => {
      setHeaderChat((prev) => [...prev, ...res])
      setLoading(false)
    })
  }
  const setActiveChat = (ind) => {
    setChat({ id: headerChat[ind].chat.id, name: headerChat[ind].user.name, user_id: headerChat[ind].user.user_id, ind })
    setHeaderChat((prev => {
      let arr = [...prev];
      arr[ind].unreadMessages = 0;
      return [...arr]
    }))
  }
  const leaveChat = async()=>{
    setButtonLoading(true)
    let body = {
      chatId: Chat.id
    }
    let indexToRemove = Chat.ind
    // setChatIds((prev)=>{
    //   let arr = [...prev.chats]
    //   arr.splice(indexToRemove,1);
    //   return ({ chats:[...arr], count: prev.count - 1,fetch: false })
    // }
    // )
    setHeaderChat((prev)=>{
      let arr = [...prev]
      arr.splice(indexToRemove,1);
      return [...arr]
    }
    )
    setChat({ id: '', name: "", user_id:"",ind: -1 })
    await axios.post(`/admin/v1/firebase/leaveChat`,body, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token")
      }
    })
    setButtonLoading(false)
  }
  const openCMSProfile = () =>{
    window.open("/admin/users/" + Chat.user_id+ "/dashboard")
  }

  return (
    <div className={classes.parent} >
      <div className={classes.chatMenu} >
        <div  className={classes.menuHeader} >
          <span className={classes.menuHeaderTitle}>All Discussions</span>
        </div>
        <div className={classes.menuItemsParent}>
          <div className={`${loading? classes.loadingBox : ""}`} >
          {loading ? <CircularProgress className={classes.loading} />: <></>}
          <div className={classes.chatItemsParent} >
            {headerChat.map((chat, ind) => {
              let message = '';
              let messageData = chat.lastMessage.data;
              let lastMessageTime = moment(convertDurationToDate(chat.lastMessage.created_at)).format('DD-MM-yy')
              if (messageData.length) {
                let index = messageData.findIndex(message=>message.type != 'text')
                if (index>=0) {
                  message = 'Attachment'
                } else {
                  message = messageData[0].data
                }
              }
              return (
                <div className={classes.menuItem} style={{ backgroundColor: chatIds.chats[ind] == Chat.id ? "#e0f9fb" : "initial",cursor:"pointer",position:"relative" }} key={ind}
                  onClick={() => { if(!buttonsLoading){setActiveChat(ind)} }}>
                  <div className={classes.menuItemHeader}>
                    <div className={classes.chatName}>{chat.user.name} {chat?.user?.user_id ? `(ID: ${chat?.user?.user_id})` : ''}</div>
                    <span className={classes.chatLastMessageTime} >{lastMessageTime}</span>
                  </div>
                  <div className={classes.menuItemBody}>
                    <div className={classes.chatLastMessage} >{chat.lastMessage.admin_id === adminId ? "Me" : admins.get(chat.lastMessage.admin_id)}: <span dangerouslySetInnerHTML={{ __html: message.replace(/@(\w+(\.\w+)?)/g, '<span style="color: blue;">$&</span>').replace(/<br>/g,' ') }}></span></div>
                    {chat.unreadMessages ? <span className={classes.chatUnreadMessages}>{chat.unreadMessages}</span> : <></>}
                  </div>
                  {ind<headerChat.length-1 ?<div style={{borderBottom: "1px solid rgb(97 97 102 / 17%)",position: "absolute",bottom: 0,left: "15%",right: "15%"}}></div>:null}
                </div>
              );
            }
            )}
            {hasMore? <button className={classes.loadMoreChat} onClick={handleLoadMore} disabled={loading}><u>Load More</u></button> : null}
          </div>
        </div>
        </div>
      </div>
      <div className={classes.expandedChat}>
        <div className={classes.expandedChatMenu}>
          <span className={classes.chatTitle} >{Chat.name && Chat.user_id ? `Deal : ${Chat.name} (ID: ${Chat?.user_id})` : 'Choose a Discussion'}</span>
          {Chat.user_id ?<div className={classes.chatButtons}>
              <button className={classes.cmsButton} onClick={leaveChat} disabled={buttonsLoading}>Leave Chat</button>
              <button className={classes.cmsButton} onClick={openCMSProfile}>CMS Profile</button>
          </div>:null}
        </div>
        {Chat.id ? <div className={classes.chatBody}><ExpandedCaseDiscussionChat ActiveChat={Chat} adminId={adminId} admins={admins} agents={agents} username={Chat.name} setPreviewImages={setPreviewImages} token={token} chatToTop={chatToTop} /></div> : <div className={classes.emptyChatBody}></div>}
      </div>
      {previewImages.images.length ? <div ><Lightbox startIndex={previewImages.startIndex} onClose={() => setPreviewImages({images:[],startIndex:0})} images={previewImages.images.map((val) => val.data !== null && process.env.REACT_APP_AWS_URL+val.data)}  ></Lightbox></div> : <></>}
    </div>

  );
};


export default CaseDiscussionChatDashboard