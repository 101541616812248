import { Button, Icon } from "@shopify/polaris";
import freshsalesIcon from "../../img/freshsales.svg";
import React, { useState } from "react";
import { ExternalMinor } from "@shopify/polaris-icons";
import axios from "../../axios";
import Cookies from "js-cookie";

const CrmFreshdeskTicket = ({ userId }) => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const getUserFSProfile = () => {
    setLoading(true);
    if(errorMsg) {
      setErrorMsg('');
    }
    axios.get(`admin/v1/users/${userId}/fs-profile`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then(res => {
      if(res.data.success){
        window.open(res.data.crm_link);
      } else {
        setErrorMsg(res.data.message);
      }
    }).catch(error => {
      console.log('FS profile error', error);
    }).finally(f => {
      setLoading(false);
    })
  }

  return (
    <div>
      <Button icon={ExternalMinor} onClick={getUserFSProfile} loading={loading} disabled={loading}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "2px",
          }}
        >
          CRM Profile
          {!loading &&
            <img
              alt="Freshsales Icon"
              src={freshsalesIcon}
              style={{ marginLeft: "5px" }}
            />
          }
        </div>
      </Button>
      {errorMsg && <p style={{marginTop: 5, color: 'red'}}>{errorMsg}</p>}
    </div>
  );
};

export default CrmFreshdeskTicket;
