import React, { useState, useEffect } from "react";
import {
  InlineError,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  Card,
  PageActions,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";
import { Editor } from "@tinymce/tinymce-react";
import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.
import "@pathofdev/react-tag-input/build/index.css";

import { useParams } from "react-router-dom";

function EditComment() {
  var { userId, commentId } = useParams();

  const [valueComment, setvalueComment] = useState(
    Cookie.get("clickedComment")
  );
  const handleChangeComment = (e) => {
    setvalueComment(e);
  };
  const [fieldRequiredComment, setfieldRequiredComment] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  useEffect(() => {
    axios
      .get(`admin/v1/users/${userId}/comments/${commentId}/show`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        result?.data?.data?.comment &&
          setvalueComment(result.data.data.comment);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <AppProvider>
      <Page
        title="Edit Comment"
        breadcrumbs={[
          {
            content: "Dashboard",
            url: "/admin/users/" + userId + "/dashboard",
          },
        ]}
      >
        <Card sectioned>
          <FormLayout>
            <TextStyle variation="strong">Comment</TextStyle>
            <Editor
              apiKey="z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve"
              value={valueComment}
              init={{
                branding: false,
                height: 200,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
              }}
              onEditorChange={handleChangeComment}
            />

            {fieldRequiredComment}
          </FormLayout>
        </Card>
        <PageActions
          primaryAction={{
            content: "Save",
            onClick: handleSave,
          }}
          secondaryActions={[
            {
              content: "Delete",
              onClick: () =>
                axios
                  .delete(
                    "admin/v1/users/" +
                      userId +
                      "/comments/" +
                      commentId +
                      "/delete",
                    {
                      headers: {
                        Authorization: "Bearer " + Cookie.get("token"),
                      },
                    }
                  )
                  .then((result) => {
                    history.push("/admin/users/" + userId + "/dashboard");
                  })
                  .catch((err) => console.log(err)),
            },
          ]}
        />
      </Page>
    </AppProvider>
  );

  function handleSave() {
    if (valueComment === "<p>[edited]</p>"||valueComment === "" || valueComment === "<p>[edited]</p>\n<p>&nbsp;</p>" ) {
      setfieldRequiredComment(
        <InlineError message="This field is required" fieldID="myFieldID" />
      );
    } else {
      let bodyObj;
      if(valueComment.startsWith(["<p>[edited]"])){
       bodyObj = {
          comment:valueComment
        };
      }else{
      bodyObj = {
        comment: `[edited] ${valueComment}`,
      };
    };

      axios
        .post("admin/v1/users/" + userId + "/comments/" + commentId, bodyObj, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          history.push("/admin/users/" + userId + "/dashboard");
          //  history.push('/admin/faqsupport/viewCategory')
        })
        .catch((err) => console.log(err));
    }
  }
}
export default EditComment;
