import React, { useState, useEffect } from "react";
import {
  InlineError,
  TextStyle,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
} from "@shopify/polaris";
import axios from "../../../../axios";
import Cookie from "js-cookie";
import history from "../../../../history";
import {useLocation, useParams} from "react-router-dom";
import UserHeader from "../../../../components/UserLayout/user-header";

function EditBilling() {
  let { id } = useParams();
  const [valueName, setValueName] = useState("");
  const [valueAddress, setValueAddress] = useState("");
  const [valueAppartement, setValueAppartement] = useState("");
  const [valueCity, setValueCity] = useState("");
  const [valueCountry, setValueCountry] = useState("");
  const [valuePostalCode, setValuePostalCode] = useState("");
  const [valuePhone, setValuePhone] = useState("");
  const [valueNote, setValueNote] = useState("");
  const [valueEmail, setValueEmail] = useState("");

  const [fieldRequiredFN, setFieldRequiredFN] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredA, setFieldRequiredA] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredCity, setFieldRequiredCity] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredCountry, setFieldRequiredCountry] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredP, setFieldRequiredP] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredE, setFieldRequiredE] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  console.log("aaa");

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    axios
      .get(`admin/v1/users/${id}/billing`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        result?.data?.data?.full_name &&
          setValueName(result.data.data.full_name);
        result?.data?.data?.full_name &&
          setValueAddress(result.data.data.address);
        result?.data?.data?.apartment &&
          setValueAppartement(result.data.data.apartment);
        result?.data?.data?.city && setValueCity(result.data.data.city);
        result?.data?.data?.city && setValueCountry(result.data.data.city);
        result?.data?.data?.postal_code &&
          setValuePostalCode(result.data.data.postal_code);
        result?.data?.data?.phone && setValuePhone(result.data.data.phone);
        result?.data?.data?.note && setValueNote(result.data.data.note);
      })
      .catch((err) => console.log(err));
  }
  const location = useLocation();
  let linkState =
      {planType: "", clinicStatus: "", clinicName: "", status: "", userId: id, pageTitle: ""};
  if (location.state !== undefined) {
    const {planType, clinicStatus, clinicName, status, userId, pageTitle} = location.state;
    linkState = {planType, clinicStatus, clinicName, status, userId, pageTitle}
  }
  const { planType, clinicStatus, clinicName, status, userId, pageTitle} = linkState;

  return (
    <Page>
      <UserHeader pageTitle={pageTitle} status={status} userId={userId} clinicStatus={clinicStatus} clinicName={clinicName} planType={planType}/>
      <br/>
      <h1 className="page-header">Edit Billing</h1>
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Full name</TextStyle>
          <TextField value={valueName} onChange={handleChangeName} />
          {fieldRequiredFN}
          <TextStyle variation="strong">Address</TextStyle>
          <TextField value={valueAddress} onChange={handleChangeAddress} />
          {fieldRequiredA}
          <TextStyle variation="strong">Appartement</TextStyle>
          <TextField
            value={valueAppartement}
            onChange={handleChangeAppartement}
          />

          <TextStyle variation="strong">City</TextStyle>
          <TextField value={valueCity} onChange={handleChangeCity} />
          {fieldRequiredCity}
          <TextStyle variation="strong">Country</TextStyle>
          <TextField value={valueCountry} onChange={handleChangeCountry} />
          {fieldRequiredCountry}
          <TextStyle variation="strong">Postal code</TextStyle>
          <TextField
            value={valuePostalCode}
            onChange={handleChangePostalCode}
          />

          <TextStyle variation="strong">Phone</TextStyle>
          <TextField value={valuePhone} onChange={handleChangePhone} />
          {fieldRequiredP}
          <TextStyle variation="strong">Note</TextStyle>
          <TextField value={valueNote} onChange={handleChangeNote} />
          <TextStyle variation="strong">Email</TextStyle>
          <TextField value={valueEmail} onChange={handleChangeEmail} />
          {fieldRequiredE}
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
    </Page>
  );
  function handleChangeName(valueName) {
    setValueName(valueName);
  }
  function handleChangeAddress(valueAddress) {
    setValueAddress(valueAddress);
  }
  function handleChangeAppartement(valueAppartement) {
    setValueAppartement(valueAppartement);
  }
  function handleChangeCity(valueCity) {
    setValueCity(valueCity);
  }
  function handleChangeCountry(valueCountry) {
    setValueCountry(valueCountry);
  }
  function handleChangePostalCode(valuePostalCode) {
    setValuePostalCode(valuePostalCode);
  }
  function handleChangePhone(valuePhone) {
    setValuePhone(valuePhone);
  }
  function handleChangeNote(valueNote) {
    setValueNote(valueNote);
  }
  function handleChangeEmail(valueEmail) {
    setValueEmail(valueEmail);
  }
  function handleSave() {
    if (
      valueName === "" ||
      valueAddress === "" ||
      valueCity === "" ||
      valueCountry === "" ||
      valuePhone === "" ||
      valueEmail === ""
    ) {
      if (valueName === "")
        setFieldRequiredFN(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueAddress === "")
        setFieldRequiredA(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueCity === "")
        setFieldRequiredCity(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueCountry === "")
        setFieldRequiredCountry(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valuePhone === "")
        setFieldRequiredP(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueEmail === "")
        setFieldRequiredE(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
    } else {
      const bodyObj = {
        full_name: valueName,
        address: valueAddress,
        apartment: valueAppartement,
        city: valueCity,
        country: valueCountry,
        postal_code: valuePostalCode,
        phone: valuePhone,
        note: valueNote,
        email: valueEmail,
      };
      axios
        .post(`admin/v1/users/${id}/billing`, bodyObj, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          history.push(`/admin/users/${id}/dashboard/legacy-files`);
          //  history.push('/admin/faqsupport/viewCategory')
        })
        .catch((err) => console.log(err));
    }
  }
}
export default EditBilling;
