import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  InputLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import moment from "moment";
import SelectDatePicker from "@netojose/react-select-datepicker";
import axios from "../axios";
import { Card, FormLayout, TextStyle } from "@shopify/polaris";
import ProcedureHeader from "./ProcedureHeader";
import Cookies from "js-cookie";
import Review from "./Review";
import Save from "./Save";
import { FormControl, MenuItem, Select } from "@mui/material";
import '../assets/styles/information_styles.css'
import TreatmentApproach from "./TreatmentApproach";
import AdditionalInstructions from "./AdditionalInstructions";
import InformationCheckboxGroup from "./InformationCheckboxGroup";
import InformationRadioButtonGroup from "./InformationRadioButtonGroup";
import { clinicalFindings } from "../util/constants";

const useStyles = makeStyles(() => {
  return {
    photoWrapper: {
      padding: '2rem',
      boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    },
    procedureOptionsWrapper: {
      padding: '1rem'
    },
    saveBtn: {
      display: 'block',
      marginTop: '1rem',
      marginLeft: 'auto',
      borderRadius: '4.7px',
      color: '#fff',
      backgroundColor: '#FF2C46',
      '&:hover': {
        backgroundColor: '#FF2C46',
      },
    },
    btn: {
      marginLeft: 'auto',
      borderRadius: '4.7px',
      color: '#fff',
      backgroundColor: '#FF2C46',
      '&:hover': {
        backgroundColor: '#FF2C46',
      },
    },
    procWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '1rem',
    },
    add: {
      backgroundColor: '#8EE2ED',
      color: '#fff',
    },
    errorMsg: {
      margin: '1rem 0',
      color: 'red',
    },
    loadingBox: {
      margin: '0 auto',
      textAlign: 'center',
      padding: 30,
      color: '#FF2C46',
    },
    loading: {
      textAlign: 'center',
      margin: '0 auto',
      color: '#ff2c46',
    },
    textarea: {
      width: '100%',
      border: 'none',
      resize: 'none',
      backgroundColor: 'rgba(211,215,219,0.25)',
      color: '#939393',
      padding: '0.8rem',
      '&:focus': {
        outline: 'none',
      },
    },
    flexBox: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '0.5rem',
    },
    fullWidth: {
      width: '100%',
    },
    dateSelectoreWidth: {
      width: '100%',
    },
    flex: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      color: '#363636',
      marginBottom: 10,
      fontSize: '13px',
    },
    label: {
      color: '#363636',
      fontSize: '13px',
    },
    bigTitle: {
      fontSize: '16px'
    },
    findingsTitlesBox: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '1rem',
    },
    findingsTitle: {
      fontSize: '13px',
      color: '#363636',
      width: '35%',
    },
    findingsTitle2: {
      width: '60%',
    },
    desc: {
      color: "#4e4e4e",
      fontSize: "13px !important",
      fontWeight: 100,
    },
    findingsBox: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '1rem',
    },
    marginTop: {
      marginTop: '3rem',
    },
    findingBox: {
      display: 'flex',
      columnGap: '1rem',
      justifyContent: 'space-between',
      alignItems: 'baseline',
    },
    colTitles: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    colTitle: {
      width: '38%',
      color: "#4e4e4e",
      fontSize: "13px !important",
      fontWeight: 100,
    },
    redColTitle: {
      width: '60%',
      color: "red",
      fontSize: "13px !important",
      textDecoration: 'underline',
      fontWeight: 100,
    },
    boldRedColTitle: {
      fontWeight: 'initial',
    },
    textField: {
      width: '60%',
      backgroundColor: "rgba(211,215,219,0.25)",
      color: "#939393",
      padding: "0 0.2rem",
      outline: "none",
    },
    errorBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '2rem',
    },
  };
});

export default function AppointmentInformation({
  userId,
  appointmentId,
  appointmentType,
  handleTabsExpand,
  index,
  openCloseTabs,
  setDisable,
  scrollToExpandedTab,
}) {
  const classes = useStyles();
  const [information, setInformation] = useState(null);
  const [data, setData] = useState(null);
  const [infoCheckboxes, setInfoCheckboxes] = useState([]);
  const [infoDetails, setInfoDetails] = useState({
    gender: '',
    dentition_type: 'permanent',
    treatment_approach: 'full-arch',
    dental_conditions: {
      spacing: false,
      crossbite: false,
      deepBite: false,
      crowding: false,
      openBite: false,
    },
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [callHandleSave, setCallHandleSave] = useState(0);
  const [viewProceduresOptions, setViewProcedureOptions] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    problem_question: '',
    dental_history: '',
    list_of_procedures: '',
    unqualifiedReasonDetailsRequiredMsg: '',
    general: '',
  });
  const [teethIds, setTeethIds] = useState({
    upper: [],
    lower: [],
    no_anterior_attachments: false,
    no_attachments: false,
  });
  const [cannotProceed, setCannotProceed] = useState(null);
  const optionsHealthy = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
  ];
  const optionsWisdomTeeth = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'No' },
    { label: 'i don\'t know', value: 'i don\'t know' },
  ];
  const optionsArches = [
    { label: 'Both', value: 3 },
    { label: 'Top', value: 1 },
    { label: 'Bottom', value: 2 },
  ];
  const procedures = {
    CLEANING : 'Cleaning',
  };
  const options = [
    { label: 'Qualified Top', value: 2 },
    { label: 'Qualified Bottom', value: 3 },
    { label: 'Qualified Both', value: 1 },
    { label: 'Not Qualified', value: 0 },
  ];
  const reasonOptions = [
    {label: 'Lead Stage - Underage', value: 'Lead Stage - Underage'},
    {label: 'Lead Stage - Difficult Case', value: 'Lead Stage - Difficult Case' },
    {label: 'Lead Stage - Unhealthy Teeth/Gums', value: 'Lead Stage - Unhealthy Teeth/Gums' },
    {label: 'Lead Stage - Country Not Covered', value: 'Lead Stage - Country Not Covered' },
    {label: 'Data Stage - Difficult Case', value: 'Data Stage - Difficult Casee'},
    {label: 'Data Stage - Incomplete Info For TP', value: 'Data Stage - Incomplete Info For TP'},
    {label: 'TP Stage - Difficult Case', value: 'TP Stage - Difficult Case'},
    {label: 'TP Stage - Incomplete Info For TP', value: 'TP Stage - Incomplete Info For TP'},
  ];
  const gender = [
    {
      value: 'male',
      label: 'Male',
    },
    {
      value: 'female',
      label: 'Female',
    },
  ];

  const handleToothClick = (e, prop) => {
    if(prop === 'no_anterior_attachments') {
      setTeethIds((prev) => ({
        ...prev,
        no_attachments: false,
        [prop]: !prev[prop],
        lower: [],
        upper: [],
      }));
      return;
    }
    if(prop === 'no_attachments') {
      setTeethIds((prev) => ({
        ...prev,
        no_anterior_attachments: false,
        [prop]: !prev[prop],
        lower: [],
        upper: [],
      }));
      return;
    }
    if (e.target.tagName !== 'path') {
      return;
    }

    if(teethIds.no_attachments || teethIds.no_anterior_attachments) {
      setTeethIds((prev) => ({
        ...prev,
        no_attachments: false,
        no_anterior_attachments: false,
      }));
    }

    let target = e.target;
    setTeethIds((prev) => {
      const newPropArray = prev[prop].includes(target.id)
        ? prev[prop].filter(id => id !== target.id)
        : [...prev[prop], target.id];
  
      target.style.fill = newPropArray.includes(target.id) ? '#fea3a3' : 'white';
  
      return {
        ...prev,
        [prop]: newPropArray
      };
    });
  };

  const onTagClick = (prop, id) => {
    setTeethIds((prev) => ({
      ...prev,
      [prop]: prev[prop].filter(item => item !== id)
    }));
    document.getElementById(id).style.fill = 'white';
  };

  const handleRadioButtonClick = (group, value) => {
    if(group === 'dental_conditions') {
      setInfoDetails(prev => ({
        ...prev,
        dental_conditions: {
          // enable/disable checkboxes based on the selected value
          spacing: value.find(element => element.value === 'spacing') ? true : false,
          crossbite: value.find(element => element.value === 'crossbite') ? true : false,
          deepBite: value.find(element => element.value === 'deepBite') ? true : false,
          crowding: value.find(element => element.value === 'crowding') ? true : false,
          openBite: value.find(element => element.value === 'openBite') ? true : false,
        }
      }));
      return;
    }
    let newRadioGroupTypes = {
      ...infoDetails,
      [group]: value,
    };
    if(group !== 'gender') {
      newRadioGroupTypes = {
        ...newRadioGroupTypes,
        dental_conditions: {
          spacing: false,
          crossbite: false,
          deepBite: false,
          crowding: false,
          openBite: false,
        },
      };
    }
    if(group === 'dentition_type') {
      newRadioGroupTypes = {
        ...newRadioGroupTypes,
        treatment_approach: 'full-arch',
      }
    }

    setInfoDetails(newRadioGroupTypes);
  }

  const handleCannotProceedChange = (e) => {
    setCannotProceed(e.target.value == 1);
    if(e.target.value == 0) {
      let clinicalFindings = data?.data?.clinical_findings_v2 || [];
      clinicalFindings.filter(f => f.procedures).forEach(finding => {
        delete finding.procedures;
      });
      setData((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          clinical_findings_v2: clinicalFindings,
        }
      }));
    }
  }

  const handleChange = (value, type) => {
    if (type === 'problem_question' || type === 'dental_history') {
      setErrorMsg((prev) => ({
        ...prev,
        [type]: '',
      }));
    }
    setSuccess(false);
    if((type == 'visited_dentist' && value === 0) || (type == 'wisdom_teeth' && value !== 'yes')){
      if(type == 'wisdom_teeth'){
        setData((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            [type]: value,
            wisdom_need_removal: '',
          }
        }));
      } else {
        setData((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            [type]: value,
            teeth_healthy: '',
          }
        }));
      }
    } else {
      setData((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          [type]: type === 'date_of_birth' ? moment(value).format('YYYY-MM-DD') : value,
        }
      }));
    }
  };

  const handleChangeV2 = (value, condition, type, subType) => {
    if(type === 'clinical_findings_v2' ) {
      if(subType === 'condition' && value) {
        setErrorMsg((prev) => ({
          ...prev,
          dental_history: '',
        }));
      }
      let clinicalFindings = data?.data?.clinical_findings_v2 || [];
      if(subType === 'condition') {
        if(value) {
          clinicalFindings.push({ condition });;
        } else {
          clinicalFindings = clinicalFindings.filter(finding => finding.condition !== condition);
        }
        setData((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            clinical_findings_v2: clinicalFindings,
          }
        }));
        return;
      }

      let index = clinicalFindings.findIndex(finding => finding?.condition === condition);
      clinicalFindings[index] = {
        ...clinicalFindings[index],
        [subType]: value,
      };
      setData(prev => {
        prev.data.clinical_findings_v2 = clinicalFindings;
        return {...prev}
      });
    }
  };

  const hanldeBookProceduresArray = (value) => {
    if(data?.procedures_array && data?.procedures_array.includes(value)){
      let array = data?.procedures_array.filter((item)=> item != value);
      setData(prev => ({
        ...prev,
        procedures_array: array,
      }));
    }else{
      setData((prev) => ({
        ...prev,
        procedures_array: [...prev?.procedures_array, value],
      }));
    }
  }

  const handleChangeProc = (value, i, type) => {
      setErrorMsg((prev) => ({
        ...prev,
        [type]: '',
      }));
      setData(prev => {
        prev.data[type][i] = value;
        return {...prev}
      })
  };

  const addRow = () => {
    setData(prev => ({
      ...prev,
      data: {
        ...prev.data,
        list_of_procedures: [...prev.data.list_of_procedures, '']
      }
    }))
  }

  const handleSave = () => {
    let errMsg = {
      problem_question: '',
      dental_history: '',
      list_of_procedures: '',
      unqualifiedReasonDetailsRequiredMsg: '',
      general: '',
    };
    setErrorMsg(errMsg);
    let listOfProcedures = [];
    if(data?.data?.clinical_findings_v2?.length > 0 && !data.data.clinical_findings_v2.find(f => f.condition === 'none')) {
      if(cannotProceed === null) {
        errMsg = {
          ...errMsg,
          general: 'Please indicate if the patient can or cannot proceed with the aligner treatment without doing pre-treatment procedures.',
        };
        setErrorMsg(errMsg);
        return;
      }
      if(cannotProceed && data.data.clinical_findings_v2.every(f => !f.procedures)) {
        errMsg = {
          ...errMsg,
          general: 'At least one text field must be filled',
        };
        setErrorMsg(errMsg);
        return;
      }
    }
    if (data?.data?.list_of_procedures) {
      listOfProcedures = data?.data?.list_of_procedures.filter((proc) => {
        if (proc) {
          return proc;
        }
      });
    }
    if((data?.mandatory && (!data?.data?.problem_question || (!data?.data?.dental_history && !data?.data?.clinical_findings_v2?.length))) || (data?.data?.dental_history_na === 1 && listOfProcedures.length === 0)) {
      if (data?.mandatory) {
        if(!data?.data?.problem_question) {
          errMsg = {
            ...errMsg,
            problem_question: 'Required field',
          };
        } else {
          errMsg = {
            ...errMsg,
            dental_history: 'Required field',
          };
        }
      }
      if (data?.data?.dental_history_na === 1 && listOfProcedures.length === 0) {
        errMsg = {
          ...errMsg,
          list_of_procedures: 'Please add a procedure',
        };
      }
      setErrorMsg(errMsg);
    } else {
      setLoading(true);
      let details = null;
      let info = infoDetails;
      if(information?.show_new_info_ui) {
        details = {};
        if(Object.values(info.dental_conditions).every(x => x === false)) {
          details = {
            dentition_type: info.dentition_type,
            treatment_approach: info.treatment_approach,
          }
        } else {
          if(!info.gender) {
            delete info.gender;
          }
          details = {
            ...info,
            dental_conditions: Object.keys(info.dental_conditions).filter(key => info.dental_conditions[key]),
          }
        }
        if(teethIds.no_anterior_attachments) {
          details = {
            ...details,
            avoid_attachments: {
              no_anterior_attachments: teethIds.no_anterior_attachments,
            }
          }
        } else {
          if(teethIds.no_attachments) {
            details = {
              ...details,
              avoid_attachments: {
                no_attachments: teethIds.no_attachments,
              }
            }
          } else {
            if(teethIds.upper.length > 0) {
              details = {
                ...details,
                avoid_attachments: {
                  ...details.avoid_attachments,
                  upper: teethIds.upper,
                }
              }
            }
            if(teethIds.lower.length > 0) {
              details = {
                ...details,
                avoid_attachments: {
                  ...details.avoid_attachments,
                  lower: teethIds.lower,
                }
              }
            }
          }
        }
      }
      let body = {
        ...data.data,
        list_of_procedures: listOfProcedures,
        procedure: appointmentType?.procedure,
        user_scan_info_id: appointmentId,
        procedures_array: data?.procedures_array,
      };
      if(information?.show_new_info_ui) {
        body = {
          ...body,
          details,
        };
      }
      if (body) {
        axios.post(`admin/v2/users/${userId}/case-record-information`, body, {
          headers: {
            Authorization: 'Bearer ' + Cookies.get('token'),
          },
        }).then((res) => {
          if (res.data.success) {
            handleDataFetched();
            setSuccess(true);
          }
        }).catch((err) => {
          setSuccess(false);
          console.log(err);
        }).finally(() => {
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }
  };

  const handleReviewStatus = (status) => {
    if(status){
      const form_data = new FormData();
      form_data.append('review_status', status);
      form_data.append('appointment_id', appointmentId);
      form_data.append('procedure', appointmentType?.procedure);
      axios.post(`/admin/v2/users/case-record-information/${information?.data?.id}/approval-status`, form_data, {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('token'),
        },
      }).then((res) => {
        handleDataFetched();
      }).catch((err) => {
        console.log(err);
      })
    }
  };

  const handleDataFetched = () => {
    setLoading(true);
    axios.get(`admin/v2/users/case-record-information/${appointmentId}?procedure=${appointmentType?.procedure}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then(res => {
      if (res.data.success) {
        setInformation(res.data);
        setDisable(res.data.patient_data_locked);
      }
    }).catch(err => {
      console.log('fetch error', err);
    }).finally(f => {
      setLoading(false);
    })
  };

  const handleChangeData = (value) => {
    setViewProcedureOptions(value?.procedures_array?.length > 0);
    if(value?.data){
      let findingsV2 = value.data.clinical_findings_v2;
      if(findingsV2?.length) {
        setCannotProceed(findingsV2.find(f => f.procedures) ? true : false);
      }
      setData({
        ...value,
        data: {
          ...value.data,
          date_of_birth: value.data?.date_of_birth ? new Date(value.data?.date_of_birth) : null,
          dental_history_na: value.data?.dental_history_na === 1,
          list_of_procedures: value.data?.list_of_procedures
            ? value.data?.list_of_procedures.length > 0
              ? value.data?.list_of_procedures
              : ['']
            : [''],
          visited_dentist: (value.data?.visited_dentist !== null && value.data?.visited_dentist !== undefined) ? (value.data?.visited_dentist === true ? 1 : 0) : "",
          teeth_healthy: (value.data?.teeth_healthy !== null && value.data?.teeth_healthy !== undefined) ? (value.data?.teeth_healthy === true ? 1 : 0) : "",
          wisdom_need_removal: (value.data?.wisdom_need_removal !== null && value.data?.wisdom_need_removal !== undefined) ? (value.data?.wisdom_need_removal === true ? 1 : 0) : "",
        }
      });
      if(value.data?.details) {
        let dentalConditions = {
          ...infoDetails.dental_conditions,
        };
        if(value.data.details?.dental_conditions) {
          value.data.details.dental_conditions.forEach(condition => {
            dentalConditions[condition] = true;
          });
        }
        let details = {
          gender: value.data.details?.gender ? value.data.details?.gender : infoDetails.gender,
          dentition_type: value.data.details?.dentition_type ? value.data.details.dentition_type : infoDetails.dentition_type,
          treatment_approach: value.data.details?.treatment_approach ? value.data.details.treatment_approach : infoDetails.treatment_approach,
          dental_conditions: dentalConditions,
        };
        setInfoDetails(details);
        if(value.data.details.avoid_attachments) {
          if(value.data.details.avoid_attachments.no_anterior_attachments) {
            setTeethIds({
              upper: [],
              lower: [],
              no_attachments: false,
              no_anterior_attachments: value.data.details.avoid_attachments.no_anterior_attachments,
            });
          } else {
            if(value.data.details.avoid_attachments.no_attachments) {
              setTeethIds({
                upper: [],
                lower: [],
                no_anterior_attachments: false,
                no_attachments: value.data.details.avoid_attachments.no_attachments,
              });
            } else {
              // fill additional-instructions-teeth-svg with red color if selected
              let upper = [];
              let lower = [];
              if(value.data.details.avoid_attachments.upper) {
                upper = value.data.details.avoid_attachments.upper;
              }
              if(value.data.details.avoid_attachments.lower) {
                lower = value.data.details.avoid_attachments.lower;
              }
              setTeethIds({
                upper,
                lower,
              });
            }
          }
        }
      }
    }
  };

  function handleSaveTreatmentQualificationStatus() {
    setLoading(true);
    setErrorMsg(prev => ({
      ...prev,
      unqualifiedReasonDetails: '',
    }));
    const bodyObj = {
      procedure: appointmentType?.procedure,
      user_scan_info_id: appointmentId,
      is_qualified: data?.data?.is_qualified,
      ...(data?.data?.unqualified_reason_details && {
        unqualified_reason_details: data?.data?.unqualified_reason_details,
      }),
      ...(data?.data?.unqualified_reason && { unqualified_reason: data?.data?.unqualified_reason }),
    };
    if (data?.data?.is_qualified !== null) {
      axios.post(`admin/v2/users/${userId}/case-record-information/qualified`, bodyObj, {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('token'),
        },
      }).then((res) => {
        setLoading(false);
        if (res.data?.success && res.data.success == 'false') {
          setErrorMsg(prev => ({
            ...prev,
            unqualifiedReasonDetails: 'Unqualified reason details are required',
          }));
        }
      }).catch((err) => console.log(err));
    }
  }

  useEffect(() => {
    handleDataFetched();
  }, []);

  useEffect(() => {
    if(information){
      handleChangeData(information);
    }
  }, [information]);

  useEffect(() => {
    scrollToExpandedTab(index, 'information');
  }, [openCloseTabs]);

  useEffect(() => {
    if (callHandleSave === 1) {
      handleSave();
      setCallHandleSave(0);
    }
  }, [callHandleSave]);

  useEffect(() => {
    setInfoCheckboxes([
      {
        value: 'spacing',
        label: 'Spacing',
        disabled: false,
        selected: infoDetails.dental_conditions.spacing,
      },
      {
        value: 'crossbite',
        label: 'Crossbite',
        disabled: false,
        selected: infoDetails.dental_conditions.crossbite,
      },
      {
        value: 'deepBite',
        label: 'Deep Bite',
        disabled: infoDetails.dental_conditions.openBite,
        selected: infoDetails.dental_conditions.deepBite,
      },
      {
        value: 'crowding',
        label: 'Crowding',
        disabled: false,
        selected: infoDetails.dental_conditions.crowding,
      },
      {
        value: 'openBite',
        label: 'Open Bite',
        disabled: infoDetails.dental_conditions.deepBite,
        selected: infoDetails.dental_conditions.openBite,
      },
    ]);
  }, [infoDetails.dental_conditions])

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type='information'
      headerTitle={<p><span className='procedure-header-title'>Information:</span> <span className='header-title-gray'>{appointmentType?.procedure}</span></p>}
      index={index}
      iconCheck={data?.isAllProvided}
    >
      {openCloseTabs[index]?.information && (
        <Box className={`${classes.photoWrapper} information-${index} info`}>
          {loading ? (
            <Box className={classes.loadingBox}>
              <CircularProgress className={classes.loading} />
            </Box>
          ) : (
            <>
              {
                information?.show_new_info_ui ?
                <>
                  <Card title='Chief Complaint and Diagnosis' sectioned>
                    <Box style={{ marginBottom: '1rem' }}>
                      <Typography className='treatment-approach-title'>Date of Birth</Typography>
                        <Box
                          className={`${classes.dateSelectoreWidth} ${classes.flexBox}}`}
                        >
                          <SelectDatePicker
                            minDate={new Date(1940, 1, 1)}
                            className='date-time-picker'
                            value={data?.data?.date_of_birth ? new Date(data?.data?.date_of_birth) : new Date(1940, 1, 1)}
                            onDateChange={(e) => handleChange(e, 'date_of_birth')}
                          />
                        </Box>
                    </Box>

                    <InformationRadioButtonGroup
                      title='Gender'
                      radioGroupTypes={infoDetails}
                      groupName='gender'
                      radioButtons={gender}
                      handleRadioButtonClick={handleRadioButtonClick}
                    />

                    <Box style={{ marginBottom: '1rem' }}>
                      <Typography className='treatment-approach-title'>Chief complaint</Typography>
                      <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
                        <TextareaAutosize
                          placeholder='Add comment...'
                          className={classes.textarea}
                          style={{ height: '6rem' }}
                          value={data?.data?.problem_question ? data?.data?.problem_question : ''}
                          onChange={(e) =>
                            handleChange(e.target.value, 'problem_question')
                          }
                        />
                      </Box>
                    </Box>
                    {errorMsg?.problem_question && (
                      <Typography className={classes.errorMsg}>
                        {errorMsg.problem_question}
                      </Typography>
                    )}
                    <Box style={{ marginBottom: '1rem' }}>
                      <Typography className='treatment-approach-title'>Clinical findings</Typography>
                      {
                        data?.data?.dental_history ?
                        <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
                          <TextareaAutosize
                            placeholder='Add comment...'
                            className={classes.textarea}
                            rowsMin={6}
                            rowsMax={8}
                            value={data?.data?.dental_history ? data?.data?.dental_history : ''}
                            onChange={(e) =>
                              handleChange(e.target.value, 'dental_history')
                            }
                          />
                        </Box> :
                        <Box className={`${classes.fullWidth} ${classes.flexBox} ${classes.findingsBox}`}>
                          <Box className={classes.findingsTitlesBox}>
                            <Typography className={classes.findingsTitle}>Mark the conditions that exist</Typography>
                            <Typography className={`${classes.findingsTitle} ${classes.findingsTitle2}`}>Tooth number</Typography>
                          </Box>
                          {
                            clinicalFindings.map((finding, key) => {
                              let clinicalFinding = data?.data?.clinical_findings_v2?.find(f => f?.condition === finding.value);
                              let checked = clinicalFinding ? true : false;
                              let toothNumber = clinicalFinding?.tooth_number ? clinicalFinding.tooth_number : '';
                              let isNoneFound = data?.data?.clinical_findings_v2?.find(f => f.condition === 'none') ? true : false;
                              return (
                                <Box className={classes.findingBox} key={key}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          disabled={checked && isNoneFound}
                                          checked={checked}
                                          onChange={(e) => handleChangeV2(e.target.checked, finding.value, 'clinical_findings_v2', 'condition')}
                                        />
                                      }
                                      label={finding.label}
                                      className='info-v2-checkbox'
                                    />
                                  </FormGroup>
                                  <TextField
                                    disabled={(!checked || (isNoneFound && toothNumber)) ? true : false}
                                    className={`${classes.textField} info-v2-textfield`}
                                    value={toothNumber}
                                    onChange={(e) =>
                                      handleChangeV2(e.target.value, finding.value, 'clinical_findings_v2', 'tooth_number')
                                    }
                                  />
                                </Box>
                              )
                            })
                          }
                          <Box className={classes.findingBox}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data?.data?.clinical_findings_v2?.find(f => f?.condition === 'none') ? true : false}
                                    onChange={(e) => handleChangeV2(e.target.checked, 'none', 'clinical_findings_v2', 'condition')}
                                  />
                                }
                                label='None'
                                className='info-v2-checkbox'
                              />
                            </FormGroup>
                          </Box>
                        </Box>
                      }
                    </Box>
                    <Typography className={classes.errorMsg}>
                      {errorMsg.dental_history}
                    </Typography>

                    <InformationCheckboxGroup
                      title='Dental Conditions'
                      placeholder='Select dental conditions'
                      checkboxes={infoCheckboxes}
                      group='dental_conditions'
                      handleCheckboxClick={handleRadioButtonClick}
                    />
                  </Card>

                  <Card title='Pre-orthodontic Treatment Required' sectioned>
                    <Box style={{ marginBottom: '1rem' }}>
                      <Typography className='treatment-approach-title'>Pain/Symptoms</Typography>
                      <Typography className={classes.desc}>Mention every tooth that has pain, sensitivity, or has a fractured crown.</Typography>
                      <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
                        <TextareaAutosize
                          placeholder='Add comment...'
                          className={classes.textarea}
                          style={{ height: '6rem' }}
                          value={data?.data?.pain_symptoms ? data.data.pain_symptoms : ''}
                          onChange={(e) =>
                            handleChange(e.target.value, 'pain_symptoms')
                          }
                        />
                      </Box>
                    </Box>
                    {
                      data?.data?.dental_history_na &&
                      <>
                        <FormGroup>
                          <FormControlLabel
                            className='treatment-approach-title' control={
                              <Checkbox
                                checked={data?.data?.dental_history_na ? true : false}
                                onChange={() => handleChange(!data?.data?.dental_history_na, 'dental_history_na')}
                              />
                            }
                            label={<Typography className={classes.label}>Patient needs to do pre-treatment procedures before starting aligner treatment.</Typography>}
                          />
                        </FormGroup>
                        <Box style={{ margin: '1rem 0' }}>
                          <Typography className='treatment-approach-title'>Add the required procedures before the orthodontic treatment</Typography>
                          {data?.data?.list_of_procedures &&
                            data?.data?.list_of_procedures.map((proc, key) => (
                              <div key={key} className={classes.procWrapper}>
                                <TextareaAutosize
                                  placeholder='Add Procedure'
                                  className={classes.textarea}
                                  style={{ width: '85%' }}
                                  value={proc}
                                  onChange={(e) =>
                                    handleChangeProc(
                                      e.target.value,
                                      key,
                                      'list_of_procedures'
                                    )
                                  }
                                />
                                {key === data?.data?.list_of_procedures.length - 1 && (
                                  <Button onClick={addRow} className={classes.add}>Add</Button>
                                )}
                              </div>
                            ))}
                        </Box>
                      </>
                    }
                    {
                      data?.data?.clinical_findings_v2?.length > 0 && !data.data.clinical_findings_v2.find(f => f.condition === 'none') &&
                      <FormGroup>
                        <Typography className='treatment-approach-title'>Are there any pre-treatment procedures required before starting the aligner treatment?</Typography>
                        <RadioGroup name='pre-treatment-ans' className='info-images-container'>
                          <InputLabel className='info-single-image-InputLabel'>
                            <Radio onClick={handleCannotProceedChange} value={1} checked={cannotProceed} />
                            Yes
                          </InputLabel>
                          <InputLabel className='info-single-image-InputLabel'>
                            <Radio onClick={handleCannotProceedChange} value={0} checked={cannotProceed === false} />
                            No
                          </InputLabel>
                        </RadioGroup>
                      </FormGroup>
                    }
                    {
                      cannotProceed &&
                      <Box className={`${classes.fullWidth} ${classes.flexBox} ${classes.findingsBox}`}>
                        <Box>
                          <Typography className='treatment-approach-title'>Pre-treatment Procedures</Typography>
                        </Box>
                        <Box className={classes.colTitles}>
                          <Typography className={classes.colTitle}>Mention the procedures to be done before starting orthodontic aligner treatment</Typography>
                          <Typography className={classes.redColTitle}>Here <span className={classes.boldRedColTitle}>mention ONLY the teeth that need to be treated before the aligner</span> treatment.</Typography>
                        </Box>
                        {
                          clinicalFindings.filter(finding => data?.data?.clinical_findings_v2?.find(f => f?.condition === finding.value)).map((finding, key) => {
                            let clinicalFinding = data?.data?.clinical_findings_v2?.find(f => f?.condition === finding.value);
                            let procedures = clinicalFinding?.procedures ? clinicalFinding.procedures : '';
                            return (
                              <Box className={classes.findingBox} key={key}>
                                <Typography style={{ color: "#363636", fontSize: '13px' }}>{finding.label}</Typography>
                                <TextField
                                  className={`${classes.textField} info-v2-textfield`}
                                  value={procedures}
                                  onChange={(e) =>
                                    handleChangeV2(e.target.value, finding.value, 'clinical_findings_v2', 'procedures')
                                  }
                                />
                              </Box>
                            )
                          })
                        }
                        <br />
                      </Box>
                    }

                    <Box className={classes.flex}>
                      <FormControl sx={{ marginBottom: 2, minWidth: '100%' }} size='small'>
                        <Typography className='treatment-approach-title'>Have you visited your dentist in the past 6 months?</Typography>
                        <Select
                          sx={{
                            '& #my-simple-select': {
                              fontSize: '2rem',
                          }
                          }}
                          value={(data?.data?.visited_dentist !== null && data?.data?.visited_dentist !== undefined) ? data?.data?.visited_dentist : ""}
                          onChange={(e) => handleChange(e.target.value, 'visited_dentist')}
                          displayEmpty
                        >
                          {optionsHealthy.map((option, key) => (
                            <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {data?.data?.visited_dentist === 1 && <FormControl sx={{ marginBottom: 2, minWidth: '100%' }} size='small'>
                        <Typography className='treatment-approach-title'>According to your dentist, are your teeth and gums healthy?</Typography>
                        <Select
                          value={(data?.data?.teeth_healthy !== null && data?.data?.teeth_healthy !== undefined) ? data?.data?.teeth_healthy: ""}
                          onChange={(e) => handleChange(e.target.value, 'teeth_healthy')}
                          displayEmpty
                        >
                          {optionsHealthy.map((option, key) => (
                            <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>}
                    </Box>
                    <Box className={classes.flex}>
                      <FormControl sx={{ marginBottom: 2, minWidth: '100%' }} size='small'>
                        <Typography className='treatment-approach-title'>Do you have any wisdom teeth?</Typography>
                        <Select
                          value={(data?.data?.wisdom_teeth !== null && data?.data?.wisdom_teeth !== undefined) ? data?.data?.wisdom_teeth : ""}
                          onChange={(e) => handleChange(e.target.value, 'wisdom_teeth')}
                          displayEmpty
                        >
                          {optionsWisdomTeeth.map((option, key) => (
                            <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {(data?.data?.wisdom_teeth && data?.data?.wisdom_teeth == 'yes') && <FormControl sx={{ marginBottom: 2, minWidth: '100%' }} size='small'>
                        <Typography className='treatment-approach-title'>According to your dentist, do your wisdom teeth need removal?</Typography>
                        <Select
                          value={(data?.data?.wisdom_need_removal !== null && data?.data?.wisdom_need_removal !== undefined) ? data?.data?.wisdom_need_removal : ""}
                          onChange={(e) => handleChange(e.target.value, 'wisdom_need_removal')}
                          displayEmpty
                        >
                          {optionsHealthy.map((option, key) => (
                            <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>}
                    </Box>

                    <Box style={{ marginBottom: '1rem' }}>
                      <Typography className='treatment-approach-title'>Please explain the issue</Typography>
                      <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
                        <TextareaAutosize
                          className={classes.textarea}
                          style={{ height: '6rem' }}
                          value={data?.data?.explain_issue ? data?.data?.explain_issue : ''}
                          onChange={(e) =>
                            handleChange(e.target.value, 'explain_issue')
                          }
                        />
                      </Box>
                    </Box>
                  </Card>

                  <Card title='Aligner Treatment Preferences' sectioned>
                    <FormControl sx={{ marginBottom: 2, minWidth: '100%' }} size='small'>
                      <Typography className='treatment-approach-title'>Which arches do you want to treat?</Typography>
                      <Select
                        value={(data?.data?.arches_to_treat !== null && data?.data?.arches_to_treat !== undefined) ? data?.data?.arches_to_treat : 3}
                        onChange={(e) => handleChange(e.target.value, 'arches_to_treat')}
                        displayEmpty
                      >
                        {optionsArches.map((option, key) => (
                          <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <TreatmentApproach
                      infoDetails={infoDetails}
                      handleRadioButtonClick={handleRadioButtonClick}
                    />
                    <br />
                    <AdditionalInstructions
                      ids={teethIds}
                      handleClick={handleToothClick}
                      onTagClick={onTagClick}
                    />
                  </Card>
                </> :
                <>
                  <Box style={{ marginBottom: '1rem' }}>
                    <Typography className='treatment-approach-title'>Date of Birth</Typography>
                      <Box
                        className={`${classes.dateSelectoreWidth} ${classes.flexBox}}`}
                      >
                        <SelectDatePicker
                          minDate={new Date(1940, 1, 1)}
                          className='date-time-picker'
                          value={data?.data?.date_of_birth ? new Date(data?.data?.date_of_birth) : new Date(1940, 1, 1)}
                          onDateChange={(e) => handleChange(e, 'date_of_birth')}
                        />
                      </Box>
                  </Box>

                  <Box style={{ marginBottom: '1rem' }}>
                    <Typography className='treatment-approach-title'>Chief complaint</Typography>
                    <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
                      <TextareaAutosize
                        placeholder='Add comment...'
                        className={classes.textarea}
                        style={{ height: '6rem' }}
                        value={data?.data?.problem_question ? data?.data?.problem_question : ''}
                        onChange={(e) =>
                          handleChange(e.target.value, 'problem_question')
                        }
                      />
                    </Box>
                  </Box>
                  {errorMsg?.problem_question && (
                    <Typography className={classes.errorMsg}>
                      {errorMsg.problem_question}
                    </Typography>
                  )}
                  <Box style={{ marginBottom: '1rem' }}>
                    <Typography className='treatment-approach-title'>Clinical findings</Typography>
                    <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
                      <TextareaAutosize
                        placeholder='Add comment...'
                        className={classes.textarea}
                        rowsMin={6}
                        rowsMax={8}
                        value={data?.data?.dental_history ? data?.data?.dental_history : ''}
                        onChange={(e) =>
                          handleChange(e.target.value, 'dental_history')
                        }
                      />
                    </Box>
                  </Box>
                  <Typography className={classes.errorMsg}>
                    {errorMsg.dental_history}
                  </Typography>

                  <>
                    <FormGroup>
                      <FormControlLabel
                        className='treatment-approach-title' control={
                          <Checkbox
                            checked={data?.data?.dental_history_na ? true : false}
                            onChange={() => handleChange(!data?.data?.dental_history_na, 'dental_history_na')}
                          />
                        }
                        label={<Typography className={classes.label}>Patient needs to do pre-treatment procedures before starting aligner treatment.</Typography>}
                      />
                    </FormGroup>
                    {
                      data?.data?.dental_history_na && data?.data?.list_of_procedures?.length &&
                      <Box style={{ margin: '1rem 0' }}>
                        <Typography className='treatment-approach-title'>Add the required procedures before the orthodontic treatment</Typography>
                          {data?.data?.list_of_procedures.map((proc, key) => (
                            <div key={key} className={classes.procWrapper}>
                              <TextareaAutosize
                                placeholder='Add Procedure'
                                className={classes.textarea}
                                style={{ width: '85%' }}
                                value={proc}
                                onChange={(e) =>
                                  handleChangeProc(
                                    e.target.value,
                                    key,
                                    'list_of_procedures'
                                  )
                                }
                              />
                              {key === data?.data?.list_of_procedures.length - 1 && (
                                <Button onClick={addRow} className={classes.add}>Add</Button>
                              )}
                            </div>
                          ))}
                      </Box>
                    }
                  </>

                  <Box style={{ marginBottom: '1rem' }}>
                    <Typography className='treatment-approach-title'>Pain/Symptoms</Typography>
                    <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
                      <TextareaAutosize
                        placeholder='Add comment...'
                        className={classes.textarea}
                        style={{ height: '6rem' }}
                        value={data?.data?.pain_symptoms ? data.data.pain_symptoms : ''}
                        onChange={(e) =>
                          handleChange(e.target.value, 'pain_symptoms')
                        }
                      />
                    </Box>
                  </Box>
                  {
                    data?.data?.clinical_findings_v2?.length > 0 && !data.data.clinical_findings_v2.find(f => f.condition === 'none') &&
                    <Box className={`${classes.fullWidth} ${classes.flexBox} ${classes.findingsBox}`}>
                      <Box>
                        <Typography className='treatment-approach-title'>Pre-treatment Procedures</Typography>
                        <Typography className={classes.desc}>Mention the procedures to be done before starting orthodontic aligner treatment</Typography>
                      </Box>
                      {
                        clinicalFindings.filter(finding => data?.data?.clinical_findings_v2?.find(f => f?.condition === finding.value)).map((finding, key) => {
                          let clinicalFinding = data?.data?.clinical_findings_v2?.find(f => f?.condition === finding.value);
                          let procedures = clinicalFinding?.procedures ? clinicalFinding.procedures : '';
                          return (
                            <Box className={classes.findingBox} key={key}>
                              <Typography style={{ color: "#363636", fontSize: '13px' }}>{finding.label}</Typography>
                              <TextField
                                className={`${classes.textField} info-v2-textfield`}
                                value={procedures}
                                onChange={(e) =>
                                  handleChangeV2(e.target.value, finding.value, 'clinical_findings_v2', 'procedures')
                                }
                              />
                            </Box>
                          )
                        })
                      }
                      <br />
                    </Box>
                  }

                  <Box className={classes.flex}>
                    <FormControl sx={{ marginBottom: 2, minWidth: '100%' }} size='small'>
                      <Typography className='treatment-approach-title'>Have you visited your dentist in the past 6 months?</Typography>
                      <Select
                        sx={{
                          '& #my-simple-select': {
                            fontSize: '2rem',
                        }
                        }}
                        value={(data?.data?.visited_dentist !== null && data?.data?.visited_dentist !== undefined) ? data?.data?.visited_dentist : ""}
                        onChange={(e) => handleChange(e.target.value, 'visited_dentist')}
                        displayEmpty
                      >
                        {optionsHealthy.map((option, key) => (
                          <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {data?.data?.visited_dentist === 1 && <FormControl sx={{ marginBottom: 2, minWidth: '100%' }} size='small'>
                      <Typography className='treatment-approach-title'>According to your dentist, are your teeth and gums healthy?</Typography>
                      <Select
                        value={(data?.data?.teeth_healthy !== null && data?.data?.teeth_healthy !== undefined) ? data?.data?.teeth_healthy: ""}
                        onChange={(e) => handleChange(e.target.value, 'teeth_healthy')}
                        displayEmpty
                      >
                        {optionsHealthy.map((option, key) => (
                          <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>}
                  </Box>
                  <Box className={classes.flex}>
                    <FormControl sx={{ marginBottom: 2, minWidth: '100%' }} size='small'>
                      <Typography className='treatment-approach-title'>Do you have any wisdom teeth?</Typography>
                      <Select
                        value={(data?.data?.wisdom_teeth !== null && data?.data?.wisdom_teeth !== undefined) ? data?.data?.wisdom_teeth : ""}
                        onChange={(e) => handleChange(e.target.value, 'wisdom_teeth')}
                        displayEmpty
                      >
                        {optionsWisdomTeeth.map((option, key) => (
                          <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {(data?.data?.wisdom_teeth && data?.data?.wisdom_teeth == 'yes') && <FormControl sx={{ marginBottom: 2, minWidth: '100%' }} size='small'>
                      <Typography className='treatment-approach-title'>According to your dentist, do your wisdom teeth need removal?</Typography>
                      <Select
                        value={(data?.data?.wisdom_need_removal !== null && data?.data?.wisdom_need_removal !== undefined) ? data?.data?.wisdom_need_removal : ""}
                        onChange={(e) => handleChange(e.target.value, 'wisdom_need_removal')}
                        displayEmpty
                      >
                        {optionsHealthy.map((option, key) => (
                          <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>}
                  </Box>

                  <Box style={{ marginBottom: '1rem' }}>
                    <Typography className='treatment-approach-title'>Please explain the issue</Typography>
                    <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
                      <TextareaAutosize
                        className={classes.textarea}
                        style={{ height: '6rem' }}
                        value={data?.data?.explain_issue ? data?.data?.explain_issue : ''}
                        onChange={(e) =>
                          handleChange(e.target.value, 'explain_issue')
                        }
                      />
                    </Box>
                  </Box>

                  <FormControl sx={{ marginBottom: 2, minWidth: '100%' }} size='small'>
                    <Typography className='treatment-approach-title'>Which arches do you want to treat?</Typography>
                    <Select
                      value={(data?.data?.arches_to_treat !== null && data?.data?.arches_to_treat !== undefined) ? data?.data?.arches_to_treat : 3}
                      onChange={(e) => handleChange(e.target.value, 'arches_to_treat')}
                      displayEmpty
                    >
                      {optionsArches.map((option, key) => (
                        <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              }

              <br />
              <Review
                disabled={data?.isAllProvided == 0 || data?.data?.review_status != null}
                reviewStatus={data?.data?.review_status}
                reviewer={data?.data?.reviewer}
                reviewedAt={data?.data?.reviewed_at}
                handleChange={() => handleReviewStatus('Reviewed')}
              />
              <Typography className={classes.errorMsg}>
                {errorMsg.list_of_procedures}
              </Typography>

              <Card>
                <div className={classes.procedureOptionsWrapper}>
                  <Button className={classes.btn} onClick={() => setViewProcedureOptions((prev) => !prev)}>
                    {viewProceduresOptions ? 'Hide' : 'Show'} Procedures Options
                  </Button>
                  {viewProceduresOptions &&
                    <FormGroup>
                      <FormControlLabel control={
                        <Checkbox
                          checked={data?.procedures_array ? data?.procedures_array?.includes(procedures.CLEANING) : false}
                          onChange={() => hanldeBookProceduresArray(procedures.CLEANING)}
                        />
                      } label={procedures.CLEANING} />
                    </FormGroup>
                  }
                  </div>
              </Card>

              <Save uploadedBy={data?.isAllProvided != 0 ? data?.data?.owner_name : ''} handleSave={handleSave} success={success} />
              <div className={classes.errorBox}>
                <TextStyle variation='negative'>{errorMsg.general}</TextStyle>
              </div>

              <Card sectioned>
                <FormLayout>
                  <Typography className={classes.bigTitle}>Treatment Qualification Status</Typography>
                  <FormControl sx={{ marginBottom: 2, minWidth: '100%' }} size='small'>
                    <Typography className='treatment-approach-title'>Please Choose</Typography>
                    <Select
                      onChange={(e) => handleChange(e.target.value, 'is_qualified')}
                      value={(data?.data?.is_qualified !== null && data?.data?.is_qualified !== undefined) ? data?.data?.is_qualified : ""}
                    >
                      {options.map((option, key) => (
                        <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormLayout>
                {data?.data?.is_qualified === 0 && 
                  <Card sectioned>
                    <FormLayout>
                      <Typography className={classes.bigTitle}>Discontinued reasons</Typography>
                      <FormControl sx={{ marginBottom: 2, minWidth: '100%' }} size='small'>
                        <Typography className='treatment-approach-title'>Please Choose</Typography>
                        <Select
                          onChange={(e) => handleChange(e.target.value, 'unqualified_reason')}
                          value={(data?.data?.unqualified_reason !== null && data?.data?.unqualified_reason !== undefined) ? data?.data?.unqualified_reason : ""}
                        >
                          {reasonOptions.map((option, key) => (
                            <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormLayout>
                    <br />
                    <Box style={{ marginBottom: '1rem' }}>
                      <Typography className='treatment-approach-title'>Unqualified reason details</Typography>
                      <Box className={`${classes.fullWidth} ${classes.flexBox}`}>
                        <TextareaAutosize
                          className={classes.textarea}
                          style={{ height: '6rem' }}
                          value={data?.data?.unqualified_reason_details ? data?.data?.unqualified_reason_details : ''}
                          onChange={(e) => handleChange(e.target.value, 'unqualified_reason_details')}
                        />
                        <TextStyle variation='negative'>{errorMsg.unqualifiedReasonDetailsRequiredMsg}</TextStyle>
                      </Box>
                    </Box>
                  </Card>
                }
                <Button
                  className={classes.saveBtn}
                  onClick={handleSaveTreatmentQualificationStatus}
                >
                  Save Treatment Qualification Status
                </Button>
              </Card>
            </>
          )}
        </Box>
      )}
    </ProcedureHeader>
  );
}
