import { forwardRef, useRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide,
  DialogContent,
  TextareaAutosize,
} from '@mui/material';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AddNoteBatchDialog = ({
  open,
  setOpen,
  selectedIndex,
  note,
  handleRowInputChange,
}) => {
  const noteInput = useRef(null);
  const handleClose = () => {
    setOpen(false);
  };

  const addNoteToFormRows = () => {
    handleRowInputChange(selectedIndex, 'note', noteInput.current.value);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Batch Note</DialogTitle>
      <DialogContent sx={{ minWidth: '600px' }}>
        <TextareaAutosize
          ref={noteInput}
          defaultValue={note}
          aria-label="minimum height"
          minRows={6}
          placeholder="Type batch note here..."
          style={{ width: '100%' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={addNoteToFormRows}>Add Note</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddNoteBatchDialog;
