import { CircularProgress, MenuItem, Select, makeStyles } from '@material-ui/core';
import { Button, Checkbox, Spinner, TextStyle } from '@shopify/polaris'
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from "../../../axios";
import { iconColors } from '../../../config';
import { toast } from "react-toastify";
import AlertUnpaidAppointmentDialog from "./alert-unpaid-appointment-dialog.component";
import NeedReschedulingDialog from './need-rescheduling-dialog.component';
import UpdateIcon from '@mui/icons-material/Update';
import MenuActions from '../../../components/MenuActions';

const useStyles = makeStyles(() => ({
  menuWrapper: {
    padding: 10,
    margin: "0 auto",
    width: "100%",
    textAlign: "center",
  },
  menu: {
    padding: "20px 0 !important",
    textAlign: "center",
  },
  menuButton: {
    minWidth: 'unset',
  },
}));

function ProceduresProfile({ isLoading, setIsLoading }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openReschedule, setOpenReschedule] = useState(false);
  const [checkedClinicDidntShowBox, setCheckedClinicDidntShowBox] = useState(false);
  const [checkedModalMessage, setCheckedModalMessage] = useState('Are sure you wanna mark this first visit as clinic didn\'t show box');
  const [appointmentId, setAppointmentId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingNeedRescheduling, setLoadingNeedRescheduling] = useState(null);
  const [clinics, setClinics] = useState([]);
  const [procedures, setProcedures] = useState([])
  const [totalCost, setTotalCost] = useState(null);
  const [clinic, setClinic] = useState("");
  const [canUpdateProcStatus, setCanUpdateProcStatus] = useState(false);
  const { id } = useParams();

  const handleSelectClinic = (e) => {
    setClinic(e.target.value);
  }

  const fetchProcedures = () => {
    setLoading(true);
    axios.get(`admin/v1/users/${id}/get-appointments-summary`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((result) => {
      setCanUpdateProcStatus(result.data.can_update_proc_status);
      let clinicsArr, clinicFound;
      if (result.data.isDoctorPortal) {
        clinicsArr = result.data.clinics;
        clinicFound = clinicsArr?.find(
          (cl) => cl.id == result.data.appointment_details?.clinic,
        );
      } else {
        clinicsArr = result.data.clinics?.map((val) => ({
          label: val.name_identification,
          value: val.id,
          isMainCityClinic: val.main_city_clinic,
        }));
        const clinicsOrdered = clinicsArr?.sort(function (a, b) {
          return a === b
            ? 0
            : a.label.en.toLowerCase() < b.label.en.toLowerCase()
              ? -1
              : 1;
        });
        clinicsOrdered && setClinics([...clinicsOrdered]);
        clinicFound = clinicsOrdered?.find(
          (cl) => cl.label.en.includes(result.data.appointment_details?.clinic),
        );
      }
      setClinic(clinicFound ? clinicFound?.value : (clinicsArr?.length > 0 ? clinicsArr[0].value : ''));
      if (result.data && result.data.procedures) {
        setProcedures(result.data.procedures);
        setTotalCost(result.data.total);
      }
    }).catch((err) => {
      console.log(`Error in profile ${err}`);
    }).finally(() => {
      setLoading(false);
    });
  }

  const updateProcStatus = (item, action) => {
    setLoading(true);
    let body = {
      user_id: id,
      action,
      procedure_id: item?.proc_id,
      clinic_id: item?.clinic_id,
    };
    axios.put(`admin/v1/cms/appointment-status/update`, body, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((res) => {
      if (res.data.success) {
        fetchProcedures();
      }
    }).catch((err) => {
      console.log(err);
    })
  };

  const handleClickOpen = (checkedValue, id) => {
    setAppointmentId(id);
    if (checkedValue) {
      setCheckedModalMessage('Are sure you wanna mark this first visit as clinic didn\'t show box');
    } else {
      setCheckedModalMessage('Are sure you wanna mark this first visit as clinic showed box');
    }
    setCheckedClinicDidntShowBox(checkedValue);
    setOpen(true);
  };

  const handleNeedRescheduleModal = (id) => {
    setAppointmentId(id);
    setOpenReschedule(true);
  };

  const disableDropdown = (status) => {
    return !(status === 'Undecided Booking' || status === 'Cancelled Booking');
  }

  const handleUpdateFirstVisitStatus = async () => {
    try {
      setLoading(true);
      let body = {
        status: checkedClinicDidntShowBox,
        appointmentId: appointmentId,
      };
      let headers = {
        Authorization: `Bearer ${Cookies.get('token')}`,
      };
      const response = await axios.post(`admin/v1/appointment/unpaid`, body, {
        headers,
      });
      if (response.status === 200) {
        const { success, message } = response.data;
        if (success) {
          toast.success(message, 3000);
        } else {
          setCheckedClinicDidntShowBox(!checkedClinicDidntShowBox);
          toast.error(message, 3000);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateAppointmentStatus = async () => {
    try {
      setOpenReschedule(false);
      setLoadingNeedRescheduling(appointmentId);
      let body = {
        appointmentId,
      };
      let headers = {
        Authorization: `Bearer ${Cookies.get("token")}`,
      };
      const response = await axios.post(`admin/v1/appointment/status`, body, {
        headers,
      });
      if (response.status === 200) {
        const { success, message } = response.data;
        if (success) {
          toast.success(message, 3000);
        } else {
          toast.error(message, 3000);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingNeedRescheduling(null);
    }
  };

  const handleChangeClinic = () => {
    if (!clinic) {
      return false;
    }
    setIsLoading(true)
    let body = {
      clinic_id: clinic,
    }
    let headers = {
      Authorization: "Bearer " + Cookies.get("token"),
    };
    axios.post(`admin/v1/users/${id}/updateClinic`, body, { headers }).then(res => {
    }).catch(e => {
      console.log(e);
    }).finally(() => {
      setIsLoading(false)
    });
  }

  useEffect(() => {
    fetchProcedures();
  }, []);

  useEffect(() => {
    if (procedures) {
      const firstVisit = procedures.filter(
        (appointment) => appointment.book_scan
      );
      if (firstVisit && firstVisit.length > 0) {
        setAppointmentId(firstVisit[0].id);
        setCheckedClinicDidntShowBox(firstVisit[0].unpaid);
      }
    }
  }, [procedures]);


  return (
    <div className="payment-wrapper">
      {
        loading ?
          <div style={{ textAlign: "center" }}>
            <CircularProgress color='primary' />
          </div>
          :
          <>
            <AlertUnpaidAppointmentDialog
              open={open}
              setOpen={setOpen}
              handleAction={handleUpdateFirstVisitStatus}
              message={checkedModalMessage}
              setCheckedClinicDidntShowBox={setCheckedClinicDidntShowBox}
              checkedClinicDidntShowBox={checkedClinicDidntShowBox}
            />
            <NeedReschedulingDialog
              open={openReschedule}
              setOpen={setOpenReschedule}
              handleConfirm={handleUpdateAppointmentStatus}
            />
            <table className='procedures-profile-table' style={{ display: "grid", width: "100%", overflowX: "auto", overflowY: "hidden" }}>
              <tr style={{ display: "flex" }}>
                <th className='procedures-profile-th' ><TextStyle variation="strong">Treatment Stage</TextStyle></th>
                <th className='procedures-profile-th-l' ><TextStyle variation="strong">Appointment</TextStyle></th>
                <th className='procedures-profile-th-l' ><TextStyle variation="strong">Type</TextStyle></th>
                <th className='procedures-profile-th' ><TextStyle variation="strong">Before Step</TextStyle></th>
                <th className='procedures-profile-th' ><TextStyle variation="strong">Status</TextStyle></th>
                <th className='procedures-profile-th-xl' ><TextStyle variation="strong">Clinic</TextStyle></th>
                <th className='procedures-profile-th' ><TextStyle variation="strong">Visible on Portal</TextStyle></th>
                <th className='procedures-profile-th' ><TextStyle variation="strong">Scheduled for</TextStyle></th>
                <th className='procedures-profile-th' ><TextStyle variation="strong">Completed on</TextStyle></th>
                <th className='procedures-profile-th' ><TextStyle variation="strong">Portal Location</TextStyle></th>
                <th className='procedures-profile-th' ><TextStyle variation="strong">Completed by</TextStyle></th>
                <th className='procedures-profile-th' ><TextStyle variation="strong">Clinic Didn't Show Box</TextStyle></th>
                <th className='procedures-profile-th' ><TextStyle variation="strong">Need Rescheduling</TextStyle></th>
                <th className='procedures-profile-th' ><TextStyle variation="strong">Appointment Cost</TextStyle></th>
              </tr>
              {procedures.map((item, key) => {
                const isDisabled = disableDropdown(item?.status);
                const isPendingProcedure = !item?.id;
                return (
                  <tr style={{ display: "flex" }} key={key}>
                    <td className='procedures-profile-td' >
                      <div >{item.treatment_stage}</div>
                    </td>
                    <td className='procedures-profile-td-l' >
                      <div >#{key + 1}</div>
                    </td>
                    <td className='procedures-profile-td-l' >
                      <div className="badge-wrapper-flex-wrap-gap">
                        {item.type && item.type.map((res, key) =>
                          <div key={key} style={{
                            padding: '0 1rem',
                            backgroundColor: iconColors[res]?.background ?? '#3CC7D3',
                            color: iconColors[res]?.color ?? 'black',
                            borderRadius: '10rem',
                          }}
                          >
                            {res}
                          </div>)}
                      </div>
                    </td>
                    <td className='procedures-profile-td' >
                      <div>{item.before_step}</div>
                    </td>
                    <td className='procedures-profile-td'>
                      <MenuActions
                        isPendingProcedure={isPendingProcedure}
                        item={item}
                        classes={classes}
                        canUpdateProcStatus={canUpdateProcStatus}
                        updateProcStatus={updateProcStatus}
                      />
                    </td>
                    <td className='procedures-profile-td-xl'>{(item.book_scan && Array.isArray(item?.type) && !item?.type.includes("Walk-in")) ?
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                        <Select style={{ fontSize: '1.4rem', margin: 'auto' }} onChange={(e) => { handleSelectClinic(e) }} disabled={isDisabled} value={clinic}>
                          {clinics.map((cl, key) => <MenuItem disabled={cl.isMainCityClinic} key={key} style={{ fontSize: '1.4rem' }} value={cl.value}>{cl.label['en']}</MenuItem>)}
                        </Select>
                        <Button loading={isLoading} disabled={isDisabled} primary onClick={handleChangeClinic}>Save</Button>
                      </div> : <div style={{ minWidth: '15%' }}>{item.assigned_clinic}</div>}</td>
                    <td className='procedures-profile-td'>
                      <div>{item.visible_on_clinic_portal == -1 ? "No" :
                        (item.visible_on_clinic_portal == 0 ? "" : item.visible_on_clinic_portal)}</div>
                    </td>
                    <td className='procedures-profile-td'>
                      <div>{item.appointment_date_and_time}</div>
                    </td>
                    <td className='procedures-profile-td'>
                      <div>{item.appointment_completed_on}</div>
                    </td>
                    <td className='procedures-profile-td'>
                      <div>{item.clinic_portal_location}</div>
                    </td>
                    <td className='procedures-profile-td'>
                      <div>{item.completed_by}</div>
                    </td>
                    <td className="procedures-profile-td">
                      <div>
                        {item.book_scan &&
                          (item.appointment_completed_on !== '-' ||
                            item.status === 'Appointment Completed') ? (
                          loading ? (
                            <Spinner size="small" />
                          ) : (
                            <Checkbox
                              checked={checkedClinicDidntShowBox}
                              onChange={(value) => handleClickOpen(value, item.id)}
                            />
                          )
                        ) : (
                          ''
                        )}
                      </div>
                    </td>
                    <td className='procedures-profile-td'>
                      {item.canReschedule && (
                        <div style={{ width: "50%", margin: "0 auto" }}>
                          <Button
                            loading={loadingNeedRescheduling === item.id}
                            primary
                            size="slim"
                            onClick={() => handleNeedRescheduleModal(item.id)}
                          >
                            {loadingNeedRescheduling !== item.id && <UpdateIcon />}
                          </Button>
                        </div>
                      )}
                    </td>
                    <td className='procedures-profile-td'>
                      <div>{item.procedures}</div>
                    </td>
                  </tr>
                )
              })
              }
              {totalCost ?
                <tr style={{ display: "flex" }}>
                  <td className='procedures-profile-td'>
                    <div />
                  </td>
                  <td className='procedures-profile-td-l'>
                    <div />
                  </td>
                  <td className='procedures-profile-td-l'>
                    <div />
                  </td>
                  <td className='procedures-profile-td' >
                    <div />
                  </td>
                  <td className='procedures-profile-td'>
                    <div />
                  </td>
                  <td className='procedures-profile-td-xl'>
                    <div />
                  </td>
                  <td className='procedures-profile-td'>
                    <div />
                  </td>
                  <td className='procedures-profile-td'>
                    <div />
                  </td>
                  <td className='procedures-profile-td'>
                    <div />
                  </td>
                  <td className='procedures-profile-td'>
                    <div />
                  </td>
                  <td className='procedures-profile-td'>
                    <div />
                  </td>
                  <td className='procedures-profile-td'>
                    <div />
                  </td>
                  <td className='procedures-profile-td'>
                    <div />
                  </td>
                  <td className='procedures-profile-td'>
                    <div>Total {totalCost}</div>
                  </td>
                </tr>
                : ''}
            </table>
          </>
      }
    </div>
  )
}

export default ProceduresProfile