import { List, ListItemText, ListItem, styled } from '@mui/material';
import { useState } from 'react';
import RichTextEditor from 'react-rte';

const SubListItem = styled(ListItem)(({ theme, isSelected }) => ({
  border: `1px solid ${isSelected ? '#FFF' : '#008060'}`,
  backgroundColor: isSelected ? '#008060' : '#FFF',
  color: isSelected ? '#FFF' : '#000',
  borderRadius: '16px',
  marginBottom: '8px',
  flexBasis: 'max-content',
  cursor: 'pointer',
  '& span': {
    fontSize: '12px',
  },
}));

const TemplateList = styled(List)(() => ({
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  gap: '5px',
  justifyContent: 'start',
  padding: '1rem',
}));

const SubListTemplates = ({
  templates,
  setvalueTreatmentDescriptionEn,
  valueTreatmentDescriptionEn,
  valueTreatmentDescriptionAr,
  setvalueTreatmentDescriptionAr,
}) => {
  const [selectedSubItem, setSelectedSubItem] = useState(null);

  const handleBubbleClick = (template) => {
    setSelectedSubItem(template);
    const newEnglishValue = `${valueTreatmentDescriptionEn.toString(
      'html'
    )} <p>${template?.description?.en}</p>`;
    const newArabicValue = `${valueTreatmentDescriptionAr.toString(
      'html'
    )} <p>${template?.description?.ar}</p>`;
    const newArabicDescriptionValue = RichTextEditor.createValueFromString(
      newArabicValue,
      'html'
    );
    const newEnglishDescriptionValue = RichTextEditor.createValueFromString(
      newEnglishValue,
      'html'
    );
    setvalueTreatmentDescriptionEn(newEnglishDescriptionValue);
    setvalueTreatmentDescriptionAr(newArabicDescriptionValue);
  };

  return (
    <TemplateList>
      {templates.map((template, index) => (
        <SubListItem
          onClick={() => handleBubbleClick(template)}
          key={index}
          isSelected={selectedSubItem === template}
        >
          <ListItemText primary={template?.description?.en} />
        </SubListItem>
      ))}
    </TemplateList>
  );
};

export default SubListTemplates;
