import React from 'react';
import { Button, Icon } from '@shopify/polaris';
import { FilterMajor } from '@shopify/polaris-icons';
import { useStyles } from '../Reports/ClinicReports/clinics-reports-date-filter.styles';
import DispatchFilter from './dispatch-filter.component';

const DispatchSearchBar = ({
  searchRef,
  setSelectedPlan,
  dispatchCodes,
  getDispatches,
  treatmentPlanTypes,
  setSelectedType,
  loading,
  setSelectedProductType,
}) => {
  const classes = useStyles();
  const productTypes = [
    { value: 'all', label: 'All' },
    { value: 'shop', label: 'Shop' },
    { value: 'batches', label: 'Aligner Batches' },
  ];
  return (
    <div className={classes.filterContainer}>
      <DispatchFilter
        treatmentPlanTypes={treatmentPlanTypes}
        setSelectedPlan={setSelectedPlan}
        dispatchCodes={dispatchCodes}
        setSelectedType={setSelectedType}
        loading={loading}
        productTypes={productTypes}
        setSelectedProductType={setSelectedProductType}
      />
      <div className={classes.inputContainer}>
        <label htmlFor="search">Search:</label>
        <input
          className={classes.input}
          ref={searchRef}
          type="search"
          id="search"
          autoComplete="off"
          placeholder="Id, username"
        />
      </div>
      <Button onClick={getDispatches} disabled={loading}>
        <Icon source={FilterMajor} color="base" />
      </Button>
    </div>
  );
};

export default DispatchSearchBar;
