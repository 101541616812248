import React, { useState, useEffect } from "react";
import {
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Card,
  PageActions,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";

import {useLocation, useParams} from "react-router-dom";
import UserHeader from "../../../components/UserLayout/user-header";

export default function EditQuestions() {
  let { id } = useParams();
  const [valueQuestion1, setValueQuestion1] = useState("");
  const [valueQuestion2, setValueQuestion2] = useState("");
  const [valueQuestion3, setValueQuestion3] = useState("");

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    axios
      .get(`admin/v1/users/${id}/questions`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setValueQuestion1(result.data.data.questions.smile_issue_question);
        setValueQuestion2(result.data.data.questions.dental_history_question);
        setValueQuestion3(result.data.data.questions.share_info_question);
      })
      .catch((err) => console.log(err));
  }
  const location = useLocation();
  let linkState =
    {planType: "", clinicStatus: "", clinicName: "", status: "", userId: id, pageTitle: ""};
  if (location.state !== undefined) {
    const {planType, clinicStatus, clinicName, status, userId, pageTitle} = location.state;
    linkState = {planType, clinicStatus, clinicName, status, userId, pageTitle}
  }
  const { planType, clinicStatus, clinicName, status, userId, pageTitle} = linkState;

  return (
    <Page>
      <UserHeader pageTitle={pageTitle} status={status} userId={userId} clinicStatus={clinicStatus} clinicName={clinicName} planType={planType}/>
      <br/>
      <h1 className="page-header">Edit Questions</h1>
      <Card sectioned>
        <FormLayout>
          <TextStyle>
            What issues with your current smile would you like to resolve?
          </TextStyle>
          <TextField value={valueQuestion1} onChange={handleChangeQuestion1} />
          <TextStyle>
            Tell us about your dental history and overall dental health
          </TextStyle>
          <TextField value={valueQuestion2} onChange={handleChangeQuestion2} />
          <TextStyle>
            Any other dental information you would like to share (optional)
          </TextStyle>
          <TextField value={valueQuestion3} onChange={handleChangeQuestion3} />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
    </Page>
  );

  function handleChangeQuestion1(valueQuestion1) {
    setValueQuestion1(valueQuestion1);
  }

  function handleChangeQuestion2(valueQuestion2) {
    setValueQuestion2(valueQuestion2);
  }
  function handleChangeQuestion3(valueQuestion3) {
    setValueQuestion3(valueQuestion3);
  }

  function handleSave() {
    const bodyObj = {
      smile_issue_question: valueQuestion1,
      dental_history_question: valueQuestion2,
      share_info_question: valueQuestion3,
    };
    axios
      .post(`admin/v1/users/${id}/questions`, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        history.push("/admin/users/" + id + "/dashboard");
      })
      .catch((err) => console.log(err));
  }
}
