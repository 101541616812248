import React from "react";
import { DropZone, Modal, Spinner } from "@shopify/polaris";
import { useStyles } from "./clinic-reports.styles";

const PaymentConfirmationInvoiceModel = ({
  activePaymentConfirmationModal,
  handleClose,
  uploadPaymentConfirmationInvoice,
  pending,
  uploadingFilesLoading,
  handleDropZoneGuide,
  uploadedFilesGuide,
  sendImageGuide,
  fileUploadGuide,
}) => {
  const classes = useStyles();
  return (
    <Modal
      title="Upload Payment Confirmation"
      open={activePaymentConfirmationModal}
      onClose={() => handleClose("payment")}
      primaryAction={{
        content: "Upload",
        onAction: uploadPaymentConfirmationInvoice,
        loading: pending,
        disabled: uploadingFilesLoading,
      }}
      secondaryActions={[
        {
          content: "Close",
          disabled: uploadingFilesLoading || pending,
          onAction: () => handleClose("payment"),
        },
      ]}
    >
      <Modal.Section>
        <div className={classes.uploadContainer}>
          {uploadingFilesLoading ? (
            <div className={classes.loadingContainer}>
              <Spinner
                accessibilityLabel="Loading Clinics Pricing"
                size="large"
              />
            </div>
          ) : (
            <DropZone
              onDrop={handleDropZoneGuide}
              onDropAccepted={sendImageGuide}
            >
              {uploadedFilesGuide}
              {fileUploadGuide}
            </DropZone>
          )}
        </div>
      </Modal.Section>
    </Modal>
  );
};

export default PaymentConfirmationInvoiceModel;
