import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../history";
import { AppProvider } from "@shopify/polaris";
import ListPayments from "./ListPayments";
import  Payment from "./Payments";
import ListOverduesUpcomings from "./ListOverduesUpcomings";
import MainDashboard from "../Users/MainDashboard";
import NavigationLayout from "../components/NavigationLayout";
import PaymentDetails from "./PaymentDetails";


function Payments() {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/payments" component={Payment} />
          <Route exact path="/admin/payments/:id" component={PaymentDetails} />
          <Route
            path="/admin/payments/:id/overduesandupcomings"
            component={ListOverduesUpcomings}
          />
          <Route path="/admin/users/:id/dashboard" component={MainDashboard} />
        </Switch>
      </Router>
    </NavigationLayout>
  );
}
export default Payments;
