import moment from "moment";
import {DispatchesSubTypes, DispatchTypeCodes} from "./constants";
import { Badge } from '@shopify/polaris';
import React from 'react';

export const capitalizeWords = (sentence) => {
  if (sentence) {
    // split the sentence into an array of words
    let words = sentence.split(' ');
    for (let i = 0; i < words.length; i++) {
      // capitalize the first letter of each word
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    // join the words back into a sentence
    return words.join(' ');
  }
  return sentence;
};

export const tagsColor = (tag = null, type) => {
  let color = "";
  if (tag) {
    if (type === "status") {
      switch (tag) {
        case "Good":
          color = "clinic-status-good";
          break;
        case "Overdue":
          color = "clinic-status-overdue";
          break;
        case "Doubtful":
          color = "clinic-status-doubtful";
          break;
        case "Written Off":
          color = "clinic-status-written-off";
          break;
        default:
          color = "";
      }
    } else if (type === "planType") {
      switch (tag) {
        case "Medium":
          color = "clinic-status-medium";
          break;
        case "Medium+":
          color = "clinic-status-medium-plus";
        break;
        case "Complex":
          color = "clinic-status-complex";
          break;
        case "Complex+":
          color = "clinic-status-complex-plus";
          break;
        default:
          color = "clinic-status-light";
      }
    }
    return color;
  }
};

export const disableStepsAccordingToBatchType = (type) => {
  let disable;
  switch (type) {
    case DispatchTypeCodes['retainers']:
    case DispatchTypeCodes['bleachingTrays']:
    case DispatchTypeCodes['nightGuards']:
    case DispatchTypeCodes['whiteningKits']:
    case DispatchTypeCodes['smileSpreader']:
    case DispatchTypeCodes['carryCases']:
    case DispatchTypeCodes['chewies']:
    case DispatchTypeCodes['removerTool']:
    case DispatchTypeCodes['electricToothBrush']:
    case DispatchTypeCodes['whiteningPen']:
    case DispatchTypeCodes['templates']:
    case DispatchTypeCodes['fixedWire']:
    case DispatchTypeCodes['passiveAligners']:
      disable = true;
      break;
    default:
      disable = false;
  }
  return disable;
};

export const filterSubTypesAccordingToType = (type, subTypes) => {
  let subTypesArray = [];
  switch (type) {
    case DispatchTypeCodes['whiteningKits']:
    case DispatchTypeCodes['smileSpreader']:
    case DispatchTypeCodes['carryCases']:
    case DispatchTypeCodes['chewies']:
    case DispatchTypeCodes['removerTool']:
    case DispatchTypeCodes['electricToothBrush']:
    case DispatchTypeCodes['whiteningPen']:
    case DispatchTypeCodes['fixedWire']:
      if (subTypes.includes(DispatchesSubTypes['standard'])) {
        subTypesArray.push(DispatchesSubTypes['standard']);
      }
      if (subTypes.includes(DispatchesSubTypes['replacement'])) {
        subTypesArray.push(DispatchesSubTypes['replacement']);
      }
      break;
    case DispatchTypeCodes['aligners']:
    case DispatchTypeCodes['templates']:
    case DispatchTypeCodes['retainers']:
    case DispatchTypeCodes['bleachingTrays']:
    case DispatchTypeCodes['nightGuards']:
      if (subTypes.includes(DispatchesSubTypes['standard'])) {
        subTypesArray.push(DispatchesSubTypes['standard']);
      }
      if (subTypes.includes(DispatchesSubTypes['replacement'])) {
        subTypesArray.push(DispatchesSubTypes['replacement']);
      }
      if (subTypes.includes(DispatchesSubTypes['extras'])) {
        subTypesArray.push(DispatchesSubTypes['extras']);
      }
      break;
    default:
      subTypesArray = subTypes;
  }
  return subTypesArray;
};

export const sortBatches = (a, b) => {
  if (a.delivery === b.delivery) {
    let first = a.number === '-' ? -1 : a.number
    let second = b.number === '-' ? -1 : b.number
    return second - first;
  }
  return a.delivery - b.delivery;
};

export const getSmilePlanName = (type) => {
  let name;
  switch (type) {
    case 'rtp':
      name = 'RTP Plan';
      break;
    case 'mca':
      name = 'MCA Plan';
      break;
    default:
      name = 'Treatment Plan';
  }
  return name;
};

export const generateRandomId = () => {
  const timestamp = Date.now().toString(36);
  const randomPart = Math.random().toString(36).substring(2, 7);
  return timestamp + randomPart;
}

export const addDaysToTargetDispatchDate = (type, subType) => {
  let daysToAdd;
  const expression = type + subType;
  switch (expression) {
    case DispatchTypeCodes['templates'] + DispatchesSubTypes['standard']:
    case DispatchTypeCodes['templates'] + DispatchesSubTypes['replacement']:
    case DispatchTypeCodes['templates'] + DispatchesSubTypes['extras']:
    case DispatchTypeCodes['retainers'] + DispatchesSubTypes['replacement']:
    case DispatchTypeCodes['retainers'] + DispatchesSubTypes['extras']:
    case DispatchTypeCodes['bleachingTrays'] +
      DispatchesSubTypes['replacement']:
    case DispatchTypeCodes['bleachingTrays'] + DispatchesSubTypes['extras']:
    case DispatchTypeCodes['nightGuards'] + DispatchesSubTypes['replacement']:
    case DispatchTypeCodes['nightGuards'] + DispatchesSubTypes['extras']:
    case DispatchTypeCodes['fixedWire'] + DispatchesSubTypes['replacement']:
      daysToAdd = 3;
      break;
    case DispatchTypeCodes['nightGuards'] + DispatchesSubTypes['standard']:
    case DispatchTypeCodes['retainers'] + DispatchesSubTypes['standard']:
    case DispatchTypeCodes['bleachingTrays'] + DispatchesSubTypes['standard']:
    case DispatchTypeCodes['fixedWire'] + DispatchesSubTypes['standard']:
      daysToAdd = 7;
      break;
    default:
      daysToAdd = 2;
  }
  return daysToAdd;
};

export const checkValidRange = (start, end) => {
  return parseInt(end) - parseInt(start) >= 1;
}

export const findNextBatch = (
  startIndex,
  type,
  array,
  subType = DispatchesSubTypes.standard,
) => {
  for (let i = startIndex + 1; i < array.length; i++) {
    if (
      array[i].type === type &&
      array[startIndex].treatmentStage === array[i].treatmentStage &&
      array[i].subType === subType
    ) {
      return i;
    }
  }
  return -1;
};

export const arraysAreEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
      return false;
    }
  }
  return true;
}

export const getProductionDays = (type, types) => {
  const productionBatch = types.find((batch) => batch.code === type);
  if (productionBatch) {
    return productionBatch.production_days;
  }
  return 0;
};

export const getTrackingTypesArray = (type = '', subType = DispatchesSubTypes['standard'], list) => {
  let trackingTypes;
  if (type === DispatchTypeCodes['aligners']) {
    trackingTypes = list.global[subType].map(type => ({value: type, label: type}));
  } else {
    trackingTypes = [{value: list.global[type][subType], label: list.global[type][subType]}];
  }
  return trackingTypes;
}

export const getTrackingDestinationArray = (
  type = '',
  subType = DispatchesSubTypes['standard'],
  list
) => {
  let trackingDestination;
  if (type === DispatchTypeCodes['aligners']) {
    const lebToUaeArray = list.LEB_TO_UAE[subType].map((type) => ({
      value: type,
      label: type,
      desc: { dhl: 'DHL', aramex: 'Aramex' },
    }));
    const uaeToClientArray = list.UAE_TO_CLIENT[subType].map((type) => ({
      value: type,
      label: type,
      desc: { dhl: 'DHL', aramex: 'Aramex' },
    }));
    trackingDestination = [...lebToUaeArray, ...uaeToClientArray];
  } else {
    const lebToUae = {
      value: list.LEB_TO_UAE[type][subType],
      label: list.LEB_TO_UAE[type][subType],
      desc: { dhl: 'DHL', aramex: 'Aramex' },
    };
    const uaeToClient = {
      value: list.UAE_TO_CLIENT[type][subType],
      label: list.UAE_TO_CLIENT[type][subType],
      desc: { dhl: 'DHL', aramex: 'Aramex' },
    };
    trackingDestination = [lebToUae, uaeToClient];
  }
  return trackingDestination;
};

export const sortDispatches = (a, b) => {
  if (a.disabled && !b.disabled) {
    return -1;
  }
  if (!a.disabled && b.disabled) {
    return 1;
  }
  
  return moment(a.target_dispatch_date).isBefore(b.target_dispatch_date) ? -1 : 1;
}

export const calculateSteps = (maxStep, startingStep) => {
  maxStep = parseInt(maxStep);
  startingStep = parseInt(startingStep);
  if (maxStep === 0 && startingStep === 0) {
    return [
      {from: 0, to: 0},
      {from: 0, to: 0}
    ];
  }
  const sizeOfSteps = maxStep - startingStep + 1;
  if (sizeOfSteps === 1) {
    return false;
  }
  const floor = Math.floor(sizeOfSteps / 2);
  const ceil = Math.ceil(sizeOfSteps / 2);
  const firstTo = startingStep + floor - 1;
  const secondFrom = maxStep - ceil + 1;
  return [
    {from: startingStep, to: firstTo},
    {from: secondFrom, to: maxStep}
  ];
}

export const getFilenameFromUrl = (url) => {
  const parts = url.split('/');
  const filenameWithExtension = parts[parts.length - 1];
  const filename = filenameWithExtension.split('.')[0];
  return filename;
};
export const downloadVideo = (videoUrl,fileName) => {
  fetch(videoUrl)
  .then(response => response.blob())
  .then(blob => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  })
  .catch(error => console.error(error));
};
export const getInitialsFromName= (name) => {
  if(name =='Me'){
    return 'Me'
  }
  const letters = String(name)
    .split(" ")
    .map((i) => i.charAt(0));
  return letters.join("");
}
export function convertDurationToDate(duration) {
  if(!duration || !duration?.seconds){
    return ""
  }
  const milliseconds = duration.seconds * 1000 + Math.round(duration.nanoseconds / 1e6);
  let date = new Date(milliseconds);
  let offset = date.getTimezoneOffset()
  date  = new Date(date.getTime() - (offset*60*1000))
  return date.toISOString().replace(/T/, ' ').replace(/\..+/, '');
}
export const getChatUser = (m,adminId,admins) => {
  return m.admin_id === adminId ? { title : 'Me', position: "right", status: "received", removeButton : true }    
  : { title : admins.get(m.admin_id), position: "left", status: "none", removeButton:false }
}
export const convertReactMentionToString = (str) => {
  str = str.replace(/\n/g, '<br>');
  const regex = /@\[([^\]]+)\]\((\d+)\)/g;
  let newStr = str;
  let match = regex.exec(str);
  const ids = [];

  while (match) {
    const [fullMatch, name, id] = match;
    ids.push(parseInt(id));
    newStr = newStr.replace(fullMatch, `@${name}`);
    match = regex.exec(str);
  }

  return [newStr, ids];
};
export const compressVideo = (file) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    const reader = new FileReader();
  
    reader.onload = (event) => {
      video.onloadedmetadata = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = video.videoWidth / 2; // Set the new width of the video
        canvas.height = video.videoHeight / 2; // Set the new height of the video
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          // The blob parameter contains the compressed video
          resolve(blob);
        }, 'video/mp4', 0.5); // Set the MIME type and compression quality of the video
      }
      video.src = event.target.result;
    }
  
    reader.readAsDataURL(file);
  });
}

export const getProceduresWithIPRSheet = (procedures) => {
  return procedures.filter((procedure) => "url" in procedure)
}

export const sortClinicReports = (a, b) => {
  const dateFormat = 'YYYY-MM-DD';
  // Parse the date strings using Moment.js
  const aDate = moment(a.date, dateFormat);
  const bDate = moment(b.date, dateFormat);

  // Compare the parsed dates in descending order
  const dateComparison = bDate.isBefore(aDate) ? -1 : bDate.isAfter(aDate) ? 1 : 0;

  // If the dates are the same, compare by version number as integers
  if (dateComparison === 0) {
    return b.version - a.version; // Compare as integers
  }

  return dateComparison;
}

export const isEmpty = (obj) => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }
  return true;
}

export const getUserTreatmentTypeBadge = (type) => {
  let badge;
  switch (type) {
    case "Light":
      badge = (
        <span className={tagsColor(type, "planType")}>
          <Badge>{type}</Badge>
        </span>
      );
      break;
    case "Medium":
      badge = (
        <span className={tagsColor(type, "planType")}>
          <Badge>{type}</Badge>
        </span>
      );
      break;
    case "Medium+":
      badge = (
        <span className={tagsColor(type, "planType")}>
          <Badge>{type}</Badge>
        </span>
      );
      break;
    case "Complex":
      badge = (
        <span className={tagsColor(type, "planType")}>
          <Badge>{type}</Badge>
        </span>
      );
      break;
    case "Complex+":
      badge = (
        <span className={tagsColor(type, "planType")}>
          <Badge>{type}</Badge>
        </span>
      );
      break;
    default:
      badge = <Badge>None</Badge>;
  }
  return badge;
};

export const getUserStatus = (status) => {
  switch(status) {
    case "Severe Case Pending":
      return "Review Severe Cases";
    case "Purchase Impression Kit":
      return "Pending Purchase Impression Kit";
    case "Taking Impressions and Photos":
      return "Uploading Impressions & Photos";
    case "Pending Taking Impressions and Photos":
      return "Review Impressions";
    case "Your Smile Plan":
      return "Qualified";
    case "Aligner Kit Purchased":
      return "Smile Journey";
    case "Completed":
      return "Completed";
    case "Your Photos":
      return "Screening";
    case "Pending purchase book a scan":
      return "Purchase Book A Scan";
    case "Customer booked appointment":
      return "Book A Scan Purchased";
    case "Account Created":
      return "Account Created";
    case "User Not Qualified":
      return "User Not Qualified";
    case "Free Book A Scan":
      return "Free Book A Scan";
    case "OTP Confirmed":
      return "OTP Confirmed";
    case "Qualified Both":
      return "Qualified Both";
    case "Pending Taking Impressions and Photos":
      return "Pending Taking Impressions and Photos";
    case "Qualified Top":
      return "Qualified Top";
    case "Qualified Bottom":
      return "Qualified Bottom";
    default:
      return "";
  }
}

export const switchUserStatusColors = (staus) => {
  switch (staus) {
    case "Screening":
    case "Book A Scan Your Photos":
      return "info";
    case "Qualified Both":
      return "success";
    case "Qualified Top":
      return "info";
    case "Qualified Bottom":
      return "attention";
    case "Refund Requested":
      return "critical";
    case "User Not Qualified":
      return "attention";
    case "Uploading Impressions & Photos":
      return "new";
    case "Review Impressions":
      return "info";
    case "Eligible With Redo Kit":
      return "info";
    case "Qualified":
      return "attention";
    case "Aligner Kit Purchased":
      return "critical";
    case "Smile Journey":
      return "success";
    case "From Crystalign":
      return "new";
    case "Account Created":
      return "info";
    case "Severe Case Pending":
      return "info";
    case "Severe Case Rejected":
      return "attention";
    case "Pending Purchase Impression Kit":
      return "critical";
    case "Purchase Book A Scan":
      return "new";
    case "Book A Scan Purchased":
      return "success";
    case "Impression Kit Purchased":
      return "success";
    case "Free Book A Scan":
      return "new";
    case "OTP Confirmed":
      return "critical";
    default:
      return null;
  }
}

export const userStatuses = [
  { label: 'OTP Confirmed', value: 'OTP Confirmed' },
  { label: 'Qualified', value: 'Your Smile Plan' },
  { label: 'Smile Journey', value: 'Aligner Kit Purchased' },
  { label: 'Completed', value: 'Completed' },
]