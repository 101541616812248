import { Badge, Icon, TextStyle } from '@shopify/polaris'
import React, { useEffect, useState } from 'react'
import TicketingFilter from './TicketingFilter/TicketingFilter';
import NavigationLayout from '../../components/NavigationLayout';
import history from '../../history';
import { Card } from '@mui/material';
import {
  ChevronLeftMinor,
  ChevronRightMinor
} from '@shopify/polaris-icons';
import {getStatusBadgeComponent, TIKCET_TYPE_ENUMS} from '../helpers';

function TicketingDashboard() {
  const [tickets, setTickets] = useState([])
  const [page, setPage] = useState(1)
  const [last, setLast] = useState(1)
  const getStatusWithBadge = (status) => {
    if (!status) {
      return '';
    }
    return <Badge status={status == 'Good' ? 'success' : "error"}>{status}</Badge>
  }
  const handleTicketClick = (item) => {
    history.push(`/admin/ticketing/${item.id}`);
  }
  return (
    <NavigationLayout>
      <Card sx={{height:"90%",margin:"2rem 0 0 2rem", borderRadius:"1rem"}}>
    <div style={{ display: "flex",height:'80vh' }}>
      <div className="payment-wrapper" style={{ width: '75%' }}>
        <table className='procedures-profile-table' style={{ display: "grid", width: "100%", overflow: "auto",maxHeight:"95%", position:"relative" }}>
          <thead style={{position:"sticky", top:0, backgroundColor:"white"}}>
            <tr style={{ display: "flex" }}>
              <th className='procedures-profile-th-s' ><TextStyle variation="strong">Ticket #</TextStyle></th>
              <th className='procedures-profile-th-s' ><TextStyle variation="strong">Status</TextStyle></th>
              <th className='procedures-profile-th' ><TextStyle variation="strong">Type</TextStyle></th>
              <th className='procedures-profile-th-s' ><TextStyle variation="strong">Priority</TextStyle></th>
              <th className='procedures-profile-th-s' ><TextStyle variation="strong">User ID</TextStyle></th>
              <th className='procedures-profile-th' ><TextStyle variation="strong">User Name</TextStyle></th>
              <th className='procedures-profile-th' ><TextStyle variation="strong">Team</TextStyle></th>
              <th className='procedures-profile-th' ><TextStyle variation="strong">Assigned To</TextStyle></th>
              <th className='procedures-profile-th' ><TextStyle variation="strong">Assigned By</TextStyle></th>
              <th className='procedures-profile-th' ><TextStyle variation="strong">Overall State</TextStyle></th>
              <th className='procedures-profile-th' ><TextStyle variation="strong">Title</TextStyle></th>
              <th className='procedures-profile-th' ><TextStyle variation="strong">First Response State</TextStyle></th>
              <th className='procedures-profile-th' ><TextStyle variation="strong">Progress State</TextStyle></th>
              <th className='procedures-profile-th' ><TextStyle variation="strong">Closed State</TextStyle></th>
            </tr>
          </thead>
          <tbody>
            {tickets.map((item, key) => (
              <tr style={{ display: "flex", cursor: "pointer" }} key={key} onClick={()=>{handleTicketClick(item)}}>
                <td className='procedures-profile-td-s' >
                  <div >{item.id}</div>
                </td>
                <td className='procedures-profile-td-s' >
                  <div >{getStatusBadgeComponent(item.status_by_user)}</div>
                </td>
                <td className='procedures-profile-td' >
                  <div >{TIKCET_TYPE_ENUMS[item.type]}</div>
                </td>
                <td className='procedures-profile-td-s'>
                  <div>{item.urgency}</div>
                </td>
                <td className='procedures-profile-td-s' >
                  <div >{item.subject_user_id}</div>
                </td>
                <td className='procedures-profile-td' >
                  <div >{item.subject_user.full_name}</div>
                </td>
                <td className='procedures-profile-td'><div style={{ minWidth: '15%' }}>{item.reported_to.team_member[0].team.team_name}</div></td>
                <td className='procedures-profile-td'>
                  <div>{item.reported_to.full_name}</div>
                </td>
                <td className='procedures-profile-td'>
                  <div>{item.reported_by.full_name}</div>
                </td>
                <td className='procedures-profile-td'>
                  <div>{[item.first_response_status, item.close_status, item.progress_status].every((val) => !val || val == 'Good') ? getStatusWithBadge('Good') : getStatusWithBadge('Overdue')}</div>
                </td>
                <td className='procedures-profile-td' >
                  <div>{item.text}</div>
                </td>
                <td className='procedures-profile-td'>
                  <div>{getStatusWithBadge(item.first_response_status)}</div>
                </td>
                <td className='procedures-profile-td'>
                  <div>{getStatusWithBadge(item.progress_status)}</div>
                </td>
                <td className='procedures-profile-td'>
                  <div>{getStatusWithBadge(item.close_status)}</div>
                </td>
              </tr>
            ))

            }
          </tbody>
        </table>
          <div style={{display:"flex", gap:"20px", alignItems:"center", justifyContent:"center",padding:"5px 0px"}}>
              <button style={{border:"none", cursor:"pointer", background:"transparent"}} onClick={() => setPage(page - 1)} disabled={page == 1}><Icon source={ChevronLeftMinor} color={page == 1 ? "base" : "primary"}/></button>
              <span style={{fontWeight:"bold"}}>{page} / {last}</span>
              <button style={{border:"none", cursor:"pointer", background:"transparent"}} disabled={page >= last} onClick={() => setPage(page + 1)}><Icon source={ChevronRightMinor} color={page >= last ?"base" : "primary"}/></button>
          </div>
      </div>
      <TicketingFilter setTickets={setTickets} page={page} setLast={setLast}/>
    </div>
    </Card>
    </NavigationLayout>
  )
}

export default TicketingDashboard