import React, { useCallback, useState, useEffect } from "react";
import {
  InlineError,
  Page,
  FormLayout,
  TextField,
  Card,
  Modal,
  PageActions,
  Thumbnail,
  Stack,
  DropZone,
  TextStyle,
  Checkbox, Toast,Tag
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";
import { useParams } from "react-router-dom";
import {
  NoteMinor,
} from "@shopify/polaris-icons";
import ReactTagInput from "@pathofdev/react-tag-input";
import Select2 from "react-dropdown-select";
import CorporateDeals from "./CorporateDeals";
import ClinicReports from "./ClinicReports";
import RichTextEditor from "react-rte";
import moment from 'moment/moment';

function Edit() {
  const { id, idCity, idClinic } = useParams();
  const [nameError, setNameError] = useState("");
  const [emailError,setEmailError] = useState("");
  const [popupActive, setPopupActive] = useState(false);
  const [files, setFiles] = useState([]);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const fileUpload = !files.length && <DropZone.FileUpload />;
  const [imageKey, setImageKey] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmationError, setPasswordConfirmationError] = useState("");
  const [selectedWeekends, setSelectedWeekends] = useState([])
  const [optionsWeekend, setOptions] = useState([{label:"Monday",value:1}, {label:"Tuesday",value:2}, {label:"Wednesday ",value:3}, {label:"Thursday",value:4}, {label:"Friday",value:5}, {label:"Saturday",value:6}, {label:"Sunday",value:7}]);
  const [productOptions, setProductOptions] = useState([]);
  const [scanner, setScanner] = useState({
    options: [],
    selected: [],
  });
  const [startDate, setStartDate] = useState("");
  const [clinicOptions, setClinicOptions] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [doctorsValues, setDoctorsValues] = useState([]);
  const [inputDoctorsValue, setInputDoctorsValue] = useState('');
  const [items,setItems]=useState([])
  const [isEmpty,setIsEmpty]=useState(false)
  const [success,setSuccess]=useState(0)
  const [error, setError] = useState("");
  const [active, setActive] = useState(false);
  const [messageData, setMessageData] = useState("");
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const [pricingData, setPricingData] = useState({
    bas: 0,
    displayed_bas_price_at_clinic: 0,
    bas_at_clinic: 0,
    opg: 0,
    xray: 0,
    cleaning: 0,
    dental_checkup: 0,
    extraction: 0,
    ipr: 0,
    buttons: 0,
    attachments: 0,
    buttonsRemoval: 0,
    attachmentsRemoval: 0,
    redoScan: 0,
    redoIk: 0,
    removeFixedRetainers: 0,
    redoScanPhotos: 0,
    refinementScan: 0,
    periodontal_checkup: 0,
    rebondAttachment: 0,
    redoScanErrorDental: 0,
    redoScanCleaning: 0,
    redoScanWire: 0,
    refinementScanError: 0,
    mcaScan: 0,
    mcaScanError: 0,
    retainer_scan: 0,
    expat: 0,
    incentive: 0,
    replace_powerchain: 0,
    mca_photos: 0,
    refinement_photos: 0,
    post_treatment_photos: 0,
    measure_spaces: 0,
    rebond_buttons: 0,
    remove_excess_around_attachments: 0,
    in_clinic_whitening: 0,
    referral_incentive: 0,
  });  
  const {
    bas,
    displayed_bas_price_at_clinic,
    bas_at_clinic,
    opg,
    xray,
    cleaning,
    dental_checkup,
    extraction,
    ipr,
    buttons,
    attachments,
    buttonsRemoval,
    attachmentsRemoval,
    redoScan,
    redoIk,
    removeFixedRetainers,
    redoScanPhotos,
    refinementScan,
    periodontal_checkup,
    rebondAttachment,
    redoScanErrorDental,
    redoScanCleaning,
    redoScanWire,
    refinementScanError,
    mcaScan,
    mcaScanError,
    retainer_scan,
    replace_powerchain,
    mca_photos,
    refinement_photos,
    post_treatment_photos,
    measure_spaces,
    rebond_buttons,
    remove_excess_around_attachments,
    in_clinic_whitening,
    expat,
    referral_incentive,
    incentive
  } = pricingData;

  const getClinicPricing = async () => {
    try {      
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/clinic-pricing/${idClinic}`
      );
      if (response) {
        const { clinicPricing } = response.data;
        if (clinicPricing) {          
        setPricingData(clinicPricing);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const updatePricing = async () => {
    try {
      setError("");
      if (
        bas !== "" &&
        displayed_bas_price_at_clinic !== "" &&
        bas_at_clinic !== "" &&
        opg !== "" &&
        xray !== "" &&
        cleaning !== "" &&
        dental_checkup !== "" &&
        extraction !== "" &&
        ipr !== "" &&
        buttons !== "" &&
        attachments !== "" &&
        buttonsRemoval !== "" &&
        attachmentsRemoval !== "" &&
        redoScan !== "" &&
        redoIk !== "" &&
        removeFixedRetainers !== "" &&
        redoScanPhotos !== "" &&
        refinementScan !== "" &&
        periodontal_checkup !== "" &&
        rebondAttachment !== "" &&
        redoScanErrorDental !== "" &&
        redoScanCleaning !== "" &&
        redoScanWire !== "" &&
        refinementScanError !== "" &&
        mcaScan !== "" &&
        mcaScanError !== "" &&
        retainer_scan !== "" &&
        replace_powerchain !== "" &&
        mca_photos !== "" &&
        refinement_photos !== "" &&
        post_treatment_photos !== "" &&
        measure_spaces !== "" &&
        rebond_buttons !== "" &&
        remove_excess_around_attachments !== "" &&
        in_clinic_whitening !== "" &&
        expat !== "" &&
        referral_incentive !== "" &&
        incentive !== ""
      ) {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/admin/clinic-pricing/${pricingData?.id}`,
          {
            ...pricingData,
            clinic_id: parseInt(idClinic),
            effective_date: startDate,
          },
        );
        if (response) {
          const { message } = response.data;
          setMessageData(message);
          setActive(true);
        }
        return;
      }
      setError("All Fields are required");
    } catch (error) {
      console.log(error);
    }
  } 
  
  const onChangeHandler = (e, check = false) => {
    const { name, value, checked } = e.target;
    if (check) {
      setPricingData((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
      return;
    }
    setPricingData((prevState) => ({ ...prevState, [name]: value }));
  };

  const inputStyle = {
    fontSize: "1.6rem",
    fontWeight: "400",
    lineHeight: "2.4rem",
    textTransform: "initial",
    letterSpacing: "initial",
    position: "relative",
    zIndex: "20",
    display: "block",
    flex: "1 1",
    width: "100%",
    minWidth: "0",
    minHeight: "3.6rem",
    margin: "0",
    padding: "0.5rem 1.2rem",
    background: "none",
    border: "0.1rem solid grey",
    borderRadius: "5px",
    fontFamily: "inherit",
  };

  const checkStyle = {
    minWidth: '20px',
    minHeight: '3.6rem',
  };

  useEffect(() => {
    getData();
    getClinicPricing();
  }, [success]);

  function getData() {
    axios
      .get(`/admin/v1/corporate_deals/${idClinic}`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        if(result.data.success==false){
          setIsEmpty(true);
          
        }
        else if (result.data.deals.length === 0) {
          setIsEmpty(true);
        }
        let res = [];
         res = result.data.deals.map((item)=>{
             return{
            id:item.id,   
            coupon_id:item.coupon_id,
            email:item.email,
            editable:false
             }
         })
        setItems(res);
      })
      .catch((err) => console.log(err));
  }

  const uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : NoteMinor
        }
      />
      <div>{files[files.length - 1].name} </div>
    </Stack>
  );
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const sendImage = useCallback(
    (files) => {
      setPopupActive(true);
      setImagePreview(
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );

      let url2 = "";
      const form_data = new FormData();
      form_data.append("content_type", files[files.length - 1].type);
      form_data.append("file_name", files[files.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          console.log("res1");
          setImageKey(res.data.key);
          url2 = res.data.url;
          axios
            .put(url2, files[files.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": files[files.length - 1].type,
              },
            })
            .then((res) => {
              console.log("res2");
              setPopupActive(false);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },

    [files]
  );
  const [object, setObject] = useState({
    nameEn: "",
    nameAr: "",
    descriptionEn: "",
    descriptionAr: "",
    locationInfoEn: "",
    locationInfoAr: "",
    addressEn: "",
    addressAr: "",
    longitude: "",
    latitude: "",
    nameIdentification: "",
    clinicId: "",
    youCanBookMeLink: "",
    locationLink: "",
    contactLink:"",
    profileId: null,
    phoneNumber1: "",
    phoneNumber2: "",
    doctors:[],
    email: "",
    notificationEmail: "",
    password:"",
    freeDomains: [],
    freeBAS: "",
    freeByDefault: "",
    is_offline: false,
    is_closed: false,
    is_screening_mandatory: false,
    basma_managed_clinic: false,
    isGhost: false,
    isMallCollaboration: false,
    isMainCityClinic: false,
    isReplacedByMainClinicCity: false,
    assignedTo: [],
    content:RichTextEditor.createEmptyValue()
  });
  const [errorMsg, setErrorMsg] = useState('');

  const handleChangePassword = useCallback((newValue) => {
    setPasswordValue(newValue);
    setPasswordError("");
  }, []);
  const handleChangePasswordConfirmation = useCallback((newValue) => {
    setPasswordConfirmation(newValue);   
  }, []);
  const handlePasswordConfirmation = (pass,passConf)=>{
    return pass === passConf;
  }
  function handleReplacedByMainCityClinic(checked) {
    setObject({...object, isReplacedByMainClinicCity: checked});
  }
  function handleGhostCheck(checked) {
    setObject({...object, isGhost: checked});
  }
  function handleMallCollabCheck (checked) {
    setObject({...object, isMallCollaboration: checked});
  }
  function handleIsMainCityClinicCheck(checked) {
    setObject({...object, isMainCityClinic: checked});
  }
  function handleCheckboxChange(checked) {
    setObject({...object, freeBAS: checked});
  }
  function handleCheckboxDefaultChange(checked) {
    setObject({...object, freeByDefault: checked});
  }
  function handleClosedChange(checked) {
    setObject({...object, is_closed: checked});
  }
  function handleOnlineDefaultChange(checked) {
    setObject({...object, is_offline: checked});
  }
  function handleMandatoryDefaultChange(checked) {
    setObject({...object, is_screening_mandatory: checked});
  }
  function handleBasmaManagedClinic(checked) {
    setObject({...object, basma_managed_clinic : checked});
  }
  function handleSelectWeekends(newSelectedWeekend) {
    setSelectedWeekends(newSelectedWeekend);
  }
  function handleSelectProducts(newSelectedProduct) {
    setSelectedProducts(newSelectedProduct);
  }
  function handleSelectScanner(scanner) {
    setScanner(prev => ({
      ...prev,
      selected: scanner,
    }));
  }
  function handleAssignedTo(assignedClinic) {
    setObject({...object, assignedTo: assignedClinic});
  }
  const handleAddDoctor = () => {
    if (inputDoctorsValue.trim() !== '') {
      setDoctorsValues((prevDoctorsValues) => [...prevDoctorsValues, inputDoctorsValue]);
      setInputDoctorsValue('');
      console.log("doctors",doctorsValues)
    }
    
  };
  const handleDoctorsTagRemove = useCallback((indexToRemove) => {
    setDoctorsValues((prevValues) =>
      prevValues.filter((_, index) => index !== indexToRemove)
    );
  }, []);

  useEffect(()=>{
    if(handlePasswordConfirmation(passwordValue,passwordConfirmation)){
      setPasswordConfirmationError("");
    }else{
      setPasswordConfirmationError("Password value should be equal");
    }
  },[passwordValue,passwordConfirmation])

  useEffect(() => {
    axios
      .get(
        `/admin/v1/countries/${id}/cities/${idCity}/clinics/${idClinic}/show`,
        {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      )
      .then((result) => {
        let clinics = result.data.clinics.map(clinic => ({label: clinic.name?.en, value: clinic.id}));
        let assignedClinic = clinics.find(clinic => clinic.value == result?.data?.data?.assigned_to);
        setClinicOptions(clinics);
        setProductOptions(result.data.products.map(prod => ({label: prod.title?.en, value: prod.id})));
        let scanners = result.data.scanners.map(scanner => ({label: `${scanner.name} (${scanner.placeholders} ${scanner.placeholders > 1 ? 'placeholders' : 'placeholder'})`, value: scanner.id}));
        setScanner({
          options: scanners,
          selected: [scanners.find(scanner => scanner.value == result?.data?.data?.scanner_id)],
        });
        setSelectedProducts(result.data.data.products.map(prod => ({label: prod.product.title?.en, value: prod.product.id})));
        setObject({
          nameEn: result?.data?.data?.name?.en ? result.data.data.name.en : "",
          nameAr: result?.data?.data?.name?.ar ? result.data.data.name.ar : "",
          descriptionEn: result?.data?.data?.description?.en
            ? result.data.data.description.en
            : "",
          descriptionAr: result?.data?.data?.description?.ar
            ? result.data.data.description.ar
            : "",
          locationInfoEn: result?.data?.data?.location_info?.en
            ? result.data.data.location_info.en
            : "",
          locationInfoAr: result?.data?.data?.location_info?.ar
            ? result.data.data.location_info.ar
            : "",
          addressEn: result?.data?.data?.address?.en
            ? result.data.data.address.en
            : "",
          addressAr: result?.data?.data?.address?.ar
            ? result.data.data.address.ar
            : "",
          longitude: result?.data?.data?.long ? result.data.data.long : "",
          latitude: result?.data?.data?.lat ? result.data.data.lat : "",
          nameIdentification: result?.data?.data?.name_identification?.en
            ? result.data.data.name_identification.en
            : "",
          clinicId: result?.data?.data?.clinic_id
            ? result.data.data.clinic_id
            : "",
          youCanBookMeLink: result?.data?.data?.youcanbookme_link
            ? result.data.data.youcanbookme_link
            : "",
          profileId: result?.data?.data?.profile_id
            ? result.data.data.profile_id
            : null,
          locationLink: result?.data?.data?.location_link
            ? result.data.data.location_link
            : "",
            contactLink: result?.data?.data?.contact_link
            ? result.data.data.contact_link
            : "",
          phoneNumber1: result?.data?.data?.phone_number_1
            ? result.data.data.phone_number_1
            : "",
          phoneNumber2: result?.data?.data?.phone_number_2
            ? result.data.data.phone_number_2
            : "",
          doctors: result?.data?.data?.doctors,
          email: result?.data?.data?.email ? result.data.data.email : "",
          notificationEmail: result?.data?.data?.notifications_email ?? "",
          freeBAS: result?.data?.data?.enable_free_booking,
          freeDomains: result?.data?.data?.free_booking_emails,
          freeByDefault: result?.data?.data?.free_by_default,
          is_offline : result?.data?.data?.is_offline,
          is_closed: result?.data?.data?.is_closed,
          is_screening_mandatory : result?.data?.data?.is_screening_mandatory,
          isGhost: result?.data?.data?.is_ghost,
          isMallCollaboration: result?.data?.data?.is_mall_collaboration,
          isMainCityClinic: result?.data?.data?.main_city_clinic,
          isReplacedByMainClinicCity: result?.data?.data?.replaced_by_main_city_clinic,
          assignedTo: assignedClinic ? [assignedClinic] : [],
          basma_managed_clinic : result?.data?.data?.basma_managed_clinic,
          content: result?.data?.data?.content ? RichTextEditor.createValueFromString(
            result.data.data.content,
            "html"
          ) : RichTextEditor.createEmptyValue(),
         });
        result?.data?.data?.main_image &&
          setImagePreview(result.data.data.main_image);
          if(result?.data?.data?.weekends){
            let weekendsarr=result?.data?.data?.weekends.split(",");
            let selected =  optionsWeekend.filter((weekendOption)=>{
              return weekendsarr.find(element => element == weekendOption.value); 
            })
            setSelectedWeekends(selected)
          };
          if( result?.data?.data?.doctors){
          setDoctorsValues(result.data.data.doctors);
          }
         else{ 
          setDoctorsValues([]);
         }    
          
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (startDate === "") {
      const currentMonth = moment().date(1);
      const formattedCurrentMonth = currentMonth.format("YYYY-MM");
      setStartDate(formattedCurrentMonth);
    }
  }, []);
  
  return (
    <Page
      title="Edit Clinic"
      breadcrumbs={[
        { url: `/admin/book-a-scan-countries/${id}/cities/${idCity}/clinics` },
      ]}
    >
      {active && messageData && (
        <Toast content={messageData} onDismiss={toggleActive} />
      )}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              value={object.nameEn}
              onChange={(newValue) =>
                setObject({ ...object, nameEn: newValue })
              }
              label="Name En"
              align="left"
            />
            <TextField
              value={object.nameAr}
              onChange={(newValue) =>
                setObject({ ...object, nameAr: newValue })
              }
              label="Name Ar"
              align="right"
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              value={object.descriptionEn}
              onChange={(newValue) =>
                setObject({ ...object, descriptionEn: newValue })
              }
              label="Description En"
              align="left"
            />
            <TextField
              value={object.descriptionAr}
              onChange={(newValue) =>
                setObject({ ...object, descriptionAr: newValue })
              }
              label="Description Ar"
              align="right"
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              value={object.locationInfoEn}
              onChange={(newValue) =>
                setObject({ ...object, locationInfoEn: newValue })
              }
              label="Location Info En"
              align="left"
            />
            <TextField
              value={object.locationInfoAr}
              onChange={(newValue) =>
                setObject({ ...object, locationInfoAr: newValue })
              }
              label="Location Info Ar"
              align="right"
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              value={object.addressEn}
              onChange={(newValue) =>
                setObject({ ...object, addressEn: newValue })
              }
              label="Address En"
              align="left"
            />
            <TextField
              value={object.addressAr}
              onChange={(newValue) =>
                setObject({ ...object, addressAr: newValue })
              }
              label="Address Ar"
              align="right"
            />
          </FormLayout.Group>

          <FormLayout.Group>
            <TextField
              value={object.longitude}
              onChange={(newValue) =>
                setObject({ ...object, longitude: newValue })
              }
              label="Longitude"
            />
            <TextField
              value={object.latitude}
              onChange={(newValue) =>
                setObject({ ...object, latitude: newValue })
              }
              label="Latitude"
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              value={object.nameIdentification}
              onChange={(newValue) =>
                setObject({ ...object, nameIdentification: newValue })
              }
              label="Name Identification"
              error={nameError}
            />

            <TextField
              value={object.clinicId}
              onChange={(newValue) =>
                setObject({ ...object, clinicId: newValue })
              }
              label="Clinic ID"
            />
          </FormLayout.Group>

          <FormLayout.Group>
            <TextField
              value={object.youCanBookMeLink}
              onChange={(newValue) =>
                setObject({ ...object, youCanBookMeLink: newValue })
              }
              label="You can book me Link"
            />
            <TextField
              disabled={!object.youCanBookMeLink}
              value={object.profileId}
              onChange={(newValue) =>
                setObject({ ...object, profileId: newValue })
              }
              label="Profile ID"
            />
          </FormLayout.Group>
          <FormLayout.Group>

          <TextField
            value={object.locationLink}
            onChange={(newValue) =>
              setObject({ ...object, locationLink: newValue })
            }
            label="Location Link"
          />
             <TextField
            value={object.contactLink}
            onChange={(newValue) =>
              setObject({ ...object, contactLink: newValue })
            }
            label="Contact Link"
          />
          </FormLayout.Group>
       
          <FormLayout.Group>
            <TextField
              value={object.phoneNumber1}
              onChange={(newValue) =>
                setObject({ ...object, phoneNumber1: newValue })
              }
              label="Phone Number 1"
            />
            <TextField
              value={object.phoneNumber2}
              onChange={(newValue) =>
                setObject({ ...object, phoneNumber2: newValue })
              }
              label="Phone Number 2"
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              value={object.email}
              onChange={(newValue) => setObject({ ...object, email: newValue })}
              label="Email"
              error={emailError}
            />
            <TextField
              value={object.notificationEmail}
              onChange={(newValue) => setObject({ ...object, notificationEmail: newValue })}
              label="Notification Email"
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <div>
              <TextField
                label="Doctors"
                value={inputDoctorsValue}
                onChange={setInputDoctorsValue}
                labelAction={{
                  content: 'Add Doctor',
                  onAction: handleAddDoctor,
                }}

              />
              <div>
                {doctorsValues.map((value, index) => (
                  <Tag key={index} onRemove={() => handleDoctorsTagRemove(index)}>
                    {value}
                  </Tag>
                ))}
              </div>
            </div>
          </FormLayout.Group>
          <FormLayout>
            <FormLayout.Group>
               <FormLayout>
                 <TextStyle variation="strong">Information for clinic</TextStyle>
                  <RichTextEditor
                        rootStyle={{
                          fontFamily:
                            "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif",
                        }}
                        className="rich-text-editor RichTextEditor_clinics "
                        value={object.content}
                        onChange={(value) =>setObject(prev => { return {...prev, content: value}})}
                      />
                </FormLayout>
            </FormLayout.Group>
          </FormLayout>
          <FormLayout.Group>
            <TextField
              value={passwordValue}
              onChange={(newValue) => {
                handleChangePassword(newValue);
                setObject({ ...object, password: newValue });
              }}
              label="Password"
              error={passwordError}
            />
            <TextField
              value={passwordConfirmation}
              onChange={handleChangePasswordConfirmation}
              label="Password Confirmation"
              error={passwordConfirmationError}
            />
          </FormLayout.Group>
          <TextStyle variation="strong">Image</TextStyle>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <Thumbnail
                size="large"
                source={imagePreview ? imagePreview : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZoneDrop} onDropAccepted={sendImage}>
                {uploadedFiles}
                {fileUpload}
              </DropZone>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", columnGap: 20 }}>
            <Checkbox
              label="Main City Clinic"
              checked={object.isMainCityClinic}
              onChange={handleIsMainCityClinicCheck}
            />
            {
              object.isMainCityClinic ?
              <Select2
                placeholder='Assign bookings to this clinic'
                options={clinicOptions}
                onChange={handleAssignedTo}
                values={object.assignedTo}
              /> :
              null
            }
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              label="Ghost Clinic"
              checked={object.isGhost}
              onChange={handleGhostCheck}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              label="Mall Collaboration"
              checked={object.isMallCollaboration}
              onChange={handleMallCollabCheck}
            />
          </div>
          <div style={{display: "flex", alignItems: "center"}}>
            <Checkbox
              label="Replaced by Main City Clinic"
              checked={object.isReplacedByMainClinicCity}
              onChange={handleReplacedByMainCityClinic}
            />
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{ width: '48%' }}>
              <TextStyle variation='strong'>Scan uploading method</TextStyle>
              <Select2
                placeholder='Select scanner'
                options={scanner.options}
                onChange={handleSelectScanner}
                values={scanner.selected}
              />
            </div>
            <div style={{ width: '48%' }}>
              <TextStyle variation='strong'>Scan at home</TextStyle>
              <Select2
                placeholder='Select Products'
                multi
                options={productOptions}
                onChange={handleSelectProducts}
                values={selectedProducts}
              />
            </div>
          </div>
          <FormLayout.Group>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                label="Enable Free BAS"
                checked={object.freeBAS}
                onChange={handleCheckboxChange}
              />
              <span
                style={{
                  fontSize: "12px",
                  color: "#d9d9db",
                  marginLeft: "2px",
                }}
              >
                {" "}
                (promo)
              </span>
            </div>
            <div>
              <span>Free Domains</span>
              <span style={{ fontSize: "12px", color: "#d9d9db" }}>
                {" "}
                (promo)
              </span>
              <ReactTagInput
                tags={object?.freeDomains ? object?.freeDomains : []}
                onChange={(newTags) => {
                  setObject({ ...object, freeDomains: newTags });
                }}
                removeOnBackspace
                editable
                validator={(email) => {
                  return (
                    String(email)
                      .toLowerCase()
                      .match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      ) ||
                    String(email)
                      .toLocaleLowerCase()
                      .match(
                        /^@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      )
                  );
                }}
              />
            </div>
            <div>
              <TextStyle variation="strong">Weekends</TextStyle>
              <Select2
                placeholder="Select Weekends"
                multi
                options={optionsWeekend}
                onChange={handleSelectWeekends}
                values={selectedWeekends}
              />
            </div>
          </FormLayout.Group>
          <FormLayout.Group>
            <Checkbox
              label="Free by default"
              checked={object.freeByDefault}
              onChange={handleCheckboxDefaultChange}
            />
            <Checkbox
              label="Is Offline"
              checked={object.is_offline}
              onChange={handleOnlineDefaultChange}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Checkbox
              label="Is Closed"
              checked={object.is_closed}
              onChange={handleClosedChange}
            />
            <Checkbox
              label="Mandatory Screening"
              checked={object.is_screening_mandatory}
              onChange={handleMandatoryDefaultChange}
            />
            <Checkbox
              label="Basma Managed Clinic"
              checked={object.basma_managed_clinic}
              onChange={handleBasmaManagedClinic}
            />
          </FormLayout.Group>
        </FormLayout>
        <CorporateDeals
          clinic_id={idClinic}
          items={items}
          setItems={setItems}
          isEmpty={isEmpty}
          setIsEmpty={setIsEmpty}
          success={success}
          setSuccess={setSuccess}
        />
      </Card>
      <ClinicReports idClinic={idClinic} clinicName={object.nameEn}/>
      <Card
        title="Clinic Pricing"
        sectioned
        primaryFooterAction={{ content: "Save", onAction: updatePricing }}
        actions={[
          {
            content: (
              <div style={{ display: "flex", columnGap: "5px" }}>
                <div>
                  <input
                    id="start-date"
                    style={{ padding: "8px", borderRadius: "5px" }}
                    type="month"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                    name="startDate"
                  />
                </div>
              </div>
            ),
          },
          { content: "Edit Bulk Pricing", url: "/admin/reports" },
        ]}
      >
        {error && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            <InlineError message={error} fieldID={""} />
          </div>
        )}
        <FormLayout>
          <FormLayout.Group>
            <div style={{ display: "grid" }}>
              <label htmlFor="bas">FIRST VISIT</label>
              <input
                id="bas"
                type="number"
                name="bas"
                value={bas}
                style={inputStyle}
                onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="displayed_bas_price_at_clinic">DISPLAYED FIRST VISIT PRICE</label>
              <input
                id="displayed_bas_price_at_clinic"
                type="number"
                name="displayed_bas_price_at_clinic"
                value={displayed_bas_price_at_clinic}
                style={inputStyle}
                onChange={onChangeHandler}
              />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  id="bas_at_clinic"
                  type="checkbox"
                  name="bas_at_clinic"
                  checked={bas_at_clinic == 1}
                  style={checkStyle}
                  onChange={(e) => onChangeHandler(e, true)}
                />
                <label htmlFor="bas_at_clinic">PAID FIRST VISIT AT CLINIC</label>
              </div>
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="redoScan">REDO SCAN - SCAN ERROR</label>
              <input
                  id="redoScan"
                  type="number"
                  name="redoScan"
                  value={redoScan}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="redoScanErrorDental">
                REDO SCAN - POST DENTAL WORK
              </label>
              <input
                  id="redoScanErrorDental"
                  type="number"
                  name="redoScanErrorDental"
                  value={redoScanErrorDental}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="redoScanCleaning">REDO SCAN - POST CLEANING</label>
              <input
                  id="redoScanCleaning"
                  type="number"
                  name="redoScanCleaning"
                  value={redoScanCleaning}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="redoScanWire">REDO SCAN - POST WIRE REMOVAL</label>
              <input
                  id="redoScanWire"
                  type="number"
                  name="redoScanWire"
                  value={redoScanWire}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="redoScanPhotos">REDO SCANS PHOTOS</label>
              <input
                  id="redoScanPhotos"
                  type="number"
                  name="redoScanPhotos"
                  value={redoScanPhotos}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="refinementScan">REFINEMENT SCAN</label>
              <input
                  id="refinementScan"
                  type="number"
                  name="refinementScan"
                  value={refinementScan}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="refinementScanError">
                REFINEMENT - SCAN ERROR
              </label>
              <input
                  id="refinementScanError"
                  type="number"
                  name="refinementScanError"
                  value={refinementScanError}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="refinement_photos">
                REFINEMENT PHOTOS
              </label>
              <input
                  id="refinement_photos"
                  type="number"
                  name="refinement_photos"
                  value={refinement_photos}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="mcaScan">MCA SCAN</label>
              <input
                  id="mcaScan"
                  type="number"
                  name="mcaScan"
                  value={mcaScan}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="mcaScanError">MCA SCAN - SCAN ERROR</label>
              <input
                  id="mcaScanError"
                  type="number"
                  name="mcaScanError"
                  value={mcaScanError}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="mca_photos">MCA PHOTOS</label>
              <input
                  id="mca_photos"
                  type="number"
                  name="mca_photos"
                  value={mca_photos}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="retainer_scan">RETAINER SCAN</label>
              <input
                  id="retainer_scan"
                  type="number"
                  name="retainer_scan"
                  value={retainer_scan}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="ipr">IPR</label>
              <input
                  id="ipr"
                  type="number"
                  name="ipr"
                  value={ipr}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="attachments">ADD ATTACHMENTS</label>
              <input
                  id="attachments"
                  type="number"
                  name="attachments"
                  value={attachments}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="attachmentsRemoval">REMOVE ATTACHMENTS</label>
              <input
                  id="attachmentsRemoval"
                  type="number"
                  name="attachmentsRemoval"
                  value={attachmentsRemoval}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="rebondAttachment">REBOND ATTACHMENT</label>
              <input
                  id="rebondAttachment"
                  type="number"
                  name="rebondAttachment"
                  value={rebondAttachment}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="buttons">BUTTONS</label>
              <input
                  id="buttons"
                  type="number"
                  name="buttons"
                  value={buttons}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="buttonsRemoval">BUTTONS REMOVAL</label>
              <input
                  id="buttonsRemoval"
                  type="number"
                  name="buttonsRemoval"
                  value={buttonsRemoval}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="replace_powerchain">REPLACE POWERCHAIN</label>
              <input
                  id="replace_powerchain"
                  type="number"
                  name="replace_powerchain"
                  value={replace_powerchain}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="removeFixedRetainers">
                REMOVE FIXED RETAINERS
              </label>
              <input
                  id="removeFixedRetainers"
                  type="number"
                  name="removeFixedRetainers"
                  value={removeFixedRetainers}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="opg">OPG</label>
              <input
                id="opg"
                type="number"
                name="opg"
                value={opg}
                style={inputStyle}
                onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="xray">XRAY</label>
              <input
                  id="xray"
                  type="number"
                  name="xray"
                  value={xray}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="cleaning">CLEANING</label>
              <input
                  id="cleaning"
                  type="number"
                  name="cleaning"
                  value={cleaning}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="extraction">Extraction</label>
              <input
                  id="Extraction"
                  type="number"
                  name="extraction"
                  value={extraction}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="dental_checkup">DENTAL CHECKUP</label>
              <input
                  id="DENTAL CHECKUP"
                  type="number"
                  name="dental_checkup"
                  value={dental_checkup}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="periodontal_checkup">PERIODONTAL CHECKUP</label>
              <input
                  id="periodontal_checkup"
                  type="number"
                  name="periodontal_checkup"
                  value={periodontal_checkup}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="redoIk">REDO IK</label>
              <input
                  id="redoIk"
                  type="number"
                  name="redoIk"
                  value={redoIk}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="post_treatment_photos">POST TREATMENT PHOTOS</label>
              <input
                  id="post_treatment_photos"
                  type="number"
                  name="post_treatment_photos"
                  value={post_treatment_photos}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>            
            <div style={{ display: "grid" }}>
              <label htmlFor="measure_spaces">MEASURE SPACES</label>
              <input
                  id="measure_spaces"
                  type="number"
                  name="measure_spaces"
                  value={measure_spaces}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>            
            <div style={{ display: "grid" }}>
              <label htmlFor="rebond_buttons">REBOND BUTTONS</label>
              <input
                  id="rebond_buttons"
                  type="number"
                  name="rebond_buttons"
                  value={rebond_buttons}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>            
            <div style={{ display: "grid" }}>
              <label htmlFor="remove_excess_around_attachments">REMOVE EXCESS AROUND ATTACHMENTS</label>
              <input
                  id="remove_excess_around_attachments"
                  type="number"
                  name="remove_excess_around_attachments"
                  value={remove_excess_around_attachments}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="in_clinic_whitening">IN-CLINIC WHITENING</label>
              <input
                  id="in_clinic_whitening"
                  type="number"
                  name="in_clinic_whitening"
                  value={in_clinic_whitening}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="expat">EXPAT %</label>
              <input
                  id="expat"
                  type="number"
                  name="expat"
                  value={expat}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="referral_incentive">REFERRAL INCENTIVE %</label>
              <input
                id="referral_incentive"
                type="number"
                name="referral_incentive"
                value={referral_incentive}
                style={inputStyle}
                onChange={onChangeHandler}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label htmlFor="incentive">INCENTIVE</label>
              <input
                  id="incentive"
                  type="number"
                  name="incentive"
                  value={incentive}
                  style={inputStyle}
                  onChange={onChangeHandler}
              />
            </div>
            <div></div>
            <div></div>
          </FormLayout.Group>
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            onClick: () =>
              axios
                .delete(
                  `/admin/v1/countries/${id}/cities/${idCity}/clinics/${idClinic}/delete`,
                  {
                    headers: {
                      Authorization: "Bearer " + Cookie.get("token"),
                    },
                  }
                )
                .then((result) => {
                  history.push(
                    `/admin/book-a-scan-countries/${id}/cities/${idCity}/clinics`
                  );
                })
                .catch((err) => console.log(err)),
          },
        ]}
      />
      <div style={{display: 'flex', justifyContent: 'end'}}>
        <TextStyle variation='negative'>{errorMsg}</TextStyle>
      </div>
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );

  function handleSave() {
    if(errorMsg){
      setErrorMsg('');
    }
    if (!object.nameIdentification) {
      setNameError("Please enter a name Identification");
    } else {
      setPopupActive(true);
      let bodyObj = null;
      if(object.password && object.password != ''){
         bodyObj = {
          password: object.password,
          password_confirmation:passwordConfirmation,
          name: {
            ...(object.nameEn && { en: object.nameEn }),
            ...(object.nameAr && { ar: object.nameAr }),
          },
          description: {
            ...(object.descriptionEn && { en: object.descriptionEn }),
            ...(object.descriptionAr && { ar: object.descriptionAr }),
          },
          address: {
            ...(object.addressEn && { en: object.addressEn }),
            ...(object.addressAr && { ar: object.addressAr }),
          },
          location_info: {
            ...(object.locationInfoEn && { en: object.locationInfoEn }),
            ...(object.locationInfoAr && { ar: object.locationInfoAr }),
          },
          ...(object.nameIdentification && {
            name_identification: object.nameIdentification,
          }),
          ...(object.clinicId && {
            clinic_id: object.clinicId,
          }),
          ...(object.longitude && {
            long: object.longitude,
          }),
          ...(object.latitude && {
            lat: object.latitude,
          }),
          ...(object.youCanBookMeLink && {
            youcanbookme_link: object.youCanBookMeLink,
          }),
          ...(object.profileId && {
            profile_id: object.profileId,
          }),
          ...(object.locationLink && {
            location_link: object.locationLink,
          }),
          ...(object.contactLink && {
            contact_link: object.contactLink,
          }),
          ...(object.phoneNumber1 && {
            phone_number_1: object.phoneNumber1,
          }),
          ...(object.phoneNumber2 && {
            phone_number_2: object.phoneNumber2,
          }),
          ...(object.content && {
            content: object.content.toString('html'),
          }),
          doctors:doctorsValues,
          ...(object.email && {
            email: object.email,
          }),
           ...(object.notificationEmail && {
            notifications_email: object.notificationEmail,
           }),
          enable_free_booking: object.freeBAS,
          free_booking_emails: object.freeDomains,
          free_by_default: object.freeByDefault,
          ...(imageKey && {
            main_image: imageKey,
          }),
          is_offline : object.is_offline,
          is_screening_mandatory : object.is_screening_mandatory,
          weekends: selectedWeekends,
          products: selectedProducts,
          scanner_id: scanner.selected.length > 0 ? scanner.selected[0].value : null,
          is_ghost: object.isGhost,
          is_mall_collaboration: object.isMallCollaboration,
          main_city_clinic: object.isMainCityClinic,
          replaced_by_main_city_clinic: object.isReplacedByMainClinicCity,
          ...(object.assignedTo.length == 1 && {
            assigned_to: object.assignedTo[0].value,
          }),
        };
      }else{
         bodyObj = {
          name: {
            ...(object.nameEn && { en: object.nameEn }),
            ...(object.nameAr && { ar: object.nameAr }),
          },
          description: {
            ...(object.descriptionEn && { en: object.descriptionEn }),
            ...(object.descriptionAr && { ar: object.descriptionAr }),
          },
          address: {
            ...(object.addressEn && { en: object.addressEn }),
            ...(object.addressAr && { ar: object.addressAr }),
          },
          location_info: {
            ...(object.locationInfoEn && { en: object.locationInfoEn }),
            ...(object.locationInfoAr && { ar: object.locationInfoAr }),
          },
          ...(object.nameIdentification && {
            name_identification: object.nameIdentification,
          }),
          ...(object.clinicId && {
            clinic_id: object.clinicId,
          }),
          ...(object.longitude && {
            long: object.longitude,
          }),
          ...(object.latitude && {
            lat: object.latitude,
          }),
          ...(object.youCanBookMeLink && {
            youcanbookme_link: object.youCanBookMeLink,
          }),
          ...(object.profileId && {
            profile_id: object.profileId,
          }),
          ...(object.locationLink && {
            location_link: object.locationLink,
          }),
          ...(object.contactLink && {
            contact_link: object.contactLink,
          }),
          ...(object.phoneNumber1 && {
            phone_number_1: object.phoneNumber1,
          }),
          ...(object.phoneNumber2 && {
            phone_number_2: object.phoneNumber2,
          }),
          ...(object.content && {
            content: object.content.toString('html'),
          }),
          ...(object.assignedTo.length == 1 && {
            assigned_to: object.assignedTo[0].value,
          }),
          doctors:doctorsValues,
          ...(object.email && {
            email: object.email,
          }),
           ...(object.notificationEmail && {
            notifications_email: object.notificationEmail,
           }),
          enable_free_booking: object.freeBAS,
          free_booking_emails: object.freeDomains,
          free_by_default: object.freeByDefault,
          ...(imageKey && {
            main_image: imageKey,
          }),
          is_offline : object.is_offline,
          is_screening_mandatory : object.is_screening_mandatory,
          is_closed: object.is_closed,
          basma_managed_clinic : object.basma_managed_clinic,
          weekends: selectedWeekends,
          products: selectedProducts,
          scanner_id: scanner.selected.length > 0 ? scanner.selected[0].value : null,
          is_ghost: object.isGhost,
          is_mall_collaboration: object.isMallCollaboration,
          main_city_clinic: object.isMainCityClinic,
          replaced_by_main_city_clinic: object.isReplacedByMainClinicCity,
        };
      }
      axios
        .post(
          `/admin/v1/countries/${id}/cities/${idCity}/clinics/${idClinic}/update`,
          bodyObj,
          {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          }
        )
        .then((res) => {
          if(res.data.success){
            history.push(
              `/admin/book-a-scan-countries/${id}/cities/${idCity}/clinics`
            );
          } else {
            setErrorMsg(res.data.message);
            setPopupActive(false);
          }
        })
        .catch((err) => {
          setPopupActive(false);
          if(err?.email){
            setEmailError("Email already in use. Please use another email.");
          }
          if(err?.password){
            setPasswordError(err?.password[0])
          }
        }
      );
    }
  }
}
export default Edit;
