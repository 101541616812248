import {
  Badge,
  Banner,
  Button,
  Card,
  Layout,
  Modal,
  Spinner,
  TextContainer,
  TextStyle,
} from '@shopify/polaris';
import '@zendeskgarden/react-pagination/dist/styles.css';
import { Link, useHistory, useParams } from 'react-router-dom';
import ViewDeliveryBatchesTable from './ViewDeliveryBatches/view-delivery-batches-table.component';
import React, { useEffect, useRef, useState } from 'react';
import axios from '../axios';
import Cookie from 'js-cookie';
import moment from 'moment/moment';
import { TextArea } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { Dangerous } from '@mui/icons-material';

export default function DispatchDetails({
  formatReceivedData,
  getDispatches,
  loading,
  handleClickScroll,
  newDispatchData,
  markAsAlignersInProduction,
  message,
  clinicName,
  clinicStatus,
  planType,
  status,
  pageTitle,
  markAlignerInProductionLoading,
  isRetainerOnly,
  purchasedAlignerKit,
}) {
  const { id } = useParams();
  const [note, setNote] = useState('');
  const [open, setOpen] = useState(false);
  const [batchId, setBatchId] = useState('');
  const [openActionModal, setOpenActionModal] = useState(false);
  const [batch, setBatch] = useState({ id: '', type: '' });

  const checkBoxRef = useRef(null);
  const handleDelayDate = (item) => {
    let paymentDelay,
      actualPaymentDate,
      targetPaymentDate,
      targetDispatch,
      actualDispatch,
      productionDelay,
      targetReceipt,
      actualReceipt,
      logisticsDelay;

    const {
      target_dispatch_date = null,
      actual_dispatch_date = null,
      expected_receipt_date = null,
      actual_receipt_date = null,
      expected_last_payment_due_date = null,
      actual_last_payment_due_date = null,
    } = item;

    targetDispatch = target_dispatch_date ? moment(target_dispatch_date) : null;
    actualDispatch = actual_dispatch_date ? moment(actual_dispatch_date) : null;
    if (targetDispatch && actualDispatch) {
      productionDelay =
        actualDispatch.diff(targetDispatch, 'days') > 0
          ? `Production Delay: ${Math.abs(
              actualDispatch.diff(targetDispatch, 'days')
            )} Days`
          : '';
    } else {
      productionDelay = null;
    }

    targetReceipt = expected_receipt_date
      ? moment(expected_receipt_date)
      : null;
    actualReceipt = actual_receipt_date ? moment(actual_receipt_date) : null;
    if (targetReceipt && actualReceipt) {
      logisticsDelay =
        actualReceipt.diff(targetReceipt, 'days') > 0
          ? `Logistics Delay: ${Math.abs(
              actualReceipt.diff(targetReceipt, 'days')
            )} Days`
          : '';
    } else {
      logisticsDelay = null;
    }

    targetPaymentDate = expected_last_payment_due_date
      ? moment(expected_last_payment_due_date)
      : null;
    actualPaymentDate = actual_last_payment_due_date
      ? moment(actual_last_payment_due_date)
      : null;

    if (targetPaymentDate && actualPaymentDate) {
      paymentDelay =
        actualPaymentDate.diff(targetPaymentDate, 'days') > 0
          ? `Payments Delay: ${Math.abs(
              actualPaymentDate.diff(targetPaymentDate, 'days')
            )} Days`
          : '';
    } else {
      paymentDelay = null;
    }

    if (!productionDelay && !logisticsDelay && !paymentDelay) {
      return null;
    }

    return (
      <div style={{ display: 'grid' }}>
        {<span>{logisticsDelay}</span>}
        {<span>{productionDelay}</span>}
        {<span>{paymentDelay}</span>}
      </div>
    );
  };

  const handleOpen = (id, note) => {
    setBatchId(id);
    setNote(note);
    setOpen(true);
  };

  const handleGetItemInfo = (batchId, type) => {
    setOpenActionModal(true);
    setBatch({ id: batchId, type });
  };

  const handleClose = () => {
    setOpen(false);
    setNote('');
  };

  const handleAddTracking = (id, userId, edit) => {
    let url = `/admin/orders/${id}/trackings/dispatch`;
    if (!edit) {
      url += '/add';
    }
    history.push(url, { userId });
  };

  const handlePaymentStatus = (item) => {
    if (item.pendingPayments && item.pendingPayments.length > 0) {
      return (
        <div style={{ display: 'flex', gap: '2px', justifyContent: 'center' }}>
          {item?.pendingPayments?.map(({ color, key }, index) => {
            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '5px',
                }}
              >
                <span
                  style={{
                    backgroundColor: color,
                    fontWeight: 'bold',
                    color: 'black',
                    fontSize: '12px',
                    padding: '0px 5px',
                    borderRadius: '50%',
                  }}
                >
                  {key}
                </span>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const batchesActions = async (batchId, type) => {
    const query = `/admin/v1/users/${id}/batch/${batchId}`;
    const body = {
      type,
    };
    await axios.post(query, body, {
      headers: {
        Authorization: `Bearer ${Cookie.get('token')}`,
      },
    });
    toast.success(
      `Marked as ${
        type === 'dispatch' ? 'dispatched' : 'received'
      } successfully`,
      3000
    );
    await getDispatches();
  };

  const handleAddNote = async () => {
    try {
      const body = { note, duplicate: checkBoxRef.current.checked }
      const response = await axios.post(
        `admin/v1/dispatches/${batchId}/add-note`,
        body,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get('token')}`,
          },
        }
      );
      if (response.status === 200) {
        handleClose();
        await getDispatches();
      }
    } catch (error) {
      console.log('Error in dispatch note api', error);
    } finally {
    }
  };

  let data = null;
  let warningPayments = false;
  if (newDispatchData?.data && newDispatchData?.data.length > 0) {
    data = newDispatchData.data;
    data = formatReceivedData(data);
    for (let i = 0; i < data.length; i++) {
      if (data[i].smile_plan && data[i].smile_plan.payment_warning) {
        warningPayments = true;
        break;
      }
    }
  }

  let isNotEmpty = data && data.length > 0;

  const handleModalConfirmAction = () => {
    void batchesActions(batch.id, batch.type);
    setOpenActionModal(false);
  };

  const history = useHistory();
  const handleEditDispatches = () => {
    history.push(`/admin/users/${id}/dashboard/edit-dispatches`, {
      clinicName: clinicName,
      planType: planType,
      status: status,
      pageTitle: pageTitle,
      clinicStatus: clinicStatus,
      userId: id,
      batches: newDispatchData?.data,
      dispatchesType: newDispatchData?.dispatchesType,
    });
  };
  
  let disableEditDispatches = !data || data.length === 0;
  if (isRetainerOnly && !purchasedAlignerKit) {
    disableEditDispatches = false;
  }

  useEffect(() => {
    void getDispatches();
  }, []);

  return (
    <Layout.Section>
      <Card
        sectioned
        title="UPCOMING DISPATCHES"
        actions={[
          {
            content: 'Refresh',
            onClick: getDispatches,
            disabled: loading,
          },
          {
            content: 'Edit',
            onAction: handleEditDispatches,
            disabled: disableEditDispatches,
          },
        ]}
      >
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              backgroundColor: 'white',
              padding: '15px',
              paddingBottom: '50px',
              marginTop: '15px',
              marginBottom: '15px',
            }}
          >
            <Spinner accessibilityLabel="Loading Dispatches" size="large" />
          </div>
        ) : (
          <div>
            {(newDispatchData?.reason === 1 ||
              newDispatchData?.reason === 2) && (
              <>
                <br />
                <Banner>
                  {newDispatchData?.reason === 1 ? (
                    <>
                      This user has incomplete{' '}
                      <Link onClick={handleClickScroll}>procedure</Link>
                    </>
                  ) : (
                    <>No Dispatch data found for this user</>
                  )}
                </Banner>
              </>
            )}
            <br />
            {isNotEmpty && newDispatchData?.dispatchesType && (
              <div
                style={{
                  marginBottom: '16px',
                  marginTop: '-62px',
                  marginLeft: '190px',
                }}
              >
                <Badge status="success">
                  {newDispatchData?.dispatchesType}
                </Badge>
              </div>
            )}
            {warningPayments && (
              <>
                <div
                  style={{
                    marginBottom: "16px",
                    marginTop: "-40px",
                    marginLeft: "250px",
                    display: "flex",
                    alignItems: "center",
                    columnGap: "5px",
                  }}
                >
                  <span>Pending payments are outside dispatch schedule</span>
                  <Tooltip title="Pending payments are outside dispatch schedule">
                    <Dangerous fontSize="large" color="error" />
                  </Tooltip>
                </div>
              </>
            )}
            {isNotEmpty && (
              <ViewDeliveryBatchesTable
                batches={data}
                handleDelayDate={handleDelayDate}
                handleOpen={handleOpen}
                handlePaymentStatus={handlePaymentStatus}
                handleGetItemInfo={handleGetItemInfo}
                handleAddTracking={handleAddTracking}
              />
            )}
            <br />
            <Button
              loading={markAlignerInProductionLoading}
              primary
              onClick={() => markAsAlignersInProduction(null)}
            >
              Aligners in Production
            </Button>
            <br />
            <br />
            <TextStyle variation="negative">{message}</TextStyle>
          </div>
        )}
      </Card>
      <Modal open={open} onClose={handleClose}>
        <div style={{ padding: '20px', textAlign: 'center' }}>
          <center>
            <TextStyle variation="strong">Add Note</TextStyle>
          </center>
          <br />
          <TextArea
            rows={8}
            style={{ width: '90%' }}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <FormGroup style={{ width: "90%", margin: "0 auto" }}>
            <FormControlLabel
              control={
                <Checkbox inputRef={checkBoxRef} />
              }
              label="Copy note to upcoming batches"
            />
          </FormGroup>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '30px',
            }}
          >
            <Button primary onClick={handleAddNote}>
              Send Note
            </Button>
            <Button onClick={handleClose}>Close</Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={openActionModal}
        onClose={() => setOpenActionModal(false)}
        title="Action"
        primaryAction={{
          content: 'No',
          onAction: () => setOpenActionModal(false),
        }}
        secondaryActions={[
          {
            content: 'Yes',
            onAction: handleModalConfirmAction,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure you want to make this action?</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </Layout.Section>
  );
}
