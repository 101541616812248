import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { TextField } from "@shopify/polaris";
import axios from "../axios";
import ProcedureHeader from "./ProcedureHeader";
import Cookies from "js-cookie";
import Review from "./Review";
import Save from "./Save";

const useStyles = makeStyles(() => {
  return {
    wrapper: {
      padding: "2rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
  };
});

const AppointmentCheckup = ({
  appointmentId,
  userId,
  appointmentType,
  handleTabsExpand,
  index,
  openCloseTabs,
  scrollToExpandedTab,
}) => {
  const { procedure } = appointmentType;
  const classes = useStyles();
  const [information, setInformation] = useState(null);
  const [iconInformation, setIconInformation] = useState(null);
  const [success, setSuccess] = useState(false);
  const [popupActive, setPopupActive] = useState(false);
  const [informationErrorMessage, setInformationErrorMessage] = useState("");
  const [data, setData] = useState(null);

  const handleDataFetched = () => {
    setPopupActive(true);
    axios.get(`admin/v2/users/checkup-information/${appointmentId}?procedure=${procedure}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then(res => {
      if (res.data.success) {
        setInformation(res.data.data);
        setIconInformation(res.data.isAllProvided);
      } else {
        setIconInformation(0);
      }
    }).catch(err => {
      console.log('fetch error', err);
    }).finally(f => {
      setPopupActive(false);
    })
  };

  const handleChange = (value, type) => {
    setSuccess(false);
    setData((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const handleChangeData = (value) => {
    setData({
      information: value?.information,
      review_status: value?.review_status,
      reviewer: value?.reviewer,
      reviewed_at: value?.reviewed_at,
    });
  };

  const handleReviewStatus = (status) => {
    if(status){
      const form_data = new FormData();
      form_data.append('review_status', status);
      form_data.append('appointment_id', appointmentId);
      form_data.append('procedure', procedure);
      axios.post(`/admin/v2/users/checkup-information/${information?.id}/approval-status`, form_data, {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('token'),
        },
      }).then((res) => {
        handleDataFetched();
      }).catch((err) => {
        console.log(err);
      })
    }
  };

  const handleSave = async () => {
    if (informationErrorMessage === "") {
      let body = {
        ...data,
        procedure: procedure,
        user_scan_info_id: appointmentId,
      };
      try {
        const response = await axios.post(`/admin/v2/users/${userId}/checkup-information`, body, {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        });
        handleDataFetched();
      } catch (error) {
        console.log(error);
        setSuccess(false);
        setInformationErrorMessage("Something wrong happened");
      }
    }
  };

  useEffect(() => {
    handleDataFetched();
  }, []);

  useEffect(() => {
    handleChangeData(information);
  }, [information]);

  useEffect(() => {
    scrollToExpandedTab(index, 'information');
  }, [openCloseTabs]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type='information'
      headerTitle={<p><span className="procedure-header-title">Information:</span> <span className='header-title-gray'>{procedure}</span></p>}
      index={index}
      iconCheck={iconInformation}
    >
      {openCloseTabs[index]?.information && (
        <Box className={`${classes.wrapper} information-${index}`}>
          {popupActive ?
            <div style={{ textAlign: 'center' }}>
              <CircularProgress color='primary' />
            </div> :
            <>
              <TextField
                label='Information'
                value={data?.information ? data?.information : ''}
                onChange={(e) => handleChange(e, 'information')}
                multiline={4}
                autoComplete=""
              />
              <br />
              <Review disabled={iconInformation == 0 || data?.review_status != null} reviewStatus={data?.review_status} reviewer={data?.reviewer} reviewedAt={data?.reviewed_at} handleChange={() => handleReviewStatus('Reviewed')} />
              <Save uploadedBy={iconInformation != 0 ? information?.owner_name : ''} handleSave={handleSave} success={success} errorMessage={informationErrorMessage} />
              </>
            }
        </Box>
      )}
    </ProcedureHeader>
  );
};

export default AppointmentCheckup;
