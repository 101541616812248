import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  main: {
    '& div, table': {
      fontFamily: 'Calibri Light',
      fontSize: '18px',
      width: '100%',
      padding: '0 16px 0 0',
      backgroundColor: '#fff',
    },
    '& hr': {
      margin: '16px 0',
    },
    '& td, th': {
      border: '0.2px solid #dddddd',
      textAlign: 'left',
      padding: '8px',
    },
    '& .table-pdf': {
      margin: '0 0 8px',
    },
    '& .table-pdf table': {
      width: '100%',
      borderCollapse: 'collapse',
    },
    '& h2, h3': {
      fontWeight: 'bold',
      fontSize: '20px',
    },
    '& span': {
      display: 'block',
      margin: '8px 0',
    },
  },
  section: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
    marginBottom: '16px',
    '& h1': {
      fontSize: '22px',
      fontWeight: 'bold',
    },
  },
  shipmentTo: {
    borderRight: '1px solid black',
    marginRight: '8px',
  },
  shipmentFrom: {
    marginLeft: '8px',
  },
}));
