import React, { useCallback, useState, useEffect } from "react";
import {
  InlineError,
  Page,
  FormLayout,
  TextField,
  Card,
  Modal,
  PageActions,
  Checkbox,
} from "@shopify/polaris";
import axios from "../../axios";
import Cookie from "js-cookie";
import history from "../../history";
import { useParams } from "react-router-dom";

function Edit() {
  const { id, idCity } = useParams();
  const [nameEnValue, setNameEnValue] = useState("");
  const [nameArValue, setNameArValue] = useState("");
  const [codeValue, setCodeValue] = useState("");
  const [popupActive, setPopupActive] = useState(false);
  const [nameError, setNameError] = useState("");
  const [checkHideOptions, setCheckHideOptions] = useState(false);
  const handleChangeNameEn = useCallback((newValue) => {
    setNameEnValue(newValue);
  }, []);
  const handleChangeNameAr = useCallback((newValue) => {
    setNameArValue(newValue);
  }, []);
  const handleCode = useCallback((newValue) => {
    setCodeValue(newValue);
  }, []);
  const handleHideOptions = (
    (newChecked) => setCheckHideOptions(newChecked)
  );
  useEffect(() => {
    axios
      .get(`/admin/v1/countries/${id}/cities/${idCity}/show`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        result?.data?.data?.name?.en &&
          setNameEnValue(result.data.data.name.en);
        result?.data?.data?.name?.ar &&
          setNameArValue(result.data.data.name.ar);
        result?.data?.data?.code && 
          setCodeValue(result.data.data.code);
        result?.data?.data?.hide_options && 
          setCheckHideOptions(result.data.data.hide_options);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page
      title="Edit  City"
      breadcrumbs={[{ url: `/admin/book-a-scan-countries/${id}/cities` }]}
    >
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              value={nameEnValue}
              onChange={handleChangeNameEn}
              label="Name En"
              align="left"
            />
            <TextField
              value={nameArValue}
              onChange={handleChangeNameAr}
              label="Name Ar"
              align="right"
            />
          </FormLayout.Group>
          {nameError}
          <FormLayout.Group>
            <TextField
              value={codeValue}
              onChange={handleCode}
              label="Code"
              align="left"
            />
          </FormLayout.Group>
            <Checkbox
              label="Hide Options page"
              checked={checkHideOptions}
              onChange={handleHideOptions}
            />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            onClick: () =>
              axios
                .delete(`admin/v1/countries/${id}/cities/${idCity}/delete`, {
                  headers: {
                    Authorization: "Bearer " + Cookie.get("token"),
                  },
                })
                .then((result) => {
                  history.push(`/admin/book-a-scan-countries/${id}/cities`);
                })
                .catch((err) => console.log(err)),
          },
        ]}
      />
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );

  function handleSave() {
    if (!nameArValue && !nameEnValue) {
      setNameError(
        <InlineError message="Please enter a name" fieldID="myFieldID" />
      );
    } else {
      setPopupActive(true);
      const bodyObj = {
        name: {
          ...(nameEnValue && { en: nameEnValue }),
          ...(nameArValue && { ar: nameArValue }),
        },
        ...(codeValue && { code: codeValue }),
        ...({ hide_options: checkHideOptions}),
      };
      axios
        .post(`/admin/v1/countries/${id}/cities/${idCity}/update`, bodyObj, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          history.push(`/admin/book-a-scan-countries/${id}/cities`);
        })
        .catch((err) => console.log(""));
    }
  }
}
export default Edit;
