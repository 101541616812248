import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Card,
  Layout,
  TextStyle,
  Toast,
  FormLayout,
} from "@shopify/polaris";
import Cookie from "js-cookie";
import axios from "../../../../axios";
import { useParams } from "react-router-dom";
import moment from "moment";

//
function ApproveRedos() {
  const { id } = useParams();
  const format = "YYYY-MM-DD HH:mm:ss";
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const [toastMessage, setToastMessage] = useState("");
  const toastMarkup = active ? (
    <Toast content={toastMessage} onDismiss={toggleActive} />
  ) : null;
  const [approvedDateValue, setApprovedDateValue] = useState("");
  const handleApproveRedoScan = () => {
    const bodyObj = { approved: true };
    axios
      .post(`/admin/v1/users/${id}/approve-redo-scan`, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        setToastMessage("Redo Scan Approved");
        setActive(true);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    axios
      .get(`admin/v1/users/${id}/approve-redo-kit `, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        if(result.data.data){
          setApprovedDateValue(moment(result.data.data).format(format));
        }
      })
      .catch((err) => console.log(err));
  }, [active]);

  return (
    <Layout.Section>
      <Card title="APPROVE REDOS" sectioned>
        <FormLayout>
          <Button primary onClick={handleApproveRedos}>
            Approve Impression Kit Redo
          </Button>
          {approvedDateValue && (
            <TextStyle>
              Impression Kit Redo Approved on : {approvedDateValue}
            </TextStyle>
          )}
          <Button onClick={handleApproveRedoScan}>Approve Redo Scan</Button>
        </FormLayout>
      </Card>
      {toastMarkup}
    </Layout.Section>
  );

  function handleApproveRedos() {
    const body = { approved: true };
    axios
      .post(`admin/v1/users/${id}/approve-redo-kit`, body, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setToastMessage("Redo Kit Approved");
        setActive(true);
      })
      .catch((err) => console.log(err));
  }
}

export default React.memo(ApproveRedos);
