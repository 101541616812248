import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import "./Plans.css";

function Plans(props) {
  const { id } = useParams();
  const [paymentOptions, setPaymentOptions] = useState([]);

  const renderPrice = (price, currency, className) => {
    return (
      <span className={className}>
        {currency} {price}
      </span>
    );
  };

  const classifyPlans = (arrayOfPlans) => {
    const plansByCase = {};
    arrayOfPlans.forEach((item) => {
      const caseType = item.case;
      const plans = item.plans;
      plansByCase[caseType] = plans;
    });
    let name =
      props.planType.charAt(0).toUpperCase() +
      props.planType.slice(1) +
      " Case";
    setPaymentOptions(plansByCase[name] ?? []);
  };

  useEffect(() => {
    classifyPlans(props.paymentOptions);
  }, [props.paymentOptions]);

  return (
    <>
      {" "}
      <div className="payment-options-container">
        {false ? (
          <div className="loading"></div>
        ) : (
          paymentOptions.map((option, ind) => (
            <div className="payment-option" key={ind}>
              <h3 className="title">{option.title.en}</h3>
              <hr className="plans-hr"/>
              <div className="totalPrice">
                {renderPrice(option.total, option.currency, "price")}
              </div>
              {option.month === 0 ? (
                <p className="description">{option.description.en}</p>
              ) : null}

              <div className="price-container">
                {option.month !== 0 ? (
                  <div className="installments">
                    {(option.type === "tabby" || option.type === "cashew") &&
                    option.down_payment > 0 ? (
                      <>
                        <p className="price-label">First payment:</p>
                        {renderPrice(
                          option.down_payment,
                          option.currency,
                          "monthly-pay"
                        )}
                      </>
                    ) : (option.type === "tabby" || option.type === "cashew") &&
                      option.down_payment === 0 ? (
                      <>
                        <p className="price-label">First payment:</p>
                        {renderPrice(
                          option.monthly_pay,
                          option.currency,
                          "monthly-pay"
                        )}
                      </>
                    ) : null}
                    <p className="price-label">Monthly payments:</p>
                    {renderPrice(
                      option.monthly_pay,
                      option.currency,
                      "monthly-pay"
                    )}
                  </div>
                ) : null}
              </div>
              <button className="purchase-button">Purchase</button>
            </div>
          ))
        )}
      </div>
    </>
  );
}

export default Plans;
