import {
  Button,
  ButtonGroup,
  Caption,
  Card,
  DataTable,
  DisplayText,
  DropZone,
  Icon,
  Image,
  Modal,
  Spinner,
  Stack,
  TextContainer,
  TextStyle,
  Thumbnail,
  Toast,
} from "@shopify/polaris";
import moment from "moment/moment";
import { PageDownMajor, PageUpMajor, ViewMinor } from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useState } from "react";
import { TextArea } from "semantic-ui-react";
import axios from "../../../axios";
import Cookies from "js-cookie";
import { saveAs } from "file-saver";
import emptyIcon from "../../../img/emptyList.svg";
import Cookie from "js-cookie";
import { useStyles } from "./clinic-reports.styles";
import { ToastContainer, toast } from "react-toastify";
import PaymentConfirmationInvoiceModel from "./payment-confirmation-invoice-model.component";
import {sortClinicReports} from "../../../util/helpers";
import VerifiedIcon from '@mui/icons-material/Verified';
import DeleteIcon from '@mui/icons-material/Delete';
import { Input, MenuItem, Select} from '@material-ui/core'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

const ClinicReports = ({ clinicName, idClinic }) => {
  const classes = useStyles();
  const [clinicReportsData, setClinicReportsData] = useState([]);
  const [activeDisputeModal, setActiveDisputeModal] = useState(false);
  const [activeGenerateNewReportModal, setActiveGenerateNewReportModal] =
    useState(false);
  const [filesGuide, setFilesGuide] = useState([]);
  const [activePaymentConfirmationModal, setActivePaymentConfirmationModal] =
    useState(false);
  const [activeNoteModal, setActiveNoteModal] = useState(false);
  const [clinicReportLoading, setClinicReportLoading] = useState(false);
  const [dispute, setDispute] = useState({});
  const [report, setReport] = useState({ reportId: 0, note: "", date: "" });
  const [startDate, setStartDate] = useState("");
  const [pending, setPending] = useState(false);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(false);
  const [paymentConfirmationInvoiceFile, setPaymentConfirmationInvoiceFile] =
    useState("");
  const [uploadingFilesLoading, setUploadingFilesLoading] = useState(false);
  const [manualEntries, setManualEntries] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const fileUploadGuide = !filesGuide.length && <DropZone.FileUpload />;
  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const { note, date } = report;
  const buttonStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "3px",
  };

  const clinicReportsHeadings = [
    "Month",
    "Accepted Date",
    "Disputed Date",
    "Download Report",
    "Report Version",
    "Invoice Uploaded Date",
    "Notes",
    "Payment Confirmation Invoice",
    "Actions",
  ];

  const transactionOptions = [
    { value: '+', label: '+' },
    { value: '-', label: '-' }
  ];

  const typeOptions = [
    { label: 'First Visit', value: 'First Visit'},
    { label: 'Attachments', value: 'Attachments'},
    { label: 'Attachments Removal', value: 'Attachments Removal'},
    { label: 'Buttons', value: 'Buttons'},
    { label: 'Buttons Removal', value: 'Buttons Removal'},
    { label: 'Cleaning', value: 'Cleaning'},
    { label: 'Dental Checkup', value: 'Dental Checkup'},
    { label: 'Extraction', value: 'Extraction'},
    { label: 'IPR', value: 'IPR'},
    { label: 'MCA - Scan', value: 'MCA - Scan'},
    { label: 'MCA Scan - Scan Error', value: 'MCA Scan - Scan Error'},
    { label: 'Replace Powerchain', value: 'Replace Powerchain'},
    { label: 'OPG', value: 'OPG'},
    { label: 'Periodontal Checkup', value: 'Periodontal Checkup'},
    { label: 'Rebond Attachment', value: 'Rebond Attachment'},
    { label: 'Redo Ik', value: 'Redo Ik'},
    { label: 'Redo Scan - Scan Error', value: 'Redo Scan - Scan Error'},
    { label: 'Redo Scan - Post Cleaning', value: 'Redo Scan - Post Cleaning'},
    { label: 'Redo Scan - Post Dental Work', value: 'Redo Scan - Post Dental Work'},
    { label: 'Redo Scan - Post Wire Removal', value: 'Redo Scan - Post Wire Removal'},
    { label: 'Redo Scan Photos', value: 'Redo Scan Photos'},
    { label: 'Refinement Scan', value: 'Refinement Scan'},
    { label: 'Refinement Scan - Scan Error', value: 'Refinement Scan - Scan Error'},
    { label: 'Remove Fixed Retainers', value: 'Remove Fixed Retainers'},
    { label: 'Retainer Scan', value: 'Retainer Scan'},
    { label: 'X-ray', value: 'X-ray'},
    { label: 'Post Treatment Photos', value: 'Post Treatment Photos'},
    { label: 'Measure Spaces', value: 'Measure Spaces'},
    { label: 'Rebond Buttons', value: 'Rebond Buttons'},
    { label: 'User paid at the clinic', value: 'User paid at the clinic'},
  ];

  const getClinicReports = async () => {
    try {
      setClinicReportLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/v1/cms-report/${idClinic}`,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      if (response) {
        const clinicReportData = response?.data?.data;
        if (clinicReportData) {
            clinicReportData.sort(sortClinicReports);
            setClinicReportsData(clinicReportData);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setClinicReportLoading(false);
    }
  };

  const downloadReport = (link, date) => {
    saveAs(link, `${clinicName}-${date}.pdf`);
  };

  const downloadPaymentConfirmation = (link, date) => {
    saveAs(link, `${clinicName}-${date}.${link.split(".").pop()}`);
  };

  const downloadInvoice = (link, date, version) => {
    saveAs(link, `Invoice-${clinicName}-${date}-V.${version}.${link.split(".").pop()}`);
  };

  const downloadDisputeFiles = async () => {
    window.open(
      `${process.env.REACT_APP_BASE_URL}/admin/v1/clinic-dispute/${dispute.id}/download-zip`
    );
    handleClose("dispute");
  };

  const generateNewReport = async () => {
    if(!date) {
      setErrorMsg('Enter start date');
      return;
    }

    let proceed = true;
    for(let i = 0; i < manualEntries.length; i++) {
      let entry = manualEntries[i];
      if(!entry?.user || !entry?.type || !entry?.date || !entry?.price || !entry?.transaction) {
        proceed = false;
        break;
      }
    }

    if(!proceed) {
      setErrorMsg('Remove empty entries / Fill all required fields');
      return;
    }

    if(errorMsg) {
      setErrorMsg('');
    }

    try {
      setPending(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/v1/clinic-report/create`,
        {
          clinic_id: idClinic,
          date: moment(date).startOf('month').format('YYYY-MM-DD'),
          manual_entries: manualEntries.map(entry => ({
            user_id: entry?.user_id,
            full_name: entry?.user?.full_name,
            type: entry?.type,
            date: entry?.date,
            transaction: entry?.transaction,
            price: entry?.price,
            reason: entry?.reason,
          })),
        },
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      if (response) {
        const { message, success } = response.data;
        if (success) {
          setMessage(message);
          setActive(true);
          setManualEntries([]);
          setStartDate('');
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setPending(false);
      await getClinicReports();
      handleClose();
      setReport({ reportId: 0, note: "", date: "" });
    }
  };

  const uploadPaymentConfirmationInvoice = async () => {
    try {
      setPending(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/v1/payment-confirmation-invoice/create`,
        {
          clinic_report_id: report.reportId,
          files: paymentConfirmationInvoiceFile,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      if (response) {
        const { success } = response.data;
        if (success) {
          await getClinicReports();
          toast.success(
            "Payment Confirmation Invoice Uploaded Successfully",
            3000
          );
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Upload Failed", 3000);
    } finally {
      setPending(false);
      setPaymentConfirmationInvoiceFile("");
      handleClose("payment");
    }
  };

  const handleOpen = (type = "", dispute = {}, report = {}) => {
    setReport(report);
    setErrorMsg("");
    if (type === "dispute") {
      setDispute(dispute);
      setActiveDisputeModal(true);
    } else if (type === "note") {
      setActiveNoteModal(true);
    } else if (type === "payment") {
      setActivePaymentConfirmationModal(true);
    } else {
      setActiveGenerateNewReportModal(true);
    }
  };

  const handleClose = (type = "") => {
    if (type === "dispute") {
      setActiveDisputeModal(false);
    } else if (type === "note") {
      setActiveNoteModal(false);
    } else if (type === "payment") {
      setActivePaymentConfirmationModal(false);
    } else {
      setActiveGenerateNewReportModal(false);
    }
    setFilesGuide([]);
  };

  const sendImageGuide = useCallback(
    (filesGuide) => {
      setUploadingFilesLoading(true);
      let imageType = filesGuide[filesGuide.length - 1].type;
      let url2 = "";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append("file_name", filesGuide[filesGuide.length - 1].name);

      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        })
        .then((res) => {
          url2 = res.data.url;
          setPaymentConfirmationInvoiceFile(res.data.key);
          axios
            .put(url2, filesGuide[filesGuide.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then(() => {
              setUploadingFilesLoading(false);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },

    [filesGuide]
  );

  const handleDropZoneGuide = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesGuide((filesGuide) => [...filesGuide, ...acceptedFiles]),
    []
  );

  let uploadedFilesGuide = filesGuide.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesGuide[filesGuide.length - 1].name}
        source={
          validImageTypes.indexOf(filesGuide[filesGuide.length - 1].type) > 0
            ? window.URL.createObjectURL(filesGuide[filesGuide.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesGuide[filesGuide.length - 1].name}{" "}
        <Caption>{filesGuide[filesGuide.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );

  const addNewRow = () => {
    setManualEntries(prev => [...prev, {}]);
  }

  const removeManualEntry = (index) => {
    let entries = manualEntries.filter((entry, key) => {
      if(key != index) {
        return entry;
      }
    });
    setManualEntries([...entries]);
  }

  const handleOnChange = (type, value, index) => {
    if(type == 'price') {
      const regex = /^[0-9\b]+$/;
      if (value === '' || regex.test(value)) {
        value = value;
      } else {
        value = '';
      }
    }
    let entries = [...manualEntries];
    entries[index][type] = value;
    setManualEntries(entries);
  }

  const checkUser = (userId, index) => {
    let entries = [...manualEntries];
    entries[index]['user_loading'] = true;
    setManualEntries(entries);
    axios.get(`admin/v1/users/${userId}/get-user`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    ).then(res => {
      let entries = [...manualEntries];
      let user = null;
      if(res.data.success) {
        user = res.data.user;
        entries[index]['user_error'] = '';
      } else {
        user = null;
        entries[index]['user_error'] = res.data.message;
      }
      entries[index]['user'] = user;
      setManualEntries(entries);
    }).catch(err => {
      console.log('err', err);
    }).finally(() => {
      let entries = [...manualEntries];
      entries[index]['user_loading'] = false;
      setManualEntries(entries);
    });
  }

  useEffect(() => {
    getClinicReports();
  }, []);

  if (clinicReportLoading) {
    return (
      <div className={classes.loadingContainer}>
        <Spinner accessibilityLabel="Loading Clinics Pricing" size="large" />
      </div>
    );
  }

  return (
    <>
      {active && message && (
        <Toast content={message} onDismiss={toggleActive} />
      )}
      {/* Dispute Modal */}
      <Modal
        title="Dispute"
        open={activeDisputeModal}
        onClose={() => handleClose("dispute")}
        primaryAction={{
          content: "Download Dispute Files",
          onAction: () => downloadDisputeFiles("dispute"),
        }}
        secondaryActions={[
          {
            content: "Close",
            onAction: () => handleClose("dispute"),
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>{dispute?.text}</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
      {/* Note Modal */}
      <Modal
        title="Notes"
        open={activeNoteModal}
        onClose={() => handleClose("note")}
        secondaryActions={[
          {
            content: "Close",
            onAction: () => handleClose("note"),
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <div className={classes.noteContainer}>{note}</div>
          </TextContainer>
        </Modal.Section>
      </Modal>
      {/* Generate Report new Version with notes */}
      <Modal
        title="Manual Report Entries"
        open={activeGenerateNewReportModal}
        onClose={handleClose}
        large
        primaryAction={{
          content: "Generate New Report Version",
          onAction: generateNewReport,
          loading: pending,
        }}
        secondaryActions={[
          {
            content: "Close",
            disabled: pending,
            onAction: handleClose,
          },
        ]}
      >
        <Modal.Section>
          <TableContainer className='manual-report-entries-container' style={{height: manualEntries.length ? '28vh' : '12vh'}}>
            <Table className='manual-report-entries-table'>
              <TableHead className='manual-report-entries-table-thead'>
                <TableRow className='manual-report-entries-table-row'>
                  <TableCell className='procedures-profile-th-l'>User ID</TableCell>
                  <TableCell className='procedures-profile-th-l'>Full Name</TableCell>
                  <TableCell className='procedures-profile-th-l'>Type</TableCell>
                  <TableCell className='procedures-profile-th'>Transaction</TableCell>
                  <TableCell className='procedures-profile-th-l'>Price</TableCell>
                  <TableCell className='procedures-profile-th-xl'>Date</TableCell>
                  <TableCell className='procedures-profile-th-xl'>Reason</TableCell>
                  <TableCell className='procedures-profile-th'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  manualEntries.map((manualEntry, key) => {
                    return (
                      <TableRow key={key} className='manual-report-entries-table-row'>
                        <TableCell className='procedures-profile-td-l'>
                          <div className='clinic-report-manual-user-input'>
                            <Input
                              type="number"
                              variant='outlined'
                              value={manualEntry?.user_id ? manualEntry.user_id : ''}
                              placeholder='Enter User ID'
                              disabled={manualEntry?.user_loading}
                              onChange={(e) => handleOnChange('user_id', e.target.value, key)}
                            />
                            <div
                              className='clinic-report-manual-icon'
                              onClick={() => {
                                if (manualEntry?.user_id) {
                                  checkUser(manualEntry?.user_id, key);
                                }
                              }}
                            >
                              <VerifiedIcon
                                color={(!manualEntry?.user_id || manualEntry?.user_loading) ? 'disabled' : 'success'}
                              />
                            </div>
                          </div>
                          <p className='clinic-report-manual-user-id-error'>{manualEntry?.user_error}</p>
                        </TableCell>
                        <TableCell className='procedures-profile-td-l'>{manualEntry?.user?.full_name}</TableCell>
                        <TableCell className='procedures-profile-td-l'>
                          <Select
                            className='clinic-report-manual-proc-select'
                            onChange={(e) => handleOnChange('type', e.target.value, key)}
                            value={manualEntry?.type ? manualEntry.type : ''}
                            disabled={!manualEntry?.user}
                          >
                            {
                              typeOptions.map((proc, key) => {
                                return (
                                  <MenuItem key={key} value={proc.value}>{proc.label}</MenuItem>
                                )
                              })
                            }
                          </Select>
                        </TableCell>
                        <TableCell className='procedures-profile-td'>
                          <Select
                            className='clinic-report-manual-transaction-select'
                            onChange={(e) => handleOnChange('transaction', e.target.value, key)}
                            value={manualEntry?.transaction ? manualEntry.transaction : ''}
                            disabled={!manualEntry?.user}
                          >
                            {
                              transactionOptions.map((transaction, key) => {
                                return (
                                  <MenuItem key={key} value={transaction.value}>{transaction.label}</MenuItem>
                                )
                              })
                            }
                          </Select>
                        </TableCell>
                        <TableCell className='procedures-profile-td-l'>
                          <div className='clinic-report-manual-user-input'>
                            <Input
                              className='clinic-report-manual-input'
                              variant='outlined'
                              type='number'
                              inputProps={{ min: 0 }}
                              value={manualEntry?.price ? manualEntry.price : ''}
                              onChange={e => handleOnChange('price', e.target.value, key)}
                              disabled={!manualEntry?.user}
                            />
                          </div>
                        </TableCell>
                        <TableCell className='procedures-profile-td-xl'>
                          <div className='clinic-report-manual-user-input'>
                            <input
                              id='start-date'
                              className='clinic-report-manual-date-picker'
                              type='datetime-local'
                              value={manualEntry?.date ? manualEntry.date : ''}
                              onChange={(e) => handleOnChange('date', e.target.value, key)}
                              disabled={!manualEntry?.user}
                            />
                          </div>
                        </TableCell>
                        <TableCell className='procedures-profile-td-xl'>
                          <TextArea
                            value={manualEntry?.reason ? manualEntry.reason : ''}
                            rows={8}
                            style={{ width: '100%' }}
                            onChange={(e) => handleOnChange('reason', e.target.value, key)}
                            disabled={!manualEntry?.user}
                          />
                        </TableCell>
                        <TableCell className='procedures-profile-td'>
                          <div className={classes.btn} onClick={() => removeManualEntry(key)}>
                            <DeleteIcon color='action' fontSize='large' />
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
          <div className='clinic-report-manual-actions'>
            <Button primary onClick={addNewRow}>Add New</Button>
          </div>
          <p className='clinic-report-manual-user-id-error'>{errorMsg}</p>
        </Modal.Section>
      </Modal>

      {/* Payment Confirmation Model */}
      <PaymentConfirmationInvoiceModel
        activePaymentConfirmationModal={activePaymentConfirmationModal}
        handleClose={handleClose}
        uploadPaymentConfirmationInvoice={uploadPaymentConfirmationInvoice}
        pending={pending}
        uploadingFilesLoading={uploadingFilesLoading}
        handleDropZoneGuide={handleDropZoneGuide}
        uploadedFilesGuide={uploadedFilesGuide}
        sendImageGuide={sendImageGuide}
        fileUploadGuide={fileUploadGuide}
      />

      <Card
        title="Clinic Reports"
        actions={[
          {
            content: (
              <div style={{ display: "flex", columnGap: "5px" }}>
                <div>
                  <input
                    id="start-date"
                    style={{ padding: "8px", borderRadius: "5px" }}
                    type="month"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                    name="startDate"
                  />
                </div>
                <Button
                  onClick={() =>
                    handleOpen("", {}, { reportId: 0, note, date: startDate })
                  }
                  primary
                >
                  Generate Report Version
                </Button>
              </div>
            ),
          },
        ]}
        sectioned
      >
        <ToastContainer />
        <DataTable
          columnContentTypes={[
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
          ]}
          headings={clinicReportsHeadings.map((heading, index) => {
            return (
              <TextStyle key={index} variation="strong">
                {heading}
              </TextStyle>
            );
          })}
          rows={
            clinicReportsData.length > 0
              ? clinicReportsData.map(
                  ({
                    id,
                    accepted_at,
                    disputed_at,
                    date,
                    aws_url_link,
                    dispute,
                    payment_confirmation_invoice,
                    note,
                    version,
                    invoice,
                    can_generate,
                  }) => [
                    moment(date).format("MMMM YYYY"),
                    accepted_at
                      ? moment(accepted_at).format("DD MMMM YYYY")
                      : "",
                    disputed_at
                      ? moment(disputed_at).format("DD MMMM YYYY")
                      : "",
                    <Button
                      onClick={() => downloadReport(aws_url_link, date)}
                      primary
                    >
                      <div style={buttonStyle}>
                        <Icon source={PageDownMajor} color="base" />
                        Report
                      </div>
                    </Button>,
                    `V.${version}`,
                    invoice?.created_at
                      ? moment(invoice.created_at).format("DD MMMM YYYY")
                      : "-",
                    <Button
                      disabled={!note}
                      onClick={() =>
                        handleOpen("note", {}, { reportId: id, note, date })
                      }
                      primary
                    >
                      <div style={buttonStyle}>
                        <Icon source={ViewMinor} color="base" /> Note
                      </div>
                    </Button>,
                    <ButtonGroup>
                      <Button
                        disabled={!invoice}
                        onClick={() =>
                          handleOpen(
                            "payment",
                            {},
                            { reportId: id, note, date }
                          )
                        }
                        primary
                      >
                        <div style={buttonStyle}>
                          <Icon source={PageUpMajor} color="base" />
                          Payment Confirmation
                        </div>
                      </Button>
                      <Button
                        disabled={!payment_confirmation_invoice}
                        onClick={() =>
                          downloadPaymentConfirmation(
                            payment_confirmation_invoice.files_link,
                            date
                          )
                        }
                        primary
                      >
                        <div style={buttonStyle}>
                          <Icon source={ViewMinor} color="base" /> View
                        </div>
                      </Button>
                    </ButtonGroup>,
                    <ButtonGroup>
                      <Button
                        disabled={!dispute}
                        onClick={() => handleOpen("dispute", dispute)}
                        primary
                      >
                        <div style={buttonStyle}>
                          <Icon source={ViewMinor} color="base" /> Dispute
                        </div>
                      </Button>
                      <Button
                        disabled={!can_generate}
                        onClick={() =>
                          handleOpen("", {}, { reportId: id, note, date })
                        }
                        primary
                      >
                        Generate Report Version
                      </Button>
                      <Button
                        disabled={!invoice}
                        onClick={() =>
                          downloadInvoice(invoice.files_link, date, version)
                        }
                        primary
                      >
                        <div style={buttonStyle}>
                          <Icon source={PageDownMajor} color="base" />
                          Invoice
                        </div>
                      </Button>
                    </ButtonGroup>,
                  ]
                )
              : []
          }
        />
        {clinicReportsData.length === 0 && (
          <div className="message-no-result">
            <div className="message-no-result-img">
              <Image src={emptyIcon} alt="empty" source=""></Image>
            </div>
            <DisplayText size="small">No Generated Reports Found</DisplayText>
            <br />
          </div>
        )}
      </Card>
    </>
  );
};
export default ClinicReports;
