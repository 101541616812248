import { IconButton, TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import { DispatchTypeCodes, DispatchesSubTypes, GeneralFormats } from '../../util/constants';
import { capitalizeWords } from '../../util/helpers';
import { Tooltip } from '@material-ui/core';
import { AddCircle, FlightLand, FlightTakeoff } from '@mui/icons-material';
import SubdirectoryArrowRightSharpIcon from '@mui/icons-material/SubdirectoryArrowRightSharp';
import React from 'react';

const ViewDeliveryBatchesRow = ({
  batchItem,
  handleDelayDate,
  handleOpen,
  handlePaymentStatus,
  handleGetItemInfo,
  handleAddTracking,
}) => {
  const {
    id = null,
    treatment_stage = '-',
    batch = '-',
    sub_type = '-',
    delivery,
    target_dispatch_date = null,
    actual_dispatch_date = null,
    expected_receipt_date = null,
    actual_receipt_date = null,
    expected_start_of_batch = null,
    expected_start_of_last_step_in_batch = null,
    expected_last_payment_due_date = null,
    actual_last_payment_due_date = null,
    user_id,
    note = null,
    disabled,
    color,
    parentId = null,
    active = false,
  } = batchItem;

  const calculateDelaysObject = {
    target_dispatch_date,
    actual_dispatch_date,
    expected_receipt_date,
    actual_receipt_date,
    expected_last_payment_due_date,
    actual_last_payment_due_date,
  };

  let name = '-';

  if (batchItem?.type?.name) {
    name = batchItem.type.name;
  }

  let upperAlignersSteps,
    lowerAlignersSteps,
    upperRem,
    lowerRem,
    upperProductQuantity,
    lowerProductQuantity;
  if (batchItem.details.length > 0) {
    batchItem.details.forEach((detail) => {
      if (detail.is_upper) {
        upperAlignersSteps =
          batchItem.type_code === DispatchTypeCodes['aligners'] && batchItem.sub_type === DispatchesSubTypes['standard']
            ? detail.steps.split('-').join(' - ')
            : detail.steps ?? '-';
        upperRem = detail.remaining_steps_to_be_distributed ?? '-';
        upperProductQuantity = detail.quantity ?? '-';
      } else {
        lowerAlignersSteps =
          batchItem.type_code === DispatchTypeCodes['aligners'] && batchItem.sub_type === DispatchesSubTypes['standard']
            ? detail.steps.split('-').join(' - ')
            : detail.steps ?? '-';
        lowerRem = detail.remaining_steps_to_be_distributed ?? '-';
        lowerProductQuantity = detail.quantity ?? '-';
      }
    });
  }

  let textColor = 'black';
  if (disabled) {
    textColor = 'lightGray';
  } else if (active) {
    textColor = 'green';
  }
  const isEdit = batchItem?.trackings_count > 0;
  const dateFormat = GeneralFormats.dateFormat;
  return (
    <TableRow
      sx={{
        backgroundColor: color,
        '& td': {
          color: textColor,
        },
      }}
    >
      <TableCell style={{zIndex: 29}} sx={{ borderLeft: parentId ? `10px solid #ABC4FF` : 'none' }}>
        {parentId && <SubdirectoryArrowRightSharpIcon fontSize="large" />}
      </TableCell>
      <TableCell style={{zIndex: 29}} sx={{ padding: '16px 0' }}>
        <div className="tracking-buttons-wrapper">
          <Tooltip
            title={actual_dispatch_date ? 'Dispatched' : 'Mark as dispatched'}
          >
            <span>
              <IconButton
                disabled={disabled || actual_dispatch_date}
                onClick={() => handleGetItemInfo(id, 'dispatch')}
              >
                <FlightTakeoff />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={actual_receipt_date ? 'Received' : 'Mark as received'}
          >
            <span>
              <IconButton
                disabled={disabled || actual_receipt_date}
                onClick={() => handleGetItemInfo(id, 'receive')}
              >
                <FlightLand />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={isEdit ? 'Edit' : 'Add'}>
            <span>
              <IconButton
                disabled={
                  disabled || actual_receipt_date || actual_dispatch_date
                }
                onClick={() => handleAddTracking(id, user_id, isEdit)}
              >
                <AddCircle />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </TableCell>
      <TableCell style={{zIndex: 29}}>{treatment_stage}</TableCell>
      <TableCell style={{zIndex: 29}}>{capitalizeWords(name)}</TableCell>
      <TableCell style={{zIndex: 29}}>{batch ?? '-'}</TableCell>
      <TableCell>{capitalizeWords(sub_type)}</TableCell>
      <TableCell>{delivery}</TableCell>
      <TableCell>
        {target_dispatch_date
          ? moment(target_dispatch_date).format(dateFormat)
          : '-'}
      </TableCell>
      <TableCell>
        {actual_dispatch_date
          ? moment(actual_dispatch_date).format(dateFormat)
          : '-'}
      </TableCell>
      <TableCell>
        {expected_receipt_date
          ? moment(expected_receipt_date).format(dateFormat)
          : '-'}
      </TableCell>
      <TableCell>
        {actual_receipt_date
          ? moment(actual_receipt_date).format(dateFormat)
          : '-'}
      </TableCell>
      <TableCell>
        {expected_start_of_batch
          ? moment(expected_start_of_batch).format(dateFormat)
          : '-'}
      </TableCell>
      <TableCell>
        {expected_start_of_last_step_in_batch
          ? moment(expected_start_of_last_step_in_batch).format(dateFormat)
          : '-'}
      </TableCell>
      <TableCell>{upperAlignersSteps}</TableCell>
      <TableCell>{lowerAlignersSteps}</TableCell>
      <TableCell>{upperProductQuantity}</TableCell>
      <TableCell>{lowerProductQuantity}</TableCell>
      <TableCell
        style={{ cursor: 'pointer' }}
        onClick={() => handleOpen(id, note)}
      >
        {note ? `${note?.slice(0, 10)}...` : '-'}
      </TableCell>
      <TableCell>{handleDelayDate(calculateDelaysObject) ?? '-'}</TableCell>
      <TableCell>{handlePaymentStatus(batchItem) ?? '-'}</TableCell>
      <TableCell>{upperRem}</TableCell>
      <TableCell>{lowerRem}</TableCell>
    </TableRow>
  );
};

export default ViewDeliveryBatchesRow;
