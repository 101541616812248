import React, { useCallback, useState, useEffect } from "react";
import {
  Modal,
  Button,
  Thumbnail,
  DropZone,
  Stack,
  TextStyle,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import { useParams } from "react-router-dom";
import { NoteMinor } from "@shopify/polaris-icons";
import Popup from "../Popup";

const SuperimposedImages = (props) => {
  const { id } = useParams();
  const [popupActive, setPopupActive] = useState(false);
  const [files1, setFiles1] = useState([]);
  const handleDropZoneDrop1 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles1((files1) => [...files1, ...acceptedFiles]),
    []
  );
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const fileUpload1 = !files1.length && <DropZone.FileUpload />;
  const uploadedFiles1 = files1.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files1[files1.length - 1].name}
        source={
          validImageTypes.indexOf(files1[files1.length - 1].type) > 0
            ? window.URL.createObjectURL(files1[files1.length - 1])
            : NoteMinor
        }
      />
      <div>{files1[files1.length - 1].name} </div>
    </Stack>
  );

  const [files2, setFiles2] = useState([]);
  const handleDropZoneDrop2 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles2((files2) => [...files2, ...acceptedFiles]),
    []
  );
  const fileUpload2 = !files2.length && <DropZone.FileUpload />;
  const uploadedFiles2 = files2.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files2[files2.length - 1].name}
        source={
          validImageTypes.indexOf(files2[files2.length - 1].type) > 0
            ? window.URL.createObjectURL(files2[files2.length - 1])
            : NoteMinor
        }
      />
      <div>{files2[files2.length - 1].name} </div>
    </Stack>
  );
  const [imagePreview1, setImagePreview1] = useState("");
  const [imagePreview2, setImagePreview2] = useState("");
  const [imageKey1, setImageKey1] = useState("");
  const [imageKey2, setImageKey2] = useState("");
  const [image, setImage] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [activeDeleteMessage, setActiveDeleteMessage] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [emptyFiles1, setEmptyFiles1] = useState(0);
  const [emptyFiles2, setEmptyFiles2] = useState(0);
  const sendImage1 = useCallback(
    (files1) => {
      setEmptyFiles1(0);
      setPopupActive(true);
      setImagePreview1(
        validImageTypes.indexOf(files1[files1.length - 1].type) > 0
          ? window.URL.createObjectURL(files1[files1.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      let url2 = "";
      const form_data = new FormData();
      form_data.append("content_type", files1[files1.length - 1].type);
      form_data.append("file_name", files1[files1.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          console.log("res1");
          setImageKey1(res.data.key);
          props.handleChangeSuperImposedKey1(res.data.key);
          url2 = res.data.url;
          axios
            .put(url2, files1[files1.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": files1[files1.length - 1].type,
              },
            })
            .then((res) => {
              console.log("res2");
              setPopupActive(false);
              props.autoSave();
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },

    [files1]
  );

  const sendImage2 = useCallback(
    (files2) => {
      setEmptyFiles2(0);
      setPopupActive(true);
      setImagePreview2(
        validImageTypes.indexOf(files2[files2.length - 1].type) > 0
          ? window.URL.createObjectURL(files2[files2.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      let url2 = "";
      const form_data = new FormData();
      form_data.append("content_type", files2[files2.length - 1].type);
      form_data.append("file_name", files2[files2.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKey2(res.data.key);
          props.handleChangeSuperImposedKey2(res.data.key);
          url2 = res.data.url;
          axios
            .put(url2, files2[files2.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": files2[files2.length - 1].type,
              },
            })
            .then((res) => {
              props.autoSave();
              setPopupActive(false);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },

    [files2]
  );

  const deleteImage = (image, imageIndex) => {
    if(imageKey1 || imageKey2){
      if(imageIndex == 1){
        props.handleChangeSuperImposedKey1('');
        setLoading1(true);
        setTimeout(() => {
          setImagePreview1(typeof(props.imagePreview1) != "object" && props.imagePreview1 ? props.imagePreview1 : "");
          setImageKey1("");
          setFiles1([]);
          setEmptyFiles1(typeof(props.imagePreview1) != "object" && props.imagePreview1 ? 0 : 1);
          setLoading1(false);
        }, 500);
      }
      else{
        props.handleChangeSuperImposedKey2('');
        setLoading2(true);
        setTimeout(() => {
          setImagePreview2(typeof(props.imagePreview2) != "object" && props.imagePreview2 ? props.imagePreview2 : "");
          setImageKey2("");
          setFiles2([]);
          setEmptyFiles2(typeof(props.imagePreview2) != "object" && props.imagePreview2 ? 0 : 1);
          setLoading2(false);
        }, 500);
      }
    }
    else {
      setImage(image);
      setActiveDeleteMessage(true);
    }
  }

  const deleteSuperimposedImage = () => {
    setActiveDeleteMessage(false);
    if(imageIndex == 1){
      setLoading1(true);
      props.setImagePreview1('');
    }
    else{
      setLoading2(true);
      props.setImagePreview2('');
    }
    axios.delete(`/admin/v1/users/${id}/smile-plan/${props.dataId}/imposed-images/delete`, {
      data: {
        image,
        imageIndex
      },
      headers: {
        Authorization: "Bearer " + Cookie.get("token"),
      },
    }).then((res) => {
      console.log('AXIOS', res);
      if(res.data.status){
        setLoading1(false);
        setLoading2(false);
        imageIndex == 1 ? setEmptyFiles1(1) : setEmptyFiles2(1);
      }
    })
  }

  useEffect(() => {
    typeof(props.imagePreview1) != "object" && setImagePreview1(props.imagePreview1);
    typeof(props.imagePreview2) != "object" && setImagePreview2(props.imagePreview2);
  }, [props.imagePreview1, props.imagePreview2]);

  return (
    <>
      <Popup
        activeDeleteMessage={activeDeleteMessage}  
        handleYesDelete={deleteSuperimposedImage} 
        handleNoDelete={()=>setActiveDeleteMessage(false)} 
        onClose={()=>setActiveDeleteMessage(false)}
        title="Delete Files"
        text="This file will be permanently deleted. Are you sure you want to continue?"
      />
      <div className="imposed-imgs-container">
          <div className="imposed-sub-container">
            <TextStyle variation="strong">Top</TextStyle>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div
                style={{ float: "left", cursor: (imagePreview1 || imageKey1) && !emptyFiles1 ? "pointer" : "" }}
                onClick={() => (imagePreview1 != "" && !emptyFiles1) ? window.open(imagePreview1, "_blank") : ""}
              >
                <Thumbnail
                  size="large"
                  source={(imagePreview1 && !emptyFiles1) ? imagePreview1 : ""}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  marginLeft: "10px",
                }}
              >
                <DropZone
                  onDrop={handleDropZoneDrop1}
                  onDropAccepted={sendImage1}
                  disabled={props.disabled}
                >
                  {uploadedFiles1}
                  {fileUpload1}
                </DropZone>
              </div>
            </div>
            <div style={{display: "flex", justifyContent: "flex-end", marginTop: '1rem'}}>
              <Button loading={loading1} plain={loading1} disabled={(!imageKey1 && !imagePreview1) || emptyFiles1} 
                onClick={()=>{
                  setImageIndex(1);
                  deleteImage(imageKey1 == "" ? imagePreview1.substring(imagePreview1.indexOf("storage/")) : imageKey1, 1)
                }}
              >
                  Delete
              </Button>
            </div>
          </div>
          <div className="imposed-sub-container">
            <TextStyle variation="strong">Bottom</TextStyle>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div
                style={{ float: "left", cursor: (imagePreview2 || imageKey2) && !emptyFiles2 ? "pointer" : "" }}
                onClick={() => (imagePreview2 != "" && !emptyFiles2) ? window.open(imagePreview2, "_blank"): ""}
              >
                <Thumbnail
                  size="large"
                  source={(imagePreview2 && !emptyFiles2) ? imagePreview2 : ""}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  marginLeft: "10px",
                }}
              >
                <DropZone
                  onDrop={handleDropZoneDrop2}
                  onDropAccepted={sendImage2}
                  disabled={props.disabled}
                >
                  {uploadedFiles2}
                  {fileUpload2}
                </DropZone>
              </div>
            </div>
            <div style={{display: "flex", justifyContent: "flex-end", marginTop: '1rem'}}>
              <Button loading={loading2} plain={loading2} disabled={(!imageKey2 && !imagePreview2) || emptyFiles2}
                  onClick={()=>{
                    setImageIndex(2);
                    deleteImage(imageKey2 == "" ? imagePreview2.substring(imagePreview2.indexOf("storage/")) : imageKey2, 2)
                  }}
                >
                  Delete
              </Button>
            </div>
          </div>
      </div>
      <Modal open={popupActive} loading={true}></Modal>
    </>
  );
};

export default React.memo(SuperimposedImages);
