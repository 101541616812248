import { Card } from '@shopify/polaris'
import React, { useEffect, useRef, useState } from 'react'
import TicketingCommentMessage from './TicketingCommentMessage'
import TicketingCommentInput from './TicketingCommentInput'
import { useStyles } from '../../components/CaseDiscussionMessage/CaseDiscussionMessageStyles';
import { useStyles as useStyles2 } from "../../components/CaseDiscussionChat/CaseDiscussionChatStyles";
import { Grid, List } from '@material-ui/core'
import { useDropzone } from 'react-dropzone';
import axios from "../../axios";
import Cookies from 'js-cookie';
import { convertReactMentionToString } from '../../util/helpers';

function TicketingComment({ comments, setPreviewImages, userId, setComments, ticketId, subjectUserId, agents }) {
    let classes = useStyles();
    let classes2 = useStyles2();
    const [loading, setLoading] = useState(false)
    const [newMessage, setNewMessage] = useState('')
    const [newMedia, setNewMedia] = useState([]);
    const chatBottom = useRef();

    useEffect(()=>{
        if(chatBottom && chatBottom.current){
            chatBottom.current.scrollIntoView({ 'behaviour': "smooth" });
        }
    },[comments])

    const handleSubmit = async(e) =>{
        e.preventDefault()
        setLoading(true);
        let fileObjects = newMedia.map((file)=>{
            const randomString = Math.random().toString(36).substring(2, 8);
            const filename = `${randomString}-${file.name}`;
            const mimeType = file.type;
            const extension = file.name.split('.').pop().toLowerCase();
            let fileType;
            if (mimeType.startsWith('image/') || extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif') {
            fileType = 'photo';
            } else if (mimeType.startsWith('video/') || extension === 'mp4' || extension === 'mov' || extension === 'wmv' || extension === 'avi') {
            fileType = 'video';
            } else {
            fileType = 'file';
            }
            return {filename,type:fileType}
        })
        let convertedStr=''
        let ids=[];
        [convertedStr, ids] = convertReactMentionToString(newMessage)
        let body = {
            text: convertedStr,
            taggedAgents: ids
        }
        try{
        let res = await getPresignedURLs(fileObjects)
        body['media'] = res;
        Promise.all(res.map(async (file,ind)=> {
            await axios.put(file.url, newMedia[ind], {
                headers: {
                    'Content-Type': file.type,
                },
            });
        })).then(async (res)=>{
            const response = await axios.post(`admin/v1/tickets/${ticketId}/comments`, body,{
                headers: {
                    Authorization: "Bearer " + Cookies.get("token"),
                },
            });
            setLoading(false);
            setComments(response.data.comments)
            setNewMedia([]);
            setNewMessage("");
        })
    }catch(e){
        setLoading(false);
        setNewMedia([]);
        setNewMessage("");
    }
    }
    async function getPresignedURLs(files) {
        const response = await axios.post('admin/v1/tickets/upload', {
            files: files,
            user_id: subjectUserId,
            comment: true
        },{
            headers: {
                Authorization: "Bearer " + Cookies.get("token"),
            },
        });
        return response.data;
    }
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {

            if (!acceptedFiles.length) {
                return
            }
            setNewMedia((prev) => [...acceptedFiles])
        },
        noClick: true,
        noKeyboard: true
    });
    return (
        <Card>
            <div style={{ height: "40vh", width: "100%", }}>
                <div style={{ fontSize: "large", padding: "8px" }}><b>Comments</b></div>
                <Grid item xs={12} className={classes2.bodyRow} style={{ background: "initial" }}  {...getRootProps()}>
                    <Grid container className={classes2.messageContainer} >
                        <Grid item xs={12} className={classes.messagesRow} id="chatbox"></Grid>
                        <List style={{ width: "100%", height:"80%", overflowY:"auto" }}>
                            {comments.map((comment) =>
                                <TicketingCommentMessage message={comment} setPreviewImages={setPreviewImages} userId={userId} />
                            )}
                            <div ref={chatBottom} />

                        </List>
                        <TicketingCommentInput setNewMedia={setNewMedia} newMedia={newMedia} handleSubmit={handleSubmit} newMessage={newMessage} setNewMessage={setNewMessage} loading={loading} agents={agents}/>
                    </Grid>
                </Grid>
            </div>
        </Card>
    )
}

export default TicketingComment


