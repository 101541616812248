import React, { useState, useEffect, useCallback } from "react";
import {
  Page,
  Modal,
  FormLayout,
  Card,
  TextStyle,
  Select,
  DataTable,
} from "@shopify/polaris";
import Pagination from "@material-ui/lab/Pagination";
import Cookie from "js-cookie";
import axios from "../axios";
import { ExportMinor } from "@shopify/polaris-icons";
import "@zendeskgarden/react-pagination/dist/styles.css";

function OverDuePaymentsReport() {
  const [popupActive, setPopupActive] = useState(true);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const exportOrders = () => {
    let date = new Date();
    axios({
      url: `/admin/v1/reports/overdue_payment_report`,
      method: 'GET',
      headers:{
        Authorization : `Bearer ${Cookie.get('token')}`
      },
      responseType: 'blob', // important
    }).then((response) => {
       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', `Overdue Payments ${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}.csv`);
       document.body.appendChild(link);
       link.click();
    });
  }

  const fetchReportDetails = () => {
    axios
    .get(`/admin/v1/reports/cms_overdue_payment_report?page=${page}&per_page=${perPage}`, {
      headers: {
        Authorization: "Bearer " + Cookie.get("token"),
      },
    })
    .then((result) => {
      if(result?.data?.success){
        setTotalPages (result?.data?.total_pages);
        let array = [];
        result?.data?.data?.map((res) => {
          array = [
            ...array,
            [
            res?.id,
            `${res?.first_name} ${res?.last_name}`,
            res?.country,
            res?.phone,
            res?.email,
            res?.over_due_payments,
            res?.total_number_of_payments,
            res?.after_sales_agent,
            res?.overdue_by
          ]];
        })
        setItems(array);
      }
    }).catch((err) => console.log(err)).finally(() => setPopupActive(false));
  }

  useEffect(() => {
    fetchReportDetails()
  }, [page]);

  return (
    <Page
      fullWidth
      title="Overdue Payments Report"
      breadcrumbs={[{ content: "List Of Reports", url: "/admin/reports" }]}
      secondaryActions={[
        {
          content: "Export",
          icon: ExportMinor,
          onAction: exportOrders,
        },
      ]}
    >
      <Card sectioned>
        <FormLayout>
        <Card>
            {" "}
            <DataTable
              columnContentTypes={[
                "text",
                "text",
                "text",
                "text",
                "text",
                "text",
                "text",
              ]}
              headings={[
                <TextStyle variation="strong"></TextStyle>,
                <TextStyle variation="strong">
                  ID
                </TextStyle>,
                <TextStyle variation="strong">Name</TextStyle>,
                <TextStyle variation="strong">
                  Country
                </TextStyle>,
                <TextStyle variation="strong">
                  Phone
                </TextStyle>,
                <TextStyle variation="strong">
                  Email
                </TextStyle>,
                <TextStyle variation="strong">Number of overdue payments</TextStyle>,
                <TextStyle variation="strong">Total number of payments</TextStyle>,
                <TextStyle variation="strong">After Sales Agent</TextStyle>,
                <TextStyle variation="strong">Overdue by</TextStyle>,
              ]}
              rows={items}
              sortable={[false, false, false, false, false, false, false]}
              defaultSortDirection="descending"
              verticalAlign="center"
              // initialSortColumnIndex={0}
              // onSort={handleSort}
            />
          </Card>
          {totalPages >= 2 && (
            <div
              style={{
                paddingTop: "10px",
                textAlign: "center",
                paddingBottom: "10px",
              }}
            >
              <Pagination
                count={totalPages}
                page={page}
                onChange={handleChange}
                size="large"
              />
            </div>
          )}
        </FormLayout>
      </Card>
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );
}

export default OverDuePaymentsReport;
