import React, { useCallback, useState, useEffect, useMemo } from "react";
import {
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Card,
  Modal,
  PageActions,
  Collapsible,
  Button,
  TextContainer,
  Icon
} from "@shopify/polaris";
import axios from "../../axios";
import Cookies from "js-cookie";
import Select2 from "react-dropdown-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import {
  CaretDownMinor,CaretUpMinor
} from '@shopify/polaris-icons';
import { useParams } from "react-router-dom";
import ShopProductTable from "./ShopProductTable";
import { Button as ButtonMui, Typography } from '@material-ui/core';
import { CardActions } from "@mui/material";
import "./ShopOrder.css"
import { convertToCurrency } from "./helpers";

function OfflineShopOrder() {

  const { mail } = useParams();
  const [loading, setLoading] = useState(false)
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#FF2D47' : '#000',
      backgroundColor: '#fff',
      padding:'1rem',
      cursor: 'pointer',
      '&:hover':{
        backgroundColor: '#d3d3d3',
      },
      fontSize: '14px',
    }),
    control: (provided, state) => ({
      ...provided,
      padding:'0.5rem',
      // boxShadow: state.isFocused ? 0 : 0,
      // border: 'none',
      // borderBottom: '1px solid #d3d3d3',
      cursor: 'pointer',
      '&:hover':{
        borderColor:'#d3d3d3',
      },
      borderRadius:'0px',
    }),
    container:(provided,state)=>({
      ...provided,
      zIndex:"40 !important",
    }),
}
  const [status,setStatus]=useState("basic")
  const [disabledShipping,setDisabledShipping]=useState(true)
  const [optionsCountries, setOptionsCountries] = useState([
    { label: "UAE", value: "AE" },
    { label: "KSA", value: "SA" },
    { label: "QAT ", value: "QA" },
    { label: "LEB ", value: "LB" },
    { label: "KWT ", value: "KW" },
    { label: "BAH ", value: "BH" },
    { label: "OMA ", value: "OM" },
  ]);
  const [basicInfo, setBasicInfo] = useState({
    email: mail,
    country: "",
  });
  const [shippingInfo, setShippingInfo] = useState({
    email: {required: true, value: ""},
    phone: {required: true, value: ""},
    full_name: {required: true, value: ""},
    address: {required: true, value: ""},
    address2: {required: true, value: ""},
    address3: {required: false, value: ""},
    city: {required: true, value: ""},
    country: {required: true, value: ""},
    postal: {required: false, value: ""},
  });
  const handleChangeBasicInfo = (e,name) => { 
    setBasicInfo({...basicInfo, [name]: e })
  }
  const handleChangeShippingInfo = (e,name) => { 
    setShippingInfo({...shippingInfo, [name]: {...shippingInfo[name],value: e} })
  }
  const [order, setOrder]= useState([])
  const addNew = () => {
    setOrder(prev => [...prev,{
      product:{},
      quantity:1,
    }]);
  }
  const updateItem = (index,key,value) => {
    const newOrder = [...order];
    newOrder[index][key] = value;
    setOrder(newOrder);
  }
  const removeItem = (index) => {
    const newOrder = [...order];
    newOrder.splice(index,1);
    setOrder(newOrder);
  }

  const [coupons, setCoupons] = useState([]);
  const [options, setOptions] = useState({
    products :[],
    coupons: [],
    shipping: [],
  });
  const handleReset = () => {
    setBasicInfo({
      email: mail,
      country: "",
    });
    setStatus('basic')
    setShippingInfo({
      email: {required: true, value: ""},
      phone: {required: true, value: ""},
      full_name: {required: true, value: ""},
      address: {required: true, value: ""},
      address2: {required: true, value: ""},
      address3: {required: false, value: ""},
      city: {required: true, value: ""},
      country: {required: true, value: ""},
      postal: {required: false, value: ""},
    })
    setCoupons({
      shipping: null,
      cart: null,
    })
    setOptions({
      products :[],
      coupons: [],
      shipping: [],
    })
    setOrder([])
  }
  const { shipping: shippingCoupon, cart: cartCoupon } = coupons;
  const [tabOpened, setTabOpened] = useState("basic");
  function checkRequiredFields(data) {
    for (const key in data) {
        if (data[key].required && !data[key].value) {
            return false;
        }
    }
    return true;
  }
  const submit = (buttonType) => {
    setLoading(true)
    if(buttonType == "basic"){
      checkAccount()
    }else if(buttonType == "shipping"){
      updateShipping()
    }else if(buttonType == "order"){
      createOrder()
    }
  }
  const addNewCoupon = (lastCoupon,action) => {
    if(action.action == "select-option"){
      if(lastCoupon.apply_to === "shipping"){
        setCoupons(prev=>({ ...prev, [lastCoupon.apply_to]: lastCoupon }))
      }else{
        setCoupons(prev=>({ ...prev, cart: lastCoupon }))
      }
    }else if(action.action == "remove-value"){
      let obj = { cart: null, shipping: null }
      if(!lastCoupon?.id){
        setCoupons(obj)
      }else{
        if(lastCoupon.apply_to === "shipping"){
          setCoupons(({ ...obj, [lastCoupon.apply_to]: lastCoupon }))
        }else{
          setCoupons(({ ...obj, cart: lastCoupon }))
        }
      }
    }

  }
  const calculateDiscount = (original) => {
    console.log(cartCoupon)
    if (!cartCoupon?.code) {
        return 0;
    }
    if (cartCoupon.type == "amount") {
        return cartCoupon.discount
    } else {
        return Math.floor((original * cartCoupon.discount) / 100)
    }
}
const calculateTotalDiscount = (original) => {
  if (!cartCoupon?.code) {
      return 0;
  }
  if (cartCoupon.apply_to != "cart") {
      let item = order.find((cartItem) => cartItem.product.id == cartCoupon.product_id)
      if (cartCoupon.type == "amount") {
          return Math.min(item.product.price * item.quantity, cartCoupon.discount)
      } else {
          let discount = Math.floor((item.product.price * item.quantity * cartCoupon.discount) / 100)
          return Math.min(item.product.price * item.quantity, discount)
      }
  }
  else {
      if (cartCoupon.type == "amount") {
          return cartCoupon.discount
      } else {
          return Math.floor((original * cartCoupon.discount) / 100)
      }
  }
}
  const checkAccount = async () =>{
    if(basicInfo.email == "" && basicInfo.country == ""){
      toast.error("PLease Fill the data :)", 3000)
      return
    }
    try{
      let res = await axios.post(`admin/v1/manual/shop/get_user`, {...basicInfo, country: basicInfo.country.value }, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      let availableCoupons = res.data.coupons.map(item => {
        return {...item, label: item.code, value: item.code,}
      })
      let availableProducts = res.data.products
      let availableCountries = res.data.shipping.map(item => {
        return {label: item.country, value: item.code, amount: item.amount}
      })
      setOptions({
        coupons:availableCoupons,
        products:availableProducts,
        shipping: availableCountries
      })
      if(res.data.userShippingInformation){
        let index = availableCountries.findIndex(item => item.label == res.data.userShippingInformation.country)
        let country = ""
        if(index >=0){
          country = availableCountries[index]
        }
        let shippingAddress = {
          address: {required: true, value:res.data.userShippingInformation.address},
          address2: {required: true, value: res.data.userShippingInformation.address_2},
          address3: {required: false, value: res.data.userShippingInformation.address_3},
          city: {required: true, value: res.data.userShippingInformation.city},
          country: {required: true, value: country},
          email: {required: true, value: res.data.userShippingInformation.email},
          full_name: {required: true, value: res.data.userShippingInformation.full_name},
          phone: {required: true, value: res.data.userShippingInformation.phone},
          postal: {required: false, value: res.data.userShippingInformation.postal_code},
        }
        setShippingInfo(shippingAddress)
        setStatus("order")
        setTabOpened("order")
      }else{
        setTabOpened("shipping")
        setStatus("shipping")
        toast.error("Please Fill The Shipping Info :)",3000)
      }
    } catch(err){
      console.log(err)
    }
    setLoading(false)
  }
  const updateShipping = async () =>{
    if(!checkRequiredFields(shippingInfo)){
      toast.error("Please Fill The Shipping Info :)",3000)
      return
    }
    let shippingBody={
      shipping_address:shippingInfo.address.value,
      shipping_address_2:shippingInfo.address2.value,
      shipping_address_3:shippingInfo.address3.value,
      shipping_city:shippingInfo.city.value,
      shipping_country:shippingInfo.country.value.label,
      shipping_email:shippingInfo.email.value,
      shipping_full_name:shippingInfo.full_name.value,
      shipping_phone:shippingInfo.phone.value,
      shipping_postal_code:shippingInfo.postal.value,
      user_email:basicInfo.email
    }
    try{
      let res = await axios.post(`admin/v1/store/shipping_info`, shippingBody, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      setDisabledShipping(true)
      setTabOpened("order")
      setStatus("order")
    } catch(err){
      console.log(err)
      setDisabledShipping(false)
    }
    setLoading(false)
  }
  const createOrder = async () =>{
    try{
      let filteredOrders = order.filter(item => item?.product?.id).map(({ quantity, product }) => ({
        ...product,
        quantity
      }));
      const couponCodes = [];
      if(coupons?.shipping?.code){
          couponCodes.push(coupons.shipping.code)
      }
      if(coupons?.cart?.code){
          couponCodes.push(coupons.cart.code)
      }
      let body ={
        products: filteredOrders,
        coupons: couponCodes,
        country: shippingInfo.country.value.value,
        paymentMethod: "Cash",
        cart_note:"",
        email: basicInfo.email,
      }
      await axios.post(`admin/v1/manual/shop/create_order?country=${basicInfo.country.value}`, body, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      toast.success("Paid ", 3000);
      handleReset()
      setLoading(false)
    } catch(err){
      toast.error("Something went wrong contact Dev Team",3000)
      handleReset()
      console.log(err)
      setLoading(false)
    }
  };
  const currency = useMemo(() =>options?.products[0]?.currency ?? "USD", [options.products]);
  const shippingPrice = useMemo(() => convertToCurrency(shippingInfo?.country?.value?.amount, currency), [shippingInfo]);
  const getTotalPrice = () => {
    return order.filter(item => item?.product?.id).reduce((totalPrice, item) => {
        return totalPrice + (item.quantity * item.product.price);
    }, 0);
    }
  const totalPrice = useMemo(() => getTotalPrice(), [order]);
  const totalDiscount = useMemo(() => calculateTotalDiscount(totalPrice), [totalPrice, cartCoupon]);
  const total = useMemo(() => Math.floor(totalPrice + (shippingCoupon ? 0 : shippingPrice) - totalDiscount), [totalPrice, totalDiscount, shippingCoupon, shippingPrice]);
  return (
    <>
    <Card sectioned>
      <Button monochrome outline icon={tabOpened == "basic"? CaretUpMinor:CaretDownMinor} onClick={()=>{setTabOpened(prev=>prev=="basic"?null:"basic")}}>Basic Info</Button>
      <Collapsible open={tabOpened=="basic"} id="basic-collapsible"
            transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
            expandOnPrint>
        <FormLayout>
          <TextStyle variation="strong" >
            Email
          </TextStyle>
          <TextField
            value={basicInfo.email}
            name="email"
            onChange={(e)=>{handleChangeBasicInfo(e,"email")}}
            error={basicInfo.emailError}
            type="text"
          />
          <TextStyle variation="strong">Country</TextStyle>
          <Select 
             options={optionsCountries}
             onChange={(e)=>{setBasicInfo({...basicInfo,country:e})}}
             value={basicInfo.country}
             placeholder="Select Country"
          />
          <div style={{color:"green",justifyContent:'end',display:"flex"}}>
          <Button monochrome outline disabled={status!="basic" || loading} onClick={()=>{submit("basic")}}>Save</Button>
          </div>
          </FormLayout>
          </Collapsible>
          </Card>

          <Card sectioned>
          <Button monochrome outline icon={tabOpened=="shipping"? CaretUpMinor:CaretDownMinor} onClick={()=>{setTabOpened(prev=>prev =="shipping"?null:"shipping")}}>Shipping Address</Button>
          <Collapsible open={tabOpened=="shipping"}id="basic-collapsible"
            transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
            expandOnPrint>
          <FormLayout>
            <TextField disabled={disabledShipping} label={'Street'} name="address" value={shippingInfo.address.value} onChange={(e)=>{handleChangeShippingInfo(e,"address")}} ></TextField>
            <TextField disabled={disabledShipping} label={'Building,Apartment'} name="address2" value={shippingInfo.address2.value}  onChange={(e)=>{handleChangeShippingInfo(e,"address2")}}></TextField>
            <TextField disabled={disabledShipping} label={'Additional Info'} name="address3" value={shippingInfo.address3.value}  onChange={(e)=>{handleChangeShippingInfo(e,"address3")}}></TextField>
            <TextField disabled={disabledShipping} label={'Full name'} name="full_name" value={shippingInfo.full_name.value}  onChange={(e)=>{handleChangeShippingInfo(e,"full_name")}}></TextField>
            <TextField disabled={disabledShipping} label={'Email'} name="email"  value={shippingInfo.email.value} onChange={(e)=>{handleChangeShippingInfo(e,"email")}}></TextField>
            <TextStyle >Country</TextStyle>
            <Select isDisabled={disabledShipping} multi={false}  placeholder={'Country'} options={options.shipping} styles={customStyles} value={shippingInfo.country.value} onChange={(e)=>{handleChangeShippingInfo(e,"country")}}></Select>
            <TextField disabled={disabledShipping} label={'City'} name="city" value={shippingInfo.city.value} onChange={(e)=>{handleChangeShippingInfo(e,"city")}}></TextField>
            <TextField disabled={disabledShipping} label={'Phone'} name="phone" value={shippingInfo.phone.value} onChange={(e)=>{handleChangeShippingInfo(e,"phone")}}></TextField>
            <TextField disabled={disabledShipping} label={'Postal Code'} name="postal" value={shippingInfo.postal.value} onChange={(e)=>{handleChangeShippingInfo(e,"postal")}}></TextField>
            <div style={{color:"green",justifyContent:'end',display:"flex"}}>
              <Button monochrome disabled={status=="basic" || loading} outline onClick={()=>{if(!disabledShipping){submit("shipping")}else{
                setDisabledShipping((prev)=>!prev)
              }}}>{disabledShipping?"Edit":"Save"}</Button>
            </div>
        </FormLayout>
        </Collapsible>
        </Card>
        <Card sectioned>
          <Button monochrome outline icon={tabOpened=="order"? CaretUpMinor:CaretDownMinor} onClick={()=>{setTabOpened(prev=>prev =="order"?null:"order")}}>Order</Button>
          <Collapsible open={tabOpened=="order"}id="basic-collapsible"
            transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
            expandOnPrint>
          <FormLayout >
          <TextStyle variation="strong">Products</TextStyle>
          <CardActions style={{ float: 'right' }}>
          <ButtonMui variant='contained' style={{ backgroundColor: 'rgb(0 128 96)', color: 'white', 'padding': '1.5em 2em'}} onClick={addNew}>
            <Typography variant='h6' >Add New</Typography>
          </ButtonMui>
        </CardActions>
          <table style={{ display: 'grid', width: '100%', overflowX: 'auto', overflowY: 'hidden', padding: '1em 0' }}>
          <thead>
            <tr style={{ display: 'flex', gap: '10px' }}>
              <th className='shop-product-header'>Product</th>
              <th className='shop-product-header'>Quantity</th>
              <th className='shop-product-header'>Total</th>
              <th className='shop-product-header'></th>
            </tr>
          </thead>
          <tbody>
            {
              order.map((item, key) => <ShopProductTable orderItem={item} index={key} updateItem={updateItem} products={options.products} removeItem={removeItem} order={order} />)
            }
          </tbody>
        </table>
          <TextStyle >Coupons</TextStyle>
          <Select isDisabled={status!="order" || order.filter(item => item?.product?.id).length == 0} isMulti={true}  placeholder={'Coupons'} options={options.coupons} styles={customStyles} value={Object.values(coupons)} onChange={(e,t)=>{addNewCoupon(e[e.length-1],t)
          }}></Select>
          <TextStyle variation="strong">Cart Breakdown</TextStyle>
          {
            order.map((productItem, key) => {
              let item = productItem.product
              if(!item?.id){
                return null;
              }
              let price = item.price;
              let currency = item.currency;
                return (
                    <div key={key} className='shop-checkout-cart-item'>
                        <div className='shop-checkout-cart-item-breakdown'>
                            <span className='shop-checkout-cart-item-title'>{item.title.en}</span>
                            {
                            cartCoupon?.code && cartCoupon?.apply_to != "cart" && cartCoupon?.product_id == item.id ?
                            <span className='shop-checkout-cart-item-price-s'>
                                {currency}<s>{price}</s> {`${price - calculateDiscount(price)} x${productItem.quantity}`}
                            </span>
                            :
                            <span className='shop-checkout-cart-item-price-s'>{`${currency} ${price} x${productItem.quantity}`}</span>
                        }
                        </div>
                        {
                            cartCoupon?.code && cartCoupon?.apply_to != "cart" && cartCoupon?.product_id == item.id ?
                            <span className='shop-checkout-cart-item-price'>
                                <s>{`${currency} ${productItem.quantity * price}`}</s> {`${productItem.quantity * price - calculateDiscount(productItem.quantity * price)}`}
                            </span>
                            :
                            <span className='shop-checkout-cart-item-price'>{`${currency} ${productItem.quantity * price}`}</span>
                        }
                    </div>
                )
            })
            }
            <div className='shop-checkout-summary-wrapper'>
                    <TextStyle variation="strong" className='checkout-subtotal'>Subtotal</TextStyle>
                    <TextStyle variation="strong" className='checkout-subtotal'>{currency} {totalPrice}</TextStyle>
                </div>
                <div className='shop-checkout-summary-wrapper'>
                    <TextStyle variation="strong" className='checkout-subtotal'>Order Discount</TextStyle>
                </div>
                {cartCoupon?.code ? <div className='shop-checkout-summary-wrapper'>
                    <TextStyle variation="strong" className='checkout-subtotal' ></TextStyle>
                    <TextStyle variation="strong" className='checkout-subtotal'>- {currency} {totalDiscount}</TextStyle>
                </div> : null}
                <div className='shop-checkout-summary-wrapper'>
                    <TextStyle variation="strong" className='checkout-shipping'>Shipping</TextStyle>
                    {
                        shippingCoupon?.code ?
                        <TextStyle variation="strong" className='checkout-shipping'><s>{shippingPrice ? currency + " " + shippingPrice : ""}</s> Free</TextStyle> :
                        shippingPrice ?
                            <TextStyle variation="strong" className='checkout-shipping'>{currency} {shippingPrice}</TextStyle> :
                            null
                    }
                </div>
                <div className='shop-checkout-summary-wrapper'>
                    <TextStyle variation="strong" className='checkout-total'>Total</TextStyle>
                    <TextStyle variation="strong" className='checkout-total'>{currency} {total}</TextStyle>
                </div>
          <div style={{color:"green",justifyContent:'end',display:"flex"}}>
            <Button monochrome outline disabled={status!="order" || loading} onClick={()=>{submit("order")}}>Create Order</Button>
          </div>
          </FormLayout>
          </Collapsible>
          </Card>
    </>
  )
}

export default OfflineShopOrder
