import { Box, Button, makeStyles } from '@material-ui/core';
import React from 'react'
import ReviewedBy from './ReviewedBy';

const useStyles = makeStyles(() => {
    return {
        btnWrapper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        btn: {
            border: "1px solid #C4CDD5",
            borderRadius: "3px",
            background: "linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%)",
            boxShadow: "0 1px 0 0 rgba(22,29,37,0.05)",
        },
    };
});

const Review = ({disabled, reviewStatus, reviewer, reviewedAt, handleChange}) => {
    const classes = useStyles();
    return (
        <Box className={classes.btnWrapper}>
            <Button
                disabled={disabled}
                className={classes.btn}
                onClick={handleChange}
            >
                {reviewStatus ? 'Reviewed' : 'Review'}
            </Button>
            <ReviewedBy reviewer={reviewer} reviewedAt={reviewedAt} />
        </Box>
    )
}

export default Review