import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useStyles } from '../../../Reports/ClinicReports/clinic-reports-invoices-table.styles';
import SmilePlanDashboardTab from './smile-plan-dashboard-tab.component';
import { getSmilePlanName } from '../../../util/helpers';

const SmilePlanDashboard = ({ plans, loading, setActivePlan }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleTabClick = (plan) => {
    setActivePlan({
      plan: plan.data,
      proceduresData: plan.iprInfo?.procedures,
    });
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          TabIndicatorProps={{
            style: {
              backgroundColor: 'rgba(0, 128, 96, 1)',
            },
          }}
          aria-label="smile plan tabs"
        >
          {Object.keys(plans).map(
            (plan, index) =>
              plans[plan] && (
                <Tab
                  onClick={() => handleTabClick(plans[plan])}
                  className={classes.tabs}
                  label={getSmilePlanName(plans[plan].data?.type)}
                  {...a11yProps(index)}
                />
              )
          )}
        </Tabs>
      </Box>
      {Object.keys(plans).map(
        (plan, index) =>
          plans[plan] && (
            <SmilePlanDashboardTab
              key={index}
              data={plans[plan]}
              value={value}
              index={index}
              loading={loading}
            />
          )
      )}
    </>
  );
};

export default SmilePlanDashboard;
