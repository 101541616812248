import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  DataTable,
  Button,
  DisplayText,
  Card,
  Layout,
  TextStyle,
  ButtonGroup,
  Pagination,
  Checkbox,
  FormLayout,
  Toast,
  Modal,
  Stack,
  TextField,
} from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../../axios";
import "@zendeskgarden/react-pagination/dist/styles.css";
import {useHistory, useParams} from "react-router-dom";
import moment from "moment";
import MultipleUploadPhotos from "./YourSmilePlan/MultipleUploadPhotos";
import { UserContext } from "../../UserContext";
import { CircularProgress } from "@material-ui/core";

function AllFiles({planType, pageTitle, status, clinicName, clinicStatus, userId}) {
  const user = useContext(UserContext);
  const [isSaving, setIsSaving] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const perPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [isDeleted, setIsDeleted] = useState(false);
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const [toastMessage, setToastMessage] = useState("");
  const toastMarkup = active ? (
    <Toast content={toastMessage} onDismiss={toggleActive} />
  ) : null;
  const [buttonClicked, setButtonClicked] = useState("");
  const [commentError, setCommentError] = useState("");  
  const [filesImage, setFilesImage] = useState([]);
  const [imagesKey,setImageKey] =useState([])
  const [images, setImages] =useState([])
  const [loading, setLoading] = useState(true);
  const [document, setDocument] = useState({
    id: null,
    redo: null,
    action: '',
  });
  const [checkedInformationNeeded, setCheckedInformationNeeded] = useState(false);
  const handleChangeInformationNeeded = useCallback(
    (newChecked) => setCheckedInformationNeeded(newChecked),
    []
  );
  const [checkedPhotosNeeded, setCheckedPhotosNeeded] = useState(false);
  const handleChangePhotosNeeded = useCallback(
    (newChecked) => setCheckedPhotosNeeded(newChecked),
    []
  );
  const [checkedScansNeeded, setCheckedScansNeeded] = useState(false);
  const handleChangeScansNeeded = useCallback(
    (newChecked) => setCheckedScansNeeded(newChecked),
    []
  );

  useEffect(() => {
    axios.get(
      `admin/v2/users/${id}/get-old-files?per_page=${perPage}&page=${currentPage}`,
      {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }
    ).then((result) => {
      setTotalPages(Math.ceil(result.data.data.total / perPage));
      result?.data?.data?.data?.length == 0 && user.setHasHistoricalData(false);
      result?.data?.data?.data &&
        setItems(
          result.data.data.data.map((item) => {
            let type = item?.type;
            let ikPurchased = result?.data?.ik_purchased;
            let url = '';
            switch(type){
              case 'user_step3':
                type = ikPurchased ? 'IK Photos' : 'First Visit';
                url = ikPurchased ? `/admin/users/${id}/dashboard/files?type=ik-photos` : `/admin/users/${id}/dashboard/files?type=fv`;
                break;
              default:
                url = `/admin/users/${id}/dashboard/files/${item.id}`;
                break;
            }

            return(
              [
              item.id,
              type,
              item?.owner_name && item.owner_name,
              moment(item.created_at).add(isBetween(moment().month(), 4, 11) ? 3 : 2, "hours").format("DD-MM-YYYY hh:mm A"),
              item?.type ? '-' : item?.scan_status && item?.scan_status?.action_time && moment(item.scan_status.action_time).add(isBetween(moment().month(), 4, 11) ? 3 : 2, "hours").format("DD-MM-YYYY hh:mm A"),
              item?.type ? '-' : (item?.sent_via_email ? 'scan files sent via email/3D scanner' : 'yes'),
              item?.type != 'Redo Scan' ?
                <ButtonGroup>
                  <Button onClick={() => history.push(url, {
                    planType,
                    pageTitle,
                    status,
                    clinicName,
                    clinicStatus,
                    userId,
                  })}>Edit</Button>
                </ButtonGroup> :
                <ButtonGroup>
                  <Button
                    id={String(item.id)}
                    onClick={() => updateDocument(item.id, type, 'delete')}
                    destructive={true}
                  >
                    Delete
                  </Button>
                  <Button
                      onClick={() => history.push(`/admin/users/${id}/dashboard/edit-scan/${item.id}`, {redo: true})}
                  >
                    Edit
                  </Button>
                  <Button primary onClick={() => updateDocument(item.id, type, 'download-scans')}>
                    Download Scans
                  </Button>
                  <Button onClick={() => updateDocument(item.id, type, 'disapprove')} disabled={item?.scan_status ? true : false}>
                    Disapprove
                  </Button>
                </ButtonGroup>
              ,
            ]
          )
        })
        );
    }).catch((err) => console.log(err))
    .finally(f => {
      setLoading(false);
    });
  }, [currentPage, isDeleted, buttonClicked]);

  const updateDocument = (id, scan_type, action) => {
    setDocument(prev => ({
      ...prev,
      id,
      redo: scan_type == 'Redo Scan' ?? false,
      action,
    }));
  }

  const downloadScans = () => {
    if(document.id){
      axios.get(`/admin/v1/users/${id}/download-documents/${document.id}?redo=${document.redo}`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }).then((result) => {
        var win = window.open(
          `${process.env.REACT_APP_BASE_URL}/storage${result.data.data}`,
          "_blank"
        );
        win.focus();
        if (result.status !== 200) toggleActive();
      }).catch((err) => {
        console.log('err', err);
        setToastMessage("No scans found to download");
        toggleActive();
      });
    }
  };

  const handleDisapproveScans = () => {
    setCommentValue("");
    setActiveModal(true);
  };

  const handleSubmitDisapprove = () => {
    if(document.id){
      if (!commentValue) {
        setCommentError("Please enter a comment");
      } else {
        setIsSaving(true);
        const str1 = "Information Needed";
        const str2 = "Photos Needed";
        const str3 = "Scans Needed";
        let array = [];
        checkedInformationNeeded && array.push(str1);
        checkedPhotosNeeded && array.push(str2);
        checkedScansNeeded && array.push(str3);
        const bodyObj = {
          redo: document.redo,
          reason: array.toString(),
          comment: commentValue,
          images: imagesKey,
          type: 'BAS',
          document_id: document.id
        };
        axios
          .post(`/admin/v1/users/${id}/documents/unapprove`, bodyObj, {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          })
          .then((res) => {
            setIsSaving(false);
            setActiveModal(false);
            setToastMessage("Scans Disapproved");
            toggleActive();
            setButtonClicked(moment());
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const [activeModal, setActiveModal] = useState(false);
  const handleChangeModal = useCallback(
    () => {
      setActiveModal(!activeModal)
      setFilesImage([])
      setImageKey([]);
      setImages([])
    },
    [activeModal]
  );
  const [commentValue, setCommentValue] = useState("");
  const handleChangeComment = useCallback(
    (newValue) => setCommentValue(newValue),
    []
  );

  useEffect(() => {
    if(document.action){
      switch(document.action){
        case 'delete': 
          handleDelete();
          break;
        case 'download-scans': 
          downloadScans();
          break;
        case 'disapprove': 
          handleDisapproveScans();
          break;
      }
    }
  }, [document])

  return (
    <Layout.Section>
      <FormLayout>
        <Card title="Historical Case Records">
          {loading ?
            <div style={{ textAlign: 'center', paddingBottom: 25 }}>
              <CircularProgress color='primary' />
            </div>
            :
            <>
              <Card.Section>
                <DataTable
                  columnContentTypes={["text", "text", "text", "text", "text"]}
                  headings={[
                    <TextStyle variation="strong">ID</TextStyle>,
                    <TextStyle variation="strong">Type</TextStyle>,
                    <TextStyle variation="strong">Clinic</TextStyle>,
                    <TextStyle variation="strong">Created At</TextStyle>,
                    <TextStyle variation="strong">Disapproved Date</TextStyle>,
                    <TextStyle variation="strong">Uploaded to clinic portal</TextStyle>,
                    <TextStyle variation="strong">Actions</TextStyle>,
                  ]}
                  rows={items}
                  verticalAlign="middle"
                />
                {totalPages >= 2 && (
                  <div
                    style={{
                      marginTop: "10px",
                      textAlign: "center",
                    }}
                  >
                    <Pagination
                      hasPrevious={currentPage > 1 && true}
                      onPrevious={() => {
                        setCurrentPage(currentPage - 1);
                      }}
                      hasNext={currentPage < totalPages && true}
                      onNext={() => {
                        setCurrentPage(currentPage + 1);
                      }}
                    />
                  </div>
                )}
              </Card.Section>{" "}
              <Modal
                open={activeModal}
                onClose={handleChangeModal}
                title="Disapprove Scans"
                primaryAction={{
                  content: "Submit",
                  onAction: handleSubmitDisapprove,
                  loading: isSaving && true,
                }}
                secondaryActions={[
                  {
                    content: "Cancel",
                    onAction: handleChangeModal,
                  },
                ]}
              >
                <Modal.Section>
                  <FormLayout>
                    <Stack horizontal>
                      <Stack>
                        <Checkbox
                          label="Information Needed"
                          checked={checkedInformationNeeded}
                          onChange={handleChangeInformationNeeded}
                        />
                        <Checkbox
                          label="Photos Needed"
                          checked={checkedPhotosNeeded}
                          onChange={handleChangePhotosNeeded}
                        />
                        <Checkbox
                          label="Scans Needed"
                          checked={checkedScansNeeded}
                          onChange={handleChangeScansNeeded}
                        />
                      </Stack>
                    </Stack>
                    <TextField
                      label="Comment"
                      value={commentValue}
                      onChange={handleChangeComment}
                      error={commentError}
                    />
                    <MultipleUploadPhotos setFilesImage={setFilesImage} filesImage={filesImage} images={images} setImages={setImages} imagesKey={imagesKey} setImageKey={setImageKey}/>
                  </FormLayout>
                </Modal.Section>
              </Modal>
              {toastMarkup}
            </>
          }
        </Card>
      </FormLayout>
    </Layout.Section>
  );

  function handleDelete() {
    if(document.id){
      setIsDeleted(false);
      axios
        .delete(
          `admin/v1/users/${id}/documents/${document.id}?redo=${document.redo}`,
          {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          }
        )
        .then((result) => {
          setIsDeleted(true);
        })
        .catch((err) => console.log(err));
    }
  }
  function isBetween(n, a, b) {
    return (n - a) * (n - b) <= 0;
  }
}
export default React.memo(AllFiles);
