import React from 'react'
import moment from 'moment';
import DownloadIcon from '@mui/icons-material/Download';
import AttachmentIcon from '@mui/icons-material/Attachment';

function TicketingCommentMessage({message, setPreviewImages, userId}) {
    const attachmentName= (data)=>{
        let str = (process.env.REACT_APP_AWS_URL+data).split('\\').pop().split('/').pop();
        if(str.length>25){
          return str.substring(0,25)+"..."
        }else{ 
          return str;
        }
      }
    let userChat = {position:"right", title:"Me"};
    if(message?.sender_id != userId){
        userChat = {position:"left", title:message.sender.full_name};
    }
    let messageDate = moment(message.created_at).format('DD-MM-yy hh:mm A')
    let videoIndex, fileIndex;
    videoIndex = message.media.findIndex(arr => arr.type == 'video');
    fileIndex = message.media.findIndex(arr => arr.type == 'file');
    let photoArray = message.media.filter(arr => arr.type == 'photo');
    let userStyles = { parentDiv: {}, message: {}};
    if (userChat.position == 'left') {
        userStyles.parentDiv.justifyContent = "left";
        userStyles.message.marginLeft = "20px";
        userStyles.message.marginRight = "unset";
    } else {
        userStyles.parentDiv.justifyContent = "right";
        userStyles.message.marginLeft = "unset";
        userStyles.message.marginRight = "20px";
    }

    return (
        <div style={{ display: "flex", ...userStyles.parentDiv }}  >
            <div id={message.id}  style={{ width: "fit-content", minWidth: "20%", maxWidth:true=='dashboard'?"50%":"75%", backgroundColor: "white", padding: "5px", boxShadow: "1px 1px 1px 1px #0003", borderRadius: "5px", borderTopLeftRadius: "0px", position: "relative", marginBottom: "2%", ...userStyles.message }} >
                <div className='case-title' style={{ alignItems: "center", color: "#4f81a1", cursor: "pointer", display: "flex", fontSize: "13px", fontWeight: 500, margin: " 0 0 8px" }}>
                    {userChat.title}
                </div>
                <div className='body'>
                    {photoArray.length ? <div className='media' style={{ display: "flex", flexWrap: "wrap", width: "250px", gap: "5px", alignItems: "center", overflow: "hidden", cursor: "pointer", minHeight: "100px", margin: "5px 0px" }}>
                        {
                            photoArray.slice(0, 4).map((im, startIndex) =>
                                <div style={{ width: '45%', flexGrow: "1", maxWidth: photoArray.length == 1 ? "unset" : "50%", position: "relative", height: photoArray.length == 1 ? "unset" : "100px", overflow: "hidden", borderRadius: "5px" }} onClick={() => { setPreviewImages({ images: [...photoArray], startIndex }) }}>
                                    <img src={process.env.REACT_APP_AWS_URL + im.url} style={{ height: "100%", width: "100%", objectFit: "cover" }} />
                                    {startIndex == 3 && photoArray.length > 4 && <div style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, background: "rgb(63 63 68 / 39%)", justifyContent: "center", display: "flex", alignItems: "center" }}>
                                        <span style={{ fontSize: "x-large", color: "white", textShadow: "-1px -1px 1px black, 1px 1px 1px black, -1px 1px 1px black, 1px -1px 1px black" }}>+{photoArray.length - 4}</span>
                                    </div>}
                                </div>
                            )}
                    </div> : null}
                    {videoIndex >= 0 ? <div style={{margin:"5px 0px"}}>
                        <video
                            controls
                            className="video"
                            style={{ width: '100%', height: '100%' }}
                            src={process.env.REACT_APP_AWS_URL + message.media[videoIndex].url}
                        ></video>
                    </div> : null}

                    {fileIndex >= 0 ? <div style={{ backgroundColor: "#9a9999a1", borderRadius: "5px", padding: "5px", display: "flex", alignItems: "center", cursor: "pointer",margin:"5px 0px" }} onClick={() => { window.open(process.env.REACT_APP_AWS_URL + message.media[fileIndex].url) }}>
                        <AttachmentIcon fontSize='large' /> {attachmentName(message.media[fileIndex].url)}  <DownloadIcon fontSize='large' />
                    </div> : null}

                    {message.text ? <div className='chat-text' style={{ fontSize: "13.6px" }}>
                        {<p dangerouslySetInnerHTML={{ __html: message.text.replace(/@(\w+(\.\w+)?)/g, '<span style="color: blue;">$&</span>') }}></p>}
                    </div> : null}
                    <div className='time' style={{ fontSize: "12px", color: "#00000073", textAlign: "end" }}>
                        {messageDate}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TicketingCommentMessage