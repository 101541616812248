import React, { useState } from "react";
import { Page, Tabs } from "@shopify/polaris";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TabComponent1 from "./WrittenOffReport/RequestedTab";
import TabComponent2 from "./WrittenOffReport/ApprovedTab";
import TabComponent3 from "./WrittenOffReport/WrittenTab";

function WrittenOffReport() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabSelect = (selectedTabIndex) => {
    setActiveTab(selectedTabIndex);
  };

  const tabs = [
    {
      id: "tab1",
      content: "Requested Write-offs",
      component: <TabComponent1 />,
    },
    {
      id: "tab2",
      content: "Approved Write-offs",
      component: <TabComponent2 />,
    },
    {
      id: "tab3",
      content: " Completed Write-offs",
      component: <TabComponent3 />,
    },
  ];

  return (

    <Page
      fullWidth
      title="Payments Write-off List"
      breadcrumbs={[{ content: "List Of Reports", url: "/admin/reports" }]}
    >
         <ToastContainer />
      <Tabs tabs={tabs} selected={activeTab} onSelect={handleTabSelect} />
      <div>{tabs[activeTab].component}</div>
    </Page>

  );
}

export default WrittenOffReport;