import React, { createContext, useEffect, useState } from "react";
import axios from './axios';
import Cookies from "js-cookie";
import RichTextEditor from "react-rte";
import history from "./history";

export const ShopContext = createContext();

export const ShopProvider = ({ children }) => {
  const [coupons, setCoupons] = useState({
    loading: false,
    items: [],
  });
  const [coupon, setCoupon] = useState({
    loading: false,
    item: null,
    products: null,
  });
  const [shipments, setShipments] = useState({
    data: [],
    countries: null,
  });
  const [shopItems, setShopItems] = useState({
    loading: false,
    products: [],
  });
  const [shopData, setShopData] = useState({
    loading: false,
    newPriceLoading: [],
    product: null,
    dispatchOptions: [],
    countries: [],
    currencies: [],
  });

  const getShopShipping = async() => {
    const response = await axios.get('admin/v1/shop/shipping', {
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
    });
    setShipments({
      data: response.data.data,
      countries: Object.keys(response.data.countries).map((code) => ({value: code, label: response.data.countries[code]})),
    });
  }

  const storeShopShipping = async(body) => {
    await axios.post('admin/v1/shop/shipping', body, {
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
    });
    getShopShipping();
  }

  const getShopProducts = async() => {
    setShopItems(prev => ({
      ...prev,
      loading: true,
    }));
    const response = await axios.get('admin/v1/shop/products', {
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
    });
    setShopItems(prev => ({
      ...prev,
      products: response.data.products,
      loading: false,
    }));
  }

  const getShopProduct = async(id) => {
    setShopData(prev => ({
      ...prev,
      loading: true,
    }));
    const response = await axios.get(`admin/v1/shop/${id}/product`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
    });

    let productData = {
      ...shopData,
      ...response.data,
      newPriceLoading: Array(response.data.product ? response.data.product.prices.length : 0),
      dispatchOptions: Object.keys(response.data.dispatchOptions).map((dispatch) => ({value: response.data.dispatchOptions[dispatch], label: dispatch.split('_').join(' ')})),
      loading: false,
    };
    if(id) {
      let title = response.data.product.title ? JSON.parse(response.data.product.title) : null;
      let description = response.data.product.description ? JSON.parse(response.data.product.description) : null;
      productData = {
        ...productData,
        product: {
          ...response.data.product,
          title,
          description: {
            en: description.en ? description.en : '',
            ar: description?.ar ? RichTextEditor.createValueFromString(description?.ar, 'html') : RichTextEditor.createEmptyValue(),
          }
        },
      }
    }
    setShopData(productData);
  }

  const storeShopProduct = async(body) => {
    setShopData(prev => ({
      ...prev,
      loading: true,
    }));
    axios.post(`admin/v1/shop/product`, body, {
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
    }).then(res => {
      if(res.data.success) {
        history.push('/admin/shop/products');
      }
    }).catch(err => {
      console.log('err', err);
    }).finally(() => {
      setShopData(prev => ({
        ...prev,
        loading: false,
      }));
    });
  }

  const deleteProductImage = async(imagesId, id) => {
    axios.delete(`admin/v1/shop/${imagesId}/product`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
    }).then(res => {
      if(res.data.success) {
        getShopProduct(id);
      }
    }).catch(err => {
      console.log('err', err);
    });
  }

  const storeProductPrice = async(index, body) => {
    let loading = shopData.newPriceLoading;
    loading[index] = true;
    setShopData(prev => ({
      ...prev,
      newPriceLoading: loading,
    }));
    axios.post(`admin/v1/shop/product-price`, body, {
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
    }).then(res => {
      if(res.data.success) {
        loading[index] = false;
        let productData = {
          ...shopData,
          newPriceLoading: loading,
          product: {
            ...shopData.product,
            prices: res.data.prices,
          }
        };
        setShopData(productData);
      }
    }).catch(err => {
      console.log('err', err);
    });
  }

  const getShopCoupons = async() => {
    setCoupons(prev => ({
      ...prev,
      loading: true,
    }));
    const response = await axios.get('admin/v1/shop/coupons', {
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
    });
    setCoupons(prev => ({
      ...prev,
      items: response.data.coupons,
      loading: false,
    }));
  }

  const storeShopCoupon = async(body) => {
    setCoupon(prev => ({
      ...prev,
      loading: true,
    }));
    axios.post('admin/v1/shop/coupon', body, {
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
    }).then(res => {
      history.push('/admin/shop/coupons');
    }).catch(err => {
      console.log('err', err);
    }).finally(() => {
      setCoupon(prev => ({
        ...prev,
        loading: false,
      }));
    })
  }

  const getShopCoupon = async(id) => {
    setCoupon(prev => ({
      ...prev,
      loading: true,
    }));
    const response = await axios.get(`admin/v1/shop/${id}/coupon`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
    });
    let products = response.data.products.map(product => ({value: product.id, label: JSON.parse(product.title).en}));
    setCoupon(prev => ({
      ...prev,
      item: {
        ...response.data.coupon,
        product: response.data.coupon?.product_id ? products.find(prod => prod.value == response.data.coupon.product_id) : null,
        apply_to: response.data.coupon?.apply_to ? response.data.coupon.apply_to : 'shipping',
      },
      products,
      loading: false,
    }));
  }

  const value = {
    states: {
      shipments,
      shopData,
      shopItems,
      coupons,
      coupon,
    }, actions: {
      setShipments,
      getShopShipping,
      storeShopShipping,
      getShopProducts,
      getShopProduct,
      storeShopProduct,
      deleteProductImage,
      storeProductPrice,
      getShopCoupons,
      storeShopCoupon,
      getShopCoupon,
    },
  };

  return (
    <ShopContext.Provider value={value}>
      {children}
    </ShopContext.Provider>
  );
};
