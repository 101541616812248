import { Spinner } from "@shopify/polaris";
import { useStyles } from "./clinics-reports-loading-spinner.styles";

const ClinicsReportsLoadingSpinner = () => {
  const classes = useStyles();
  return (
    <div className={classes.spinnerContainer}>
      <Spinner accessibilityLabel="Loading Clinics Pricing" size="large" />
    </div>
  );
};

export default ClinicsReportsLoadingSpinner;
