import React, { useState, useEffect, useCallback } from "react";
import {
  Page,
  Modal,
  FormLayout,
  Card,
  TextStyle,
  Select,
} from "@shopify/polaris";
import Cookie from "js-cookie";
import axios from "../axios";

import "@zendeskgarden/react-pagination/dist/styles.css";

function RedoKitsReport() {
  const [numberSentBefore, setnumberSentBefore] = useState("");
  const [numberSentAfter, setnumberSentAfter] = useState("");
  const [popupActive, setPopupActive] = useState(true);
  const [selected, setSelected] = useState("within_this_month");
  const handleSelectChange = useCallback((value) => {
    setPopupActive(true);
    setSelected(value);
  }, []);
  const options = [
    { label: "Within This Month", value: "within_this_month" },
    { label: "Within This Week", value: "within_this_week" },
    { label: "Over One Month", value: "over_one_month" },
  ];
  useEffect(() => {
    axios
      .get(`/admin/v1/reports/redo-kit?time_frame=${selected}`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setPopupActive(false);
        setnumberSentBefore(result.data.data.before_treatment);
        setnumberSentAfter(result.data.data.after_treatment);
      })
      .catch((err) => console.log(err));
  }, [selected]);
  return (
    <Page
      fullWidth
      title="Redo Kits Report"
      breadcrumbs={[{ content: "List Of Reports", url: "/admin/reports" }]}
    >
      <Card sectioned>
        <FormLayout>
          <Select
            options={options}
            onChange={handleSelectChange}
            value={selected}
          />
          <TextStyle>
            Number of redo kits sent before treatment plan was designed :
            {numberSentBefore}
          </TextStyle>
          <TextStyle>
            Number of redo kits sent after treatment purchase:
            {numberSentAfter}
          </TextStyle>
        </FormLayout>
      </Card>
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );
}

export default RedoKitsReport;
