import React, { useState, useEffect } from "react";
import {
  DataTable,
  Button,
  Image,
  DisplayText,
  Card,
  Layout,
  TextStyle,
  ButtonGroup,
  Pagination,
} from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../../../axios";
import history from "../../../history";
import "@zendeskgarden/react-pagination/dist/styles.css";
import emptyIcon from "../../../img/emptyList.svg";
import {useHistory, useParams} from "react-router-dom";
import moment from "moment";
import CustomTooltip from "./../../../components/CustomTooltip";

function Notifications(props) {
  const { id } = useParams();
  let perPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [isListEmpty, setIsListEmpty] = useState(true);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    axios
      .get(
        `admin/v1/users/${id}/notifications?per_page=${perPage}&page=${currentPage}`,
        {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      )
      .then((result) => {
        !result.data.data.data.length
          ? setIsListEmpty(true)
          : setIsListEmpty(false);
        setTotalPages(Math.ceil(result.data.data.total / perPage));
        result.data.data &&
          result.data.data.data &&
          setItems(
            result.data.data.data.map((item) => [
              item.title.en,
              moment(item.created_at)
                .add(2, "hours")
                .format("YYYY-MM-DD hh:mm:ss"),
              moment(item.updated_at)
                .add(2, "hours")
                .format("YYYY-MM-DD hh:mm:ss"),
                item.id,
              <ButtonGroup>
                <Button
                  id={String(item.id)}
                  onClick={(e) => handleEditNotification(e)}
                >
                  Edit
                </Button>
              </ButtonGroup>,
            ])
          );
      })
      .catch((err) => console.log(err));
  }, [currentPage]);

  const emtyState = isListEmpty ? (
    <div className="message-no-result"
    >
      <img src={emptyIcon} className="empty-state-image"></img>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : null;
    const history = useHistory();

    const handleEdit = () => {
        history.push(
            `/admin/users/${id}/dashboard/addnotification`,
            {
                clinicName: props.clinicName,
                planType: props.planType,
                status: props.status,
                pageTitle: props.pageTitle,
                clinicStatus: props.clinicStatus,
                userId: props.userId
            }
        );
    }
  return (
    <Layout.Section
      actions={{
        content: <CustomTooltip title="" />,
      }}
    >
      <Card
        title="NOTIFICATIONS"
        actions={[
          {
            content: "Add Notification",
            onClick: handleEdit,
          },
        ]}
      >
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "text"]}
          headings={[
            <TextStyle variation="strong">Title</TextStyle>,
            <TextStyle variation="strong">Created At</TextStyle>,
            <TextStyle variation="strong">Updated At</TextStyle>,
            <TextStyle variation="strong">ID</TextStyle>,
            <TextStyle variation="strong">Actions</TextStyle>,
          ]}
          rows={items}
        />
        {totalPages >= 2 && (
          <div
            style={{
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            <Pagination
              hasPrevious={currentPage > 1 && true}
              onPrevious={() => {
                setCurrentPage(currentPage - 1);
              }}
              hasNext={currentPage < totalPages && true}
              onNext={() => {
                setCurrentPage(currentPage + 1);
              }}
            />
          </div>
        )}
        {emtyState}
      </Card>
    </Layout.Section>
  );

  function handleEditNotification(e) {
    let currentID = e.currentTarget.id;

    history.push(
      "/admin/users/" + id + "/dashboard/editnotification/" + currentID
    );
  }
}
export default React.memo(Notifications);
