import React from "react";

const UpperTeeth = (props) => {
  return (
    <div className="teeth-upper">
      {props.Value.attachments.slice(0, 16).map((attachment, index) => (
        <React.Fragment key={index}>
          <div className="single-tooth-pdf">
            <div>
              <img
                src={require(`./pdfimages/teeth/Tooth${attachment.num}.png`).default}
                alt={`Tooth ${attachment.num}`}
                className="teeth-image"
              />

              {attachment.attachment && (
                <span className="red-square-upper"></span>
              )}
            </div>

            <span>{attachment.num}</span>
          </div>

          {index < 15 && (
            <div className="between-teeth">
              {props.Value.spacing[attachment.num + "" + props.Value.attachments[index + 1].num]?.distance !== null && (
                <span
                  className="inside-box-number"
                  style={{
                    border:
                      props.Value.spacing[attachment.num + "" + props.Value.attachments[index + 1].num]?.distance !== ""
                        ? "1px solid black"
                        : "none",
                    textAlign: "center",
                    visibility:
                      props.Value.spacing[attachment.num + "" + props.Value.attachments[index + 1].num]
                        ? "visible"
                        : "hidden",
                  }}
                >
                  {props.Value.spacing[attachment.num + "" + props.Value.attachments[index + 1].num]?.distance ?? ""}
                </span>
              )}
              <hr
                size="95"
                style={{ border: index === 7 ? "0" : "1px dashed black" }}
              />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default UpperTeeth;
