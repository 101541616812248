import { Button, DataTable, TextStyle } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import axios from '../axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import moment from 'moment';

const ViewsConfiguration = ({
  settings,
  updateState,
  setUpdateState,
  setIsJobListEmpty,
  setJobs,
  setLoadingSettings,
  setSettings,
}) => {
  const [settingView, setSettingView] = useState({
    contactLongViewId: 0,
    contactShortViewId: 0,
    contactLongView: 'Not Set',
    contactShortView: 'Not Set',
    contactLongViewDate: '',
    contactShortViewDate: '',
  });

  const {
    contactLongViewId,
    contactShortViewId,
    contactLongView,
    contactShortViewDate,
    contactLongViewDate,
    contactShortView,
  } = settingView;

  const updateOrCreate = async (id, name) => {
    setLoadingSettings(true);
    let data = {};
    if (name === 'contactLongView' && contactLongView !== 'Not Set') {
      data = { view: contactLongView, type: 'contacts', duration: 'long' };
    } else if (name === 'contactShortView' && contactShortView !== 'Not Set') {
      data = { view: contactShortView, type: 'contacts', duration: 'short' };
    } else {
      console.log('Error');
      return;
    }
    try {
      const response = await axios.put(
        `admin/v1/zoho-freshsales/settings/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        }
      );

      if (response.data) {
        if (response.data.success === false) {
          const {
            data: { view },
          } = response.data;
          toast.error(view[0], 3000);
          return;
        }
        const { message, setting} = response.data;
        const settingExist = settings.find(
          (settingData) => settingData.id === setting.id
        );
        if (settingExist) {
          setSettings((prevSetting) =>
            prevSetting.map((settingData) =>
                settingData.id === id ? setting : settingData
            )
          );
        } else {
          setSettings([...settings, setting]);
        }
        toast.success(message, 3000);
      }
    } catch (e) {
      console.log('Error in updating settings || creating a setting', e);
    } finally {
      setUpdateState(false);
      setLoadingSettings(false);
    }
  };

  const dispatch = async (name) => {
    setLoadingSettings(true);
    let isScheduled;
    let data = {};
    if (name === 'contactLongView' && contactLongView !== 'Not Set') {
      isScheduled = contactLongViewDate !== '' ? 'true' : 'false';
      data = {
        is_scheduled: isScheduled,
        view: contactLongView,
        duration: 'contacts_long',
        date: isScheduled
          ? moment(contactLongViewDate).utc()
          : contactLongViewDate,
      };
    } else if (name === 'contactShortView' && contactShortView !== 'Not Set') {
      isScheduled = contactShortViewDate !== '' ? 'true' : 'false';
      data = {
        is_scheduled: isScheduled,
        view: contactShortView,
        duration: 'contacts_short',
        date: isScheduled
          ? moment(contactShortViewDate).utc()
          : contactShortViewDate,
      };
    } else {
      console.log('View Not Valid');
      return;
    }
    try {
      const response = await axios.post(
        'admin/v1/zoho-freshsales/settings',
        data,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        }
      );
      if (response.data) {
        if (response.data.success === false) {
          const {
            data: { view },
          } = response.data;
          toast.error(view[0], 3000);
          return;
        }
        const { message, jobs } = response.data;
        toast.success(message, 3000);
        const newJobs = [...jobs];
        setJobs(newJobs);
        if (jobs.length !== 0) {
          setIsJobListEmpty(false);
        }
      }
    } catch (e) {
      console.log('Error in dispatching a job');
    } finally {
      setLoadingSettings(false);
    }
  };

  useEffect(() => {
    if (settings.length > 0) {
      const newSettingView = {
        contactLongViewId: 0,
        contactShortViewId: 0,
        contactLongView: 'Not Set',
        contactShortView: 'Not Set',
        contactLongViewDate: '',
        contactShortViewDate: '',
      };
      const longContactArray = settings.filter(
        (setting) => setting.duration === 'long'
      );
      const shortContactArray = settings.filter(
        (setting) => setting.duration === 'short'
      );
      if (longContactArray && longContactArray.length > 0) {
        newSettingView.contactLongView = longContactArray[0]?.view;
        newSettingView.contactLongViewId = longContactArray[0]?.id;
      }
      if (shortContactArray && shortContactArray.length > 0) {
        newSettingView.contactShortView = shortContactArray[0]?.view;
        newSettingView.contactShortViewId = shortContactArray[0]?.id;
      }
      setSettingView(newSettingView);
    }
  }, [settings]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setSettingView((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const contentArray = [
    [
      updateState ? (
        <>
          <input
            name="contactLongView"
            onChange={onChange}
            value={contactLongView}
            type="text"
            style={{ padding: 8 }}
          />
        </>
      ) : (
        contactLongView
      ),
      'Contacts',
      'Long',
      <>
        {!updateState && (
          <input
            style={{ padding: 8 }}
            type="datetime-local"
            onChange={onChange}
            name="contactLongViewDate"
            value={contactLongViewDate}
          />
        )}
      </>,
      <div>
        {!updateState && (
          <Button
            disabled={contactLongViewDate === ''}
            primary
            onClick={() => dispatch('contactLongView')}
          >
            Dispatch
          </Button>
        )}
        <span style={{ display: 'inline-block', margin: '0 2px' }}> </span>
        {updateState ? (
          <Button
            primary
            onClick={() => updateOrCreate(contactLongViewId, 'contactLongView')}
          >
            Update
          </Button>
        ) : (
          ''
        )}
      </div>,
    ],
    [
      updateState ? (
        <>
          <input
            name="contactShortView"
            onChange={onChange}
            value={contactShortView}
            type="text"
            style={{ padding: 8 }}
          />
        </>
      ) : (
        contactShortView
      ),
      'Contacts',
      'Short',
      <>
        {!updateState && (
          <input
            type="datetime-local"
            onChange={onChange}
            style={{ padding: 8 }}
            name="contactShortViewDate"
            value={contactShortViewDate}
          />
        )}
      </>,
      <div>
        {!updateState && (
          <Button
            primary
            onClick={() => dispatch('contactShortView')}
            disabled={contactShortView === 'Not Set'}
          >
            Dispatch
          </Button>
        )}
        <span style={{ display: 'inline-block', margin: '0 2px' }}> </span>
        {updateState ? (
          <Button
            primary
            onClick={() =>
              updateOrCreate(contactShortViewId, 'contactShortView')
            }
          >
            Update
          </Button>
        ) : (
          ''
        )}
      </div>,
    ],
  ];
  return (
    <>
      <DataTable
        columnContentTypes={['text', 'text', 'text', 'text', 'text']}
        headings={[
          <TextStyle variation="strong">View</TextStyle>,
          <TextStyle variation="strong">Type</TextStyle>,
          <TextStyle variation="strong">Duration</TextStyle>,
          !updateState && <TextStyle variation="strong">Date</TextStyle>,
          <TextStyle variation="strong">Actions</TextStyle>,
        ]}
        rows={contentArray}
      ></DataTable>
    </>
  );
};

export default ViewsConfiguration;
