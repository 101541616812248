import { Checkbox, Modal, TextContainer } from '@shopify/polaris';
import moment from 'moment';
import { GeneralFormats } from '../../util/constants';
import { useState } from 'react';

const ApproveProductRequestDialog = ({
  open,
  setOpen,
  dispatch,
  handleApprove,
}) => {
  const [newDelivery, setNewDelivery] = useState(dispatch === null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnChange = (value) => {
    setNewDelivery(value);
  };

  return (
    <Modal
      title="Approve Product Request"
      open={open}
      onClose={handleClose}
      primaryAction={{
        content: 'Approve',
        onAction: () => handleApprove(dispatch?.id, newDelivery),
      }}
      secondaryActions={[
        {
          content: 'Close',
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>
            Do you want to merge it with this batch that has this date{' '}
            {dispatch?.target_dispatch_date
              ? moment(dispatch.target_dispatch_date).format(
                  GeneralFormats.dateFormat
                )
              : ''}
            ?
          </p>
          <Checkbox
            label="Create a new delivery instead"
            checked={newDelivery}
            disabled={dispatch === null}
            onChange={handleOnChange}
          />
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
};

export default ApproveProductRequestDialog;
