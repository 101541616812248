import { Box, Typography, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => {
    return {
        flex: {
            display: 'flex',
            flexDirection: 'column',
        },
        reviewTxt: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: 2,
            margin: "1rem 0",
        },
        reviewBoldTxt: {
            fontWeight: 'bold',
        },
        size11: {
            fontSize: '11px',
        },
    }
})

const CreatedUpdated = ({createdAt, updatedAt}) => {
    const classes = useStyles();

    return (
        <Box className={classes.flex}>
            {createdAt && <Typography className={classes.size11}><span><span className={classes.reviewBoldTxt}>Created At:</span> <span>{createdAt}</span></span></Typography>}
            {(createdAt !== updatedAt) && <Typography className={classes.size11}><span><span className={classes.reviewBoldTxt}>Updated At:</span> <span>{updatedAt}</span></span></Typography>}
        </Box>
    )
}

export default CreatedUpdated