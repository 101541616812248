import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Divider,
} from '@mui/material';
import { useStyles } from './user-costs-popup.styles';
import CloseIcon from '@mui/icons-material/Close';

const UserCostsPopup = ({
  userCostsPopup,
  handleUserCosts,
  costs,
  totalClinicCost,
}) => {
  const classes = useStyles();
  const showUsd = !costs?.isUSD;
  return (
    <Dialog open={userCostsPopup} onClose={handleUserCosts}>
      <DialogTitle className={classes.dialogTitle}></DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleUserCosts}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className={classes.dialogContent}>
        <div>
          <h3>Treatment</h3>
          <Divider />
          <div className={classes.container}>
            <div>
              <h4>Original Price:</h4>
              <span>
                {costs?.treatmentPurchase?.noDiscount?.current}
                {showUsd && ` | ${costs?.treatmentPurchase?.noDiscount?.USD}`}
              </span>
            </div>
            <div>
              <h4>Discount:</h4>
              <span>{costs?.treatmentPurchase?.discountAmount + '%'}</span>
            </div>
            <div>
              <h4>Discounted Price:</h4>
              <span>
                {costs?.treatmentPurchase?.discount?.current}
                {showUsd && ` | ${costs?.treatmentPurchase?.discount?.USD}`}
              </span>
            </div>
          </div>
        </div>
        <div className={classes.container}>
          <h3>Clinic Visit</h3>
          <Divider />
          <div>
            <h4>Total Cost:</h4>
            <span>{totalClinicCost ?? 0}</span>
          </div>
        </div>
        <div className={classes.container}>
          <h3>Paid to-date</h3>
          <Divider />
          <div>
            <h4>Settled:</h4>
            <span>
              {costs?.totalPaid?.current}{' '}
              {showUsd && `| ${costs?.totalPaid?.USD}`}
            </span>
          </div>
        </div>
        <div>
          <h3>Total Fees</h3>
          <Divider />
          <span>{costs?.fees} (AED)</span>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UserCostsPopup;
