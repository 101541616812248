
import React from 'react';
import Select from '@mui/material/Select';
import { FormControl } from '@material-ui/core';

function SelectParentChildCheckbox({title, checkboxes, setCheckboxes, teams}) {
    const handleCheckboxChange = (child) => {
        setCheckboxes((prev) => {
            let newdata = { ...prev };
            if (newdata[child.value]) {
                delete newdata[child.value];
            } else {
                newdata[child.value] = child.label;
            }
            return newdata;
        });
    };

    const handleParentClicked = (ind) => {
        if (checkedParent(ind)) {
            setCheckboxes((prev) => {
                let newdata = { ...prev };
                teams[ind].children.map((child) => {
                    delete newdata[child.value]
                })
                return newdata
            })
        } else {
            setCheckboxes((prev) => {
                let newdata = { ...prev };
                teams[ind].children.map((child) => {
                    newdata[child.value] = child.label;
                })
                return newdata
            })
        }
    }

    const data = [
        {
            name: 'product',
            label: 'Product Team',
            children: [
                {
                    value: 6888,
                    label: 'Test',
                },
                {
                    value: 6785,
                    label: 'Dana',
                },
                {
                    value: 6880,
                    label: 'Ahmad',
                },
            ],
        },
        {
            name: 'lab',
            label: 'Lab Team',
            children: [
                {
                    value: 1,
                    label: 'Karine',
                },
                {
                    value: 2,
                    label: 'Tania',
                },
                {
                    value: 3,
                    label: 'Jana',
                },
            ],
        },
        {
            name: 'aftersales',
            label: 'After Sales Team',
            children: [
                {
                    value: 4,
                    label: 'Ayat',
                },
                {
                    value: 5,
                    label: 'Kamal',
                },
                {
                    value: 6,
                    label: 'Nour',
                },
            ],
        },
        
    ];
    const checkedParent = (ind) => {
        return teams[ind].children.every(child => checkboxes[child.value]);
    }
    return (
        <div>
            <FormControl fullWidth >
                <label htmlFor='filter-assigned-by-select' >{title}</label>
                <Select variant='standard' style={{ fontSize: '1.4rem', width: "100%", marginTop: "0px" }} id="filter-urgency-select"  value={Object.keys(checkboxes).map((key) =>checkboxes[key])}  renderValue={()=>{ return <p>{Object.keys(checkboxes).map((key) =>checkboxes[key]).join(' ')}</p>;}} multiple>
                    <ul style={{listStyleType:"none",padding:10}}>
                        {teams.map((item, ind) => (
                            <li key={item.name}>
                                <label htmlFor={item.name}>
                                    <input
                                        type="checkbox"
                                        name={item.name}
                                        id={item.name}
                                        checked={checkedParent(ind)}
                                        onChange={() => { handleParentClicked(ind) }}
                                    />
                                    {item.label}
                                </label>

                                {item.children && (
                                    <ul style={{listStyleType:"none"}}>
                                        {item.children.map((child) => (
                                            <li key={child.value}>
                                                <label htmlFor={child.value}>
                                                    <input
                                                        type="checkbox"
                                                        name={child.value}
                                                        id={child.value}
                                                        checked={checkboxes[child.value] || false}
                                                        onChange={() => { handleCheckboxChange(child) }}
                                                    />
                                                    {child.label}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </Select>
            </FormControl>
        </div>
    );
};


export default SelectParentChildCheckbox



   