import React, { useCallback, useState, useEffect } from "react";
import {
  Button,
  FormLayout,
  Card,
  Layout,
  TextStyle,
  Toast,
} from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../../axios";
import history from "../../history";
import "@zendeskgarden/react-pagination/dist/styles.css";
import emptyIcon from "../../img/emptyList.svg";
import { useParams } from "react-router-dom";
import CustomTooltip from "./../../components/CustomTooltip";

function ResetPassword() {
  const { id } = useParams();
  const [resetPasswordLink, setResetPasswordLink] = useState("");
  const [active, setActive] = useState(false);
  const [toastMessage, setToastMessage] = useState("Account Disactivated");
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content={toastMessage} onDismiss={toggleActive} />
  ) : null;
  return (
    <Layout.Section>
      <Card
        sectioned
        title="RESET PASSWORD"
      >
        <FormLayout>
          <Button
            primary
            onClick={() =>
              axios
                .get("/admin/v1/users/" + id + "/reset-password", {
                  headers: {
                    Authorization: "Bearer " + Cookie.get("token"),
                  },
                })
                .then((result) => {
                  setResetPasswordLink(result.data.url);
                  setToastMessage("Email Sent Successfully");
                  toggleActive();
                })
                .catch((err) => console.log(err))
            }
          >
            Reset Password
          </Button>
          {resetPasswordLink && (
            <TextStyle>Generated Link: <a target="blank" href={resetPasswordLink}>{resetPasswordLink}</a></TextStyle>
          )}
        </FormLayout>
      </Card>
      {toastMarkup}
    </Layout.Section>
  );
}
export default React.memo(ResetPassword);
