import React, { useState, useEffect, useContext } from "react";
import {
  DataTable,
  Button,
  Image,
  DisplayText,
  Card,
  TextStyle,
  ButtonGroup,
  Spinner,
} from "@shopify/polaris";
import emptyIcon from "../img/emptyList.svg";
import ShopLayout from "../components/ShopLayout";
import { ShopContext } from "../ShopContext";
import history from "../history";
import moment from "moment";
import { GeneralFormats } from "../util/constants";

const ShopCoupons = () => {
  const dateFormat = GeneralFormats.dateFormat;
  const {
    states: {
      coupons,
    },
    actions: {
      getShopCoupons,
    }
  } = useContext(ShopContext);

  const [items, setItems] = useState([]);

  const emtyState = coupons.loading ? (
    <div className="spinnerContainer">
      <div className="vertical-center">
        <Spinner accessibilityLabel="Spinner example" size="large" />
      </div>
    </div>
  ) : (
    !coupons.items.length &&
    <div className="message-no-result"
    >
      <div className="message-no-result-img">
        <Image src={emptyIcon}></Image>{" "}
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  );

  function handleEdit(id) {
    history.push(`/admin/shop/coupon/${id}/edit`);
  }

  const addNewProduct = () => {
    history.push('/admin/shop/add-coupon');
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return value.map((val) => `Filter by ${val}`).join(", ");
      case "productType":
        return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }

  useEffect(() => {
    getShopCoupons();
  }, []);

  useEffect(() => {
    if(coupons.items.length) {
      setItems(
        coupons.items.map((item) => [
          item.id,
          item.code,
          (item.discount && item.type) ? (item.type == 'amount' ? `USD ${item.discount}` : `${item.discount}%`) : '-',
          item.usage_count,
          item?.product && item?.product?.title ? JSON.parse(item.product.title).en : '-',
          item.apply_to,
          item.active === 1 ? 'Active' : item.active === 0 && moment(item.end_date).isBefore(moment()) ? 'Expired' : 'Inactive',
          moment(item.start_date).format(dateFormat),
          moment(item.end_date).format(dateFormat),
          <ButtonGroup>
            <Button id={String(item.id)} onClick={() => handleEdit(item.id)}>
              Edit
            </Button>
          </ButtonGroup>,
        ])
      );
    }
  }, [coupons.items])

  return (
    <ShopLayout>
      <div className='shop-products-wrapper'>
        <div className='button-wrapper'>
          <Button primary onClick={addNewProduct}>Add new</Button>
        </div>
        <Card>
          <DataTable
            columnContentTypes={[
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
            ]}
            headings={[
              <TextStyle variation="strong">ID</TextStyle>,
              <TextStyle variation="strong">Code</TextStyle>,
              <TextStyle variation="strong">Discount</TextStyle>,
              <TextStyle variation="strong">Uses</TextStyle>,
              <TextStyle variation="strong">Product</TextStyle>,
              <TextStyle variation="strong">Type</TextStyle>,
              <TextStyle variation="strong">Status</TextStyle>,
              <TextStyle variation="strong">Start Date</TextStyle>,
              <TextStyle variation="strong">End Date</TextStyle>,
              <TextStyle variation="strong"></TextStyle>,
            ]}
            rows={items}
            sortable={[true, false, false, false, false, false, false]}
            defaultSortDirection="descending"
            initialSortColumnIndex={0}
            verticalAlign="center"
          />
          {emtyState}
        </Card>
      </div>
    </ShopLayout>
  )
}

export default ShopCoupons