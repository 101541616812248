import React, { useState, useEffect } from "react";
import axios from "../../axios";
import DataTableComponent from "./DataTableComponent.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookie from "js-cookie";
import { Button, Loading } from "@shopify/polaris";

function TabComponent1() {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const loadingMarkup = loading ? <Loading /> : null;

  useEffect(() => {
    const url = "/admin/v1/reports/request-written-off-users";
    axios
      .get(`${url}?page=${page}&per_page=${perPage}`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        if (result.data.success) {

          setTotalPages(result?.data?.data?.last_page);
          let array = result?.data?.data?.data?.map((res) => {
            return [
              res?.user_id,
              `${res?.user?.first_name} ${res?.user?.last_name}`,
              res?.request_reason,
              res?.requested_by,
              res?.requested_at,
              res?.approved_at,
              res?.approved_by,
              res?.completed_at,
              res?.completed_by,
              <Button
                primary
                onClick={() =>
                  handleAction(res.user_id, res)
                }
              >
                Approve Write Off
              </Button>,
            ];
          });
          setItems(array);
        }
      })
      .catch((err) => console.log("an error", err));
  }, [page, perPage]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleAction = async (id, writtenOffUser) => {
    if (!writtenOffUser.completed_at) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/admin/v1/users/${id}/write-off-status`,
          writtenOffUser,
          {
            headers: {
              Authorization: `Bearer ${Cookie.get("token")}`,
            },
          }
        );
        if (response) {
          const { message, success } = response.data;
          if (success) {
            toast.success(message, { autoClose: 3000 });
            setItems((prevItems) =>
              prevItems.filter((item) => item[0] !== id)
            );
          } else {
            toast.error(message, { autoClose: 3000 });
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div >
      {loadingMarkup}
      <DataTableComponent
        items={items}
        totalPages={totalPages}
        page={page}
        tab="1"
        handleChange={handleChange}
      />
    </div>
  );
}

export default TabComponent1;
