import NavigationLayout from '../components/NavigationLayout';
import { Card, DisplayText, Image, Loading, Page } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import ViewsConfiguration from './ViewsConfiguration';
import ViewJobs from './ViewJobs';
import emptyIcon from '../img/emptyList.svg';
import Cookies from 'js-cookie';
import axios from '../axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ZohoFreshsales = () => {
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingSettings, setLoadingSettings] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [isJobListEmpty, setIsJobListEmpty] = useState(false);
  const [updateState, setUpdateState] = useState(false);

  const emptyJobState = isJobListEmpty ? (
    <div className="message-no-result">
      <div className="message-no-result-img">
        <Image src={emptyIcon} alt={'No Result'} source={''}></Image>{' '}
      </div>
      <DisplayText size="small">Job Queue Empty</DisplayText>
      <br />
    </div>
  ) : null;

  const getSettings = async () => {
    try {
      setLoadingSettings(true);
      const response = await axios.get('admin/v1/zoho-freshsales/settings', {
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
      });
      const {
        data: { data },
      } = response;
      setSettings(data);
    } catch (e) {
      console.log('Error in getting settings');
    } finally {
      setLoadingSettings(false);
    }
  };

  const getJobs = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/admin/v1/zoho-freshsales/jobs', {
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
      });
      const { data } = response;
      if (data.length === 0) {
        setIsJobListEmpty(true);
      }
      setJobs(data);
    } catch (e) {
      console.log('Error in getting jobs', e);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateState = () => {
    setUpdateState(!updateState);
  };

  const onDelete = async (id) => {
    try {
      setLoading(true);
      const response = await axios.delete(
        `admin/v1/zoho-freshsales/jobs/${id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        }
      );
      if (response.data) {
        const { message } = response.data;
        toast.success(message, 3000);
        const newJobs = jobs.filter((element) => element.id !== id);
        setJobs(newJobs);
        setIsJobListEmpty(newJobs.length === 0);
      }
    } catch (e) {
      console.log('Error in deleting a job', e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void getSettings();
    void getJobs();
  }, []);

  if (loading || loadingSettings) {
    return (
      <NavigationLayout>
        <Page fullWidth title={'Zoho Freshsales Configuration'}>
          <Loading />
        </Page>
      </NavigationLayout>
    );
  }

  return (
    <NavigationLayout>
      <ToastContainer />
      <Page fullWidth title={'Zoho Freshsales Configuration'}>
        <Card title="Jobs" sectioned>
          {!loading && <ViewJobs onDelete={onDelete} jobs={jobs} />}
          {emptyJobState}
        </Card>
        <Card
          title="Configuration"
          sectioned
          actions={[
            {
              content: updateState ? 'Cancel' : 'Edit',
              onAction: handleUpdateState,
            },
          ]}
        >
          {!loading && (
            <ViewsConfiguration
              settings={settings}
              setSettings={setSettings}
              updateState={updateState}
              setUpdateState={setUpdateState}
              setJobs={setJobs}
              setIsJobListEmpty={setIsJobListEmpty}
              setLoadingSettings={setLoadingSettings}
            />
          )}
        </Card>
      </Page>
    </NavigationLayout>
  );
};

export default ZohoFreshsales;
