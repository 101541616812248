import React, { useCallback, useState } from "react";
import {
  InlineError,
  Page,
  FormLayout,
  TextField,
  Card,
  Modal,
  PageActions,
  Thumbnail,
  Stack,
  DropZone,
  TextStyle,
  Button,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";
import { useParams } from "react-router-dom";
import { NoteMinor } from "@shopify/polaris-icons";
import CustomTooltip from "../../../components/CustomTooltip";

function AddRedoScan() {
  const { id } = useParams();
  const [popupActive, setPopupActive] = useState(false);
  const [imageError, setImageError] = useState("");
  const [imageError2, setImageError2] = useState("");
  const [imageError3, setImageError3] = useState("");
  const [imageError4, setImageError4] = useState("");
  const [files, setFiles] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [files3, setFiles3] = useState([]);
  const [files4, setFiles4] = useState([]);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const fileUpload = !files.length && <DropZone.FileUpload />;
  const fileUpload2 = !files2.length && <DropZone.FileUpload />;
  const fileUpload3 = !files3.length && <DropZone.FileUpload />;
  const fileUpload4 = !files4.length && <DropZone.FileUpload />;
  const [imagePreview, setImagePreview] = useState("");
  const [imagePreview2, setImagePreview2] = useState("");
  const [imagePreview3, setImagePreview3] = useState("");
  const [imagePreview4, setImagePreview4] = useState("");
  const [imageKey, setImageKey] = useState("");
  const [imageKey2, setImageKey2] = useState("");
  const [imageKey3, setImageKey3] = useState("");
  const [imageKey4, setImageKey4] = useState("");
  const uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : NoteMinor
        }
      />
      <div>{files[files.length - 1].name} </div>
    </Stack>
  );
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const sendImage = useCallback(
    (files) => {
      setPopupActive(true);
      setImagePreview(
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      let url2 = "";
      const form_data = new FormData();
      form_data.append("content_type", files[files.length - 1].type);
      form_data.append("file_name", files[files.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          console.log("res1");
          setImageKey(res.data.key);
          url2 = res.data.url;
          axios
            .put(url2, files[files.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": files[files.length - 1].type,
              },
            })
            .then((res) => {
              console.log("res2");
              setPopupActive(false);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },

    [files]
  );

  const uploadedFiles2 = files2.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files2[files2.length - 1].name}
        source={
          validImageTypes.indexOf(files2[files2.length - 1].type) > 0
            ? window.URL.createObjectURL(files2[files2.length - 1])
            : NoteMinor
        }
      />
      <div>{files2[files2.length - 1].name} </div>
    </Stack>
  );
  const handleDropZoneDrop2 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles2((file) => [...file, ...acceptedFiles]),
    []
  );
  const sendImage2 = useCallback(
    (file) => {
      setPopupActive(true);
      setImagePreview2(
        validImageTypes.indexOf(file[file.length - 1].type) > 0
          ? window.URL.createObjectURL(file[file.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      let url2 = "";
      const form_data = new FormData();
      form_data.append("content_type", file[file.length - 1].type);
      form_data.append("file_name", file[file.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKey2(res.data.key);
          setImageError2("");
          url2 = res.data.url;
          axios
            .put(url2, file[file.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": file[file.length - 1].type,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },

    [files2]
  );

  const uploadedFiles3 = files3.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files3[files3.length - 1].name}
        source={
          validImageTypes.indexOf(files3[files3.length - 1].type) > 0
            ? window.URL.createObjectURL(files3[files3.length - 1])
            : NoteMinor
        }
      />
      <div>{files3[files3.length - 1].name} </div>
    </Stack>
  );
  const handleDropZoneDrop3 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles3((file) => [...file, ...acceptedFiles]),
    []
  );
  const sendImage3 = useCallback(
    (file) => {
      setPopupActive(true);
      setImagePreview3(
        validImageTypes.indexOf(file[file.length - 1].type) > 0
          ? window.URL.createObjectURL(file[file.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      let url2 = "";
      const form_data = new FormData();
      form_data.append("content_type", file[file.length - 1].type);
      form_data.append("file_name", file[file.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKey3(res.data.key);
          setImageError3("");
          url2 = res.data.url;
          axios
            .put(url2, file[file.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": file[file.length - 1].type,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },

    [files3]
  );
  const uploadedFiles4 = files4.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files4[files4.length - 1].name}
        source={
          validImageTypes.indexOf(files4[files4.length - 1].type) > 0
            ? window.URL.createObjectURL(files4[files4.length - 1])
            : NoteMinor
        }
      />
      <div>{files4[files4.length - 1].name} </div>
    </Stack>
  );
  const handleDropZoneDrop4 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles4((file) => [...file, ...acceptedFiles]),
    []
  );
  const sendImage4 = useCallback(
    (file) => {
        setPopupActive(true);
        setImagePreview4(
          validImageTypes.indexOf(file[file.length - 1].type) > 0
          ? window.URL.createObjectURL(file[file.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        );
        let url2 = "";
        const form_data = new FormData();
        form_data.append("content_type", file[file.length - 1].type);
        form_data.append("file_name", file[file.length - 1].name);
        axios
          .post("/admin/v1/images/s3", form_data, {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          })
          .then((res) => {
            setImageKey4(res.data.key);
            setImageError4("");
            url2 = res.data.url;
            axios
              .put(url2, file[file.length - 1], {
                headers: {
                  "x-amz-acl": "public-read-write",
                  "Content-Type": file[file.length - 1].type,
                },
              })
              .then((res) => {
                setPopupActive(false);
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
        },

    [files4]
  );

  function handleDelete(e) {
    const imageID = e.currentTarget.id;
    updateImageStates(imageID);
  }

  const updateImageStates = (value) => {
    if (value == "document") {
          setImageKey("");
          setImagePreview("");
          setImageError("");
          setFiles([]);
    }
    if (value == "lower_scan") {
          setImageKey2("");
          setImagePreview2("");
          setImageError2("");
          setFiles2([]);
    }
    if (value == "bite_scan_1") {
          setImageKey3("");
          setImagePreview3("");
          setImageError3("");
          setFiles3([]); 
    }
    if (value == "bite_scan_2") {
          setImageKey4("");
          setImagePreview4("");
          setImageError4("");
          setFiles4([]);
    }
  }

  return (
    <Page
      title="Add Scan"
      breadcrumbs={[
        {
          url: `/admin/users/${id}/dashboard`,
        },
      ]}
    >
      <Card sectioned>
      <FormLayout.Group>
        <FormLayout>
          <TextStyle variation="strong">Upper Scan</TextStyle>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <Thumbnail
                size="large"
                source={imagePreview ? imagePreview : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZoneDrop} onDropAccepted={sendImage}>
                {uploadedFiles}
                {fileUpload}
              </DropZone>
            </div>
          </div>
          <p style={{color:'red'}}>{imageError}</p>
          <Button id="document" onClick={(e) => handleDelete(e)}>
              Delete
          </Button>
        </FormLayout>
        <FormLayout>
          <TextStyle variation="strong">Lower Scan</TextStyle>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <Thumbnail
                size="large"
                source={imagePreview2 ? imagePreview2 : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZoneDrop2} onDropAccepted={sendImage2}>
                {uploadedFiles2}
                {fileUpload2}
              </DropZone>
            </div>
          </div>
          <p style={{color:'red'}}>{imageError2}</p>
          <Button id="lower_scan" onClick={(e) => handleDelete(e)}>
              Delete
          </Button>
        </FormLayout>
        </FormLayout.Group>
        <FormLayout.Group>
        <FormLayout>
          <TextStyle variation="strong">Bite Scan 1</TextStyle>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <Thumbnail
                size="large"
                source={imagePreview3 ? imagePreview3 : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZoneDrop3} onDropAccepted={sendImage3}>
                {uploadedFiles3}
                {fileUpload3}
              </DropZone>
            </div>
          </div>
          <p style={{color:'red'}}>{imageError3}</p>
          <Button id="bite_scan_1" onClick={(e) => handleDelete(e)}>
              Delete
          </Button>
        </FormLayout>
        <FormLayout>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent:"space-between"
            }}
          >
            <TextStyle variation="strong">Bite Scan 2</TextStyle>
            <CustomTooltip title="If you are using 3 Shape, upload a second bite scan file"/>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <Thumbnail
                size="large"
                source={imagePreview4 ? imagePreview4 : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZoneDrop4} onDropAccepted={sendImage4}>
                {uploadedFiles4}
                {fileUpload4}
              </DropZone>
            </div>
          </div>
          <p style={{color:'red'}}>{imageError4}</p>
          <Button id="bite_scan_2" onClick={(e) => handleDelete(e)}>
              Delete
          </Button>
        </FormLayout>
        </FormLayout.Group>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );

  function handleSave() {
      setPopupActive(true);
      let bodyObj = {
        document: imageKey,
        lower_scan: imageKey2,
        bite_scan_1: imageKey3,
        bite_scan_2: imageKey4,
        redo: true
      };

      if(files.length > 0){
          bodyObj = {
            ...bodyObj,
            name: files[files.length - 1].name,
          }
      }

      if(files2.length > 0){
        bodyObj = {
          ...bodyObj,
          lower_scan_name: files2[files2.length - 1].name,
          
        }
      }

      if(files3.length > 0){
        bodyObj = {
          ...bodyObj,
          bite_scan_1_name: files3[files3.length - 1].name,
        }
      }

      if(files4.length > 0){
        bodyObj = {
          ...bodyObj,
          bite_scan_2_name: files4[files4.length - 1].name,
        }
      }
        
      axios
        .post(`/admin/v1/users/${id}/documents`, bodyObj,{
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          if(res.data.success){
            history.push("/admin/users/" + id + "/dashboard");
          }
          setPopupActive(false);
        })
        .catch((err) =>{
          setPopupActive(false);
            console.log(err)
        });
  
    }
}
export default AddRedoScan;
