import { Button, makeStyles } from '@material-ui/core'
import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles(() => {
    return {
        btn: {
            border: 'none',
            background: 'transparent',
            padding: 0,
            minWidth: 'fit-content',
        },
    }
})

const DeleteBtn = ({ disabled, handleDelete}) => {
    const classes = useStyles();

    return (
        <Button className={classes.btn} disabled={disabled} onClick={handleDelete}><DeleteIcon color={disabled ? 'disabled' : 'action'} fontSize='large' /></Button>
    )
}

export default DeleteBtn