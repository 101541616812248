import React from "react";
import InformationRadioButtonGroup from "./InformationRadioButtonGroup";

const TreatmentApproach = ({
    infoDetails,
    handleRadioButtonClick,
}) => {
    const dentition_types = [
        {
            value: 'permanent',
            label: 'Permanent',
        },
        {
            value: 'mixed',
            label: 'Mixed Dentition',
        },
    ];

    const treatment_approach_permanent = [
        {
            value: 'aesthetic',
            label: 'Aesthetic',
        },
        {
            value: 'full-arch',
            label: 'Full Arch Correction',
        },
    ];

    const treatment_approach_mixed = [
        {
            value: 'full-arch',
            label: 'Full Arch Correction (Recommended)',
        },
        {
            value: 'aesthetic',
            label: 'Aesthetic',
        },
    ];

    return (
        <div className='treatment-approach-container'>
            {/* <InformationRadioButtonGroup
                title='Dentition Type'
                radioGroupTypes={infoDetails}
                groupName='dentition_type'
                radioButtons={dentition_types}
                handleRadioButtonClick={handleRadioButtonClick}
            /> */}
            <InformationRadioButtonGroup
                title='Treatment Approach'
                radioGroupTypes={infoDetails}
                groupName='treatment_approach'
                radioButtons={infoDetails.dentition_type === 'permanent' ? treatment_approach_permanent : treatment_approach_mixed}
                handleRadioButtonClick={handleRadioButtonClick}
            />
        </div>
    );
};

export default TreatmentApproach;
