import React, { useState, useEffect, useContext } from "react";
import {
  InlineError,
  Checkbox,
  TextStyle,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { ShopContext } from "../ShopContext";
import { Radio, RadioGroup } from 'react-radio-group';
import Select from 'react-select';

function ShopCoupon() {
  let { id } = useParams();
  const {
    states: {
      coupon,
    }, actions: {
      getShopCoupon,
      storeShopCoupon,
    },
  } = useContext(ShopContext);
  const [productCoupon, setProductCoupon] = useState(null);
  const [fieldRequired, setFieldRequired] = useState({
    code: <InlineError message='' fieldID='myFieldID' />,
    discount: <InlineError message='' fieldID='myFieldID' />,
    product: <InlineError message='' fieldID='myFieldID' />,
    quantity: <InlineError message='' fieldID='myFieldID' />,
    uses: <InlineError message='' fieldID='myFieldID' />,
  });

  const handleSave = () => {
    let proceed = true;
    let errors = fieldRequired;
    if(
      !productCoupon?.usage_count || 
      !productCoupon?.code || 
      (productCoupon.apply_to != 'shipping' && !productCoupon?.discount) ||
      ((productCoupon.apply_to == 'product' || productCoupon.apply_to == 'buy_x_get_y') && !productCoupon?.product) ||
      (productCoupon?.apply_to == 'buy_x_get_y' && !productCoupon?.required_quantity)
    ) {
      proceed = false;
      if(!productCoupon?.code) {
        errors = {
          ...errors,
          code: <InlineError message='This field is required' fieldID='myFieldID' />,
        };
      }

      if(!productCoupon?.usage_count) {
        errors = {
          ...errors,
          uses: <InlineError message='This field is required' fieldID='myFieldID' />,
        };
      }

      if(productCoupon.apply_to != 'shipping' && !productCoupon?.discount) {
        errors = {
          ...errors,
          discount: <InlineError message='This field is required' fieldID='myFieldID' />,
        };
      }

      if((productCoupon.apply_to == 'product' || productCoupon.apply_to == 'buy_x_get_y') && !productCoupon?.product) {
        errors = {
          ...errors,
          product: <InlineError message='This field is required' fieldID='myFieldID' />,
        };
      }

      if(productCoupon?.apply_to == 'buy_x_get_y' && !productCoupon?.required_quantity) {
        errors = {
          ...errors,
          quantity: <InlineError message='This field is required' fieldID='myFieldID' />,
        };
      }

      if(!proceed) {
        setFieldRequired(errors);
      }
    }

    if(proceed && productCoupon) {
      let body = {
        id: id ? id : null,
        ...productCoupon,
        product_id: productCoupon?.product ? productCoupon.product.value : null,
        discount: productCoupon?.discount ? productCoupon.discount : 0,
      };
      storeShopCoupon(body);
    }
  }

  const handleChange = (type, value) => {
    setProductCoupon(prev => ({
      ...prev,
      [type]: value,
    }));
  }

  useEffect(() => {
    getShopCoupon(id);
  }, []);

  useEffect(() => {
    setProductCoupon(coupon.item);
  }, [coupon.item]);

  return (
    <Page
      title={id ? 'Edit Coupon' : 'Add Coupon'}
      breadcrumbs={[{ content: "List Of Coupons", url: "/admin/shop/coupons" }]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Code (Without Spaces)</TextStyle>
          <TextField value={productCoupon?.code} onChange={(e) => handleChange('code', e)} />
          {fieldRequired.code}
          <TextStyle variation="strong">Count (Maximum Number Of Usage)</TextStyle>
          <TextField
            value={String(productCoupon?.usage_count)}
            onChange={(e) => handleChange('usage_count', e)}
            type="number"
          />
          {fieldRequired.uses}
          <RadioGroup
            name='apply_to'
            selectedValue={productCoupon?.apply_to}
            onChange={(e) => handleChange('apply_to', e)}
          >
            <div style={{ display: 'flex', columnGap: 20 }}>
              <label style={{ cursor: 'pointer' }}>
                <Radio value='shipping' />
                Shipping
              </label>
              <label style={{ cursor: 'pointer' }}>
                <Radio value='cart' />
                Cart
              </label>
              <label style={{ cursor: 'pointer' }}>
                <Radio value='product' />
                Product
              </label>
              <label style={{ cursor: 'pointer' }}>
                <Radio value='buy_x_get_y' />
                Buy x get y
              </label>
            </div>
          </RadioGroup>
          {
            (productCoupon?.apply_to && productCoupon.apply_to != 'shipping') &&
            <div style={{marginTop: '2.6rem'}}>
              <FormLayout>
                <TextStyle variation="strong">Discount</TextStyle>
                <TextField
                  value={String(productCoupon?.discount)}
                  onChange={(e) => handleChange('discount', e)}
                  type="number"
                />
                {fieldRequired.discount}
              </FormLayout>
            </div>
          }
          {
            (productCoupon?.apply_to && productCoupon.apply_to != 'shipping') &&
            <RadioGroup
              name='type'
              selectedValue={productCoupon?.type ? productCoupon.type : 'amount'}
              onChange={(e) => handleChange('type', e)}
            >
              <div style={{ display: 'flex', columnGap: 20 }}>
                <label style={{ cursor: 'pointer' }}>
                  <Radio value='amount' />
                  Amount
                </label>
                <label style={{ cursor: 'pointer' }}>
                  <Radio value='percentage' />
                  Percentage
                </label>
              </div>
            </RadioGroup>
          }
          {
            (productCoupon?.apply_to == 'product' || productCoupon?.apply_to == 'buy_x_get_y') &&
            <div className='shop-coupon-products-wrapper'>
              <Select
                options={coupon.products}
                onChange={(e) => handleChange('product', e)}
                value={productCoupon?.product}
              />
              {fieldRequired.product}
            </div>
          }
          {
            productCoupon?.apply_to == 'buy_x_get_y' &&
            <div style={{marginTop: '2.6rem'}}>
              <FormLayout>
                <TextStyle variation="strong">Required Quantity</TextStyle>
                <TextField
                  value={productCoupon?.required_quantity ? String(productCoupon?.required_quantity) : productCoupon?.required_quantity}
                  onChange={(e) => handleChange('required_quantity', e)}
                  type="number"
                />
                {fieldRequired.quantity}
              </FormLayout>
            </div>
          }
          <TextStyle variation="strong">Start Date</TextStyle>
          <TextField
            value={productCoupon?.start_date ? String(productCoupon?.start_date) : ''}
            onChange={(e) => handleChange('start_date', e)}
            type="date"
          />
          <TextStyle variation="strong">End Date</TextStyle>
          <TextField
            value={productCoupon?.end_date ? String(productCoupon?.end_date) : ''}
            onChange={(e) => handleChange('end_date', e)}
            type="date"
          />
          <Checkbox
            label="Is Active"
            checked={productCoupon?.active}
            onChange={(e) => handleChange('active', e)}
          />
          <Checkbox
            label="Auto Applied"
            checked={productCoupon?.auto_applied}
            onChange={(e) => handleChange('auto_applied', e)}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: coupon.loading,
          disabled: coupon.loading,
        }}
      />
    </Page>
  );
}

export default ShopCoupon;
