import React, { useState, useEffect } from "react";
import {
  DataTable,
  DisplayText,
  Card,
  Layout,
  TextStyle,
  Button,
  Pagination,
  ButtonGroup,
} from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../../axios";
import "@zendeskgarden/react-pagination/dist/styles.css";
import emptyIcon from "../../img/emptyList.svg";
import { useHistory, useParams } from "react-router-dom";

function ListOfTrackings() {
  const { id } = useParams();
  const history = useHistory();  
  let perPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [isListEmpty, setIsListEmpty] = useState(true);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const handleClick = (trackingId, mainId, isDispatch) => {
    const type = isDispatch ? 'dispatch' : '';
    history.push(
      `/admin/orders/${mainId}/trackings/${trackingId}/edit/${type}`,
      { userId: id }
    );
  };

  const handleViewHistory = (trackingId, mainId) => {
    history.push(`/admin/orders/${mainId}/trackings/${trackingId}/history`, {
      userId: id,
    });
  };

  useEffect(() => {
    axios
      .get(
        `admin/v1/users/${id}/orders-tracking?per_page=${perPage}&page=${currentPage}`,
        {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      )
      .then((result) => {
        !result.data.data.data.length ? setIsListEmpty(true) : setIsListEmpty(false);
        const { total, current_page, data } = result.data.data;
        setTotalPages(Math.ceil(total / perPage));
        setCurrentPage(current_page);
        let arrayTrackings = [];
        for (let i = 0; i < data.length; i++) {
            let mainId = null;
            let isDispatch = false
            if (data[i].order_id) {
                mainId = data[i].order_id;
            } else if (data[i].dispatch_id) {
                mainId = data[i].dispatch_id;
                isDispatch = true;
            }
            arrayTrackings.push([
              data[i].order_id,
              data[i].shipment_event_code,
              data[i].shipment_event_date_time,
              data[i].shipment_event_description,
              data[i].status,
              data[i].tracking_number,
              data[i].type,
              data[i].id,
              <ButtonGroup>
                <Button
                  onClick={() => handleClick(data[i]?.id, mainId, isDispatch)}
                >
                  Edit
                </Button>
                ,
                <Button onClick={() => handleViewHistory(data[i]?.id, mainId)}>
                  History
                </Button>
                ,
              </ButtonGroup>,
            ]);
        }

        setItems(arrayTrackings);
      })
      .catch((err) => console.log(err));
  }, [currentPage]);

  const emtyState = isListEmpty ? (
    <div className="message-no-result"
    >
      <img  src={emptyIcon} className="empty-state-image"></img>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : null;
  return (
    <Layout.Section>
      <Card
        title="TRACKINGS"
      >
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "text"]}
          headings={[
            <TextStyle variation="strong">Order ID</TextStyle>,
            <TextStyle variation="strong">Shipment Event Code</TextStyle>,
            <TextStyle variation="strong">Shipment Event Date</TextStyle>,
            <TextStyle variation="strong">
              Shipment Event Description
            </TextStyle>,
            <TextStyle variation="strong">Status</TextStyle>,
            <TextStyle variation="strong">Tracking Number</TextStyle>,
            <TextStyle variation="strong">Type</TextStyle>,
            <TextStyle variation="strong">ID</TextStyle>,
            <TextStyle variation="strong">Actions</TextStyle>,
          ]}
          rows={items}
        />
        {emtyState}
        <div id="pagination" style={{ padding: '20px 0' }}>
          <Pagination
            hasPrevious={currentPage > 1}
            onPrevious={() => setCurrentPage(currentPage - 1)}
            hasNext={currentPage < totalPages}
            onNext={() => setCurrentPage(currentPage + 1)}
          />
        </div>
      </Card>
    </Layout.Section>
  );
}

export default React.memo(ListOfTrackings);
