import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    headerRow: {
        zIndex: 5,
        position: "relative",
        flex: "0 0 5%",
      },
      paper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        color: theme.palette.primary.dark,
      },

}));