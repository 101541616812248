import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Button,
} from '@mui/material';
import { useStyles } from '../../../../Reports/ClinicReports/clinic-reports-invoices-table.styles';
import { useStyles as customStyles } from './create-smile-plan-categories.styles';
import { AddCircle } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import SmilePlanCategoriesRow from './smile-plan-categories-row.component';
import { DisplayText, Image } from '@shopify/polaris';
import emptyIcon from '../../../../img/emptyList.svg';
import AddSmilePlanCategoryDialog from './add-smile-plan-category-dialog.component';
import ClinicsReportsLoadingSpinner from '../../../../Reports/ClinicReports/clinics-reports-loading-spinner.component';

const SmilePlanCategoriesTable = ({
  smilePlanCategories,
  loadingSmilePlanCategories,
  createSmilePlanCategory,
  deleteSmilePlanCategory,
  getSmilePlanCategories,
  updateSmilePlanCategory,
}) => {
  const classes = useStyles();
  const smilePlanCategoriesClasses = customStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    void getSmilePlanCategories();
  }, []);

  if (loadingSmilePlanCategories) {
    return <ClinicsReportsLoadingSpinner />;
  }

  return (
    <>
      <div className={smilePlanCategoriesClasses.tableAddRow}>
        <Typography variant="h4">Smile Plan Categories</Typography>
        <Button
          color="primary"
          onClick={handleClickOpen}
          startIcon={<AddCircle />}
        >
          Add
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.header}>Id</TableCell>
              <TableCell className={classes.header}>Name</TableCell>
              <TableCell className={classes.header}>Edit</TableCell>
              <TableCell className={classes.header}>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {smilePlanCategories.length > 0 &&
                smilePlanCategories.map((smilePlanCategory) => (
                <SmilePlanCategoriesRow
                  key={smilePlanCategory.id}
                  smilePlanCategory={smilePlanCategory}
                  updateSmilePlanCategory={updateSmilePlanCategory}
                  deleteSmilePlanCategory={deleteSmilePlanCategory}
                />
              ))}
          </TableBody>
        </Table>
        {smilePlanCategories.length === 0 && (
          <div className="message-no-result">
            <div className="message-no-result-img">
              <Image src={emptyIcon} alt="empty" source=""></Image>
            </div>
            <DisplayText size="small">No smile plan categories were found.</DisplayText>
            <br />
          </div>
        )}
      </TableContainer>
      <AddSmilePlanCategoryDialog
        open={open}
        setOpen={setOpen}
        createSmilePlanCategory={createSmilePlanCategory}
      />
    </>
  );
};

export default SmilePlanCategoriesTable;
