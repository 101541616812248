import React from 'react'
import {
    Button,
    Card,
} from "@shopify/polaris";
import Image from '../Image/Image';

const SmileJourneyImages = ({title, image, userId, getData, stepId, setPopupActive, selected, handleSelectImages, areChecked, handleSelectDelete, handleDeleteImages}) => {
    return (
        <Card sectioned title={title}>
            <div className='bulk-select-container'>
                <Button
                    disabled={!image?.id || !image?.images_count}
                    onClick={() => handleSelectImages(image?.id)}
                >
                    {`${!selected ? 'Select' : 'Unselect'} images`}
                </Button>
                {
                    selected &&
                    <Button
                        destructive
                        onClick={() => handleDeleteImages(image?.id)}
                    >
                        Delete images
                    </Button>
                }
            </div>
            <div className="journey-images-wrapper">
                <Image
                    title='Big Smile'
                    subTitle='With Aligners'
                    source={image?.big_smile_with_aligner_url ? image?.big_smile_with_aligner_url : ''}
                    id='big_smile_with_aligner'
                    stepId={stepId}
                    imagesId={image?.id}
                    userId={userId}
                    getData={getData}
                    setPopupActive={setPopupActive}
                    areChecked={areChecked}
                    handleSelectDelete={handleSelectDelete}
                />
                <Image
                    title='Big Smile'
                    subTitle='Without Aligners'
                    source={image?.big_smile_without_aligner_url ? image?.big_smile_without_aligner_url : ''}
                    id='big_smile_without_aligner'
                    stepId={stepId}
                    imagesId={image?.id}
                    userId={userId}
                    getData={getData}
                    setPopupActive={setPopupActive}
                    areChecked={areChecked}
                    handleSelectDelete={handleSelectDelete}
                />
            </div>
            <div className="journey-images-wrapper">
                <Image
                    title='Front Closed'
                    subTitle='With Aligners'
                    source={image?.front_closed_with_aligner_url ? image?.front_closed_with_aligner_url : ''}
                    id='front_closed_with_aligner'
                    stepId={stepId}
                    imagesId={image?.id}
                    userId={userId}
                    getData={getData}
                    setPopupActive={setPopupActive}
                    areChecked={areChecked}
                    handleSelectDelete={handleSelectDelete}
                />
                <Image
                    title='Front Closed'
                    subTitle='Without Aligners'
                    source={image?.front_closed_without_aligner_url ? image?.front_closed_without_aligner_url : ''}
                    id='front_closed_without_aligner'
                    stepId={stepId}
                    imagesId={image?.id}
                    userId={userId}
                    getData={getData}
                    setPopupActive={setPopupActive}
                    areChecked={areChecked}
                    handleSelectDelete={handleSelectDelete}
                />
            </div>
            <div className="journey-images-wrapper">
                <Image
                    title='Left Closed'
                    subTitle='With Aligners'
                    source={image?.left_closed_with_aligner_url ? image?.left_closed_with_aligner_url : ''}
                    id='left_closed_with_aligner'
                    stepId={stepId}
                    imagesId={image?.id}
                    userId={userId}
                    getData={getData}
                    setPopupActive={setPopupActive}
                    areChecked={areChecked}
                    handleSelectDelete={handleSelectDelete}
                />
                <Image
                    title='Left Closed'
                    subTitle='Without Aligners'
                    source={image?.left_closed_without_aligner_url ? image?.left_closed_without_aligner_url : ''}
                    id='left_closed_without_aligner'
                    stepId={stepId}
                    imagesId={image?.id}
                    userId={userId}
                    getData={getData}
                    setPopupActive={setPopupActive}
                    areChecked={areChecked}
                    handleSelectDelete={handleSelectDelete}
                />
            </div>
            <div className="journey-images-wrapper">
                <Image
                    title='Right Closed'
                    subTitle='With Aligners'
                    source={image?.right_closed_with_aligner_url ? image?.right_closed_with_aligner_url : ''}
                    id='right_closed_with_aligner'
                    stepId={stepId}
                    imagesId={image?.id}
                    userId={userId}
                    getData={getData}
                    setPopupActive={setPopupActive}
                    areChecked={areChecked}
                    handleSelectDelete={handleSelectDelete}
                />
                <Image
                    title='Right Closed'
                    subTitle='Without Aligners'
                    source={image?.right_closed_without_aligner_url ? image?.right_closed_without_aligner_url : ''}
                    id='right_closed_without_aligner'
                    stepId={stepId}
                    imagesId={image?.id}
                    userId={userId}
                    getData={getData}
                    setPopupActive={setPopupActive}
                    areChecked={areChecked}
                    handleSelectDelete={handleSelectDelete}
                />
            </div>
            <div className="journey-images-wrapper">
                <Image
                    title='Top Closed'
                    subTitle='With Aligners'
                    source={image?.top_with_aligner_url ? image?.top_with_aligner_url : ''}
                    id='top_with_aligner'
                    stepId={stepId}
                    imagesId={image?.id}
                    userId={userId}
                    getData={getData}
                    setPopupActive={setPopupActive}
                    areChecked={areChecked}
                    handleSelectDelete={handleSelectDelete}
                />
                <Image
                    title='Top Closed'
                    subTitle='Without Aligners'
                    source={image?.top_without_aligner_url ? image?.top_without_aligner_url : ''}
                    id='top_without_aligner'
                    stepId={stepId}
                    imagesId={image?.id}
                    userId={userId}
                    getData={getData}
                    setPopupActive={setPopupActive}
                    areChecked={areChecked}
                    handleSelectDelete={handleSelectDelete}
                />
            </div>
            <div className="journey-images-wrapper">
                <Image
                    title='Bottom Closed'
                    subTitle='With Aligners'
                    source={image?.bottom_with_aligner_url ? image?.bottom_with_aligner_url : ''}
                    id='bottom_with_aligner'
                    stepId={stepId}
                    imagesId={image?.id}
                    userId={userId}
                    getData={getData}
                    setPopupActive={setPopupActive}
                    areChecked={areChecked}
                    handleSelectDelete={handleSelectDelete}
                />
                <Image
                    title='Bottom Closed'
                    subTitle='Without Aligners'
                    source={image?.bottom_without_aligner_url ? image?.bottom_without_aligner_url : ''}
                    id='bottom_without_aligner'
                    stepId={stepId}
                    imagesId={image?.id}
                    userId={userId}
                    getData={getData}
                    setPopupActive={setPopupActive}
                    areChecked={areChecked}
                    handleSelectDelete={handleSelectDelete}
                />
            </div>
        </Card>
    )
}

export default SmileJourneyImages