import React  from 'react';
import Modal from 'react-modal';
import close from '../../img/close.png';
import   '../ListJobs.css';


function FileViewer({isOpen,setIsOpen,file}) {


  const closeModal = () => {
    setIsOpen(false);
  };
  const customStyles = {
    content: {
      width: '70%', 
      height: '70%', 
      margin: 'auto', 
      marginTop: '100px', 
      scroll:'none'
    }
  };
  return (
    <div>
    
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
        
      >
        <div style={{justifyContent:'end',display:'flex'}}>
        <button onClick={closeModal} className='close_btn'><img src={close} alt='close'  className='close-img' /></button>

        </div>
        <div style={{height:'100%'}}>
            {file?<iframe
                title="PDF Viewer"
                src={file}
                width="100%"
                height="100%"
            ></iframe>:'' }
        
        </div>
      
      </Modal>
    </div>
  );
}

export default FileViewer;
