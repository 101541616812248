import axios from "../../axios";
import Cookie from "js-cookie";
import {
  Button,
  Checkbox,
  Modal,
  TextContainer,
  List,
  TextStyle,
} from "@shopify/polaris";
import { useState } from "react";
import { toast } from "react-toastify";

const Influencer = ({ userId, isInfluencer, setCheckedIsInfluencer, checkedPurchaseAlignerKit }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleCheckInfluencerDealStage = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `admin/v1/users/${userId}/is-influencer/status`,
        {},
        {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      );
      if (response.status === 200) {
        const { status } = response.data;
        if (status) {
          handleOpen();
        } else {
          toast.error("User is not in TP Ready Stage", { autoClose: 3000 });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleInfluencerFlow = async () => {
    try {
      handleClose();
      setLoading(true);
      const response = await axios.post(
        `admin/v1/users/${userId}/is-influencer/flow`,
        {},
        {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      );
      if (response.status === 200) {
        const { message } = response.data;
        toast.success(message, { autoClose: 3000 });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeCbIsInfluencer = async (checkedIsInfluencer) => {
    setCheckedIsInfluencer(checkedIsInfluencer);
    const bodyObj = {
      is_influencer: checkedIsInfluencer,
    };
    try {
      setLoading(true);
      const response = await axios.post(
        `admin/v1/users/${userId}/is-influencer`,
        bodyObj,
        {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      );
      if (response.status === 200) {
        const { message } = response.data;
        toast.success(message, { autoClose: 3000 });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="rowDiv">
      <div style={{ display: "flex", columnGap: "10px", alignItems: "center" }}>
        <Checkbox
          label="Mark this user as an Influencer/Winner"
          checked={isInfluencer}
          onChange={handleChangeCbIsInfluencer}
        />
        {isInfluencer && !checkedPurchaseAlignerKit && (
          <Button onClick={handleCheckInfluencerDealStage} size="slim">
            Activate Flow
          </Button>
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        title={
          "Are you sure you want to mark this user as an Influencer/Winner?"
        }
        primaryAction={{
          content: "Yes, I'm sure",
          onAction: handleInfluencerFlow,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleClose,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <TextStyle variation="strong">This Will:</TextStyle>
            <List type="bullet">
              <List.Item>
                Move the user to Aligners in Production or Redo Scan needed (if
                applicable) in the CRM
              </List.Item>
              <List.Item>
                Create an order for the patient with value 0
              </List.Item>
              <List.Item>Create a deal in the CRM</List.Item>
              <List.Item>Assign a Patient Care Owner</List.Item>
              <List.Item>Add an Aligner Kit Purchase value</List.Item>
              <List.Item>
                Move the user to the Smile Journey stage in their web and app
                accounts
              </List.Item>
              <List.Item>Create 2 dispatch batches</List.Item>
              <List.Item>
                Send a Slack Notification to the #customer_treatment_purchase
                (with mark "Influencer/winner")
              </List.Item>
              <List.Item>Add an "Influencer" label on the CRM</List.Item>
            </List>
          </TextContainer>
        </Modal.Section>
      </Modal>
      <Modal open={loading} loading={true}></Modal>
    </div>
  );
};

export default Influencer;
