import axios from '../../axios';
import React, { useEffect, useRef } from 'react'
import history from '../../history';
import { Button, FormLayout, Page } from '@shopify/polaris';
import Loading from '../../components/Loading/loading';
import Cookies from 'js-cookie';

export default function ScanConfirmLoading() {
    const query = new URLSearchParams(document.location.search);
    console.log(history)
    const bookingId = query.get('bookingId');
    const firstName = query.get('fname');
    const lastName = query.get('lname');
    const phone = query.get('phone');
    const profileId = query.get('profileId');
    const email = query.get('email');
    const bookingDate = query.get('date');
    const buttonRef = useRef(null);
    const clinicData = history.location.state  
    const clinicId =  Cookies.get('clinic_id');
    console.log(clinicData)
    useEffect(() => {
        setTimeout(() => {
            buttonRef.current.click();
        },3000)
    },[])
    const onSubmit = () => {
          let bookingData = {
              first_name: firstName,
              last_name: lastName,
              personal_mobile_number: phone,
              email: email,
              booking_id: bookingId,
              profile_id: profileId,
              clinic_id: parseInt(clinicId),
              booking_date: bookingDate,
              book_a_scan: true,
              clinic: clinicData,
              
            };
              axios
                .post(`admin/v1/users/confirm-booking`, bookingData, {
                    headers: {
                        Authorization: "Bearer " + Cookies.get("token"),
                      },
                })
                .then((response) => {
                    const booking_response = {}
                    booking_response.email = email;
                    booking_response.phone = phone
                Cookies.set('booking_response', booking_response)
                console.log('response', response);
                if(response.data.success){
                    history.push("/admin/users/booking-setup");
                } else {
                    history.goBack();
                }
            })
                .catch((error) => {
                  console.log(error);
                })
      };
    
    return (
          <Page>
          {<div><Loading/>
            <button style={{display:"none"}} ref={buttonRef} onClick={onSubmit}>Submit</button> </div> }
        </Page>
    )
}