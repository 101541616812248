import React, { useState, useEffect } from "react";
import {
  Page,
  Card,
  TextStyle,
  DataTable,
} from "@shopify/polaris";
import Cookies from "js-cookie";
import axios from "../axios";
import { CircularProgress } from "@material-ui/core";

function DesignerCases() {
  const [data, setData] = useState({
    loading: false,
    designerCases: [],
  });

  const getDesignerCases = () => {
    setData(prev => ({
      ...prev,
      loading: true,
    }));
    axios.get('admin/v1/designer-cases', {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((result) => {
      let cases = result.data.cases;
      let totalCases = Object.keys(cases).map((key) => ({designer: key, ...cases[key]}));
      setData(prev => ({
        ...prev,
        designerCases: totalCases,
      }));
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      setData(prev => ({
        ...prev,
        loading: false,
      }));
    })
  }

  useEffect(() => {
    getDesignerCases();
  }, [])

  return (
    <Page
      fullWidth
      title="Designer Cases"
      breadcrumbs={[{ content: "List Of Reports", url: "/admin/reports" }]}
    >
      <div className="approved-tps-wrapper">
        {
          data.loading ?
          <div style={{ textAlign: 'center' }}>
            <CircularProgress color='primary' />
          </div> :
          <Card>
            <DataTable
              columnContentTypes={["text", "text"]}
              headings={[
                <TextStyle variation="strong">Designer</TextStyle>,
                <TextStyle variation="strong">TP Ready</TextStyle>,
                <TextStyle variation="strong">TP Ready (Outsourced)</TextStyle>,
                <TextStyle variation="strong">Active</TextStyle>,
                <TextStyle variation="strong">Active (Outsourced)</TextStyle>,
                <TextStyle variation="strong">Completed</TextStyle>,
                <TextStyle variation="strong">Completed (Outsourced)</TextStyle>,
              ]}
              rows={
                data.designerCases.map((designerCase) => {
                  return (
                    [
                      <div>
                        <p>{designerCase.designer}</p>
                      </div>,
                      <div>
                        <p>{designerCase['Your Smile Plan']}</p>
                      </div>,
                      <div>
                        <p>{designerCase['outsourced_Your Smile Plan']}</p>
                      </div>,
                      <div>
                        <p>{designerCase['Aligner Kit Purchased']}</p>
                      </div>,
                      <div>
                        <p>{designerCase['outsourced_Aligner Kit Purchased']}</p>
                      </div>,
                      <div>
                        <p>{designerCase['Completed']}</p>
                      </div>,
                      <div>
                        <p>{designerCase['outsourced_Completed']}</p>
                      </div>,
                    ]
                  )
                })
              }
              sortable={[false, false, false, false, false]}
              defaultSortDirection="descending"
              verticalAlign="center"
            />
          </Card>
        }
      </div>
    </Page>
  );
}

export default DesignerCases;
