import React, { useState } from "react";
import {
  InlineError,
  Checkbox,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Card,
  PageActions,
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";
import history from "../history";
import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.

import { useParams } from "react-router-dom";

function AddSubPlan() {
  let { id } = useParams();
  const [valuePrice, setValuePrice] = useState("");
  const [checked, setChecked] = useState(false);
  const [fieldRequiredPrice, setFieldRequiredPrice] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );

  return (
    <Page
      title="Add Payment"
      breadcrumbs={[
        {
          content: "List Of Payments",
          url: "/admin/plans/" + id + "/payments",
        },
      ]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Price</TextStyle>
          <TextField
            value={valuePrice}
            onChange={handleChangePrice}
            type="number"
            prefix="$"
          />
          {fieldRequiredPrice}

          <Checkbox
            label="Down Payment"
            checked={checked}
            onChange={handleChangeCb}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
    </Page>
  );
  function handleChangePrice(valuePrice) {
    setValuePrice(valuePrice);
  }

  function handleChangeCb(checked) {
    setChecked(checked);
  }
  function handleSave() {
    if (valuePrice === "") {
      if (valuePrice === "")
        setFieldRequiredPrice(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
    } else {
      const bodyObj = {
        price: valuePrice,
        down_payment: checked,
      };
      axios
        .post("/admin/v1/plans/" + id + "/payments", bodyObj, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          history.push("/admin/plans/" + id + "/payments");
        })
        .catch((err) => console.log(err));
    }
  }
}
export default AddSubPlan;
