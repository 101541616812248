import React, { useState, useEffect } from "react";
import {
  Page,
  AppProvider,
  FormLayout,
  Card,
  TextStyle,
  Layout,
} from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../axios";

import "@zendeskgarden/react-pagination/dist/styles.css";
import { useParams } from "react-router-dom";
import { Timeline, TimelineEvent } from "react-event-timeline";
import "react-events-timeline/dist/main.css";

function TrackingDetails() {
  var { orderId, trackingId } = useParams();
  const [arrayEvents, setArrayEvents] = useState([]);
  const [currentTarcking, setCurrentTarcking] = useState([]);
  const data = [
    {
      date: 2019,
      title: "Senior Developer",
      label: "GitHub",
      location: "Palo Alto, California (USA)",
      content: <div>Description</div>,
    },
  ];
  const icon = <i className="fa fa-briefcase" />;
  useEffect(() => {
    axios
      .get(`admin/v1/orders/${orderId}/trackings/${trackingId}`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        console.log("responseTrackingsEvents=", result.data.data);
        setCurrentTarcking(result.data.data.tracking);
        setArrayEvents(
          result.data.data.carrier_tracking.ShipmentInfo.ShipmentEvent.map(
            (item, index, elements) => (
              <div key={index}>
                <TimelineEvent
                  title={item.ServiceEvent.Description}
                  createdAt={item.Date + "   " + item.Time}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16"
                      viewBox="0 0 24 24"
                      width="16"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z" />
                    </svg>
                  }
                >
                  {/* I received the payment for $543. Should be shipping the item
                  within a couple of hours. */}
                </TimelineEvent>
              </div>
            )
          )
        );
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page
      title="Tracking Details"
      breadcrumbs={[
        {
          content: "List Of Trackings",
          url: `/admin/orders/${orderId}/trackings`,
        },
      ]}
    >
      <FormLayout>
        <Layout>
          <Layout.Section oneThird>
            <Card sectioned>
              <FormLayout>
                <TextStyle>{currentTarcking.tracking_number}</TextStyle>
                {/* <TextStyle>{currentTarcking.type}</TextStyle> */}
              </FormLayout>
            </Card>
          </Layout.Section>
          <Layout.Section oneThird>
            <Card sectioned>
              <FormLayout>
                <TextStyle>
                  {currentTarcking.shipment_event_description}
                </TextStyle>
              </FormLayout>
            </Card>
          </Layout.Section>
          <Layout.Section oneThird>
            {" "}
            <Card sectioned>
              <TextStyle>{currentTarcking.shipment_event_date_time}</TextStyle>
            </Card>
          </Layout.Section>
        </Layout>
        <Card title="Shipment Events" sectioned>
          <Timeline lineStyle={{ height: "93%" }}>{arrayEvents}</Timeline>
        </Card>
      </FormLayout>
      {/* <Card sectioned>
          <div className="app">
            ...
            <EventsTimeline
              // title="WORK HISTORY"
              icon={icon}
              color="blue"
              data={data}
            />
          </div>
        </Card> */}
    </Page>
  );
}

export default TrackingDetails;
