export const iconColors = {
    'First Visit': {'background':'#FF2C4C','color':"white"},
    'IPR': {'background':'#52b788','color':"white"},
    'Redo Scan': {'background':'#F2B620','color':"white"},
    'OPG': {'background':'#AECE46','color':"white"},
    'Cleaning': {'background':'#9163CB','color':"white"},
    'Walk-in': {'background':'#d0e0e3','color':"white"},
    'Redo Impression': {'background':'#D5BDAF','color':"#434343"},
    'Offline Booking': {'background':'#55BB8C','color':"white"},
    'Buttons': {'background':'#00acac','color':"white"},
    'Add Attachments': {'background':'#FF7F51','color':"white"},
    'Remove Attachments': {'background':'#FF9B54','color':"white"},
    'Removal Of Buttons': {'background':'#F968DF','color':"white"},
    'X-RAY': {'background':'#e5fc9a','color':"#434343"},
    'Dental Checkup': {'background':'#D2B7E5','color':"white"},
    'Remove Fixed Retainers': {'background':'#fff372','color':"#434343"},
    'Redo Scan Photos': {'background':'#c1d3fe','color':"#434343"},
    'Post IPR Photos': {'background':'#b7e4c7','color':"#434343"},
    'Refinement Scan': {'background':'#FB6F92','color':"white"},
    'Periodontal Checkup': {'background':'#dec9e9','color':"#434343"},
    'Rebond Attachments': {'background':'#FED8B1','color':"#434343"},  
    'Redo Scan - Scan Error': {'background':'#87ABFF','color':"white"},
    'Redo Scan - Post Dental Work': {'background':'#5091F8','color':"white"},
    'Redo Scan - Post Cleaning': {'background':'#4476C6','color':"white"},
    'Redo Scan - Post Wire Removal': {'background':'#0E5A98','color':"white"},
    'Refinement Scan - Scan Error': {'background':'#FFC2D1','color':"#434343"},
    'MCA Scan': {'background':'#00acac','color':"#434343"},
    'MCA - Scan': {'background':'#00acac','color':"#434343"},
    'MCA Scan - Scan Error': {'background':'#00CCCC','color':"white"},
    'Retainer Scan': {'background':'#f1e34d','color':"#434343"},
    'Replace Powerchain': {'background':'#a5ffd6','color':"#434343"},
    'Post Treatment Photos': {'background':'#F17070','color':"white"},
    'Extraction': {'background':'#d05656','color':"white"},
    'Measure Spaces': {'background':'#4e8269','color':"white"},
    'Rebond Buttons': {'background':'#ff00d1','color':"white"},
    'Remove Excess Around Attachments': { background: '#ff6d01', color: "white" },
    'In-Clinic Whitening': { background: '#ffa601', color: "white" },
    'MCA Photos': { background: '#ff6d01', color: "white" },
    'Refinement Photos': { background: '#ff6d01', color: "white" },
}