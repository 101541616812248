import React, { useEffect, useState } from "react";
import "../ListJobs.css";
import axios from "../../axios";
import FileViewer from "../Modal/FileViewer";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import NavigationLayout from "../../components/NavigationLayout";
import { Page } from "@shopify/polaris";
import { Card } from "@material-ui/core";
import CopyToClipboard from "react-copy-to-clipboard";
import copy from "../../assets/images/copy.png";
import { ToastContainer, toast } from "react-toastify";

function ApplicantInfo() {
  const { id } = useParams();
  const [departments, setDepartments] = useState("");
  const [rate, setRate] = useState("");
  const [user, setUser] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState("");

  const fetchData = async () => {
    try {
      const response = await axios.get(`admin/v1/getallapplicants?id=${id}`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });
      const user = response.data.data;
      setDepartments(response.data.departments);
      setUser(user[0]);
      setRate(user[0].rating)
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  function formatDate(dateString) {
    const dateObject = new Date(dateString);
    const formattedDate = dateObject.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  }
  const rating = [
    { id: 0, name: "Select...", value: "" },
    { id: 1, name: "Not a fit", value: "Not a fit" },
    { id: 2, name: "Good fit", value: "Good fit" },
    { id: 3, name: "Maybe", value: "Maybe" },
    { id: 3, name: "Blacklist", value: "Blacklist" },
  ];
  const save = async (e) => {
    e.preventDefault();
    let data = {
      id: user.id,
      rating: rate,
    };
    try {
      await axios
        .post(`/admin/v1/addrating`, JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data.message, 3000);
          } else {
            toast.error(res.data.message, 3000);
          }
        });
      fetchData();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return !user ? (
    ""
  ) : (
    <NavigationLayout>
      <Page title="Applicant Info " fullWidth>
        <Card sectioned>
          <ToastContainer />
          {isOpen && (
            <FileViewer isOpen={isOpen} setIsOpen={setIsOpen} file={file} />
          )}

          <div style={{ padding: "2em" }}>
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                className="form-row-question"
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "30px",
                }}
              >
                <button onClick={() => window.history.go(-1)}>Go Back</button>
              </div>
            </div>
            <div className="applicants-details-title">
              Details
              <hr />
            </div>
            {/* <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <div className="" style={{ width: '100%' }}>
                                <div style={{ maxHeight: "40vh", overflow: "auto", alignItems: 'end' }}>


                                    <div className="listofjobs">

                                        <div className="col15">
                                            <label>
                                                Name
                                            </label>
                                        </div>
                                        <div className="col-20">
                                            <label>
                                                Email
                                            </label>
                                        </div>
                                        <div className="col-20">
                                            <label>
                                                Phone Number
                                            </label>
                                        </div>
                                        {user.reference_type==='App\\DepartmentRoles'&&
                                     <div className="col-20">
                                        <label>
                                        Location
                                        </label>
                                    
                                 </div>
                                    }
                                        <div className="col-20">
                                            <label>
                                                Date of Application
                                            </label>
                                        </div>

                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style={{ margin: '0px ' }} /> */}

            <div className="row ">
              <div className="col-3">
                <label>Frist Name</label>
                <div>{user.first_name}</div>
              </div>

              <div className="col-3">
                <label>Last Name</label>
                <div>{user.last_name}</div>
              </div>
              <div className="col-3">
                <div>
                  <label>Phone Number</label>
                  <CopyToClipboard text={user.phone}>
                    <img
                      src={copy}
                      alt="copy"
                      className="copy_img"
                      style={{ marginInline: "5px" }}
                    />
                  </CopyToClipboard>
                </div>
                <div>{user.phone}</div>
              </div>
              <div className="col-3">
                <div>
                  <label>Email</label>
                  <CopyToClipboard text={user.email}>
                    <img
                      src={copy}
                      alt="copy"
                      className="copy_img"
                      style={{ marginInline: "5px" }}
                    />
                  </CopyToClipboard>
                </div>
                <div>{user.email}</div>
              </div>

              <div className="col-3">
                <label>Date of Application</label>
                <div>{formatDate(user.created_at)}</div>
              </div>
              <div className="col-3">
                <label>Years of Experience</label>
                <div>{user.years_experience}</div>
              </div>
              <div className="col-3">
                <label>Rating</label>
                <div>{user.rating !== null ? user.rating : "-"}</div>
              </div>

              <div className="col-3">
                <label>Type of Application</label>
                <div>
                  {user.reference_type === "App\\Career"
                    ? "Posted Job"
                    : "Future Opportunity"}
                </div>
              </div>

              {user.reference_type === "App\\Career" && (
                <div className="col-3">
                  <label>Job ID</label>
                  <div>{user?.reference?.id}</div>
                </div>
              )}
              {user.reference_type === "App\\Career" && (
                <div className="col-3">
                  <label>Job Versrion</label>
                  <div>{user?.reference?.version}</div>
                </div>
              )}
              <div className="col-3">
                <label>Position Title</label>
                <div>
                  {user.reference_type === "App\\Career"
                    ? user?.reference?.title
                    : user?.reference?.name}
                </div>
              </div>

              {user.reference_type === "App\\Career" ? (
                <div className="col-3">
                  <label>Employment Type</label>
                  <div>{user.reference?.employment_type}</div>
                </div>
              ):
              <div className="col-3">
              <label>Employment Type</label>
              <div>{user?.employment_type}</div>
            </div>}

              <div className="col-3">
                <label>Department</label>
                <div>
                  {user.reference_type === "App\\Career"
                    ? departments
                        .filter(
                          (dep) => dep.id === user.reference?.department_id
                        )
                        .map((dep) => dep.name)
                    : departments
                        .filter(
                          (dep) => dep.id === user.reference?.departments_id
                        )
                        .map((dep) => dep.name)}
                </div>
              </div>

              {user.reference_type === "App\\Career" ? (
                <div className="col-3">
                  <label>Job Location</label>
                  <div>{user.reference?.location}</div>
                </div>
              ):
              <div className="col-3">
                  <label>Job Location</label>
                  <div>{ user?.countries}</div>
                </div>
              }
              <div className="col-3">
                <label>Rating:</label>
                <div>
                  <select
                    name="rating"
                    value={rate}
                    onChange={(e) => setRate(e.target.value)}
                    style={{width:'150px',height:'30px'}}
                  >
                    {rating.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <button
                    type="button"
                    className="addnewjob"
                    style={{ cursor: "pointer" }}
                    onClick={save}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>

            <div
              style={{
                paddingTop: "21px",
                paddingBottom: "21px",
                textAlign: "start",
                display: "flex",
              }}
            >
              <div>
                {user.cv_link_url && (
                  <button
                    className="btn-cv"
                    onClick={() => setIsOpen(true) & setFile(user.cv_link_url)}
                  >
                    View CV
                  </button>
                )}
              </div>
              <div>
                {user.cover_letter_url && (
                  <button
                    className="btn-cv"
                    style={{ marginInline: "10px" }}
                    onClick={() =>
                      setIsOpen(true) & setFile(user.cover_letter_url)
                    }
                  >
                    View Cover Letter
                  </button>
                )}
              </div>
              <div>
                {user.additional_files_url && (
                  <button
                    className="btn-cv"
                    onClick={() =>
                      setIsOpen(true) & setFile(user.additional_files_url)
                    }
                  >
                    View Additional Files
                  </button>
                )}
              </div>
            </div>

            <div style={{ paddingTop: "20px" }}>
              {user && user.answer_applicant.length !== 0 && (
                <>
                  <div className="applicants-details-title">
                    Summary of all the questions and responses
                    <hr />
                  </div>
                  <div style={{ paddingTop: "20px" }}>
                    {user.answer_applicant.map((info) => (
                      <div className="">
                        <span style={{ fontWeight: "500" }}>Question:</span>{" "}
                        {info.reference_type === "App\\JobApplicantsAnswers"
                          ? info.futurequestion.survey_question
                              .screening_question_en
                          : info.question.survey_question.screening_question_en}
                        <div
                          style={{ paddingTop: "10px", paddingBottom: "10px" }}
                        >
                          <span style={{ fontWeight: "500" }}>Answer:</span>{" "}
                          {info.survey_answer}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </Card>
      </Page>
    </NavigationLayout>
  );
}
export default ApplicantInfo;
