import React, { useEffect, useState } from "react";
import NavigationLayout from "../../components/NavigationLayout";
import { Page } from "@shopify/polaris";
import { Card } from "@material-ui/core";
import "../ListJobs.css";
import axios from "../../axios";
import ReactSelect from "react-select";
import Cookies from "js-cookie";
import FileViewer from "../Modal/FileViewer";
import history from "../../history";
import ThinLoading from "../../components/Loading/thin-loading.component";

function ManageCVBanks() {
  const [applicants, setApplicants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [cvlink, setCvlink] = useState("");
  const [applicantdetails, setApplicantDetails] = useState("");
  console.log("applicants", applicants);
  // const [page, setPage] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    years_experience: "",
    rating: "",
    reference_type: "",
    department: "",
    title: "",
    created_at: "",
    location: "",
    employment_type: "",
  });
  const [departments, setDepartments] = useState([]);

  const rating = [
    { id: 0, name: "Select...", value: "" },
    { id: 1, name: "Not a fit", value: "Not a fit" },
    { id: 2, name: "Good fit", value: "Good fit" },
    { id: 3, name: "Maybe", value: "Maybe" },
    { id: 3, name: "Blacklist", value: "Blacklist" },
  ];

  const fetchApplicants = async () => {
    try {
      setLoading(true);
      const params = {};

      if (formData.name !== "") {
        params.name = formData.name;
      }
      if (formData.email !== "") {
        params.email = formData.email;
      }
      if (formData.phone !== "") {
        params.phone = formData.phone;
      }
      if (formData.years_experience !== "") {
        params.years_experience = formData.years_experience;
      }
      if (formData.rating !== "") {
        params.rating = formData.rating;
      }
      if (formData.reference_type !== "") {
        params.reference_type = formData.reference_type;
      }
      if (formData.department !== "") {
        params.department = formData.department;
      }
      if (formData.title !== "") {
        params.title = formData.title;
      }
      if (formData.created_at !== "") {
        params.created_at = formData.created_at;
      }
      if (formData.employment_type !== "") {
        params.employment_type = formData.employment_type;
      }
      if (formData.location !== "") {
        params.location = formData.location;
      }
      const jobApplicant = await axios.get(`admin/v1/getallapplicants`, {
        params: params,
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });

      const data = jobApplicant.data.data;
      setApplicants(data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchAllApplicants = async () => {
      try {
        setLoading(true);

        const response = await axios.get(`admin/v1/getallapplicants`, {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        });

        setApplicants(response.data.data);
        setDepartments(response.data.departments);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllApplicants();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const FilterApplicants = () => {
    // if (currentpage === 1) {
    //     // fetchCareers()
    // } else {
    //     setCurrentPage(1)
    // }
    fetchApplicants();
  };

  // const [page, setPage] = useState(1);

  const fetchAllApplicants = async () => {
    try {
      const jobApplicant = await axios.get(`admin/v1/getallapplicants`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });

      const data = jobApplicant.data.data;
      setApplicants(data);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const resetFilter = () => {
    setFormData({
      name: "",
      email: "",
      phone: "",
      years_experience: "",
      rating: "",
      reference_type: "",
      department: "",
      title: "",
      created_at: "",
      location: "",
      employment_type: "",
    });
    fetchAllApplicants();
  };
  const type = [
    { id: 0, name: "Select Type", value: "" },
    { id: 1, name: "Posted Job ", value: "App\\Career" },
    { id: 2, name: "Future Opportunity", value: "App\\DepartmentRoles" },
  ];
  const employmenttype = [
    { id: 0, name: "Select Employment Type ", value: "" },

    { id: 1, name: "Full-time", value: "full-time" },
    { id: 2, name: "Part-time", value: "part-time" },
    { id: 3, name: "Internship", value: "internship" },
    { id: 4, name: "Contract", value: "contract" },
    { id: 5, name: "Freelance/Project Based", value: "Freelance/Project Based" },

  ];
  const countries = [
    { id: 1, label: "Bahrain", value: "Bahrain" },
    { id: 2, label: "Kuwait", value: "Kuwait" },
    { id: 3, label: "Lebanon", value: "Lebanon" },
    { id: 4, label: "Qatar", value: "Qatar" },
    { id: 5, label: "Saudi Arabia", value: "Saudi Arabia" },
    { id: 6, label: "United Arab Emirates", value: "United Arab Emirates" },
    { id: 7, label: "United Kingdom", value: "United Kingdom" },
    { id: 8, label: "Other", value: "Other" }
  ];
  const optionsStyling = {
    backgroundColor: "#fff",
    padding: "1rem",
    cursor: "pointer",
    overflowY: "scroll",
  };

  const descCustomStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#000" : "#000",
      ...optionsStyling,
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused ? 0 : 0,
      border: "1px solid #ccc",
      padding: "0rem !importent",

      ...controlStyling,
    }),
  };
  const controlStyling = {
    paddingTop: "1px",
    paddingBottom: "1px",

    backgroundColor: "transparent",
    cursor: "pointer",
    color: "black",
    // fontSize: '14px',
    "&:hover": {
      borderColor: "lightgray",
    },
  };
  const handleChangeCountries = (selectedOptions) => {
    if (selectedOptions && selectedOptions.length > 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        location: selectedOptions.map((option) => option.value).join(","),
      }));
    }
  };
  function formatDate(dateString) {
    const dateObject = new Date(dateString);
    const formattedDate = dateObject.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  }
  return (
    <NavigationLayout>
      <Page title="Manage CV Banks" fullWidth>
        {show && <FileViewer isOpen={show} setIsOpen={setShow} file={cvlink} />}
        <Card sectioned>
          <div style={{ padding: "2em" }}>
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                className="form-row-question"
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "30px",
                }}
              >
                <button onClick={() => window.history.go(-1)}>Go Back</button>
              </div>
            </div>
            <form>
              <div style={{ fontWeight: "500", paddingBottom: "20px" }}>
                Search by:
              </div>
              <div
                className="career-form-group list-jobs-form-group"
                style={{ display: "flex", alignItems: "end", flexWrap: "wrap" }}
              >
                <div>
                  <div>
                    <label>Name:</label>
                    <input
                      type="text"
                      name="name"
                      className="input_form"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div></div>
                </div>
                <div>
                  <label>Email:</label>

                  <input
                    type="email"
                    name="email"
                    className="input_form"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>Phone:</label>

                  <input
                    type="phone"
                    name="phone"
                    className="input_form"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label>Type of Application:</label>
                  <select
                    name="reference_type"
                    value={formData.reference_type}
                    onChange={handleChange}
                    required
                  >
                    {type.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label>Department:</label>

                  <select
                    name="department"
                    value={formData.department}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select...</option>
                    {departments.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label>Title:</label>

                  <input
                    type="text"
                    name="title"
                    className="input_form"
                    value={formData.title}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label>Date of Application:</label>
                  <input
                    type="date"
                    name="created_at"
                    className="input_form"
                    value={formData.created_at}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>Minimun Years of Experience:</label>

                  <input
                    type="number"
                    name="years_experience"
                    className="input_form"
                    value={formData.years_experience}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>Employment Type:</label>

                  <select
                    name="employment_type"
                    value={formData.employment_type}
                    onChange={handleChange}
                  >
                    {employmenttype.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label>Rating:</label>
                  <select
                    name="rating"
                    value={formData.rating}
                    onChange={handleChange}
                  >
                    {rating.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label>Location:</label>

                  <ReactSelect
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        neutral50: "#808080",
                      },
                    })}
                    options={countries}
                    isMulti
                    onChange={handleChangeCountries}
                    className="select_countries_input"
                    styles={descCustomStyles}
                  />
                </div>

                <div>
                  <div style={{ display: "flex ", justifyContent: "end " }}>
                    <button
                      type="button"
                      className="addnewjob"
                      style={{ cursor: "pointer" }}
                      onClick={FilterApplicants}
                    >
                      Filter
                    </button>
                    <button
                      type="button"
                      className="addnewjob"
                      style={{ cursor: "pointer", marginInline: "10px" }}
                      onClick={resetFilter}
                    >
                      Reset Filter
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="" style={{ width: "100%" }}>
                <div style={{ maxHeight: "40vh", overflow: "auto" }}>
                  <div className="listofjobs">
                    <div className="col-20">
                      <label>Name</label>
                    </div>
                    <div className="col-20">
                      <label>
                        Type of
                        <br />
                        Application
                      </label>
                    </div>

                    <div className="col-20">
                      <label>
                        Position
                        <br />
                        Title
                      </label>
                    </div>
                    <div className="col-20">
                      <label>Department</label>
                    </div>
                    <div className="col-20">
                      <label>Country</label>
                    </div>
                    <div className="col-20">
                      <label>
                        Years of
                        <br />
                        Experience
                      </label>
                    </div>
                    <div className="col-20">
                      <label>
                        Employment
                        <br />
                        Type
                      </label>
                    </div>
                    <div className="col-20">
                      <label>
                        Date of
                        <br />
                        Application
                      </label>
                    </div>
                    <div className="col-20" style={{ textAlign: "end" }}>
                      <label>Actions</label>
                    </div>
                    <div className="col-20">
                      <label></label>
                    </div>
                  </div>
                </div>
                <hr style={{ margin: "0px " }} />
                <div style={{ maxHeight: "40vh", overflow: "auto" }}>
                  {loading ? (
                    <div
                      style={{
                        textAlign: "center",
                        paddingTop: "5vh",
                        paddingBottom: "5vh",
                      }}
                    >
                      <ThinLoading />
                    </div>
                  ) : applicants.length === 0 ? (
                    <div
                      style={{
                        textAlign: "center",
                        paddingTop: "5vh",
                        paddingBottom: "5vh",
                      }}
                    >
                      No Applicants
                    </div>
                  ) : (
                    applicants.map((data) => (
                      <>
                        <div
                          className="listofjobs-map"
                          style={{ cursor: "pointer" }}
                          onClick={() => setApplicantDetails(data)}
                        >
                          {console.log("applicants", applicants)}
                          <div className="col-20">
                            <span>
                              {data.first_name} {data.last_name}
                            </span>
                          </div>
                          <div className="col-20">
                            <span>
                              {data.reference_type === "App\\Career"
                                ? "Posted Job"
                                : "Future Opportunity"}
                            </span>
                          </div>

                          <div className="col-20">
                            <span>
                              {data.reference !== null
                                ? data.reference_type === "App\\Career"
                                  ? data.reference.title
                                  : data.reference.name
                                : ""}
                            </span>
                          </div>
                          <div className="col-20">
                            <span>
                              {data.reference_type === "App\\Career"
                                ? departments
                                    .filter(
                                      (dep) =>
                                        dep.id ===
                                        data?.reference?.department_id
                                    )
                                    .map((dep) => dep.name)
                                : departments
                                    .filter(
                                      (dep) =>
                                        dep.id ===
                                        data?.reference?.departments_id
                                    )
                                    .map((dep) => dep.name)}
                            </span>
                          </div>
                          <div className="col-20">
                            <span>
                              {data.reference_type === "App\\Career"
                                ? data?.reference?.location
                                : data.countries}
                            </span>
                          </div>
                          <div className="col-20">
                            <span>{data.years_experience}</span>
                          </div>

                          <div className="col-20">
                            <span>
                            {data.reference_type === "App\\Career"
                              ? data?.reference?.employment_type || '-'
                              : data?.employment_type || '-'}
                            </span>
                          </div>
                          <div className="col-20">
                            <span>{formatDate(data.created_at)}</span>
                          </div>
                          <div className="col-20 " style={{ display: "flex" }}>
                            <button
                              className="btnview"
                              onClick={() =>
                                setShow(true) & setCvlink(data.cv_link_url)
                              }
                            >
                              View CV
                            </button>
                          </div>
                          <div className="col-20 " style={{ display: "flex" }}>
                            <button
                              className="btnview"
                              style={{ marginLeft: "10px" }}
                              onClick={() =>
                                history.push(`/admin/applicant/${data.id}`)
                              }
                            >
                              View Applicant
                            </button>
                          </div>
                        </div>

                        <hr className="hr-list" />
                      </>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Page>
    </NavigationLayout>
  );
}
export default ManageCVBanks;
