import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  table: {
    '& tr th, & tr td': {
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif !important',
      fontSize: '1em',
    },
    '& tr th': {
      fontWeight: 'bold',
    },
  },
}));
