import React from "react";
import { Switch, Route, Router, withRouter } from "react-router-dom";
import history from "../history";
import { AppProvider } from "@shopify/polaris";
import ListOfUsers from "./ListOfUsers";
import Edit from "./Edit";
import NavigationLayout from "../components/NavigationLayout";

function MainEdit() {
  return (
    <AppProvider>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/coupons/:id" component={Edit} />
          <Route path="/admin/coupons/:id/users" component={ListOfUsers} />
        </Switch>
      </Router>
    </AppProvider>
  );
}
export default MainEdit;
