import React, { useEffect, useState } from "react";
import NavigationLayout from "../components/NavigationLayout";
import "./ListJobs.css";
import { Page } from "@shopify/polaris";
import Pagination from "@material-ui/lab/Pagination";
import axios from "../axios";
import history from "../history";
import { Card } from "@material-ui/core";
import Cookies from "js-cookie";
import ReactSelect from "react-select";
import ThinLoading from "../components/Loading/thin-loading.component";

function ListJobs() {
  const [careers, setCareers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastpage, setLastPage] = useState(0);
  const [currentpage, setCurrentPage] = useState(1);
  const [departments, setDepartments] = useState([]);

  // const [page, setPage] = useState(1);
  const [formData, setFormData] = useState({
    title: "",
    department_id: "",
    status: "",
    location: "",
    workplace_type: "",
    employment_type: "",
    created_at: "",
  });
  const workplacetype = [
    { id: 0, name: "Select Workplace Type", value: "" },
    { id: 1, name: "On-site", value: "on-site" },
    { id: 2, name: "Remote", value: "remote" },
    { id: 3, name: "Hybrid", value: "hybrid" },
  ];

  const employmenttype = [
    { id: 0, name: "Select Employment Type ", value: "" },

    { id: 1, name: "Full-time", value: "full-time" },
    { id: 2, name: "Part-time", value: "part-time" },
    { id: 3, name: "Internship", value: "internship" },
    { id: 4, name: "Contract", value: "contract" },
    { id: 5, name: "Freelance/Project Based", value: "Freelance/Project Based" },

  ];
  const status = [
    { id: 0, name: "Select Status", value: "" },
    { id: 1, name: "Open", value: "open" },
    { id: 2, name: "Closed", value: "closed" },
    { id: 3, name: "Draft", value: "draft" },
  ];

  const fetchCareers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `admin/v1/career?page=${currentpage}${
          formData.created_at !== "" ? `&created_at=${formData.created_at}` : ""
        }${
          formData.department_id !== ""
            ? `&department_id=${formData.department_id}`
            : ""
        }${formData.location != "" ? `&location=${formData.location}` : ""}${
          formData.workplace_type != ""
            ? `&workplace_type=${formData.workplace_type}`
            : ""
        }${
          formData.employment_type != ""
            ? `&employment_type=${formData.employment_type}`
            : ""
        }${formData.title != "" ? `&title=${formData.title}` : ""}${
          formData.status != "" ? `&status=${formData.status}` : ""
        }`,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      const data = response.data.data;

      setLastPage(data.last_page);

      setCareers(data.data);
      setDepartments(response.data.department);
    } catch (error) {
      console.error("Error fetching careers:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCareers();
  }, [currentpage]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const FilterJobs = () => {
    if (currentpage === 1) {
      fetchCareers();
    } else {
      setCurrentPage(1);
    }
  };

  const resetFilterAndFetchCareers = async () => {
    try {
      setLoading(true);
      setFormData({
        title: "",
        department_id: "",
        status: "",
        location: "",
        workplace_type: "",
        employment_type: "",
        created_at: "",
      });
      setCurrentPage(1);
      const response = await axios.get(`admin/v1/career`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });

      const data = response.data.data;
      setLastPage(data.last_page);
      setCareers(data.data);
    } catch (error) {
      console.error("Error fetching careers:", error);
    } finally {
      setLoading(false);
    }
  };

  const countries = [
    { id: 1, label: "Bahrain", value: "Bahrain" },
    { id: 2, label: "Kuwait", value: "Kuwait" },
    { id: 3, label: "Lebanon", value: "Lebanon" },
    { id: 4, label: "Qatar", value: "Qatar" },
    { id: 5, label: "Saudi Arabia", value: "Saudi Arabia" },
    { id: 6, label: "United Arab Emirates", value: "United Arab Emirates" },
    { id: 7, label: "United Kingdom", value: "United Kingdom" },
    { id: 8, label: "Other", value: "Other" },
  ];
  const optionsStyling = {
    backgroundColor: "#fff",
    padding: "1rem",
    cursor: "pointer",
    // fontSize: '14px',
    overflowY: "scroll",
  };

  const descCustomStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#000" : "#000",
      ...optionsStyling,
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused ? 0 : 0,
      border: "1px solid #ccc",
      padding: "0rem !importent",

      ...controlStyling,
    }),
  };
  const controlStyling = {
    paddingTop: "1px",
    paddingBottom: "1px",

    backgroundColor: "transparent",
    cursor: "pointer",
    color: "black",
    // fontSize: '14px',
    "&:hover": {
      borderColor: "lightgray",
    },
  };
  const handleChangeCountries = (selectedOptions) => {
    if (selectedOptions && selectedOptions.length > 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        location: selectedOptions.map((option) => option.value).join(","),
      }));
    }
  };
  // const [page, setPage] = useState(1);
  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };
  function formatDate(dateString) {
    const dateObject = new Date(dateString);
    const formattedDate = dateObject.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  }
  return (
    <NavigationLayout>
      <Page title="List Of Jobs" fullWidth>
        <Card sectioned>
          <div style={{ padding: "2em" }}>
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                className="form-row-question"
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "30px",
                }}
              >
                <button onClick={() => history.push(`/admin/careers`)}>
                  Go Back
                </button>
              </div>
            </div>
            <form>
              <div
                className="career-form-group list-jobs-form-group"
                style={{ display: "flex", alignItems: "end" }}
              >
                <div>
                  <div>
                    <label>Title:</label>
                    <input
                      type="text"
                      name="title"
                      className="input_form"
                      value={formData.title}
                      onChange={handleChange}
                    />
                  </div>
                  <div></div>
                </div>
                <div>
                  <label>Department:</label>

                  <select
                    name="department_id"
                    value={formData.department_id}
                    onChange={handleChange}
                  >
                    {departments.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label>Location:</label>

                  <ReactSelect
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        neutral50: "#808080",
                      },
                    })}
                    options={countries}
                    isMulti
                    onChange={handleChangeCountries}
                    className="select_countries_input"
                    styles={descCustomStyles}
                  />
                </div>
                <div>
                  <label>Workplace Type:</label>
                  <select
                    name="workplace_type"
                    value={formData.workplace_type}
                    onChange={handleChange}
                  >
                    {workplacetype.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label>Employment Type:</label>
                  <select
                    name="employment_type"
                    value={formData.employment_type}
                    onChange={handleChange}
                  >
                    {employmenttype.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label>Status:</label>
                  <select
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                  >
                    {status.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label>Published On:</label>
                  <input
                    type="date"
                    name="created_at"
                    className="input_form"
                    value={formData.created_at}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <div style={{ display: "flex ", justifyContent: "end " }}>
                    <button
                      type="button"
                      className="addnewjob"
                      style={{ cursor: "pointer" }}
                      onClick={FilterJobs}
                    >
                      Filter
                    </button>
                    <button
                      type="button"
                      className="addnewjob"
                      style={{ cursor: "pointer", marginInline: "10px" }}
                      onClick={resetFilterAndFetchCareers}
                    >
                      Reset Filter
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div style={{ maxHeight: "40vh", overflow: "auto" }}>
              <div className="listofjobs">
                <div className="col10">
                  <label>ID</label>
                </div>
                <div className="col10">
                  <label>version</label>
                </div>
                <div className="col20">
                  <label>Title</label>
                </div>
                <div className="col20">
                  <label>Status</label>
                </div>

                <div className="col20">
                  <label>Applicants</label>
                </div>
                <div className="col20">
                  <label>Country</label>
                </div>
                <div className="col20">
                  <label>Employment Type</label>
                </div>
                <div className="col20">
                  <label>Closed On</label>
                </div>
                <div className="col10"></div>
                <div className="col20"></div>
              </div>
            </div>
            <hr style={{ margin: "0px " }} />
            <div style={{ maxHeight: "40vh", overflow: "auto" }}>
              {loading ? (
                <div
                  style={{
                    textAlign: "center",
                    paddingTop: "5vh",
                    paddingBottom: "5vh",
                  }}
                >
                  <ThinLoading />
                </div>
              ) : careers.length === 0 ? (
                <div
                  style={{
                    textAlign: "center",
                    paddingTop: "5vh",
                    paddingBottom: "5vh",
                  }}
                >
                  No data
                </div>
              ) : (
                careers
                  .sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                  )
                  .map((data) => (
                    <>
                      <div className="listofjobs-map">
                        <div className="col10">
                          <span>{data.id}</span>
                        </div>
                        <div className="col10">
                          <span>{data.version}</span>
                        </div>

                        <div className="col20">
                          <span>{data.title}</span>
                        </div>
                        <div className="col20">
                          <span>
                            {data.status === "Open" && (
                              <div className="green-circle">Open</div>
                            )}
                            {data.status === "Closed" && (
                              <div className="blue-circle">Closed</div>
                            )}
                            {data.status === "Draft" && (
                              <div className="grey-circle">Draft</div>
                            )}
                          </span>
                        </div>

                        <div className="col20">
                          <span>
                            {/* {departments.filter(dep => dep.id === data.department_id).map(dep => dep.name)} */}
                            {data.number_of_applicants.count}
                          </span>
                        </div>
                        <div className="col20">
                          <span>{data.location}</span>
                        </div>
                        <div className="col20">
                          <span>{data.employment_type}</span>
                        </div>
                        <div className="col20">
                          <span>
                            {data.closed_on ? formatDate(data.closed_on) : "-"}
                          </span>
                        </div>
                        <div className="col10" style={{ display: "flex" }}>
                          <div>
                            <button
                              className="btnview2"
                              onClick={() =>
                                history.push({
                                  pathname: `/admin/jobs/${data.id}`,
                                  state: { referrer: "/admin/alljobs" },
                                })
                              }
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                        <div className="col20" style={{ display: "flex" }}>
                          <div style={{ marginLeft: "10px" }}>
                            <button
                              className="btnview"
                              onClick={() =>
                                history.push(`/admin/job/applicants/${data.id}`)
                              }
                            >
                              View Candidates
                            </button>
                          </div>
                        </div>
                      </div>
                      <hr className="hr-list" />
                    </>
                  ))
              )}
            </div>

            {lastpage >= 2 && (
              <div
                style={{
                  paddingTop: "40px",
                  textAlign: "center",
                  paddingBottom: "10px",
                }}
              >
                <Pagination
                  count={lastpage}
                  page={currentpage}
                  onChange={handleChangePage}
                  size="large"
                />
              </div>
            )}
          </div>
        </Card>
      </Page>
    </NavigationLayout>
  );
}
export default ListJobs;
