import { TableCell, TableRow, IconButton } from '@mui/material';
import { useStyles } from '../../../../Reports/ClinicReports/clinic-reports-invoices-table.styles';
import { Delete, Edit } from '@mui/icons-material';
import EditSmilePlanCategoryDialog from './edit-smile-plan-category-dialog.component';
import { useState } from 'react';
import DeleteSmilePlanCategoryDialog from './delete-smile-plan-category-dialog.component';

const SmilePlanCategoriesRow = ({ smilePlanCategory, updateSmilePlanCategory, deleteSmilePlanCategory }) => {
  const [openEditDialog, setEditDialogOpen] = useState(false);
  const [openDeleteDialog, setDeleteDialogOpen] = useState(false);

  const handleEditOpen = () => {
    setEditDialogOpen(true);
  };
  const handleDeleteOpen = () => {
    setDeleteDialogOpen(true);
  };

  const classes = useStyles();
  return (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell className={classes.tableCell}>{smilePlanCategory.id}</TableCell>
      <TableCell className={classes.tableCell}>{smilePlanCategory.name}</TableCell>
      <TableCell className={classes.tableCell}>
        <IconButton onClick={handleEditOpen}>
          <Edit />
        </IconButton>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <IconButton onClick={handleDeleteOpen}>
          <Delete />
        </IconButton>
      </TableCell>
      <EditSmilePlanCategoryDialog
        open={openEditDialog}
        setOpen={setEditDialogOpen}
        updateSmilePlanCategory={updateSmilePlanCategory}
        smilePlanCategory={smilePlanCategory}
      />
      <DeleteSmilePlanCategoryDialog
        id={smilePlanCategory.id}
        open={openDeleteDialog}
        setOpen={setDeleteDialogOpen}
        deleteSmilePlanCategory={deleteSmilePlanCategory}
      />
    </TableRow>
  );
};

export default SmilePlanCategoriesRow;
