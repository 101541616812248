import React, { useEffect, useState } from 'react';
import { Badge, Button, Icon } from "@shopify/polaris";
import { useHistory } from 'react-router-dom';
import { ExternalMinor, MobileBackArrowMajor } from "@shopify/polaris-icons";
import Cookies from "js-cookie";
import axios from "../../axios";
import Cookie from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { tagsColor } from "../../util/helpers";
import CopyToClipboard from "react-copy-to-clipboard";
import copy from "../../assets/images/copy.png";

const UserHeader = ({
  clinicName,
  pageTitle,
  status,
  userId,
  planType,
  clinicStatus,
}) => {
  const history = useHistory();
  const [userTags, setUserTags] = useState({
    isReferredByAFriend: false,
    purchasedTpWithin15DayWindow: false,
  });
  const [copiedText, setCopiedText] = useState(false);

  const handleCopy = () => {
    setCopiedText(true);
  };
  
  function handleImpersonateButtonClick() {
    axios
      .get(`/admin/v1/users/${userId}/impersonate`, {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      })
      .then((res) => {
        toast.success("This user is impersonated", 3000);
        window.open(res.data.link, "_blank");
      })
      .catch((err) => console.log(err));
  }

  const userReferral = () => {
    axios.get(`/admin/v1/users/${userId}/tags`, {
      headers: {
        Authorization: `Bearer ${Cookie.get("token")}`,
      },
    }).then((res) => {
      setUserTags({
        isReferredByAFriend: res.data.is_referred,
        purchasedTpWithin15DayWindow: res.data.purchased_tp_within_15_day_window,
      });
    }).catch((err) => console.log(err));
  }

  function handleTeleconsultationButtonClick() {
    const url = `/admin/users/${userId}/teleconsult`; // Modify the URL pattern according to your routing configuration
    history.push(url);
  }

  let planTypeColor = tagsColor(planType?.status, "planType");
  let statusColor = tagsColor(status?.status, "status");
  const  containerdiv= {
    display:'flex',
    marginRight:'20px',
  };
  const copyimage = {
    width: '16px',
    heigth: '16px',
    cursor: 'pointer',
    marginLeft:'7px'
  
  };
  const copiedtext = {
    fontWeight: '400',
    fontSize: '15px',
    marginLeft:'7px'
  };
  
  const url = window.location.href;
  let clipboardText = url;
  if (pageTitle?.id && pageTitle?.fullname) {
    const { id, fullname } = pageTitle;
    clipboardText = `${fullname} (ID: ${id}) ${url}`;
  }

  useEffect(() => {
    if (copiedText) {
      const timer = setTimeout(
        () => setCopiedText(false),
        2000,
      );
      return () => clearTimeout(timer);
    }
  }, [copiedText]);

  useEffect(() => {
    userReferral();
  }, [])

  return (
    <div className="navHeaderWrap">
      <div
        style={{
          height: "fit-content",
          padding: "10px",
          paddingLeft: "20px",
          fontWeight: "bold",
          width: "100%",
          fontSize: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Button onClick={() => history.goBack()}>
            <Icon source={MobileBackArrowMajor} color="base" />
          </Button>
          <div style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => window.scroll({ top: 0, behavior: "smooth" })}
            >
                <div style={containerdiv}>
                  <div>
                    {pageTitle?.name}&nbsp;
                  </div>
                  <CopyToClipboard 
                    text={clipboardText}
                    onCopy={handleCopy}
                    options={{ format: "text/plain" }}
                  >
                    <div>
                      {copiedText ? (
                        <div style={copiedtext}>
                      Copied!
                      </div>
                      ) : (
                        <img src={copy} alt="copy" style={copyimage} />
                      )}
                    </div>
                  </CopyToClipboard>

                </div>
      
              <div style={{ fontWeight: "400", fontSize: "13px" }}>
                <span>{clinicName} -</span>&nbsp;
                <span>{pageTitle?.country}</span>&nbsp;
              </div>
            </div>
            
            <div
              style={{ display: "flex", flexWrap: "wrap", columnGap: "5px" }}
            >
              <span className={statusColor}>
                {status?.status && <Badge status={status.flag}>{status.status}</Badge>}
              </span>
              <span>
                {status?.outsourcedProduction && <Badge>Outsourced Production</Badge>}
              </span>
              <span>
                {status?.outsourcedDesigner && <Badge>Outsourced Designer</Badge>}
              </span>
              <span>
                {userTags.isReferredByAFriend && <Badge>Referred by a friend</Badge>}
              </span>
              <span>
                {userTags.purchasedTpWithin15DayWindow && <Badge>Within 15-day window</Badge>}
              </span>
               <span className="linked-user-status">
                 {status?.hasLinkedUser && <Badge>Linked to {status?.hasLinkedUser}</Badge>}
               </span>
              <span className={planTypeColor}>
                {planType && (
                  <Badge status={planType.flag}>{planType.status}</Badge>
                )}
              </span>
              <span className="clinic-status">
                {clinicStatus === 1 && (
                  <Badge status="critical">Base Clinic</Badge>
                )}
              </span>
              
            </div>
          </div>
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <span>
            <Button
              disabled={String(Cookies.get("userType")) === "50" && true}
              onClick={handleTeleconsultationButtonClick}
              primary
              icon={ExternalMinor}
            >
              Teleconsultation
            </Button>
          </span>
          <span>
            <Button
              disabled={String(Cookies.get("userType")) === "50" && true}
              onClick={handleImpersonateButtonClick}
              primary
              icon={ExternalMinor}
            >
            Impersonate Web
          </Button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserHeader;
