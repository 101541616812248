import React from "react";

const ButtomTeeth = (props) => {
  return (
    <div className="teeth-bottom">
      {props.Value.attachments.slice(16, 32).map((attachment, index) => {
        index = index + 1;
        return (
          <React.Fragment key={index}>
            <div className="single-tooth-pdf">
              <span>{attachment.num}</span>
              <img
                src={require(`./pdfimages/teeth/Tooth${attachment.num}.png`).default}
                alt={`Tooth ${attachment.num}`}
                className="teeth-image"
              />

              {attachment.attachment === true && (
                <span className="red-square-buttom"></span>
              )}
            </div>

            {index < 16 && (
              <div className="between-teeth">
                <hr
                  size="100"
                  style={{
                    border: index === 8 ? "0" : "1px dashed black",
                  }}
                />

                {props.Value.spacing[props.Value.attachments[index + 15].num + "" + props.Value.attachments[index + 16].num]?.distance !== null && (
                  <span
                    className="inside-box-number"
                    style={{
                      border:
                        props.Value.spacing[props.Value.attachments[index + 15].num + "" + props.Value.attachments[index + 16].num]?.distance !== ""
                          ? "1px solid black"
                          : "none",
                      textAlign: "center",
                      visibility:
                        props.Value.spacing[props.Value.attachments[index + 15].num + "" + props.Value.attachments[index + 16].num]
                          ? "visible"
                          : "hidden",
                    }}
                  >
                    {props.Value.spacing[props.Value.attachments[index + 15].num + "" + props.Value.attachments[index + 16].num]?.distance ?? ""}
                  </span>
                )}
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default ButtomTeeth;
