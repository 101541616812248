import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../history";
import { AppProvider } from "@shopify/polaris";
import List from "./List";
import Add from "./Add";
import MainEdit from "./MainEdit";
import NavigationLayout from "../components/NavigationLayout";
import MainDashboard from "./../Users/MainDashboard";

function Doctors() {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/doctors" component={List} />
          <Route path="/admin/doctors/add" component={Add} />
          <Route path="/admin/doctors/:id" component={MainEdit} />
          <Route path="/admin/users/:id/dashboard" component={MainDashboard} />
        </Switch>
      </Router>
    </NavigationLayout>
  );
}
export default Doctors;
