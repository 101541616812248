import React, { useCallback, useEffect } from 'react'
import { Button, Card, Checkbox, FormLayout, Page, TextField, TextStyle } from "@shopify/polaris";
import { useParams } from 'react-router-dom';
import axios from './axios';
import Cookies from 'js-cookie';
import Select from 'react-select';
import history from './history';

export default function FreshDeskTicketCreation() {
    let { id } = useParams();
    const [subject, setSubject] = React.useState('')
    const [type, setType] = React.useState('')
    const [treatmentDesigner, setTD] = React.useState('')
    const [pc, setPc] = React.useState('')
    const [oType, setOtype] = React.useState({value: '', label: 'unassigned', });
    const [description, setDesc] = React.useState('')
    const [ticketPriority, setPriority] = React.useState({value: 2, label: 'Medium'})
    const [owners, setOwners] = React.useState([])
    const [tpDesigners, setTpDesigners] = React.useState([])
    const [radioButtonGroup, setRadioButtonGroup] = React.useState([]);
    const [showTreatmentDesigner, setShowTreatmentDesigner] = React.useState(false);
    const [showOwnerType, setShowOwnerType] = React.useState(false);
    const [options, setOptions] = React.useState([
      {id: "freeReplacement", label: "Send replacement for free", name: 'replacementOptions1', isChecked: false, k: 1},
      {id: "paidReplacement", label: "Send paid replacement", name: 'replacementOptions2', isChecked: false, k: 1},
      {id: "anotherTP-noIPR", label: "Another TP with no IPR", name: 'anotherTPOptions1', isChecked: false, k: 2},
      {id: "anotherTP-noAttachments", label: "Another TP with no attachments", name: 'anotherTPOptions2', isChecked: false, k: 2},
    ]);

    const [email, setEmail] = React.useState('')
    useEffect(() => {
        if(id){
            axios
          .get("admin/v1/users/" + id + "/profile", {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
          })
          .then((result) => {
            result?.data?.data?.user?.email &&
            setEmail(result.data.data.user.email);
              })
          .catch((err) => console.log(err));
        }
        if(oType?.value){
          axios.get(`admin/v1/agents/type/${oType.value}/userId/${id}`, {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
          }).then((response) => {
            let newArray = '';
            if(response.data.agents) {
              newArray = [...response?.data?.agents?.map((val) => ({value: val?.freshdesk_id, label: val?.full_name}))]
              newArray.unshift({value: '', label: 'unassigned', })
            }
            if(oType.value == 'lab designer'){
              setTpDesigners(newArray);
              let td = newArray.find(ow =>  ow.label == response?.data?.designer);
              setTD(td ? td : newArray[0]);
            } else {
              setOwners(newArray);
              let so = newArray.find(ow =>  ow.value == response?.data?.afterSales?.freshdesk_id);
              setPc(so ? so : newArray[0]);
            }
          })
        }
      }, [oType]);

    const handleCreateTicket = () => {
        let optionsCheckedArr = options.filter(op => op.isChecked == true);
        let descriptionPrefix = ''; 
        optionsCheckedArr.forEach(op => {
          descriptionPrefix += '<div>'+op.label+'</div>'
        });
        const bodyObj = {
            subject: subject,
            email: email,
            priority: ticketPriority?.value,
            type: type?.label ? type?.label : '',
            assignee:  treatmentDesigner?.value ? Number(treatmentDesigner.value) : pc?.value ? Number(pc.value) : '',
            description: descriptionPrefix + description,
            ownerType: oType?.value
        }
          if(id){
            axios
            .post(`admin/v1/freshdesk/createTicket/${id}`, bodyObj , {
                headers: {
                Authorization: "Bearer " + Cookies.get("token"),
                },
            })
            .then((result) => {
              history.push(`/admin/users/${id}/dashboard`)
              console.log(result.data.id)
                })
            .catch((err) => console.log(err));
          }
    }
    const handleSelectOwnerType = useCallback(
        (selectedOption) => setOtype(selectedOption),
        []
    ); 
    const handlechangeSubject = useCallback(
      (selectedOption) => setSubject(selectedOption),
      []
  ); 
   
    const handleSelectPriority = useCallback(
        (selectedOption) => setPriority(selectedOption),
        []
    );

    const handleSelectTD = useCallback(
        (selectedOption) => setTD(selectedOption),
        []
        );

    const handleSelectPC = useCallback(
        (selectedOption) => setPc(selectedOption),
        []
    );

    const handleSelectDesc = useCallback(
        (selectedOption) => setDesc(selectedOption),
        []
    );

    const handleSelectType = useCallback(
        (selectedOption) => {
          let arr = [...options].map(t => {
            t.isChecked = false;
            return t;
          });
          setOptions(arr);
          if(selectedOption.ownerType == 1){
            setOtype({label: '', value: 'lab designer'});
            setShowTreatmentDesigner(true);
            setShowOwnerType(false);
          } else {
            setOtype({ value: "customer success owner", label: "After Sales owner"});
            setShowOwnerType(true);
            setShowTreatmentDesigner(false);
          }
          setType(selectedOption);
          let requestOptionsArr = options.filter(t => selectedOption.k == t.k) ;
          setRadioButtonGroup(requestOptionsArr);
        },
        []
    );

    const ownerTypes = [
      {type: 'Treatment Designer', id: 1},
      {type: 'Patient Care Agent', id: 2},
    ]

    const priority =[
        {value: 1, label: 'Low'},
        {value: 2, label: 'Medium'},
        {value: 3, label: 'High'},
        {value: 4, label: 'Urgent'},
    ]

    const concernsOptions = [
      {label: "Patient Concerned About End Result", value: 1, ownerType: ownerTypes[0].id},
    ];

    const issuesOptions = [
      {label: "Patient Broke Aligners", value: 3, k: 1, ownerType: ownerTypes[0].id},
      {label: "Patient Lost Aligners", value: 4, k: 1, ownerType: ownerTypes[0].id},
      {label: "Patient Broke Retainer", value: 7, k: 1, ownerType: ownerTypes[0].id},
    ];

    const tpOptions = [
      {label: "Patient Not Satisfied With TP", value: 5, k: 2, ownerType: ownerTypes[0].id},
      {label: "Patient Inquires About TP", value: 6, ownerType: ownerTypes[0].id},
    ];

    const callBackOptions = [
      {label: "Patient Requests Follow Up", value: 2, ownerType: ownerTypes[1].id},
    ];

    const deliveryOfAlignersOptions = [
      {label: "Patient Inquires About Aligners Dispatch", value: 12, ownerType: ownerTypes[0].id},
    ];

    const notUploadingPicturesOptions = [
      {label: "Not Uploading Pictures", value: 14, ownerType: ownerTypes[1].id},
    ];

    const Type =[
        {label: 'End result concerns', options: concernsOptions},
        {label: 'Aligners/Retainers Issues', options: issuesOptions},
        {label: 'Treatment Plan', options: tpOptions},
        {label: 'Call-back Request', options: callBackOptions},
        {label: 'Delivery of Aligners', options: deliveryOfAlignersOptions},
        {label: 'Not Uploading Pictures', options: notUploadingPicturesOptions},

        // {value: 'Broken aligners', label: 'Broken aligners'},
        // {value: 'Lost Aligners', label: 'Lost aligners'},
        // {value: 'Not wearing aligners', label: 'Not wearing aligners'},
        // {value: 'Not uploading pictures', label: 'Not uploading pictures'},
        // {value: 'Clinic Appointment Due', label: 'Clinic Appointment Due'},
        // {value: 'TP Inquiry', label: 'TP inquiry'},
        // {value: 'TP adjustment', label: 'TP adjustment'},
        // {value: 'New TP request', label: 'New TP request'},
        // {value: 'Dispatch Date', label: 'Dispatch Date'},
    ]

    const ownerType = [
        { value: "customer success owner", label: "After Sales owner"},
        { value: "sales support", label: "Support"},
    ]

    const handleCheckBoxChange = (rb) => {
      let arr = [...options].map(t => {
        if(t.id == rb.id){
          t.isChecked = !t.isChecked;
        }
        return t;
      });
      setOptions(arr);
    };

    return (
       <Page 
       breadcrumbs={[{ content: "list", url: "/admin/users" }]}
       title="Freshdesk tickets">
         <div className="alert alert-success" role="alert" id="infoMessage" style={{display:"none"}} ></div>
         <div className="alert alert-danger" role="alert" id="errorMessage" style={{display:"none"}} ></div>

        <Card style={{padding:"50px", backgroundColor:"white", width:"100%", margin:"0 auto"}}>
        <form style={{margin: '0 auto', padding :"10px"}}>
            <div style={{display:"grid", gap: '10px', margin:"10px auto", width:"70%", textAlign: 'left'}} className="form-group">
        <h1 style={{fontSize:"40px", fontWeight:"bold", margin:"20px 0", textAlign: 'center'}}>FreshDesk Ticket Creation</h1>
                <TextStyle variation="strong">Subject*</TextStyle>
               <TextField name="subject" value={subject} onChange={handlechangeSubject} type="text" className="form-control" id="subject" />

                <FormLayout>
                <TextStyle variation="strong">Priority</TextStyle>
               <Select
                  name="selectproduct"
                  options={priority}
                  onChange={handleSelectPriority}
                  value={ticketPriority}
                />
                </FormLayout>

                <FormLayout>
                <TextStyle variation="strong">Type*</TextStyle>
                <Select
                  name="type"
                  options={Type}
                  onChange={handleSelectType}
                  value={type}
                />
                </FormLayout>

                {radioButtonGroup?.map(rb =>
                  <Checkbox
                    label={rb.label}
                    checked={rb.isChecked}
                    onChange={() => handleCheckBoxChange(rb)}
                  />
                )}

                {/* <TextStyle variation="strong">Group Assignee</TextStyle>
                <div style={{zIndex: 0}}>
                  <TextField name="groupAssignee" onChange={handleGroupAssignee} value={groupAssignee} type="text" className="form-control" disabled="true" id="assignee" />
                </div> */}

               {showTreatmentDesigner && <FormLayout>
                <TextStyle variation="strong">Treatment Designer</TextStyle>
                <Select
                  name="treatmentDesigner"
                  options={tpDesigners}
                  onChange={handleSelectTD}
                  value={treatmentDesigner}
                  // disabled={designerExist}
                />
                </FormLayout>}

                {showOwnerType && <FormLayout>
                <TextStyle variation="strong">Owner Type</TextStyle>
                <Select
                  name="patientCare"
                  options={ownerType}
                  onChange={handleSelectOwnerType}
                  value={oType}
                  // disabled = {owners.length == 0}
                />

                <TextStyle variation="strong">Assignee</TextStyle>
                <Select
                  name="patientCare"
                  options={owners}
                  onChange={handleSelectPC}
                  value={pc}
                  // disabled = {owners.length == 0}
                />
                </FormLayout>}

               <TextStyle variation="strong">Description</TextStyle>
                <div style={{zIndex: 0}}>
                 <TextField name="description" value={description} onChange={handleSelectDesc} multiline={true} id="description" className="form-control"/>
                </div>
               <input style={{display:"none"}} type="hidden" id="email"/>
               <input style={{display:"none"}} type="hidden" id="cmsLink"/>
            <Button onClick={handleCreateTicket} primary id="create-ticket" type="send" className="btn btn-primary basmaButton" onclick="return false;">Send</Button>
            </div>
         </form>
        </Card>
      </Page>
    )
}