import React, { useState, useEffect } from "react";
import {
  Card,
  Layout,
  Badge,
  AppProvider,
  TextStyle,
  Page,
  FormLayout,
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";

import { useParams } from "react-router-dom";

export default function OrderDetails() {
  let { id } = useParams();
  const [referenceID, setReferenceID] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [shippingMethod, setShippingMethod] = useState("");
  const [shippingStatus, setShippingStatus] = useState("");
  const [productID, setProductID] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productQuantity, setProductQuantity] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productWeight, setproductWeight] = useState("");
  const [productDimension, setproductDimension] = useState("");
  const [resultProduct, setResultProduct] = useState([]);

  useEffect(() => {
    getData();
  }, []);
  function getData() {
    axios
      .get("/admin/v1/orders/" + id + "/details", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setResultProduct(result.data.data.product);
        setBillingAddress(result.data.data.billing_address.address);
        setReferenceID(result.data.data.reference_id);
        setShippingAddress(result.data.data.shipping_address.address);
        setPaymentStatus(result.data.data.payment_status);
        setShippingMethod(result.data.data.shipping_method);
        setShippingStatus(result.data.data.shipping_status);
        setProductID(result.data.data.product.id);
        setProductQuantity(result.data.data.product.quantity);
        setProductPrice(result.data.data.product.price);
        setProductDescription(result.data.data.product.description.en);
        setproductWeight(result.data.data.weight);
        setproductDimension(result.data.data.dimension);
        Cookie.set("order_payment_status", result.data.data.payment_status);
      })
      .catch((err) => console.log(err));
  }
  var json_str = Cookie.get("clickedItem");
  var cookieResult = JSON.parse(json_str);
  const [pageTitle, setPageTitle] = useState(cookieResult[0]);
  return (
    <Page breadcrumbs={[{ content: "Orders", url: "/admin/orders" }]}>
      <Layout>
        <Layout.Section>
          <Card title="Order Details">
            <Card.Section title="Reference">
              <p>{referenceID}</p>
            </Card.Section>
            <Card.Section title="Billing address">
              <p>{billingAddress}</p>
            </Card.Section>
            <Card.Section title="Shipping address">
              <p>{shippingAddress}</p>
            </Card.Section>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card title="Payment">
            <Card.Section
              title="Payment Status"
              actions={[
                {
                  content:
                    (paymentStatus === "Refunded" ||
                      paymentStatus === "Partial Refund") &&
                    "Edit",
                  url: "/admin/orders/" + id + "/editpaymentstatus",
                },
              ]}
            >
              <Badge status="success">{paymentStatus}</Badge>
            </Card.Section>
            <Card.Section title="Shipping Method">
              <p>{shippingMethod}</p>
            </Card.Section>
            <Card.Section title="Shipping Status">
              <p>{shippingStatus}</p>
            </Card.Section>
          </Card>
        </Layout.Section>
        <Layout.Section>
          {resultProduct && (
            <Card title="Product Details">
              <Card.Section>
                <FormLayout>
                  {resultProduct.id && (
                    <div>
                      <TextStyle variation="strong">ID : </TextStyle>
                      <TextStyle>{resultProduct.id}</TextStyle>
                    </div>
                  )}
                  {/* {resultProduct.description && (
                      <div>
                        <TextStyle variation="strong">Description : </TextStyle>
                        <TextStyle>{resultProduct.description}</TextStyle>{" "}
                      </div>
                    )} */}
                  {resultProduct.price && (
                    <div>
                      <TextStyle variation="strong">Price : </TextStyle>
                      <TextStyle>{resultProduct.price}</TextStyle>
                    </div>
                  )}

                  {resultProduct.quantity && (
                    <div>
                      <TextStyle variation="strong">Quantity : </TextStyle>
                      <TextStyle>{resultProduct.quantity}</TextStyle>
                    </div>
                  )}

                  {/* <TextStyle variation='strong'>Weight : </TextStyle><TextStyle >{productWeight}</TextStyle><br />
                <TextStyle variation='strong'>Dimension : </TextStyle><TextStyle >{productDimension}</TextStyle><br /> */}
                </FormLayout>
                <TextStyle></TextStyle>
              </Card.Section>
            </Card>
          )}
        </Layout.Section>
      </Layout>
    </Page>
  );
}
