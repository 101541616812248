import React, { useCallback, useState, useEffect } from "react";
import {
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Select,
  Card,
  Modal,
  PageActions,
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";
import history from "../history";
import Axios from "axios";
import Select2 from "react-dropdown-select";

function Create() {
  const [totalPriceValue, setTotalPriceValue] = useState("");
  const [selectedUser, setSelectedUser] = useState([]);
  const [optionsUsers, setOptionsUsers] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState([]);
  const handleSelectChangeCoupon = useCallback(
    (value) => setSelectedCoupon(value[0].value),
    []
  );
  const [optionsCoupons, setOptionsCoupons] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [optionsProducts, setOptionsProducts] = useState(null);
  const [popupActive, setPopupActive] = useState(false);
  const [userError, setUserError] = useState("");
  const [productError, setProductError] = useState("");
  const [totalPriceError, setTotalPriceError] = useState("");
  const formatYmd = (date) => date.toISOString().slice(0, 10);
  const [dateValue, setDateValue] = useState("");
  const[loadingUser,setLoadingUser]=useState(false)
  const[searchUser,setSearchUser]=useState("")
  const handleChangeDateValue = useCallback(
    (newValue) => setDateValue(newValue),
    []
  );
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("");
  const handleSelectChangePaymentStatus = useCallback(
    (value) =>{setSelectedPaymentStatus(value[0].value)},
    []
  );
  const optionsPaymentStatus = [
    { label: "Pending", value: "Pending" },
    { label: "Refunded", value: "Refunded" },
    { label: "Partial Refund", value: "Partial Refund" },
    { label: "Cash", value: "Cash" },
    { label: "Paid", value: "Paid" },
    { label: "Failed", value: "Failed" },
    { label: "Canceled", value: "Canceled" },
    { label: "Cash Paid'", value: "Cash Paid" },
  ];
  useEffect(()=>{
    let listUsers=[];
    const ourRequest = Axios.CancelToken.source() 
    if(searchUser.length>=3){
      setLoadingUser(true)
      axios
      .get(`admin/v1/users/search/?name=${searchUser}`, {
        cancelToken: ourRequest.token,
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        for (var i = 0; i < result.data.data.length; i++) {
          listUsers.push({
            key: result.data.data[i].id,
            label:
              result.data.data[i].first_name +
              " " +
              result.data.data[i].last_name +
              " ( ID : " +
              result.data.data[i].id +
              " )",
            value: String(result.data.data[i].id),
          });
        }
        setOptionsUsers(listUsers);
      })
      .catch((err) => console.log(err))
      .finally((final)=> setLoadingUser(false));
     
    }
    return () => {
      ourRequest.cancel() // <-- 3rd step
    }
  },[searchUser])
  useEffect(() => {
    Promise.all([
      axios.get(`admin/v1/coupons?per_page=1000000&page=1`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }),
      axios.get(`admin/v1/products`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }),
      axios.get(`admin/v1/plans`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }),
    ]).then(function (responses) {
      const responseCoupons = responses[0].data.data.data;
      const responseProducts = responses[1].data.data.data;
      const responsePlans = responses[2].data.data;
      let coupons = [];
      for (var i = 0; i < responseCoupons.length; i++) {
        coupons.push({
          key: responseCoupons[i].id,
          label:
            responseCoupons[i].code + " ( ID : " + responseCoupons[i].id + " )",
          value: String(responseCoupons[i].id),
        });
      }
      setOptionsCoupons(coupons);

      let products = [];
      for (var i = 0; i < responseProducts.length; i++) {
        products.push({
          key: "product_" + responseProducts[i].id,
          label:
            responseProducts[i].title.en +
            " ( ID : " +
            responseProducts[i].id +
            " )",
          value: "Product"+String(responseProducts[i].id),
        });
      }
      for (var i = 0; i < responsePlans.length; i++) {
        products.push({
          key: "plan_" + responsePlans[i].id,
          label:
            responsePlans[i].title.en + " ( ID : " + responsePlans[i].id + " )",
          value: "Plan"+String(responsePlans[i].id),
        });
      }
      setOptionsProducts(products);
    });
  }, []);
  return (
    <Page
      title="Create Order"
      breadcrumbs={[{ content: "List Of Orders", url: "/admin/orders" }]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong" prefix="$">
            Total Price
          </TextStyle>
          <TextField
            value={totalPriceValue}
            onChange={handleChangeTotalPriceValue}
            error={totalPriceError}
            type="number"
          />
          <TextStyle variation="strong">User</TextStyle>
          <Select2
            options={optionsUsers}
            searchFn={(e)=>setSearchUser(e.state.search)}
            loading={loadingUser}
            onChange={handleSelectChangeUser}
            value={selectedUser}
            placeholder="Search user by name"
            error={userError}
          />
          <TextStyle variation="strong">Product</TextStyle>
          <Select2
            options={optionsProducts}
            onChange={handleSelectChangeProduct}
            value={selectedProduct}
            placeholder="Please Select"
            error={productError}
          />
          <TextStyle variation="strong">Coupon</TextStyle>
          <Select2
            options={optionsCoupons}
            onChange={handleSelectChangeCoupon}
            value={selectedCoupon}
            placeholder="Please Select"
          />
          <TextStyle variation="strong">Date</TextStyle>
          <div className="date-container">
          <TextField
            value={dateValue}
            onChange={handleChangeDateValue}
            type="date"
          />
          </div>
          <TextStyle variation="strong">Payment Status</TextStyle>
          <Select2
            options={optionsPaymentStatus}
            onChange={handleSelectChangePaymentStatus}
            value={selectedPaymentStatus}
            placeholder="Please Select"
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );
  function handleSelectChangeProduct(value) {
    setSelectedProduct(value);
    setProductError("");
  }
  function handleSelectChangeUser(value) {
    setSelectedUser(value[0].value);
    setUserError("");
  }
  function handleChangeTotalPriceValue(value) {
    setTotalPriceValue(value);
    setTotalPriceError("");
  }

  function handleSave() {
    if (!selectedUser|| Array.isArray(selectedUser)  || !selectedProduct || !totalPriceValue) {
      if (!selectedUser) setUserError("This field is required");
      if (!selectedProduct) setProductError("This field is required");
      if (!totalPriceValue) setTotalPriceError("This field is required");
    } else {
      const bodyObj = {
        user_id:  Array.isArray(selectedUser)?"":selectedUser,
        product_id: selectedProduct,
        plan_id: selectedProduct,
        total_price: totalPriceValue * 100,
        coupon_id: Array.isArray(selectedCoupon)?"":selectedCoupon,
        created_at: dateValue,
        ...(!Array.isArray(selectedPaymentStatus) && { payment_status: selectedPaymentStatus }),
      };
      !selectedProduct.includes("product") && delete bodyObj.product_id;
      !selectedProduct.includes("plan") && delete bodyObj.plan_id;
      axios
        .post("admin/v1/orders", bodyObj, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          history.push("/admin/orders");
        })
        .catch((err) => console.log(err));
    }
  }
}
export default Create;
