import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../history";
import List from "./List";
import Add from "./Add";
import NavigationLayout from "../components/NavigationLayout";
import MainEdit from "./MainEdit";

function BookAScan() {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/book-a-scan-countries" component={List} />
          <Route path="/admin/book-a-scan-countries/new" component={Add} />
          <Route path="/admin/book-a-scan-countries/:id" component={MainEdit} />
        </Switch>
      </Router>
    </NavigationLayout>
  );
}
export default BookAScan;
