import React from "react";
const IRPFooter = (props) => {
  return (
    <div>
      <div className="footer-important">
        <div>
          <span style={{ fontWeight: "bold" }}>Important</span>
        </div>
        <div style={{ fontSize: "13px" }}>
          <div>
            Use a gauge to measure the space created after IPR. Do not forget to
            polish after performing IPR
          </div>
          <div className="arabic-text-ipr">استخدم أداة المقياس لقياس المساحة التي تمَّ انشائها بعد برد الأسنان <span dir="rtl">.</span>&rlm;  لا تنسى تلميع الأسنان بعد البرد <span dir="rtl">.</span>&rlm;</div>
        </div>
      </div>
      {/* footer */}
      <div className="footer-container flex " >
        <div className="row-flexing" style={{ gap: "3px" }}>
          <div className="">For technical questions:</div>
          <div>Dr.Ahmed: +971 55 773 2600</div>
          </div>
        <div style={{marginLeft:"30%"}}> www.basma.com hello@basma.com</div>

      </div>
    </div>
  );
};
export default IRPFooter;
