import { TextField, TextStyle } from '@shopify/polaris';

function CommercialInvoiceFormInput({ label, type, field, fieldValue, handleChange }) {
  return (
    <div>
      <TextStyle variation="strong">{label}</TextStyle>
      <TextField
        label={label}
        type={type}
        labelHidden
        value={fieldValue}
        onChange={(value) => handleChange(field, value)}
      />
    </div>
  );
}

export default CommercialInvoiceFormInput;
