import Cookies from "js-cookie";
import React, { useEffect, Fragment, useState } from "react";
import Loading from "../../components/Loading/loading";
import axios from '../../axios'
const BookingCalendar = ({ clinicData }) => {
    console.log(clinicData)
    const [loading, setLoading] = useState(true);
  useEffect(() => {
    Cookies.set('clinic_id', clinicData.clinic_id)
    setLoading(true);
    if (clinicData) {
      window.addEventListener &&
        window.addEventListener(
          "message",
          function (event) {
            if (event.origin === clinicData.link) {
              document.getElementById("ycbmiframenabih").style.height =
                event.data + "px";
            }
          },
          false
        );

      if (window !== "undefined") {
        try {
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        } catch (error) {
          window.scrollTo(0, 0);
        }
      }
    }
    setLoading(false);
  }, []);
  if(!clinicData){
    return <Loading/>
}
const HOST = process.env.REACT_APP_HOST ? process.env.REACT_APP_HOST : 'basma-cms.netlify.app';
const PORT = process.env.REACT_APP_PORT ? process.env.REACT_APP_PORT : 443 ;
  const prefilledUrl = `&HOST=${HOST}&PORT=${PORT}&LANG=en&PROMO=0&TYPE=BAS`;

  return (
    !loading && <Fragment>
      <section
      >
        <div>
          <div>
            <div>
              <div>
                <div>
                  <div>
                    <iframe
                      src={`${clinicData.link}/?noframe=true&skipHeaderFooter=true${prefilledUrl}`}
                      id="ycbmiframenabih"
                      style={{
                        width: "100%",
                        height: "80vh",
                        margin:"70px auto",
                        border: "0px",
                        backgroundColor: "transparent",
                      }}
                      frameBorder="0"
                      allowtransparency="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default BookingCalendar;
 
