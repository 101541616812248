import { Button, Card, TextStyle } from '@shopify/polaris'
import React from 'react'

const TpApproval = ({ updateMode, labApproved, disableButtonApproval, doctorApproved, approvedFunc, labApprovedDate, doctorApprovedDate }) => {
    return (
        updateMode ? <Card sectioned>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    gap: "10px",
                }}
            >
                <div className="tp-banner1">
                    <Button
                        disabled={labApproved != "" || disableButtonApproval}
                        primary
                        onClick={() => approvedFunc("lab_approved")}
                    >
                        Lab Approved
                    </Button>
                </div>
                <div className="tp-banner1">
                    <Button
                        disabled={doctorApproved != "" || disableButtonApproval}
                        primary
                        onClick={() => approvedFunc("doctor_approved")}
                    >
                        Doctor Approved
                    </Button>
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "column",
                    gap: "10px",
                }}
            >
                <TextStyle>
                    <span style={{ fontWeight: "bold" }}>Lab approval:</span>{" "}
                    {labApproved != "" ? labApproved : "None"}{" "}
                    {labApprovedDate}
                </TextStyle>
                <TextStyle>
                    <span style={{ fontWeight: "bold" }}>
                        Doctor approval:
                    </span>{" "}
                    {doctorApproved != "" ? doctorApproved : "None"}{" "}
                    {doctorApprovedDate}
                </TextStyle>
            </div>
        </Card> :
        null
    )
}

export default TpApproval