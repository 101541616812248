import React, { useEffect, useState } from "react";
import {
    Page,
    FormLayout,
    TextField,
    Card,
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";
import TipsAndNewsList from "./TipsAndNewsList";
import WhatsNewList from "./WhatsNew/WhatsNewList";

function Home() {
    const [disableAndroid, setDisableAndroid] = useState(true)
    const [disableIos, setDisableIos] = useState(true)
    const [disableIosStoreLink, setdisableIosStoreLink] = useState(true)
    const [disableAndroidStoreLink, setdisableAndroidStoreLink] = useState(true)
    const [disableMobileNumber, setDisableMobileNumber] = useState(true)
    const [androidVersion, setAndroidVersion] = useState("")
    const [iosVersion, setIosVersion] = useState("")
    const [appStoreLink, setIosStoreLink] = useState("")
    const [googlePlayLink, setAndroidStoreLink] = useState("")
    const [whatsappMobileNumber, setWhatsappMobileNumber] = useState("")

    const getData = async () => {
        const res = await axios.get('admin/v1/mobile/appVersion', {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          });
        try {
            setAndroidVersion(res.data.androidVersion)
            setIosVersion(res.data.iosVersion)
            setIosStoreLink(res.data.appStoreLink)
            setAndroidStoreLink(res.data.googlePlayLink)
            setWhatsappMobileNumber(res.data.whatsappMobileNumber)
        } catch (e) {
            console.log(e)
        }
    }
    const SaveAndroid = async () => {
        const bodyObj = { version: androidVersion, storeLink: googlePlayLink, os: "android"};
        const res = await axios.post(`admin/v1/mobile/appVersion/update`, bodyObj, {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          })
    }
    const SaveIos = async () => {
        const bodyObj = { version: iosVersion, storeLink: appStoreLink, os: "iOS"};
        const res = await axios.post(`admin/v1/mobile/appVersion/update`, bodyObj, {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          })
    }
    const SaveMobile = async () => {
        const bodyObj = { mobile: whatsappMobileNumber};
        const res = await axios.post(`admin/v1/mobile/appVersion/update`, bodyObj, {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          })
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <Page>
            <Card title="Mobile App Version" sectioned>
                <Card title="Android Version"
                    actions={[
                        {
                            content: disableAndroid ? "Edit" : "Save",
                            onAction: () => { if (!disableAndroid) { SaveAndroid() } setDisableAndroid((prev) => !prev) },
                            disabled: String(Cookie.get("userType")) === "50" && true,
                        },
                    ]}>
                    <Card.Section>
                        <FormLayout>
                            <TextField disabled={disableAndroid} value={androidVersion} onChange={setAndroidVersion}></TextField>
                        </FormLayout>
                    </Card.Section>
                </Card>
                <Card title="iOS Version" actions={[
                    {
                        content: disableIos ? "Edit" : "Save",
                        onAction: () => { if (!disableIos) { SaveIos() } setDisableIos((prev) => !prev) },
                        disabled: String(Cookie.get("userType")) === "50" && true,
                    },
                ]}>
                    <Card.Section>
                        <FormLayout>
                            <TextField disabled={disableIos} value={iosVersion} onChange={setIosVersion}></TextField>
                        </FormLayout>
                    </Card.Section>
                </Card>
            </Card>

            <Card title="AppStore Link" actions={[
                {
                    content: disableIosStoreLink ? "Edit" : "Save",
                    onAction: () => { if (!disableIosStoreLink) { SaveIos() } setdisableIosStoreLink((prev) => !prev) },
                    disabled: String(Cookie.get("userType")) === "50" && true,
                },
            ]}>
                <Card.Section>
                    <FormLayout>
                        <TextField disabled={disableIosStoreLink} value={appStoreLink} onChange={setIosStoreLink}></TextField>
                    </FormLayout>
                </Card.Section>
            </Card>


            <Card title="GooglePlay Link" actions={[
                {
                    content: disableAndroidStoreLink ? "Edit" : "Save",
                    onAction: () => { if (!disableAndroidStoreLink) { SaveAndroid() } setdisableAndroidStoreLink((prev) => !prev) },
                    disabled: String(Cookie.get("userType")) === "50" && true,
                },
            ]}>
                <Card.Section>
                    <FormLayout>
                        <TextField disabled={disableAndroidStoreLink} value={googlePlayLink} onChange={setAndroidStoreLink}></TextField>
                    </FormLayout>
                </Card.Section>
            </Card>

            <Card title="WhatsApp Mobile Number" actions={[
                {
                    content: disableMobileNumber ? "Edit" : "Save",
                    onAction: () => { if (!disableMobileNumber) { SaveMobile() } setDisableMobileNumber((prev) => !prev) },
                    disabled: String(Cookie.get("userType")) === "50" && true,
                },
            ]}>
                <Card.Section>
                    <FormLayout>
                        <TextField disabled={disableMobileNumber} value={whatsappMobileNumber} onChange={setWhatsappMobileNumber}></TextField>
                    </FormLayout>
                </Card.Section>
            </Card>
            <WhatsNewList />
            <TipsAndNewsList />
        </Page>
    )
}
export default Home;