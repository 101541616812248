import React, { useEffect, useState } from 'react'
import Loading from '../../components/Loading/loading';
import history from '../../history';
import BookingCalendar from './Booking_calendar';

export default function Bookavisit() {
    const clinicData = history.location.state
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        },1000)
    })
    return (
        loading ? <Loading/> :  <BookingCalendar
        clinicData={clinicData}
      />
    )
}