import { Modal, TextContainer } from "@shopify/polaris";

const ClinicsReportsDisputeModal = ({
  dispute,
  handleDisputeDialog,
  activeDisputeModal,
}) => {
  const downloadDisputeFiles = async () => {
    window.open(
      `${process.env.REACT_APP_BASE_URL}/admin/v1/clinic-dispute/${dispute.id}/download-zip`
    );
    handleDisputeDialog();
  };
  return (
    <Modal
      title="Dispute"
      open={activeDisputeModal}
      onClose={handleDisputeDialog}
      primaryAction={{
        content: "Download Dispute Files",
        onAction: downloadDisputeFiles,
      }}
      secondaryActions={[
        {
          content: "Close",
          onAction: handleDisputeDialog,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>{dispute?.text}</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
};

export default ClinicsReportsDisputeModal;
