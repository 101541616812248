import React, { useCallback, useState, useEffect } from "react";
import { Router } from "react-router-dom";
import history from "./history";
import {
  FormLayout,
  TextStyle,
  PageActions,
  Toast,
  Checkbox,
  Button,
} from "@shopify/polaris";
import axios from "./axios";
import NavigationLayout from "./components/NavigationLayout";
import ReactTagInput from "@pathofdev/react-tag-input";
import {
  Page,
  Card,
  Layout,
  Select,
  Tabs,
  TextField,
  DropZone,
  Icon,
  Caption,
  Thumbnail,
  Stack,
} from "@shopify/polaris";
import {
    DeleteMajor,
    EditMajor,
    MobileAcceptMajor,
    ChevronLeftMinor,
    ChevronRightMinor
  } from '@shopify/polaris-icons';
import Cookies from "js-cookie";
import {
    PlayCircleMajor,
  } from '@shopify/polaris-icons';
import { set } from "react-ga";


function WebContent() {
    
    const [instagramPosts, setInstagramPosts] = useState([]);
    const [active, setActive] = useState(false);
    const [toastContent, setToastContent] = useState("Content Saved");
    const toggleActive = useCallback(() => setActive((active) => !active), []);
    const [question, setQuestion] = useState({en: '', ar:''})
    const [answer, setAnswer] = useState({en: '', ar:''})
    const [topic, setTopic] = useState({en: '', ar:''})
    const [topicUpdate, setTopicUpdate] = useState({en: '', ar:''})
    const [link, setLink] = useState({en: '', ar:''})
    const [imageKeyVideoSrc, setImageKeyVideoSrc] =useState("");
    const [imageKeyVideoSrcAr, setImageKeyVideoSrcAr] =useState("");
    const [imagePreviewVideoSrc, setImagePreviewVideoSrc] = useState("");
    const [imagePreviewVideoSrcAr, setImagePreviewVideoSrcAr] = useState("");
    const [filesVideoSrc, setFilesVideoSrc] = useState([]);
    const [filesVideoSrcAr, setFilesVideoSrcAr] = useState([]);
    const [selected, setSelected] = useState(0);
    const [selectedTopics, setSelectedTopics] = useState(0);
    const [faqs, setFaqs] = useState([])
    const [topics, setTopics] = useState([])
    const [topicId, setTopicId] = useState(0)
    const [page, setPage] = useState(1)
    const [render, setRender] = useState(false)
    const handleTabChange = (selectedTabIndex) => {
      setSelected(selectedTabIndex)
      setPage(1)
    }

    const handleTabChangeTopics = useCallback(
        (selectedTabIndex) => setSelectedTopics(selectedTabIndex),
        [],
      );
    const handleResetTopics = () => {
        setTopic({en: '', ar:''})
    }
    const handleReset = () => {
        setQuestion({en: '', ar:''})
        setAnswer({en: '', ar:''})
        setLink({en: '', ar:''})
        setImageKeyVideoSrc("")
        setFilesVideoSrcAr([])
        setFilesVideoSrc([])
        setImageKeyVideoSrcAr("")
        setImagePreviewVideoSrc('')
        setImagePreviewVideoSrcAr('')
    }
    useEffect(() => {
        fetchFaqs()
        fetchtopics()
    }, [page, render])
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(0)
    const [checkVideo, setCheckVideo] = useState(false)
    const fetchFaqs = async () => {
        await axios
          .get(`admin/v1/new-faq?page=${page}`, {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
          })
          .then((result) => {
            setFaqs(result?.data?.faqs?.data)
            console.log(result?.data?.faqs)
            setTotal(result?.data?.faqs?.total)
            setPerPage(result?.data?.faqs?.per_page)
          })
          .catch((err) => console.log(err));
    }
    function handleCheckVideo(checked) {
        setCheckVideo(checked);
        setLink({ en: '', ar: '' })
      }
    const fetchtopics = async () => {
        await axios
          .get(`admin/v1/topics`, {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
          })
          .then((result) => {
            setTopics(result.data.topics)
          })
          .catch((err) => console.log(err));
    }
    const tabs = [
      {
        id: 'english-faqs',
        content: 'English FAQs',
        panelID: 'english-faqs',
      },
      {
        id: 'arabic-faqs',
        content: 'Arabic FAQs',
        panelID: 'arabic-faqs',
      },
    ];

    const topicTabs = [
        {
          id: 'english-topics',
          content: 'English Topics',
          panelID: 'english-topics',
        },
        {
          id: 'arabic-topics',
          content: 'Arabic Topics',
          panelID: 'arabic-topics',
        },
      ];
    const toastMarkup = active ? (
        <Toast content={toastContent} onDismiss={toggleActive} />
    ) : null;

    const handleDelete = (id) => {
        axios.delete(`admin/v1/new-faq/delete/${id}`, {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
        }).then((result) => {
            const filteredArray = faqs.filter((val) => val.id !== id)
            setFaqs(filteredArray)
            setPage(1)
            setRender(prev => !prev)
        }).catch(err => console.log(err));
}

const handleDeleteTopics = (id) => {
    axios.delete(`admin/v1/topics/delete/${id}`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
    }).then((result) => {
        const filteredArray = topics.filter((val) => val.id !== id)
        setTopics(filteredArray)
    }).catch(err => console.log(err));
}
const [chosenEditField, setChosenEditField] = useState('')

const handleEditTopic = (id, index, key) => {
   let topic
    if(id){
    topic = topics.find((val, key) => {
        return key == index
    }).id
    setChosenEditField(topic)
    let topicValue
    topicValue = topics.find((val, key) => {
        return key == index
    })
    setTopicUpdate((prev) => { return {...prev, [key.split('_').pop()]:topicValue[key]}})
    }
    if(chosenEditField) {
        const body = {
            [key] : key == 'name_en' ? topicUpdate.en : topicUpdate.ar
        };
        axios.post(`admin/v1/topics/update/${id}`, body, {
            headers: {
                Authorization: "Bearer " + Cookies.get("token"),
            },
        }).then((result) => {
            if(result.data.success){
                const newArray = [...topics.filter((val) => val.id !== id)]
                newArray.push(result.data.data)
                setTopics(newArray)
                setTopicUpdate({ en: '', ar: ''})
                setChosenEditField('')
            } else {
                setChosenEditField('')
            }
        }).catch(err => {
            console.log(err)
        });
        
    }
}
    const [errorMessage, setErrorMessage] = useState('')
    const handleSubmitFaq = () => {
        if(!link.en && !link.ar && !imageKeyVideoSrc && !imageKeyVideoSrcAr){
            setErrorMessage('Video is required')
        } else if (!topicId) {
            setErrorMessage('Topic is required')
        } else if (!checkVideo && (!httpRegex.test(link.en) || !httpRegex.test(link.ar))) {
            setErrorMessage('Link format is wrong')
        } else {
            const body = {
                answer : answer,
                question : question,
                video_src : imageKeyVideoSrc,
                video_src_ar : imageKeyVideoSrcAr,
                link_en : link.en,
                link_ar : link.ar,
                topic_id: topicId
            };
            axios.post('admin/v1/new-faq/create', body, {
                headers: {
                Authorization: "Bearer " + Cookies.get("token"),
                },
            }).then((result) => {
                handleReset()
                console.log(result.data.data)
                let newArray = [...faqs]
                newArray.push(result.data.data)
                setFaqs(newArray)
                setErrorMessage('')
                setRender(prev => !prev)
            }).catch(err => console.log(err));
        }
        setTimeout(() => {
            setErrorMessage('')    
        },3000)
    }

    const handleSubmitTopic = () => {
        const body = {
            name_en : topic.en,
            name_ar : topic.ar,
        };
        axios.post('admin/v1/topics/create', body, {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
        }).then((result) => {
            handleResetTopics()
            let newArray = [...topics]
            newArray.push(result.data.data)
            setTopics(newArray)
        }).catch(err => console.log(err));
}
    const httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
    const videoThumbnail = () => {
        return (
            <div style={{border:'0.1rem solid grey', margin: '1rem 0', width:'5rem', height:'5rem', position:'relative'}}>
                <div  
                style={{
                    position: 'absolute',
                    opacity: 0.5, 
                    top: '50%', 
                    left: '50%', 
                    transform: 'translateX(-50%) translateY(-50%)'
                }}
                >
                <Icon
                    source={PlayCircleMajor}
                    color="base"
                />
                </div>
                <video
                    className="video"
                    style={{width:'100%', height:'100%'}}
                    src={imagePreviewVideoSrc}
                ></video>
            </div>
        )
    }    

    const videoThumbnailAr = () => {
        return (
            <div style={{border:'0.1rem solid grey', margin: '1rem 0', width:'5rem', height:'5rem', position:'relative'}}>
                <div  
                style={{
                    position: 'absolute',
                    opacity: 0.5, 
                    top: '50%', 
                    left: '50%', 
                    transform: 'translateX(-50%) translateY(-50%)'
                }}
                >
                <Icon
                    source={PlayCircleMajor}
                    color="base"
                />
                </div>
                <video
                    className="video"
                    style={{width:'100%', height:'100%'}}
                    src={imagePreviewVideoSrcAr}
                ></video>
            </div>
        )
    }    
    const handleDropZoneVideoSrc = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) =>
        setFilesVideoSrc((filesVideoSrc) => [
            ...filesVideoSrc,
            ...acceptedFiles,
        ]),
        []
    );
    const handleDropZoneVideoSrcAr = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) =>
        setFilesVideoSrcAr((filesVideoSrc) => [
            ...filesVideoSrc,
            ...acceptedFiles,
        ]),
        []
    );
    const fileUploadVideoSrc =
        !filesVideoSrc.length && <DropZone.FileUpload />;
    let uploadedFilesVideoSrc = filesVideoSrc.length >
        0 && (
        <Stack alignment="center">
        {videoThumbnail()}
        <div>
            {
            filesVideoSrc[filesVideoSrc.length - 1]
                .name
            }{" "}
            <Caption>
            {
                filesVideoSrc[filesVideoSrc.length - 1]
                .type
            }{" "}
            bytes
            </Caption>
        </div>
        </Stack>
    );

    const fileUploadVideoSrcAr =
    !filesVideoSrcAr.length && <DropZone.FileUpload />;
    let uploadedFilesVideoSrcAr = filesVideoSrcAr.length >
    0 && (
    <Stack alignment="center">
    {videoThumbnailAr()}
    <div>
        {
        filesVideoSrcAr[filesVideoSrcAr.length - 1]
            .name
        }{" "}
        <Caption>
        {
            filesVideoSrcAr[filesVideoSrcAr.length - 1]
            .type
        }{" "}
        bytes
        </Caption>
    </div>
    </Stack>
);
    const sendImageVideoSrc = useCallback(
        (filesVideoSrc) => {
        setImagePreviewVideoSrc(
            window.URL.createObjectURL(
                filesVideoSrc[
                    filesVideoSrc.length - 1
                ]
                )
        );
        let imageType =
            filesVideoSrc[filesVideoSrc.length - 1]
            .type;
        let headersVar = null;
        let url2 = "";
        const form_data = new FormData();
        form_data.append("content_type", imageType);
        axios
            .post("/admin/v1/images/s3", form_data, {
            headers: {
                Authorization: "Bearer " + Cookies.get("token"),
            },
            })
            .then((res) => {
            setImageKeyVideoSrc(res.data.key);
            url2 = res.data.url;
            headersVar = res.data.headers;
            axios
                .put(
                url2,
                filesVideoSrc[
                    filesVideoSrc.length - 1
                ],
                {
                    headers: {
                    "x-amz-acl": "public-read-write",
                    "Content-Type": imageType,
                    },
                }
                )
                .then((res) => {
                    console.log(res)
                })
                .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
        },

        [filesVideoSrc]
    );

    const sendImageVideoSrcAr = useCallback(
        (filesVideoSrc) => {
        setImagePreviewVideoSrcAr(
            window.URL.createObjectURL(
                filesVideoSrc[
                    filesVideoSrc.length - 1
                ]
                )
        );
        let imageType =
            filesVideoSrc[filesVideoSrc.length - 1]
            .type;
        let headersVar = null;
        let url2 = "";
        const form_data = new FormData();
        form_data.append("content_type", imageType);
        axios
            .post("/admin/v1/images/s3", form_data, {
            headers: {
                Authorization: "Bearer " + Cookies.get("token"),
            },
            })
            .then((res) => {
            setImageKeyVideoSrcAr(res.data.key);
            url2 = res.data.url;
            headersVar = res.data.headers;
            axios
                .put(
                url2,
                filesVideoSrc[
                    filesVideoSrc.length - 1
                ],
                {
                    headers: {
                    "x-amz-acl": "public-read-write",
                    "Content-Type": imageType,
                    },
                }
                )
                .then((res) => {
                    console.log(res)
                })
                .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
        },

        [filesVideoSrcAr]
    );

    return (
        <NavigationLayout>
            <Router history={history}>
                <Page breadcrumbs={[{ content: "Content Management", url: "/admin/content" }]} title="Manage Topics" primaryAction={{
                            content: "Save Topics",
                            onClick: handleSubmitTopic,
                        }}>
                               <div style={{display:"flex", alignContent:"center", gap:"20px", width:"100%"}}>
                       <div style={{width:"50%", backgroundColor:"white", borderRadius:"10px", boxShadow:"1px 1px 5px lightgray", padding:"15px"}}>
                                <TextStyle variation="strong">Topic title (English)</TextStyle>
                                <TextField
                                    placeholder="Enter Topic"
                                    type="text"
                                    value={topic.en}
                                    onChange={(value) => setTopic((prev) => { return {...prev, en: value}})}
                                    error={''}
                                />
                        </div>
                        <div style={{width:"50%", backgroundColor:"white", borderRadius:"10px", boxShadow:"1px 1px 5px lightgray", padding:"15px"}}>
                                <TextStyle variation="strong">Topic title (Arabic)</TextStyle>
                                <TextField
                                    placeholder="Enter Topic"
                                    type="text"
                                    value={topic.ar}
                                    onChange={(value) => setTopic((prev) => { return {...prev, ar: value}})}
                                    error={''}
                                />
                        </div>
                       </div>
                       <br/>
                       <Card title="List of topics" sectioned>
                        <Tabs tabs={topicTabs} selected={selectedTopics} onSelect={handleTabChangeTopics}>
                            {selectedTopics == 0 &&
                                    <div>
                                       {topics.length > 0 ?  <table className="styled-table">
                                    <thead>
                                        <tr>
                                            <th>Topic id</th>
                                            <th>Topic name</th>
                                            <th>Edit</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                         {topics && topics.map((val, key) => {
                                            return <tr style={{padding:"1px 0"}} key={key}>
                                                    <td>{key + 1}</td>
                                                    {chosenEditField == val.id ?<td><input onChange={(e) => {setTopicUpdate((prev) => { return {...prev, en:e?.target?.value}})}} style={{padding:"5px", borderRadius:"5px", border:"1px lightgray solid"}} type="text" defaultValue={topicUpdate?.en}/></td> : <td><span>{val?.name_en}</span></td>}
                                                    <td><button style={{cursor:"pointer", background:"transparent", border:"none"}} disabled={chosenEditField && chosenEditField !== val.id} onClick={() => handleEditTopic(val?.id, key, 'name_en')}><Icon source={chosenEditField == val.id ? MobileAcceptMajor : EditMajor} color="base"/></button></td>
                                                    <td onClick={() => handleDeleteTopics(val.id)} style={{cursor:"pointer"}}><Icon source={DeleteMajor} color="critical"/></td>

                                                </tr>
                                            })}
                                    </tbody>
                                </table> : <div style={{padding:"20px"}}>No topics found</div>}
                                    </div>
                            }
                            {selectedTopics == 1 &&
                             <table className="styled-table">
                             <thead>
                                 <tr>
                                     <th>Topic id</th>
                                     <th>Topic name</th>
                                     <th>Edit</th>
                                     <th>Delete</th>
                                 </tr>
                             </thead>
                             <tbody>
                                  {topics && topics.map((val, key) => {
                                     return <tr style={{padding:"1px 0"}} key={key}>
                                             <td>{key + 1}</td>
                                             {chosenEditField == val.id ?<td><input onChange={(e) => {setTopicUpdate((prev) => { return {...prev, ar:e?.target?.value}})}} style={{padding:"5px", borderRadius:"5px", border:"1px lightgray solid"}} type="text" defaultValue={topicUpdate?.ar}/></td> : <td><span>{val?.name_ar}</span></td>}
                                             <td><button style={{cursor:"pointer", background:"transparent", border:"none"}} disabled={chosenEditField && chosenEditField !== val.id} onClick={() => handleEditTopic(val?.id, key, 'name_ar')}><Icon source={chosenEditField == val.id ? MobileAcceptMajor : EditMajor} color="base"/></button></td>
                                             <td onClick={() => handleDeleteTopics(val.id)} style={{cursor:"pointer"}}><Icon source={DeleteMajor} color="critical"/></td>

                                         </tr>
                                     })}
                             </tbody>
                         </table>
                            }
                        </Tabs>
                        </Card>
                        </Page>
                <Page title="Manage FAQs" primaryAction={{
                            content: "Save FAQs",
                            onClick: handleSubmitFaq,
                        }}>
                        {errorMessage && <Toast content={errorMessage} onDismiss={toggleActive} />}
                        <div style={{width:"100%", backgroundColor:"white", borderRadius:"10px", boxShadow:"1px 1px 5px lightgray", padding:"15px"}}>
                            <TextStyle variation="strong">Topics</TextStyle>
                                <br/>
                                <select style={{marginTop:"10px", width:"100%", padding:"5px", borderRadius:"5px"}} onChange={(e) => {
                                    setTopicId(e.target.value)
                                }}>
                                    <option value="">-- Choose topic --</option>
                                    {topics.map((value, key) => {
                                        return <option key={key} value={parseInt(value.id)}>{value.name_en} / {value.name_ar}</option>
                                    })}
                                </select>
                        </div>
                        <br/>
                        {/* <div style={{width:"100%", backgroundColor:"white", borderRadius:"10px", boxShadow:"1px 1px 5px lightgray", padding:"15px"}}>
                            <TextStyle variation="strong">Upload Video <span style={{fontSize:"12px", fontWeight:"300"}}>(upload video instead of youtube url)</span></TextStyle>
                                <br/>
                                <Checkbox
                                    label="Upload video"
                                    checked={checkVideo}
                                    onChange={handleCheckVideo}
                                    />
                        </div> */}
                        <br/>
                       <div style={{display:"flex", alignContent:"center", gap:"20px", width:"100%"}}>
                       <div style={{width:"50%", backgroundColor:"white", borderRadius:"10px", boxShadow:"1px 1px 5px lightgray", padding:"15px"}}>
                            <TextStyle variation="strong">FAQ Content (English)</TextStyle>
                                {checkVideo ? <div>
                                {videoThumbnail()}
                                <DropZone
                                    onDrop={handleDropZoneVideoSrc}
                                    onDropAccepted={sendImageVideoSrc}
                                >
                                    {uploadedFilesVideoSrc}
                                    {fileUploadVideoSrc}
                                </DropZone>
                                </div> : 
                                 <div>
                                    <br/>
                                    <TextStyle variation="strong">Insert Youtube link (English)</TextStyle>
                                    <TextField
                                        placeholder="Enter Link"
                                        type="text"
                                        value={link.en}
                                        onChange={(value) => setLink((prev) => { return {...prev, en: value}})}
                                        error={''}
                                    />
                                 </div> }
                                <br/>
                                <TextStyle variation="strong">FAQ question (English)</TextStyle>
                                <TextField
                                    placeholder="Enter question"
                                    type="text"
                                    value={question.en}
                                    onChange={(value) => setQuestion((prev) => { return {...prev, en: value}})}
                                    error={''}
                                />
                                <br/>
                                <TextStyle variation="strong">FAQ answer (English)</TextStyle>
                                <TextField
                                    placeholder="Enter answer"
                                    type="text"
                                    onChange={(value) => setAnswer((prev) => { return {...prev, en: value}})}
                                    value={answer.en}
                                    error={''}
                                />
                                <br/>
                        </div>
                        <div style={{width:"50%", backgroundColor:"white", borderRadius:"10px", boxShadow:"1px 1px 5px lightgray", padding:"15px"}}>
                            <TextStyle variation="strong">FAQ Content (Arabic)</TextStyle>
                               {checkVideo ?  <div>
                                {videoThumbnailAr()}
                                <DropZone
                                    onDrop={handleDropZoneVideoSrcAr}
                                    onDropAccepted={sendImageVideoSrcAr}
                                >
                                    {uploadedFilesVideoSrcAr}
                                    {fileUploadVideoSrcAr}
                                </DropZone>
                                </div> : <div>
                                    <br/>
                                <TextStyle variation="strong">Insert Youtube link (Arabic)</TextStyle>
                                    <TextField
                                        placeholder="Enter Link"
                                        type="text"
                                        value={link.ar}
                                        onChange={(value) => setLink((prev) => { return {...prev, ar: value}})}
                                        error={''}
                                    />
                                    </div>}
                                <br/>
                                <TextStyle variation="strong">FAQ question (Arabic)</TextStyle>
                                <TextField
                                    placeholder="Enter question"
                                    type="text"
                                    value={question.ar}
                                    onChange={(value) => setQuestion((prev) => { return {...prev, ar: value}})}
                                    error={''}
                                />
                                <br/>
                                <TextStyle variation="strong">FAQ answer (Arabic)</TextStyle>
                                <TextField
                                    placeholder="Enter answer"
                                    type="text"
                                    onChange={(value) => setAnswer((prev) => { return {...prev, ar: value}})}
                                    value={answer.ar}
                                    error={''}
                                />
                                <br/>
                        </div>
                       </div>
                       <br/>
                        <Card title="Lists of FAQs" sectioned>
                        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                            {selected == 0 ? 
                            <Card.Section title={tabs[selected].content}>
                                {faqs?.length > 0 ? faqs?.map((val, key) => {
                                    return <Card  primaryFooterAction={{
                                        content: "Delete",
                                        onClick:() => handleDelete(val?.id)
                                      }} 
                                      actions={[
                                        {
                                          content: "Edit",
                                          url: `/admin/new-content/${val?.id}/faq`
                                        },
                                      ]} key={key}>
                                       <div style={{display:"flex", gap:"10px", padding:"10px"}}>
                                        <iframe frameBorder='0' src={val?.embed_link_en ? val.embed_link_en : val?.video_src_url} width="250" height="142"/>                                        
                                            <div style={{display:"flex", flexDirection:"column"}}>
                                                <span><strong>Question: </strong>{val?.question?.en}</span>
                                                <span><strong>Answer: </strong>{val?.answer?.en}</span>
                                                <span><strong>Topic: </strong>{val?.topic?.name_en}</span>
                                            </div>
                                       </div>
                                    </Card>
                                }) : <div style={{padding:"10px"}}>No FAQS found</div>}
                            </Card.Section> : 
                            <Card.Section title={tabs[selected].content}>
                                  {faqs?.length > 0 ?  faqs?.map((val, key) => {
                                    return <Card primaryFooterAction={{
                                        content: "Delete",
                                        onClick:() => handleDelete(val?.id)
                                      }} 
                                      actions={[
                                        {
                                          content: "Edit",
                                          url: `/admin/new-content/${val?.id}/faq`
                                        },
                                      ]} key={key}>
                                       <div style={{display:"flex", gap:"10px"}}>
                                        <iframe frameBorder='0' src={val?.embed_link_ar ? val.embed_link_ar : val?.video_src_url_ar} width="250" height="142"/>                                        
                                            <div style={{display:"flex", flexDirection:"column"}}>
                                                <span><strong>Question: </strong>{val?.question?.ar}</span>
                                                <span><strong>Answer: </strong>{val?.answer?.ar}</span>
                                                <span><strong>Topic: </strong>{val?.topic?.name_ar}</span>
                                            </div>
                                       </div>
                                    </Card>
                                }) : <div style={{padding:"10px"}}>No FAQS found</div>}
                            </Card.Section>}
                                <div style={{display:"flex", gap:"20px", alignItems:"center", justifyContent:"center"}}>
                                    <button style={{border:"none", cursor:"pointer", background:"transparent"}} onClick={() => setPage(page - 1)} disabled={page == 1}><Icon source={ChevronLeftMinor} color={page == 1 ? "base" : "primary"}/></button>
                                    <span style={{fontWeight:"bold"}}>{page} / {total == 0 ? 1 : Math.ceil(total/perPage)}</span>
                                    <button style={{border:"none", cursor:"pointer", background:"transparent"}} disabled={total <= 0 || page == Math.ceil(total/perPage)} onClick={() => setPage(page + 1)}><Icon source={ChevronRightMinor} color={page == Math.ceil(total/perPage) || total <= 0 ? "base" : "primary"}/></button>
                                </div>
                        </Tabs>
                        </Card>
                    {toastMarkup}
                </Page>
            </Router>
        </NavigationLayout>
    );
}
export default WebContent;
