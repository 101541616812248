import { Card, TextStyle } from '@shopify/polaris'
import React from 'react'
import CrmFreshdeskTicket from '../crm-freshdesk-ticket.component'

const Profile = ({ fullName, id, country, city }) => {
    return (
        <Card>
            <div className="smile-plan-intro-container">
                <div className="smile-plan-intro-1">
                    {fullName && (
                        <div>
                            <TextStyle>Full name: </TextStyle>
                            <TextStyle>{fullName}</TextStyle>
                            <br />
                        </div>
                    )}
                    <CrmFreshdeskTicket userId={id} />
                </div>
                {country && (
                    <div>
                        <TextStyle>Country: </TextStyle>
                        <TextStyle>{country}</TextStyle>
                        <br />
                    </div>
                )}
                {city && (
                    <div>
                        <TextStyle>City: </TextStyle>
                        <TextStyle>{city}</TextStyle>
                        <br />
                    </div>
                )}
            </div>
        </Card>
    )
}

export default Profile