import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Page,
  Filters,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Card,
  Badge,
  TextStyle,
  Spinner,
  Tabs,
  Thumbnail,
} from "@shopify/polaris";
import Cookie from "js-cookie";
import axios from "../axios";
import history from "../history";
import emptyListImage from "../img/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import { RadioGroup, Radio } from "react-radio-group";

const LeadsOfLeads = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [radioGroupValue, setRadioGroupValue] = useState("id");
  const tabs = [
    {
      id: "",
      content: "All Leads",
    },
    {
      id: "Entered Landing Page Flow",
      content: "Entered Landing Page Flow",
    },
    {
      id: "Filling Landing Page Flow",
      content: "Filling Landing Page Flow",
    },
    {
      id: "Completed Landing Page fFlow",
      content: "Completed Landing Page Flow",
    },
    {
      id: "Purchased Impression Kit",
      content: "Purchased Impression Kit",
    },
    {
      id: "Pre-mature Leads",
      content: "All Pre-mature Leads",
    },
  ];

  const [page, setPage] = useState(1);
  const perPage = 30;
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [headings, setHeadings] = useState([]);
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleAvailabilityChange = (availability) => {
    setAvailability(availability);
  };
  let filters = [];
  if(selectedTab != 5){
    filters = [
      {
        key: "availability",
        label: "Filter by",
        filter: (
          <ChoiceList
            title="Filter by"
            titleHidden
            choices={[
              { label: "ID", value: "id" },
              { label: "First Name", value: "First Name" },
              { label: "Last Name", value: "Last Name" },
              { label: "Email", value: "Email" },
            ]}
            selected={availability || []}
            onChange={handleAvailabilityChange}
          />
        ),
        shortcut: true,
      },
    ];
  }else{
    filters = [
      {
        key: "availability",
        label: "Filter by",
        filter: (
          <ChoiceList
            title="Filter by"
            titleHidden
            choices={[
              { label: "Smile 1", value: "Smile 1" },
              { label: "Smile 2", value: "Smile 2" },
              { label: "Smile 3", value: "Smile 3" },
            ]}
            selected={availability || []}
            onChange={handleAvailabilityChange}
          />
        ),
        shortcut: true,
      },
    ];
  }
  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }
  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div className="message-no-result"
    >
      <div className="message-no-result-img">
        <Image src={emptyListImage}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );
  const handleFiltersQueryChange = (queryValue) => {
    setQueryValue(queryValue);
  };
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelectedTab(selectedTabIndex),
    []
  );
  const handleChangeFilter = (selectedValue) => {
    setRadioGroupValue(selectedValue);
  };

  useEffect(() => {
    if(selectedTab != 5){
      axios
        .get(
          `/admin/v1/user-leads?page=${page}&per_page=${perPage}&filter[status]=${
            tabs[selectedTab].id
          }&filter[${
            !radioGroupValue ? "first_name" : radioGroupValue
          }]=${queryValue}`,
          {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          }
        )
        .then((result) => {
          console.log(result.data);
          setIsLoading(false);
          !result.data.data.data.length
            ? setIsListEmpty(true)
            : setIsListEmpty(false);
          setTotalPages(Math.ceil(result.data.data.total / perPage));
          setItems(
            result.data.data.data.map((item) => [
              <div
                className="itemClick"
                id={String(item.id)}
                onClick={(e) => handleRowClick(e)}
              >
                <b>{item.id}</b>
              </div>,
              item?.email && (
                <div
                  className="itemClick"
                  id={String(item.id)}
                  onClick={(e) => handleRowClick(e)}
                >
                  {item.email}
                </div>
              ),
              item?.first_name && (
                <div
                  className="itemClick"
                  id={String(item.id)}
                  onClick={(e) => handleRowClick(e)}
                >
                  {item.first_name}
                </div>
              ),
              item?.last_name && (
                <div
                  className="itemClick"
                  id={String(item.id)}
                  onClick={(e) => handleRowClick(e)}
                >
                  {item.last_name}
                </div>
              ),
  
              item?.status && (
                <div
                  className="itemClick"
                  id={String(item.id)}
                  onClick={(e) => handleRowClick(e)}
                >
                  <Badge>{item.status}</Badge>
                </div>
              ),
              <div style={{ display: "flex", flex: "row" }}>
                {item?.user_lead_photos?.image1_url && (
                  <Thumbnail
                    source={item.user_lead_photos?.image1_url}
                    size="small"
                  />
                )}
  
                {item?.user_lead_photos?.image2_url && (
                  <div style={{ marginLeft: "5px" }}>
                    <Thumbnail
                      source={item.user_lead_photos?.image2_url}
                      size="small"
                    />
                  </div>
                )}
  
                {item?.user_lead_photos?.image3_url && (
                  <div style={{ marginLeft: "5px" }}>
                    <Thumbnail
                      source={item.user_lead_photos?.image3_url}
                      size="small"
                    />
                  </div>
                )}
              </div>,
            ])
          );
          setHeadings(["ID","Email", "First Name","Last Name","Status","Images"]);
        })
        .catch((err) => setIsLoading(false));
    }
  }, [page, radioGroupValue, queryValue, selectedTab]);

  useEffect(()=>{
    if(selectedTab == 5){
        axios
          .get(
            `/admin/v1/pre-mature-leads/fetch?page=${page}&per_page=${perPage}&search=${queryValue}&search_by=${radioGroupValue}&filter=${availability}`,
            {
              headers: {
                Authorization: "Bearer " + Cookie.get("token"),
              },
            }
          )
          .then((result) => {
            console.log(result.data);
            setIsLoading(false);
            !result.data.data.data.length
              ? setIsListEmpty(true)
              : setIsListEmpty(false);
            setTotalPages(Math.ceil(result.data.data.total / perPage));
            setItems(
              result.data.data.data.map((item) => [
                <div
                  className="itemClick"
                  id={String(item.id)}
                  onClick={(e) => handleRowClick(e)}
                >
                  <b>{item.id}</b>
                </div>,
                item?.email && (
                  <div
                    className="itemClick"
                    id={String(item.id)}
                    onClick={(e) => handleRowClick(e)}
                  >
                    {item.email}
                  </div>
                ),
                item?.full_name && (
                  <div
                    className="itemClick"
                    id={String(item.id)}
                    onClick={(e) => handleRowClick(e)}
                  >
                    {item.full_name	}
                  </div>
                ),
                item?.phone_number && (
                  <div
                    className="itemClick"
                    id={String(item.id)}
                    onClick={(e) => handleRowClick(e)}
                  >
                    {item.phone_number}
                  </div>
                ),
                item?.city && (
                  <div
                    className="itemClick"
                    id={String(item.id)}
                    onClick={(e) => handleRowClick(e)}
                  >
                    {item.city}
                  </div>
                ),
                item?.variant && (
                  <div
                    className="itemClick"
                    id={String(item.id)}
                    onClick={(e) => handleRowClick(e)}
                  >
                    {item.variant}
                  </div>
                ),
              ])
            );
            setHeadings(["ID","Email", "Full Name","Phone Number","City", "Variant"])
          })
          .catch((err) => setIsLoading(false));
      }
  }, [page, radioGroupValue, queryValue, selectedTab, availability])
  return (
    <Page fullWidth title="Leads">
      {loadingMarkup}
      <Card>
        <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
          <Card>
            <Card.Section>
              {" "}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <TextStyle variation="strong">Search By : </TextStyle>
                <RadioGroup
                  name="fruit"
                  selectedValue={radioGroupValue}
                  onChange={handleChangeFilter}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {/* <label style={{ marginLeft: "20px" }}>
                      <Radio value="in_table" />
                      All
                    </label> */}
                    <label style={{ marginLeft: "20px" }}>
                      <Radio value="id" />
                      ID
                    </label>
                    {selectedTab != 5 ?
                    <>
                      <label style={{ marginLeft: "20px" }}>
                        <Radio value="first_name" />
                        First Name
                      </label>
                      <label style={{ marginLeft: "20px" }}>
                        <Radio value="last_name" />
                        Last Name
                      </label>
                    </>
                    : 
                    <label style={{ marginLeft: "20px" }}>
                      <Radio value="full_name" />
                      Full Name
                    </label>
                    }
                    <label style={{ marginLeft: "20px" }}>
                      <Radio value="email" />
                      Email
                    </label>
                    {selectedTab == 5 && 
                    <>
                      <label style={{ marginLeft: "20px" }}>
                          <Radio value="city" />
                          City
                      </label>
                      <label style={{ marginLeft: "20px" }}>
                        <Radio value="variant" />
                        Variant
                      </label>
                    </>
                    }
                  </div>
                </RadioGroup>
              </div>
            </Card.Section>
            <Card.Section>
              <Filters
                queryValue={queryValue}
                filters={filters}
                appliedFilters={appliedFilters}
                onQueryChange={handleFiltersQueryChange}
                onQueryClear={handleQueryValueRemove}
                onClearAll={handleFiltersClearAll}
              />
            </Card.Section>{" "} 
            <DataTable
              columnContentTypes={["text", "text", "text", "text"]}
              headings={headings}
              rows={items}
              verticalAlign="middle"
            />
          </Card>
        </Tabs>
        {emtyState}
      </Card>
      {totalPages >= 2 && (
        <div
          style={{
            paddingTop: "10px",
            textAlign: "center",
            paddingBottom: "10px",
          }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChange}
            size="large"
          />
        </div>
      )}
    </Page>
  );
  function handleRowClick(e) {
    if(selectedTab != 5){
      history.push(`/admin/leads/${e.currentTarget.id}`);
    }
  }
  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return value.map((val) => `Filter by ${val}`).join(", ");
      case "productType":
        return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};

export default LeadsOfLeads;
