import { TableCell, TableRow } from '@mui/material';
import { getStatusBadgeComponent, TIKCET_TYPE_ENUMS } from '../helpers';
import moment from 'moment';
import { GeneralFormats } from '../../util/constants';

const TicketsUserTableRow = ({ ticket }) => {
  const { status_by_user = '-', id = '-', type = '-' } = ticket;
  const handleClick = () => window.open(`/admin/ticketing/${id}`);
  return (
    <TableRow>
      <TableCell>{getStatusBadgeComponent(status_by_user)}</TableCell>
      <TableCell style={{ cursor: 'pointer' }} onClick={handleClick}>
        #{id}
      </TableCell>
      <TableCell>{TIKCET_TYPE_ENUMS[type]}</TableCell>
      <TableCell>
        {ticket?.created_at
          ? moment(ticket?.created_at).format(GeneralFormats.dateFormat)
          : ''}
      </TableCell>
      <TableCell>{ticket?.reported_by?.full_name ?? '-'}</TableCell>
      <TableCell>{ticket?.reported_to?.full_name ?? '-'}</TableCell>
    </TableRow>
  );
};

export default TicketsUserTableRow;
