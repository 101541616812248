import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../history";
import ListPlans from "./ListPlans";
import { AppProvider } from "@shopify/polaris";
import AddPlan from "./AddPlan";
import MainSub from "./MainSub";
import NavigationLayout from "../components/NavigationLayout";
import LightPlan from "./LightPlan";
import MediumPlan from "./MediumPlan";
import ComplexPlan from "./ComplexPlan";

function Plans() {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/plans" component={ListPlans} />
          <Route exact path="/admin/plans/light" component={LightPlan} />
          <Route exact path="/admin/plans/medium" component={MediumPlan} />
          <Route exact path="/admin/plans/complex" component={ComplexPlan} />
          <Route path="/admin/plans/create" component={AddPlan} />
          <Route path="/admin/plans/:id" component={MainSub} />
        </Switch>
      </Router>
    </NavigationLayout>
  );
}
export default Plans;
