import { Button, Page } from '@shopify/polaris'
import React from 'react'
import { Router } from 'react-router-dom'
import NavigationLayout from '../components/NavigationLayout'
import history from '../history'

const VoucherMenu = () => {
    return (
        <NavigationLayout>
            <Router history={history}>
                <Page breadcrumbs={[{ content: "Back", url: '/admin/home' }]} title="Content Management">
                   <div style={{display:"flex", justifyContent:"space-around", gap:"20px"}}>
                   <div style={{ textAlign: "center", width: "100%", backgroundColor: "white", borderRadius: "10px", boxShadow: "1px 1px 5px lightgray", padding: "15px" }}>
                        <Button onClick={() => history.push('/admin/voucher-system')} primary>Voucher System</Button>
                    </div>
                    <div style={{ textAlign: "center", width: "100%", backgroundColor: "white", borderRadius: "10px", boxShadow: "1px 1px 5px lightgray", padding: "15px" }}>
                        <Button onClick={() => history.push('/admin/careplus')} primary>Care+</Button>
                    </div>
                   </div>
                </Page>
            </Router>
        </NavigationLayout>
    )
}

export default VoucherMenu