import React, { useState, useEffect } from "react";
import { FormLayout, Card, Layout, Badge, TextStyle } from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../../../axios";
import "@zendeskgarden/react-pagination/dist/styles.css";
import {useHistory, useParams} from "react-router-dom";
import CustomTooltip from "./../../../components/CustomTooltip";

function Status(props) {
  const { id } = useParams();
  const [statusResponse, setStatusResponse] = useState([]);
  const history = useHistory();
  let statusIDs = [
    "Severe Case Pending",
    "Purchase Impression Kit",
    "Taking Impressions and Photos",
    "Pending Taking Impressions and Photos",
    "Your Smile Plan",
    "Aligner Kit Purchased",
    "Completed",
    "Your Photos",
    "Purchase Book A Scan",
    "Book A Scan Purchased",
    "Free Book A Scan",
    "OTP Confirmed",
  ];
  let statusNames = [
    "Review Severe Cases",
    "Pending Purchase Impression Kit",
    "Uploading Impressions & Photos",
    "Review Impressions",
    "Qualified",
    "Smile Journey",
    "Completed",
    "Screening",
    "Pending purchase book a scan",
    "Customer booked appointment",
    "Free Book A Scan",
    "OTP Confirmed",
  ];
  useEffect(() => {
    axios
      .get(`admin/v1/users/${id}/status?tp_stage=${true}`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        result.data.data && setStatusResponse(result.data);
      })
      .catch((err) => console.log(err));
  }, []);

    const handleEdit = () => {
        history.push(
            `/admin/users/${id}/dashboard/editstatus`,
            {
                clinicName: props.clinicName,
                planType: props.planType,
                status: props.status,
                pageTitle: props.pageTitle,
                clinicStatus: props.clinicStatus,
                userId: props.userId
            }
        );
    }

  return (
    <Layout.Section oneHalf>
      <Card
        sectioned
        title="STATUS"
        actions={[
          {
            content: "Edit",
            onClick: handleEdit, 
            disabled: String(Cookie.get("userType")) === "50" && true,
          },
        ]}
      >
        <FormLayout>
          {statusResponse.data && (
            <div className="rowDiv">
              <Badge>
                Status: {statusNames[statusIDs.indexOf(statusResponse.data)]}
              </Badge>
            </div>
          )}

          {statusResponse.sub_status && (
            <div className="rowDiv">
              <Badge>Sub-status: {statusResponse.sub_status}</Badge>
            </div>
          )}

          {statusResponse.treatment_stage && (
            <div className='rowDiv'>
              <span>
                <TextStyle variation='strong'>Treatment Stage: </TextStyle>
                <TextStyle>{statusResponse.treatment_stage.tp_name}: {statusResponse.treatment_stage.tp_stage}</TextStyle>
              </span>
            </div>
          )}
        </FormLayout>
      </Card>
    </Layout.Section>
  );
}
export default React.memo(Status);
