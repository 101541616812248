import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  tabs: {
    fontSize: "1.2rem !important",
    fontWeight: "600 !important",
    textTransform: "none !important",
  },
  header: {
    fontWeight: "600 !important",
    fontSize: "1.3rem !important",
  },
  tableCell: {
    fontSize: "1.2rem !important",
    fontWeight: "400 !important",
  },
  buttonStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "3px",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    columnGap: "1rem",
  },
  hide: {
    display: "none !important",
  },
}));
