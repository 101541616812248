import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    messageBox: {
        "& .rce-mbox-text:after": {
            display: "none"
        },
        "& > div:first-of-type": {
            maxWidth:"80%",
            "& .rce-mbox-file > button":{
                maxWidth:"100% !important"
            }
        },
        // "& .rce-mbox-forward":{
        //   backgroundColor:"yellow"
        // }
    },
    messagesRow: {
        maxHeight: "85%",
        overflowY: "auto",
    },
    mentionButton:{
        position:"fixed",
        right:"2%",
        bottom:"15%", 
        backgroundColor:"white", 
        cursor:"pointer",
        border: '1px solid #ccc',
        borderRadius: '5px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        transition: 'box-shadow 0.3s ease',

        "&:hover": {
            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.4)',
        }
        
    }

}));