import { Button, CircularProgress, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import ScansDropZone from "./ScansDropZone";
import axios from "../axios";
import axiosAws from "axios";
import ProcedureHeader from "./ProcedureHeader";
import Cookies from "js-cookie";
import ApprovalActions from "./ApprovalActions";
import stl from "../assets/images/stl-thumbnail.png";
import Save from "./Save";
import DisapprovalModal from "./DisapprovalModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertDeleteDialog from "./Popup/alert-delete-dialog.component";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import moment from "moment";
import { GeneralFormats, IssueTypes, ReviewStatus } from "../util/constants";
import JSZip from "jszip";

const useStyles = makeStyles(() => {
  return {
    wrapper: {
      padding: "2rem",
      boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    box: {
      display: "flex",
      flexWrap: "wrap",
      columnGap: 10
    },
    titleStyle: {
      height: "16px",
      color: "#303030",
      fontSize: "16px !important",
      letterSpacing: 0,
      lineHeight: "22px",
    },
    titleBox: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '2rem',
    },
    title: {
      fontSize: 14,
      marginBottom: 5,
    },
    date: {
      fontSize: 13,
      marginTop: 5,
    },
    saveWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      marginTop: 10,
      ["@media only screen and (max-width: 500px) "]: {
          flex: "33%",
      },
    },
    btn: {
      display: 'block',
      border: "1px solid #C4CDD5",
      borderRadius: "3px",
      background: "linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%)",
      boxShadow: "0 1px 0 0 rgba(22,29,37,0.05)",
    },
    reviewBoldTxt: {
      fontWeight: 'bold',
    },
    size11: {
        fontSize: '11px',
    },
  };
});
export default function AppointmentScans({
  customStyle = {},
  userId,
  disable = false,
  handleTabsExpand = () => { },
  index = 0,
  appointmentId = null,
  appointmentNumber,
  appointmentType = "scans",
  openCloseTabs,
  scrollToExpandedTab,
}) {
  const classes = useStyles();
  const dateFormat = GeneralFormats.dateFormat;
  const [iconScan, setIconScan] = useState(null);
  const [scans, setScans] = useState(null);
  const [substituteScan, setSubstituteScan] = useState({
    document: false,
    lower_scan: false,
    bite_scan_1: false,
    bite_scan_2: false,
    scans_folder: false,
  });
  const [segmentationTechnicians, setSegmentationTechnicians] = useState([]);
  const [open, setOpen] = useState(false);
  const [deletePhoto, setDeletePhoto] = useState({id: null, type: ''});
  const [scanIssues, setScanIssues] = useState([]);
  const [options, setOptions] = useState([]);
  const [tpOption, setTpOption] = useState(null);
  const [loading, setLoading] = useState({
    disabled: false,
    popupActive: false,
    success: false,
    disableReviewBtn: false,
    download: false,
  });
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [imageKey, setImageKey] = useState(null);
  const [callHandleSave, setCallHandleSave] = useState(0);
  const [uploadProgress, setUploadProgress] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [fileImages, setFileImages] = useState([]);
  const [images, setImages] = useState([]);
  const [imageKeys, setImageKeys] = useState([]);
  const [reviewData, setReviewData] = useState({
    comment: '',
    commentError: '',
    loading: false,
    modalOpened: false,
    tpOptionError: '',
    status: ''
  });
  const [deleteLoading, setDeleteLoading] = useState({
    document: false,
    lower_scan: false,
    bite_scan_1: false,
    bite_scan_2: false,
    scans_folder: false,
    substitute_document: false,
    substitute_lower_scan: false,
    substitute_bite_scan_1: false,
    substitute_bite_scan_2: false,
    substitute_scans_folder: false,
  });
  const substituteFiles = useRef(null);
  
  const handleChangeSubstitutionScan = (type) => {
    setSubstituteScan(prev => ({
      ...prev,
      [type]: !substituteScan?.[type],
    }));
    if(!substituteScan?.[type]) {
      substituteFiles.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const handleUploadError = (type, err) => {
    setErrorMsg("Upload failed. Please try again.");
    setScans((prev) => ({
      ...prev,
      [type]: undefined,
    }));
    setImageKey((prev) => ({
      // ...prev,
      [type]: undefined,
    }));
    setLoading(prev => ({
      ...prev,
      disabled: false,
    }));
    console.log(err);
  };

  const handleClickOpen = (idData, typeData) => {
    setDeletePhoto({id: idData, type: typeData});
    setOpen(true);
  };

  const handleChangeComment = (newValue) => {
    setReviewData(prev => ({
      ...prev,
      comment: newValue,
    }));
  }

  const handleChangeModal = () => {
    setReviewData(prev => ({
      ...prev,
      modalOpened: false,
    }));
    setScanIssues([]);
  }

  const handleChangeReview = (status) => {
    setLoading(prev => ({
      ...prev,
      disableReviewBtn: status == ReviewStatus.approved,
    }));
    if(status == ReviewStatus.approved){
      handleReviewStatus(status);
    } else {
      setReviewData(prev => ({
        ...prev,
        modalOpened: true,
        status
      }));
    }
  }

  const handleChange = (files, id) => {
    setLoading(prev => ({
      ...prev,
      disabled: true,
      success: false,
    }));
    setErrorMsg("");
    const file = files[0];
    let imgUrl =
      validImageTypes.indexOf(file.type) > 0
        ? window.URL.createObjectURL(file)
        : stl;
    setScans((prev) => ({
      ...prev,
      [id]: imgUrl,
    }));
    saveInAws(file, id);
  };

  const uploadConfig = (progressEvent, name) => {
    if (progressEvent.loaded === progressEvent.total) {
      delete uploadProgress[name];
      setUploadProgress(uploadProgress);
    } else {
      const array = {
        ...uploadProgress,
        [name]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
      };
      setUploadProgress(array);
    }
  };

  const zipScanFiles = async (arrayFiles, type) => {
    const zip = new JSZip();
    // Function to read a File and add it to the zip.
    const addFileToZip = (file) => {
      console.log('file', file);
      return new Promise(async (resolve) => {
        // Read the file as an ArrayBuffer.
        const fileData = await new Promise((resolveFile, rejectFile) => {
          const reader = new FileReader();
          reader.onload = () => resolveFile(reader.result);
          reader.onerror = rejectFile;
          reader.readAsArrayBuffer(file);
        });

        // Add the file to the zip archive with its original name.
        zip.file(file.name, fileData, { compression: 'DEFLATE', compressionOptions: { level: 9 } });
        resolve();
      });
    };
    const promises = Array.from(arrayFiles).map(addFileToZip);
    try {
      await Promise.all(promises);
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      const form_data = new FormData();
      form_data.append('content_type', 'text/plain');
      form_data.append('file_name', `user_${userId}_Scans-Folder.zip`);
      try {
        const response = await axios.post('/admin/v1/images/s3', form_data, {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        });
        if (response.status === 200) {
          const { key, url } = response.data;
          setImageKey((prev) => ({
            // ...prev,
            [type]: key,
            [`${type}_name`]: `user_${userId}_Scans-Folder.zip`,
          }));
          await axios.put(url, zipBlob, {
            headers: {
              "x-amz-acl": "public-read-write",
              "Content-Type": "text/plain",
            },
            onUploadProgress: (event) => uploadConfig(event, type),
          }).then((res) => {
            if(type == 'scans_folder') {
              setCallHandleSave(1);
            } else {
              setCallHandleSave(2);
            }
          })
        }
      } catch (error) {
        console.log("Error in saving zip file on aws", error);
      }
    } catch (error) {
      console.error('Error zipping files:', error);
    }
  };

  const handleFolderUpload = (event, type) => {
    const files = event?.target?.files;
    zipScanFiles(files, type);
  };

  const saveInAws = (value, type) => {
    setErrorMsg("");
    let imageType = value.type;
    const form_data = new FormData();
    form_data.append("content_type", imageType);
    form_data.append("file_name", value.name);
    axios.post("/admin/v1/images/s3", form_data, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((res) => {
      if (type == "document") {
        setImageKey((prev) => ({
          // ...prev,
          [type]: res.data.key,
          name: value.name,
        }));
      } else {
        setImageKey((prev) => ({
          // ...prev,
          [type]: res.data.key,
          [`${type}_name`]: value.name,
        }));
      }
      const url2 = res.data.url;
      axiosAws.put(url2, value, {
        headers: {
          "x-amz-acl": "public-read-write",
          "Content-Type": imageType,
        },
        onUploadProgress: (event) => uploadConfig(event, type),
      }).then((res) => {
        if(type == 'document' || type == 'lower_scan' || type == 'bite_scan_1' || type == 'bite_scan_2') {
          setCallHandleSave(1);
        } else {
          setCallHandleSave(2);
        }
      }).catch((err) => {
        handleUploadError(type, err);
      });
    }).catch((err) => {
      handleUploadError(type, err);
    });
  };

  const handleDelete = () => {
    const { id, type} = deletePhoto;
    setDeleteLoading(prev => ({...prev, [type]: true}));
    let url = `/admin/v2/case-record-scans/delete-all/${scans?.id}`;
    if(id){
      url = `/admin/v2/case-record-scans/delete/${id}`;
    }
    axios.delete(url, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((res) => {
      handleDataFetched();
    }).catch((err) => {
      console.log('handleDelete err', err);
    }).finally(() => {
      setDeleteLoading(prev => ({...prev, [type]: false}));
    });
  }

  const downloadScans = () => {
    setLoading(prev => ({
      ...prev,
      download: true,
    }));
    axios.get(`/admin/v2/users/${userId}/download-scans/${scans?.id}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((result) => {
      var win = window.open(
        `${process.env.REACT_APP_BASE_URL}/storage${result.data.data}`,
        "_blank"
      );
      win.focus();
      if(!result.data.success){
        toast.error('No scans found to download', 3000);
      }
    }).catch((err) => {
      console.log('err', err);
    }).finally(f => {
      setLoading(prev => ({
        ...prev,
        download: false,
      }));
    });
  }

  const handleSave = () => {
    if(imageKey){
      setErrorMsg("");
      setLoading(prev => ({
        ...prev,
        disabled: true,
        success: false,
      }));
      const form_data = new FormData();
      imageKey?.document && form_data.append("document", imageKey.document);
      imageKey?.name && form_data.append("name", imageKey.name);
      imageKey?.lower_scan && form_data.append("lower_scan", imageKey.lower_scan);
      imageKey?.lower_scan_name && form_data.append("lower_scan_name", imageKey.lower_scan_name);
      imageKey?.bite_scan_1 && form_data.append("bite_scan_1", imageKey.bite_scan_1);
      imageKey?.bite_scan_1_name && form_data.append("bite_scan_1_name", imageKey.bite_scan_1_name);
      imageKey?.bite_scan_2 && form_data.append("bite_scan_2", imageKey.bite_scan_2);
      imageKey?.bite_scan_2_name && form_data.append("bite_scan_2_name", imageKey.bite_scan_2_name);
      imageKey?.scans_folder && form_data.append("scans_folder", imageKey.scans_folder);
      imageKey?.scans_folder_name && form_data.append("scans_folder_name", imageKey.scans_folder_name);
      imageKey?.segmentation_technician_id !== undefined && form_data.append("segmentation_technician_id", imageKey.segmentation_technician_id);
      form_data.append("procedure", appointmentType?.procedure);
      form_data.append("user_scan_info_id", appointmentId);
      axios.post(`/admin/v2/users/${userId}/case-record-scans`, form_data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }).then((res) => {
        handleDataFetched();
        if (res.data.success) {
          setLoading(prev => ({
            ...prev,
            success: true,
            disabled: false,
          }));
        }
      }).catch((err) => {
        setErrorMsg("Upload failed. Please try again.");
        setLoading(prev => ({
          ...prev,
          success: false,
          disabled: false,
        }));
        console.log(err);
      });
    } else {
      setErrorMsg("Please upload scan files");
    }
  };

  const handleSaveSubstituteFiles = () => {
    if(imageKey){
      setErrorMsg("");
      setLoading(prev => ({
        ...prev,
        disabled: true,
        success: false,
      }));
      const form_data = new FormData();
      form_data.append("original_scan_id", scans?.id);
      imageKey?.substitute_document && form_data.append("substitute_document", imageKey.substitute_document);
      imageKey?.substitute_document_name && form_data.append("substitute_document_name", imageKey.substitute_document_name);
      imageKey?.substitute_lower_scan && form_data.append("substitute_lower_scan", imageKey.substitute_lower_scan);
      imageKey?.substitute_lower_scan_name && form_data.append("substitute_lower_scan_name", imageKey.substitute_lower_scan_name);
      imageKey?.substitute_bite_scan_1 && form_data.append("substitute_bite_scan_1", imageKey.substitute_bite_scan_1);
      imageKey?.substitute_bite_scan_1_name && form_data.append("substitute_bite_scan_1_name", imageKey.substitute_bite_scan_1_name);
      imageKey?.substitute_bite_scan_2 && form_data.append("substitute_bite_scan_2", imageKey.substitute_bite_scan_2);
      imageKey?.substitute_bite_scan_2_name && form_data.append("substitute_bite_scan_2_name", imageKey.substitute_bite_scan_2_name);
      imageKey?.substitute_scans_folder && form_data.append("substitute_scans_folder", imageKey.substitute_scans_folder);
      imageKey?.substitute_scans_folder_name && form_data.append("substitute_scans_folder_name", imageKey.substitute_scans_folder_name);
      imageKey?.substitute_segmentation_technician_id !== undefined && form_data.append("substitute_segmentation_technician_id", imageKey.substitute_segmentation_technician_id);
      axios.post(`/admin/v2/users/${userId}/case-record-substitute-scans`, form_data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }).then((res) => {
        handleDataFetched();
        if (res.data.success) {
          setLoading(prev => ({
            ...prev,
            success: true,
            disabled: false,
          }));
        }
      }).catch((err) => {
        setErrorMsg("Upload failed. Please try again.");
        setLoading(prev => ({
          ...prev,
          success: false,
          disabled: false,
        }));
        console.log(err);
      });
    } else {
      setErrorMsg("Please upload scan files");
    }
  };

  const handleReviewStatus = async (status) => {
    if (status != ReviewStatus.approved && !scanIssues.length && !reviewData.comment) {
      setReviewData(prev => ({
        ...prev,
        commentError: 'Please enter a comment',
      }));
    } else {
      let body = {
        review_status: status,
        procedure: appointmentType?.procedure,
        appointment_id: appointmentId,
      }
      if(status != ReviewStatus.approved) {
        let comment = `
          Appointment ${appointmentNumber} - ${appointmentType?.procedure}<br/>
          Status: Scans ${status}
        `;
        if (options.length && !scanIssues.length) {
          return;
        }
        if(!tpOption && status === ReviewStatus.approvedWithIssues) {
          setReviewData(prev => ({
            ...prev,
            tpOptionError: 'Please select an option',
          }));
          return;
        }
        if(scanIssues.length) {
          comment += '<br/>Scan issues: ';
          comment += scanIssues.map(issue => issue.label).join(', ');
        }
        if(tpOption) {
          comment += `<br/>TP option: ${tpOption.label}`;
        }
        if(reviewData.comment) {
          comment += `<br/>Notes: ${reviewData.comment}`;
        }
        body = {
          ...body,
          comment,
          images: imageKeys,
          issues: scanIssues.map(issue => issue.id),
        };
      }
      setReviewData(prev => ({
        ...prev,
        loading: true,
      }));
      try {
        const response = await axios.post(`/admin/v2/users/case-record-scans/${scans?.id}/approval-status`, body, {
          headers: {
            Authorization: 'Bearer ' + Cookies.get('token'),
          },
        });
        if (response.status === 200) {
          setScans(prev => ({
            ...prev,
            reviewer: response.data?.data?.reviewer,
            review_status: response.data?.data?.review_status,
            reviewed_at: response.data?.data?.reviewed_at,
          }));
        }
      } catch (error) {
        console.log('error disapprove', error);
      } finally {
        setReviewData(prev => ({
          ...prev,
          loading: false,
          modalOpened: false,
        }));
      }
    }
  };

  const handleDataFetched = () => {
    setLoading(prev => ({
      ...prev,
      popupActive: true,
    }));
    axios.get(`admin/v2/users/case-record-scans/${appointmentId}?procedure=${appointmentType?.procedure}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then(res => {
      let empty = true;
      if (res.data.success) {
        if(!res.data.data.review_status && loading.disableReviewBtn){
          setLoading(prev => ({
            ...prev,
            disableReviewBtn: false,
          }));
        }
        setSegmentationTechnicians(res.data.segmentationTechnicians);
        setScans({
          ...res.data.data,
          document: res.data.data?.document ? stl : null,
          lower_scan: res.data.data?.lower_scan ? stl : null,
          bite_scan_1: res.data.data?.bite_scan_1 ? stl : null,
          bite_scan_2: res.data.data?.bite_scan_2 ? stl : null,
          scans_folder: res.data.data?.scans_folder ? stl : null,
          substitute_document: res.data.data?.substitute_document ? stl : null,
          substitute_lower_scan: res.data.data?.substitute_lower_scan ? stl : null,
          substitute_bite_scan_1: res.data.data?.substitute_bite_scan_1 ? stl : null,
          substitute_bite_scan_2: res.data.data?.substitute_bite_scan_2 ? stl : null,
          substitute_scans_folder: res.data.data?.substitute_scans_folder ? stl : null,
        });
        setSubstituteScan({
          document: res.data.data?.substitute_document ? true : false,
          lower_scan: res.data.data?.substitute_lower_scan ? true : false,
          bite_scan_1: res.data.data?.substitute_bite_scan_1 ? true : false,
          bite_scan_2: res.data.data?.substitute_bite_scan_2 ? true : false,
          scans_folder: res.data.data?.substitute_scans_folder ? true : false,
        });
        setIconScan(res.data.isAllProvided);
        empty = res.data.isAllProvided == 0 ||
        (res.data.data.scanner_placeholders == 4 && !res.data.data.lower_scan_id && !res.data.data.bite_scan_1_id && !res.data.data.bite_scan_2_id && !res.data.data.document_id) ||
        (res.data.data.scanner_placeholders == 1 && !res.data.data.scans_folder_id);
      } else {
        setIconScan(0);
      }
      if(empty){
        setLoading(prev => ({
          ...prev,
          download: empty,
        }));
      }
    }).catch(err => {
      console.log('fetch error', err);
    }).finally(f => {
      setLoading(prev => ({
        ...prev,
        popupActive: false,
      }));
    })
  };

  const handleChangeSegmentationTechnician = (type, id) => {
    setImageKey({[type]: id});
    setCallHandleSave(type == 'segmentation_technician_id' ? 1 : 2);
  };

  useEffect(() => {
    handleDataFetched();
  }, []);

  useEffect(() => {
    if (callHandleSave === 1) {
      handleSave();
      setCallHandleSave(0);
    } else if (callHandleSave === 2) {
      handleSaveSubstituteFiles();
      setCallHandleSave(0);
    }
  }, [callHandleSave]);

  useEffect(() => {
    scrollToExpandedTab(index, "scans");
  }, [openCloseTabs]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type='scans'
      headerTitle={<p><span className="procedure-header-title">Scans:</span> <span className='header-title-gray'>{appointmentType?.procedure}</span></p>}
      index={index}
      iconCheck={iconScan}
    >
      {openCloseTabs[index]?.scans && (
        <Box
          style={customStyle}
          className={`${classes.wrapper} scans-${index}`}
        >
          {loading.popupActive ?
            <div style={{ textAlign: 'center' }}>
              <CircularProgress color='primary' />
            </div> :
            <>
              <ToastContainer/>
              <Box className={classes.titleBox}>
                <Typography className={classes.titleStyle}>Upload Scans</Typography>
                <Button disabled={loading.download} onClick={downloadScans}>Download Scans</Button>
              </Box>
              {
                scans?.scanner_placeholders == 1 ?
                <Box className={classes.box}>
                  <ScansDropZone
                    uploadProgress={uploadProgress?.scans_folder}
                    disable={disable || loading.disabled}
                    value={scans?.scans_folder}
                    id='scans_folder'
                    handleChange={handleFolderUpload}
                    message='Scans Folder'
                    name={scans?.scans_folder_name}
                    createdAt={scans?.scans_folder_created_at}
                    updatedAt={scans?.scans_folder_updated_at}
                    disabled={deleteLoading.scans_folder || !scans?.scans_folder_id}
                    handleDelete={() => handleClickOpen(scans?.scans_folder_id, 'scans_folder')}
                    oemReplaced={(appointmentType?.procedure == 'First Visit' || appointmentType?.procedure == 'Walk-in') ? substituteScan.scans_folder : null}
                    handleOemReplaced={() => handleChangeSubstitutionScan('scans_folder')}
                    folderUpload
                  />
                </Box> :
                <>
                  <Box className={classes.box}>
                    <ScansDropZone
                      uploadProgress={uploadProgress?.document}
                      disable={disable || loading.disabled}
                      value={scans?.document}
                      id={"document"}
                      handleChange={handleChange}
                      message='Upload upper scan'
                      name={scans?.name}
                      createdAt={scans?.document_created_at}
                      updatedAt={scans?.document_updated_at}
                      disabled={deleteLoading.document || !scans?.document_id}
                      handleDelete={() => handleClickOpen(scans?.document_id, 'document')}
                      oemReplaced={(appointmentType?.procedure == 'First Visit' || appointmentType?.procedure == 'Walk-in') ? substituteScan.document : null}
                      handleOemReplaced={() => handleChangeSubstitutionScan('document')}
                    />
                    <ScansDropZone
                      uploadProgress={uploadProgress?.lower_scan}
                      disable={disable || loading.disabled}
                      value={scans?.lower_scan}
                      id={"lower_scan"}
                      handleChange={handleChange}
                      message='Upload lower scan'
                      name={scans?.lower_scan_name}
                      createdAt={scans?.lower_scan_created_at}
                      updatedAt={scans?.lower_scan_updated_at}
                      scanFileId={scans?.lower_scan_id}
                      disabled={deleteLoading.lower_scan || !scans?.lower_scan_id}
                      handleDelete={() => handleClickOpen(scans?.lower_scan_id, 'lower_scan')}
                      oemReplaced={(appointmentType?.procedure == 'First Visit' || appointmentType?.procedure == 'Walk-in') ? substituteScan.lower_scan : null}
                      handleOemReplaced={() => handleChangeSubstitutionScan('lower_scan')}
                    />
                  </Box>
                  <Box className={classes.box}>
                    <ScansDropZone
                      uploadProgress={uploadProgress?.bite_scan_1}
                      disable={disable || loading.disabled}
                      value={scans?.bite_scan_1}
                      id={"bite_scan_1"}
                      handleChange={handleChange}
                      message='Bite scan 1'
                      name={scans?.bite_scan_1_name}
                      createdAt={scans?.bite_scan_1_created_at}
                      updatedAt={scans?.bite_scan_1_updated_at}
                      disabled={deleteLoading.bite_scan_1 || !scans?.bite_scan_1_id}
                      handleDelete={() => handleClickOpen(scans?.bite_scan_1_id, 'bite_scan_1')}
                      oemReplaced={(appointmentType?.procedure == 'First Visit' || appointmentType?.procedure == 'Walk-in') ? substituteScan.bite_scan_1 : null}
                      handleOemReplaced={() => handleChangeSubstitutionScan('bite_scan_1')}
                    />
                    <ScansDropZone
                      uploadProgress={uploadProgress?.bite_scan_2}
                      disable={disable || loading.disabled}
                      value={scans?.bite_scan_2}
                      id={"bite_scan_2"}
                      handleChange={handleChange}
                      message='Bite scan 2'
                      name={scans?.bite_scan_2_name}
                      createdAt={scans?.bite_scan_2_created_at}
                      updatedAt={scans?.bite_scan_2_updated_at}
                      disabled={deleteLoading.bite_scan_2 || !scans?.bite_scan_2_id}
                      handleDelete={() => handleClickOpen(scans?.bite_scan_2_id, 'bite_scan_2')}
                      oemReplaced={(appointmentType?.procedure == 'First Visit' || appointmentType?.procedure == 'Walk-in') ? substituteScan.bite_scan_2 : null}
                      handleOemReplaced={() => handleChangeSubstitutionScan('bite_scan_2')}
                    />
                  </Box>
                </>
              }
              <br />
              <FormControl sx={{ marginBottom: 2, width: 'fit-content' }} size='small'>
                <Typography className={classes.title}>Segmentation Technician</Typography>
                <Select
                  value={imageKey?.segmentation_technician_id || scans?.segmentation_technician_id}
                  onChange={(e) => handleChangeSegmentationTechnician('segmentation_technician_id', e.target.value)}
                  displayEmpty
                >
                  {segmentationTechnicians.map((option, key) => (
                    <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
                {scans?.segmentation_date && <Typography className={classes.date}>Segmentation Date: {moment(scans.segmentation_date).format(dateFormat)}</Typography>}
              </FormControl>
              <br />
              <ApprovalActions
                disabled={iconScan == 0 || loading.disableReviewBtn || scans?.review_status != null}
                reviewStatus={scans?.review_status}
                reviewer={scans?.reviewer}
                reviewedAt={scans?.reviewed_at}
                handleChangeReview={handleChangeReview}
                withApprovedWithIssuesOption={true}
              />
              <br />
              {iconScan != 0 && scans?.owner_name && <Typography className={classes.size11}><span className={classes.reviewBoldTxt}>Uploaded By:</span> <span>{scans?.owner_name}</span></Typography>}
              <br />
              <Box ref={substituteFiles} className={classes.box}>
                {
                  substituteScan.document &&
                  <ScansDropZone
                    uploadProgress={uploadProgress?.substitute_document}
                    disable={disable || loading.disabled}
                    value={scans?.substitute_document}
                    id={"substitute_document"}
                    handleChange={handleChange}
                    message='Upload upper scan'
                    name={scans?.substitute_document_name}
                    createdAt={scans?.substitute_document_created_at}
                    updatedAt={scans?.substitute_document_updated_at}
                    disabled={deleteLoading.substitute_document || !scans?.substitute_document_id}
                    handleDelete={() => handleClickOpen(scans?.substitute_document_id, 'substitute_document')}
                  />
                }
                {
                  substituteScan.lower_scan &&
                  <ScansDropZone
                    uploadProgress={uploadProgress?.substitute_lower_scan}
                    disable={disable || loading.disabled}
                    value={scans?.substitute_lower_scan}
                    id={"substitute_lower_scan"}
                    handleChange={handleChange}
                    message='Upload lower scan'
                    name={scans?.substitute_lower_scan_name}
                    createdAt={scans?.substitute_lower_scan_created_at}
                    updatedAt={scans?.substitute_lower_scan_updated_at}
                    scanFileId={scans?.lower_scan_id}
                    disabled={deleteLoading.substitute_lower_scan || !scans?.substitute_lower_scan_id}
                    handleDelete={() => handleClickOpen(scans?.substitute_lower_scan_id, 'substitute_lower_scan')}
                  />
                }
                {
                  substituteScan.bite_scan_1 &&
                  <ScansDropZone
                    uploadProgress={uploadProgress?.substitute_bite_scan_1}
                    disable={disable || loading.disabled}
                    value={scans?.substitute_bite_scan_1}
                    id={"substitute_bite_scan_1"}
                    handleChange={handleChange}
                    message='Bite scan 1'
                    name={scans?.substitute_bite_scan_1_name}
                    createdAt={scans?.substitute_bite_scan_1_created_at}
                    updatedAt={scans?.substitute_bite_scan_1_updated_at}
                    disabled={deleteLoading.substitute_bite_scan_1 || !scans?.substitute_bite_scan_1_id}
                    handleDelete={() => handleClickOpen(scans?.substitute_bite_scan_1_id, 'substitute_bite_scan_1')}
                  />
                }
                {
                  substituteScan.bite_scan_2 &&
                  <ScansDropZone
                    uploadProgress={uploadProgress?.substitute_bite_scan_2}
                    disable={disable || loading.disabled}
                    value={scans?.substitute_bite_scan_2}
                    id={"substitute_bite_scan_2"}
                    handleChange={handleChange}
                    message='Bite scan 2'
                    name={scans?.substitute_bite_scan_2_name}
                    createdAt={scans?.substitute_bite_scan_2_created_at}
                    updatedAt={scans?.substitute_bite_scan_2_updated_at}
                    disabled={deleteLoading.substitute_bite_scan_2 || !scans?.substitute_bite_scan_2_id}
                    handleDelete={() => handleClickOpen(scans?.substitute_bite_scan_2_id, 'substitute_bite_scan_2')}
                  />
                }
                {
                  substituteScan.scans_folder &&
                  <ScansDropZone
                    uploadProgress={uploadProgress?.substitute_scans_folder}
                    disable={disable || loading.disabled}
                    value={scans?.substitute_scans_folder}
                    id='substitute_scans_folder'
                    handleChange={handleFolderUpload}
                    message='Scans Folder'
                    name={scans?.substitute_scans_folder_name}
                    createdAt={scans?.substitute_scans_folder_created_at}
                    updatedAt={scans?.substitute_scans_folder_updated_at}
                    disabled={deleteLoading.substitute_scans_folder || !scans?.substitute_scans_folder_id}
                    handleDelete={() => handleClickOpen(scans?.substitute_scans_folder_id, 'substitute_scans_folder')}
                    folderUpload
                  />
                }
              </Box>
              <br />
              {
                (substituteScan.document || substituteScan.lower_scan || substituteScan.bite_scan_1 || substituteScan.bite_scan_2 || substituteScan.scans_folder) &&
                <FormControl sx={{ marginBottom: 2, width: 'fit-content' }} size='small'>
                  <Typography className={classes.title}>Segmentation Technician</Typography>
                  <Select
                    value={imageKey?.substitute_segmentation_technician_id || scans?.substitute_segmentation_technician_id}
                    onChange={(e) => handleChangeSegmentationTechnician('substitute_segmentation_technician_id', e.target.value)}
                    displayEmpty
                  >
                    {segmentationTechnicians.map((option, key) => (
                      <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                  {scans?.substitute_segmentation_date && <Typography className={classes.date}>Segmentation Date: {moment(scans.substitute_segmentation_date).format(dateFormat)}</Typography>}
                </FormControl>
              }
              <br />
              {scans?.substitute_owner_name && <Typography className={classes.size11}><span className={classes.reviewBoldTxt}>Uploaded By:</span> <span>{scans.substitute_owner_name}</span></Typography>}
              <Save uploadedBy={''} handleSave={handleSave} success={loading.success} errorMessage={errorMsg} />
              </>
            }
        </Box>
      )}
      {reviewData.modalOpened && (
        <DisapprovalModal
          activeModal={reviewData.modalOpened}
          handleChangeModal={handleChangeModal}
          title={reviewData.status === ReviewStatus.approvedWithIssues ? 'Approve With Issues' : 'Disapprove Scans'}
          dropDownTitle='Scan Issues'
          handleSubmitDisapprove={() => handleReviewStatus(reviewData.status)}
          loading={reviewData.loading}
          commentValue={reviewData.comment}
          handleChangeComment={handleChangeComment}
          commentError={reviewData.commentError}
          setFilesImage={setFileImages}
          filesImage={fileImages}
          images={images}
          setImages={setImages}
          imagesKey={imageKeys}
          setImageKey={setImageKeys}
          issueType={reviewData.status == ReviewStatus.approvedWithIssues ? IssueTypes.scansApprovedWithIssues : IssueTypes.scansNotApproved}
          setIssues={setScanIssues}
          issues={scanIssues}
          options={options}
          setOptions={setOptions}
          showTpDropdown={reviewData.status === ReviewStatus.approvedWithIssues}
          setTpOption={setTpOption}
          tpOptionError={reviewData.tpOptionError}
        />
      )}
      <AlertDeleteDialog open={open} setOpen={setOpen} handleAction={handleDelete}/>
    </ProcedureHeader>
  );
}
