import { Grid, List } from '@material-ui/core';
import React, { useState } from 'react'
import { useStyles } from './CaseDiscussionDashboardMessagesStyles';
import CaseDiscussionMessageV2 from '../CaseDiscussionMessageV2';
import { VscMention } from "react-icons/vsc";



function CaseDiscussionDashboardMessages({ messages, lastVisibleItem, setNewMessage, admins, handleLoadMore, adminId, chatBottom, chatTop, setPreviewImages, getLastMention, mentionAvailable }) {
  const classes = useStyles();
  const [hoverId, setHoverId] = useState("")
  const handleScrollToOriginal = (id) => {
    let originalMessage = document.querySelector(`#${id}`);
    if (!originalMessage) {
      return;
    }
    originalMessage.scrollIntoView({ 'behaviour': "smooth" });
    originalMessage.style.backgroundColor = "#f2f2f2";
    originalMessage.style.transition = "all 0.5s ease-out";

    setTimeout(() => {
      originalMessage.style.backgroundColor = "white";
    }, 700)
  }
  const replyClicked = (message, userChat,textIndex,videoIndex,fileIndex) => {
    setNewMessage((prev) => {
      return {
        ...prev, reply_to: message.id,
        reply: {
          title: userChat.title,
          type: message.type,
          data: textIndex >= 0 ? message.data[textIndex].data : fileIndex>=0 ? "Attachment" : videoIndex>=0 ? "video" : "photo",
          replied_to: message.admin_id,
          photoURL: null
        }
      }
    })
  }
  const attachmentName= (data)=>{
    let str = (process.env.REACT_APP_AWS_URL+data).split('\\').pop().split('/').pop();
    if(str.length>25){
      return str.substring(0,25)+"..."
    }else{ 
      return str;
    }
  }
  return (
    <Grid item xs={12} className={classes.messagesRow} id="chatbox" style={{position:"relative"}}>
      <div ref={chatTop} />
      {lastVisibleItem ? <u style={{ margin: "auto", display: "block", cursor: "pointer", width: "fit-content" }} onClick={handleLoadMore}>Load More</u> : null}
      {messages && (
        <List>
          {messages.map((m, key) => 
            <CaseDiscussionMessageV2 message={m} hoverId={hoverId} setHoverId={setHoverId} adminId={adminId} admins={admins} setPreviewImages={setPreviewImages}
             handleScrollToOriginal={handleScrollToOriginal} replyClicked={replyClicked} attachmentName={attachmentName} type='dashboard'/>
          )}
        </List>
      )}
      <div ref={chatBottom} />
      <button className={classes.mentionButton} onClick={getLastMention} disabled={!mentionAvailable}> <VscMention size={30}/> </button>
    </Grid>

  )
}

export default CaseDiscussionDashboardMessages