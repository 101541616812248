import React, { useState, useEffect, useCallback } from "react";
import {
  Page,
  Card,
  TextStyle,
  Checkbox,
  Image,
  DisplayText,
  DataTable,
  Button,
} from "@shopify/polaris";
import Cookies from "js-cookie";
import axios from "../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emptyIcon from "../img/emptyList.svg";
import "@zendeskgarden/react-pagination/dist/styles.css";
import { Pagination } from "@zendeskgarden/react-pagination";

function ApprovedTps() {
  const [loading, setLoading] = useState({
    singleRow: [],
    bulk: false,
  });
  const [tpItems, setTpItems] = useState([]);
  const [selectedTps, setSelectedTps] = useState([]);
  const [tpCurrentPage, setTpCurrentPage] = useState(1);
  const [tpTotalPages, setTpTotalPages] = useState(0);

  const emptyTpState = tpItems.length == 0 ? (
    <div className="message-no-result"
    >
      <div className="message-no-result-img">
        <Image src={emptyIcon}></Image>{" "}
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : null;

  const handleSelectedAllTps = () => {
    if(selectedTps.length == tpItems.length) {
      setSelectedTps([]);
    } else {
      setSelectedTps(tpItems.map(tp => tp.id));
    }
  }

  function handleTpPageChange(currentPage) {
    setTpCurrentPage(currentPage);
  }

  const handleSelectedTpsChange = (planId) => {
    let found = selectedTps.find(tp => tp == planId);
    if(found) {
      setSelectedTps(prev => {
        return prev.filter(tp => tp != planId);
      })
    } else {
      setSelectedTps(prev => [...prev, planId]);
    }
  }

  const activateTp = (planIds = []) => {
    let ids = planIds.length ? planIds : selectedTps;
    if(planIds.length) {
      let index = tpItems.findIndex(tp => tp.id == ids[0]);
      let loadingItems = loading.singleRow;
      loadingItems[index] = true;
      setLoading(prev => ({
        ...prev,
        singleRow: loadingItems,
      }));
    } else {
      setLoading(prev => ({
        ...prev,
        bulk: true,
      }));
    }
    let body = {
      plan_ids: ids,
    };
    axios.post(`admin/v1/smile_plan/admin/activate`, body, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((result) => {
      if(result.data.success) {
        toast.success(ids.length > 1 ? 'TPs activated' : 'Tp activated', 3000);
        getApprovedTps();
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const getApprovedTps = () => {
    let curentPage = (selectedTps.length > 1 && tpCurrentPage > 1) ? tpCurrentPage - 1 : tpCurrentPage;
    handleTpPageChange(curentPage);
    axios.get(`admin/v1/approved-tps?page=${curentPage}&paginate=10`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((result) => {
      let tps = result.data.tps.data;
      setTpItems(tps);
      setLoading(prev => ({
        ...prev,
        bulk: false,
        singleRow: Array(tps.length).fill(false),
      }));
      if(selectedTps.length) {
        setSelectedTps([]);
      }
      setTpTotalPages(result.data.tps.last_page);
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    getApprovedTps();
  }, [tpCurrentPage])

  return (
    <Page
      fullWidth
      title="TPs to be activated"
      breadcrumbs={[{ content: "List Of Reports", url: "/admin/reports" }]}
    >
      <ToastContainer/>
      <div className="approved-tps-wrapper">
        <Card>
          <DataTable
            columnContentTypes={["text", "text"]}
            headings={[
              <TextStyle variation="strong">
                <Checkbox
                  disabled={!tpItems.length}
                  checked={tpItems.length && selectedTps.length == tpItems.length}
                  onChange={handleSelectedAllTps}
                />
              </TextStyle>,
              <TextStyle variation="strong">ID</TextStyle>,
              <TextStyle variation="strong">Full Name</TextStyle>,
              <TextStyle variation="strong">TP Name</TextStyle>,
              <TextStyle variation="strong">
                <Button loading={loading.bulk} disabled={!tpItems.length || !selectedTps.length} onClick={activateTp}>
                  Activate
                </Button>
              </TextStyle>,
            ]}
            rows={tpItems.map((item, key) => {
              return (
                [
                  <div id={String(item.id)}>
                    <Checkbox
                      checked={selectedTps.find(tp => tp == item.id)}
                      onChange={() => handleSelectedTpsChange(item.id)}
                    />
                  </div>,
                  <div id={String(item.id)}>
                    <p>{item.user_id}</p>
                  </div>,
                  <div id={String(item.id)}>
                    <p>{item.user?.full_name}</p>
                  </div>,
                  <div id={String(item.id)}>
                    <p>{item.name}</p>
                  </div>,
                  <div id={String(item.id)}>
                    <Button loading={loading.singleRow[key]} disabled={loading.singleRow[key]} onClick={() => activateTp([item.id])}>
                      Activate
                    </Button>
                  </div>,
                ]
              )
            })}
            sortable={[false, false, false, false, false]}
            defaultSortDirection="descending"
            verticalAlign="center"
          />
          {tpTotalPages >= 2 && (
            <div id="pagination" style={{ marginTop: 20 }}>
              <Pagination
                totalPages={tpTotalPages}
                pagePadding={3}
                currentPage={tpCurrentPage}
                onChange={handleTpPageChange}
              />
            </div>
          )}
          {emptyTpState}
        </Card>
      </div>
    </Page>
  );
}

export default ApprovedTps;
