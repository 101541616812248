import AppointmentProcedureFiles from "./AppointmentProcedureFiles";

const AppointmentProcedures = ({
  procedure,
  handleTabsExpand,
  userId,
  appointmentId,
  appointmentNumber,
  openCloseTabs,
  setDisable,
  disable,
  index,
  scrollToExpandedTab,
}) => {

  if (procedure.required_files.length === 0) {
    return <></>;
  }

  return (
    procedure.required_files.map((caseRecord, idx) => (
      <AppointmentProcedureFiles
        key={idx}
        caseRecord={caseRecord}
        procedure={procedure}
        index={index}
        handleTabsExpand={handleTabsExpand}
        appointmentId={appointmentId}
        appointmentNumber={appointmentNumber}
        openCloseTabs={openCloseTabs}
        setDisable={setDisable}
        disable={disable}
        userId={userId}
        scrollToExpandedTab={scrollToExpandedTab}
      />
    ))
  );
};

export default AppointmentProcedures;
