import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  AppProvider,
  Card,
  FormLayout,
  Button,
  Page,
  InlineError,
  DisplayText,
  Image,
  TextField,
  Link,
  Toast,
  Frame,
  TextStyle,
} from "@shopify/polaris";
import axios from "./axios.js";
import { Redirect, withRouter, Router, Switch, Route } from "react-router-dom";
import history from "./history";
import Helmet from "react-helmet";
import Cookie from "js-cookie";
import logo from "./img/basmalogo.svg";
import Mailto from "react-protected-mailto";

function ForgotPassword() {
  const [emailFieldValue, setEmailFieldValue] = useState("");
  const [tokenValue, setTokenValue] = useState(
    "kdiLK1BNYujbrg7NTg5bEUckWVWwG5fiCxXC21h41j2P6DlHUUts6xGiZIXJ"
  );
  const [fieldRequiredE, setFieldRequiredE] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [isSuccess, setIsSuccess] = useState(false);

  const handleEmailFieldChange = useCallback((value) => {
    setEmailFieldValue(value);
    //value && setIsDirty(true);
  }, []);

  function handleSend(e) {
    if (
      emailFieldValue != null &&
      !emailFieldValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    ) {
      setFieldRequiredE(
        <InlineError message="Invalid email" fieldID="myFieldID" />
      );
    } else {
      const faqObject = {
        email: emailFieldValue,
      };
      axios
        .post("/admin/v1/password/create", faqObject, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setIsSuccess(true);
        })
        .catch((err) => console.log(err));
    }
  }

  const pageContent = isSuccess ? (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "40%",
        transform: "translate(-50%, -50%)",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <div style={{ minWidth: 400, marginTop: 20 }}>
        <div style={{ marginBottom: 20 }}>
          <FormLayout>
            <DisplayText size="large">
              Your password reset email has been sent
            </DisplayText>
            <TextStyle>
              A reset password link has been generated and will be sent to you
              via email. You can then follow that link and select a new
              password.
            </TextStyle>
          </FormLayout>
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "40%",
        transform: "translate(-50%, -50%)",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <div style={{ minWidth: 400, marginTop: 20 }}>
        <div style={{ marginBottom: 20 }}>
          <FormLayout>
            <DisplayText size="large">Forgot Password?</DisplayText>
            <TextStyle>We'll email you a link to reset it</TextStyle>
          </FormLayout>
        </div>

        <Card sectioned>
          <FormLayout>
            <div>
              <TextField
                type="email"
                label="Email"
                value={emailFieldValue}
                onChange={handleEmailFieldChange}
              />
            </div>

            <Button fullWidth primary onClick={handleSend}>
              Send
            </Button>
            {fieldRequiredE}
          </FormLayout>
        </Card>
      </div>
    </div>
  );

  return <AppProvider>{pageContent}</AppProvider>;
}
export default ForgotPassword;
