import React, { useCallback, useState } from "react";
import {
  InlineError,
  Page,
  FormLayout,
  TextField,
  Card,
  Modal,
  PageActions,
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";
import history from "../history";

function Add() {
  const [nameEnValue, setNameEnValue] = useState("");
  const [nameArValue, setNameArValue] = useState("");
  const [codeValue, setCodeValue] = useState("");
  const [popupActive, setPopupActive] = useState(false);
  const [nameError, setNameError] = useState("");
  const handleChangeNameEn = useCallback((newValue) => {
    setNameEnValue(newValue);
  }, []);
  const handleChangeNameAr = useCallback((newValue) => {
    setNameArValue(newValue);
  }, []);
  const handleCode = useCallback((newValue) => {
    setCodeValue(newValue);
  }, []);

  return (
    <Page
      title="Add Country"
      breadcrumbs={[{ url: "/admin/book-a-scan-countries" }]}
    >
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              value={nameEnValue}
              onChange={handleChangeNameEn}
              label="Name En"
              align="left"
            />
            <TextField
              value={nameArValue}
              onChange={handleChangeNameAr}
              label="Name Ar"
              align="right"
            />
          </FormLayout.Group>
          {nameError}
          <FormLayout.Group>
            <TextField
              value={codeValue}
              onChange={handleCode}
              label="Code"
              align="left"
            />
          </FormLayout.Group>
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );

  function handleSave() {
    if (!nameArValue && !nameEnValue) {
      setNameError(
        <InlineError message="Please enter a name" fieldID="myFieldID" />
      );
    } else {
      setPopupActive(true);
      const bodyObj = {
        name: {
          ...(nameEnValue && { en: nameEnValue }),
          ...(nameArValue && { ar: nameArValue }),
        },
        ...(codeValue && { code: codeValue }),
      };
      axios
        .post("/admin/v1/countries", bodyObj, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          history.push("/admin/book-a-scan-countries");
        })
        .catch((err) => console.log(""));
    }
  }
}
export default Add;
