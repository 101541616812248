import { Menu, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useStyles } from './NotificationBoxstyles'
import { ArrowForwardIos, FiberManualRecord, KeyboardArrowDown } from '@mui/icons-material'
import moment from 'moment-timezone'
import { updateDoc, writeBatch } from 'firebase/firestore'
import { db } from '../../firebase'

function NotificationBox({expandNotification, handleCloseNotificationTab, notifications, handleViewAll, handleLoadMore, ClearAll, ClearNotification}) {
let defaultNumberOfNotifications = 3;
const classes = useStyles()

const[sizenotifactions,setSize]=useState(defaultNumberOfNotifications)
  useEffect(()=>{
    if(expandNotification.full){
      setSize(notifications.length);
    }
    else{
      setSize(defaultNumberOfNotifications);
    }
  },[expandNotification,notifications])
  return (
    <Menu
    aria-labelledby="demo-positioned-button1"
    anchorEl={expandNotification.anchor}
    open={expandNotification.status}
    onClose={handleCloseNotificationTab}
    className={classes.mainMenu}
    getContentAnchorEl={null}
    anchorOrigin={{ vertical: 35, horizontal: "center" }}
    transformOrigin={{ vertical: "top", horizontal: "right" }}
    
    >
        <div className={classes.notiticationTabHeaderBox}>
        <div className={classes.notiticationTabHeader}>
          <Typography variant='h4' className={`${classes.pending} ${classes.fontFamilyEina}`} display='inline'>{notifications.filter(item => !item.read).length} Pending Notification</Typography>
          <Typography variant='h4' className={`${classes.clear} ${classes.fontFamilyEina}`} display='inline' onClick={ClearAll}>Clear All</Typography>
          <Typography variant='h4' className={`${classes.view} ${classes.fontFamilyEina}`} display='inline' onClick={handleViewAll}>View All { expandNotification.full ?(<KeyboardArrowDown/>):(<ArrowForwardIos />)}</Typography>
        </div>
      </div>
      <div className={classes.notificationsParent}  id={expandNotification.full ? "opened" :""}>
        {notifications.length>0 &&notifications.map((notification,key)=>{
          return key < sizenotifactions &&(
              <div key={key} className={classes.notification} onClick={(e) => {
                  let openChat = '';
                  if(notification.type == 'tag' || notification.type == 'reply'){
                    openChat = '?openChat=true';
                  }
                  ClearNotification(notification.ref)
                  handleCloseNotificationTab();
                  if(e.ctrlKey){
                    window.open(notification.path+openChat)
                  }else{
                    window.open(notification.path+openChat,"_self");
                  }
              }}>
        <div className={classes.notificationIcon}>
            {notification.type=="tag"?<img src='/tagged.svg'/>
            : notification.type=="reply"? <img src='/replied.svg'/>
            : <img src='/replied.svg'/>}
            </div>
          <div className={classes.notificationBody}>
            <Typography variant='h4' gutterBottom className={`${classes.title} ${classes.fontFamilyEina}`}> {!notification.read &&<FiberManualRecord className={classes.redDot}/>} {notification.title}</Typography>
            <Typography variant='h4' className={`${classes.description} ${classes.fontFamilyEina}`}>{notification.body}</Typography>
            <Typography variant='h4' className={`${classes.descriptionTime} ${classes.fontFamilyEina}`}gutterBottom>{moment(notification.date.toDate()).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("ddd, MMM DD [at] hh:mm A")}</Typography>
            <Typography variant='h4' className={`${classes.timeAgo} ${classes.fontFamilyEina}`} >  {moment(notification?.date.toDate()).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).fromNow()}</Typography>
            </div>
        </div>
          )
        })}
       
        {expandNotification.full ? <u style={{ margin: "auto", display: "block", cursor: "pointer", width: "fit-content" }} onClick={handleLoadMore}>Load More</u> : <></>}
      </div>
    </Menu>
  )
}

export default NotificationBox