import { initializeApp } from "firebase/app";
import {  CACHE_SIZE_UNLIMITED, getFirestore, persistentLocalCache, persistentMultipleTabManager,memoryLocalCache  } from "firebase/firestore";
import { getStorage  } from "firebase/storage";
import { getMessaging  } from "firebase/messaging";
import { getAuth  } from "firebase/auth";
const firebaseConfig = {
  apiKey:  process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig,{localCache: persistentLocalCache(/*settings*/{tabManager: persistentMultipleTabManager()}),cacheSizeBytes: CACHE_SIZE_UNLIMITED})
const db = getFirestore(app)
// enableMultiTabIndexedDbPersistence(db);
const storage = getStorage(app)
const messaging = {}
const auth = getAuth(app);

export {db, storage,messaging, auth}