import React, { useCallback, useState, useEffect } from "react";
import {
  Page,
  TextField,
  DropZone,
  Caption,
  Thumbnail,
  Stack,
  PageActions,
  FormLayout,
  Toast,
} from "@shopify/polaris";
import { Router, useParams } from "react-router-dom";
import history from "./history";
import { Editor } from "@tinymce/tinymce-react";
import {
  TextStyle,
  Modal,
} from "@shopify/polaris";
import axios from "./axios";
import NavigationLayout from "./components/NavigationLayout";
import Cookies from "js-cookie"
import Select from "react-dropdown-select";
import Select2 from "react-dropdown-select";

function Blog() {
  const { id } = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const toggleActive = useCallback(() => setErrorMessage(''), []);
  const [blogDescriptionvalue, setBlogDescriptionValue] = useState({en: '', ar: ''});
  const [blogTitle, setBlogTitle] = useState({en: '', ar:''});
  const [blogSeoTitle, setBlogSeoTitle] = useState({en: '', ar:''});
  const [blogSeoMetaDesc, setBlogSeoMetaDesc] = useState({en: '', ar:''});
  const [videoUrl, setVideoUrl] = useState({en: '', ar:''});
  const [blogPostAlt, setBlogPostAlt] = useState({en: '', ar:''});
  const [blogImageAlt1, setBlogImageAlt1] = useState({ind: 0, en: '', ar:'', imgKey: ''});
  const [blogImageAlt2, setBlogImageAlt2] = useState({ind: 1, en: '', ar:'', imgKey: ''});
  const [blogImageAlt3, setBlogImageAlt3] = useState({ind: 2, en: '', ar:'', imgKey: ''});
  const [blogImageAlt4, setBlogImageAlt4] = useState({ind: 3, en: '', ar:'', imgKey: ''});
  const [blogImageAlt5, setBlogImageAlt5] = useState({ind: 4, en: '', ar:'', imgKey: ''});
  const [blogImageAlt6, setBlogImageAlt6] = useState({ind: 5, en: '', ar:'', imgKey: ''});
  const [blogPostUrl, setBlogPostUrl] = useState('');
  const [popupActive, setPopupActive] = useState(false);
  const [filesImage, setFilesImage] = useState([]);
  const [filesImage1, setFilesImage1] = useState([]);
  const [filesImage2, setFilesImage2] = useState([]);
  const [filesImage3, setFilesImage3] = useState([]);
  const [filesImage4, setFilesImage4] = useState([]);
  const [filesImage5, setFilesImage5] = useState([]);
  const [filesImage6, setFilesImage6] = useState([]);
  const [imageKeyImage, setImageKeyImage] = useState("");
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [imagePreviewImage, setImagePreviewImage] = useState();
  const [imagePreviewImage1, setImagePreviewImage1] = useState();
  const [imagePreviewImage2, setImagePreviewImage2] = useState();
  const [imagePreviewImage3, setImagePreviewImage3] = useState();
  const [imagePreviewImage4, setImagePreviewImage4] = useState();
  const [imagePreviewImage5, setImagePreviewImage5] = useState();
  const [imagePreviewImage6, setImagePreviewImage6] = useState();
  const [selectedTopics, setSelectedTopics] = useState([])
  const [topicOptions, setTopicOptions] = useState([]);
  const optionsType = [
    { label: "Active", value: 0 },
    { label: "Draft", value: 1 },
  ];
  const [valuePublishDraft, setPublishDraft] = useState([{value: 0, label: 'Active'}]);
  let imageType = "";
  const httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

  function handleSelectBlogTopics(newSelectedTopic) {
    setSelectedTopics(newSelectedTopic);
  }

  const handlePublishDraft = useCallback((value) => setPublishDraft(value));

  const handleDropZoneImage = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesImage((filesImage) => [...filesImage, ...acceptedFiles]),
    []
);
const fileUploadImage = !filesImage.length && <DropZone.FileUpload />;
let uploadedFilesImage = filesImage.length > 0 && (
  <Stack alignment="center">
    <Thumbnail
      size="small"
      alt={filesImage[filesImage.length - 1].name}
      source={
        validImageTypes.indexOf(filesImage[filesImage.length - 1].type) > 0
          ? window.URL.createObjectURL(filesImage[filesImage.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      }
    />
    <div>
      {filesImage[filesImage.length - 1].name}{" "}
      <Caption>{filesImage[filesImage.length - 1].type} bytes</Caption>
    </div>
  </Stack>
);

const sendImage = useCallback(
  (filesImage) => {
    setPopupActive(true);
    setImagePreviewImage(validImageTypes.indexOf(filesImage[filesImage.length - 1].type) > 0 ? 
      window.URL.createObjectURL(filesImage[filesImage.length - 1])
      : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
    imageType = filesImage[filesImage.length - 1].type;
    let headersVar = null;
    let url = "";
    const form_data = new FormData();
    form_data.append("content_type", imageType);
    axios
      .post("/admin/v1/images/s3", form_data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        setImageKeyImage(res.data.key);
        url = res.data.url;
        headersVar = res.data.headers;
        axios
          .put(url, filesImage[filesImage.length - 1], {
            headers: {
              "x-amz-acl": "public-read-write",
              "Content-Type": imageType,
            },
          })
          .then((res) => {
            setPopupActive(false);
          })
          .catch((err) => setPopupActive(false));
      })
      .catch((err) => setPopupActive(false));
    },
    [filesImage]
  );

  const handleDropZoneImage1 = useCallback(
      (_dropFiles, acceptedFiles, _rejectedFiles) =>
        setFilesImage1((filesImage1) => [...filesImage1, ...acceptedFiles]),
      []
  );
  const fileUploadImage1 = !filesImage1.length && <DropZone.FileUpload />;
  let uploadedFilesImage1 = filesImage1.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesImage1[filesImage1.length - 1].name}
        source={
          validImageTypes.indexOf(filesImage1[filesImage1.length - 1].type) > 0
            ? window.URL.createObjectURL(filesImage1[filesImage1.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesImage1[filesImage1.length - 1].name}{" "}
        <Caption>{filesImage1[filesImage1.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );

  const sendImage1 = useCallback(
    (filesImage1) => {
      setPopupActive(true);
      setImagePreviewImage1(validImageTypes.indexOf(filesImage1[filesImage1.length - 1].type) > 0 ? 
        window.URL.createObjectURL(filesImage1[filesImage1.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesImage1[filesImage1.length - 1].type;
      let headersVar = null;
      let url2 = "";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        })
        .then((res) => {
          setBlogImageAlt1( prev => {return {...prev, imgKey: res.data.key}});
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesImage1[filesImage1.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesImage1]
  );

  const handleDropZoneImage2 = useCallback(
      (_dropFiles, acceptedFiles, _rejectedFiles) =>
        setFilesImage2((filesImage2) => [...filesImage2, ...acceptedFiles]),
      []
  );
  const fileUploadImage2 = !filesImage2.length && <DropZone.FileUpload />;
  let uploadedFilesImage2 = filesImage2.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesImage2[filesImage2.length - 1].name}
        source={
          validImageTypes.indexOf(filesImage2[filesImage2.length - 1].type) > 0
            ? window.URL.createObjectURL(filesImage2[filesImage2.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesImage2[filesImage2.length - 1].name}{" "}
        <Caption>{filesImage2[filesImage2.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );

  const sendImage2 = useCallback(
    (filesImage2) => {
      setPopupActive(true);
      setImagePreviewImage2(validImageTypes.indexOf(filesImage2[filesImage2.length - 1].type) > 0 ? 
        window.URL.createObjectURL(filesImage2[filesImage2.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesImage2[filesImage2.length - 1].type;
      let headersVar = null;
      let url = "";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        })
        .then((res) => {
          setBlogImageAlt2( prev => {return {...prev, imgKey: res.data.key}});
          url = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url, filesImage2[filesImage2.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesImage2]
  );

  const handleDropZoneImage3 = useCallback(
      (_dropFiles, acceptedFiles, _rejectedFiles) =>
        setFilesImage3((filesImage3) => [...filesImage3, ...acceptedFiles]),
      []
  );
  const fileUploadImage3 = !filesImage3.length && <DropZone.FileUpload />;
  let uploadedFilesImage3 = filesImage3.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesImage3[filesImage3.length - 1].name}
        source={
          validImageTypes.indexOf(filesImage3[filesImage3.length - 1].type) > 0
            ? window.URL.createObjectURL(filesImage3[filesImage3.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesImage3[filesImage3.length - 1].name}{" "}
        <Caption>{filesImage3[filesImage3.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );

  const sendImage3 = useCallback(
    (filesImage3) => {
      setPopupActive(true);
      setImagePreviewImage3(validImageTypes.indexOf(filesImage3[filesImage3.length - 1].type) > 0 ? 
        window.URL.createObjectURL(filesImage3[filesImage3.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesImage3[filesImage3.length - 1].type;
      let headersVar = null;
      let url = "";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        })
        .then((res) => {
          setBlogImageAlt3( prev => {return {...prev, imgKey: res.data.key}});
          url = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url, filesImage3[filesImage3.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesImage3]
  );

  const handleDropZoneImage4 = useCallback(
      (_dropFiles, acceptedFiles, _rejectedFiles) =>
        setFilesImage4((filesImage4) => [...filesImage4, ...acceptedFiles]),
      []
  );
  const fileUploadImage4 = !filesImage4.length && <DropZone.FileUpload />;
  let uploadedFilesImage4 = filesImage4.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesImage4[filesImage4.length - 1].name}
        source={
          validImageTypes.indexOf(filesImage4[filesImage4.length - 1].type) > 0
            ? window.URL.createObjectURL(filesImage4[filesImage4.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesImage4[filesImage4.length - 1].name}{" "}
        <Caption>{filesImage4[filesImage4.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );

  const sendImage4 = useCallback(
    (filesImage4) => {
      setPopupActive(true);
      setImagePreviewImage4(validImageTypes.indexOf(filesImage4[filesImage4.length - 1].type) > 0 ? 
        window.URL.createObjectURL(filesImage4[filesImage4.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesImage4[filesImage4.length - 1].type;
      let headersVar = null;
      let url = "";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        })
        .then((res) => {
          setBlogImageAlt4( prev => {return {...prev, imgKey: res.data.key}});
          url = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url, filesImage4[filesImage4.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesImage4]
  );

  const handleDropZoneImage5 = useCallback(
      (_dropFiles, acceptedFiles, _rejectedFiles) =>
        setFilesImage5((filesImage5) => [...filesImage5, ...acceptedFiles]),
      []
  );
  const fileUploadImage5 = !filesImage5.length && <DropZone.FileUpload />;
  let uploadedFilesImage5 = filesImage5.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesImage5[filesImage5.length - 1].name}
        source={
          validImageTypes.indexOf(filesImage5[filesImage5.length - 1].type) > 0
            ? window.URL.createObjectURL(filesImage5[filesImage5.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesImage5[filesImage5.length - 1].name}{" "}
        <Caption>{filesImage5[filesImage5.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );

  const sendImage5 = useCallback(
    (filesImage5) => {
      setPopupActive(true);
      setImagePreviewImage5(validImageTypes.indexOf(filesImage5[filesImage5.length - 1].type) > 0 ? 
        window.URL.createObjectURL(filesImage5[filesImage5.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesImage5[filesImage5.length - 1].type;
      let headersVar = null;
      let url = "";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        })
        .then((res) => {
          setBlogImageAlt5( prev => {return {...prev, imgKey: res.data.key}});
          url = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url, filesImage5[filesImage5.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesImage5]
  );

  const handleDropZoneImage6 = useCallback(
      (_dropFiles, acceptedFiles, _rejectedFiles) =>
        setFilesImage6((filesImage6) => [...filesImage6, ...acceptedFiles]),
      []
  );
  const fileUploadImage6 = !filesImage6.length && <DropZone.FileUpload />;
  let uploadedFilesImage6 = filesImage6.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesImage6[filesImage6.length - 1].name}
        source={
          validImageTypes.indexOf(filesImage6[filesImage6.length - 1].type) > 0
            ? window.URL.createObjectURL(filesImage6[filesImage6.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesImage6[filesImage6.length - 1].name}{" "}
        <Caption>{filesImage6[filesImage6.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );

  const sendImage6 = useCallback(
    (filesImage6) => {
      setPopupActive(true);
      setImagePreviewImage6(validImageTypes.indexOf(filesImage6[filesImage6.length - 1].type) > 0 ? 
        window.URL.createObjectURL(filesImage6[filesImage6.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesImage6[filesImage6.length - 1].type;
      let headersVar = null;
      let url = "";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        })
        .then((res) => {
          setBlogImageAlt6( prev => {return {...prev, imgKey: res.data.key}});
          url = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url, filesImage6[filesImage6.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesImage6]
  );

  const fetchBlogById = async () => {
    await axios.get(`admin/v1/blog/${id}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((result) => {
      let blog = result.data.blog;
      let blogTopicsArr = blog.blog_topic_relations.map(val =>  ({label: `${val.topic.name_en} - ${val.topic.name_ar}`, value: val.topic.id}));
      setSelectedTopics(blogTopicsArr);
      setBlogTitle({en: blog.title_en, ar: blog.title_ar});
      setBlogSeoTitle({en: blog.seo_title_en, ar: blog.seo_title_ar});
      setBlogSeoMetaDesc({en: blog.seo_meta_en, ar: blog.seo_meta_ar});
      setPublishDraft([{value: blog.is_draft, label: blog.is_draft ? 'Draft' : 'Active'}]);
      setVideoUrl({en: blog.video_en, ar: blog.video_ar});
      setBlogPostAlt({en: blog.main_image_alt.en, ar: blog.main_image_alt.ar});
      setBlogPostUrl(blog.main_image_url);
      setBlogDescriptionValue({en: blog.body_en, ar: blog.body_ar});
      setBlogImageAlt1(blog.images_url.find(imgObj => imgObj.ind == 0));
      setBlogImageAlt2(blog.images_url.find(imgObj => imgObj.ind == 1));
      setBlogImageAlt3(blog.images_url.find(imgObj => imgObj.ind == 2));
      setBlogImageAlt4(blog.images_url.find(imgObj => imgObj.ind == 3));
      setBlogImageAlt5(blog.images_url.find(imgObj => imgObj.ind == 4));
      setBlogImageAlt6(blog.images_url.find(imgObj => imgObj.ind == 5));
    }).catch((err) => console.log(err));
  }

  const handleSubmit = () => {
    if((videoUrl.en && !httpRegex.test(videoUrl.en)) || (videoUrl.ar && !httpRegex.test(videoUrl.ar))){
      setErrorMessage('Link format is wrong');
    } else {
      // setPopupActive(true);
      let imagesArr = [
        blogImageAlt1,
        blogImageAlt2,
        blogImageAlt3,
        blogImageAlt4,
        blogImageAlt5,
        blogImageAlt6,
      ]
      let topicsArr = selectedTopics.map(({value}) => value);
      const body = {
        is_draft: valuePublishDraft[0].value,
        main_image: imageKeyImage,
        main_image_alt: blogPostAlt,
        title_en: blogTitle.en,
        title_ar: blogTitle.ar,
        body_en: blogDescriptionvalue.en,
        body_ar: blogDescriptionvalue.ar,
        video_en: videoUrl.en,
        video_ar: videoUrl.ar,
        images: imagesArr,
        seo_title_en: blogSeoTitle.en,
        seo_title_ar: blogSeoTitle.ar,
        seo_meta_en: blogSeoMetaDesc.en,
        seo_meta_ar: blogSeoMetaDesc.ar,
        topics: topicsArr,
      };
      let url = id ? `admin/v1/blog/update/${id}` : 'admin/v1/blog/create';
      axios.post(url, body, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }).then((res) => {
        history.push('/admin/blogs');
      }).catch(err => {
        console.log(err);
      }).finally(f => {
        setPopupActive(false);
      });
    }
  }

  const handleDelete = () => {
      setPopupActive(true);
      axios.delete(`admin/v1/blog-topic/delete/${id}`, {
          headers: {
              Authorization: "Bearer " + Cookies.get("token"),
          },
      }).then((res) => {
          history.push('/admin/blogs');
      }).catch(err => {
          console.log(err)
      }).finally(f => {
          setPopupActive(false);
      });
  }

  const handleBlogBodyChange = (e, lang) => {
    if(lang == 'en'){
      setBlogDescriptionValue(prev => { return {...prev, en: e.target.getContent()}});
    } else {
      setBlogDescriptionValue(prev => { return {...prev, ar: e.target.getContent()}});
    }
  };

  const fetchTopics = async() => {
    await axios.get('admin/v1/blog-topics', {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((result) => {
      let topics = result.data.topics;
      setTopicOptions(topics.map(tp => ({label: `${tp.name_en} - ${tp.name_ar}`, value: tp.id})));
    }).catch((err) => console.log(err));
  }

  useEffect(() => {
      if(id){
        fetchBlogById();
      }
      fetchTopics();
  }, [])

  return (
      <NavigationLayout>
          <Router history={history}>
              <Page breadcrumbs={[{ content: "All faqs", url: "/admin/blogs" }]}>
                <br/>
                <div style={{display:"flex", flexDirection: 'column', alignContent:"center", gap:"20px", width:"100%"}}>
                  <div style={{width:"100%", backgroundColor:"white", borderRadius:"10px", boxShadow:"1px 1px 5px lightgray", padding:"15px"}}>
                    <TextStyle variation="strong">Blog (English)</TextStyle>
                    <div>
                      <br />
                      <TextStyle variation="strong">SEO Title</TextStyle>
                      <TextField
                        placeholder="Enter seo title"
                        type="text"
                        value={blogSeoTitle.en}
                        onChange={(value) => setBlogSeoTitle((prev) => { return {...prev, en: value}})}
                        error={''}
                      />
                      <br/>
                      <TextStyle variation="strong">SEO meta description</TextStyle>
                      <TextField
                          placeholder="Enter seo meta description"
                          type="text"
                          value={blogSeoMetaDesc.en}
                          onChange={(value) => setBlogSeoMetaDesc((prev) => { return {...prev, en: value}})}
                          error={''}
                      />
                      <br/>
                      <TextStyle variation="strong">Title</TextStyle>
                      <TextField
                          placeholder="Enter title"
                          type="text"
                          value={blogTitle.en}
                          onChange={(value) => setBlogTitle((prev) => { return {...prev, en: value}})}
                          error={''}
                      />
                      <br/>
                      <TextStyle variation="strong">Video url</TextStyle>
                      <TextField
                          placeholder="Enter video url"
                          type="text"
                          value={videoUrl.en}
                          onChange={(value) => setVideoUrl((prev) => { return {...prev, en: value}})}
                          error={''}
                      />
                      <br/>
                      <FormLayout>
                        <FormLayout.Group>
                          <FormLayout>
                            <TextStyle variation="strong">Description</TextStyle>
                            <Editor
                              apiKey="z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve"
                              value={blogDescriptionvalue.en}
                              init={{
                                branding: false,
                                height: 200,
                                menubar: false,
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help wordcount",
                                ],
                                toolbar: "undo redo | formatselect | bold italic backcolor | \ alignleft aligncenter alignright alignjustify | fontsizeselect fontselect forecolor | \link bullist numlist outdent indent | removeformat | help",
                                font_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                              }}
                              onChange={(e) => handleBlogBodyChange(e, 'en')}
                            />
                          </FormLayout>
                          </FormLayout.Group>
                        </FormLayout>
                      <br/>
                    </div>
                  </div>
                  <div style={{width:"100%", backgroundColor:"white", borderRadius:"10px", boxShadow:"1px 1px 5px lightgray", padding:"15px"}}>
                    <TextStyle variation="strong">Blog (Arabic)</TextStyle>
                    <div>
                      <br />
                      <TextStyle variation="strong">SEO Title</TextStyle>
                      <TextField
                          placeholder="Enter seo title"
                          type="text"
                          value={blogSeoTitle.ar}
                          onChange={(value) => setBlogSeoTitle((prev) => { return {...prev, ar: value}})}
                          error={''}
                      />
                      <br/>
                      <TextStyle variation="strong">SEO meta description</TextStyle>
                      <TextField
                          placeholder="Enter seo meta description"
                          type="text"
                          value={blogSeoMetaDesc.ar}
                          onChange={(value) => setBlogSeoMetaDesc((prev) => { return {...prev, ar: value}})}
                          error={''}
                      />
                      <br/>
                      <TextStyle variation="strong">Title</TextStyle>
                      <TextField
                          placeholder="Enter title"
                          type="text"
                          value={blogTitle.ar}
                          onChange={(value) => setBlogTitle((prev) => { return {...prev, ar: value}})}
                          error={''}
                      />
                      <br/>
                      <TextStyle variation="strong">Video url</TextStyle>
                      <TextField
                          placeholder="Enter video url"
                          type="text"
                          value={videoUrl.ar}
                          onChange={(value) => setVideoUrl((prev) => { return {...prev, ar: value}})}
                          error={''}
                      />
                      <br/>
                      <FormLayout>
                        <FormLayout.Group>
                          <FormLayout>
                            <TextStyle variation="strong">Description</TextStyle>
                            <Editor
                              apiKey="z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve"
                              value={blogDescriptionvalue.ar}
                              init={{
                                branding: false,
                                height: 200,
                                menubar: false,
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help wordcount",
                                ],
                                toolbar: "undo redo | formatselect | bold italic backcolor | \ alignleft aligncenter alignright alignjustify | fontsizeselect fontselect forecolor | \link bullist numlist outdent indent | removeformat | help",
                                font_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                              }}
                              onChange={(e) => handleBlogBodyChange(e, 'ar')}
                            />
                          </FormLayout>
                          </FormLayout.Group>
                        </FormLayout>
                    </div>
                  </div>
                  <div style={{width:"100%", backgroundColor:"white", borderRadius:"10px", boxShadow:"1px 1px 5px lightgray", padding:"15px"}}>
                    <TextStyle variation="strong">Blog post</TextStyle>
                    <div style={{display: 'flex', columnGap: 5}}>
                      <Thumbnail size="large" source={imagePreviewImage ? imagePreviewImage : blogPostUrl}/>
                      <div style={{flex: 1}}>
                        <DropZone onDrop={handleDropZoneImage} onDropAccepted={sendImage}>
                            {uploadedFilesImage}
                            {fileUploadImage}
                        </DropZone>
                      </div>
                    </div>
                    <br/>
                    <div style={{display: 'flex', columnGap: 10}}>
                      <div style={{flex: 1}}>
                        <TextStyle variation="strong">Blog post alt (English)</TextStyle>
                        <TextField placeholder="Enter blog post alt" type="text" onChange={(value) => setBlogPostAlt((prev) => { return {...prev, en: value}})} value={blogPostAlt.en} error={''}/>  
                      </div>
                      <div style={{flex: 1}}>
                        <TextStyle variation="strong">Blog post alt (Arabic)</TextStyle>
                        <TextField placeholder="Enter blog post alt" type="text" onChange={(value) => setBlogPostAlt((prev) => { return {...prev, ar: value}})} value={blogPostAlt.ar} error={''}/>  
                      </div>
                    </div>
                    <br/>
                    <div style={{display: 'flex', gap: 10, flexWrap: 'wrap', justifyContent: 'space-between'}}>
                      <div style={{display: 'flex', flexDirection: 'column', rowGap: 5, width: '250px'}}>
                        <Thumbnail size="large" source={imagePreviewImage1 ? imagePreviewImage1 : blogImageAlt1.imgKey}/>
                        <div className="blog-img-dropzone-wrapper">
                          <DropZone onDrop={handleDropZoneImage1} onDropAccepted={sendImage1}>
                              {uploadedFilesImage1}
                              {fileUploadImage1}
                          </DropZone>
                        </div>
                        <TextField placeholder="Enter image alt (English)" type="text" value={blogImageAlt1.en} onChange={(value) => setBlogImageAlt1(prev => {return {...prev, en: value}})} error={''} />
                        <TextField placeholder="Enter image alt (Arabic)" type="text" value={blogImageAlt1.ar} onChange={(value) => setBlogImageAlt1(prev => {return {...prev, ar: value}})} error={''} />
                      </div>
                      <div style={{display: 'flex', flexDirection: 'column', rowGap: 5, width: '250px'}}>
                        <Thumbnail size="large" source={imagePreviewImage2 ? imagePreviewImage2 : blogImageAlt2.imgKey}/>
                        <div className="blog-img-dropzone-wrapper">
                          <DropZone onDrop={handleDropZoneImage2} onDropAccepted={sendImage2}>
                              {uploadedFilesImage2}
                              {fileUploadImage2}
                          </DropZone>
                        </div>
                        <TextField placeholder="Enter image alt (English)" type="text" value={blogImageAlt2.en} onChange={(value) => setBlogImageAlt2(prev => {return {...prev, en: value}})} error={''} />
                        <TextField placeholder="Enter image alt (Arabic)" type="text" value={blogImageAlt2.ar} onChange={(value) => setBlogImageAlt2(prev => {return {...prev, ar: value}})} error={''} />
                      </div>
                      <div style={{display: 'flex', flexDirection: 'column', rowGap: 5, width: '250px'}}>
                        <Thumbnail size="large" source={imagePreviewImage3 ? imagePreviewImage3 : blogImageAlt3.imgKey}/>
                        <div className="blog-img-dropzone-wrapper">
                          <DropZone onDrop={handleDropZoneImage3} onDropAccepted={sendImage3}>
                              {uploadedFilesImage3}
                              {fileUploadImage3}
                          </DropZone>
                        </div>
                        <TextField placeholder="Enter image alt (English)" type="text" value={blogImageAlt3.en} onChange={(value) => setBlogImageAlt3(prev => {return {...prev, en: value}})} error={''} />
                        <TextField placeholder="Enter image alt (Arabic)" type="text" value={blogImageAlt3.ar} onChange={(value) => setBlogImageAlt3(prev => {return {...prev, ar: value}})} error={''} />
                      </div>
                      <div style={{display: 'flex', flexDirection: 'column', rowGap: 5, width: '250px'}}>
                        <Thumbnail size="large" source={imagePreviewImage4 ? imagePreviewImage4 : blogImageAlt4.imgKey}/>
                        <div className="blog-img-dropzone-wrapper">
                          <DropZone onDrop={handleDropZoneImage4} onDropAccepted={sendImage4}>
                              {uploadedFilesImage4}
                              {fileUploadImage4}
                          </DropZone>
                        </div>
                        <TextField placeholder="Enter image alt (English)" type="text" value={blogImageAlt4.en} onChange={(value) => setBlogImageAlt4(prev => {return {...prev, en: value}})} error={''} />
                        <TextField placeholder="Enter image alt (Arabic)" type="text" value={blogImageAlt4.ar} onChange={(value) => setBlogImageAlt4(prev => {return {...prev, ar: value}})} error={''} />
                      </div>
                      <div style={{display: 'flex', flexDirection: 'column', rowGap: 5, width: '250px'}}>
                        <Thumbnail size="large" source={imagePreviewImage5 ? imagePreviewImage5 : blogImageAlt5.imgKey}/>
                        <div className="blog-img-dropzone-wrapper">
                          <DropZone onDrop={handleDropZoneImage5} onDropAccepted={sendImage5}>
                              {uploadedFilesImage5}
                              {fileUploadImage5}
                          </DropZone>
                        </div>
                        <TextField placeholder="Enter image alt (English)" type="text" value={blogImageAlt5.en} onChange={(value) => setBlogImageAlt5(prev => {return {...prev, en: value}})} error={''} />
                        <TextField placeholder="Enter image alt (Arabic)" type="text" value={blogImageAlt5.ar} onChange={(value) => setBlogImageAlt5(prev => {return {...prev, ar: value}})} error={''} />
                      </div>
                      <div style={{display: 'flex', flexDirection: 'column', rowGap: 5, width: '250px'}}>
                        <Thumbnail size="large" source={imagePreviewImage6 ? imagePreviewImage6 : blogImageAlt6.imgKey}/>
                        <div className="blog-img-dropzone-wrapper">
                          <DropZone onDrop={handleDropZoneImage6} onDropAccepted={sendImage6}>
                              {uploadedFilesImage6}
                              {fileUploadImage6}
                          </DropZone>
                        </div>
                        <TextField placeholder="Enter image alt (English)" type="text" value={blogImageAlt6.en} onChange={(value) => setBlogImageAlt6(prev => {return {...prev, en: value}})} error={''} />
                        <TextField placeholder="Enter image alt (Arabic)" type="text" value={blogImageAlt6.ar} onChange={(value) => setBlogImageAlt6(prev => {return {...prev, ar: value}})} error={''} />
                      </div>
                    </div>
                  </div>
                </div>
                <br/>
                <div style={{width:"100%", backgroundColor:"white", borderRadius:"10px", boxShadow:"1px 1px 5px lightgray", padding:"15px"}}>
                  <TextStyle variation="strong">Status</TextStyle>
                    <Select2
                      onChange={(newValue) => handlePublishDraft(newValue)}
                      values={valuePublishDraft}
                      options={optionsType}
                    />
                    <br/>
                  <TextStyle variation="strong">Blog topic(s)</TextStyle>
                  <Select2
                    placeholder="Select blog topic(s)"
                    multi
                    options={topicOptions}
                    onChange={handleSelectBlogTopics}
                    values={selectedTopics}
                    />
                </div>
                <PageActions
                    primaryAction={{
                        content: 'Save',
                        onClick: handleSubmit,
                    }}
                    secondaryActions={{
                        destructive: true,
                        disabled: !id,
                        content: 'Delete',
                        onClick: handleDelete,
                    }}
                />
                {errorMessage && <Toast content={errorMessage} onDismiss={toggleActive} />}
                <Modal open={popupActive} loading={true}></Modal>
              </Page>
          </Router>
      </NavigationLayout>
  );
}
export default Blog;
