import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(() => ({
  table: {
    '& tr th, & tr td': {
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif !important',
      fontSize: '1em',
      textAlign: 'center',
      border: '1px solid rgba(224, 224, 224, 1)',
    },
    '& tr th, .table-second-header td, .field': {
      fontWeight: 'bold',
    },
    '& tr th': {
      backgroundColor: '#CCDBFD',
    },
    '& .table-second-header td': {
      backgroundColor: '#EDF2FB',
    },
    '& .field': {
      textAlign: 'left',
    },
    '& .field-width td': {
      maxWidth: '150px',
      wordWrap: 'break-word',
    },
  },
}));
