import { Badge, Button, Card, Modal, TextStyle } from '@shopify/polaris'
import React, { useEffect, useRef, useState } from 'react'
import NavigationLayout from '../../components/NavigationLayout';
import { MenuItem, Select, FormControl } from '@material-ui/core';
import { useParams } from "react-router-dom"
import axios from "../../axios";
import Cookies from 'js-cookie';
import TicketingWorkFlow from './TicketingWorkFlow';
import TicketingMedia from './TicketingMedia';
import TicketingComment from './TicketingComment';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { TIKCET_TYPE_ENUMS, type_options } from '../helpers';
import "./TicketingDetails.css"
import moment from 'moment';
import Select2 from "react-select";
import { GeneralFormats } from '../../util/constants';


function TicketingDetails() {
    const dateFormat = GeneralFormats.dateFormat;
    const option = useRef(null);
    const arch = useRef(null);
    const [ticket, setTicket] = useState(null)
    const [user, setUser] = useState({id:null})
    const [agents, setAgents] = useState('')
    const [comments, setComments] = useState([])
    const [statusItem, setStatusItem] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [previewImages, setPreviewImages] = useState({images:[],startIndex:0});
    const [loading, setLoading] = useState(false);
    const [dispatchDetails, setDispatchDetails] = useState({
        options: [],
        id: null,
        error: '',
    });
    const alignerSteps = [
        { label: `Lower Arches`, value: 'lower' },
        { label: 'Upper Arches', value: 'upper' },
        { label: 'Both', value: 'both' },
    ];
    const { id } = useParams();
    let statuses = [
        {label:"Open", value:"open"},
        {label:"In Progress", value:"progress"}, 
        {label:"Awaiting Feedback", value:"awaiting feedback"},
        {label:"Ongoing", value:"ongoing"},
        {label:"Resolved", value:"resolved"},
        {label:"Close", value:"closed"},
    ];
    const getStatusWithBadge = (status) => {
        if (!status) {
            return '';
        }
        return <Badge status={status == 'Good' ? 'success' : "error"}>{status}</Badge>
    }

    const emptyErrors = () => {
        if(dispatchDetails.error) {
            setDispatchDetails(prev => ({
                ...prev,
                error: '',
            }));
        }
    }

    const handleChangeStatus = (e) => {
        let { value } = e.target;
        setStatusItem(value);
    }

    const checkDispatchDate = () => {
        axios.get(
            `/admin/v1/product-requests/${ticket.subject_user_id}/dispatches`,
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`,
                },
            }
        ).then(response => {
            if (response.status === 200) {
                if (response.data.success === true) {
                    const { dispatch } = response.data;
                    if (dispatch) {
                        let upcomingDispatchDate = moment(dispatch.target_dispatch_date).format(dateFormat);
                        setDispatchDetails(prev => ({
                            ...prev,
                            id: dispatch.id,
                            options: [
                                { label: `Add to the upcoming batch ${upcomingDispatchDate}`, value: 'link' },
                                { label: 'Create a separate delivery to be sent immediately', value: 'create_new' },
                            ],
                        }));
                    } else {
                        setDispatchDetails(prev => ({
                            ...prev,
                            options: [
                                { label: 'Create a separate delivery to be sent immediately', value: 'create_new' },
                            ],
                        }));
                    }
                    emptyErrors();
                    setIsModalOpen(true);
                }
            }
        }).catch(err => {
            console.log('checkDispatchDate error', err);
        });
    }

    const updateTicket = async(option = null) => {
        emptyErrors();
        let status = statusItem;
        let body = {
            status,
        }
        if(status == "awaiting feedback"){
            body.status = "pending";
        }
        if(option) {
            if(!option?.dispatch_option || !option?.dispatch_arches) {
                setDispatchDetails(prev => ({
                    ...prev,
                    error: 'Please fill the options',
                }));
                return;
            } else {
                body = {
                    ...body,
                    ...option,
                    dispatch_id: dispatchDetails.id,
                };
            }
        }
        setLoading(true);
        await axios.post(`admin/v1/tickets/${id}/update_status`,
            body,{
            headers: {
                Authorization: "Bearer " + Cookies.get("token"),
            },
        }).catch(err => {
            console.log('err', err);
        }).finally(() => {
            setLoading(false);
        });
        window.location.reload();
    }

    const handleUpdate = () => {
        let optionsWithDispatch = type_options.filter(option => option?.dispatch).map(option => option.name);
        if(optionsWithDispatch.includes(TIKCET_TYPE_ENUMS[ticket.type]) && statusItem == 'resolved') {
            checkDispatchDate();
        } else {
            updateTicket();
        }
    }

    const userProfile = () => {
        window.open("/admin/users/" + ticket.subject_user_id+ "/dashboard")
    }
    const getTimeInHours = (time)=>{
        let message = '';
        let timeInMinutes = Math.floor(time / (60))
        if (timeInMinutes >= 1) {
            message += timeInMinutes + (timeInMinutes > 1 ? ' hours' : ' hour')
            time = time % 60
        }
        if (time) {
            message += (message ? " " : "") + time + (time > 1 ?' minutes' : ' minute')
        }
        return message
    }
    useEffect(() => {
        getTicket();
    }, [])
    const getTicket = async () => {
        let res = await axios
            .get(
                `admin/v1/tickets/${id}`,
                {
                    headers: {
                        Authorization: "Bearer " + Cookies.get("token"),
                    },
                }
            )
        setComments(res.data.ticket.comments)
        setStatusItem(res.data.ticket.status_by_user)
        setTicket((prev)=>({...res.data.ticket,workflows:res.data.ticket.workflows.reverse()}))
        setUser(res.data.user)
        let agentsData = [];
        res.data.admins?.map((admin)=>{
            agentsData.push({ id: admin.user_id, display: admin.user.full_name.replace(" ", ".") });
        })
        setAgents(agentsData)
    }
    if(!ticket){
        return null
    }
     return (
        <NavigationLayout>
            <div style={{display:"flex",margin:"50px",gap:"20px",flexWrap:"wrap"}} >
            <div className='ticket-details-main'>
            <Card>
                <div className='ticket-details-parent'>
                <div className='ticket-details-general'>
                    <div style={{display:"flex", justifyContent:"space-between"}}><b style={{fontSize:"x-large"}}>#{ticket.id} - User {ticket.subject_user_id}: {ticket.text}</b>
                    <div>
                        <Button primary onClick={userProfile}>CMS Profile</Button>
                    </div></div>
                    <div style={{fontSize:"smaller",color:"grey"}}>Created by: {ticket.reported_by.full_name}</div>
                    <div style={{ marginTop: "20px" }}>Name: {ticket?.subject_user?.full_name}</div>
                    <div style={{overflowY:"auto"}} dangerouslySetInnerHTML={{ __html: ticket.description}}></div>
                    
                </div>
                <div className='ticket-details-info'>
                    <div style={{overflowY:"auto",height:"80%",display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
                        <div style={{display:"flex"}}>
                            <Select style={{ fontSize: '1.4rem', width: "100%", marginTop: "0px" }} onChange={(e) => { handleChangeStatus(e) }} value={statusItem} >
                                {statuses.map((status,ind)=>
                                    <MenuItem key={ind} style={{ fontSize: '1.4rem' }} disabled={ticket.allowed_actions.indexOf(status.value)==-1} value={status.value}>{status.label}</MenuItem>
                                )}
                            </Select>
                            <div style={{alignSelf:"center",float:"right"}}>{[ticket.first_response_status, ticket.close_status, ticket.progress_status].every((val) => !val || val == 'Good') ? getStatusWithBadge('Good') : getStatusWithBadge('Overdue')}</div>
                        </div>
                        <div>First Response Time: {getTimeInHours(ticket.first_response_minutes)}<div style={{alignSelf:"center",float:"right"}}>{getStatusWithBadge(ticket.first_response_status)}</div></div>
                        <div>Resolution Time: {getTimeInHours(ticket.progress_minutes + ticket.pending_minutes)} <div style={{alignSelf:"center",float:"right"}}>{getStatusWithBadge(ticket.progress_status)}</div></div>
                        <div>Closed Time: {getTimeInHours(ticket.close_minutes)} <div style={{alignSelf:"center",float:"right"}}>{getStatusWithBadge(ticket.close_status)}</div></div>
                        <div>Priority: {ticket.urgency}</div>
                        <div>Type: {TIKCET_TYPE_ENUMS[ticket.type]}</div>
                        <div>Source: {ticket.source}</div>
                    </div>

                    <div style={{ width: '50%', margin: "10px auto 0px auto" }}>
                        <Button primary fullWidth onClick={handleUpdate} disabled={loading} loading={loading}>Update</Button>
                    </div>
                </div>
                </div>
            </Card>
            </div>
            <div className='ticket-details-media'>
                <TicketingMedia media={ticket.media} setPreviewImages={setPreviewImages}/>
            </div>
            <div className='ticket-details-comment'>
                <TicketingComment comments={comments} setPreviewImages={setPreviewImages} userId={user.id} setComments={setComments} ticketId={id} subjectUserId={ticket.subject_user_id} agents={agents}/>
            </div>
            <div className='ticket-details-workflow'>
                <TicketingWorkFlow workflows={ticket?ticket.workflows:[]}/>
            </div>
            </div>
            
            {previewImages.images.length ? <div ><Lightbox startIndex={previewImages.startIndex} onClose={() => setPreviewImages({images:[],startIndex:0})} images={previewImages.images.map((val) => val.url !== null && process.env.REACT_APP_AWS_URL+val.url)}  ></Lightbox></div> : <></>}
             <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title="Dispatch Details"
                primaryAction={{
                    content: 'SUBMIT',
                    loading: loading,
                    disabled: loading,
                    onAction: () => updateTicket({
                        dispatch_option: option.current?.props?.value?.value,
                        dispatch_arches: arch.current?.props?.value?.value,
                    }),
                }}
                secondaryActions={[
                    {
                        content: "Continue without modifications",
                        loading: loading,
                        disabled: loading,
                        onAction: () => updateTicket(null),
                    },
                    {
                        content: "Cancel",
                        onAction: () => setIsModalOpen(false),
                    },
                ]}
             >
                <Modal.Section>
                <div className="tracking-dropdown-container" style={{ marginTop: 13 }}>
                    <FormControl>
                        <Select2
                            ref={option}
                            placeholder='Send with'
                            options={dispatchDetails.options}
                        />
                        <br />
                        <Select2
                            ref={arch}
                            placeholder='Arches to send'
                            options={alignerSteps}
                        />
                        <br />
                        <TextStyle variation='negative'>{dispatchDetails.error}</TextStyle>
                    </FormControl>
                </div>
                </Modal.Section>
            </Modal>
        </NavigationLayout>
    )
}

export default TicketingDetails