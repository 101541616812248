import React, { useEffect, useState } from 'react';
import { Badge, Button, Icon } from "@shopify/polaris";
import { useHistory } from 'react-router-dom';
import { ExternalMinor, MobileBackArrowMajor } from "@shopify/polaris-icons";
import "react-toastify/dist/ReactToastify.css";
import CopyToClipboard from "react-copy-to-clipboard";
import copy from "../../assets/images/copy.png";

const GuestHeader = ({
  pageTitle,
}) => {
  const history = useHistory();
  const [copiedText, setCopiedText] = useState({ text: null, copied: false });

  const handleCopy = () => {
    setCopiedText({ ...copiedText, copied: true });
  };

  const  containerdiv= {
    display:'flex',
    marginRight:'20px',
  };
  const copyimage = {
    width: '16px',
    heigth: '16px',
    cursor: 'pointer',
    marginLeft:'7px'
  
  };
  const copiedtext = {
    fontWeight: '400',
    fontSize: '15px',
    marginLeft:'7px'
  };
  
  const url = window.location.href;
  let clipboardText = url;
  if (pageTitle?.id && pageTitle?.fullname) {
    const { id, fullname } = pageTitle;
    clipboardText = `${fullname} (ID: ${id}) ${url}`;
  }

  useEffect(() => {
    if (copiedText.copied) {
      const timer = setTimeout(
        () => setCopiedText((prevState) => ({ ...prevState, copied: false })),
        2000,
      );
      return () => clearTimeout(timer);
    }
  }, [copiedText.copied]);

  return (
    <div className="navHeaderWrap">
      <div
        style={{
          height: "fit-content",
          padding: "10px",
          paddingLeft: "20px",
          fontWeight: "bold",
          width: "100%",
          fontSize: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Button onClick={() => history.goBack()}>
            <Icon source={MobileBackArrowMajor} color="base" />
          </Button>
          <div style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => window.scroll({ top: 0, behavior: "smooth" })}
            >
                <div style={containerdiv}>
                  <div>
                    {pageTitle?.name}&nbsp;
                  </div>
                  <CopyToClipboard text={clipboardText} onCopy={handleCopy}>
                    <div>
                      {copiedText.copied ? (
                        <div style={copiedtext}>
                      Copied!
                      </div>
                      ) : (
                        <img src={copy} alt="copy" style={copyimage} />
                      )}
                    </div>
                  </CopyToClipboard>

                </div>
      
              <div style={{ fontWeight: "400", fontSize: "13px" }}>
                <span>{pageTitle?.email}</span>
              </div>
            </div>
            
            <div
              style={{ display: "flex", flexWrap: "wrap", columnGap: "5px" }}
            >
              <span>
                <Badge status='success'>Guest</Badge>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestHeader;
