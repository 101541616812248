import React, { useCallback, useState, useEffect } from "react";
import {
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Card,
  Modal,
  PageActions,
  Collapsible,
  Button,
  TextContainer,
  Icon
} from "@shopify/polaris";
import axios from "../../axios";
import Cookie from "js-cookie";
import Axios from "axios";
import Select2 from "react-dropdown-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import Select from "react-select";
import {
  CaretDownMinor,CaretUpMinor
} from '@shopify/polaris-icons';
import { useParams } from "react-router-dom";
import CustomPaymentTab from "../CustomPaymentTab";
import {
  MobileCancelMajor
} from '@shopify/polaris-icons';
import CustomPaymentTabV2 from "../CustomPaymentTabV2";
import moment from "moment";

function OfflineTPOrder() {

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#FF2D47' : '#000',
      backgroundColor: '#fff',
      padding:'1rem',
      cursor: 'pointer',
      '&:hover':{
        backgroundColor: '#d3d3d3',
      },
      fontSize: '14px',
    }),
    control: (provided, state) => ({
      ...provided,
      padding:'0.5rem',
      // boxShadow: state.isFocused ? 0 : 0,
      // border: 'none',
      // borderBottom: '1px solid #d3d3d3',
      cursor: 'pointer',
      '&:hover':{
        borderColor:'#d3d3d3',
      },
      borderRadius:'0px',
    }),
    container:(provided,state)=>({
      ...provided,
      zIndex:"40 !important",
    }),
}
  const { mail } = useParams();
  const [email, setEmail] = useState(mail);
  const [emailError, setEmailError] = useState("");

  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState("");

  const [treatment, setTreatment] = useState("");
  const [treatmentError, setTreatmentError] = useState("");
  const [optionsTreatments,setOptionsTreatments]=useState([])

  const [plan, setPlan] = useState("")
  const [planError, setPlanError] = useState("");
  const [optionsPlans,setOptionsPlans]=useState([])

  const [coupon, setCoupon] = useState("");
  const [optionsCoupons, setOptionsCoupons] = useState([]);

  const [dateValue, setDateValue] = useState("");
  const [dateError,setDateError] = useState("");  

  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

  const [popupActive, setPopupActive] = useState(false);

  const [action,setAction]=useState("")
  const [optionsAction,setOptionsAction]=useState([])
  const [plans, setPlans] = useState([])
  const [updated,setUpdated]=useState(false)
  const [nextPayment,setNextPayment]=useState("")
  const [isOpen,setIsOpen]=useState(false)
  const [activepayemnt,setActivePayment]=useState([])
  const [total,setTotal]=useState(0);

  const[disabledPlan,setDisabledPlan]=useState(true)
  const[disabledCoupon,setDisabledCoupon]=useState(true)
  const[disabledPaymentStatus,setDisabledPaymentStatus]=useState(true)
  const[disabledPaymentMethod,setDisabledPaymentMethod]=useState(true)
  const[disabledAction,setDisabledAction]=useState(true)
  const[disabledDate,setDisabledDate]=useState(true)
  const[disabledTreatment,setDisabledTreatment]=useState(true)
  const formatYmd = (date) => date.toISOString().slice(0, 10);
  const[stage,setStage]=useState('Check Eligible')

  const[getShipping,setGetShipping]=useState(0)
  const [disabledShipping,setDisabledShipping]=useState(true)
  const [shippingAddress,setShippingAddress]=useState("")
  const [deposit, setDeposit] = useState('')
  const [shippingAddress2,setShippingAddress2]=useState("")
  const [shippingAddress3,setShippingAddress3]=useState("")
  const [shippingCity,setShippingCity]=useState("")
  const [shippingCountry,setShippingCountry]=useState("")
  const [shippingEmail,setShippingEmail]=useState("")
  const [shippingFullName,setShippingFullName]=useState("")
  const [shippingPhone,setShippingPhone]=useState("")
  const [shippingPostal,setShippingPostal]=useState("")
  const [basicTab,setBasicTab]=useState(false)
  const [tpTab,setTpTab]=useState(false)
  const [planTab,setPlanTab]=useState(false)
  const [shippingTab,setShippingTab]=useState(false)
  const [depositTab,setDepositTab]=useState(false)
  const [fillShipping,setFillShipping]=useState(false)
  const [couponAmount,setCouponAmount]=useState(0);
  const [refNumber,setRefNumber]=useState("");
  const [custom,setCustom]=useState(false)
  const [disableDeposit, setDisableDeposit] = useState(false)
  const [loading, setLoading]=useState(false)
  const [optionsShippingCountries,setOptionsShippingCountries]=useState([
    {value: 'Afghanistan', label: 'Afghanistan'},
    {value: 'Albania', label: 'Albania'},
    {value: 'Algeria', label: 'Algeria'},
    {value: 'American Samoa', label: 'American Samoa'},
    {value: 'Andorra', label: 'Andorra'},
    {value: 'Angola', label: 'Angola'},
    {value: 'Anguilla', label: 'Anguilla'},
    {value: 'Antigua & Barbuda', label: 'Antigua & Barbuda'},
    {value: 'Argentina', label: 'Argentina'},
    {value: 'Armenia', label: 'Armenia'},
    {value: 'Aruba', label: 'Aruba'},
    {value: 'Australia', label: 'Australia'},
    {value: 'Austria', label: 'Austria'},
    {value: 'Azerbaijan', label: 'Azerbaijan'},
    {value: 'Bahamas', label: 'Bahamas'},
    {value: 'Bahrain', label: 'Bahrain'},
    {value: 'Bangladesh', label: 'Bangladesh'},
    {value: 'Barbados', label: 'Barbados'},
    {value: 'Belgium', label: 'Belgium'},
    {value: 'Belize', label: 'Belize'},
    {value: 'Benin', label: 'Benin'},
    {value: 'Bermuda', label: 'Bermuda'},
    {value: 'Bhutan', label: 'Bhutan'},
    {value: 'Bolivia', label: 'Bolivia'},
    {value: 'Bonaire', label: 'Bonaire'},
    {value: 'Bosnia & Herzegovina', label: 'Bosnia & Herzegovina'},
    {value: 'Botswana', label: 'Botswana'},
    {value: 'Brazil', label: 'Brazil'},
    {value: 'British Indian Ocean Ter', label: 'British Indian Ocean Ter'},
    {value: 'Brunei', label: 'Brunei'},
    {value: 'Bulgaria', label: 'Bulgaria'},
    {value: 'Burkina Faso', label: 'Burkina Faso'},
    {value: 'Burundi', label: 'Burundi'},
    {value: 'Cambodia', label: 'Cambodia'},
    {value: 'Cameroon', label: 'Cameroon'},
    {value: 'Canada', label: 'Canada'},
    {value: 'Canary Islands', label: 'Canary Islands'},
    {value: 'Cape Verde', label: 'Cape Verde'},
    {value: 'Cayman Islands', label: 'Cayman Islands'},
    {value: 'Central African Republic', label: 'Central African Republic'},
    {value: 'Chad', label: 'Chad'},
    {value: 'Channel Islands', label: 'Channel Islands'},
    {value: 'Chile', label: 'Chile'},
    {value: 'China', label: 'China'},
    {value: 'Christmas Island', label: 'Christmas Island'},
    {value: 'Cocos Island', label: 'Cocos Island'},
    {value: 'Colombia', label: 'Colombia'},
    {value: 'Comoros', label: 'Comoros'},
    {value: 'Congo', label: 'Congo'},
    {value: 'Cook Islands', label: 'Cook Islands'},
    {value: 'Costa Rica', label: 'Costa Rica'},
    {value: 'Croatia', label: 'Croatia'},
    {value: 'Curacao', label: 'Curacao'},
    {value: 'Cyprus', label: 'Cyprus'},
    {value: 'Czech Republic', label: 'Czech Republic'},
    {value: 'Denmark', label: 'Denmark'},
    {value: 'Djibouti', label: 'Djibouti'},
    {value: 'Dominica', label: 'Dominica'},
    {value: 'Dominican Republic', label: 'Dominican Republic'},
    {value: 'East Timor', label: 'East Timor'},
    {value: 'Ecuador', label: 'Ecuador'},
    {value: 'Egypt', label: 'Egypt'},
    {value: 'El Salvador', label: 'El Salvador'},
    {value: 'Equatorial Guinea', label: 'Equatorial Guinea'},
    {value: 'Eritrea', label: 'Eritrea'},
    {value: 'Estonia', label: 'Estonia'},
    {value: 'Ethiopia', label: 'Ethiopia'},
    {value: 'Falkland Islands', label: 'Falkland Islands'},
    {value: 'Faroe Islands', label: 'Faroe Islands'},
    {value: 'Fiji', label: 'Fiji'},
    {value: 'Finland', label: 'Finland'},
    {value: 'France', label: 'France'},
    {value: 'French Guiana', label: 'French Guiana'},
    {value: 'French Polynesia', label: 'French Polynesia'},
    {value: 'French Southern Ter', label: 'French Southern Ter'},
    {value: 'Gabon', label: 'Gabon'},
    {value: 'Gambia', label: 'Gambia'},
    {value: 'Georgia', label: 'Georgia'},
    {value: 'Germany', label: 'Germany'},
    {value: 'Ghana', label: 'Ghana'},
    {value: 'Gibraltar', label: 'Gibraltar'},
    {value: 'Great Britain', label: 'Great Britain'},
    {value: 'Greece', label: 'Greece'},
    {value: 'Greenland', label: 'Greenland'},
    {value: 'Grenada', label: 'Grenada'},
    {value: 'Guadeloupe', label: 'Guadeloupe'},
    {value: 'Guam', label: 'Guam'},
    {value: 'Guatemala', label: 'Guatemala'},
    {value: 'Guinea', label: 'Guinea'},
    {value: 'Guyana', label: 'Guyana'},
    {value: 'Haiti', label: 'Haiti'},
    {value: 'Hawaii', label: 'Hawaii'},
    {value: 'Honduras', label: 'Honduras'},                                             
    {value: 'Hong Kong', label: 'Hong Kong'},                                             
    {value: 'Hungary', label: 'Hungary'},
    {value: 'Iceland', label: 'Iceland'},
    {value: 'Indonesia', label: 'Indonesia'},
    {value: 'India', label: 'India'},
    {value: 'Ireland', label: 'Ireland'},
    {value: 'Isle of Man', label: 'Isle of Man'},
    {value: 'Italy', label: 'Italy'},
    {value: 'Jamaica', label: 'Jamaica'},
    {value: 'Japan', label: 'Japan'},
    {value: 'Jordan', label: 'Jordan'},
    {value: 'Kazakhstan', label: 'Kazakhstan'},
    {value: 'Kenya', label: 'Kenya'},
    {value: 'Kiribati', label: 'Kiribati'},
    {value: 'Korea South', label: 'Korea South'},
    {value: 'Kuwait', label: 'Kuwait'},
    {value: 'Kyrgyzstan', label: 'Kyrgyzstan'},
    {value: 'Laos', label: 'Laos'},
    {value: 'Latvia', label: 'Latvia'},
    {value: 'Lebanon', label: 'Lebanon'},
    {value: 'Lesotho', label: 'Lesotho'},
    {value: 'Libya', label: 'Libya'},
    {value: 'Liechtenstein', label: 'Liechtenstein'},
    {value: 'Lithuania', label: 'Lithuania'},
    {value: 'Luxembourg', label: 'Luxembourg'},
    {value: 'Macau', label: 'Macau'},
    {value: 'Macedonia', label: 'Macedonia'},
    {value: 'Madagascar', label: 'Madagascar'},
    {value: 'Malaysia', label: 'Malaysia'},
    {value: 'Malawi', label: 'Malawi'},
    {value: 'Maldives', label: 'Maldives'},
    {value: 'Mali', label: 'Mali'},
    {value: 'Malta', label: 'Malta'},
    {value: 'Marshall Islands', label: 'Marshall Islands'},
    {value: 'Martinique', label: 'Martinique'},
    {value: 'Mauritania', label: 'Mauritania'},
    {value: 'Mauritius', label: 'Mauritius'},
    {value: 'Mayotte', label: 'Mayotte'},
    {value: 'Mexico', label: 'Mexico'},
    {value: 'Midway Islands', label: 'Midway Islands'},
    {value: 'Moldova', label: 'Moldova'},
    {value: 'Monaco', label: 'Monaco'},
    {value: 'Mongolia', label: 'Mongolia'},
    {value: 'Montserrat', label: 'Montserrat'},
    {value: 'Morocco', label: 'Morocco'},
    {value: 'Mozambique', label: 'Mozambique'},
    {value: 'Nambia', label: 'Nambia'},
    {value: 'Nauru', label: 'Nauru'},
    {value: 'Nepal', label: 'Nepal'},
    {value: 'Netherland Antilles', label: 'Netherland Antilles'},
    {value: 'Netherlands (Holland, Europe)', label: 'Netherlands (Holland, Europe)'},
    {value: 'Nevis', label: 'Nevis'},
    {value: 'New Caledonia', label: 'New Caledonia'},
    {value: 'New Zealand', label: 'New Zealand'},
    {value: 'Nicaragua', label: 'Nicaragua'},
    {value: 'Niger', label: 'Niger'},
    {value: 'Nigeria', label: 'Nigeria'},
    {value: 'Niue', label: 'Niue'},
    {value: 'Norfolk Island', label: 'Norfolk Island'},
    {value: 'Norway', label: 'Norway'},
    {value: 'Oman', label: 'Oman'},
    {value: 'Pakistan', label: 'Pakistan'},
    {value: 'Palau Island', label: 'Palau Island'},
    {value: 'Palestine', label: 'Palestine'},
    {value: 'Panama', label: 'Panama'},
    {value: 'Papua New Guinea', label: 'Papua New Guinea'},
    {value: 'Paraguay', label: 'Paraguay'},
    {value: 'Peru', label: 'Peru'},
    {value: 'Philippines', label: 'Philippines'},
    {value: 'Pitcairn Island', label: 'Pitcairn Island'},
    {value: 'Poland', label: 'Poland'},
    {value: 'Portugal', label: 'Portugal'},
    {value: 'Puerto Rico', label: 'Puerto Rico'},
    {value: 'Qatar', label: 'Qatar'},
    {value: 'Republic of Montenegro', label: 'Republic of Montenegro'},
    {value: 'Republic of Serbia', label: 'Republic of Serbia'},
    {value: 'Reunion', label: 'Reunion'},
    {value: 'Romania', label: 'Romania'},
    {value: 'Russia', label: 'Russia'},
    {value: 'Rwanda', label: 'Rwanda'},
    {value: 'St Barthelemy', label: 'St Barthelemy'},
    {value: 'St Eustatius', label: 'St Eustatius'},
    {value: 'St Helena', label: 'St Helena'},
    {value: 'St Kitts-Nevis', label: 'St Kitts-Nevis'},
    {value: 'St Lucia', label: 'St Lucia'},
    {value: 'St Maarten', label: 'St Maarten'},
    {value: 'St Pierre & Miquelon', label: 'St Pierre & Miquelon'},
    {value: 'St Vincent & Grenadines', label: 'St Vincent & Grenadines'},
    {value: 'Saipan', label: 'Saipan'},
    {value: 'Samoa', label: 'Samoa'},
    {value: 'Samoa American', label: 'Samoa American'},
    {value: 'San Marino', label: 'San Marino'},
    {value: 'Sao Tome & Principe', label: 'Sao Tome & Principe'},
    {value: 'Saudi Arabia', label: 'Saudi Arabia'},
    {value: 'Senegal', label: 'Senegal'},
    {value: 'Seychelles', label: 'Seychelles'},
    {value: 'Sierra Leone', label: 'Sierra Leone'},
    {value: 'Singapore', label: 'Singapore'},
    {value: 'Slovakia', label: 'Slovakia'},
    {value: 'Slovenia', label: 'Slovenia'},
    {value: 'Solomon Islands', label: 'Solomon Islands'},
    {value: 'Somalia', label: 'Somalia'},
    {value: 'South Africa', label: 'South Africa'},
    {value: 'Spain', label: 'Spain'},
    {value: 'Sri Lanka', label: 'Sri Lanka'},
    {value: 'Suriname', label: 'Suriname'},
    {value: 'Swaziland', label: 'Swaziland'},
    {value: 'Sweden', label: 'Sweden'},
    {value: 'Switzerland', label: 'Switzerland'},
    {value: 'Tahiti', label: 'Tahiti'},
    {value: 'Taiwan', label: 'Taiwan'},
    {value: 'Tajikistan', label: 'Tajikistan'},
    {value: 'Tanzania', label: 'Tanzania'},
    {value: 'Thailand', label: 'Thailand'},
    {value: 'Togo', label: 'Togo'},
    {value: 'Tokelau', label: 'Tokelau'},
    {value: 'Tonga', label: 'Tonga'},
    {value: 'Trinidad & Tobago', label: 'Trinidad & Tobago'},
    {value: 'Tunisia', label: 'Tunisia'},
    {value: 'Turkey', label: 'Turkey'},
    {value: 'Turkmenistan', label: 'Turkmenistan'},
    {value: 'Turks & Caicos Is', label: 'Turks & Caicos Is'},
    {value: 'Tuvalu', label: 'Tuvalu'},
    {value: 'Uganda', label: 'Uganda'},
    {value: 'United Kingdom', label: 'United Kingdom'},
    {value: 'Ukraine', label: 'Ukraine'},
    {value: 'United Arab Emirates', label: 'United Arab Emirates'},
    {value: 'United States of America', label: 'United States of America'},
    {value: 'Uruguay', label: 'Uruguay'},
    {value: 'Uzbekistan', label: 'Uzbekistan'},
    {value: 'Vanuatu', label: 'Vanuatu'},
    {value: 'Vatican City State', label: 'Vatican City State'},
    {value: 'Venezuela', label: 'Venezuela'},
    {value: 'Vietnam', label: 'Vietnam'},
    {value: 'Virgin Islands (Brit)', label: 'Virgin Islands (Brit)'},
    {value: 'Virgin Islands (USA)', label: 'Virgin Islands (USA)'},
    {value: 'Wake Island', label: 'Wake Island'},
    {value: 'Wallis & Futana Is', label: 'Wallis & Futana Is'},
    {value: 'Yemen', label: 'Yemen'},
    {value: 'Zaire', label: 'Zaire'},
    {value: 'Zambia', label: 'Zambia'},
])

  //Options start
  const [optionsCountries, setOptionsCountries] = useState([
    { label: "UAE", value: "AE" },
    { label: "KSA", value: "SA" },
    { label: "QAT ", value: "QA" },
    { label: "LEB ", value: "LB" },
    { label: "KWT ", value: "KW" },
    { label: "BAH ", value: "BH" },
    { label: "OMA ", value: "OM" },
  ]);
  const optionsPaymentStatus = [
    { label: "Paid", value: "Paid" },
    { label: "Refunded", value: "Refunded" },
    { label: "Partial Refund", value: "Partial Refund" },
  ];
  const optionsPaymentMethod = [
    { label: "Cash", value: "Cash" },
    { label: "POS", value: "POS" },
    { label: "Check", value: "Check" },
    { label: "Money Transfer", value: "Money Transfer" },
  ];
  //Options end
  useEffect(()=>{
    setCustom(false)
    if(stage=="Check Eligible"){
        setAction("")
        setCouponAmount(0)
        setBasicTab(true)
        setTpTab(false)
        setPlanTab(false)
        setSelectedPaymentStatus("")
        setSelectedPaymentMethod("")
        setDateValue("")
        setCoupon("")
        setTreatment("")
        setPlan("")
        setPlans([])
        setShippingAddress("")
        setShippingAddress2("")
        setShippingAddress3("")
        setShippingCity("")
        setShippingCountry("")
        setShippingEmail("")
        setShippingFullName("")
        setShippingPhone("")
        setShippingPostal("")
        setDisabledPlan(true)
        setDisabledTreatment(true)
        setDisabledCoupon(true)
        setDisabledDate(true)
        setDisabledAction(true)
        setDisabledPaymentMethod(true)
        setDisabledPaymentStatus(true)
    }
    else if(stage=="Choose TP"){
        setPlans([])
        setCouponAmount(0)
        setDisabledTreatment(false)
        setBasicTab(false)
        setTpTab(true)
        setPlanTab(false)
        setAction("")
        setSelectedPaymentStatus("")
        setSelectedPaymentMethod("")
        setDateValue("")
        setCoupon("")
        setPlan("")
        setDisabledPlan(true)
        setDisabledCoupon(true)
        setDisabledDate(true)
        setDisabledAction(true)
        setDisabledPaymentMethod(true)
        setDisabledPaymentStatus(true)
    }
    else if(stage=="Choose Plan"){
        setCouponAmount(0)
        setDisabledPlan(false)
        setDisabledCoupon(false)
        setBasicTab(false)
        setTpTab(false)
        setPlanTab(true)
        setDisabledDate(false)
        setDisabledPaymentMethod(false)
        setDisabledPaymentStatus(false)
        setAction("")
        setDisabledAction(true)
        setDisabledTreatment(true)
    }
    else if(stage=="Choose Payment"){
      setCouponAmount(0)
        setDisabledDate(false)
        setBasicTab(false)
        setTpTab(false)
        setPlanTab(true)
        setDisabledPaymentMethod(false)
        setDisabledPaymentStatus(false)
        setDisabledAction(false)
        setDisabledTreatment(true)
        setDisabledPlan(true)
        setDisabledCoupon(true)
    }
  },[stage])
  const [invoiceRequested, setInvoiceRequested] = useState(false)
  const [invoiceDate, setInvoiceDate] = useState(false)
  useEffect(()=>{
     axios.get(`admin/v1/get/invoice-requests/${mail}`, {
       headers: {
         Authorization: "Bearer " + Cookie.get("token"),
       },
     }).then((res)=>{
        setInvoiceRequested(res.data.invoiceRequested)
        setInvoiceDate(moment(res.data.invoiceDate).format('YYYY-MM-YY h:m A'))
     }).catch((err) => {
      console.log(err)
     });
   },[])

  useEffect(()=>{
   if(getShipping>0){
    let shippingBody={
      user_email:email
    }
    axios.post(`admin/v1/get/shipping_info`,shippingBody, {
      headers: {
        Authorization: "Bearer " + Cookie.get("token"),
      },
    }).then((res)=>{
      let responseShipping=res?.data?.body
      if(responseShipping){
      setShippingAddress(responseShipping.shipping_address)
      setShippingAddress2(responseShipping.shipping_address_2)
      setShippingAddress3(responseShipping.shipping_address_3)
      setShippingCity(responseShipping.shipping_city)
      setShippingCountry({label:responseShipping.shipping_country,value:responseShipping.shipping_country})
      setShippingEmail(responseShipping.shipping_email)
      setShippingFullName(responseShipping.shipping_full_name)
      setShippingPhone(responseShipping.shipping_phone)
      setShippingPostal(responseShipping.shipping_postal_code)
      setShippingTab(false)
      }else{
        setDisabledShipping(false)
      }
    });
  }
  },[getShipping])

  useEffect(()=>{
    
     axios.get(`admin/v1/get/deposit/user/${email}`, {
       headers: {
         Authorization: "Bearer " + Cookie.get("token"),
       },
     }).then((response)=>{
        console.log('res', response)
        setDeposit(response.data.data.amount)
        setDisableDeposit(true)
   })},[])
  
  useEffect(() => {
    console.log("saji3", plan)
    if(plan){
      if(plans.length>0){
       let planIndex= plans.findIndex(object => {
          return object.id == plan.value;
        })
        if(plans[planIndex].payments.length){
          let index = plans[planIndex].payments.findIndex(object => {
            return object.paid == false;
          });
          let nextpay=plans[planIndex].payments[index];
          setNextPayment(nextpay.price+" "+nextpay.currency)
          setActivePayment(plans[planIndex].payments)
          setCustom(plans[planIndex].custom)
          let t=plans[planIndex].payments.map(item => parseInt(item.price)).reduce((prev, next) => prev + next)
          setTotal(t)
        }
      }
    }
    let body={
      id:plan?plan.value?plan.value:0:0,
      email:email,
    }
    Promise.all([
      
      axios.post(`admin/v1/aligners_coupons/show`,body, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }),
    ]).then(function (responses) {
      const responseCoupons = responses[0]?.data?.data;
      let coupons = [];
      for (var i = 0; i < responseCoupons.length; i++) {
        coupons.push({
          key: responseCoupons[i]?.id,
          label:
            responseCoupons[i]?.code + " ( ID : " + responseCoupons[i]?.id + " )",
          value: responseCoupons[i]?.code,
          amount :responseCoupons[i]?.amount
        });
      }
      setOptionsCoupons(coupons);
      if(responses[0].data.selected){
        setCoupon({
          key: responseCoupons[0]?.id,
          label:
            responseCoupons[0]?.code + " ( ID : " + responseCoupons[0]?.id + " )",
          value: responseCoupons[0]?.code,
        })
      }
      else{
        setCoupon("")
      }
    });
  }, [plan]);
  const SaveDeposit=async()=>{
    if(!deposit){
      toast.error("Please Enter a deposit amount first :)",3000)
      return;
    }
    const body={
      email:email,
      amount:deposit
    }
    try{
      await axios.post(`admin/v1/store/deposit`, body, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
    } catch(err){
      console.log(err)
    }
    setDisableDeposit((prev)=>!prev)
    toast.success("Deposit amount has been set",3000)
  }

  const SaveShipping=async()=>{
    if(!shippingAddress||!shippingAddress2||!shippingCity||!Object.keys(shippingCountry).length||!shippingEmail||!shippingFullName||!shippingPhone){
      toast.error("Please Fill The Shipping Info :)",3000)
      return;
    }
    let shippingBody={
      shipping_address:shippingAddress,
      shipping_address_2:shippingAddress2,
      shipping_address_3:shippingAddress3,
      shipping_city:shippingCity,
      shipping_country:shippingCountry.value,
      shipping_email:shippingEmail,
      shipping_full_name:shippingFullName,
      shipping_phone:shippingPhone,
      shipping_postal_code:shippingPostal,
      user_email:email
    }
    let res =await axios.post(`admin/v1/store/shipping_info`,shippingBody, {
      headers: {
        Authorization: "Bearer " + Cookie.get("token"),
      },
    })
    setDisabledShipping((prev)=>!prev)
    setShippingTab(false)
    toast.success("Shipping Info saved",3000)
  }
  return (
    <>
      {(invoiceRequested && invoiceDate) && <div style={{backgroundColor:'#9fe9d9', boxShadow:"1px 2px 3px lightgray", borderRadius:"5px", padding:"15px", marginBottom:"10px", width:"100%", height:"50px"}}>
        Invoice already requested at {invoiceDate}
      </div>}
      <Card sectioned>
      <Button monochrome outline destructive={stage=='Check Eligible'?true:false} icon={basicTab? CaretUpMinor:CaretDownMinor} onClick={()=>{setBasicTab(prev=>!prev)}}>Basic Info</Button>
      <Collapsible open={basicTab}id="basic-collapsible"
            transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
            expandOnPrint>
        <FormLayout>
          <TextStyle variation="strong" >
            Email
          </TextStyle>
          <TextField
            value={email}
            onChange={(e)=>{setEmail(e)}}
            error={emailError}
            type="text"
          />
          <TextStyle variation="strong">Country</TextStyle>
          <Select 
             options={optionsCountries}
             onChange={setCountry}
             value={country}
             placeholder="Select Country"
          />
          <div style={{color:"green",justifyContent:'end',display:"flex"}}>
          <Button monochrome outline disabled={stage!="Check Eligible"} onClick={()=>handleSave()}>Save</Button>
          </div>
          </FormLayout>
          </Collapsible>
          </Card>

          <Card sectioned>
          <Button monochrome outline destructive={fillShipping} icon={depositTab? CaretUpMinor:CaretDownMinor} onClick={()=>{setDepositTab(prev=>!prev)}}>Deposit</Button>
          <Collapsible open={depositTab}id="basic-collapsible"
            transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
            expandOnPrint>
          <Card.Section>
          <FormLayout>
            <TextField min={0} type="number" disabled={disableDeposit} label={'Amount'} value={deposit} placeholder={'Place desired amount'} onChange={setDeposit}></TextField>
            <div style={{color:"green",justifyContent:'end',display:"flex"}}>
              <Button monochrome outline onClick={()=>{if(!disableDeposit){SaveDeposit()}else{
                setDisableDeposit((prev)=>!prev)
              }}}>{disableDeposit?"Edit":"Save"}</Button>
            </div>
        </FormLayout>
          </Card.Section>
        </Collapsible>
        </Card>

          <Card sectioned>
      <Button monochrome outline  destructive={stage=='Choose TP'?true:false} icon={tpTab? CaretUpMinor:CaretDownMinor} onClick={()=>{setTpTab(prev=>!prev)}}>Treatment Info</Button>
      <Collapsible open={tpTab}id="basic-collapsible"
            transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
            expandOnPrint>
        <FormLayout>
        <TextStyle variation="strong">Treatment</TextStyle>
          <Select 
             options={optionsTreatments}
             onChange={setTreatment}
             value={treatment}
             placeholder="Please Select a Treatment"
             error={treatmentError}
            isDisabled={disabledTreatment}
          />
          <div style={{color:"green",justifyContent:'end',display:"flex"}}>
          <Button monochrome outline disabled={stage!="Choose TP"} onClick={()=>handleSave()}>Save</Button>
          </div>
          </FormLayout>
          </Collapsible>
          </Card>

          <Card sectioned>
          <Button monochrome outline destructive={fillShipping} icon={shippingTab? CaretUpMinor:CaretDownMinor} onClick={()=>{setShippingTab(prev=>!prev)}}>Shipping Address</Button>
          <Collapsible open={shippingTab}id="basic-collapsible"
            transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
            expandOnPrint>
          <FormLayout>
            <TextField disabled={disabledShipping} label={'Street'} value={shippingAddress} onChange={setShippingAddress}></TextField>
            <TextField disabled={disabledShipping} label={'Building,Apartment'} value={shippingAddress2} onChange={setShippingAddress2}></TextField>
            <TextField disabled={disabledShipping} label={'Additional Info'} value={shippingAddress3} onChange={setShippingAddress3}></TextField>
            <TextField disabled={disabledShipping} label={'Full name'} value={shippingFullName} onChange={setShippingFullName}></TextField>
            <TextField disabled={disabledShipping} label={'Email'} value={shippingEmail} onChange={setShippingEmail}></TextField>
            <TextStyle >Country</TextStyle>
            <Select isDisabled={disabledShipping} multi={false}  placeholder={'Country'} options={optionsShippingCountries} value={shippingCountry} onChange={setShippingCountry} styles={customStyles}></Select>
            <TextField disabled={disabledShipping} label={'City'} value={shippingCity} onChange={setShippingCity}></TextField>
            <TextField disabled={disabledShipping} label={'Phone'} value={shippingPhone} onChange={setShippingPhone}></TextField>
            <TextField disabled={disabledShipping} label={'Postal Code'} value={shippingPostal} onChange={setShippingPostal}></TextField>
            <div style={{color:"green",justifyContent:'end',display:"flex"}}>
              <Button monochrome outline onClick={()=>{if(!disabledShipping){SaveShipping()}else{
                setDisabledShipping((prev)=>!prev)
              }}}>{disabledShipping?"Edit":"Save"}</Button>
            </div>
        </FormLayout>
        </Collapsible>
        </Card>

          <Card sectioned>
          <Button monochrome outline destructive={stage=='Choose Plan'||stage=='Choose Payment'?true:false} icon={planTab? CaretUpMinor:CaretDownMinor} onClick={()=>{setPlanTab(prev=>!prev)}}>Plan Info</Button>
          <Collapsible open={planTab}id="basic-collapsible"
            transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
            expandOnPrint>
          <FormLayout>

          <TextStyle variation="strong">Plan</TextStyle>
          <Select 
             options={optionsPlans}
             onChange={setPlan}
             value={plan}
             placeholder="Please Select a Plan"
             error={planError}
             isDisabled={disabledPlan||updated}
          />
          <TextStyle variation="strong">Coupon</TextStyle>
          <Select 
             options={optionsCoupons}
             onChange={(e)=>{setCoupon(e);if(stage=="Choose Plan"){
              setCouponAmount(e.amount)}
            }}
             value={coupon}
             placeholder="Please Select"
             isDisabled={disabledCoupon}
          />
          <TextStyle variation="strong">Date Of Payments</TextStyle>
          <div className="date-container">
          <TextField
            value={dateValue}
            onChange={setDateValue}
            type="date"
            error={dateError}
            disabled={disabledDate}
          />
          </div>
          {nextPayment&&<div style={{display:"flex",justifyContent:"space-between"}}>
              <h3  style={{display:'flex',alignItems:"center",fontWeight:"600"}}>Next Payment: {Math.round(parseInt(nextPayment)-parseInt(nextPayment)*couponAmount/100)}</h3>
              <Button onClick={()=>{setIsOpen(true)}} >Change it</Button>
            </div>}
          <TextStyle variation="strong">Payment Method</TextStyle>
          <Select 
             options={optionsPaymentMethod}
             onChange={setSelectedPaymentMethod}
             value={selectedPaymentMethod}
             placeholder="Please Select"
             isDisabled={disabledPaymentMethod}
          />
          {selectedPaymentMethod?.value=="Money Transfer" &&<div>
         <TextStyle variation="strong">Reference Number</TextStyle>
         <div className="date-container">
          <TextField 
            value={refNumber}
            onChange={setRefNumber}
            type="text"
            disabled={selectedPaymentMethod?.value!="Money Transfer"}
          />
          </div>
          </div>}
          <TextStyle variation="strong">Payment Status</TextStyle>
          <Select 
            options={optionsPaymentStatus}
            onChange={setSelectedPaymentStatus}
            value={selectedPaymentStatus}
            placeholder="Please Select"
            isDisabled={disabledPaymentStatus}
          />
          <TextStyle variation="strong">Action</TextStyle>
          <Select 
              options={optionsAction}
              onChange={setAction}
              value={action}
              placeholder="Please Select"
              isDisabled={disabledAction}
          />
          <div style={{color:"green",justifyContent:'end',display:"flex"}}>
          <Button primary outline disabled={(stage != "Choose Plan") || loading == true || invoiceRequested} onClick={()=>handleRequestInvoice()}>Request Invoice</Button>&nbsp;
          <Button monochrome outline disabled={(stage!="Choose Plan"&&stage!="Choose Payment")||loading==true} onClick={()=>handleSave()}>Save</Button>
          </div>
          </FormLayout>
          </Collapsible>
      </Card>

      <Modal open={popupActive} loading={true}></Modal>
      <Modal
        open={isOpen}
        title={<div style={{position:"relative"}}><h2 style={{}}>Custom Payment</h2><button onClick={() => setIsOpen(false)}  style={{position:"absolute",zIndex:1,border:"none","backgroundColor":"white",right:"0%",top:"2%",cursor:"pointer"}}><Icon
        source={MobileCancelMajor}
        color="black"
      /></button></div>}

        >
            <Modal.Section>
              {
              (activepayemnt.length>0&& couponAmount)?
              <CustomPaymentTabV2 plan={plan.value} email={email} setIsOpen={setIsOpen} setUpdated={setUpdated} setNextPayment={setNextPayment} payments={activepayemnt} setPayments={setActivePayment} coupon={couponAmount} total={Math.round(total-total*couponAmount/100)} setCustom={setCustom}  ></CustomPaymentTabV2>
               :activepayemnt.length>0&&<CustomPaymentTab plan={plan.value} email={email} setIsOpen={setIsOpen} setUpdated={setUpdated} setNextPayment={setNextPayment} payments={activepayemnt} setPayments={setActivePayment} total={total}>
               </CustomPaymentTab>}
              </Modal.Section>
        </Modal>
    </>
  );
  async function handleReset(){
    setCountry('')
    setEmail("")
    setGetShipping(0)
    setStage("Check Eligible")
  }

  async function handleRequestInvoice() {
    setLoading(true)
    
    if(!shippingAddress||!shippingAddress2||!shippingCity||!shippingCountry||!shippingEmail||!shippingFullName||!shippingPhone){
      toast.error("Please Fill The Shipping Info :)",3000)
      setFillShipping(true)
      setShippingTab(true)
      return;
    }
    if(!(email&&Object.keys(country).length&&Object.keys(treatment).length&&dateValue&& Object.keys(selectedPaymentMethod).length&& Object.keys(selectedPaymentStatus).length&& Object.keys(plan).length)){
      toast.error("PLease Fill the data :)", 3000)
      setLoading(false)
      return;
    }
    if(stage !== 'Choose Plan'){
      toast.error("This user is not in the right stage :)", 3000)
      setLoading(false)
      return;
    }
    const body = {
      shippingAddress: shippingAddress,
      shippingAddress2: shippingAddress2,
      shippingCity: shippingCity,
      shippingCountry: shippingCountry,
      shippingEmail: shippingEmail,
      shippingFullName: shippingFullName,
      shippingPhone: shippingPhone,
      dateValue: dateValue,
      coupon_code:coupon?Object.keys(coupon).length?coupon.value:"":"",
      coupon_amount:coupon?Object.keys(coupon).length?coupon.amount:"":"",
      plan: plan
    }
    try{ 
      await axios.post('admin/v1/manual/request-invoice', body, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
          },});
          toast.success("Invoice Requested ", 3000);
      }catch(e){
          toast.error("Something went wrong contact Dev Team",3000)
          handleReset()
          setLoading(false)
          return;
      }

    setLoading(false)
  }


  async function  handleSave() {
    setLoading(true);
    let res="";
    if(stage=="Check Eligible"){
      if(!(email&&Object.keys(country).length)){
        toast.error("PLease Fill the data :)", 3000)
        return;
      }
        let data={email:email,country:country.value}
        res=await axios.post('admin/v1/manual/check_status',data ,{
           headers: {
             Authorization: "Bearer " + Cookies.get("token"),
             },});
          setGetShipping((prev)=>{return prev+1})
    }
    else if(stage=="Choose TP" ){
      if(!(email&&Object.keys(country).length&&Object.keys(treatment).length)){
        toast.error("PLease Fill the data :)", 3000)
        return;
      }
        let data={email:email,country:country.value,treatment_id:treatment.value}
        res=await axios.post('admin/v1/manual/choose_treatment',data ,{
           headers: {
             Authorization: "Bearer " + Cookies.get("token"),
             },});
    }
    else if(stage=="Choose Plan"){
      if(!(email&&Object.keys(country).length&&Object.keys(treatment).length&&dateValue&& Object.keys(selectedPaymentMethod).length&& Object.keys(selectedPaymentStatus).length&& Object.keys(plan).length)){
        toast.error("PLease Fill the data :)", 3000)
        setLoading(false)
        return;
      }
      if(!shippingAddress||!shippingAddress2||!shippingCity||!shippingCountry||!shippingEmail||!shippingFullName||!shippingPhone){
        toast.error("Please Fill The Shipping Info :)",3000)
        setFillShipping(true)
        setShippingTab(true)
        return;
      }
        let data={email:email,
            country:country.value,
            coupon_code:coupon?Object.keys(coupon).length?coupon.value:"":"",
            plan_id:plan.value,
            payment_status:selectedPaymentStatus.value,
            payment_method:selectedPaymentMethod.value,
            date:dateValue, 
            }
         if(selectedPaymentMethod.value=="Money Transfer"){
            if(!refNumber){
              toast.error("PLease Fill the data :)", 3000)
              setLoading(false)
              return;
            }
            data.ref_number = refNumber;
         }  
         try{ 
            res=await axios.post('admin/v1/manual/manual_payment',data ,{
              headers: {
                Authorization: "Bearer " + Cookies.get("token"),
                },});
                toast.success("Paid ", 3000);
            }catch(e){
                toast.error("Something went wrong contact Dev Team",3000)
                handleReset()
                setLoading(false)
                return;
            }
    }   
    else if(stage=="Choose Payment"){
      if(!(email&&Object.keys(country).length&&Object.keys(treatment).length&&dateValue&& Object.keys(selectedPaymentMethod).length&& Object.keys(selectedPaymentStatus).length&&Object.keys(plan).length&&Object.keys(action).length)){
        toast.error("PLease Fill the data :)", 3000)
        setLoading(false)
        return;
      }
      if(!shippingAddress||!shippingAddress2||!shippingCity||!Object.keys(shippingCountry).length||!shippingEmail||!shippingFullName||!shippingPhone){
        toast.error("Please Fill The Shipping Info :)",3000)
        setFillShipping(true)
        return;
      }
        let data={email:email,
            country:country.value,
            coupon_code:coupon?Object.keys(coupon).length?coupon.value:"":"",
            plan_id:plan.value,
            payment_status:selectedPaymentStatus.value,
            payment_method:selectedPaymentMethod.value,
            date:dateValue,
            action:action.value
            }
        try{
        res=await axios.post('admin/v1/manual/manual_payment',data ,{
           headers: {
             Authorization: "Bearer " + Cookies.get("token"),
             },});
             toast.success(res.data.settle==true?"Paid Settle All":"Paid", 3000);
            }catch(e){
              toast.error("Something went wrong contact Dev Team",3000)
              handleReset()
              setLoading(false)
              return;
            }
    }   
    if(res.data.success){
        if(res.data.can_pay){
            if(res.data.payment_type=="first"){
                setStage("Choose Plan")
                toast.success("Please Choose a Plan ", 3000);
                let treatment={
                            label:"ID: " + res.data.tp.id + " Plan: "+res.data.tp.plan_type,
                            value: String(res.data.tp.id)}
                setOptionsTreatments([treatment]) 
                setTreatment(treatment)
                let plans = [];
            for (var i = 0; i < res.data.plans.length; i++) {
                plans.push({
                label:"PlanID: "+res.data.plans[i].id+" "+res.data.plans[i].title?.en,
                value: String(res.data.plans[i].id),
              });
            }
            setPlans(res.data.plans)
            setOptionsPlans(plans);
                
            }
            else if(res.data?.settle==true){
              handleReset()
            }
            else{
                setStage("Choose Payment")
                toast.success("Please Choose an Action ", 3000);
                setCoupon(res.data.coupon)
                 let treatment={
                            label:"ID: " + res.data.tp.id + " Plan: "+res.data.tp.plan_type,
                            value: String(res.data.tp.id)}
                setOptionsTreatments([treatment]) 
                setTreatment(treatment)
                let plan={
                        label:"PlanID: "+res.data.plan.id+" "+res.data.plan.title?.en,
                        value: String(res.data.plan.id)}
                setOptionsTreatments(plan) 
                let arr=[res.data.plan]
                setPlans(arr)
                setPlan(plan)
                setOptionsAction([{
                    label:"Settle All",
                    value:"Settle All"
                },{
                    label:"Monthly Payment",
                    value:"Monthly Payment"
                }])
            }
        }
        else{
            setStage("Choose TP")
            toast.success("Please Choose a Treatment ", 3000);
            let treatments = [];
            for (var i = 0; i < res.data.tps.length; i++) {
                treatments.push({
                label:"ID: " + res.data.tps[i].id + " Plan: "+res.data.tps[i].plan_type,
                value: String(res.data.tps[i].id),
              });
            }
            setOptionsTreatments(treatments);
        }
    }
    else{
      setStage("Check Eligible")
      toast.error(res.data.message, 3000);
    }
    setLoading(false)

  }
}
export default OfflineTPOrder;
