import React, { useCallback } from "react";
import { TextField, Card, Button } from "@shopify/polaris";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Videos = (props) => {
  const handleChangeVimeoId1 = useCallback((newValue) => {
    props.handleChangeVimeo1Value(newValue);
  }, []);
  const handleChangeVimeoId2 = useCallback((newValue) => {
    props.handleChangeVimeo2Value(newValue);
  }, []);

  return (
    <div>
        <Card.Section title="Vimeo ID 1">
          <TextField
            value={props.vimeo1Value}
            onChange={handleChangeVimeoId1}
            error={props.vimeo1Error}
            connectedRight={
              <CopyToClipboard text={props.vimeo1Value}>
                <Button>Copy Link</Button>
              </CopyToClipboard>
            }
          />
        </Card.Section>
        <Card.Section title="Vimeo ID 2">
          <TextField
            value={props.vimeo2Value}
            onChange={handleChangeVimeoId2}
            error={props.vimeo2Error}
            connectedRight={
              <CopyToClipboard text={props.vimeo2Value}>
                <Button>Copy Link</Button>
              </CopyToClipboard>
            }
          />
        </Card.Section>
        <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            primary
            onClick={props.autoSave}
            disabled={props.disabled}
          >
            Save
          </Button>
        </div>
      </div>
  );
  function copyLink() {
    var copyText = document.getElementById("myInput");
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy");
  }
};

export default React.memo(Videos);
