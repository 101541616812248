import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  filterContainer: {
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
    columnGap: "10px",
    margin: "15px 0",
  },
  inputContainer: {
    display: "grid",
  },
  input: {
    padding: "8px",
    borderRadius: "5px",
  },
}));
