import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide,
} from "@mui/material";
import { forwardRef } from "react";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function NeedReschedulingDialog({ open, setOpen, handleConfirm }) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ minWidth: "600px", fontSize: "16px" }}>
        Are you sure want to change it to need rescheduling?
      </DialogTitle>
      <DialogActions>
        <Button sx={{ fontSize: "12px" }} onClick={handleClose}>
          No
        </Button>
        <Button sx={{ fontSize: "12px" }} onClick={handleConfirm}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NeedReschedulingDialog;
