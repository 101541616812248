import {Button, Card, DataTable, Icon, Modal, Spinner, TextStyle} from '@shopify/polaris'
import React, {useEffect, useRef, useState} from 'react'
import NavigationLayout from '../components/NavigationLayout'
import axios from "../axios";
import Cookies from 'js-cookie';
import {
  ChevronLeftMinor,
  ChevronRightMinor,
} from '@shopify/polaris-icons';
import moment from 'moment';
import { TextArea } from 'semantic-ui-react';
import history from '../history';
import {capitalizeWords} from "../util/helpers";
import {DispatchTypeCodes} from "../util/constants";
import DispatchSearchBar from "./dispatch-search-bar.component";
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

const DispatchesList = () => {
  const [dispatches, setDispatches] = useState([]);
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [render, setRender] = useState(false)

  const handleNavigateToUserDispatches = (id) => {
    history.push(`/admin/users/${id}/dashboard`)
}
  const checkBoxRef = useRef(null);

  const [note, setNote] = useState('')
  const [open, setOpen] = useState(false)
  const [batchId, setBatchId] = useState('');
  const [loading, setLoading] = useState(false);
  const [treatmentPlanTypes, setTreatmentPlanTypes] = useState([]);
  const [dispatchCodes, setDispatchCodes] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({ value: 'all' });
  const [selectedType, setSelectedType] = useState({ value: 'all' });
  const [selectedProductType, setSelectedProductType] = useState({ value: 'all' });
  const [sortState, setSortState] = useState({relation: '', query: 'target_dispatch_date DESC'});
  const searchRef = useRef();
  const handleClose = () => {
    setOpen(false)
    setNote('')
  }
  const handleOpen = (id, note) => {
    setBatchId(id)
    setNote(note)
    setOpen(true)
  }
  const handleAddNote = () => {
    const body = { note, duplicate: checkBoxRef.current.checked }
    axios.post(`admin/v1/dispatches/${batchId}/add-note`, body, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((response)=> {
      handleClose()
      setRender(prev => !prev)
    })
  }
  
  const handleSort = (index) => {
    switch (index) {
      case 0:
        setSortState({
          relation: 'user',
          query: sortState.query === 'id ASC' ? 'id DESC' : 'id ASC',
        });
        break;
      case 1:
        setSortState({
          relation: 'user',
          query:
            sortState.query === 'first_name ASC, last_name ASC'
              ? 'first_name DESC, last_name DESC'
              : 'first_name ASC, last_name ASC',
        });
        break;
      case 2:
        setSortState({
          relation: 'smile_plan',
          query: sortState.query === 'type ASC' ? 'type DESC' : 'type ASC',
        });
        break;
      case 3:
        setSortState({
          relation: '',
          query:
            sortState.query === 'type_code ASC'
              ? 'type_code DESC'
              : 'type_code ASC',
        });
        break;
      default:
        setSortState({
          relation: '',
          query:
            sortState.query === 'target_dispatch_date ASC'
              ? 'target_dispatch_date DESC'
              : 'target_dispatch_date ASC',
        });
    }
  };
  
  const getDispatches = () => {
    setLoading(true);
    let searchQuery = null;
    if (searchRef.current) {
      searchQuery = searchRef.current.value;
    }
    let plan = 'all'
    if (selectedPlan?.value) {
      plan = selectedPlan.value;
    }
    let type = 'all';
    if (selectedType?.value) {
      type = selectedType.value;
    }
    let productType = 'all';
    if (selectedProductType?.value) {
      productType = selectedProductType.value;
    }
    const url = `admin/v1/dispatches?page=${page}&search=${searchQuery}&productType=${productType}&plan=${plan}&type=${type}&relation=${sortState.relation}&sort=${sortState.query}`;
    axios.get(url, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((response)=> {
      setPerPage(response?.data?.data.per_page);
      setTotal(response?.data?.data?.total);
      if (response?.data?.treatmentPlanTypes) {
        const treatmentPlanTypesData = response?.data?.treatmentPlanTypes;
        let treatmentPlanTypesArray = [{label: 'All', value: 'all'}];
        const treatmentPlanTypesMapped = Object.keys(treatmentPlanTypesData).map(
          (type, index) => {
            return { label: type, value: treatmentPlanTypesData[type] };
          }
        );
        treatmentPlanTypesArray = [...treatmentPlanTypesArray, ...treatmentPlanTypesMapped];
        setTreatmentPlanTypes(treatmentPlanTypesArray);
      }
      if (response?.data?.dispatchTypes) {
        const typesData = response?.data?.dispatchTypes;
        let typesArray = [{label: 'All', value: 'all'}];
        typesArray = [...typesArray, ...typesData];
        setDispatchCodes(typesArray);
      }
      const today = moment()
      setDispatches(response?.data?.data?.data.map((value) => {
        const dispatchDate = moment(value?.target_dispatch_date)
        const diffInDays = dispatchDate.diff(today, 'days');
        let upperAlignersSteps, lowerAlignersSteps, upperRem, lowerRem, upperProductQuantity, lowerProductQuantity;
        if (value.details.length > 0) {
          value.details.forEach((detail) => {
            if (detail.is_upper) {
              upperAlignersSteps = value.type_code === DispatchTypeCodes['aligners'] ? detail.steps.split('-').join(' - ') : detail.steps ?? '-';
              upperRem = detail.remaining_steps_to_be_distributed ?? '-';
              upperProductQuantity = detail.quantity ?? '-';
            } else {
              lowerAlignersSteps = value.type_code === DispatchTypeCodes['aligners'] ? detail.steps.split('-').join(' - ') : detail.steps ?? '-';
              lowerRem = detail.remaining_steps_to_be_distributed ?? '-';
              lowerProductQuantity = detail.quantity ?? '-';
            }
          });
        }
        
        const checkIfShopProduct = value?.is_shop_product === true ? '(SP)' : '';
        const checkIfChild = value?.parent_id ? 'L' : '';
        const batchNumber = value?.batch ? `${value?.batch} of ${value?.total} ${checkIfChild}` : '-';
        
        return [
          <p style={{cursor: "pointer", textAlign: 'center'}} onClick={() => handleNavigateToUserDispatches(value?.user_id)}>{value?.user_id}</p>,
          <p style={{cursor: "pointer", textAlign: 'center'}} onClick={() => handleNavigateToUserDispatches(value?.user_id)}>{value?.user?.full_name}</p>,
          <p style={{cursor: "pointer", textAlign: 'center'}} onClick={() => handleNavigateToUserDispatches(value?.user_id)}>{value?.treatment_stage}</p>,
          <p
            style={{ cursor: "pointer", textAlign: "center" }}
            onClick={() => handleNavigateToUserDispatches(value?.user_id)}
          >{`${checkIfShopProduct} ${value?.type?.name && capitalizeWords(value?.type?.name)}`}</p>,
          <p
            style={{ cursor: "pointer", textAlign: "center" }}
            onClick={() => handleNavigateToUserDispatches(value?.user_id)}
          >
            {batchNumber}
          </p>,
          <p style={{cursor: "pointer", textAlign: 'center'}} onClick={() => handleNavigateToUserDispatches(value?.user_id)}>{value?.target_dispatch_date}</p>,
          <p style={{cursor: "pointer", textAlign: 'center'}} onClick={() => handleNavigateToUserDispatches(value?.user_id)}>{diffInDays}</p>,
          <p style={{cursor: "pointer", textAlign: 'center'}} onClick={() => handleNavigateToUserDispatches(value?.user_id)}>{upperAlignersSteps}</p>,
          <p style={{cursor: "pointer", textAlign: 'center'}} onClick={() => handleNavigateToUserDispatches(value?.user_id)}>{lowerAlignersSteps}</p>,
          <p style={{cursor: "pointer", textAlign: 'center'}} onClick={() => handleNavigateToUserDispatches(value?.user_id)}>{upperRem}</p>,
          <p style={{cursor: "pointer", textAlign: 'center'}} onClick={() => handleNavigateToUserDispatches(value?.user_id)}>{lowerRem}</p>,
          <p style={{cursor: "pointer", textAlign: 'center'}} onClick={() => handleNavigateToUserDispatches(value?.user_id)}>{upperProductQuantity}</p>,
          <p style={{cursor: "pointer", textAlign: 'center'}} onClick={() => handleNavigateToUserDispatches(value?.user_id)}>{lowerProductQuantity}</p>,
          <p style={{cursor: "pointer", textAlign: 'center'}} onClick={() => handleNavigateToUserDispatches(value?.user_id)}>{value?.note && `${value?.note?.slice(0,10)}...`}</p>,
          <Button fullWidth primary onClick={() => handleOpen(value?.id, value?.note)}>Add Note</Button>
        ]
      }))
      }).finally(() => setLoading(false))
    }
    
  useEffect(() => {
    getDispatches()
  }, [page, render, sortState])
  
  return (
    <NavigationLayout>
      <div style={{padding: '30px 50px'}}>
      <Card title="Upcoming Dispatches">
        <Card.Section>
          <Modal open={open} onClose={handleClose}>
            <div style={{padding:"20px", textAlign:"center"}}>
              <center><TextStyle variation='strong'>Add Note</TextStyle></center>
              <br/>
              <TextArea rows={8} style={{width:"90%"}} value={note}  onChange={(e) => setNote(e.target.value)}/>
              <FormGroup style={{ width: "90%", margin: "0 auto" }}>
                <FormControlLabel
                  control={
                    <Checkbox inputRef={checkBoxRef} />
                  }
                  label="Copy note to upcoming batches"
                />
              </FormGroup>
              <div style={{display:"flex", justifyContent:"space-between", padding:"30px"}}>
                <Button primary onClick={handleAddNote}>Send Note</Button>
                <Button onClick={handleClose}>Close</Button>
              </div>
            </div>
          </Modal>
          <DispatchSearchBar
              searchRef={searchRef}
              setSelectedPlan={setSelectedPlan}
              setSelectedType={setSelectedType}
              setSelectedProductType={setSelectedProductType}
              getDispatches={getDispatches}
              treatmentPlanTypes={treatmentPlanTypes}
              dispatchCodes={dispatchCodes}
              loading={loading}
          />
          {loading ? <div style={{ display: "flex", justifyContent: "center", width: "100%", backgroundColor: "white", padding:"15px", paddingBottom:"50px", marginTop:"15px", marginBottom:"15px"  }}>
          <Spinner accessibilityLabel="Loading Upcoming Dispatches" size="large" />
        </div> :
        dispatches && dispatches.length > 0 ?
          <>
            <DataTable
                columnContentTypes={[
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                ]}
                headings={[
                  <p style={{textAlign: 'center', fontWeight: 'bold'}}>ID</p>,
                  <p style={{textAlign: 'center', fontWeight: 'bold'}}>User <br/>Name</p>,
                  <p style={{textAlign: 'center', fontWeight: 'bold'}}>Treatment <br/>Stage</p>,
                  <p style={{ textAlign: "center", fontWeight: "bold" }}>
                    Type
                  </p>,
                  <p style={{textAlign: 'center', fontWeight: 'bold'}}>Batch Number</p>,
                  <p style={{textAlign: 'center', fontWeight: 'bold'}}>Target <br/>Dispatch Date</p>,
                  <p style={{textAlign: 'center', fontWeight: 'bold'}}>Days <br/>To Target</p>,
                  <p style={{textAlign: 'center', fontWeight: 'bold'}}>Upper <br/> Steps</p>,
                  <p style={{textAlign: 'center', fontWeight: 'bold'}}>Lower <br/> Steps</p>,
                  <p style={{textAlign: 'center', fontWeight: 'bold'}}>Upper <br/> Remaining Steps</p>,
                  <p style={{textAlign: 'center', fontWeight: 'bold'}}>Lower <br/> Remaining Steps</p>,
                  <p style={{textAlign: 'center', fontWeight: 'bold'}}>Upper <br/> Product Quantity</p>,
                  <p style={{textAlign: 'center', fontWeight: 'bold'}}>Lower <br/> Product Quantity</p>,
                  <p style={{textAlign: 'center', fontWeight: 'bold'}}>Delivery <br/>Batch Note</p>,
                  <p style={{textAlign: 'center', fontWeight: 'bold'}}>Add <br/>Note</p>
                ]}
                rows={dispatches}
                sortable={[true, true, true, true, false, true, true, false, false, false, false, false, false, false, false]}
                defaultSortDirection="descending"
                verticalAlign='center'
                onSort={handleSort}
                hideScrollIndicator
              />
            <div style={{display:"flex", gap:"20px", alignItems:"center", justifyContent:"center", padding :"25px"}}>
              <button style={{border:"none", cursor:"pointer", background:"transparent"}} onClick={() => setPage(page - 1)} disabled={page == 1}><Icon source={ChevronLeftMinor} color={page == 1 ? "base" : "primary"}/></button>
              <span style={{fontWeight:"bold"}}>{page} / {total == 0 ? 1 : Math.ceil(total/perPage)}</span>
              <button style={{border:"none", cursor:"pointer", background:"transparent"}} disabled={total <= 0 || page == Math.ceil(total/perPage)} onClick={() => setPage(page + 1)}><Icon source={ChevronRightMinor} color={page == Math.ceil(total/perPage) || total <= 0 ? "base" : "primary"}/></button>
            </div>
          </> : <div style={{textAlign:"center", paddingTop:"20px"}}><TextStyle variation='strong'>No Batches Found</TextStyle></div>}
        </Card.Section>
       </Card>
      </div>
    </NavigationLayout>
  )
}

export default DispatchesList