import React, { useState } from 'react'
import OfflineTPOrder from './Offline/OfflineTPOrder'
import { Button, Card, Page } from '@shopify/polaris'
import { ToastContainer } from 'react-toastify'
import OfflineShopOrder from './Offline/OfflineShopOrder'
import "./Offline/ShopOrder.css"

function ManualPayments() {
  const [paymentType, setPaymentType] = useState(null)
  return (
    <Page
      title="Offline Payments"
      breadcrumbs={[{ content: "List Of Orders", url: "/admin/orders" }]}
    >
        <div className='offline-shop-btn-mode-wrapper'>
          <button className={`offline-shop-btn-mode ${paymentType =='tp'?'active':''}`} onClick={()=>{setPaymentType('tp')}}>TP Offline Order</button>
          <button className={`offline-shop-btn-mode ${paymentType =='shop'?'active':''}`} onClick={()=>{setPaymentType('shop')}}>Shop Offline Order</button>
        </div>
      <ToastContainer/>
        {paymentType == 'tp'?
          <OfflineTPOrder/> :
          paymentType =='shop'?
        <OfflineShopOrder/>: null}
    </Page>
  )
}

export default ManualPayments
