import { FormLayout } from '@shopify/polaris';
import CommercialInvoiceFormInput from './commercial-invoice-form-input.component';

function CommercialInvoiceForm({
  formData,
  setFormData,
  shippingFrom,
  setShippingFrom,
}) {
  const {
    descriptionOfGood,
    quantity,
    unitValue,
    subTotalValue,
    unitNetWeight,
    countryOfManufacture,
    commCode,
    totalDeclaredValue,
    totalNetWeight,
    totalUnit,
    totalGrossWeight,
  } = formData;

  const { name, email, country, city, phone, address } = shippingFrom;

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleChangeShipping = (field, value) => {
    setShippingFrom({ ...shippingFrom, [field]: value });
  };

  return (
    <form>
      <FormLayout>
        <FormLayout.Group>
          <CommercialInvoiceFormInput
            label="Description of Goods"
            type="text"
            handleChange={handleChange}
            fieldValue={descriptionOfGood}
            field={'descriptionOfGood'}
          />
          <CommercialInvoiceFormInput
            label="Quantity"
            type="number"
            handleChange={handleChange}
            fieldValue={quantity}
            field={'quantity'}
          />
          <CommercialInvoiceFormInput
            label="Unit Value"
            type="number"
            handleChange={handleChange}
            fieldValue={unitValue}
            field={'unitValue'}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <CommercialInvoiceFormInput
            label="Subtotal Value"
            type="number"
            handleChange={handleChange}
            fieldValue={subTotalValue}
            field={'subTotalValue'}
          />
          <CommercialInvoiceFormInput
            label="Unit Net Weight"
            type="number"
            handleChange={handleChange}
            fieldValue={unitNetWeight}
            field={'unitNetWeight'}
          />
          <CommercialInvoiceFormInput
            label="Country of Maufacture"
            type="text"
            handleChange={handleChange}
            fieldValue={countryOfManufacture}
            field={'countryOfManufacture'}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <CommercialInvoiceFormInput
            label="Comm. Code"
            type="text"
            handleChange={handleChange}
            fieldValue={commCode}
            field={'commCode'}
          />
          <CommercialInvoiceFormInput
            label="Total Declared Value"
            type="number"
            handleChange={handleChange}
            fieldValue={totalDeclaredValue}
            field={'totalDeclaredValue'}
          />
          <CommercialInvoiceFormInput
            label="Total Net Weight"
            type="number"
            handleChange={handleChange}
            fieldValue={totalNetWeight}
            field={'totalNetWeight'}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <CommercialInvoiceFormInput
            label="Total Unit"
            type="number"
            handleChange={handleChange}
            fieldValue={totalUnit}
            field={'totalUnit'}
          />
          <CommercialInvoiceFormInput
            label="Total Gross Weight"
            type="number"
            handleChange={handleChange}
            fieldValue={totalGrossWeight}
            field={'totalGrossWeight'}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <CommercialInvoiceFormInput
            label="Name"
            type="text"
            handleChange={handleChangeShipping}
            fieldValue={name}
            field={'name'}
          />
          <CommercialInvoiceFormInput
            label="Email"
            type="text"
            handleChange={handleChangeShipping}
            fieldValue={email}
            field={'email'}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <CommercialInvoiceFormInput
            label="Phone"
            type="text"
            handleChange={handleChangeShipping}
            fieldValue={phone}
            field={'phone'}
          />
          <CommercialInvoiceFormInput
            label="City"
            type="text"
            handleChange={handleChangeShipping}
            fieldValue={city}
            field={'city'}
          />
          <CommercialInvoiceFormInput
            label="Country"
            type="text"
            handleChange={handleChangeShipping}
            fieldValue={country}
            field={'country'}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <CommercialInvoiceFormInput
            label="Shipping Address"
            type="text"
            handleChange={handleChangeShipping}
            fieldValue={address}
            field={'address'}
          />
        </FormLayout.Group>
      </FormLayout>
    </form>
  );
}

export default CommercialInvoiceForm;
