import { Badge, Card, Layout } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import axios from '../../../../axios';
import Cookies from 'js-cookie';
import history from '../../../../history';

const WaiverComponent = ({ planType, pageTitle, status, clinicName, clinicStatus, userId }) => {
    const [isWaiverSubmitted, setIsWaiverSubmitted] = useState('Not Submitted');

    const handleShowMoreWavier = () => {
        history.push(
            `/admin/users/${userId}/dashboard/waiver`,
            {
              clinicName,
              planType,
              status,
              pageTitle,
              clinicStatus,
              id: userId,
            }
        );
    }

    function getDataWaiver() {
        axios.get(`/admin/v1/users/${userId}/waiver`, {
            headers: {
                Authorization: "Bearer " + Cookies.get("token"),
            },
        }).then((result) => {
            if (result.data.data !== null) {
                document.getElementById("waiverDiv").style.display = "block";
                setIsWaiverSubmitted("Submitted");
            }
        }).catch((err) => {
            console.log("errrrr=", err);
        });
    }

    useEffect(() => {
        getDataWaiver();
    }, [])

    return (
        <Layout.Section>
            <Card
                title="WAIVER"
                sectioned
                actions={[
                    {
                        content: "Show More",
                        onClick: handleShowMoreWavier
                    },
                ]}
            >
                <Badge>{isWaiverSubmitted}</Badge>
            </Card>
        </Layout.Section>
    )
}

export default WaiverComponent