import { Button, Card, CardActions, CardContent, CardHeader, Typography, Badge } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import TicketField from '../TicketField/TicketField'
import IconButton from "@material-ui/core/IconButton";
import FileUpload from '@mui/icons-material/AttachFile';
import { Close } from "@mui/icons-material";
import axios from "../../axios";
import Cookies from 'js-cookie';
import { source_options, type_options, urgency_options, extra_fields, admin_options } from '../helpers';
import "./CreateTicket.css"

function CreateTicket({ customStyle = {}, newMedia, setNewMedia, setTicketingModalActive }) {
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState('');
    let optionsToChange = ['tp_stage'];
    const [userDynamicOptions, setUserDynamicOptions] = useState({});
    const fileInputRef = useRef(null);
    const handleFileUploadClick = () => {
        fileInputRef.current && fileInputRef.current.click();
    };
    const handleFileChange = (event) => {
        let selectedFiles = Array.from(event.target.files || [])
        setNewMedia((prev) => [...prev, ...selectedFiles])
    };
    const handlePaste = (e) => {
        let pastedData = Array.from(e.clipboardData.files || [])
        setNewMedia((prev) => [...prev, ...pastedData])
    }
    useEffect(()=>{
        var regex = /\/users\/(\d+)\/dashboard/;
        let match = window.location.pathname.match(regex);
        if (match) {
          let userNumber = match[1];
          setFields((prev) => {
            let arr = [...prev];
            arr[0].value = userNumber
            return [...arr]
        })
        } 
      },[])
    const SubmitUser = async (user) => {
        setLoading(true)
        try {
            const response = await axios.post('admin/v1/tickets/get_user', { userId: user }, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("token"),
                },
            });
            let tp_stage = {}
            response.data.user.smile_plan_active.map((smile)=> {
                tp_stage[smile.name] = Math.max(smile.number_of_aligner_upper,smile.number_of_aligner_lower)
            })
            console.log(tp_stage)
            setUserDynamicOptions({"tp_stage":tp_stage})
            setUser(response.data.user.id)
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }
    const Submit = async () => {
        setLoading(true);
        let fieldObjects = fields.reduce((obj, item) => {
            const { field, ...value } = item;
            obj[item.field] = value;
            return obj;
        }, {});
        let arr = [...extraFields, ...fields].filter(field => !['subject_user', 'assigned_by', 'assigned_to', 'type', 'source', 'urgency', 'title'].includes(field.field));
        let message = ""
        arr.map((field) => {
            message += field.name + ": " + field.value + "<br/>"
        })
        let fileObjects = newMedia.map((file) => {
            const randomString = Math.random().toString(36).substring(2, 8);
            const filename = `${randomString}-${file.name}`;
            const mimeType = file.type;
            const extension = file.name.split('.').pop().toLowerCase();
            let fileType;
            if (mimeType.startsWith('image/') || extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif') {
                fileType = 'photo';
            } else if (mimeType.startsWith('video/') || extension === 'mp4' || extension === 'mov' || extension === 'wmv' || extension === 'avi') {
                fileType = 'video';
            } else {
                fileType = 'file';
            }
            return { filename, type: fileType }
        })
        let body = {
            text: fieldObjects.title.value,
            description: message,
            subject_user_id: fieldObjects.subject_user.value,
            reported_to_id: fieldObjects.assigned_to.value,
            reported_by_id: fieldObjects.assigned_by.value,
            urgency: fieldObjects.urgency.value,
            type: fieldObjects.type.value,
            source: fieldObjects.source.value,
        }
        try {
            let res = await getPresignedURLs(fileObjects)
            body['media'] = res;
            Promise.all(res.map(async (file, ind) => {
                await axios.put(file.url, newMedia[ind], {
                    headers: {
                        'Content-Type': file.type,
                    },
                });
            })).then(async (res) => {
                const response = await axios.post('admin/v1/tickets/create', body, {
                    headers: {
                        Authorization: "Bearer " + Cookies.get("token"),
                    },
                });
                setLoading(false);
                setTicketingModalActive(false)
            })
        } catch (e) {
            setLoading(false);
            setTicketingModalActive(false)
        }
    }
    async function getPresignedURLs(files) {
        let userIndex = fields.findIndex(field => field.field == "subject_user")
        const response = await axios.post('admin/v1/tickets/upload', {
            files: files,
            user_id: fields[userIndex].value
        }, {
            headers: {
                Authorization: "Bearer " + Cookies.get("token"),
            },
        });
        return response.data;
    }
    const onChangeAndAddFields = (ind, e, extra) => {
        let { value } = e.target;
        let targetField;
        if (extra) {
            targetField = extraFields[ind];
            if (targetField.type == 'checkbox') {
                let index = targetField.options.findIndex((option) => option.value == value)
                if (index >= 0 && targetField.options[index].extraFields) {
                    setExtraFields((prev) => [...prev, ...targetField.options[index].extraFields.map((field) => extra_fields[field])]);
                }
            }
            setExtraFields((prev) => {
                let arr = [...prev];
                arr[ind].value = value
                return [...arr]
            })
        } else {
            targetField = fields[ind];
            if (targetField.type == 'checkbox') {
                let index = targetField.options.findIndex((option) => option.value == value)
                if (index >= 0 && targetField.options[index].extraFields) {
                    setExtraFields([...targetField.options[index].extraFields.map((field) => {
                        let lookupField = extra_fields[field].field;
                        if (optionsToChange.indexOf(lookupField) >= 0) {
                            extra_fields[field].options = Object.keys(userDynamicOptions[lookupField]).map((key) => ({ name: key, value: key }));
                        }
                        return extra_fields[field];
                    })]);
                }
            }
            setFields((prev) => {
                let arr = [...prev];
                arr[ind].value = value
                return [...arr]
            })
        }
    }
    const [fields, setFields] = useState([
        { name: "User Id", field: "subject_user", value: "", type: "text", options: null, error: "", placeholder: "User Id", size: "20%", grow: "initial", required: true, customSubmit: SubmitUser },
        { name: "Type", field: "type", value: "", type: "checkbox", options: type_options, error: "", placeholder: "", size: "20%", grow: "initial", required: true, requireUser: true },
        { name: "Assigned by", field: "assigned_by", value: "", type: "checkbox", options: admin_options, error: "", placeholder: "", size: "20%", grow: "initial", required: true, requireUser: true },
        { name: "Assigned to", field: "assigned_to", value: "", type: "checkbox", options: admin_options, error: "", placeholder: "", size: "20%", grow: "initial", required: true, requireUser: true },
        { name: "Source", field: "source", value: "", type: "checkbox", options: source_options, error: "", placeholder: "", size: "20%", grow: "initial", required: true, requireUser: true },
        { name: "Priority", field: "urgency", value: "medium", type: "checkbox", options: urgency_options, error: "", placeholder: "", size: "20%", grow: "initial", required: true, requireUser: true },
        { name: "Title", field: "title", value: "", type: "text", options: null, error: "", placeholder: "Enter a Title for your Ticket", size: "45%", grow: "initial", required: true, requireUser: true },
        { name: "Description", field: "notes", value: "", type: "text", options: null, error: "", placeholder: "Enter any additional notes", size: "100%", grow: 1, required: false, requireUser: true },
    ])
    const [extraFields, setExtraFields] = useState([]);
    return (
        <div style={customStyle} onPaste={handlePaste}>
            <Card className='create-ticket-card' variant='elevation'>
                <CardHeader
                    title={
                        <Typography
                            variant='h3'
                        >
                            Add A New Ticket
                        </Typography>
                    }></CardHeader>
                <CardContent>
                    <div className='create-tikcet-filter-body'>
                        {fields.map((field, ind) =>
                            <div style={{ width: field.size, flexGrow: field.grow, position: "relative" }}>
                                <TicketField user={user} field={field} onChange={onChangeAndAddFields} ind={ind} extra={false} loading={loading}></TicketField>
                            </div>
                        )}
                        {extraFields.map((field, ind) =>
                            <div style={{ width: field.size, flexGrow: field.grow }}>
                                <TicketField user={user} field={field} onChange={onChangeAndAddFields} ind={ind} extra={true} allFields={[...fields, ...extraFields]} userDynamicOptions={userDynamicOptions}></TicketField>
                            </div>
                        )}
                    </div>

                </CardContent>
                <CardActions>
                    <Button size="large" color="secondary" variant='contained' onClick={Submit} >Create</Button>
                    <div >
                        <IconButton >
                            <Badge badgeContent={newMedia.length} >
                                <FileUpload style={{ width: "1.5em", height: "1.5em" }} onClick={handleFileUploadClick} />
                            </Badge>
                        </IconButton>
                        <input
                            type="file"
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            onChange={handleFileChange}
                        />
                        {newMedia.length ?
                            <div className='create-ticket-media-parent'>
                                {newMedia.map((media, ind) => <div className='create-ticket-media' key={ind}>{media.name}
                                    <Close style={{ position: "absolute", right: "0", top: "0" }} onClick={() => { setNewMedia((prev) => { let arr = [...prev]; arr.splice(ind, 1); return [...arr] }) }} /></div>)}
                            </div> : null}
                    </div>
                </CardActions>
            </Card>
        </div>
    )
}

export default CreateTicket