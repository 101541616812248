import React, { useState } from "react";
import { DataTable, TextStyle, Popover, Button } from "@shopify/polaris";
import Pagination from "@material-ui/lab/Pagination";
import "./DataTableComponent.css";

function DataTableComponent({ items, totalPages, page, tab, handleChange }) {
  const [activePopover, setActivePopover] = useState(null);

  const handleCellClick = (content) => {
    setActivePopover(content);
  };

  const handlePopoverClose = () => {
    setActivePopover(null);
  };

  const renderCellContent = (content) => {
    if (typeof content === "string" && content.length > 45) {
      return (
        <Popover
          active={activePopover === content}
          activator={
            <Button
            
              plain
              onClick={() =>
                handleCellClick(content)
              }
            >
              {content.slice(0, 45) + "..."}
            </Button>
          }
          onClose={handlePopoverClose}
        >
          <div style={{ padding: "20px"}}>{content}</div>
        </Popover>
      );
    } else {
      return content;
    }
  };

  const transformedItems = items.map((item) =>
    item.map((content, index) =>
      index === 2 ? renderCellContent(content) : content
    )
  );

  return (
    <div>
      <DataTable
        columnContentTypes={[
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
        ]}
        headings={[
          <TextStyle variation="strong">User ID</TextStyle>,
          <TextStyle variation="strong">Full Name</TextStyle>,
          <TextStyle variation="strong">Mobile Number</TextStyle>,
          <TextStyle variation="strong">Tracking Number</TextStyle>,
          <TextStyle variation="strong">Status</TextStyle>,
          <TextStyle variation="strong">Type</TextStyle>,
          <TextStyle variation="strong">Shipment Description</TextStyle>,
          <TextStyle variation="strong">Shipment Date</TextStyle>,
          <TextStyle variation="strong">Carrier</TextStyle>,
          <TextStyle variation="strong">Order Tracking ID</TextStyle>,
          <TextStyle variation="strong">Actions</TextStyle>,
        ]}
        rows={transformedItems}
        sortable={[
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ]}
        hideScrollIndicator
      />
      {totalPages >= 1 && (
        <div
          style={{
            paddingTop: "10px",
            textAlign: "center",
            paddingBottom: "10px",
          }}
        >
          <Pagination count={totalPages} page={page} onChange={handleChange} size="large" />
        </div>
      )}
    </div>
  );
}

export default DataTableComponent;
