import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    inputRow: {
        display: "flex",
        alignItems: "flex-end",
        height: "15%",
        zIndex: 6,
      },
      form: {
        width: "100%",
        padding: theme.spacing(0, 2, 1),
      },
      newMessageRow: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "end",
        flexWrap:"nowrap !important"
      },
      attachmentBadge: {
        "& .MuiBadge-badge": {
          fontSize: "1.5rem"
        }
      },
      send:{
        position: "relative",
        width: "1.5em !important",
        height: "1.5em !important",
      }
}));