import React from "react";
import { Switch, Route, Router, withRouter } from "react-router-dom";
import history from "../history";
import ListOfUsers from "./ListOfUsers";
import AddUser from "./AddUser";
import MainDashboard from "./MainDashboard";
import MainTrackings from "../Orders/MainTrackings";
import MainPayments from "../Payments/Main";
import ListOverduesUpcomings from "../Payments/ListOverduesUpcomings";
import NavigationLayout from "../components/NavigationLayout";
import Bookascan from "./Booking/Bookascan";
import Bookavisit from "./Booking/Bookavisit";
import ScanConfirmLoading from "./Booking/scan-cnfm-loading";
import BookingSetup from './Booking/BookingSetup'
import FreshDeskTicketCreation from "../FreshdeskTicketCreation";
import TeleConsultation from "./Teleconsultation/TeleConsultation";
import CaseDiscussionChatDashboard from "../components/CaseDiscussionChatDashboard/CaseDiscussionChatDashboard";
import OEMOverview from "../OEMDesigner/Kline";

function Users() {
  const isTeleConsultationPage = window.location.pathname.includes("teleconsult");

  return (
    <NavigationLayout isTeleConsultationPage={isTeleConsultationPage}>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/users" component={ListOfUsers} />
          <Route path="/admin/users/add" component={AddUser} />
          <Route path="/admin/users/book-a-scan" component={Bookascan} />
          <Route path="/admin/users/book-a-visit" component={Bookavisit} />
          <Route path="/en/scan-cnfm-loading" component={ScanConfirmLoading} />
          <Route path="/admin/users/booking-setup" component={BookingSetup} />
          <Route exact path="/admin/users/dashboard" component={CaseDiscussionChatDashboard} />
          <Route path="/admin/users/:id/dashboard" component={MainDashboard} />
          <Route path="/admin/users/:id/designer" component={OEMOverview} />
          <Route path="/admin/users/:id/teleconsult" component={TeleConsultation} />
          <Route path="/admin/users/:id/freshdesk" component={FreshDeskTicketCreation} />
          <Route path="/admin/orders/:id/trackings" component={MainTrackings} />
          <Route path="/admin/payments" component={MainPayments} />
          <Route
            path="/admin/payments/:id/overduesandupcomings"
            component={ListOverduesUpcomings}
          />
          
        </Switch>
      </Router>
    </NavigationLayout>
  );
}
export default Users;
