import React, { useState, useEffect, useContext } from "react";
import {
  InlineError,
  Checkbox,
  TextStyle,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Button,
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";
import { useParams } from "react-router-dom";
import { ShopContext } from "../ShopContext";
import { Editor } from "@tinymce/tinymce-react";
import RichTextEditor from "react-rte";
import ScreeningPhotoUpload from "../components/ScreeningPhotoUpload";
import Select from 'react-select';
import ShopProductCurrencies from "./ShopProductCurrencies";

function ShopProduct() {
  let { id } = useParams();
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const {
    states: {
      shopData,
    }, actions: {
      getShopProduct,
      storeShopProduct,
      deleteProductImage,
    },
  } = useContext(ShopContext);
  const [productContent, setProductContent] = useState(null);
  const [dispatchOption, setDispatchOption] = useState({value: 'reta', label: 'RETAINERS'});
  const [fieldRequired, setFieldRequired] = useState({
    title: {
      en: <InlineError message='' fieldID='myFieldID' />,
      ar: <InlineError message='' fieldID='myFieldID' />,
    },
    description: {
      en: <InlineError message='' fieldID='myFieldID' />,
      ar: <InlineError message='' fieldID='myFieldID' />,
    },
    url_identifier : <InlineError message='' fieldID='myFieldID' />
  });

  const handleImages = (e, id, ind) => {
    const file = e.target.files[0];
    let imageType = file.type;
    const form_data = new FormData();
    form_data.append('content_type', imageType);
    form_data.append('file_name', file.name);
    axios.post('/admin/v1/images/s3', form_data, {
      headers: {
        Authorization: `Bearer ${Cookie.get('token')}`,
      },
    }).then((res) => {
      let images = productContent.images;
      images[ind] = {
        id,
        file_url: validImageTypes.indexOf(imageType) > 0 ? window.URL.createObjectURL(file) : 'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304',
        key: res.data.key,
      };
      setProductContent(prev => ({
        ...prev,
        images,
      }));
      const url2 = res.data.url;
      axios.put(url2, file, {
        headers: {
          'x-amz-acl': 'public-read-write',
          'Content-Type': imageType,
        },
      }).then((res) => {
      }).catch((err) => {
        console.log('upload error 1', err);
      });
    }).catch((err) => {
      console.log('upload error 2', err);
    });
  };

  const handleSave = async() => {
    let proceed = true;
    let errors = fieldRequired;
    if(!productContent?.title?.en || !productContent?.title?.ar || !productContent?.description?.en || !productContent?.description?.ar || !productContent?.url_identifier) {
      proceed = false;
      if(!productContent?.url_identifier) {
        errors = {
          ...errors,
          url_identifier: <InlineError message='This field is required' fieldID='myFieldID' />
        };
      }
      if(!productContent?.title?.en) {
        errors = {
          ...errors,
          title: {
            ...errors.title,
            en: <InlineError message='This field is required' fieldID='myFieldID' />,
          }
        };
      }
      if(!productContent?.title?.ar) {
        errors = {
          ...errors,
          title: {
            ...errors.title,
            ar: <InlineError message='This field is required' fieldID='myFieldID' />,
          }
        };
      }
      if(!productContent?.description?.en) {
        errors = {
          ...errors,
          description: {
            ...errors.description,
            en: <InlineError message='This field is required' fieldID='myFieldID' />,
          }
        };
      }
      if(!productContent?.description?.ar) {
        errors = {
          ...errors,
          description: {
            ...errors.description,
            ar: <InlineError message='This field is required' fieldID='myFieldID' />,
          }
        };
      }
      if(!proceed) {
        setFieldRequired(errors);
      }
    }

    if(proceed && productContent) {
      let body = {
        ...productContent,
        code: dispatchOption.value,
      };
      if(productContent?.title) {
        body = {
          ...body,
          id: id ? id : null,
          title: JSON.stringify(body.title),
        }
      }
      if(productContent?.description?.ar) {
        body = {
          ...body,
          id: id ? id : null,
          description: JSON.stringify({
            ...body.description,
            ar: body.description.ar.toString('html'),
          })
        }
      }
      await storeShopProduct(body);
    }
  }

  const handleChange = (type, lang = null, value) => {
    setProductContent(prev => ({
      ...prev,
      [type]: lang ? {
        ...prev?.[type],
        [lang]: value,
      } : value,
    }));
  }

  const addNewPhoto = () => {
    let images = productContent?.images ? productContent?.images : [];
    images = [...images, {}];
    setProductContent(prev => ({
      ...prev,
      images,
    }));
  }

  const addNewPrice = () => {
    let prices = productContent?.prices ? productContent?.prices : [];
    prices = [...prices, {}];
    setProductContent(prev => ({
      ...prev,
      prices,
    }));
  }

  useEffect(() => {
    getShopProduct(id);
  }, []);

  useEffect(() => {
    setProductContent(shopData.product);
  }, [shopData.product]);

  return (
    <Page
      title={id ? 'Edit Product' : 'Add Product'}
      breadcrumbs={[{ content: "List Of Products", url: "/admin/shop/products" }]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Title En</TextStyle>
          <TextField value={productContent?.title?.en} onChange={(e) => handleChange('title', 'en', e)} />
          {fieldRequired.title.en}
          <TextStyle variation="strong">Title Ar</TextStyle>
          <TextField value={productContent?.title?.ar} onChange={(e) => handleChange('title', 'ar', e)} />
          {fieldRequired.title.ar}
          <TextStyle variation="strong">URL Identifier</TextStyle>
          <TextField value={productContent?.url_identifier} onChange={(e) => handleChange('url_identifier', null, e)} />
          {fieldRequired.url_identifier}
          <div className="shop-rich-text-wrapper">
            <div className="shop-rich-text-sub-wrapper">
              <TextStyle variation="strong">Description En</TextStyle>
              <Editor
                apiKey="z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve"
                value={productContent?.description?.en}
                init={{
                  branding: false,
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help",
                }}
                onChange={(e) => handleChange('description', 'en', e.target.getContent())}
              />
              {fieldRequired.description.en}
            </div>
            <div className="shop-rich-text-sub-wrapper">
              <TextStyle variation="strong">Description Ar</TextStyle>
              <RichTextEditor
                className="shop-rich-text-editor"
                value={productContent?.description?.ar ? productContent.description.ar : RichTextEditor.createEmptyValue()}
                onChange={(e) => handleChange('description', 'ar', e)}
              />
              {fieldRequired.description.ar}
            </div>
          </div>

          <div>
            <Select
              options={shopData.dispatchOptions}
              onChange={(e) => setDispatchOption(e)}
              value={dispatchOption}
            />
          </div>

          <div style={{ textAlign: "right", marginTop: "1rem" }}>
            <Button onClick={addNewPhoto}>Add image</Button>
          </div>
          <div className='shop-product-images-wrapper'>
            {Array.from(Array(productContent?.images ? productContent.images.length : 0).keys()).map((idx) => (
              <ScreeningPhotoUpload
                key={idx}
                idx={idx}
                value={productContent?.images?.[idx]?.file_url ? productContent.images[idx].file_url : 'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304'}
                handleImages={handleImages}
                id={productContent?.images?.[idx]?.id ? productContent.images[idx].id : null}
                title=''
                handleDelete={() => deleteProductImage(productContent?.images?.[idx]?.id, id)}
                imagesArray={productContent?.images ? productContent.images.map(image => ({id: image.id, url: image.file_url})) : []}
                identifier={`shop-product-${idx}`}
              />
            ))}
          </div>
          {
            id &&
            <ShopProductCurrencies
              product_id={id}
              items={productContent?.prices ? productContent.prices : []}
              addNewPrice={addNewPrice}
              isEmpty={!productContent?.prices?.length}
            />
          }
          <Checkbox
            label="Is New"
            checked={productContent?.is_new}
            onChange={(e) => handleChange('is_new', null, e)}
          />
          <Checkbox
            label="Is Trending"
            checked={productContent?.is_trending}
            onChange={(e) => handleChange('is_trending', null, e)}
          />
          <Checkbox
            label="Tabby Product"
            checked={productContent?.tabby_product}
            onChange={(e) => handleChange('tabby_product', null, e)}
          />
          <Checkbox
            label="Guest Product"
            checked={productContent?.guest_product}
            onChange={(e) => handleChange('guest_product', null, e)}
          />
          <Checkbox
            label="Archive"
            checked={productContent?.archived}
            onChange={(e) => handleChange('archived', null, e)}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: shopData.loading,
          disabled: shopData.loading,
        }}
      />
    </Page>
  );
}

export default ShopProduct;
