import { Card } from '@shopify/polaris'
import moment from 'moment'
import React from 'react'
import {getStatusBadgeComponent} from "../helpers";

function TicketingWorkFlow({workflows = []}) {
  return (
    <Card>
        <div style={{height:"40vh", width:"100%",}}>
            <div style={{fontSize:"large", padding:"8px"}}><b>Activity History</b></div>
            <div style={{overflowY:"auto", height:"85%"}}>
                {workflows.map((workflow)=>
                <div style={{padding:"16px",borderBottom:"1px solid #efe9e9",fontSize:"14px"}}>
                    <div>Status: {getStatusBadgeComponent(workflow.status)}</div>
                    <div>Changed by: {workflow.created_by.full_name}</div>
                    <div style={{textAlign:"end"}}>{moment.utc(workflow.created_at).local().format("YYYY-MM-DD HH:mm:ss")}</div>
                </div>
                )}
            </div>
        </div>
    </Card>
  )
}

export default TicketingWorkFlow