import { Button, Card, Select, TextField } from '@shopify/polaris';
import React from 'react'
import Videos from './Videos';
import ThreeDfilesDropZone from './ThreeDfilesDropZone';
import CopyToClipboard from 'react-copy-to-clipboard';

const ThreeDSimulation = ({
  selectedOption,
  handleSelectChangeOption,
  vimeo1Value,
  vimeo2Value,
  vimeoRefinementValue,
  vimeo1Error,
  vimeo2Error,
  vimeoRefinementError,
  handleChangeVimeo1Error,
  handleChangeVimeo2Error,
  handleChangeRefinementError,
  handleChangeVimeo1Value,
  handleChangeVimeo2Value,
  handleChangeRefinementValue,
  handleChangeArrayElements,
  numberOf3dFiles,
  setNumberOf3dFiles,
  displayable3dFiles,
  setDisplayable3dFiles,
  dataId,
  id,
  yourSmilePlanData,
  handleLearnMore,
  setHandleLearnMore,
  createMode,
  autoSave,
  disabled,
  oem3DLink,
  setOem3DLink
}) => {
  const options = [
    { label: "Enable 3D Files Only", value: "1" },
    { label: "Enable Video Only", value: "2" },
    { label: "Enable External 3D Viewer Link", value: "3" },
  ];

  return (
    <div style={{ width: '100%' }}>
      <Card
        sectioned
        actions={
          selectedOption != '1' ?
            []
            :
            [
              {
                content: "Learn more",
                onClick: () => setHandleLearnMore(true)
              }
            ]
        }
      >
        <Select
          options={options}
          onChange={handleSelectChangeOption}
          value={selectedOption}
        />
        {selectedOption === "2" && (
          <Videos
            vimeo1Value={vimeo1Value}
            vimeo2Value={vimeo2Value}
            vimeoRefinementValue={vimeoRefinementValue}
            vimeo1Error={vimeo1Error}
            vimeo2Error={vimeo2Error}
            vimeoRefinementError={vimeoRefinementError}
            handleChangeVimeo1Error={handleChangeVimeo1Error}
            handleChangeVimeo2Error={handleChangeVimeo2Error}
            handleChangeRefinementError={handleChangeRefinementError}
            handleChangeVimeo1Value={handleChangeVimeo1Value}
            handleChangeVimeo2Value={handleChangeVimeo2Value}
            handleChangeRefinementValue={handleChangeRefinementValue}
            autoSave={autoSave}
            disabled={disabled}
          />
        )}
        {selectedOption === "3" && (
          <Card.Section title="External 3D Viewer Link">
          <TextField
            value={oem3DLink}
            onChange={setOem3DLink}
            connectedRight={
              <CopyToClipboard text={oem3DLink}>
                <Button>Copy Link</Button>
              </CopyToClipboard>
            }
          />
        </Card.Section>
        )}

        {selectedOption === "1" && (
          <ThreeDfilesDropZone
            handleChangeArrayElements={handleChangeArrayElements}
            numberOf3dFiles={numberOf3dFiles}
            setNumberOf3dFiles={setNumberOf3dFiles}
            displayable3dFiles={displayable3dFiles}
            setDisplayable3dFiles={setDisplayable3dFiles}
            plan_id={dataId}
            id={id}
            files3D={yourSmilePlanData}
            handleLearnMore={handleLearnMore}
            setHandleLearnMore={setHandleLearnMore}
            createMode={createMode}
            autoSave={autoSave}
            disabled={disabled}
          />
        )}
      </Card>
    </div>
  )
}

export default ThreeDSimulation