import { Card, Page } from '@shopify/polaris';
import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import axios from "../../axios";
import Loading from '../../components/Loading/loading';
import SingleClinic from './SingleClinic';

export default function Bookascan() {
  const [clinics, setClinics] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      getAllClinicsHandler();
  }, []);
  const getAllClinicsHandler = () => {
    setLoading(true);
    axios
      .get("admin/v1/clinics", {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((response) => {
        setClinics([...response.data.data]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
    return (
loading ? <Loading/> : <Page>
          <Card title='Choose Clinic'>
          {clinics.map((clinic) => (
            <SingleClinic
              title={clinic.name['en']}
              address={clinic.address['en']}
              clinicData={clinic}
            />
        ))}
        </Card>
        </Page>
    )
}