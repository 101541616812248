import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Card } from '@shopify/polaris';
import { useState } from 'react';

export default function TicketingMedia({ media = [], setPreviewImages }) {

  let photoArray = media.filter(arr => arr.type == 'photo');
  let videoArray = media.filter(arr => arr.type == 'video');
  let fileArray = media.filter(arr => arr.type == 'file');
  let base = process.env.REACT_APP_AWS_URL

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Card>
      <div style={{ fontSize: "large", padding: "8px" }}><b>Files</b></div>
      <div style={{ maxHeight: "40vh" }}>
        <Accordion disableGutters expanded={expanded == 'photo-accordion'} onChange={handleChange('photo-accordion')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="photo-accordion-content"
            id="photo-accordion-header"
          >
            <Typography>Photos</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ width: "100%", whiteSpace: "nowrap", overflowX: "auto", display: "flex", gap: "5%" }}>
              {photoArray.map((photo, startIndex) =>
                <img src={base + photo.url} style={{ height: "100px", width: "45%", objectFit: "cover" }}  onClick={()=>{setPreviewImages({ images: [...photoArray], startIndex }) }}/>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion disableGutters expanded={expanded == 'video-accordion'} onChange={handleChange('video-accordion')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="video-accordion-content"
            id="video-accordion-header"
          >
            <Typography>Videos</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ width: "100%", whiteSpace: "nowrap", overflowX: "auto", display: "flex", gap: "5px", paddingBottom: '10px' }}>
              {videoArray.map((video, startIndex) =>
                <a href={base + video.url} target='_blank'>{video.name ?? "Video " + startIndex}</a>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion disableGutters expanded={expanded == 'file-accordion'} onChange={handleChange('file-accordion')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="file-accordion-content"
            id="file-accordion-header"
          >
            <Typography>Files</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ width: "100%", whiteSpace: "nowrap", overflowX: "auto", display: "flex", gap: "5px", paddingBottom: '10px' }}>
              {fileArray.map((file, startIndex) =>
                <a href={base + file.url} target='_blank'>{file.name ?? "File " + startIndex}</a>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </Card>
  );
}
