import { useEffect } from 'react';
import ThinLoading from '../../components/Loading/thin-loading.component';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import TicketsUserTableRow from './tickets-user-table-row.component';
import { useStyles } from './tickets-user-table.styles';

const TicketsUserTable = ({ getTickets, loading, tickets, currentPage }) => {
  const classes = useStyles();

  useEffect(() => {
    getTickets();
  }, [currentPage]);

  if (loading) {
    return <ThinLoading label="loading tickets" />;
  }
  return (
    <TableContainer component={Paper}>
      <Table
        classes={{ root: classes.table }}
        stickyHeader
        aria-label="view tickets table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>Ticket ID</TableCell>
            <TableCell>Ticket Type</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Reported by</TableCell>
            <TableCell>Reported to</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tickets.map((ticket, index) => (
            <TicketsUserTableRow ticket={ticket} key={index} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TicketsUserTable;
