export const formatShippingAddress = (tracking, setShippingTo) => {
  if (tracking) {
    let user, shipping;
    if (tracking?.dispatch?.user?.get_shipping_info) {
      user = tracking?.dispatch?.user;
    } else if (tracking?.order?.user?.get_shipping_info) {
      user = tracking?.order?.user;
    }
    if (user) {
      shipping = user?.get_shipping_info;
    }
    setShippingTo({
      email: user?.email ?? '',
      name: user?.full_name ?? '',
      address: `${shipping?.address ?? ''}, ${shipping?.address_2 ?? ''}, ${
        shipping?.address_3 ?? ''
      }`,
      city: shipping?.city ?? '',
      country: shipping?.country ?? '',
      phone: user?.phone ?? '',
    });
  }
};
