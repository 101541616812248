import React, { useEffect, useState } from "react";
import {
  InlineError,
  Checkbox,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Card,
  PageActions,
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";
import history from "../history";
import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.

import { useParams } from "react-router-dom";
import PaymentCurrency from "./PaymentCurrency";

function EditSubPlan() {
  let { payment_id,id } = useParams();

  const [isEmpty, setIsEmpty] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);
    const[success,setSuccess]=useState(0);
  const [valuePrice, setValuePrice] = useState("0");
  const [checked, setChecked] = useState(false);
  const [fieldRequiredPrice, setFieldRequiredPrice] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  useEffect(() => {
    getData();
  }, [success]);

  function getData() {
    axios
      .get(`/admin/v1/plan_payments_currency/${payment_id}`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        if(result.data.success==false){
          setIsEmpty(true);
          setIsLoading(false);
          
        }
        else if (result.data.body.prices.length === 0) {
          setIsEmpty(true);
        }
        setValuePrice(result.data.body.price+"")
        setChecked(result.data.body.down_payment)
        setIsLoading(false);
        let res = [];
         res = result.data.body.prices.map((item)=>{
             return{
            id:item.id,   
            local_price:item.local_price,
            currency:item.currency,
            country:item.country
             }
         })
        setItems(res);
      })
      .catch((err) => console.log(err));
  }
  return (
    <Page
      title="Edit Payment"
      breadcrumbs={[
        {
          content: "List Of Payments",
          url: "/admin/plans/" + id + "/payments",
        },
      ]}
    >
      <Card sectioned>
        <FormLayout>
        <PaymentCurrency plan_payment_id={payment_id} items={items} setItems={setItems} isEmpty={isEmpty} setIsEmpty={setIsEmpty} success={success} setSuccess={setSuccess}/>
          <TextStyle variation="strong">Price</TextStyle>
          <TextField
            value={valuePrice}
            onChange={handleChangePrice}
            type="number"
            prefix="$"
          />
          {fieldRequiredPrice}

          <Checkbox
            label="Down Payment"
            checked={checked}
            onChange={handleChangeCb}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
        secondaryActions={[
          {
            content: "Delete",
            onClick: () =>
              axios
                .delete(
                  "/admin/v1/plans/" +
                    Cookie.get("id") +
                    "/payments/" +
                    payment_id +
                    "/delete",
                  {
                    headers: {
                      Authorization: "Bearer " + Cookie.get("token"),
                    },
                  }
                )
                .then((result) => {
                  history.push(
                    "/admin/plans/" +id + "/payments"
                  );
                })
                .catch((err) => console.log(err)),
          },
        ]}
      />
    </Page>
  );
  function handleChangePrice(valuePrice) {
    setValuePrice(valuePrice);
  }

  function handleChangeCb(checked) {
    setChecked(checked);
  }
  function handleSave() {
    if (valuePrice === "") {
      if (valuePrice === "")
        setFieldRequiredPrice(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
    } else {
      const bodyObj = {
        price: valuePrice,
        down_payment: checked,
      };
      axios
        .post(
          "/admin/v1/plans/" +
            Cookie.get("id") +
            "/payments/" +
            payment_id +
            "/update",
          bodyObj,
          {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          }
        )
        .then((res) => {
          history.push("/admin/plans/" +id + "/payments");
        })
        .catch((err) => console.log(err));
    }
  }
}
export default EditSubPlan;
