import React, { useState, useEffect } from "react";
import {
  Page,
  FormLayout,
  Card,
  Spinner,
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";

import { useParams } from "react-router-dom";

function ViewAllUsers() {
    let { id } = useParams();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    let usersBoxStyles = {
        background: "#0074D9",
        color: "#fff",
        borderBottom: "1px solid #fff",
        display:"inline-block",
        margin:"0px 5px",
        cursor:"pointer",
        padding:"5px 10px",
    }
    useEffect(() => {
          axios.get(`admin/v1/plans/${id}/users`, {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          }).then(function (responses) {
            setUsers(responses.data.data);
            setLoading(false);
          })
      }, []);
  return (
    <Page
      title="All Users"
      breadcrumbs={[{ url: "/admin/plans/"+id, content: "Back to Plan"}]}
    >
      <Card sectioned>
        {loading?
            <div style={{
                position: "fixed",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                background: "rgba(255,255,255,0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            
            }}>
                <Spinner accessibilityLabel="Spinner example" size="large" color="teal" />
            </div>
        :null}
        <FormLayout>
            {users.length?<div style={{padding: "10px",margin: "10px 0px",border: "1px solid #d9d9d9",borderRadius: "3px",}} >
                {users.map((item,ind)=>
                    <div key={ind} style={usersBoxStyles} >
                    {item.full_name}
                    </div>
                )}
            </div> :null}
        </FormLayout>
      </Card>
    </Page>
    
  )
}

export default ViewAllUsers