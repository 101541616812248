import React, { useCallback, useEffect, useState } from "react";
import {
  InlineError,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  Card,
  PageActions,
  DropZone,
  Thumbnail,
  Icon,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";
import { Editor } from "@tinymce/tinymce-react";
import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.
import "@pathofdev/react-tag-input/build/index.css";
import {
  CircleCancelMajor
} from '@shopify/polaris-icons';

import { useParams } from "react-router-dom";
function AddComment() {
  let { id } = useParams();
  let imageType = "";
  const [valueComment, setvalueComment] = useState("");
  const [filesImage1, setFilesImage1] = useState([]);
  const [imagesKey,setImageKey] =useState([])
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

  const [popupActive, setPopupActive] = useState(false);
  const handleDropZoneImage1 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesImage1((filesImage1) => [...filesImage1, ...acceptedFiles]),
    []
  );
  const removeImageKey = (key)=>{
    setImageKey(imagesKey=> imagesKey.filter((image,ind)=>(ind != key)))
    setFilesImage1(filesImage1=> filesImage1.filter((file,ind)=>(ind != key)))
  }
  const sendImage1 = useCallback(
    (filesImage1) => {
      setPopupActive(true);
      if(validImageTypes.indexOf(filesImage1[filesImage1.length - 1].type) < 0){
        return;
      }
      imageType = filesImage1[filesImage1.length - 1].type;
      let url2 = "";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKey(prev=>[...prev,res.data.key]);
          url2 = res.data.url;
          axios
            .put(url2, filesImage1[filesImage1.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesImage1]
  );

  const [fieldRequiredComment, setfieldRequiredComment] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const handleChangeComment = (e) => {
    setvalueComment(e);
  };
  return (
    <Page
      title="Add Comment"
      breadcrumbs={[
        {
          content: "Dashboard",
          url: "/admin/users/" + id + "/dashboard",
        },
      ]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Comment</TextStyle>

          <Editor
            apiKey="z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve"
            value={valueComment}
            init={{
              branding: false,
              height: 200,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
            }}
            onEditorChange={handleChangeComment}
          />

          {fieldRequiredComment}
        </FormLayout>
        <div style={{display:"flex", gap: "10px"}}>
        {filesImage1.map((file,key)=>
        imagesKey.length>key ?<div key={key} style={{position:"relative"}}>
          <div style={{position:"absolute",zIndex:1,right:0}} onClick={()=>removeImageKey(key)}>
            <Icon
            source={CircleCancelMajor}
            color="base"
          />
        </div>
        <Thumbnail
            size="large"
            source={window.URL.createObjectURL(file) }
          />
          </div>
         :<div key={key} style={{position:"relative"}}>
         <Thumbnail
         size="large"
         source="https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
       />
       </div>)}
         </div>
          <DropZone onDrop={handleDropZoneImage1} onDropAccepted={sendImage1}>
            <DropZone.FileUpload/>
          </DropZone>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
    </Page>
  );

  function handleSave() {
    if (valueComment === "") {
      setfieldRequiredComment(
        <InlineError message="This field is required" fieldID="myFieldID" />
      );
    } else {
      const bodyObj = {
        comment: valueComment,
        images:imagesKey
      };
      axios
        .post("admin/v1/users/" + id + "/comments/", bodyObj, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          history.push("/admin/users/" + id + "/dashboard");
          //  history.push('/admin/faqsupport/viewCategory')
        })
        .catch((err) => console.log(err));
    }
  }
}
export default AddComment;
