import React, { useState, useEffect } from "react";
import {
  Layout,
  Card,
  FormLayout,
  Thumbnail,
  Badge,
  Button,
  TextStyle,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import "@zendeskgarden/react-pagination/dist/styles.css";
import { useParams } from "react-router-dom";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import moment from "moment";
import CustomTooltip from "./../../../components/CustomTooltip";

function PreScreening() {
  let { id } = useParams();
  const [responseScreening, setResponseScreening] = useState(null);
  const[aws,setAws]=useState('');
  const [qualifiedValue, setQualifiedValue] = useState("");
  const [unqualifiedReason, setUnqualifiedReason] = useState('');
  useEffect(() => {
    axios
      .get(`admin/v1/users/${id}/prepurchase`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setResponseScreening(result.data.data);
        setAws(result.data.url);
        setUnqualifiedReason(result.data.data.unqualified_reason)
        isDefined(result.data.data.is_qualified) &&
          getQualifiedStatus(result.data.data.is_qualified);
        let arrayImages = [
          result.data.data.image_1,
          result.data.data.image_2,
          result.data.data.image_3,
        ];
        setImages(arrayImages);
      })
      .catch((err) => console.log(err));
  }, []);
  const [value, setValue] = useState("");

  function handleChange(_checked, newValue) {
    setValue(newValue);
  }
  const [isOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  return (
    <Layout.Section>
      <Card
        title="SCREENING"
        actions={[
          {
            content: "Edit",
            url: `/admin/leads/${id}/editprescreening`,
          },
          { content: <CustomTooltip title="" /> },
        ]}
        sectioned
      >
        {responseScreening && (
          <Card.Section sectioned title="Images">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  id="0"
                  onClick={(e) => handleShowLargeImage(e)}
                  style={{ marginBottom: 10 }}
                >
                  <Thumbnail
                    size="large"
                    source={
                      responseScreening?.image_1
                        ?aws+"/"+ responseScreening.image_1
                        : ""
                    }
                  />
                </div>
                <TextStyle>Image 1</TextStyle>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginInlineStart: 20,
                }}
              >
                <div
                  id="1"
                  onClick={(e) => handleShowLargeImage(e)}
                  style={{ marginBottom: 10 }}
                >
                  <Thumbnail
                    size="large"
                    source={
                      responseScreening?.image_2
                        ?aws+"/"+ responseScreening.image_2
                        : ""
                    }
                  />
                </div>
                <TextStyle>Image 2</TextStyle>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginInlineStart: 20,
                }}
              >
                <div
                  id="2"
                  onClick={(e) => handleShowLargeImage(e)}
                  style={{ marginBottom: 10 }}
                >
                  <Thumbnail
                    size="large"
                    source={
                      responseScreening?.image_3
                        ? aws+"/"+responseScreening.image_3
                        : ""
                    }
                  />
                </div>
                <TextStyle>Image 3</TextStyle>
              </div>
            </div>
          </Card.Section>
        )}

        {responseScreening && (
          <Card.Section sectioned title="Questions">
            <FormLayout>
              {responseScreening.date_of_birth && (
                <div>
                  {" "}
                  <TextStyle variation="strong">Date of birth: </TextStyle>
                  <TextStyle>{responseScreening.date_of_birth}</TextStyle>
                </div>
              )}
              {responseScreening.problem_question ? (
                <div>
                  {" "}
                  <TextStyle variation="strong">
                    What problem are you looking to get fixed?/Chief complaint{" "}
                  </TextStyle>
                  <TextStyle>{responseScreening.problem_question}</TextStyle>
                </div>
              ) : null}
              {responseScreening.visited_dentist === true ||
              responseScreening.visited_dentist === false ? (
                <div>
                  <TextStyle variation="strong">
                    Have you visited your dentist in the past 6 months?{" "}
                  </TextStyle>
                  <TextStyle>
                    {responseScreening.visited_dentist === true ? "Yes" : "No"}
                  </TextStyle>
                </div>
              ) : null}
              {responseScreening.visited_dentist === true ? (
                <div>
                  {" "}
                  <TextStyle variation="strong">
                    Are your teeth and gums healthy?{" "}
                  </TextStyle>
                  <TextStyle>
                    {responseScreening.teeth_healthy === true ? "Yes" : "No"}
                  </TextStyle>
                </div>
              ) : null}
              {responseScreening.explain_issue ? (
                <div>
                  {" "}
                  <TextStyle variation="strong">
                    Please explain the issue:{" "}
                  </TextStyle>
                  <TextStyle>{responseScreening.explain_issue}</TextStyle>
                </div>
              ) : null}
              {responseScreening.wisdom_teeth ? (
                <div>
                  {" "}
                  <TextStyle variation="strong">
                    {" "}
                    Do you have any wisdom teeth?{" "}
                  </TextStyle>
                  <TextStyle>{responseScreening.wisdom_teeth}</TextStyle>
                </div>
              ) : null}

              {responseScreening.wisdom_teeth === "yes" ? (
                <div>
                  {" "}
                  <TextStyle variation="strong">
                    According to your dentist, do your wisdom teeth need
                    removal?{" "}
                  </TextStyle>
                  <TextStyle>
                    {responseScreening.wisdom_need_removal === true
                      ? "Yes"
                      : "No"}
                  </TextStyle>
                </div>
              ) : null}
              {responseScreening.arches_to_treat ? (
                <div>
                  {" "}
                  <TextStyle variation="strong">
                    Which arches do you want to treat?{" "}
                  </TextStyle>
                  <TextStyle>
                    {getArchesToTreat(responseScreening.arches_to_treat)}
                  </TextStyle>
                </div>
              ) : null}
              {responseScreening.dental_history ? (
                <div>
                  {" "}
                  <TextStyle variation="strong">Any other dental history we should know about (implants, bridges, fillings, etc.)?/Clinical findings{" "}</TextStyle>
                  <TextStyle>{responseScreening.dental_history}</TextStyle>
                </div>
              ) : null}

              {responseScreening.is_qualified_at ? (
                <div>
                  {" "}
                  <TextStyle variation="strong">Status changed at: </TextStyle>
                  <TextStyle>
                    {moment(responseScreening.is_qualified_at)
                      .add(isBetween(moment().month(), 4, 11) ? 3 : 2, "hours")
                      .format("YYYY-MM-DD hh:mm:ss")}
                  </TextStyle>
                </div>
              ) : null}

              {qualifiedValue && <Badge>{qualifiedValue}</Badge>}
              <span>Reason: {unqualifiedReason && <Badge>{unqualifiedReason}</Badge> }</span>
            </FormLayout>
          </Card.Section>
        )}

        {/* <h1>{photoIndex}</h1> */}
        {isOpen && <Lightbox startIndex={photoIndex} onClose={() => setIsOpen(false)} images={images}></Lightbox>}
      </Card>
    </Layout.Section>
  );
  function getQualifiedStatus(param) {
    switch (param) {
      case 0:
        setQualifiedValue("Not Qualified");
        break;
      case 1:
        setQualifiedValue("Qualified Both");
        break;
      case 2:
        setQualifiedValue("Qualified Top");
        break;
      case 3:
        setQualifiedValue("Qualified Bottom");
        break;
    }
  }
  function getArchesToTreat(param) {
    switch (param) {
      case 1:
        return "Top";
      case 2:
        return "Bottom";
      case 3:
        return "both";
    }
  }
  function isDefined(value) {
    if (value !== undefined && value !== null) return true;
  }
  function handleShowLargeImage(e) {
    setPhotoIndex(parseInt(e.currentTarget.id));
    setIsOpen(true);
  }
  function handleChange(_checked, newValue) {
    setValue(newValue);
    const bodyObj = {
      is_qualified: newValue === "qualified" ? true : false,
    };
    axios
      .post(`admin/v1/users/${id}/your-photos/qualified `, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {})
      .catch((err) => console.log(err));
  }
  function isBetween(n, a, b) {
    return (n - a) * (n - b) <= 0;
  }
}

export default React.memo(PreScreening);
