import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "white",
    padding: "15px",
    marginTop: "15px",
    marginBottom: "15px",
  },
}));
