import { useParams } from 'react-router-dom';
import { Card, Layout, Pagination } from '@shopify/polaris';
import { Modal } from '@material-ui/core';
import CreateTicket from '../CreateTicket/CreateTicket';
import React, { useState } from 'react';
import TicketsUserTable from './tickets-user-table.component';
import axios from '../../axios';
import Cookie from 'js-cookie';

const overlayTicketingStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  p: 4,
};

const TicketsUser = () => {
  const { id } = useParams();
  const [ticketingModalActive, setTicketingModalActive] = useState(false);
  const [newMedia, setNewMedia] = useState([]);
  const [loadingTickets, setLoadingTickets] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const handleClose = () => setTicketingModalActive(false);
  const handleOpen = () => setTicketingModalActive(true);

  const getTickets = async () => {
    try {
      setLoadingTickets(true);
      const response = await axios.get(
        `admin/v1/tickets/users/${id}?page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get('token')}`,
          },
        }
      );
      if (response.status === 200) {
        const perPage = 10;
        const { total, current_page, data } = response.data.data;
        setTotalPages(Math.ceil(total / perPage));
        setCurrentPage(current_page);
        setTickets(data);
      }
    } catch (error) {
      console.log('Error in tickets per user api', error);
    } finally {
      setLoadingTickets(false);
    }
  };

  return (
    <Layout.Section>
      <Card
        sectioned
        title="TICKETS"
        actions={[
          {
            content: 'Create a new Ticket',
            onAction: handleOpen,
          },
        ]}
      >
        <TicketsUserTable
          getTickets={getTickets}
          loading={loadingTickets}
          tickets={tickets}
          currentPage={currentPage}
        />
        {tickets.length > 0 && (currentPage > 1 || currentPage < totalPages) &&  (
          <div id="pagination" style={{ padding: '20px 0' }}>
            <Pagination
              hasPrevious={currentPage > 1}
              onPrevious={() => setCurrentPage(currentPage - 1)}
              hasNext={currentPage < totalPages}
              onNext={() => setCurrentPage(currentPage + 1)}
            />
          </div>
        )}
      </Card>
      <Modal open={ticketingModalActive} onClose={handleClose}>
        <CreateTicket
          customStyle={overlayTicketingStyle}
          newMedia={newMedia}
          setNewMedia={setNewMedia}
          setTicketingModalActive={setTicketingModalActive}
        />
      </Modal>
    </Layout.Section>
  );
};

export default TicketsUser;
