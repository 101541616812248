import React, { useEffect, useState } from "react";
import NavigationLayout from "../../components/NavigationLayout";
import { Page } from "@shopify/polaris";
import "../ListJobs.css";
import axios from "../../axios";
import { Card } from "@material-ui/core";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import ApplicatnsDetails from "./ApplicatnsDetails";
import ThinLoading from "../../components/Loading/thin-loading.component";

function Applicants() {
  const { id } = useParams();

  const [career, setCareer] = useState({
    title: "",
    updated_at: "",
    status: "",
    location: "",
    workplace_type: "",
    employment_type: "",
    total_applicant:0
  });
  const [applicants, setApplicants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [applicantdetails, setApplicantDetails] = useState("");

  // const [page, setPage] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    years_experience: "",
    rating: "",
  });

  const rating = [
    { id: 0, name: "Select...", value: "" },
    { id: 1, name: "Not a fit", value: "Not a fit" },
    { id: 2, name: "Good fit", value: "Good fit" },
    { id: 3, name: "Maybe", value: "Maybe" },
    { id: 3, name: "Blacklist", value: "Blacklist" },
  ];

  const fetchApplicants = async () => {
    try {
      setLoading(true);

      const params = {
        reference_type: "Career",
        reference_id: id,
      };

      if (formData.name !== "") {
        params.name = formData.name;
      }
      if (formData.years_experience !== "") {
        params.years_experience = formData.years_experience;
      }
      if (formData.rating !== "") {
        params.rating = formData.rating;
      }
      const jobApplicant = await axios.get(`/admin/v1/fetchAllCareerApplicant`, {
        params: params,
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });
      const data = jobApplicant.data.data;
      setCareer({
        title: jobApplicant.data[0].title,
        status: jobApplicant.data[0].status,
        location: jobApplicant.data[0].location,
        workplace_type: jobApplicant.data[0].workplace_type,
        employment_type: jobApplicant.data[0].employment_type,
        updated_at: jobApplicant.data[0].updated_at,
        total_applicant:jobApplicant.data[0].number_of_applicants.count,
      });
      setApplicants(data);
    } catch (error) {
      console.error("Error:", error);
    }finally{
      setLoading(false);

    }
  };
  useEffect(() => {
    fetchApplicants();
  }, [id]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const FilterApplicants = () => {
    fetchApplicants();
  };

  const fetchAllApplicants = async () => {
    try {
      setLoading(true);
      const jobApplicant = await axios.get(`/admin/v1/fetchAllCareerApplicant`, {
        params: {
          reference_type: "Career",
          reference_id: id,
        },
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });

      const data = jobApplicant.data.data;
      setApplicants(data);
    
    } catch (error) {
      console.error("Error:", error);
    }finally{
      setLoading(false);
    }
  };
  const resetFilter = () => {
    setFormData({
      name: "",
      years_experience: "",
      rating: "",
    });
    fetchAllApplicants();
  };

  return (
    <NavigationLayout>
      <Page
        title="Applicants"
        fullWidth
      >
        <Card sectioned style={{ marginBottom: "40px" }}>
          <div style={{ padding: "2em" }}>
           <div style={{justifyContent:"space-between",display:"flex",alignItems:"center"}} >
                <div className="form-row-question" style={{ display: 'flex', justifyContent: 'start', paddingBottom: "30px" }}>
                    <button onClick={()=>window.history.go(-1)}>Go Back</button>
                </div>
          </div>
            <form>
              <div
                className="listofjobs"
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  paddingBottom: "60px",
                  flexWrap: "wrap",
                }}
              >
                <div className="">
                  <div className="applicants-title">Title:</div>
                  <div className="applicants-description ">{career.title}</div>
                </div>
                <div className="">
                  <div className="applicants-title">Published on:</div>

                  <div className="applicants-description ">
                    {career.updated_at}
                  </div>
                </div>

                <div className="">
                  <div className="applicants-title">Status:</div>

                  <div className="applicants-description ">
                    {career.status === "Open" && (
                      <div className="green-circle">Open</div>
                    )}
                    {career.status === "Closed" && (
                      <div className="blue-circle">Closed</div>
                    )}
                    {career.status === "Draft" && (
                      <div className="grey-circle">Draft</div>
                    )}
                  </div>
                </div>
                <div className="">
                  <div className="applicants-title">Location:</div>

                  <div className="applicants-description ">
                    {career.location}
                  </div>
                </div>
                <div className="">
                  <div className="applicants-title">Workplace Type:</div>

                  <div className="applicants-description ">
                    {career.workplace_type}
                  </div>
                </div>
                <div className="">
                  <div className="applicants-title">Employment Type:</div>

                  <div className="applicants-description ">
                    {career.employment_type}
                  </div>
                </div>
                <div className="">
                  <div className="applicants-title">Total Applicants</div>
                  <div className="applicants-description ">
                    {career.total_applicant}
                  </div>
                </div>
              </div>
              <div
                className="career-form-group list-jobs-form-group"
                style={{ display: "flex", alignItems: "end", flexWrap: "wrap" }}
              >
                <div>
                  <div>
                    <label>Name:</label>
                    <input
                      type="text"
                      name="name"
                      className="input_form"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div></div>
                </div>
                <div>
                  <label>Minimun Years of Experience:</label>

                  <input
                    type="text"
                    name="years_experience"
                    className="input_form"
                    value={formData.years_experience}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label>Rating:</label>
                  <select
                    name="rating"
                    value={formData.rating}
                    onChange={handleChange}
                  >
                    {rating.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <div style={{ display: "flex ", justifyContent: "end " }}>
                    <button
                      type="button"
                      className="addnewjob"
                      style={{ cursor: "pointer" }}
                      onClick={FilterApplicants}
                    >
                      Filter
                    </button>
                    <button
                      type="button"
                      className="addnewjob"
                      style={{ cursor: "pointer", marginInline: "10px" }}
                      onClick={resetFilter}
                    >
                      Reset Filter
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Card>
        <div style={{ display: "flex", width: "100%" }}>
          <div className="col-40">
            <Card sectioned>
              <div style={{ padding: "2em" }}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div style={{ width: "100%" }}>
                    <div style={{ maxHeight: "40vh", overflow: "auto" }}>
                      <div className="listofjobs">
                      <div className="col-10">
                          <label> Version</label>
                        </div>
                        <div className="col-20">
                          <label>First Name</label>
                        </div>
                        <div className="col-20">
                          <label>Last Name</label>
                        </div>

                        <div className="col-35">
                          <label>Date of Application</label>
                        </div>
                        <div className="col-20">
                          <label>Rating</label>
                        </div>
                      </div>
                    </div>
                    <hr style={{ margin: "0px " }} />
                    <div style={{ maxHeight: "40vh", overflow: "auto" }}>
                      {loading === true ? (
                        <div
                          style={{
                            textAlign: "center",
                            paddingTop: "5vh",
                            paddingBottom: "5vh",
                          }}
                        >
                          <ThinLoading />
                        </div>
                      ) : applicants.length === 0 ? (
                        <div
                          style={{
                            textAlign: "center",
                            paddingTop: "5vh",
                            paddingBottom: "5vh",
                          }}
                        >
                          No Applicants
                        </div>
                      ) : (
                        applicants
                          .map((data) => (
                            <>
                              <div
                                className="listofjobs-map"
                                style={{ cursor: "pointer" }}
                                onClick={() => setApplicantDetails(data.applicant)}
                              >
                                  <div className="col-10">
                                  <span>{data.applicant.reference.version} </span>
                                </div>
                                <div className="col-20">
                                  <span>{data.applicant.first_name}</span>
                                </div>
                                <div className="col-20">
                                  <span>{data.applicant.last_name}</span>
                                </div>

                                <div className="col-35">
                                  <span>{data.applicant.created_at}</span>
                                </div>
                                <div className="col-20">
                                  <span>{data.applicant.rating ? data.applicant.rating : "-"}</span>
                                </div>
                              </div>

                              <hr className="hr-list" />
                            </>
                          ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <div className="col-60">
            {applicantdetails && (
              <Card sectioned>
                <div style={{ padding: "2em" }}>
                  <ApplicatnsDetails
                    data={applicantdetails}
                    fetchAllApplicants={fetchAllApplicants}
                  />
                </div>
              </Card>
            )}
          </div>
        </div>
      </Page>
    </NavigationLayout>
  );
}
export default Applicants;
