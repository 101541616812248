import { Card, DataTable, DisplayText, Layout } from '@shopify/polaris';
import React, { useEffect } from 'react'

function NpsComponent({ name, score, number_of_responses, data}) {
  let getData =()=>{
    let arr =[[],[]];
    Object.keys(data).map((item)=> {
        arr[0].push(<div>{data[item].count}</div>)
        arr[1].push(<div>{data[item].percentage}</div>)  
    })
    return arr;
  }
  return (
    <Layout.Section oneHalf>
    <Card 
      title={name}
    >
      <Card.Section>
        <DisplayText size="medium">
        Total NPS score: {score}
        </DisplayText>
        <DisplayText size="medium">
        Total responses: {number_of_responses}
        </DisplayText>
      </Card.Section>
      <Card.Section>
        {typeof data === 'object' && data !== null &&<DataTable columnContentTypes={["text", "text", "text"]} headings={['detractor','passive','promoter']} 
        rows={getData()}></DataTable>}
      </Card.Section>
    </Card>
  </Layout.Section>
  )
}

export default NpsComponent