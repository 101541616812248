
import React, {useRef } from 'react'
import IconButton from "@material-ui/core/IconButton";
import SendIcon from '@mui/icons-material/Send';
import FileUpload from '@mui/icons-material/AttachFile';
import { useStyles } from '../../components/CaseDiscussionInput/CaseDiscussionInputStyles';
import { Grid, Badge, TextField, InputLabel, FormControl, Input } from '@material-ui/core';
import { Close } from "@mui/icons-material";
import { OutlinedInput } from '@mui/material';
import mentionsInputStyle from '../../components/CaseDiscussionInput/mentionsInputStyle'
import mentionStyle from '../../components/CaseDiscussionInput/mentionStyle';
import { MentionsInput, Mention } from 'react-mentions'

function TicketingCommentInput({ newMessage, setNewMessage, handleSubmit, newMedia =[], setNewMedia, loading, agents }) {
    const classes = useStyles()
    const fileInputRef = useRef(null);
    const handleFileUploadClick = () => {
        fileInputRef.current && fileInputRef.current.click();
    };
    const handleFileChange = (event) => {
        let selectedFiles = Array.from(event.target.files || [])
        setNewMedia((prev) => [...prev,...selectedFiles])
    };
    const handlePaste = (e) => {
        let pastedData = Array.from(e.clipboardData.files || [])
        setNewMedia((prev) => [...prev,...pastedData])
    }
    return (
        <Grid item xs={12} className={classes.inputRow} onPaste={handlePaste}>
            <form onSubmit={handleSubmit} className={classes.form}>
                <Grid
                    container
                    className={classes.newMessageRow}
                    alignItems="flex-end"
                >
                    <Grid item style={{ flexGrow: 1, maxWidth: "80%" }}>
                        <MentionsInput id="inputt" value={newMessage} style={mentionsInputStyle}
                        disabled={loading}
                      forceSuggestionsAboveCursor
                      allowSpaceInQuery
                      placeholder="use @ to mention an agent"
                      onKeyDown={(e)=>
                        {
                        if(e.key === 'Enter' && e.shiftKey){
                        handleSubmit({preventDefault:()=>{}})
                        return;
                      }
                    }}
                      onChange={(e) =>{
                        setNewMessage(e.target.value)
                      }
                    }>
                      <Mention
                        trigger="@"
                        allowSpaceInQuery={true}
                        forceSuggestionsAboveCursor={true}
                        data={agents}
                        appendSpaceOnAdd={true}
                        style={mentionStyle}
                      />
                    </MentionsInput>
                    </Grid>
                    <IconButton >
                        <Badge className={classes.attachmentBadge} badgeContent={newMedia.length} >
                            <FileUpload style={{ width: "1.5em", height: "1.5em" }} onClick={handleFileUploadClick} />
                        </Badge>
                    </IconButton>
                    <IconButton type="submit" disabled={loading}>
                        <SendIcon className={classes.send} />
                    </IconButton>
                </Grid>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                />
                {newMedia.length ? <div>
                    Attached:
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "2px" }}>
                        {newMedia.map((media, ind) => <div style={{ position: "relative", width: "fit-content", paddingRight: "3%", paddingLeft: "1%", border: "1px solid #b0adad", borderRadius: "5px", backgroundColor: "#9895954f" }}>{media.name}
                            <Close style={{ position: "absolute", right: "0", top: "0" }} onClick={() => { setNewMedia((prev) => { let arr = [...prev]; arr.splice(ind, 1); return [...arr] }) }} /></div>)}
                    </div>
                </div> : null}
            </form>
        </Grid>
    )
}

export default TicketingCommentInput