import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Button,
  Page,
  DisplayText,
  Loading,
  Badge,
  ChoiceList,
  Filters,
  Card,
  TextStyle,
  ButtonGroup,
  Spinner,
} from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../axios";
import history from "../history";
import { ExportMinor } from "@shopify/polaris-icons";
import emptyIcon from "../img/emptyList.svg";
import moment from "moment";
import Axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import Cookies from "js-cookie";


export default function List() {
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  let arrayOfObjects = [];
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const perPage = 100;
  const [sortState, setSortState] = useState('-updated_at');
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  let valueFilter = "";
  let orderStatus = ["Canceled", "Failed", "Cash Paid", "Cash"];
  let orderColor = ["success", "info", "attention", "warning"];

  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    getData(ourRequest);
    return () => {
      ourRequest.cancel();
    }
  }, [currentPage, queryValue, availability, sortState]);
  function getData(ourRequest) {
    valueFilter = availability;
    if (valueFilter === "") {
      valueFilter = "in_table";
    }
    axios
      .get(
        `/admin/v1/orders?sort_by=${sortState}&per_page=${perPage}&page=${currentPage}&filter[${valueFilter}]=${queryValue}`,
        {
          cancelToken: ourRequest.token,
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      )
      .then((result) => {
        console.log("responseOreders", result);
        if (result.data.data.data.length === 0) {
          setIsListEmpty(true);
        } else {
          setIsListEmpty(false);
        }
        setIsLoading(false);
        arrayOfObjects = result.data.data.data.map((item) => [
          item.id,
          item.reference_id,
          item.user_id,
          item.note,
        ]);
        setItems(
          result.data.data.data.map((item) => [
            item.id,
            item.user_id,
            <div
              style={{ cursor: "pointer" }}
              id={item.user_id}
              onClick={(e) => onUserClick(e)}
            >
              {item.user.first_name + " " + item.user.last_name}
            </div>,

            item.reference_id,
            <TextStyle>
              {item.product
                ? item.product.title.en
                : item.plan
                ? item.plan.title.en
                : ""}
            </TextStyle>,

            <TextStyle>{item.coupon ? item.coupon.code : null}</TextStyle>,
            <TextStyle>
              {item.coupon ? item.coupon.amount + " %" : null}
            </TextStyle>,
            item.note ? <TextStyle>{item.note.payment_method}</TextStyle> : "",
            item.note ? (
              <TextStyle>{item.note.payments_remaining}</TextStyle>
            ) : (
              ""
            ),
            item.note && item.note.amount_remaining ? (
              <TextStyle>{`$ ${item.note.amount_remaining}`}</TextStyle>
            ) : (
              "0"
            ),
            moment(item.created_at)
              .add(2, "hours")
              .format("YYYY-MM-DD hh:mm:ss"),

            moment(item.paid_at)
              .add(2, "hours")
              .format("YYYY-MM-DD hh:mm:ss"),
            "$ " + (item.total_price / 100).toFixed(2),
            <Badge
              status={orderColor[orderStatus.indexOf(item.payment_status)]}
            >
              {item.payment_status}
            </Badge>,
            item.shipping_method,
            <ButtonGroup>
              <Button
                primary
                id={String(item.id)}
                onClick={(e) => handleAddTracking(e)}
              >
                List of trackings
              </Button>
              <Button
                id={String(item.id)}
                onClick={(e) => handleViewDetails(e)}
              >
                View Details
              </Button>
            </ButtonGroup>,
          ])
        );
        setTotalPages(Math.ceil(result.data.data.total / perPage));
      })
      .catch((err) => console.log(err));
  }
  function onUserClick(e) {
    e.ctrlKey
      ? window.open(
          "/admin/users/" + e.currentTarget.id + "/dashboard",
          "_blank"
        )
      : history.push("/admin/users/" + e.currentTarget.id + "/dashboard");
  }
  function exportOrders() {
    //a
    window.open(
      `${process.env.REACT_APP_BASE_URL}/admin/orders-csv?token=` +
        "Bearer " +
        Cookie.get("token")
    );
  }
  async function exportShopOrders() {
    try {
      const response = await axios.get(`admin/v1/reports/shop`, {
        responseType: 'blob',
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Shop-Orders-${new Date().toLocaleDateString('en-GB').split('/').join('-')}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }catch(e){

    }

  }

  function handleAddTracking(e) {
    let currentID = e.currentTarget.id;
    var json_str = JSON.stringify(
      findArrayElementById(arrayOfObjects, currentID)
    );
    Cookie.set("clickedItem", json_str);
    Cookie.set("userID", findArrayElementById(arrayOfObjects, currentID)[2]);
    Cookie.set("orderID", e.currentTarget.id);
    history.push("/admin/orders/" + currentID + "/trackings");
  }

  function handleViewDetails(e) {
    let currentID = e.currentTarget.id;
    var json_str = JSON.stringify(
      findArrayElementById(arrayOfObjects, currentID)
    );
    Cookie.set("clickedItem", json_str);
    Cookie.set("userID", findArrayElementById(arrayOfObjects, currentID)[2]);
    history.push("/admin/orders/" + currentID);
  }

  function handleFiltersQueryChange(queryValue) {
    setQueryValue(queryValue);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue("");
  }, []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "All", value: "in_table" },
            { label: "Shipping method", value: "shipping_method" },
            { label: "Payment Status", value: "payment_status" },
            { label: "Product Title", value: "product.title" },
            { label: "Plan Title", value: "plan.title" },
            { label: "ID", value: "user_id" },
            { label: "First Name", value: "user.first_name" },
            { label: "Last Name", value: "user.last_name" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
          // allowMultiple
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    []
  );
  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div className="message-no-result"
    >
      <div className="message-no-result-img">
         <img src={emptyIcon} className="message-no-result-img"></img>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner accessibilityLabel="Spinner example" size="large" />
        </div>
      </div>
    )
  );

  return (
    <Page
      fullWidth
      title="List Of Orders"
      secondaryActions={[
        {
          content: "Export Shop Orders",
          icon: ExportMinor,
          onAction: exportShopOrders,
        },
        {
          content: "Export",
          icon: ExportMinor,
          onAction: exportOrders,
        },
        {
          content: "Offline Payments",
          url:'/admin/orders/manual/payments/',
        },
      ]}
      primaryAction={{
        content: "Create Order",
        url: "/admin/orders/create",
      }}
    > 
      {loadingMarkup}
      <Card>
        <Card.Section>
          <Filters
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
        </Card.Section>{" "}
        <DataTable
          columnContentTypes={[
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
          ]}
          hideScrollIndicator
          headings={[
            <TextStyle variation="strong">Order ID</TextStyle>,
            <TextStyle variation="strong">User ID</TextStyle>,
            <TextStyle variation="strong">User Name</TextStyle>,
            <TextStyle variation="strong">Reference ID</TextStyle>,
            <TextStyle variation="strong">Product Title</TextStyle>,
            <TextStyle variation="strong">Promo Code</TextStyle>,
            <TextStyle variation="strong">Discount Percentage</TextStyle>,
            <TextStyle variation="strong">Payment Method</TextStyle>,
            <TextStyle variation="strong">Remaining Payments</TextStyle>,
            <TextStyle variation="strong">Remaining Amount</TextStyle>,
            <TextStyle variation="strong">Created At</TextStyle>,
            <TextStyle variation="strong">Updated At</TextStyle>,
            <TextStyle variation="strong">Total price</TextStyle>,
            <TextStyle variation="strong">Payment status</TextStyle>,
            <TextStyle variation="strong">Shipping method</TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
          ]}
          rows={items}
          sortable={[true, true, false, false, false, false, false, false, false, false, true, true, true, false, false]}
          initialSortColumnIndex={0}
          defaultSortDirection="descending"
          onSort={handleSort}
          verticalAlign="middle"
        />
        {emtyState}
      </Card>
      {totalPages >= 2 && (
        <div style={{ marginTop: '20px' }}>
          <Pagination
            size='large'
            count={totalPages}
            boundaryCount={2}
            style={{margin: "auto", width: "fit-content"}}
            showFirstButton
            showLastButton
            onChange={(e, p) => {
              setCurrentPage(p)
            }}
            page={Number(currentPage)}
            />
        </div>
      )}
    </Page>
  );

  function handleSort(index) {
    switch (index) {
      case 0:
        setSortState(sortState === "id" ? "-id" : "id");
        break;
      case 1:
        setSortState(sortState === "-user_id" ? "user_id" : "-user_id");
        break;
      case 10:
        setSortState(sortState === "-created_at" ? "created_at" : "-created_at");
        break;
      case 11:
        setSortState(sortState === "-updated_at" ? "updated_at" : "-updated_at");
        break;
      case 12:
        setSortState(sortState === "-total_price" ? "total_price" : "-total_price");
        break;
      default:
      // code block
    }
  }

  function handleEdit(e) {
    let currentID = e.currentTarget.id;
    var json_str = JSON.stringify(
      findArrayElementById(arrayOfObjects, currentID)
    );
    Cookie.set("clickedItem", json_str);
    history.push("/admin/products/" + currentID);
  }

  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element[0]) === parseInt(id);
    });
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return value.map((val) => `Filter by ${val}`).join(", ");
      case "productType":
        return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }

  function handlePageChange(currentPage) {
    setCurrentPage(currentPage);
  }
}
