import React, {useState, useEffect} from "react";
import {
  Modal,
  TextStyle,
  Page,
  FormLayout,
  Card,
  PageActions, Checkbox, Spinner
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";
import {useLocation, useParams} from "react-router-dom";
import UserHeader from "../../../components/UserLayout/user-header";
import styled from "styled-components";
import { set } from "date-fns";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
const Input = styled.input`
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  position: relative;
  z-index: 20;
  display: block;
  flex: 1 1;
  min-width: 50px;
  min-height: 3.6rem;
  margin: 10px 0 0 0;
  padding: 0.5rem 1.2rem;
  background: none;
  border: 0.1rem solid lightgrey;
  border-radius: 5px;

  &:focus {
    outline: none;
    border-color: black;
  }
`;
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    maxWidth:300,
    marginTop:10
  },
  inputLabel: {
    fontSize: '1.6rem', 
  },
  input: {
    fontSize: '1.6rem', 
  },
}));

function EditShipping() {
  let {id} = useParams();
  const [loading, setLoading] = useState(false);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [errors, setErrors] = useState({});
  const [popupActive, setPopupActive] = useState(false);
  const [isSecondaryDisabled, setIsSecondaryDisabled] = useState(false);
  const [isTemporaryDisabled, setIsTemporaryDisabled] = useState(false);
  const today=new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const todayString = today.toISOString().split('T')[0];
  const tomorrowString = tomorrow.toISOString().split('T')[0];

  const invalidStyle = {
    color: "red",
    fontSize: "0.8em",
    marginTop: "0.2em",
  };
  const invalidInputStyle = {
    borderColor: "red"
  }

  const shippingAddressObject = {
    user_id: id,
    full_name: "",
    address: "",
    address_2: "",
    address_3: "",
    apartment: "",
    city: "",
    country: "",
    postal_code: "",
    phone: "",
    note: "",
    email: "",
    type: "primary",
    temporary_start_date:"",
    temporary_end_date:""
  }
  
  const onChangeHandler = (index, event) => {
    const {name, value} = event.target;
    const list = [...shippingAddresses];
    list[index][name] = value;
    setShippingAddresses(list);
  }
  const onChecked = (index) => {
    const list = [...shippingAddresses];
    const statusChecked = list[index]["type"] === "secondary" ? "primary" : "secondary";
    list[index]["type"] = statusChecked;
    if (list.length > 1) {
      if (index === 0) {
        list[1]["type"] = statusChecked === "secondary" ? "primary" : "secondary"
      } else {
        list[0]["type"] = statusChecked === "secondary" ? "primary" : "secondary"
      }
    }
    setShippingAddresses(list);
  }

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`admin/v1/users/${id}/shipping`, {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      });
      if (response.status === 200) {
        if (response.data) {
          if (response?.data?.data.length > 0) {
            const {data: shippingInfo} = response.data;
            setShippingAddresses(shippingInfo);
            if (response?.data?.data.length >= 2) {
              response?.data?.data.forEach(element => {
                if(element.type==="temporary"){
                  setIsTemporaryDisabled(true);
                }
                if(element.type==="secondary"){
                  setIsSecondaryDisabled(true);
                }
              });
            } 
          }else {
            setShippingAddresses([{...shippingAddressObject, type: "primary"}])
          }
        }
      }
    } catch (error) {
      console.log("Error", error)
    } finally {
      setLoading(false);
    }

  }
 
  const mainDivStyle = {
    display: "flex",
    width: "100%",
    columnGap: "20px"
  }

  const formDiv = {
    flex: "1 1 0"
  }

  useEffect(() => {
    void getData();
  }, []);

  const addSecondaryShippingAddressButton = () => {
    setShippingAddresses(prevAddresses => [
      ...prevAddresses,
      { ...shippingAddressObject, type: "secondary" }
    ]);
    setIsSecondaryDisabled(true);
  };
  
  const addTemporaryShippingAddressButton = () => {
    setShippingAddresses(prevAddresses => [
      ...prevAddresses,
      { ...shippingAddressObject, type: "temporary" }
    ]);
    setIsTemporaryDisabled(true);
  };
  

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    for (let i = 0; i < shippingAddresses.length; i++) {
      const address = shippingAddresses[i];
      if (!address.full_name) {
        errors[`full_name${i}`] = 'Name is required';
        isValid = false;
      }
      if (!address.address) {
        errors[`address${i}`] = 'Area & Street is required';
        isValid = false;
      } else if (address.address.length < 10) {
        errors[`address${i}`] = 'Area & Street should be at least 10 characters';
        isValid = false;
      }
      if (!address.address_2) {
        errors[`address_2${i}`] = 'Building, Floor, Apartment is required';
        isValid = false;
      } else if (address.address_2.length < 10) {
        errors[`address_2${i}`] = 'Building, Floor, Apartment should be at least 10 characters';
        isValid = false;
      }
      if (!address.city) {
        errors[`city${i}`] = 'City is required';
        isValid = false;
      }
      if (!address.country) {
        errors[`country${i}`] = 'Country is required';
        isValid = false;
      }
      if (!address.phone) {
        errors[`phone${i}`] = 'Phone is required';
        isValid = false;
      }
      if (address.type==="temporary" && !address.temporary_end_date && !address.temporary_end_date) {
        errors[`date ${i}`] = 'Date is required';
        isValid = false;
      }
    }

    setErrors(errors);
    return isValid;
  };

  const handleSave = async () => {
    if (validateForm()) {
      setPopupActive(true);
      try {
        const response = await axios
          .post(`admin/v1/users/${id}/shipping`, {data: shippingAddresses}, {
            headers: {
              Authorization: `Bearer ${Cookie.get("token")}`,
            },
          });
        if (response.status === 200) {
          history.push(`/admin/users/${id}/dashboard`);
        }
      } catch (error) {
        console.log("Error", error);
      } finally {
        setPopupActive(false);
      }
    }
  }

  const location = useLocation();
  let linkState =
    {planType: "", clinicStatus: "", clinicName: "", status: "", userId: id, pageTitle: ""};
  if (location.state !== undefined) {
    const {planType, clinicStatus, clinicName, status, userId, pageTitle} = location.state;
    linkState = {planType, clinicStatus, clinicName, status, userId, pageTitle}
  }
  const {planType, clinicStatus, clinicName, status, userId, pageTitle} = linkState;
  const classes = useStyles();
  
  return (
    <Page>
      <UserHeader pageTitle={pageTitle} status={status} userId={userId} clinicStatus={clinicStatus}
                  clinicName={clinicName} planType={planType}/>
      <br/>
      {loading ? <div style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          backgroundColor: "white",
          padding: "50px",
          marginTop: "15px",
          marginBottom: "15px"
        }}>
          <Spinner accessibilityLabel="Loading Clinics Pricing" size="large"/>
        </div> :
        <>
          <h1 className="page-header">Edit Shipping</h1>
          <Card sectioned actions={[{
            content: 'Add Secondary Shipping Address',
            onAction: addSecondaryShippingAddressButton,
            disabled: isSecondaryDisabled
          },
          {
            content: 'Add Temporary Shipping Address',
            onAction: addTemporaryShippingAddressButton,
            disabled: isTemporaryDisabled
          }
          ]}>
            <div style={mainDivStyle}>
              {shippingAddresses.map((address, index) => {
                return <div style={formDiv} key={index}>
                  {address.type==="primary" ? (<TextStyle variation="strong">Primary Shipping Address</TextStyle>) :
                    (address.type==="secondary"?<TextStyle variation="strong">Secondary Shipping Address</TextStyle>:<TextStyle variation="strong">Temporary Shipping Address</TextStyle>)}
                  <div style={{paddingTop: "10px"}}>
                    <FormLayout>
                      <div style={{display: "grid"}}>
                        <label htmlFor="full_name">Full Name</label>
                        <Input
                          style={errors[`full_name${index}`] && invalidInputStyle}
                          id="full_name"
                          type="text"
                          name="full_name"
                          value={address.full_name ? address.full_name : ''}
                          onChange={event => onChangeHandler(index, event)}
                        />
                        {errors[`full_name${index}`] && <div style={invalidStyle}>{errors[`full_name${index}`]}</div>}
                      </div>
                      <div style={{display: "grid"}}>
                        <label htmlFor="country">Country</label>
                        <Input
                          id="country"
                          style={errors[`country${index}`] && invalidInputStyle}
                          type="text"
                          name="country"
                          value={address.country ? address.country : ''}
                          onChange={event => onChangeHandler(index, event)}
                        />
                        {errors[`country${index}`] && <div style={invalidStyle}>{errors[`country${index}`]}</div>}
                      </div>
                      <div style={{display: "grid"}}>
                        <label htmlFor="city">City</label>
                        <Input
                          id="city"
                          style={errors[`city${index}`] && invalidInputStyle}
                          type="text"
                          name="city"
                          value={address.city ? address.city : ''}
                          onChange={event => onChangeHandler(index, event)}
                        />
                        {errors[`city${index}`] && <div style={invalidStyle}>{errors[`city${index}`]}</div>}
                      </div>
                      <div style={{display: "grid"}}>
                        <label htmlFor="address">Area & Street</label>
                        <Input
                          id="address"
                          style={errors[`address${index}`] && invalidInputStyle}
                          type="text"
                          name="address"
                          value={address.address ? address.address : ''}
                          onChange={event => onChangeHandler(index, event)}
                        />
                        {errors[`address${index}`] && <div style={invalidStyle}>{errors[`address${index}`]}</div>}
                      </div>
                      <div style={{display: "grid"}}>
                        <label htmlFor="address_2">Building, Floor, Apartment</label>
                        <Input
                          id="address_2"
                          style={errors[`address_2${index}`] && invalidInputStyle}
                          type="text"
                          name="address_2"
                          value={address.address_2 ? address.address_2 : ''}
                          onChange={event => onChangeHandler(index, event)}
                        />
                        {errors[`address_2${index}`] && <div style={invalidStyle}>{errors[`address_2${index}`]}</div>}
                      </div>
                      <div style={{display: "grid"}}>
                        <label htmlFor="address_3">Additional Address Details</label>
                        <Input
                          id="address_3"
                          type="text"
                          name="address_3"
                          value={address.address_3 ? address.address_3 : ''}
                          onChange={event => onChangeHandler(index, event)}
                        />
                      </div>
                      <div style={{display: "grid"}}>
                        <label htmlFor="apartment">Additional Address Details 2</label>
                        <Input
                          id="apartment"
                          type="text"
                          name="apartment"
                          value={address.apartment ? address.apartment : ''}
                          onChange={event => onChangeHandler(index, event)}
                        />
                      </div>
                      <div style={{display: "grid"}}>
                        <label htmlFor="postal_code">Postal Code</label>
                        <Input
                          id="postal_code"
                          type="text"
                          name="postal_code"
                          value={address.postal_code ? address.postal_code : ''}
                          onChange={event => onChangeHandler(index, event)}
                        />
                      </div>
                      <div style={{display: "grid"}}>
                        <label htmlFor="phone">Phone</label>
                        <Input
                          id="phone"
                          type="text"
                          name="phone"
                          style={errors[`phone${index}`] && invalidInputStyle}
                          value={address.phone ? address.phone : ''}
                          onChange={event => onChangeHandler(index, event)}
                        />
                        {errors[`phone${index}`] && <div style={invalidStyle}>{errors[`phone${index}`]}</div>}
                      </div>
                      <div style={{display: "grid"}}>
                        <label htmlFor="note">Note</label>
                        <Input
                          id="note"
                          type="text"
                          name="note"
                          value={address.note ? address.note : ''}
                          onChange={event => onChangeHandler(index, event)}
                        />
                      </div>
                      <div style={{display: "grid"}}>
                        <label htmlFor="email">Email</label>
                        <Input
                          id="email"
                          type="email"
                          name="email"
                          value={address.email ? address.email : ''}
                          onChange={event => onChangeHandler(index, event)}
                        />
                      </div>
                      {(address.type==="primary"||address.type==="secondary")&&
                      <Checkbox
                        label="Is Primary"
                        checked={address.type === "primary"}
                        onChange={() => onChecked(index)}
                      />}
                      { 
                      (address.type==="temporary")&&
                      <>
                         <div style={{display: "grid"}}>
                  
                       <form className={classes.container} noValidate>
                          <TextField
                            id="temporary_start_date"
                            label="Start Date"
                            name="temporary_start_date"
                            type="date"
                            defaultValue={address.temporary_start_date??todayString}
                            className={classes.textField}
                            onChange={event => onChangeHandler(index, event)}
                            InputProps={{
                              classes: {
                                input: classes.input,
                              },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: classes.inputLabel,
                              },
                            }}
                          />
                        </form>
                      {errors[`date${index}`] && <div style={invalidStyle}>{errors[`date${index}`]}</div>}
                    </div>
                    <div style={{display: "grid"}}>
                  
                  <form className={classes.container} noValidate>
                     <TextField
                       id="end_date"
                       label="End Date"
                       name="temporary_end_date"
                       type="date"
                       defaultValue={address.temporary_end_date?? tomorrowString}
                       className={classes.textField}
                       onChange={event => onChangeHandler(index, event)}
                       InputProps={{
                        classes: {
                          input: classes.input,
                        },
                      }}
                       InputLabelProps={{
                        classes: {
                          root: classes.inputLabel,
                        },
                      }}
                     />
                   </form>
                 {errors[`date${index}`] && <div style={invalidStyle}>{errors[`date${index}`]}</div>}
               </div>
                    </>
                    }
                    </FormLayout>
                  </div>
                </div>
              })}
            </div>
          </Card>
          <PageActions
            primaryAction={{
              content: "Save",
              onClick: handleSave,
            }}
          />
        </>
      }

      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );
}

export default EditShipping;
