import moment from "moment";
import { Button, DataTable, TextStyle } from "@shopify/polaris";

const ViewJobs = ({ jobs, onDelete }) => {

  return (
    <DataTable
      columnContentTypes={["text", "text", "text"]}
      headings={[
        <TextStyle variation="strong">Queue</TextStyle>,
        <TextStyle variation="strong">Scheduled At</TextStyle>,
        <TextStyle variation="strong">Actions</TextStyle>,
      ]}
      rows={jobs.map((job) => {
        const { queue, available_at, id } = job;
        return [
          queue,
          moment.unix(available_at).format("dddd, Do MMM YYYY, h:mm:ss A"),
          <Button onClick={() => onDelete(id)}>Abort</Button>,
        ];
      })}
    ></DataTable>
  );
};

export default ViewJobs;
