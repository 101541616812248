import React, { useCallback, useState, useEffect } from "react";
import {
  Page,
  Card,
  Layout,
  Select,
  Tabs,
  TextField,
  DropZone,
  Icon,
  Caption,
  Thumbnail,
  Stack,
  Checkbox,
} from "@shopify/polaris";
import axios from "./axios";
import { Router, useParams } from "react-router-dom";
import history from "./history";
import {
  FormLayout,
  TextStyle,
} from "@shopify/polaris";
import NavigationLayout from "./components/NavigationLayout";
import ReactTagInput from "@pathofdev/react-tag-input";
import Cookies from "js-cookie";

function InstagramWidget() {
    const { id } = useParams()
    const [instagramPosts, setInstagramPosts] = useState([]);
    function getData() {
        axios.get('admin/v1/instagram-posts', {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
        }).then((result) => {
            setInstagramPosts(result.data.posts.links);
        }).catch(err => console.log(err));
    }

    useEffect(() => {
        // let roleId = [];
        // if(Cookies.get("roleId")){
        //     roleId = JSON.parse(Cookies.get("roleId"));
        // }
        // if(!(roleId.includes(4) || roleId.includes(1))){
        //     history.push("/admin/home");
        // } else {
            getData();
        // }
    }, []);

    function handleSubmit() {
        if(instagramPosts.length > 0){
            const body = {
                links: instagramPosts,
            };
            axios.post('admin/v1/instagram-posts', body, {
                headers: {
                  Authorization: "Bearer " + Cookies.get("token"),
                },
            }).then((result) => {
                if(result.data.success){
                }
            }).catch(err => console.log(err));
        }
    }

    return (
        <NavigationLayout>
            <Router history={history}>
                       <Page breadcrumbs={[{ content: "All faqs", url: "/admin/content" }]} title="Instagram Urls" primaryAction={{
                            content: "Save Urls",
                            onClick: handleSubmit,
                        }}>
                        <Card sectioned>
                            <FormLayout>
                            <TextStyle variation="strong">Instagram URLs</TextStyle>
                            <ReactTagInput
                                tags={instagramPosts} 
                                onChange={(newTags) => {setInstagramPosts(newTags)}}
                                removeOnBackspace
                                editable
                            />
                            </FormLayout>
                        </Card>
                </Page>
            </Router>
        </NavigationLayout>
    );
}
export default InstagramWidget;
