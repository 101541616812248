import React, { useState, useEffect } from "react";
import {TextStyle } from "@shopify/polaris";
import Cookie from "js-cookie";
import axios from "../../axios";
import "@zendeskgarden/react-pagination/dist/styles.css";
import { useParams } from "react-router-dom";
import moment from "moment";
const MediaConsent = () => {
  const { id } = useParams();
  const [isFilled, setIsFilled] = useState("Not Filled");
  useEffect(() => {
    axios
      .get(`admin/v1/users/${id}/media-consent`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        result.data.data &&
          setIsFilled(
            "Customer consented to the Media Form agreement at:" +
              moment(result.data.data.created_at)
                .add(2, "hours")
                .format("YYYY-MM-DD hh:mm:ss")
          );
      })
      .catch((err) => setIsFilled("No content available"));
  }, []);
  return <TextStyle>Media Consent: {isFilled}</TextStyle>;
};

export default React.memo(MediaConsent);
