import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../history";
import { AppProvider } from "@shopify/polaris";
import TrackingDetails from "./TrackingDetails";
import EditTracking from "./EditTracking";
import ViewTrackingHistory from './view-tracking-history.component';

function MainTrackings2() {
  return (
    <AppProvider>
      <Router history={history}>
        <Switch>
          <Route
            exact
            path="/admin/orders/:orderId/trackings/:trackingId"
            component={TrackingDetails}
          />
          <Route
            path="/admin/orders/:orderId/trackings/:trackingId/edit/:type?"
            component={EditTracking}
          />
          <Route
            path="/admin/orders/:orderId/trackings/:trackingId/history"
            component={ViewTrackingHistory}
          />
        </Switch>
      </Router>
    </AppProvider>
  );
}
export default MainTrackings2;
