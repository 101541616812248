import { TableCell, TableRow } from '@mui/material';
import { GeneralFormats } from '../../util/constants';
import moment from 'moment';
import { Button } from '@shopify/polaris';
import { capitalizeWords } from '../../util/helpers';
import { useStyles } from '../ClinicReports/clinic-reports-invoices-table.styles';

const ProductRequestsReportsTableRow = ({
  productRequest,
  handleCheckDispatches,
  hideColumnsOnRequested,
  hideColumnsOnApproved,
  handOpenReasonDialog,
  openUserProfile,
}) => {
  const {
    id = null,
    requested_by = '',
    created_at = null,
    urgency = '',
    quantity = '',
    paid = 'Free',
    reason = '',
    user_id = null,
    approved_by = '',
    approved_at = null,
  } = productRequest;

  const classes = useStyles();

  let full_name = null;
  let name = null;
  if (productRequest.user) {
    full_name = productRequest.user.full_name;
  }

  if (productRequest.type) {
    name = productRequest.type.name;
  }

  const dateFormat = GeneralFormats.dateFormat;
  return (
    <>
      <TableRow>
        <TableCell
          style={{ cursor: 'pointer' }}
          onClick={() => openUserProfile(user_id)}
        >
          {full_name}
        </TableCell>
        <TableCell>{capitalizeWords(name)}</TableCell>
        <TableCell>{requested_by}</TableCell>
        <TableCell>
          {created_at ? moment(created_at).format(dateFormat) : ''}
        </TableCell>
        <TableCell className={`${hideColumnsOnRequested && classes.hide}`}>
          {approved_by}
        </TableCell>
        <TableCell className={`${hideColumnsOnRequested && classes.hide}`}>
          {approved_at ? moment(approved_at).format(dateFormat) : ''}
        </TableCell>
        <TableCell>{urgency}</TableCell>
        <TableCell>{quantity}</TableCell>
        <TableCell>{paid ? 'Paid' : 'Free'}</TableCell>
        <TableCell
          style={{ cursor: 'pointer' }}
          onClick={() => handOpenReasonDialog(reason)}
        >
          {reason ? `${reason?.slice(0, 10)}...` : '-'}
        </TableCell>
        <TableCell className={`${hideColumnsOnApproved && classes.hide}`}>
          <Button onClick={() => handleCheckDispatches(user_id, id)} primary>
            Approve Request
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProductRequestsReportsTableRow;
