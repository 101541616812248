import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { Button, Card, Page, Toast } from '@shopify/polaris'
import Cookies from 'js-cookie'
import React, { useCallback, useEffect, useState } from 'react'
import NavigationLayout from '../components/NavigationLayout'
import axios from '../axios'

const Voucher = () => {
    const [selectedType, setSelectedType] = useState('')
    const [country, setCountry] = useState('')
    const [amount, setAmount] = useState(0)
    const [vouchers, setVouchers] = useState([])
    const [updatedVouchers, setUpdatedVouchers] = useState([]);
    const [render, setRender] = useState(false)
    const [message, setMessage] = useState('')
    const toggleActive = useCallback(() => setMessage(''), []);

    const getVouchers = async () => {
        await axios
            .get(`/admin/v1/vouchers-list`, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("token"),
                },
            })
            .then((result) => {
                setVouchers(result.data.vouchers)
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const resetStates = () => {
        setCountry('')
        setAmount(0)
        setSelectedType('')
    }
    const createVoucher = async (status) => {
        const bodyObj = status == 'add' ? {
            value: amount,
            country,
            type: selectedType
        } : updatedVouchers;
        const url = status == 'add' ? 'create-voucher' : 'update-voucher'
        await axios
            .post(`/admin/v1/${url}`, bodyObj, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("token"),
                },
            })
            .then((result) => {
               if(status == 'add'){
                setMessage('Voucher created successfully')
                resetStates()
                const allVouchers = [...vouchers]
                allVouchers.push(bodyObj)
                setVouchers(allVouchers)
               } else {
                   setMessage('List of Vouchers updated successfully')
               }
               setRender(prev => !prev)
            })
            .catch((e) => {
                console.log(e)
            })
            .finally((f) => {
              window.scroll({
                top:0,
                behavior: 'smooth'
              })
            })
    }
    const deleteVoucher = async(id) => {
        const bodyObj = {
            id
        }
        await axios
        .post(`/admin/v1/delete-voucher`, bodyObj, {
            headers: {
                Authorization: "Bearer " + Cookies.get("token"),
            },
        })
        .then((result) => {
           const filteredVouchers = vouchers.filter((voucher) => voucher.id !== id)
           setVouchers(filteredVouchers)
           setMessage('Voucher deleted successfully')
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const addToUpdatedVouchers = (voucher) => {
        setUpdatedVouchers((prev) => {
            const nprev = [...prev];
            const index = nprev.findIndex((v) => v.id == voucher.id);
            if(index == -1){
                nprev.push(voucher);
            } else {
                nprev[index] = voucher;
            }
            return nprev;
        });
    };

    const handleUpdateAmount = useCallback(
        (value) => setVouchers(value),
        []
    );

    useEffect(() => {
        getVouchers();
    }, [render])

    return (
        <NavigationLayout>
            <Page
                title="Voucher System"
                breadcrumbs={[{ content: "Voucher System", url: "/admin/voucher-careplus" }]}
            >
                {message && <Toast content={message} onDismiss={toggleActive} />}
                <Card title="Create Voucher">
                    <Card.Section>
                        <div style={{ display: "flex", gap: "45px", position:"relative" }}>
                            <span className="plan-column" style={{ marginTop: "15px" }}>
                                <TextField required value={amount} type='number' onChange={(e) => setAmount(e.target.value)} className="plan-field" placeholder='Amount' />
                            </span>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                <Select
                                    required
                                    for="demo-simple-select-label"
                                    style={{ width: "100px" }}
                                    className="plan-field"
                                    placeholder='Select Type'
                                    onChange={(e) => { setSelectedType(e.target.value) }}
                                    value={selectedType}
                                >
                                    <MenuItem value={'testimonials'} >Testimonials</MenuItem>
                                    <MenuItem value={'Referral'} >Referral</MenuItem>
                                    <MenuItem value={'BeforeAndAfters'} >Before and afters</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Country/Currency</InputLabel>
                                <Select
                                    required
                                    style={{ width: "100px" }}
                                    className="plan-field"
                                    onChange={(e) => { setCountry(e.target.value) }}
                                    value={country}
                                >
                                    <MenuItem value={"AE"}>UAE</MenuItem>
                                    <MenuItem value={"SA"}>KSA</MenuItem>
                                    <MenuItem value={"QA"}>QAT</MenuItem>
                                    <MenuItem value={"LB"}>LEB</MenuItem>
                                    <MenuItem value={"KW"}>KWT</MenuItem>
                                    <MenuItem value={"BH"}>BAH</MenuItem>
                                    <MenuItem value={"OM"}>OMR</MenuItem>
                                </Select>
                            </FormControl>
                            <span style={{position:"absolute", right:"5px", bottom:'5px'}}><Button primary variant='contained' color="success" onClick={() => createVoucher('add')}>Submit</Button></span>
                        </div>
                    </Card.Section>
                </Card>
                <Card title="List of Vouchers">
                    <Card.Section>
                        <div style={{ display: "grid", gap: "45px", position:"relative" }}>
                            {Array.from(Array(vouchers.length).keys()).map((key) => {
                                return <div key={key} style={{ display: "flex", gap: "45px" }}>
                                    <span className="plan-column" style={{ marginTop: "15px" }}>
                                    <TextField value={vouchers[key].value} type='number' onChange={(e) => {
                                                addToUpdatedVouchers(vouchers[key]);
                                                let val = e.target.value;
                                                handleUpdateAmount((prev) => {
                                                    const nprev = [...prev];
                                                    if(nprev[key].id){
                                                        nprev[key].value = parseInt(val);
                                                    } else {
                                                        nprev[key] = val;
                                                    }
                                                    return nprev;
                                                })}} 
                                                className="plan-field" placeholder='Amount' />
                                    </span>
                                    <FormControl>
                                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                        <Select
                                            for="demo-simple-select-label"
                                            style={{ width: "100px" }}
                                            className="plan-field"
                                            placeholder='Select Type'
                                            onChange={(e) => {
                                                addToUpdatedVouchers(vouchers[key]);
                                                let val = e.target.value;
                                                handleUpdateAmount((prev) => {
                                                    const nprev = [...prev];
                                                    if(nprev[key].id){
                                                        nprev[key].type = val;
                                                    } else {
                                                        nprev[key] = val;
                                                    }
                                                    return nprev;
                                                })}} 
                                            value={vouchers[key].type}
                                        >
                                            <MenuItem value={'testimonials'} >Testimonials</MenuItem>
                                            <MenuItem value={'referral'} >Referral</MenuItem>
                                            <MenuItem value={'beforeandafters'} >Before and afters</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <InputLabel id="demo-simple-select-label1">Country/Currency</InputLabel>
                                        <Select
                                            for="demo-simple-select-label1"
                                            style={{ width: "100px" }}
                                            className="plan-field"
                                            value={vouchers[key].country}
                                            onChange={(e) => {
                                                addToUpdatedVouchers(vouchers[key]);
                                                let val = e.target.value;
                                                handleUpdateAmount((prev) => {
                                                    const nprev = [...prev];
                                                    if(nprev[key].id){
                                                        nprev[key].country = val;
                                                    } else {
                                                        nprev[key] = val;
                                                    }
                                                    return nprev;
                                                })}} 
                                        >
                                            <MenuItem value={"AE"}>UAE</MenuItem>
                                            <MenuItem value={"SA"}>KSA</MenuItem>
                                            <MenuItem value={"QA"}>QAT</MenuItem>
                                            <MenuItem value={"LB"}>LEB</MenuItem>
                                            <MenuItem value={"KW"}>KWT</MenuItem>
                                            <MenuItem value={"BH"}>BAH</MenuItem>
                                            <MenuItem value={"OM"}>OMR</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Button onClick={() => deleteVoucher(vouchers[key].id)} destructive>-</Button>
                                    <span style={{position:"absolute", right:"5px", bottom: '5px'}}>{key == vouchers.length - 1 && <Button primary onClick={() => createVoucher('update')}>Update</Button>}</span>
                                </div>
                            })}
                        </div>
                    </Card.Section>
                </Card>
            </Page>
        </NavigationLayout>
    )
}

export default Voucher