import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from '@mui/material';

import { useStyles } from './dialog.styles';
import { useState } from 'react';

const EditSmilePlanCategoryDialog = ({
  open,
  setOpen,
  updateSmilePlanCategory,
  smilePlanCategory,
}) => {
  const id = smilePlanCategory.id || '';
  const nameData = smilePlanCategory.name || '';
  const [name, setName] = useState(nameData);
  const [error, setError] = useState(false);
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };

  const handleNameChange = (event) => {
    const { value } = event.target;
    setName(value);
    setError(false);
  };

  const handleCaseTypeUpdate = () => {
    void updateSmilePlanCategory({ name }, id);
    handleClose();
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>
        Edit Smile Plan Category
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          InputLabelProps={{
            classes: {
              root: classes.labelSize,
            },
          }}
          className={classes.input}
          onChange={handleNameChange}
          margin="dense"
          id="name"
          label="Case Type Name"
          type="text"
          fullWidth
          value={name}
          variant="standard"
          error={error}
          helperText={error ? 'Name is required' : ''}
        />
      </DialogContent>
      <DialogActions className={classes.button}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button disabled={name === ''} onClick={handleCaseTypeUpdate}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditSmilePlanCategoryDialog;
