import React, {
  useCallback,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import {
  DataTable,
  Button,
  Page,
  AppProvider,
  Image,
  DisplayText,
  Loading,
  FormLayout,
  Badge,
  TextField,
  Card,
  TextStyle,
  ButtonGroup,
  Modal,
  TextContainer,
  Toast,
} from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../axios";
import history from "../history";
import {} from "@shopify/polaris-icons";
import "@zendeskgarden/react-pagination/dist/styles.css";
import emptyIcon from "../img/emptyList.svg";
import EllipsisText from "react-ellipsis-text";
import { ExportMinor } from "@shopify/polaris-icons";
import ReactHtmlParser from "react-html-parser";
import Select from "react-dropdown-select";

export default function ImpressionSalesReport() {
  const [userID, setuserID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [itemsCountsTable, setItemsCountsTable] = useState([]);
  const [itemsMainTable, setItemsMainTable] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [queryValueSalesOwner, setQueryValueSalesOwner] = useState("");
  const [currentID, setCurrentID] = useState(0);
  const [active, setActive] = useState(false);
  const [toastMessage, setToastMessage] = useState("Account Disactivated");
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content={toastMessage} onDismiss={toggleActive} />
  ) : null;
  const node = useRef(null);
  const handleFocus = useCallback(() => {
    if (node.current == null) {
      return;
    }
    node.current.input.select();
    document.execCommand("copy");
  }, []);
  const [activeNote, setActiveNote] = useState(false);
  const handleChangeNote = useCallback(
    () => setActiveNote(!activeNote),
    [activeNote]
  );
  const activatorNote = <Button onClick={handleChangeNote}>Open</Button>;
  const [valueNote, setValueNote] = useState("");

  const [optionsSalesOwner, setOptionsSalesOwner] = useState([
    { label: "None", value: "None" },
    { label: "Amir Abdel Baki", value: "Amir Abdel Baki" },
    { label: "Karl Salameh", value: "Karl Salameh" },
    { label: "Dori Atallah", value: "Dori Atallah" },
    { label: "Mashhadeye Mokdad", value: "Mashhadeye Mokdad" },
    { label: "Joseph Chreim", value: "Joseph Chreim" },
  ]);
  const [selectedSalesOwner, setSelectedSalesOwner] = useState("");

  const [optionsStatus, setOptionsStatus] = useState([
    {
      label: "Client purchased Impression Kit",
      value: "Client purchased Impression Kit",
    },
    { label: "Lab sent Impression Kit", value: "Lab sent Impression Kit" },
    {
      label: "Client received Impression kit",
      value: "Client received Impression kit",
    },
    {
      label: "Client sent Impression kit",
      value: "Client sent Impression kit",
    },
    {
      label: "Lab received Impression Kit",
      value: "Lab received Impression Kit",
    },
    {
      label: "Lab sent Aligner Kit",
      value: "Lab sent Aligner Kit",
    },
  ]);
  const [selectedStatus, setSelectedStatus] = useState("");
  useEffect(() => {
    getData();
  }, [selectedSalesOwner, selectedStatus]);

  function getData() {
    let url = `admin/v1/reports/impression-sales-stage?filter[sales_owner]=${Array.prototype.map
      .call(selectedSalesOwner, function (item) {
        return item.label;
      })
      .join(",")}`;
    if (selectedStatus && selectedSalesOwner) {
      url = `admin/v1/reports/impression-sales-stage?filter[sub_status]=${Array.prototype.map
        .call(selectedStatus, function (item) {
          return item.label;
        })
        .join(",")}&filter[sales_owner]=${Array.prototype.map
        .call(selectedSalesOwner, function (item) {
          return item.label;
        })
        .join(",")}`;
    } else if (selectedStatus && !selectedSalesOwner) {
      url = `admin/v1/reports/impression-sales-stage?filter[sub_status]=${Array.prototype.map
        .call(selectedStatus, function (item) {
          return item.label;
        })
        .join(",")}`;
    } else if (!selectedStatus && selectedSalesOwner) {
      url = `admin/v1/reports/impression-sales-stage?filter[sales_owner]=${Array.prototype.map
        .call(selectedSalesOwner, function (item) {
          return item.label;
        })
        .join(",")}`;
    } else if (!selectedStatus && !selectedSalesOwner) {
      url = `admin/v1/reports/impression-sales-stage`;
    }

    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setIsLoading(false);
        setItemsCountsTable([
          [
            <TextStyle variation="strong">Count</TextStyle>,
            result.data.client_purchased_impression_count,
            result.data.lab_sent_impression_count,
            result.data.client_received_impression_count,
            result.data.client_sent_impression_count,
            result.data.lab_received_impression_count,
            result.data.lab_sent_aligner_kit_count,
            result.data.total,
          ],
          [
            <TextStyle variation="strong">Average</TextStyle>,
            result.data.client_purchased_impression.avg_elapsed_day &&
              result.data.client_purchased_impression.avg_elapsed_day.toFixed(
                2
              ),
            result.data.lab_sent_impression.avg_elapsed_day &&
              result.data.lab_sent_impression.avg_elapsed_day.toFixed(2),
            result.data.client_received_impression.avg_elapsed_day &&
              result.data.client_received_impression.avg_elapsed_day.toFixed(2),
            result.data.client_sent_impression.avg_elapsed_day &&
              result.data.client_sent_impression.avg_elapsed_day.toFixed(2),
            result.data.lab_received_impression.avg_elapsed_day &&
              result.data.lab_received_impression.avg_elapsed_day.toFixed(2),
            result.data.lab_sent_aligner_kit.avg_elapsed_day &&
              result.data.lab_sent_aligner_kit.avg_elapsed_day.toFixed(2),
            "",
          ],
          [
            <TextStyle variation="strong">Minimum</TextStyle>,
            result.data.client_purchased_impression.min_elapsed_day,
            result.data.lab_sent_impression.min_elapsed_day,
            result.data.client_received_impression.min_elapsed_day,
            result.data.client_sent_impression.min_elapsed_day,
            result.data.lab_received_impression.min_elapsed_day,
            result.data.lab_sent_aligner_kit.min_elapsed_day,
            "",
          ],
          [
            <TextStyle variation="strong">Maximum</TextStyle>,
            result.data.client_purchased_impression.max_elapsed_day,
            result.data.lab_sent_impression.max_elapsed_day,
            result.data.client_received_impression.max_elapsed_day,
            result.data.client_sent_impression.max_elapsed_day,
            result.data.lab_received_impression.max_elapsed_day,
            result.data.lab_sent_aligner_kit.max_elapsed_day,
            "",
          ],
        ]);
        // setItemsAverageTable([
        //   [
        //     result.data.client_purchased_impression.avg_elapsed_day,
        //     result.data.lab_sent_impression.avg_elapsed_day,
        //     result.data.client_received_impression.avg_elapsed_day,
        //     result.data.client_sent_impression.avg_elapsed_day,
        //     result.data.lab_received_impression.avg_elapsed_day,
        //   ],
        // ]);
        // setItemsMinimumTable([
        //   [
        //     result.data.client_purchased_impression.min_elapsed_day,
        //     result.data.lab_sent_impression.min_elapsed_day,
        //     result.data.client_received_impression.min_elapsed_day,
        //     result.data.client_sent_impression.min_elapsed_day,
        //     result.data.lab_received_impression.min_elapsed_day,
        //   ],
        // ]);
        // setItemsMaximumTable([
        //   [
        //     result.data.client_purchased_impression.max_elapsed_day,
        //     result.data.lab_sent_impression.max_elapsed_day,
        //     result.data.client_received_impression.max_elapsed_day,
        //     result.data.client_sent_impression.max_elapsed_day,
        //     result.data.lab_received_impression.max_elapsed_day,
        //   ],
        // ]);
        let array = [];
        if (
          result.data.detailed_table.client_purchased_impression.length !== 0
        ) {
          for (
            var i = 0;
            i < result.data.detailed_table.client_purchased_impression.length;
            i++
          ) {
            let item =
              result.data.detailed_table.client_purchased_impression[i];

            array.push([
              item.sub_status,
              item.id,
              <div
                style={{ cursor: "pointer" }}
                id={item.id}
                onClick={(e) => onUserClick(e)}
              >
                {item.first_name + " " + item.last_name}
              </div>,
              item.sales_owner,
              item.elapsed_day,
              <EllipsisText
                text={ReactHtmlParser(item.payment_notes).toString()}
                length={20}
              />,
              item.redo_impression_message ? (
                <Badge>{item.redo_impression_message}</Badge>
              ) : null,
              <ButtonGroup>
                <Button
                  id={String(item.id)}
                  onClick={(e) =>
                    handleEditNote(
                      e,
                      item.payment_notes ? item.payment_notes : ""
                    )
                  }
                >
                  Edit Note
                </Button>
              </ButtonGroup>,
            ]);
          }
        }

        if (result.data.detailed_table.lab_sent_impression.length !== 0) {
          for (
            var i = 0;
            i < result.data.detailed_table.lab_sent_impression.length;
            i++
          ) {
            let item = result.data.detailed_table.lab_sent_impression[i];
            array.push([
              item.sub_status,
              item.id,
              <div
                style={{ cursor: "pointer" }}
                id={item.id}
                onClick={(e) => onUserClick(e)}
              >
                {item.first_name + " " + item.last_name}
              </div>,
              item.sales_owner,
              item.elapsed_day,

              <div style={{ pointer: "cursor" }}>
                <EllipsisText
                  text={ReactHtmlParser(item.payment_notes).toString()}
                  length={20}
                />
              </div>,
              item.redo_impression_message ? (
                <Badge>{item.redo_impression_message}</Badge>
              ) : null,
              <ButtonGroup>
                <Button
                  id={String(item.id)}
                  onClick={(e) =>
                    handleEditNote(
                      e,
                      item.payment_notes ? item.payment_notes : ""
                    )
                  }
                >
                  Edit Note
                </Button>
              </ButtonGroup>,
            ]);
          }
        }
        if (
          result.data.detailed_table.client_received_impression.length !== 0
        ) {
          for (
            var i = 0;
            i < result.data.detailed_table.client_received_impression.length;
            i++
          ) {
            let item = result.data.detailed_table.client_received_impression[i];
            array.push([
              item.sub_status,
              item.id,
              <div
                style={{ cursor: "pointer" }}
                id={item.id}
                onClick={(e) => onUserClick(e)}
              >
                {item.first_name + " " + item.last_name}
              </div>,
              item.sales_owner,
              item.elapsed_day,

              <div style={{ pointer: "cursor" }}>
                <EllipsisText
                  text={ReactHtmlParser(item.payment_notes).toString()}
                  length={20}
                />
              </div>,
              item.redo_impression_message ? (
                <Badge>{item.redo_impression_message}</Badge>
              ) : null,
              <ButtonGroup>
                <Button
                  id={String(item.id)}
                  onClick={(e) =>
                    handleEditNote(
                      e,
                      item.payment_notes ? item.payment_notes : ""
                    )
                  }
                >
                  Edit Note
                </Button>
              </ButtonGroup>,
            ]);
          }
        }
        if (result.data.detailed_table.client_sent_impression.length !== 0) {
          for (
            var i = 0;
            i < result.data.detailed_table.client_sent_impression.length;
            i++
          ) {
            let item = result.data.detailed_table.client_sent_impression[i];
            array.push([
              item.sub_status,
              item.id,
              <div
                style={{ cursor: "pointer" }}
                id={item.id}
                onClick={(e) => onUserClick(e)}
              >
                {item.first_name + " " + item.last_name}
              </div>,
              item.sales_owner,
              item.elapsed_day,

              <div style={{ pointer: "cursor" }}>
                <EllipsisText
                  text={ReactHtmlParser(item.payment_notes).toString()}
                  length={20}
                />
              </div>,
              item.redo_impression_message ? (
                <Badge>{item.redo_impression_message}</Badge>
              ) : null,
              <ButtonGroup>
                <Button
                  id={String(item.id)}
                  onClick={(e) =>
                    handleEditNote(
                      e,
                      item.payment_notes ? item.payment_notes : ""
                    )
                  }
                >
                  Edit Note
                </Button>
              </ButtonGroup>,
            ]);
          }
        }
        if (result.data.detailed_table.lab_received_impression.length !== 0) {
          for (
            var i = 0;
            i < result.data.detailed_table.lab_received_impression.length;
            i++
          ) {
            let item = result.data.detailed_table.lab_received_impression[i];
            array.push([
              item.sub_status,
              item.id,
              <div
                style={{ cursor: "pointer" }}
                id={item.id}
                onClick={(e) => onUserClick(e)}
              >
                {item.first_name + " " + item.last_name}
              </div>,
              item.sales_owner,
              item.elapsed_day,

              <div style={{ cursor: "pointer" }}>
                {" "}
                <EllipsisText
                  text={ReactHtmlParser(item.payment_notes).toString()}
                  length={30}
                />
              </div>,
              item.redo_impression_message ? (
                <Badge>{item.redo_impression_message}</Badge>
              ) : null,
              <ButtonGroup>
                <Button
                  id={String(item.id)}
                  onClick={(e) =>
                    handleEditNote(
                      e,
                      item.payment_notes ? item.payment_notes : ""
                    )
                  }
                >
                  Edit Note
                </Button>
              </ButtonGroup>,
            ]);
          }
        }

        if (result.data.detailed_table.lab_sent_aligner_kit.length !== 0) {
          for (
            var i = 0;
            i < result.data.detailed_table.lab_sent_aligner_kit.length;
            i++
          ) {
            let item = result.data.detailed_table.lab_sent_aligner_kit[i];
            array.push([
              item.sub_status,
              item.id,
              <div
                style={{ cursor: "pointer" }}
                id={item.id}
                onClick={(e) => onUserClick(e)}
              >
                {item.first_name + " " + item.last_name}
              </div>,
              item.sales_owner,
              item.elapsed_day,

              <div style={{ cursor: "pointer" }}>
                {" "}
                <EllipsisText
                  text={ReactHtmlParser(item.payment_notes).toString()}
                  length={30}
                />
              </div>,
              item.redo_impression_message ? (
                <Badge>{item.redo_impression_message}</Badge>
              ) : null,
              <ButtonGroup>
                <Button
                  id={String(item.id)}
                  onClick={(e) =>
                    handleEditNote(
                      e,
                      item.payment_notes ? item.payment_notes : ""
                    )
                  }
                >
                  Edit Note
                </Button>
              </ButtonGroup>,
            ]);
          }
        }
        // console.log("result=", array);
        setItemsMainTable(array);
      })
      .catch((err) => console.log(err));
  }
  function handleButtonSubmitNote(e) {
    let currentID = e.currentTarget.id;
    const bodyObj = {
      payment_notes: valueNote,
    };
    axios
      .post(`admin/v1/users/${userID}/payment-notes `, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        setToastMessage("Note Added Successfully");
        setActiveNote(false);
        toggleActive();
        getData();
      })
      .catch((err) => console.log(err));
  }
  function handleEditNote(e, note) {
    setuserID(e.currentTarget.id);
    setValueNote(note);
    setActiveNote(true);
  }
  function handleFiltersQueryChange(queryValue) {
    setQueryValue(queryValue);
  }
  function handleFiltersQuerySalesOwnerChange(queryValueSalesOwner) {
    setQueryValueSalesOwner(queryValueSalesOwner);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const handleQueryValueRemoveSalesOwner = useCallback(
    () => setQueryValueSalesOwner(""),
    []
  );
  const handleFiltersrClearAllSalesOwner = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemoveSalesOwner();
  }, [handleAvailabilityRemove, handleQueryValueRemoveSalesOwner]);

  const filters = [
    // {
    //   key: "availability",
    //   label: "Filter by",
    //   filter: (
    //     <ChoiceList
    //       title="Filter by"
    //       titleHidden
    //       choices={[
    //         { label: "Status", value: "sub_status" },
    //         { label: "Sales Owner", value: "sales_owner" },
    //         { label: "Both", value: "both" },
    //       ]}
    //       selected={availability || []}
    //       onChange={handleAvailabilityChange}
    //       // allowMultiple
    //     />
    //   ),
    //   shortcut: true,
    // },
  ];

  // const appliedFilters = [];
  // if (!isEmpty(availability)) {
  //   const key = "availability";
  //   appliedFilters.push({
  //     key,
  //     label: disambiguateLabel(key, availability),
  //     onRemove: handleAvailabilityRemove,
  //   });
  // }

  // const appliedFiltersSaleOwner = [];
  // if (!isEmpty(availability)) {
  //   const key = "availability";
  //   appliedFiltersSaleOwner.push({
  //     key,
  //     label: disambiguateLabel(key, availability),
  //     onRemove: handleAvailabilityRemove,
  //   });
  // }

  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    []
  );
  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div className="message-no-result">
      <div className="message-no-result-img">
        <Image src={emptyIcon}></Image>{" "}
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : null;
  const [activeDeleteMessage, setActiveDeleteMessage] = useState(false);

  return (
    <Page
      fullWidth
      title="Impression Sales Report"
      breadcrumbs={[{ content: "List Of Reports", url: "/admin/reports" }]}
      secondaryActions={[
        {
          content: "Export",
          icon: ExportMinor,
          onAction: exportOrders,
        },
      ]}
    >
      {" "}
      {loadingMarkup}
      <Card sectioned>
        <FormLayout>
          <Select
            placeholder="Filter by sales owner"
            onChange={handleSelectSalesOwner}
            values={[]}
            options={optionsSalesOwner}
            multi
          />
          <Select
            placeholder="Filter by status"
            onChange={handleSelectStatus}
            values={[]}
            options={optionsStatus}
            multi
          />
          {/* <Filters
              queryValue={queryValue}
              filters={filters}
              // appliedFilters={appliedFilters}
              onQueryChange={handleFiltersQueryChange}
              onQueryClear={handleQueryValueRemove}
              onClearAll={handleFiltersClearAll}
              queryPlaceholder="Filter by status"
            />
            <Filters
              queryValue={queryValueSalesOwner}
              filters={filters}
              // appliedFilters={appliedFiltersSaleOwner}
              onQueryChange={handleFiltersQuerySalesOwnerChange}
              onQueryClear={handleQueryValueRemoveSalesOwner}
              onClearAll={handleFiltersrClearAllSalesOwner}
              queryPlaceholder="Filter by sales owner"
            /> */}
          <Card>
            {" "}
            <DataTable
              columnContentTypes={[
                "text",
                "text",
                "text",
                "text",
                "text",
                "text",
                "text",
              ]}
              headings={[
                <TextStyle variation="strong"></TextStyle>,
                <TextStyle variation="strong">
                  Client Purchased Impression
                </TextStyle>,
                <TextStyle variation="strong">Lab Sent Impression</TextStyle>,
                <TextStyle variation="strong">
                  Client Received Impression
                </TextStyle>,
                <TextStyle variation="strong">
                  Client Sent Impression
                </TextStyle>,
                <TextStyle variation="strong">
                  Lab Received Impression Kit
                </TextStyle>,
                <TextStyle variation="strong">Lab Sent Aligner Kit</TextStyle>,
                <TextStyle variation="strong">Total</TextStyle>,
              ]}
              rows={itemsCountsTable}
              sortable={[false, false, false, false, false, false, false]}
              defaultSortDirection="descending"
              verticalAlign="center"
              // initialSortColumnIndex={0}
              // onSort={handleSort}
            />
          </Card>

          <Card>
            <DataTable
              columnContentTypes={[
                "text",
                "text",
                "text",
                "text",
                "text",
                "text",
                "text",
                "text",
              ]}
              headings={[
                <TextStyle variation="strong">Status</TextStyle>,
                <TextStyle variation="strong">Client ID</TextStyle>,
                <TextStyle variation="strong">Client Name</TextStyle>,
                <TextStyle variation="strong">Sales Owner</TextStyle>,
                <TextStyle variation="strong">Elapsed Days</TextStyle>,
                <TextStyle variation="strong">Note</TextStyle>,
                <TextStyle variation="strong"></TextStyle>,
                <TextStyle variation="strong"></TextStyle>,
              ]}
              rows={itemsMainTable}
              sortable={[false, false, false, false, false, false]}
              defaultSortDirection="descending"
              verticalAlign="center"
              // initialSortColumnIndex={0}
              // onSort={handleSort}
            />
            {/* {totalPages >= 2 && (
            <div id="pagination" style={{ marginTop: 20 }}>
              <Pagination
                totalPages={totalPages}
                pagePadding={3}
                currentPage={currentPage}
                onChange={handlePageChange}
              />
            </div>
          )} */}
            {emtyState}
          </Card>
        </FormLayout>
      </Card>
      {toastMarkup}
      {activatorNote && (
        <Modal
          // activator={activatorNote}
          open={activeNote}
          onClose={handleChangeNote}
          title="Add Note"
        >
          <Modal.Section>
            <TextContainer>
              <TextField
                label="Note"
                onFocus={handleFocus}
                value={valueNote}
                onChange={(value) => {
                  setValueNote(value);
                }}
                connectedRight={
                  <Button primary onClick={handleButtonSubmitNote}>
                    SUBMIT
                  </Button>
                }
              />
            </TextContainer>
          </Modal.Section>
        </Modal>
      )}
    </Page>
  );
  function handleSelectSalesOwner(selectedSalesOwner) {
    setSelectedSalesOwner(selectedSalesOwner);
  }
  function handleSelectStatus(selectedStatus) {
    setSelectedStatus(selectedStatus);
  }
  function onUserClick(e) {
    e.ctrlKey
      ? window.open(
          "/admin/users/" + e.currentTarget.id + "/dashboard",
          "_blank"
        )
      : history.push("/admin/users/" + e.currentTarget.id + "/dashboard");
  }
  function exportOrders() {
    window.open(
      `${process.env.REACT_APP_BASE_URL}/admin/v1/reports/export?filter[${
        availability === "" ? "sub_status" : availability
      }]=${queryValue}&token=` +
        "Bearer " +
        Cookie.get("token")
    );
  }
  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element.id) === parseInt(id);
    });
  }
  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return value.map((val) => `Filter by ${val}`).join(", ");
      case "productType":
        return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }

  function handlePageChange(currentPage) {
    setCurrentPage(currentPage);
  }
  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element.id) === parseInt(id);
    });
  }

  function handleChangeDeleteMessage() {
    setActiveDeleteMessage(false);
  }

  function handleNoDelete() {
    setActiveDeleteMessage(!activeDeleteMessage);
  }
  function handleYesDelete(id) {
    setActiveDeleteMessage(!activeDeleteMessage);
    axios
      .delete(`/admin/regards/${id}`)
      .then((res) => {
        setToastMessage("This regard is successfully deleted");
        toggleActive();
        getData();
      })
      .catch((err) => console.log(err));
  }
}
