import React, { useCallback, useEffect, useState } from "react";
import {
  InlineError,
  Page,
  FormLayout,
  TextField,
  Card,
  Modal,
  PageActions,
  Thumbnail,
  Stack,
  DropZone,
  TextStyle,
  Button,
  Toast,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import { useParams } from "react-router-dom";
import { NoteMinor } from "@shopify/polaris-icons";
import CustomTooltip from "../../../components/CustomTooltip";
import { FormControl, MenuItem, Select } from "@mui/material";
import moment from "moment";
import { GeneralFormats } from "../../../util/constants";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => {
  return {
    title: {
      fontSize: 14,
      marginBottom: 5,
    },
    date: {
      fontSize: 13,
      marginTop: 5,
    },
  };
});

function EditScan({ history }) {
  const { id, documentId } = useParams();
  const classes = useStyles();
  const dateFormat = GeneralFormats.dateFormat;
  const [date, setDate] = useState({
    bite_scan_1_created_at: "",
    bite_scan_1_updated_at: "",
    bite_scan_2_created_at: "",
    bite_scan_2_updated_at: "",
    lower_scan_created_at: "",
    lower_scan_updated_at: "",
  });
  const {
    bite_scan_1_created_at,
    bite_scan_1_updated_at,
    bite_scan_2_created_at,
    bite_scan_2_updated_at,
    lower_scan_created_at,
    lower_scan_updated_at
  } = date;
  const [popupActive, setPopupActive] = useState(false);
  const [document, isDocument] = useState(false);
  const [lowerScan, isLowerScan] = useState(false);
  const [biteScan1, isBiteScan1] = useState(false);
  const [biteScan2, isBiteScan2] = useState(false);
  const [imageError, setImageError] = useState("");
  const [imageError2, setImageError2] = useState("");
  const [imageError3, setImageError3] = useState("");
  const [imageError4, setImageError4] = useState("");
  const [files, setFiles] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [files3, setFiles3] = useState([]);
  const [files4, setFiles4] = useState([]);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const fileUpload = !files.length && <DropZone.FileUpload />;
  const fileUpload2 = !files2.length && <DropZone.FileUpload />;
  const fileUpload3 = !files3.length && <DropZone.FileUpload />;
  const fileUpload4 = !files4.length && <DropZone.FileUpload />;
  const [imagePreview, setImagePreview] = useState("");
  const [imagePreview2, setImagePreview2] = useState("");
  const [imagePreview3, setImagePreview3] = useState("");
  const [imagePreview4, setImagePreview4] = useState("");
  const [imageKey, setImageKey] = useState("");
  const [imageKey2, setImageKey2] = useState("");
  const [imageKey3, setImageKey3] = useState("");
  const [imageKey4, setImageKey4] = useState("");
  const [disapprovedReason, setDisapprovedReason] = useState('');
  const [segmentationTechnicians, setSegmentationTechnicians] = useState([]);
  const [segmentationTechnician, setSegmentationTechnician] = useState({
    id: '',
    date: null,
  });
  const urlPlaceholder = "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304";
  const uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : NoteMinor
        }
      />
      <div>{files[files.length - 1].name} </div>
    </Stack>
  );
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const sendImage = useCallback(
    (files) => {
      setPopupActive(true);
      setImagePreview(
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : urlPlaceholder
      );
      let url2 = "";
      const form_data = new FormData();
      form_data.append("content_type", files[files.length - 1].type);
      form_data.append("file_name", files[files.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKey(res.data.key);
          url2 = res.data.url;
          axios
            .put(url2, files[files.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": files[files.length - 1].type,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },

    [files]
  );

  const uploadedFiles2 = files2.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files2[files2.length - 1].name}
        source={
          validImageTypes.indexOf(files2[files2.length - 1].type) > 0
            ? window.URL.createObjectURL(files2[files2.length - 1])
            : NoteMinor
        }
      />
      <div>{files2[files2.length - 1].name} </div>
    </Stack>
  );
  const handleDropZoneDrop2 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles2((file) => [...file, ...acceptedFiles]),
    []
  );
  const sendImage2 = useCallback(
    (file) => {
      setPopupActive(true);
      setImagePreview2(
        validImageTypes.indexOf(file[file.length - 1].type) > 0
          ? window.URL.createObjectURL(file[file.length - 1])
          : urlPlaceholder
      );
      let url2 = "";
      const form_data = new FormData();
      form_data.append("content_type", file[file.length - 1].type);
      form_data.append("file_name", file[file.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKey2(res.data.key);
          setImageError2("");
          url2 = res.data.url;
          axios
            .put(url2, file[file.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": file[file.length - 1].type,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },

    [files2]
  );

  const uploadedFiles3 = files3.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files3[files3.length - 1].name}
        source={
          validImageTypes.indexOf(files3[files3.length - 1].type) > 0
            ? window.URL.createObjectURL(files3[files3.length - 1])
            : NoteMinor
        }
      />
      <div>{files3[files3.length - 1].name} </div>
    </Stack>
  );
  const handleDropZoneDrop3 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles3((file) => [...file, ...acceptedFiles]),
    []
  );
  const sendImage3 = useCallback(
    (file) => {
      setPopupActive(true);
      setImagePreview3(
        validImageTypes.indexOf(file[file.length - 1].type) > 0
          ? window.URL.createObjectURL(file[file.length - 1])
          : urlPlaceholder
      );
      let url2 = "";
      const form_data = new FormData();
      form_data.append("content_type", file[file.length - 1].type);
      form_data.append("file_name", file[file.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKey3(res.data.key);
          setImageError3("");
          url2 = res.data.url;
          axios
            .put(url2, file[file.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": file[file.length - 1].type,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },

    [files3]
  );
  const uploadedFiles4 = files4.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files4[files4.length - 1].name}
        source={
          validImageTypes.indexOf(files4[files4.length - 1].type) > 0
            ? window.URL.createObjectURL(files4[files4.length - 1])
            : NoteMinor
        }
      />
      <div>{files4[files4.length - 1].name} </div>
    </Stack>
  );
  const handleDropZoneDrop4 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles4((file) => [...file, ...acceptedFiles]),
    []
  );
  const sendImage4 = useCallback(
    (file) => {
        setPopupActive(true);
        setImagePreview4(
          validImageTypes.indexOf(file[file.length - 1].type) > 0
          ? window.URL.createObjectURL(file[file.length - 1])
          : urlPlaceholder
        );
        let url2 = "";
        const form_data = new FormData();
        form_data.append("content_type", file[file.length - 1].type);
        form_data.append("file_name", file[file.length - 1].name);
        axios
          .post("/admin/v1/images/s3", form_data, {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          })
          .then((res) => {
            setImageKey4(res.data.key);
            setImageError4("");
            url2 = res.data.url;
            axios
              .put(url2, file[file.length - 1], {
                headers: {
                  "x-amz-acl": "public-read-write",
                  "Content-Type": file[file.length - 1].type,
                },
              })
              .then((res) => {
                setPopupActive(false);
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
        },

    [files4]
  );

  function handleDelete(e) {
    const imageID = e.currentTarget.id;
    if (imageID == "document") {
        if(document){
          updateImageStates(imageID)
          deleteImage(imageID);
        }else{
          updateImageStates(imageID)
        }
    }
    if (imageID == "lower_scan") {
        if(lowerScan){
          updateImageStates(imageID)
          deleteImage(imageID);
        }else{
          updateImageStates(imageID)
        }
    }
    if (imageID == "bite_scan_1") {
      if(biteScan1){
        updateImageStates(imageID)
        deleteImage(imageID);
      }else{
        updateImageStates(imageID)
      }
    }

    if (imageID == "bite_scan_2") {
      if(biteScan2){
        updateImageStates(imageID)
        deleteImage(imageID);
      }else{
        updateImageStates(imageID)
      }
    }
  }

  const updateImageStates = (value) => {
    if (value == "document") {
          setImageKey("");
          setImagePreview("");
          setFiles([]);
    }
    if (value == "lower_scan") {
          setImageKey2("");
          setImagePreview2("");
          setFiles2([]);
    }
    if (value == "bite_scan_1") {
          setImageKey3("");
          setImagePreview3("");
          setFiles3([]); 
    }
    if (value == "bite_scan_2") {
          setImageKey4("");
          setImagePreview4("");
          setFiles4([]);
    }
  }

  const handleDeleteMessage = (value, message) => {
    if (value.document) {
      setImageError("Upper scan image" + message)
    }
    if (value.lower_scan) {
      setImageError2("Lower scan image" + message)

    }
    if (value.bite_scan_1) {
      setImageError3("Bite scan 1 image" + message)

    }
    if (value.bite_scan_2) {
      setImageError4("Bite scan 2 image" + message)

    }
  }

  const handleDeleteApiCall = (value) =>{      
      if(value.document){
        setImagePreview(typeOfFile(value.document)? value.document_url : urlPlaceholder);
        isDocument(true);
      }else{
        setImagePreview("");
        isDocument(false);
      }
      
      if(value.lower_scan){
        setImagePreview2(typeOfFile(value.lower_scan)? value.lower_scan_url : urlPlaceholder)
        isLowerScan(true);
      }else{
        setImagePreview2("");
        isLowerScan(false);
      }
      
      if(value.bite_scan_1){
        setImagePreview3(typeOfFile(value.bite_scan_1)? value.bite_scan_one_url : urlPlaceholder)
        isBiteScan1(true);
      }else{
        setImagePreview3("");
        isBiteScan1(false);
      }
      
      if(value.bite_scan_2){
        setImagePreview4(typeOfFile(value.bite_scan_2)? value.bite_scan_two_url : urlPlaceholder)
        isBiteScan2(true);
      }else{
        setImagePreview4("");
        isBiteScan2(false);
      }
  }

  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const toastMarkup = active ? (
    <Toast content="Image Deleted" onDismiss={toggleActive} />
  ) : null;

  const deleteImage = async (value) =>{
    setPopupActive(true);
    try{
        const res = await axios.delete(`/admin/v1/users/${id}/documents/${documentId}/${value}/delete`, {
          headers:{
            "Authorization":`Bearer ${Cookie.get('token')}`
          }
        });
        const data = res.data;
        if(data.success){
          updateImageStates(data.key);
          toggleActive();
        }else{
            data.key &&  handleDeleteMessage(data.key, " wasn't deleted");
        }
        setPopupActive(false);
    }catch(error){
        console.log({error});
        setPopupActive(false);
    }
    
  }

  const fetchDocumentById = async () =>{
    setPopupActive(true);
    try{
        const res = await axios.get(`admin/v1/users/${id}/documents/${documentId}/show`, {
          headers:{
            "Authorization":`Bearer ${Cookie.get('token')}`
          }
        });
          const data = res.data;
          if(data.success){
            setSegmentationTechnician({
              id: data.data.segmentation_technician_id ? data.data.segmentation_technician_id : '',
              date: data.data.segmentation_date,
            });
            const newDate = {
              bite_scan_1_created_at: data.data.bite_scan_1_created_at,
              bite_scan_1_updated_at: data.data.bite_scan_1_updated_at,
              bite_scan_2_created_at: data.data.bite_scan_2_created_at,
              bite_scan_2_updated_at: data.data.bite_scan_2_updated_at,
              lower_scan_created_at: data.data.lower_scan_created_at,
              lower_scan_updated_at: data.data.lower_scan_updated_at,
            };
            setDate(newDate);
            setDisapprovedReason(data.data.scan_status?.reason);
            handleDeleteApiCall(data.data);
          }
          setSegmentationTechnicians(data.segmentationTechnicians);
          setPopupActive(false);
    }catch(error){
        setPopupActive(false);
        console.log({error});
    }
  }

  const typeOfFile = (value) => {
    const n = value.lastIndexOf('.');
    const validType = ['jpg','jpeg', 'png', 'gif'];
    const value2 =  value.split('.', n);
    if(validType.indexOf(value2[value2.length - 1]) != -1){
      return true;
    }else{
      return false;
    }
  }


  useEffect(()=>{
    fetchDocumentById();
  },[])

  return (
    <Page
      title="Edit Scan"
      breadcrumbs={[
        {
          url: `/admin/users/${id}/dashboard`,
        },
      ]}
    >
      <Card sectioned>
      {disapprovedReason && <span><TextStyle variation="strong">Disapproved reason:</TextStyle> {disapprovedReason}</span>}
      <FormLayout.Group>
        <FormLayout>
          <TextStyle variation="strong">Upper Scan</TextStyle>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <Thumbnail
                size="large"
                source={imagePreview ? imagePreview : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZoneDrop} onDropAccepted={sendImage}>
                {uploadedFiles}
                {fileUpload}
              </DropZone>
            </div>
          </div>
          <p style={{color:'red'}}>{imageError}</p>
          <Button id="document" onClick={(e) => handleDelete(e)}>
              Delete
          </Button>
        </FormLayout>
        <FormLayout>
          <TextStyle variation="strong">Lower Scan</TextStyle>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <Thumbnail
                size="large"
                source={imagePreview2 ? imagePreview2 : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZoneDrop2} onDropAccepted={sendImage2}>
                {uploadedFiles2}
                {fileUpload2}
              </DropZone>
            </div>
          </div>
          <p style={{color:'red'}}>{imageError2}</p>
          <div style={{display: "flex"}}>
            <Button id="lower_scan" onClick={(e) => handleDelete(e)}>
              Delete
            </Button>
            <div style={{marginLeft: "15px", display: "flex", justifyContent: "space-between", width: "100%"}}>
              <div>
                {lower_scan_created_at && (
                  <>
                    <TextStyle variation="strong">Created At:</TextStyle>
                    <p>{lower_scan_created_at}</p>
                  </>
                )
                }
              </div>
              <div>
                {lower_scan_updated_at &&
                  <>
                    <TextStyle variation="strong">Updated At:</TextStyle>
                    <p>{lower_scan_updated_at}</p>
                  </>
                }
              </div>
            </div>
          </div>
        </FormLayout>
        </FormLayout.Group>
        <FormLayout.Group>
        <FormLayout>
          <TextStyle variation="strong">Bite Scan 1</TextStyle>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <Thumbnail
                size="large"
                source={imagePreview3 ? imagePreview3 : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZoneDrop3} onDropAccepted={sendImage3}>
                {uploadedFiles3}
                {fileUpload3}
              </DropZone>
            </div>
          </div>
          <p style={{color:'red'}}>{imageError3}</p>
          <div style={{display: "flex"}}>
            <Button id="bite_scan_1" onClick={(e) => handleDelete(e)}>
              Delete
            </Button>
            <div style={{marginLeft: "15px", display: "flex", justifyContent: "space-between", width: "100%"}}>
              <div>
                {bite_scan_1_created_at && (
                  <>
                    <TextStyle variation="strong">Created At:</TextStyle>
                    <p>{bite_scan_1_created_at}</p>
                  </>
                )
                }
              </div>
              <div>
                {bite_scan_1_updated_at &&
                  <>
                    <TextStyle variation="strong">Updated At:</TextStyle>
                    <p>{bite_scan_1_updated_at}</p>
                  </>
                }
              </div>
            </div>
          </div>
        </FormLayout>
        <FormLayout>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent:"space-between"
            }}
          >
            <TextStyle variation="strong">Bite Scan 2</TextStyle>
            <CustomTooltip title="If you are using 3 Shape, upload a second bite scan file"/>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <Thumbnail
                size="large"
                source={imagePreview4 ? imagePreview4 : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZoneDrop4} onDropAccepted={sendImage4}>
                {uploadedFiles4}
                {fileUpload4}
              </DropZone>
            </div>
          </div>
          <p style={{color:'red'}}>{imageError4}</p>
          <div style={{display: "flex"}}>
            <Button id="bite_scan_2" onClick={(e) => handleDelete(e)}>
              Delete
            </Button>
            <div style={{marginLeft: "15px", display: "flex", justifyContent: "space-between", width: "100%"}}>
              <div>
                {bite_scan_2_created_at && (
                  <>
                    <TextStyle variation="strong">Created At:</TextStyle>
                    <p>{bite_scan_2_created_at}</p>
                  </>
                )
                }
              </div>
              <div>
                {bite_scan_2_updated_at &&
                  <>
                    <TextStyle variation="strong">Updated At:</TextStyle>
                    <p>{bite_scan_2_updated_at}</p>
                  </>
                }
              </div>
            </div>
          </div>
        </FormLayout>
        </FormLayout.Group>
        <FormControl sx={{ marginTop: 5, width: 'fit-content', marginLeft: "15px" }} size='small'>
          <Typography className={classes.title}>Segmentation Technician</Typography>
          <Select
            value={segmentationTechnician.id}
            onChange={(e) => setSegmentationTechnician(prev => ({
              ...prev,
              id: e.target.value,
            }))}
            displayEmpty
          >
            {segmentationTechnicians.map((option, key) => (
              <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
          {segmentationTechnician.date && <Typography className={classes.date}>Segmentation Date: {moment(segmentationTechnician.date).format(dateFormat)}</Typography>}
        </FormControl>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
      {toastMarkup}
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );

  function handleSave() {
      setPopupActive(true);
      let bodyObj = {
        document: imageKey,
        lower_scan: imageKey2,
        bite_scan_1: imageKey3,
        bite_scan_2: imageKey4,
        redo : history?.location?.state?.redo
      };

      if(files.length > 0){
          bodyObj = {
            ...bodyObj,
            name: files[files.length - 1].name,
          }
      }

      if(files2.length > 0){
        bodyObj = {
          ...bodyObj,
          lower_scan_name: files2[files2.length - 1].name,
          
        }
      }

      if(files3.length > 0){
        bodyObj = {
          ...bodyObj,
          bite_scan_1_name: files3[files3.length - 1].name,
        }
      }

      if(files4.length > 0){
        bodyObj = {
          ...bodyObj,
          bite_scan_2_name: files4[files4.length - 1].name,
        }
      }
      
      if(segmentationTechnician.id) {
        bodyObj = {
          ...bodyObj,
          segmentation_technician_id: segmentationTechnician.id,
        }
      }
      
      axios
        .post(`/admin/v1/users/${id}/document/${documentId}/update`, bodyObj, {
          headers:{
            "Authorization":`Bearer ${Cookie.get('token')}`
          }
        })
        .then((res) => {
          if(res.data.success){
            history.push("/admin/users/" + id + "/dashboard");
          }else{
            res.data.errors &&  handleDeleteMessage(res.data.errors, " wasn't uploaded");
          }
          setPopupActive(false);
        })
        .catch((err) =>{
           console.log(err)
        });
    }
}
export default EditScan;
