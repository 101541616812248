import React, { useCallback, useState, useEffect } from "react";
import {
  Page,
  AppProvider,
  FormLayout,
  Select,
  Card,
  PageActions,
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";
import history from "../history";

import { useParams } from "react-router-dom";

export default function EditPaymentStatus() {
  let { id } = useParams();

  console.log(Cookie.get("order_payment_status"));
  // Declare a new state variable, which we'll call "count"
  const [selected, setSelected] = useState(Cookie.get("order_payment_status"));
  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const options = [
    { label: "Refunded", value: "Refunded" },
    { label: "Partial Refund", value: "Partial Refund" },
  ];
  useEffect(() => {
    //setSelected()
    //  getData();
  }, []);

  function getData() {
    axios
      .get("admin/v1/users/" + id + "/status", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setSelected(result.data.data);
      })
      .catch((err) => console.log(err));
  }
  return (
    <Page
      title="Edit Payment Status"
      breadcrumbs={[{ content: "Order Details", url: "/admin/orders/" + id }]}
    >
      <Card sectioned title="Status">
        <FormLayout>
          <Select
            options={options}
            onChange={handleSelectChange}
            value={selected}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
    </Page>
  );
  function handleSave() {
    const bodyObj = {};
    bodyObj["refund-status"] = selected;
    axios
      .post(`admin/v1/orders/${id}/refund-status`, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        history.push("/admin/orders/" + id);
      })
      .catch((err) => console.log(err));
  }
}
