import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Button,
  Card,
  Layout,
  TextStyle,
  FormLayout,
  Banner,
  Toast,
  Modal,
  Stack,
  TextField,
  Checkbox,
} from "@shopify/polaris";
import axios from "../../../../axios";
import Cookies from "js-cookie";
import "@zendeskgarden/react-pagination/dist/styles.css";
import moment from "moment";
import {useHistory, useParams} from "react-router-dom";
import CustomTooltip from "../../../../components/CustomTooltip";

//
const Impressions = (props) => {
  const { id } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [popupActive, setPopupActive] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const toggleActive = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    []
  );
  const [toastMessage, setToastMessage] = useState("");
  const toastMarkup = activeToast ? (
    <Toast content={toastMessage} onDismiss={toggleActive} />
  ) : null;
  const [active, setActive] = useState(false);
  const node = useRef(null);

  const toggleModal = useCallback(() => setActive((active) => !active), []);
  const [commentValue, setCommentValue] = useState("");
  const [commentError, setCommentError] = useState("");
  const handleChangeComment = useCallback(
    (newValue) => setCommentValue(newValue),
    []
  );
  const [buttonClicked, setButtonClicked] = useState("");
  const [checkedInformationNeeded, setCheckedInformationNeeded] =
    useState(false);
  const handleChangeInformationNeeded = useCallback(
    (newChecked) => setCheckedInformationNeeded(newChecked),
    []
  );
  const [checkedPhotosNeeded, setCheckedPhotosNeeded] = useState(false);
  const handleChangePhotosNeeded = useCallback(
    (newChecked) => setCheckedPhotosNeeded(newChecked),
    []
  );
  const [checkedImpressionsNeeded, setCheckedImpressionsNeeded] =
    useState(false);
  const handleChangeImpressionsNeeded = useCallback(
    (newChecked) => setCheckedImpressionsNeeded(newChecked),
    []
  );
  const [noteValue, setNoteValue] = useState("");
  const [receivedTime, setReceivedTime] = useState("");
  const [response, setResponse] = useState("");
  const [status, setStatus] = useState("");
  const handleSubmit = () => {
    if (!commentValue) {
      setCommentError("Please enter a comment");
    } else {
      setIsSaving(true);
      const str1 = "Information Needed";
      const str2 = "Photos Needed";
      const str3 = "Impressions Needed";
      let array = [];
      checkedInformationNeeded && array.push(str1);
      checkedPhotosNeeded && array.push(str2);
      checkedImpressionsNeeded && array.push(str3);
      const bodyObj = {
        redo: true,
        reason: array.toString(),
        comment: commentValue,
        type: "IK",
      };
      axios
        .post(`/admin/v1/users/${id}/documents/unapprove`, bodyObj, {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        })
        .then((res) => {
          setIsSaving(false);
          setActive(false);
          setToastMessage("Redo Impressions Disapproved");
          toggleActive();

          setButtonClicked(moment());
        })
        .catch((err) => console.log(err));
    }
  };
  const handleReceived = () => {
    setPopupActive(true);
    const bodyObj = { received: true, redo: true, type: "IK" };
    axios
      .post(`/admin/v1/users/${id}/scan-customer-data-completed`, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        setPopupActive(false);
        setToastMessage("Redo Impressions Received");
        toggleActive();
        setButtonClicked(moment());
      })
      .catch((err) => console.log(err));
  };
  const handleApprove = () => {
    setPopupActive(true);
    const bodyObj = { redo: true, type: "IK" };
    axios
      .post(`/admin/v1/users/${id}/documents/approve`, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        setPopupActive(false);
        setToastMessage("Redo Impressions Approved");
        toggleActive();
        setButtonClicked(moment());
      })
      .catch((err) => console.log(err));
  };
  const handleDisapprove = () => {
    setActive(true);
  };
  useEffect(() => {
    axios
      .get(`admin/v1/users/${id}/documents?redo=true&type=IK`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((result) => {
        console.log("documents=", result.data.status);
        result?.data && setResponse(result.data.status);
      })
      .catch((err) => console.log("errr=", err));
  }, [buttonClicked]);
  useEffect(() => {
    axios
      .get(`admin/v1/users/${id}/scan-notes?redo=true&type=IK`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((result) => {
        result?.data?.data?.note && setNoteValue(result.data.data.note);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    axios
      .get(
        `admin/v1/users/${id}/scan-customer-data-completed?redo=true&type=IK`,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      )
      .then((result) => {
        setReceivedTime(result.data.data?.created_at);
      })
      .catch((err) => console.log(err));
  }, [buttonClicked]);
  useEffect(() => {
    axios
      .get(`/admin/v1/users/${id}/documents/status?type=IK`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((result) => {
        result?.data?.data && setStatus(result.data.data);
      })
      .catch((err) => {});
  }, [buttonClicked]);
  const history = useHistory();

  const handleEdit = () => {
    history.push(
        `/admin/users/${id}/dashboard/redo-impressions-note`,
        {
          clinicName: props.clinicName,
          planType: props.planType,
          status: props.status,
          pageTitle: props.pageTitle,
          clinicStatus: props.clinicStatus,
          userId: props.userId
        }
    );
  }
  return (
    <Layout.Section>
      <Card
        title="REDO IMPRESSIONS"
        actions={{
          content: <CustomTooltip title="" />,
        }}
      >
        <Card.Section>
          <FormLayout>
            <div className="rowDiv" style={{display:"flex", justifyContent:'center', gap:'100px'}}>
              <Button onClick={handleReceived}>
                Redo Impressions Received
              </Button>{" "}
              <Button onClick={handleApprove}>Approve Redo Impressions</Button>{" "}
              <Button onClick={handleDisapprove}>
                Disapprove Redo Impressions
              </Button>{" "}
            </div>
            {response?.ik_status && (
              <Banner
                title={`${
                  response?.ik_status == "approved"
                    ? "Approved at: "
                    : "Disapproved at: "
                } ${moment(response.action_time)
                  .add(isBetween(moment().month(), 4, 11) ? 3 : 2, "hours")
                  .format("YYYY-MM-DD hh:mm:ss")}`}
                status={
                  response?.ik_status == "approved" ? "success" : "warning"
                }
              />
            )}
            {receivedTime && (
              <Banner
                status="success"
                title={`Redo Impressions Received on: ${moment(receivedTime)
                  .add(isBetween(moment().month(), 4, 11) ? 3 : 2, "hours")
                  .format("YYYY-MM-DD hh:mm:ss")}`}
              />
            )}
            {status && response?.ik_status == "unapproved" && (
              <div style={{ marginTop: "30px" }}>
                <FormLayout>
                  <TextStyle variation="strong">
                    Why the redo impressions are disapproved?
                  </TextStyle>
                  {status.reason && (
                    <TextStyle>{`Reason: ${status.reason}`}</TextStyle>
                  )}
                  {status.comment && (
                    <TextStyle>{`Comment: ${status.comment}`}</TextStyle>
                  )}
                </FormLayout>
              </div>
            )}
          </FormLayout>
        </Card.Section>
        <Card.Section
          title="Note"
          actions={[
            {
              content: "Edit",
              onClick: handleEdit
            },
          ]}
        >
          {noteValue && <TextStyle>{noteValue}</TextStyle>}
        </Card.Section>
      </Card>

      <Modal
        open={active}
        onClose={toggleModal}
        title="Disapprove Impressions"
        secondaryActions={{
          content: "Close",
          onAction: toggleModal,
        }}
        primaryAction={[
          {
            content: "Submit",
            onAction: handleSubmit,
            loading: isSaving && true,
          },
        ]}
      >
        <Modal.Section>
          <FormLayout>
            <TextStyle>Reason</TextStyle>
            <Stack>
              <Checkbox
                label="Information Needed"
                checked={checkedInformationNeeded}
                onChange={handleChangeInformationNeeded}
              />
              <Checkbox
                label="Photos Needed"
                checked={checkedPhotosNeeded}
                onChange={handleChangePhotosNeeded}
              />
              <Checkbox
                label="Impressions Needed"
                checked={checkedImpressionsNeeded}
                onChange={handleChangeImpressionsNeeded}
              />
            </Stack>
            <TextField
              // ref={node}
              value={commentValue}
              onChange={handleChangeComment}
              multiline={3}
              error={commentError}
              label="Comment"
            />
          </FormLayout>
        </Modal.Section>
      </Modal>
      <Modal open={popupActive} loading={true}></Modal>
      {toastMarkup}
    </Layout.Section>
  );
  function isBetween(n, a, b) {
    return (n - a) * (n - b) <= 0;
  }
  function findArrayElementByStatus(array) {
    return array.find((element, index) => {
      return element.ik_type == "redo_ik";
    });
  }
};

export default React.memo(Impressions);
