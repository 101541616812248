import React, { useCallback, useRef, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Dashboard from "./Dashboard";
import GuestDashboard from "./GuestDashboard";
import axios from "../axios";
import Cookie from "js-cookie";
import {
    Layout,
    Card,
    FormLayout,
    Badge,
    Checkbox,
    Toast,
    PageActions,
    Modal,
    TextContainer,
    Banner,
    Button,
    Spinner,
    Collapsible
  } from "@shopify/polaris";


function UserDashboard() {
  let { id } = useParams();
  const [isGuest, setIsGuest] = useState(false)
  const [loading, setIsLoading] = useState(true)
  const [pageTitle, setPageTitle] = useState({})

  const getIsGuest =async()=>{
    try {
        setIsLoading(true);
        const response = await axios.get(`/admin/v1/users/${id}/is_guest`, {
          headers: {
            Authorization: `Bearer ${Cookie.get('token')}`,
          },
        });
        if(response.data.is_guest){
            setIsGuest(response.data.is_guest)
            setPageTitle({id:response.data.user.id,
                fullname:response.data.user.first_name +
                " " +
                response.data.user.last_name,
                
                name : response.data.user.first_name +
                " " +
                response.data.user.last_name +
                "    " +
                ` (ID:  ${response.data.user.id})`,email: response.data.user.email});
        }
      } catch (error) {
        console.log('Error in get dispatches api', error);
      } finally {
        setIsLoading(false);
      }

  }
  

  useEffect(()=>{
    getIsGuest()
  },[])
  return (
    <div>
        {loading?
        <Modal open={true} loading={true}></Modal>
        : isGuest? 
        <GuestDashboard pageTitle={pageTitle}/>
        :
        <Dashboard/>
        }
      
    </div>
  )
}

export default UserDashboard
