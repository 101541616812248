import { forwardRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide,
} from '@mui/material';
import { calculateSteps, generateRandomId } from '../../util/helpers';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AlertSplitBatchDialog = ({
  open = false,
  setOpen,
  selectedIndex,
  setFormRows,
  setSplittingMode,
}) => {
  const handleSplitting = () => {
    setFormRows((prevRows) => {
      let newRows = [...prevRows];
      const chosenBatch = newRows[selectedIndex];
      const newUpperSteps = calculateSteps(
        chosenBatch.upperStepsTo,
        chosenBatch.upperStepsFrom
      );
      const newLowerSteps = calculateSteps(
        chosenBatch.lowerStepsTo,
        chosenBatch.lowerStepsFrom
      );
      if (!newUpperSteps || !newLowerSteps) {
        return newRows;
      }
      const newBatch = {
        number: '-',
        delivery: chosenBatch.delivery + 1,
        treatmentStage: chosenBatch.treatmentStage,
        subType: chosenBatch.subType,
        type: chosenBatch.type,
        parentId: null,
        batchId: generateRandomId(),
        expectedReceiptDate: '-',
        actualReceiptDate: '-',
        expectedStartOfBatch: '-',
        expectedStartOfLastStepInBatch: '-',
        expectedLastPaymentDueDate: '-',
        actualLastPaymentDueDate: '-',
        upperStepsFrom: newUpperSteps[1].from,
        upperStepsTo: newUpperSteps[1].to,
        lowerStepsFrom: newLowerSteps[1].from,
        lowerStepsTo: newLowerSteps[1].to,
        upperSteps: 0,
        lowerSteps: 0,
        upperPlasticOptions: chosenBatch.upperPlasticOptions,
        lowerPlasticOptions: chosenBatch.lowerPlasticOptions,
        cuttingTechnician: chosenBatch.cuttingTechnician,
        isAligner: chosenBatch.isAligner,
        targetDispatchDate: chosenBatch.targetDispatchDate,
        actualDispatchDate: '-',
        note: '',
        upperProductQuantity: 0,
        lowerProductQuantity: 0,
        error: false,
        isDisabled: false,
      };
      newRows.splice(selectedIndex, 1);
      chosenBatch.upperStepsTo = newUpperSteps[0].to;
      chosenBatch.upperStepsFrom = newUpperSteps[0].from;
      chosenBatch.lowerStepsTo = newLowerSteps[0].to;
      chosenBatch.lowerStepsFrom = newLowerSteps[0].from;
      newRows.splice(selectedIndex, 0, chosenBatch, newBatch);
      let counter = 1;
      newRows = newRows.map((row) => {
        if (row.parentId === chosenBatch.batchId) {
          return { ...row, parentId: null, delivery: counter++ };
        }
        if (row.parentId === null) {
          return { ...row, delivery: counter++ };
        }
        return row;
      });

      newRows = newRows.map((row) => {
        const parentIndex = newRows.findIndex(
          (value) => value.batchId === row.parentId
        );
        if (parentIndex !== -1) {
          return {
            ...row,
            color: newRows[parentIndex].color,
            delivery: newRows[parentIndex].delivery,
          };
        }
        return row;
      });
      return newRows;
    });
    setSplittingMode(true);
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ minWidth: '600px', fontSize: '16px' }}>
        {'Are you sure you want to split this batch?'}
      </DialogTitle>
      <DialogActions>
        <Button sx={{ fontSize: '12px' }} onClick={handleClose}>
          No
        </Button>
        <Button sx={{ fontSize: '12px' }} onClick={handleSplitting}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertSplitBatchDialog;
