import React from 'react'
import { InputLabel, Radio, RadioGroup, Typography } from "@material-ui/core";

const InformationRadioButtonGroup = ({
    title,
    radioGroupTypes,
    groupName,
    radioButtons,
    handleRadioButtonClick,
}) => {
    return (
        <div>
            <Typography className='treatment-approach-title'>{title}</Typography>
            <RadioGroup name={groupName} className='info-images-container'>
                {
                    radioButtons.map((radio, key) => {
                        return (
                            <InputLabel key={key} className='info-single-image-InputLabel'>
                                <Radio onClick={() => handleRadioButtonClick(groupName, radio.value)} value={radio.value} checked={radioGroupTypes[groupName] === radio.value} />
                                {radio.label}
                            </InputLabel>
                        )
                    })
                }
            </RadioGroup>
        </div>
    )
}

export default InformationRadioButtonGroup
