import { forwardRef, useRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextareaAutosize,
} from '@mui/material';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AlertDispatchMissingPendingPaymentDialog = ({
  open,
  setOpen,
  postDispatchData,
  note,
  setFormRows,
  formRows,
}) => {
  const noteWarningInput = useRef('');
  const handleClose = () => {
    setOpen(false);
  };

  const confirmUpdate = async () => {
    const updatedFormRows = [...formRows];
    if (updatedFormRows && updatedFormRows.length > 0) {
      updatedFormRows[updatedFormRows.length - 1].note = noteWarningInput.current.value;
      setFormRows(updatedFormRows);
    }
    handleClose();
    await postDispatchData(true);
  };

  return (
    <Dialog
      open={open ?? false}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ minWidth: '600px', fontSize: '16px' }}>
        Are sure you want to proceed with these changes?
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{ fontSize: '14px' }}
          id="alert-dialog-description"
        >
          With the current updates the user will receive all their batches
          without paying all payments.
        </DialogContentText>
        <TextareaAutosize
          ref={noteWarningInput}
          defaultValue={note}
          aria-label="minimum height"
          minRows={6}
          placeholder="Type something..."
          style={{ width: '100%' }}
        />
      </DialogContent>
      <DialogActions>
        <Button sx={{ fontSize: '12px' }} onClick={handleClose}>
          No
        </Button>
        <Button sx={{ fontSize: '12px' }} onClick={confirmUpdate}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDispatchMissingPendingPaymentDialog;
