import React, { useState, useEffect } from "react";
import {
  Page,
  AppProvider,
  Card,
} from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../../../../axios";
import { FormGroup } from "@material-ui/core";
import {useLocation, useParams} from "react-router-dom";
import UserHeader from "../../../../components/UserLayout/user-header";

export default function Waiver() {
  let { id } = useParams();
  let arrayQuestions = [
    "I have had a dental checkup in the past year.",
    "My dentist checked my x-rays.",
    "I have no shortened or resorbed roots or any impacted teeth.",
    "My dentist has probed or measured my gum pockets.",
    "I do not have periodontal or gum disease.",
  ];
  const [arrayOfAnswers, setArrayOfAnswers] = useState([
    "No",
    "No",
    "No",
    "No",
    "No",
  ]);
  const [elements, setElements] = useState([
    <div key="1">
      <Card sectioned>
        <div>
          <font size="3">
            <b>Question</b>
            <br />
            <p style={{ marginTop: 10 }}>Answer</p>
          </font>
        </div>
      </Card>
      <br />
    </div>,
  ]);

  useEffect(() => {
    let array = [];
    for (var i = 0; i < arrayQuestions.length; i++) {
      array.push(
        <div key={String(i)}>
          <div>
            <font size="3">
              <b>{arrayQuestions[i]}</b>
              <br />
              <p style={{ marginTop: 10 }}>{arrayOfAnswers[i]}</p>
            </font>
          </div>
          <br />
        </div>
      );
    }
    setElementsWaiver(array);

    getData();
  }, []);
  function getData() {
    axios
      .get("/admin/v1/users/" + id + "/waiver", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        let newArrayAnswers = [...arrayOfAnswers];
        for (var i = 0; i < Object.keys(result.data.data.answers).length; i++) {
          let newAnswer = "";

          if (
            String(
              result.data.data.answers[Object.keys(result.data.data.answers)[i]]
            ) === "0"
          ) {
            newAnswer = "No";
          } else {
            newAnswer = "Yes";
          }
          arrayOfAnswers[
            Object.keys(result.data.data.answers)[i].split("_")[1] - 1
          ] = newAnswer;
        }

        //setArrayOfAnswers(newArrayAnswers);

        setElementsWaiver([]);

        let array = [];
        for (var i = 0; i < arrayQuestions.length; i++) {
          array.push(
            <div key={String(i)}>
              <div>
                <font size="3">
                  <b>{arrayQuestions[i]}</b>
                  <br />
                  <p style={{ marginTop: 10 }}>{arrayOfAnswers[i]}</p>
                </font>
              </div>
              <br />
            </div>
          );
        }
        setElementsWaiver(array);
      })
      .catch((err) => console.log("errrrr=", err));
  }
  const [elementsWaiver, setElementsWaiver] = useState([]);

  const location = useLocation();
  let linkState =
    {planType: "", clinicStatus: "", clinicName: "", status: "", userId: id, pageTitle: ""};
  if (location.state !== undefined) {
    const {planType, clinicStatus, clinicName, status, userId, pageTitle} = location.state;
    linkState = {planType, clinicStatus, clinicName, status, userId, pageTitle}
  }
  const { planType, clinicStatus, clinicName, status, userId, pageTitle} = linkState;
  
  return (
    <AppProvider>
      <Page>
        <UserHeader pageTitle={pageTitle} status={status} userId={userId} clinicStatus={clinicStatus} clinicName={clinicName} planType={planType}/>
        <br/>
        <h1 className="page-header">Waiver</h1>
        <Card sectioned>
          <FormGroup>{elementsWaiver}</FormGroup>
        </Card>
      </Page>
    </AppProvider>
  );
}
