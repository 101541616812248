import { Dialog, DialogTitle, DialogActions, Button } from '@mui/material';

import { useStyles } from './dialog.styles';

const DeleteSmilePlanCategoryDialog = ({ open, setOpen, deleteSmilePlanCategory, id }) => {
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };

  const handleCaseTypeDelete = () => {
    void deleteSmilePlanCategory(id);
    handleClose();
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>
        Are you sure you want to delete this Smile Plan Category?
      </DialogTitle>
      <DialogActions className={classes.button}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleCaseTypeDelete}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteSmilePlanCategoryDialog;
