import { Button, DataTable, TextField, TextStyle } from '@shopify/polaris'
import axios from "../axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from 'react'

function CustomPaymentTab({plan,email,setUpdated,payments,setIsOpen,total,setPayments,setNextPayment}) {
  const SaveCustom=async()=>{
    setLoading(true)
    let data={plan_id:plan,
      email:email,
      payments:payments,
    }
   let res=await axios.post('admin/v1/manual/adjust_payments',data ,{
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
        },}).then((response)=>{
          setIsOpen(false)
          setLoading(false)
          setUpdated(true)
          setNextPayment(response.data.next)
        });    
    
  }
  const [items,setItems]=useState([])
  const [loading,setLoading]=useState(false)
  const [allowed,setAllowed]=useState(false)
  useEffect(()=>{
    let newprice =payments.map(item => parseInt(item.price)).reduce((prev, next) => prev + next);
    setItems([...
      payments?.map((item,index) => [
        item.id,item.currency,
        <TextField variation="strong" type='number' value={item.price+""} disabled={item.paid||loading} onChange={(e)=>{
          setPayments((prev)=>{
            let arr=[...prev];
            arr[index].price=e
            return arr;
          })
        }}></TextField>,]),[...[<h2>Plan Total {total}</h2>,<h2>Current Total <span style={{color:newprice==total?"blue":"red"}}>{newprice}</span></h2>,<h2>Difference <span style={{color:newprice!=total?"red":"blue"}}>{newprice-total}</span></h2>] ]])
        
      setAllowed(newprice==total)
  },[payments])
  return (
    <div>
       <DataTable
            columnContentTypes={["text","text","text","text","text","text","text","text",'text']}
            headings={[
              <TextStyle variation="strong">SubPlan ID</TextStyle>,
              <TextStyle variation="strong">Currency</TextStyle>,
              <TextStyle variation="strong">Price</TextStyle>,
            ]}
            rows={items}
            sortable={[false,false,false]}
            defaultSortDirection="descending"
            verticalAlign="center"
          />        
  <Button onClick={()=>{if(allowed){SaveCustom()}}}
  destructive={!allowed} primary={allowed}
   >Save</Button>
  </div>
  )
}

export default CustomPaymentTab