import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import CarePlus from "./care-plus";
import FaqContent from "./faq-content";
import history from "./history";
import InstagramWidget from "./instagram-widget";
import NewContentEdit from "./new-content-edit";

function FaqMain() {
  return (
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/new-content/:id/faq" component={NewContentEdit} />
          <Route exact path="/admin/instagram-widget" component={InstagramWidget} />
          <Route exact path="/admin/faq-content" component={FaqContent} />
        </Switch>
      </Router>
  );
}
export default FaqMain;
