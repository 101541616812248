import React from 'react'
import { convertDurationToDate, getChatUser } from '../../util/helpers';
import moment from 'moment';
import { CSSTransition } from 'react-transition-group';
import { useStyles } from './style';
import DownloadIcon from '@mui/icons-material/Download';
import AttachmentIcon from '@mui/icons-material/Attachment';
import ReplyIcon from '@mui/icons-material/Reply';

function CaseDiscussionMessageV2({hoverId, setHoverId,  handleScrollToOriginal, replyClicked, attachmentName, message, adminId, admins, setPreviewImages, type}) {
    let userChat = getChatUser(message, adminId, admins)
    let messageDate = moment(convertDurationToDate(message.created_at)).format('DD-MM-yy hh:mm A')
    let textIndex, videoIndex, fileIndex;
    textIndex = message.data.findIndex(arr => arr.type == 'text');
    videoIndex = message.data.findIndex(arr => arr.type == 'video');
    fileIndex = message.data.findIndex(arr => arr.type == 'file');
    let photoArray = message.data.filter(arr => arr.type == 'photo');
    let userStyles = { parentDiv: {}, message: {}, replyDiv: {}, replySvg: {} };
    if (userChat.position == 'left') {
        userStyles.parentDiv.justifyContent = "left";
        userStyles.message.marginLeft = "20px";
        userStyles.message.marginRight = "unset";
        userStyles.replyDiv.right = "-25%";
        userStyles.replyDiv.left = "unset";
        userStyles.replySvg.transform = "initial";
    } else {
        userStyles.parentDiv.justifyContent = "right";
        userStyles.message.marginLeft = "unset";
        userStyles.message.marginRight = "20px";
        userStyles.replyDiv.right = "unset";
        userStyles.replyDiv.left = "-25%";
        userStyles.replySvg.transform = "scaleX(-1)";
    }

    return (
        <div id={message.id} key={message.id} style={{ display: "flex", ...userStyles.parentDiv, backgroundColor:"initial", transition: "background-color 0.5s ease"  }}  onMouseOver={() => { setHoverId(message.id) }}>
            <div style={{ width: "fit-content", minWidth: "20%", maxWidth:type=='dashboard'?"50%":"75%", backgroundColor: "white", padding: "5px", boxShadow: "1px 1px 1px 1px #0003", borderRadius: "5px", borderTopLeftRadius: "0px", position: "relative", marginBottom: "2%", ...userStyles.message }} >
                <CSSTransition in={hoverId == message.id} timeout={300} classNames="fade" unmountOnExit>
                    <div style={{ position: "absolute", top: "25%", cursor: "pointer", ...userStyles.replyDiv }} onClick={() => { replyClicked(message, userChat, textIndex, videoIndex, fileIndex) }}>
                        <ReplyIcon fontSize='large' style={{ backgroundColor: "white", borderRadius: "50%", ...userStyles.replySvg }} />
                    </div>
                </CSSTransition>
                <div className='case-title' style={{ alignItems: "center", color: "#4f81a1", cursor: "pointer", display: "flex", fontSize: "13px", fontWeight: 500, margin: " 0 0 8px" }}>
                    {userChat.title}
                </div>
                <div className='body'>
                    {message.reply ? <div className='reply' style={{ borderLeft: "#8ed2dd 5px solid", backgroundColor: "#ececec", padding: "5px 0px 5px 5px", borderRadius: "5px", cursor: "pointer" }} onClick={() => {
                        if (message.reply && message.reply.id) {
                            handleScrollToOriginal(message.reply.id)
                        }
                    }}>
                        <div className='reply-title' style={{ color: "#8ed2dd" }}>
                            {message.reply.admin_id === adminId ? "Me" : admins.get(message.reply.admin_id)}
                        </div>
                        <div className='reply-text' style={{color: "rgb(90, 90, 90)",overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "90%"}}  dangerouslySetInnerHTML={{ __html: message.reply.type == "text" ?  message.reply.data.replace(/@(\w+(\.\w+)?)/g, '<span style="color: blue;">$&</span>').replace(/<br>/g," ")  : message.reply.type == "file" ? "Attachment" : message.reply.type}}>
                        </div>
                    </div> : null}
                    {photoArray.length ? <div className='media' style={{ display: "flex", flexWrap: "wrap", width: "250px", gap: "5px", alignItems: "center", overflow: "hidden", cursor: "pointer", minHeight: "100px", margin: "5px 0px" }}>
                        {
                            photoArray.slice(0, 4).map((im, startIndex) =>
                                <div style={{ width: '45%', flexGrow: "1", maxWidth: photoArray.length == 1 ? "unset" : "50%", position: "relative", height: photoArray.length == 1 ? "unset" : "100px", overflow: "hidden", borderRadius: "5px" }} onClick={() => { setPreviewImages({ images: [...photoArray], startIndex }) }}>
                                    <img src={process.env.REACT_APP_AWS_URL + im.data} style={{ height: "100%", width: "100%", objectFit: "cover" }} />
                                    {startIndex == 3 && photoArray.length > 4 && <div style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, background: "rgb(63 63 68 / 39%)", justifyContent: "center", display: "flex", alignItems: "center" }}>
                                        <span style={{ fontSize: "x-large", color: "white", textShadow: "-1px -1px 1px black, 1px 1px 1px black, -1px 1px 1px black, 1px -1px 1px black" }}>+{photoArray.length - 4}</span>
                                    </div>}
                                </div>
                            )}
                    </div> : null}
                    {videoIndex >= 0 ? <div style={{margin:"5px 0px"}}>
                        <video
                            controls
                            className="video"
                            style={{ width: '100%', height: '100%' }}
                            src={process.env.REACT_APP_AWS_URL + message.data[videoIndex].data}
                        ></video>
                    </div> : null}

                    {fileIndex >= 0 ? <div style={{ backgroundColor: "#9a9999a1", borderRadius: "5px", padding: "5px", display: "flex", alignItems: "center", cursor: "pointer",margin:"5px 0px" }} onClick={() => { window.open(process.env.REACT_APP_AWS_URL + message.data[fileIndex].data) }}>
                        <AttachmentIcon fontSize='large' /> {attachmentName(message.data[fileIndex].data)}  <DownloadIcon fontSize='large' />
                    </div> : null}

                    {textIndex >= 0 ? <div className='chat-text' style={{ fontSize: "13.6px" }}>
                        {<p dangerouslySetInnerHTML={{ __html: message.data[textIndex].data.replace(/@(\w+(\.\w+)?)/g, '<span style="color: blue;">$&</span>') }}></p>}
                    </div> : null}
                    <div className='time' style={{ fontSize: "12px", color: "#00000073", textAlign: "end" }}>
                        {messageDate}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CaseDiscussionMessageV2