import { useStyles } from './dialog.styles';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

const DeleteSmilePlanTemplateDialog = ({
  open,
  setOpen,
  deleteSingleSmilePlanTemplate,
  id,
  index,
  handleRemoveRow,
}) => {
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };

  const handleCaseTypeDelete = () => {
    if (id !== '#') {
      void deleteSingleSmilePlanTemplate(id);
    }
    handleRemoveRow(index);
    handleClose();
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>
        Are you sure you want to delete this Smile Plan Template?
      </DialogTitle>
      <DialogActions className={classes.button}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleCaseTypeDelete}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteSmilePlanTemplateDialog;
