import { useEffect } from "react";

const useZoomDragAndDrop = ({element}) =>{
    let newPosX = 0, newPosY = 0, startPosX = 0, startPosY = 0;
    let zoom = 1;
    const ZOOM_SPEED = 0.1;
  
    const handleZoom = (event) =>{
      if(element.length > 0){
        element[0].addEventListener('mousedown', handleMoveDown);
        if(event.deltaY < 0){
          element[0].style.position = 'absolute';
          element[0].style.cursor = 'grab';
          element[0].style.transform = `scale(${zoom += ZOOM_SPEED})`;  
          element[0].style.transition = ` transform 0.2s`;  
        }else{
          element[0].style.transform = `scale(${zoom -= ZOOM_SPEED})`;
          element[0].style.position = `unset`;
          element[0].style.top = `unset`;
          element[0].style.left = `unset`;
          element[0].style.cursor = 'unset';
          element[0].style.transition = ` transform 0.2s, top 0.2s, left 0.2s`;
        }
      }

      if(zoom <= 1){
        zoom = 1;
      }
    }
  
    const handleMoveDown = (e) => {
      e.preventDefault();
      
      // get the starting position of the cursor
      startPosX = e.clientX;
      startPosY = e.clientY;
      
      document.addEventListener('mousemove', mouseMove);
      
      document.addEventListener('mouseup', function(){
          document.removeEventListener('mousemove', mouseMove);
      });
    }
  
    const mouseMove = (e) => {
      // calculate the new position 
      newPosX = startPosX - e.clientX;
      newPosY = startPosY - e.clientY;
  
      // with each move we also want to update the start X and Y
      startPosX = e.clientX;
      startPosY = e.clientY;
  
      // set the element's new position:
      element[0].style.top = (element[0].offsetTop - newPosY) + "px";
      element[0].style.left = (element[0].offsetLeft - newPosX) + "px";
    }
  
    useEffect(() => {
      document.addEventListener('wheel', handleZoom);
      return () => { 
        document.removeEventListener('wheel', handleZoom);
        if(element.length > 0){
          element[0].removeEventListener('mousedown', handleMoveDown);
          element[0].removeEventListener('mouseup', mouseMove);
        }
      }
    }, [])
}

export default useZoomDragAndDrop;