import React from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
// import '../../assets/sass/custom-loader.scss';


const ThreeDViewerLoader = ({ progress }) => {
    return (
        <div className="threeD-loader">
            <h6 className="threeD-msg">
                Your new smile's waiting for you. Watch it change, from start to finish.
            </h6>

            <div style={{ width: '200px' }}>
                <CircularProgressbar
                    value={progress}
                    text={`${progress}%`}
                    styles={buildStyles({
                        pathTransitionDuration: 0.15
                    })}
                />
            </div>

        </div>

    )
}

export default ThreeDViewerLoader;