import axios from "../../axios";
import { useEffect, useRef, useState } from "react"
import Cookies from "js-cookie";
import Loading from "../../components/Loading/loading";
import history from "../../history";
const BookingSetup = () => {
const [bookingResponse, setBookingResponse] = useState({})
const buttonRef = useRef(null);
let utm = {};
if (Cookies.get("utm_data")) {
  utm = JSON.parse(Cookies.get("utm_data"));
}
useEffect(() => {
    const booking_resp = JSON.parse(Cookies.get('booking_response'))
    setBookingResponse(booking_resp)
    if(bookingResponse) {
        setTimeout(()=> {
            buttonRef.current.click();
        },3000)
    }
    },[])

    const handleSetup = () => {
       if(bookingResponse && bookingResponse !== {}){
           const bookingData = {
                email:bookingResponse.email,
                phone:bookingResponse.phone
           }
        axios.post('admin/v1/users/register-booking', bookingData, {
            headers: {
                Authorization: "Bearer " + Cookies.get("token"),
              },
            }).then((result) => {
            history.push('/admin/users');
        }).catch((err) => {
            console.log(err)
        })
       }
    }
    return (
        <div style={{margin:"8% auto"}}>
            <Loading />
            <button style={{display:"none"}} ref={buttonRef} onClick={handleSetup}></button>
        </div>
    )
  };  
export default BookingSetup