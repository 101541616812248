import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Button,
  Page,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Card,
  TextStyle,
  ButtonGroup,
  Link,
  Spinner,
  Modal,
} from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../../axios";
import history from "../../history";
import "@zendeskgarden/react-pagination/dist/styles.css";
import { Pagination } from "@zendeskgarden/react-pagination";
import emptyIcon from "../../img/emptyList.svg";
import { useParams } from "react-router-dom";

const Doctors = () => {
  const { id } = useParams();
  const perPage = 15;
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [sortState, setSortState] = useState("-id");
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [page, setPage] = useState(1);
  const [popupActive, setPopupActive] = useState(false);
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const handleDelete = (e) => {
    setPopupActive(true);
    const bodyObj = {
      patient_id: e.currentTarget.id,
      attach: false,
    };
    axios
      .post(`/admin/v1/management/doctors/${id}/patients`, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        setPopupActive(false);
        getData();
        //  history.push('/admin/faqsupport/viewCategory')
      })
      .catch((err) => setPopupActive(false));
  };
  const getData = () => {
    axios
      .get(`/admin/v1/management/doctors/${id}/patients`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setIsLoading(false);
        !result.data.data.length ? setIsListEmpty(true) : setIsListEmpty(false);
        setTotalPages(Math.ceil(result.data.total / perPage));
        setItems(
          result.data.data.map((item) => [
            <Link
              monochrome
              onClick={() =>
                window.open("/admin/users/" + item.id + "/dashboard")
              }
            >
              {item.id}
            </Link>,
            <Link
              monochrome
              onClick={() =>
                window.open("/admin/users/" + item.id + "/dashboard")
              }
            >
              {item.first_name}
            </Link>,
            <Link
              monochrome
              onClick={() =>
                window.open("/admin/users/" + item.id + "/dashboard")
              }
            >
              {item.last_name}
            </Link>,
            item.email,
            <ButtonGroup>
              <Button
                destructive
                id={String(item.id)}
                onClick={(e) => handleDelete(e)}
              >
                Delete
              </Button>
            </ButtonGroup>,
          ])
        );
      })
      .catch((err) => setIsLoading(false));
  };
  useEffect(() => {
    getData();
  }, [page, sortState, availability, queryValue]);

  function handleFiltersQueryChange(queryValue) {
    setQueryValue(queryValue);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "ID", value: "id" },
            { label: "Title", value: "name" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div className="message-no-result"
    >
      <div className="message-no-result-img">
        <Image src={emptyIcon}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );
  return (
    <Page
      fullWidth
      title="Patients"
      primaryAction={{
        content: "Add Patient",
        url: `/admin/doctors/${id}/patients/add`,
      }}
    >
      <Card>
        {loadingMarkup}
        {/* <Card.Section>
          <Filters
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
        </Card.Section> */}
        <DataTable
          columnContentTypes={["text", "text", "text"]}
          headings={[
            <TextStyle variation="strong">ID</TextStyle>,
            <TextStyle variation="strong">First Name</TextStyle>,
            <TextStyle variation="strong">Last Name</TextStyle>,
            <TextStyle variation="strong">Email</TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
          ]}
          rows={items}
          sortable={[false, false, false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={0}
          verticalAlign="middle"
        />

        {emtyState}
        {totalPages >= 2 && (
          <div
            style={{
              paddingTop: "10px",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              size="large"
            />
          </div>
        )}
      </Card>
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;
      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
  function handleAssignPatients(e) {
    history.push("/admin/doctors/" + e.currentTarget.id + "/patients");
  }
  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element.id) === parseInt(id);
    });
  }
};

export default Doctors;
