import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Page,
  DisplayText,
  AppProvider,
  FormLayout,
  Card,
  ActionList,
  Popover,
  Modal,
  Layout,
} from "@shopify/polaris";
import "react-dynamic-charts/dist/index.css"; // Don't forget to import the styles
import axios from "./axios";
import Cookies from "universal-cookie";
import Chart from "react-apexcharts";
import countryList from "react-select-country-list";
import NavigationLayout from "./components/NavigationLayout";

export default function Home() {
  const cookies = new Cookies();
  const [totalSalesValue, setTotalSalesValue] = useState("");
  const [npsScore, setNpsScore] = useState({});
  const [todaySalesValue, setTodaySalesValue] = useState("");
  const [todayOrdersValue, setTodayOrdersValue] = useState("");
  const [todayLeadsValue, setTodayLeadsValue] = useState("");
  const [popupActive, setPopupActive] = useState(false);
  const [popoverCountriesActive, setPopoverCountriesActive] = useState(false);
  const [buttonTextPopupCountries, setButtonTextPopupCountries] =
    useState("All");
  const [buttonTextPopupTime, setButtonTextPopupTime] = useState("This month");
  const togglePopoverCountriesActive = useCallback(
    () =>
      setPopoverCountriesActive(
        (popoverCountriesActive) => !popoverCountriesActive
      ),
    []
  );
  const activatorCountries = (
    <Button onClick={togglePopoverCountriesActive} disclosure>
      {buttonTextPopupCountries}
    </Button>
  );

  function handleCountriesAction(countryName) {
    setCountryFilter(countryName === "All" ? "" : countryName);
    setButtonTextPopupCountries(countryName);
    setPopoverCountriesActive(false);
  }
  function handleMonthsAction(monthName, monthIndex) {
    console.log("monthIndex==", monthIndex);
    setMonthFilter(monthIndex);
    setButtonTextPopupTime(monthName);
    setPopoverTimeActive(false);
  }
  function handleYearsAction(yearValue) {
    console.log("year=", yearValue);
    setYearFilter(yearValue);
    setButtonTextPopupYear(yearValue);
    setPopoverYearActive(false);
  }
  const [popoverTimeActive, setPopoverTimeActive] = useState(false);
  const togglePopoverTimeActive = useCallback(
    () => setPopoverTimeActive((popoverTimeActive) => !popoverTimeActive),
    []
  );
  const activatorTime = (
    <Button onClick={togglePopoverTimeActive} disclosure>
      {buttonTextPopupTime}
    </Button>
  );

  const [buttonTextPopupYear, setButtonTextPopupYear] = useState(
    new Date().getFullYear().toString()
  );
  const [popoverYearActive, setPopoverYearActive] = useState(false);
  const togglePopoverYearActive = useCallback(
    () => setPopoverYearActive((popoverYearActive) => !popoverYearActive),
    []
  );
  const activatorYear = (
    <Button onClick={togglePopoverYearActive} disclosure>
      {buttonTextPopupYear}
    </Button>
  );

  function getTotalsValues() {
    axios
      .get("admin/v1/dashboard", {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
        },
      })

      .then((result) => {
        setTotalSalesValue(
          "$ " + numberWithCommas(result.data.daily_order.total_sales)
        );
        setTodaySalesValue(
          "$ " + numberWithCommas(result.data.daily_order.today_sales)
        );
        setTodayOrdersValue(result.data.daily_order.today_orders);
        setTodayLeadsValue(result.data.daily_order.today_leads);
      })
      .catch((err) => console.log(err));
  }
  function fillArray(value, len) {
    if (len == 0) return [];
    var a = [value];
    while (a.length * 2 <= len) a = a.concat(a);
    if (a.length < len) a = a.concat(a.slice(0, len - a.length));
    return a;
  }
  let resultSaved = [];
  function countInArray(array, what) {
    var count = 0;
    for (var i = 0; i < array.length; i++) {
      if (array[i] === what) {
        count++;
      }
    }
    return count;
  }
  function getGraphsData() {
    setPopupActive(true);
    axios
      .get(
        `admin/v1/dashboard?month=${monthFilter}&country=${countryFilter}&year=${yearFilter}`,
        {
          headers: {
            Authorization: "Bearer " + cookies.get("token"),
          },
        }
      )

      .then((result) => {
        let array1 = [];
        let array2 = [];
        let array3 = [];
        let array4 = [];
        let array_products = [];
        let array_aligners = [];

        for (var i = 0; i < daysInMonth(monthFilter, yearFilter); i++) {
          array1.push(
            findArrayElementByDay(result.data.dashboard.sales, i + 1)
              ? parseInt(
                  findArrayElementByDay(result.data.dashboard.sales, i + 1)
                    .value
                )
              : 0
          );

          if (
            findArrayElementByDay(result.data.dashboard.orders, i + 1) &&
            findArrayElementByDay(result.data.dashboard.orders, i + 1)
              .description === "product"
          ) {
          }

          if (
            findArrayElementByDay(result.data.dashboard.orders, i + 1) &&
            countInArray(
              result.data.dashboard.orders,
              findArrayElementByDay(result.data.dashboard.orders, i + 1)
            ) === 2
          ) {
            i--;
          }
          if (
            findArrayElementByDay(result.data.dashboard.orders, i + 1) &&
            findArrayElementByDay(result.data.dashboard.orders, i + 1)
              .description === "aligner kit"
          ) {
          }

          const result2 = result.data.dashboard.orders.filter(
            (item) =>
              item.date ===
              new Date().getFullYear() +
                "-" +
                monthFilter +
                "-" +
                ("0" + (i + 1)).slice(-2)
          );

          if (result2.length === 2) {
            array_products.push(
              findArrayElementByDescriptionProduct(result2, "product").order
            );
            array_aligners.push(
              findArrayElementByDescriptionAlignerKit(result2, "aligner kit")
                .order
            );
          } else {
            array_products.push(
              findArrayElementByDay(result.data.dashboard.orders, i + 1) &&
                findArrayElementByDay(result.data.dashboard.orders, i + 1)
                  .description === "product"
                ? parseInt(
                    findArrayElementByDay(result.data.dashboard.orders, i + 1)
                      .order
                  )
                : 0
            );
            array_aligners.push(
              findArrayElementByDay(result.data.dashboard.orders, i + 1) &&
                findArrayElementByDay(result.data.dashboard.orders, i + 1)
                  .description === "aligner kit"
                ? parseInt(
                    findArrayElementByDay(result.data.dashboard.orders, i + 1)
                      .order
                  )
                : 0
            );
          }

          array3.push(
            findArrayElementByDay(result.data.dashboard.leads, i + 1)
              ? parseInt(
                  findArrayElementByDay(result.data.dashboard.leads, i + 1)
                    .value
                )
              : 0
          );
          array4.push(
            findArrayElementByDay(result.data.dashboard.visitors, i + 1)
              ? parseInt(
                  findArrayElementByDay(result.data.dashboard.visitors, i + 1)
                    .value
                )
              : 0
          );
        }

        let categArray = [];
        for (var i = 1; i <= daysInMonth(monthFilter, yearFilter); i++) {
          categArray.push(i);
        }
        setdataGraph({
          categories: categArray,
          // categories: new Array(daysInMonth(monthFilter, 2020)),
          g1: array1,

          g3: array3,
          g4: array4,
          g_products: array_products,
          g_aligners: array_aligners,
          titleG1: numberWithCommas(result.data.dashboard.total_sales),
          titleG2: result.data.dashboard.total_orders,
          titleG3: result.data.dashboard.total_leads,
          titleG4: result.data.dashboard.total_visitors,
        });
        //setdataGraph2(array2);
        //setdataGraph3(array3);
        //setdataGraph4(array4);

        setPopupActive(false);
      })
      .catch((err) => console.log(err));
  }

  function getCurrentMonth(date) {
    var date = new Date(),
      month = date.getMonth();
    return month + 1 < 10 ? "0" + month : month;
  }
  function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }
  function findArrayElementByDay(array, day) {
    return array.find((element) => {
      return element.date.split(" ")[0].split("-")[2] === ("0" + day).slice(-2);
    });
  }

  function findArrayElementByDescriptionProduct(array, description) {
    return array.find((element) => {
      return element.description === "product";
    });
  }
  function findArrayElementByDescriptionAlignerKit(array, description) {
    return array.find((element) => {
      return element.description === "aligner kit";
    });
  }

  const [dataGraph, setdataGraph] = useState({
    categories: [],
    g1: [],
    g2: [],
    g3: [],
    g4: [],
    g_aligners: [],
    g_products: [],
    titleG1: "",
    titleG2: "",
    titleG3: "",
    titleG4: "",
  });
  let options2 = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "numeric",
      // tickAmount: 10,
      categories: dataGraph.categories,
      labels: {
        show: true,
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
    legend: {
      position: "right",
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
  };

  let options = {
    chart: {
      animations: {
        enabled: true,
        easing: "linear",
        speed: 800,
        animateGradually: {
          enabled: false,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },

        toolbar: {
          show: false,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
          autoSelected: "zoom",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "numeric",
      // tickAmount: 10,
      categories: dataGraph.categories,
      labels: {
        show: true,
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
    colors: ["#9c6ade"],
  };

  let seriesGraph1 = [
    {
      name: "",
      data: dataGraph.g1,
    },
  ];

  let seriesGraph2 = [
    {
      name: "Impression Kit",
      data: dataGraph.g_products,
    },
    {
      name: "Aligner Kit",
      data: dataGraph.g_aligners,
    },
  ];

  let seriesGraph3 = [
    {
      name: "",
      data: dataGraph.g3,
    },
  ];

  let seriesGraph4 = [
    {
      name: "",
      data: dataGraph.g4,
    },
  ];
  let itemsCountries = [
    {
      content: "All",
      onAction: () => handleCountriesAction("All"),
    },
    {
      content: "Lebanon",
      onAction: () => handleCountriesAction("Lebanon"),
    },
    {
      content: "Jordan",
      onAction: () => handleCountriesAction("Jordan"),
    },
    {
      content: "Egypt",
      onAction: () => handleCountriesAction("Egypt"),
    },
    {
      content: "United Arab Emirates",
      onAction: () => handleCountriesAction("United Arab Emirates"),
    },
    {
      content: "Saudi Arabia",
      onAction: () => handleCountriesAction("Saudi Arabia"),
    },
    {
      content: "Kuwait",
      onAction: () => handleCountriesAction("Kuwait"),
    },

    {
      content: "Qatar",
      onAction: () => handleCountriesAction("Qatar"),
    },
  ];
  
  let itemsMonths = [
    {
      content: "January",
      onAction: () => handleMonthsAction("January", "01"),
    },
    {
      content: "February",
      onAction: () => handleMonthsAction("February", "02"),
    },
    {
      content: "March",
      onAction: () => handleMonthsAction("March", "03"),
    },
    {
      content: "April",
      onAction: () => handleMonthsAction("April", "04"),
    },
    {
      content: "May",
      onAction: () => handleMonthsAction("May", "05"),
    },
    {
      content: "June",
      onAction: () => handleMonthsAction("June", "06"),
    },
    {
      content: "July",
      onAction: () => handleMonthsAction("July", "07"),
    },
    {
      content: "August",
      onAction: () => handleMonthsAction("August", "08"),
    },
    {
      content: "September",
      onAction: () => handleMonthsAction("September", "09"),
    },
    {
      content: "October",
      onAction: () => handleMonthsAction("October", "10"),
    },
    {
      content: "November",
      onAction: () => handleMonthsAction("November", "11"),
    },
    {
      content: "December",
      onAction: () => handleMonthsAction("December", "12"),
    },
  ];
  const [itemsYears, setItemsYears] = useState([]);
  const [monthFilter, setMonthFilter] = useState(
    ("0" + (parseInt(getCurrentMonth()) + 1)).slice(-2)
  );
  console.log(
    "test=",
    (parseInt(getCurrentMonth()) + 1).toString().length === 2
      ? ("0" + (parseInt(getCurrentMonth()) + 1)).slice(-2)
      : (
          "0" +
          (parseInt(getCurrentMonth().replace(/(^|-)0+/g, "$1")) + 1)
        ).slice(-2)
  );
  const [countryFilter, setCountryFilter] = useState("");
  const [yearFilter, setYearFilter] = useState(new Date().getFullYear());

  useEffect(() => {
    getNPSScore();
    getYearsList();
    let arrayCountries = [];
    for (var i = 0; i < countryList().getData().length; i++) {
      let object = {};
      let countryName = countryList().getData()[i].label;
      if (countryName !== "Israel") {
        object = {
          content: countryList().getData()[i].label,
          onAction: () => handleCountriesAction(countryName),
        };
        arrayCountries.push(object);
      }
    }
  }, []);
  useEffect(() => {
    getTotalsValues();
    getGraphsData();
  }, [monthFilter, countryFilter, yearFilter]);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  async function getNPSScore() {
    let res = await axios.get("admin/v1/get-nps", {
      headers: {
        Authorization: "Bearer " + cookies.get("token"),
      },
    })
    setNpsScore(res.data.data)
  }
  function getYearsList() {
    const currentYear = new Date().getFullYear();
    var list = [];
    for (var i = 2020; i <= currentYear; i++) {
      console.log("i.toString()=", i.toString());
      const strYear = i.toString();
      const obj = {
        content: strYear,
        onAction: () => handleYearsAction(strYear),
      };
      list.push(obj);
    }
    setItemsYears(list);
    console.log("list=", list);
    console.log("listCountries=", itemsCountries);
  }
  return (
    <NavigationLayout>
      <Page fullWidth>
        <FormLayout>
        <Layout>
          {Object.entries(npsScore).map(([type,val])=>{
            return(
              <Layout.Section oneThird>
              <Card title={type}>
                <Card.Section>
                  <DisplayText size="large">Total NPS score: {val.tota_nps_score}</DisplayText>
                  <DisplayText size="large">Total responses: {val.number_of_responses}</DisplayText>
                </Card.Section>
              </Card>
            </Layout.Section>
            )
          })}
          </Layout>
          <Layout>
            <Layout.Section oneThird>
              <Card title="Total Sales">
                <Card.Section>
                  <DisplayText size="large">{totalSalesValue}</DisplayText>
                </Card.Section>
              </Card>
            </Layout.Section>
            <Layout.Section oneThird>
              <Card title="Today's Sales">
                <Card.Section>
                  <DisplayText size="large">{todaySalesValue}</DisplayText>
                </Card.Section>
              </Card>
            </Layout.Section>
            <Layout.Section oneThird>
              <Card title="Today's Orders">
                <Card.Section>
                  <DisplayText size="large">{todayOrdersValue}</DisplayText>
                </Card.Section>
              </Card>
            </Layout.Section>
            <Layout.Section oneThird>
              <Card title="Today's Leads">
                <Card.Section>
                  <DisplayText size="large">{todayLeadsValue}</DisplayText>
                </Card.Section>
              </Card>
            </Layout.Section>
          </Layout>

          <Card sectioned>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Popover
                // active={popoverCountriesActive}
                activator={activatorCountries}
                onClose={togglePopoverCountriesActive}
              >
                <ActionList items={itemsCountries} />
              </Popover>

              <div style={{ marginLeft: 20 }}>
                <Popover
                  active={popoverTimeActive}
                  activator={activatorTime}
                  onClose={togglePopoverTimeActive}
                >
                  <ActionList items={itemsMonths} />
                </Popover>
              </div>
              <div style={{ marginLeft: 20 }}>
                <Popover
                  active={popoverYearActive}
                  activator={activatorYear}
                  onClose={togglePopoverYearActive}
                >
                  <ActionList items={itemsYears} />
                </Popover>
              </div>
            </div>

            <FormLayout>
              <Layout>
                <Layout.Section oneHalf>
                  <Card
                    title="TOTAL SALES"
                    actions={[{ content: "View Report" }]}
                  >
                    <Card.Section>
                      <DisplayText size="large">
                        $ {dataGraph.titleG1}
                      </DisplayText>
                    </Card.Section>

                    <Card.Section>
                      {/* <div>
                        <BarChart
                          width={500}
                          height={300}
                          data={totalSalesGraphData}
                          margin={margin}
                          ylabel="Quantity"
                        >
                          <XAxis dataKey="text" />
                          <YAxis dataKey="value" />
                          <Bar dataKey="value" fill="#CE93D8" />
                        </BarChart>
                      </div> */}
                      <Chart
                        options={options}
                        series={seriesGraph1}
                        type="bar"
                        width={550}
                        height={120}
                      />
                    </Card.Section>
                  </Card>
                </Layout.Section>
                <Layout.Section oneHalf>
                  <Card
                    title="TOTAL ORDERS"
                    actions={[{ content: "View Report" }]}
                  >
                    <Card.Section>
                      <DisplayText size="large">
                        {dataGraph.titleG2}
                      </DisplayText>
                    </Card.Section>
                    <Card.Section>
                      <div>
                        <Chart
                          options={options2}
                          series={seriesGraph2}
                          type="bar"
                          width={550}
                          height={120}
                        />
                      </div>
                    </Card.Section>
                  </Card>
                </Layout.Section>
              </Layout>
              <Layout>
                <Layout.Section oneHalf>
                  <Card
                    title="TOTAL LEADS"
                    actions={[{ content: "View Report" }]}
                  >
                    <Card.Section>
                      <DisplayText size="large">
                        {dataGraph.titleG3}
                      </DisplayText>
                    </Card.Section>

                    <Card.Section>
                      <div>
                        <Chart
                          options={options}
                          series={seriesGraph3}
                          type="bar"
                          width={550}
                          height={120}
                        />
                      </div>
                    </Card.Section>
                  </Card>
                </Layout.Section>
                <Layout.Section oneHalf>
                  <Card
                    title="WEBSITE VISITORS"
                    actions={[{ content: "View Report" }]}
                  >
                    <Card.Section>
                      <DisplayText size="large">
                        {dataGraph.titleG4}
                      </DisplayText>
                    </Card.Section>
                    <Card.Section>
                      <div>
                        <Chart
                          options={options}
                          series={seriesGraph4}
                          type="bar"
                          width={550}
                          height={120}
                        />
                      </div>
                    </Card.Section>
                  </Card>
                </Layout.Section>
              </Layout>
            </FormLayout>
          </Card>
        </FormLayout>
        {/* <h1>{categories.length}</h1> */}
        <Modal open={popupActive} loading={true}></Modal>
      </Page>
    </NavigationLayout>
  );
}
