import React from "react";
import { Button, Icon } from "@shopify/polaris";
import { useHistory } from "react-router-dom";
import { ExternalMinor, MobileBackArrowMajor } from "@shopify/polaris-icons";
import Cookies from "js-cookie";
import axios from "../../../axios";
import Cookie from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./UserHeader.css";

const UserHeader = (props) => {
  const history = useHistory();

  function handleImpersonateButtonClick() {
    axios
      .get(`/admin/v1/users/${props.userId}/impersonate`, {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      })
      .then((res) => {
        toast.success("This user is impersonated", 3000);
        window.open(res.data.link, "_blank");
      })
      .catch((err) => console.log(err));
  }

  const handleChange = (event) => {
    props.setCountry(event.target.value);
  };

  return (
    <div className="navHeaderWrap">
      <div
        style={{
          height: "fit-content",
          padding: "10px",
          paddingLeft: "20px",
          fontWeight: "bold",
          width: "100%",
          fontSize: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Button onClick={() => history.goBack()}>
            <Icon source={MobileBackArrowMajor} color="base" />
          </Button>
          <div style={{ display: "flex" }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => window.scroll({ top: 0, behavior: "smooth" })}
            >
              {props.name}
            </div>
            <div
              style={{ display: "flex", flexWrap: "wrap", columnGap: "5px" }}
            ></div>
          </div>
        </div>
        <div className="buttonsContainer">
          <span style={{ paddingRight: "10px" }} className="dropdown">
            <FormControl
              sx={{ m: 1, minWidth: 80, color: "#ff2d47", maxHeight: "22px" }}
            >
              <InputLabel
                id="demo-simple-select-autowidth-label"
                sx={{ color: "black", fontSize: "10px", fontFamily: "inherit" }}
              >
                Country
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={props.country}
                onChange={handleChange}
                autoWidth
                label="Country"
                sx={{
                  color: "#ff2d47",
                  fontSize: "10px",
                  fontFamily: "inherit",
                  fontWeight: "bold",
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="SA">Saudi Arabia</MenuItem>
                <MenuItem value="AE">United Arab Emirates</MenuItem>
                <MenuItem value="LB">Lebanon</MenuItem>
                <MenuItem value="KW">Kuwait</MenuItem>
                <MenuItem value="QA">Qatar</MenuItem>
                <MenuItem value="BH">Bahrain</MenuItem>
                <MenuItem value="OM">Oman</MenuItem>
                <MenuItem value="RW">Rest of World</MenuItem>
              </Select>
            </FormControl>
          </span>
          <span
            style={{
              color: "#ff2d47",
              marginTop: "20px",
              alignItems: "center",
            }}
          >
            <Button
              disabled={String(Cookies.get("userType")) === "50" && true}
              onClick={handleImpersonateButtonClick}
              monochrome
              outline
              icon={ExternalMinor}
            >
              View Account
            </Button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserHeader;
