import React from "react";
import {Modal, TextContainer} from "@shopify/polaris";

function Popup(props) {
  return (
      <Modal
        open={props.activeDeleteMessage}
        onClose={props.onClose}
        title={props.title}
        primaryAction={{
            content: "No",
            onAction: props.handleNoDelete,
        }}
        secondaryActions={[
            {
                content: "Yes",
                onAction: props.handleYesDelete,
            },
        ]}
        >
            <Modal.Section>
                <TextContainer>
                  <p>{props.text}</p>
                </TextContainer>
              </Modal.Section>
        </Modal>
  );

}
export default Popup;
