import React, { useState, useEffect } from "react";
import { Card, FormLayout, TextStyle } from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import { useParams } from "react-router-dom";
import "./Screening.css";
import { clinicalFindings } from "../../../util/constants";

function Screening() {
  let { id } = useParams();
  const [responseScreening, setResponseScreening] = useState(null);
  const [listOfProcedures, setListOfProcedures] = useState([]);

  useEffect(() => {
    axios
      .get(`admin/v1/users/${id}/your-photos/teleconsult-screening`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setResponseScreening(result.data.data);
        result.data.data.list_of_procedures == null
          ? setListOfProcedures([])
          : setListOfProcedures(result.data.data.list_of_procedures);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="screening-container">
      <h1 className="screening-title">SCREENING</h1>
      <Card sectioned>
        {responseScreening && (
          <Card.Section sectioned>
            <FormLayout>
              {responseScreening.date_of_birth && (
                <div>
                  <TextStyle variation="strong">Date of Birth: </TextStyle>
                  <TextStyle>{responseScreening.date_of_birth}</TextStyle>
                </div>
              )}
              {responseScreening.problem_question && (
                <div>
                  <TextStyle variation="strong">Chief Complaint: </TextStyle>
                  <TextStyle>{responseScreening.problem_question}</TextStyle>
                </div>
              )}
              {(responseScreening.dental_history || (responseScreening?.clinical_findings_v2?.length &&  !responseScreening.clinical_findings_v2.find(clinical_finding => clinical_finding.condition === 'none'))) && (
                <div>
                  <TextStyle variation="strong">Clinical Findings: </TextStyle>
                  {
                    responseScreening.dental_history ?
                    <TextStyle>{responseScreening.dental_history}</TextStyle> :
                    responseScreening?.clinical_findings_v2?.map((finding, key) => {
                      let toothNumber = finding.tooth_number ? `Tooth Number (${finding.tooth_number})` : '';
                      return (
                        <TextStyle key={key}>{`${clinicalFindings.find(f => f.value === finding.condition).label}: ${toothNumber}, `}</TextStyle>
                      )
                    })
                  }
                </div>
              )}
              {(responseScreening.dental_history_na === 1 || responseScreening?.clinical_findings_v2?.length) ? (
                <div>
                  <TextStyle variation="strong">
                    {(responseScreening.dental_history_na === 1 || (responseScreening?.clinical_findings_v2?.length && !responseScreening.clinical_findings_v2.find(clinical_finding => clinical_finding.condition === 'none') && responseScreening.clinical_findings_v2.filter(clinical_finding => clinical_finding?.procedures).length))
                      ? "Patient cannot proceed with aligner treatment without prior pre-treatment procedures."
                      : "Patient can proceed with aligner treatment without prior pre-treatment procedures."}
                  </TextStyle>
                </div>
              ) : null}
              {(responseScreening.dental_history_na === 1 || (responseScreening?.clinical_findings_v2?.length && !responseScreening.clinical_findings_v2.find(clinical_finding => clinical_finding.condition === 'none') && responseScreening.clinical_findings_v2.filter(clinical_finding => clinical_finding?.procedures).length)) ? (
                <div>
                  <TextStyle variation="strong">List of Procedures: </TextStyle>
                  {
                    responseScreening.dental_history_na === 1 ?
                      listOfProcedures.map((procedure, index) => (
                        <div key={index}>
                          <TextStyle>--</TextStyle>
                          <TextStyle>{procedure}</TextStyle>
                        </div>
                      )) :
                      responseScreening.clinical_findings_v2.filter(clinical_finding => clinical_finding.procedures).map((procedure, index) => {
                        return (
                          <div key={index}>
                            <TextStyle>--</TextStyle>
                            <TextStyle>{`${clinicalFindings.find(f => f.value === procedure.condition).label}: (${procedure.procedures})`}</TextStyle>
                          </div>
                        )
                    })
                  }
                </div>
              ) : null}
            </FormLayout>
          </Card.Section>
        )}
      </Card>
    </div>
  );
}

export default React.memo(Screening);
