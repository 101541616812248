import React, { useCallback, useState, useEffect } from "react";
import {
  InlineError,
  Page,
  FormLayout,
  TextField,
  Card,
  Modal,
  PageActions,
  Thumbnail,
  Stack,
  DropZone,
  TextStyle,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";
import {useLocation, useParams} from "react-router-dom";
import UserHeader from "../../../components/UserLayout/user-header";

function ScansNote() {
  const { id } = useParams();
  const [popupActive, setPopupActive] = useState(true);
  const [noteValue, setNoteValue] = useState("");
  const [noteError, setNoteError] = useState("");
  const handleChangeNote = useCallback(
    (newValue) => setNoteValue(newValue),
    []
  );
  useEffect(() => {
    axios
      .get(`admin/v1/users/${id}/scan-notes?redo=false&type=BAS`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setPopupActive(false);
        result?.data?.data?.note && setNoteValue(result.data.data.note);
      })
      .catch((err) => console.log(err));
  }, []);
  const location = useLocation();
  let linkState =
    {planType: "", clinicStatus: "", clinicName: "", status: "", userId: id, pageTitle: ""};
  if (location.state !== undefined) {
    const {planType, clinicStatus, clinicName, status, userId, pageTitle} = location.state;
    linkState = {planType, clinicStatus, clinicName, status, userId, pageTitle}
  }
  const { planType, clinicStatus, clinicName, status, userId, pageTitle} = linkState;
  return (
    <Page>
      <UserHeader pageTitle={pageTitle} status={status} userId={userId} clinicStatus={clinicStatus} clinicName={clinicName} planType={planType}/>
      <br/>
      <h1 className="page-header">Edit Scan Note</h1>
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Note</TextStyle>
          <TextField
            error={noteError}
            value={noteValue}
            onChange={handleChangeNote}
          ></TextField>
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );

  function handleSave() {
    if (!noteValue) {
      setNoteError("Please enter a note");
    } else {
      setPopupActive(true);
      const bodyObj = {
        note: noteValue,
        redo: false,
        type: "BAS",
      };
      axios
        .post(`/admin/v1/users/${id}/scan-notes  `, bodyObj, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          history.push("/admin/users/" + id + "/dashboard");
        })
        .catch((err) => console.log(""));
    }
  }
}
export default ScansNote;
