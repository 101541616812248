import React, { useCallback, useState, useEffect, useRef } from "react";
import IPRSheet from "../IPRSheet.css";
const IRPPatientData = (props) => {
  return (
    <div>
      <div className="row-flexing">
        <div
          className="column-flexing"
          style={{
            flexGrow: "9",
          }}
        >
          <div className="patient-question">
            <div className="row-flexing row-name">
              <div>Patient Name: </div>
              <div className="patient-data">
                <span>{props.PatientName}</span>
              </div>
            </div>
          </div>

          <div className="patient-question column-flexing">
            <div
              className="row-flexing"
              style={{
                gap: "40px",
              }}
            >
              <div className="row-flexing">
                <div>ID: </div>
                <div className="patient-data">{props.PatientID}</div>
              </div>
              <div className="row-flexing">
                <div>Generated Date:</div>
                
                <div className="patient-data">{props.date} </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                columnGap: "20px",
              }}
            >
              <div className="patient-question row-flexing">
                <div>Treatment Stage:</div>
                <div className="patient-data">{props.TratmentStage}</div>
              </div>
            </div>
            <div style={{ marginTop: "2%" }}>
              <span className="aligner-question">Before aligner step#</span>
              <span className="aligner-data">{props.Key}{props.position==null?"":"-"+props.position}</span>
            </div>
          </div>
        </div>
        <div className="attatchment-img">
          <img src={require("./pdfimages/attachement.png").default}></img>
        </div>
      </div>
    </div>
  );
};
export default IRPPatientData;
