import React, { useCallback, useState, useEffect } from "react";
import {
  Modal,
  Thumbnail,
  Caption,
  DropZone,
  Checkbox,
  Stack,
  TextStyle,
  Page,
  Button,
  FormLayout,
  TextField,
  Select,
  Card,
  PageActions,
  Layout,
  InlineError,
  Icon,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";
import { useParams } from "react-router-dom";
import { NoteMinor } from "@shopify/polaris-icons";
import leftBiteImage from "../../../assets/images/left_bite.jpg";
import rightBiteImage from "../../../assets/images/right_bite.jpeg";
import centerBiteImage from "../../../assets/images/center_bite.jpeg";
import lowerTeethImage from "../../../assets/images/lower_teeth.jpeg";
import upperTeethImage from "../../../assets/images/upper_teeth.jpeg";
import front_facing_big_smile from "../../../assets/images/front_facing_big_smile.jpg";
import front_facing_no_smile from "../../../assets/images/front_facing_no_smile.jpg";
import profile_no_smile from "../../../assets/images/right_side_profile.jpg";
import {
  PlayCircleMajor
} from '@shopify/polaris-icons';
import Lightbox from "react-awesome-lightbox";

const EditFile = (props) => {
  var { userId, fileId } = useParams();
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [popupActive, setPopupActive] = useState(false);
  const [selected, setSelected] = useState("Post IPR");
  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const options = [
    { label: "Post IPR Photos", value: "Post IPR" },
    { label: "Post Redo Photos", value: "Post Redo" },
    { label: "Other", value: "Other" },
  ];
  const [addFilesRedoKey, setAddFilesRedoKey] = useState([]);
  const [postRedoObject, setPostRedoObject] = useState(null);
  const [deleted, setDeleted] = useState(true);
  const [files1, setFiles1] = useState([]);
  const handleDropZoneDrop1 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles1((files1) => [...files1, ...acceptedFiles]),
    []
  );
  const [date, setDate] = useState({
    center_bite_created_at: "",
    center_bite_updated_at: "",
    front_facing_no_smile_created_at: "",
    front_facing_no_smile_updated_at: "",
    front_facing_big_smile_created_at: "",
    front_facing_big_smile_updated_at: "",
    left_bite_created_at: "",
    left_bite_updated_at: "",
    lower_teeth_created_at: "",
    lower_teeth_updated_at: "",
    profile_no_smile_created_at: "",
    profile_no_smile_updated_at: "",
    right_bite_created_at: "",
    right_bite_updated_at: "",
    upper_teeth_created_at: "",
    upper_teeth_updated_at: "",
  });
  const {
    center_bite_created_at,
    center_bite_updated_at,
    front_facing_big_smile_created_at,
    front_facing_big_smile_updated_at,
    front_facing_no_smile_created_at,
    front_facing_no_smile_updated_at,
    left_bite_updated_at,
    left_bite_created_at,
    right_bite_updated_at,
    lower_teeth_created_at,
    lower_teeth_updated_at,
    profile_no_smile_created_at,
    profile_no_smile_updated_at,
    right_bite_created_at,
    upper_teeth_created_at,
    upper_teeth_updated_at
  } = date;
  // const [completedCheck, setCompletedCheck] = useState(false);
  const [approvedCheck, setApprovedCheck] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [imagesArray, setImagesArray] = useState([]);
  const [index, setIndex] = useState(0);
  const [stepNumber, setStepNumber] = useState(0);
  // const handleCompletedCheck = (newValue) => {
  //   setCompletedCheck(newValue);
  // };  
  const handleApprovedCheck = (newValue) => {
    setApprovedCheck(newValue);
  };
  const handleStepNumberChange = (e) => {
    setStepNumber(e.target.value)
  };
  const fileUpload1 = !files1.length && <DropZone.FileUpload />;
  const uploadedFiles1 = files1.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files1[files1.length - 1].name}
        source={
          validImageTypes.indexOf(files1[files1.length - 1].type) > 0
            ? window.URL.createObjectURL(files1[files1.length - 1])
            : NoteMinor
        }
      />
      <div>{files1[files1.length - 1].name} </div>
    </Stack>
  );
  const [imagePreview1, setImagePreview1] = useState("");
  const [imageKey1, setImageKey1] = useState("");
  const sendImage1 = (files1, addFilesRedoKey, other_id) => {
      setPopupActive(true);
      let img = validImageTypes.indexOf(files1[files1.length - 1].type) > 0 ? window.URL.createObjectURL(files1[files1.length - 1]) : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304";
      setImagePreview1(img);
      updateImagesArray('other', img);
      let url2 = "";
      const form_data = new FormData();
      let imageType = files1[files1.length - 1].type;
      form_data.append("content_type", imageType);
      form_data.append("file_name", files1[files1.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          console.log("res1");
          // setImageKey1(res.data.key);
          setAddFilesRedoKey([
            ...addFilesRedoKey,
            {id: other_id ?? 0, name: 'other', file_type: imageType, file : res.data.key}
          ]);
          url2 = res.data.url;
          axios
            .put(url2, files1[files1.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": files1[files1.length - 1].type,
              },
            })
            .then((res) => {
              setFileError("");
              setPopupActive(false);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    };

  const [noteValue, setNoteValue] = useState("");
  const handleChangeNoteValue = useCallback(
    (newValue) => setNoteValue(newValue),
    []
  );
  const [fileError, setFileError] = useState("");

  let imageType = "";
  const [imageKeyCenterBite, setImageKeyCenterBite] = useState("");
  const [imageKeyLeftBite, setImageKeyLeftBite] = useState("");
  const [imageKeyRightBite, setImageKeyRightBite] = useState("");
  const [imageKeyUpperTeeth, setImageKeyUpperTeeth] = useState("");
  const [imageKeyLowerTeeth, setImageKeyLowerTeeth] = useState("");
  const [imagePreviewCenterBite, setImagePreviewCenterBite] =
    useState(centerBiteImage);
  const [imagePreviewLeftBite, setImagePreviewLeftBite] =
    useState(leftBiteImage);
  const [imagePreviewRightBite, setImagePreviewRightBite] =
    useState(rightBiteImage);
  const [imagePreviewUpperTeeth, setImagePreviewUpperTeeth] =
    useState(upperTeethImage);
  const [imagePreviewLowerTeeth, setImagePreviewLowerTeeth] =
    useState(lowerTeethImage);
  const [imagePreviewFronFacingNoSmile, setImagePreviewFrontFacingNoSmile] =
    useState(front_facing_no_smile);
  const [imagePreviewFrontFacingBigSmile, setImagePreviewFrontFacingBigSmile] =
    useState(front_facing_big_smile);
  const [imagePreviewProfileNoSmile, setImagePreviewProfileNoSmile] =
    useState(profile_no_smile);
  const [filesCenterBite, setFilesCenterBite] = useState([]);
  const handleDropZoneCenterBite = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesCenterBite((filesCenterBite) => [
        ...filesCenterBite,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadCenterBite = !filesCenterBite.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesCenterBite = filesCenterBite.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesCenterBite[filesCenterBite.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesCenterBite[filesCenterBite.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesCenterBite[filesCenterBite.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesCenterBite[filesCenterBite.length - 1].name}{" "}
        <Caption>
          {filesCenterBite[filesCenterBite.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageCenterBite = (filesCenterBite, addFilesRedoKey, center_bite_id) => {
      setPopupActive(true);
      let img = validImageTypes.indexOf(filesCenterBite[filesCenterBite.length - 1].type) > 0 ? window.URL.createObjectURL(filesCenterBite[filesCenterBite.length - 1]) : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304";
      setImagePreviewCenterBite(img);
      updateImagesArray('center_bite', img);
      imageType = filesCenterBite[filesCenterBite.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append("file_name", filesCenterBite[filesCenterBite.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          // setImageKeyCenterBite(res.data.key);
          setAddFilesRedoKey([
            ...addFilesRedoKey,
            {id: center_bite_id ?? 0, name: 'center_bite', file_type: imageType, file : res.data.key}
          ]);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesCenterBite[filesCenterBite.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    };

  const [filesLeftBite, setFilesLeftBite] = useState([]);
  const handleDropZoneLeftBite = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesLeftBite((filesLeftBite) => [...filesLeftBite, ...acceptedFiles]),
    []
  );
  const fileUploadLeftBite = !filesLeftBite.length && <DropZone.FileUpload />;
  let uploadedFilesLeftBite = filesLeftBite.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesLeftBite[filesLeftBite.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesLeftBite[filesLeftBite.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesLeftBite[filesLeftBite.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesLeftBite[filesLeftBite.length - 1].name}{" "}
        <Caption>{filesLeftBite[filesLeftBite.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImageLeftBite = (filesLeftBite, addFilesRedoKey, left_bite_id) => {
      setPopupActive(true);
      let img = validImageTypes.indexOf(filesLeftBite[filesLeftBite.length - 1].type) > 0 ? window.URL.createObjectURL(filesLeftBite[filesLeftBite.length - 1]) : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304";
      setImagePreviewLeftBite(img);
      updateImagesArray('left_bite', img);
      imageType = filesLeftBite[filesLeftBite.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append("file_name", filesLeftBite[filesLeftBite.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          // setImageKeyLeftBite(res.data.key);
          setAddFilesRedoKey([
            ...addFilesRedoKey,
            {id: left_bite_id ?? 0, name: 'left_bite', file_type: imageType, file : res.data.key}
          ]);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesLeftBite[filesLeftBite.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    };

  const [filesRightBite, setFilesRightBite] = useState([]);
  const handleDropZoneRightBite = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesRightBite((filesRightBite) => [
        ...filesRightBite,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadRightBite = !filesRightBite.length && <DropZone.FileUpload />;
  let uploadedFilesRightBite = filesRightBite.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesRightBite[filesRightBite.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesRightBite[filesRightBite.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesRightBite[filesRightBite.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesRightBite[filesRightBite.length - 1].name}{" "}
        <Caption>
          {filesRightBite[filesRightBite.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageRightBite = (filesRightBite, addFilesRedoKey, right_bite_id) => {
      setPopupActive(true);
      let img = validImageTypes.indexOf( filesRightBite[filesRightBite.length - 1].type ) > 0 ? window.URL.createObjectURL( filesRightBite[filesRightBite.length - 1] ) : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304";
      setImagePreviewRightBite(img);
      updateImagesArray('right_bite', img);
      imageType = filesRightBite[filesRightBite.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append("file_name", filesRightBite[filesRightBite.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          // setImageKeyRightBite(res.data.key);
          setAddFilesRedoKey([
            ...addFilesRedoKey,
            {id: right_bite_id ?? 0, name: 'right_bite', file_type: imageType, file : res.data.key}
          ]);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesRightBite[filesRightBite.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    };

  const [filesUpperTeeth, setFilesUpperTeeth] = useState([]);
  const handleDropZoneUpperTeeth = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesUpperTeeth((filesUpperTeeth) => [
        ...filesUpperTeeth,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadUpperTeeth = !filesUpperTeeth.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesUpperTeeth = filesUpperTeeth.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesUpperTeeth[filesUpperTeeth.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesUpperTeeth[filesUpperTeeth.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesUpperTeeth[filesUpperTeeth.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesUpperTeeth[filesUpperTeeth.length - 1].name}{" "}
        <Caption>
          {filesUpperTeeth[filesUpperTeeth.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageUpperTeeth = (filesUpperTeeth, addFilesRedoKey, upper_teeth_id) => {
      setPopupActive(true);
      let img = validImageTypes.indexOf( filesUpperTeeth[filesUpperTeeth.length - 1].type ) > 0 ? window.URL.createObjectURL( filesUpperTeeth[filesUpperTeeth.length - 1] ) : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304";
      setImagePreviewUpperTeeth(img);
      updateImagesArray('upper_teeth', img);
      imageType = filesUpperTeeth[filesUpperTeeth.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append("file_name", filesUpperTeeth[filesUpperTeeth.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          // setImageKeyUpperTeeth(res.data.key);
          setAddFilesRedoKey([
            ...addFilesRedoKey,
            {id: upper_teeth_id ?? 0, name: 'upper_teeth', file_type: imageType, file : res.data.key}
          ]);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesUpperTeeth[filesUpperTeeth.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    };

  const [filesLowerTeeth, setFilesLowerTeeth] = useState([]);
  const handleDropZoneLowerTeeth = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesLowerTeeth((filesLowerTeeth) => [
        ...filesLowerTeeth,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadLowerTeeth = !filesLowerTeeth.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesLowerTeeth = filesLowerTeeth.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesLowerTeeth[filesLowerTeeth.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesLowerTeeth[filesLowerTeeth.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesLowerTeeth[filesLowerTeeth.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesLowerTeeth[filesLowerTeeth.length - 1].name}{" "}
        <Caption>
          {filesLowerTeeth[filesLowerTeeth.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageLowerTeeth = (filesLowerTeeth, addFilesRedoKey, lower_teeth_id) => {
      setPopupActive(true);
      let img = validImageTypes.indexOf( filesLowerTeeth[filesLowerTeeth.length - 1].type ) > 0 ? window.URL.createObjectURL( filesLowerTeeth[filesLowerTeeth.length - 1] ) : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304";
      setImagePreviewLowerTeeth(img);
      updateImagesArray('lower_teeth', img);
      imageType = filesLowerTeeth[filesLowerTeeth.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append("file_name", filesLowerTeeth[filesLowerTeeth.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          // setImageKeyLowerTeeth(res.data.key);
          setAddFilesRedoKey([
            ...addFilesRedoKey,
            {id: lower_teeth_id ?? 0, name: 'lower_teeth', file_type: imageType ,file : res.data.key}
          ]);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesLowerTeeth[filesLowerTeeth.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    };
  /** new Images */ 
  const [frontFacingNoSmile, setFrontFacingNoSmile] = useState([]);
  const handleDropZoneFrontFacingNoSmile = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFrontFacingNoSmile((frontFacingNoSmile) => [
        ...frontFacingNoSmile,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadFrontFacingNoSmile = !frontFacingNoSmile.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesFrontFacingNoSmile = frontFacingNoSmile.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={frontFacingNoSmile[frontFacingNoSmile.length - 1].name}
        source={
          validImageTypes.indexOf(
            frontFacingNoSmile[frontFacingNoSmile.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                frontFacingNoSmile[frontFacingNoSmile.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {frontFacingNoSmile[frontFacingNoSmile.length - 1].name}{" "}
        <Caption>
          {frontFacingNoSmile[frontFacingNoSmile.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageFrontFacingNoSmile = (frontFacingNoSmile, addFilesRedoKey, front_facing_no_smile_id) => {
      setPopupActive(true);
      let img = validImageTypes.indexOf( frontFacingNoSmile[frontFacingNoSmile.length - 1].type ) > 0 ? window.URL.createObjectURL( frontFacingNoSmile[frontFacingNoSmile.length - 1] ) : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304";
      setImagePreviewFrontFacingNoSmile(img);
      updateImagesArray('front_facing_no_smile', img);
      imageType = frontFacingNoSmile[frontFacingNoSmile.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append("file_name", frontFacingNoSmile[frontFacingNoSmile.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          // setImageKeyFrontFacingNoSmile(res.data.key);
          setAddFilesRedoKey([
            ...addFilesRedoKey,
            {id: front_facing_no_smile_id ?? 0, name: 'front_facing_no_smile', file_type: imageType ,file : res.data.key}
          ]);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, frontFacingNoSmile[frontFacingNoSmile.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    };

  const [frontFacingBigSmile, setFrontFacingBigSmile] = useState([]);
  const handleDropZoneFrontFacingBigSmile = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFrontFacingBigSmile((frontFacingBigSmile) => [
        ...frontFacingBigSmile,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadFrontFacingBigSmile = !frontFacingBigSmile.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesFrontFacingBigSmile = frontFacingBigSmile.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={frontFacingBigSmile[frontFacingBigSmile.length - 1].name}
        source={
          validImageTypes.indexOf(
            frontFacingBigSmile[frontFacingBigSmile.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                frontFacingBigSmile[frontFacingBigSmile.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {frontFacingBigSmile[frontFacingBigSmile.length - 1].name}{" "}
        <Caption>
          {frontFacingBigSmile[frontFacingBigSmile.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageFrontFacingBigSmile = (frontFacingBigSmile, addFilesRedoKey, front_facing_big_smile_id) => {
      setPopupActive(true);
      let img = validImageTypes.indexOf( frontFacingBigSmile[frontFacingBigSmile.length - 1].type ) > 0 ? window.URL.createObjectURL( frontFacingBigSmile[frontFacingBigSmile.length - 1] ) : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304";
      setImagePreviewFrontFacingBigSmile(img);
      updateImagesArray('front_facing_big_smile', img);
      imageType = frontFacingBigSmile[frontFacingBigSmile.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append("file_name", frontFacingBigSmile[frontFacingBigSmile.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          // setImageKeyFrontFacingBigSmile(res.data.key);
          setAddFilesRedoKey([
            ...addFilesRedoKey,
            {id: front_facing_big_smile_id ?? 0, name: 'front_facing_big_smile', file_type: imageType ,file : res.data.key}
          ]);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, frontFacingBigSmile[frontFacingBigSmile.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    };

  const [profileNoSmile, setProfileNoSmile] = useState([]);
  const handleDropZoneProfileNoSmile = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setProfileNoSmile((profileNoSmile) => [
        ...profileNoSmile,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadProfileNoSmile = !profileNoSmile.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesProfileNoSmile = profileNoSmile.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={profileNoSmile[profileNoSmile.length - 1].name}
        source={
          validImageTypes.indexOf(
            profileNoSmile[profileNoSmile.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                profileNoSmile[profileNoSmile.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {profileNoSmile[profileNoSmile.length - 1].name}{" "}
        <Caption>
          {profileNoSmile[profileNoSmile.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageProfileNoSmile = (profileNoSmile, addFilesRedoKey, profile_no_smile_id) => {
      setPopupActive(true);
      let img = validImageTypes.indexOf( profileNoSmile[profileNoSmile.length - 1].type ) > 0 ? window.URL.createObjectURL( profileNoSmile[profileNoSmile.length - 1] ) : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304";
      setImagePreviewProfileNoSmile(img);
      updateImagesArray('profile_no_smile', img);
      imageType = profileNoSmile[profileNoSmile.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      form_data.append("file_name", profileNoSmile[profileNoSmile.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          // setImageKeyProfileNoSmile(res.data.key);
          setAddFilesRedoKey([
            ...addFilesRedoKey,
            {id: profile_no_smile_id ?? 0, name: 'profile_no_smile', file_type: imageType ,file : res.data.key}
          ]);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, profileNoSmile[profileNoSmile.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    };

    /* 
  *  multiple add files Post IPR photos 
  */
    const [addFilesSave, setAddFilesSave] = useState([]);
    const [addFilesPreview, setAddFilesPreview] = useState([]);
    const [addFilesKey, setAddFilesKey] = useState([]);
    const [errorFilesUpload, setErrorFilesUpload] = useState([]);
  
    const sendImages = (files, index, addFilesPreview, imageId) => {
        setPopupActive(true);
        const validImageUrl = validImageTypes.indexOf(
          files[files.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              files[files.length - 1]
            )
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304";
          console.log('files upload index',index);
        updateImagesArray(index, validImageUrl);
        addFilesPreview[index] = { id: 0, name: null, file_url : validImageUrl };
        setAddFilesPreview(addFilesPreview);
        addFilesSave[index] = files[files.length - 1]
        imageType = files[files.length - 1].type;
        let headersVar = null;
        let url2 = "";
        let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
        const form_data = new FormData();
        form_data.append("content_type", imageType);
        form_data.append("file_name", files[files.length - 1].name);
        axios
          .post("/admin/v1/images/s3", form_data,{
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          })
          .then((res) => {
            addFilesKey[index] = {id: imageId ?? 0, file_type: imageType, name: null, file : res.data.key};
            url2 = res.data.url;
            headersVar = res.data.headers;
            axios
              .put(url2, files[files.length - 1], {
                headers: {
                  "x-amz-acl": "public-read-write",
                  "Content-Type": imageType,
                },
              })
              .then((res) => {
                setPopupActive(false);
              })
              .catch((err) =>{
                errorFilesUpload[index] = "Upload failed. Please try again.";
                addFilesPreview[index] = null;
                addFilesSave[index] = null;
                setPopupActive(false);
              });
                
          })
          .catch((err) => {
            errorFilesUpload[index] = "Upload failed. Please try again.";
            addFilesPreview[index] = null;
            addFilesSave[index] = null;
            setPopupActive(false)
          });
    };
  
    const handleAddFiles = () => {
      setAddFilesPreview((prev)=>[
        ...prev,
        {}
      ]);
    }

  const updateImagesArray = (id, url) => {
    let arr = imagesArray;
    let ind = arr.findIndex(img => img?.id == id);
    if(ind >= 0){
      arr[ind].url = url;
    } else {
      arr.push({id, url});
    }
    setImagesArray(arr);
  }

  const handleIndexChange = (id) => {
    let ind = imagesArray.findIndex(img => img?.id == id);
    if(ind >= 0){
      setIndex(ind);
      setIsOpen(true);
    }
  }

  useEffect(() => {
    if(deleted){
    axios
      .get(`admin/v1/users/${userId}/storage/${fileId}/show`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        result?.data?.data?.note && setNoteValue(result.data.data.note);
        setPostRedoObject(result?.data?.redo_info);
        let arr = [];
        if(result?.data?.redo_info?.other){
          arr.push({id: 'other', url: result?.data?.redo_info?.other});
          setImagePreview1(result.data.redo_info.other);
        }
        if(result?.data?.redo_info?.center_bite){
          arr.push({id: 'center_bite', url: result?.data?.redo_info?.center_bite});
          setImagePreviewCenterBite(result.data.redo_info.center_bite);
        }
        if(result?.data?.redo_info?.left_bite){
          arr.push({id: 'left_bite', url: result?.data?.redo_info?.left_bite});
          setImagePreviewLeftBite(result.data.redo_info.left_bite);
        }
        if(result?.data?.redo_info?.right_bite){
          arr.push({id: 'right_bite', url: result?.data?.redo_info?.right_bite});
          setImagePreviewRightBite(result.data.redo_info.right_bite);
        }
        if(result?.data?.redo_info?.upper_teeth){
          arr.push({id: 'upper_teeth', url: result?.data?.redo_info?.upper_teeth});
          setImagePreviewUpperTeeth(result.data.redo_info.upper_teeth);
        }
        if(result?.data?.redo_info?.lower_teeth){
          arr.push({id: 'lower_teeth', url: result?.data?.redo_info?.lower_teeth});
          setImagePreviewLowerTeeth(result.data.redo_info.lower_teeth);
        }
        if(result?.data?.redo_info?.front_facing_no_smile){
          arr.push({id: 'front_facing_no_smile', url: result?.data?.redo_info?.front_facing_no_smile});
          setImagePreviewFrontFacingNoSmile(result.data.redo_info.front_facing_no_smile);
        }
        if(result?.data?.redo_info?.front_facing_big_smile){
          arr.push({id: 'front_facing_big_smile', url: result?.data?.redo_info?.front_facing_big_smile});
          setImagePreviewFrontFacingBigSmile(result.data.redo_info.front_facing_big_smile);
        }
        if(result?.data?.redo_info?.profile_no_smile){
          arr.push({id: 'profile_no_smile', url: result?.data?.redo_info?.profile_no_smile});
          setImagePreviewProfileNoSmile(result.data.redo_info.profile_no_smile);
        }
        if(result?.data?.storage_file){
          if(result?.data?.storage_file.length){// check mybe tfut here when adding another file bel post redo
            result?.data?.storage_file.forEach(element => {
              arr.push({
                url: element?.file_url,
                id: element?.id,
              });
            });
          }
          setAddFilesPreview(result?.data?.storage_file);
        }
        setSelected(result.data.data.type);
        if(arr.length){
          setImagesArray(arr);
        }
        result?.data?.data?.post_ipr_step_number &&
          setStepNumber(result.data.data.post_ipr_step_number);
        // result?.data?.data?.post_ipr_completed &&
        //   setCompletedCheck(result.data.data.post_ipr_completed);
        result?.data?.data?.post_ipr_approved &&
          setApprovedCheck(result.data.data.post_ipr_approved);
        const newDate = {
          center_bite_created_at: result?.data?.redo_info?.center_bite_created_at,
          center_bite_updated_at: result?.data?.redo_info?.center_bite_updated_at,
          front_facing_big_smile_created_at: result?.data?.redo_info?.front_facing_big_smile_created_at,
          front_facing_big_smile_updated_at: result?.data?.redo_info?.front_facing_big_smile_updated_at,
          front_facing_no_smile_created_at: result?.data?.redo_info?.front_facing_no_smile_created_at,
          front_facing_no_smile_updated_at: result?.data?.redo_info?.front_facing_no_smile_updated_at,
          left_bite_created_at: result?.data?.redo_info?.left_bite_created_at,
          left_bite_updated_at: result?.data?.redo_info?.left_bite_updated_at,
          lower_teeth_created_at: result?.data?.redo_info?.lower_teeth_created_at,
          lower_teeth_updated_at: result?.data?.redo_info?.lower_teeth_updated_at,
          profile_no_smile_created_at: result?.data?.redo_info?.profile_no_smile_created_at,
          profile_no_smile_updated_at: result?.data?.redo_info?.profile_no_smile_updated_at,
          right_bite_created_at: result?.data?.redo_info?.right_bite_created_at,
          right_bite_updated_at: result?.data?.redo_info?.right_bite_updated_at,
          upper_teeth_created_at: result?.data?.redo_info?.upper_teeth_created_at,
          upper_teeth_updated_at: result?.data?.redo_info?.upper_teeth_updated_at,
        };
        setDate(newDate);
      })
      .catch((err) => console.log(err))
      .finally(() => setDeleted(false));
    }
  }, [deleted]);

  return (
    <Page
      title="Edit File"
      breadcrumbs={[
        {
          content: "Dashboard",
          url: `/admin/users/${userId}/dashboard`,
        },
      ]}
    >
      <Card sectioned>
        <FormLayout>
          {/* <Select
            options={options}
            onChange={handleSelectChange}
            value={selected}
            placeholder="Please select a type"
          /> */}
          <TextStyle variation="strong">{selected}</TextStyle>
          {selected == "Other" && (
            <div style={{ marginTop: 10 }}>
              <TextStyle>File</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left", cursor: 'pointer' }} onClick={() => handleIndexChange('other')}>
                  <Thumbnail
                    size="large"
                    source={imagePreview1 ? imagePreview1 : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone
                    onDrop={handleDropZoneDrop1}
                    onDropAccepted={(file) => sendImage1(file, addFilesRedoKey, postRedoObject?.other_id)}
                  >
                    {uploadedFiles1}
                    {fileUpload1}
                  </DropZone>
                </div>
              </div>
              <br />
              <Button id="center_bite" onClick={(e) => handleDelete(e, postRedoObject?.other_id)}>
                  Delete
              </Button>
              {fileError}
            </div>
          )}
          {(selected == "Post IPR" || selected == "Post Redo") && (
            <div style={{textAlign: 'right'}}>
              <Button onClick={(e) => handleAddFiles()}>
                  Add Another File
              </Button>
            </div>
          )}
          {(selected == "Post Redo") && (
            <FormLayout>
              <FormLayout.Group>
                <FormLayout>
                  <TextStyle variation="strong">Center Bite</TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left", cursor: 'pointer' }} onClick={() => handleIndexChange('center_bite')}>
                      <Thumbnail size="large" source={imagePreviewCenterBite} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                        // maxHeight: "10px",
                      }}
                    >
                      <DropZone
                        onDrop={handleDropZoneCenterBite}
                        onDropAccepted={(file) => sendImageCenterBite(file, addFilesRedoKey, postRedoObject?.center_bite_id)}
                      >
                        {uploadedFilesCenterBite}
                        {fileUploadCenterBite}
                      </DropZone>
                    </div>
                  </div>
                  <div style={{display: "flex"}}>
                    <Button id="center_bite" onClick={(e) => handleDelete(e, postRedoObject?.center_bite_id)}>
                      Delete
                    </Button>
                    <div style={{marginLeft: "15px", display: "flex", justifyContent: "space-between", width: "100%"}}>
                      <div>
                        {center_bite_created_at && (
                          <>
                            <TextStyle variation="strong">Created At:</TextStyle>
                            <p>{center_bite_created_at}</p>
                          </>
                        )
                        }
                      </div>
                      <div>
                        {center_bite_updated_at &&
                          <>
                            <TextStyle variation="strong">Updated At:</TextStyle>
                            <p>{center_bite_updated_at}</p>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </FormLayout>
                <FormLayout>
                  <TextStyle variation="strong">Left Bite</TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left", cursor: 'pointer' }} onClick={() => handleIndexChange('left_bite')}>
                      <Thumbnail size="large" source={imagePreviewLeftBite} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                        // maxHeight: "10px",
                      }}
                    >
                      <DropZone
                        onDrop={handleDropZoneLeftBite}
                        onDropAccepted={(file) => sendImageLeftBite(file, addFilesRedoKey, postRedoObject?.left_bite_id)}
                      >
                        {uploadedFilesLeftBite}
                        {fileUploadLeftBite}
                      </DropZone>
                    </div>
                  </div>
                  <div style={{display: "flex"}}>
                    <Button id="left_bite" onClick={(e) => handleDelete(e, postRedoObject?.left_bite_id)}>
                      Delete
                    </Button>
                    <div style={{marginLeft: "15px", display: "flex", justifyContent: "space-between", width: "100%"}}>
                      <div>
                        {left_bite_created_at && (
                          <>
                            <TextStyle variation="strong">Created At:</TextStyle>
                            <p>{left_bite_created_at}</p>
                          </>
                        )
                        }
                      </div>
                      <div>
                        {left_bite_updated_at &&
                          <>
                            <TextStyle variation="strong">Updated At:</TextStyle>
                            <p>{left_bite_updated_at}</p>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </FormLayout>
              </FormLayout.Group>

              <FormLayout.Group>
                <FormLayout>
                  <TextStyle variation="strong">Right Bite</TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left", cursor: 'pointer' }} onClick={() => handleIndexChange('right_bite')}>
                      <Thumbnail size="large" source={imagePreviewRightBite} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                        // maxHeight: "10px",
                      }}
                    >
                      <DropZone
                        onDrop={handleDropZoneRightBite}
                        onDropAccepted={(file) => sendImageRightBite(file, addFilesRedoKey, postRedoObject?.right_bite_id)}
                      >
                        {uploadedFilesRightBite}
                        {fileUploadRightBite}
                      </DropZone>
                    </div>
                  </div>
                  <div style={{display: "flex"}}>
                    <Button id="right_bite" onClick={(e) => handleDelete(e, postRedoObject?.right_bite_id)}>
                      Delete
                    </Button>
                    <div style={{marginLeft: "15px", display: "flex", justifyContent: "space-between", width: "100%"}}>
                      <div>
                        {right_bite_created_at && (
                          <>
                            <TextStyle variation="strong">Created At:</TextStyle>
                            <p>{right_bite_created_at}</p>
                          </>
                        )
                        }
                      </div>
                      <div>
                        {right_bite_updated_at &&
                          <>
                            <TextStyle variation="strong">Updated At:</TextStyle>
                            <p>{right_bite_updated_at}</p>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </FormLayout>
                <FormLayout>
                  <TextStyle variation="strong">Upper Teeth</TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left", cursor: 'pointer' }} onClick={() => handleIndexChange('upper_teeth')}>
                      <Thumbnail size="large" source={imagePreviewUpperTeeth} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                        // maxHeight: "10px",
                      }}
                    >
                      <DropZone
                        onDrop={handleDropZoneUpperTeeth}
                        onDropAccepted={(file) => sendImageUpperTeeth(file, addFilesRedoKey, postRedoObject?.upper_teeth_id)}
                      >
                        {uploadedFilesUpperTeeth}
                        {fileUploadUpperTeeth}
                      </DropZone>
                    </div>
                  </div>
                  <div style={{display: "flex"}}>
                    <Button id="upper_teeth" onClick={(e) => handleDelete(e, postRedoObject?.upper_teeth_id)}>
                      Delete
                    </Button>
                    <div style={{marginLeft: "15px", display: "flex", justifyContent: "space-between", width: "100%"}}>
                      <div>
                        {upper_teeth_created_at && (
                          <>
                            <TextStyle variation="strong">Created At:</TextStyle>
                            <p>{upper_teeth_created_at}</p>
                          </>
                        )
                        }
                      </div>
                      <div>
                        {upper_teeth_updated_at &&
                          <>
                            <TextStyle variation="strong">Updated At:</TextStyle>
                            <p>{upper_teeth_updated_at}</p>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </FormLayout>
              </FormLayout.Group>

              <FormLayout.Group>
                <FormLayout>
                  <TextStyle variation="strong">Lower Teeth</TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left", cursor: 'pointer' }} onClick={() => handleIndexChange('lower_teeth')}>
                      <Thumbnail size="large" source={imagePreviewLowerTeeth} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                        // maxHeight: "10px",
                      }}
                    >
                      <DropZone
                        onDrop={handleDropZoneLowerTeeth}
                        onDropAccepted={(file) => sendImageLowerTeeth(file, addFilesRedoKey,  postRedoObject?.lower_teeth_id)}
                      >
                        {uploadedFilesLowerTeeth}
                        {fileUploadLowerTeeth}
                      </DropZone>
                    </div>
                  </div>
                  <div style={{display: "flex"}}>
                    <Button id="lower_teeth" onClick={(e) => handleDelete(e, postRedoObject?.lower_teeth_id)}>
                      Delete
                    </Button>           
                    <div style={{marginLeft: "15px", display: "flex", justifyContent: "space-between", width: "100%"}}>
                      <div>
                        {lower_teeth_created_at && (
                          <>
                            <TextStyle variation="strong">Created At:</TextStyle>
                            <p>{lower_teeth_created_at}</p>
                          </>
                        )
                        }
                      </div>
                      <div>
                        {lower_teeth_updated_at &&
                          <>
                            <TextStyle variation="strong">Updated At:</TextStyle>
                            <p>{lower_teeth_updated_at}</p>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </FormLayout>
                <FormLayout>
                  <TextStyle variation="strong">Front Facing No Smile</TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left", cursor: 'pointer' }} onClick={() => handleIndexChange('front_facing_no_smile')}>
                      <Thumbnail size="large" source={imagePreviewFronFacingNoSmile} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                        // maxHeight: "10px",
                      }}
                    >
                      <DropZone
                        onDrop={handleDropZoneFrontFacingNoSmile}
                        onDropAccepted={(file) => sendImageFrontFacingNoSmile(file, addFilesRedoKey,  postRedoObject?.front_facing_no_smile_id)}
                      >
                        {uploadedFilesFrontFacingNoSmile}
                        {fileUploadFrontFacingNoSmile}
                      </DropZone>
                    </div>
                  </div>
                  <div style={{display: "flex"}}>
                    <Button id="front_facing_no_smile" onClick={(e) => handleDelete(e, postRedoObject?.front_facing_no_smile_id)}>
                      Delete
                    </Button>
                    <div style={{marginLeft: "15px", display: "flex", justifyContent: "space-between", width: "100%"}}>
                      <div>
                        {front_facing_no_smile_created_at && (
                          <>
                            <TextStyle variation="strong">Created At:</TextStyle>
                            <p>{front_facing_no_smile_created_at}</p>
                          </>
                        )
                        }
                      </div>
                      <div>
                        {front_facing_no_smile_updated_at &&
                          <>
                            <TextStyle variation="strong">Updated At:</TextStyle>
                            <p>{front_facing_no_smile_updated_at}</p>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </FormLayout>
              </FormLayout.Group>
              
              <FormLayout.Group>
                <FormLayout>
                  <TextStyle variation="strong">Front Facing Big Smile</TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left", cursor: 'pointer' }} onClick={() => handleIndexChange('front_facing_big_smile')}>
                      <Thumbnail size="large" source={imagePreviewFrontFacingBigSmile} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                        // maxHeight: "10px",
                      }}
                    >
                      <DropZone
                        onDrop={handleDropZoneFrontFacingBigSmile}
                        onDropAccepted={(file) => sendImageFrontFacingBigSmile(file, addFilesRedoKey,  postRedoObject?.front_facing_big_smile_id)}
                      >
                        {uploadedFilesFrontFacingBigSmile}
                        {fileUploadFrontFacingBigSmile}
                      </DropZone>
                    </div>
                  </div>
                  <div style={{display: "flex"}}>
                    <Button id="front_facing_big_smile" onClick={(e) => handleDelete(e, postRedoObject?.front_facing_big_smile_id)}>
                      Delete
                    </Button>
                    <div style={{marginLeft: "15px", display: "flex", justifyContent: "space-between", width: "100%"}}>
                      <div>
                        {front_facing_big_smile_created_at && (
                          <>
                            <TextStyle variation="strong">Created At:</TextStyle>
                            <p>{front_facing_big_smile_created_at}</p>
                          </>
                        )
                        }
                      </div>
                      <div>
                        {front_facing_big_smile_updated_at &&
                          <>
                            <TextStyle variation="strong">Updated At:</TextStyle>
                            <p>{front_facing_big_smile_updated_at}</p>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </FormLayout>
                <FormLayout>
                  <TextStyle variation="strong">Profile No Smile</TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left", cursor: 'pointer' }} onClick={() => handleIndexChange('profile_no_smile')}>
                      <Thumbnail size="large" source={imagePreviewProfileNoSmile} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                        // maxHeight: "10px",
                      }}
                    >
                      <DropZone
                        onDrop={handleDropZoneProfileNoSmile}
                        onDropAccepted={(file) => sendImageProfileNoSmile(file, addFilesRedoKey,  postRedoObject?.profile_no_smile_id)}
                      >
                        {uploadedFilesProfileNoSmile}
                        {fileUploadProfileNoSmile}
                      </DropZone>
                    </div>
                  </div>
                  <div style={{display: "flex"}}>
                    <Button id="profile_no_smile" onClick={(e) => handleDelete(e, postRedoObject?.profile_no_smile_id)}>
                      Delete
                    </Button>
                    <div style={{marginLeft: "15px", display: "flex", justifyContent: "space-between", width: "100%"}}>
                      <div>
                        {profile_no_smile_created_at && (
                          <>
                            <TextStyle variation="strong">Created At:</TextStyle>
                            <p>{profile_no_smile_created_at}</p>
                          </>
                        )
                        }
                      </div>
                      <div>
                        {profile_no_smile_updated_at &&
                          <>
                            <TextStyle variation="strong">Updated At:</TextStyle>
                            <p>{profile_no_smile_updated_at}</p>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </FormLayout>
              </FormLayout.Group>
            </FormLayout>
          )}
          {(selected == "Post IPR" || selected == "Post Redo") && (
            <>
              <div style={{display: 'flex', flexDirection:'row', justifyContent : 'space-between', flexWrap:'wrap', gap:'1rem'}}>
                {Array.from(Array(addFilesPreview.length).keys()).map((idx)=>
                  <div key={idx} style={{ width: addFilesPreview.length == (idx + 1) && addFilesPreview.length % 2 != 0 ? '100%' : '49%', marginTop:'1rem'}}>
                    <FormLayout>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                          }}
                        >
                          <div style={{ float: "left", cursor: addFilesPreview[idx]?.file_url ? 'pointer' : 'default' }} onClick={() => {if(addFilesPreview[idx]?.file_url){let id = addFilesPreview[idx]?.id; handleIndexChange(id == 0 ? idx : addFilesPreview[idx]?.id)}}}>
                            {addFilesPreview[idx]?.file_type?.includes('video') ?
                                <div style={{width:'5rem', height:'5rem', position:'relative'}}>
                                  <div  
                                    style={{
                                      position: 'absolute',
                                      opacity: 0.5, 
                                      top: '50%', 
                                      left: '50%', 
                                      transform: 'translateX(-50%) translateY(-50%)'
                                    }}
                                  >
                                    <Icon
                                      source={PlayCircleMajor}
                                      color="base"
                                    />
                                  </div>
                                  <video
                                      className="video"
                                      style={{width:'100%', height:'100%'}}
                                      src={addFilesPreview[idx]?.file_url}
                                  ></video>
                                </div>                      
                              : 
                                <Thumbnail size="large" source={addFilesPreview[idx]?.file_url} />}
                          </div>
                          <div
                            style={{
                              width: "100%",
                              marginLeft: "10px",
                              // maxHeight: "10px",
                            }}
                          >
                            <DropZone
                              // onDrop={handleDropZone}
                              onDropAccepted={(file) => sendImages(file, idx, addFilesPreview, addFilesPreview[idx]?.id)}
                            >
                             {addFilesSave[idx] != null ? <Stack alignment="center">
                                <Thumbnail
                                  size="small"
                                  alt={addFilesSave[idx]?.name}
                                  source={
                                    validImageTypes.indexOf(
                                      addFilesSave[idx]?.type
                                    ) > 0
                                      ? window.URL.createObjectURL(
                                          addFilesSave[idx]
                                        )
                                      : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
                                  }
                                />
                                <div>
                                  {addFilesSave[idx]?.name}{" "}
                                  <Caption>
                                    {addFilesSave[idx]?.type} bytes
                                  </Caption>
                                </div>
                              </Stack>
                              :
                              <DropZone.FileUpload />}
                            </DropZone>
                          </div>
                        </div>
                      <div style={{display: "flex"}}>
                        <Button onClick={(e) => handleDelete(e, addFilesPreview[idx]?.id, idx)}>
                          Delete
                        </Button>
                        <div
                          style={{marginLeft: "15px", display: "flex", justifyContent: "space-between", width: "100%"}}>
                          <div>
                            {addFilesPreview[idx].file_created_at && (
                              <>
                                <TextStyle variation="strong">Created At:</TextStyle>
                                <p>{addFilesPreview[idx].file_created_at}</p>
                              </>
                            )
                            }
                          </div>
                          <div>
                            {addFilesPreview[idx].file_updated_at &&
                              <>
                                <TextStyle variation="strong">Updated At:</TextStyle>
                                <p>{addFilesPreview[idx].file_updated_at}</p>
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    </FormLayout>
                  </div>
                )}
              </div>
            </>
          )}

          <TextField
            label="Note"
            value={noteValue}
            onChange={handleChangeNoteValue}
            multiline={4}
          />
        {(selected == "Post IPR") && <div style={{display:"flex", gap:"50px"}}>
        <input
          style={{width:"20%", height:"36px", border:"1px lightgray solid", borderRadius:"5px", marginRight:'20%'}}
            className="text-field"
              type="number"
              placeholder="Post IPR Step Number"
              value={stepNumber}
              onChange={handleStepNumberChange}
            />
         <div style={{display:"flex", gap:"50px"}}>
         {/* <Checkbox
            label="Post IPR Completed"
            checked={completedCheck}
            onChange={handleCompletedCheck}
          /> */}
          <Checkbox
          label="Post IPR Approved"
          checked={approvedCheck}
          onChange={handleApprovedCheck}
        />
         </div>
        </div>}
          {isOpen && imagesArray?.length && 
            <div className={imagesArray?.length == 1 ? 'basma-chat-lightbox' : ''}>
              <Lightbox 
                keyboardInteraction={imagesArray?.length > 1}
                startIndex={index}
                onClose={() => setIsOpen(false)}
                images={imagesArray}
                showNext={imagesArray.length > 1}
                showPrev={imagesArray.length > 1}
              />
            </div>
          }
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );
  function handleSave() {
    setPopupActive(true);
    const form_data = new FormData();
    imageKey1 && form_data.append("content", imageKey1);
    imageKeyCenterBite && form_data.append("center_teeth", imageKeyCenterBite);
    imageKeyLeftBite && form_data.append("left_teeth", imageKeyLeftBite);
    imageKeyRightBite && form_data.append("right_teeth", imageKeyRightBite);
    imageKeyUpperTeeth && form_data.append("upper_teeth", imageKeyUpperTeeth);
    imageKeyLowerTeeth && form_data.append("lower_teeth", imageKeyLowerTeeth);
    form_data.append('array_ipr_files', JSON.stringify([...addFilesKey, ...addFilesRedoKey]));
    noteValue && form_data.append("note", noteValue);
    selected && form_data.append("type", selected);
    (approvedCheck != null) && form_data.append("post_ipr_approved", approvedCheck);
    // (completedCheck != null) && form_data.append("post_ipr_completed", completedCheck);
    stepNumber && form_data.append("post_ipr_step_number", stepNumber);

    axios
      .post(`/admin/v1/users/${userId}/storage/${fileId}/update`, form_data, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        history.push("/admin/users/" + userId + "/dashboard");
      })
      .catch((err) => setPopupActive(false));
  }
  function handleDelete(e, id, index = null) {
    const imageID = e.currentTarget.id;
    if (imageID == "center_bite") {
      setImageKeyCenterBite("");
      setImagePreviewCenterBite("");
      setFilesCenterBite([]);
    }
    if (imageID == "left_bite") {
      setImageKeyLeftBite("");
      setImagePreviewLeftBite("");
      setFilesLeftBite([]);
    }
    if (imageID == "right_bite") {
      setFilesRightBite("");
      setImagePreviewRightBite("");
      setFilesRightBite([]);
    }
    if (imageID == "lower_teeth") {
      setImageKeyLowerTeeth("");
      setImagePreviewLowerTeeth("");
      setFilesLowerTeeth([]);
    }
    if (imageID == "upper_teeth") {
      setImageKeyUpperTeeth("");
      setImagePreviewUpperTeeth("");
      setFilesUpperTeeth([]);
    }
    if (imageID == "front_facing_no_smile") {
      const deleteFileKey = addFilesKey.filter((res)=> res.name != 'front_facing_no_smile');
      setAddFilesKey(deleteFileKey);
      setImagePreviewFrontFacingNoSmile("");
      setFrontFacingNoSmile([]);
    }
    if (imageID == "front_facing_big_smile") {
      const deleteFileKey2 = addFilesKey.filter((res)=> res.name != 'front_facing_big_smile');
      setAddFilesKey(deleteFileKey2);
      setImagePreviewFrontFacingBigSmile("");
      setFrontFacingBigSmile([]);
    }
    if (imageID == "profile_no_smile") {
      const deleteFileKey3 = addFilesKey.filter((res)=> res.name != 'profile_no_smile');
      setAddFilesKey(deleteFileKey3);
      setImagePreviewProfileNoSmile("");
      setProfileNoSmile([]);
    }
    
    
    if(id && id != 0){
      setPopupActive(true);
      axios
      .delete(`admin/v1/user_storage_file/delete/${id}`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setDeleted(true);
      })
      .catch((err) => console.log(err))
      .finally(()=> setPopupActive(false));
    }
  }
};


export default React.memo(EditFile);
