import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../history";
import ListSubPlans from "./ListSubPlans";
import { AppProvider } from "@shopify/polaris";
import AddSubPlan from "./AddSubPlan";
import EditSubPlan from "./EditSubPlan";

function MainSub2() {
  return (
    <AppProvider>
      <Router history={history}>
        <Switch>
          <Route
            exact
            path="/admin/plans/:id/payments"
            component={ListSubPlans}
          />
          <Route
            path="/admin/plans/:id/payments/create"
            component={AddSubPlan}
          />
          <Route path="/admin/plans/:id/payments/:payment_id" component={EditSubPlan} />
        </Switch>
      </Router>
    </AppProvider>
  );
}
export default MainSub2;
