import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@material-ui/core';
import axios from '../axios'
import Cookies from 'js-cookie';
import NavigationLayout from '../components/NavigationLayout'
import { ActionList, Button, Card, DisplayText, FormLayout, Layout, Page, Popover, TextStyle } from '@shopify/polaris';
import ChartComponent from './ChartComponent';
import NpsComponent from './NpsComponent';
import moment from 'moment';

function SurveyChart() {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({ startDate: "", endDate: "", careAgent:"",country:""});
  const { startDate, endDate, careAgent, country } = formData;
  useEffect(() => {
    getCharts()
  }, [])
  const getCharts = async () => {
    setLoading(true)
    let res = await axios.get(`admin/v1/get-chart?startDate=${startDate}&endDate=${endDate}&careAgent=${careAgent}&country=${country}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    });
    setData(res.data.data)
    setLoading(false)
  }
  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Page fullWidth>
      <FormLayout>
        <div style={{ display: "flex", margin: "30px auto", justifyContent: "center", gap: "30px",flexWrap:"wrap" }}>
          <div style={{ display: "grid" }}>
            <TextStyle variation="strong" htmlFor="start-date">Start Date:</TextStyle>
            <input
              disabled={loading}
              id="start-date"
              style={{ padding: "8px", borderRadius: "5px", width: "200px" }}
              type="date"
              value={startDate}
              onChange={onChange}
              name="startDate"
            />
          </div>
          <div style={{ display: "grid" }}>
            <TextStyle variation="strong" htmlFor="end-date">End Date:</TextStyle>
            <input
              disabled={loading}
              id="end-date"
              style={{ padding: "8px", borderRadius: "5px", width: "200px" }}
              type="date"
              value={endDate}
              onChange={onChange}
              name="endDate"
            />
          </div>
          <div style={{ display: "grid" }}>
            <TextStyle variation="strong" htmlFor="care-agent">Care Agent:</TextStyle>
            <select
              disabled={loading}
              style={{ padding: "8px", borderRadius: "5px", width: "200px" }}
              id="care-agent"
              value={careAgent}
              onChange={onChange}
              name="careAgent"
            >
              <option value="">-</option>
              <option value="43">Sandra Ali</option>
              <option value="44">Natalia Abou Mrad</option>
              <option value="2">Ayat Baalbaki</option>
              <option value="19">Kamal Ghemrawi</option>
            </select>
          </div>
          <div style={{ display: "grid" }}>
            <TextStyle variation="strong" htmlFor="country">Country:</TextStyle>
            <select
              disabled={loading}
              style={{ padding: "8px", borderRadius: "5px", width: "200px" }}
              id="country"
              value={country}
              onChange={onChange}
              name="country"
            >
              <option value="">Worldwide</option>
              <option value="LB">Lebanon</option>
              <option value="SA">Saudi Arabia</option>
              <option value="AE">United Arab Emirates</option>
              <option value="QA">Qatar</option>
              <option value="KW">Kuwait</option>
              <option value="BH">Bahrain</option>
              <option value="OM">Oman</option>
            </select>
          </div>
          <div style={{ display: 'flex', alignItems: "end" }}>
            <Button primary onClick={getCharts} disabled={loading}>Submit</Button>
          </div>

        </div>
        {typeof data === 'object' && data !== null && Object.keys(data).map((dat) => {
          return <Card sectioned title={dat}>
            <FormLayout>
              <Layout >
                {data[dat] && data[dat].length && data[dat].map((chartData) =>
                  chartData.nps ?
                    <NpsComponent name={chartData.name} score={chartData.tota_nps_score}
                      number_of_responses={chartData.number_of_responses} data={chartData.answers} />
                    :
                    <ChartComponent name={chartData.name} subText1={"Average score: " + chartData.average_score}
                      subText2={"Total responses: " + chartData.number_of_responses} data={chartData.answers} />
                )}
              </Layout>
            </FormLayout>
          </Card>
        })}
      </FormLayout>
    </Page>
  )
}

export default SurveyChart