import { useCallback } from 'react';
import { FormLayout, TextField } from '@shopify/polaris';
import { capitalizeWords } from '../util/helpers';

const AddTechnician = ({
  type,
  setTechnician,
  technician,
  addTechnician,
}) => {
  const handleChange = useCallback(
    (newValue) => setTechnician(newValue),
    []
  );
  return (
    <FormLayout>
      <TextField
        label={capitalizeWords(type)}
        value={technician}
        onChange={handleChange}
        labelAction={{
          content: 'Add new technician',
          onAction: addTechnician,
        }}
      />
    </FormLayout>
  );
};

export default AddTechnician;
