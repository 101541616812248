import React, { useCallback, useState, useEffect } from "react";
import {
  Button,
  Toast,
  Modal,
  Thumbnail,
  Caption,
  Layout,
  DropZone,
  Checkbox,
  Stack,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  Card,
  PageActions,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";

import {useLocation, useParams} from "react-router-dom";
import UserHeader from "../../../components/UserLayout/user-header";

function EditImpressionsAndPhotosStep1() {
  const { id } = useParams();
  let imageType = "";

  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

  const [step3UploadedBy, setStep3UploadedBy] = useState("");
  const [imageKeyCenterBite, setImageKeyCenterBite] = useState("");
  const [imageKeyLeftBite, setImageKeyLeftBite] = useState("");
  const [imageKeyRightBite, setImageKeyRightBite] = useState("");
  const [imageKeyUpperTeeth, setImageKeyUpperTeeth] = useState("");
  const [imageKeyLowerTeeth, setImageKeyLowerTeeth] = useState("");
  const [imageKeyFrontFacingNoSmile, setImageKeyFrontFacingNoSmile] = useState(
    ""
  );
  const [
    imageKeyFrontFacingBigSmile,
    setImageKeyFrontFacingBigSmile,
  ] = useState("");
  const [imageKeyRightSideProfile, setImageKeyRightSideProfile] = useState("");

  const [imagePreviewCenterBite, setImagePreviewCenterBite] = useState("");
  const [imagePreviewLeftBite, setImagePreviewLeftBite] = useState("");
  const [imagePreviewRightBite, setImagePreviewRightBite] = useState("");
  const [imagePreviewUpperTeeth, setImagePreviewUpperTeeth] = useState("");
  const [imagePreviewLowerTeeth, setImagePreviewLowerTeeth] = useState("");
  const [
    imagePreviewFrontFacingNoSmile,
    setImagePreviewFrontFacingNoSmile,
  ] = useState("");
  const [
    imagePreviewFrontFacingBigSmile,
    setImagePreviewFrontFacingBigSmile,
  ] = useState("");
  const [
    imagePreviewRightSideProfile,
    setImagePreviewRightSideProfile,
  ] = useState("");
  const [notApplicable, setNotApplicable] = useState({
    front_facing_big_smile_na: false,
    front_facing_no_smile_na: false,
    right_side_profile_no_smile_na: false,
  });

  let arrayOfKeys = [
    imageKeyLeftBite,
    imageKeyCenterBite,
    imageKeyRightBite,
    imageKeyUpperTeeth,
    imageKeyLowerTeeth,
    imageKeyFrontFacingNoSmile,
    imageKeyFrontFacingBigSmile,
    imageKeyRightSideProfile,
    notApplicable.front_facing_big_smile_na,
    notApplicable.front_facing_no_smile_na,
    notApplicable.right_side_profile_no_smile_na,
  ];
  let arrayOfStrings = [
    "left_bite",
    "center_bite",
    "right_bite",
    "upper_teeth",
    "lower_teeth",
    "front_facing_no_smile",
    "front_facing_big_smile",
    "right_side_profile_no_smile",
    "front_facing_big_smile_na",
    "front_facing_no_smile_na",
    "right_side_profile_no_smile_na",
  ];

  const [filesCenterBite, setFilesCenterBite] = useState([]);
  const handleDropZoneCenterBite = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesCenterBite((filesCenterBite) => [
        ...filesCenterBite,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadCenterBite = !filesCenterBite.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesCenterBite = filesCenterBite.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesCenterBite[filesCenterBite.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesCenterBite[filesCenterBite.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesCenterBite[filesCenterBite.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesCenterBite[filesCenterBite.length - 1].name}{" "}
        <Caption>
          {filesCenterBite[filesCenterBite.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageCenterBite = useCallback(
    (filesCenterBite) => {
      setPopupActive(true);
      setImagePreviewCenterBite(
        validImageTypes.indexOf(
          filesCenterBite[filesCenterBite.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              filesCenterBite[filesCenterBite.length - 1]
            )
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesCenterBite[filesCenterBite.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeyCenterBite(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesCenterBite[filesCenterBite.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesCenterBite]
  );

  const [filesLeftBite, setFilesLeftBite] = useState([]);
  const handleDropZoneLeftBite = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesLeftBite((filesLeftBite) => [...filesLeftBite, ...acceptedFiles]),
    []
  );
  const fileUploadLeftBite = !filesLeftBite.length && <DropZone.FileUpload />;
  let uploadedFilesLeftBite = filesLeftBite.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesLeftBite[filesLeftBite.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesLeftBite[filesLeftBite.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesLeftBite[filesLeftBite.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesLeftBite[filesLeftBite.length - 1].name}{" "}
        <Caption>{filesLeftBite[filesLeftBite.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImageLeftBite = useCallback(
    (filesLeftBite) => {
      setPopupActive(true);
      setImagePreviewLeftBite(
        validImageTypes.indexOf(filesLeftBite[filesLeftBite.length - 1].type) >
          0
          ? window.URL.createObjectURL(filesLeftBite[filesLeftBite.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesLeftBite[filesLeftBite.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeyLeftBite(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesLeftBite[filesLeftBite.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesLeftBite]
  );

  const [filesRightBite, setFilesRightBite] = useState([]);
  const handleDropZoneRightBite = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesRightBite((filesRightBite) => [
        ...filesRightBite,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadRightBite = !filesRightBite.length && <DropZone.FileUpload />;
  let uploadedFilesRightBite = filesRightBite.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesRightBite[filesRightBite.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesRightBite[filesRightBite.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesRightBite[filesRightBite.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesRightBite[filesRightBite.length - 1].name}{" "}
        <Caption>
          {filesRightBite[filesRightBite.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageRightBite = useCallback(
    (filesRightBite) => {
      setPopupActive(true);
      setImagePreviewRightBite(
        validImageTypes.indexOf(
          filesRightBite[filesRightBite.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              filesRightBite[filesRightBite.length - 1]
            )
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesRightBite[filesRightBite.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeyRightBite(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesRightBite[filesRightBite.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesRightBite]
  );

  const [filesUpperTeeth, setFilesUpperTeeth] = useState([]);
  const handleDropZoneUpperTeeth = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesUpperTeeth((filesUpperTeeth) => [
        ...filesUpperTeeth,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadUpperTeeth = !filesUpperTeeth.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesUpperTeeth = filesUpperTeeth.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesUpperTeeth[filesUpperTeeth.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesUpperTeeth[filesUpperTeeth.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesUpperTeeth[filesUpperTeeth.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesUpperTeeth[filesUpperTeeth.length - 1].name}{" "}
        <Caption>
          {filesUpperTeeth[filesUpperTeeth.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageUpperTeeth = useCallback(
    (filesUpperTeeth) => {
      setPopupActive(true);
      setImagePreviewUpperTeeth(
        validImageTypes.indexOf(
          filesUpperTeeth[filesUpperTeeth.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              filesUpperTeeth[filesUpperTeeth.length - 1]
            )
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesUpperTeeth[filesUpperTeeth.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeyUpperTeeth(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesUpperTeeth[filesUpperTeeth.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesUpperTeeth]
  );

  const [filesLowerTeeth, setFilesLowerTeeth] = useState([]);
  const handleDropZoneLowerTeeth = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesLowerTeeth((filesLowerTeeth) => [
        ...filesLowerTeeth,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadLowerTeeth = !filesLowerTeeth.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesLowerTeeth = filesLowerTeeth.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesLowerTeeth[filesLowerTeeth.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesLowerTeeth[filesLowerTeeth.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesLowerTeeth[filesLowerTeeth.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesLowerTeeth[filesLowerTeeth.length - 1].name}{" "}
        <Caption>
          {filesLowerTeeth[filesLowerTeeth.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageLowerTeeth = useCallback(
    (filesLowerTeeth) => {
      setPopupActive(true);
      setImagePreviewLowerTeeth(
        validImageTypes.indexOf(
          filesLowerTeeth[filesLowerTeeth.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              filesLowerTeeth[filesLowerTeeth.length - 1]
            )
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesLowerTeeth[filesLowerTeeth.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeyLowerTeeth(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesLowerTeeth[filesLowerTeeth.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesLowerTeeth]
  );

  const [filesFrontFacingNoSmile, setFilesFrontFacingNoSmile] = useState([]);
  const handleDropZoneFacingNoSmile = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesFrontFacingNoSmile((filesFrontFacingNoSmile) => [
        ...filesFrontFacingNoSmile,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadFrontFacingNoSmile = !filesFrontFacingNoSmile.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesFrontFacingNoSmile = filesFrontFacingNoSmile.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesFrontFacingNoSmile[filesFrontFacingNoSmile.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesFrontFacingNoSmile[filesFrontFacingNoSmile.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesFrontFacingNoSmile[filesFrontFacingNoSmile.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesFrontFacingNoSmile[filesFrontFacingNoSmile.length - 1].name}{" "}
        <Caption>
          {filesFrontFacingNoSmile[filesFrontFacingNoSmile.length - 1].type}{" "}
          bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageFrontFacingNoSmile = useCallback(
    (filesFrontFacingNoSmile) => {
      setPopupActive(true);
      setImagePreviewFrontFacingNoSmile(
        validImageTypes.indexOf(
          filesFrontFacingNoSmile[filesFrontFacingNoSmile.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              filesFrontFacingNoSmile[filesFrontFacingNoSmile.length - 1]
            )
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType =
        filesFrontFacingNoSmile[filesFrontFacingNoSmile.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeyFrontFacingNoSmile(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(
              url2,
              filesFrontFacingNoSmile[filesFrontFacingNoSmile.length - 1],
              {
                headers: {
                  "x-amz-acl": "public-read-write",
                  "Content-Type": imageType,
                },
              }
            )
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesFrontFacingNoSmile]
  );

  const [filesFrontFacingBigSmile, setFilesFrontFacingBigSmile] = useState([]);
  const handleDropZoneFacingBigSmile = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesFrontFacingBigSmile((filesFrontFacingBigSmile) => [
        ...filesFrontFacingBigSmile,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadFrontFacingBigSmile = !filesFrontFacingBigSmile.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesFrontFacingBigSmile = filesFrontFacingBigSmile.length >
    0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesFrontFacingBigSmile[filesFrontFacingBigSmile.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesFrontFacingBigSmile[filesFrontFacingBigSmile.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesFrontFacingBigSmile[filesFrontFacingBigSmile.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesFrontFacingBigSmile[filesFrontFacingBigSmile.length - 1].name}{" "}
        <Caption>
          {filesFrontFacingBigSmile[filesFrontFacingBigSmile.length - 1].type}{" "}
          bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageFrontFacingBigSmile = useCallback(
    (filesFrontFacingBigSmile) => {
      setPopupActive(true);
      setImagePreviewFrontFacingBigSmile(
        validImageTypes.indexOf(
          filesFrontFacingBigSmile[filesFrontFacingBigSmile.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              filesFrontFacingBigSmile[filesFrontFacingBigSmile.length - 1]
            )
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType =
        filesFrontFacingBigSmile[filesFrontFacingBigSmile.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeyFrontFacingBigSmile(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(
              url2,
              filesFrontFacingBigSmile[filesFrontFacingBigSmile.length - 1],
              {
                headers: {
                  "x-amz-acl": "public-read-write",
                  "Content-Type": imageType,
                },
              }
            )
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesFrontFacingBigSmile]
  );

  const [filesRightSideProfile, setFilesRightSideProfile] = useState([]);
  const handleDropZoneRightSideProfile = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesRightSideProfile((filesRightSideProfile) => [
        ...filesRightSideProfile,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadRightSideProfile = !filesRightSideProfile.length && (
    <DropZone.FileUpload />
  );
  let uploadedFilesRightSideProfile = filesRightSideProfile.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesRightSideProfile[filesRightSideProfile.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesRightSideProfile[filesRightSideProfile.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesRightSideProfile[filesRightSideProfile.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesRightSideProfile[filesRightSideProfile.length - 1].name}{" "}
        <Caption>
          {filesRightSideProfile[filesRightSideProfile.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageRightSideProfile = useCallback(
    (filesRightSideProfile) => {
      setPopupActive(true);
      setImagePreviewRightSideProfile(
        validImageTypes.indexOf(
          filesRightSideProfile[filesRightSideProfile.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              filesRightSideProfile[filesRightSideProfile.length - 1]
            )
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesRightSideProfile[filesRightSideProfile.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeyRightSideProfile(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(
              url2,
              filesRightSideProfile[filesRightSideProfile.length - 1],
              {
                headers: {
                  "x-amz-acl": "public-read-write",
                  "Content-Type": imageType,
                },
              }
            )
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesRightSideProfile]
  );

  useEffect(() => {
    // getData();
  }, []);

  function getData() {
    axios
      .get("admin/v1/pages/" + id, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {})
      .catch((err) => console.log(err));
  }

  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const toastMarkup = active ? (
    <Toast content="Image Deleted" onDismiss={toggleActive} />
  ) : null;
  const [isUploadingAtive, setIsUploadingAtive] = useState(false);
  const [popupActive, setPopupActive] = useState(false);

  const handleChangeNotApplicable = (prop) => {
    setNotApplicable(prev => ({...prev, [prop]: !prev[prop]}));
  }

  useEffect(() => {
    axios
      .get("admin/v1/users/" + id + "/impressions&photos", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        result.data.data.step3 && 
          result.data.data.step3?.uploaded_by_user?.full_name &&
            setStep3UploadedBy(result.data.data.step3.uploaded_by_user.full_name)
        result.data.data.step3 &&
          result.data.data.step3.center_bite_url &&
          setImagePreviewCenterBite(result.data.data.step3.center_bite_url);
        result.data.data.step3 &&
          result.data.data.step3.left_bite_url &&
          setImagePreviewLeftBite(result.data.data.step3.left_bite_url);
        result.data.data.step3 &&
          result.data.data.step3.right_bite_url &&
          setImagePreviewRightBite(result.data.data.step3.right_bite_url);
        result.data.data.step3 &&
          result.data.data.step3.upper_teeth_url &&
          setImagePreviewUpperTeeth(result.data.data.step3.upper_teeth_url);
        result.data.data.step3 &&
          result.data.data.step3.lower_teeth_url &&
          setImagePreviewLowerTeeth(result.data.data.step3.lower_teeth_url);
        result.data.data.step3 &&
          result.data.data.step3.front_facing_no_smile_url &&
          setImagePreviewFrontFacingNoSmile(
            result.data.data.step3.front_facing_no_smile_url
          );
          setNotApplicable({
            front_facing_big_smile_na: result.data.data.step3.front_facing_big_smile_na,
            front_facing_no_smile_na: result.data.data.step3.front_facing_no_smile_na,
            right_side_profile_no_smile_na: result.data.data.step3.right_side_profile_no_smile_na,
          });
        result.data.data.step3 &&
          result.data.data.step3.front_facing_big_smile_url &&
          setImagePreviewFrontFacingBigSmile(
            result.data.data.step3.front_facing_big_smile_url
          );
        result.data.data.step3 &&
          result.data.data.step3.right_side_profile_no_smile_url &&
          setImagePreviewRightSideProfile(
            result.data.data.step3.right_side_profile_no_smile_url
          );
      })
      .catch((err) => console.log(err));
  }, []);
  const location = useLocation();
  let linkState =
    {planType: "", clinicStatus: "", clinicName: "", status: "", userId: id, pageTitle: ""};
  if (location.state !== undefined) {
    const {planType, clinicStatus, clinicName, status, userId, pageTitle} = location.state;
    linkState = {planType, clinicStatus, clinicName, status, userId, pageTitle}
  }
  const { planType, clinicStatus, clinicName, status, userId, pageTitle} = linkState;
  return (
    <Page>
        <UserHeader pageTitle={pageTitle} status={status} userId={userId} clinicStatus={clinicStatus} clinicName={clinicName} planType={planType}/>
        <br/>
        <h1 className="page-header">Edit impressions and photos - Step 3</h1>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <FormLayout>
              <FormLayout.Group>
                <FormLayout>
                  <TextStyle variation="strong">Center Bite</TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail size="large" source={imagePreviewCenterBite} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                        // maxHeight: "10px",
                      }}
                    >
                      <DropZone
                        onDrop={handleDropZoneCenterBite}
                        onDropAccepted={sendImageCenterBite}
                      >
                        {uploadedFilesCenterBite}
                        {fileUploadCenterBite}
                      </DropZone>
                    </div>
                  </div>
                  <Button id="center_bite" onClick={(e) => handleDelete(e)}>
                    Delete
                  </Button>
                </FormLayout>
                <FormLayout>
                  <TextStyle variation="strong">Left Bite</TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail size="large" source={imagePreviewLeftBite} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                        // maxHeight: "10px",
                      }}
                    >
                      <DropZone
                        onDrop={handleDropZoneLeftBite}
                        onDropAccepted={sendImageLeftBite}
                      >
                        {uploadedFilesLeftBite}
                        {fileUploadLeftBite}
                      </DropZone>
                    </div>
                  </div>
                  <Button id="left_bite" onClick={(e) => handleDelete(e)}>
                    Delete
                  </Button>
                </FormLayout>
              </FormLayout.Group>

              <FormLayout.Group>
                <FormLayout>
                  <TextStyle variation="strong">Right Bite</TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail size="large" source={imagePreviewRightBite} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                        // maxHeight: "10px",
                      }}
                    >
                      <DropZone
                        onDrop={handleDropZoneRightBite}
                        onDropAccepted={sendImageRightBite}
                      >
                        {uploadedFilesRightBite}
                        {fileUploadRightBite}
                      </DropZone>
                    </div>
                  </div>
                  <Button id="right_bite" onClick={(e) => handleDelete(e)}>
                    Delete
                  </Button>
                </FormLayout>
                <FormLayout>
                  <TextStyle variation="strong">Upper Teeth</TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail size="large" source={imagePreviewUpperTeeth} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                        // maxHeight: "10px",
                      }}
                    >
                      <DropZone
                        onDrop={handleDropZoneUpperTeeth}
                        onDropAccepted={sendImageUpperTeeth}
                      >
                        {uploadedFilesUpperTeeth}
                        {fileUploadUpperTeeth}
                      </DropZone>
                    </div>
                  </div>
                  <Button id="upper_teeth" onClick={(e) => handleDelete(e)}>
                    Delete
                  </Button>
                </FormLayout>
              </FormLayout.Group>

              <FormLayout.Group>
                <FormLayout>
                  <TextStyle variation="strong">Lower Teeth</TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail size="large" source={imagePreviewLowerTeeth} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                        // maxHeight: "10px",
                      }}
                    >
                      <DropZone
                        onDrop={handleDropZoneLowerTeeth}
                        onDropAccepted={sendImageLowerTeeth}
                      >
                        {uploadedFilesLowerTeeth}
                        {fileUploadLowerTeeth}
                      </DropZone>
                    </div>
                  </div>
                  <Button id="lower_teeth" onClick={(e) => handleDelete(e)}>
                    Delete
                  </Button>
                </FormLayout>
                <FormLayout>
                  <TextStyle variation="strong">
                    Front Facing - No Smile
                  </TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail
                        size="large"
                        source={imagePreviewFrontFacingNoSmile}
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                        // maxHeight: "10px",
                      }}
                    >
                      <DropZone
                        onDrop={handleDropZoneFacingNoSmile}
                        onDropAccepted={sendImageFrontFacingNoSmile}
                      >
                        {uploadedFilesFrontFacingNoSmile}
                        {fileUploadFrontFacingNoSmile}
                      </DropZone>
                    </div>
                  </div>
                  <Button
                    id="front_facing_no_smile"
                    onClick={(e) => handleDelete(e)}
                  >
                    Delete
                  </Button>
                  <div className="rowDiv">
                    <Checkbox
                      label="Not available"
                      checked={notApplicable.front_facing_no_smile_na}
                      onChange={() => handleChangeNotApplicable('front_facing_no_smile_na')}
                    />
                  </div>
                </FormLayout>
              </FormLayout.Group>

              <FormLayout.Group>
                <FormLayout>
                  <TextStyle variation="strong">
                    Front Facing - Big Smile
                  </TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail
                        size="large"
                        source={imagePreviewFrontFacingBigSmile}
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                        // maxHeight: "10px",
                      }}
                    >
                      <DropZone
                        onDrop={handleDropZoneFacingBigSmile}
                        onDropAccepted={sendImageFrontFacingBigSmile}
                      >
                        {uploadedFilesFrontFacingBigSmile}
                        {fileUploadFrontFacingBigSmile}
                      </DropZone>
                    </div>
                  </div>
                  <Button
                    id="front_facing_big_smile"
                    onClick={(e) => handleDelete(e)}
                  >
                    Delete
                  </Button>
                  <div className="rowDiv">
                    <Checkbox
                      label="Not available"
                      checked={notApplicable.front_facing_big_smile_na}
                      onChange={() => handleChangeNotApplicable('front_facing_big_smile_na')}
                    />
                  </div>
                </FormLayout>
                <FormLayout>
                  <TextStyle variation="strong">
                    Right Side Profile - No Smile
                  </TextStyle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div style={{ float: "left" }}>
                      <Thumbnail
                        size="large"
                        source={imagePreviewRightSideProfile}
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                        // maxHeight: "10px",
                      }}
                    >
                      <DropZone
                        onDrop={handleDropZoneRightSideProfile}
                        onDropAccepted={sendImageRightSideProfile}
                      >
                        {uploadedFilesRightSideProfile}
                        {fileUploadRightSideProfile}
                      </DropZone>
                    </div>
                  </div>
                  <Button
                    id="right_side_profile_no_smile"
                    onClick={(e) => handleDelete(e)}
                  >
                    Delete
                  </Button>
                  <div className="rowDiv">
                    <Checkbox
                      label="Not available"
                      checked={notApplicable.right_side_profile_no_smile_na}
                      onChange={() => handleChangeNotApplicable('right_side_profile_no_smile_na')}
                    />
                  </div>
                </FormLayout>
              </FormLayout.Group>
              <TextStyle variation="strong">Uploaded By:</TextStyle>
              <TextStyle>{step3UploadedBy}</TextStyle>
            </FormLayout>
          </Card>
        </Layout.Section>
      </Layout>

      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
      {toastMarkup}
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );

  function handleDelete(e) {
    const imageID = e.currentTarget.id;
    e.persist();
    axios
      .delete(
        `admin/v1/users/${id}/impressions&photos/step3/image/${imageID}/delete`,
        {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      )
      .then((result) => {
        toggleActive();
        toastImage(id);
      })
      .catch((err) => console.log(err));
  }
  function toastImage(param) {
    switch (param) {
      case "center_bite":
        setImagePreviewCenterBite("");
        break;
      case "left_bite":
        setImagePreviewLeftBite("");
        break;
      case "right_bite":
        setImagePreviewRightBite("");
        break;
      case "upper_teeth":
        setImagePreviewUpperTeeth("");
        break;
      case "lower_teeth":
        setImagePreviewLowerTeeth("");
        break;
      case "front_facing_no_smile":
        setImagePreviewFrontFacingNoSmile("");
        break;
      case "front_facing_big_smile":
        setImagePreviewFrontFacingBigSmile("");
        break;
      case "right_side_profile_no_smile":
        setImagePreviewRightSideProfile("");
        break;
    }
  }
  function handleSave() {
    let bodyObj = {};
    for (var i = 0; i < arrayOfKeys.length; i++) {
      if (arrayOfKeys[i] !== "") {
        bodyObj[arrayOfStrings[i]] = arrayOfKeys[i];
      }
    }

    // bodyObj ={"left_bite":imageKeyLeftBite}
    //console.log('bodyObjtwo=',bodyObj)
    // const bodyObj = {
    //     "left_bite":imageKeyLeftBite,
    //     "center_bite":imageKeyCenterBite,
    //     "right_bite":imageKeyRightBite,
    //     "upper_teeth":imageKeyUpperTeeth,
    //     "lower_teeth":imageKeyLowerTeeth,
    //     "front_facing_no_smile":imageKeyFrontFacingNoSmile,
    //     "front_facing_big_smile":imageKeyFrontFacingBigSmile,
    //     "right_side_profile_no_smile":imageKeyRightSideProfile

    // };
    //console.log('bodyObj=', bodyObj)
    // axios.post('admin/v1/users/' + Cookie.get('userID') + '/impressions&photos/step2', bodyObj, {
    axios
      .post(`admin/v1/users/${id}/impressions&photos/step3`, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        history.push("/admin/users/" + id + "/dashboard");
        // axios.get('admin/v1/users/' + Cookie.get('userID') + '/impressions&photos', {
        //     headers: {
        //       'Authorization': 'Bearer ' + Cookie.get('token'),
        //     }
        //   })
        //     .then(result => {
        //       console.log('resultImpressionsDone=', result.data.data)

        //     })
        //     .catch(err => console.log(err))
      })
      .catch((err) => console.log(err));
  }
}
export default EditImpressionsAndPhotosStep1;
