import React, {  useState, useEffect } from "react";
import {
  Image,
  DisplayText,
  Card,

} from "@shopify/polaris";
import { Button as ButtonMui,Typography } from "@material-ui/core";

import emptyIcon from "../img/emptyList.svg";
import Cookie from "js-cookie";
import axios from "../axios";
import { CardActions, TextField } from "@mui/material";
import ExistingPlan from "./ExistingPlan";
import ExistingPaymentCurrency from "./ExistingPaymentCurrency";

function PaymentCurrency({plan_payment_id, items, setItems,isEmpty, setIsEmpty, success, setSuccess}) {
    
 
  return (
    <Card sectioned title="Prices per country">
      <CardActions style={{ float: "right" }}>
        <ButtonMui variant="contained" style={{backgroundColor:"rgb(0 128 96)",color:"white","padding":"1.5em 2em",}}
         onClick={()=>{console.log('clicked');setItems((prev)=>[...prev,{titlen:"", titlar:"", descen:"", descar:"", country:"",
          down_payment:0, monthly:0, monthly_pay:0 ,total:0 ,editable:true,disablePayment:false,id:null}]); setIsEmpty(false);}}>
            <Typography variant="h6" >Add New</Typography></ButtonMui>
          </CardActions>
          <table style={{display:"grid",width: "100%",overflowX:"auto",overflowY: "hidden" ,padding:"1em 0"}}>
              <tr style={{display: "flex",gap:'10px'}}>
              <th  className="plan-header" >Country</th>
              <th className="plan-header">Currency</th>
              <th className="plan-header">Local Price</th>
              <th className="plan-header"></th>
              </tr>
           { items.map((item) => (
               <ExistingPaymentCurrency plan_payment_id={plan_payment_id} id={item.id} currency={item.currency} country={item.country} local_price={item.local_price}  editable={item.editable} success={success} setSuccess={setSuccess} />
           ))
      }
            </table>
        {isEmpty ? (
        <div className="message-no-result">
          <div className="message-no-result-img">
            <Image src={emptyIcon}></Image>{" "}
          </div>
          <DisplayText size="small">No results found</DisplayText>
          <br />
        </div>
      ) : null}
      </Card>
  )
}

export default PaymentCurrency