import React, { Component, useEffect, useState, Fragment } from "react";
import * as T from "three";
import OrbitControls from "three-orbitcontrols";
import axios from 'axios'
import ThreeDViewerSPinner from "./ThreeDViewerLoader";
import Play from '../img/play.svg'
import Plat2 from "../assets/svg/playRed.svg";

let THREE = T
THREE.MTLLoader = function (manager) {

    this.manager = (manager !== undefined) ? manager : THREE.DefaultLoadingManager;

};

THREE.MTLLoader.prototype = {

    constructor: THREE.MTLLoader,
    
    load: function (url, onLoad, onProgress, onError) {

        var scope = this;

        var loader = new THREE.FileLoader(this.manager);
        loader.setPath(this.path);
        loader.load(url, function (text) {

            onLoad(scope.parse(text));

        }, onProgress, onError);

    },


    setPath: function (path) {

        this.path = path;

    },

    setTexturePath: function (path) {

        this.texturePath = path;

    },

    setBaseUrl: function (path) {

        this.setTexturePath(path);

    },

    setCrossOrigin: function (value) {

        this.crossOrigin = value;

    },

    setMaterialOptions: function (value) {

        this.materialOptions = value;

    },

    parse: function (text) {

        var lines = text.split('\n');
        var info = {};
        var delimiter_pattern = /\s+/;
        var materialsInfo = {};

        for (var i = 0; i < lines.length; i++) {

            var line = lines[i];
            line = line.trim();

            if (line.length === 0 || line.charAt(0) === '#') {
                continue;

            }

            var pos = line.indexOf(' ');

            var key = (pos >= 0) ? line.substring(0, pos) : line;
            key = key.toLowerCase();

            var value = (pos >= 0) ? line.substring(pos + 1) : '';
            value = value.trim();

            if (key === 'newmtl') {
                info = { name: value };
                materialsInfo[value] = info;

            } else if (info) {

                if (key === 'ka' || key === 'kd' || key === 'ks') {

                    var ss = value.split(delimiter_pattern, 3);
                    info[key] = [parseFloat(ss[0]), parseFloat(ss[1]), parseFloat(ss[2])];

                } else {

                    info[key] = value;

                }

            }

        }

        var materialCreator = new THREE.MTLLoader.MaterialCreator(this.texturePath || this.path, this.materialOptions);
        materialCreator.setCrossOrigin(this.crossOrigin);
        materialCreator.setManager(this.manager);
        materialCreator.setMaterials(materialsInfo);
        return materialCreator;

    }

};

THREE.MTLLoader.MaterialCreator = function (baseUrl, options) {

    this.baseUrl = baseUrl || '';
    this.options = options;
    this.materialsInfo = {};
    this.materials = {};
    this.materialsArray = [];
    this.nameLookup = {};

    this.side = (this.options && this.options.side) ? this.options.side : THREE.FrontSide;
    this.wrap = (this.options && this.options.wrap) ? this.options.wrap : THREE.RepeatWrapping;

};

THREE.MTLLoader.MaterialCreator.prototype = {

    constructor: THREE.MTLLoader.MaterialCreator,

    crossOrigin: 'Anonymous',

    setCrossOrigin: function (value) {

        this.crossOrigin = value;

    },

    setManager: function (value) {

        this.manager = value;

    },

    setMaterials: function (materialsInfo) {

        this.materialsInfo = this.convert(materialsInfo);
        this.materials = {};
        this.materialsArray = [];
        this.nameLookup = {};

    },

    convert: function (materialsInfo) {

        if (!this.options) return materialsInfo;

        var converted = {};

        for (var mn in materialsInfo) {


            var mat = materialsInfo[mn];

            var covmat = {};

            converted[mn] = covmat;

            for (var prop in mat) {

                var save = true;
                var value = mat[prop];
                var lprop = prop.toLowerCase();

                switch (lprop) {

                    case 'kd':
                    case 'ka':
                    case 'ks':


                        if (this.options && this.options.normalizeRGB) {

                            value = [value[0] / 255, value[1] / 255, value[2] / 255];

                        }

                        if (this.options && this.options.ignoreZeroRGBs) {

                            if (value[0] === 0 && value[1] === 0 && value[2] === 0) {

                                save = false;

                            }

                        }

                        break;

                    default:

                        break;

                }

                if (save) {

                    covmat[lprop] = value;

                }

            }

        }

        return converted;

    },

    preload: function () {

        for (var mn in this.materialsInfo) {

            this.create(mn);

        }

    },

    getIndex: function (materialName) {

        return this.nameLookup[materialName];

    },

    getAsArray: function () {

        var index = 0;

        for (var mn in this.materialsInfo) {

            this.materialsArray[index] = this.create(mn);
            this.nameLookup[mn] = index;
            index++;

        }

        return this.materialsArray;

    },

    create: function (materialName) {

        if (this.materials[materialName] === undefined) {

            this.createMaterial_(materialName);

        }

        return this.materials[materialName];

    },

    createMaterial_: function (materialName) {

        var scope = this;
        var mat = this.materialsInfo[materialName];
        var params = {

            name: materialName,
            side: this.side

        };

        function resolveURL(baseUrl, url) {

            if (typeof url !== 'string' || url === '')
                return '';
            if (/^https?:\/\//i.test(url)) return url;

            return baseUrl + url;

        }

        function setMapForType(mapType, value) {

            if (params[mapType]) return;

            var texParams = scope.getTextureParams(value, params);
            var map = scope.loadTexture(resolveURL(scope.baseUrl, texParams.url));

            map.repeat.copy(texParams.scale);
            map.offset.copy(texParams.offset);

            map.wrapS = scope.wrap;
            map.wrapT = scope.wrap;

            params[mapType] = map;

        }

        for (var prop in mat) {

            var value = mat[prop];
            var n;

            if (value === '') continue;

            switch (prop.toLowerCase()) {

                case 'kd':

                    params.color = new THREE.Color().fromArray(value);

                    break;

                case 'ks':

                    params.specular = new THREE.Color().fromArray(value);

                    break;

                case 'map_kd':
                    setMapForType("map", value);

                    break;

                case 'map_ks':
                    setMapForType("specularMap", value);

                    break;

                case 'norm':

                    setMapForType("normalMap", value);

                    break;

                case 'map_bump':
                case 'bump':

                    setMapForType("bumpMap", value);

                    break;

                case 'ns':
                    params.shininess = parseFloat(value);

                    break;

                case 'd':
                    n = parseFloat(value);

                    if (n < 1) {

                        params.opacity = n;
                        params.transparent = true;

                    }

                    break;

                case 'tr':
                    n = parseFloat(value);

                    if (n > 0) {

                        params.opacity = 1 - n;
                        params.transparent = true;

                    }

                    break;

                default:
                    break;

            }

        }

        this.materials[materialName] = new THREE.MeshPhongMaterial(params);
        return this.materials[materialName];

    },

    getTextureParams: function (value, matParams) {

        var texParams = {

            scale: new THREE.Vector2(1, 1),
            offset: new THREE.Vector2(0, 0)

        };

        var items = value.split(/\s+/);
        var pos;

        pos = items.indexOf('-bm');

        if (pos >= 0) {

            matParams.bumpScale = parseFloat(items[pos + 1]);
            items.splice(pos, 2);

        }

        pos = items.indexOf('-s');

        if (pos >= 0) {

            texParams.scale.set(parseFloat(items[pos + 1]), parseFloat(items[pos + 2]));
            items.splice(pos, 4);

        }

        pos = items.indexOf('-o');

        if (pos >= 0) {

            texParams.offset.set(parseFloat(items[pos + 1]), parseFloat(items[pos + 2]));
            items.splice(pos, 4);

        }

        texParams.url = items.join(' ').trim();
        return texParams;

    },

    loadTexture: function (url, mapping, onLoad, onProgress, onError) {

        var texture;
        var loader = THREE.Loader.Handlers.get(url);
        var manager = (this.manager !== undefined) ? this.manager : THREE.DefaultLoadingManager;

        if (loader === null) {

            loader = new THREE.TextureLoader(manager);

        }

        if (loader.setCrossOrigin) loader.setCrossOrigin(this.crossOrigin);
        texture = loader.load(url, onLoad, onProgress, onError);

        if (mapping !== undefined) texture.mapping = mapping;

        return texture;

    }

};

THREE.OBJLoader = (function () {
    var object_pattern = /^[og]\s*(.+)?/;
    var material_library_pattern = /^mtllib /;
    var material_use_pattern = /^usemtl /;
    var map_use_pattern = /^usemap /;

    function ParserState() {

        var state = {
            objects: [],
            object: {},

            vertices: [],
            normals: [],
            colors: [],
            uvs: [],

            materials: {},
            materialLibraries: [],

            startObject: function (name, fromDeclaration) {

                if (this.object && this.object.fromDeclaration === false) {

                    this.object.name = name;
                    this.object.fromDeclaration = (fromDeclaration !== false);
                    return;

                }

                var previousMaterial = (this.object && typeof this.object.currentMaterial === 'function' ? this.object.currentMaterial() : undefined);

                if (this.object && typeof this.object._finalize === 'function') {

                    this.object._finalize(true);

                }

                this.object = {
                    name: name || '',
                    fromDeclaration: (fromDeclaration !== false),

                    geometry: {
                        vertices: [],
                        normals: [],
                        colors: [],
                        uvs: []
                    },
                    materials: [],
                    smooth: true,

                    startMaterial: function (name, libraries) {

                        var previous = this._finalize(false);
                        if (previous && (previous.inherited || previous.groupCount <= 0)) {

                            this.materials.splice(previous.index, 1);

                        }

                        var material = {
                            index: this.materials.length,
                            name: name || '',
                            mtllib: (Array.isArray(libraries) && libraries.length > 0 ? libraries[libraries.length - 1] : ''),
                            smooth: (previous !== undefined ? previous.smooth : this.smooth),
                            groupStart: (previous !== undefined ? previous.groupEnd : 0),
                            groupEnd: - 1,
                            groupCount: - 1,
                            inherited: false,

                            clone: function (index) {

                                var cloned = {
                                    index: (typeof index === 'number' ? index : this.index),
                                    name: this.name,
                                    mtllib: this.mtllib,
                                    smooth: this.smooth,
                                    groupStart: 0,
                                    groupEnd: - 1,
                                    groupCount: - 1,
                                    inherited: false
                                };
                                cloned.clone = this.clone.bind(cloned);
                                return cloned;

                            }
                        };

                        this.materials.push(material);

                        return material;

                    },

                    currentMaterial: function () {

                        if (this.materials.length > 0) {

                            return this.materials[this.materials.length - 1];

                        }

                        return undefined;

                    },

                    _finalize: function (end) {

                        var lastMultiMaterial = this.currentMaterial();
                        if (lastMultiMaterial && lastMultiMaterial.groupEnd === - 1) {

                            lastMultiMaterial.groupEnd = this.geometry.vertices.length / 3;
                            lastMultiMaterial.groupCount = lastMultiMaterial.groupEnd - lastMultiMaterial.groupStart;
                            lastMultiMaterial.inherited = false;

                        }

                        if (end && this.materials.length > 1) {

                            for (var mi = this.materials.length - 1; mi >= 0; mi--) {

                                if (this.materials[mi].groupCount <= 0) {

                                    this.materials.splice(mi, 1);

                                }

                            }

                        }

                        if (end && this.materials.length === 0) {

                            this.materials.push({
                                name: '',
                                smooth: this.smooth
                            });

                        }

                        return lastMultiMaterial;

                    }
                };
                if (previousMaterial && previousMaterial.name && typeof previousMaterial.clone === 'function') {

                    var declared = previousMaterial.clone(0);
                    declared.inherited = true;
                    this.object.materials.push(declared);

                }

                this.objects.push(this.object);

            },

            finalize: function () {

                if (this.object && typeof this.object._finalize === 'function') {

                    this.object._finalize(true);

                }

            },

            parseVertexIndex: function (value, len) {

                var index = parseInt(value, 10);
                return (index >= 0 ? index - 1 : index + len / 3) * 3;

            },

            parseNormalIndex: function (value, len) {

                var index = parseInt(value, 10);
                return (index >= 0 ? index - 1 : index + len / 3) * 3;

            },

            parseUVIndex: function (value, len) {

                var index = parseInt(value, 10);
                return (index >= 0 ? index - 1 : index + len / 2) * 2;

            },

            addVertex: function (a, b, c) {

                var src = this.vertices;
                var dst = this.object.geometry.vertices;

                dst.push(src[a + 0], src[a + 1], src[a + 2]);
                dst.push(src[b + 0], src[b + 1], src[b + 2]);
                dst.push(src[c + 0], src[c + 1], src[c + 2]);

            },

            addVertexPoint: function (a) {

                var src = this.vertices;
                var dst = this.object.geometry.vertices;

                dst.push(src[a + 0], src[a + 1], src[a + 2]);

            },

            addVertexLine: function (a) {

                var src = this.vertices;
                var dst = this.object.geometry.vertices;

                dst.push(src[a + 0], src[a + 1], src[a + 2]);

            },

            addNormal: function (a, b, c) {

                var src = this.normals;
                var dst = this.object.geometry.normals;

                dst.push(src[a + 0], src[a + 1], src[a + 2]);
                dst.push(src[b + 0], src[b + 1], src[b + 2]);
                dst.push(src[c + 0], src[c + 1], src[c + 2]);

            },

            addColor: function (a, b, c) {

                var src = this.colors;
                var dst = this.object.geometry.colors;

                dst.push(src[a + 0], src[a + 1], src[a + 2]);
                dst.push(src[b + 0], src[b + 1], src[b + 2]);
                dst.push(src[c + 0], src[c + 1], src[c + 2]);

            },

            addUV: function (a, b, c) {

                var src = this.uvs;
                var dst = this.object.geometry.uvs;

                dst.push(src[a + 0], src[a + 1]);
                dst.push(src[b + 0], src[b + 1]);
                dst.push(src[c + 0], src[c + 1]);

            },

            addUVLine: function (a) {

                var src = this.uvs;
                var dst = this.object.geometry.uvs;

                dst.push(src[a + 0], src[a + 1]);

            },

            addFace: function (a, b, c, ua, ub, uc, na, nb, nc) {

                var vLen = this.vertices.length;

                var ia = this.parseVertexIndex(a, vLen);
                var ib = this.parseVertexIndex(b, vLen);
                var ic = this.parseVertexIndex(c, vLen);

                this.addVertex(ia, ib, ic);

                if (this.colors.length > 0) {

                    this.addColor(ia, ib, ic);

                }

                if (ua !== undefined && ua !== '') {

                    var uvLen = this.uvs.length;
                    ia = this.parseUVIndex(ua, uvLen);
                    ib = this.parseUVIndex(ub, uvLen);
                    ic = this.parseUVIndex(uc, uvLen);
                    this.addUV(ia, ib, ic);

                }

                if (na !== undefined && na !== '') {
                    var nLen = this.normals.length;
                    ia = this.parseNormalIndex(na, nLen);

                    ib = na === nb ? ia : this.parseNormalIndex(nb, nLen);
                    ic = na === nc ? ia : this.parseNormalIndex(nc, nLen);

                    this.addNormal(ia, ib, ic);

                }

            },

            addPointGeometry: function (vertices) {

                this.object.geometry.type = 'Points';

                var vLen = this.vertices.length;

                for (var vi = 0, l = vertices.length; vi < l; vi++) {

                    this.addVertexPoint(this.parseVertexIndex(vertices[vi], vLen));

                }

            },

            addLineGeometry: function (vertices, uvs) {

                this.object.geometry.type = 'Line';

                var vLen = this.vertices.length;
                var uvLen = this.uvs.length;

                for (var vi = 0, l = vertices.length; vi < l; vi++) {

                    this.addVertexLine(this.parseVertexIndex(vertices[vi], vLen));

                }

                for (var uvi = 0, l = uvs.length; uvi < l; uvi++) {

                    this.addUVLine(this.parseUVIndex(uvs[uvi], uvLen));

                }

            }

        };

        state.startObject('', false);

        return state;

    }

    //

    function OBJLoader(manager) {

        THREE.Loader.call(this, manager);

        this.materials = null;

    }

    OBJLoader.prototype = Object.assign(Object.create(THREE.Loader.prototype), {

        constructor: OBJLoader,

        load: function (url, onLoad, onProgress, onError) {

            var scope = this;

            var loader = new THREE.FileLoader(scope.manager);
            loader.setPath(this.path);
            loader.load(url, function (text) {

                onLoad(scope.parse(text));

            }, onProgress, onError);

        },

        setMaterials: function (materials) {

            this.materials = materials;

            return this;

        },

        parse: function (text) {

            var state = new ParserState();

            if (text.indexOf('\r\n') !== - 1) {
                text = text.replace(/\r\n/g, '\n');

            }

            if (text.indexOf('\\\n') !== - 1) {
                text = text.replace(/\\\n/g, '');

            }

            var lines = text.split('\n');
            var line = '', lineFirstChar = '';
            var lineLength = 0;
            var result = [];

            var trimLeft = (typeof ''.trimLeft === 'function');

            for (var i = 0, l = lines.length; i < l; i++) {

                line = lines[i];

                line = trimLeft ? line.trimLeft() : line.trim();

                lineLength = line.length;

                if (lineLength === 0) continue;

                lineFirstChar = line.charAt(0);

                if (lineFirstChar === '#') continue;

                if (lineFirstChar === 'v') {

                    var data = line.split(/\s+/);

                    switch (data[0]) {

                        case 'v':
                            state.vertices.push(
                                parseFloat(data[1]),
                                parseFloat(data[2]),
                                parseFloat(data[3])
                            );
                            if (data.length >= 7) {

                                state.colors.push(
                                    parseFloat(data[4]),
                                    parseFloat(data[5]),
                                    parseFloat(data[6])

                                );

                            }
                            break;
                        case 'vn':
                            state.normals.push(
                                parseFloat(data[1]),
                                parseFloat(data[2]),
                                parseFloat(data[3])
                            );
                            break;
                        case 'vt':
                            state.uvs.push(
                                parseFloat(data[1]),
                                parseFloat(data[2])
                            );
                            break;

                    }

                } else if (lineFirstChar === 'f') {

                    var lineData = line.substr(1).trim();
                    var vertexData = lineData.split(/\s+/);
                    var faceVertices = [];
                    for (var j = 0, jl = vertexData.length; j < jl; j++) {

                        var vertex = vertexData[j];

                        if (vertex.length > 0) {

                            var vertexParts = vertex.split('/');
                            faceVertices.push(vertexParts);

                        }

                    }
                    var v1 = faceVertices[0];

                    for (var j = 1, jl = faceVertices.length - 1; j < jl; j++) {

                        var v2 = faceVertices[j];
                        var v3 = faceVertices[j + 1];

                        state.addFace(
                            v1[0], v2[0], v3[0],
                            v1[1], v2[1], v3[1],
                            v1[2], v2[2], v3[2]
                        );

                    }

                } else if (lineFirstChar === 'l') {

                    var lineParts = line.substring(1).trim().split(" ");
                    var lineVertices = [], lineUVs = [];

                    if (line.indexOf("/") === - 1) {

                        lineVertices = lineParts;

                    } else {

                        for (var li = 0, llen = lineParts.length; li < llen; li++) {

                            var parts = lineParts[li].split("/");

                            if (parts[0] !== "") lineVertices.push(parts[0]);
                            if (parts[1] !== "") lineUVs.push(parts[1]);

                        }

                    }
                    state.addLineGeometry(lineVertices, lineUVs);

                } else if (lineFirstChar === 'p') {
                    var lineData = line.substr(1).trim();
                    var pointData = lineData.split(" ");
                    state.addPointGeometry(pointData);
                } else if ((result = object_pattern.exec(line)) !== null) {
                    var name = (" " + result[0].substr(1).trim()).substr(1);

                    state.startObject(name);

                } else if (material_use_pattern.test(line)) {
                    state.object.startMaterial(line.substring(7).trim(), state.materialLibraries);
                } else if (material_library_pattern.test(line)) {
                    state.materialLibraries.push(line.substring(7).trim());

                } else if (map_use_pattern.test(line)) {
                    console.warn('THREE.OBJLoader: Rendering identifier "usemap" not supported. Textures must be defined in MTL files.');

                } else if (lineFirstChar === 's') {

                    result = line.split(' ');
                    if (result.length > 1) {

                        var value = result[1].trim().toLowerCase();
                        state.object.smooth = (value !== '0' && value !== 'off');

                    } else {

                        state.object.smooth = true;

                    }
                    var material = state.object.currentMaterial();
                    if (material) material.smooth = state.object.smooth;

                } else {

                    if (line === '\0') continue;

                    console.warn('THREE.OBJLoader: Unexpected line: "' + line + '"');

                }

            }

            state.finalize();

            var container = new THREE.Group();
            container.materialLibraries = [].concat(state.materialLibraries);

            for (var i = 0, l = state.objects.length; i < l; i++) {

                var object = state.objects[i];
                var geometry = object.geometry;
                var materials = object.materials;
                var isLine = (geometry.type === 'Line');
                var isPoints = (geometry.type === 'Points');
                var hasVertexColors = false;

                if (geometry.vertices.length === 0) continue;

                var buffergeometry = new THREE.BufferGeometry();

                buffergeometry.setAttribute('position', new THREE.Float32BufferAttribute(geometry.vertices, 3));

                if (geometry.normals.length > 0) {

                    buffergeometry.setAttribute('normal', new THREE.Float32BufferAttribute(geometry.normals, 3));

                } else {

                    buffergeometry.computeVertexNormals();

                }

                if (geometry.colors.length > 0) {

                    hasVertexColors = true;
                    buffergeometry.setAttribute('color', new THREE.Float32BufferAttribute(geometry.colors, 3));

                }

                if (geometry.uvs.length > 0) {

                    buffergeometry.setAttribute('uv', new THREE.Float32BufferAttribute(geometry.uvs, 2));

                }

                var createdMaterials = [];

                for (var mi = 0, miLen = materials.length; mi < miLen; mi++) {

                    var sourceMaterial = materials[mi];
                    var materialHash = sourceMaterial.name + '_' + sourceMaterial.smooth + '_' + hasVertexColors;
                    var material = state.materials[materialHash];

                    if (this.materials !== null) {

                        material = this.materials.create(sourceMaterial.name);

                        if (isLine && material && !(material instanceof THREE.LineBasicMaterial)) {

                            var materialLine = new THREE.LineBasicMaterial();
                            THREE.Material.prototype.copy.call(materialLine, material);
                            materialLine.color.copy(material.color);
                            material = materialLine;

                        } else if (isPoints && material && !(material instanceof THREE.PointsMaterial)) {

                            var materialPoints = new THREE.PointsMaterial({ size: 10, sizeAttenuation: false });
                            THREE.Material.prototype.copy.call(materialPoints, material);
                            materialPoints.color.copy(material.color);
                            materialPoints.map = material.map;
                            material = materialPoints;

                        }

                    }

                    if (material === undefined) {

                        if (isLine) {

                            material = new THREE.LineBasicMaterial();

                        } else if (isPoints) {

                            material = new THREE.PointsMaterial({ size: 1, sizeAttenuation: false });

                        } else {

                            material = new THREE.MeshPhongMaterial();

                        }

                        material.name = sourceMaterial.name;
                        material.flatShading = sourceMaterial.smooth ? false : true;
                        material.vertexColors = hasVertexColors;

                        state.materials[materialHash] = material;

                    }

                    createdMaterials.push(material);

                }

                var mesh;

                if (createdMaterials.length > 1) {

                    for (var mi = 0, miLen = materials.length; mi < miLen; mi++) {

                        var sourceMaterial = materials[mi];
                        buffergeometry.addGroup(sourceMaterial.groupStart, sourceMaterial.groupCount, mi);

                    }

                    if (isLine) {

                        mesh = new THREE.LineSegments(buffergeometry, createdMaterials);

                    } else if (isPoints) {

                        mesh = new THREE.Points(buffergeometry, createdMaterials);

                    } else {

                        mesh = new THREE.Mesh(buffergeometry, createdMaterials);

                    }

                } else {

                    if (isLine) {

                        mesh = new THREE.LineSegments(buffergeometry, createdMaterials[0]);

                    } else if (isPoints) {

                        mesh = new THREE.Points(buffergeometry, createdMaterials[0]);

                    } else {

                        mesh = new THREE.Mesh(buffergeometry, createdMaterials[0]);

                    }

                }

                mesh.name = object.name;

                container.add(mesh);

            }

            return container;

        }

    });

    return OBJLoader;

})();

var scene = null
var camera = null
var renderer = null
var controls = null
let upperLight = null



const ThreeScene = ({ models, t, responsive = false, bgColor = 0xF5F5F5 }) => {

    const [selectedIndex, setSelectedIndex] = useState(0)
    const [prevIndex, setPrevIndex] = useState(null)
    const [currentProgressValue, setCurrentProgressValue] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const [isUpper, setIsUpper] = useState(true)
    const [isLower, setIsLower] = useState(true)
    const [lowerUpperLoaded, setlowerUpperLoaded] = useState({
        lower_loaded: false,
        upper_loaded: false
    })
    const minZoomDistance = 80;
    const maxZoomDistance = 150;
    const defaultZoomDistance = 120;

    useEffect(() => {
        scene = new THREE.Scene();
        scene.background = new THREE.Color(bgColor);
        camera = new THREE.PerspectiveCamera(40, 3 / 2, 0.1, 1000);
        camera.position.set(0, 0, defaultZoomDistance);
        renderer = new THREE.WebGLRenderer();
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.shadowMap.enabled = true;
        document.body.appendChild(renderer.domElement);
        controls = new THREE.OrbitControls(camera, renderer.domElement);
        controls.enablePan = false;
        controls.minDistance = minZoomDistance;
        controls.maxDistance = maxZoomDistance;
        controls.enableDamping = true;
        controls.dampingFactor = 0.15;
        controls.enableZoom = true;
        controls.rotateSpeed = 0.15;
        let p1=new THREE.DirectionalLight(new THREE.Color('hsl(0, 0%, 50%)'));
        p1.intensity=0.8;
        p1.position.set(0,20,maxZoomDistance)
        let p2=new THREE.DirectionalLight(new THREE.Color('hsl(0, 0%, 50%)'));
        p2.intensity=0.8;
        p2.position.set(0,20,-80)
        let p3=new THREE.DirectionalLight(new THREE.Color('hsl(0, 0%, 50%)'));
        p3.intensity=0.4;
        p3.position.set(300,20,0)//right
        let p5=new THREE.DirectionalLight(new THREE.Color('hsl(0, 0%, 50%)'));
        p5.intensity=0.4;
        p5.position.set(-300,20,0)
        let p4=new THREE.DirectionalLight(new THREE.Color('hsl(0, 0%, 50%)'));
        p4.intensity=0.6;
        p4.position.set(0,-100,-20)
        var ambientlight = new THREE.AmbientLight(new THREE.Color('hsl(0, 0%, 50%)'), 0.9);

        scene.add(ambientlight);
        scene.add(p1);
        scene.add(p2);
        scene.add(p3);
        scene.add(p5);
        scene.add(p4);
        var container = document.getElementById('canvas');
        container.appendChild(renderer.domElement);
    
        var animate = function () {
            renderer.render(scene, camera);
            requestAnimationFrame(animate);
            controls.update();
            renderer.render(scene, camera);
            renderer.setClearColor(0xffffff);
            var newWidth;
            if (window.innerWidth > 1400) {
                newWidth = 900;
            }
            else {
             newWidth = (window.innerWidth/2)+125;
            }
            renderer.setSize(newWidth, newWidth * (2 / 3));
            // renderer.setSize(newWidth, newWidth * (2 / 3));
        };

        animate();
    }, [])

    let upper_index = 0;
    let lower_index = 0;


    useEffect(() => {
        if (Math.ceil(currentProgressValue) >= 100) {
            setIsLoading(false)
            setTimeout(function () {
                var select_First_Upper_Object = scene.getObjectByName(`upper_0`);
                var select_First_Lower_Object = scene.getObjectByName(`lower_0`);
                select_First_Upper_Object.visible = true
                select_First_Lower_Object.visible = true
            }, 1000);
        }
    }, [currentProgressValue])


    useEffect(() => {
        if (models) {
            setIsLoading(true)
            var objLoader = new THREE.OBJLoader();
            var mtlLoader = new THREE.MTLLoader();

            function loadUpperFiles() {
                if (upper_index > models.length - 1) {
                    console.log("upper", lowerUpperLoaded)
                    setlowerUpperLoaded(prevState => {
                        return { ...prevState, upper_loaded: true };
                    });
                    return;
                };
                mtlLoader.load(models[upper_index].upper.mtl,
                    function (materials) {
                        materials.preload();
                        objLoader.setMaterials(materials);
                        objLoader.load(models[upper_index].upper.obj,
                            function (object) {
                                object.traverse(node => {
                                    if ('geometry' in node) {
                                        const tempGeometry = new THREE.Geometry().fromBufferGeometry(node.geometry);
                                        tempGeometry.mergeVertices();
                                        tempGeometry.computeVertexNormals();
                                        node.geometry = new THREE.BufferGeometry().fromGeometry(tempGeometry);
                                    }
                                    if (node instanceof THREE.Mesh) {
                                        node.material.forEach(element => {
                                            element.shininess = 50;
                                        });
                                    }
                                })
                                object.name = `upper_${upper_index}`;
                                scene.add(object);
                                object.position.y -= 0;
                                object.position.x -= 0;
                                object.visible = false;
                                upper_index++;
                                console.log(object)
                                loadUpperFiles();
                            },
                            function (xhr) {
                                let currentValue = currentProgressValue
                                let UpperProgress = ((xhr.loaded / xhr.total * 100) * (100 / models.length)) / 100
                                console.log(UpperProgress)

                                if (UpperProgress === (100 / models.length)) {
                                    setCurrentProgressValue(prevState => {
                                        console.log(prevState)
                                        return (prevState + UpperProgress);
                                    });
                                }
                            }
                        );
                    });

            }

            function loadLowerFiles() {
                if (lower_index > models.length - 1) {
                    console.log("lower", lowerUpperLoaded)
                    setlowerUpperLoaded(prevState => {
                        return { ...prevState, lower_loaded: true };
                    });
                    return;
                };;
                mtlLoader.load(models[lower_index].lower.mtl,
                    function (materials) {
                        materials.preload();
                        objLoader.setMaterials(materials);
                        objLoader.load(models[lower_index].lower.obj,
                            function (object) {
                                object.traverse(node => {
                                    if ('geometry' in node) {
                                        const tempGeometry = new THREE.Geometry().fromBufferGeometry(node.geometry);
                                        tempGeometry.mergeVertices();
                                        tempGeometry.computeVertexNormals();
                                        node.geometry = new THREE.BufferGeometry().fromGeometry(tempGeometry);
                                    }
                                    if (node instanceof THREE.Mesh) {
                                        node.material.forEach(element => {
                                            element.shininess = 50;
                                        });
                                    }
                                })
                                object.name = `lower_${lower_index}`;
                                scene.add(object);
                                object.position.y -= 0;
                                object.position.x -= 0;
                                object.visible = false;
                                lower_index++;
                                loadLowerFiles();
                            },
                            function (xhr) {
                                let LowerProgress = Math.floor(((xhr.loaded / xhr.total * 100) * lower_index + 1) / models.length)
                            }
                        );

                    });
            }

            loadUpperFiles();
            loadLowerFiles();
        }
    }, [models])

    const FrontBtnHandler = () => {
        camera.position.set(0, 0, maxZoomDistance);
        scene.remove(upperLight);
        setIsLower(true)
        setIsUpper(true)
        var selected_Upper_Object = scene.getObjectByName(`upper_0`);
        selected_Upper_Object.visible = true
        var selected_Lower_Object = scene.getObjectByName(`lower_0`);
        selected_Lower_Object.visible = true
        controls.reset();
    }

    const LowerBtnHandler = () => {
        camera.position.set(0, 0, maxZoomDistance);
        scene.remove(upperLight);
        setIsUpper(false)
        setIsLower(true)

        for (let i = 0; i < models.length; i++) {
            scene.getObjectByName(`upper_${i}`).visible = false
            scene.getObjectByName(`lower_${i}`).visible = false
        }

        var selected_Upper_Object = scene.getObjectByName(`upper_${selectedIndex}`);
        selected_Upper_Object.visible = false
        var selected_Lower_Object = scene.getObjectByName(`lower_${selectedIndex}`);
        selected_Lower_Object.visible = true
        camera.position.x = 0
        camera.position.y = maxZoomDistance
        camera.position.z = 0
        camera.lookAt(scene.position);
        renderer.render(scene, camera);
    }

    const UpperBtnHandler = () => {
        camera.position.set(0, 0, maxZoomDistance);
        setIsUpper(true)
        setIsLower(false)

        for (let i = 0; i < models.length; i++) {
            scene.getObjectByName(`upper_${i}`).visible = false
            scene.getObjectByName(`lower_${i}`).visible = false
        }

        var selected_Upper_Object = scene.getObjectByName(`upper_${selectedIndex}`);
        selected_Upper_Object.visible = true
        var selected_Lower_Object = scene.getObjectByName(`lower_${selectedIndex}`);
        selected_Lower_Object.visible = false
        camera.position.x = 0
        camera.position.y = -maxZoomDistance
        camera.position.z = 0
        camera.lookAt(scene.position);
        renderer.render(scene, camera);
    }
    
    const LeftBtnHandler = () => {
        camera.position.set(0, 0, maxZoomDistance);
        scene.remove(upperLight);
        controls.reset();
        var selected_Upper_Object = scene.getObjectByName(`upper_${selectedIndex}`);
        selected_Upper_Object.visible = true
        var selected_Lower_Object = scene.getObjectByName(`lower_${selectedIndex}`);
        selected_Lower_Object.visible = true
        camera.position.x = 300;
        camera.position.z = maxZoomDistance;
        camera.lookAt(scene.position);
        renderer.render(scene, camera);
    }

    const RightBtnHandler = () => {
        camera.position.set(0, 0, maxZoomDistance);
        scene.remove(upperLight);
        controls.reset();
        var selected_Upper_Object = scene.getObjectByName(`upper_${selectedIndex}`);
        selected_Upper_Object.visible = true
        var selected_Lower_Object = scene.getObjectByName(`lower_${selectedIndex}`);
        selected_Lower_Object.visible = true
        camera.position.x = -300;
        camera.position.z = maxZoomDistance;
        camera.lookAt(scene.position);
        renderer.render(scene, camera);
    }

    useEffect(() => {
        if (prevIndex !== null && prevIndex != selectedIndex) {
            if (isUpper && isLower) {
                var unselected_Upper_Object = scene.getObjectByName(`upper_${prevIndex}`);
                unselected_Upper_Object.visible = false
                var unselected_Lower_Object = scene.getObjectByName(`lower_${prevIndex}`);
                unselected_Lower_Object.visible = false

                var selected_Upper_Object = scene.getObjectByName(`upper_${selectedIndex}`);
                selected_Upper_Object.visible = true
                var selected_Lower_Object = scene.getObjectByName(`lower_${selectedIndex}`);
                selected_Lower_Object.visible = true
            }
            else if (isUpper && !isLower) {
                var unselected_Upper_Object = scene.getObjectByName(`upper_${prevIndex}`);
                unselected_Upper_Object.visible = false
                var unselected_Lower_Object = scene.getObjectByName(`lower_${prevIndex}`);
                unselected_Lower_Object.visible = false

                var selected_Upper_Object = scene.getObjectByName(`upper_${selectedIndex}`);
                selected_Upper_Object.visible = true
                var selected_Lower_Object = scene.getObjectByName(`lower_${selectedIndex}`);
                selected_Lower_Object.visible = false
            }
            else if (!isUpper && isLower) {
                var unselected_Upper_Object = scene.getObjectByName(`upper_${prevIndex}`);
                unselected_Upper_Object.visible = false
                var unselected_Lower_Object = scene.getObjectByName(`lower_${prevIndex}`);
                unselected_Lower_Object.visible = false

                var selected_Upper_Object = scene.getObjectByName(`upper_${selectedIndex}`);
                selected_Upper_Object.visible = false
                var selected_Lower_Object = scene.getObjectByName(`lower_${selectedIndex}`);
                selected_Lower_Object.visible = true
            }
        }
    }, [prevIndex, selectedIndex])

    const NextStepHandler = (modelItem, index) => {
        setPrevIndex(selectedIndex)
        setSelectedIndex(index)
    }

    const PlayBtnHandler = () => {
        setSelectedIndex(0)
        if (isLower && isUpper) {
            var select_First_Upper_Object = scene.getObjectByName(`upper_0`);
            var select_First_Lower_Object = scene.getObjectByName(`lower_0`);
            select_First_Upper_Object.visible = true
            select_First_Lower_Object.visible = true

            for (let j = 1; j < models.length; j++) {
                var selected_Upper_Object = scene.getObjectByName(`upper_${j}`);
                var selected_Lower_Object = scene.getObjectByName(`lower_${j}`);
                selected_Upper_Object.visible = false
                selected_Lower_Object.visible = false
            }

            let i = 1;
            function myLoop() {
                var selected_Upper_Object = scene.getObjectByName(`upper_${i}`);
                var selected_Lower_Object = scene.getObjectByName(`lower_${i}`);

                select_First_Upper_Object.visible = false
                select_First_Lower_Object.visible = false

                if (i < models.length) {
                    setSelectedIndex(i)
                    selected_Upper_Object.visible = true
                    selected_Lower_Object.visible = true
                    i++;

                    setTimeout(() => {
                        selected_Upper_Object.visible = false
                        selected_Lower_Object.visible = false
                        myLoop();
                    }, 500);

                }
                else {
                    var Last_Upper_Object = scene.getObjectByName(`upper_${models.length - 1}`);
                    var Last_Lower_Object = scene.getObjectByName(`lower_${models.length - 1}`);
                    Last_Upper_Object.visible = true
                    Last_Lower_Object.visible = true
                }
            }
            myLoop();
        }
        else if (isUpper && !isLower) {
            var select_First_Upper_Object = scene.getObjectByName(`upper_0`);
            var select_First_Lower_Object = scene.getObjectByName(`lower_0`);
            select_First_Upper_Object.visible = true
            select_First_Lower_Object.visible = false

            for (let j = 1; j < models.length; j++) {
                var selected_Upper_Object = scene.getObjectByName(`upper_${j}`);
                var selected_Lower_Object = scene.getObjectByName(`lower_${j}`);
                selected_Upper_Object.visible = false
                selected_Lower_Object.visible = false
            }

            let i = 1;
            function myLoop() {
                var selected_Upper_Object = scene.getObjectByName(`upper_${i}`);
                var selected_Lower_Object = scene.getObjectByName(`lower_${i}`);

                select_First_Upper_Object.visible = false
                select_First_Lower_Object.visible = false

                if (i < models.length) {
                    setSelectedIndex(i)
                    selected_Upper_Object.visible = true
                    selected_Lower_Object.visible = false
                    i++;

                    setTimeout(() => {
                        selected_Upper_Object.visible = false
                        selected_Lower_Object.visible = false
                        myLoop();
                    }, 500);

                }
                else {
                    var Last_Upper_Object = scene.getObjectByName(`upper_${models.length - 1}`);
                    var Last_Lower_Object = scene.getObjectByName(`lower_${models.length - 1}`);
                    Last_Upper_Object.visible = true
                    Last_Lower_Object.visible = false
                }
            }
            myLoop();
        }
        if (!isUpper && isLower) {
            var select_First_Upper_Object = scene.getObjectByName(`upper_0`);
            var select_First_Lower_Object = scene.getObjectByName(`lower_0`);
            select_First_Upper_Object.visible = false
            select_First_Lower_Object.visible = true

            for (let j = 1; j < models.length; j++) {
                var selected_Upper_Object = scene.getObjectByName(`upper_${j}`);
                var selected_Lower_Object = scene.getObjectByName(`lower_${j}`);
                selected_Upper_Object.visible = false
                selected_Lower_Object.visible = false
            }

            let i = 1;
            function myLoop() {
                var selected_Upper_Object = scene.getObjectByName(`upper_${i}`);
                var selected_Lower_Object = scene.getObjectByName(`lower_${i}`);

                select_First_Upper_Object.visible = false
                select_First_Lower_Object.visible = false

                if (i < models.length) {
                    setSelectedIndex(i)
                    selected_Upper_Object.visible = false
                    selected_Lower_Object.visible = true
                    i++;

                    setTimeout(() => {
                        selected_Upper_Object.visible = false
                        selected_Lower_Object.visible = false
                        myLoop();
                    }, 500);

                }
                else {
                    var Last_Upper_Object = scene.getObjectByName(`upper_${models.length - 1}`);
                    var Last_Lower_Object = scene.getObjectByName(`lower_${models.length - 1}`);
                    Last_Upper_Object.visible = false
                    Last_Lower_Object.visible = true
                }
            }
            myLoop();
        }
    }

    return (
      <div className="container3d ">
          <div id="canvas" style={{position: "relative"}}>
              {
                  lowerUpperLoaded.lower_loaded && lowerUpperLoaded.upper_loaded ?
                    <>
                        <div className="step-controls">
                            <div className="system">
                                <div className="buttons">
                                    <a onClick={PlayBtnHandler}>
                                        <img className="play-btn" src={Plat2}/>
                                    </a>
                                </div>
                                <div className="digits">
                                    {
                                        models ?
                                          models.map((item, index) =>
                                            <Fragment key={index}>
                                                <span onClick={() => NextStepHandler(item, index)}
                                                      className="step start-text selected" data-index="0">
                                                    <span
                                                      className={selectedIndex === index ? 'step-text' : 'step-text small'}>.</span>
                                                </span>
                                                {
                                                    models[models.length - 1] !== item ?
                                                      <span className="step many-steps" data-index="1">
                                                            <span className="step-text rect">.</span>
                                                        </span> : null
                                                }
                                            </Fragment>
                                          )
                                          : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="step-controls-play">
                            <div className="timeline">
                                <div className="menu">
                                    <a onClick={UpperBtnHandler} id="upper" className="purpleBtn">Upper</a>
                                    <a onClick={LowerBtnHandler} id="lower" className="purpleBtn">Lower</a>
                                    <a onClick={FrontBtnHandler} id="front" className="purpleBtn">Front</a>
                                    <a onClick={LeftBtnHandler} id="left" className="purpleBtn">Left</a>
                                    <a onClick={RightBtnHandler} id="right" className="purpleBtn">Right</a>
                                </div>
                            </div>
                        </div>
                    </> : null
              }
              {
                  isLoading ? <ThreeDViewerSPinner progress={Math.floor(currentProgressValue)}/> : null
              }
          </div>          
      </div>
    )
}

export default (ThreeScene);
