import React, { useState, useEffect } from "react";
import {
  InlineError,
  Checkbox,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Select,
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";
import history from "../history";
import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.

import { useParams } from "react-router-dom";
import Select2 from "react-dropdown-select";
import Axios from "axios";

function EditPlan() {
  let { id } = useParams();
  const [valueTitleEn, setValueTitleEn] = useState();
  const [valueSubTitleEn, setValueSubTitleEn] = useState();
  const [valueDescriptionEn, setValueDescriptionEn] = useState();
  const [valuePaymentInterval, setValuePaymentInterval] = useState("");
  const [checked, setChecked] = useState();
  const [valueTitleAr, setValueTitleAr] = useState();
  const [valueSubTitleAr, setValueSubTitleAr] = useState();
  const [valueDescriptionAr, setValueDescriptionAr] = useState();
  const [options, setOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState({ add: [], remove: [] });
  const [totalPriceValue, setTotalPriceValue] = useState(null);
  const [localPriceCurrency, setLocalPriceCurrency] = useState();
  const[typePayment,setTypePayment]=useState([]);
  const[searchUser,setSearchUser]=useState("")
  const[loadingUser,setLoadingUser]=useState(false)
  const[custom,setCustom]=useState(false)
  const[hideForTabbyCountries,setHideForTabbyCountries]=useState(false)
  const [optionsPayment, setOptionsPayment] = useState([
    { label: "Stripe", value: "stripe" },
    { label: "Payfort", value: "payfort" },
    { label: "Tamara ", value: "tamara" },
    { label: "Tabby ", value: "tabby" },
    { label: "Cashew ", value: "cashew" },
  ]);
  const [selectedCountry, setSelectedCountry] = useState("AE");
  const [optionsCountries, setOptionsCountries] = useState([
    { label: "UAE", value: "AE" },
    { label: "KSA", value: "SA" },
    { label: "QAT ", value: "QA" },
    { label: "LEB ", value: "LB" },
    { label: "KWT ", value: "KW" },
    { label: "BAH ", value: "BH" },
    { label: "OMN ", value: "OM" },
    { label: "UAE or KSA or KWT", value: "AE + SA + KW" },
    { label: "UAE or KSA or KWT or QAT or BAH or LEB", value: "AE + SA + KW + BH + QA + LB" },
    { label: "UAE or KSA", value: "AE + SA" },
    { label: "Worldwide ", value: "worldwide" },
    { label :"Worldwide except UAE or KSA",value:"Worldwide except GULF"}
  ]);
  const[typePlan,setTypePlan]=useState([]);
  const [optionsType, setOptionsType] = useState([
    { label: "light", value: "light" },
    { label: "medium", value: "medium" },
    { label: "medium+", value: "medium+" },
    { label: "complex", value: "complex" },
    { label: "complex+", value: "complex+" },
  ]);
  useEffect(()=>{
    const ourRequest = Axios.CancelToken.source() 
    if(searchUser.length>=3){
      setLoadingUser(true)
      axios
      .get(`admin/v1/users/search/v2/${id}?name=${searchUser}`, {
        cancelToken: ourRequest.token,
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        console.log(result)
        for (var i = 0; i < result.data.data.length; i++) {
          listUsers.push({
            key: result.data.data[i].id,
            label:
              result.data.data[i].first_name +
              " " +
              result.data.data[i].last_name +
              " ( ID: " +
              result.data.data[i].id +
              " )",
            value: String(result.data.data[i].id),
            selected: result.data.data[i].plans.length > 0 ? true : false,
          });
        }
        setOptions(listUsers);
      })
      .catch((err) => console.log(err))
      .finally((final)=> setLoadingUser(false));
     
    }
    return () => {
      ourRequest.cancel() // <-- 3rd step
    }
  },[searchUser])
  let listUsers = [];

  const [fieldRequiredTitleEn, setFieldRequiredTitleEn] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredSubTitleEn, setFieldRequiredSubTitleEn] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredPaymentInterval, setFieldRequiredPaymentInterval] =
    useState(<InlineError message="" fieldID="myFieldID" />);
  const [fieldRequiredDescriptionEn, setFieldRequiredDescriptionEn] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredTitleAr, setFieldRequiredTitleAr] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredSubTitleAr, setFieldRequiredSubTitleAr] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredDescriptionAr, setFieldRequiredDescriptionAr] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredLocalPriceCurrency, setFieldRequiredLocalPriceCurrency] =
    useState(<InlineError message="" fieldID="myFieldID" />);
  let selectedStyles = {
    background: "#0074D9",
    color: "#fff",
    borderBottom: "1px solid #fff",
  }
  let usersBoxStyles = {
    background: "#0074D9",
    color: "#fff",
    borderBottom: "1px solid #fff",
    display:"inline-block",
    margin:"0px 5px",
    cursor:"pointer",
    padding:"5px 10px",
  }
  useEffect(() => {
    Promise.all([
      axios.get(`admin/v1/plans/${id}/show`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }),

    ]).then(function (responses) {
      console.log("res",responses[0].data);
      const responsePlanShow = responses[0].data.data;
      responsePlanShow.title && setValueTitleEn(responsePlanShow.title.en);
      responsePlanShow.title && setValueTitleAr(responsePlanShow.title.ar);
      responsePlanShow.sub_title &&
        setValueSubTitleEn(responsePlanShow.sub_title.en);
      responsePlanShow.sub_title &&
        setValueSubTitleAr(responsePlanShow.sub_title.ar);
      responsePlanShow.description &&
        setValueDescriptionEn(responsePlanShow.description.en);
      responsePlanShow.description &&
        setValueDescriptionAr(responsePlanShow.description.ar);
      responsePlanShow.payment_interval &&
        setValuePaymentInterval(String(responsePlanShow.payment_interval));
      responsePlanShow.others &&
        responsePlanShow.others.total_price &&
        setTotalPriceValue(responsePlanShow.others.total_price);
      responsePlanShow.price_in_local_currency &&
        setLocalPriceCurrency(String(responsePlanShow.price_in_local_currency));
      setChecked(responsePlanShow.active);
      setCustom(responsePlanShow.custom);
      setHideForTabbyCountries(responsePlanShow.hide_for_tabby_countries);
      responsePlanShow.product_country &&
        setSelectedCountry(responsePlanShow.product_country);
      responsePlanShow.treatment_type &&
        setTypePlan([{label:responsePlanShow.treatment_type,value:responsePlanShow.treatment_type}]);
        responsePlanShow.type &&
        setTypePayment([{ label: responsePlanShow.type.charAt(0).toUpperCase() +  responsePlanShow.type.slice(1), value:  responsePlanShow.type }])

      setSelectedUser(responses[0].data.users);
    });
  }, []);

  return (
    <Page
      title="Edit Plan"
      breadcrumbs={[{ content: "List Of Plans", url: "/admin/plans" }]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Title En</TextStyle>
          <TextField value={valueTitleEn} onChange={handleChangeTitleEn} />
          {fieldRequiredTitleEn}
          <TextStyle variation="strong">Title Ar</TextStyle>
          <TextField value={valueTitleAr} onChange={handleChangeTitleAr} />
          {fieldRequiredTitleAr}
          <TextStyle variation="strong">SubTitle En</TextStyle>
          <TextField
            value={valueSubTitleEn}
            onChange={handleChangeSubTitleEn}
            DescriptionEn="number"
          />
          {fieldRequiredSubTitleEn}
          <TextStyle variation="strong">SubTitle Ar</TextStyle>
          <TextField
            value={valueSubTitleAr}
            onChange={handleChangeSubTitleAr}
            DescriptionEn="number"
          />
          {fieldRequiredSubTitleAr}
          <TextStyle variation="strong">Description En</TextStyle>
          <TextField
            value={valueDescriptionEn}
            onChange={handleChangeDescriptionEn}
          />
          {fieldRequiredDescriptionEn}
          <TextStyle variation="strong">Description Ar</TextStyle>
          <TextField
            value={valueDescriptionAr}
            onChange={handleChangeDescriptionAr}
          />
          {fieldRequiredDescriptionAr}
          <TextStyle variation="strong">Payment Interval</TextStyle>
          <TextField
            value={valuePaymentInterval}
            onChange={handleChangePaymentInterval}
            type="number"
          />
          {fieldRequiredPaymentInterval}
          <TextStyle variation="strong">Total Price</TextStyle>
          <TextField
            value={totalPriceValue}
            onChange={handleChangeTotalPrice}
            type="number"
          />
          <TextStyle variation="strong">Price in local currency</TextStyle>
          <TextField
            value={localPriceCurrency}
            onChange={handleChangeLocalPriceCurrency}
            type="number"
          />
          {fieldRequiredLocalPriceCurrency}
          <TextStyle variation="strong">Product Country</TextStyle>
          <Select
            options={optionsCountries}
            onChange={handleSelectCountry}
            value={selectedCountry}
          />
          <div style={{display:"flex",gap:"10px",justifyContent:"space-between"}}>
            <div style={{flex:"0 0 50%"}}>
              <TextStyle variation="strong">Added Users</TextStyle>
              <div  style={{overflow:"auto",height: "150px",padding: "10px",margin: "10px 0px",border: "1px solid #d9d9d9",borderRadius: "3px",}} >
                {selectedUsers.add.map((item)=>
                <div key={item.key} style={{...usersBoxStyles, background: "#000"}} onClick={()=>{handleSelectUserV2(item)}}>
                  {item.label}
                </div>
                )}
              </div>
              </div>
            <div style={{flex:"0 0 50%"}}>
              <TextStyle variation="strong">Remove Users</TextStyle>
              <div style={{overflow:"auto",height:"150px",padding: "10px",margin: "10px 0px",border: "1px solid #d9d9d9",borderRadius: "3px",}} >
                {selectedUsers.remove.map((item)=>
                <div key={item.value} style={usersBoxStyles} onClick={()=>{handleSelectUserV2(item)}}>
                  {item.label}
                </div>
                )}
              </div>
            </div>
          </div>
          
          <TextStyle variation="strong">User <a href="/users" style={{float:"right"}}>View All Users for this plan</a></TextStyle>
          <Select2
            placeholder="Search user by name"
            searchFn={(e)=>setSearchUser(e.state.search)}
            loading={loadingUser}
            multi
            options={options}
            itemRenderer={({ item, itemIndex, props, state, methods }) =>
            <div key={itemIndex} style={item.selected ? {...selectedStyles,padding:"5px 10px",} :{padding:"5px 10px",}} onClick={() => handleSelectUserV2(item)}>
                <span>{item.label}</span>
              </div>
            }
          />
           <TextStyle variation="strong">Payment Method</TextStyle>
          <Select2
            placeholder="Search payments by payment name"
            // multi
            // noDataRenderer={customNoDataRenderer}
            onChange={(newValue) => setTypePayment(newValue)}
            values={typePayment}
            options={optionsPayment}
          />
           <TextStyle variation="strong">Plan Type</TextStyle>
          <Select2
            placeholder="Search plan type by plan name"
            // multi
            // noDataRenderer={customNoDataRenderer}
            onChange={(newValue) => setTypePlan(newValue)}
            values={typePlan}
            options={optionsType}
          />
          <Checkbox
            label="Is active for all users"
            checked={checked}
            onChange={handleChangeCb}
          />
          <Checkbox
            label="Custom Plan"
            checked={custom}
            onChange={handleChangeCustom}
          />
          <Checkbox
              label="Show only Tabby"
              checked={hideForTabbyCountries}
              onChange={handleChangeHideForTabbyCountries}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
        secondaryActions={[
          {
            content: "Delete",
            disabled: (id === "17" || id === "18" || id === "8") && true,

            onClick: () => handeDelete(),
          },
        ]}
      />
    </Page>
  );
  function handeDelete() {
    if (id !== 1 && id !== 8) {
      axios
        .delete("/admin/v1/plans/" + id + "/delete", {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((result) => {
          history.push("/admin/plans");
        })
        .catch((err) => console.log(err));
    }
  }
  function handleChangeTotalPrice(totalPriceValue) {
    setTotalPriceValue(totalPriceValue);
  }
  function handleSelectCountry(selectedCountry) {
    setSelectedCountry(selectedCountry);
  }
  function handleChangeLocalPriceCurrency(localPriceCurrency) {
    setLocalPriceCurrency(localPriceCurrency);
  }
  function handleSelectUser(newSelectedUser) {
    setSelectedUser(newSelectedUser);
  }
  function handleSelectUserV2(item) {
    const { add, remove } = selectedUsers;
    const itemIndexInAdd = add.findIndex((el) => el.key === item.key);
    const itemIndexInRemove = remove.findIndex((el) => el.key === item.key);
    const updatedAdd = [...add];
    const updatedRemove = [...remove];
    if (itemIndexInAdd !== -1) {
      updatedAdd.splice(itemIndexInAdd, 1);
    } else if (itemIndexInRemove !== -1) {
      updatedRemove.splice(itemIndexInRemove, 1);
    } else {
      if(item.selected){
        updatedRemove.push(item);
      }else{
        updatedAdd.push(item);
      }
    }
    setSelectedUsers({ add: updatedAdd, remove: updatedRemove });
  };
  function handleChangeTitleEn(valueTitleEn) {
    setValueTitleEn(valueTitleEn);
  }
  function handleChangeSubTitleEn(valueSubTitleEn) {
    setValueSubTitleEn(valueSubTitleEn);
  }

  function handleChangePaymentInterval(valuePaymentInterval) {
    setValuePaymentInterval(valuePaymentInterval);
  }
  function handleChangeDescriptionEn(valueDescriptionEn) {
    setValueDescriptionEn(valueDescriptionEn);
  }
  function handleChangeTitleAr(valueTitleAr) {
    setValueTitleAr(valueTitleAr);
  }
  function handleChangeSubTitleAr(valueSubTitleAr) {
    setValueSubTitleAr(valueSubTitleAr);
  }
  function handleChangeDescriptionAr(valueDescriptionAr) {
    setValueDescriptionAr(valueDescriptionAr);
  }

  function handleChangeCb(checked) {
    setChecked(checked);
  }
  function handleChangeCustom(custom) {
    setCustom(custom);
  }
  function handleChangeHideForTabbyCountries(isTabbyHidden) {
    setHideForTabbyCountries(isTabbyHidden);
  }
  function handleSave() {
    const bodyObj = {
      title: {
        en: valueTitleEn,
        ar: valueTitleAr,
      },
      sub_title: {
        en: valueSubTitleEn,
        ar: valueSubTitleAr,
      },
      description: {
        en: valueDescriptionEn,
        ar: valueDescriptionAr,
      },
      payment_interval: valuePaymentInterval,
      active: checked,
      custom: custom,
      hide_for_tabby_countries: hideForTabbyCountries,
      add_users: selectedUsers.add.map((item) => item.key),
      remove_users: selectedUsers.remove.map((item) => item.key),
      others: {
        total_price: totalPriceValue,
      },
      price_in_local_currency: localPriceCurrency,
      product_country: selectedCountry,
      type:typePayment[0].value,
      treatment_type:typePlan[0].value
    };
    axios
      .post("/admin/v1/plans/" + id + "/update/v2", bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        history.push("/admin/plans");
      })
      .catch((err) => console.log(err));
  }
  //}
}
export default EditPlan;
