import { makeStyles } from "@material-ui/core";
let defaultNumberOfNotifications = 5;
export const useStyles = makeStyles(() => ({
    fontFamilyAlmarai:{
      fontFamily:'Almarai !important'
    },
    fontFamilyEina:{
      fontFamily:"Eina !important",
    },
    mainMenu:{
      "& .MuiPaper-root":{
       overflow:"unset",
       left:'unset !important',
       right:"5%",
       maxWidth:"35%",
      }
    },
    view:{
      cursor:"pointer",
      color:"#212B36",
      fontWeight:"bold",
      fontSize:"12px!important",
      opacity:0.56,
      "& svg":{
        width:'0.5em',height:'0.5em'
      }
    },
    pending:{
      fontWeight:'bold',
      opacity:0.56,
      fontSize:"12px!important",
      color:"#212B36"
    },
    clear:{
      fontWeight:'bold',
      opacity:0.56,
      fontSize:"12px!important",
  
      cursor:"pointer",
      color:"#C58D00"
    },
    notificationTab: {
      width: '100%',
      height: '100%',
      padding: 20
    },
    notiticationTabHeaderBox:{
    height: "30px",
    margin: 0,
    padding: "10px",
    position:"inherit",
    zIndex:2
    },
    notiticationTabHeader: {
      margin: 0,
      padding: "5px 10px",
      backgroundColor:"#F5F5F5",
      borderRadius: "4px",
      display:"flex",
     justifyContent:"space-between"
    },
    notificationsParent:{
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      overflowY:"auto",
      // maxWidth:"20vw",
      minHeight: `calc(${defaultNumberOfNotifications}*10vh)`,
      maxHeight: `calc(${defaultNumberOfNotifications}*14vh)`,
      "& div:last-child":{
        borderBottom:"none",
      },
      // ['@media(max-width: 1000px)'] : {
      //   maxWidth: "30vw",
      // },
    },
    notification:{
      cursor:"pointer",
      margin:"0px 10px 0 10px",
      display:"flex",
      padding:10,
      marginTop:"15px",
      borderBottom:"1px solid grey"
    },
    notificationIcon:{
      flex: "0 1 15%",
      margin:"5px 10px 0 0",
      color:"#212B36",
      "& img":{
        width:"100%",
      },
      
  
    },
    triangle:{
      height: "5px",
      width: "14px",
      backgroundColor: "white",
      boxShadow:" 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
      clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
      position: "absolute",
      bottom: "100%",
      right: "3%",
    },
    notificationBody:{
    },
    description:{
     color: "#303030",
    fontWeight:'bold',
    fontSize:"12px!important",
     opacity:0.8,
     maxWidth: "25vw",
     ['@media(max-width: 1000px)'] : {
      maxWidth: "35vw",
    },
    ['@media(max-width: 600px)'] : {
      maxWidth: "45vw",
    },
    ['@media(max-width: 400px)'] : {
      maxWidth: "60vw",
    },
    },
    descriptionTime:{
      color:"#C58D00",
      fontWeight:"bold",
      fontSize:"12px!important",
      opacity:0.8,
     },
     title:{
      color: "#303030",
      fontWeight:"bold",
      fontSize:"14px!important",
      opacity:0.8,
      display: 'flex',
      gap: '5px',
      alignItems:"center"
     },
     timeAgo:{
      color:"#212B36",
      fontWeight:"bold",
      fontSize:"12px!important",
      opacity:0.56
     },
     redDot:{
       color:"red !important",
       fontSize:"1em !important",
       verticalAlign: "text-top",
     },
  }));