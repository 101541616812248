import { Badge, Icon, TextStyle } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import NavigationLayout from "../components/NavigationLayout";
import axios from "../axios";
import history from "../history";
import { Card, TextField } from "@mui/material";
import { ChevronLeftMinor, ChevronRightMinor } from "@shopify/polaris-icons";
import Cookies from "js-cookie";
import moment from "moment";

function OEMDashboard() {
  const [cases, setCases] = useState([]);
  const [page, setPage] = useState(1);
  const [last, setLast] = useState(1);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getCases();
  }, [page]);
  const getCases = async () => {
    let res = await axios.get(`/admin/v1/oem/desginer/cases?search=${search}&page=${page}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    });
    setCases(res.data.cases.data);
    setLast(res.data.cases.last_page);
  };
  const handleTicketClick = (item) => {
    history.push(`/admin/users/${item.user_id}/designer`);
  };
  return (
    <NavigationLayout>
      <Card
        sx={{ height: "90%", margin: "2rem 0 0 2rem", borderRadius: "1rem" }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ margin: "5px auto"}}>
              <TextField placeholder='Search User' size='small'  variant="standard"
                    inputProps={{style: {fontSize: 12}}} 
                    value={search} onChange={(val)=>{setSearch(val.target.value)}}/>
              <button class="btn-search" onClick={getCases}>
                <i class="fas fa-search"></i>
              </button>
          </div>
          <div style={{ display: "flex", height: "80vh" }}>
            <div className="payment-wrapper" style={{ width: "100%" }}>
              <table
                className="procedures-profile-table"
                style={{
                  display: "grid",
                  width: "100%",
                  overflow: "auto",
                  maxHeight: "90%",
                  position: "relative",
                }}
              >
                <thead
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                  }}
                >
                  <tr style={{ display: "flex" }}>
                    <th className="procedures-profile-th" style={{ flex: "1" }}>
                      <TextStyle variation="strong">User ID</TextStyle>
                    </th>
                    <th className="procedures-profile-th" style={{ flex: "1" }}>
                      <TextStyle variation="strong">Status</TextStyle>
                    </th>
                    <th className="procedures-profile-th" style={{ flex: "1" }}>
                      <TextStyle variation="strong">Username</TextStyle>
                    </th>
                    <th className="procedures-profile-th" style={{ flex: "1" }}>
                      <TextStyle variation="strong">OEM Portal</TextStyle>
                    </th>
                    <th className="procedures-profile-th" style={{ flex: "1" }}>
                      <TextStyle variation="strong">
                        Submitted Case Date
                      </TextStyle>
                    </th>
                    <th className="procedures-profile-th" style={{ flex: "1" }}>
                      <TextStyle variation="strong"></TextStyle>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cases.map((item, key) => {
                    let submittedDate = moment
                      .utc(item.created_at)
                      .local()
                      .format("DD-MM-yy hh:mm A");
                    return (
                      <tr
                        style={{ display: "flex", cursor: "default" }}
                        key={key}
                      >
                        <td
                          className="procedures-profile-td"
                          style={{ flex: "1" }}
                        >
                          <div>{item.user_id}</div>
                        </td>
                        <td
                          className="procedures-profile-td"
                          style={{ flex: "1" }}
                        >
                          <div>{item.status.replace("_", " ")}</div>
                        </td>
                        <td
                          className="procedures-profile-td"
                          style={{ flex: "1" }}
                        >
                          <div>{item.user.full_name}</div>
                        </td>
                        <td
                          className="procedures-profile-td"
                          style={{ flex: "1" }}
                        >
                          <a
                            href={
                              "https://clearalignerportal.com/#/case/detail/" +
                              item.case_id
                            }
                          >
                            OEM Link
                          </a>
                        </td>
                        <td
                          className="procedures-profile-td"
                          style={{ flex: "1" }}
                        >
                          <div>{submittedDate}</div>
                        </td>
                        <td
                          className="procedures-profile-td"
                          style={{ flex: "1" }}
                        >
                          <button
                            onClick={() => {
                              handleTicketClick(item);
                            }}
                            className="treatment-actions"
                            type="confirm"
                            style={{ width: "fit-content", margin: "auto" }}
                          >
                            View Case
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px 0px",
                }}
              >
                <button
                  style={{
                    border: "none",
                    cursor: "pointer",
                    background: "transparent",
                  }}
                  onClick={() => setPage(page - 1)}
                  disabled={page == 1}
                >
                  <Icon
                    source={ChevronLeftMinor}
                    color={page == 1 ? "base" : "primary"}
                  />
                </button>
                <span style={{ fontWeight: "bold" }}>
                  {page} / {last}
                </span>
                <button
                  style={{
                    border: "none",
                    cursor: "pointer",
                    background: "transparent",
                  }}
                  disabled={page >= last}
                  onClick={() => setPage(page + 1)}
                >
                  <Icon
                    source={ChevronRightMinor}
                    color={page >= last ? "base" : "primary"}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </NavigationLayout>
  );
}

export default OEMDashboard;
