import React, { useContext, useEffect } from 'react'
import ShopLayout from '../components/ShopLayout'
import { ShopContext } from '../ShopContext';
import { Card, DisplayText, Image } from '@shopify/polaris';
import { CardActions } from '@mui/material';
import { Button as ButtonMui, Typography } from '@material-ui/core';
import emptyIcon from '../img/emptyList.svg';
import ShopShippingTable from './ShopShippingTable';

const ShopShipping = () => {
  const {
    states: {
      shipments,
    },
    actions: {
      setShipments,
      getShopShipping,
    }
  } = useContext(ShopContext);

  const addNew = () => {
    let shippingData = shipments.data;
    shippingData.push('');
    setShipments(prev => ({
      ...prev,
      data: shippingData,
    }));
  }

  useEffect(() => {
    getShopShipping();
  }, [])

  return (
    <ShopLayout>
      <Card sectioned title='Shipping Fees'>
        <CardActions style={{ float: 'right' }}>
          <ButtonMui variant='contained' style={{ backgroundColor: 'rgb(0 128 96)', color: 'white', 'padding': '1.5em 2em'}} onClick={addNew}>
            <Typography variant='h6' >Add New</Typography>
          </ButtonMui>
        </CardActions>
        <table style={{ display: 'grid', width: '100%', overflowX: 'auto', overflowY: 'hidden', padding: '1em 0' }}>
          <thead>
            <tr style={{ display: 'flex', gap: '10px' }}>
              <th className='shipping-header' >Country</th>
              <th className='shipping-header'>Amount</th>
              <th className='shipping-header'></th>
              <th className='shipping-header'></th>
            </tr>
          </thead>
          <tbody>
            {
              shipments.data.map((item, key) => <ShopShippingTable id={item.id} amount={item.amount} code={item.code} editable={!item.id} key={key} />)
            }
          </tbody>
        </table>
        {!shipments.data.length ? (
          <div className='message-no-result'>
            <div className='message-no-result-img'>
              <Image src={emptyIcon}></Image>{' '}
            </div>
            <DisplayText size='small'>No results found</DisplayText>
            <br />
          </div>
        ) : null}
      </Card>
    </ShopLayout>
  )
}

export default ShopShipping