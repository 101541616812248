import React, { useState, useEffect } from "react";
import axios from "../../axios";
import DataTableComponent from "./DataTableComponent.js";
import "react-toastify/dist/ReactToastify.css";
import Cookie from "js-cookie";
import {
  Loading,
  Stack,
  RadioButton,
  TextField,
  Button,
} from "@shopify/polaris";

const TabComponent2 = ({ markAsReceived, reload }) => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [searchField, setSearchField] = useState("phone");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const loadingMarkup = loading ? <Loading /> : null;

  useEffect(() => {
    fetchData();
  }, [page, perPage, searchQuery, reload]);

  const fetchData = () => {
    const url = "/admin/v1/reports/in-transit-orders";
    let params = {
      page: page,
      per_page: perPage,
      searchField: searchField,
      searchQuery: searchQuery,
    };

    setLoading(true);

    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
        params: params,
      })
      .then((result) => {
        if (result.data.success) {
          setTotalPages(result?.data?.data?.last_page);
          let array = result?.data?.data?.data?.map((res) => {
            return [
              res?.patient_id,
              `${res?.first_name} ${res?.last_name}`,
              res?.mobile_number,
              res?.tracking_number,
              res?.status,
              res?.type,
              res?.shipment_event_description,
              res?.shipment_event_date_time,
              res?.carrier,
              res?.id,
              res?.mark_as_received ? <Button onClick={() => markAsReceived(res?.patient_id, res?.dispatch_id)}>Mark As Received</Button> : '-'
            ];
          });
          setItems(array);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("an error", err);
        setLoading(false);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleSearch = () => {
    setSearchQuery(searchValue);
    setPage(1); // Reset the page to the first page after performing the search
    fetchData();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div onKeyDown={handleKeyDown}>
      {loadingMarkup}
      <div style={{ marginTop: "10px" }}>
        <Stack alignment="center" distribution="trailing" wrap={true}>
          <RadioButton
            label="Mobile Number"
            checked={searchField === "phone"}
            onChange={() => setSearchField("phone")}
          />
          <RadioButton
            label="First Name"
            checked={searchField === "first_name"}
            onChange={() => setSearchField("first_name")}
          />
          <RadioButton
            label="Last Name"
            checked={searchField === "last_name"}
            onChange={() => setSearchField("last_name")}
          />
          <RadioButton
            label="User ID"
            checked={searchField === "user_id"}
            onChange={() => setSearchField("user_id")}
          />
          <RadioButton
            label="Tracking Number"
            checked={searchField === "tracking_number"}
            onChange={() => setSearchField("tracking_number")}
          />
          <RadioButton
            label="Order Tracking ID"
            checked={searchField === "id"}
            onChange={() => setSearchField("id")}
          />
        </Stack>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr auto",
          gap: "10px",
          width: "100%",
        }}
      >
        <div>
          <TextField
            label="Search"
            value={searchValue}
            onChange={setSearchValue}
            placeholder={`Search by ${searchField}`}
          />
        </div>
        <div style={{ marginTop: "24px" }}>
          <Button onClick={handleSearch}>Search</Button>
        </div>
      </div>

      <DataTableComponent
        items={items}
        totalPages={totalPages}
        page={page}
        tab="1"
        handleChange={handleChange}
      />
    </div>
  );
}

export default TabComponent2;
