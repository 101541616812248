import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  Avatar,
  Button,
  ResourceItem,
  ResourceList,
  Thumbnail,
  Caption,
  InlineError,
  DropZone,
  Checkbox,
  Stack,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  ActionList,
  Popover,
  Select,
  Card,
  DataTable,
  Tabs,
  PageActions,
} from "@shopify/polaris";
import axios from "../../axios";
import Cookie from "js-cookie";
import history from "../../history";
import Helmet from "react-helmet";
import { Editor } from "@tinymce/tinymce-react";

function Payments() {
  const rows = [
    ["Emerald Silk Gown", "$875.00", "Emerald Silk Gown"],
    ["Emerald Silk Gown", "$875.00", "Emerald Silk Gown"],
  ];
  const [items, setItems] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  function getData() {
    axios
      .get("admin/v1/users/" + Cookie.get("userID") + "/pending-payments", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setItems(
          result.data.data.map((item) => [
            "name",
            item.email_due_date,
            "status",
          ])
        );
      })
      .catch((err) => console.log(err));
  }
  return (
    <Page
      title="Payments"
      breadcrumbs={[
        {
          content: "Dashboard",
          url: "/admin/users/" + Cookie.get("userID") + "/dashboard",
        },
      ]}
    >
      <Card>
        <DataTable
          columnContentTypes={["text", "text", "text"]}
          headings={["Payment", "Due-Date", "Status"]}
          rows={items}
        />
      </Card>
    </Page>
  );
}
export default React.memo(Payments);
