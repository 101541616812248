import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useStyles } from './user-freshsales-contacts-google-analytics.styles';

function UserFreshsalesContactsGoogleAnalytics({ data }) {
  const classes = useStyles();
  const fields = Object.keys(data['Survey']['First Click']);
  const otherFields = Object.keys(data['Survey']['others']);
  const customOrder = [
    'Source',
    'Campaign',
    'Content',
    'Medium',
    'Term',
    'Referrer',
    'Landing page',
  ];
  const customOrderOthers = [
    'All traffic sources',
    'Device',
    'Operating system',
    'Browser',
    'Number of website visits',
    'Pages visited',
    'Time spent on website',
    'Google analytics cid',
    'Google click identifier',
  ];
  let sortedArrayCustomOrder = [],
    sortedArrayCustomOrderOther = [];
  if (fields && fields.length > 0) {
    sortedArrayCustomOrder = fields.slice().sort((a, b) => {
      return customOrder.indexOf(a) - customOrder.indexOf(b);
    });
  }
  if (otherFields && otherFields.length > 0) {
    sortedArrayCustomOrderOther = otherFields.slice().sort((a, b) => {
      return customOrderOthers.indexOf(a) - customOrderOthers.indexOf(b);
    });
  }
  const otherBas = data['BAS']['others'];
  const otherSurvey = data['Survey']['others'];
  const firstClickBas = data['BAS']['First Click'];
  const lastClickBas = data['BAS']['Last Click'];
  const firstClickSurvey = data['Survey']['First Click'];
  const lastClickSurvey = data['Survey']['Last Click'];
  return (
    <>
      <div style={{ width: '100%' }}>
        <TableContainer
          component={Paper}
          style={{ maxWidth: '1200px', margin: '0 auto' }}
        >
          <Table
            classes={{ root: classes.table }}
            stickyHeader
            aria-label="view google analtyics"
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell colSpan={2}>Survey</TableCell>
                <TableCell colSpan={2}>BAS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className="table-second-header">
                <TableCell></TableCell>
                <TableCell>First Click</TableCell>
                <TableCell>Last Click</TableCell>
                <TableCell>First Click</TableCell>
                <TableCell>Last Click</TableCell>
              </TableRow>
              {sortedArrayCustomOrder.length > 0 &&
                sortedArrayCustomOrder.map((value, index) => (
                  <TableRow className="field-width" key={index}>
                    <TableCell className="field">{value ?? '-'}</TableCell>
                    <TableCell>{firstClickSurvey[value] ?? '-'}</TableCell>
                    <TableCell>{lastClickSurvey[value] ?? '-'}</TableCell>
                    <TableCell>{firstClickBas[value] ?? '-'}</TableCell>
                    <TableCell>{lastClickBas[value] ?? '-'}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ marginTop: '24px', width: '100%' }}>
        <TableContainer
          component={Paper}
          style={{ margin: '0 auto', maxWidth: '1200px' }}
        >
          <Table
            classes={{ root: classes.table }}
            stickyHeader
            aria-label="view google analtyics other fields"
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Survey</TableCell>
                <TableCell>BAS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedArrayCustomOrderOther.length > 0 &&
                sortedArrayCustomOrderOther.map((value, index) => (
                  <TableRow className="field-width" key={index}>
                    <TableCell className="field">{value ?? '-'}</TableCell>
                    <TableCell>{otherSurvey[value] ?? '-'}</TableCell>
                    <TableCell>{otherBas[value] ?? '-'}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default UserFreshsalesContactsGoogleAnalytics;
