import { Card, DataTable, Pagination, TextStyle } from '@shopify/polaris';
import React, { useEffect } from 'react';

const TechnicianTable = ({
  technicians,
  getTechnicians,
  page,
  setPage,
  totalPages,
}) => {
  useEffect(() => {
    if (technicians && technicians.length === 0) {
      const abortController = new AbortController();
      const { signal } = abortController;
      void getTechnicians(signal);
      return () => {
        abortController.abort();
      };
    }
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    void getTechnicians(signal);
    return () => {
      abortController.abort();
    };
  }, [page]);
  
  return (
    <Card>
      <DataTable
        columnContentTypes={['text', 'text', 'text', 'text', 'text']}
        headings={[
          <TextStyle variation="strong">ID</TextStyle>,
          <TextStyle variation="strong">Name</TextStyle>,
        ]}
        rows={technicians}
      />
      <div id="pagination" style={{ padding: '20px 0' }}>
        <Pagination
          hasPrevious={page > 1}
          onPrevious={() => setPage(page - 1)}
          hasNext={page < totalPages}
          onNext={() => setPage(page + 1)}
        />
      </div>
    </Card>
  );
};

export default TechnicianTable;
