import React, { useCallback, useState } from "react";
import {
  InlineError,
  Page,
  FormLayout,
  TextField,
  Card,
  Modal,
  PageActions,
  Thumbnail,
  Stack,
  DropZone,
  TextStyle,
} from "@shopify/polaris";
import axios from "../../../../axios";
import Cookie from "js-cookie";
import history from "../../../../history";
import { useParams } from "react-router-dom";
import { NoteMinor } from "@shopify/polaris-icons";

function Add() {
  const { id, idCity, idClinic } = useParams();
  const [popupActive, setPopupActive] = useState(false);
  const [imageError, setImageError] = useState("");
  const [files, setFiles] = useState([]);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const fileUpload = !files.length && <DropZone.FileUpload />;
  const [imagePreview, setImagePreview] = useState("");
  const [imageKey, setImageKey] = useState("");
  const uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : NoteMinor
        }
      />
      <div>{files[files.length - 1].name} </div>
    </Stack>
  );
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const sendImage = useCallback(
    (files) => {
      setPopupActive(true);
      setImagePreview(
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      let url2 = "";
      const form_data = new FormData();
      form_data.append("content_type", files[files.length - 1].type);
      form_data.append("file_name", files[files.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          console.log("res1");
          setImageKey(res.data.key);
          url2 = res.data.url;
          axios
            .put(url2, files[files.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": files[files.length - 1].type,
              },
            })
            .then((res) => {
              console.log("res2");
              setPopupActive(false);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },

    [files]
  );

  return (
    <Page
      title="Add Image"
      breadcrumbs={[
        {
          url: `/admin/book-a-scan-countries/${id}/cities/${idCity}/clinics/${idClinic}/images`,
        },
      ]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Image</TextStyle>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <Thumbnail
                size="large"
                source={imagePreview ? imagePreview : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZoneDrop} onDropAccepted={sendImage}>
                {uploadedFiles}
                {fileUpload}
              </DropZone>
            </div>
          </div>
          {imageError}
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );

  function handleSave() {
    if (!imageKey) {
      setImageError("Please enter a name Identification");
    } else {
      setPopupActive(true);
      const bodyObj = {
        image: imageKey,
      };
      axios
        .post(
          `/admin/v1/countries/${id}/cities/${idCity}/clinics/${idClinic}/images`,
          bodyObj,
          {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          }
        )
        .then((res) => {
          history.push(
            `/admin/book-a-scan-countries/${id}/cities/${idCity}/clinics/${idClinic}/images`
          );
        })
        .catch((err) => console.log(""));
    }
  }
}
export default Add;
