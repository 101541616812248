import React, { useState, useEffect } from "react";
import {
  DataTable,
  Image,
  DisplayText,
  Card,
  Layout,
  TextStyle,
  Pagination,
} from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../../axios";
import history from "../../history";
import "@zendeskgarden/react-pagination/dist/styles.css";
import emptyIcon from "../../img/emptyList.svg";
import { useParams } from "react-router-dom";
import moment from "moment";
import CustomTooltip from "./../../components/CustomTooltip";

export default function AutomatedEmails() {
  const { id } = useParams();
  let perPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [isListEmpty, setIsListEmpty] = useState(true);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    axios
      .get(
        `admin/v1/users/${id}/sent-emails?per_page=${perPage}&page=${currentPage}`,
        {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      )
      .then((result) => {
        console.log("result.data.data=", result.data.data);
        !result.data.data.data.length
          ? setIsListEmpty(true)
          : setIsListEmpty(false);
        setTotalPages(Math.ceil(result.data.data.total / perPage));
        result?.data?.data?.data &&
          setItems(
            result.data.data.data.map((item) => [
              moment(item.date).add(2, "hours").format("YYYY-MM-DD hh:mm:ss"),
              item.name,
              item.id,
            ])
          );
      })
      .catch((err) => console.log(err));
  }, [currentPage]);

  const emtyState = isListEmpty ? (
    <div className="message-no-result"
    >
      <img  src={emptyIcon} className="empty-state-image"></img>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : null;
  return (
    <Layout.Section>
      <Card
        title="AUTOMATED EMAILS"
      >
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "text"]}
          headings={[
            <TextStyle variation="strong">Date</TextStyle>,
            <TextStyle variation="strong">Email Name</TextStyle>,
            <TextStyle variation="strong">ID</TextStyle>,
          ]}
          rows={items}
        />
        {totalPages >= 2 && (
          <div
            style={{
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            <Pagination
              hasPrevious={currentPage > 1 && true}
              onPrevious={() => {
                setCurrentPage(currentPage - 1);
              }}
              hasNext={currentPage < totalPages && true}
              onNext={() => {
                setCurrentPage(currentPage + 1);
              }}
            />
          </div>
        )}
        {emtyState}
      </Card>
    </Layout.Section>
  );

  function handleEdit(e) {
    history.push(`/admin/notifications/${e.currentTarget.id}`);
  }
}
