import { Card, DisplayText, Layout } from '@shopify/polaris';
import React from 'react'
import "react-dynamic-charts/dist/index.css"; // Don't forget to import the styles
import Chart from "react-apexcharts"

function ChartComponent({name, data, subText1,subText2}) {
    let options = {
        chart: {
          animations: {
            enabled: true,
            easing: "linear",
            speed: 800,
            animateGradually: {
              enabled: false,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
    
            toolbar: {
              show: false,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false | '<img src="/static/icons/reset.png" width="20">',
                customIcons: [],
              },
              autoSelected: "zoom",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        yaxis:{
            categories:["0%","10%","20%","30%","40%","50%","60%","70%","80%","90%","100%"]
        },
        xaxis: {
          type: "numeric",
          // tickAmount: 10,
          categories: [0,1,2,3,4,5,6,7,8,9,10],
          labels: {
            show: true,
            formatter: function (val) {
                console.log('fghj',val)
              return val.toFixed(0);
            },
          },
        },
        colors: ["#9c6ade"],
      };
  return (
    <Layout.Section oneHalf>
    <Card 
      title={name}
    >
      <Card.Section>
        <DisplayText size="medium">
          {subText1}
        </DisplayText>
        <DisplayText size="medium">
          {subText2}
        </DisplayText>
      </Card.Section>
      <Card.Section>
        <Chart
          options={options}
          //:[0,0,0,"20%",0,0,0,"20%",'40%','20%',0]
          series={[{name:"",data:data??[]}]}
          type="bar"
          width="100%"
          height="100%"
        />
      </Card.Section>
    </Card>
  </Layout.Section>
  )
}

export default ChartComponent