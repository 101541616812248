import { Box, Button, makeStyles } from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconCheckView from "./IconCheckView";

const useStyles = makeStyles(() => {
  return {
    headerWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "1.375rem 1.313rem",
      backgroundColor: "#FFFFFF",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    bold: {
      fontWeight: "bold",
    },
    arrowBtn: {
      padding: 0,
      "&:hover": {
        backgroundColor: "#ffff",
      },
    },
    margin: {
      margin: "1rem",
    },
    flex: {
      display: "flex",
    },
  };
});
const ProcedureHeader = ({ handleTabsExpand, index, type, headerTitle, iconCheck, showCloseButton = false, children }) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.margin}`}>
      <div
        onClick={() => {
          handleTabsExpand(index, type);
        }}
        className={`${classes.headerWrapper} ${index}-${type}`}
      >
        {headerTitle}
        <Box className={classes.flex}>
          {!showCloseButton && <IconCheckView value={iconCheck} />}
          <Button className={classes.arrowBtn}>
            <KeyboardArrowDownIcon />
          </Button>
        </Box>
      </div>
      {children}
    </Box>
  );
};

export default ProcedureHeader;
