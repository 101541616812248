import React, { useContext, useState } from "react";
import { TextField, Select, MenuItem } from "@material-ui/core";
import { Button } from "@shopify/polaris";
import { ShopContext } from "../ShopContext";

export function ShopProductCurrency({index, product_id, id = null, country = '', currency = '', originalAmount, amount, editable}){
  const[Country, setCountry] = useState(country);
  const[Currency, setCurrency] = useState(currency);
  const[oAmount, setOAmount] = useState(originalAmount);
  const[amnt, setAmnt] = useState(amount);
  const[edit, setEdit] = useState(editable);
  const {
    states: {
      shopData,
    }, actions: {
      storeProductPrice,
    },
  } = useContext(ShopContext);

  const save = () => {
    const bodyObj = {
      id: id,
      product_id: product_id,
      original_amount: oAmount,
      amount: amnt,
      country: Country,
      currency: Currency,
    }
    storeProductPrice(index, bodyObj);
    setEdit((prev) => !prev);
  }

  return(
    <tr style={{display: "flex", padding: "1em 0", gap: "20px"}}>
      <td  className="plan-column" >
        <Select
          disabled={!edit}
          style={{maxWidth: "100%"}}
          className="plan-field"
          onChange={(e)=>{setCountry(e.target.value)}}
          value={Country}
        >
          {
            shopData.countries.map((country, key) => {
              return (
                <MenuItem key={key} value={country.value}>{country.label}</MenuItem>
              )
            })
          }
        </Select>
      </td>
      <td className="plan-column" >  
        <Select
          disabled={!edit}
          style={{maxWidth: "100%"}}
          className="plan-field"
          onChange={(e)=>{setCurrency(e.target.value)}}
          value={Currency}
        >
          {
            shopData.currencies.map((currency, key) => {
              return (
                <MenuItem key={key} value={currency}>{currency}</MenuItem>
              )
            })
          }
        </Select>
      </td>
      <td className="plan-column">
        <TextField disabled={!edit} type="number" className="plan-field" value={oAmount} onChange={(e)=>{setOAmount(e.target.value)}}/>
      </td>
      <td className="plan-column">
        <TextField disabled={!edit} type="number" className="plan-field" value={amnt} onChange={(e)=>{setAmnt(e.target.value)}}/>
      </td>
      <td className="plan-button">
        <Button disabled={shopData.newPriceLoading.find(loading => loading) ? true : false} loading={shopData.newPriceLoading.length ? shopData.newPriceLoading[index] : false} style={{backgroundColor: "rgba(0, 128, 96, 1)", padding: "1rem 3rem", cursor: "pointer"}} textAlign="bottom" primary onClick={() => {edit ? save() : setEdit((prev)=>(!prev))}}>{edit ? 'Save' : 'Edit'}</Button>
      </td>
    </tr>
  )
}

export default ShopProductCurrency