import React, { useCallback, useState, useEffect } from "react";
import {
    Thumbnail,
    TextStyle,
    Card,
    Toast,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import { useHistory, useParams } from "react-router-dom";
import Lightbox from "react-awesome-lightbox";
import './files.css'

const FVFiles = ({title, type, planType, clinicStatus, clinicName, status, pageTitle}) => {
    const { userId } = useParams();
    const history = useHistory();
    const [impresionsAndPhotosResponse, setImpresionsAndPhotosResponse] = useState([]);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [imagesArray, setImagesArray] = useState([]);
    const [isMallCollaboration, setIsMallCollaboration] = useState(false);
    const [active, setActive] = useState(false);
    const [toastMessage, setToastMessage] = useState('Account Disactivated');
    const toggleActive = useCallback(() => setActive((active) => !active), []);
    const toastMarkup = active ? <Toast content={toastMessage} onDismiss={toggleActive} /> : null;

    function downloadPictures() {
        axios.get(`admin/v1/users/${userId}/download-photos`, {
            headers: {
                Authorization: "Bearer " + Cookie.get("token"),
            },
        }).then((result) => {
            var win = window.open(
                "https://api.basma.com/storage/" + result.data.data,
                "_blank"
            );
            win.focus();
            if (result.status !== 200){
                setToastMessage("No images found to download");
            }
        }).catch(
            (err) => toggleActive(),
            setToastMessage("No images found to download")
        );
    }

    function handleShowLargeImage(e) {
        setIsOpen(!isOpen);
        setPhotoIndex(
            imagesArray.indexOf(findArrayElementById(imagesArray, e.currentTarget.id))
        );
    }

    function findArrayElementById(array, id) {
        return array.find((element) => {
            return element.id === id;
        });
    }

    const handleEditStep3 = () => {
        history.push(`/admin/users/${userId}/dashboard/editimpressionsandphotosstep3`,
            {
                clinicName,
                planType,
                status,
                pageTitle,
                clinicStatus,
                userId,
            }
        );
    }

    const handleEditStep4 = () => {
        history.push(`/admin/users/${userId}/dashboard/editimpressionsandphotosstep4`,
            {
                clinicName,
                planType,
                status,
                pageTitle,
                clinicStatus,
                userId,
            }
        );
    }

    useEffect(() => {
        axios.get(`admin/v1/users/${userId}/impressions&photos`, {
            headers: {
                Authorization: "Bearer " + Cookie.get("token"),
            },
        }).then(response => {
            setIsMallCollaboration(response.data.clinic?.is_mall_collaboration);
            const responseImpressionsAndPhotos = response.data.data;
            responseImpressionsAndPhotos && setImpresionsAndPhotosResponse(responseImpressionsAndPhotos);
            setImagesArray([
                {
                    id: "step2",
                    image:
                        responseImpressionsAndPhotos.step2 &&
                            responseImpressionsAndPhotos.step2.first_test_impression_url
                            ? responseImpressionsAndPhotos.step2.first_test_impression_url
                            : "",
                },
                {
                    id: "step3a",
                    image:
                        responseImpressionsAndPhotos.step3 &&
                            responseImpressionsAndPhotos.step3.center_bite_url
                            ? responseImpressionsAndPhotos.step3.center_bite_url
                            : "",
                },
                {
                    id: "step3b",
                    image:
                        responseImpressionsAndPhotos.step3 &&
                            responseImpressionsAndPhotos.step3.left_bite_url
                            ? responseImpressionsAndPhotos.step3.left_bite_url
                            : "",
                },
                {
                    id: "step3c",
                    image:
                        responseImpressionsAndPhotos.step3 &&
                            responseImpressionsAndPhotos.step3.right_bite_url
                            ? responseImpressionsAndPhotos.step3.right_bite_url
                            : "",
                },

                {
                    id: "step3d",
                    image:
                        responseImpressionsAndPhotos.step3 &&
                            responseImpressionsAndPhotos.step3.upper_teeth_url
                            ? responseImpressionsAndPhotos.step3.upper_teeth_url
                            : "",
                },
                {
                    id: "step3e",
                    image:
                        responseImpressionsAndPhotos.step3 &&
                            responseImpressionsAndPhotos.step3.lower_teeth_url
                            ? responseImpressionsAndPhotos.step3.lower_teeth_url
                            : "",
                },
                {
                    id: "step3f",
                    image:
                        responseImpressionsAndPhotos.step3 &&
                            responseImpressionsAndPhotos.step3.front_facing_no_smile_url
                            ? responseImpressionsAndPhotos.step3.front_facing_no_smile_url
                            : "",
                },
                {
                    id: "step3g",
                    image:
                        responseImpressionsAndPhotos.step3 &&
                            responseImpressionsAndPhotos.step3.front_facing_big_smile_url
                            ? responseImpressionsAndPhotos.step3.front_facing_big_smile_url
                            : "",
                },
                {
                    id: "step3h",
                    image:
                        responseImpressionsAndPhotos.step3 &&
                            responseImpressionsAndPhotos.step3.right_side_profile_no_smile_url
                            ? responseImpressionsAndPhotos.step3
                                .right_side_profile_no_smile_url
                            : "",
                },
                {
                    id: "step4a",
                    image:
                        responseImpressionsAndPhotos.step4 &&
                            responseImpressionsAndPhotos.step4.x_ray_url
                            ? responseImpressionsAndPhotos.step4.x_ray_url
                            : "",
                },
                {
                    id: "step4b",
                    image:
                        responseImpressionsAndPhotos.step4 &&
                            responseImpressionsAndPhotos.step4.gum_score_url
                            ? responseImpressionsAndPhotos.step4.gum_score_url
                            : "",
                },
            ]);
        });
    }, []);

    return (
        <>
            {
                !isMallCollaboration &&
                <Card
                    title={title}
                    primaryFooterAction={{
                        content: "Download Pictures",
                        onClick: downloadPictures,
                    }}
                >
                    <Card.Section
                        title="Step3"
                        actions={[
                            {
                                content: "Edit",
                                onClick: handleEditStep3,
                                disabled: String(Cookie.get("userType")) === "50" && true,
                            },
                        ]}
                    >
                        <div className='ik-bas-wrapper'>
                            <div className='ik-bas-s3-wrapper'>
                                <div id="step3a" onClick={(e) => handleShowLargeImage(e)}>
                                    <Thumbnail size="large" source={impresionsAndPhotosResponse?.step3?.center_bite_url ? impresionsAndPhotosResponse.step3.center_bite_url : ""} />
                                </div>
                                <TextStyle>Center Bite</TextStyle>
                            </div>

                            <div className='ik-bas-s3-wrapper'>
                                <div id="step3b" onClick={(e) => handleShowLargeImage(e)}>
                                    <Thumbnail size="large" source={impresionsAndPhotosResponse?.step3?.left_bite_url ? impresionsAndPhotosResponse.step3.left_bite_url : ""} />
                                </div>
                                <TextStyle>Left Bite</TextStyle>
                            </div>
                            <div className='ik-bas-s3-wrapper'>
                                <div id="step3c" onClick={(e) => handleShowLargeImage(e)}>
                                    <Thumbnail size="large" source={impresionsAndPhotosResponse?.step3?.right_bite_url ? impresionsAndPhotosResponse.step3.right_bite_url : ""} />
                                </div>
                                <TextStyle>Right Bite</TextStyle>
                            </div>
                            <div className='ik-bas-s3-wrapper'>
                                <div id="step3d" onClick={(e) => handleShowLargeImage(e)}>
                                    <Thumbnail size="large" source={impresionsAndPhotosResponse?.step3?.upper_teeth_url ? impresionsAndPhotosResponse.step3.upper_teeth_url : ""} />
                                </div>
                                <TextStyle>Upper Teeth</TextStyle>
                            </div>
                            <div className='ik-bas-s3-wrapper'>
                                <div id="step3e" onClick={(e) => handleShowLargeImage(e)}>
                                    <Thumbnail size="large" source={impresionsAndPhotosResponse?.step3?.lower_teeth_url ? impresionsAndPhotosResponse.step3.lower_teeth_url : ""} />
                                </div>
                                <TextStyle>Lower Teeth</TextStyle>
                            </div>
                            <div className='ik-bas-s3-wrapper'>
                                <div id="step3f" onClick={(e) => handleShowLargeImage(e)}>
                                    <Thumbnail size="large" source={impresionsAndPhotosResponse?.step3?.front_facing_no_smile_url ? impresionsAndPhotosResponse.step3.front_facing_no_smile_url : ""} />
                                </div>
                                <div className='ik-bas-txt'>
                                    <TextStyle>Front Facing</TextStyle><br />
                                    <TextStyle>No Smile</TextStyle>
                                </div>
                            </div>
                            <div className='ik-bas-s3-wrapper'>
                                <div id="step3g" onClick={(e) => handleShowLargeImage(e)}>
                                    <Thumbnail size="large" source={impresionsAndPhotosResponse?.step3?.front_facing_big_smile_url ? impresionsAndPhotosResponse.step3.front_facing_big_smile_url : ""} />
                                </div>
                                <div className='ik-bas-txt'>
                                    <TextStyle>Front Facing</TextStyle><br />
                                    <TextStyle>Big Smile</TextStyle>
                                </div>
                            </div>
                            <div className='ik-bas-s3-wrapper'>
                                <div id="step3h" onClick={(e) => handleShowLargeImage(e)}>
                                    <Thumbnail size="large" source={impresionsAndPhotosResponse?.step3?.right_side_profile_no_smile_url ? impresionsAndPhotosResponse.step3.right_side_profile_no_smile_url : ""} />
                                </div>
                                <div className='ik-bas-txt'>
                                    <TextStyle>Right Side Profile</TextStyle><br />
                                    <TextStyle>No Smile</TextStyle>
                                </div>
                            </div>
                        </div>
                    </Card.Section>
                    <Card.Section
                        title="Step4"
                        actions={[
                            {
                                content: "Edit",
                                onClick: handleEditStep4,
                                disabled: String(Cookie.get("userType")) === "50" && true,
                            },
                        ]}
                    >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div className='ik-bas-s3-wrapper'>
                                <div id="step4a" onClick={(e) => handleShowLargeImage(e)} >
                                    <Thumbnail
                                        size="large"
                                        source={
                                            impresionsAndPhotosResponse?.step4?.x_ray_url
                                                ? impresionsAndPhotosResponse.step4.x_ray_url
                                                : ""
                                        }
                                    />
                                </div>
                                <TextStyle>X-Ray</TextStyle>
                            </div>
                        </div>
                    </Card.Section>
                </Card>
            }
            {isOpen && <Lightbox startIndex={photoIndex} onClose={() => setIsOpen(false)} images={imagesArray.map((val) => val.image !== null && val.image)}></Lightbox>}
            {toastMarkup}
        </>
    );
};

export default FVFiles;
