import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  FormLayout,
  Layout,
  Modal,
  TextStyle,
} from "@shopify/polaris";
import CustomTooltip from "../CustomTooltip";
import Cookie from "js-cookie";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TextArea } from "semantic-ui-react";

const WrittenOff = ({ userId, writtenOff, setStatus }) => {
  const [content, setContent] = useState({
    request_reason: writtenOff?.request_reason,
    requested_at: writtenOff?.requested_at,
    requested_by: writtenOff?.requested_by,
    approved_at: writtenOff?.approved_at,
    approved_by: writtenOff?.approved_by,
    completed_by: writtenOff?.completed_by,
    completed_at: writtenOff?.completed_at,
  });
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const {
    requested_at,
    requested_by,
    approved_at,
    approved_by,
    completed_at,
    completed_by,
    request_reason,
  } = content;

  const handleRequestNoteChange = (e) => {
    const { value } = e.target;
    setContent((prevState) => ({ ...prevState, request_reason: value }));
  };

  const [loading, setLoading] = useState(false);
  const handleChangeWriteOff = async (type = "") => {
    let data = {};
    if (type === "request") {
      data = { request_reason };
      handleClose();
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/v1/users/${userId}/write-off-status`,
        data,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        }
      );
      if (response) {
        const { message, writeOffUser, success } = response.data;
        if (success) {
          setContent({
            request_reason: writeOffUser.request_reason,
            requested_at: writeOffUser.requested_at,
            requested_by: writeOffUser.requested_by,
            completed_by: writeOffUser.completed_by,
            completed_at: writeOffUser.completed_at,
            approved_by: writeOffUser.approved_by,
            approved_at: writeOffUser.approved_at,
          });
          if (writeOffUser.completed_at) {
            setStatus((prev) => ({ ...prev, status: "Written Off", flag: "" }));
          }
          toast.success(message, {autoClose: 3000});
        } else {
          toast.error(message, {autoClose: 3000});
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  let badge = <Badge status="primary">None</Badge>;
  let buttonWriteOff = (
    <Button primary onClick={handleOpen}>
      Request Write Off
    </Button>
  );

  if (requested_at && !completed_at && !approved_at) {
    badge = <Badge status="critical">Write-Off Requested</Badge>;
    buttonWriteOff = (
      <Button primary onClick={handleChangeWriteOff}>
        Approve Write Off
      </Button>
    );
  } else if (requested_at && !completed_at && approved_at) {
    badge = <Badge status="attention">Write-Off Approved</Badge>;
    buttonWriteOff = (
      <Button primary onClick={handleChangeWriteOff}>
        Complete Write Off
      </Button>
    );
  } else if (requested_at && completed_at && approved_at) {
    badge = <Badge status="success">Write-Off Completed</Badge>;
    buttonWriteOff = "";
  }

  return (
    <Layout.Section>
      <Modal open={open} onClose={handleClose} title={"Request Write Off"}>
        <div style={{ padding: "20px", textAlign: "center" }}>
          <TextArea
            rows={8}
            style={{ width: "90%" }}
            value={request_reason}
            onChange={handleRequestNoteChange}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "30px",
            }}
          >
            <Button
              disabled={!request_reason}
              primary
              onClick={() => handleChangeWriteOff("request")}
            >
              Send
            </Button>
            <Button onClick={handleClose}>Close</Button>
          </div>
        </div>
      </Modal>
      <div id="write-off">
        <Card
          sectioned
          title={
            <div className="written-off-badge">
              <h2
                className="Polaris-Heading"
                style={{ display: "inline-block", marginRight: "10px" }}
              >
                PAYMENT WRITE-OFF
              </h2>
              {badge}
            </div>
          }
        >
          <FormLayout>
            <div style={{ margin: "10px 0" }}>
              {request_reason && (
                <>
                  <TextStyle>Reason: {request_reason}</TextStyle>
                  <br />
                </>
              )}
              {requested_at && requested_by && (
                <>
                  <TextStyle>
                    Requested at: {moment(requested_at).format("DD-MM-YYYY")}
                  </TextStyle>
                  <br />
                  <TextStyle>Requested by: {requested_by}</TextStyle>
                  <br />
                </>
              )}
              {approved_at && approved_by && (
                <>
                  <TextStyle>
                    Approved at: {moment(approved_at).format("DD-MM-YYYY")}
                  </TextStyle>
                  <br />
                  <TextStyle>Approved by: {approved_by}</TextStyle>
                  <br />
                </>
              )}
              {completed_at && completed_by && (
                <>
                  <TextStyle>
                    Completed at: {moment(completed_at).format("DD-MM-YYYY")}
                  </TextStyle>
                  <br />
                  <TextStyle>Completed by: {completed_by}</TextStyle>
                </>
              )}
            </div>
            {buttonWriteOff}
          </FormLayout>
        </Card>
      </div>
      <Modal open={loading} loading={true}></Modal>
    </Layout.Section>
  );
};

export default WrittenOff;
