import { Button, Card, Page } from '@shopify/polaris';
import React from 'react';
import history from '../../history';

const SingleClinic = ({
  title,
  address,
  clinicData,
}) => {
  const bookScanHandler = (clinic) => {
        history.push({pathname : `book-a-visit`, 
        state:{
            city_id : clinic.city_id,
            clinic_id : clinic.id,
            country_id : clinic.city.country_id,
            link: clinic.youcanbookme_link    
        }})
  };
  return (
    <Page 
    >
        <Card>
      <Card.Section>
        <div style={{padding:"20px",clear:"both", height:"25vh" , gridGap:"5px"}}>
            <div style={{float:"left"}}>
                <h1><b>Clinic details</b></h1>
                <h1>{title}</h1>
                <p>{address}</p>
            </div>
       <div style={{float:"right"}}>
        <Button primary
            onClick={bookScanHandler.bind(this, clinicData)}
            >
            Choose Clinic
        </Button>
        </div>
        </div>
    </Card.Section>
      </Card>
    </Page>
  );
};

export default SingleClinic;
