import { Button, Card } from "@shopify/polaris";
import AsyncSelect from "react-select/async";

function LinkUserSelect({
  loadingUser,
  getUserByName,
  setSelectedUser,
  linkedUser,
  selectedUser,
  handleUnLinkUser,
  handleLinkUser,
  asyncSelectRef,
}) {
  const handleChange = (selected) => {
    setSelectedUser(selected);
  };

  return (
    <Card sectioned>
      <div
        style={{
          display: "flex",
          width: "100%",
          gap: "10px",
          alignItems: "center",
        }}
      >
        {linkedUser.value ? (
          <p>{linkedUser.label}</p>
        ) : (
          <div style={{ width: "50%" }}>
            <AsyncSelect
              ref={asyncSelectRef}
              menuPosition="fixed"
              menuPlacement="auto"
              onChange={handleChange}
              loadOptions={getUserByName}
              isLoading={loadingUser}
              placeholder={linkedUser.label}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
            />
          </div>
        )}

        <Button
          primary
          disabled={!linkedUser.value && !selectedUser}
          onClick={linkedUser.value ? handleUnLinkUser : handleLinkUser}
        >
          {loadingUser
            ? "Loading..."
            : linkedUser.value
              ? "Unlink User"
              : "Link User"}
        </Button>
      </div>
    </Card>
  );
}

export default LinkUserSelect;
