import { Button, Card, DataTable, Page, TextStyle } from "@shopify/polaris";
import history from "../history";
import { useLocation, useParams } from "react-router-dom";
import React, { useState } from "react";
import axios from "../axios";
import Cookie from "js-cookie";
import { toast, ToastContainer } from "react-toastify";

function ViewTrackingHistory() {
  const [trackingHistory, setTrackingHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { trackingId } = useParams();
  let userId = "";
  if (location.state) {
    userId = location.state.userId;
  }

  const handleBack = () => {
    history.push(`/admin/users/${userId}/dashboard/`);
  };

  const handleRetrieve = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `admin/v1/trackings/${trackingId}/history`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        },
      );
      if (response.status === 200) {
        const { trackingHistory } = response.data.data;
        const { message, success } = response.data;
        if (trackingHistory && trackingHistory.length > 0) {
          setTrackingHistory(
            trackingHistory.map((item) => [
              item.date,
              item.location,
              item.description,
            ]),
          );
        }
        if (success) {
          toast.success(message, 3000);
        } else {
          toast.error(message, 3000);
        }
      }
    } catch (error) {
      console.log("Error in handleRetrieve", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page
      fullWidth
      title="Tracking History"
      breadcrumbs={[{ onAction: handleBack }]}
    >
      <ToastContainer />
      <Card sectioned>
        {trackingHistory && trackingHistory.length > 0 ? (
          <DataTable
            columnContentTypes={["text", "text", "text", "text"]}
            headings={[
              <TextStyle variation="strong">Date</TextStyle>,
              <TextStyle variation="strong">Location</TextStyle>,
              <TextStyle variation="strong">Activity</TextStyle>,
            ]}
            rows={trackingHistory}
            sortable={[false, false, false]}
          />
        ) : (
          <p style={{ display: "flex", justifyContent: "center" }}>
            <Button loading={loading} onClick={handleRetrieve}>
              View Tracking History
            </Button>
          </p>
        )}
      </Card>
    </Page>
  );
}

export default ViewTrackingHistory;
