import React, { useCallback, useState, useEffect } from "react";
import {
  FormLayout,
  Card,
  TextStyle,
  Thumbnail,
  Toast,
} from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../../../axios";
import "@zendeskgarden/react-pagination/dist/styles.css";
import { useParams } from "react-router-dom";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import "./Photos.css";

export default function Step3Impressions(props) {
  let { id } = useParams();
  const [impresionsAndPhotosResponse, setImpresionsAndPhotosResponse] =
    useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [imagesArray, setImagesArray] = useState([]);
  const [active, setActive] = useState(false);
  const [toastMessage, setToastMessage] = useState("Account Disactivated");
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content={toastMessage} onDismiss={toggleActive} />
  ) : null;
  useEffect(() => {
    Promise.all([
      axios.get("admin/v1/users/" + id + "/impressions&photos/teleconsult-photos", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }),
    ]).then(function (response) {
      const responseImpressionsAndPhotos = response[0].data.data;
      responseImpressionsAndPhotos &&
        setImpresionsAndPhotosResponse(responseImpressionsAndPhotos);
      setImagesArray([
        {
          id: "step3a",
          image:
            responseImpressionsAndPhotos.step3 &&
            responseImpressionsAndPhotos.step3.center_bite_url
              ? responseImpressionsAndPhotos.step3.center_bite_url
              : "",
        },
        {
          id: "step3b",
          image:
            responseImpressionsAndPhotos.step3 &&
            responseImpressionsAndPhotos.step3.left_bite_url
              ? responseImpressionsAndPhotos.step3.left_bite_url
              : "",
        },
        {
          id: "step3c",
          image:
            responseImpressionsAndPhotos.step3 &&
            responseImpressionsAndPhotos.step3.right_bite_url
              ? responseImpressionsAndPhotos.step3.right_bite_url
              : "",
        },

        {
          id: "step3d",
          image:
            responseImpressionsAndPhotos.step3 &&
            responseImpressionsAndPhotos.step3.upper_teeth_url
              ? responseImpressionsAndPhotos.step3.upper_teeth_url
              : "",
        },
        {
          id: "step3e",
          image:
            responseImpressionsAndPhotos.step3 &&
            responseImpressionsAndPhotos.step3.lower_teeth_url
              ? responseImpressionsAndPhotos.step3.lower_teeth_url
              : "",
        },
        {
          id: "step3f",
          image:
            responseImpressionsAndPhotos.step3 &&
            responseImpressionsAndPhotos.step3.front_facing_no_smile_url
              ? responseImpressionsAndPhotos.step3.front_facing_no_smile_url
              : "",
        },
        {
          id: "step3g",
          image:
            responseImpressionsAndPhotos.step3 &&
            responseImpressionsAndPhotos.step3.front_facing_big_smile_url
              ? responseImpressionsAndPhotos.step3.front_facing_big_smile_url
              : "",
        },
        {
          id: "step3h",
          image:
            responseImpressionsAndPhotos.step3 &&
            responseImpressionsAndPhotos.step3.right_side_profile_no_smile_url
              ? responseImpressionsAndPhotos.step3
                  .right_side_profile_no_smile_url
              : "",
        },
        {
          id: "step4a",
          image:
            responseImpressionsAndPhotos.step4 &&
            responseImpressionsAndPhotos.step4.x_ray_url
              ? responseImpressionsAndPhotos.step4.x_ray_url
              : "",
        },
        {
          id: "step4b",
          image:
            responseImpressionsAndPhotos.step4 &&
            responseImpressionsAndPhotos.step4.gum_score_url
              ? responseImpressionsAndPhotos.step4.gum_score_url
              : "",
        },
      ]);
    });
  }, []);

  return (
    <div className="photosContainer">
      <h1 className="photosTitle">PHOTOS</h1>

      <Card sectioned>
        {" "}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            rowGap: 30,
            margin: 40,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginInlineStart: 20,
              alignItems: "center",
            }}
          >
            <div
              id="step3a"
              style={{
                marginBottom: 10,
              }}
              onClick={(e) => handleShowLargeImage(e)}
            >
              <Thumbnail
                size="large"
                source={
                  impresionsAndPhotosResponse?.step3?.center_bite_url
                    ? impresionsAndPhotosResponse.step3.center_bite_url
                    : ""
                }
              />
            </div>
            <TextStyle>Center Bite</TextStyle>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginInlineStart: 20,
              alignItems: "center",
            }}
          >
            <div
              style={{ marginBottom: 10 }}
              id="step3b"
              onClick={(e) => handleShowLargeImage(e)}
            >
              <Thumbnail
                size="large"
                source={
                  impresionsAndPhotosResponse?.step3?.left_bite_url
                    ? impresionsAndPhotosResponse.step3.left_bite_url
                    : ""
                }
              />
            </div>{" "}
            <TextStyle>Left Bite</TextStyle>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginInlineStart: 20,
              alignItems: "center",
            }}
          >
            <div
              style={{ marginBottom: 10 }}
              id="step3c"
              onClick={(e) => handleShowLargeImage(e)}
            >
              <Thumbnail
                size="large"
                source={
                  impresionsAndPhotosResponse?.step3?.right_bite_url
                    ? impresionsAndPhotosResponse.step3.right_bite_url
                    : ""
                }
              />{" "}
            </div>{" "}
            <TextStyle>Right Bite</TextStyle>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginInlineStart: 20,
              alignItems: "center",
            }}
          >
            <div
              style={{ marginBottom: 10 }}
              id="step3d"
              onClick={(e) => handleShowLargeImage(e)}
            >
              <Thumbnail
                size="large"
                source={
                  impresionsAndPhotosResponse?.step3?.upper_teeth_url
                    ? impresionsAndPhotosResponse.step3.upper_teeth_url
                    : ""
                }
              />
            </div>{" "}
            <TextStyle>Upper Teeth</TextStyle>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginInlineStart: 20,
              alignItems: "center",
            }}
          >
            <div
              style={{ marginBottom: 10 }}
              id="step3e"
              onClick={(e) => handleShowLargeImage(e)}
            >
              <Thumbnail
                size="large"
                source={
                  impresionsAndPhotosResponse?.step3?.lower_teeth_url
                    ? impresionsAndPhotosResponse.step3.lower_teeth_url
                    : ""
                }
              />
            </div>{" "}
            <TextStyle>Lower Teeth</TextStyle>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginInlineStart: 20,
              alignItems: "center",
            }}
          >
            <div
              style={{ marginBottom: 10 }}
              id="step3f"
              onClick={(e) => handleShowLargeImage(e)}
            >
              <Thumbnail
                size="large"
                source={
                  impresionsAndPhotosResponse?.step3?.front_facing_no_smile_url
                    ? impresionsAndPhotosResponse.step3
                        .front_facing_no_smile_url
                    : ""
                }
              />
            </div>{" "}
            <div>
              <TextStyle>Front Facing</TextStyle> <br />
            </div>
            <div>
              {" "}
              <TextStyle>No Smile</TextStyle>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginInlineStart: 20,
              alignItems: "center",
            }}
          >
            <div
              style={{ marginBottom: 10 }}
              id="step3g"
              onClick={(e) => handleShowLargeImage(e)}
            >
              <Thumbnail
                size="large"
                source={
                  impresionsAndPhotosResponse?.step3?.front_facing_big_smile_url
                    ? impresionsAndPhotosResponse.step3
                        .front_facing_big_smile_url
                    : ""
                }
              />
            </div>{" "}
            <div>
              <TextStyle>Front Facing</TextStyle> <br />
            </div>
            <div>
              {" "}
              <TextStyle>Big Smile</TextStyle>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginInlineStart: 20,
              alignItems: "center",
            }}
          >
            <div
              style={{ marginBottom: 10 }}
              id="step3h"
              onClick={(e) => handleShowLargeImage(e)}
            >
              <Thumbnail
                size="large"
                source={
                  impresionsAndPhotosResponse?.step3
                    ?.right_side_profile_no_smile_url
                    ? impresionsAndPhotosResponse.step3
                        .right_side_profile_no_smile_url
                    : ""
                }
              />
            </div>{" "}
            <div>
              <TextStyle>Right Side Profile</TextStyle> <br />
            </div>
            <div>
              {" "}
              <TextStyle>No Smile</TextStyle>
            </div>
          </div>
          {impresionsAndPhotosResponse ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginInlineStart: 20,
                  alignItems: "center",
                }}
              >
                <div
                  id="step4a"
                  onClick={(e) => handleShowLargeImage(e)}
                  style={{ marginBottom: 10 }}
                >
                  <Thumbnail
                    size="large"
                    source={
                      impresionsAndPhotosResponse?.step4?.x_ray_url
                        ? impresionsAndPhotosResponse.step4.x_ray_url
                        : ""
                    }
                  />
                </div>
                <TextStyle>X-Ray</TextStyle>
              </div>
        ) : null}
        </div>
        
        
      </Card>
      {isOpen && (
        <Lightbox
          startIndex={photoIndex}
          onClose={() => setIsOpen(false)}
          images={imagesArray.map((val) => val.image !== null && val.image)}
        ></Lightbox>
      )}
      {toastMarkup}
    </div>
  );
  function handleShowLargeImage(e) {
    setIsOpen(!isOpen);
    setPhotoIndex(
      imagesArray.indexOf(findArrayElementById(imagesArray, e.currentTarget.id))
    );
  }
  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return element.id === id;
    });
  }
}
