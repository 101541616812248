import React, { useEffect, useState, useRef, useCallback } from "react";
import ReactHtmlParser from "react-html-parser";
import IPRSheet from "./IPRSheet";
import { Modal } from "@shopify/polaris";
import Cookie from "js-cookie";
import html2pdf from "html2pdf.js";
import * as moment from "moment";
import IRPPatientData from "./IRPPDF/IRPPatientData";
import UpperTeeth from "./IRPPDF/UpperTeeth";
import LeftRight from "./IRPPDF/LeftRight";
import ButtomTeeth from "./IRPPDF/ButtomTeeth";
import Notes from "./IRPPDF/Notes";
import IRPFooter from "./IRPPDF/IRPFooter";
import IRPHeader from "./IRPPDF/IRPHeader";
import "./IRPPDF/IRP.css";
import axios from "../../axios";
import { saveAs } from 'file-saver';
import JSZip from "jszip";
function IPRSheetModal({ Iprs, setIprs, treatmentStage, userId ,iprData,setIPRFileKey, handeChangeIPRkeyFile, fullName}) {
  const [groupTeethDist, setGroupTeethDist] = useState([]);
  const [groupTeeth, setGroupTeeth] = useState([]);
  const [sheets, setSheets] = useState([]);
  const [pdfData, setPdfData] = useState({});
  const [loadingGeneartingPdf, setLoadingGeneratingPdf] = useState(false);
  const NewDate = moment(new Date(), "DD-MM-YYYY");
  const [isSuccess, setIsSuccess] = useState(false);
  const [generateSheets,setGenerateSheets]=useState(false)

  useEffect(() => {
    setGroupTeethDist(Array(Iprs.length).fill(new Map()));
    setGroupTeeth(
      Array(Iprs.length).fill([
        { num: 18, attachment: false },
        { num: 17, attachment: false },
        { num: 16, attachment: false },
        { num: 15, attachment: false },
        { num: 14, attachment: false },
        { num: 13, attachment: false },
        { num: 12, attachment: false },
        { num: 11, attachment: false },
        { num: 21, attachment: false },
        { num: 22, attachment: false },
        { num: 23, attachment: false },
        { num: 24, attachment: false },
        { num: 25, attachment: false },
        { num: 26, attachment: false },
        { num: 27, attachment: false },
        { num: 28, attachment: false },
        { num: 48, attachment: false },
        { num: 47, attachment: false },
        { num: 46, attachment: false },
        { num: 45, attachment: false },
        { num: 44, attachment: false },
        { num: 43, attachment: false },
        { num: 42, attachment: false },
        { num: 41, attachment: false },
        { num: 31, attachment: false },
        { num: 32, attachment: false },
        { num: 33, attachment: false },
        { num: 34, attachment: false },
        { num: 35, attachment: false },
        { num: 36, attachment: false },
        { num: 37, attachment: false },
        { num: 38, attachment: false },
      ])
    );
   setSheets (
      Iprs.map((sheet, key) => {
        return { step: Iprs[key].step!=0?Iprs[key].step:"S1", notes: "",procedureID:Iprs[key].id ,position:Iprs[key].step_position};
      })
    );
  }, [Iprs]);
  const handleCheckboxChange = (key, ind) => {
    setGroupTeeth((prev) => {
      const newArray = [...prev];
      const sheetArray = [...newArray[ind]];
      const updatedObject = {
        ...sheetArray[key],
        attachment: !sheetArray[key].attachment,
      };
      sheetArray[key] = updatedObject;
      newArray[ind] = sheetArray;
      return newArray;
    });
  };
  const handleDistanceChange = (val, id, ind, toChange, side) => {
    let mapKey;
    if (
      (side === "left" && toChange === "d") ||
      (side === "right" && toChange === "m") ||
      (side === "right" && toChange === "m2")
    ) {
      mapKey = groupTeeth[ind][id - 1].num + "" + groupTeeth[ind][id].num; //1817
    } else {
      mapKey = groupTeeth[ind][id].num + "" + groupTeeth[ind][id + 1].num; //1817
    }
    setGroupTeethDist((prev) => {
      let arr = [...prev];
      const mapToUpdate = new Map(arr[ind]);
      let obj = arr[ind].has(mapKey)
        ? { ...arr[ind].get(mapKey) }
        : { distance: "", d: false, m: false ,m2:false};
      mapToUpdate.set(mapKey, { ...obj, [toChange]: val });
      arr[ind] = mapToUpdate;
      return arr;
    });
  };
  const handleTextChange = (val, ind) => {
    setSheets((prev) => {
      let arr = [...prev];
      arr[ind] = { ...arr[ind], notes: val };
      return arr;
    });
  };
  const PDFSize = Object.keys(pdfData).length;
  function formatDate(date) {
    if (date !== undefined && date !== "") {
      var myDate = new Date(date);
      var month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ][myDate.getMonth()];
      var str = myDate.getDate() + " " + month + " " + myDate.getFullYear();
      return str;
    }
    return "";
  }
  useEffect(() => {
    if (Object.keys(iprData).length > 0) {
      const newGroupTeethDist = groupTeethDist.map((currentMap, index) => {
        const currentKey = Object.keys(iprData)[index];
        const currentData = iprData[currentKey] ? iprData[currentKey][0] : null;

        if (currentData && currentData.spacing) {
          const spacingData = new Map();
          Object.entries(currentData.spacing).forEach(([key, value]) => {
            spacingData.set(key, value);
          });
          return spacingData;
        } else {
          return currentMap;
        }
      });

      setGroupTeethDist(newGroupTeethDist);

      const backendAttachments = Object.values(iprData).map(
        (arr) => arr[0]?.attachments
      );

      const updatedGroupTeeth = groupTeeth.map((currentAttachments, index) => {
        if (backendAttachments[index]) {
          return backendAttachments[index];
        } else {
          return currentAttachments;
        }
      });
      setGroupTeeth(updatedGroupTeeth);

      const backendSheets = Object.keys(iprData).reduce((acc, key) => {
        const dataItem = iprData[key][0];
        acc[key] = { 
            step: iprData[key][1], 
            notes: dataItem?.notes,
            procedureID: key, 
            position: iprData[key][2] 
        };
        return acc;
      }, {});

      const updatedSheets = Iprs.map((sheet, index) => {
        const backendSheet = backendSheets[index];
        if (backendSheet) {
          return {
            ...backendSheet,
            procedureID: sheet.id,
            position: sheet.step_position,
          };
        } else {
          return {
            step: sheet.step !== 0 ? sheet.step : "S1",
            notes: "",
            procedureID: sheet.id,
            position: sheet.step_position,
          };
        }
      });
      setSheets(updatedSheets);
    }
  }, [iprData]);

useEffect(()=>{
  axios
      .post("admin/v1/ipr_data", pdfData, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        setIsSuccess(true);
      })
      .catch((err) => console.log("error in submiting Ipr sheet", err));
  },[pdfData]
);
  const [pdfVisibility, setPdfVisibility] = useState("none");
  const handlePdfGeneration = () => {
    setLoadingGeneratingPdf(true);
    let data = {};
    sheets.forEach((sheet, ind) => {
      let obj = {};
      obj.procedure_id = Iprs[ind].id;
      obj.step=Iprs[ind].step;
      obj.position=Iprs[ind].step_position;
      obj.attachments = groupTeeth[ind];
      obj.spacing = Object.fromEntries(groupTeethDist[ind]);
      obj.notes = sheet.notes;
      let comments = [...groupTeethDist[ind]];
      let i = 0;
      let arr = [];
      while (i < comments.length) {
        if (
          comments[i][0].substr(0, 1) == "1" ||
          comments[i][0].substr(0, 1) === "4"
        ) {
          if( comments[i][0]=="1121"|| comments[i][0]=="4131"){
            if (
              comments[i][1].distance == "" &&
              comments[i][1].d == false &&
              comments[i][1].m == false &&
              comments[i][1].m2 == false 
            ) {
            }else{
            if (comments[i][1].distance != "" && comments[i][1].m == true) {
              arr.push(
                `${comments[i][1].distance} mm IPR to be done between ${comments[
                  i
                ][0].substr(2, 2)} and ${comments[i][0].substr(
                  0,
                  2
                )} on the MESIAL SIDE of ${comments[i][0].substr(0, 2)} only.`
              );
            }
            if (comments[i][1].distance != "" && comments[i][1].m2 == true) {
              arr.push(
                `${comments[i][1].distance} mm IPR to be done between ${comments[
                  i
                ][0].substr(2, 2)} and ${comments[i][0].substr(
                  0,
                  2
                )} on the MESIAL SIDE of ${comments[i][0].substr(2, 2)} only.`
              );
            }
          }
        }else{
          if (
            comments[i][1].distance == "" &&
            comments[i][1].d == false &&
            comments[i][1].m == false
          ) {
          }
          if (comments[i][1].distance != "" && comments[i][1].m == true) {
            arr.push(
              `${comments[i][1].distance} mm IPR to be done between ${comments[
                i
              ][0].substr(2, 2)} and ${comments[i][0].substr(
                0,
                2
              )} on the MESIAL SIDE of ${comments[i][0].substr(0, 2)} only.`
            );
          }
          if (comments[i][1].distance != "" && comments[i][1].d == true) {
            arr.push(
              `${comments[i][1].distance} mm IPR to be done between ${comments[
                i
              ][0].substr(2, 2)} and ${comments[i][0].substr(
                0,
                2
              )} on the DISTAL SIDE of ${comments[i][0].substr(2, 2)} only.`
            );
          }
          
        }
        } else {
          if (
            comments[i][1].distance == "" &&
            comments[i][1].d == false &&
            comments[i][1].m == false
          ) {
          }
          if (comments[i][1].distance != "" && comments[i][1].m == true) {
            arr.push(
              `${comments[i][1].distance} mm IPR to be done between ${comments[
                i
              ][0].substr(2, 2)} and ${comments[i][0].substr(
                0,
                2
              )} on the MESIAL SIDE of ${comments[i][0].substr(2, 2)} only.`
            );
          }
          if (comments[i][1].distance != "" && comments[i][1].d == true) {
            arr.push(
              `${comments[i][1].distance} mm IPR to be done between ${comments[
                i
              ][0].substr(2, 2)} and ${comments[i][0].substr(
                0,
                2
              )} on the DISTAL SIDE of ${comments[i][0].substr(0, 2)} only.`
            );
          }
        }
        if (
          comments[i][1].distance !== "" &&
          comments[i][1].d == false &&
          comments[i][1].m == false &&
          comments[i][1].m2 ==false  
        ) {
          arr.push(
            `${comments[i][1].distance} mm IPR to be done between ${comments[
              i
            ][0].substr(2, 2)} and ${comments[i][0].substr(0, 2)}`
          );
        }
        ++i;
      }
      let attachs = [];
      let attachmentText = "Attachments on teeth";
      let j = 0;
      while (j < obj.attachments.length) {
        if (obj.attachments[j].attachment == true) {
          attachs.push(obj.attachments[j].num);
        }
        j++;
      }
      if (attachs != null) {
        let attachmentNumberText = 0;
        while (attachmentNumberText < attachs.length) {
          attachmentText =
            attachmentText +
            (attachmentNumberText == 0 ? " " : ", ") +
            (attachmentNumberText == attachs.length - 1
              ? "and " + attachs[attachmentNumberText] + "."
              : attachs[attachmentNumberText]);
          attachmentNumberText++;
        }
        if (attachmentText != "Attachments on teeth") {
          arr.push(attachmentText);
        }
      }
      obj.comments = arr;
      data[sheet.procedureID] = obj;
    
    });
   
    setGenerateSheets(true);
    setPdfVisibility("block");
    setPdfData({...data});
    
  }
  const sendIPRFile = useCallback(
    async (fileIPR,filename,id,userId) => {
        setLoadingGeneratingPdf(true);
      try {
          let url2;
          const form_data = new FormData();
          form_data.append("content_type","application/pdf");
          form_data.append("file_name", filename);
          const res = await axios.post("/admin/v1/images/s3", form_data, {
              headers: {
                  Authorization: "Bearer " + Cookie.get("token"),
              },
          });
          setIPRFileKey(res.data.key);
          handeChangeIPRkeyFile(res.data.key);
          url2 = res.data.url;
          await axios.put(url2, fileIPR, {
              headers: {
                  "x-amz-acl": "public-read-write",
                  "Content-Type": " application/pdf",
              },
          });
          await axios.put(
              "admin/v1/add_procedure_url",
              { procedure_id: id, url: res.data.key, user_id: userId },
              {
                  headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + Cookie.get("token"),
                  },
              }
          );
      } catch (e) {
          console.log("err",e)
      }
    },
    []
  );
  
  const IprHandler = async () => {
      // generate multiple download pdf
      Iprs.forEach((ipr, index) => {
          let pdf = document.getElementById(`ipr-sheet-${index}`);
          html2pdf(pdf, {
              margin: 1,
              padding: 0,
              filename: `Step ${pdfData[Object.keys(pdfData)[index]].step} ${pdfData[Object.keys(pdfData)[index]].position!=null?pdfData[Object.keys(pdfData)[index]].position:""} - ${fullName} - ${treatmentStage} - IPR and Attachments - ${userId}.pdf`,
              image: { type: "jpeg", quality: 1 },
              html2canvas: { dpi: 192, scale: 5, letterRendering: true, useCORS: true },
              jsPDF: { unit: "cm", format: "a4", orientation: "p" },
          }).outputPdf().then((pdfBytes) => {
              if (pdfBytes) {
                  setPdfVisibility("none");
                  function stringToUint8Array(str) {
                      const arr = new Uint8Array(str.length);
                      for (let i = 0; i < str.length; i++) {
                          arr[i] = str.charCodeAt(i);
                      }
                      return arr;
                  }
                  const byteArray = stringToUint8Array(pdfBytes);
                  const blob = new Blob([byteArray], { type: 'application/pdf' });
                  let fileName=`Step ${pdfData[Object.keys(pdfData)[index]].step} ${pdfData[Object.keys(pdfData)[index]].position!=null?pdfData[Object.keys(pdfData)[index]].position:""} - ${fullName} - ${treatmentStage} - IPR and Attachments - ${userId}.pdf`;
                  sendIPRFile(blob,fileName, pdfData[Object.keys(pdfData)[index]].procedure_id,userId);
              } else {
                  console.error("No PDF bytes returned.");
              }
          }).catch(function (error) {
              console.error("Error generating PDFBytes:", error);
          });
      });

      setTimeout(() => setLoadingGeneratingPdf(false), Iprs.length * 10000);
  }
  
  useEffect(() => {
    if(pdfData !== {} && Iprs.length > 0 && generateSheets) {
        void IprHandler();
    //  // zip file
    // Promise.all(
    //   Iprs.map((ipr, index) => {
    //     let pdf = document.getElementById(`ipr-sheet-${index}`);
    //     return html2pdf()
    //       .set({
    //         margin: 1,
    //         filename: `file_${index + 1}.pdf`,
    //         image: { type: "jpeg", quality: 1 },
    //         html2canvas: { dpi: 192, scale: 5, letterRendering: true, useCORS: true },
    //         jsPDF: { unit: "cm", format: "a4", orientation: "p" },
    //       })
    //       .from(pdf)
    //       .outputPdf()
    //   }
    //   )     
    // )
    //   .then((pdfFiles) => {
    //     const zip = new JSZip();
    //     pdfFiles.forEach((pdfFile, index) => {
    //       zip.file(`Step ${Iprs[index].step} - ${PatientName} - ${treatmentStage} - IPR - ${userId}.pdf`, pdfFile, { binary: true });
    //     });
    //     zip.generateAsync({ type: "blob" }).then((content) => {
    //       saveAs(content, `${PatientName} - IPR ${userId}.zip`);
    //     });
    //   setPdfVisibility("none")          
    //   })
    //   .catch((error) => {
    //     console.error("Error generating PDF files:", error);
    //   });
}}, [pdfData, Iprs,generateSheets])
  return (
    <div className="iprsheet">
      <Modal
        open={Iprs.length ? true : false}
        large
       primaryAction={{ content: "Save IPR", onAction: handlePdfGeneration, loading: loadingGeneartingPdf }}
        secondaryActions={[
          {
            content: "Close IPR",
            disabled: loadingGeneartingPdf,
            onAction: () => {
              setIprs([]);
              setGenerateSheets(false);
              setPdfData({})
              setSheets([]);
              setGroupTeeth([]);
              setGroupTeethDist([]);
            },
          },
        ]}
      >
        <Modal.Section>
          <div>
            {Iprs.length &&
              Iprs.map((Ipr, key) => (
                <IPRSheet
                  sheetNumber={key}
                  groupTeeth={groupTeeth}
                  sheets={sheets}
                  groupTeethDist={groupTeethDist}
                  handleCheckboxChange={handleCheckboxChange}
                  handleDistanceChange={handleDistanceChange}
                  handleTextChange={handleTextChange}
                ></IPRSheet>
              ))}
          </div>
        </Modal.Section>
        <div style={{ display: pdfVisibility }}>
            {
            Object.entries(pdfData).map(([Key, Value], index) => (
              
          <div id={`ipr-sheet-${index}`}>
            
              <div key={index} className="pdf-style">
                <IRPHeader />
                <IRPPatientData
                  Key={Value.step}
                  position={Value.position}
                  PatientName={fullName}
                  PatientID={userId}
                  date={formatDate(NewDate)}
                  TratmentStage={treatmentStage}
                />
                <div className="tooth-checkbox-container-pdf">
                  <div className="vl"></div>
                  <UpperTeeth Value={Value} />
                  <LeftRight />
                  <ButtomTeeth Value={Value} />
                </div>
                <Notes Value={Value} />
                <IRPFooter index={index} size={PDFSize} Value={Value} />
              </div>
            </div>
            ))}
        </div>
      </Modal>
    </div>
  );
}

export default IPRSheetModal;
