import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(() => ({
  table: {
    '& tr th, & tr td': {
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif !important',
      fontSize: '1em',
      textAlign: 'center',
    },
    '& tr th': {
      fontWeight: 'bold',
    },
    '& tr th:nth-child(n+1):nth-child(-n+5)': {
      zIndex: 31,
    },
    '& tr td:nth-child(n+1):nth-child(-n+5)': {
      zIndex: 30,
      position: 'sticky',
      backgroundColor: '#fff',
    },
    '& tr td:nth-child(5), tr th:nth-child(5)': {
      borderRight: '1px lightgray solid',
    },
  },
}));
