import React, { useState, useEffect } from "react";
import {
  Card,
  TextStyle,
  DataTable,
  Pagination,
  DisplayText,
  ButtonGroup,
  Button,
  Modal,
  TextContainer,
  TextField,
  FormLayout,
  Thumbnail,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import emptyIcon from "../../../img/emptyList.svg";
import axios from "../../../axios";
import Cookie from "js-cookie";
import moment from "moment";
import EllipsisText from "react-ellipsis-text";
import Lightbox from "react-awesome-lightbox";
import MultipleUploadPhotos from "./MultipleUploadPhotos";

const Comments = (props) => {
  const { id } = useParams();
  const { plan_id, showComment } = props
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isListEmpty, setIsListEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [clickedRow, setClickedRow] = useState(-1);
  const [items, setItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [imagesArray, setImagesArray] = useState([]);
  const [filesImage, setFilesImage] = useState([]);
  const [imagesKey,setImageKey] =useState([])
  const [images, setImages] =useState([])

  function handleShowLargeImage(images,key) {
    setIsOpen(!isOpen);
    setPhotoIndex(key);
    setImagesArray([...images])
  }
  const emtyState = isListEmpty && (
    <div className="message-no-result">
      <img src={emptyIcon} className="message-no-result-img"></img>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  );
  const [commentValue, setCommentValue] = useState("");
  const [active, setActive] = useState(false);
  const handleChange = () => {
    setActive(!active)
    setFilesImage([])
    setImageKey([]);
    setImages([])
  };
  
  const activator = <Button onClick={handleChange}>Open</Button>;
  const [isNew, setIsNew] = useState(true);

  const fetchComments = ()=>{
    axios
    .get(`admin/v1/users/${id}/smile-plan/${plan_id}/comments`, {
      headers: {
        Authorization: "Bearer " + Cookie.get("token"),
      },
    })
    .then((result) => {
      result.data.data.length !== 0
        ? setIsListEmpty(false)
        : setIsListEmpty(true);
      result.data.data &&
        setItems(
          result.data.data.map((item,key) => [
            item.owner?.full_name,
            item.comment && (
              <EllipsisText text={item.comment} length={1000} />
            ),
            <div style={{display:"flex", gap: "10px"}}>{item.images_url?.length>0&&item.images_url.map((image,key)=>
              <div  onClick={(e) => handleShowLargeImage(item.images_url,key)}>
                <Thumbnail
                size="medium"
                source={
                  image.image
                    ? image.image
                    : ""
                }/>
              </div>  
                )}</div>,
            moment(item.created_at)
              .add(2, "hours")
              .format("YYYY-MM-DD hh:mm:ss"),
            item.id,
            <ButtonGroup>
              <Button
                id={String(item.id) + "_" + item.comment}
                onClick={(e) => handleEdit(e,item.images_url)}
              >
                Edit
              </Button>
            </ButtonGroup>,
          ])
        );
    })
    .catch((err) => console.log(err));
  } 
  useEffect(() => {
    if(showComment) {
      fetchComments()
    }
  }, [currentPage]);

  function handleAddNewRow() {
    setIsNew(true);
    setCommentValue("");
    setActive(true);
  }
  function handleButtonSubmit(e) {
    setIsLoading(true)
    if (isNew) {
      const bodyObj = {
        comment: commentValue,
        images :imagesKey
      };
    if(plan_id){
      axios
      .post(`admin/v1/users/${id}/smile-plan/${plan_id}/comments`, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        fetchComments()
        setActive(false);
        setIsListEmpty(false);
        setIsLoading(false)
        handleChange()
      })
      .catch((err) => console.log(err));
    }
    } else {
      const bodyObj = {
        comment: commentValue,
        images:imagesKey
      };
      axios
        .post(
          `admin/v1/users/${id}/smile-plan/comments/${clickedRow}/update`,
          bodyObj,
          {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          }
        )
        .then((result) => {
         fetchComments();
          setActive(false);
          setIsLoading(false)
          handleChange()
        })
        .catch((err) => console.log(err));
    }
    
  }
  function handleEdit(e,images) {
    setIsNew(false);
    setClickedRow(e.currentTarget.id.split("_")[0]);
    setCommentValue(e.currentTarget.id.split("_")[1]);
    setActive(true);
    setImages(images)
    setImageKey(images.map((image)=>image.image))
  }
  function handleDelete(e) {
    axios
      .delete(`admin/v1/users/${id}/smile-plan/comments/${clickedRow}/delete`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setItems(items.filter((item) => item[4] != clickedRow));
        setActive(false);
        items.filter((item) => item[4] != clickedRow).length !== 0
          ? setIsListEmpty(false)
          : setIsListEmpty(true);
      })
      .catch((err) => console.log(err));
  }
if(!showComment){
  return <div className='full-width-tp-section'>
  <Card sectioned >
    <FormLayout>
        <Button
          primary
          onClick={() => props.save('/yoursmileplan/update')}
          disabled={props.disabled}
        >
          Add Comments
        </Button>
        <p>Add comments on this Treatment Plan before submitting</p>
        </FormLayout>
    </Card>
    </div>
}else{
  return (
    <div className='full-width-tp-section'>
      <Card
        actions={[{ content: "Add New", onClick: handleAddNewRow }]}
      >
        <DataTable
          columnContentTypes={["text", "text", "text", "text"]}
          headings={[
            <TextStyle variation="strong">Actor</TextStyle>,
            <TextStyle variation="strong">Comment</TextStyle>,
            <TextStyle variation="strong">Additional Images</TextStyle>,
            <TextStyle variation="strong">Created At</TextStyle>,
            <TextStyle variation="strong">ID</TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
          ]}
          rows={items}
          verticalAlign="middle"
        />
        {totalPages >= 2 && (
          <div style={{ marginTop: "20px" }}>
            <Pagination
              hasPrevious={currentPage > 1 && true}
              onPrevious={() => {
                setCurrentPage(currentPage - 1);
              }}
              hasNext={currentPage < totalPages && true}
              onNext={() => {
                setCurrentPage(currentPage + 1);
              }}
              label={`${currentPage}/${totalPages}`}
            />
          </div>
        )}
        {emtyState}
      </Card>
      {activator &&
        (isNew ? (
          <Modal
            // activator={activator}
            open={active}
            onClose={handleChange}
            primaryAction={[
              {
                content: "Submit",
                disabled:isLoading,
                onAction: handleButtonSubmit,
              },
            ]}
            secondaryActions={{
              content: "Cancel",
              disabled:isLoading,
              onAction: handleChange,
            }}
          >
            <Modal.Section>
              <TextContainer>
                <TextField
                    multiline={3}
                    label="Comment"
                    value={commentValue}
                    onChange={(value) => {
                      setCommentValue(value);
                    }}
                />
              </TextContainer>
              <MultipleUploadPhotos setFilesImage={setFilesImage} filesImage={filesImage} imagesKey={imagesKey} setImageKey={setImageKey}/>
            </Modal.Section>
          </Modal>
        ) : (
          <Modal
            // activator={activator}
            open={active}
            onClose={handleChange}
            secondaryActions={[
              {
                content: "Cancel",
                disabled:isLoading,
                onAction: handleChange,
              },
              {
                content: "Delete",
                disabled:isLoading,
                onAction: handleDelete,
                destructive: true,
              },
            ]}
            primaryAction={[
              {
                content: "Submit",
                disabled:isLoading,
                onAction: handleButtonSubmit,
              },
            ]}
          >
            <Modal.Section>
              <TextContainer>
                <TextField
                    label="Comment"
                    multiline={3}
                    value={commentValue}
                    onChange={(value) => {
                      setCommentValue(value);
                    }}
                />
              </TextContainer>
              <MultipleUploadPhotos setFilesImage={setFilesImage} images={images} setImages={setImages} filesImage={filesImage} imagesKey={imagesKey} setImageKey={setImageKey}/>
            </Modal.Section>
          </Modal>
        ))}
        {isOpen && imagesArray?.length&& <Lightbox startIndex={photoIndex} onClose={() => setIsOpen(false)} images={imagesArray.map((val) => val.image !== null && val.image)}></Lightbox>}
    </div>
  );
}
 
};

export default React.memo(Comments);
