import { forwardRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide,
} from '@mui/material';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const WarningRegenerateDispatches = ({
  setOpen,
  open,
  handleSave,
  warningMessage,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleOnSave = () => {
    handleClose();
    handleSave(1);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ minWidth: '600px', fontSize: '16px' }}>{warningMessage}</DialogTitle>
      <DialogActions>
        <Button sx={{ fontSize: '12px' }} onClick={handleClose}>Close</Button>
        <Button sx={{ fontSize: '12px' }} onClick={handleOnSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningRegenerateDispatches;
