import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  title: {
    fontSize: '22px !important',
  },
  details: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    flexBasis: '23%',
    maxWidth: '23%',
    wordBreak: 'break-word',
    margin: '5px 1%',
    backgroundColor: 'rgba(39,49,122,.03)',
    borderRadius: '5px',
    '& h6': {
      color: '#666666',
      fontSize: '16px',
    },
    '& span': {
      fontWeight: 500,
    },
  },
  empty: {
    margin: '0 auto',
  },
}));
