import React, { useState } from "react";
import {
  Page,
  Loading,
} from "@shopify/polaris";
import PaymentPlan from "./PaymentPlan";

export default function ComplexPlan() {
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const loadingMarkup = isLoading ? <Loading /> : null;

  return (
    <Page
      fullWidth
      title="Complex Plan"
      breadcrumbs={[{ content: "List Of Plans", url: "/admin/plans" }]}
    >
      {" "}
      {loadingMarkup}
      <PaymentPlan plantype='complex' payment="stripe"/>
      <PaymentPlan plantype='complex' payment="tabby"/>
    </Page>
  );

}
