import {
  Button as PolarisButton,
} from "@shopify/polaris";
import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "../axios";
import axiosAws from "axios";
import ProcedureHeader from "./ProcedureHeader";
import Cookies from "js-cookie";
import Save from "./Save";
import ScreeningPhotoUpload from "./ScreeningPhotoUpload";
import AlertDeleteDialog from "./Popup/alert-delete-dialog.component";
import { IssueTypes, ReviewStatus } from "../util/constants";
import DisapprovalModal from "./DisapprovalModal";
import ApprovalActions from "./ApprovalActions";

const useStyles = makeStyles(() => {
  return {
    wrapper: {
      padding: '2rem',
      boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    },
    uploadBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    float: {
      float: 'right',
    },
    input: {
      padding: '0.5rem 1.2rem',
      border: '1px lightgray solid',
      borderRadius: '5px',
      margin: '15px 0',
    },
    mediaWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '2rem',
      marginBottom: '1rem',
    }
  };
});

const AppointmentButtons = ({
  handleTabsExpand,
  appointmentId,
  appointmentNumber,
  openCloseTabs,
  index,
  appointmentType,
  userId,
  scrollToExpandedTab,
}) => {
  const classes = useStyles();
  const [buttons, setButtons] = useState(null);
  const [buttonsIcon, setButtonsIcon] = useState(null);
  const [success, setSuccess] = useState(false);
  const [popupActive, setPopupActive] = useState(false);
  const [errorFilesUpload, setErrorFilesUpload] = useState('');
  const [addFilesKey, setAddFilesKey] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [callHandleSave, setCallHandleSave] = useState(0);
  const [imageKey, setImageKey] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [photoId, setPhotoId] = useState(null);
  const [reviewData, setReviewData] = useState({
    comment: '',
    commentError: '',
    loading: false,
    modalOpened: false,
    status: ''
  });
  const [issues, setIssues] = useState([]);
  const [options, setOptions] = useState([]);
  const [fileImages, setFileImages] = useState([]);
  const [images, setImages] = useState([]);
  const [imageKeys, setImageKeys] = useState([]);
  const [photos, setPhotos] = useState([]);

  const handleDataFetched = () => {
    setPopupActive(true);
    axios.get(`admin/v2/users/case-record-buttons/${appointmentId}/show?procedure=${appointmentType?.procedure}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then(res => {
      if (res.data.success) {
        if(!res.data.data.review_status){
          setReviewData(prev => ({
            ...prev,
            disabled: false,
          }));
        }
        setButtons(res.data.data);
        setButtonsIcon(res.data.isAllProvided);
        let files = res.data.data?.files;
        if(files?.length) {
          let arr = [];
          files.forEach(file => {
            arr.push({id: file.name, url: file.file_url});
          });
          setPhotos(arr);
        }
      } else {
        setButtonsIcon(0);
      }
    }).catch(err => {
      console.log('fetch error', err);
    }).finally(() => {
      setPopupActive(false);
      setLoading(false);
    })
  };

  const handleChangeModal = () => {
    setReviewData(prev => ({
      ...prev,
      modalOpened: false,
    }));
    setIssues([]);
  }

  const handleChangeComment = (newValue) => {
    setReviewData(prev => ({
      ...prev,
      comment: newValue,
    }));
  }

  const handleChangeReview = (status) => {
    if(status == ReviewStatus.approved){
      handleReviewStatus(status);
    } else {
      setReviewData(prev => ({
        ...prev,
        modalOpened: true,
        status,
      }));
    }
  }

  const handleReviewStatus = (status) => {
    if (status != ReviewStatus.approved && !issues.length && !reviewData.comment) {
      setReviewData(prev => ({
        ...prev,
        commentError: 'Please enter a comment',
      }));
    } else {
      setReviewData(prev => ({
        ...prev,
        disabled: true,
        loading: true,
      }));
      let body = {
        review_status: status,
        procedure: appointmentType?.procedure,
        appointment_id: appointmentId,
      }

      if(status != ReviewStatus.approved){
        let comment = `
          Appointment ${appointmentNumber} - ${appointmentType?.procedure}<br/>
          Status: Photos ${status}
        `;
        if (options.length && !issues.length) {
          return;
        }
        if(issues.length) {
          comment += '<br/>Buttons issues: ';
          comment += issues.map(issue => issue.label).join(', ');
        }
        if(reviewData.comment) {
          comment += `<br/>Notes: ${reviewData.comment}`;
        }
        body = {
          ...body,
          comment,
          images: imageKeys,
          issues: issues.map(issue => issue.id),
        };
      }
      axios.post(`/admin/v2/users/case-record-buttons/${buttons?.id}/approval-status`, body, {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('token'),
        },
      }).then((res) => {
        if (res.data.success) {
          setButtons(prev => ({
            ...prev,
            reviewer: res.data.data?.reviewer,
            review_status: res.data.data?.review_status,
            reviewed_at: res.data.data?.reviewed_at,
          }));
        }
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        setReviewData(prev => ({
          ...prev,
          disabled: false,
          loading: false,
          modalOpened: false,
        }));
      });
    }
  };

  const uploadConfig = (progressEvent, name) => {
    if (progressEvent.loaded === progressEvent.total) {
      delete uploadProgress[name];
      setUploadProgress(uploadProgress);
    } else {
      const array = {
        ...uploadProgress,
        [name]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
      };
      setUploadProgress(array);
    }
  };

  const handleUploadError = (err) => {
    setLoading(false);
    setErrorFilesUpload('Upload failed. Please try again.');
    setImageKey(null);
    console.log(err);
  };

  const handleImages = (e, id) => {
    setErrorFilesUpload('');
    setLoading(true);
    const file = e.target.files[0];
    let imageType = file.type;
    const form_data = new FormData();
    form_data.append("content_type", imageType);
    form_data.append("file_name", file.name);
    axios.post("/admin/v1/images/s3", form_data, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((res) => {
      if(imageKey){
        setImageKey(null);
      }
      setAddFilesKey([
        {
          file_type: imageType,
          file: res.data.key,
          name: id,
        },
      ]);
      const url2 = res.data.url;
      axiosAws.put(url2, file, {
        headers: {
          "x-amz-acl": "public-read-write",
          "Content-Type": imageType,
        },
        onUploadProgress: (event) => uploadConfig(event, id),
      }).then((res) => {
        setCallHandleSave(1);
      }).catch((err) => {
        handleUploadError(err, id);
      });
    }).catch((err) => {
      handleUploadError(err, id);
    })
  };

  const handleClickOpen = (id) => {
    setPhotoId(id);
    setOpen(true);
  };

  const handleDelete = () => {
    setLoading(true);
    axios.delete(`admin/v2/case-record-buttons/delete/${photoId}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((res) => {
      handleDataFetched();
    }).catch((err) => {
      setErrorFilesUpload("Delete failed. Please try again.");
      console.log(err);
    }).finally(() => {
      setLoading(false);
    });
  }

  const handleSave = async () => {
    setErrorFilesUpload('');
    let body = {
      procedure: appointmentType.procedure,
      user_scan_info_id: appointmentId,
    };
    if(addFilesKey.length){
      body.files = addFilesKey;
    }
    try {
      const response = await axios.post(`/admin/v2/users/${userId}/case-record-buttons`, body, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });
      if (response.status === 200) {
        if (addFilesKey.length > 0) {
          setAddFilesKey([]);
        }
        handleDataFetched();
      }
    } catch (error) {
      setSuccess(false);
    }
  };

  useEffect(() => {
    handleDataFetched();
  }, []);

  useEffect(() => {
    if (callHandleSave === 1) {
      handleSave();
      setCallHandleSave(0);
    }
  }, [callHandleSave]);

  useEffect(() => {
    scrollToExpandedTab(index, 'buttons');
  }, [openCloseTabs]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type='buttons'
      headerTitle={<p><span className="procedure-header-title">Photos:</span> <span className='header-title-gray'>{appointmentType?.procedure}</span></p>}
      index={index}
      iconCheck={buttonsIcon}
    >
      {openCloseTabs[index]?.buttons && (
        <Box className={`${classes.wrapper} buttons-${index}`}>
          {popupActive ?
            <div style={{ textAlign: 'center' }}>
              <CircularProgress color='primary' />
            </div> :
            <>
              <div className={classes.mediaWrapper}>
                <ScreeningPhotoUpload
                  disabled={loading}
                  value={buttons?.upper_url}
                  fileType={buttons?.upper_type}
                  handleImages={handleImages}
                  id='upper_occlusal'
                  title='Upper Occlusal'
                  uploadProgress={uploadProgress?.upper_occlusal}
                  handleDelete={() => handleClickOpen(buttons?.upper_id)}
                  deleteDisabled={loading || !buttons?.upper_id}
                  imagesArray={photos}
                  identifier={`buttons-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={loading}
                  value={buttons?.lower_url}
                  fileType={buttons?.lower_type}
                  handleImages={handleImages}
                  id='lower_occlusal'
                  title='Lower Occlusal'
                  uploadProgress={uploadProgress?.lower_occlusal}
                  handleDelete={() => handleClickOpen(buttons?.lower_id)}
                  deleteDisabled={loading || !buttons?.lower_id}
                  imagesArray={photos}
                  identifier={`buttons-${appointmentType?.procedure}`}
                />
              </div>
              <div className={classes.mediaWrapper}>
                <ScreeningPhotoUpload
                  disabled={loading}
                  value={buttons?.center_url}
                  fileType={buttons?.center_type}
                  handleImages={handleImages}
                  id='center'
                  title='Center'
                  uploadProgress={uploadProgress?.center}
                  handleDelete={() => handleClickOpen(buttons?.center_id)}
                  deleteDisabled={loading || !buttons?.center_id}
                  imagesArray={photos}
                  identifier={`buttons-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={loading}
                  value={buttons?.left_url}
                  fileType={buttons?.left_type}
                  handleImages={handleImages}
                  id='left'
                  title='Left'
                  uploadProgress={uploadProgress?.left}
                  handleDelete={() => handleClickOpen(buttons?.left_id)}
                  deleteDisabled={loading || !buttons?.left_id}
                  imagesArray={photos}
                  identifier={`buttons-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={loading}
                  value={buttons?.right_url}
                  fileType={buttons?.right_type}
                  handleImages={handleImages}
                  id='right'
                  title='Right'
                  uploadProgress={uploadProgress?.right}
                  handleDelete={() => handleClickOpen(buttons?.right_id)}
                  deleteDisabled={loading || !buttons?.right_id}
                  imagesArray={photos}
                  identifier={`buttons-${appointmentType?.procedure}`}
                />
              </div>
              <br />
              <ApprovalActions
                disabled={buttonsIcon == 0 || reviewData.disabled || buttons?.review_status != null}
                reviewStatus={buttons?.review_status}
                reviewer={buttons?.reviewer}
                reviewedAt={buttons?.reviewed_at}
                handleChangeReview={handleChangeReview}
              />
              <Save uploadedBy={buttonsIcon != 0 ? buttons?.owner_name : ''} handleSave={handleSave} success={success} errorMessage={errorFilesUpload} />
            </>
          }
        </Box>
      )}
      {reviewData.modalOpened && (
        <DisapprovalModal
          activeModal={reviewData.modalOpened}
          handleChangeModal={handleChangeModal}
          title={reviewData.status === ReviewStatus.approvedWithIssues ? 'Approve With Issues' : 'Disapprove Buttons Photos'}
          dropDownTitle='Buttons Photos Issues'
          handleSubmitDisapprove={() => handleReviewStatus(reviewData.status)}
          loading={reviewData.loading}
          commentValue={reviewData.comment}
          handleChangeComment={handleChangeComment}
          commentError={reviewData.commentError}
          setFilesImage={setFileImages}
          filesImage={fileImages}
          images={images}
          setImages={setImages}
          imagesKey={imageKeys}
          setImageKey={setImageKeys}
          issueType={IssueTypes.buttonsNotApproved}
          setIssues={setIssues}
          issues={issues}
          options={options}
          setOptions={setOptions}
        />
      )}
      <AlertDeleteDialog open={open} setOpen={setOpen} handleAction={handleDelete} />
    </ProcedureHeader>
  );
};

export default AppointmentButtons;
  