import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../history";
import Edit from "./Edit";
import NavigationLayout from "../components/NavigationLayout";
import Cities from "./Cities/Main";

function MainEdit() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/admin/book-a-scan-countries/:id" component={Edit} />
        <Route
          path="/admin/book-a-scan-countries/:id/cities"
          component={Cities}
        />
      </Switch>
    </Router>
  );
}
export default MainEdit;
