import React, { useCallback, useEffect, useState } from "react";
import {
  InlineError,
  Page,
  FormLayout,
  TextField,
  Card,
  Modal,
  PageActions,
  Thumbnail,
  Stack,
  DropZone,
  TextStyle,
  Checkbox
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";
import { useParams } from "react-router-dom";
import { NoteMinor } from "@shopify/polaris-icons";
import ReactTagInput from "@pathofdev/react-tag-input";
import Select2 from "react-dropdown-select";
import RichTextEditor from "react-rte";

function Add() {
  const { id, idCity } = useParams();
  const [nameEnValue, setNameEnValue] = useState("");
  const [nameArValue, setNameArValue] = useState("");
  const [descriptionEnValue, setDescriptionEnValue] = useState("");
  const [descriptionArValue, setNameDescriptionArValue] = useState("");
  const [locationInfoEnValue, setLocationInfoEnValue] = useState("");
  const [locationInfoArValue, setLocationInfoArValue] = useState("");
  const [addressEnValue, setAddressEnValue] = useState("");
  const [addressArValue, setAddressArValue] = useState("");
  const [nameIdentificationValue, setNameIdentificationValue] = useState("");
  const [clinicIdValue, setClinicIdValue] = useState("");
  const [longitudeValue, setLongitudeValue] = useState("");
  const [latitudeValue, setLatitudeValue] = useState("");
  const [youCanBookMeLinkValue, setYouCanBookMeLinkValue] = useState("");
  const [locationLinkValue, setLocationLinkValue] = useState("");
  const [contactLinkValue, setContactLinkValue] = useState("");
  const [profileId, setProfileId] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [phoneNumber1Value, setPhoneNumber1Value] = useState("");
  const [phoneNumber2Value, setPhoneNumber2Value] = useState("");
  const [contactPersonValue, setContactPersonValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [notificationEmailValue, setNotificationEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [nameEnError, setNameEnError] = useState("");
  const [nameArError, setNameArError] = useState("");
  const [clinicIdError, setClinicIdError] = useState("");
  const [nameIdentificationError, setNameIdentificationError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmationError, setPasswordConfirmationError] = useState("");
  const [popupActive, setPopupActive] = useState(false);
  const [isClosed, setIsClosed] = useState(false)
  const [files, setFiles] = useState([]);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const fileUpload = !files.length && <DropZone.FileUpload />;
  const [imagePreview, setImagePreview] = useState("");
  const [imageKey, setImageKey] = useState("");
  const [freeDomains, setFreeDomains] = useState([]);
  const [freeBAS, setFreeBAS] = useState(false);
  const [isGhost, setIsGhost] = useState(false);
  const [isReplacedByMainClinicCity, setIsReplacedByMainClinicCity] = useState(false);
  const [isMallCollab, setIsMallCollab] = useState(false);
  const [isMainCityClinic, setIsMainCityClinic] = useState(false);
  const [assignedTo, setAssignedTo] = useState([]);
  const [freeByDefault, setFreeByDefault] = useState(false);
  const [isOffline, setIsOffline] = useState(true);
  const [isScreeningMandatory, setMandatory] = useState(false);
  const [basmaManagedClinic, setBasmaManagedClinic] = useState(false);
  const [selectedWeekends, setSelectedWeekends] = useState([])
  const [optionsWeekend, setOptions] = useState([{label:"Monday",value:1}, {label:"Tuesday",value:2}, {label:"Wednesay",value:3}, {label:"Thursday",value:4}, {label:"Friday",value:5}, {label:"Saturday",value:6}, {label:"Sunday",value:7}]);
  const [productOptions, setProductOptions] = useState([]);
  const [scanner, setScanner] = useState({
    options: [],
    selected: [],
  });
  const [clinicOptions, setClinicOptions] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [content, setContent] = useState(RichTextEditor.createEmptyValue());
  const uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : NoteMinor
        }
      />
      <div>{files[files.length - 1].name} </div>
    </Stack>
  );
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const sendImage = useCallback(
    (files) => {
      setPopupActive(true);
      setImagePreview(
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      let url2 = "";
      const form_data = new FormData();
      form_data.append("content_type", files[files.length - 1].type);
      form_data.append("file_name", files[files.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          console.log("res1");
          setImageKey(res.data.key);
          url2 = res.data.url;
          axios
            .put(url2, files[files.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": files[files.length - 1].type,
              },
            })
            .then((res) => {
              console.log("res2");
              setPopupActive(false);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },

    [files]
  );
  const handleChangeNameEn = useCallback((newValue) => {
    setNameEnValue(newValue);
    setNameEnError("");
  }, []);
  const handleChangeNameAr = useCallback((newValue) => {
    setNameArValue(newValue);
    setNameArError("");
  }, []);
  const handleChangeDescriptionEn = useCallback((newValue) => {
    setDescriptionEnValue(newValue);
  }, []);
  const handleChangeDescriptionAr = useCallback((newValue) => {
    setNameDescriptionArValue(newValue);
  }, []);
  const handleChangeLocationInfoEn = useCallback((newValue) => {
    setLocationInfoEnValue(newValue);
  }, []);

  const handleChangelocationInfoAr = useCallback((newValue) => {
    setLocationInfoArValue(newValue);
  }, []);

  const handleChangeAddressEn = useCallback((newValue) => {
    setAddressEnValue(newValue);
  }, []);
  const handleChangeAddressAr = useCallback((newValue) => {
    setAddressArValue(newValue);
  }, []);
  const handleChangeNameIdentification = useCallback((newValue) => {
    setNameIdentificationValue(newValue);
    setNameIdentificationError("");
  }, []);
  const handleChangeClinicId = useCallback((newValue) => {
    setClinicIdValue(newValue);
    setClinicIdError("")
  }, []);
  const handleChangeLongitude = useCallback((newValue) => {
    setLongitudeValue(newValue);
  }, []);
  const handleChangeLatitude = useCallback((newValue) => {
    setLatitudeValue(newValue);
  }, []);
  const handleChangeYouCanBookMeLink = useCallback((newValue) => {
    setYouCanBookMeLinkValue(newValue);
  }, []);
  const handleChangeLocationLink = useCallback((newValue) => {
    setLocationLinkValue(newValue);
  }, []);
  const handleChangeContactLink = useCallback((newValue) => {
    setContactLinkValue(newValue);
  }, []);
  const handleChangeProfileId = useCallback((newValue) => {
    setProfileId(newValue);
  }, []);
  const handleChangePhoneNumber1 = useCallback((newValue) => {
    setPhoneNumber1Value(newValue);
  }, []);
  const handleChangePhoneNumber2 = useCallback((newValue) => {
    setPhoneNumber2Value(newValue);
  }, []);
  const handleChangeContactPerson = useCallback((newValue) => {
    setContactPersonValue(newValue);
  }, []);
  const handleChangeEmail = useCallback((newValue) => {
    setEmailValue(newValue);
    setEmailError("");
  }, []);
  const handleChangeNotificationEmail = useCallback((newValue) => {
    setNotificationEmailValue(newValue);
  }, []);
  const handleChangePassword = useCallback((newValue) => {
    setPasswordValue(newValue);
    setPasswordError("");
  }, []);
  const handleChangePasswordConfirmation = useCallback((newValue) => {
    setPasswordConfirmation(newValue);   
  }, []);
  const handlePasswordConfirmation = (pass,passConf)=>{
    return pass === passConf;
  }
  function handleReplacedByMainCityClinic(checked) {
    setIsReplacedByMainClinicCity(checked);
  }
  function handleGhostCheck(checked) {
    setIsGhost(checked);
  }
  function handleMallCollabCheck (checked) {
    setIsMallCollab(checked);
  }
  function handleIsMainCityClinicCheck(checked) {
    if(checked && clinicOptions.length == 0) {
      getClinics();
    }
    setIsMainCityClinic(checked);
  }
  function handleCheckboxChange(checked) {
    setFreeBAS(checked);
  }
  function handleCheckboxDefaultChange(checked) {
    setFreeByDefault(checked);
  }
  function handleClosedChange(checked) {
    setIsClosed(checked);
  }
  function handleOnlineDefaultChange(checked) {
    setIsOffline(checked);
  }
  function handleMandatoryDefaultChange(checked) {
    setMandatory(checked);
  }
  function handleBasmaManagedClinic(checked) {
    setBasmaManagedClinic(checked);
  }
  function handleSelectWeekends(newSelectedWeekend) {
    setSelectedWeekends(newSelectedWeekend);
  }
  function handleAssignedTo(assignedClinic) {
    setAssignedTo(assignedClinic);
  }
  function handleSelectProducts(newSelectedProduct) {
    setSelectedProducts(newSelectedProduct);
  }
  function handleSelectScanner(scanner) {
    setScanner(prev => ({
      ...prev,
      selected: scanner,
    }));
  }
  const handleChangeContent = (value) => {
    setContent(value);
  };
  const getProducts = () => {
    axios.get('/admin/v1/products?type=scan_at_home',
      {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }
    ).then((result) => {
      setProductOptions(result.data.data.map(prod => ({label: prod.title?.en, value: prod.id})));
    }).catch((err) => console.log(err));
  }

  const getScanners = () => {
    axios.get('/admin/v1/scanners',
      {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }
    ).then((result) => {
      setScanner(prev => ({
        ...prev,
        options: result.data.data.map(scanner => ({label: `${scanner.name} (${scanner.placeholders} ${scanner.placeholders > 1 ? 'placeholders' : 'placeholder'})`, value: scanner.id})),
      }));
    }).catch((err) => console.log(err));
  }

  const getClinics = () => {
    axios.get(`/admin/v1/countries/${id}/clinics`,
      {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }
    ).then((result) => {
      setClinicOptions(result.data.data.map(clinic => ({label: clinic.name?.en, value: clinic.id})));
    }).catch((err) => console.log(err));
  }

  useEffect(() => {
    getProducts();
    getScanners();
  }, [])

  useEffect(()=>{
    if(handlePasswordConfirmation(passwordValue,passwordConfirmation)){
      setPasswordConfirmationError("");
    }else{
      setPasswordConfirmationError("Password value should be equal");
    }
  },[passwordValue,passwordConfirmation])
  return (
    <Page
      title="Add Clinic"
      breadcrumbs={[
        { url: `/admin/book-a-scan-countries/${id}/cities/${idCity}/clinics` },
      ]}
    >
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              value={nameEnValue}
              onChange={handleChangeNameEn}
              label="Name En"
              align="left"
              error={nameEnError}
            />
            <TextField
              value={nameArValue}
              onChange={handleChangeNameAr}
              label="Name Ar"
              align="right"
              error={nameArError}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              value={descriptionEnValue}
              onChange={handleChangeDescriptionEn}
              label="Description En"
              align="left"
            />
            <TextField
              value={descriptionArValue}
              onChange={handleChangeDescriptionAr}
              label="Description Ar"
              align="right"
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              value={locationInfoEnValue}
              onChange={handleChangeLocationInfoEn}
              label="Location Info En"
              align="left"
            />
            <TextField
              value={locationInfoArValue}
              onChange={handleChangelocationInfoAr}
              label="Location Info Ar"
              align="right"
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              value={addressEnValue}
              onChange={handleChangeAddressEn}
              label="Address En"
              align="left"
            />
            <TextField
              value={addressArValue}
              onChange={handleChangeAddressAr}
              label="Address Ar"
              align="right"
            />
          </FormLayout.Group>

          <FormLayout.Group>
            <TextField
              value={longitudeValue}
              onChange={handleChangeLongitude}
              label="Longitude"
            />
            <TextField
              value={latitudeValue}
              onChange={handleChangeLatitude}
              label="Latitude"
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              value={nameIdentificationValue}
              onChange={handleChangeNameIdentification}
              label="Name Identification"
              error={nameIdentificationError}
            />
            <TextField
              value={clinicIdValue}
              onChange={handleChangeClinicId}
              label="Clinic ID"
              error={clinicIdError}
            />
          </FormLayout.Group>

          <FormLayout.Group>
            <TextField
              value={youCanBookMeLinkValue}
              onChange={handleChangeYouCanBookMeLink}
              label="You can book me Link"
            />
            <TextField
              disabled={!youCanBookMeLinkValue}
              value={profileId}
              onChange={handleChangeProfileId}
              label="Profile ID"
            />
          </FormLayout.Group>
          <FormLayout.Group>
          <TextField
            value={locationLinkValue}
            onChange={handleChangeLocationLink}
            label="Location Link"
          />
            <TextField
            value={contactLinkValue}
            onChange={handleChangeContactLink}
            label="Contact Link"
          />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              value={phoneNumber1Value}
              onChange={handleChangePhoneNumber1}
              label="Phone Number 1"
            />
            <TextField
              value={phoneNumber2Value}
              onChange={handleChangePhoneNumber2}
              label="Phone Number 2"
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              value={emailValue}
              onChange={handleChangeEmail}
              label="Email"
              error={emailError}
            />
            <TextField
              value={notificationEmailValue}
              onChange={handleChangeNotificationEmail}
              label="Notification Email"
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              value={contactPersonValue}
              onChange={handleChangeContactPerson}
              label="Contact Person"
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
                value={passwordValue}
                onChange={handleChangePassword}
                label="Password"
                error={passwordError}
              />
              <TextField
                value={passwordConfirmation}
                onChange={handleChangePasswordConfirmation}
                label="Password Confirmation"
                error={passwordConfirmationError}
              />
          </FormLayout.Group>
          <FormLayout>
            <FormLayout.Group>
               <FormLayout>
                  <TextStyle variation="strong">Information for clinic</TextStyle>
                    <RichTextEditor
                      rootStyle={{
                        fontFamily:
                          "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif",
                      }}
                      className="rich-text-editor RichTextEditor_clinics"
                      value={content}
                      onChange={handleChangeContent}
                    />
                </FormLayout>
            </FormLayout.Group>
          </FormLayout>
          <TextStyle variation="strong">Image</TextStyle>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <Thumbnail
                size="large"
                source={imagePreview ? imagePreview : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZoneDrop} onDropAccepted={sendImage}>
                {uploadedFiles}
                {fileUpload}
              </DropZone>
            </div>
          </div>
          <div style={{display: "flex", alignItems: "center", columnGap: 20}}>
            <Checkbox
              label="Main City Clinic"
              checked={isMainCityClinic}
              onChange={handleIsMainCityClinicCheck}
            />
            {
              isMainCityClinic &&
              <Select2
                placeholder='Assign bookings to this clinic'
                options={clinicOptions}
                onChange={handleAssignedTo}
                values={assignedTo}
              />
            }
          </div>
          <div style={{display: "flex", alignItems: "center"}}>
            <Checkbox
              label="Ghost Clinic"
              checked={isGhost}
              onChange={handleGhostCheck}
            />
          </div>
          <div style={{display: "flex", alignItems: "center"}}>
            <Checkbox
              label="Mall Collaboration"
              checked={isMallCollab}
              onChange={handleMallCollabCheck}
            />
          </div>
          <div style={{display: "flex", alignItems: "center"}}>
            <Checkbox
              label="Replaced by Main City Clinic"
              checked={isReplacedByMainClinicCity}
              onChange={handleReplacedByMainCityClinic}
            />
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{ width: '48%' }}>
              <TextStyle variation='strong'>Scan uploading method</TextStyle>
              <Select2
                placeholder='Select scanner'
                options={scanner.options}
                onChange={handleSelectScanner}
                values={scanner.selected}
              />
            </div>
            <div style={{ width: '48%' }}>
              <TextStyle variation='strong'>Scan at home</TextStyle>
              <Select2
                placeholder='Select Products'
                multi
                options={productOptions}
                onChange={handleSelectProducts}
                values={selectedProducts}
              />
            </div>
          </div>
          <FormLayout.Group>
          <div style={{display: "flex", alignItems: "center"}}>
            <Checkbox
              label="Enable Free BAS"
              checked={freeBAS}
              onChange={handleCheckboxChange}
            />
            <span style={{fontSize: "12px", color: "#d9d9db", marginLeft: "2px"}}> (promo)</span>
          </div>
          <div>
            <span>Free Domains</span><span style={{fontSize: "12px", color: "#d9d9db"}}> (promo)</span>
            <ReactTagInput
              tags={freeDomains} 
              onChange={(newTags) => {setFreeDomains(newTags)}}
              removeOnBackspace
              editable
              validator={(email) => {
                return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ||
                  String(email).toLocaleLowerCase().match(/^@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
                }
              }
            />
          </div>
          <div>
          <TextStyle variation="strong">Weekends</TextStyle>
          <Select2
            placeholder="Select Weekends"
            multi
            options={optionsWeekend}
            onChange={handleSelectWeekends}
            values={selectedWeekends}
          />
          </div>
        </FormLayout.Group>
        <FormLayout.Group>
          <Checkbox
            label="Free by default"
            checked={freeByDefault}
            onChange={handleCheckboxDefaultChange}
          />
          <Checkbox
            label="Is Closed"
            checked={isClosed}
            onChange={handleClosedChange}
          />
          <Checkbox
            label="Is Offline"
            checked={isOffline}
            onChange={handleOnlineDefaultChange}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <Checkbox
            label="Mandatory Screening"
            checked={isScreeningMandatory}
            onChange={handleMandatoryDefaultChange}
          />
          <Checkbox
            label="Basma Managed Clinic"
            checked={basmaManagedClinic}
            onChange={handleBasmaManagedClinic}
          />
        </FormLayout.Group>
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
      <div style={{display: 'flex', justifyContent: 'end'}}>
        <TextStyle variation='negative'>{errorMsg}</TextStyle>
      </div>
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );

  function handleSave() {
    if(errorMsg){
      setErrorMsg('');
    }
    if (!nameIdentificationValue || 
      !nameEnValue ||
      !nameArValue ||
      !emailValue ||
      !passwordValue || 
      !passwordConfirmation ||
      !clinicIdValue ||
      !handlePasswordConfirmation(passwordValue,passwordConfirmation) 
      
      ) {
        !nameIdentificationValue && setNameIdentificationError("Please enter a name Identification");
        !nameArValue && setNameArError('Please enter a name');
        !nameEnValue && setNameEnError('Please enter a name');
        !emailValue && setEmailError('Please enter an email');
        !passwordValue && setPasswordError('Please enter a password');
        !passwordConfirmation && setPasswordConfirmationError("Please enter a password")
        !clinicIdValue && setClinicIdError("Please enter a clinic id")
        passwordConfirmation && !handlePasswordConfirmation(passwordValue,passwordConfirmation) && setPasswordConfirmationError('Password should be equal');
    } else {
      setPopupActive(true);
      const bodyObj = {
        name: {
          ...(nameEnValue && { en: nameEnValue }),
          ...(nameArValue && { ar: nameArValue }),
        },
        description: {
          ...(descriptionEnValue && { en: descriptionEnValue }),
          ...(descriptionArValue && { ar: descriptionArValue }),
        },
        address: {
          ...(addressEnValue && { en: addressEnValue }),
          ...(addressArValue && { ar: addressArValue }),
        },
        location_info: {
          ...(locationInfoEnValue && { en: locationInfoEnValue }),
          ...(locationInfoArValue && { ar: locationInfoArValue }),
        },
        ...(nameIdentificationValue && {
          name_identification: nameIdentificationValue,
        }),
        ...(clinicIdValue && {
          clinic_id: clinicIdValue,
        }),
        ...(longitudeValue && {
          long: longitudeValue,
        }),
        ...(latitudeValue && {
          lat: latitudeValue,
        }),
        ...(youCanBookMeLinkValue && {
          youcanbookme_link: youCanBookMeLinkValue,
        }),
        ...(locationLinkValue && {
          location_link: locationLinkValue,
        }),
        ...(contactLinkValue && {
          contact_link: contactLinkValue,
        }),
        ...(profileId && {
          profile_id: profileId,
        }),
        ...(phoneNumber1Value && {
          phone_number_1: phoneNumber1Value,
        }),
        ...(phoneNumber2Value && {
          phone_number_2: phoneNumber2Value,
        }),
        ...(contactPersonValue && {
          contact_person: contactPersonValue,
        }),
        ...(emailValue && {
          email: emailValue,
        }),
        ...(notificationEmailValue && {
          notifications_email: notificationEmailValue,
        }),
        ...(content && {
          content: content.toString("html"),
        }),
        free_booking_emails: freeDomains,
        enable_free_booking: freeBAS,
        free_by_default: freeByDefault,
        ...(imageKey && {
          main_image: imageKey,
        }),
        password: passwordValue,
        password_confirmation:passwordConfirmation,
        is_offline: isOffline,
        is_closed: isClosed,
        is_screening_mandatory: isScreeningMandatory,
        basma_managed_clinic: basmaManagedClinic,
        weekends: selectedWeekends,
        products: selectedProducts,
        is_ghost: isGhost,
        is_mall_collaboration: isMallCollab,
        main_city_clinic: isMainCityClinic,
        replaced_by_main_city_clinic: isReplacedByMainClinicCity,
        ...(assignedTo.length == 1 && {
          assigned_to: assignedTo[0].value,
        }),
        scanner_id: scanner.selected.length > 0 ? scanner.selected[0].value : null,
      };
      console.log('bodyObj',bodyObj)
      axios
        .post(`/admin/v1/countries/${id}/cities/${idCity}/clinics`, bodyObj, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          if(res.data.success){
            history.push(
              `/admin/book-a-scan-countries/${id}/cities/${idCity}/clinics`
            );
          } else {
            setErrorMsg(res.data.message);
            setPopupActive(false);
          }
        })
        .catch((err) =>{
          setPopupActive(false);
          if(err?.email){
            setEmailError("Email already in use. Please use another email.");
          } 
          if(err?.password){
            setPasswordError(err?.password[0])
          }

        });
    }
  }
}
export default Add;
