import { Select } from '@shopify/polaris'
import React from 'react'

const TpDesigner = ({ optionsTPDesigner, handleSelectChangeTPDesigner, selectedTPDesigner, disabled }) => {
    return (
        <div className='full-width-tp-section'>
            <Select
                disabled={disabled}
                options={optionsTPDesigner}
                onChange={handleSelectChangeTPDesigner}
                value={selectedTPDesigner}
            />
        </div>
    )
}

export default TpDesigner