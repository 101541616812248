import React, { useState } from "react";
import { Typography, TextField, Select, MenuItem } from "@material-ui/core";
import axios from "../axios";
import Cookies from "js-cookie";
import { Button } from "@shopify/polaris";

export function ExistingProductCurrency({product_id, id, country, currency, local_price, editable, setSuccess}){
  const[Country, setCountry] = useState(country);
  const[Currency, setCurrency] = useState(currency);
  const[localPrice, setLocalPrice] = useState(local_price);
  const[edit, setEdit] = useState(editable);
  const[loading, setLoading] = useState(false);

  const save = async() => {
    setLoading(true);
    const bodyObj = {
      id: id,
      product_id: product_id,
      local_price: localPrice,
      country: Country,
      currency: Currency,
    }

    await axios.post(`/admin/v1/product_currency`, bodyObj, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      }
    }).then((res) => {
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      setLoading(false);
      setSuccess(prev => prev + 1);
      setEdit((prev) => !prev);
    })
  }

  return(
    <tr style={{display: "flex", padding: "1em 0", gap: "10px"}}>
      <td  className="plan-column" >
        <Select
          disabled={!edit}
          style={{maxWidth: "100%"}}
          className="plan-field"
          onChange={(e)=>{setCountry(e.target.value)}}
          value={Country}
        >
          <MenuItem value={"AE"}>UAE</MenuItem>
          <MenuItem value={"SA"}>KSA</MenuItem>
          <MenuItem value={"QA"}>QAT</MenuItem>
          <MenuItem value={"LB"}>LEB</MenuItem>
          <MenuItem value={"BH"}>BAH</MenuItem>
          <MenuItem value={"KW"}>KWT</MenuItem>
          <MenuItem value={"OM"}>OMN</MenuItem>
        </Select>
      </td>
      <td className="plan-column" >  
        <Select
          disabled={!edit}
          style={{maxWidth: "100%"}}
          className="plan-field"
          onChange={(e)=>{setCurrency(e.target.value)}}
          value={Currency}
        >
          <MenuItem value={"AED"}>AED</MenuItem>
          <MenuItem value={"SAR"}>SAR</MenuItem>
          <MenuItem value={"QAR"}>QAR</MenuItem>
          <MenuItem value={"USD"}>USD</MenuItem>
          <MenuItem value={"BHD"}>BHD</MenuItem>
          <MenuItem value={"KWD"}>KWD</MenuItem>
          <MenuItem value={"OMR"}>OMR</MenuItem>
        </Select>
      </td>
      <td className="plan-column">
        <TextField disabled={!edit} type="number" className="plan-field" value={localPrice} onChange={(e)=>{setLocalPrice(e.target.value)}}/>
      </td>
      <td className="plan-column">
        <Typography>{currency} {local_price}</Typography>
      </td>
      <td className="plan-button">
        <Button loading={loading} style={{backgroundColor: "rgba(0, 128, 96, 1)", padding: "1rem 3rem", cursor: "pointer"}} textAlign="bottom" primary onClick={() => {edit ? save() : setEdit((prev)=>(!prev))}}>{edit ? 'Save' : 'Edit'}</Button>
      </td>
    </tr>     
  )
}

export default ExistingProductCurrency