import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../history";
import { AppProvider } from "@shopify/polaris";
import MainSub2 from "./MainSub2";
import EditPlan from "./EditPlan";
import ViewAllUsers from "./ViewAllUsers";

function MainSub() {
  return (
    <AppProvider>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/plans/:id" component={EditPlan} />
          <Route exact path="/admin/plans/:id/users" component={ViewAllUsers} />
          <Route path="/admin/plans/:id/payments" component={MainSub2} />
        </Switch>
      </Router>
    </AppProvider>
  );
}
export default MainSub;
