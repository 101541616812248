import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  Avatar,
  Button,
  ResourceItem,
  ResourceList,
  Thumbnail,
  Caption,
  InlineError,
  DropZone,
  Checkbox,
  Stack,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  ActionList,
  Popover,
  Select,
  Card,
  Tabs,
  Modal,
  PageActions,
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";
import history from "../history";
import Select2 from "react-dropdown-select";

import { Loading } from "@shopify/app-bridge/actions";

function Add() {
  const [valueFirstName, setvalueFirstName] = useState("");
  const [valueLastName, setvalueLastName] = useState("");
  const [valueEmail, setValueEmail] = useState("");
  const [valuePassword, setValuePassword] = useState("");
  const [valueConfirmPassword, setValueConfirmPassword] = useState("");
  const [selected, setSelected] = useState(['admin']);
  const [clinicOptions, setClinicsOptions] = useState([]);
  const [chosenClinics, setChosenClinics]=useState([]);
  const [checked, setChecked] = useState(false);
  const handleChange = useCallback((newChecked) => setChecked(newChecked), []);
  // const handleSelectChange = useCallback((value) => setSelected(value), []);
  const [fieldRequiredImage, setFieldRequiredImage] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredClinicAccess, setFieldRequiredClinicAccess] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );

  const handleSelectChange = (e, value) => {
    if(selected.includes(value)){
      const array = selected.filter((res) => res != value);
      setSelected(array);
    }else{
      setSelected((prev) => [ ...prev, value])
    }
  };
  const [popupActive, setPopupActive] = useState(false);

  // const [options, setOptions] = useState([]);
  //let arrayChoices = ["Editor", "Writer", "Moderator", "Admin", "Super-Admin"];
  // const options = [
  //   { label: "Editor", value: "1" },
  //   { label: "Moderator", value: "3" },
  //   { label: "Admin", value: "4" },
  //   { label: "Accounting", value: "6" },
  //   { label: "Doctor", value: "7" },
  // ];
  const options = {
    Admin : "admin", 
    Editor : "editor", 
    Moderator : "moderator",  
    Accounting: "accounting", 
    Doctor : "doctor",
    'Clinic Moderator':"clinic-moderator",
    'Clinic Admin':"clinic-admin",
    OEM: 'oem',
    'Freelance Designer': 'freelance-designer',
    HR:'hr'
  };
  const [fieldRequiredFN, setFieldRequiredFN] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredLN, setFieldRequiredLN] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredE, setFieldRequiredE] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredP, setFieldRequiredP] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredCP, setFieldRequiredCP] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [imagePreview, setImagePreview] = useState("");
  let imageType = "";
  const [imageKey, setImageKey] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = useCallback(
    (files) => {
      setPopupActive(true);
      setImagePreview(
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = files[files.length - 1].type;
      let headersVar = null;
      let url2 = "";
      const form_data = new FormData();
      form_data.append("image_name", files[files.length - 1].name);

      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKey(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          let form_data = new FormData();
          //form_data.append("image_name", files[files.length - 1].name);
          //form_data.append("image", files[files.length - 1]);
          axios
            .put(url2, files[files.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [files]
  );

  useEffect(() => {
    axios.get("/admin/v1/clinics", {
      headers: {
        Authorization: "Bearer " + Cookie.get("token"),
      },
    }).then((res) => {
      let clinicsArray = res.data.data.map((clinic) => ({value: clinic.id, label: clinic.name['en']}));
      setClinicsOptions(clinicsArray);
    }).catch((err) => console.log(err));
  }, [])

  return (
    <AppProvider>
      <Page
        title="Add User"
        breadcrumbs={[{ content: "List Of Users", url: "/admin/admin-users" }]}
      >
        <Card sectioned>
          <FormLayout>
            <TextStyle variation="strong">First Name</TextStyle>
            <TextField
              value={valueFirstName}
              onChange={handleChangeFirstName}
            />
            {fieldRequiredFN}
            <TextStyle variation="strong">Last Name</TextStyle>
            <TextField value={valueLastName} onChange={handleChangeLastName} />
            {fieldRequiredLN}

            <TextStyle variation="strong">Email</TextStyle>
            <TextField value={valueEmail} onChange={handleChangeEmail} />
            {fieldRequiredE}
            
            <TextStyle variation="strong">Image</TextStyle>
            <Thumbnail size="large" source={imagePreview} />
            <DropZone onDrop={handleDropZoneDrop} onDropAccepted={sendImage}>
              {uploadedFiles}
              {fileUpload}
            </DropZone>
            {fieldRequiredImage}
            <TextStyle variation="strong">Password</TextStyle>
            <TextField
              type="password"
              value={valuePassword}
              onChange={handleChangePassword}
            />
            {fieldRequiredP}
            <TextStyle variation="strong">Confirm Password</TextStyle>
            <TextField
              type="password"
              value={valueConfirmPassword}
              onChange={handleChangeConfirmPassword}
            />
            {fieldRequiredCP}
            <TextStyle variation="strong">Select Role</TextStyle>
            {/* <Select
              options={options}
              onChange={handleSelectChange}
              value={selected}
            /> */}
            {Object.entries(options).map((res) =>
              <Checkbox
                label={res[0]}
                checked={selected.includes(res[1])}
                onChange={(e) => handleSelectChange(e, res[1])}
              />
            )}
            <div>
              <TextStyle variation="strong">Choose clinic access</TextStyle>
              <Select2
                disabled={!selected.includes('clinic-moderator') && !selected.includes('clinic-admin')}
                placeholder="Select Clinic"
                multi
                options={clinicOptions}
                onChange={setChosenClinics}
                values={chosenClinics}
              />
              {fieldRequiredClinicAccess}
            </div>
            {/* <Checkbox
              label="Is Active"
              checked={checked}
              onChange={handleChange}
            /> */}
          </FormLayout>
        </Card>
        <PageActions
          primaryAction={{
            content: "Save",
            onClick: handleSave,
          }}
        />
        <Modal open={popupActive} loading={true}></Modal>
      </Page>
    </AppProvider>
  );
  function handleChangeFirstName(valueFirstName) {
    setvalueFirstName(valueFirstName);
    if (valueFirstName !== "") {
      setFieldRequiredFN(<InlineError message="" fieldID="myFieldID" />);
    }
  }
  function handleChangeLastName(valueLastName) {
    setvalueLastName(valueLastName);
    if (valueLastName !== "") {
      setFieldRequiredLN(<InlineError message="" fieldID="myFieldID" />);
    }
  }
  function handleChangeEmail(valueEmail) {
    setValueEmail(valueEmail);
    if (valueEmail !== "") {
      setFieldRequiredE(<InlineError message="" fieldID="myFieldID" />);
    }
  }
  function handleChangePassword(valuePassword) {
    setValuePassword(valuePassword);
    if (valuePassword !== "") {
      setFieldRequiredP(<InlineError message="" fieldID="myFieldID" />);
    }
  }
  function handleChangeConfirmPassword(valueConfirmPassword) {
    setValueConfirmPassword(valueConfirmPassword);
    if (valueConfirmPassword !== "") {
      setFieldRequiredCP(<InlineError message="" fieldID="myFieldID" />);
    }
  }
  function handleSave() {
    if (
      valueFirstName === "" ||
      valueLastName === "" ||
      valueEmail === "" ||
      valuePassword === "" ||
      valueConfirmPassword === "" ||
      (valueEmail !== "" &&
        !valueEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) ||
      valuePassword.length < 6 ||
      ((selected.includes('clinic-moderator') || selected.includes('clinic-admin')) && chosenClinics.length == 0) ||
      valuePassword !== valueConfirmPassword
    ) {
      if (valueFirstName === "")
        setFieldRequiredFN(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueLastName === "")
        setFieldRequiredLN(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueEmail === "")
        setFieldRequiredE(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valuePassword === "")
        setFieldRequiredP(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueConfirmPassword === "")
        setFieldRequiredCP(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (
        valueEmail !== "" &&
        !valueEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      )
        setFieldRequiredE(
          <InlineError
            message="Please enter a valid email address"
            fieldID="myFieldID"
          />
        );
      if (valuePassword.length < 6)
        setFieldRequiredP(
          <InlineError
            message="Password must be at least 6 digit"
            fieldID="myFieldID"
          />
        );
      if (valuePassword !== valueConfirmPassword) {
        setFieldRequiredCP(
          <InlineError
            message="Passwords are not matching"
            fieldID="myFieldID"
          />
        );
      }
      if ((selected.includes('clinic-moderator') || selected.includes('clinic-admin')) && chosenClinics.length == 0) {
        setFieldRequiredClinicAccess(
          <InlineError
            message="Please select clinics"
            fieldID="myFieldID"
          />
        );
      }
    } else {
      const bodyObj = {
        first_name: valueFirstName,
        last_name: valueLastName,
        email: valueEmail,
        password: valuePassword,
        active: checked,
        // role: selected,
        roles : selected,
        clinics: chosenClinics,
        "confirm-password": valueConfirmPassword,
        profile_image: imageKey,
      };
      axios
        .post("admin/v1/management/users", bodyObj, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          history.push("/admin/admin-users");
          //  history.push('/admin/faqsupport/viewCategory')
        })
        .catch((err) => handleError(err));
    }
  }
  function handleError(err) {
    setFieldRequiredE(
      <InlineError message="This email is already used" fieldID="myFieldID" />
    );
  }
}
export default Add;
