import basmaLogo from '../../img/doctorsLogo.png';
import { useStyles } from './commercial-invoice-pdf.styles';
import moment from 'moment';
import { GeneralFormats } from '../../util/constants';
function CommercialInvoicePdf({
  pdfRef,
  formData,
  shippingTo,
  shippingFrom,
  carrier = null,
}) {
  const {
    descriptionOfGood,
    quantity,
    unitValue,
    subTotalValue,
    unitNetWeight,
    countryOfManufacture,
    commCode,
    totalDeclaredValue,
    totalNetWeight,
    totalUnit,
    totalGrossWeight,
  } = formData;
  const classes = useStyles();
  return (
    <div ref={pdfRef} className={classes.main}>
      <div className={classes.section}>
        <img src={basmaLogo} alt="Logo" />
        <h1>COMMERCIAL INVOICE</h1>
      </div>
      <div>
        <h2>Shipment From</h2>
        <span>Shipping Manager</span>
        <span>{shippingFrom?.name}</span>
        <span>{shippingFrom?.address}</span>
        <span>{shippingFrom?.city}</span>
        <span>{shippingFrom?.country}</span>
        <span>{shippingFrom?.phone}</span>
        <span>{shippingFrom?.email}</span>
      </div>
      <hr />
      <div>
        {carrier && carrier === 'Aramex' && (
          <span>{moment().format(GeneralFormats.dateFormat)}</span>
        )}
        <div className={classes.section}>
          <div className={classes.shipmentTo}>
            <h3>Shipment To</h3>
            <span>{shippingTo?.name}</span>
            <span>{shippingTo?.address}</span>
            <span>{shippingTo?.city}</span>
            <span>{shippingTo?.country}</span>
            <span>{shippingTo?.phone}</span>
            <span>{shippingTo?.email}</span>
            <span>Tax ID / VAT /</span>
          </div>
          <div className={classes.shipmentFrom}>
            <h3>Billed To</h3>
            <span>{shippingTo?.name}</span>
            <span>{shippingTo?.address}</span>
            <span>{shippingTo?.city}</span>
            <span>{shippingTo?.country}</span>
            <span>{shippingTo?.phone}</span>
            <span>{shippingTo?.email}</span>
            <span>Tax ID / VAT /</span>
          </div>
        </div>
      </div>
      <div className="table-pdf">
        <table>
          <thead>
            <tr>
              <th>Description of Goods</th>
              <th>Quantity</th>
              <th>Unit Value</th>
              <th>Subtotal Value</th>
              <th>Unit Net Weight</th>
              <th>Country of Manufacture/Origin</th>
              <th>Comm. Code</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{descriptionOfGood}</td>
              <td>{quantity}</td>
              <td>{unitValue}</td>
              <td>{subTotalValue}</td>
              <td>{unitNetWeight}</td>
              <td>{countryOfManufacture}</td>
              <td>{commCode}</td>
            </tr>
            <tr>
              <td></td>
              <td colSpan={3}>
                Total Declared Value: {totalDeclaredValue} USD
              </td>
              <td colSpan={3}>Total Net Weight: {totalNetWeight}kg(s)</td>
            </tr>
            <tr>
              <td></td>
              <td colSpan={3}>Total Unit(s): {totalUnit}</td>
              <td colSpan={3}>Total Gross Weight: {totalGrossWeight}kg(s)</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <span>Type of Export: Permanent</span>
        <span>Currency Code: USD</span>
        <span>Reason for Export: Impression Material</span>
        <span>Terms of Trade: DAP</span>
        <span>Signature: Basma</span>
        <span>Company Stamp: Basma</span>
      </div>
    </div>
  );
}

export default CommercialInvoicePdf;
