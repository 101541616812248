import React from 'react';
import { Spinner } from '@shopify/polaris';

const ThinLoading = ({ label = 'generic label', size = 'large' }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: 'white',
        padding: '15px',
        paddingBottom: '50px',
        marginTop: '15px',
        marginBottom: '15px',
      }}
    >
      <Spinner accessibilityLabel={label} size={size} />
    </div>
  );
};

export default ThinLoading;
