import React from 'react'
import {
  Thumbnail,
  TextStyle,
} from "@shopify/polaris";
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';

const StepImage = ({
    handleOnClickImageSmileJourney,
    extendedImage,
    title,
    subTitle,
    replacedImg,
}) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    marginBottom: 10,
                }}
                onClick={() => {
                    if(extendedImage) {
                        handleOnClickImageSmileJourney(extendedImage);
                    }
                }}
            >
                <Thumbnail
                    size="large"
                    source={extendedImage ? extendedImage : ''}
                />
            </div>
            <div>
                <TextStyle>{title}</TextStyle> <br />
            </div>
            <div>
                <TextStyle>{subTitle}</TextStyle>
            </div>
            {
                replacedImg &&
                <div>
                    <ImageNotSupportedIcon style={{ color: '#CC0909', fontSize: '20px' }} />
                </div>
            }
        </div>
    )
}

export default StepImage