import React, { useState } from "react";
import {
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Card,
  PageActions,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";
import { Editor } from "@tinymce/tinymce-react";

import {useLocation, useParams} from "react-router-dom";
import UserHeader from "../../../components/UserLayout/user-header";

//import { Select } from '@shopify/app-bridge/actions/ResourcePicker';

function AddNotification() {
  let { id } = useParams();
  const [valueTitleEn, setValueTitleEn] = useState("");
  const [valueMessageEn, setValueMessageEn] = useState("");
  const [valueButtonTextEn, setValueButtonTextEn] = useState("");
  const [valueTitleAr, setValueTitleAr] = useState("");
  const [valueMessageAr, setValueMessageAr] = useState("");
  const [valueButtonTextAr, setValueButtonTextAr] = useState("");
  const [valueButtonURL, setValueButtonURL] = useState("");
  const handleChangeMessageEn = (e) => {
    setValueMessageEn(e.target.getContent());
  };
  const handleChangeMessageAr = (e) => {
    setValueMessageAr(e.target.getContent());
  };
  const location = useLocation();
  let linkState =
    {planType: "", clinicStatus: "", clinicName: "", status: "", userId: id, pageTitle: ""};
  if (location.state !== undefined) {
    const {planType, clinicStatus, clinicName, status, userId, pageTitle} = location.state;
    linkState = {planType, clinicStatus, clinicName, status, userId, pageTitle}
  }
  const {planType, clinicStatus, clinicName, status, userId, pageTitle} = linkState;
  return (
    <Page>
      <UserHeader pageTitle={pageTitle} status={status} userId={userId} clinicStatus={clinicStatus} clinicName={clinicName} planType={planType}/>
      <br/>
      <h1 className="page-header">Add Notification</h1>
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Title En</TextStyle>
          <TextField value={valueTitleEn} onChange={handleChangeTitleEn} />
          <TextStyle variation="strong">Title Ar</TextStyle>
          <TextField value={valueTitleAr} onChange={handleChangeTitleAr} />

          <TextStyle variation="strong">Message En</TextStyle>

          <Editor
            apiKey="z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve"
            value={valueMessageEn}
            init={{
              branding: false,
              height: 200,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
            }}
            onChange={handleChangeMessageEn}
          />
          <TextStyle variation="strong">Message Ar</TextStyle>

          <Editor
            apiKey="z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve"
            value={valueMessageAr}
            init={{
              branding: false,
              height: 200,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic backcolor | \
 alignleft aligncenter alignright alignjustify | \
 bullist numlist outdent indent | removeformat | help",
            }}
            onChange={handleChangeMessageAr}
          />
          <TextStyle variation="strong">Button Text En</TextStyle>
          <TextField
            value={valueButtonTextEn}
            onChange={handleChangeButtonTextEn}
          />
          <TextStyle variation="strong">Button Text Ar</TextStyle>
          <TextField
            value={valueButtonTextAr}
            onChange={handleChangeButtonTextAr}
          />
          <TextStyle variation="strong">Button URL</TextStyle>
          <TextField value={valueButtonURL} onChange={handleChangeButtonURL} />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
    </Page>
  );
  function handleChangeTitleEn(valueTitleEn) {
    setValueTitleEn(valueTitleEn);
  }

  function handleChangeButtonTextEn(valueButtonTextEn) {
    setValueButtonTextEn(valueButtonTextEn);
  }
  function handleChangeTitleAr(valueTitleAr) {
    setValueTitleAr(valueTitleAr);
  }

  function handleChangeButtonTextAr(valueButtonTextAr) {
    setValueButtonTextAr(valueButtonTextAr);
  }
  function handleChangeButtonURL(valueButtonURL) {
    setValueButtonURL(valueButtonURL);
  }

  function handleSave() {
    const bodyObj = {
      title: {
        en: valueTitleEn,
        ar: valueTitleAr,
      },
      message: {
        en: valueMessageEn,
        ar: valueMessageAr,
      },
      button_text: {
        en: valueButtonTextEn,
        ar: valueButtonTextAr,
      },
      button_url: valueButtonURL,
    };
    axios
      .post(`admin/v1/users/${id}/notifications`, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        history.push("/admin/users/" + id + "/dashboard");
        //  history.push('/admin/faqsupport/viewCategory')
      })
      .catch((err) => console.log(err));
  }
}
export default AddNotification;
