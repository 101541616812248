import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Button,
  Page,
  Image,
  DisplayText,
  AppProvider,
  Loading,
  Card,
  TextStyle,
  ButtonGroup,
} from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../axios";
import history from "../history";

import emptyIcon from "../img/emptyList.svg";

export default function ListOfRemainingAmounts() {
  var json_str = Cookie.get("basma_orders_remainingamounts");
  var cookieResult = JSON.parse(json_str);
  console.log("cookieResult=", cookieResult);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sortState, setSortState] = useState("ascending");
  const [items, setItems] = useState([]);
  let arrayOfObjects = [];
  let newArray = [];

  let listOfProducts = [];
  let listOfPlans = [];
  useEffect(() => {
    setItems(
      cookieResult.map((item) => [
        item.plan_id,
        item.plan.title.en,
        item.plan.user_id,
        item.price,
      ])
    );
  }, []);
  function getData() {
    //GET PRODUCTS AND PLANS

    axios
      .get("admin/v1/products", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        // listOptionsProducts.push();
        for (var i = 0; i < result.data.data.data.length; i++) {
          listOfProducts.push({
            id: result.data.data.data[i].id,
            title: result.data.data.data[i].title.en,
          });
        }
        axios
          .get("admin/v1/plans", {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          })
          .then((result) => {
            for (var i = 0; i < result.data.data.length; i++) {
              listOfPlans.push({
                id: result.data.data[i].id,
                title: result.data.data[i].title.en,
              });
            }

            axios
              .get("admin/v1/coupons", {
                headers: {
                  Authorization: "Bearer " + Cookie.get("token"),
                },
              })
              .then((result) => {
                if (result.data.data.data.length === 0) {
                  setIsEmpty(true);
                }
                setIsLoading(false);
                for (var i = 0; i < result.data.data.data.length; i++) {
                  if (result.data.data.data[i]["product_id"] !== null) {
                    arrayOfObjects.push([
                      result.data.data.data[i].id,
                      result.data.data.data[i].code,
                      result.data.data.data[i].count,
                      result.data.data.data[i].amount,
                      result.data.data.data[i].start_date,
                      result.data.data.data[i].end_date,
                      result.data.data.data[i].product_id,
                      result.data.data.data[i].active,
                      "_product",
                    ]);
                  } else {
                    arrayOfObjects.push([
                      result.data.data.data[i].id,
                      result.data.data.data[i].code,
                      result.data.data.data[i].count,
                      result.data.data.data[i].amount,
                      result.data.data.data[i].start_date,
                      result.data.data.data[i].end_date,
                      result.data.data.data[i].plan_id,
                      result.data.data.data[i].active,
                      "_plan",
                    ]);
                  }
                }

                setItems(
                  result.data.data.data.map((item) => [
                    item.id,
                    item.code,
                    item.count,
                    findById(listOfProducts, item.product_id)
                      ? findById(listOfProducts, item.product_id).title
                      : findById(listOfPlans, item.plan_id)
                      ? findById(listOfPlans, item.plan_id).title
                      : "",
                    item.start_date,
                    item.end_date,
                    <ButtonGroup>
                      <Button
                        id={String(item.id)}
                        onClick={(e) => handleEdit(e)}
                      >
                        Edit
                      </Button>
                    </ButtonGroup>,
                  ])
                );
              })
              .catch((err) => console.log(err));
          })

          .catch((err) => console.log(err));
      })

      .catch((err) => console.log(err));
  }

  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    []
  );
  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isEmpty ? (
    <div className="message-no-result">
      <div className="message-no-result-img">
        <Image src={emptyIcon}></Image>{" "}
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : null;

  return (
    <Page
      fullWidth
      title="List Of Coupons"
      primaryAction={{
        content: "Create Coupon",
        url: "/admin/coupons/create",
      }}
    >
      <Card>
        <DataTable
          columnContentTypes={["text", "text", "text", "text"]}
          headings={[
            <TextStyle variation="strong">Plan ID</TextStyle>,
            <TextStyle variation="strong">Plan Title</TextStyle>,
            <TextStyle variation="strong">User ID</TextStyle>,
            <TextStyle variation="strong">Price</TextStyle>,
          ]}
          rows={items}
          sortable={[true, false, false, false, false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={1}
          //onSort={handleSort}
        />
        {emtyState}
      </Card>

      {/* <Button id='12333' onClick={(e)=>handleEdit(e)}>aaa</Button> */}
    </Page>
  );

  function handleEdit(e) {
    let currentID = e.currentTarget.id;
    var json_str = JSON.stringify(
      findArrayElementById(arrayOfObjects, currentID)
    );
    //Cookie.set('id',findArrayElementById(arrayOfObjects, currentID)[0]);
    Cookie.set("clickedItem", json_str);
    history.push("/admin/coupons/" + currentID);
  }
  function handleUp(event) {
    let currentID = event.currentTarget.id;

    const objBody = {
      sort: "up",
    };
    axios
      .post(
        "admin/v1/support/category-faq/" +
          Cookie.get("categID") +
          "/faq/" +
          currentID +
          "/sort",
        objBody,
        {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      )
      .then((result) => {
        if (
          arrayOfObjects.indexOf(
            findArrayElementById(arrayOfObjects, currentID)
          ) -
            1 !=
          -1
        ) {
          newArray = swapArrayElements(
            arrayOfObjects,
            arrayOfObjects.indexOf(
              findArrayElementById(arrayOfObjects, currentID)
            ),
            arrayOfObjects.indexOf(
              findArrayElementById(arrayOfObjects, currentID)
            ) - 1
          );
          setItems(
            newArray.map((item) => [
              newArray[0],
              newArray[1],
              newArray[2],
              newArray[4],
              newArray[5],
              <Button id={String(newArray[0])} onClick={(e) => handleEdit(e)}>
                Edit
              </Button>,
            ])
          );
        }
      })
      .catch((err) => console.log(err));

    //setItems(swapArrayElements(items,1,2))
  }
  function handleDown(event) {
    let currentID = event.currentTarget.id;

    const objBody = {
      sort: "up",
    };
    axios
      .post(
        "admin/v1/support/category-faq/" +
          Cookie.get("categID") +
          "/faq/" +
          currentID +
          "/sort",
        objBody,
        {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      )
      .then((result) => {
        if (
          arrayOfObjects.indexOf(
            findArrayElementById(arrayOfObjects, currentID)
          ) +
            1 !=
          -1
        ) {
          newArray = swapArrayElements(
            arrayOfObjects,
            arrayOfObjects.indexOf(
              findArrayElementById(arrayOfObjects, currentID)
            ),
            arrayOfObjects.indexOf(
              findArrayElementById(arrayOfObjects, currentID)
            ) + 1
          );
          setItems(
            newArray.map((item) => [
              newArray[0],
              newArray[1],
              newArray[2],
              newArray[4],
              newArray[5],
              <Button id={String(newArray[0])} onClick={(e) => handleEdit(e)}>
                Edit
              </Button>,
            ])
          );
        }
      })
      .catch((err) => console.log(err));
  }
  function handleViewDetails(event) {
    let currentID = event.currentTarget.id;
  }
  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element[0]) === parseInt(id);
    });
  }
  function swapArrayElements(a, x, y) {
    if (a.length === 1) return a;
    a.splice(y, 1, a.splice(x, 1, a[y])[0]);
    return a;
  }

  function findById(array, id) {
    return array.find((element) => {
      return element.id === id;
    });
  }
}
