import React, { useState } from "react";
import { Card, Heading } from "@shopify/polaris";
import Model3dPart from "../../../ThreeDViewer/Model3d";
import "../../../ThreeDViewer/model3d.css";
import Play from "../../../img/play.svg";

const ThreeDfilesViewer = (props) => {
  const [loadViewer, setLoadViewer] = useState(false);
  return (
      <Card title="3D Viewer" sectioned>
        <div className="model3d">
          {loadViewer ? (
            <Model3dPart models={loadViewer ? props.files : null} />
          ) : (
            <div className="load-model-btn">
              <a
                  onClick={() => {
                      setLoadViewer(true);
                  }}
              >
                <img src={Play} />
              </a>
              <Heading>Load Viewer</Heading>
            </div>
          )}
        </div>
      </Card>
  );
};

export default ThreeDfilesViewer;
