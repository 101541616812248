import React, { useState, useEffect } from "react";
import { FormLayout, Card, Layout, TextStyle } from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../../../../axios";
import "@zendeskgarden/react-pagination/dist/styles.css";
import {useHistory, useParams} from "react-router-dom";

function Billing(props) {
  const { id } = useParams();
  const [billingResponse, setBillingResponse] = useState([]);

  useEffect(() => {
    axios
      .get("admin/v1/users/" + id + "/billing", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        result.data.data && setBillingResponse(result.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

    const history = useHistory();

    const handleEdit = () => {
        history.push(
            `/admin/users/${id}/dashboard/editbilling`,
            {
                clinicName: props.clinicName,
                planType: props.planType,
                status: props.status,
                pageTitle: props.pageTitle,
                clinicStatus: props.clinicStatus,
                userId: props.userId
            }
        );
    }

  return (
    <Layout.Section>
      <Card
        sectioned
        title="BILLING"
        actions={[
          {
            content: "Edit",
            onClick: handleEdit,
          },
        ]}
      >
        <FormLayout>
          {billingResponse.full_name && <TextStyle>{"Full Name: "}</TextStyle>}
          {billingResponse.address && <TextStyle>{"Address: "}</TextStyle>}
          {billingResponse.apartment && <TextStyle>{"Apartment: "}</TextStyle>}
          {billingResponse.city && <TextStyle>{"City: "}</TextStyle>}
          {billingResponse.country && <TextStyle>{"Country: "}</TextStyle>}
          {billingResponse.postal_code && (
            <TextStyle>{"Postal Code: "}</TextStyle>
          )}
          {billingResponse.email && <TextStyle>{"Email: "}</TextStyle>}
          {billingResponse.note && <TextStyle>{"Note: "}</TextStyle>}
        </FormLayout>
      </Card>
    </Layout.Section>
  );
}
export default React.memo(Billing);
