import React, { useState, useEffect } from "react";
import {
  Modal,
  Page,
  Layout,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import { useParams } from "react-router-dom";
import SmileJourneyImages from "../../../components/SmileJourneyImages/SmileJourneyImages";
import { isEmpty } from "../../../util/helpers";

function EditSmileJourney() {
  var { userId, stepId, imagesId } = useParams();

  const [popupActive, setPopupActive] = useState(false);
  const [image, setImage] = useState(null);
  const [areChecked, setAreChecked] = useState({});

  const handleSelectDelete = (imagesId, id) => {
    if(imagesId) {
      setAreChecked(prev => ({
          ...prev,
          [imagesId]: {
            ...prev?.[imagesId],
            [id]: prev?.[imagesId]?.hasOwnProperty(`${id}`) ? !prev?.[imagesId]?.[`${id}`] : false
          },
      }));
    }
  }

  const handleDeleteImages = (imgId) => {
    let images = [];
    if(areChecked.hasOwnProperty(imgId)) {
      for (let prop in areChecked?.[imgId]) {
        if (areChecked?.[imgId].hasOwnProperty(prop)) {
          if (areChecked?.[imgId][prop] === true) {
            images.push(prop);
          }
        }
      }
      setPopupActive(true);

      let bodyObj = {
          images_id: imgId,
          image_names: images,
      };
      axios.post(`admin/v1/user_images/delete`, bodyObj, {
        headers: {
            Authorization: "Bearer " + Cookie.get("token"),
        },
      }).then((res) => {
        if(res.data.success) {
          getData();
        }
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        if(!isEmpty(areChecked)) {
          setAreChecked({});
        }
        setPopupActive(false);
      });
    }
  }

  const handleSelectImages = (imgId) => {
    if(areChecked?.hasOwnProperty(imgId)) {
      setAreChecked({});
    } else {
      setAreChecked({
        [imgId]: {
          big_smile_with_aligner: true,
          big_smile_without_aligner: true,
          front_closed_with_aligner: true,
          front_closed_without_aligner: true,
          left_closed_with_aligner: true,
          left_closed_without_aligner: true,
          right_closed_with_aligner: true,
          right_closed_without_aligner: true,
          top_with_aligner: true,
          top_without_aligner: true,
          bottom_with_aligner: true,
          bottom_without_aligner: true,
        }
      });
    }
  }

  function getData(imgId = null) {
    axios
      .get(`admin/v1/users/${stepId}/step-images/${imagesId !== 'null' ? imagesId : imgId}`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }).then((result) => {
        let img = result.data.data;
        setImage(img);
        setPopupActive(false);
      }).catch((err) => console.log("errrsp=", err));
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Page
      title="Your Smile Plan"
      breadcrumbs={[
        {
          content: "Dashboard",
          url: "/admin/users/" + userId + "/dashboard",
        },
      ]}
    >
      <Layout>
        <Layout.Section>
          {
            image?.extended_images?.reverse().map((img, key) => {
              return (
                <SmileJourneyImages
                  title={key == 0 ? `Step ${image?.position ? image?.step_nb + '.' + image?.position : image?.step_nb} (Extended Images)` : 'Extended Images'}
                  key={key}
                  image={img}
                  userId={userId}
                  getData={getData}
                  stepId={stepId}
                  setPopupActive={setPopupActive}
                  areChecked={areChecked}
                  selected={areChecked?.hasOwnProperty(img?.id)}
                  handleSelectDelete={handleSelectDelete}
                  handleSelectImages={handleSelectImages}
                  handleDeleteImages={handleDeleteImages}
                />
              )
            })
          }
          <SmileJourneyImages
            title={image?.extended_images?.length ? '' : (!image?.step_nb ? '' : `Step ${image?.position ? image?.step_nb + '.' + image?.position : image?.step_nb}`)}
            image={image}
            userId={userId}
            getData={getData}
            stepId={stepId}
            setPopupActive={setPopupActive}
            areChecked={areChecked}
            selected={areChecked?.hasOwnProperty(image?.id)}
            handleSelectDelete={handleSelectDelete}
            handleSelectImages={handleSelectImages}
            handleDeleteImages={handleDeleteImages}
          />
        </Layout.Section>
      </Layout>
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );
}

export default EditSmileJourney;
