import React, {useState} from "react";
import {
  Page,
  Loading,
} from "@shopify/polaris";
import PaymentPlan from "./PaymentPlan";

export default function MediumPlan() {
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const loadingMarkup = isLoading ? <Loading /> : null;

  return (
    <Page
      fullWidth
      title="Medium Plan"
      breadcrumbs={[{ content: "List Of Plans", url: "/admin/plans" }]}
    >
      {" "}
      {loadingMarkup}
      <PaymentPlan plantype='medium' payment="stripe"/>
      <PaymentPlan plantype='medium' payment="tabby"/>
    </Page>
  );

}
