import React, { useState, useEffect } from "react";
import axios from "../../axios";
import Cookie from "js-cookie";
import DataTableComponent from "./DataTableComponent.js";
import {
    Button,
  } from "@shopify/polaris";
function TabComponent3() {
  const [items, setItems] = useState([]);
  
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    const url = "/admin/v1/reports/written-off-users";
    axios
      .get(`${url}?page=${page}&per_page=${perPage}`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        if (result?.data?.success) {
          setTotalPages(result?.data?.data?.last_page);
          let array = result?.data?.data?.data?.map((res) => {
            return [
              res?.user_id,
              `${res?.user?.first_name} ${res?.user?.last_name}`,
              res?.request_reason,
              res?.requested_by,
              res?.requested_at,
              res?.approved_by,
              res?.approved_at,
              res?.completed_by,
              res?.completed_at,
                <></>
            ];
          });
          setItems(array);
        }
      })
      .catch((err) => console.log("an error", err));
  }, [page, perPage]);

  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleAction=(id)=>{
    alert(id);
   }

  return (
    <div>
      <DataTableComponent
        items={items}
        totalPages={totalPages}
        page={page}
        tab="3"
        handleChange={handleChange}
      />
    </div>
  );
}

export default TabComponent3;
