import React, { useEffect, useRef, useState } from "react";
import "./newquestions.css";
import { Page, Card } from "@shopify/polaris";
import axios from "../../axios";
import Cookies from "js-cookie";
import history from "../../history";
import { ToastContainer, toast } from "react-toastify";

function AddQuestions({ id, show, myDivRef }) {
  // const { id } = useParams();
  const lastAddedQuestionRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState([
    {
      id: 1,
      survey_question: {
        screening_option_en: "",
        screening_question_en: "",
      },
      mandatory: false,
      type: "",
      archived: false,
      careers_id: id,
    },
  ]);

  useEffect(() => {
    const fetchCareerQuestions = async () => {
      try {
        const response = await axios.get(
          `admin/v1/careerquestion?careers_id=${id}`,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
          }
        );
        const formDataArray = response.data.data.map((item) => ({
          id: item.id,
          careers_id: item.careers_id,
          survey_question: {
            screening_option_en: item.survey_question.screening_option_en,
            screening_question_en: item.survey_question.screening_question_en,
          },
          mandatory: item.mandatory,
          type: item.type,
          archived: item.archived,
        }));
        setFormData(formDataArray);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCareerQuestions();
  }, [id]);

  const handleSave = async (index) => {
    try {
      setLoading(true);
      const formDataAtIndex = formData[index];
      formDataAtIndex["careers_id"] = id;
      console.log("formDataAtIndex", formDataAtIndex);
      if (formDataAtIndex["id"] !== undefined) {
        if (
          formDataAtIndex.type === "options" &&
          formDataAtIndex.survey_question.screening_option_en === ""
        ) {
          toast.error("Option input is required", 3000);
        } else {
          const response = await axios.post(
            `admin/v1/careerquestion/create`,
            formDataAtIndex,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
            }
          );

          setFormData((prev) => {
            const newArr = [...prev];

            newArr[index].id = response.data.data.id;

            return newArr;
          });
          if (response.data.data.careers_id != id||response.data.data.careers_id!== id) {
            history.push(`/admin/jobs/${response.data.data.careers_id}`);
          }
          if (response.status === 200) {
            toast.success(response.data.message, 3000);
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.type[0], 3000);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (index, e) => {
    const { name, value, type, checked } = e.target;
    const newFormData = [...formData];

    if (type === "checkbox") {
      newFormData[index][name] = checked;
    } else if (name.startsWith("screening")) {
      newFormData[index].survey_question[name.split(",")] = value;
    } else {
      newFormData[index][name] = value;
    }

    setFormData(newFormData);
  };
  const handleAddRow = () => {
    setFormData([
      ...formData,
      {
        id: null,
        survey_question: {
          screening_option_en: "",
          screening_question_en: "",
        },
        type: "",
        mandatory: false,
        archived: false,
      },
    ]);
    scrollToLastAddedQuestion();
  };

  const scrollToLastAddedQuestion = () => {
    if (lastAddedQuestionRef.current) {
      lastAddedQuestionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const type = [
    { id: 0, value: "", name: "Type" },
    { id: 1, value: "textarea", name: "Text Area" },
    { id: 2, value: "options", name: "Options" },
    { id: 3, value: "number", name: "Number" },
    { id: 4, value: "text-field", name: "Text Field" },
  ];
  return (
    show && (
      <Page
        fullWidth
        title="Add Question"
        //   breadcrumbs={[
        //     { url: `/admin/book-a-scan-countries/${id}/cities/${idCity}/clinics` },
        //   ]}
      >
        <ToastContainer />
        <Card sectioned>
          <div
            style={{
              justifyContent: "end",
              display: "flex",
              alignItems: "center",
            }}
            ref={myDivRef}
          >
            {/* <div className="form-row-question" style={{ display: 'flex', justifyContent: 'start', paddingBottom: "30px" }}>
                        <button onClick={()=>history.push(`/admin/jobs/${id}`)}>Go Back</button>

                    </div> */}
            <div
              className="form-row-question"
              style={{
                display: "flex",
                justifyContent: "end",
                paddingBottom: "30px",
              }}
            >
              <button onClick={handleAddRow}>Add New Question</button>
            </div>
          </div>

          <div className="scrollable-container container-grid">
            <div className="form-row-question container-grid">
              <div className="container-grid">
                <div className="column3">
                  <label>Additional Screening Question:</label>
                </div>
                <div className="column1">
                  <label>Type</label>
                </div>
                <div className="column3">
                  <label>Options</label>
                </div>
                <div className="column">
                  <label>Is Mandatory</label>
                </div>
                <div className="column">
                  <label>Archived</label>
                </div>
                <div className="column"></div>
              </div>
            </div>
          </div>
          <hr />
          <div className="scrollable-container container-grid">
            <div className="w100">
              {formData &&
                formData.map((data, index) => (
                  <div
                    key={index}
                    className="form-row-question container-grid"
                    ref={
                      index === formData.length - 1
                        ? lastAddedQuestionRef
                        : null
                    }
                  >
                    <div className="column3">
                      <input
                        className="career-input_form"
                        type="text"
                        name={`screening_question_en`}
                        value={data.survey_question.screening_question_en}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>

                    <div className="column1">
                      <select
                        className="career-input_form"
                        name="type"
                        value={data.type}
                        onChange={(e) => handleChange(index, e)}
                      >
                        {type.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="column3">
                      <input
                        className="career-input_form"
                        type="text"
                        name={`screening_option_en`}
                        placeholder=""
                        value={data.survey_question.screening_option_en}
                        onChange={(e) => handleChange(index, e)}
                        disabled={data.type !== "options"}
                        required={data.type === "options"}
                      />
                    </div>
                    <div
                      className="column"
                      style={{ alignItems: "end", display: "flex" }}
                    >
                      <input
                        className="job_input_check"
                        type="checkbox"
                        name="mandatory"
                        checked={data.mandatory}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                    <div
                      className="column"
                      style={{ alignItems: "end", display: "flex" }}
                    >
                      <input
                        className="job_input_check"
                        type="checkbox"
                        name="archived"
                        checked={data.archived}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                    <div className="column">
                      <button onClick={() => handleSave(index)}>Save</button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Card>
      </Page>
    )
  );
}
export default AddQuestions;
