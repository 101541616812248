import React, { useEffect, useRef } from 'react'
import { MentionsInput, Mention } from 'react-mentions'
import IconButton from "@material-ui/core/IconButton";
import SendIcon from '@mui/icons-material/Send';
import FileUpload from '@mui/icons-material/AttachFile';
import mentionsInputStyle from './mentionsInputStyle';
import mentionStyle from './mentionStyle';
import { CSSTransition } from 'react-transition-group';
import CaseDiscussionReply from "../CaseDiscussionReply/CaseDiscussionReply";
import { useStyles } from './CaseDiscussionInputStyles';
import { Grid, Badge } from '@material-ui/core';
import { Close } from "@mui/icons-material";

function CaseDiscussionInput({newMessage,setNewMessage,handleSubmit,agents, newMedia, setNewMedia, formDisabled}) {
    const classes = useStyles()
    const fileInputRef = useRef(null);
    const handleFileUploadClick = () => {
      fileInputRef.current && fileInputRef.current.click();
    };
    const handleFileChange = (event) => {
      let selectedFiles = Array.from(event.target.files || [])
      const videoTypes = ['video/', 'mp4', 'mov', 'wmv', 'avi'];
      const imageTypes = ['image/', 'jpg', 'jpeg', 'png', 'gif'];
      if(!selectedFiles.length){
        return
      }
      if(selectedFiles.length>1){
        let images = selectedFiles.filter(file=>imageTypes.some(type=>file.type.includes(type)))
        setNewMedia((prev) => [...images])
        return;
      }
      setNewMedia((prev) => [...selectedFiles])
    };
    const handlePaste = (e) =>{
      let pastedData = Array.from(e.clipboardData.files || [])
      const videoTypes = ['video/', 'mp4', 'mov', 'wmv', 'avi'];
      const imageTypes = ['image/', 'jpg', 'jpeg', 'png', 'gif'];
      if(!pastedData.length){
        return
      }
      if(pastedData.length>1){
        let images = pastedData.filter(file=>imageTypes.some(type=>file.type.includes(type)))
        setNewMedia((prev) => [...images])
        return;
      }
      setNewMedia((prev) => [...pastedData])
    } 
  return (
    <Grid item xs={12} className={classes.inputRow} onPaste={handlePaste}>
              <form onSubmit={handleSubmit} className={classes.form}>
                <Grid
                  container
                  className={classes.newMessageRow}
                  alignItems="flex-end"
                >
                  <Grid item style={{ flexGrow: 1, maxWidth:"80%" }}>
                    <CSSTransition in={(newMessage && newMessage.reply_to) ? true : false} timeout={300} classNames="fade" unmountOnExit>
                      <CaseDiscussionReply message={newMessage.reply} setNewMessage={setNewMessage} />
                    </CSSTransition>
                    <MentionsInput id="inputt" value={newMessage.data} style={mentionsInputStyle}
                      forceSuggestionsAboveCursor
                      allowSpaceInQuery
                      disabled={formDisabled}
                      placeholder="use @ to mention an agent"
                      onKeyDown={(e)=>
                        {
                        if(e.key === 'Enter' && e.shiftKey){
                        handleSubmit({preventDefault:()=>{}})
                        return;
                      }
                    }}
                      onChange={(e) =>{
                        setNewMessage((prev) => { return { ...prev, data: e.target.value}
                      })}
                    }>
                      <Mention
                        trigger="@"
                        allowSpaceInQuery={true}
                        forceSuggestionsAboveCursor={true}
                        data={agents}
                        appendSpaceOnAdd={true}
                        style={mentionStyle}
                      />
                    </MentionsInput>
                  </Grid>
                  <IconButton style={{ padding: "3% 2%" }} >
                    <Badge className={classes.attachmentBadge} badgeContent={newMedia.length} >
                      <FileUpload style={{ width: "1.5em", height: "1.5em" }} onClick={handleFileUploadClick} />
                    </Badge>
                  </IconButton>
                  <IconButton type="submit" disabled={formDisabled}>
                    <SendIcon className={classes.send} />
                  </IconButton>
                </Grid>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
                {newMedia.length?<div>
                  Attached:
                  <div style={{display:"flex",flexWrap:"wrap", gap:"2px"}}>
                  {newMedia.map((media,ind)=> <div style={{position:"relative",width:"fit-content" ,paddingRight:"3%",paddingLeft:"1%",border: "1px solid #b0adad",borderRadius: "5px",backgroundColor: "#9895954f"}}>{media.name} 
                <Close style={{ position: "absolute", right: "0",top:"0" }} onClick={() => { setNewMedia((prev) => { let arr = [...prev]; arr.splice(ind, 1); return [...arr] })}} /></div>)}
                </div>
                </div>:null}
              </form>
            </Grid>
  )
}

export default CaseDiscussionInput