import React, { useCallback, useRef, useState, useEffect, useContext } from "react";
import {
  AppProvider,
  Card,
  FormLayout,
  Button,
  Page,
  InlineError,
  DisplayText,
  Image,
  TextField,
  Link,
  Toast,
  Frame,
  TextStyle,
  Modal,
  Checkbox,
} from "@shopify/polaris";
import axios from "./axios.js";
import { Redirect, withRouter, Router, Switch, Route } from "react-router-dom";
import history from "./history";
import Helmet from "react-helmet";
import Cookie from "js-cookie";
import logo from "./img/basmalogo.svg";
import doctorsBackgroundImage from "./img/loginBackgroundImage.jpg";
import doctorsBackgroundImageMobile from "./img/loginBackgroundImageMobile.png";
import logoDoctors from "./img/doctorsLogo.png";
import Mailto from "react-protected-mailto";
import ReactGA from "react-ga";
import ReCAPTCHA from "react-google-recaptcha";
import { isMobile } from "react-device-detect";
import { UserContext } from "./UserContext.js";
import { db, messaging } from "./firebase.js";
import { getToken } from "firebase/messaging";
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";

function Login() {
  const recaptchaRef = React.createRef();
  const isLocalEnvironment = process.env.NODE_ENV === 'development';
  const {setBasmaAdminID} =  useContext(UserContext)
  const [emailFieldValue, setEmailFieldValue] = useState(
    Cookie.get("rememberme") === "yes" ? Cookie.get("email") : ""
  );
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordFieldValue, setPasswordFieldValue] = useState(
    Cookie.get("rememberme") === "yes" ? Cookie.get("password") : ""
  );
  const [active, setActive] = useState(false);
  const [popupActive, setPopupActive] = useState(false);
  const [isRecaptchaChecked, setIsRecaptchaChecked] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const handleEmailFieldChange = useCallback((value) => {
    setEmailFieldValue(value);
    setEmailError("");
    setErrorMessage("");
  }, []);
  // const getTokenAndRefresh = async (adminId) => {
  //   try {
  //     let token = await getToken(messaging,{vapidKey:process.env.REACT_APP_VAPID_KEY})
  //     const adminCollection = collection(db,"Admins");
  //     const adminQuery = query(adminCollection, where('admin_id','==',parseInt(adminId)))
  //     getDocs(adminQuery).then((adminRef)=>{
  //       console.log('admins',adminRef)
  //       if(adminRef.size>0){
  //         let admin = adminRef.docs[0];
  //         if(token != admin.data().fcm_token){
  //           updateDoc(doc(db,"Admins",admin.id), {fcm_token : token });
  //         }
  //       }
  //     })
  //   } catch (error) {
  //     console.log('An error occurred while retrieving token.', error);
  //   }
  // };
  const handlePasswordFieldChange = useCallback((value) => {
    setPasswordFieldValue(value);
    setPasswordError("");
    setErrorMessage("");
  }, []);

  const toastMarkup = active ? (
    <Toast
      content="These credentials do not match our records."
      onDismiss={toggleActive}
    />
  ) : null;

  function onChange(value) {
    setCaptchaValue(value);
    setIsRecaptchaChecked(true);
  }
  const onSubmitWithReCAPTCHA = async () => {
    setPopupActive(true);
    const form_data = new FormData();
    form_data.append("email", emailFieldValue);
    form_data.append("password", passwordFieldValue);
    if (!isLocalEnvironment) {
      const token = await recaptchaRef.current.executeAsync();
      form_data.append("g-recaptcha-response", token);
    }
    axios
      .post("/admin/v1/auth/login", form_data)
      .then(async(res) => {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.register('/firebase-readMessage-sw.js').then((registration) => {
            console.log('ServiceWorker registration successful with scope: ', registration.scope);
          }, (err) => {
            console.log('ServiceWorker registration failed: ', err);
          });
        }
        setPopupActive(false);
        setBasmaAdminID(res.data.id)
        Cookie.set("admin_id", res.data.id);
        Cookie.set("token", res.data.access_token);
        Cookie.set("password", passwordFieldValue);
        Cookie.set("email", emailFieldValue);
        Cookie.set("name", res.data.name);
        Cookie.set("profileImage", res.data.profile_image);
        Cookie.set("roleId", res.data.roles);
        Cookie.set("imageUrl", res.data.profile_image);
        Cookie.set("userType", res.data.type);
        // await getTokenAndRefresh(res.data.id);
        const returnUrl = history?.location?.state?.returnUrl;
        if (returnUrl) {
          history.push(returnUrl);
        } else if (res.data.roles[0] === 12) {
          history.push("/admin/careers");
        }else if (res.data.type === "50") {
          history.push("/admin/users");
        } else {
          if (res.data.roles[0] === 6) {
            history.push("/admin/orders");
          } else {
            history.push("/admin/home");
          }
        }
      })
      .catch(function (error) {
        if (!isLocalEnvironment) {
          window.grecaptcha.reset();
        }
        setIsRecaptchaChecked("false");
        setPopupActive(false);
        setErrorMessage("The email or password is incorrect");
      });
    // apply to form data
  };

  const [checked, setChecked] = useState(
    Cookie.get("rememberme") === "yes" ? true : false
  );
  const handleChange = (newChecked) => {
    setChecked(newChecked);
    console.log(newChecked);
    Cookie.set("rememberme", newChecked ? "yes" : "no");
  };
  useEffect(() => {
    console.log("cookies=", Cookie.get("rememberme"));
  }, []);
  return (
    <AppProvider>
      {!isLocalEnvironment && (
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LfdVwgkAAAAAE0hIhdjI8nVNMb0U0gOOd-2_jkO"
          onChange={onChange}
          size="invisible"
        />
      )}
      <div
        className={
          window.location.href.indexOf("doctors.basma") > -1
            ? "doctorsImage"
            : ""
        }
        style={{
          backgroundImage:
            window.location.href.indexOf("doctors.basma") > -1
              ? isMobile
                ? `url('${doctorsBackgroundImageMobile}')`
                : `url('${doctorsBackgroundImage}')`
              : null,
        }}
      >
        <div>
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Image
              src={
                window.location.href.indexOf("doctors.basma") > -1
                  ? logoDoctors
                  : logo
              }
              alt="Logo"
            />

            <div style={{ minWidth: 400, marginTop: 20 }}>
              <div style={{ marginBottom: 30 }}>
                <DisplayText size="large">
                  {" "}
                  {window.location.href.indexOf("doctors.basma") > -1
                    ? "Doctor Portal"
                    : "Login"}
                </DisplayText>
              </div>

              <FormLayout>
                <Card sectioned>
                  <FormLayout>
                    <TextField
                      label="Email"
                      value={emailFieldValue}
                      onChange={handleEmailFieldChange}
                      error={emailError}
                    />
                    <div onKeyDown={handleKeyPress}>
                      <TextField
                        value={passwordFieldValue}
                        onChange={handlePasswordFieldChange}
                        type="password"
                        error={passwordError}
                      />
                    </div>
                    <Checkbox
                      label="Remember me"
                      checked={checked}
                      onChange={handleChange}
                    />
                    <TextStyle variation="negative">{errorMessage}</TextStyle>
                    {window.location.href.indexOf("doctors.basma") > -1 ? (
                      <button
                        style={{
                          backgroundColor: "#ff2c46",
                          width: "100%",
                          display: "inline-block",
                          height: "36px",
                          border: "none",
                          borderRadius: "4px",
                          color: "#ffffff",
                          textAlign: "center",
                          cursor: "pointer",
                          fontSize: "17px",
                        }}
                        onClick={handleSignIn}
                      >
                        Log In
                      </button>
                    ) : (
                      <Button
                        fullWidth
                        primary
                        onClick={handleSignIn}
                        style={{ "background-color": "green" }}
                      >
                        Log In
                      </Button>
                    )}

                    <Link url="/forgotpassword">Forgot password?</Link>
                  </FormLayout>
                </Card>
                <div>
                  <div style={{ float: "left" }}>
                    <p style={{ float: "left" }}>
                      {"© Copyright " +
                        new Date().getFullYear() +
                        " " +
                        process.env.REACT_APP_CLIENT_NAME}
                    </p>
                    <br />
                  </div>
                  <div style={{ float: "right" }}></div>
                </div>
              </FormLayout>
            </div>
            {toastMarkup}
            <Modal open={popupActive} loading={true}></Modal>
          </div>
        </div>
      </div>
    </AppProvider>
    // <div
    // className="doctorsImage"
    // style={{
    //   backgroundImage: `url('${doctorsBackgroundImage}')`,
    // }}
    // ></div>
  );

  function handleSignIn(e) {
    if (
      !emailFieldValue ||
      (emailFieldValue &&
        !emailFieldValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) ||
      !passwordFieldValue
    ) {
      if (!emailFieldValue) {
        setEmailError("Please enter your email");
      }
      if (
        emailFieldValue &&
        !emailFieldValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      ) {
        setEmailError("Please enter a valid email");
      }
      if (!passwordFieldValue) {
        setPasswordError("Please enter your password");
      }
    } else {
      onSubmitWithReCAPTCHA();
    }
  }

  function toggleActive() {
    setActive((active) => !active);
  }
  function handleKeyPress(event) {
    const enterKeyPressed = event.keyCode === 13;
    if (enterKeyPressed) {
      handleSignIn();
    }
  }
}
export default Login;
