import { useStyles } from './clinic-reports-invoices-table.styles';
import { TableCell, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { Button, Icon } from '@shopify/polaris';
import { PageDownMajor, PageUpMajor, ViewMinor } from '@shopify/polaris-icons';
import moment from 'moment';
import ClinicsReportsNoteModal from './clinics-reports-note-modal.component';
import ClinicsReportsDisputeModal from './clinics-reports-dispute-modal.component';
import { saveAs } from 'file-saver';

const ClinicsReportsInvoicesTableRow = ({
  report,
  hideColumns,
  handlePaymentConfirmationDialog,
  setReportId,
}) => {
  const classes = useStyles();
  const [activeNoteModal, setActiveNoteModal] = useState(false);
  const [activeDisputeModal, setActiveDisputeModal] = useState(false);
  const clinicName = report?.clinic?.name?.en;
  const cityName = report?.clinic?.city?.name?.en;
  const countryName = report?.clinic?.city?.country?.name?.en;
  const disputeDate = report?.dispute?.created_at
    ? moment(report?.dispute?.created_at).format('DD MMMM YYYY')
    : '-';
  const acceptedDate = report?.accepted_at
    ? moment(report.accepted_at).format('DD MMMM YYYY')
    : '-';
  const invoiceUploadedDate = report?.invoice?.created_at
    ? moment(report?.invoice?.created_at).format('DD MMMM YYYY')
    : '-';
  const note = report?.note ? report?.note : '';
  const dispute = report?.dispute ? report?.dispute : '';
  const invoice = report?.invoice ? report?.invoice : '';
  const version = report?.version ?? '';
  const aws_url_link = report?.aws_url_link ?? '';
  const date = report?.date ?? '';
  const handleNoteDialog = () => {
    setActiveNoteModal(!activeNoteModal);
  };
  const handleDisputeDialog = () => {
    setActiveDisputeModal(!activeDisputeModal);
  };
  const downloadReport = (link, date) => {
    saveAs(link, `${clinicName}-${date}.pdf`);
  };
  const downloadInvoice = (link, date, version) => {
    saveAs(
      link,
      `Invoice-${clinicName}-${date}-${version}.${link.split('.').pop()}`
    );
  };
  return (
    <>
      <TableRow
        className={classes.tableCell}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell className={classes.tableCell}>{clinicName}</TableCell>
        <TableCell className={classes.tableCell}>{cityName}</TableCell>
        <TableCell className={classes.tableCell}>{countryName}</TableCell>
        <TableCell
          className={`${classes.tableCell} ${hideColumns && classes.hide}`}
        >
          {acceptedDate}
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Button onClick={() => downloadReport(aws_url_link, date)} primary>
            <div className={classes.buttonStyle}>
              <Icon source={PageDownMajor} color="base" />
              Report
            </div>
          </Button>
        </TableCell>
        <TableCell className={classes.tableCell}>{version}</TableCell>
        <TableCell className={classes.tableCell}>{disputeDate}</TableCell>
        <TableCell
          className={`${classes.tableCell} ${hideColumns && classes.hide}`}
        >
          {invoiceUploadedDate}
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Button disabled={!note} onClick={handleNoteDialog} primary>
            <div className={classes.buttonStyle}>
              <Icon source={ViewMinor} color="base" /> Note
            </div>
          </Button>
        </TableCell>
        <TableCell>
          <div className={classes.buttonGroup}>
            <Button disabled={!dispute} onClick={handleDisputeDialog} primary>
              <div className={classes.buttonStyle}>
                <Icon source={ViewMinor} color="base" /> Dispute
              </div>
            </Button>
            <div className={`${hideColumns && classes.hide}`}>
              <Button
                disabled={!invoice}
                onClick={() =>
                  downloadInvoice(invoice.files_link, date, version)
                }
                primary
              >
                <div className={classes.buttonStyle}>
                  <Icon source={PageDownMajor} color="base" />
                  Invoice
                </div>
              </Button>
            </div>
            <div className={`${hideColumns && classes.hide}`}>
              <Button
                disabled={!invoice}
                onClick={() => {
                  setReportId(report.id);
                  handlePaymentConfirmationDialog();
                }}
                primary
              >
                <div className={classes.buttonStyle}>
                  <Icon source={PageUpMajor} color="base" />
                  <span style={{ width: 'max-content' }}>
                    Payment Confirmation
                  </span>
                </div>
              </Button>
            </div>
          </div>
        </TableCell>
      </TableRow>
      <ClinicsReportsNoteModal
        note={note}
        activeNoteModal={activeNoteModal}
        handleNoteDialog={handleNoteDialog}
      />
      <ClinicsReportsDisputeModal
        dispute={dispute}
        activeDisputeModal={activeDisputeModal}
        handleDisputeDialog={handleDisputeDialog}
      />
    </>
  );
};

export default ClinicsReportsInvoicesTableRow;
