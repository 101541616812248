import React, { useCallback, useState, useEffect, useRef } from "react";
const IRPHeader = () => {
  return (
    <div>
      {" "}
      <div >
        <img src={require("./pdfimages/logo-red.jpg").default} alt="favicon" height="46px"></img>
      </div>
      <h1 className="centered-heading">
        Interproximal Reduction (IPR) Indicator
      </h1>
    </div>
  );
};
export default IRPHeader;
