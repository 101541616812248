import React, {useCallback, useEffect, useState} from "react";
import {
  TextStyle,
  Page,
  FormLayout,
  TextField,
  Select,
  Card,
  Modal,
  PageActions,
  Checkbox,
  RadioButton,
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";
import history from "../history";
import Select2 from 'react-select';

import { useLocation, useParams } from "react-router-dom";
import {
  capitalizeWords,
  getTrackingDestinationArray,
  getTrackingTypesArray
} from "../util/helpers";
import {DispatchTypeCodes} from "../util/constants";
function AddTracking() {
  let { id, type } = useParams();
  const location = useLocation();
  const [valueTrackingNumber, setValueTrackingNumber] = useState("");
  const [selected, setSelected] = useState({ value: "Impression Kit", label: "Impression Kit" });
  const [country, setCountry] = useState("Rest of the world");
  const [desc, setDesc] = useState("");
  const [popupActive, setPopupActive] = useState(false);
  const [dispatch, setDispatch] = useState({});
  const [checkedSendEmail, setCheckedSendEmail] = useState(false);
  const [types, setTypes] = useState([]);
  const [subType, setSubType] = useState('');
  const [subTypes, setSubTypes] = useState([]);
  const [dropdownList, setDropDownList] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [shippingCarrier, setShippingCarrier] = useState("aramex");
  let  userId = '';
  if (location.state) {
    userId = location.state.userId;
  }
  const handleCountry = (value) => setCountry(value);
  
  const hideDispatchFlow = type !== 'dispatch';
  const disable = !hideDispatchFlow && dispatch?.type_code !== DispatchTypeCodes.aligners;
  
  const handleCarrierChange = useCallback((_, newValue) => {
    setShippingCarrier(newValue);
  }, []);
  
  const handleSelectChange = useCallback((val) => {
    setSelected(val);
    let destinationData = hideDispatchFlow ? orderDestinations : destinations;
    let value = destinationData.find(dest => dest.value === val.value);
    if(value) {
      setDesc(value.desc);
    }
  }, []);
  
  const countries = [
    { value: "KSA", label: "KSA" },
    { value: "Rest of the world", label: "Rest of the world" },
  ];

  const orderOptions = [
    { value: "Aligner Kit", label: "Aligner Kit" },
    { value: "Batch of Aligners", label: "Batch of Aligners" },
    { value: "Bleaching Trays", label: "Bleaching Trays" },
    { value: "Carry Cases", label: "Carry Cases" },
    { value: "Chewies", label: "Chewies" },
    { value: "Electric Toothbrush", label: "Electric Toothbrush" },
    { value: "Extra Aligners", label: "Extra Aligners" },
    { value: "Extra Batch of Aligners", label: "Extra Batch of Aligners" },
    { value: "Extra Bleaching Trays", label: "Extra Bleaching Trays" },
    { value: "Extra Carry Cases", label: "Extra Carry Cases" },
    { value: "Extra Chewies", label: "Extra Chewies" },
    { value: "Extra Electric Toothbrush", label: "Extra Electric Toothbrush" },
    { value: "Extra MCA aligners", label: "Extra MCA aligners" },
    { value: "Extra Night Guards", label: "Extra Night Guards" },
    { value: "Extra Refinement aligner", label: "Extra Refinement aligner" },
    { value: "Extra Remover Tool", label: "Extra Remover Tool" },
    { value: "Extra Retainers", label: "Extra Retainers" },
    { value: "Extra Smile Spreaders", label: "Extra Smile Spreaders" },
    { value: "Extra Templates", label: "Extra Templates" },
    { value: "Extra Whitening Kit", label: "Extra Whitening Kit" },
    { value: "Extra Whitening Pen", label: "Extra Whitening Pen" },
    { value: "Impression Kit", label: "Impression Kit" },
    { value: "In-Clinic Impression", label: "In-Clinic Impression" },
    { value: "MCA aligners", label: "MCA aligners" },
    { value: "Night Guards", label: "Night Guards" },
    { value: "Redo Impression", label: "Redo Impression" },
    { value: "Refinement aligner", label: "Refinement aligner" },
    { value: "Remover Tool", label: "Remover Tool" },
    { value: "Replacement Aligners", label: "Replacement Aligners" },
    { value: "Replacement Batch of Aligners", label: "Replacement Batch of Aligners" },
    { value: "Replacement Bleaching Trays", label: "Replacement Bleaching Trays" },
    { value: "Replacement Carry Cases", label: "Replacement Carry Cases" },
    { value: "Replacement Chewies", label: "Replacement Chewies" },
    { value: "Replacement Electric Toothbrush", label: "Replacement Electric Toothbrush" },
    { value: "Replacement MCA aligners", label: "Replacement MCA aligners" },
    { value: "Replacement Night Guards", label: "Replacement Night Guards" },
    { value: "Replacement Refinement aligner", label: "Replacement Refinement aligner" },
    { value: "Replacement Remover Tool", label: "Replacement Remover Tool" },
    { value: "Replacement Retainers", label: "Replacement Retainers" },
    { value: "Replacement Smile Spreaders", label: "Replacement Smile Spreaders" },
    { value: "Replacement Templates", label: "Replacement Templates" },
    { value: "Replacement Whitening Kit", label: "Replacement Whitening Kit" },
    { value: "Replacement Whitening Pen", label: "Replacement Whitening Pen" },
    { value: "Retainers", label: "Retainers" },
    { value: "Return Impression Kit", label: "Return Impression Kit" },
    { value: "Return Redo Impression", label: "Return Redo Impression" },
    { value: "Smile Spreaders", label: "Smile Spreaders" },
    { value: "Templates", label: "Templates" },
    { value: "Whitening Kit", label: "Whitening Kit" },
    { value: "Whitening Pen", label: "Whitening Pen" },
  ];

  const orderDestinations = [
    {
      value: "AK from LEB to UAE",
      label: "(KSA) AK from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "AK from UAE to Client",
      label: "(KSA) AK from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Batch of Aligners from LEB to UAE",
      label: "(KSA) Batch of Aligners from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Batch of Aligners from UAE to Client",
      label: "(KSA) Batch of Aligners from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Bleaching Trays from LEB to UAE",
      label: "(KSA) Bleaching Trays from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Bleaching Trays from UAE to Client",
      label: "(KSA) Bleaching Trays from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Carry Cases from LEB to UAE",
      label: "(KSA) Carry Cases from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Carry Cases from UAE to Client",
      label: "(KSA) Carry Cases from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Chewies from LEB to UAE",
      label: "(KSA) Chewies from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Chewies from UAE to Client",
      label: "(KSA) Chewies from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Electric Toothbrush from LEB to UAE",
      label: "(KSA) Electric Toothbrush from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Electric Toothbrush from UAE to Client",
      label: "(KSA) Electric Toothbrush from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Aligners from LEB to UAE",
      label: "(KSA) Extra Aligners from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Aligners from UAE to Client",
      label: "(KSA) Extra Aligners from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Batch of Aligners from LEB to UAE",
      label: "(KSA) Extra Batch of Aligners from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Batch of Aligners from UAE to Client",
      label: "(KSA) Extra Batch of Aligners from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Bleaching Trays from LEB to UAE",
      label: "(KSA) Extra Bleaching Trays from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Bleaching Trays from UAE to Client",
      label: "(KSA) Extra Bleaching Trays from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Carry Cases from LEB to UAE",
      label: "(KSA) Extra Carry Cases from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Carry Cases from UAE to Client",
      label: "(KSA) Extra Carry Cases from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Chewies from LEB to UAE",
      label: "(KSA) Extra Chewies from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Chewies from UAE to Client",
      label: "(KSA) Extra Chewies from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Electric Toothbrush from LEB to UAE",
      label: "(KSA) Extra Electric Toothbrush from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Electric Toothbrush from UAE to Client",
      label: "(KSA) Extra Electric Toothbrush from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra MCA AK from LEB to UAE",
      label: "(KSA) Extra MCA AK from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra MCA AK from UAE to Client",
      label: "(KSA) Extra MCA AK from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Night Guards from LEB to UAE",
      label: "(KSA) Extra Night Guards from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Night Guards from UAE to Client",
      label: "(KSA) Extra Night Guards from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Refinement AK from LEB to UAE",
      label: "(KSA) Extra Refinement AK from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Refinement AK from UAE to Client",
      label: "(KSA) Extra Refinement AK from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Remover Tool from LEB to UAE",
      label: "(KSA) Extra Remover Tool from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Remover Tool from UAE to Client",
      label: "(KSA) Extra Remover Tool from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Retainers from LEB to UAE",
      label: "(KSA) Extra Retainers from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Retainers from UAE to Client",
      label: "(KSA) Extra Retainers from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Smile Spreaders from LEB to UAE",
      label: "(KSA) Extra Smile Spreaders from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Smile Spreaders from UAE to Client",
      label: "(KSA) Extra Smile Spreaders from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Templates from LEB to UAE",
      label: "(KSA) Extra Templates from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Templates from UAE to Client",
      label: "(KSA) Extra Templates from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Whitening Kit from LEB to UAE",
      label: "(KSA) Extra Whitening Kit from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Whitening Kit from UAE to Client",
      label: "(KSA) Extra Whitening Kit from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Whitening Pen from LEB to UAE",
      label: "(KSA) Extra Whitening Pen from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Extra Whitening Pen from UAE to Client",
      label: "(KSA) Extra Whitening Pen from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "MCA AK from LEB to UAE",
      label: "(KSA) MCA AK from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "MCA AK from UAE to Client",
      label: "(KSA) MCA AK from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Night Guards from LEB to UAE",
      label: "(KSA) Night Guards from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Night Guards from UAE to Client",
      label: "(KSA) Night Guards from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Refinement AK from LEB to UAE",
      label: "(KSA) Refinement AK from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Refinement AK from UAE to Client",
      label: "(KSA) Refinement AK from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Remover Tool from LEB to UAE",
      label: "(KSA) Remover Tool from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Remover Tool from UAE to Client",
      label: "(KSA) Remover Tool from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement AK from LEB to UAE",
      label: "(KSA) Replacement AK from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement AK from UAE to Client",
      label: "(KSA) Replacement AK from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Batch of Aligners from LEB to UAE",
      label: "(KSA) Replacement Batch of Aligners from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Batch of Aligners from UAE to Client",
      label: "(KSA) Replacement Batch of Aligners from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Bleaching Trays from LEB to UAE",
      label: "(KSA) Replacement Bleaching Trays from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Bleaching Trays from UAE to Client",
      label: "(KSA) Replacement Bleaching Trays from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Carry Cases from LEB to UAE",
      label: "(KSA) Replacement Carry Cases from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Carry Cases from UAE to Client",
      label: "(KSA) Replacement Carry Cases from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Chewies from LEB to UAE",
      label: "(KSA) Replacement Chewies from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Chewies from UAE to Client",
      label: "(KSA) Replacement Chewies from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Electric Toothbrush from LEB to UAE",
      label: "(KSA) Replacement Electric Toothbrush from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Electric Toothbrush from UAE to Client",
      label: "(KSA) Replacement Electric Toothbrush from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement MCA AK from LEB to UAE",
      label: "(KSA) Replacement MCA AK from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement MCA AK from UAE to Client",
      label: "(KSA) Replacement MCA AK from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Night Guards from LEB to UAE",
      label: "(KSA) Replacement Night Guards from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Night Guards from UAE to Client",
      label: "(KSA) Replacement Night Guards from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Refinement AK from LEB to UAE",
      label: "(KSA) Replacement Refinement AK from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Refinement AK from UAE to Client",
      label: "(KSA) Replacement Refinement AK from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Remover Tool from LEB to UAE",
      label: "(KSA) Replacement Remover Tool from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Remover Tool from UAE to Client",
      label: "(KSA) Replacement Remover Tool from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Retainers from LEB to UAE",
      label: "(KSA) Replacement Retainers from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Retainers from UAE to Client",
      label: "(KSA) Replacement Retainers from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Smile Spreaders from LEB to UAE",
      label: "(KSA) Replacement Smile Spreaders from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Smile Spreaders from UAE to Client",
      label: "(KSA) Replacement Smile Spreaders from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Templates from LEB to UAE",
      label: "(KSA) Replacement Templates from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Templates from UAE to Client",
      label: "(KSA) Replacement Templates from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Whitening Kit from LEB to UAE",
      label: "(KSA) Replacement Whitening Kit from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Whitening Kit from UAE to Client",
      label: "(KSA) Replacement Whitening Kit from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Whitening Pen from LEB to UAE",
      label: "(KSA) Replacement Whitening Pen from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Replacement Whitening Pen from UAE to Client",
      label: "(KSA) Replacement Whitening Pen from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Retainers from LEB to UAE",
      label: "(KSA) Retainers from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Retainers from UAE to Client",
      label: "(KSA) Retainers from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Return IK from KSA client to UAE",
      label: "(KSA) Return IK from KSA client to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Return IK from UAE to LEB",
      label: "(KSA) Return IK from UAE to LEB",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Return redo IK from KSA client to UAE",
      label: "(KSA) Return redo IK from KSA client to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Return redo IK from UAE to LEB",
      label: "(KSA) Return redo IK from UAE to LEB",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Smile Spreaders from LEB to UAE",
      label: "(KSA) Smile Spreaders from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Smile Spreaders from UAE to Client",
      label: "(KSA) Smile Spreaders from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Templates from LEB to UAE",
      label: "(KSA) Templates from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Templates from UAE to Client",
      label: "(KSA) Templates from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Whitening Kit from LEB to UAE",
      label: "(KSA) Whitening Kit from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Whitening Kit from UAE to Client",
      label: "(KSA) Whitening Kit from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Whitening Pen from LEB to UAE",
      label: "(KSA) Whitening Pen from LEB to UAE",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
    {
      value: "Whitening Pen from UAE to Client",
      label: "(KSA) Whitening Pen from UAE to Client",
      desc: { dhl: "DHL", aramex: "Aramex" },
    },
  ];

  const handleChangeSendEmail = useCallback(
    (newChecked) => setCheckedSendEmail(newChecked),
    []
  );

  const getDispatchType = async () => {
    try {
      setPopupActive(true);
      const response = await axios.get(`admin/v1/dispatches/${id}`, {
        headers: {
          Authorization: `Bearer ${Cookie.get('token')}`,
        },
      });
      if (response.status === 200) {
        const { data, list, subTypesData } = response.data;
        if (userId === '' && data.user_id) {
            userId = data.user_id;
        }
        const subTypesArray = [];
        for (let i = 0; i < subTypesData.length; i++) {
          subTypesArray.push({value: subTypesData[i], label: capitalizeWords(subTypesData[i])});
        }
        const typesArray = getTrackingTypesArray(data.type_code, data.sub_type, list);
        setDispatch(data);
        setSelected(typesArray[0]);
        setSubTypes(subTypesArray);
        setDropDownList(list);
        setTypes(typesArray);
      }
    } catch (error) {
      console.error('Error in dispatch api', error);
    } finally {
      setPopupActive(false);
    }
  };
  const handleBackTracking = () => {
    history.push(
      `/admin/orders/${id}/trackings/${type === "dispatch" ? "dispatch" : ""}`,
      { userId }
    );
  };
  const [errorTrackingNumber, setErrorTrackingNumber] = useState("");
  
  useEffect(() => {
    if (type === "dispatch") {
      void getDispatchType();
    }
  }, [id]);


  useEffect(() => {
    if (!hideDispatchFlow) {
      if (country === 'KSA') {
        const trackingDestinationArray = getTrackingDestinationArray(dispatch.type_code, dispatch.sub_type, dropdownList)
        setDestinations(trackingDestinationArray);
        setSelected(trackingDestinationArray[0]);
        setDesc(trackingDestinationArray[0]?.desc)
      }
      setSubType(dispatch.sub_type);
    } else {
      setSelected(orderOptions[0]);
    }
  }, [country, dispatch]);
  
  return (
    <Page
      title="Add Tracking Number"
      breadcrumbs={[
        {
          content: 'List Of trackings',
          onAction: handleBackTracking,
        },
      ]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Select Country</TextStyle>
          <Select
            options={countries}
            onChange={handleCountry}
            value={country}
          />
          {country === 'Rest of the world' && (
            <div>
              <div>
                <TextStyle variation="strong">Carrier</TextStyle>
              </div>
              <RadioButton
                label="DHL"
                helpText="Ship with DHL."
                checked={shippingCarrier === 'dhl'}
                id="dhl"
                onChange={handleCarrierChange}
              />
              <RadioButton
                label="Aramex"
                helpText="Ship with Aramex."
                id="aramex"
                checked={shippingCarrier === 'aramex'}
                onChange={handleCarrierChange}
              />
            </div>
          )}

          <TextStyle variation="strong">Type</TextStyle>
          <div className="orderOptions-react-dropdown-select-dropdown">
            <Select2
              options={!hideDispatchFlow ? types : (country === "KSA" ? orderDestinations : orderOptions)}
              onChange={handleSelectChange}
              value={selected}
              disabled={disable}
              menuPortalTarget={document.body}
              styles={{ menuPortal: base => ({ ...base, zIndex: 21 }) }}
            />
          </div>
          {!hideDispatchFlow && (
            <>
              <TextStyle variation="strong">Sub Type</TextStyle>
              <Select
                options={subTypes}
                onChange={handleSelectChange}
                value={subType}
                disabled={type === 'dispatch'}
              />
              {country === 'KSA' && (
                  <>
                    <TextStyle variation="strong">Destination</TextStyle>
                    <Select2
                        options={destinations}
                        onChange={handleSelectChange}
                        value={selected}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 21 }) }}
                    />
                  </>
              )}
            </>
          )}

          <Checkbox
            label="Send Email"
            checked={checkedSendEmail}
            onChange={handleChangeSendEmail}
          />
          {country == 'KSA' && (
            <div>
              {!(typeof desc == 'string') && (
                <div>
                  <div>
                    <TextStyle variation="strong">Carrier</TextStyle>
                  </div>
                  <div>
                    <RadioButton
                      label="DHL"
                      helpText="Ship with DHL."
                      checked={shippingCarrier === 'dhl'}
                      id="dhl"
                      onChange={handleCarrierChange}
                    />
                    <RadioButton
                      label="Aramex"
                      helpText="Ship with Aramex."
                      id="aramex"
                      checked={shippingCarrier === 'aramex'}
                      onChange={handleCarrierChange}
                    />
                  </div>
                </div>
              )}

              <div>
                <TextStyle variation="strong">Tracking Description</TextStyle>
                <TextField
                  value={
                    country == 'KSA'
                      ? typeof desc == 'string'
                        ? desc
                        : desc[shippingCarrier]
                      : ''
                  }
                />
              </div>
            </div>
          )}
          <TextStyle variation="strong">Tracking Number</TextStyle>
          <TextField
            value={valueTrackingNumber}
            onChange={handleChangeTrackingNumber}
            error={errorTrackingNumber}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: 'Save',
          onClick: handleSave,
        }}
      />
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );

  function handleChangeTrackingNumber(valueTrackingNumber) {
    setValueTrackingNumber(valueTrackingNumber);
  }

  function handleSave() {
    setPopupActive(true);
    if (valueTrackingNumber === "") {
      setErrorTrackingNumber("Tracking Number Field is empty");
      setPopupActive(false);
      return;
    }
    setErrorTrackingNumber("");
    const bodyObj = {
      tracking_number: valueTrackingNumber.replace(/\s/g, ""),
      type: selected?.value,
      sending_email: checkedSendEmail,
      country: country,
      carrier:
        country == "Rest of the world"
          ? shippingCarrier.charAt(0).toUpperCase() + shippingCarrier.slice(1)
          : typeof desc == "string"
          ? desc
          : desc[shippingCarrier],
    };

    let url = `admin/v1/orders/${id}/trackings/`;
    if (type) {
      url += type;
    }
    axios
      .post(url, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        setPopupActive(false);
        if (res.success === false) {
          setErrorTrackingNumber("Invalid tracking number");
        } 
        else {
          setPopupActive(false);
          history.push(
            `/admin/orders/${id}/trackings${
              type === "dispatch" ? "/dispatch" : ""
            }`,
            { userId }
          );
        }
      }).catch(function (error) {
        setPopupActive(false);
        setErrorTrackingNumber("Invalid tracking number");
      });
  }
}
export default AddTracking;
