import React, { useRef, useState } from 'react';
import { Card, Page } from '@shopify/polaris';
import axios from '../../axios';
import Cookies from 'js-cookie';
import CreateProductRequestForm from './create-product-request-form.component';
import Cookie from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Axios from 'axios';

const CreateProductRequest = () => {
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [urgencyStatues, setUrgencyStatues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [formData, setFormData] = useState({
    userId: '',
    product: '',
    quantity: '',
    urgency: '',
    reason: '',
    paid: false,
  });

  const cancelTokenRef = useRef(null);

  const getProductRequestParameters = async (signal) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `/admin/v1/product-requests/parameters`,
        {
          signal,
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        }
      );
      if (response.status === 200) {
        const { productTypes, urgency } = response?.data;
        setProducts(productTypes);
        setUrgencyStatues(urgency);
      }
    } catch (error) {
      console.log('Error in product requests api', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      setLoadingSave(true);
      const response = await axios.post(
        `/admin/v1/product-requests`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.success === true) {
          history.push('/admin/reports/product-requests');
        } else {
          toast.error(response.data.message, 3000);
        }
      } else {
        toast.error('Something Wrong Happened', 3000);
      }
    } catch (error) {
      console.log('Error in store product requests api', error);
    } finally {
      setLoadingSave(false);
    }
  };

  const getUserByName = async (inputValue, callback) => {
    if (!inputValue || inputValue.length < 3) {
      callback([]);
      return;
    }

    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel('Operation canceled by user.');
    }

    const cancelTokenSource = Axios.CancelToken.source();
    cancelTokenRef.current = cancelTokenSource;

    try {
      setLoadingUser(true);
      const response = await axios.get(
        `/admin/v1/users/search/?name=${inputValue}&dispatch=true`,
        {
          cancelToken: cancelTokenSource.token,
          headers: {
            Authorization: 'Bearer ' + Cookie.get('token'),
          },
        }
      );
      
      if (response.status === 200 && !cancelTokenSource.token.reason) {
        let usersFound = [];
        let result = response.data.data;
        if (result && result.length > 0) {
          usersFound = result.map((datum) => ({
            label: `${datum.first_name} ${datum.last_name} ( ID : ${datum.id} )`,
            value: datum.id,
          }));
        }
        callback(usersFound);
      }
    } catch (error) {
      console.log('Error get user by name api:', error);
      callback([]);
    } finally {
      setLoadingUser(false);
    }
  };

  return (
    <Page
      fullWidth
      title="Create Product Request"
      breadcrumbs={[
        { content: 'List Of Reports', url: '/admin/reports/product-requests' },
      ]}
      primaryAction={{
        content: 'Save',
        onClick: handleSave,
        loading: loadingSave,
      }}
    >
      <ToastContainer />
      <Card sectioned>
        <CreateProductRequestForm
          getProductRequestParameters={getProductRequestParameters}
          products={products}
          urgencyStatues={urgencyStatues}
          loading={loading}
          getUserByName={getUserByName}
          loadingUser={loadingUser}
          formData={formData}
          setFormData={setFormData}
        />
      </Card>
    </Page>
  );
};

export default CreateProductRequest;
