import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../../history";
import { AppProvider } from "@shopify/polaris";
import ListOfComments from "./List";
import AddComment from "./Add";
import EditComment from "./Edit";

function MainComments() {
  return (
    <AppProvider>
      <Router history={history}>
        <Switch>
          <Route
            exact
            path="/admin/users/:id/dashboard/comments"
            component={ListOfComments}
          />
          <Route
            path="/admin/users/:id/dashboard/comments/add"
            component={AddComment}
          />
          <Route
            path="/admin/users/:userId/dashboard/comments/:commentId"
            component={EditComment}
          />
        </Switch>
      </Router>
    </AppProvider>
  );
}
export default MainComments;
