import React, { useCallback, useState, useEffect } from "react";
import {
  Card,
  Tabs,
  Filters,
  Badge,
  TextStyle,
  Page,
  DataTable, Image, DisplayText
} from '@shopify/polaris';
import axios from "../axios";
import Cookie from "js-cookie";
import { RadioGroup, Radio } from "react-radio-group";
import Axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import { getUserTreatmentTypeBadge, tagsColor } from '../util/helpers';
import ThinLoading from '../components/Loading/thin-loading.component';
import emptyListImage from '../img/emptyList.svg';
import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  link: {
    color: 'inherit',
    textDecoration: 'none'
  }
}));

export default function ListOfUsers() {
  const classes = styles();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    Cookie.get("usersCurrentPage") ? Cookie.get("usersCurrentPage") : 1
  );
  const [totalPages, setTotalPages] = useState(0);
  const [sortState, setSortState] = useState('created_at DESC');
  const perPage = 20;
  const [selectedTab, setSelectedTab] = useState(
    parseInt(Cookie.get("usersSelectedTab"))
      ? parseInt(Cookie.get("usersSelectedTab"))
      : 0
  );
  
  function handleTabChange(selectedTab) {
    setCurrentPage(1);
    setSelectedTab(selectedTab);
    Cookie.set("usersSelectedTab", selectedTab);
  }

  function handleSort(index) {
    switch (index) {
      case 0:
        setSortState(sortState === "id ASC" ? "id DESC" : "id ASC");
        break;
      case 1:
        setSortState(sortState === "first_name ASC, last_name ASC" ? "first_name DESC, last_name DESC" : "first_name ASC, last_name ASC");
        break;
      default:
    }
  }

  const tabs = [
    {
      id: "?filter[status]=",
      content: "All",
    },
    {
      id: "?filter[status]=OTP Confirmed",
      content: "Booked A Scan",
    },
    {
      id: "?filter[status]=Taking Impressions and Photos",
      content: "Uploading Impressions & Photos",
    },
    {
      id: "?filter[status]=Your Smile Plan",
      content: "Qualified",
    },
    {
      id: "?filter[status]=Aligner Kit Purchased",
      content: "Smile Journeys",
    },
    {
      id: "?filter[status]=Completed",
      content: "Completed",
    },
    {
      id: "?filter[status]=Severe Case Pending",
      content: "Review Severe Cases",
    },
    {
      id: "?filter[status]=Purchase Impression Kit",
      content: "Pending Purchase Impression Kit",
    },
    {
      id: "?filter[status]=Pending Taking Impressions and Photos",
      content: "Review Impressions",
    },
    {
      id: "?filter[status]=Your Photos",
      content: "Screening",
    },
    {
      id: "?filter[is_crystalign]=1",
      content: "From Crystalign",
    },
    {
      id: "?filter[is_lost]=1",
      content: "Is Lost",
    },
    {
      id: "?filter[is_influencer]=1",
      content: "Is Influencer",
    },
  ];
  const [queryValue, setQueryValue] = useState(
    Cookie.get("usersQueryValue") ? Cookie.get("usersQueryValue") : ""
  );
  const handleQueryValueRemove = useCallback(() => {
    Cookie.set("usersQueryValue", "");
    setQueryValue("");
  }, []);
  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);
  const [items, setItems] = useState([]);
  const [radioGroupValue, setRadioGroupValue] = useState(
    Cookie.get("usersAvailability")
  );
  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    getData(ourRequest);
    return () => {
      ourRequest.cancel();
    }
  }, [selectedTab, queryValue, currentPage, sortState]);

  function getData(ourRequest) {
    Cookie.set("availability", Cookie.get("usersAvailability"));
    setLoading(true);
    axios
      .get(
        decodeURI(
          encodeURI(
            `admin/v1/users${
              tabs[selectedTab].id
            }&sort_by=${sortState}&per_page=${perPage}&page=${currentPage}&filter[${radioGroupValue}]=${Cookie.get(
              "usersQueryValue"
            )}`
          )
        ),
        {
          cancelToken: ourRequest.token,
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      )
      .then((result) => {
        setTotalPages(Math.ceil(result.data.data.total / perPage));
        setItems(
          result.data.data.data.map((item) => [
            <a
              href={`/admin/users/${item.id}/dashboard`}
              className={classes.link}
            >
              <b>{item.id}</b>
            </a>,
            <a
              href={`/admin/users/${item.id}/dashboard`}
              className={classes.link}
            >
              {item.full_name}
            </a>,
            item.sub_status && (
              <a
                href={`/admin/users/${item.id}/dashboard`}
                className={classes.link}
              >
                <Badge>{item.sub_status}</Badge>
              </a>
            ),
            <a
              href={`/admin/users/${item.id}/dashboard`}
              className={classes.link}
            >
              <Badge
                status={switchUserStatusColors(switchUserStatus(item.status))}
              >
                {switchUserStatus(item.status)}
              </Badge>
            </a>,
            <a
              href={`/admin/users/${item.id}/dashboard`}
              className={classes.link}
            >
              {item?.clinic?.name_identification?.en ?? item?.clinic?.name}
            </a>,
            <a
              href={`/admin/users/${item.id}/dashboard`}
              className={classes.link}
            >
              {item?.clinic?.city?.country?.name?.en}
            </a>,
            <a
              href={`/admin/users/${item.id}/dashboard`}
              className={classes.link}
            >
              {getUserTreatmentTypeBadge(item.operations_plan_type)}
            </a>,
            item.client_status && (
              <a
                href={`/admin/users/${item.id}/dashboard`}
                className={tagsColor(item.client_status, "status")}
              >
                <div style={{ minWidth: "100px", maxWidth: "100px" }}>
                  <Badge
                    status={
                      item.client_status == "Good"
                        ? "success"
                        : item.client_status == "Overdue"
                          ? "attention"
                          : item.client_status == "Doubtful"
                            ? "warning"
                            : item.client_status == "Settled"
                              ? "info"
                              : "critical"
                    }
                  >
                    {item.client_status}
                  </Badge>
                </div>
              </a>
            ),
          ]),
        );
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }
  
  return (
    <Page
      fullWidth
      title="Users"
      primaryAction={{
        content: "Add User",
        url: "/admin/users/add",
      }}
    >
        <Card>
        <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
          <Card>
            <Card.Section>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <TextStyle variation="strong">Search By : </TextStyle>
                <RadioGroup
                  name="fruit"
                  selectedValue={radioGroupValue}
                  onChange={handleChangeFilter}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <label style={{ marginLeft: "20px" }}>
                      <Radio value="in_table" />
                      All
                    </label>
                    <label style={{ marginLeft: "20px" }}>
                      <Radio value="first_name" />
                      First Name
                    </label>
                    <label style={{ marginLeft: "20px" }}>
                      <Radio value="last_name" />
                      Last Name
                    </label>
                    <label style={{ marginLeft: "20px" }}>
                      <Radio value="email" />
                      Email
                    </label>
                    <label style={{ marginLeft: "20px" }}>
                      <Radio value="id" />
                      ID
                    </label>
                    <label style={{ marginLeft: "20px" }}>
                      <Radio value="tracking" />
                      DHL Tracking Number
                    </label>
                  </div>
                </RadioGroup>
              </div>
            </Card.Section>
            <Card.Section>
              <Filters
                queryValue={queryValue}
                onQueryChange={handleQueryValueChange}
                onQueryClear={handleQueryValueRemove}
                onClearAll={handleClearAll}
                filters={[]}
                disableFilters
              />
            </Card.Section>
            {loading ? (
              <ThinLoading />
            ) : (
              <>
                <DataTable
                  hideScrollIndicator
                  columnContentTypes={["text", "text", "text", "text", "text", "text", "text", "text"]}
                  headings={["ID", "Full Name", "Substatus", "Status", "Clinic", "Country", "Treatment Type", "Customer Standing"]}
                  rows={items}
                  sortable={[true, true, false, false, false, false, false, false]}
                  initialSortColumnIndex={0}
                  defaultSortDirection="descending"
                  onSort={handleSort}
                  verticalAlign="middle"
                />
                {items && items.length === 0 && (
                  <div className="message-no-result">
                    <div className="message-no-result-img">
                      <Image src={emptyListImage} alt="empty result"></Image>
                    </div>
                    <DisplayText size="small">No results found</DisplayText>
                    <br />
                  </div>
                )}
                {totalPages > 1 && (
                  <div
                    style={{
                      paddingTop: "10px",
                      textAlign: "center",
                      paddingBottom: "10px",
                    }}
                  >
                    <Pagination
                      size="large"
                      page={Number(currentPage)}
                      count={totalPages}
                      boundaryCount={2}
                      style={{ margin: "auto", width: "fit-content" }}
                      showFirstButton
                      showLastButton
                      onChange={(e, p) => {
                        setCurrentPage(p);
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </Card>
        </Tabs>
      </Card>
    </Page>
  );
  function handleQueryValueChange(queryValue) {
    Cookie.set("usersQueryValue", queryValue);
    setQueryValue(queryValue);
  }
  function handleChangeFilter(selectedValue) {
    setRadioGroupValue(selectedValue);
    Cookie.set("usersAvailability", selectedValue);
  }
  function switchUserStatusColors(param) {
    switch (param) {
      case "Screening": case "Book A Scan Your Photos":
        return "info";
      case "Qualified Both":
        return "success";
      case "Qualified Top":
        return "info";
      case "Qualified Bottom":
        return "attention";
      case "Refund Requested":
        return "critical";
      case "User Not Qualified":
        return "attention";
      case "Uploading Impressions & Photos":
        return "new";
      case "Review Impressions":
        return "info";
      case "Eligible With Redo Kit":
        return "info";
      case "Qualified":
        return "attention";
      case "Aligner Kit Purchased":
        return "critical";
      case "Smile Journey":
        return "success";
      case "From Crystalign":
        return "new";
      case "Account Created":
        return "info";
      case "Severe Case Pending":
        return "info";
      case "Severe Case Rejected":
        return "attention";
      case "Pending Purchase Impression Kit":
        return "critical";
      case "Purchase Book A Scan":
        return "new";
      case "Book A Scan Purchased":
        return "success";
      case "Impression Kit Purchased":
        return "success";
      case "Free Book A Scan":
        return "new";
      case "OTP Confirmed":
        return "critical";
      default:
    }
  }
  function switchUserStatus(param) {
    switch (param) {
      case "Your Photos":
        return "Screening";
      case "Completed":
        return "Completed";
      case "Qualified Both":
        return "Qualified Both";
      case "Qualified Top":
        return "Qualified Top";
      case "Qualified Bottom":
        return "Qualified Bottom";
      case "Refund Requested":
        return "Refund Requested";
      case "User Not Qualified":
        return "User Not Qualified";
      case "Taking Impressions and Photos":
        return "Uploading Impressions & Photos";
      case "Pending Taking Impressions and Photos":
        return "Review Impressions";
      case "Eligible With Redo Kit":
        return "Eligible With Redo Kit";
      case "Your Smile Plan":
        return "Qualified";
      case "Aligner Kit Purchased":
        return "Aligner Kit Purchased";
      case "Your Smile Journey":
        return "Smile Journey";
      case "From Crystalign":
        return "From Crystalign";
      case "Account Created":
        return "Account Created";
      case "Severe Case Pending":
        return "Severe Case Pending";
      case "Severe Case Rejected":
        return "Severe Case Rejected";
      case "Purchase Impression Kit":
        return "Pending Purchase Impression Kit";
      case "Purchase Book A Scan":
        return "Pending purchase book a scan";
      case "Book A Scan Purchased":
        return "Book A Scan Purchased";
      case "Book A Scan Your Photos":
        return "Book A Scan Your Photos";
      case "Impression Kit Purchased":
        return "Impression Kit Purchased";
      case "Free Book A Scan":
        return "Free Book A Scan";
      case "OTP Confirmed":
        return "OTP Confirmed";
      default:
    }
  }
}
