import {
  Button,
  Card,
  DataTable,
  DisplayText,
  Image,
  InlineError,
  TextStyle,
  Toast,
} from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import axios from "../axios";
import emptyIcon from "../img/emptyList.svg";
import moment from 'moment';

const ScanClinicPricing = ({
  allScanClinicsPricing,
  clinicSelectOptions,
  selectedClinics,
}) => {
  const [clinicIds, setClinicIds] = useState(selectedClinics);
  const [clinicPricing, setClinicPricing] = useState([]);
  const [messageData, setMessageData] = useState("");
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const [error, setError] = useState("");
  const onChangeHandler = (e, index, check = false) => {
    const { name, value, checked } = e.target;
    let newClinicPricing = clinicPricing.map((item, i) => {
      if (index === i) {
        if (check) {
          return { ...item, [name]: checked ? 1 : 0 };
        }
        if (value >= 0) {
          return { ...item, [name]: value };
        } else {
          return item;
        }
      } else {
        return item;
      }
    });
    setClinicPricing(newClinicPricing);
  };

  const onSaveHandler = async (index) => {
    try {
      setError("");
      const data = clinicPricing[index];
      const {
        id,
        bas,
        displayed_bas_price_at_clinic,
        bas_at_clinic,
        opg,
        xray,
        cleaning,
        dental_checkup,
        extraction,
        ipr,
        buttons,
        attachments,
        buttonsRemoval,
        attachmentsRemoval,
        redoScan, // REDO SCAN - SCAN ERROR
        redoIk,
        removeFixedRetainers,
        redoScanPhotos,
        refinementScan,
        periodontal_checkup,
        rebondAttachment,
        expat,
        redoScanErrorDental, // REDO SCAN - POST DENTAL WORK
        redoScanCleaning,
        redoScanWire,
        refinementScanError,
        mcaScan,
        mcaScanError,
        retainer_scan,
        incentive,
        replace_powerchain,
        mca_photos,
        refinement_photos,
        post_treatment_photos,
        measure_spaces,
        rebond_buttons,
        remove_excess_around_attachments,
        in_clinic_whitening,
        referral_incentive,
      } = data;
      if (
        bas !== "" &&
        displayed_bas_price_at_clinic !== "" &&
        bas_at_clinic !== "" &&
        opg !== "" &&
        xray !== "" &&
        cleaning !== "" &&
        dental_checkup !== "" &&
        extraction !== "" &&
        ipr !== "" &&
        buttons !== "" &&
        attachments !== "" &&
        buttonsRemoval !== "" &&
        attachmentsRemoval !== "" &&
        redoScan !== "" &&
        redoIk !== "" &&
        removeFixedRetainers !== "" &&
        redoScanPhotos !== "" &&
        refinementScan !== "" &&
        periodontal_checkup !== "" &&
        rebondAttachment !== "" &&
        expat !== "" &&
        rebondAttachment !== "" &&
        redoScanErrorDental !== "" &&
        redoScanCleaning !== "" &&
        redoScanWire !== "" &&
        refinementScanError !== "" &&
        mcaScan !== "" &&
        mcaScanError !== "" &&
        retainer_scan !== "" &&
        incentive !== "" &&
        replace_powerchain !== "" &&
        mca_photos !== "" &&
        refinement_photos !== "" &&
        post_treatment_photos !== "" &&
        measure_spaces !== "" &&
        rebond_buttons !== "" &&
        remove_excess_around_attachments !== "" &&
        in_clinic_whitening !== "" &&
        referral_incentive !== "" &&
        clinicIds[index] !== undefined
      ) {
        const currentMonth = moment().date(1);
        const formattedCurrentMonth = currentMonth.format("YYYY-MM");
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/admin/clinic-pricing/${id}`,
          {
            ...clinicPricing[index],
            clinic_id: parseInt(clinicIds[index]),
            effective_date: formattedCurrentMonth,
          },
        );
        if (response) {
          const { message } = response.data;
          setMessageData(message);
          setActive(true);
        }
        return;
      }
      setError("All Fields are required");
    } catch (error) {
      console.log(error);
    }
  };

  const onDeleteHandler = async (index) => {
    try {
      setError("");
      let newClinicPricing = clinicPricing.filter(pricing => clinicPricing[index]?.id !== pricing.id);
      setClinicPricing(newClinicPricing);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/clinic-pricing/${clinicPricing[index]?.id}`,
        { _method: "DELETE" }
      );
      if (response) {
        const { message } = response.data;
        setMessageData(message);
        setActive(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const inputStyle = {
    fontSize: "1.6rem",
    fontWeight: "400",
    lineHeight: "2.4rem",
    textTransform: "initial",
    letterSpacing: "initial",
    position: "relative",
    zIndex: "20",
    display: "block",
    flex: "1 1",
    minWidth: "50px",
    minHeight: "3.6rem",
    margin: "0",
    padding: "0.5rem 1.2rem",
    background: "none",
    border: "0.1rem solid grey",
    borderRadius: "5px",
    fontFamily: "inherit",
  };

  const checkStyle = {
    minWidth: '20px',
    minHeight: '3.6rem',
    margin: 'auto',
  };

  const handleSelectClinicReports = (selectedClinicId, index) => {
    let newClinicsId = clinicIds;
    if (!clinicIds[index]) {
      newClinicsId.push(selectedClinicId.value);
    } else {
      newClinicsId = clinicIds.map((item, i) => {
        if (index === i) {
          return selectedClinicId.value;
        } else {
          return item;
        }
      });
    }
    setClinicIds(newClinicsId);
  };

  const handleCounter = () => {
    setClinicPricing((prev) => [
      ...prev,
      {
        bas: 0,
        displayed_bas_price_at_clinic: 0,
        bas_at_clinic: 0,
        opg: 0,
        xray: 0,
        cleaning: 0,
        dental_checkup: 0,
        extraction: 0,
        ipr: 0,
        buttons: 0,
        attachments: 0,
        buttonsRemoval: 0,
        attachmentsRemoval: 0,
        redoScan: 0,
        redoIk: 0,
        removeFixedRetainers: 0,
        redoScanPhotos: 0,
        refinementScan: 0,
        periodontal_checkup: 0,
        rebondAttachment: 0,
        expat: 0,
        redoScanErrorDental: 0,
        redoScanCleaning: 0,
        redoScanWire: 0,
        refinementScanError: 0,
        mcaScan: 0,
        mcaScanError: 0,
        retainer_scan: 0,
        replace_powerchain: 0,
        mca_photos: 0,
        refinement_photos: 0,
        post_treatment_photos: 0,
        measure_spaces: 0,
        rebond_buttons: 0,
        remove_excess_around_attachments: 0,
        in_clinic_whitening: 0,
        incentive: 0,
        referral_incentive: 0,
        clinic_id: null,
      },
    ]);
  };

  useEffect(() => {
    clinicSelectOptions.shift();
    setClinicIds(selectedClinics);
    setClinicPricing(allScanClinicsPricing);
  }, [allScanClinicsPricing, selectedClinics]);

  if (clinicPricing.length === 0) {
    return (
      <div className="message-no-result">
        <div className="message-no-result-img">
          <Image src={emptyIcon} alt="empty" source=""></Image>
        </div>
        <DisplayText size="small">No results found</DisplayText>
        <br />
      </div>
    );
  }

  const headings = [
    "Clinics",
    "FIRST VISIT",
    "DISPLAYED FIRST VISIT PRICE",
    "PAID FIRST VISIT AT CLINIC",
    "REDO SCAN - SCAN ERROR",
    "REDO SCAN - POST DENTAL WORK",
    "REDO SCAN - POST CLEANING",
    "REDO SCAN - POST WIRE REMOVAL",
    "REDO SCANS PHOTOS",
    "REFINEMENT SCAN",
    "REFINEMENT - SCAN ERROR",
    "REFINEMENT PHOTOS",
    "MCA SCAN",
    "MCA SCAN - SCAN ERROR",
    "MCA PHOTOS",
    "RETAINER SCAN",
    "IPR",
    "ADD ATTACHMENTS",
    "REMOVE ATTACHMENTS",
    "REBOND ATTACHMENT",
    "BUTTONS",
    "BUTTONS REMOVAL",
    "REPLACE POWERCHAIN",
    "REMOVE FIXED RETAINERS",
    "OPG",
    "XRAY",
    "CLEANING",
    "DENTAL CHECKUP",
    "PERIODONTAL CHECKUP",
    "REDO IK",
    "EXTRACTION",
    "POST TREATMENT PHOTOS",
    "MEASURE SPACES",
    "REBOND BUTTONS",
    "REMOVE EXCESS AROUND ATTACHMENTS",
    "IN-CLINIC WHITENING",
    "EXPAT %",
    "REFERRAL INCENTIVE %",
    "INCENTIVE",
    "ACTIONS",
  ];
  
  return (
    <Card sectioned>
      {active && messageData && (
        <Toast content={messageData} onDismiss={toggleActive} />
      )}
      {error && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "15px",
            marginBottom: "15px",
          }}
        >
          <InlineError message={error} fieldID={""} />
        </div>
      )}
      <div className="custom-table-sticky">
        <DataTable
          columnContentTypes={[
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
          ]}
          headings={headings.map((heading, index) => (
            <TextStyle key={index} variation="strong">
              {heading}
            </TextStyle>
          ))}
          rows={clinicPricing.map(
            (
              {
                bas,
                displayed_bas_price_at_clinic,
                bas_at_clinic,
                opg,
                xray,
                cleaning,
                dental_checkup,
                extraction,
                ipr,
                buttons,
                attachments,
                buttonsRemoval,
                attachmentsRemoval,
                redoScan,
                redoIk,
                removeFixedRetainers,
                redoScanPhotos,
                refinementScan,
                periodontal_checkup,
                rebondAttachment,
                expat,
                redoScanErrorDental,
                redoScanCleaning,
                redoScanWire,
                refinementScanError,
                mcaScan,
                mcaScanError,
                retainer_scan,
                replace_powerchain,
                mca_photos,
                refinement_photos,
                post_treatment_photos,
                measure_spaces,
                rebond_buttons,
                remove_excess_around_attachments,
                in_clinic_whitening,
                referral_incentive,
                incentive,
                clinic_id,
                id,
              },
              index
            ) => [
              [
                <div key="1" style={{width: '250px'}}>
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 100002 }),
                    }}
                    id="clinic-id"
                    onChange={(event) =>
                      handleSelectClinicReports(event, index)
                    }
                    placeholder="Select clinic"
                    defaultValue={clinicSelectOptions.find(
                      (val) => val.value === clinic_id
                    )}
                    options={clinicSelectOptions}
                  />
                </div>,
              ],
              [
                <input
                  key="2"
                  id="bas"
                  type="number"
                  name="bas"
                  min={0}
                  value={bas}
                  style={inputStyle}
                  onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                  key="39"
                  id="displayed_bas_price_at_clinic"
                  type="number"
                  name="displayed_bas_price_at_clinic"
                  min={0}
                  value={displayed_bas_price_at_clinic}
                  style={inputStyle}
                  onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                  key="40"
                  id="bas_at_clinic"
                  type="checkbox"
                  name="bas_at_clinic"
                  checked={bas_at_clinic == 1}
                  style={checkStyle}
                  onChange={(event) => onChangeHandler(event, index, true)}
                />,
              ],
              [
                <input
                    key="12"
                    id="redoScan"
                    type="number"
                    min={0}
                    name="redoScan"
                    value={redoScan}
                    style={inputStyle}
                    onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                    key="20"
                    id="redoScanErrorDental"
                    type="number"
                    min={0}
                    name="redoScanErrorDental"
                    value={redoScanErrorDental}
                    style={inputStyle}
                    onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                    key="21"
                    id="redoScanCleaning"
                    type="number"
                    min={0}
                    name="redoScanCleaning"
                    value={redoScanCleaning}
                    style={inputStyle}
                    onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                    key="22"
                    id="redoScanWire"
                    type="number"
                    min={0}
                    name="redoScanWire"
                    value={redoScanWire}
                    style={inputStyle}
                    onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                    key="15"
                    id="redoScanPhotos"
                    type="number"
                    min={0}
                    name="redoScanPhotos"
                    value={redoScanPhotos}
                    style={inputStyle}
                    onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                    key="17"
                    id="refinementScan"
                    type="number"
                    min={0}
                    name="refinementScan"
                    value={refinementScan}
                    style={inputStyle}
                    onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                    key="23"
                    id="refinementScanError"
                    type="number"
                    min={0}
                    name="refinementScanError"
                    value={refinementScanError}
                    style={inputStyle}
                    onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                  key="36"
                  id="refinement_photos"
                  type="number"
                  min={0}
                  name="refinement_photos"
                  value={refinement_photos}
                  style={inputStyle}
                  onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                    key="24"
                    id="mcaScan"
                    type="number"
                    min={0}
                    name="mcaScan"
                    value={mcaScan}
                    style={inputStyle}
                    onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                    key="25"
                    id="mcaScanError"
                    type="number"
                    min={0}
                    name="mcaScanError"
                    value={mcaScanError}
                    style={inputStyle}
                    onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                  key="35"
                  id="mca_photos"
                  type="number"
                  min={0}
                  name="mca_photos"
                  value={mca_photos}
                  style={inputStyle}
                  onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                  key="33"
                  id="retainer_scan"
                  type="number"
                  min={0}
                  name="retainer_scan"
                  value={retainer_scan}
                  style={inputStyle}
                  onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                    key="7"
                    id="ipr"
                    min={0}
                    type="number"
                    name="ipr"
                    value={ipr}
                    style={inputStyle}
                    onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                    key="9"
                    id="attachments"
                    type="number"
                    min={0}
                    name="attachments"
                    value={attachments}
                    style={inputStyle}
                    onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                    key="11"
                    id="attachmentsRemoval"
                    type="number"
                    min={0}
                    name="attachmentsRemoval"
                    value={attachmentsRemoval}
                    style={inputStyle}
                    onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                    key="19"
                    id="rebondAttachment"
                    type="number"
                    min={0}
                    name="rebondAttachment"
                    value={rebondAttachment}
                    style={inputStyle}
                    onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                    key="8"
                    id="buttons"
                    min={0}
                    type="number"
                    name="buttons"
                    value={buttons}
                    style={inputStyle}
                    onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                    key="10"
                    id="buttonsRemoval"
                    type="number"
                    min={0}
                    name="buttonsRemoval"
                    value={buttonsRemoval}
                    style={inputStyle}
                    onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                    key="28"
                    id="replace_powerchain"
                    type="number"
                    min={0}
                    name="replace_powerchain"
                    value={replace_powerchain}
                    style={inputStyle}
                    onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                    key="14"
                    id="removeFixedRetainers"
                    type="number"
                    min={0}
                    name="removeFixedRetainers"
                    value={removeFixedRetainers}
                    style={inputStyle}
                    onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                  key="3"
                  id="opg"
                  min={0}
                  type="number"
                  name="opg"
                  value={opg}
                  style={inputStyle}
                  onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                  key="4"
                  id="xray"
                  min={0}
                  type="number"
                  name="xray"
                  value={xray}
                  style={inputStyle}
                  onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                  key="5"
                  id="cleaning"
                  min={0}
                  type="number"
                  name="cleaning"
                  value={cleaning}
                  style={inputStyle}
                  onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                  key="6"
                  id="dentalCheckup"
                  type="number"
                  min={0}
                  name="dental_checkup"
                  value={dental_checkup}
                  style={inputStyle}
                  onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                    key="18"
                    id="periodontal_checkup"
                    type="number"
                    min={0}
                    name="periodontal_checkup"
                    value={periodontal_checkup}
                    style={inputStyle}
                    onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                  key="13"
                  id="redoIk"
                  min={0}
                  type="number"
                  name="redoIk"
                  value={redoIk}
                  style={inputStyle}
                  onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                  key="34"
                  id="extraction"
                  type="number"
                  min={0}
                  name="extraction"
                  value={extraction}
                  style={inputStyle}
                  onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                  key="35"
                  id="post_treatment_photos"
                  type="number"
                  min={0}
                  name="post_treatment_photos"
                  value={post_treatment_photos}
                  style={inputStyle}
                  onChange={(event) => onChangeHandler(event, index)}
                />,
              ],              
              [
                <input
                  key="36"
                  id="measure_spaces"
                  type="number"
                  min={0}
                  name="measure_spaces"
                  value={measure_spaces}
                  style={inputStyle}
                  onChange={(event) => onChangeHandler(event, index)}
                />,
              ],              
              [
                <input
                  key="37"
                  id="rebond_buttons"
                  type="number"
                  min={0}
                  name="rebond_buttons"
                  value={rebond_buttons}
                  style={inputStyle}
                  onChange={(event) => onChangeHandler(event, index)}
                />,
              ],              
              [
                <input
                  key="38"
                  id="remove_excess_around_attachments"
                  type="number"
                  min={0}
                  name="remove_excess_around_attachments"
                  value={remove_excess_around_attachments}
                  style={inputStyle}
                  onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                  key="41"
                  id="in_clinic_whitening"
                  type="number"
                  min={0}
                  name="in_clinic_whitening"
                  value={in_clinic_whitening}
                  style={inputStyle}
                  onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                  key="26"
                  id="expat"
                  type="number"
                  min={0}
                  name="expat"
                  value={expat}
                  style={inputStyle}
                  onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                  key="26"
                  id="referral_incentive"
                  type="number"
                  min={0}
                  name="referral_incentive"
                  value={referral_incentive}
                  style={inputStyle}
                  onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <input
                  key="26"
                  id="incentive"
                  type="number"
                  min={0}
                  name="incentive"
                  value={incentive}
                  style={inputStyle}
                  onChange={(event) => onChangeHandler(event, index)}
                />,
              ],
              [
                <div key="27">
                  <Button onClick={() => onSaveHandler(index)} primary>
                    Save
                  </Button>
                  <span
                    style={{ display: "inline-block", marginLeft: "10px" }}
                  ></span>
                  <Button onClick={() => onDeleteHandler(index)} destructive>
                    Delete
                  </Button>
                </div>,
              ],
            ]
          )}
        />
      </div>
      <br />
      <Button onClick={handleCounter} primary>
        Add
      </Button>
    </Card>
  );
};

export default ScanClinicPricing;
