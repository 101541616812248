import React from "react";
import { Layout } from "@shopify/polaris";
import './files.css'
import FVFiles from "./FVFiles";
import UserHeader from "../../../components/UserLayout/user-header";
import { useParams, useLocation } from "react-router-dom";
import Questions from "./Questions";

const IKBASFiles = () => {
    let { id } = useParams();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    let linkState = { planType: '', clinicStatus: '', clinicName: '', status: '', userId: id, pageTitle: '' };
    if (location !== undefined && location.state !== undefined) {
        const { planType, clinicStatus, clinicName, status, userId, pageTitle } = location.state;
        linkState = { planType, clinicStatus, clinicName, status, userId, pageTitle }
    }
    const { planType, clinicStatus, clinicName, status, userId, pageTitle } = linkState;

    const file = (type) => {
        if (!type) {
            return <div></div>
        }
        switch (type) {
            case 'ik-screening':
            case 'bas-screening':
                return (
                    <Questions
                        title={type == 'ik-screening' ? 'Impression Kit Screening' : 'BAS Screening'}
                        type={type}
                    />
                );
            case 'fv':
            case 'ik-photos':
                return (
                    <FVFiles
                        title={type == 'fv' ? 'First Visit Files' : 'Impression Kit Photos'}
                        type={type}
                        planType={planType}
                        clinicStatus={clinicStatus}
                        clinicName={clinicName}
                        status={status}
                        userId={userId}
                        pageTitle={pageTitle}
                    />
                );
        }
    }

    return (
        <div className='ik-bas-files-wrapper'>
            <UserHeader pageTitle={pageTitle} status={status} userId={userId} clinicStatus={clinicStatus} clinicName={clinicName} planType={planType} />
            <br/>
            <Layout>
                <Layout.Section>
                    {file(query?.get('type'))}
                </Layout.Section>
            </Layout>
        </div>
    );
};

export default React.memo(IKBASFiles);
