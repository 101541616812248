import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "../axios";
import axiosAws from "axios";
import ProcedureHeader from "./ProcedureHeader";
import Cookies from "js-cookie";
import Save from "./Save";
import ScreeningPhotoUpload from "./ScreeningPhotoUpload";
import Review from "./Review";
import { TextField } from "@shopify/polaris";
import AlertDeleteDialog from "./Popup/alert-delete-dialog.component";
  
const useStyles = makeStyles(() => {
  return {
    photoWrapper: {
      padding: "1rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    photoBox: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    photoWidthSM: {
      width: "60%",
      ["@media only screen and (max-width: 700px) "]: {
        width: "100%",
      },
    },
    photoWidth: {
      width: "70%",
      marginBottom: 15,
      ["@media only screen and (max-width: 700px) "]: {
        width: "100%",
      },
    },
    title: {
      height: "16px",
      width: "134px",
      color: "#303030",
      fontSize: "16px !important",
      letterSpacing: 0,
      lineHeight: "22px",
      marginBottom: "1rem",
    },
  };
});

export default function AppointmentPostTreatmentPhotos({
  disable,
  appointmentId,
  appointmentType,
  handleTabsExpand,
  userId,
  index,
  openCloseTabs,
  scrollToExpandedTab,
}) {
  const classes = useStyles();
  const [photos, setPhotos] = useState([]);
  const [open, setOpen] = useState(false);
  const [deletePhoto, setDeletePhoto] = useState({photoId: null, type: ''});
  const [photosArray, setPhotosArray] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [imageKey, setImageKey] = useState(null);
  const [callHandleSave, setCallHandleSave] = useState(0);
  const [iconPhotos, setIconPhotos] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState({
    disabled: false,
    popupActive: false,
    success: false,
    disableReviewBtn: false,
  });
  const [deleteLoading, setDeleteLoading] = useState({
    frontFacingBigSmile: false,
    frontFacingNoSmile: false,
    rightSideProfileNoSmile: false,
    upperOcclusal: false,
    lowerOcclusal: false,
    bigSmile: false,
    bigSmileFullFace: false,
    leftClosed: false,
    rightClosed: false,
    lowerTeeth: false,
  });
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

  const handleIconPhotosView = (value) => {
    setIconPhotos(value);
  };

  const handleChange = (value) => {
    setImageKey({
      note: value,
    })
  }

  const handleClickOpen = (photoIdData, typeData) => {
    setDeletePhoto({photoId: photoIdData, type: typeData});
    setOpen(true);
  };

  const handleImages = (e, id) => {
    setErrorMsg("");
    setLoading(prev => ({
      ...prev,
      success: false,
      disabled: true,
    }));
    const imageId = id;
    const file = e.target.files[0];
    let imgUrl =
      validImageTypes.indexOf(file.type) > 0
        ? window.URL.createObjectURL(file)
        : "../assets/images/Fileicon.svg";
    setPhotos((prev) => ({
      ...prev,
      [imageId]: imgUrl,
    }));
    saveInAws(file, imageId);
  };

  const handleUploadError = (type, err) => {
    setErrorMsg("Upload failed. Please try again.");
    setPhotos((prev) => ({
      ...prev,
      [type]: undefined,
    }));
    setImageKey((prev) => ({
      // ...prev,
      [type]: undefined,
    }));
    console.log(err);
  };

  const uploadConfig = (progressEvent, name) => {
    if (progressEvent.loaded === progressEvent.total) {
      delete uploadProgress[name];
      setUploadProgress(uploadProgress);
    } else {
      const array = {
        ...uploadProgress,
        [name]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
      };
      setUploadProgress(array);
    }
  };

  const saveInAws = (value, type) => {
    let imageType = value.type;
    const form_data = new FormData();
    form_data.append("content_type", imageType);
    form_data.append("file_name", value.name);
    axios.post("/admin/v1/images/s3", form_data, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((res) => {
      setImageKey((prev) => ({
        // ...prev,
        [type]: res.data.key,
      }));
      const url2 = res.data.url;
      axiosAws.put(url2, value, {
        headers: {
          "x-amz-acl": "public-read-write",
          "Content-Type": imageType,
        },
        onUploadProgress: (event) => uploadConfig(event, type),
      }).then((res) => {
        setCallHandleSave(1);
      }).catch((err) => {
        setLoading(prev => ({
          ...prev,
          disabled: false,
        }));
        handleUploadError(type, err);
      });
    }).catch((err) => {
      setLoading(prev => ({
        ...prev,
        disabled: false,
      }));
      handleUploadError(type, err);
    });
  };

  const handleDelete = () => {
    const { photoId, type} = deletePhoto;
    setDeleteLoading(prev => ({...prev, [type]: true}));
    axios.delete(`admin/v2/case-record-post-treatment/delete/${photoId}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((res) => {
      if (res.data.success) {
        handleDataFetched();
      }
    }).catch((err) => {
      setErrorMsg("Delete failed. Please try again.");
      console.log(err);
    }).finally(() => setDeleteLoading(prev => ({...prev, [type]: false})));
  }

  const handleSave = () => {
    if(imageKey){
      setLoading(prev => ({
        ...prev,
        success: false,
      }));
      setErrorMsg("");
      const form_data = new FormData();
      imageKey?.upper_occlusal && form_data.append("upper_occlusal", imageKey.upper_occlusal);
      imageKey?.lower_occlusal && form_data.append("lower_occlusal", imageKey.lower_occlusal);
      imageKey?.big_smile && form_data.append("big_smile", imageKey.big_smile);
      imageKey?.big_smile_full_face && form_data.append("big_smile_full_face", imageKey.big_smile_full_face);
      imageKey?.left_closed && form_data.append("left_closed", imageKey.left_closed);
      imageKey?.right_closed && form_data.append("right_closed", imageKey.right_closed);
      imageKey?.note && form_data.append("note", imageKey.note);
      form_data.append("user_scan_info_id", appointmentId);
      form_data.append("procedure", appointmentType?.procedure);
      axios.post(`admin/v2/users/${userId}/case-record-post-treatment`, form_data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }).then((res) => {
        handleDataFetched();
        if (res.data.success) {
          setLoading(prev => ({
            ...prev,
            success: false,
          }));
        }
      }).catch((err) => {
        setErrorMsg("Upload failed. Please try again.");
        console.log(err);
      }).finally(() => {
        setLoading(prev => ({
          ...prev,
          disabled: false,
        }));
      });
    }
  };

  const handleReviewStatus = (status) => {
    if(status){
      setLoading(prev => ({
        ...prev,
        disableReviewBtn: true,
      }));
      setErrorMsg('');
      const form_data = new FormData();
      form_data.append('review_status', status);
      form_data.append('appointment_id', appointmentId);
      form_data.append('procedure', appointmentType?.procedure);
      axios.post(`/admin/v2/users/case-record-post-treatment/${photos?.id}/approval-status`, form_data, {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('token'),
        },
      }).then((res) => {
        if (res.data.success) {
          setPhotos(prev => ({
            ...prev,
            reviewer: res.data.data?.reviewer,
            review_status: res.data.data?.review_status,
            reviewed_at: res.data.data?.reviewed_at,
          }));
        }
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        setLoading(prev => ({
          ...prev,
          disabled: false,
        }));
      });
    }
  };

  const handleChangeNotApplicable = (e, prop) => {
    setImageKey({ [prop]: e ? 1 : 0 });
    setCallHandleSave(1);
  };

  const handleDataFetched = () => {
    setLoading(prev => ({
      ...prev,
      popupActive: true,
    }));
    axios.get(`admin/v2/users/case-record-post-treatment/${appointmentId}/show?procedure=${appointmentType?.procedure}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then(res => {
      if (res.data.success) {
        setPhotos(res.data.data);
        handleIconPhotosView(res.data.isAllProvided);
      } else {
        handleIconPhotosView(0);
      }
    }).catch(err => {
      console.log('fetch error', err);
    }).finally(f => {
      setLoading(prev => ({
        ...prev,
        popupActive: false,
      }));
    })
  };

  useEffect(() => {
    handleDataFetched();
  }, []);

  useEffect(() => {
    if (callHandleSave === 1) {
      handleSave();
      setCallHandleSave(0);
    }
  }, [callHandleSave]);

  useEffect(() => {
    scrollToExpandedTab(index, "photos");
  }, [openCloseTabs]);

  useEffect(() => {
    if(photos){
      let arr = [];
      if(photos.upper_occlusal && !arr.includes(photos.upper_occlusal)){
        arr.push({id: 'upper_occlusal', url: photos.upper_occlusal});
      }
      if(photos.lower_occlusal && !arr.includes(photos.lower_occlusal)){
        arr.push({id: 'lower_occlusal', url: photos.lower_occlusal});
      }
      if(photos.big_smile && !arr.includes(photos.big_smile)){
        arr.push({id: 'big_smile', url: photos.big_smile});
      }
      if(photos.big_smile_full_face && !arr.includes(photos.big_smile_full_face)){
        arr.push({id: 'big_smile_full_face', url: photos.big_smile_full_face});
      }
      if(photos.left_closed && !arr.includes(photos.left_closed)){
        arr.push({id: 'left_closed', url: photos.left_closed});
      }
      if(photos.right_closed && !arr.includes(photos.right_closed)){
        arr.push({id: 'right_closed', url: photos.right_closed});
      }

      setPhotosArray(arr);
    }
  }, [photos]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type="photos"
      headerTitle={<p><span className="procedure-header-title">Photos:</span> <span className='header-title-gray'>{appointmentType?.procedure}</span></p>}
      index={index}
      iconCheck={iconPhotos}
    >
      {openCloseTabs[index]?.photos && (
        <Box className={`${classes.photoWrapper} photos-${index}`}>
          {loading.popupActive ?
            <div style={{ textAlign: 'center' }}>
              <CircularProgress color='primary' />
            </div> :
            <>
              <Typography className={classes.title}>Screening Photos</Typography>
              <Box className={`${classes.photoBox} ${classes.photoWidth}`}>
                <ScreeningPhotoUpload
                  disabled={disable || loading.disabled}
                  value={photos?.upper_occlusal}
                  handleImages={handleImages}
                  id='upper_occlusal'
                  title='Upper Occlusal'
                  uploadProgress={uploadProgress?.upper_occlusal}
                  handleDelete={() => handleClickOpen(photos?.upper_occlusal_id, 'upperOcclusal')}
                  deleteDisabled={deleteLoading.upperOcclusal || !photos?.upper_occlusal_id}
                  imagesArray={photosArray}
                  identifier={`post-treatment-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={disable || loading.disabled}
                  value={photos?.lower_occlusal}
                  handleImages={handleImages}
                  id='lower_occlusal'
                  title='Lower Occlusal'
                  uploadProgress={uploadProgress?.lower_occlusal}
                  handleDelete={() => handleClickOpen(photos?.lower_occlusal_id, 'lowerOcclusal')}
                  deleteDisabled={deleteLoading.lowerOcclusal || !photos?.lower_occlusal_id}
                  imagesArray={photosArray}
                  identifier={`post-treatment-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={disable || loading.disabled}
                  value={photos?.big_smile}
                  handleImages={handleImages}
                  id='big_smile'
                  title='Big Smile'
                  uploadProgress={uploadProgress?.big_smile}
                  handleDelete={() => handleClickOpen(photos?.big_smile_id, 'bigSmile')}
                  deleteDisabled={deleteLoading.bigSmile || !photos?.big_smile_id}
                  imagesArray={photosArray}
                  identifier={`post-treatment-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={disable || loading.disabled}
                  value={photos?.big_smile_full_face}
                  handleImages={handleImages}
                  id='big_smile_full_face'
                  title='Big Smile - Full Face'
                  uploadProgress={uploadProgress?.big_smile_full_face}
                  handleDelete={() => handleClickOpen(photos?.big_smile_full_face_id, 'bigSmileFullFace')}
                  deleteDisabled={deleteLoading.bigSmileFullFace || !photos?.big_smile_full_face_id}
                  imagesArray={photosArray}
                  identifier={`post-treatment-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={disable || loading.disabled}
                  value={photos?.left_closed}
                  handleImages={handleImages}
                  id='left_closed'
                  title='Left Closed'
                  uploadProgress={uploadProgress?.left_closed}
                  handleDelete={() => handleClickOpen(photos?.left_closed_id, 'leftClosed')}
                  deleteDisabled={deleteLoading.leftClosed || !photos?.left_closed_id}
                  imagesArray={photosArray}
                  identifier={`post-treatment-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={disable || loading.disabled}
                  value={photos?.right_closed}
                  handleImages={handleImages}
                  id='right_closed'
                  title='Right Closed'
                  uploadProgress={uploadProgress?.right_closed}
                  handleDelete={() => handleClickOpen(photos?.right_closed_id, 'rightClosed')}
                  deleteDisabled={deleteLoading.rightClosed || !photos?.right_closed_id}
                  imagesArray={photosArray}
                  identifier={`post-treatment-${appointmentType?.procedure}`}
                />
              </Box>
              <TextField
                label='Note'
                value={imageKey?.note || photos?.note}
                onChange={(e) => handleChange(e)}
                multiline={4}
              />
              <br />
              <Review
                disabled={iconPhotos == 0 || loading.disableReviewBtn || photos?.review_status != null}
                reviewStatus={photos?.review_status}
                reviewer={photos?.reviewer}
                reviewedAt={photos?.reviewed_at}
                handleChange={() => handleReviewStatus('Reviewed')}
              />
              <Save uploadedBy={iconPhotos != 0 ? photos?.uploaded_by_user : ''} handleSave={handleSave} success={loading.success} errorMessage={errorMsg} />
            </>
          }
        </Box>
      )}
      <AlertDeleteDialog open={open} setOpen={setOpen} handleAction={handleDelete} />
    </ProcedureHeader>
  );
}
  