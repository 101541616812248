import Cookie from "js-cookie";
import React, { useCallback, useState } from 'react'
import axios from "../../axios";
import {
    Button,
    Thumbnail,
    DropZone,
    Toast,
    Checkbox,
} from "@shopify/polaris";

const Image = ({
    stepId,
    imagesId,
    title,
    subTitle,
    source,
    id,
    userId,
    getData,
    setPopupActive,
    areChecked,
    handleSelectDelete,
}) => {
    const [msgText, setMsgText] = useState('');
    const [active, setActive] = useState(false);
    const toggleActive = useCallback(() => setActive((active) => !active), []);

    const toastMarkup = active ? (
        <Toast content={msgText} onDismiss={toggleActive} />
    ) : null;

    const sendImage = (files) => {
        setPopupActive(true);
        let imageType = files[files.length - 1].type;
        const form_data = new FormData();
        form_data.append("content_type", imageType);
        axios.post("/admin/v1/images/s3", form_data, {
            headers: {
                Authorization: "Bearer " + Cookie.get("token"),
            },
        }).then((res) => {
            let imgKey = res.data.key;
            let url2 = res.data.url;
            axios.put(url2, files[files.length - 1], {
                headers: {
                    "x-amz-acl": "public-read-write",
                    "Content-Type": imageType,
                    },
                }
            ).then((res) => {
                const bodyObj = {
                    step_id: stepId,
                    images_id: imagesId,
                    [id]: imgKey,
                };
                axios.post(`admin/v1/users/${userId}/aligner-kit`, bodyObj, {
                    headers: {
                        Authorization: "Bearer " + Cookie.get("token"),
                    },
                }).then((res) => {
                    let imgId = res.data.data.id;
                    if(!imagesId && res.data.data.id) {
                        window.history.pushState(null, null, `/admin/users/${userId}/dashboard/editsmilejourney/${stepId}/images/${res.data.data.id}`);
                        window.onpopstate = function () {
                            window.history.go(1);
                        };
                    }
                    getData(imgId);
                }).catch((err) => console.log(err));
            }).catch((err) => setPopupActive(false));
        }).catch((err) => setPopupActive(false));
    }

    function handleDelete() {
        setPopupActive(true);
        let bodyObj = {
            images_id: imagesId,
            image_names: [id],
        };

        axios.post(`admin/v1/user_images/delete`, bodyObj, {
            headers: {
                Authorization: "Bearer " + Cookie.get("token"),
            },
        }).then((res) => {
            if(res.data.success) {
                setMsgText('Image successfully deleted');
                toggleActive();
                getData();
            }
        }).catch((err) => console.log(err));
    }

    return (
        <div className="journey-image-wrapper">
            <div>
                <p className="journey-image-p">{title}</p>
                <p className="journey-image-p">{subTitle}</p>
            </div>
            <Thumbnail
                size="large"
                source={source}
            />
            <div className="journey-dropzone-wrapper">
                <DropZone onDropAccepted={sendImage} />
            </div>
            <div className="button-delete">
                {
                    areChecked?.hasOwnProperty(imagesId) && 
                    <Checkbox
                        disabled={!source}
                        checked={!source ? false : (areChecked?.[imagesId]?.hasOwnProperty(id) ? areChecked?.[imagesId]?.[id] : areChecked?.hasOwnProperty(imagesId))}
                        onChange={() => handleSelectDelete(imagesId, id)}
                    />
                }
                <Button
                    disabled={!source}
                    onClick={handleDelete}
                >
                    Delete
                </Button>
            </div>
            {toastMarkup}
        </div>
    )
}

export default Image