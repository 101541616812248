import React, { useCallback, useState, useEffect } from "react";
import {
  Page,
  Card,
  Layout,
  Select,
  Tabs,
  TextField,
  DropZone,
  Icon,
  Caption,
  Thumbnail,
  Stack,
  Checkbox,
} from "@shopify/polaris";
import { Router, useParams } from "react-router-dom";
import history from "./history";
import {
  FormLayout,
  TextStyle,
  PageActions,
  Toast,
} from "@shopify/polaris";
import axios from "./axios";
import NavigationLayout from "./components/NavigationLayout";
import ReactTagInput from "@pathofdev/react-tag-input";
import Cookies from "js-cookie";
import {
    PlayCircleMajor
  } from '@shopify/polaris-icons';
function NewContent() {
    const { id } = useParams()
    const [instagramPosts, setInstagramPosts] = useState([]);
    const [active, setActive] = useState(false);
    const [toastContent, setToastContent] = useState("Content Saved");
    const toggleActive = useCallback(() => setActive((active) => !active), []);
    const [question, setQuestion] = useState({en: '', ar:''})
    const [answer, setAnswer] = useState({en: '', ar:''})
    const [imageKeyVideoSrc, setImageKeyVideoSrc] =useState("");
    const [imageKeyVideoSrcAr, setImageKeyVideoSrcAr] =useState("");
    const [imagePreviewVideoSrc, setImagePreviewVideoSrc] = useState("");
    const [imagePreviewVideoSrcAr, setImagePreviewVideoSrcAr] = useState("");
    const [filesVideoSrc, setFilesVideoSrc] = useState([]);
    const [filesVideoSrcAr, setFilesVideoSrcAr] = useState([]);
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        fetchFaqsById()
    }, [])

    const fetchFaqsById = async () => {
        await axios
          .get(`admin/v1/new-faq/${id}`, {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
          })
          .then((result) => {
            setQuestion((prev) => {return {...prev, en:result?.data?.faqs?.question.en, ar:result?.data?.faqs?.question.ar}})
            setAnswer((prev) => {return {...prev, en:result?.data?.faqs?.answer.en, ar:result?.data?.faqs?.answer.ar}})
            setLink((prev) => {return {...prev, en:result?.data?.faqs?.link_en, ar:result?.data?.faqs?.link_ar}})
            setImagePreviewVideoSrc(result?.data?.faqs?.video_src_url)
            setImagePreviewVideoSrcAr(result?.data?.faqs?.video_src_url_ar)
            // if(result?.data?.faqs?.video_src_url && result?.data?.faqs?.video_src_url_ar){
            //     setCheckVideo(true)
            // } else {
            //     setCheckVideo(false)
            // }
          })
          .catch((err) => console.log(err));
    }
    const httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

    const handleSubmit = () => {
        if (!checkVideo && (!httpRegex.test(link.en) || !httpRegex.test(link.ar))) {
            setErrorMessage('Link format is wrong')
        } else {
                const body = {
                    answer : answer,
                    question : question,
                    link_en : link.en,
                    link_ar : link.ar,
                    video_src : imageKeyVideoSrc ?? null,
                    video_src_ar : imageKeyVideoSrcAr ?? null,
                };

                axios.post(`admin/v1/new-faq/update/${id}`, body, {
                    headers: {
                      Authorization: "Bearer " + Cookies.get("token"),
                    },
                }).then((result) => {
                    history.push('/admin/faq-content')
                }).catch(err => console.log(err));
            }
    }

    const videoThumbnail = () => {
        return (
            <div style={{border:'0.1rem solid grey', margin: '1rem 0', width:'5rem', height:'5rem', position:'relative'}}>
                <div  
                style={{
                    position: 'absolute',
                    opacity: 0.5, 
                    top: '50%', 
                    left: '50%', 
                    transform: 'translateX(-50%) translateY(-50%)'
                }}
                >
                <Icon
                    source={PlayCircleMajor}
                    color="base"
                />
                </div>
                <video
                    className="video"
                    style={{width:'100%', height:'100%'}}
                    src={imagePreviewVideoSrc}
                ></video>
            </div>
        )
    }    

    const videoThumbnailAr = () => {
        return (
            <div style={{border:'0.1rem solid grey', margin: '1rem 0', width:'5rem', height:'5rem', position:'relative'}}>
                <div  
                style={{
                    position: 'absolute',
                    opacity: 0.5, 
                    top: '50%', 
                    left: '50%', 
                    transform: 'translateX(-50%) translateY(-50%)'
                }}
                >
                <Icon
                    source={PlayCircleMajor}
                    color="base"
                />
                </div>
                <video
                    className="video"
                    style={{width:'100%', height:'100%'}}
                    src={imagePreviewVideoSrcAr}
                ></video>
            </div>
        )
    }    
    const handleDropZoneVideoSrc = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) =>
        setFilesVideoSrc((filesVideoSrc) => [
            ...filesVideoSrc,
            ...acceptedFiles,
        ]),
        []
    );
    const handleDropZoneVideoSrcAr = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) =>
        setFilesVideoSrcAr((filesVideoSrc) => [
            ...filesVideoSrc,
            ...acceptedFiles,
        ]),
        []
    );
    const fileUploadVideoSrc =
        !filesVideoSrc?.length && <DropZone.FileUpload />;
    let uploadedFilesVideoSrc = filesVideoSrc?.length >
        0 && (
        <Stack alignment="center">
        {videoThumbnail()}
        <div>
            {
            filesVideoSrc[filesVideoSrc?.length - 1]
                .name
            }{" "}
            <Caption>
            {
                filesVideoSrc[filesVideoSrc?.length - 1]
                .type
            }{" "}
            bytes
            </Caption>
        </div>
        </Stack>
    );

    const fileUploadVideoSrcAr =
    !filesVideoSrcAr?.length && <DropZone.FileUpload />;
    let uploadedFilesVideoSrcAr = filesVideoSrcAr?.length >
    0 && (
    <Stack alignment="center">
    {videoThumbnailAr()}
    <div>
        {
        filesVideoSrcAr[filesVideoSrcAr?.length - 1]
            .name
        }{" "}
        <Caption>
        {
            filesVideoSrcAr[filesVideoSrcAr?.length - 1]
            .type
        }{" "}
        bytes
        </Caption>
    </div>
    </Stack>
);
const [checkVideo, setCheckVideo] = useState(false)
const [link, setLink] = useState({en: '', ar:''})

// function handleCheckVideo(checked) {
//     setCheckVideo(checked);
//     setLink({ en: '', ar: '' })
//   }
    const sendImageVideoSrc = useCallback(
        (filesVideoSrc) => {
        setImagePreviewVideoSrc(
            window.URL.createObjectURL(
                filesVideoSrc[
                    filesVideoSrc?.length - 1
                ]
                )
        );
        let imageType =
            filesVideoSrc[filesVideoSrc?.length - 1]
            .type;
        let headersVar = null;
        let url2 = "";
        const form_data = new FormData();
        form_data.append("content_type", imageType);
        axios
            .post("/admin/v1/images/s3", form_data, {
            headers: {
                Authorization: "Bearer " + Cookies.get("token"),
            },
            })
            .then((res) => {
            setImageKeyVideoSrc(res.data.key);
            url2 = res.data.url;
            headersVar = res.data.headers;
            axios
                .put(
                url2,
                filesVideoSrc[
                    filesVideoSrc?.length - 1
                ],
                {
                    headers: {
                    "x-amz-acl": "public-read-write",
                    "Content-Type": imageType,
                    },
                }
                )
                .then((res) => {
                    console.log(res)
                })
                .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
        },

        [filesVideoSrc]
    );

    const sendImageVideoSrcAr = useCallback(
        (filesVideoSrc) => {
        setImagePreviewVideoSrcAr(
            window.URL.createObjectURL(
                filesVideoSrc[
                    filesVideoSrc?.length - 1
                ]
                )
        );
        let imageType =
            filesVideoSrc[filesVideoSrc?.length - 1]
            .type;
        let headersVar = null;
        let url2 = "";
        const form_data = new FormData();
        form_data.append("content_type", imageType);
        axios
            .post("/admin/v1/images/s3", form_data, {
            headers: {
                Authorization: "Bearer " + Cookies.get("token"),
            },
            })
            .then((res) => {
            setImageKeyVideoSrcAr(res.data.key);
            url2 = res.data.url;
            headersVar = res.data.headers;
            axios
                .put(
                url2,
                filesVideoSrcAr[
                    filesVideoSrcAr?.length - 1
                ],
                {
                    headers: {
                    "x-amz-acl": "public-read-write",
                    "Content-Type": imageType,
                    },
                }
                )
                .then((res) => {
                    console.log(res)
                })
                .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
        },

        [filesVideoSrcAr]
    );
    return (
        <NavigationLayout>
            <Router history={history}>
                <Page 
                    breadcrumbs={[{ content: "All faqs", url: "/admin/faq-content" }]}
                    primaryAction={{
                    content: "update",
                    onClick: handleSubmit,
                        }}>
                        {errorMessage && <Toast content={errorMessage} onDismiss={toggleActive} />}
                        {/* <div style={{width:"100%", backgroundColor:"white", borderRadius:"10px", boxShadow:"1px 1px 5px lightgray", padding:"15px"}}>
                            <TextStyle variation="strong">Upload Video <span style={{fontSize:"12px", fontWeight:"300"}}>(upload video instead of youtube url)</span></TextStyle>
                            <br/>
                            <Checkbox
                                label="Upload video"
                                checked={checkVideo}
                                // onChange={handleCheckVideo}
                                />
                        </div> */}
                        <br/>
                       <div style={{display:"flex", alignContent:"center", gap:"20px", width:"100%"}}>
                       <div style={{width:"50%", backgroundColor:"white", borderRadius:"10px", boxShadow:"1px 1px 5px lightgray", padding:"15px"}}>
                            <TextStyle variation="strong">Faq Content (English)</TextStyle>
                            {checkVideo ? <div>
                                {videoThumbnail()}
                                <DropZone
                                    onDrop={handleDropZoneVideoSrc}
                                    onDropAccepted={sendImageVideoSrc}
                                >
                                    {uploadedFilesVideoSrc}
                                    {fileUploadVideoSrc}
                                </DropZone>
                                </div> : 
                                 <div>
                                    <br/>
                                    <TextStyle variation="strong">Insert Youtube link (English)</TextStyle>
                                    <TextField
                                        placeholder="Enter Link"
                                        type="text"
                                        value={link.en}
                                        onChange={(value) => setLink((prev) => { return {...prev, en: value}})}
                                        error={''}
                                    />
                                 </div> }
                                <br/>
                                <TextStyle variation="strong">Faq question (English)</TextStyle>
                                <TextField
                                    placeholder="Enter question"
                                    type="text"
                                    value={question.en}
                                    onChange={(value) => setQuestion((prev) => { return {...prev, en: value}})}
                                    error={''}
                                />
                                <br/>
                                <TextStyle variation="strong">Faq answer (English)</TextStyle>
                                <TextField
                                    placeholder="Enter answer"
                                    type="text"
                                    onChange={(value) => setAnswer((prev) => { return {...prev, en: value}})}
                                    value={answer.en}
                                    error={''}
                                />
                        </div>
                        <div style={{width:"50%", backgroundColor:"white", borderRadius:"10px", boxShadow:"1px 1px 5px lightgray", padding:"15px"}}>
                            <TextStyle variation="strong">Faq Content (Arabic)</TextStyle>
                            {checkVideo ?  <div>
                                {videoThumbnailAr()}
                                <DropZone
                                    onDrop={handleDropZoneVideoSrcAr}
                                    onDropAccepted={sendImageVideoSrcAr}
                                >
                                    {uploadedFilesVideoSrcAr}
                                    {fileUploadVideoSrcAr}
                                </DropZone>
                                </div> : <div>
                                    <br/>
                                <TextStyle variation="strong">Insert Youtube link (Arabic)</TextStyle>
                                    <TextField
                                        placeholder="Enter Link"
                                        type="text"
                                        value={link.ar}
                                        onChange={(value) => setLink((prev) => { return {...prev, ar: value}})}
                                        error={''}
                                    />
                                    </div>}
                                <br/>
                                <TextStyle variation="strong">Faq question (Arabic)</TextStyle>
                                <TextField
                                    placeholder="Enter question"
                                    type="text"
                                    value={question.ar}
                                    onChange={(value) => setQuestion((prev) => { return {...prev, ar: value}})}
                                    error={''}
                                />
                                <br/>
                                <TextStyle variation="strong">Faq answer (Arabic)</TextStyle>
                                <TextField
                                    placeholder="Enter answer"
                                    type="text"
                                    onChange={(value) => setAnswer((prev) => { return {...prev, ar: value}})}
                                    value={answer.ar}
                                    error={''}
                                />
                        </div>
                       </div>
                       <br/>
                </Page>
            </Router>
        </NavigationLayout>
    );
}
export default NewContent;
