import {
    FormLayout,
    Modal,
    TextField, TextStyle,
} from "@shopify/polaris";
import Select from "react-select";
import "@zendeskgarden/react-pagination/dist/styles.css";

import MultipleUploadPhotos from "../Users/DashboardComponents/YourSmilePlan/MultipleUploadPhotos";
import { useCallback, useEffect } from 'react';
import axios from '../axios';
import Cookies from "js-cookie";
import { screeningImages, tpOptions } from "../util/constants";
import IPRSheet from "./IPRSheet/IPRSheet";
import Cookie from 'js-cookie';

function DisapprovalModal({
  activeModal,
  handleChangeModal,
  title,
  dropDownTitle,
  handleSubmitDisapprove,
  loading,
  commentValue,
  handleChangeComment,
  commentError,
  setFilesImage,
  filesImage,
  images,
  setImages,
  imagesKey,
  setImageKey,
  issueType = '',
  setIssues,
  issues, 
  showIPRDiagram = false,
  sheets = [],
  setSheets,
  groupTeeth = [],
  setGroupTeeth,
  showImagesDropdown = false,
  showTpDropdown = false, 
  setImagesIssues,
  options,
  setOptions,
  setTpOption,
  tpOptionError = '',
}) {
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
    const handleCheckboxChange = (key, ind) => {
        setGroupTeeth((prev) => {
            const newArray = [...prev];
            const sheetArray = [...newArray[ind]];
            const updatedObject = {
                ...sheetArray[key],
                attachment: !sheetArray[key].attachment,
            };
            sheetArray[key] = updatedObject;
            newArray[ind] = sheetArray;
            return newArray;
        });
    };

    const handleTextChange = (val, ind) => {
        setSheets((prev) => {
            let arr = [...prev];
            arr[ind] = { ...arr[ind], notes: val };
            return arr;
        });
    };

    useEffect(() => {
        if (issueType) {
            axios.get(`admin/v2/procedure-issues?type=${issueType}`, {
                headers: {
                  Authorization: "Bearer " + Cookies.get("token"),
                },
            }).then(res => {
                setOptions(res.data.data.map(entry => ({
                    id: entry.id,
                    label: entry.issue,
                    value: entry.issue,
                })));
            }).catch(err => {
                console.log(err);
            });
        }
    }, [issueType]);

  const onPasteImages = async (e) => {
    let pastedData = Array.from(e.clipboardData.files || []);
    if (!pastedData.length) {
      return;
    }
    if (pastedData.length > 1) {
      let images = pastedData.filter((file) =>
        validImageTypes.some((type) => file.type.includes(type)),
      );
      pastedData = [...images];
      setFilesImage((prev) => [...images, ...prev]);
    } else {
      pastedData = [...pastedData];
      setFilesImage((prev) => [...pastedData, ...prev]);
    }
    
    await sendImage(pastedData);
  };


  const sendImage = useCallback(
    async (filesImage) => {
      for (const file of filesImage) {
        if (validImageTypes.indexOf(file.type) < 0) {
          continue;
        }
        let imageType = file.type;
        let url2 = "";
        const form_data = new FormData();
        form_data.append("content_type", imageType);
        try {
          const res = await axios.post("/admin/v1/images/s3", form_data, {
            headers: {
              Authorization: `Bearer ${Cookie.get("token")}`,
            },
          });
          setImageKey((prev) => [...prev, res.data.key]);
          url2 = res.data.url;
          await axios.put(url2, file, {
            headers: {
              "x-amz-acl": "public-read-write",
              "Content-Type": imageType,
            },
          });
        } catch (err) {
          console.log("Error in (sendImage)", err);
        }
      }
    },
    [filesImage],
  );


    return (
        <Modal
            open={activeModal}
            onClose={handleChangeModal}
            title={title}
            primaryAction={{
                content: 'Submit',
                onAction: handleSubmitDisapprove,
                loading: loading,
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: handleChangeModal,
                    disabled: loading
                },
            ]}
        >
            <Modal.Section>
              <div onPaste={onPasteImages}>
                <FormLayout>
                    {options.length > 0 && (
                        <>
                            <TextStyle>{dropDownTitle}</TextStyle>
                            <Select
                                options={options}
                                id="scan-issues"
                                isClearable
                                isMulti
                                onChange={(selectedOption) => setIssues(selectedOption)}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 31 }) }}
                                menuPosition="fixed"
                                menuPlacement="auto"
                            />
                            {issues.length === 0 && <p style={{ color: 'red' }}>Please select an option.</p>}
                        </>
                    )}
                    {
                        showImagesDropdown && issues.length > 0 &&
                        <>
                            <TextStyle>Select images</TextStyle>
                            <Select
                                options={screeningImages}
                                id="images-issues"
                                isClearable
                                isMulti
                                onChange={(selectedOption) => setImagesIssues(selectedOption)}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 31 }) }}
                                menuPosition="fixed"
                                menuPlacement="auto"
                            />
                        </>
                    }
                    {
                        showTpDropdown &&
                        <>
                            <Select
                                options={tpOptions}
                                id="tp-options"
                                isClearable
                                onChange={(selectedOption) => setTpOption(selectedOption)}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 31 }) }}
                                menuPosition="fixed"
                                menuPlacement="auto"
                            />
                            {tpOptionError && <p style={{ color: 'red' }}>{tpOptionError}</p>}
                        </>
                    }
                    <TextField
                        label='Comment'
                        value={commentValue}
                        onChange={handleChangeComment}
                        error={commentError}
                    />
                    <MultipleUploadPhotos setFilesImage={setFilesImage} filesImage={filesImage} images={images} setImages={setImages} imagesKey={imagesKey} setImageKey={setImageKey} />
                    {
                        showIPRDiagram &&
                        <IPRSheet
                            sheetNumber={0}
                            groupTeeth={groupTeeth}
                            sheets={sheets}
                            handleCheckboxChange={handleCheckboxChange}
                            handleTextChange={handleTextChange}
                            title='Affected Teeth'
                            selectTeethOnly
                        />
                    }
                </FormLayout>
              </div>
            </Modal.Section>
        </Modal>
    )
}

export default DisapprovalModal