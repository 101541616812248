import { Modal, TextContainer } from "@shopify/polaris";
import React from "react";

const ClinicsReportsNoteModal = ({
  activeNoteModal,
  handleNoteDialog,
  note,
}) => {
  return (
    <Modal
      title="Notes"
      open={activeNoteModal}
      onClose={handleNoteDialog}
      secondaryActions={[
        {
          content: "Close",
          onAction: handleNoteDialog,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>{note}</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
};

export default ClinicsReportsNoteModal;
