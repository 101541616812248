import React, { useEffect, useState } from 'react';
import '@zendeskgarden/react-pagination/dist/styles.css';
import { useParams } from 'react-router-dom';
import './YourSmilePlan.css';
import ThreeDfilesViewer from '../ThreeD/ThreeDfilesViewer';
import Plans from './Plans';
import { Loading } from '@shopify/polaris';
import PDFSheetPreview from '../../DashboardComponents/YourSmilePlan/pdf-sheet-preview.component';

function YourSmilePlan(props) {
  let { id } = useParams();
  const { loading, procedures, zipFileUrl } = props;
  const [showPlan, setShowPlan] = useState(false);
  const [showIPRSheets, setShowIPRSheets] = useState(false);
  const [filteredProcedures, setFilteredProcedures] = useState([]);
  const filterProceduresForIprSheet = () => {
    let modifiedProceduresArray = procedures.map((procedure) => {
      let modifiedObject = {
        step: `${procedure.step} ${procedure.step_position ?? ''}`,
      };
      if (procedure.ipr_sheet && 'pdf_url' in procedure.ipr_sheet) {
        modifiedObject = {
          ...modifiedObject,
          url: procedure?.ipr_sheet?.pdf_url,
        };
      }
      return modifiedObject;
    });
    if (props.ipr_url) {
      const previousIprSheet = { previous: true, url: props.ipr_url };
      modifiedProceduresArray = [previousIprSheet, ...modifiedProceduresArray];
    }
    setFilteredProcedures(modifiedProceduresArray);
  };

  useEffect(() => {
    filterProceduresForIprSheet();
  }, [procedures]);

  const showIPRSheetsPreview = () => {
    setShowIPRSheets(!showIPRSheets);
  };

  const openFile = (e) => {
    const win = window.open(e.currentTarget.id, '_blank');
    win.focus();
  };

  if (!id) {
    return <div></div>;
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="yourSmilePlanContainer">
          <div>
            <div className="titleSmile">
              <b>Your Smile Plan</b>
            </div>
            <div className="subtitle">
              {props.treatment_length && (
                <div>{props.treatment_length} months</div>
              )}
            </div>
            <div>
              {props.files.length || zipFileUrl ? (
                <ThreeDfilesViewer
                  files={props.files}
                  zipFileUrl={zipFileUrl}
                  bgColor={props.bgColor}
                  setActiveModel={props.setActiveModel}
                  isActive={props.isActive}
                  index={props.index}
                />
              ) : null}
            </div>
            <div className="buttonContainer">
              <div>
                {props.guide_url && true ? (
                  <button>
                    <div
                      onClick={(e) => openFile(e)}
                      id={props.guide_url}
                      className="ctaButton"
                    >
                      View Treatment Plan
                    </div>
                  </button>
                ) : null}
              </div>
              {filteredProcedures.length > 0 && (
                <button>
                  <div
                    onClick={showIPRSheetsPreview}
                    id={props.ipr_url}
                    className="ctaButton"
                  >
                    View IPR Sheets
                  </div>
                </button>
              )}
            </div>
            {showIPRSheets && (
              <div className="buttonContainer">
                <PDFSheetPreview procedures={filteredProcedures} />
              </div>
            )}
            <div className="buttonContainer">
              <button>
                <div
                  onClick={() => setShowPlan(!showPlan)}
                  id={props.ipr_url}
                  className="ctaButtonPay"
                >
                  View Payment Options
                </div>
              </button>
            </div>
            {showPlan && (
              <Plans
                planType={props.planType}
                paymentOptions={props.paymentOptions}
              />
            )}{' '}
          </div>
        </div>
      )}
    </>
  );
}

export default React.memo(YourSmilePlan);
