import React, { useState, useEffect } from "react";
import {
  Page,
  Card,
  TextStyle,
  Image,
  DisplayText,
  DataTable,
  Button,
} from "@shopify/polaris";
import Cookies from "js-cookie";
import axios from "../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emptyIcon from "../img/emptyList.svg";
import "@zendeskgarden/react-pagination/dist/styles.css";
import { Pagination } from "@zendeskgarden/react-pagination";
import history from "../history";

function PendingTps() {
  const [loading, setLoading] = useState({
    singleRow: [],
    bulk: false,
  });
  const [tpItems, setTpItems] = useState([]);
  const [tpCurrentPage, setTpCurrentPage] = useState(1);
  const [tpTotalPages, setTpTotalPages] = useState(0);

  const emptyTpState = tpItems.length == 0 ? (
    <div className="message-no-result"
    >
      <div className="message-no-result-img">
        <Image src={emptyIcon}></Image>{" "}
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : null;

  function handleTpPageChange(currentPage) {
    setTpCurrentPage(currentPage);
  }

  const handleRowClick = (userId, tpId, tpParentId) => {
    let url = `/admin/users/${userId}/dashboard/yoursmileplan/${tpId}/update`;
    if(tpParentId) {
      url = `/admin/users/${userId}/dashboard/yoursmileplan/${tpId}/refinement/edit`;
    }
    history.push(url);
  }

  const activateTp = (planId, type) => {
    setLoading(prev => ({
      ...prev,
      bulk: true,
    }));
    const body ={
      plan_id : planId,
    };
    if (type === 'doctor') {
      body.doctor_approved = true;
    } else if (type === 'lab') {
      body.lab_approved = true
    }
    axios.post(`admin/v1/smile_plan/admin/approved`, body, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((result) => {
      if(result.data.success) {
        toast.success('Tp approved', 3000);
        getApprovedTps();
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const getApprovedTps = () => {
    // let curentPage = (selectedTps.length > 1 && tpCurrentPage > 1) ? tpCurrentPage - 1 : tpCurrentPage;
    handleTpPageChange(tpCurrentPage);
    axios.get(`admin/v1/pending-tps?page=${tpCurrentPage}&paginate=10`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((result) => {
      let tps = result.data.tps.data;
      setTpItems(tps);
      setLoading(prev => ({
        ...prev,
        bulk: false,
        singleRow: Array(tps.length).fill(false),
      }));
      setTpTotalPages(result.data.tps.last_page);
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    getApprovedTps();
  }, [tpCurrentPage])

  return (
    <Page
      fullWidth
      title="TPs to be approved"
      breadcrumbs={[{ content: "List Of Reports", url: "/admin/reports" }]}
    >
      <ToastContainer/>
      <div className="pending-tps-wrapper">
        <Card>
          <DataTable
            columnContentTypes={["text", "text", "text", "text", "text"]}
            headings={[
              <TextStyle variation="strong">ID</TextStyle>,
              <TextStyle variation="strong">Full Name</TextStyle>,
              <TextStyle variation="strong">TP Name</TextStyle>,
              <TextStyle variation="strong">TP Pending Date</TextStyle>,
              <TextStyle variation="strong">Actions</TextStyle>,
            ]}
            rows={tpItems.map((item, key) => {
              return (
                [
                  <div id={String(item.id)} onClick={() => handleRowClick(item.user_id, item.id, item.parent_id)}>
                    <p>{item.user_id}</p>
                  </div>,
                  <div id={String(item.id)} onClick={() => handleRowClick(item.user_id, item.id, item.parent_id)}>
                    <p>{item.user?.full_name}</p>
                  </div>,
                  <div id={String(item.id)} onClick={() => handleRowClick(item.user_id, item.id, item.parent_id)}>
                    <p>{item.name}</p>
                  </div>,
                  <div id={String(item.id)} onClick={() => handleRowClick(item.user_id, item.id, item.parent_id)}>
                    <p>{item.pending_at}</p>
                  </div>,
                  <div style={{ display: "flex", columnGap: "8px" }} id={String(item.id)}>
                    <Button loading={loading.singleRow[key]} disabled={loading.singleRow[key] || item.lab_approved} onClick={() => activateTp(item.id, 'lab')}>
                      Lab Approved
                    </Button>
                    <Button loading={loading.singleRow[key]} disabled={loading.singleRow[key] || item.doctor_approved} onClick={() => activateTp(item.id, 'doctor')}>
                      Doctor Approved
                    </Button>
                  </div>
                ]
              )
            })}
            sortable={[false, false, false, false, false]}
            defaultSortDirection="descending"
            hideScrollIndicator
            verticalAlign="center"
          />
          {tpTotalPages >= 2 && (
            <div id="pagination" style={{ marginTop: 20 }}>
              <Pagination
                totalPages={tpTotalPages}
                pagePadding={3}
                currentPage={tpCurrentPage}
                onChange={handleTpPageChange}
              />
            </div>
          )}
          {emptyTpState}
        </Card>
      </div>
    </Page>
  );
}

export default PendingTps;
