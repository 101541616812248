import React, { useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Paper } from '@material-ui/core';
import { DisplayText, Image } from '@shopify/polaris';
import emptyIcon from '../../img/emptyList.svg';
import { useStyles } from '../ClinicReports/clinic-reports-invoices-table.styles';
import { useStyles as TableStyles } from './product-request-reports-table.styles';
import ClinicsReportsLoadingSpinner from '../ClinicReports/clinics-reports-loading-spinner.component';
import ProductRequestsReportsTableRow from './product-requests-reports-table-row.component';
import Pagination from '../../components/pagination.component';

const ProductRequestsReportsTable = ({
  getProductRequests,
  type,
  productRequests,
  loading,
  handleCheckDispatches = () => {},
  page,
  perPage,
  total,
  setPage,
  handOpenReasonDialog,
  openUserProfile,
}) => {
  const hideColumnsOnApproved = type === 'approved';
  const hideColumnsOnRequested = type === 'requested';
  const classes = useStyles();
  const tableClasses = TableStyles();

  useEffect(() => {
    void getProductRequests(type);
  }, [page]);

  if (loading) {
    return <ClinicsReportsLoadingSpinner />;
  }
  return (
    <TableContainer component={Paper}>
      <Table
        classes={{ root: tableClasses.table }}
        sx={{ minWidth: 650 }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Customer Name</TableCell>
            <TableCell>Product</TableCell>
            <TableCell>Agent</TableCell>
            <TableCell>Date Requested</TableCell>
            <TableCell className={`${hideColumnsOnRequested && classes.hide}`}>
              Approved By
            </TableCell>
            <TableCell className={`${hideColumnsOnRequested && classes.hide}`}>
              Approved At
            </TableCell>
            <TableCell>Urgency</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Paid</TableCell>
            <TableCell>Reason</TableCell>
            <TableCell className={`${hideColumnsOnApproved && classes.hide}`}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productRequests[type].length > 0 &&
            productRequests[type].map((productRequest) => (
              <ProductRequestsReportsTableRow
                key={productRequest.id}
                productRequest={productRequest}
                handleCheckDispatches={handleCheckDispatches}
                hideColumnsOnRequested={hideColumnsOnRequested}
                hideColumnsOnApproved={hideColumnsOnApproved}
                handOpenReasonDialog={handOpenReasonDialog}
                openUserProfile={openUserProfile}
              />
            ))}
        </TableBody>
      </Table>
      {productRequests[type].length > 0 && (
        <Pagination
          setPage={setPage}
          page={page}
          perPage={perPage}
          total={total}
        />
      )}

      {productRequests[type].length === 0 && (
        <div className="message-no-result">
          <div className="message-no-result-img">
            <Image src={emptyIcon} alt="empty" source=""></Image>
          </div>
          <DisplayText size="small">
            No product requests were found.
          </DisplayText>
          <br />
        </div>
      )}
    </TableContainer>
  );
};

export default ProductRequestsReportsTable;
