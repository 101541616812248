import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Link,
  Button,
  Page,
  AppProvider,
  Checkbox,
  Icon,
  Image,
  DisplayText,
  FormLayout,
  ChoiceList,
  Filters,
  Loading,
  Avatar,
  TextField,
  Select,
  Card,
  Layout,
  TextStyle,
  ButtonGroup,
  Badge,
  Pagination,
} from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../../axios";
import history from "../../history";
import "@zendeskgarden/react-pagination/dist/styles.css";
import emptyIcon from "../../img/emptyList.svg";
import { useParams } from "react-router-dom";
import EllipsisText from "react-ellipsis-text";
import moment from "moment";
import CustomTooltip from "./../../components/CustomTooltip";

function History() {
  const { id } = useParams();
  let perPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [isListEmpty, setIsListEmpty] = useState(true);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [type, setType] = useState('all');

  const optionsTypes = [
    { label: "All", value: "all" },
    { label: "User", value: "user" },
    { label: "Admin", value: "admin" },
    { label: "Clinic", value: "clinic" }
  ]

  const handleHistoryType = (typeValue) => {
    setCurrentPage(1);
    setType(typeValue);
  }

  useEffect(() => {
    axios
      .get(
        `admin/v1/users/${id}/history?per_page=${perPage}&page=${currentPage}&type=${type}`,
        {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      )
      .then((result) => {
        !result.data.data.data.length
          ? setIsListEmpty(true)
          : setIsListEmpty(false);
        setTotalPages(Math.ceil(result.data.data.total / perPage));
        result.data.data &&
          result.data.data.data &&
          setItems(
            result.data.data.data.map((item) => [
              item?.admin ? item.type == 'clinic' ? `${item?.admin?.clinic_info?.name_identification?.en} (${item?.admin?.last_name})` : `${item?.admin?.first_name} ${item?.admin?.last_name}` : '',
              <EllipsisText text={item.action} length={100} />,
              moment(item.updated_at)
                .add(2, "hours")
                .format("YYYY-MM-DD hh:mm:ss"),
              item.ip,
              item.id,
            ])
          );
      })
      .catch((err) => console.log(err));
  }, [currentPage, type]);

  const emtyState = isListEmpty ? (
    <div className="message-no-result"
    >
      <img  src={emptyIcon} className="empty-state-image"></img>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : null;
  return (
    <Layout.Section>
      <Card
        title="HISTORY"
      >
        <div style={{ margin : '2rem'}}>
          <Select
            label="Filter by Actor Role:"
            onChange={handleHistoryType}
            value={type}
            options={optionsTypes}
            multi
          />
        </div>
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "text"]}
          headings={[
            <TextStyle variation="strong">Actor</TextStyle>,
            <TextStyle variation="strong">Activity </TextStyle>,
            <TextStyle variation="strong">Date</TextStyle>,
            <TextStyle variation="strong">IP</TextStyle>,
            <TextStyle variation="strong">Action ID</TextStyle>,
          ]}
          rows={items}
        />
        {totalPages >= 2 && (
          <div
            style={{
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            <Pagination
              hasPrevious={currentPage > 1 && true}
              onPrevious={() => {
                setCurrentPage(currentPage - 1);
              }}
              hasNext={currentPage < totalPages && true}
              onNext={() => {
                setCurrentPage(currentPage + 1);
              }}
            />
          </div>
        )}
        {emtyState}
      </Card>
    </Layout.Section>
  );

  function handleEdit(e) {
    history.push(`/admin/notifications/${e.currentTarget.id}`);
  }
}
export default React.memo(History);
