import { Paper } from '@material-ui/core';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect } from 'react';
import { useStyles } from './clinic-reports-invoices-table.styles';
import ClinicsReportsInvoicesTableRow from './clinics-reports-invoices-table-row.component';
import { DisplayText, Image } from '@shopify/polaris';
import emptyIcon from '../../img/emptyList.svg';
import ClinicsReportsLoadingSpinner from './clinics-reports-loading-spinner.component';

const ClinicsReportsInvoicesTable = ({
  reports,
  getClinicReports,
  loadingReports,
  type,
  handlePaymentConfirmationDialog,
  setReportId,
}) => {
  const hideColumns = type === 'pending';
  const classes = useStyles({ hideColumns });
  useEffect(() => {
    if (reports[type].length === 0) {
      void getClinicReports(type);
    }
  }, []);

  if (loadingReports) {
    return <ClinicsReportsLoadingSpinner />;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.header}>Clinic Name</TableCell>
            <TableCell className={classes.header}>City</TableCell>
            <TableCell className={classes.header}>Country</TableCell>
            <TableCell
              className={`${classes.header} ${hideColumns && classes.hide}`}
            >
              Accepted Date
            </TableCell>
            <TableCell className={classes.header}>Download Report</TableCell>
            <TableCell className={classes.header}>Report Version</TableCell>
            <TableCell className={classes.header}>Disputed Date</TableCell>
            <TableCell
              className={`${classes.header} ${hideColumns && classes.hide}`}
            >
              Invoice Uploaded Date
            </TableCell>
            <TableCell className={classes.header}>Notes</TableCell>
            <TableCell className={classes.header}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reports[type].length > 0 &&
            reports[type].map((report) => (
              <ClinicsReportsInvoicesTableRow
                key={report.id}
                report={report}
                hideColumns={hideColumns}
                handlePaymentConfirmationDialog={
                  handlePaymentConfirmationDialog
                }
                setReportId={setReportId}
              />
            ))}
        </TableBody>
      </Table>
      {reports[type].length === 0 && (
        <div className="message-no-result">
          <div className="message-no-result-img">
            <Image src={emptyIcon} alt="empty" source=""></Image>
          </div>
          <DisplayText size="small">No Reports were found.</DisplayText>
          <br />
        </div>
      )}
    </TableContainer>
  );
};

export default ClinicsReportsInvoicesTable;
