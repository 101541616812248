import {Button, Card, DataTable, Filters, Icon, Spinner, Tabs, TextStyle} from '@shopify/polaris'
import Cookies from 'js-cookie';
import Pagination from "@material-ui/lab/Pagination";
import React, { useCallback, useEffect, useState } from 'react'
import axios from '../axios'
import { Radio, RadioGroup } from 'react-radio-group';
import history from '../history';
import QuestionsComponent from './QuestionsComponent';
import Axios from "axios";
import { ExportMinor, FilterMajor } from '@shopify/polaris-icons';
import moment from "moment";
import ThinLoading from '../components/Loading/thin-loading.component';

const SatisfactionSurvey = () => {
  const [sortState, setSortState] = useState('&id=ASC');
  const [loadingExportReport, setLoadingExportReport] = useState(false);
  const [selected, setSelected] = useState(0);
  const [items, setItems] = useState({})
  const [radioGroupValue, setRadioGroupValue] = useState({
    testimonials: "email",
    data: "email",
    beforeAfter: "email",
  });
  const [queryValue, setQueryValue] = useState({
    testimonials: 1,
    data: 1,
    beforeAfter: 1,
    startDate: "",
    endDate: ""
  });
  const [page, setPage] = useState({
    testimonials: 1,
    data: 1,
    beforeAfter: 1,
  });
  const perPage = 20;
  const [totalPages, setTotalPages] = useState({
    testimonials: 1,
    data: 1,
    beforeAfter: 1,
  });
  const [questionsArray, setQuestionsArray] = useState({
    start: [],
    mid: [],
    end: [],
  });
  const [loading, setLoading] = useState(false);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [loadingFilteredByDate, setLoadingFilteredByDate] = useState(false);
  const [isQuestionSaved, setIsQuestionSaved] = useState(0);

  function handleRowClick(id) {
    history.push({
      pathname: `/admin/user/${id}/careplus`,
      state: {
        backUrl: '/admin/careplus',
      },
    })
  }

  const getSurveyQuestions = () => {
    setLoadingQuestions(true);
    axios.get('/admin/v1/users/satisfaction-survey-questions', {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then(res => {
      let questions = {
        start:[],
        mid:[],
        end:[]
      };
      Object.keys(res.data.data).forEach((type) => {
        questions[type] = res.data.data[type];
      })
      setQuestionsArray(questions);
    }).catch(err => {
      console.log(err);
    }).finally(() => setLoadingQuestions(false));
  }

  const getSurveyAnswers = (ourRequest) => {
    setLoading(true);
    axios
      .get(`/admin/v1/users/satisfaction-survey-answers?page=${page.data}&per_page=${perPage}&search=${queryValue.data}&search_by=${radioGroupValue.data}&start_date=${queryValue.startDate}&end_date=${queryValue.endDate}`, {
        cancelToken: ourRequest.token,
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        if (res) {
          setTotalPages({
            data: Math.ceil(parseInt(res.data.data.total) / perPage),
          })
          setItems({
            ...items,
            data: res.data.data.data.map((item, key) => [
              <div className="itemClick"
                onClick={() => handleRowClick(item.id)}
              >
                {item.id}
              </div>,
              <div className="itemClick"
                onClick={() => handleRowClick(item.id)}
              >
                {item.full_name}
              </div>,
              <div className="itemClick"
                onClick={() => handleRowClick(item.id)}
              >
                {item.email}
              </div>,
              <div className="itemClick"
                onClick={() => handleRowClick(item.id)}
              >
                {item.type}
              </div>,
              <div className="itemClick"
                   onClick={() => handleRowClick(item.id)}
              >
                {item.answer_score}
              </div>,
              <div className="itemClick"
                onClick={() => handleRowClick(item.id)}
              >
                {moment(item.date).format("DD-MM-YYYY")}
              </div>,
            ]),
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        setLoadingFilteredByDate(false);
      });
  }
  
  const exportReportAnswers = async () => {
    try {
      setLoadingExportReport(true);
      const response = await axios.get(
        `/admin/v1/users/satisfaction-survey-answers/answers/report?start_date=${queryValue.startDate}&end_date=${queryValue.endDate}`,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        },
      );
      if (response) {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Survey-Answers-${new Date().toLocaleDateString("en-GB").split("/").join("-")}.xlsx`,
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.log("Error in exportReportAnswers", error);
    } finally {
      setLoadingExportReport(false);
    }
  };

  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    if (selected == 0) {
      getSurveyAnswers(ourRequest);
    } else if (selected == 1) {
      getSurveyQuestions();
    }
    return () => {
      ourRequest.cancel();
    }
  }, [queryValue.testimonials, queryValue.data, queryValue.beforeAfter, page, radioGroupValue, selected, isQuestionSaved])


  function handleSort(index) {
    if (index === 0) {
      if (sortState === "&id=DESC") {
        setSortState("&id=ASC");
      } else {
        setSortState("&id=DESC");
      }
    }
  }

  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      setSelected(selectedTabIndex);
      setRadioGroupValue({
        testimonials: 'email',
        data: 'email',
        beforeAfter: 'email'
      })
      setQueryValue({
        testimonials: '',
        data: '',
        beforeAfter: '',
      })
    },
    [],
  );

  const TabsContent = [
    {
      content: <UsersSurveyAnswers
        queryValue={queryValue}
        setQueryValue={setQueryValue}
        setRadioGroupValue={setRadioGroupValue}
        setPage={setPage}
        radioGroupValue={radioGroupValue}
        setItems={setItems}
        setSelected={setSelected}
        perPage={perPage}
        items={items}
        handleSort={handleSort}
        page={page}
        loading={loadingFilteredByDate}
        setLoading={setLoadingFilteredByDate}
        getSurveyAnswers={getSurveyAnswers}
        selected={selected}
        setTotalPages={setTotalPages}
        totalPages={totalPages}
        apiLoading={loading}
        loadingExportReport={loadingExportReport}
        exportReportAnswers={exportReportAnswers}
      />
    },
    {
      content: <Questions
        questionsArray={questionsArray}
        setQuestionsArray={setQuestionsArray}
        loading={loading}
        setLoading={setLoading}
        apiLoading={loadingQuestions}
        setIsQuestionSaved={setIsQuestionSaved}
      />
    },
  ]
  const tabs = [
    {
      id: 'answers',
      content: 'Answers',
      panelID: 'answers',
    },
    {
      id: 'questions',
      content: 'Questions',
      panelID: 'questions',
    },
  ];

  return (
    <Card>
      <Card.Section>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          <Card.Section>
            {TabsContent[selected].content}
          </Card.Section>
        </Tabs>
      </Card.Section>
    </Card>
  )
}

const Questions = ({
 questionsArray,
 setQuestionsArray,
 loading,
 setLoading,
 setIsQuestionSaved,
 apiLoading,
}) => {
  const handleIncCounter = (type) => {
    setQuestionsArray(prev => ({
      ...prev,
      [type]: [...prev[type], {id: null, answer_type: 'rating', type, is_mandatory: 0, survey_question: {quest_ar: '', quest_en: '', lowest_score_en: '', highest_score_en: '', lowest_score_ar: '', highest_score_ar: '', placeholder_en: '', placeholder_ar: ''}}]
    }))
  }

  const handleChange = (e, i, type, property) => {
    setQuestionsArray(prev => {
      prev[type][i].survey_question[property] = e;
      return {...prev, [type]: prev[type]}
    })
  };

  const handleSelectedTypes = (e, i, type, property) => {
    setQuestionsArray(prev => {
      prev[type][i][property] = e;
      return {...prev, [type]: prev[type]}
    })
  };
  
  const handleSave = (type, ind) => {
    let obj = questionsArray[type][ind]?.survey_question;
    if(!(obj.quest_en && obj.quest_ar && (questionsArray[type][ind].answer_type == 'rating' ? obj.lowest_score_en && obj.highest_score_en && obj.lowest_score_ar && obj.highest_score_ar : obj.placeholder_en && obj.placeholder_ar))){
      return;
    }
    let body = {
      id: questionsArray[type][ind]?.id,
      survey_question: questionsArray[type][ind]?.survey_question,
      answer_type: questionsArray[type][ind]?.answer_type,
      is_mandatory: questionsArray[type][ind]?.is_mandatory,
      type,
    }
    setLoading(prev => ({ type, id: ind }));
    axios.post('/admin/v1/users/create/satisfaction-survey-questions', body, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((res) => {
    }).catch(err => {
      console.log(err)
    }).finally(f => {
      setIsQuestionSaved(prev => prev + 1);
      setLoading(prev => ({ }));
    });
  };
  
  if (apiLoading) {
    return <ThinLoading />
  }

  return (
    <div>
      <QuestionsComponent
        title='Start of treatment questions'
        questionsArray={questionsArray.start}
        handleIncCounter={handleIncCounter}
        handleChange={handleChange}
        handleSelectedTypes={handleSelectedTypes}
        loading={loading}
        handleSave={handleSave}
        type='start'
      />
      <QuestionsComponent
        title='Mid of treatment questions'
        questionsArray={questionsArray.mid}
        handleIncCounter={handleIncCounter}
        handleChange={handleChange}
        handleSelectedTypes={handleSelectedTypes}
        loading={loading}
        handleSave={handleSave}
        type='mid'
      />
      <QuestionsComponent
        title='End of treatment questions'
        questionsArray={questionsArray.end}
        handleIncCounter={handleIncCounter}
        handleChange={handleChange}
        handleSelectedTypes={handleSelectedTypes}
        loading={loading}
        handleSave={handleSave}
        type='end'
      />
    </div>
  )
}

const UsersSurveyAnswers = ({
  queryValue,
  items,
  loading,
  setLoading,
  handleSort,
  page,
  perPage,
  selected,
  setItems,
  setPage,
  setQueryValue,
  setRadioGroupValue,
  radioGroupValue,
  totalPages,
  getSurveyAnswers,
  setTotalPages,
  apiLoading,
  loadingExportReport,
  exportReportAnswers,
}) => {
  const handleChangeFilter = (selectedValue) => {
    setRadioGroupValue({ data: selectedValue });
  };
  function handleQueryValueChange(queryValue) {
    setQueryValue({ ...queryValue, data: queryValue });
  }
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue({ ...queryValue, data: "" });
  }, []);
  const filters = [];
  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);
  const handleChange = (event, value) => {
    setPage({
      ...page,
      data: value
    });
  };
  
  const handleFilterOnChange = (e) => {
    const { name, value } = e.target;
    setQueryValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  
  const filterByDateHandler = () => {
    setLoading(true);
    const ourRequest = Axios.CancelToken.source();
    getSurveyAnswers(ourRequest);
  }
  
  const exportReportAnswersHandler = async () => {
    await exportReportAnswers();
  };
  
  const {startDate, endDate} = queryValue;
  if (loading || apiLoading) {
    return <ThinLoading />
  }
  
  return (
    <div>
      <Filters
        queryValue={queryValue.data}
        filters={filters}
        onQueryChange={handleQueryValueChange}
        onQueryClear={handleQueryValueRemove}
        onClearAll={handleClearAll}
      />
      <div 
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end"
      }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: '15px 0'
          }}
        >
          <TextStyle variation="strong">Search By : </TextStyle>
          <RadioGroup
            name="fruit"
            selectedValue={radioGroupValue.data}
            onChange={handleChangeFilter}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <>
                <label style={{ marginLeft: "20px" }}>
                  <Radio value="email" />
                  email
                </label>
              </>
            </div>
          </RadioGroup>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            columnGap: "10px",
            margin: "15px 0"
          }}
        >
          <div style={{ display: "grid" }}>
            <label htmlFor="start-date">Start Date:</label>
            <input
              id="start-date"
              style={{ padding: "8px", borderRadius: "5px" }}
              type="date"
              value={startDate}
              onChange={handleFilterOnChange}
              name="startDate"
            />
          </div>
          <div style={{ display: "grid" }}>
            <label htmlFor="end-date">End Date:</label>
            <input
              id="end-date"
              style={{ padding: "8px", borderRadius: "5px" }}
              type="date"
              value={endDate}
              onChange={handleFilterOnChange}
              name="endDate"
            />
          </div>
          <Button loading={loadingExportReport} onClick={exportReportAnswersHandler}>
            <Icon source={ExportMinor} color="base" />
          </Button>
          <Button
            onClick={filterByDateHandler}
          >
            <Icon source={FilterMajor} color="base"/>
          </Button>
        </div>
      </div>
      {items.data && <DataTable
        hideScrollIndicator
        columnContentTypes={["text", "text", "text", "text", "text", "text"]}
        headings={["ID", "Full Name", "Email", "Survey Type", "NPS Score", "Date"]}
        rows={items.data}
        sortable={[false, false, false, false, false, false]}
        initialSortColumnIndex={0}
        defaultSortDirection="descending"
        onSort={handleSort}
        verticalAlign="middle"
      />}
      <div style={{ textAlign: "center", margin: "20px auto" }}>
        <Pagination
          count={totalPages.data}
          page={page.data}
          onChange={handleChange}
          size="large"
        />
      </div>
    </div>
  )
}

export default SatisfactionSurvey