import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../history";
import { AppProvider } from "@shopify/polaris";
import ListOfTrackings from "./ListOfTrackings";
import AddTracking from "./AddTracking";
import MainTrackings2 from "./MainTrackings2";

function MainTrackings() {
  return (
    <AppProvider>
      <Router history={history}>
        <Switch>
          <Route
            exact
            path="/admin/orders/:id/trackings/:type?"
            component={ListOfTrackings}
          />
          <Route
            path="/admin/orders/:id/trackings/:type?/add"
            component={AddTracking}
          />
          <Route
            path="/admin/orders/:orderId/trackings/:trackingId"
            component={MainTrackings2}
          />
        </Switch>
      </Router>
    </AppProvider>
  );
}
export default MainTrackings;
