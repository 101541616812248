import React, { useState, useEffect } from "react";
import {
  DataTable,
  Button,
  Image,
  DisplayText,
  Card,
  Layout,
  TextStyle,
  ButtonGroup,
  Pagination,
  Thumbnail,
} from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../../../axios";
import history from "../../../history";
import "@zendeskgarden/react-pagination/dist/styles.css";
import emptyIcon from "../../../img/emptyList.svg";
import { useParams } from "react-router-dom";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import CustomTooltip from "./../../../components/CustomTooltip";
import Lightbox from "react-awesome-lightbox";

export default function Comments() {
  const { id } = useParams();
  let perPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [isListEmpty, setIsListEmpty] = useState(true);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [imagesArray, setImagesArray] = useState([]);
  function handleShowLargeImage(images,key) {
    setIsOpen(!isOpen);
    setPhotoIndex(key);
    setImagesArray([...images])
  }

  useEffect(() => {
    axios
      .get(
        `admin/v1/users/${id}/comments?per_page=${perPage}&page=${currentPage}`,
        {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      )
      .then((result) => {
          console.log('Result', result);
        result.data?.data?.data && !result.data.data.data.length
          ? setIsListEmpty(true)
          : setIsListEmpty(false);
        setTotalPages(Math.ceil(result.data.data.total / perPage));
        result.data.data &&
          result.data.data.data &&
          setItems(
            result.data.data.data.map((item) => [
                item?.owner_name && item.owner_name ? item.owner_name : item?.admins ? item.admins?.first_name + " " + item.admins?.last_name : "No Name",
              ReactHtmlParser(item.comment),
              <div style={{display:"flex", gap: "10px"}}>{item.images_url?.length>0&&item.images_url.map((image,key)=>
                <div  onClick={(e) => handleShowLargeImage(item.images_url,key)}>
                  <Thumbnail
                  size="small"
                  source={
                    image.image
                      ? image.image
                      : ""
                  }/>
                </div>  
                  )}</div>,
              new Date(
                `${moment(item.created_at)
                  .subtract(2, "hours")
                  .format("YYYY-MM-DD hh:mm:ss")} UTC`
              ).toLocaleString(),
                item.id,
                item.editable &&
              <ButtonGroup>
                <Button id={String(item.id)} onClick={(e) => handleEdit(e)}>
                  Edit
                </Button>
              </ButtonGroup>,
            ])
          );
      })
      .catch((err) => console.log(err));
  }, [currentPage]);

  const emtyState = isListEmpty ? (
    <div className="message-no-result"
    >
      <img src={emptyIcon} className="empty-state-image"></img>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : null;

  return (
    <Layout.Section>
      <Card
        title="CLINIC COMMUNICATION"
        actions={[
          {
            content: "Add Comment",
            url: "/admin/users/" + id + "/dashboard/addcomment",
          },
        ]}
      >
          <div className='clinic-communication'>
              <DataTable
                  columnContentTypes={["text", "text", "text", "text", "text"]}
                  headings={[
                      <TextStyle variation="strong">Name</TextStyle>,
                      <TextStyle variation="strong">Comment</TextStyle>,
                      <TextStyle variation="strong">Additional Images</TextStyle>,
                      <TextStyle variation="strong">Created At</TextStyle>,
                      <TextStyle variation="strong">ID</TextStyle>,
                      <TextStyle variation="strong">Actions</TextStyle>,
                  ]}
                  rows={items}
              />
          </div>
        {totalPages >= 2 && (
          <div
            style={{
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            <Pagination
              hasPrevious={currentPage > 1 && true}
              onPrevious={() => {
                setCurrentPage(currentPage - 1);
              }}
              hasNext={currentPage < totalPages && true}
              onNext={() => {
                setCurrentPage(currentPage + 1);
              }}
            />
          </div>
        )}
        {emtyState}
      </Card>
      {isOpen && imagesArray?.length&& <Lightbox startIndex={photoIndex} onClose={() => setIsOpen(false)} images={imagesArray.map((val) => val.image !== null && val.image)}></Lightbox>}
    </Layout.Section>
  );

  function handleEdit(e) {
    history.push(
      "/admin/users/" + id + "/dashboard/comments/" + e.currentTarget.id
    );
  }
}
