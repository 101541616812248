import { Badge } from "@shopify/polaris";
import { capitalizeWords } from "../util/helpers";

export let TIKCET_TYPE_ENUMS = {
    "broken-ak": "Broken Aligner",
    "lost-ak": "Lost Aligner",
    "broken-attachment": "Fallen/Broken Attachment",
    "fallen-buttons": "Fallen Buttons",
    "clinic-issue": "Reporting Clinic Issue",
    "app-inquiry": "How to use app",
    "broken-retainer": "Broken Retainer",
    "lost-retainer": "Lost Retainer",
    "received-deformed-ak": "Received Deformed Aligners",
    "ak-not-fitting": "Aligners not Fitting",
    "received-wrong-ak": "Received Wrong Aligners",
    "progress-pictures-tracking": "Progress pictures tracking",
    "tp-plan-adjustment": "Treatment Plan adjustment",
    "relapse": "Relapse",
    "not-satisfied-end": "Not satisfied with end result",
    "discontinued": "Discontinued",
    "special-request": "Special request (attachments, pausing...)",
    "delay-prod": "Delay production",
    "delay-delivery": "Delay delivery",
    "unclear-address": "Unclear address",
    "new-photos": "Request new photos",
    "excess-around-attachments": "Excess around attachments",
}
export let admin_options = [
    {name:"Yasser Swaidan",value:7668},
    {name:"Sandra Ali",value:44594},
    {name:"Natalia Abou Mrad",value:46477},
    {name:"Ayat Baalbaki",value:7812},
    {name:"Kamal Ghamrawi",value:26078},
    {name:"Ghady Freiha",value:247,},
    {name:"Karine Shhaitly",value:245,},
    {name:"Tania Abi Farraj",value:1036,},
    {name:"Jana Kabalan",value:1046},
    {name:"Kareem Mazloum",value: 33383},
    {name:"Yorgo Hoayek",value:2610},
    {name:"Zeina Rafeh",value:2608},
    {name:"Ghadir Ismail",value:2609},
    {name:"Joseph Sawaya",value:2611},
    {name:"Fatima Harkous",value: 50699},
    {name:"Fatima Dagher",value: 43163},
    {name:"Dori Atallah",value:4627},
    {name:"Hussein Yehya",value:5492},
    {name:"Celine Awad",value:10348},
    {name:"Mohamad Saleh",value:13915},
    {name:"Basma Bou Ghanem",value:235},
    {name:"Arsho Metrebian",value:22297},
    {name:"Ahmad AlZein",value:6880},
    { name: "Elissa Mokh", value: 41673 },
    { name: "Jana Al Houssaini", value: 36275 },
    { name: "Hasan Daher", value: 42578 },
    { name: "George Hakim", value: 33382 },
]
export let type_options = [
    { name: "Broken Aligner", value: "broken-ak", extraFields: ["worn_time", "treatment_stage", "step_number"], dispatch: true },
    { name: "Lost Aligner", value: "lost-ak", extraFields: ["worn_time", "treatment_stage", "step_number"], dispatch: true },
    { name: "Fallen/Broken Attachment", value: "broken-attachment", extraFields: ["worn_time", "treatment_stage", "step_number"] },
    { name: "Fallen Buttons", value: "fallen-buttons", extraFields: ["worn_time", "treatment_stage", "step_number"] },
    { name: "Reporting Clinic Issue", value: "clinic-issue", extraFields: ["treatment_stage", "clinic_issue"] },
    { name: "How to use app", value: "app-inquiry", extraFields: [] },
    { name: "Broken Retainer", value: "broken-retainer", extraFields: ["worn_time", "treatment_stage", "step_number"], dispatch: true },
    { name: "Lost Retainer", value: "lost-retainer", extraFields: ["worn_time", "treatment_stage", "step_number"], dispatch: true },
    { name: "Received Deformed Aligners", value: "received-deformed-ak", extraFields: ["treatment_stage", "step_number"], dispatch: true },
    { name: "Aligners not Fitting", value: "ak-not-fitting", extraFields: ["worn_time", "treatment_stage", "step_number"], dispatch: true },
    { name: "Received Wrong Aligners", value: "received-wrong-ak", extraFields: ["treatment_stage", "step_number"], dispatch: true },
    { name: "Progress pictures tracking", value: "progress-pictures-tracking", extraFields: [] },
    { name: "Treatment Plan adjustment", value: "tp-plan-adjustment", extraFields: ["treatment_stage", "tp_issue"] },
    { name: "Relapse", value: "relapse", extraFields: ["treatment_stage", "relapse_issue"] },
    { name: "Not satisfied with end result", value: "not-satisfied-end", extraFields: ["treatment_stage"] },
    { name: "Discontinued", value: "discontinued", extraFields: [] },
    { name: "Special request (attachments, pausing...)", value: "special-request", extraFields: [] },
    { name: "Delay production", value: "delay-prod", extraFields: [] },
    { name: "Delay delivery", value: "delay-delivery", extraFields: [] },
    { name: "Unclear address", value: "unclear-address", extraFields: [] },
    { name: "Request new photos", value: "new-photos", extraFields: ["treatment_stage", "step_number", "photos_issue"] },
    { name: "Excess around attachments", value: "excess-around-attachments", extraFields: [] },
];
export let urgency_options = [
    { name: "Urgent", value: "urgent" },
    { name: "High", value: "high" },
    { name: "Medium", value: "medium" },
    { name: "Low", value: "low" },
]
export let source_options = [
    { name: "App", value: "app" },
    { name: "WhatsApp", value: "whatsapp" },
    { name: "Chat", value: "chat" },
    { name: "Call", value: "call" },
    { name: "Social Media", value: "social media" },
]
export let worn_time_options = [
    {name:"1 Day",value:"1 Day"},
    {name:"2 Days",value:"2 Days"},
    {name:"3 Days",value:"3 Days"},
    {name:"4 Days",value:"4 Days"},
    {name:"5 Days",value:"5 Days"},
    {name:"6 Days",value:"6 Days"},
    {name:"7 Days",value:"7 Days"},
    {name:"8 Days",value:"8 Days"},
    {name:"9 Days",value:"9 Days"},
    {name:"10 Days",value:"10 Days"},
    {name:"11 Days",value:"11 Days"},
    {name:"12 Days",value:"12 Days"},
    {name:"13 Days",value:"13 Days"},
    {name:"14 Days",value:"14 Days"},
    {name:"Less Than 6 Days",value:"Less Than 6 Days"},
    {name:"Between 7 and 14 Days",value:"Between 7 and 14 Days"},
];

export let step_number_options = [
    {name:"Step 1",value:"Step 1"},
    {name:"Step 2",value:"Step 2"},
    {name:"Step 3",value:"Step 3"},
    {name:"Step 4",value:"Step 4"},
    {name:"Step 5",value:"Step 5"},
    {name:"Step 6",value:"Step 6"},
    {name:"Step 7",value:"Step 7"},
    {name:"Step 8",value:"Step 8"},
    {name:"Step 9",value:"Step 9"},
    {name:"Step 10",value:"Step 10"},
    {name:"Step 11",value:"Step 11"},
    {name:"Step 12",value:"Step 12"},
    {name:"Step 13",value:"Step 13"},
    {name:"Step 14",value:"Step 14"},
    {name:"Step 15",value:"Step 15"},
    {name:"Step 16",value:"Step 16"},
    {name:"Step 17",value:"Step 17"},
    {name:"Step 18",value:"Step 18"},
    {name:"Step 19",value:"Step 19"},
    {name:"Step 20",value:"Step 20"},
    {name:"Step 21",value:"Step 21"},
    {name:"Step 22",value:"Step 22"},
    {name:"Step 23",value:"Step 23"},
    {name:"Step 24",value:"Step 24"},
    {name:"Step 25",value:"Step 25"},
    {name:"Step 26",value:"Step 26"},
    {name:"Step 27",value:"Step 27"},
    {name:"Step 28",value:"Step 28"},
    {name:"Step 29",value:"Step 29"},
    {name:"Step 30",value:"Step 30"},
    {name:"Step 31",value:"Step 31"},
    {name:"Step 32",value:"Step 32"},
    {name:"Step 33",value:"Step 33"},
    {name:"Step 34",value:"Step 34"},
    {name:"Step 35",value:"Step 35"},
    {name:"Step 36",value:"Step 36"},
    {name:"Step 37",value:"Step 37"},
    {name:"Step 38",value:"Step 38"},
    {name:"Step 39",value:"Step 39"},
    {name:"Step 40",value:"Step 40"},
]

export let treatment_stage_options = [
    {name:"Initial Treatment",value:"Initial Treatment"},
    {name:"Refinement",value:"Refinement"},
    {name:"MCA",value:"MCA"},
]

export let clinic_issue_options = [
    {name:"IPR",value:"IPR"},
    {name:"Attachments",value:"Attachments"},
    {name:"Buttons",value:"Buttons"},
]
export let tp_issue_options = [
    {name:"Change in attachments",value:"Change in attachments"},
    {name:"Teeth Movements",value:"Teeth Movements"},
    {name:"IPR Stages",value:"IPR Stages"},
    {name:"Duration of treatment",value:"Duration of treatment"},
    {name:"Wear Time",value:"Wear Time"},
    {name:"Pause Treatment",value:"Pause Treatment"},
]
export let relapse_issue_options = [
    {name:"Change in attachments",value:"Change in attachments"},
    {name:"Teeth Movements",value:"Teeth Movements"},
    {name:"IPR Stages",value:"IPR Stages"},
    {name:"Duration of treatment",value:"Duration of treatment"},
    {name:"Wear Time",value:"Wear Time"},
    {name:"Pause Treatment",value:"Pause Treatment"},
]
export let photos_issue_options = [
    {name:"All photos are without aligners",value:"All photos are without aligners"},
    {name:"All photos are with aligners",value:"All photos are with aligners"},
    {name:"Photots are uploaded on the wrong step",value:"Photots are uploaded on the wrong step"},
]

export let extra_fields = {
    "worn_time": { name: "Worn Time", field: "worn_time", value: "", type: "checkbox", options: worn_time_options, error: "", placeholder: "" , size: "20%", grow:"initial", required:true, extraFields: [], requireUser: true},
    "treatment_stage": { name: "TP Stage", field: "tp_stage", value: "", type: "checkbox", options: treatment_stage_options, error: "", placeholder: "" , size: "20%", grow:"initial", required:true, extraFields: [], requireUser: true},
    "step_number": { name: "Step Number", field: "step_number", value: "", type: "checkbox", options: step_number_options, error: "", placeholder: "" , size: "20%", grow:"initial", required:true, extraFields: [], requireUser: true, dependant: "tp_stage"},
    "clinic_issue": { name: "Clinic Issue", field: "clinic_issue", value: "", type: "checkbox", options: clinic_issue_options, error: "", placeholder: "" , size: "20%", grow:"initial", required:true, extraFields: [], requireUser: true},
    "tp_issue": { name: "Adjustment Issue", field: "tp_issue", value: "", type: "checkbox", options: tp_issue_options, error: "", placeholder: "" , size: "20%", grow:"initial", required:true, extraFields: [], requireUser: true},
    "relapse_issue": { name: "Relapse Issue", field: "relapse_issue", value: "", type: "checkbox", options: relapse_issue_options, error: "", placeholder: "" , size: "20%", grow:"initial", required:true, extraFields: [], requireUser: true},
    "photos_issue": { name: "Photos Issue", field: "photos_issue", value: "", type: "checkbox", options: photos_issue_options, error: "", placeholder: "" , size: "20%", grow:"initial", required:true, extraFields: [], requireUser: true},
}

export const getStatusBadgeComponent = (status) => {
  let badge;
  const statusValue = capitalizeWords(status);
  switch (status) {
    case 'open':
      badge = <Badge status="new">{statusValue}</Badge>;
      break;
    case 'awaiting feedback':
    case 'pending':
      badge = <Badge status="attention">{statusValue}</Badge>;
      break;
    case 'resolved':
      badge = <Badge status="warning">{statusValue}</Badge>;
      break;
    case 'ongoing':
      badge = <Badge status="info">{statusValue}</Badge>;
      break;
    case 'closed':
      badge = <Badge status="critical">{statusValue}</Badge>;
      break;
    case 'progress':
      badge = <Badge status="success">{statusValue}</Badge>;
      break;
    default:
      badge = <Badge>{statusValue}</Badge>;
  }
  return badge;
};