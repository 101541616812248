import React from 'react';
import { Icon } from '@shopify/polaris';
import { ChevronLeftMinor, ChevronRightMinor } from '@shopify/polaris-icons';

const Pagination = ({ page, setPage, total, perPage }) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: '20px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '25px',
      }}
    >
      <button
        style={{ border: 'none', cursor: 'pointer', background: 'transparent' }}
        onClick={() => setPage(page - 1)}
        disabled={page == 1}
      >
        <Icon
          source={ChevronLeftMinor}
          color={page == 1 ? 'base' : 'primary'}
        />
      </button>
      <span style={{ fontWeight: 'bold' }}>
        {page} / {total == 0 ? 1 : Math.ceil(total / perPage)}
      </span>
      <button
        style={{ border: 'none', cursor: 'pointer', background: 'transparent' }}
        disabled={total <= 0 || page == Math.ceil(total / perPage)}
        onClick={() => setPage(page + 1)}
      >
        <Icon
          source={ChevronRightMinor}
          color={
            page == Math.ceil(total / perPage) || total <= 0
              ? 'base'
              : 'primary'
          }
        />
      </button>
    </div>
  );
};

export default Pagination;
