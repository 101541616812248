import React, { useCallback, useEffect, useState } from "react";
import {
  Thumbnail,
  Caption,
  DropZone,
  Stack,
  Page,
  Card,
  Button,
} from "@shopify/polaris";
import axios from "../../axios";
import Cookie from "js-cookie";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import history from "../../history";
import MediaItem from "./MediaItem";

function WhatsNewItemMedia() {
  const { versionId, id } = useParams();
  const [imageKeyImage1, setImageKeyImage1] = useState("");
  const [imageKeyImage2, setImageKeyImage2] = useState("");
  const [filesImage1, setFilesImage1] = useState([]);
  const [filesImage2, setFilesImage2] = useState([]);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [mediaItemDetails, setMediaItemDetails] = useState({
    loading: false,
    titleEn: '',
    titleAr: '',
    descriptionTitleEn: '',
    descriptionTitleAr: '',
    descriptionEn: '',
    descriptionAr: '',
    imageEnUrl: '',
    imageArUrl: '',
  });

  const fetchMediaItem = () => {
    setMediaItemDetails(prev => ({
      ...prev,
      loading: true,
    }));
    axios.get(`/admin/v1/mobile/${id}/whats-new-media-item`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((res) => {
      setMediaItemDetails(prev => ({
        ...prev,
        titleEn: res.data.media_item.title_en,
        titleAr: res.data.media_item.title_ar,
        descriptionTitleEn: res.data.media_item.description_title_en,
        descriptionTitleAr: res.data.media_item.description_title_ar,
        descriptionEn: res.data.media_item.description_en,
        descriptionAr: res.data.media_item.description_ar,
        imageEnUrl: res.data.media_item.image_en_url,
        imageArUrl: res.data.media_item.image_ar_url,
      }));
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      setMediaItemDetails(prev => ({
        ...prev,
        loading: false,
      }));
    });
  }

  const handleSaveMediaItem = () => {
    setMediaItemDetails(prev => ({
      ...prev,
      loading: true,
    }));
    const bodyObj = {
      id,
      image_en: imageKeyImage1,
      image_ar: imageKeyImage2,
      title_en: mediaItemDetails.titleEn,
      description_title_en: mediaItemDetails.descriptionTitleEn,
      description_en: mediaItemDetails.descriptionEn,
      title_ar: mediaItemDetails.titleAr,
      description_title_ar: mediaItemDetails.descriptionTitleAr,
      description_ar: mediaItemDetails.descriptionAr,
      mobile_app_version_id: versionId,
    }
    axios.post('/admin/v1/mobile/store-whats-new-media', bodyObj, {
      headers: {
        Authorization: "Bearer " + Cookie.get("token"),
      },
    }).then(res => {
      history.push(`/admin/mobile-app/${versionId}/whats-new`);
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      setMediaItemDetails(prev => ({
        ...prev,
        loading: false,
      }));
    });
  }

  const handleChange = (property, value) => {
    setMediaItemDetails(prev => ({
      ...prev,
      [property]: value,
    }));
  }

  const sendImage1 = useCallback(
    (filesImage1) => {
      let imageType = filesImage1[filesImage1.length - 1].type;
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios.post("/admin/v1/images/s3", form_data, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }).then((res) => {
        setImageKeyImage1(res.data.key);
        let url2 = res.data.url;
        axios.put(url2, filesImage1[filesImage1.length - 1], {
          headers: {
            "x-amz-acl": "public-read-write",
            "Content-Type": imageType,
          },
        }).then((res) => {
        }).catch((err) => {
          console.log('err', err);
        });
      }).catch((err) => {
        console.log('err', err);
      });
    }, [filesImage1]
  );

  const sendImage2 = useCallback(
    (filesImage2) => {
      let imageType = filesImage2[filesImage2.length - 1].type;
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios.post("/admin/v1/images/s3", form_data, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }).then((res) => {
        setImageKeyImage2(res.data.key);
        let url2 = res.data.url;
        axios.put(url2, filesImage2[filesImage2.length - 1], {
          headers: {
            "x-amz-acl": "public-read-write",
            "Content-Type": imageType,
          },
        }).then((res) => {
        }).catch((err) => {
          console.log('err', err);
        });
      }).catch((err) => {
        console.log('err', err);
      });
    }, [filesImage2]
  );

  const handleDropZoneImage1 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesImage1((filesImage1) => [...filesImage1, ...acceptedFiles]),
    []
  );
  const handleDropZoneImage2 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesImage2((filesImage2) => [...filesImage2, ...acceptedFiles]),
    []
  );
  const fileUploadImage1 = !filesImage1.length && <DropZone.FileUpload />;
  let uploadedFilesImage1 = filesImage1.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesImage1[filesImage1.length - 1].name}
        source={
          validImageTypes.indexOf(filesImage1[filesImage1.length - 1].type) > 0
            ? window.URL.createObjectURL(filesImage1[filesImage1.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesImage1[filesImage1.length - 1].name}{" "}
        <Caption>{filesImage1[filesImage1.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );

  const fileUploadImage2 = !filesImage2.length && <DropZone.FileUpload />;
  let uploadedFilesImage2 = filesImage2.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesImage2[filesImage2.length - 1].name}
        source={
          validImageTypes.indexOf(filesImage2[filesImage2.length - 1].type) > 0
            ? window.URL.createObjectURL(filesImage2[filesImage2.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesImage2[filesImage2.length - 1].name}{" "}
        <Caption>{filesImage2[filesImage2.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );

  useEffect(() => {
    fetchMediaItem();
  }, [])

  return (
    <Page title="What's New" breadcrumbs={[
      {
        content: "Dashboard",
        url: `/admin/mobile-app/${versionId}/whats-new`,
      },
    ]}>
      <Card sectioned>
        <Card>
          <div style={{ padding: '60px', display: "grid", gap: "15px", position: "relative" }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <MediaItem
                title={mediaItemDetails.titleEn}
                descriptionTitle={mediaItemDetails.descriptionTitleEn}
                description={mediaItemDetails.descriptionEn}
                lang='En'
                handleChange={handleChange}
                titleProp='titleEn'
                descriptionTitleProp='descriptionTitleEn'
                descriptionProp='descriptionEn'
                imageUrl={
                  filesImage1.length > 0 ?
                  (
                    validImageTypes.indexOf(filesImage1[filesImage1.length - 1]?.type) > 0 ?
                    window.URL.createObjectURL(filesImage1[filesImage1.length - 1]) :
                    'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304'
                  ) :
                  mediaItemDetails.imageEnUrl
                }
                onDrop={handleDropZoneImage1}
                onDropAccepted={sendImage1}
                uploadedFilesImage={uploadedFilesImage1}
                fileUploadImage={fileUploadImage1}
              />
              <MediaItem
                title={mediaItemDetails.titleAr}
                descriptionTitle={mediaItemDetails.descriptionTitleAr}
                description={mediaItemDetails.descriptionAr}
                lang='Ar'
                handleChange={handleChange}
                titleProp='titleAr'
                descriptionTitleProp='descriptionTitleAr'
                descriptionProp='descriptionAr'
                imageUrl={
                  filesImage2.length > 0 ?
                  (
                    validImageTypes.indexOf(filesImage2[filesImage2.length - 1]?.type) > 0 ?
                    window.URL.createObjectURL(filesImage2[filesImage2.length - 1]) :
                    'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304'
                  ) :
                  mediaItemDetails.imageArUrl
                }
                onDrop={handleDropZoneImage2}
                onDropAccepted={sendImage2}
                uploadedFilesImage={uploadedFilesImage2}
                fileUploadImage={fileUploadImage2}
              />
            </div>
            <div style={{ textAlign: 'right' }}>
              <Button onClick={handleSaveMediaItem} primary disabled={mediaItemDetails.loading} loading={mediaItemDetails.loading}>Save</Button>
            </div>
          </div>
        </Card>
      </Card>
    </Page>
  )
}

export default WhatsNewItemMedia;
