import React, { useEffect, useState } from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DisplayText, Image } from '@shopify/polaris';
import emptyIcon from '../../../../img/emptyList.svg';
import { useStyles } from '../../../../Reports/ClinicReports/clinic-reports-invoices-table.styles';
import { useStyles as customStyles } from './create-smile-plan-categories.styles';
import Select from 'react-select';
import ClinicsReportsLoadingSpinner from '../../../../Reports/ClinicReports/clinics-reports-loading-spinner.component';
import SmilePlanTemplatesRow from './smile-plan-templates-row.component';
import { AddCircle } from '@mui/icons-material';

const SmilePlanTemplatesTable = ({
  smilePlanCategories,
  getSmilePlanTemplates,
  loadingSmilePlanTemplates,
  smilePlanTemplates,
  saveSingleSmilePlanTemplate,
  selectedOption,
  setSelectedOption,
  deleteSingleSmilePlanTemplate,
}) => {
  const [categories, setCategories] = useState([{ value: '', label: '' }]);
  const [formRows, setFormRows] = useState([]);
  const classes = useStyles();
  const smilePlanCategoriesClasses = customStyles();
  const handleSelectChange = async (selectedOption) => {
    setSelectedOption(selectedOption);
    await getSmilePlanTemplates(selectedOption.value);
  };
  const reactSelectCustomStyles = {
    control: (provided) => ({
      ...provided,
      width: 'max-content',
    }),
  };

  const mapSmilePlanCategories = () => {
    const mappedCategories = smilePlanCategories.map((smilePlanCategory) => ({
      value: smilePlanCategory.id,
      label: smilePlanCategory.name,
    }));
    setCategories(mappedCategories);
  };

  const handleRowInputChange = (index, field, value) => {
    setFormRows((prevRows) => {
      const newRows = [...prevRows];
      newRows[index] = {
        ...newRows[index],
        [field]: value,
      };
      return newRows;
    });
  };

  const handleAddRow = (index) => {
    const newRow = {
      id: '#',
      english: '',
      arabic: '',
    };

    setFormRows((prevRows) => {
      const newRows = [...prevRows];
      newRows.splice(index + 1, 0, newRow);
      return newRows;
    });
  };

  const handleRemoveRow = (index) => {
    setFormRows((prevRows) => {
      const newRows = [...prevRows];
      newRows.splice(index, 1);
      return newRows;
    });
  };

  const setFormattedRows = (smilePlanTemplatesData) => {
    if (smilePlanTemplatesData && smilePlanTemplatesData.length > 0) {
      const formattedTemplates = smilePlanTemplatesData.map((templateData) => ({
        id: templateData.id,
        english: templateData.description.en || '',
        arabic: templateData.description.ar || '',
      }));
      setFormRows(formattedTemplates);
    } else {
      setFormRows([]);
    }
  };

  useEffect(() => {
    if (smilePlanCategories && smilePlanCategories.length > 0) {
      mapSmilePlanCategories();
      setSelectedOption(null);
    }
  }, [smilePlanCategories]);

  useEffect(() => {
    setFormattedRows(smilePlanTemplates);
  }, [selectedOption, smilePlanTemplates]);

  if (loadingSmilePlanTemplates) {
    return <ClinicsReportsLoadingSpinner />;
  }

  return (
    <>
      <div className={smilePlanCategoriesClasses.tableAddRow}>
        <Typography variant="h4">Smile Plan Templates</Typography>
        <Select
          options={categories}
          styles={reactSelectCustomStyles}
          placeholder="Select an option"
          onChange={handleSelectChange}
          value={selectedOption}
        />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.header}>Id</TableCell>
              <TableCell className={classes.header}>
                English Description
              </TableCell>
              <TableCell className={classes.header}>
                Arabic Description
              </TableCell>
              <TableCell className={classes.header}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedOption !== null &&
              formRows.length > 0 &&
              formRows.map((smilePlanTemplate, index) => (
                <SmilePlanTemplatesRow
                  key={index}
                  smilePlanTemplate={smilePlanTemplate}
                  handleRowInputChange={handleRowInputChange}
                  index={index}
                  handleAddRow={handleAddRow}
                  handleRemoveRow={handleRemoveRow}
                  saveSingleSmilePlanTemplate={saveSingleSmilePlanTemplate}
                  deleteSingleSmilePlanTemplate={deleteSingleSmilePlanTemplate}
                />
              ))}
          </TableBody>
        </Table>
        {(!selectedOption || formRows.length === 0) && (
          <div className="message-no-result">
            <div className="message-no-result-img">
              <Image src={emptyIcon} alt="empty" source=""></Image>
            </div>
            <DisplayText size="small">
              No smile plan templates were found{' '}
              <Button
                variant="outlined"
                onClick={() => handleAddRow(0)}
                startIcon={<AddCircle />}
                disabled={selectedOption === null}
              >
                Add
              </Button>
            </DisplayText>
          </div>
        )}
      </TableContainer>
    </>
  );
};

export default SmilePlanTemplatesTable;
