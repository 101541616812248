import React, { useState, useEffect } from "react";
import { Card, Layout, TextStyle } from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../../axios";
import "@zendeskgarden/react-pagination/dist/styles.css";
import { useParams } from "react-router-dom";
import moment from "moment";
import MediaConsent from "./MediaConsent";
const Consent = () => {
  const { id } = useParams();
  const [isFilled, setIsFilled] = useState("Not Filled");

  useEffect(() => {
    axios
      .get(`admin/v1/users/${id}/consent`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        result.data.data &&
          setIsFilled(
            "Approved by user at " +
              moment(result.data.data.created_at)
                .add(2, "hours")
                .format("YYYY-MM-DD hh:mm:ss")
          );
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Layout.Section>
      <Card title="CONSENTS" sectioned>
        <TextStyle>Informed Consent: {isFilled}</TextStyle>
        <br />
        <br />
        <MediaConsent />
      </Card>
    </Layout.Section>
  );
};

export default Consent;
