import { Button, Icon } from "@shopify/polaris";
import { FilterMajor } from "@shopify/polaris-icons";
import { useStyles } from "./clinics-reports-date-filter.styles";

const ClinicReportsDateFilter = ({date, setDate, getClinicReports, type}) => {
  const classes = useStyles();
  const handleDateChange = (e) => {
    const { value } = e.target;
    setDate({...date, [type]:value});
  };
  return (
    <div className={classes.filterContainer}>
      <div className={classes.inputContainer}>
        <label htmlFor="start-date">Month:</label>
        <input
          className={classes.input}
          id="start-date"
          type="month"
          value={date[type]}
          onChange={handleDateChange}
          name="startDate"
        />
      </div>
      <Button
      onClick={() => getClinicReports(type)}
      >
        <Icon source={FilterMajor} color="base" />
      </Button>
    </div>
  );
};

export default ClinicReportsDateFilter;
