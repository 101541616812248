import React, {  useState, useEffect } from "react";
import {
  Image,
  DisplayText,
  Card,

} from "@shopify/polaris";
import { Button as ButtonMui,Typography } from "@material-ui/core";

import emptyIcon from "../img/emptyList.svg";
import Cookie from "js-cookie";
import axios from "../axios";
import { CardActions, TextField } from "@mui/material";
import ExistingPlan from "./ExistingPlan";

function PaymentPlan({plantype,payment}) {
    let arrayOfObjects = [];
    const [isEmpty, setIsEmpty] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);
    const[success,setSuccess]=useState(0);
  useEffect(() => {
    getData();
  }, [success]);

  function getData() {
    axios
      .get(`/admin/v1/active_plans?payment=${payment}&plan=${plantype}`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        if (result.data.body.length === 0) {
          setIsEmpty(true);
        }
        setIsLoading(false);
        let res = [];
         res = result.data.body.map((item)=>{
             return{
            id:item.id,   
            titlen:  item.title.en,
            titlar:  item.title.ar,
            descen:  item.description.en,
            descar : item.description.ar,
            country:item.product_country,
            down_payment:item.down_payment,
            monthly:item.month,
            monthly_pay:item.monthly_pay,
            total:item.total,
            editable:false,
            disablePayment:true,
             }
         })
        setItems(res);
      })
      .catch((err) => console.log(err));
  }
  return (
    <Card sectioned title={payment}>
      <CardActions style={{ float: "right" }}>
        <ButtonMui variant="contained" style={{backgroundColor:"rgb(0 128 96)",color:"white","padding":"1.5em 2em",}}
         onClick={()=>{console.log('clicked');setItems((prev)=>[...prev,{titlen:"", titlar:"", descen:"", descar:"", country:"",
          down_payment:0, monthly:0, monthly_pay:0 ,total:0 ,editable:true,disablePayment:false,id:null}])}}>
            <Typography variant="h6" >Add New</Typography></ButtonMui>
          </CardActions>
          <table style={{display:"grid",width: "100%",overflowX:"auto",overflowY: "hidden" ,padding:"1em 0"}}>
              <tr style={{display: "flex",gap:'10px'}}>
              <th  className="plan-header" >Country</th>
              <th className="plan-header">Title En</th>
              <th className="plan-header">Title Ar</th>
              <th className="plan-header">Description En</th>
              <th className="plan-header">Description Ar</th>
              <th className="plan-header">Down Payment</th>
              <th className="plan-header">Monthly</th>
              <th className="plan-header">Monthly Payment</th>
              <th className="plan-header">Total</th>
              <th className="plan-header"></th>
              </tr>
           { items.map((item) => (
               <ExistingPlan id={item.id} payment={payment} plan={plantype} titlen={item.titlen} titlar={item.titlar} descen={item.descen} descar={item.descar} country={item.country} down_payment={item.down_payment} monthly={item.monthly}  monthly_pay={item.monthly_pay} total={item.total} editable={item.editable} disablePayment={item.disablePayment} success={success} setSuccess={setSuccess} />
           ))
      }
            </table>
        {isEmpty ? (
        <div className="message-no-result">
          <div className="message-no-result-img">
            <Image src={emptyIcon}></Image>{" "}
          </div>
          <DisplayText size="small">No results found</DisplayText>
          <br />
        </div>
      ) : null}
      </Card>
  )
}

export default PaymentPlan