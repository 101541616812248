import React, { useState, useEffect } from "react";
import { DataTable, Page, FormLayout, Card, TextStyle } from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../axios";

import "@zendeskgarden/react-pagination/dist/styles.css";
import { useParams } from "react-router-dom";

function ListOfUsers() {
  let { id } = useParams();
  const [items, setItems] = useState([]);
  useEffect(() => {
    axios
      .get(`/admin/v1/coupons/${id}/users-list`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setItems(
          result.data.data.map((item) => [
            item.id,
            item.user.id,
            item.user.first_name,
            item.user.last_name,
            item.user.email,
            item.product.title.en
          ])
        );
      })
      .catch((err) => console.log(err));
  }, []);

  const exportCouponsUsers = () => {
    let date = new Date();
    axios({
      url: `/admin/coupon-users-csv/${id}`,
      method: 'GET',
      headers:{
        Authorization : `Bearer ${Cookie.get('token')}`
      },
      responseType: 'blob', // important
    }).then((response) => {
       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', `Coupons users ${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}.csv`);
       document.body.appendChild(link);
       link.click();
    });
  }
  return (
    <Page
      title="Users"
      breadcrumbs={[{ content: "List Of Coupons", url: "/admin/coupons" }]}
      primaryAction={{
        content: "Export",
        onClick: exportCouponsUsers,
        disabled: !items || items.length <= 0
      }}
      fullWidth
    >
      {items.length !== 0 ? (
        <Card>
          <DataTable
            columnContentTypes={["text", "text"]}
            headings={[
              <TextStyle variation="strong">Order ID</TextStyle>,
              <TextStyle variation="strong">User ID</TextStyle>,
              <TextStyle variation="strong">First Name</TextStyle>,
              <TextStyle variation="strong">Last Name</TextStyle>,
              <TextStyle variation="strong">Email</TextStyle>,
              <TextStyle variation="strong">Product</TextStyle>,
            ]}
            rows={items}
          />
        </Card>
      ) : (
        <Card sectioned>
          <TextStyle>No Users</TextStyle>
        </Card>
      )}
    </Page>
  );
}

export default ListOfUsers;
