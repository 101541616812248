import React from "react";
import { Switch, Route, Router, withRouter } from "react-router-dom";
import history from "../../history";
import { AppProvider } from "@shopify/polaris";
import List from "./List";
import Add from "./Add";
import Edit from "./Edit";

function MainPatients() {
  return (
    <AppProvider>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/doctors/:id/patients" component={List} />
          <Route path="/admin/doctors/:id/patients/add" component={Add} />
          <Route path="/admin/doctors/:id/patients/:id" component={Edit} />
        </Switch>
      </Router>
    </AppProvider>
  );
}
export default MainPatients;
