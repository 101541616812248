import React from 'react'
import { Typography } from "@material-ui/core";
import Select from "react-dropdown-select";

const InformationCheckboxGroup = ({
    title,
    placeholder,
    checkboxes,
    group,
    handleCheckboxClick,
}) => {
    const handleClick = (check) => {
        handleCheckboxClick(group, check);
    }

    return (
        <div>
            <Typography className='treatment-approach-title'>{title}</Typography>
            <Select
                placeholder={placeholder}
                multi
                options={checkboxes}
                onChange={handleClick}
                values={checkboxes.filter(check => check.selected)}
            />
        </div>
    )
}

export default InformationCheckboxGroup
