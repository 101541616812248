import React, { useState, useEffect } from "react";
import {FormLayout, Card, Layout, TextStyle, Spinner} from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../../../axios";
import "@zendeskgarden/react-pagination/dist/styles.css";
import {useHistory, useParams} from "react-router-dom";
import moment from "moment";

function Shipping(props) {
  const { id } = useParams();
  const [shippingResponse, setShippingResponse] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    axios
      .get(`admin/v1/users/${id}/shipping?type=current`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        result.data.data && setShippingResponse(result.data.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);
    const history = useHistory();

    const handleEdit = () => {
        history.push(
            `/admin/users/${id}/dashboard/editshipping`,
            {
                clinicName: props.clinicName,
                planType: props.planType,
                status: props.status,
                pageTitle: props.pageTitle,
                clinicStatus: props.clinicStatus,
                userId: props.userId
            }
        );
    }

  return (
    <Layout.Section>
      <Card
        sectioned
        title="SHIPPING ADDRESS"
        actions={[
          {
            content: "Edit",
              onClick: handleEdit
          },
        ]}
      >
        {loading ? <div style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          backgroundColor: "white",
          padding: "50px",
          marginTop: "15px",
          marginBottom: "15px"
        }}>
          <Spinner accessibilityLabel="Loading Clinics Pricing" size="large"/>
        </div> : shippingResponse && (
          <FormLayout>
            {shippingResponse.full_name && (
              <TextStyle>
                {"Full Name: " + shippingResponse.full_name}
              </TextStyle>
            )}
            {shippingResponse.phone && (
              <TextStyle>{"Phone Number: " + shippingResponse.phone}</TextStyle>
            )}
            {shippingResponse.email && (
              <TextStyle>{"Email: " + shippingResponse.email}</TextStyle>
            )}
            {shippingResponse.country && (
              <TextStyle>{"Country: " + shippingResponse.country}</TextStyle>
            )}
            {shippingResponse.city && (
              <TextStyle>{"City: " + shippingResponse.city}</TextStyle>
            )}
            {shippingResponse.address && (
              <TextStyle>{"Area & Street: " + shippingResponse.address}</TextStyle>
            )}
            {shippingResponse.address_2 && (
              <TextStyle>
                {"Building, Floor, Apartment: " + shippingResponse.address_2}
              </TextStyle>
            )}
            {shippingResponse.address_3 && (
              <TextStyle>
                {"Additional Address Details: " + shippingResponse.address_3}
              </TextStyle>
            )}
            {shippingResponse.apartment && (
              <TextStyle>
                {"Additional Address Details 2: " + shippingResponse.apartment}
              </TextStyle>
            )}
            {shippingResponse.postal_code && (
              <TextStyle>
                {"Postal Code: " + shippingResponse.postal_code}
              </TextStyle>
            )}
            {shippingResponse.created_at && (
              <TextStyle>
                {"Created At: " +
                  moment(shippingResponse.created_at)
                    .add(2, "hours")
                    .format("YYYY-MM-DD hh:mm:ss")}
              </TextStyle>
            )}
            {shippingResponse.updated_at && (
              <TextStyle>
                {"Updated At: " +
                  moment(shippingResponse.updated_at)
                    .add(2, "hours")
                    .format("YYYY-MM-DD hh:mm:ss")}
              </TextStyle>
            )}
            {shippingResponse.note && (
              <TextStyle>{"Note: " + shippingResponse.note}</TextStyle>
            )}
          </FormLayout>
        )}
      </Card>
    </Layout.Section>
  );
}
export default React.memo(Shipping);
