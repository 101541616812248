import Select, { components } from 'react-select';
import { getProceduresWithIPRSheet } from '../../../util/helpers';
import { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';

const DownloadSelectIprSheetComponent = ({ procedures, loading }) => {
  const [downloadableIpSheets, setDownloadableIpSheets] = useState([]);
  const customNoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        No IPR Steps Available.
      </components.NoOptionsMessage>
    );
  };

  const openStepFile = (url) => {
    let filepath = url.split('/');
    let fileName = filepath.pop();
    saveAs(process.env.REACT_APP_AWS_URL + url, fileName);
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption?.value) {
      if (selectedOption?.label === 'Previous IPR') {
        const win = window.open(selectedOption?.value, '_blank');
        win.focus();
      } else {
        openStepFile(selectedOption.value);
      }
    }
  };

  const filterIncomingIpSheetsOptions = () => {
    const iprSheetProcedures = getProceduresWithIPRSheet(procedures);
    const mappedIPRSheetProcedures = iprSheetProcedures.map((procedure) => {
      if (procedure.previous) {
        return {
          value: procedure.url,
          label: `Previous IPR`,
        };
      }
      return {
        value: procedure.url,
        label: `IPR Step ${procedure.step}`,
      };
    });
    setDownloadableIpSheets(mappedIPRSheetProcedures);
  };

  useEffect(() => {
    if (procedures) {
      filterIncomingIpSheetsOptions();
    }
  }, [procedures]);

  return (
    downloadableIpSheets &&
    downloadableIpSheets.length > 0 && (
      <Select
        options={downloadableIpSheets}
        onChange={handleSelectChange}
        placeholder="Select IPR Sheet to Download"
        isClearable
        isSearchable
        isLoading={loading}
        components={{ NoOptionsMessage: customNoOptionsMessage }}
      ></Select>
    )
  );
};

export default DownloadSelectIprSheetComponent;
