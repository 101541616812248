import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Button,
  Page,
  Filters,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Card,
  TextStyle,
  ButtonGroup,
  Pagination,
  Spinner,
  Thumbnail,
} from "@shopify/polaris";
import Cookie from "js-cookie";
import axios from "../../../../axios";
import history from "../../../../history";
import emptyListImage from "../../../../img/emptyList.svg";
import { useParams } from "react-router-dom";

const List = () => {
  const { id, idCity, idClinic } = useParams();
  const format = "YYYY-MM-DD HH:mm:ss";
  const perPage = 30;
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [sortState, setSortState] = useState("-id");
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  const handleEdit = (e) => {
    history.push(
      `/admin/book-a-scan-countries/${id}/cities/${idCity}/clinics/${idClinic}/images/${e.currentTarget.id}`
    );
  };

  useEffect(() => {
    axios
      .get(
        `/admin/v1/countries/${id}/cities/${idCity}/clinics/${idClinic}/images`,
        {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      )
      .then((result) => {
        setIsLoading(false);
        console.log(!result.data.data.length);
        !result.data.data.length ? setIsListEmpty(true) : setIsListEmpty(false);
        // setTotalPages(Math.ceil(result.data.meta.total / perPage));
        setItems(
          result.data.data.map((item) => [
            item.id,
            <div onClick={() => window.open(item.image, "_blank")}>
              <Thumbnail source={item.image} size="small" />
            </div>,
            <ButtonGroup>
              <Button onClick={handleEdit} id={item.id}>
                Edit
              </Button>
            </ButtonGroup>,
          ])
        );
      })
      .catch((err) => setIsLoading(false));
  }, [currentPage, sortState, availability, queryValue]);

  function handleFiltersQueryChange(queryValue) {
    setQueryValue(queryValue);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "ID", value: "id" },
            { label: "Name", value: "Name" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div className="message-no-result"
    >
      <div className="message-no-result-img">
        <Image src={emptyListImage}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );
  return (
    <Page
      fullWidth
      title="Images"
      primaryAction={{
        content: "Add Image",
        url: `/admin/book-a-scan-countries/${id}/cities/${idCity}/clinics/${idClinic}/images/new`,
      }}
      breadcrumbs={[{ url: `/admin/book-a-scan-countries/${id}/cities` }]}
    >
      <Card>
        {loadingMarkup}
        {/* <Card.Section>
          <Filters
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
        </Card.Section> */}
        <DataTable
          columnContentTypes={["text", "text"]}
          headings={[
            <TextStyle variation="strong">ID</TextStyle>,
            <TextStyle variation="strong">Thumbnail</TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
          ]}
          rows={items}
          sortable={[false, false, false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={0}
          onSort={handleSort}
          verticalAlign="middle"
        />

        {emtyState}
      </Card>
      {totalPages >= 2 && (
        <div style={{ marginTop: "20px" }}>
          <Pagination
            hasPrevious={currentPage > 1 && true}
            onPrevious={() => {
              setCurrentPage(currentPage - 1);
            }}
            hasNext={currentPage < totalPages && true}
            onNext={() => {
              setCurrentPage(currentPage + 1);
            }}
            label={`${currentPage}/${totalPages}`}
          />
        </div>
      )}
    </Page>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return value.map((val) => `Filter by ${val}`).join(", ");
      case "productType":
        return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};

export default List;
