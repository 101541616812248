import React, { useState, useEffect } from "react";
import { Button, TextStyle } from "@shopify/polaris";

import "@zendeskgarden/react-pagination/dist/styles.css";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";

function FirstTimeWearingAligner(props) {
  const { id } = useParams();
  const [dateValue, setDateValue] = useState("");
  useEffect(() => {
    setDateValue(props.dateValue);
  }, [props]);
  const history = useHistory();

  const handleEdit = () => {
    history.push(`/admin/users/${id}/dashboard/smilejourneystartingdate`, {
      clinicName: props.clinicName,
      planType: props.planType,
      status: props.status,
      pageTitle: props.pageTitle,
      clinicStatus: props.clinicStatus,
      userId: props.userId,
    });
  };
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <TextStyle>
        First Aligner Start Date: {dateValue ? moment(dateValue).format("DD-MM-YYYY") : "Not Set"}
      </TextStyle>
      <Button onClick={handleEdit}>Edit</Button>
    </div>
  );
}
export default React.memo(FirstTimeWearingAligner);
