import React, { useEffect, useState } from "react";
import { Page, Card } from "@shopify/polaris";
import NavigationLayout from "../components/NavigationLayout";
import axios from "../axios";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import history from "../history";
import ThinLoading from "../components/Loading/thin-loading.component";
function ManageDeparments() {
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState([]);
  const fetchDepartments = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/admin/v1/getalldepartments`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });

      setDepartments(response.data.departments);
    } catch (error) {
      console.error("Error fetching careers:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDepartments();
  }, []);

  const handleDepartmentNameChange = (index, newName) => {
    const updatedDepartments = [...departments];
    updatedDepartments[index].name = newName;
    setDepartments(updatedDepartments);
  };

  const handleRoleChange = (depIndex, roleIndex, newName) => {
    const updatedDepartments = [...departments];
    updatedDepartments[depIndex].roles[roleIndex].name = newName;
    setDepartments(updatedDepartments);
  };

  const handleAddRole = (depIndex) => {
    const updatedDepartments = [...departments];
    updatedDepartments[depIndex].roles.push({ id: null, name: "" });
    setDepartments(updatedDepartments);
  };

  const handleAddDepartment = () => {
    setDepartments([
      ...departments,
      { id: null, name: "", roles: [{ id: null, name: "" }] },
    ]);
  };

  const handleSaveDepartment = async (department, depIndex) => {
    try {
      const response = await axios.post(
        "/admin/v1/create-departments-roles",
        {
          id: department.id,
          name: department.name,
          roles: department.roles,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      if (response.data.id) {
        setDepartments((prev) => {
          const newArr = [...prev];
          newArr[depIndex].id = response.data.id;
          return newArr;
        });
      }

      response.data.roles.forEach((newRole, index) => {
        if (!department.roles[index].id && newRole.id) {
          setDepartments((prev) => {
            const newArr = [...prev];
            newArr[depIndex].roles[index].id = newRole.id;
            return newArr;
          });
        }
      });
      if (response.status === 200) {
        toast.success(response.data.message, 3000);
      } else {
        toast.error("Error", 3000);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error", 3000);
    }
  };
  const handleDeleteEmptyRole = (depIndex, roleIndex) => {
    const updatedDepartments = [...departments];
    const department = updatedDepartments[depIndex];
    if (department.roles[roleIndex].name.trim() === "") {
      updatedDepartments[depIndex].roles.splice(roleIndex, 1);
      setDepartments(updatedDepartments);
    }
  };
  const handleDeleteRole = async (roleId) => {
    try {
            const response = await axios.delete(`/admin/v1/roles/${roleId}`, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + Cookies.get("token"),
                },
            });
            if (response.status === 200) {
                toast.success(response.data.message, 3000);
                fetchDepartments()
            } else {
                toast.error('Error', 3000);
            }
        
    } catch (error) {
        console.error('Error:', error);
        toast.error('Error', 3000);
    }
};
const handleDeleteDepartment = async (departmentId,depIndex) => {
  try {
    if (departmentId === null) {
      const updatedDepartments = [...departments];
      updatedDepartments.splice(depIndex, 1);
      setDepartments(updatedDepartments);
      return; // Exit the function if the department ID is null
  }
      const response = await axios.delete(`/admin/v1/departments/${departmentId}`, {
          headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + Cookies.get("token"),
          },
      });
      if (response.status === 200) {
          toast.success(response.data.message, 3000);
          fetchDepartments(); 
      } else {
          toast.error('Error', 3000);
      }
  } catch (error) {
      console.error('Error:', error);
      toast.error('Error', 3000);
  }
};
  return (
    <NavigationLayout>
      <Page title="Managing Departments and Roles">
        <ToastContainer />

        <Card sectioned>
          <div>
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
                paddingBottom: "30px",
              }}
              className="form-row-question"
            >
              <button onClick={() => history.push(`/admin/careers`)}>
                Go Back
              </button>
              <div>
                <button onClick={handleAddDepartment}>Add Department</button>
              </div>
            </div>
            <div className=" container-grid">
              <div className="w100">
                <div className="form-row-question container-grid">
                  <div className="column25">
                    <label>Department</label>
                  </div>
                  <div className="column25" style={{ paddingLeft: "40px" }}>
                    <label>Role</label>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <div
                style={{
                  textAlign: "center",
                  paddingTop: "5vh",
                  paddingBottom: "5vh",
                }}
              >
                <ThinLoading />
              </div>
            ) : (
              <div className="question-scrollable-container container-grid ">
                <div className="w100">
                  {departments.map((department, depIndex) => (
                    <div
                      key={depIndex}
                      className="form-row-question container-grid"
                      style={{
                        display: "flex",
                        alignItems: "start",
                        flexWrap: "wrap",
                      }}
                    >
                      <div className="column25">
                        <input
                          className="career-input_form"
                          type="text"
                          value={department.name}
                          placeholder="Department"
                          onChange={(e) =>
                            handleDepartmentNameChange(depIndex, e.target.value)
                          }
                        />
                      </div>

                      <div className="column25">
                        <ul style={{ marginTop: "0px", marginLeft: "0px" }}>
                          {department.roles.map((role, roleIndex) => (
                            <li
                              key={roleIndex}
                              style={{
                                paddingBottom: "10px",
                                listStyle: "none",
                                display:'flex',
                                alignItems:'center'
                              }}
                            >
                              <input
                                className="career-input_form"
                                type="text"
                                placeholder="Role"
                                value={role.name}
                                onChange={(e) =>
                                  handleRoleChange(
                                    depIndex,
                                    roleIndex,
                                    e.target.value
                                  )
                                }
                              />
                              {role.name.trim() !== "" && role.id &&  (
                                <button
                                  onClick={() =>
                                    handleDeleteRole(role.id)
                                  }
                                  style={{padding:'0.5rem 1rem',marginLeft:'5px',backgroundColor:'red'}}
                                >
                                  -
                                </button>
                              )}
                             {role.name.trim() === "" && !role.id && (
                                <button
                                  onClick={() =>
                                    handleDeleteEmptyRole(depIndex, roleIndex)
                                  }
                                  style={{padding:'0.5rem 1rem',marginLeft:'5px',backgroundColor:'red'}}
                                >
                                  -
                                </button>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div className="">
                        <div style={{ paddingBottom: "10px" }}>
                          <button
                            style={{ marginLeft: "30px" }}
                            onClick={() => handleAddRole(depIndex)}
                          >
                            Add Role
                          </button>
                        </div>
                      </div>
                    
                      <div className="">
                        <div style={{ paddingBottom: "10px" }}>
                          <button
                            onClick={() =>
                              handleSaveDepartment(department, depIndex)
                            }
                            style={{ marginInline: "20px" }}
                          >
                           
                            Save
                          </button>
                        </div>
                      </div>
                      <div className="">
                        <div style={{ paddingBottom: "10px" }}>
                          <button
                            onClick={() =>
                              handleDeleteDepartment(department.id,depIndex)
                            }
                          >
                           
                             Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Card>
      </Page>
    </NavigationLayout>
  );
}
export default ManageDeparments;
