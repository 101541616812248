import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  uploadContainer: {
    display: "flex",
    flexDirection: "row",
    "& .Polaris-Labelled--hidden": {
      width: "100%",
    },
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "white",
    padding: "15px",
    marginTop: "15px",
    marginBottom: "15px",
  },
  noteContainer: {
    whiteSpace: "pre-wrap",
  },
  btn: {
    cursor: 'pointer',
    border: 'none',
    background: 'transparent',
    padding: 0,
    minWidth: 'fit-content',
    '&:hover': {
      opacity: 0.8,
    }
  },
}));
