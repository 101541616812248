import { Badge, Box } from '@material-ui/core';
import ChatIcon from '@mui/icons-material/Chat';
import NotificationsIcon from '@mui/icons-material/Notifications';
import React, { useEffect, useState } from 'react'
import { useStyles } from './Notificationstyles';
import NotificationBox from '../NotificationBox/NotificationBox';
import Cookies from 'js-cookie';
import { collection, endBefore, getCountFromServer, getDocs, limit, onSnapshot, or, orderBy, query, startAfter, updateDoc, where } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { signInWithCustomToken } from 'firebase/auth';
import axios from "../../axios";

function Notification() {
    const[expandNotification,setExpandNotification] = useState({status:false,anchor:null,full:false});
    const[notifications, setNotifications] = useState([]);
    const [lastVisibleItem, setLastVisibleItem] = useState(null);
    const [notificationCollectionRef, setNotificationCollectionRef] = useState(null)
    const [notificationCount, setNotificationCount] = useState(0);
    const itemsPerPage = 5;
    const handleClickNotification = (event) => {
        setExpandNotification({status:true,anchor:event.currentTarget,full:false});
      };
    const handleCloseNotificationTab = () =>{
        setExpandNotification({status:false,anchor:null,full:false});
    }
    const handleViewAll = () =>{
      setExpandNotification((prev)=>{return {...prev,full:!prev.full}});
  }
    const classes = useStyles()
    useEffect(()=>{
        let unsubscribe =()=>{}
        let firebasePromise ;
        let firebaseSignIn = false;
        if(!auth.currentUser && process.env.REACT_APP_FIREBASE_V2){
          firebasePromise = axios.get(`/admin/v1/firebase/agentauth`,{
            headers:{
              Authorization: "Bearer " + Cookies.get("token")
            }
          })
          firebaseSignIn = true;
        }else{
          firebasePromise = Promise.resolve();
        }
        firebasePromise.then(async(res) => {
          let adminId
          if(firebaseSignIn){
            let { token } = res.data
            adminId = res.data.adminId;
            let res2 = await signInWithCustomToken(auth, token);
          }else{
            adminId = parseInt(Cookies.get('admin_id'));
          }
          console.log('notifications')

          const adminCollection = collection(db, "Admins");
          const adminQuery = query(adminCollection, where('admin_id', '==', adminId))
          let adminRef = await getDocs(adminQuery, { source: "server" })
          if (adminRef.docs.length) {
            const notificationCollection = collection(adminRef.docs[0].ref, "CaseNotification");
            setNotificationCollectionRef(adminRef.docs[0].ref)
            getCountFromServer(query(notificationCollection,where('read', 'in',[0,false]))).then((count)=>{
              console.log(count,count.data())
              setNotificationCount(count.data().count)
            })
            let q = query(notificationCollection, orderBy('date', 'desc'), limit(itemsPerPage))
            getDocs(q, { source: "server" }).then(async (snapshot) => {
              let arr = [];
              snapshot.docs.forEach(snap => {
                let obj = { ...snap.data(), "id": snap.id, "adminId": adminRef.docs[0].ref, "ref": snap.ref };
                arr.push(obj);
              });
              setNotifications(arr)
              let snapq;
              if (snapshot.docs.length) {
                let firstNotification = snapshot.docs[0];
                if (snapshot.docs.length < itemsPerPage) {
                  setLastVisibleItem(null)
                } else {
                  setLastVisibleItem(snapshot.docs[snapshot.docs.length - 1])
                }
                snapq = query(notificationCollection, orderBy('date', 'desc'), endBefore(firstNotification))
              } else {
                setLastVisibleItem(null)
                snapq = query(notificationCollection, orderBy('date', 'desc'))
              }
              let unsubscribe = onSnapshot(snapq, async (snapshot) => {
                let newNot = [];
                snapshot.docChanges().forEach(snap=>{
                  setNotificationCount(prev=>prev+1)
                })
                snapshot.docs.forEach(snap => {
                  let obj = { ...snap.data(), "id": snap.id, "adminId": adminRef.docs[0].ref, "ref": snap.ref };
                  newNot.push(obj);
                });

                setNotifications(prev => {
                  let arr = [...prev]
                  for (let i = 0; i < newNot.length; i++) {
                    const newObj = newNot[i];
                    let found = false;
                    for (let j = 0; j < arr.length; j++) {
                      const oldObj = arr[j];
                      if (newObj.id === oldObj.id) {
                        arr[j] = newObj;
                        found = true;
                        break;
                      }
                    }
                    if (!found) {
                      arr.unshift(newObj);
                    }
                  }
                  return [...arr];
                })
              })
            })
          }
      })
      return () => {
        console.log('clean')
        unsubscribe()
      };
    },[])

    const handleLoadMore = () => {
      if (!lastVisibleItem || !notificationCollectionRef) {
        return null;
      }
      const notificationCollection = collection(notificationCollectionRef, "CaseNotification");
      let q = query(notificationCollection, orderBy('date', 'desc'), startAfter(lastVisibleItem), limit(itemsPerPage))
      getDocs(q).then(async (snapshot) => {
        if (snapshot.size) {
          let arr = [];
          snapshot.docs.forEach(snap => {
            let obj = { ...snap.data(), "id": snap.id, "adminId": notificationCollectionRef, "ref": snap.ref };
            arr.push(obj);
          });
          setNotifications(prev =>[...prev,...arr])
          if(snapshot.size<itemsPerPage){
            setLastVisibleItem(null)
          }else{
            setLastVisibleItem(snapshot.docs[snapshot.docs.length - 1])
          }
        } else {
          setLastVisibleItem(null)
        }
      });
    }
    const ClearAll = async()=>{
      let q = query(collection(notificationCollectionRef, "CaseNotification"),where('read', 'in', [0, false]));
      let unreadNotification = await getDocs(q)
      unreadNotification.docs.forEach(e => {
        updateDoc(e.ref,{read:1})
      });
      setNotifications((prev)=>{
        let arr = [...prev];
        arr.forEach(ar=>{
          ar.read = 1;
        })
        return [...arr]
      })
      setNotificationCount(0)
    }
    const ClearNotification = async(ref)=>{
      let index = notifications.findIndex(obj=>{
        return obj.ref == ref
      })
      if(index>=0 && !notifications[index].read){
        setNotifications((prev)=>{
          let arr = [...prev];
          arr[index].read = 1;
          return [...arr]
        })
        await updateDoc(ref, { read: 1 })
        setNotificationCount(prev=>prev-1)
      }

    }
   
  return (


    <div>
        <Box onClick={handleClickNotification}>
        <Badge className={classes.notificationBadge}   badgeContent={notificationCount} color='error'>
            <ChatIcon
                aria-controls={expandNotification.status ? 'demo-positioned-menu1' : undefined}
                aria-haspopup="true"
                aria-expanded={expandNotification.status ? 'true' : undefined} 
                className={classes.notificationIcon} 
            />
        </Badge>
        </Box>
        <NotificationBox expandNotification={expandNotification} handleCloseNotificationTab={handleCloseNotificationTab} notifications={notifications} handleViewAll={handleViewAll} handleLoadMore={handleLoadMore} lastVisibleItem={lastVisibleItem} ClearAll={ClearAll} ClearNotification={ClearNotification}  />
    </div>
  )
}

export default Notification