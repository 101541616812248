import React, { useCallback, useState, useEffect, useContext } from "react";
import {
  DataTable,
  Button,
  Page,
  AppProvider,
  Image,
  DisplayText,
  Loading,
  ChoiceList,
  Card,
  TextStyle,
  ButtonGroup,
  Modal,
  TextContainer,
  Toast,
  Spinner,
  Pagination,
} from "@shopify/polaris";
import Cookie from "js-cookie";
import axios from "../axios";
import history from "../history";
import emptyIcon from "../img/emptyList.svg";
import Pagination2 from "@material-ui/lab/Pagination";
import { ExportMinor } from "@shopify/polaris-icons";

export default function List() {
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  let arrayOfObjects = [];
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const [sortState, setSortState] = useState("-id");
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [countries, setCountries] = useState([]);
  const [currentID, setCurrentID] = useState(0);
  let valueFilter = "";
  const [active, setActive] = useState(false);
  const [toastMessage, setToastMessage] = useState("Account Disactivated");
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content={toastMessage} onDismiss={toggleActive} />
  ) : null;
  useEffect(() => {
    getData();
  }, [currentPage, queryValue, availability]);
  function getData() {
    axios
      .get(
        `/admin/v1/potential-doctors?per_page=${perPage}&page=${currentPage}`,
        {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      )
      .then((result) => {
        setIsLoading(false);
        setTotalPages(Math.ceil(result.data.data.total / perPage));
        result.data.data.data.length === 0
          ? setIsListEmpty(true)
          : setIsListEmpty(false);

        setItems(
          result.data.data.data.map((item) => [
            item.id,
            item.first_name,
            item.last_name,
            item.is_approved ? "YES" : "NO",
            item.city,
            item.clinic_name,
            getRoleAtPractice(item.role_at_practice),
            item.clinic_chair_capacity,
            !item.clinic_have_scanner ? "NO" : "YES",
            <ButtonGroup>
              <Button
                primary
                id={String(item.id)}
                onClick={(e) => handleApprove(e)}
              >
                View
              </Button>
              <Button
                destructive
                id={String(item.id)}
                onClick={(e) => handleDelete(e)}
              >
                Delete
              </Button>
            </ButtonGroup>,
          ])
        );
      })
      .catch((err) => console.log(err));
  }
  function handleApprove(e) {
    history.push(`/admin/potential-doctors/${e.currentTarget.id}`);
  }
  function handleDelete(e) {
    setCurrentID(e.currentTarget.id);
    setActiveDeleteMessage(true);
  }

  function handleFiltersQueryChange(queryValue) {
    console.log(queryValue);
    setQueryValue(queryValue);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[{ label: "Name", value: "name" }]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
          // allowMultiple
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    []
  );
  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div className="message-no-result"
    >
      <div className="message-no-result-img">
        <Image src={emptyIcon}></Image>{" "}
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner accessibilityLabel="Spinner example" size="large" />
        </div>
      </div>
    )
  );
  const [activeDeleteMessage, setActiveDeleteMessage] = useState(false);
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <Page
      fullWidth
      title="List Of Potential Doctors"
      secondaryActions={[
        {
          content: "Export",
          icon: ExportMinor,
          onAction: exportPotententialDoctors,
        },
      ]}
    >
      {loadingMarkup}
      <Card>
        {/* <Card.Subsection>
            <Filters
              queryValue={queryValue}
              filters={filters}
              appliedFilters={appliedFilters}
              onQueryChange={handleFiltersQueryChange}
              onQueryClear={handleQueryValueRemove}
              onClearAll={handleFiltersClearAll}
            />
          </Card.Subsection> */}
        <DataTable
          columnContentTypes={["text", "text", "text", "text"]}
          headings={[
            <TextStyle variation="strong">ID</TextStyle>,
            <TextStyle variation="strong">First Name</TextStyle>,
            <TextStyle variation="strong">Last Name</TextStyle>,
            <TextStyle variation="strong">Is Approved</TextStyle>,
            <TextStyle variation="strong">City</TextStyle>,
            <TextStyle variation="strong">Clinic Name</TextStyle>,
            <TextStyle variation="strong">Role At Practice</TextStyle>,
            <TextStyle variation="strong">Clinic Chair Capacity</TextStyle>,
            <TextStyle variation="strong">Clinic Have 3D Scanner</TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
          ]}
          rows={items}
          sortable={[false, false, false, false, false, false, false]}
          defaultSortDirection="descending"
          verticalAlign="center"
          // initialSortColumnIndex={0}
          // onSort={handleSort}
        />

        <div></div>

        {emtyState}
      </Card>
      {totalPages >= 2 && (
        <div style={{ marginTop: "20px" }}>
          <Pagination
            hasPrevious={currentPage > 1 && true}
            onPrevious={() => {
              setCurrentPage(currentPage - 1);
            }}
            hasNext={currentPage < totalPages && true}
            onNext={() => {
              setCurrentPage(currentPage + 1);
            }}
            label={`${currentPage}/${totalPages}`}
          />
        </div>
      )}

      {/* <Pagination2
        count={10}
        page={page}
        onChange={handleChange}
        size="large"
      /> */}

      {toastMarkup}
      <Modal
        open={activeDeleteMessage}
        onClose={handleChangeDeleteMessage}
        title="Delete Doctor"
        primaryAction={{
          content: "No",
          onAction: handleNoDelete,
        }}
        secondaryActions={[
          {
            content: "Yes",
            onAction: () => handleYesDelete(currentID),
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure you want to delete this doctor ?</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </Page>
  );
  function exportPotententialDoctors() {
    window.open(
      `${process.env.REACT_APP_BASE_URL}/admin/v1/potential-doctors/export?token=` +
        "Bearer " +
        Cookie.get("token")
    );
  }
  function getRoleAtPractice(param) {
    switch (param) {
      case 1:
        return "Dentist";
      case 2:
        return "Orthodontist";
      case 3:
        return "Dental Assistant";
      case 4:
        return "Office Manager";
      case 5:
        return "Other";
    }
  }
  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return value.map((val) => `Filter by ${val}`).join(", ");
      case "productType":
        return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }

  function handlePageChange(currentPage) {
    setCurrentPage(currentPage);
  }
  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element.id) === parseInt(id);
    });
  }

  function handleChangeDeleteMessage() {
    setActiveDeleteMessage(false);
  }

  function handleNoDelete() {
    setActiveDeleteMessage(!activeDeleteMessage);
  }
  function handleYesDelete(id) {
    setActiveDeleteMessage(!activeDeleteMessage);
    axios
      .delete(`admin/v1/potential-doctors/${id}/delete`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        setToastMessage("This doctor is successfully deleted");
        toggleActive();
        getData();
      })
      .catch((err) => console.log(err));
  }
}
