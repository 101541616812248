import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
    },
    title: {
        fontSize: '16px !important',
    },
    details: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'stretch',
    },
    headerDiv: {
        display: 'flex',
        width: '98%',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subTitle: {
        fontSize: '12px !important',
    },
}));

const TPAccordion = ({ title, defaultExpanded = true, children, subTitle = '' }) => {
    const classes = useStyles();

    return (
        <Accordion
            className={classes.container}
            TransitionProps={{ unmountOnExit: true }}
            defaultExpanded={defaultExpanded}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <div className={classes.headerDiv}>
                    <Typography className={classes.title}>
                        {title}
                    </Typography>
                    {
                        subTitle && 
                        <Typography className={classes.subTitle}>
                            {subTitle}
                        </Typography>
                    }
                </div>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

export default TPAccordion