import React, { useState, useEffect } from "react";
import { Router } from "react-router-dom";
import history from "./history";
import {
  DataTable,
  TextContainer,
} from "@shopify/polaris";
import axios from "./axios";
import NavigationLayout from "./components/NavigationLayout";
import Pagination from "@material-ui/lab/Pagination";
import {
  Page,
  Card,
  Modal,
} from "@shopify/polaris";
import Cookies from "js-cookie";

function Blogs() {
  const [isDeleted, setIsDeleted] = useState(0);
  const [blogs, setBlogs] = useState([]);
  const [blogId, setBlogId] = useState(0);
  const [page, setPage] = useState(1);
  const [blPage, setBlPage] = useState(1);
  const [activeDelete, setActiveDelete] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalBlPages, setTotalBlPages] = useState(1);
  const [items, setItems] = useState([]);
  const [headings, setHeadings] = useState([]);

  const fetchBlogs = async () => {
    await axios.get(`admin/v1/blogs?page=${blPage}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((result) => {
      setBlogs(result.data.blogs.data);
      setTotalBlPages(result.data.blogs.last_page);
    }).catch((err) => console.log(err));
  }

  function handleRowClick(e) {
    history.push(`/admin/basma-blog-topic/${e.currentTarget.id}`);
  }

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleBlChange = (event, value) => {
    setBlPage(value);
  };

  const fetchtopics = async () => {
    await axios.get(`admin/v1/blog-topics?page=${page}&paginate=1`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((result) => {
      setTotalPages(result.data.topics.last_page);
      setItems(
        result.data.topics.data.map((item) => [
          <div className="itemClick" id={String(item.id)} onClick={(e) => handleRowClick(e)}>
            <b>{item.id}</b>
          </div>,
          item?.name_en && (
            <div className="itemClick" id={String(item.id)} onClick={(e) => handleRowClick(e)}>
              {item.name_en}
            </div>
          ),
          item?.name_ar && (
            <div className="itemClick" id={String(item.id)} onClick={(e) => handleRowClick(e)}>
              {item.name_ar}
            </div>
          ),
        ])
      );
      setHeadings(["ID", "Topic Name En", "Topic Name Ar"]);
    }).catch((err) => console.log(err));
  }

  const handleDelete = () => {
    axios.delete(`admin/v1/blog/delete/${blogId}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((res) => {
      setIsDeleted(prev => prev+1);
    }).catch(err => {
      console.log(err)
    }).finally(f => {
      setActiveDelete(false);
    });
  }

  useEffect(() => {
    fetchtopics();
  }, [page])
  
  useEffect(() => {
    fetchBlogs();
  }, [blPage, isDeleted])

  return (
    <NavigationLayout>
      <Router history={history}>
        <Page breadcrumbs={[{ content: "Content Management", url: "/admin/content" }]} title="Manage Topics" 
          primaryAction={{
            content: "Add Topic",
            onClick: () => history.push(`/admin/add/basma-blog-topic`),
          }}
        >
          <Card title="List of topics" sectioned>
            <DataTable
              columnContentTypes={["text", "text", "text"]}
              headings={headings}
              rows={items}
              verticalAlign="middle"
            />
          </Card>
          {totalPages > 1 && (
            <div style={{paddingTop: "10px", textAlign: "center", paddingBottom: "10px"}}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handleChange}
                size="large"
              />
            </div>
          )}
        </Page>
        <Page title="Manage Blogs" 
          primaryAction={{
            content: "Add Blog",
            onClick: () => history.push(`/admin/add/basma-blog`),
        }}>
          <Card title="List of Blogs" sectioned>
            <Card.Section>
              {blogs?.length > 0 ? blogs?.map((val, key) => {
                return <Card primaryFooterAction={{
                  content: "Delete",
                  destructive: true,
                  onClick:() => {
                  setBlogId(val.id);
                  setActiveDelete(true)
                  },}} 
                  actions={[
                    {
                      content: "Edit",
                      url: `/admin/basma-blog/${val.id}`
                    },
                  ]} key={key}>
                  <div style={{display:"flex", gap:"10px", padding:"10px"}}>
                    <img style={{width: '200px', height: '200px', border: '0.5px solid lightgray', borderRadius: 5}} src={val.main_image_url} alt={val.main_image_alt.en} />
                    <div style={{display:"flex", flexDirection:"column", flex: 1}}>
                      <span><strong>Title En: </strong>{val?.title_en}</span>
                      <span><strong>Title Ar: </strong>{val?.title_ar}</span>
                      {val?.blog_topic_relations.length > 0 && <span style={{display: 'flex', flexWrap: 'wrap', gap: 5}}><strong>Related Topic(s): </strong>
                        {val?.blog_topic_relations.map((blogTopiRelation, key) => <span key={key} style={{padding: '2px 5px', borderRadius: 5, backgroundColor: 'lightgray', fontSize: 12}}>{blogTopiRelation?.topic?.name_en}</span>)}
                      </span>}
                      <span><strong>Status: </strong>{val?.is_draft ? 'Draft' : 'Published'}</span>
                      <span><strong>Date of publishing: </strong>{val?.created_at}</span>
                    </div>
                  </div>
                </Card>
              }) : <div style={{padding:"10px"}}>No Blogs found</div>}
            </Card.Section>
          </Card>
          {totalBlPages > 1 && (
            <div style={{paddingTop: "10px", textAlign: "center", paddingBottom: "10px"}}>
              <Pagination
                count={totalBlPages}
                page={blPage}
                onChange={handleBlChange}
                size="large"
              />
            </div>
          )}
          <Modal
            open={activeDelete}
            onClose={() => setActiveDelete(false)}
            title="Delete Blog"
            primaryAction={{
              content: "No",
              onAction: () => setActiveDelete(!activeDelete),
            }}
            secondaryActions={[
              {
                content: "Yes",
                onAction: handleDelete,
              },
            ]}
          >
            <Modal.Section>
              <TextContainer>
                <p>Are you sure you want to delete this blog?</p>
              </TextContainer>
            </Modal.Section>
          </Modal>
        </Page>
      </Router>
    </NavigationLayout>
  );
}
export default Blogs;
