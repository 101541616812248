import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide,
} from '@mui/material';
import { forwardRef } from 'react';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AlertUnpaidAppointmentDialog = ({
  open = false,
  setOpen,
  handleAction,
  message = 'Are you sure you want to delete?',
  checkedClinicDidntShowBox,
  setCheckedClinicDidntShowBox,
}) => {
  const handleClose = (hardClose = false) => {
    if (hardClose) {
      setCheckedClinicDidntShowBox(!checkedClinicDidntShowBox);
    }
    setOpen(false);
  };

  const handleConfirm = () => {
    handleAction();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ minWidth: '600px', fontSize: '16px' }}>
        {message}
      </DialogTitle>
      <DialogActions>
        <Button sx={{ fontSize: '12px' }} onClick={() => handleClose(true)}>
          No
        </Button>
        <Button sx={{ fontSize: '12px' }} onClick={handleConfirm}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertUnpaidAppointmentDialog;
