import React, { useState, useEffect } from "react";
import {
  Card,
  Layout,
  TextStyle,
} from "@shopify/polaris";

import "@zendeskgarden/react-pagination/dist/styles.css";
import CrmFreshdeskTicket from "./crm-freshdesk-ticket.component";

function PreSalesOwner(props) {
  const [selectedPreSalesOwner, setSelectedPreSalesOwner] = useState("");

  useEffect(() => {
    props?.preSalesOwnerValue &&
      setSelectedPreSalesOwner(props.preSalesOwnerValue);
  }, [props?.preSalesOwnerValue]);

  const [selectedSalesOwnerSupport, setSalesOwnerSupport] = useState("");
  
  useEffect(() => {
    props?.salesOwnerSupportValue &&
    setSalesOwnerSupport(props.salesOwnerSupportValue);
  }, [props?.salesOwnerSupportValue]);

  const [selectedSalesOwner, setSelectedSalesOwner] = useState("");

  useEffect(() => {
    props?.salesOwnerValue && setSelectedSalesOwner(props.salesOwnerValue);
  }, [props?.salesOwnerValue]);

  const [selectedSuccessOwner, setSelectedSuccessOwner] = useState("");

  useEffect(() => {
    props?.customerSuccessOwnerValue &&
      setSelectedSuccessOwner(props.customerSuccessOwnerValue);
  }, [props?.customerSuccessOwnerValue]);

  return (
    <Layout.Section oneHalf>
      <Card title="CRM" sectioned>
        <div style={{ display: "flex", columnGap: "20px", justifyContent: "space-between" }}>
          <div>
            <div style={{ margin: "0px 0 5px 0" }}>
              Pre-sales Agent (Lead): <TextStyle>{selectedPreSalesOwner}</TextStyle>
            </div>
            <div style={{margin: "0px 0 5px 0"}}>
              Pre-sales Agent (Contact): <TextStyle>{selectedSalesOwnerSupport}</TextStyle>
            </div>
            <div style={{ margin: "5px 0" }}>
              Sales Agent: <TextStyle>{selectedSalesOwner}</TextStyle>
            </div>
            <div style={{ margin: "5px 0" }}>
              Patient Care Agent: <TextStyle>{selectedSuccessOwner}</TextStyle>
            </div>
          </div>
          <CrmFreshdeskTicket userId={props.userId}/>
        </div>
      </Card>
    </Layout.Section>
  );
}

export default React.memo(PreSalesOwner);
