import axios from "axios";
import history from "./history";
import { handledRolePages } from "./components/NavigationLayout";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
const errorHandler = (response) => {
  console.log("axiosError" + response.status);
  if (response.status === 401) {
    if (history.location.pathname !== "/") {
      history.push("/", { returnUrl: history?.location?.pathname });
    }
  }else if (response.status === 403) { 
    if(handledRolePages().pages.includes('Orders'))
      // history.push("/admin/orders");
      console.log("Yes")
    else if(handledRolePages().pages.includes("View Clinics"))
      history.push("/admin/view-clinics");
    else
      history.push("/admin/users");
  }else {
    return Promise.reject(response.data.errors);
  }
  return Promise.reject({ ...response.status });
};

const successHandler = (response) => {
  return response;
};

instance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error.response)
);

export default instance;
