import React from 'react';
import { Modal, TextContainer } from '@shopify/polaris';

const ProductRequestReasonDialog = ({ open, setOpen, reason }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Modal
      title="Reason"
      open={open}
      onClose={handleClose}
      primaryAction={{
        content: 'Close',
        onAction: handleClose,
      }}
    >
      <Modal.Section>
        <TextContainer>{reason}</TextContainer>
      </Modal.Section>
    </Modal>
  );
};

export default ProductRequestReasonDialog;
