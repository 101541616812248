import React, { useEffect, useState } from "react";
import '../ListJobs.css'
import axios from "../../axios";
import FileViewer from "../Modal/FileViewer";
import Cookies from "js-cookie";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copy from "../../assets/images/copy.png";
import { ToastContainer, toast } from "react-toastify";

function ApplicatnsDetails({ data, fetchAllApplicants}) {
    const [formData, setFormData] = useState({
        id: data.id,
        rating: '',
    });
    const [isOpen, setIsOpen] = useState(false)
    const [file, setFile] = useState('')

    const rating = [
        { id: 0, name: 'Select...', value: '' },
        { id: 1, name: 'Not a fit', value: 'Not a fit' },
        { id: 2, name: 'Good fit', value: 'Good fit' },
        { id: 3, name: 'Maybe', value: 'Maybe' },
        { id: 3, name: "Blacklist", value: "Blacklist" },
    ];

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const save = async (e) => {
        e.preventDefault();
        console.log('formData',formData)
        try {
            await axios.post(`/admin/v1/addrating`, JSON.stringify(formData), {
                headers: {
                    'Content-Type': 'application/json',
                      Authorization: "Bearer " + Cookies.get("token"),
                },
            }).then((res)=>{
                if (res.status === 200) {
                    toast.success(res.data.message,3000);
                } else {
                    toast.error(res.data.message,3000);
                }
            }
            )
            fetchAllApplicants();
        } catch (error) {
            console.error('Error:', error);
        }

    };

    useEffect(() => {
        setFormData({
            id: data.id,
            rating: data.rating,
        });

    }, [data])


    return data && (
        <div className="">
            <ToastContainer/>
            {isOpen && <FileViewer isOpen={isOpen} setIsOpen={setIsOpen} file={file} />}
            <div className="applicants-details-title">
                Details
                <hr />

            </div>

            <div className="applicants-details">
                <div>
                    <div className="applicants-label" style={{paddingTop:"10px"}}>
                        First Name
                    </div>
                    <div>

                        {data.first_name}
                    </div>
                </div>

                <div>
                    <div className="applicants-label" style={{paddingTop:"10px"}}>
                        Last Name
                    </div>
                    <div>

                        {data.last_name}
                    </div>
                </div>
                <div>
                    <div className="applicants-label" style={{display:'flex',justifyContent:'space-between',paddingTop:"10px"}}>
                        Email       
                        <CopyToClipboard text={data.email}>
                        <img src={copy} alt="copy" className="copy_img"/>
                    </CopyToClipboard>
                    </div>
                    <div  >
                        {data.email}
                    </div>
                </div>
                <div>
                   
                    <div className="applicants-label" style={{display:'flex',justifyContent:'space-between',paddingTop:"10px"}}>
                    Phone Number       
                        <CopyToClipboard text={data.phone}>
                        <img src={copy} alt="copy" className="copy_img"/>
                    </CopyToClipboard>
                    </div>
                    <div>

                        {data.phone}
                    </div>
                </div>
                <div>
                    <div className="applicants-label " style={{paddingTop:"10px"}}>
                        Date of Application
                    </div>
                    <div>

                        {data.created_at}
                    </div>
                </div>
                <div>
                    <div className="applicants-label" style={{paddingTop:"10px"}}>
                        Years of Experience:
                    </div>
                    <div>

                        {data.years_experience}
                    </div>
                </div>
            </div>
            <div>
                <div className="career-form-group list-jobs-form-group" >
                    <form >
                        <div style={{ display: "flex", alignItems: "end", paddingTop: "20px" }}>
                            <div>
                                <label>
                                    Rate the candidate

                                </label>
                                <select name="rating" value={formData.rating} onChange={handleChange}  >
                                    {rating.map((option, index) => (
                                        <option key={index} value={option.value}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <button type="button" className="addnewjob" style={{ cursor: "pointer", marginInline: "10px" }}
                                    onClick={save}>Save</button>
                            </div>

                        </div>

                    </form>

                </div>
                <div style={{ paddingTop: "20px" }}>


                    {data.cv_link_url &&
                        <button className='btn-list-view' onClick={() => setIsOpen(true) & setFile(data.cv_link_url)}>
                            View  CV
                        </button>
                    }
                    {data.cover_letter_url &&
                        <button className='btn-list-view' style={{ marginInline: '20px' }} onClick={() => setIsOpen(true) & setFile(data.cover_letter_url)}>
                            View  Cover Letter
                        </button>
                    }
                    {data.additional_files_url &&
                        <button className='btn-list-view' onClick={() => setIsOpen(true) & setFile(data.additional_files_url)}>
                            View  Additional Files
                        </button>
                    }
                </div>
                <div style={{ paddingTop: "20px" }}>
                    {data?.answer_applicant?.length !== 0 && <>

                        <div className="applicants-details-title">
                            Summary of all the questions and responses
                            <hr />
                        </div>
                        <div style={{ paddingTop: "20px" }}>
                            {data.answer_applicant?.map(info =>

                                <div className="">
                                    <span style={{ fontWeight: "500" }}>Question:</span> {info.question.survey_question.screening_question_en} 
                                    
                                    <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                        <span style={{ fontWeight: "500" }}>Answer:</span> {info.survey_answer}
                                    </div>
                                </div>

                            )}
                        </div>
                    </>}
                </div>

            </div>
        </div>
    );
}
export default ApplicatnsDetails;
