import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "../axios";
import axiosAws from "axios";
import ProcedureHeader from "./ProcedureHeader";
import Cookies from "js-cookie";
import Save from "./Save";
import ScreeningPhotoUpload from "./ScreeningPhotoUpload";
import AlertDeleteDialog from "./Popup/alert-delete-dialog.component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApprovalActions from "./ApprovalActions";
import { IssueTypes, ReviewStatus } from "../util/constants";
import DisapprovalModal from "./DisapprovalModal";
  
const useStyles = makeStyles(() => {
  return {
    photoWrapper: {
      padding: "1rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    photoBox: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    photoWidthSM: {
      width: "60%",
      ["@media only screen and (max-width: 700px) "]: {
        width: "100%",
      },
    },
    photoWidth: {
      width: "70%",
      marginBottom: 15,
      ["@media only screen and (max-width: 700px) "]: {
        width: "100%",
      },
    },
    titleStyle: {
      height: "16px",
      color: "#303030",
      fontSize: "16px !important",
      letterSpacing: 0,
      lineHeight: "22px",
    },
    titleBox: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '2rem',
    },
  };
});

export default function AppointmentScreeningPhotos({
  disable,
  appointmentId,
  appointmentNumber,
  appointmentType,
  handleTabsExpand,
  userId,
  index,
  openCloseTabs,
  scrollToExpandedTab,
}) {
  const classes = useStyles();
  const [photos, setPhotos] = useState([]);
  const [open, setOpen] = useState(false);
  const [deletePhoto, setDeletePhoto] = useState({photoId: null, type: ''});
  const [photosArray, setPhotosArray] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [imageKey, setImageKey] = useState(null);
  const [callHandleSave, setCallHandleSave] = useState(0);
  const [iconPhotos, setIconPhotos] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState({
    disabled: false,
    popupActive: false,
    success: false,
    disableReviewBtn: false,
    download: false,
  });
  const [deleteLoading, setDeleteLoading] = useState({
    frontFacingBigSmile: false,
    frontFacingNoSmile: false,
    rightSideProfileNoSmile: false,
    centerBite: false,
    rightBite: false,
    leftBite: false,
    upperTeeth: false,
    lowerTeeth: false,
  });
  const [reviewData, setReviewData] = useState({
    comment: '',
    commentError: '',
    loading: false,
    modalOpened: false,
    status: ''
  });
  const [screeningIssues, setScreeningIssues] = useState([]);
  const [imagesIssues, setImagesIssues] = useState([]);
  const [fileImages, setFileImages] = useState([]);
  const [images, setImages] = useState([]);
  const [imageKeys, setImageKeys] = useState([]);
  const [options, setOptions] = useState([]);

  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

  const handleIconPhotosView = (value) => {
    setIconPhotos(value);
  };

  const handleImages = (e, id) => {
    setErrorMsg("");
    setLoading(prev => ({
      ...prev,
      success: false,
      disabled: true,
    }));
    const imageId = id;
    const file = e.target.files[0];
    let imgUrl =
      validImageTypes.indexOf(file.type) > 0
        ? window.URL.createObjectURL(file)
        : "../assets/images/Fileicon.svg";
    setPhotos((prev) => ({
      ...prev,
      [imageId]: imgUrl,
    }));
    saveInAws(file, imageId);
  };

  const handleUploadError = (type, err) => {
    setErrorMsg("Upload failed. Please try again.");
    setPhotos((prev) => ({
      ...prev,
      [type]: undefined,
    }));
    setImageKey((prev) => ({
      // ...prev,
      [type]: undefined,
    }));
    console.log(err);
  };

  const handleClickOpen = (photoIdData, typeData) => {
    setDeletePhoto({photoId: photoIdData, type: typeData});
    setOpen(true);
  };

  const uploadConfig = (progressEvent, name) => {
    if (progressEvent.loaded === progressEvent.total) {
      delete uploadProgress[name];
      setUploadProgress(uploadProgress);
    } else {
      const array = {
        ...uploadProgress,
        [name]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
      };
      setUploadProgress(array);
    }
  };

  const saveInAws = (value, type) => {
    let imageType = value.type;
    const form_data = new FormData();
    form_data.append("content_type", imageType);
    form_data.append("file_name", value.name);
    axios.post("/admin/v1/images/s3", form_data, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((res) => {
      setImageKey((prev) => ({
        // ...prev,
        [type]: res.data.key,
      }));
      const url2 = res.data.url;
      axiosAws.put(url2, value, {
        headers: {
          "x-amz-acl": "public-read-write",
          "Content-Type": imageType,
        },
        onUploadProgress: (event) => uploadConfig(event, type),
      }).then((res) => {
        setCallHandleSave(1);
      }).catch((err) => {
        setLoading(prev => ({
          ...prev,
          disabled: false,
        }));
        handleUploadError(type, err);
      });
    }).catch((err) => {
      setLoading(prev => ({
        ...prev,
        disabled: false,
      }));
      handleUploadError(type, err);
    });
  };

  const handleDelete = () => {
    const {photoId, type} = deletePhoto;
    setDeleteLoading(prev => ({...prev, [type]: true}));
    axios.delete(`admin/v2/users/case-record-screening-photos/delete/${photoId}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((res) => {
      if (res.data.success) {
        handleDataFetched();
      }
    }).catch((err) => {
      setErrorMsg("Delete failed. Please try again.");
      console.log(err);
    }).finally(() => setDeleteLoading(prev => ({...prev, [type]: false})));
  }

  const handleSave = () => {
    if(imageKey){
      setLoading(prev => ({
        ...prev,
        success: false,
      }));
      setErrorMsg("");
      const form_data = new FormData();
      imageKey?.front_facing_big_smile && form_data.append("front_facing_big_smile", imageKey.front_facing_big_smile);
      imageKey?.front_facing_big_smile_na !== undefined && form_data.append("front_facing_big_smile_na", imageKey.front_facing_big_smile_na);
      imageKey?.front_facing_no_smile && form_data.append("front_facing_no_smile", imageKey.front_facing_no_smile);
      imageKey?.front_facing_no_smile_na !== undefined && form_data.append("front_facing_no_smile_na", imageKey.front_facing_no_smile_na);
      imageKey?.right_side_profile_no_smile && form_data.append("right_side_profile_no_smile", imageKey.right_side_profile_no_smile);
      imageKey?.right_side_profile_no_smile_na !== undefined && form_data.append("right_side_profile_no_smile_na", imageKey.right_side_profile_no_smile_na);
      imageKey?.center_bite && form_data.append("center_bite", imageKey.center_bite);
      imageKey?.right_bite && form_data.append("right_bite", imageKey.right_bite);
      imageKey?.left_bite && form_data.append("left_bite", imageKey.left_bite);
      imageKey?.upper_teeth && form_data.append("upper_teeth", imageKey.upper_teeth);
      imageKey?.lower_teeth && form_data.append("lower_teeth", imageKey.lower_teeth);
      form_data.append("user_scan_info_id", appointmentId);
      form_data.append("procedure", appointmentType?.procedure);
      axios.post(`admin/v2/users/${userId}/case-record-screening-photos`, form_data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }).then((res) => {
        handleDataFetched();
        if (res.data.success) {
          setLoading(prev => ({
            ...prev,
            success: false,
          }));
        }
      }).catch((err) => {
        setErrorMsg("Upload failed. Please try again.");
        console.log(err);
      }).finally(() => {
        setLoading(prev => ({
          ...prev,
          disabled: false,
        }));
      });
    }
  };

  const downloadScans = () => {
    setLoading(prev => ({
      ...prev,
      download: true,
    }));
    axios.get(`/admin/v2/users/${userId}/download-screening-photos/${photos?.id}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((result) => {
      var win = window.open(
        `${process.env.REACT_APP_BASE_URL}/storage${result.data.data}`,
        "_blank"
      );
      win.focus();
      if(!result.data.success){
        toast.error('No scans found to download', 3000);
      }
    }).catch((err) => {
      console.log('err', err);
    }).finally(f => {
      setLoading(prev => ({
        ...prev,
        download: false,
      }));
    });
  }

  const handleReviewStatus = async (status) => {
    if (status != ReviewStatus.approved && !screeningIssues.length && !reviewData.comment) {
      setReviewData(prev => ({
        ...prev,
        commentError: 'Please enter a comment',
      }));
    } else {
      let body = {
        review_status: status,
        procedure: appointmentType?.procedure,
        appointment_id: appointmentId,
      }
      if(status != ReviewStatus.approved){
        let comment = `
          Appointment ${appointmentNumber} - ${appointmentType?.procedure}<br/>
          Status: Photos ${status}
        `;
        if (options.length && !screeningIssues.length) {
          return;
        }
        if(screeningIssues.length) {
          comment += '<br/>Screening issues: ';
          comment += screeningIssues.map(issue => issue.label).join(', ');
        }
        if(imagesIssues.length) {
          comment += '<br/>Selected images: ';
          comment += imagesIssues.map(img => img.label).join(', ');
        }
        if(reviewData.comment) {
          comment += `<br/>Notes: ${reviewData.comment}`;
        }
        body = {
          ...body,
          comment,
          images: imageKeys,
          issues: screeningIssues.map(issue => issue.id),
        };
      }
      setReviewData(prev => ({
        ...prev,
        loading: true,
      }));

      try {
        const response = await axios.post(`/admin/v2/users/case-record-screening-photos/${photos?.id}/approval-status`, body, {
          headers: {
            Authorization: 'Bearer ' + Cookies.get('token'),
          },
        });
        if (response.data.success) {
          setPhotos(prev => ({
            ...prev,
            reviewer: response.data.data?.reviewer,
            review_status: response.data.data?.review_status,
            reviewed_at: response.data.data?.reviewed_at,
          }));
        }
      } catch (error) {
        console.log('error disapprove', error);
      } finally {
        setReviewData(prev => ({
          ...prev,
          loading: false,
          modalOpened: false,
        }));
      }
    }
  };

  const handleChangeComment = (newValue) => {
    setReviewData(prev => ({
      ...prev,
      comment: newValue,
    }));
  }

  const handleChangeModal = () => {
    setReviewData(prev => ({
      ...prev,
      modalOpened: false,
    }));
    setScreeningIssues([]);
    setImagesIssues([]);
  }

  const handleChangeReview = (status) => {
    setLoading(prev => ({
      ...prev,
      disableReviewBtn: status == ReviewStatus.approved,
    }));
    if(status == ReviewStatus.approved){
      handleReviewStatus(status);
    } else {
      setReviewData(prev => ({
        ...prev,
        modalOpened: true,
        status,
      }));
    }
  }

  const handleChangeNotApplicable = (e, prop) => {
    setImageKey({ [prop]: e ? 1 : 0 });
    setCallHandleSave(1);
  };

  const handleDataFetched = () => {
    setLoading(prev => ({
      ...prev,
      popupActive: true,
    }));
    axios.get(`admin/v2/users/case-record-screening-photos/${appointmentId}?procedure=${appointmentType?.procedure}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then(res => {
      if (res.data.success) {
        if(!res.data.data.review_status && loading.disableReviewBtn){
          setLoading(prev => ({
            ...prev,
            disableReviewBtn: false,
          }));
        }
        setPhotos(res.data.data);
        handleIconPhotosView(res.data.isAllProvided);
      } else {
        handleIconPhotosView(0);
      }
    }).catch(err => {
      console.log('fetch error', err);
    }).finally(f => {
      setLoading(prev => ({
        ...prev,
        popupActive: false,
      }));
    })
  };

  useEffect(() => {
    handleDataFetched();
  }, []);

  useEffect(() => {
    if (callHandleSave === 1) {
      handleSave();
      setCallHandleSave(0);
    }
  }, [callHandleSave]);

  useEffect(() => {
    scrollToExpandedTab(index, "photos");
  }, [openCloseTabs]);

  useEffect(() => {
    if(photos){
      let arr = [];
      if(photos.front_facing_big_smile_url && !arr.includes(photos.front_facing_big_smile_url)){
        arr.push({id: 'front_facing_big_smile', url: photos.front_facing_big_smile_url});
      }
      if(photos.front_facing_no_smile_url && !arr.includes(photos.front_facing_no_smile_url)){
        arr.push({id: 'front_facing_no_smile', url: photos.front_facing_no_smile_url});
      }
      if(photos.right_side_profile_no_smile_url && !arr.includes(photos.right_side_profile_no_smile_url)){
        arr.push({id: 'right_side_profile_no_smile', url: photos.right_side_profile_no_smile_url});
      }
      if(photos.center_bite_url && !arr.includes(photos.center_bite_url)){
        arr.push({id: 'center_bite', url: photos.center_bite_url});
      }
      if(photos.right_bite_url && !arr.includes(photos.right_bite_url)){
        arr.push({id: 'right_bite', url: photos.right_bite_url});
      }
      if(photos.left_bite_url && !arr.includes(photos.left_bite_url)){
        arr.push({id: 'left_bite', url: photos.left_bite_url});
      }
      if(photos.upper_teeth_url && !arr.includes(photos.upper_teeth_url)){
        arr.push({id: 'upper_teeth', url: photos.upper_teeth_url});
      }
      if(photos.lower_teeth_url && !arr.includes(photos.lower_teeth_url)){
        arr.push({id: 'lower_teeth', url: photos.lower_teeth_url});
      }

      setPhotosArray(arr);
    }
  }, [photos]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type="photos"
      headerTitle={<p><span className="procedure-header-title">Photos:</span> <span className='header-title-gray'>{appointmentType?.procedure}</span></p>}
      index={index}
      iconCheck={iconPhotos}
    >
      {openCloseTabs[index]?.photos && (
        <Box className={`${classes.photoWrapper} photos-${index}`}>
          {loading.popupActive ?
            <div style={{ textAlign: 'center' }}>
              <CircularProgress color='primary' />
            </div> :
            <>
              <ToastContainer />
              <Box className={classes.titleBox}>
                <Typography className={classes.titleStyle}>Screening Photos</Typography>
                <Button disabled={loading.download} onClick={downloadScans}>Download Photos</Button>
              </Box>
              <Box className={`${classes.photoBox} ${classes.photoWidthSM}`}>
                <ScreeningPhotoUpload
                  disabled={disable || loading.disabled}
                  value={photos?.front_facing_big_smile_url}
                  handleImages={handleImages}
                  id='front_facing_big_smile'
                  title='Front Facing - Big Smile'
                  uploadProgress={uploadProgress?.front_facing_big_smile}
                  checked={imageKey?.front_facing_big_smile_na || photos?.front_facing_big_smile_na}
                  handleChangeNotApplicable={handleChangeNotApplicable}
                  type='front_facing_big_smile_na'
                  handleDelete={() => handleClickOpen(photos?.front_facing_big_smile_id, 'frontFacingBigSmile')}
                  deleteDisabled={deleteLoading.frontFacingBigSmile || !photos?.front_facing_big_smile_id}
                  imagesArray={photosArray}
                  identifier={`screening-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={disable || loading.disabled}
                  value={photos?.front_facing_no_smile_url}
                  handleImages={handleImages}
                  id='front_facing_no_smile'
                  title='Front Facing - No Smile'
                  uploadProgress={uploadProgress?.front_facing_no_smile}
                  checked={imageKey?.front_facing_no_smile_na || photos?.front_facing_no_smile_na}
                  handleChangeNotApplicable={handleChangeNotApplicable}
                  type='front_facing_no_smile_na'
                  handleDelete={() => handleClickOpen(photos?.front_facing_no_smile_id, 'frontFacingNoSmile')}
                  deleteDisabled={deleteLoading.frontFacingNoSmile || !photos?.front_facing_no_smile_id}
                  imagesArray={photosArray}
                  identifier={`screening-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={disable || loading.disabled}
                  value={photos?.right_side_profile_no_smile_url}
                  handleImages={handleImages}
                  id='right_side_profile_no_smile'
                  title='Profile - No Smile'
                  uploadProgress={uploadProgress?.right_side_profile_no_smile}
                  checked={imageKey?.right_side_profile_no_smile_na || photos?.right_side_profile_no_smile_na}
                  handleChangeNotApplicable={handleChangeNotApplicable}
                  type='right_side_profile_no_smile_na'
                  handleDelete={() => handleClickOpen(photos?.right_side_profile_no_smile_id, 'rightSideProfileNoSmile')}
                  deleteDisabled={deleteLoading.rightSideProfileNoSmile || !photos?.right_side_profile_no_smile_id}
                  imagesArray={photosArray}
                  identifier={`screening-${appointmentType?.procedure}`}
                />
              </Box>
              <Box className={`${classes.photoBox} ${classes.photoWidth}`}>
                <ScreeningPhotoUpload
                  disabled={disable || loading.disabled}
                  value={photos?.center_bite_url}
                  handleImages={handleImages}
                  id='center_bite'
                  title='Center Bite'
                  uploadProgress={uploadProgress?.center_bite}
                  handleDelete={() => handleClickOpen(photos?.center_bite_id, 'centerBite')}
                  deleteDisabled={deleteLoading.centerBite || !photos?.center_bite_id}
                  imagesArray={photosArray}
                  identifier={`screening-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={disable || loading.disabled}
                  value={photos?.right_bite_url}
                  handleImages={handleImages}
                  id='right_bite'
                  title='Right Bite'
                  uploadProgress={uploadProgress?.right_bite}
                  handleDelete={() => handleClickOpen(photos?.right_bite_id, 'rightBite')}
                  deleteDisabled={deleteLoading.rightBite || !photos?.right_bite_id}
                  imagesArray={photosArray}
                  identifier={`screening-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={disable || loading.disabled}
                  value={photos?.left_bite_url}
                  handleImages={handleImages}
                  id='left_bite'
                  title='Left Bite'
                  uploadProgress={uploadProgress?.left_bite}
                  handleDelete={() => handleClickOpen(photos?.left_bite_id, 'leftBite')}
                  deleteDisabled={deleteLoading.leftBite || !photos?.left_bite_id}
                  imagesArray={photosArray}
                  identifier={`screening-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={disable || loading.disabled}
                  value={photos?.upper_teeth_url}
                  handleImages={handleImages}
                  id='upper_teeth'
                  title='Upper Occlusal'
                  uploadProgress={uploadProgress?.upper_teeth}
                  handleDelete={() => handleClickOpen(photos?.upper_teeth_id, 'upperTeeth')}
                  deleteDisabled={deleteLoading.upperTeeth || !photos?.upper_teeth_id}
                  imagesArray={photosArray}
                  identifier={`screening-${appointmentType?.procedure}`}
                />
                <ScreeningPhotoUpload
                  disabled={disable || loading.disabled}
                  value={photos?.lower_teeth_url}
                  handleImages={handleImages}
                  id='lower_teeth'
                  title='Lower Occlusal'
                  uploadProgress={uploadProgress?.lower_teeth}
                  handleDelete={() => handleClickOpen(photos?.lower_teeth_id, 'lowerTeeth')}
                  deleteDisabled={deleteLoading.lowerTeeth || !photos?.lower_teeth_id}
                  imagesArray={photosArray}
                  identifier={`screening-${appointmentType?.procedure}`}
                />
              </Box>
              <ApprovalActions
                disabled={iconPhotos == 0 || loading.disableReviewBtn || photos?.review_status != null}
                reviewStatus={photos?.review_status}
                reviewer={photos?.reviewer}
                reviewedAt={photos?.reviewed_at}
                handleChangeReview={handleChangeReview}
                withApprovedWithIssuesOption={true}
              />
              <Save uploadedBy={iconPhotos != 0 ? photos?.uploaded_by_user : ''} handleSave={handleSave} success={loading.success} errorMessage={errorMsg} />
            </>
          }
        </Box>
      )}
      {reviewData.modalOpened && (
        <DisapprovalModal
          activeModal={reviewData.modalOpened}
          handleChangeModal={handleChangeModal}
          title={reviewData.status === ReviewStatus.approvedWithIssues ? 'Approve With Issues' : 'Disapprove Screening Photos'}
          dropDownTitle='Screening Photos Issues'
          handleSubmitDisapprove={() => handleReviewStatus(reviewData.status)}
          loading={reviewData.loading}
          commentValue={reviewData.comment}
          handleChangeComment={handleChangeComment}
          commentError={reviewData.commentError}
          setFilesImage={setFileImages}
          filesImage={fileImages}
          images={images}
          setImages={setImages}
          imagesKey={imageKeys}
          setImageKey={setImageKeys}
          issueType={reviewData.status == ReviewStatus.approvedWithIssues ? IssueTypes.screeningApprovedWithIssues : IssueTypes.screeningNotApproved}
          setIssues={setScreeningIssues}
          issues={screeningIssues}
          showImagesDropdown
          setImagesIssues={setImagesIssues}
          options={options}
          setOptions={setOptions}
        />
      )}
      <AlertDeleteDialog open={open} setOpen={setOpen} handleAction={handleDelete} />
    </ProcedureHeader>
  );
}
  