import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  tableAddRow: {
    marginBottom: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& button': {
      fontSize: '1rem',
    },
  },
}));
