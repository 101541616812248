import { Button, Card, DisplayText, TextField, TextStyle } from "@shopify/polaris";
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import Select from "react-dropdown-select";
import "./Procedure.css";
import { Select as Select2, MenuItem } from "@material-ui/core";
import AlertDeleteDialog from "../../../components/Popup/alert-delete-dialog.component";
import { UserContext } from '../../../UserContext';

function Procedure({
  value,
  setValue,
  setProcedures,
  procedures,
  beforeStepValueArray,
  handleChangeBeforeStepNumberArray,
  optionsProcedures,
  appointmentsOptions,
  optionsProceduresHashMap,
  handleCheckClinic,
  handleUpdate,
  handleSave,
  handleRemoveStep,
  handleDeleteStep,
  firstAppointmentNumber,
  createMode,
  disabled,
}) {
  const [disableSelect, setDisableSelect] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const { bookAScanInfo } = useContext(UserContext);
  const isDoctorMedicalProvider = !!bookAScanInfo?.doctor_id;
  const [procedureInfo, setProcedureInfo] = useState({
    procedure: null,
    id: null,
  });
  const emtyState = Array.isArray(beforeStepValueArray) && beforeStepValueArray.length === 0 ? (
    <div className="message-no-result">
      <DisplayText size="small">No procedures found</DisplayText>
    </div>
  ) : null;
  const dropOpen = (i) => {
    const selects = document.querySelectorAll(".plan-select");
    let select = selects[i];
    let selectDiv = select.querySelector("div");
    let table = document.querySelector(".plan-table");
    let tableRect = table.getBoundingClientRect();
    const tdRect = selectDiv.getBoundingClientRect();
    const tdLeft = tdRect.left;
    let list = select.querySelector("div div .react-dropdown-select-dropdown");
    if (list) {
      list.style.top = `${tdRect.y - tableRect.y + tdRect.height}px`;
      list.style.left = `${tdLeft - tableRect.left}px`;
    }
  };
  const handleChange = (newValue, idx) => {
    if (beforeStepValueArray[idx].step) {
      // we are updating saved data
      let objtValue = beforeStepValueArray[idx];
      Object.keys(optionsProceduresHashMap).forEach((prop) => {
        let index = newValue.findIndex((val) => val.value == prop);
        objtValue[prop] = index >= 0 ? 1 : 0;
      });
      beforeStepValueArray[idx] = objtValue;
      handleChangeBeforeStepNumberArray([...beforeStepValueArray]);
    } else {
      // we are updating new unsaved data
      let objtValue = procedures;
      Object.keys(optionsProceduresHashMap).forEach((prop) => {
        let index = newValue.findIndex((val) => val.value == prop);
        objtValue[prop] = index >= 0 ? 1 : 0;
      });
      setProcedures({ ...objtValue });
    }
  };
  const selectPositionChangeHandler = (event, idx) => {
    const { value } = event.target;
    handleChangeBeforeStepNumberArray((prev) => {
      const nprev = [...prev];
      if (nprev[idx].id) {
        nprev[idx].position = value;
      } else {
        nprev[idx] = value;
      }
      return nprev;
    });
  };

  const selectStepsChangeHandler = (event, idx) => {
    const val = event.target.value === "S1" ? 0 : event.target.value;
    handleChangeBeforeStepNumberArray((prev) => {
      const nprev = [...prev];
      if (nprev[idx].id) {
        nprev[idx].step = val;
      } else {
        nprev[idx] = val;
      }
      return nprev;
    });
  };

  const validateProcedures = (procedure, idx) => {
    setProcedureInfo({
      procedure,
      id: idx,
    });
    if(procedures.replace_powerchain == 1 || procedure.replace_powerchain == 1) {
      setIsOpen(true);
    } else {
      addUpdateProcedure(procedure, idx);
    }
  }

  const addUpdateProcedure = (procedure = procedureInfo.procedure, idx = procedureInfo.id, autoRP = false) => {
    procedure.step !== undefined && procedure.step != null ?
    handleUpdate( procedure.id, procedure.step, procedure, idx, autoRP) :
    handleSave(procedure, idx, autoRP);
    setIsOpen(false);
  }

  useEffect(() => {
    if (beforeStepValueArray) {
      let newDisableSelect = [];
      let comments = {};
      for (let i = 0; i < beforeStepValueArray.length; i++) {
        newDisableSelect.push({
          idx: i,
          step: beforeStepValueArray[i].step,
        });
        if (
          beforeStepValueArray[i].descriptions &&
          beforeStepValueArray[i].descriptions.length > 0
        ) {
          {
            for (let j = 0; j < beforeStepValueArray[i].descriptions.length; j++) {
              if(beforeStepValueArray[i].descriptions[j].type === "Dental Checkup") {
                comments = {
                  ...comments,
                  [i]: {
                    ...comments[i],
                    'dental_checkup': beforeStepValueArray[i].descriptions[j].comment,
                  }
                };
              }
              if(beforeStepValueArray[i].descriptions[j].type === "Extraction") {
                comments = {
                  ...comments,
                  [i]: {
                    ...comments[i],
                    'extraction': beforeStepValueArray[i].descriptions[j].comment,
                  }
                };
              }
            }
          }
        }
        setDisableSelect(newDisableSelect);

        setValue(comments);
      }
    }
  }, []);

  //on save, check if last index is new (procedures) and handle it. index last and if not object
  const handleCommentChange = useCallback((e, idx, field) => {
    setValue((prev) => ({
      ...prev,
      [idx]: {
        ...(prev && prev[idx]),
        [field]: e,
      }
    }));
  }, []);
  return (
    createMode && !beforeStepValueArray.length ? null :
      emtyState ? (
        !createMode ? emtyState : null
      ) :
        <Card sectioned>
          <div style={{ position: "relative" }}>
            <table
              className="plan-table"
              style={{
                display: "grid",
                width: "100%",
                overflowX: "auto",
                overflowY: "hidden",
                padding: "1em 0",
              }}
            >
              <thead>
                <tr style={{ display: "flex", gap: "10px", borderBottom: '1px solid', paddingBottom: 5 }}>
                  <th className="plan-header">
                    <TextStyle variation="strong"></TextStyle>
                  </th>
                  <th className="plan-header">
                    <TextStyle variation="strong">Before Step</TextStyle>
                  </th>
                  <th className="plan-header xl">
                    <TextStyle variation="strong">Procedures Type</TextStyle>
                  </th>
                  <th className="plan-header button">
                    <TextStyle variation="strong"></TextStyle>
                  </th>
                  <th className="plan-header">
                    <TextStyle variation="strong">Completed</TextStyle>
                  </th>
                  <th className="plan-header button">
                    <TextStyle variation="strong"></TextStyle>
                  </th>
                  <th className="plan-header button">
                    <TextStyle variation="strong"></TextStyle>
                  </th>
                </tr>
              </thead>
              <tbody>
                {beforeStepValueArray.map((singleStepValue, idx) => {
                  let hasDentalCheckup = false;
                  let hasExtraction = false;
                  if (
                    idx == beforeStepValueArray.length - 1 &&
                    typeof singleStepValue != "object"
                  ) {
                    hasDentalCheckup = procedures.dental_checkup ? true : false;
                    hasExtraction = procedures.extraction ? true : false;
                  } else {
                    hasDentalCheckup = beforeStepValueArray[idx].dental_checkup
                      ? true
                      : false;
                    hasExtraction = beforeStepValueArray[idx].extraction
                      ? true
                      : false;
                  }
                  const canAddPosition = disableSelect.some(
                    (obj) =>
                      obj.idx === idx && obj.step === beforeStepValueArray[idx].step
                  );
                  return (
                    <Fragment key={idx}>
                      <tr style={{ display: "flex", padding: "1em 0", gap: "10px" }}>
                        <td className="plan-column">
                          <div style={{ fontSize: 12, fontWeight: 500, margin: 8 }}>
                            Appt #{firstAppointmentNumber + idx}
                          </div>
                        </td>
                        <td className="plan-column">
                          <Select2
                            style={{ maxWidth: "100%" }}
                            className="plan-field"
                            onChange={(event) =>
                              selectStepsChangeHandler(event, idx)
                            }
                            value={
                              beforeStepValueArray[idx].step == "0"
                                ? "S1"
                                : beforeStepValueArray[idx].step
                            }
                          >
                            {appointmentsOptions.map(
                              (appointmentsOption, key) => {
                                return (
                                  <MenuItem
                                    key={key}
                                    value={appointmentsOption.value}
                                  >
                                    {appointmentsOption.value}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select2>
                        </td>
                        {beforeStepValueArray[idx].position &&
                          beforeStepValueArray[idx].positions?.length > 1 &&
                          canAddPosition && (
                            <td className="plan-column">
                              <Select2
                                style={{ maxWidth: "100%" }}
                                className="plan-field"
                                onChange={(event) =>
                                  selectPositionChangeHandler(event, idx)
                                }
                                value={beforeStepValueArray[idx].position}
                              >
                                {beforeStepValueArray[idx].positions.map(
                                  (value, key) => {
                                    return (
                                      <MenuItem key={key} value={value}>
                                        {value}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Select2>
                            </td>
                          )}
                        <td className="plan-column plan-select xl">
                          <Select
                            onDropdownOpen={() => {
                              dropOpen(idx);
                            }}
                            placeholder="Select procedures"
                            multi
                            options={optionsProcedures}
                            onChange={(e) => {
                              handleChange(e, idx);
                            }}
                            values={Object.keys(beforeStepValueArray[idx])
                              .filter(
                                (key) =>
                                  beforeStepValueArray[idx][key] == 1 &&
                                  optionsProceduresHashMap[key] !== undefined
                              )
                              .map((key) => ({
                                label: optionsProceduresHashMap[key],
                                value: key,
                              }))}
                          />
                        </td>
                        <td className="plan-column">
                          <div style={{ textAlign: "center" }}>
                            {beforeStepValueArray[idx].id ? (
                              <input
                                type="checkbox"
                                disabled
                                checked={beforeStepValueArray[idx].completed}
                                onChange={(e) => {
                                  let val = e.target.checked;
                                  handleChangeBeforeStepNumberArray((prev) => {
                                    const nprev = [...prev];
                                    if (nprev[idx].id) {
                                      nprev[idx].completed = val;
                                    }
                                    return nprev;
                                  });
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </td>
                        {!isDoctorMedicalProvider && (
                          <td className="plan-column button">
                            <div>
                              <button
                                style={{
                                  padding: "1rem 0.5rem",
                                  width: "70px",
                                  backgroundColor: "rgba(241, 242, 243, 1)",
                                  color: "rgba(68, 71, 74, 1)",
                                  fontWeight: "600",
                                  borderRadius: "3px",
                                  border: "none transparent",
                                }}
                                onClick={() => {
                                  handleCheckClinic(
                                    beforeStepValueArray[idx],
                                    idx,
                                  );
                                }}
                              >
                                Assign
                              </button>
                            </div>
                          </td>
                        )}
                        <td className="plan-column button">
                          <div>
                            <Button
                              disabled={disabled || beforeStepValueArray[idx].completed === 1}
                              primary
                              onClick={() => {
                                validateProcedures(beforeStepValueArray[idx], idx);
                              }}
                            >
                              Save
                            </Button>
                          </div>
                        </td>
                        <td className="plan-column button">
                          <div>
                            <button
                              style={{
                                padding: "1rem",
                                width: "40px",
                                backgroundColor: "red",
                                color: "#fff",
                                fontWeight: "500",
                                borderRadius: "3px",
                                border: "none transparent",
                              }}
                              onClick={() => {
                                handleRemoveStep(beforeStepValueArray[idx]);
                                handleDeleteStep(
                                  beforeStepValueArray[idx].id,
                                  beforeStepValueArray[idx].step,
                                  beforeStepValueArray[idx]
                                );
                              }}
                            >
                              -
                            </button>
                          </div>
                        </td>
                      </tr>
                      {hasDentalCheckup && (
                        <div id="dental-checkup-parent" >
                          <TextField
                            label="Dental Checkup comment"
                            value={(value && value[idx]?.dental_checkup) ? value[idx]?.dental_checkup : ''}
                            onChange={(e) => {
                              handleCommentChange(e, idx, 'dental_checkup');
                            }}
                            multiline={2}
                            autoComplete="off"
                          />
                        </div>
                      )}
                      {hasExtraction && (
                        <div id="extraction-parent" >
                          <TextField
                            label="Extraction comment"
                            value={(value && value[idx]?.extraction) ? value[idx]?.extraction : ''}
                            onChange={(e) => {
                              handleCommentChange(e, idx, 'extraction');
                            }}
                            multiline={2}
                            autoComplete="off"
                          />
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
          <AlertDeleteDialog
            open={isOpen}
            setOpen={setIsOpen}
            handleAction={() => {
              addUpdateProcedure(undefined, undefined, true);
            }}
            handleCancel={() => {
              addUpdateProcedure(undefined, undefined, false);
            }}
            message={<span>You've just added a Replace Powerchain appointment for this patient.<br />Do you want to automatically add the rest of the Replace Powerchain procedures every 4 weeks (2 steps)?</span>}
          />
        </Card>
  );
}

export default Procedure;
