import React from 'react'
import basmaLogo from "../../../img/doctorsLogo.png";
import eye from "../../../img/eye.png";
import warning from "../../../img/warning.png";
import redo from "../../../img/redo.png";
import phones from "../../../img/phone-2.png";
import googleStoreEn from "../../../img/google-play-en.png";
import appStoreEn from "../../../img/app-store-en.png";
import certificate from "../../../img/certificate.png";
import card from "../../../img/card.png";
import phone2 from "../../../img/2-phone.png";
import smile from "../../../img/smile.png";
import wtsp from "../../../img/wtsp.png";
import email from "../../../img/email.png";
import phone from "../../../img/phone.png";
import im3 from "../../../img/im3.svg";
import footer from "../../../img/tp-pdf-footer-en.png";
import CheckIcon from '@mui/icons-material/Check';

const TreatmentPDFEn = ({
    user,
    selectedCaseTypes,
    procedures,
    id,
    cases,
    valueTreatmentDescription,
    valueLengthOfTreatment,
    selectedAlignerWearTime,
    valueNumberAlignersUpper,
    valueNumberAlignersLower,
    createdAt,
    name,
    checkedIsTeethImageVisible,
    type,
    beforeStepValueArray,
    firstAppointmentNumber,
    comments,
    optionsProceduresHashMap,
    checkedRequireRedo,
}) => {

    return (
        <>
            <div className="tp-pdf-sub-container tp-pdf-blue-container">
                <div className="tp-pdf-blue-container-1">
                    <h1 className="tp-pdf-blue-container-h1">Hello, {user}!</h1>
                    <p className="tp-pdf-blue-container-p">We can't wait to get you that new smile!</p>
                </div>
                <div className="tp-pdf-blue-container-2">
                    <img src={basmaLogo} alt='Logo' width='150' height='62' />
                </div>
            </div>
            <div className="pdf-generated-container">
                <p className="pdf-generated-p"><span className="pdf-generated-txt">Generated:</span> {createdAt}</p>
            </div>
            <hr className="pdf-hr" />
            <div className="tp-pdf-sub-container">
                <div>
                    <p className="tp-pdf-question-title">What's in your Treatment Plan</p>
                    <ul className="tp-pdf-question-points">
                        <li>Treatment summary</li>
                        <li>Required in-clinic visits</li>
                        <li>What do my procedures mean?</li>
                        <li>Remote progress checkups</li>
                        <li>Cases we've treated</li>
                    </ul>
                </div>
                {checkedRequireRedo ?
                    <div className="tp-pdf-redo-scan">
                        <p>Your case requires a redo scan before starting the treatment.</p>
                        <img className="tp-pdf-redo-scan-img" src={redo} alt='Redo Scan' />
                    </div> :
                    null
                }
            </div>
            <div className="tp-pdf-sub-container">
                <div className="tp-pdf-time-container">
                    <div className="tp-pdf-time-title-container">
                        <p className="tp-pdf-time-title">Treatment Time</p>
                    </div>
                    <p className="tp-pdf-time-count">{valueLengthOfTreatment}</p>
                    <p className="tp-pdf-time-months">{valueLengthOfTreatment == 1 ? 'month' : 'months'}</p>
                    <p className="tp-pdf-time-desc">20 - 22 hours per day</p>
                    <p className="tp-pdf-time-desc">{selectedAlignerWearTime} wear time per aligner</p>
                </div>
                <div className="tp-pdf-time-container">
                    <div className="tp-pdf-time-title-container">
                        <p className="tp-pdf-time-title">Aligners Needed</p>
                    </div>
                    <p className="tp-pdf-time-count">{Math.max(valueNumberAlignersUpper, valueNumberAlignersLower)}</p>
                    <p className="tp-pdf-time-months">steps</p>
                    <p className="tp-pdf-time-desc">{valueNumberAlignersUpper} top aligners</p>
                    <p className="tp-pdf-time-desc">{valueNumberAlignersLower} bottom aligners</p>
                </div>
            </div>
            <div className="tp-pdf-sub-container-2">
                <p className="tp-pdf-desc-title"><span className="tp-pdf-desc-span">Treatment Type: </span>{name ? name : (type ? (type == 'tp' ? 'Initial Treatment' : (type == 'rtp' ? 'Refinement Treatment' : 'MCA Treatment')) : '')}</p>
                <p className="tp-pdf-desc-title"><span className="tp-pdf-desc-span">Case: </span>{selectedCaseTypes.map(c => c.label).join(', ')}</p>
                <div>
                    <p className="tp-pdf-desc-title"><span className="tp-pdf-desc-span">Remote Photo Checkups: </span>{valueLengthOfTreatment * 2}</p>
                    <p className="tp-pdf-details-p">More details below</p>
                </div>
                {beforeStepValueArray?.length ? <div>
                    <p className="tp-pdf-desc-title"><span className="tp-pdf-desc-span">In-clinic Visits: </span>{beforeStepValueArray.length}</p>
                    <p className="tp-pdf-details-p">More details below</p>
                </div> : ''}
                <hr className="pdf-hr" />
            </div>
            <div className="tp-pdf-sub-container-3">
                {valueTreatmentDescription ? <div className="tp-pdf-desc-paragraph">{valueTreatmentDescription}</div> : null}
                {checkedIsTeethImageVisible && (
                    <div className="tp-pdf-teeth-image-container">
                        <img className="tp-pdf-teeth-image" src={im3} />
                    </div>
                )}
                <div className="tp-pdf-desc-container">
                    <div className="tp-pdf-desc-sub-container">
                        <img src={eye} className="tp-pdf-desc-img" width="48" height="48" />
                        <hr className="tp-pdf-desc-hr" />
                        <div className='tp-pdf-desc-text-container'>
                            <p className="tp-pdf-desc-p">Please review your 3D simulation to see how your teeth will look like at the end of your treatment.</p>
                        </div>
                    </div>
                    <div className="tp-pdf-desc-red-sub-container">
                        <img src={warning} className="tp-pdf-desc-img" />
                        <hr className="tp-pdf-desc-red-hr" />
                        <div className='tp-pdf-desc-text-container'>
                            <p className="tp-pdf-desc-p">Not wearing your aligners as indicated will delay your treatment around 2 - 3 months or more.</p>
                        </div>
                    </div>
                </div>
            </div>
            {beforeStepValueArray?.length ?
                <div>
                    <div className="tp-pdf-procedures-table">
                        <h1 className="tp-pdf-table-title">Your required in-clinic visits</h1>
                        <p className="tp-pdf-table-desc">As part of your treatment, you will need to visit the clinic for the following procedure(s):</p>
                        <table className="tp-pdf-table" >
                            <tbody>
                                <tr className="tp-pdf-table-tr">
                                    <th className="tp-pdf-table-th">Appointment</th>
                                    <th className="tp-pdf-table-th">Type</th>
                                    <th className="tp-pdf-table-th">Before step</th>
                                </tr>
                                {
                                    firstAppointmentNumber == 2 && <tr className="tp-pdf-table-tr-data">
                                        <td className="tp-pdf-table-td tp-pdf-table-td-center">
                                            #1
                                        </td>
                                        <td className="tp-pdf-table-td tp-pdf-table-td-center">First Visit</td>
                                        <td className="tp-pdf-table-td tp-pdf-table-td-center">
                                            <CheckIcon style={{ fontSize: '25px' }} />
                                        </td>
                                    </tr>
                                }
                                {beforeStepValueArray.map((singleStepValue, idx) => {
                                    return (
                                        <tr className="tp-pdf-table-tr-data" key={idx}>
                                            <td className="tp-pdf-table-td tp-pdf-table-td-center">
                                                #{firstAppointmentNumber + idx}
                                            </td>
                                            <td className="tp-pdf-table-td tp-pdf-table-td-center">
                                                {
                                                    Object.keys(singleStepValue).filter(
                                                        (key) =>
                                                            singleStepValue[key] == 1 &&
                                                            optionsProceduresHashMap[key] !== undefined
                                                    )
                                                    .map((key) => (optionsProceduresHashMap[key])).join(', ')
                                                }
                                            </td>
                                            <td className="tp-pdf-table-td tp-pdf-table-td-center">
                                                {singleStepValue.before_step}
                                                {singleStepValue.position && singleStepValue.positions?.length > 1 && singleStepValue.position}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        {comments.dentalWork && <p className="tp-pdf-table-end-p">Your dental work includes: {comments.dentalWork}</p>}
                        {comments.extraction && <p className="tp-pdf-table-end-p">Your extraction: {comments.extraction}</p>}
                        <p className="tp-pdf-table-end-p2">One of our agents will reach out to you to remind you about your appointments.</p>
                    </div>
                    {
                        procedures.length ?
                        <>
                            <div className="tp-pdf-bg-container" />
                            <div className="tp-pdf-clip-path-container">
                                <div className="tp-pdf-inner-clip-path">
                                    {
                                        procedures.map((proc, key) => {
                                            return (
                                                <div key={key} className="tp-pdf-single-procedure">
                                                    <img src={proc.icon} className="tp-pdf-single-procedure-icon" />
                                                    <div className="tp-pdf-single-procedure-container">
                                                        <p className="tp-pdf-single-procedure-title">{proc.label}</p>
                                                        <p className="tp-pdf-single-procedure-desc">{proc.description}</p>
                                                        {proc.img && <img src={proc.img} className="tp-pdf-single-procedure-img" />}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </> :
                        null
                    }
                </div> :
                ''
            }
            <div className="tp-pdf-sub-remote-container">
                <img src={phones} className="tp-pdf-remote-img" />
                <div className="tp-pdf-remote-container">
                    <h1 className="tp-pdf-remote-h1">Remote progress checkups</h1>
                    <p className="tp-pdf-remote-p">On every step throughout your aligner treatment, you'll need to upload progress photos for the orthodontists to reveiw. This is to ensure that your treatment is going as planned. Download the app to track your progress, get notifications, and chat with our support at any time.</p>
                    <div className="tp-pdf-remote-img-container">
                        <a href="https://play.google.com/store/apps/details?id=com.basmapatientapp" target="blank"><img src={googleStoreEn} className="google-play" /></a>
                        <a href="https://apps.apple.com/app/basma-com/id1643932915" target="blank"><img src={appStoreEn} className="app-store" /></a>
                    </div>
                </div>
            </div>
            <div className="tp-pdf-sub-remote-container">
                <img src={certificate} className="tp-pdf-certificate-img" />
                <div className="tp-pdf-certificate-container">
                    <h1 className="tp-pdf-certificate-h1">Smile Guarantee</h1>
                    <hr className="tp-pdf-certificate-hr" />
                    <p className="tp-pdf-certificate-p">Some cases may require a minor refinement at the end, which would involve a few extra aligner steps. If you have been wearing your aligners as prescribed and uploading progress photos at every step, and we believe that your case requires refinement, we will offer you free additional refinement aligners (that are extra steps on top of your treatment plan) to get you the result we promised.</p>
                </div>
            </div>
            {/* <div className="tp-pdf-bg-container tp-pdf-case-bg-container" />
            <div className="tp-pdf-clip-path-container tp-pdf-cases-container">
                {
                    cases.map((c, key) => {
                        return (
                            <div key={key} className="tp-pdf-case-container">
                                <div className="tp-pdf-case-sub-container">
                                    <p className="tp-pdf-case-p">{c.label}</p>
                                    <hr className="tp-pdf-case-hr" />
                                </div>
                                <div className="tp-pdf-case-imgs-container">
                                    {
                                        c.images.map((img, key) => {
                                            return (
                                                <div key={key} className="tp-pdf-case-img-container">
                                                    <img src={img.img} className="tp-pdf-case-img" />
                                                    <p className="tp-pdf-case-img-p">{img.titleEn}</p>
                                                    <p className="tp-pdf-case-img-p">{img.descriptionEn}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div> */}
            <p className="tp-pdf-next-steps-title">Next steps</p>
            <div className="tp-pdf-sub-container tp-pdf-next-steps-container">
                <ol className="tp-pdf-next-steps-ol">
                    <p><img src={card} className="tp-pdf-next-steps-img" /><li>Pick the payment method that suits you best!</li></p>
                    <p className='tp-pdf-next-steps-p'><img src={phone2} className="tp-pdf-next-steps-img" /><li>Order your aligner treatment.</li></p>
                    <p><img src={smile} className="tp-pdf-next-steps-img" /><li>Get started on your journey to a brand-new smile!</li></p>
                </ol>
            </div>
            <div className="tp-pdf-questions-container">
                <p className="tp-pdf-questions-title">Questions?</p>
                <p className="tp-pdf-questions-desc">Our team is always ready to help!</p>
            </div>
            <div className="tp-pdf-social-media-container">
                <a href="mailto: care@basma.com" className="tp-pdf-social-media-txt"><img src={email} className="tp-pdf-social-media-img" />care@basma.com</a>
                <a href='https://wa.me/971545755078' rel="no follow" target='_blank' className="tp-pdf-social-media-txt">+971 54 575 5078<img src={wtsp} className="tp-pdf-social-media-img" /></a>
            </div>
            <div className="tp-pdf-footer-bg-container tp-pdf-bg-container">
                <p className="tp-pdf-social-media-txt"><img src={phone} className="tp-pdf-phone-img" /></p>
            </div>
            <div className="tp-pdf-footer-container">
                <div className="tp-pdf-footer-sub-container">
                    <span className="tp-pdf-footer-phone"><span className="tp-pdf-footer-phone-title">UAE: </span><a className='tp-pdf-tel' href="tel:+97142789914">+971 4 278 9914</a></span>
                    <span className="tp-pdf-footer-phone"><span className="tp-pdf-footer-phone-title">KSA: </span><a className='tp-pdf-tel' href="tel:+966118343423">+966 11 834 3423</a></span>
                    <span className="tp-pdf-footer-phone"><span className="tp-pdf-footer-phone-title">KWT: </span><a className='tp-pdf-tel' href="tel:+96522052637">+965 2205 2637</a></span>
                    <span className="tp-pdf-footer-phone"><span className="tp-pdf-footer-phone-title">QTR: </span><a className='tp-pdf-tel' href="tel:+97142789914">+971 4 278 9914</a></span>
                    <span className="tp-pdf-footer-phone"><span className="tp-pdf-footer-phone-title">BAH: </span><a className='tp-pdf-tel' href="tel:+97316639174">+973 1663 9174</a></span>
                    <span className="tp-pdf-footer-phone"><span className="tp-pdf-footer-phone-title">OMN: </span><a className='tp-pdf-tel' href="tel:+96824442694">+968 24 442694</a></span>
                    <span className="tp-pdf-footer-phone"><span className="tp-pdf-footer-phone-title">LEB: </span><a className='tp-pdf-tel' href="tel:+96181144653">+961 81 144 653</a></span>
                </div>
                <img src={footer} className="tp-pdf-footer-img" />
                <p className="tp-pdf-footer-id">{id}</p>
            </div>
        </>
    )
}

export default TreatmentPDFEn