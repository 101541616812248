import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    notificationIcon: {
        transform: "scale(2.5)",
        color: "rgb(255 255 255)",
    },
    notificationBadge: {
        "& .MuiBadge-badge":{
            transform: "scale(1) translate(75%, -60%)",
        }
    }
}));