import { Card, Layout } from '@shopify/polaris';
import axios from '../axios';
import Cookie from 'js-cookie';
import { useState } from 'react';
import { capitalizeWords } from '../util/helpers';
import AddTechnician from './add-technician.component';
import TechnicianTable from './technician-table.component';

const Technician = ({ type }) => {
  const [technicians, setTechnicians] = useState([]);
  const [technician, setTechnician] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const perPage = 10;

  const getTechnicians = async (signal) => {
    try {
      const response = await axios.get(
        `admin/v1/users/technicians?type=${type}&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get('token')}`,
          },
          signal,
        }
      );
      if (response.status === 200) {
        const { data, current_page, total } = response.data.data;
        if (data.length > 0) {
          setPage(current_page);
          setTotalPages(Math.ceil(total / perPage));
          const technicians = data.map((technician) => [
            technician.id,
            capitalizeWords(technician.full_name),
          ]);
          setTechnicians(technicians);
        }
      }
    } catch (error) {
      console.log('Error in technicians api', error);
    }
  };

  const addTechnician = async () => {
    if (technician) {
      try {
        const response = await axios.post(
          `admin/v1/users/technician`,
          {
            full_name: technician.trim(),
            type,
          },
          {
            headers: {
              Authorization: `Bearer ${Cookie.get('token')}`,
            },
          }
        );
        if (response.status === 200) {
          const { data, current_page, total } = response.data.data;
          if (data.length > 0) {
            setPage(current_page);
            setTotalPages(Math.ceil(total / perPage));
            const technicians = data.map((technician) => [
              technician.id,
              capitalizeWords(technician.full_name),
            ]);
            setTechnicians(technicians);
            setTechnician('');
          }
        }
      } catch (error) {
        console.log('Error in technicians api', error);
      }
    }
  };

  return (
    <Layout.AnnotatedSection title={capitalizeWords(type) + 's'}>
      <Card sectioned>
        <AddTechnician
          type={type}
          addTechnician={addTechnician}
          technician={technician}
          setTechnician={setTechnician}
        />
      </Card>
      <TechnicianTable
        getTechnicians={getTechnicians}
        technicians={technicians}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
      />
    </Layout.AnnotatedSection>
  );
};

export default Technician;
