import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
      height: "100%",
      flexWrap: "unset",
      flexDirection: "column"
    },
    bodyRow: {
      height: "85%",
      background: "url('/BasmaChatBackground.png')",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPositionY: "bottom",
      // backgroundColor: "grey",
      // backgroundBlendMode: "color-burn"
    },
    messageContainer: {
      height: "100%",
      display: "flex",
      alignContent: "space-between",
      position: "relative"
  
    },
    // scrollToBottomBtn: {
    //   display: "none",
    //   position: "absolute",
    //   bottom: "20%",
    //   right: "5%",
    //   backgroundColor: "black",
    //   border: "1px solid #ccc",
    //   padding: "5px",
    //   boxShadow: "0px 0px 5px #ccc",
    //   height: "2em"
    // },
    loadingBox: {
      position: "relative",
      zIndex: "10",
      "&::before": {
        content: '""',
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        backgroundColor: "#a19a9a",
        opacity: "0.3",
        width: "100%",
        height:"90vh",
        zIndex: -1,
      }
    },
    loading: {
      position: "fixed",
      top: "50%",
      bottom: "50%",
      right: "50%",
      left: "50%",
      color: '#ff2c46'
    },
  
  }));