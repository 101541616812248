import React, { useState } from "react";
import {
  TextStyle,
  FormLayout,
  TextField,
  Select,
  Button,
  Checkbox,
} from "@shopify/polaris";
import Select2 from "react-dropdown-select";
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import axios from "../../../axios";
import RichTextEditor from "react-rte";
import CollapsibleSmilePlanCategories from "./SmilePlanCategoriesAndTemplates/collapsible-smile-plan-categories.component";
import Cookies from "js-cookie";

const TreatmentPlan = (props) => {
  const { setvalueTreatmentDescriptionEn, setvalueTreatmentDescriptionAr } = props;
  const createMode = window.location.pathname.includes('create');
  const operationsOptionsType = [
    { label: "light", value: "light" },
    { label: "medium", value: "medium" },
    { label: "medium+", value: "medium+" },
    { label: "complex", value: "complex"},
    { label: "complex+", value: "complex+"},
  ];
  const optionsType = [
    { label: "light", value: "light" },
    { label: "medium", value: "medium" },
    { label: "medium+", value: "medium+" },
    { label: "complex", value: "complex"},
    { label: "complex+", value: "complex+"},
  ];
  const optionsAlignerWearTime = [
    { value: "1-week", label: "1-week" },
    { value: "10-day", label: "10-day" },
    {
      value: "2-week",
      label: "2-week",
    },
  ];
  
  const caseTypesOptions = [
    {value: 'Spacing', label: 'Spacing'},
    {value: 'Crowding', label: 'Crowding'},
    {value: 'Protrusion', label: 'Protrusion'},
  ]

  const [loadingSmilePlanCategoriesAndTemplates, setLoadingSmilePlanCategoriesAndTemplates] = useState(false);
  const [smilePlanCategoriesAndTemplates, setSmilePlanCategoriesAndTemplates] = useState([]);
  const animatedComponents = makeAnimated();

  const handleTreatmentDescriptionEn = (value) => {
    props.handleTreatmentDescriptionEn(value);
  };
  const handleTreatmentDescriptionAr = (value) => {
    props.handleTreatmentDescriptionAr(value);
  };

  const handleChangeIsTeethImageVisible = (newChecked) => {
    props.handleCheckedIsTeethImageVisible(newChecked);
  };

  const getSmilePlanCategoriesWithTemplates = async () => {
    setLoadingSmilePlanCategoriesAndTemplates(true);
    try {
      const response = await axios.get('admin/v1/smile-plan-categories-templates/', {
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
      });
      if (response.status === 200) {
        const { data } = response.data;
        setSmilePlanCategoriesAndTemplates(data);
      }
    } catch (e) {
      console.log('Response Error: GET - Smile Plan Categories And Templates', e);
    } finally {
      setLoadingSmilePlanCategoriesAndTemplates(false);
    }
  }

  function viewTP() {
    var win = window.open(props.TPLink, "_blank");
    win.focus();
  }
  function handleSelectChangeAlignerWearTime(value) {
    props.handeChangeSelectedWearTime(value);
  }
  function handleChangeNumberAlignersUpper(valueNumberAlignersUpper) {
    props.handleChangeUpperAlignersNb(valueNumberAlignersUpper);
  }

  function handleChangeNumberAlignersLower(valueNumberAlignersLower) {
    props.handleChangeLowerAlignersNb(valueNumberAlignersLower);
  }

  return (
    <>
      <div className="tp-main-container">
        <div style={{ marginBottom: '2rem' }} id='upperStepsError'>
          <CollapsibleSmilePlanCategories
              smilePlanCategoriesAndTemplates={smilePlanCategoriesAndTemplates} 
              loadingSmilePlanCategoriesAndTemplates={loadingSmilePlanCategoriesAndTemplates} 
              getSmilePlanCategoriesWithTemplates={getSmilePlanCategoriesWithTemplates}
              valueTreatmentDescriptionEn={props.valueTreatmentDescriptionEn}
              setvalueTreatmentDescriptionEn={setvalueTreatmentDescriptionEn}
              valueTreatmentDescriptionAr={props.valueTreatmentDescriptionAr}
              setvalueTreatmentDescriptionAr={setvalueTreatmentDescriptionAr}
          />
        </div>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle size="strong">English Description</TextStyle>
              <TextStyle variation="subdued">
                Maximum Length: 523 characters
              </TextStyle>
              <RichTextEditor
                rootStyle={{
                  fontFamily: "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif"
                }}
                className="rich-text-editor"
                value={props.valueTreatmentDescriptionEn}
                onChange={handleTreatmentDescriptionEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle size="strong">Arabic Description</TextStyle>
              <TextStyle variation="subdued">
                Maximum Length: 523 characters
              </TextStyle>
              <RichTextEditor
                className="rich-text-editor"
                value={props.valueTreatmentDescriptionAr}
                onChange={handleTreatmentDescriptionAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout>
            <FormLayout.Group condensed>
              <FormLayout>
                <TextStyle size="strong">Steps - Top</TextStyle>
                <TextField
                  value={props.valueNumberAlignersUpper}
                  onChange={handleChangeNumberAlignersUpper}
                  type="number"
                  min={0}
                  error={props.upperStepsError}
                />
              </FormLayout>
              <FormLayout>
                <TextStyle size="strong">Steps - Bottom</TextStyle>
                <TextField
                  value={props.valueNumberAlignersLower}
                  onChange={handleChangeNumberAlignersLower}
                  type="number"
                  min={0}
                  error={props.lowerStepsError}
                />
              </FormLayout>
            </FormLayout.Group>
          </FormLayout>
          <FormLayout>
            <FormLayout.Group condensed>
              <FormLayout>
                <TextStyle size="strong">Treatment Length (Months)</TextStyle>
                <TextField
                  value={props.valueLengthOfTreatment}
                  type="number"
                  disabled
                />
              </FormLayout>
              <FormLayout>
                <TextStyle size="strong">Aligner Wear-time</TextStyle>
                <Select
                  options={optionsAlignerWearTime}
                  onChange={handleSelectChangeAlignerWearTime}
                  value={props.selectedAlignerWearTime}
                />
              </FormLayout>
            </FormLayout.Group>
          </FormLayout>
          <TextStyle variation="strong">Case Types</TextStyle>
          <ReactSelect
              options={caseTypesOptions}
              isMulti
              onChange={props.handleSelectChange}
              value={props.selectedCaseTypes}
              components={animatedComponents}
          />
          <TextStyle variation="strong">Plan Type (For Operations)</TextStyle>
          <Select2
            placeholder="This is for the operations team"
            onChange={(newValue) => props.handleOperationsValuePlanType(newValue)}
            values={props.operationsValuePlanType}
            options={operationsOptionsType}
          />
          <TextStyle variation="strong">Plan Type (Visible to Clients)</TextStyle>
          <Select2
            placeholder="This is the plan the user will see and pay"
            onChange={(newValue) => props.handleValuePlanType(newValue)}
            values={props.valuePlanType}
            options={optionsType}
          />
          <Checkbox
            label="Show teeth diagram on treatment plan"
            checked={props.checkedIsTeethImageVisible}
            onChange={handleChangeIsTeethImageVisible}
          />
          <div className="end-section-div">
            <Button
              onClick={() => props.handleSave(2)}
              disabled={props.disabled || props.disableSave}
              primary
            >
              Save Section
            </Button>
          </div>
          {!createMode ?
            <div className="tp-btn-container">
              <Button
                disabled={!props.TPLink}
                onClick={viewTP}
                primary
              >
                View Treatment Plan
              </Button>
            </div> :
            null
          }
          {props.invalidPlans}
        </FormLayout>
      </div>
    </>
  );
};

export default React.memo(TreatmentPlan);
