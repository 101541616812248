import React from "react";
import {
    Thumbnail,
    DropZone,
    TextField,
} from "@shopify/polaris";
import { TextArea } from "semantic-ui-react";

const MediaItem = ({
    title,
    descriptionTitle,
    description,
    lang,
    titleProp,
    descriptionTitleProp,
    handleChange,
    descriptionProp,
    imageUrl = null,
    onDrop,
    onDropAccepted,
    uploadedFilesImage,
    fileUploadImage,
}) => {
    return (
        <div className='app-whats-new-media-item-container'>
            <TextField value={title} placeholder={`Title ${lang}`} onChange={(e) => handleChange(titleProp, e)} />
            <TextField value={descriptionTitle} placeholder={`Description Title ${lang}`} onChange={(e) => handleChange(descriptionTitleProp, e)} />
            <TextArea
                placeholder={`Description ${lang}`}
                rows={8}
                value={description}
                onChange={(e) => handleChange(descriptionProp, e.target.value)}
            />
            <div className='app-whats-new-media-item-dropzone-container'>
                <div className='app-whats-new-media-item-thumbnail'>
                    <Thumbnail
                        size="large"
                        source={imageUrl}
                    />
                </div>
                <div className='app-whats-new-media-item-dropzone'>
                    <DropZone onDrop={onDrop} onDropAccepted={onDropAccepted}>
                        {uploadedFilesImage}
                        {fileUploadImage}
                    </DropZone>
                </div>
            </div>
        </div>
    )
}

export default MediaItem