import React, { useCallback, useState } from "react";
import {
  Thumbnail,
  Caption,
  InlineError,
  DropZone,
  Stack,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Card,
  Modal,
  PageActions,
  Checkbox
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";
import history from "../history";

function Add() {
  const [valueFirstName, setvalueFirstName] = useState("");
  const [valueLastName, setvalueLastName] = useState("");
  const [valueAddress, setValueAddress] = useState("");
  const [valueCity, setValueCity] = useState("");
  const [valueCountry, setvalueCountry] = useState("");
  const [valuePhone, setValuePhone] = useState("");
  const [valueEmail, setValueEmail] = useState("");
  const [valuePassword, setValuePassword] = useState("");
  const [valueConfirmPassword, setValueConfirmPassword] = useState("");
  const [selected, setSelected] = useState("4");
  const [checked, setChecked] = useState(false);
  const handleChange = useCallback((newChecked) => setChecked(newChecked), []);
  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const [fieldRequiredImage, setFieldRequiredImage] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [popupActive, setPopupActive] = useState(false);

  // const [options, setOptions] = useState([]);
  //let arrayChoices = ["Editor", "Writer", "Moderator", "Admin", "Super-Admin"];
  const options = [
    { label: "Editor", value: "1" },
    { label: "Lab", value: "3" },
    { label: "Admin", value: "4" },
    { label: "Accounting", value: "6" },
    { label: "Doctor", value: "7" },
  ];
  const [fieldRequiredFN, setFieldRequiredFN] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredLN, setFieldRequiredLN] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredE, setFieldRequiredE] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredP, setFieldRequiredP] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredCP, setFieldRequiredCP] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [imagePreview, setImagePreview] = useState("");
  let imageType = "";
  const [imageKey, setImageKey] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = useCallback(
    (files) => {
      setPopupActive(true);
      setImagePreview(
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = files[files.length - 1].type;
      let headersVar = null;
      let url2 = "";
      const form_data = new FormData();
      form_data.append("image_name", files[files.length - 1].name);

      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKey(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          let form_data = new FormData();
          //form_data.append("image_name", files[files.length - 1].name);
          //form_data.append("image", files[files.length - 1]);
          axios
            .put(url2, files[files.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [files]
  );
  return (
    <Page
      title="Add Doctor"
      breadcrumbs={[{ content: "List Of Doctors", url: "/admin/doctors" }]}
    >
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">First Name</TextStyle>
              <TextField
                value={valueFirstName}
                onChange={handleChangeFirstName}
              />
              {fieldRequiredFN}
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Last Name</TextStyle>
              <TextField
                value={valueLastName}
                onChange={handleChangeLastName}
              />
              {fieldRequiredLN}
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Address</TextStyle>
              <TextField value={valueAddress} onChange={handleChangeAddress} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">City</TextStyle>
              <TextField value={valueCity} onChange={handleChangeCity} />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Country</TextStyle>
              <TextField value={valueCountry} onChange={handleChangeCountry} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Phone</TextStyle>
              <TextField value={valuePhone} onChange={handleChangePhone} />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Email</TextStyle>
              <TextField value={valueEmail} onChange={handleChangeEmail} />
              {fieldRequiredE}{" "}
            </FormLayout>
          </FormLayout.Group>

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Password</TextStyle>
              <TextField
                value={valuePassword}
                onChange={handleChangePassword}
              />
              {fieldRequiredP}
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Confirm Password</TextStyle>
              <TextField
                value={valueConfirmPassword}
                onChange={handleChangeConfirmPassword}
              />
              {fieldRequiredCP}
            </FormLayout>
          </FormLayout.Group>

          <TextStyle variation="strong">Profile Image</TextStyle>
          <Thumbnail size="large" source={imagePreview} />
          <DropZone onDrop={handleDropZoneDrop} onDropAccepted={sendImage}>
            {uploadedFiles}
            {fileUpload}
          </DropZone>
          {fieldRequiredImage}

          {/* <Checkbox
              label="Is Active"
              checked={checked}
              onChange={handleChange}
            /> */}
            <Checkbox
              label="Send Email Updates For Doctor"
              checked={checked}
              onChange={handleChange}
            />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );
  function handleChangeAddress(valueAddress) {
    setValueAddress(valueAddress);
  }
  function handleChangeCity(valueCity) {
    setValueCity(valueCity);
  }
  function handleChangeCountry(valueCountry) {
    setvalueCountry(valueCountry);
  }
  function handleChangePhone(valuePhone) {
    setValuePhone(valuePhone);
  }
  function handleChangeFirstName(valueFirstName) {
    setvalueFirstName(valueFirstName);
    if (valueFirstName !== "") {
      setFieldRequiredFN(<InlineError message="" fieldID="myFieldID" />);
    }
  }
  function handleChangeLastName(valueLastName) {
    setvalueLastName(valueLastName);
    if (valueLastName !== "") {
      setFieldRequiredLN(<InlineError message="" fieldID="myFieldID" />);
    }
  }
  function handleChangeEmail(valueEmail) {
    setValueEmail(valueEmail);
    if (valueEmail !== "") {
      setFieldRequiredE(<InlineError message="" fieldID="myFieldID" />);
    }
  }
  function handleChangePassword(valuePassword) {
    setValuePassword(valuePassword);
    if (valuePassword !== "") {
      setFieldRequiredP(<InlineError message="" fieldID="myFieldID" />);
    }
  }
  function handleChangeConfirmPassword(valueConfirmPassword) {
    setValueConfirmPassword(valueConfirmPassword);
    if (valueConfirmPassword !== "") {
      setFieldRequiredCP(<InlineError message="" fieldID="myFieldID" />);
    }
  }
  function handleSave() {
    if (
      valueFirstName === "" ||
      valueLastName === "" ||
      valueEmail === "" ||
      valuePassword === "" ||
      valueConfirmPassword === "" ||
      (valueEmail !== "" &&
        !valueEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) ||
      valuePassword.length < 6 ||
      valuePassword !== valueConfirmPassword
    ) {
      if (valueFirstName === "")
        setFieldRequiredFN(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueLastName === "")
        setFieldRequiredLN(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueEmail === "")
        setFieldRequiredE(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valuePassword === "")
        setFieldRequiredP(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueConfirmPassword === "")
        setFieldRequiredCP(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (
        valueEmail !== "" &&
        !valueEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      )
        setFieldRequiredE(
          <InlineError
            message="Please enter a valid email address"
            fieldID="myFieldID"
          />
        );
      if (valuePassword.length < 6)
        setFieldRequiredP(
          <InlineError
            message="Password must be at least 6 digit"
            fieldID="myFieldID"
          />
        );
      if (valuePassword !== valueConfirmPassword) {
        setFieldRequiredCP(
          <InlineError
            message="Passwords are not matching"
            fieldID="myFieldID"
          />
        );
      }
    } else {
      setPopupActive(true);
      const bodyObj = {
        first_name: valueFirstName,
        last_name: valueLastName,
        email: valueEmail,
        password: valuePassword,
        // "confirm-password": valueConfirmPassword,
        profile_image: imageKey,
        address: valueAddress,
        city: valueCity,
        country: valueCountry,
        phone: valuePhone,
        email_updates: checked
      };
      axios
        .post("admin/v1/management/doctors", bodyObj, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setPopupActive(false);
          history.push("/admin/doctors");

          history.push("/admin/doctors");
          //  history.push('/admin/faqsupport/viewCategory')
        })
        .catch(function (error) {
          setPopupActive(false);
          setFieldRequiredE(
            <InlineError
              message="This email is already used"
              fieldID="myFieldID"
            />
          );
        });
    }
  }
  function handleError(err) {
    setFieldRequiredE(
      <InlineError message="This email is already used" fieldID="myFieldID" />
    );
  }
}
export default Add;
