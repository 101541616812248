import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../history";
import NavigationLayout from "../components/NavigationLayout";
import Home from "./Home";

function Main() {
    return (
        <NavigationLayout>
            <Router history={history}>
                <Switch>
                    <Route exact path="/admin/mobile-app" component={Home} />
                </Switch>
            </Router>
        </NavigationLayout>
    );
}

export default Main;