import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../history";
import ListOfReports from "./List";
import ImpressionSalesReport from "./ImpressionSalesReport";
import MainDashboard from "../Users/MainDashboard";
import RedoKitsReport from "./RedoKitsReport";
import NavigationLayout from "../components/NavigationLayout";
import OverDuePaymentsReport from "./OverDuePaymentsreport";
import CompletedAlignerCases from "./CompletedAlignerCases";
import WrittenOffReport from  "./WrittenOffReport"
import SurveyChart from "../Chart/SurveyChart";
import ClinicReportsInvoices from "./ClinicReports/clinic-reports-invoices.component";
import BatchTrackings from "./BatchTrackings.js/BatchTrackings";
import ApprovedTps from "./ApprovedTPs";
import PendingTps from "./PendingTPs";
import ProductRequestsReport from "./ProductRequests/product-requests-report.component";
import CreateProductRequest from "./ProductRequests/create-product-request.component";
import DesignerCases from "./DesignerCases";

function Reports() {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/reports" component={ListOfReports} />
          <Route
            path="/admin/reports/survey-report"
            component={SurveyChart}
          />
          <Route
            path="/admin/reports/impression-sales"
            component={ImpressionSalesReport}
          />
          <Route path="/admin/users/:id/dashboard" component={MainDashboard} />
          <Route path="/admin/reports/redo-kits" component={RedoKitsReport} />
          <Route path="/admin/reports/completed-aligner-cases" component={CompletedAlignerCases} />
          <Route path="/admin/reports/over-due-payemnts-report" component={OverDuePaymentsReport} />
          <Route path="/admin/reports/written-off-report" component={WrittenOffReport} />
          <Route path="/admin/reports/product-requests/create" component={CreateProductRequest} />
          <Route path="/admin/reports/product-requests" component={ProductRequestsReport} />
          <Route path="/admin/reports/clinics-reports-invoices" component={ClinicReportsInvoices} />
          <Route path="/admin/reports/batch-trackings" component={BatchTrackings} />
          <Route path="/admin/reports/pending-tps" component={PendingTps} />
          <Route path="/admin/reports/approved-tps" component={ApprovedTps} />
          <Route path="/admin/reports/designer-cases" component={DesignerCases} />
        </Switch>
      </Router>
    </NavigationLayout>
  );
}
export default Reports;
