import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        flexWrap: "unset",
        flexDirection: "column"
      },
      noCaseBodyRow: {
        height: "85%",
        background: "url('/basmaSmile.png')",
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "bottom",
        backgroundSize: "cover"
      },
      noCasePaper: {
        background: "inherit",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        color: theme.palette.primary.dark,
      },
      noCaseDiv: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      },
      noCaseTitle: {
        fontSize: "1.3em",
        color: "#464646"
      },
      btn:{
        width: '90%', 
        borderRadius: '5px', 
        backgroundColor: '#FF2C46', 
        color:'#fff',
        fontSize: "1.1em",
        padding: "3px 0",
        cursor:"pointer",
        '&:hover':{
          backgroundColor: '#b70c21'
        },
        '&:disabled':{
          backgroundColor: '#790816'
        }
      },


}));