import React from "react";
const LeftRight = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
      }}
    >
      <span>Right</span>
      <hr
        style={{
          width: "100%",
          backgroundColor: "black",
          height: "0.5px",
        }}
      />
      <span>Left</span>
    </div>
  );
};
export default LeftRight;
