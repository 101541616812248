import React, { useCallback, useState, useEffect } from "react";
import {
  InlineError,
  Checkbox,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Select,
  Card,
  PageActions,
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";
import history from "../history";
import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.
import { useParams } from "react-router-dom";
import Select2 from "react-dropdown-select";

function Edit() {
  let { id } = useParams();

  const [valueCode, setValueCode] = useState();
  const [valueCount, setValueCount] = useState();
  const [valueType, setValueType] = useState("Percentage Discount");
  const [valueAmount, setValueAmount] = useState();
  const [valueProduct, setValueProduct] = useState();
  const [valueStartDate, setValueStartDate] = useState();
  const [valueEndDate, setValueEndDate] = useState();
  const [checked, setChecked] = useState();
  const [options, setOptions] = useState([]);
  const [isMemberShip, setIsMemberShip] = useState(false);
  const [clinicOptions, setClinicsOptions] = useState([]);
  const [clinics, setClinics] = useState([]);
  const [earlyBird, setEarlyBird]=useState('');
  const [earlyBirdClinics, setEarlyBirdClinics]=useState([]);
  const [corporate, setCorporate]=useState(false);
  // const options = [
  //     // { label: 'Account Created', value: 'Account Created' },
  //     // { label: 'Sever Case', value: 'Sever Case' },
  //     // { label: 'Purchase Impression Kit', value: 'Purchase Impression Kit' },
  //     // { label: 'Impression Kit Purchased', value: 'Impression Kit Purchased' },
  //     // { label: 'Taking Impressions Photos', value: 'Taking Impressions Photos' },
  //     // { label: 'Taking Impressions Photos Pending', value: 'Taking Impressions Photos Pending' },
  //     // { label: 'Impressions Not Approved', value: 'Impressions Not Approved' },
  //     // { label: 'Eligible With Rredo Kit ', value: 'Eligible With Rredo Kit' },
  //     // { label: 'Eligible', value: 'Eligible' },
  //     // { label: 'Your Smile Plan', value: 'Your Smile Plan' },
  //     // { label: 'Aligner Kit Purchased', value: 'Aligner Kit Purchased' },

  //   ];
  const handleSelectProduct = useCallback(
    (valueProduct) => setValueProduct(valueProduct),
    []
  );
  const earlyBirdCouponOptions = [
    { label: "First 5 days", value: 1 },
    { label: "Between 5 and 10 days", value: 2 },
  ];
  const [fieldRequiredCode, setFieldRequiredCode] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredCount, setFieldRequiredCount] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredAmount, setFieldRequiredAmount] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredSD, setFieldRequiredSD] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredED, setFieldRequiredED] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredProduct, setFieldRequiredProduct] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  let listOptionsProducts = [];
  let listOptionsPlans = [];
  const [checkedDiscountOnAligner, setCheckedDiscountOnAligner] =
    useState(false);
  const handleChangeDiscountOnAlignerChecked = useCallback(
    (newChecked) => setCheckedDiscountOnAligner(newChecked),
    []
  );
  const [discountOnAlignerValue, setDiscountOnAlignerValue] = useState("");
  const handleChangeDiscountOnAligner = useCallback(
    (newValue) => setDiscountOnAlignerValue(newValue),
    []
  );
  const handleMemberShipCheckboxChange = (checked) => {
    setIsMemberShip(checked);
    if(checked == false){
      setClinics([]);
    }
  }
  function handleSelectedEarlyBird(value) {
    setEarlyBird(parseInt(value));
  }
  useEffect(() => {
    Promise.all([
      axios.get(`admin/v1/coupons/${id}/show`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }),
      axios.get(`admin/v1/products`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }),
      axios.get(`admin/v1/plans`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }),
      axios.get("/admin/v1/clinics", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }),
      axios.get(`/admin/v1/coupon-clinics/${id}/show`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }),
      axios.get(`/admin/v1/early-bird/${id}/show`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }),
    ]).then(function (responses) {
      const responseCouponShow = responses[0].data.data;
      const responseProducts = responses[1].data.data.data;
      const responsePlans = responses[2].data.data;
      let clinicsArray = responses[3].data.data.map((clinic) => ({value: clinic.id, label: clinic.name['en']}));
      const couponData = responses[4].data.data.map((couponClinic) => ({value: couponClinic.clinic_id, label: couponClinic.clinic.name['en']}));
      const earlyBirdData = responses[5].data.data.map((early) => ({value: early.clinic_id, label: early.clinic.name['en']}));
      setClinicsOptions(clinicsArray);
      setEarlyBird(responseCouponShow.early_bird)
      if(couponData.length > 0){
        setIsMemberShip(true);
        setClinics(couponData);
      }      
      if(earlyBirdData.length > 0){
        setEarlyBirdClinics(earlyBirdData);
      }
      console.log(responseCouponShow)
      if(responseCouponShow.plan_id === null && responseCouponShow.product_id === null){
        setValueProduct('allProducts')
      }
      setCorporate(responseCouponShow.corporate)
      setValueCode(String(responseCouponShow.code));
      setValueCount(String(responseCouponShow.count));
      setValueAmount(String(responseCouponShow.amount));
      setValueStartDate(String(responseCouponShow.start_date));
      setValueEndDate(String(responseCouponShow.end_date));
      setChecked(responseCouponShow.active === 0 ? false : true);
      responseCouponShow.discount_on_aligner == 1 &&
        setCheckedDiscountOnAligner(true);
      setDiscountOnAlignerValue(String(responseCouponShow.discount_value));

      let list = [];
      for (var i = 0; i < responseProducts.length; i++) {
        list.push({
          key: i + responseProducts[i].title.en,
          label: responseProducts[i].title.en,
          value: String(responseProducts[i].id) + "_product",
        });
      }
      for (var i = 0; i < responsePlans.length; i++) {
        list.push({
          key: i + responsePlans[i].title.en,
          label: responsePlans[i].title.en,
          value: String(responsePlans[i].id) + "_plan",
        });
      }
      list.push({
        label: "All Aligners",
        value: "Aligner",
      });
      list.push({
        label: "All Products",
        value: "allProducts",
      });
      setOptions(list);
      responseCouponShow.plan_id &&
        setValueProduct(responseCouponShow.plan_id + "_plan");
      responseCouponShow.product_id &&
        setValueProduct(responseCouponShow.product_id + "_product");
      responseCouponShow.type === "Aligner" && setValueProduct("Aligner");
    });
  }, []);

  return (
    <Page
      title="Edit Coupon"
      breadcrumbs={[{ content: "List Of Coupons", url: "/admin/coupons" }]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Code (Without Spaces)</TextStyle>
          <TextField value={valueCode} onChange={handleChangeCode} />
          {fieldRequiredCode}
          <TextStyle variation="strong">
            Count (Maximum Number Of Usage)
          </TextStyle>
          <TextField
            value={valueCount}
            onChange={handleChangeCount}
            type="number"
          />
          {fieldRequiredCount}
          <TextStyle variation="strong">Type</TextStyle>
          <TextField value={valueType} />
          <TextStyle variation="strong">Amount</TextStyle>
          <TextField
            value={valueAmount}
            onChange={handleChangeAmount}
            type="number"
          />
          {setFieldRequiredAmount}
          <TextStyle variation="strong">Start Date</TextStyle>
          <TextField
            value={valueStartDate}
            onChange={handleChangeStartDate}
            type="date"
          />
          {fieldRequiredSD}
          <TextStyle variation="strong">End Date</TextStyle>
          <TextField
            value={valueEndDate}
            onChange={handleChangeEndDate}
            type="date"
          />
          {setFieldRequiredED}
          <TextStyle variation="strong">Product</TextStyle>
          <Select
            options={options}
            onChange={handleSelectProduct}
            value={valueProduct}
          />
          <TextStyle variation="strong">Early Bird</TextStyle>
          <Select
            options={earlyBirdCouponOptions}
            onChange={handleSelectedEarlyBird}
            value={earlyBird}
            placeholder="Please Choose"
          />  
          <div>
            <TextStyle variation="strong">Early Bird Clinics</TextStyle>
            <Select2
              placeholder="Select Clinic"
              multi
              options={clinicOptions}
              onChange={setEarlyBirdClinics}
              values={earlyBirdClinics}
            />
          </div>
          <Checkbox
            label="Discount on aligner"
            checked={checkedDiscountOnAligner}
            onChange={handleChangeDiscountOnAlignerChecked}
          />

          {checkedDiscountOnAligner && (
            <TextField
              label="Discount Value"
              type="number"
              value={discountOnAlignerValue}
              onChange={handleChangeDiscountOnAligner}
            />
          )}

          <Checkbox
            label="Is Active"
            checked={checked}
            onChange={handleChangeCb}
          />
          <Checkbox
            label="Corporate Partnership"
            checked={corporate}
            onChange={setCorporate}
          />
          <Checkbox
            label="Clinic Membership Program"
            checked={isMemberShip}
            onChange={handleMemberShipCheckboxChange}
          />
          <TextStyle variation="strong">Membership Clinics</TextStyle>
          <Select2
            placeholder="Search clinics"
            multi
            isDisabled={isMemberShip == false}
            options={clinicOptions}
            values={clinics}
            onChange={setClinics}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
        // secondaryActions={[
        //   {
        //     content: "Delete",
        //     onClick: () =>
        //       axios
        //         .delete("/admin/v1/coupons/" + id + "/delete", {
        //           headers: {
        //             Authorization: "Bearer " + Cookie.get("token"),
        //           },
        //         })
        //         .then((result) => {
        //           history.push("/admin/coupons");
        //         })
        //         .catch((err) => console.log(err)),
        //   },
        // ]}
      />
    </Page>
  );
  function handleChangeCode(valueCode) {
    setValueCode(valueCode);
    setFieldRequiredCode(<InlineError message="" fieldID="myFieldID" />);
  }
  function handleChangeCount(valueCount) {
    setValueCount(valueCount);
  }
  function handleChangeStartDate(valueStartDate) {
    setValueStartDate(valueStartDate);
  }
  function handleChangeEndDate(valueEndDate) {
    setValueEndDate(valueEndDate);
  }
  function handleChangeAmount(valueAmount) {
    setValueAmount(valueAmount);
  }
  function handleChangeProduct(valueProduct) {
    setValueProduct(valueProduct);
  }
  function handleChangeCb(checked) {
    setChecked(checked);
  }
  function handleSave() {
    if (
      valueCode === "" ||
      valueCount === "" ||
      valueAmount === "" ||
      valueStartDate === "" ||
      valueEndDate === ""
    ) {
      if (valueCode === "")
        setFieldRequiredCode(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueCount === "")
        setFieldRequiredCount(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueAmount === "")
        setFieldRequiredAmount(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueStartDate === "")
        setFieldRequiredSD(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueEndDate === "")
        setFieldRequiredED(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueProduct === "")
        setFieldRequiredProduct(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
    } else {
      let bodyObj = {};
      let couponClinicData = {};
      console.log("valueProduct=", valueProduct);
      if (valueProduct === "Aligner") {
        bodyObj = {
          code: valueCode,
          amount: valueAmount,
          count: valueCount,
          product_id: null,
          plan_id: null,
          type: "Aligner",
          start_date: valueStartDate,
          end_date: valueEndDate,
          active: checked,
          discount_on_aligner: checkedDiscountOnAligner ? 1 : 0,
          discount_value: discountOnAlignerValue,
          early_bird: earlyBird,
          corporate:corporate,
        };
      }else if(valueProduct === "allProducts" ) {
        bodyObj = {
          code: valueCode,
          amount: valueAmount,
          count: valueCount,
          product_id: null,
          plan_id: null,
          type: null,
          start_date: valueStartDate,
          end_date: valueEndDate,
          active: checked,
          corporate:corporate,
          discount_on_aligner: checkedDiscountOnAligner ? 1 : 0,
          ...(discountOnAlignerValue && {
            discount_value: discountOnAlignerValue,
          }),
        }
      } else {
        bodyObj = {
          code: valueCode,
          amount: valueAmount,
          count: valueCount,
          type: null,
          product_id: valueProduct.split("_")[0],
          plan_id: valueProduct.split("_")[0],
          start_date: valueStartDate,
          end_date: valueEndDate,
          active: checked,
          corporate:corporate,
          discount_on_aligner: checkedDiscountOnAligner ? 1 : 0,
          discount_value: discountOnAlignerValue,
        };
      }
      if (valueProduct.split("_")[1] === "product") {
        bodyObj.plan_id=null;
      }
      if (valueProduct.split("_")[1] === "plan") {
        bodyObj.product_id=null;
        bodyObj.early_bird = earlyBird;
      }

      couponClinicData = {
        clinics: clinics,
      };
     let earlyBirdData ={
        early: earlyBirdClinics
      }
      axios
        .post("/admin/v1/coupons/" + id + "/update", bodyObj, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          axios.post(`/admin/v1/coupon-clinics/${id}/update`, couponClinicData, {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          }).then((res) => {
            axios.post("/admin/v1/early-bird/" + id + "/update", earlyBirdData, {
              headers: {
                Authorization: "Bearer " + Cookie.get("token"),
              },
            });  
            history.push("/admin/coupons");
          }).catch((err) => console.log(err));     
        })
        .catch((err) =>
          setFieldRequiredCode(
            <InlineError
              message="The code has already been taken."
              fieldID="myFieldID"
            />
          )
        );
    }
  }
}
export default Edit;
