import { FormControl, Input, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import VerifiedIcon from '@mui/icons-material/Verified';
import React from 'react'
import "./TicketField.css"

const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
      },
    },
  }

function TicketField({ field, onChange, ind, extra = false, allFields = [], userDynamicOptions = {}, user, loading }) {
    let dependantOn;
    if(field.dependant){
        let index = allFields.findIndex((f)=>f.field== field.dependant);
        if(index){
            dependantOn = allFields[index];
        }
    }
    if (field.type == 'checkbox') {
        return (<FormControl fullWidth  key={ind}>
            <label htmlFor={`field-type-${field.field}-${ind}`}>{field.name}</label>
            <Select style={{ width: "100%", fontSize: '1.2rem'}} MenuProps={MenuProps} id={`filter-type-${field.field}-${ind}`}
             disabled={(field.requireUser && !user) || (field.dependant? (dependantOn.value?false:true ):false)}
              onChange={(e) => { onChange(ind, e, extra) }} value={field.value}
               >
                {field.dependant? [...Array(userDynamicOptions[dependantOn.field][dependantOn.value])].map((e, i)=>
                    <MenuItem key={i+1} style={{ fontSize: '1.2rem' }} value={i+1}>{i+1}</MenuItem>,
                ):
                 field.options.map((option, num) =>
                    <MenuItem key={num} style={{ fontSize: '1.2rem' }} value={option.value}>{option.name}</MenuItem>,
                )}
            </Select>
        </FormControl>)
    }else
    if (field.type == 'text') {
        return (
        <FormControl fullWidth sx={{ m: 1 }} key={ind}>
            <label htmlFor={`field-type-${field.field}-${ind}`}>{field.name}</label>
            <Input id={`filter-type-${field.field}-${ind}`} multiline   variant="outlined" value={field.value} style={{fontSize:"16px"}} 
                placeholder= {field.placeholder}
                disabled={field.requireUser && !user}
                onChange={(e) => { onChange(ind, e) }}>
            </Input>
            {field.customSubmit && <div className='ticket-custom-submit'onClick={()=>{field.customSubmit(field.value)}}><VerifiedIcon color={loading?'disabled':'success'}/></div>}
            </FormControl>)
    }else{
        return (null)
    }
}

export default TicketField