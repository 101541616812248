import { Typography,TextField,Select,MenuItem } from "@material-ui/core";
import axios from "../../../axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";



export function ExistingCorporateDeals({clinic_id,id,email,coupon,couponOptions,editable,success,setSuccess}) {
    const[Email,setEmail]=useState(email)
    const[Coupon,setCoupon]=useState(coupon)
    const[edit,setEdit]=useState(editable)

    const save=async()=>{
    const bodyObj={
      id:id,
      email:Email,
      coupon_id:Coupon,
      clinic_id:clinic_id
    }
    const res=await axios
          .post(`/admin/v1/corporate_deals`, bodyObj,{
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
          })
          .then((result) => {

          })
          .catch((e)=>{
            console.log(e)
          })
          .finally((f)=>{
            setEdit((prev)=>!prev)
            setSuccess((prev)=>prev+1)
          })
    }

  return(
       <tr style={{display:"flex",padding:"1em 0",gap:"10px"}}>
          <td  className="plan-column" >  
          <TextField
            disabled={!edit}
            style={{maxWidth:"100%"}}
            className="plan-field"
            placeholder="example @gmail.com"
            onChange={(e)=>{setEmail(e.target.value)}}
            value={Email}
          />
          
          </td>
          <td  className="plan-column" >  
          <Select
            disabled={!edit}
            style={{maxWidth:"100%"}}
            className="plan-field"
            onChange={(e)=>{setCoupon(e.target.value)}}
            value={Coupon}
          >
            {couponOptions.map((couponOption)=>
            <MenuItem value={couponOption.id}>{couponOption.code}</MenuItem>
            )}
          </Select>
          </td>
          {edit?
            <td  className="plan-button">
            <button
            style={{ backgroundColor:"rgba(0, 128, 96, 1)",padding:"1rem 3rem"}} 
            textAlign="bottom" 
            primary
            onClick={()=>{
                save();
            }}
            >
              Save
            </button>
             </td>
             :<td className="plan-button">
            <button
                style={{backgroundColor:"rgba(0, 128, 96, 1)",cursor:"pointer"}} 
                textAlign="bottom" 
                primary 
                onClick={()=>{
                    setEdit((prev)=>(!prev))
                }}
            >
              Edit
            </button>
             </td>}
            </tr>
         
  )
}

export default ExistingCorporateDeals