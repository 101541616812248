import React from 'react'
import "./IPRSheet.css"
import { Card } from '@shopify/polaris';
import { TextField } from '@material-ui/core';

function IPRSheet({
  groupTeeth,
  groupTeethDist = [],
  sheets,
  sheetNumber,
  handleCheckboxChange,
  handleDistanceChange,
  handleTextChange,
  title = `Before Aligner Step #${sheets[sheetNumber] ? sheets[sheetNumber]?.step : ''} ${sheets[sheetNumber]?.position != null ? `-${sheets[sheetNumber].position}` : ''}`,
  selectTeethOnly = false,
}) {
  let teethDist = groupTeethDist.length ? groupTeethDist[sheetNumber] : [];
  let teeth = groupTeeth[sheetNumber]
  let upperTeeth = teeth ? teeth.slice(0, 16) : []
  let bottomTeeth = teeth ? teeth.slice(16, 32) : []

  return (
    <Card
      title={title}
      sectioned
    >
      <div className="tooth-checkbox-container">
        <div className="teeth-upper">
          {upperTeeth.length &&
            upperTeeth.map((tooth, key) => (
              <>
                <div className="single-tooth">
                  <div
                    style={{ width: "100%", position: "relative" }}
                    onClick={() => {
                      handleCheckboxChange(key, sheetNumber);
                    }}
                  >
                    <img
                      src={`/assets/teeth/Tooth${tooth.num}.svg`}
                      style={{
                        scale: selectTeethOnly ? '1.4' : '1',
                        position: "relative",
                        color: tooth.attachment ? "#000" : "#bebfc1",
                      }}
                    />
                    {tooth.attachment === true && (
                      <span className="red-square-upper-sheet"></span>
                    )}
                  </div>

                {
                  !selectTeethOnly &&
                  <>
                    {
                      key < upperTeeth.length - 1 ? (
                        <form>
                          <input
                            type="checkbox"
                            style={{ display: "inline" }}
                            id="MesialU"
                            name={`MesialU${key}${sheetNumber}`}
                            onChange={(e) => {
                              handleDistanceChange(
                                e.target.checked,
                                key,
                                sheetNumber,
                                key === 8 ? "m2" : "m",
                                upperTeeth.length / 2 <= key ? "right" : "left"
                              );
                            }}
                            value={
                              teethDist.has(
                                upperTeeth[key].num + "" + upperTeeth[key + 1].num
                              )
                                ? teethDist.get(
                                    upperTeeth[key].num +
                                      "" +
                                      upperTeeth[key + 1].num
                                  )?.m
                                : "false"
                            }
                            checked={
                              key < 8
                                ?( teethDist.has(
                                    upperTeeth[key].num +
                                      "" +
                                      upperTeeth[key + 1].num
                                  )
                                  ? teethDist.get(
                                      upperTeeth[key].num +
                                        "" +
                                        upperTeeth[key + 1].num
                                    )?.m==true?true:false
                                  : false)
                                : (key === 8
                                ?( teethDist.has(
                                    upperTeeth[key-1].num +
                                      "" +
                                      upperTeeth[key].num
                                  )
                                  ? teethDist.get(
                                      upperTeeth[key-1].num +
                                        "" +
                                        upperTeeth[key ].num
                                    )?.m2==true?true:false
                                  : false)
                                :teethDist.has(
                                  upperTeeth[key -1].num + "" + upperTeeth[key].num
                                )
                                  ? teethDist.get(
                                      upperTeeth[key-1].num +
                                        "" +
                                        upperTeeth[key].num
                                    )?.m==true?true:false
                                  : false )
                            }
                          ></input>
                          <label htmlFor={`MisialU${key}${sheetNumber}`}>M</label>
                          <input
                            type="checkbox"
                            style={{ display: "inline" }}
                            id="DistalU"
                            name={`DistalU${key}${sheetNumber}`}
                            disabled={key === 0}
                            onChange={(e) => {
                              handleDistanceChange(
                                e.target.checked,
                                key,
                                sheetNumber,
                                "d",
                                upperTeeth.length / 2 <= key ? "right" : "left"
                              );
                            }}
                            value={
                              teethDist.has(
                                upperTeeth[key].num + "" + upperTeeth[key + 1].num
                              )
                                ? teethDist.get(
                                    upperTeeth[key].num +
                                      "" +
                                      upperTeeth[key + 1].num
                                  )?.d
                                : "false"
                            }
                            checked={
                            ( key<8 )?
                            key>=1?
                              ( teethDist.has(
                                upperTeeth[key-1].num + "" + upperTeeth[key].num
                              )
                                ? teethDist.get(
                                    upperTeeth[key-1].num +
                                      "" +
                                      upperTeeth[key].num
                                  )?.d==true?true:false
                                : false)
                                :false
                              :
                            ( teethDist.has(
                                upperTeeth[key].num + "" + upperTeeth[key + 1].num
                              )
                                ? teethDist.get(
                                    upperTeeth[key].num +
                                      "" +
                                      upperTeeth[key + 1].num
                                  )?.d==true?true:false
                                : false)
                            }
                            
                          ></input>
                          <label htmlFor={`DistalU${key}${sheetNumber}`}>D</label>
                        </form>
                      ) : (
                        <form>
                          <input
                            type="checkbox"
                            style={{ display: "inline" }}
                            id="MesialU"
                            name={`MesialU${key}${sheetNumber}`}
                            onChange={(e) => {
                              handleDistanceChange(
                                e.target.checked,
                                key,
                                sheetNumber,
                                "m",
                                upperTeeth.length / 2 <= key ? "right" : "left"
                              );
                            }}
                            value={
                              teethDist.has(upperTeeth[key].num + "00")
                                ? teethDist.get(upperTeeth[key].num + "00")?.m
                                : "false"
                            }
                            checked={
                              teethDist.has("2728")
                                ? teethDist.get("2728")?.m===true?true:false
                                : false
                            }
                          ></input>
                          <label htmlFor={`MisialU${key}${sheetNumber}`}>M</label>
                          <input
                            type="checkbox"
                            style={{ display: "inline" }}
                            id="DistalU"
                            name={`DistalU${key}${sheetNumber}`}
                            disabled
                            onChange={(e) => {
                              handleDistanceChange(
                                e.target.checked,
                                key,
                                sheetNumber,
                                "d",
                                upperTeeth.length / 2 <= key ? "right" : "left"
                              );
                            }}
                            value={
                              teethDist.has(upperTeeth[key].num + "00")
                                ? teethDist.get(upperTeeth[key].num + "00")?.d
                                : "false"
                            }
                          ></input>
                          <label htmlFor={`DistalU${key}${sheetNumber}`}>D</label>
                        </form>
                      )
                    }
                  </>
                }
                  <span>{tooth.num}</span>
                </div>
                {key < bottomTeeth.length - 1 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "10px",
                      margin: "auto",
                      alignItems: "center",
                      height: selectTeethOnly ? '70px' : 'unset',
                    }}
                  >
                    {
                      !selectTeethOnly &&
                      <input
                        type="text"
                        style={{ fontSize: "x-small", width: "30px" }}
                        onChange={(e) => {
                          handleDistanceChange(
                            e.target.value,
                            key,
                            sheetNumber,
                            "distance"
                          );
                        }}
                        value={
                          teethDist.has(
                            upperTeeth[key].num + "" + upperTeeth[key + 1].num
                          )
                            ? teethDist.get(
                                upperTeeth[key].num + "" + upperTeeth[key + 1].num
                              )?.distance
                            : ""
                        }
                      />
                    }
                    <hr
                      width="1"
                      size="130"
                      style={{ border: "1px dashed black" }}
                    />
                  </div>
                )}
              </>
            ))}
        </div>
        <div style={{ display: "flex", width: "100%", alignItems: "center", columnGap: '5px' }}>
          <span>Right</span>
          <hr
            style={{ flex: 1, backgroundColor: "black", height: "1px" }}
          />
          <span>Left</span>
        </div>

        <div className="teeth-bottom">
          {bottomTeeth.length &&
            bottomTeeth.map((tooth, key) => (
              <>
                <div className="single-tooth">
                  <span>{tooth.num}</span>
                  <div
                    style={{ width: "100%", position: "relative" }}
                    onClick={() => {
                      handleCheckboxChange(
                        key + bottomTeeth.length,
                        sheetNumber
                      );
                    }}
                  >
                    <img
                      src={`/assets/teeth/Tooth${tooth.num}.svg`}
                      style={{
                        scale: selectTeethOnly ? '1.4' : '1',
                        position: "relative",
                        color: tooth.attachment ? "#000" : "#bebfc1",
                      }}
                    />
                    {tooth.attachment === true && (
                      <span className="red-square-upper-sheet-bottom"></span>
                    )}
                  </div>
                  {
                    !selectTeethOnly &&
                    <>
                      {
                        key < bottomTeeth.length - 1 ? (
                          <form>
                            <input
                              type="checkbox"
                              style={{ display: "inline" }}
                              id="MesialB"
                              name={`MesialB${key}${sheetNumber}`}
                              onChange={(e) => {
                                handleDistanceChange(
                                  e.target.checked,
                                  key + bottomTeeth.length,
                                  sheetNumber,
                                  key === 8 ? "m2" : "m",
                                  bottomTeeth.length / 2 <= key ? "right" : "left"
                                );
                              }}
                              value={
                                teethDist.has(
                                  bottomTeeth[key].num + "" + bottomTeeth[key + 1].num
                                )
                                  ? teethDist.get(
                                      bottomTeeth[key].num +
                                        "" +
                                        bottomTeeth[key + 1].num
                                    )?.m
                                  : "false"
                              }
                              checked={
                                key < 8
                                  ?( teethDist.has(
                                    bottomTeeth[key].num +
                                        "" +
                                        bottomTeeth[key + 1].num
                                    )
                                    ? teethDist.get(
                                      bottomTeeth[key].num +
                                          "" +
                                          bottomTeeth[key + 1].num
                                      )?.m==true?true:false
                                    : false)
                                  : (key === 8
                                  ?( teethDist.has(
                                    bottomTeeth[key-1].num +
                                        "" +
                                        bottomTeeth[key ].num
                                    )
                                    ? teethDist.get(
                                      bottomTeeth[key-1].num +
                                          "" +
                                          bottomTeeth[key ].num
                                      )?.m2==true?true:false
                                    : false)
                                  :teethDist.has(
                                    bottomTeeth[key -1].num + "" + bottomTeeth[key].num
                                  )
                                    ? teethDist.get(
                                      bottomTeeth[key-1].num +
                                          "" +
                                          bottomTeeth[key].num
                                      )?.m==true?true:false
                                    : false )
                              }
                            ></input>
                            <label htmlFor={`MisialB${key}`}>M</label>
                            <input
                              type="checkbox"
                              style={{ display: "inline" }}
                              id="DistalB"
                              name={`DistalB${key}${sheetNumber}`}
                              disabled={key === 0}
                              onChange={(e) => {
                                handleDistanceChange(
                                  e.target.checked,
                                  key + bottomTeeth.length,
                                  sheetNumber,
                                  "d",
                                  bottomTeeth.length / 2 <= key ? "right" : "left"
                                );
                              }}
                              value={
                                teethDist.has(
                                  bottomTeeth[key].num + "" + bottomTeeth[key + 1].num
                                )
                                  ? teethDist.get(
                                      bottomTeeth[key].num +
                                        "" +
                                        bottomTeeth[key + 1].num
                                    )?.d
                                  : "false"
                              }
                              checked={
                                ( key<8 )?
                                key>=1?
                                ( teethDist.has(
                                  bottomTeeth[key-1].num + "" +  bottomTeeth[key].num
                                )
                                  ? teethDist.get(
                                    bottomTeeth[key-1].num +
                                        "" +
                                        bottomTeeth[key].num
                                    )?.d==true?true:false
                                  : false)
                                  :false
                                :
                                ( teethDist.has(
                                  bottomTeeth[key].num + "" +  bottomTeeth[key + 1].num
                                )
                                  ? teethDist.get(
                                    bottomTeeth[key].num +
                                        "" +
                                        bottomTeeth[key + 1].num
                                    )?.d==true?true:false
                                  : false)
                              }
                            ></input>
                            <label htmlFor={`DistalB${key}${sheetNumber}`}>D</label>
                          </form>
                        ) : (
                          <form>
                            <input
                              type="checkbox"
                              style={{ display: "inline" }}
                              id="MesialB"
                              name={`MesialB${key}${sheetNumber}`}
                              onChange={(e) => {
                                handleDistanceChange(
                                  e.target.checked,
                                  key + bottomTeeth.length,
                                  sheetNumber,
                                  "m",
                                  bottomTeeth.length / 2 <= key ? "right" : "left"
                                );
                              }}
                              value={
                                teethDist.has(bottomTeeth[key].num + "00")
                                  ? teethDist.get(bottomTeeth[key].num + "00")?.m
                                  : "false"
                              }
                              
                              checked={
                                teethDist.has("3738")
                                  ? teethDist.get("3738")?.m===true?true:false
                                  : false
                              }
                              
                            ></input>
                            <label htmlFor={`MisialB${key}${sheetNumber}`}>M</label>
                            <input
                              type="checkbox"
                              style={{ display: "inline" }}
                              id="DistalB"
                              name={`DistalB${key}${sheetNumber}`}
                              disabled
                              onChange={(e) => {
                                handleDistanceChange(
                                  e.target.checked,
                                  key + bottomTeeth.length,
                                  sheetNumber,
                                  "d",
                                  bottomTeeth.length / 2 <= key ? "right" : "left"
                                );
                              }}
                              value={
                                teethDist.has(bottomTeeth[key].num + "00")
                                  ? teethDist.get(bottomTeeth[key].num + "00")?.d
                                  : "false"
                              }
                            
                            ></input>
                            <label htmlFor={`DistalB${key}${sheetNumber}`}>D</label>
                          </form>
                        )
                      }
                    </>
                  }
                </div>
                {key < bottomTeeth.length - 1 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column-reverse",
                      width: "10px",
                      margin: "auto",
                      alignItems: "center",
                      height: selectTeethOnly ? '70px' : 'unset',
                    }}
                  >
                  {
                    !selectTeethOnly &&
                    <input
                      type="text"
                      style={{ fontSize: "x-small", width: "30px" }}
                      onChange={(e) => {
                        handleDistanceChange(
                          e.target.value,
                          key + bottomTeeth.length,
                          sheetNumber,
                          "distance"
                        );
                      }}
                      value={
                        teethDist.has(
                          bottomTeeth[key].num + "" + bottomTeeth[key + 1].num
                        )
                          ? teethDist.get(
                              bottomTeeth[key].num +
                                "" +
                                bottomTeeth[key + 1].num
                            )?.distance
                          : ""
                      }
                    />
                  }
                    <hr
                      width="1"
                      size="140"
                      style={{ border: "1px dashed black" }}
                    />
                  </div>
                )}
              </>
            ))}
        </div>
      </div>
      <TextField
        variant="outlined"
        multiline
        rows={6}
        fullWidth
        label="Notes"
        size="small"
        onChange={(e) => {
          handleTextChange(e.target.value, sheetNumber);
        }}
        value={sheets[sheetNumber] ? sheets[sheetNumber].notes : ""}
      />
    </Card>
  );
}

export default IPRSheet