import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  dialogTitle: {
    minWidth: '600px',
    fontSize: '22px !important',
  },
  dialogContent: {
    minWidth: '600px',
    '& div, & hr': {
      marginBottom: '10px',
    },
    '& h3': {
      fontSize: '18px',
      fontWeight: '500',
    },
  },
  container: {
    '& h4': {
      fontWeight: '500',
    },
    '& div': {
      display: 'flex',
    },
    '& span': {
      marginLeft: '3px',
    },
  },
}));
