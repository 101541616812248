import { Card, Icon, InlineError } from "@shopify/polaris";
import Select from "react-select";
import React, { useState } from "react";
import { ExportMinor } from "@shopify/polaris-icons";
import axios from "axios";
import moment from "moment";
import Cookie from "js-cookie";

const ScanClinicReports = ({ clinicSelectOptions }) => {
  const [clinicId, setClinicId] = useState(null);
  const [formData, setFormData] = useState({ startDate: "", endDate: "" });
  const [error, setError] = useState("");
  const { startDate, endDate } = formData;

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const OnClickHandler = async () => {
    setError("");
    if (startDate === "" || endDate === "") {
      setError("All Fields are required");
      return;
    }
    try {
      const formattedStartDate = moment(startDate).format("DD/MM/YYYY hh:mm A");
      const formattedEndDate = moment(endDate).format("DD/MM/YYYY hh:mm A");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/clinic-portal-report?clinicId=${clinicId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`,
      );
      if (response?.data?.success === false) {
        setError("Records Empty");
      } else {
        window.open(
          `${process.env.REACT_APP_BASE_URL}/admin/clinic-portal-report?clinicId=${clinicId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
        );
      }
    } catch (error) {
      setError("Records Empty");
      console.log(error);
    }
  };

  const handleSelectClinicReports = (selectedClinicId) => {
    setClinicId(selectedClinicId.value);
  };

  return (
    <Card title="Generate Clinics Scan Reports">
      <Card.Section>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <label htmlFor="clinic-id">Clinics:</label>
            <Select
              id="clinic-id"
              menuPortalTarget={document.body}
              styles={{ menuPortal: base => ({ ...base, zIndex: 100002 }) }}
              onChange={handleSelectClinicReports}
              placeholder="Select clinic"
              options={clinicSelectOptions}
              values={[]}
            />
          </div>
          <div style={{ display: "grid" }}>
            <label htmlFor="start-date">Start Date:</label>
            <input
              id="start-date"
              style={{ padding: "8px", borderRadius: "5px" }}
              type="date"
              value={startDate}
              onChange={onChange}
              name="startDate"
            />
          </div>
          <div style={{ display: "grid" }}>
            <label htmlFor="end-date">End Date:</label>
            <input
              id="end-date"
              style={{ padding: "8px", borderRadius: "5px" }}
              type="date"
              value={endDate}
              onChange={onChange}
              name="endDate"
            />
          </div>
          <button
            style={{
              border: "none",
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
            color="base"
            onClick={OnClickHandler}
          >
            <Icon source={ExportMinor} color="base" />
          </button>
        </div>
        {error && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15px",
            }}
          >
            <InlineError message={error} fieldID={""} />
          </div>
        )}
      </Card.Section>
    </Card>
  );
};

export default ScanClinicReports;
