import { TextField, Select, MenuItem } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { ShopContext } from '../ShopContext';
import { Button } from '@shopify/polaris';

const ShopShippingTable = ({ id = null, amount, code, editable }) => {
    const [Amount, setAmount] = useState(amount);
    const [Code, setCode] = useState(code);
    const [edit, setEdit] = useState(editable);
    const [loading, setLoading] = useState(false);

    const {
        states: {
            shipments,
        },
        actions: {
            storeShopShipping,
        }
    } = useContext(ShopContext);

    const handleSelectChange = (e) => {
        setCode(e.target.value);
    }

    const save = async() => {
        setLoading(true);
        let body = {
            id,
            amount: Amount,
            code: Code,
            country: shipments.countries.find(country => country.value == Code)?.label,
        };
        await storeShopShipping(body);
        setLoading(false);
    }

    return (
        <tr style={{ display: 'flex', padding: '1em 0', gap: '10px' }}>
            <td className='shipping-column' >
                <Select
                    disabled={!edit}
                    style={{ maxWidth: "100%" }}
                    className="plan-field"
                    onChange={handleSelectChange}
                    value={Code ? Code : ''}
                >
                    {
                        shipments.countries.map(country => {
                            return (
                                <MenuItem value={country.value}>{country.label}</MenuItem>
                            )
                        })
                    }
                </Select>
            </td>
            <td className='shipping-column'>
                <TextField
                    disabled={!edit}
                    type='number'
                    className='plan-field'
                    value={Amount}
                    onChange={(e) => {
                        setAmount(e.target.value);
                    }}
                />
            </td>
            <td className='shipping-column'>
                <p>USD {Amount}</p>
            </td>
            <td className='shipping-column'>
                {
                    edit ?
                        <Button
                            disabled={loading}
                            loading={loading}
                            primary
                            onClick={save}
                        >
                            Save
                        </Button> :
                        <Button
                            disabled={loading}
                            loading={loading}
                            primary
                            onClick={() => {
                                setEdit((prev) => (!prev))
                            }}
                        >
                            Edit
                        </Button>
                }
            </td>
        </tr>

    )
}

export default ShopShippingTable
