import { Box, Button, makeStyles } from '@material-ui/core';
import React from 'react'
import ReviewedBy from './ReviewedBy';
import { ReviewStatus } from "../util/constants";

const useStyles = makeStyles(() => {
    return {
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        btnWrapper: {
            display: 'flex',
            columnGap: 5,
        },
        btn: {
            border: "1px solid #C4CDD5",
            borderRadius: "3px",
            background: "linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%)",
            boxShadow: "0 1px 0 0 rgba(22,29,37,0.05)",
        },
    };
});

const ApprovalActions = ({ disabled, reviewer, reviewedAt, reviewStatus, handleChangeReview, withApprovedWithIssuesOption = false }) => {
    const classes = useStyles();

    return (
        <Box className={classes.wrapper}>
            <Box className={classes.btnWrapper}>
                <Button
                    disabled={disabled}
                    className={classes.btn}
                    onClick={() => handleChangeReview(ReviewStatus.approved)}
                >
                    Approved
                </Button>
                <Button
                    disabled={disabled}
                    className={classes.btn}
                    onClick={() => handleChangeReview(ReviewStatus.notApproved)}
                >
                    Not Approved
                </Button>
                {
                    withApprovedWithIssuesOption ?
                    <Button
                        disabled={disabled}
                        className={classes.btn}
                        onClick={() => handleChangeReview(ReviewStatus.approvedWithIssues)}
                    >
                        Approved With Issues
                    </Button> :
                    null
                }
            </Box>
            <ReviewedBy reviewer={reviewer} reviewedAt={reviewedAt} status={reviewStatus}/>
        </Box>
    )
}

export default ApprovalActions