import classnames from "classnames";
import React, { useState } from 'react'
import {
  ProductsMajor,
  DiscountsMajor,
  ShipmentMajor,
  MobileHamburgerMajor,
  CartMajor
} from '@shopify/polaris-icons';
import {
  Icon,
} from "@shopify/polaris";
import "../components/UserLayout/UserLayout.css"
import { Link } from 'react-router-dom';

export default function ShopLayout({ children }) {
  const [openSideNav, setOpenSideNav] = useState(true);
  const mobile = window.matchMedia("(max-width: 768px)").matches;
  const linkColor = "#FF2C46";

  const ontoggleNav = () => {
    setOpenSideNav(!openSideNav);
  }

  const renderSection = (anchor, title = false) => {
    if (openSideNav) {
      if (title) {
        return <span><strong>{anchor}</strong></span>
      }
      return anchor
    } else {
      return ''
    }

  }

  return (
    <div className="HeaderStickyResp" style={{ transition: 0.5, marginLeft: openSideNav ? '195px' : '55px', top: "55px", minWidth: "80%" }}>
      <div>
        <div className={classnames({ blur: mobile && openSideNav })} />
        <div
          className={classnames(
            "sidenav",
            { sidenavOpen: openSideNav },
            { sidenavClose: !openSideNav }
          )}
        >
          <div className='sidenav-title-wrapper' onClick={ontoggleNav}>
            {renderSection('SHOP', true)}
            <div>
              <Icon
                source={MobileHamburgerMajor}
                onClick={ontoggleNav}
              />
            </div>
          </div>
          <Link to="/admin/shop/orders" style={window.location.href.indexOf('/admin/shop/orders') > -1 ? { color: linkColor, fontWeight: 500 } : {}}><span className='iconsDiv'><Icon source={CartMajor} color='base' /><span>Orders</span></span></Link>
          <Link to="/admin/shop/products" style={window.location.href.indexOf('/admin/shop/products') > -1 ? { color: linkColor, fontWeight: 500 } : {}}><span className='iconsDiv'><Icon source={ProductsMajor} color='base' /><span>Products</span></span></Link>
          <Link to="/admin/shop/coupons" style={window.location.href.indexOf('/admin/shop/coupons') > -1 ? { color: linkColor, fontWeight: 500 } : {}}><span className='iconsDiv'><Icon source={DiscountsMajor} color='base' /><span>Coupons</span></span></Link>
          <Link to="/admin/shop/shipping" style={window.location.href.indexOf('/admin/shop/shipping') > -1 ? { color: linkColor, fontWeight: 500 } : {}}><span className='iconsDiv'><Icon source={ShipmentMajor} color='base' /><span>Shipping</span></span></Link>
        </div>
        <div
          style={{ padding: "0px 20px" }}
          className={classnames(
            "main",
            { mainShrink: openSideNav },
            { mainExpand: !openSideNav },
            { noscroll: mobile && openSideNav }
          )}
        >
          {children}
        </div>
      </div>
    </div>
  )
}