import React, { useCallback, useState } from "react";
import {
  Thumbnail,
  Caption,
  DropZone,
  Stack,
  Page,
  TextField,
  Card,
  Checkbox,
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";

import history from "../history";

function TipsAndNews() {
    let imageType = "";
    const [imageKeyImage1, setImageKeyImage1] = useState("");
    const [imageKeyImage2, setImageKeyImage2] = useState("");
    const [filesImage1, setFilesImage1] = useState([]);
    const [filesImage2, setFilesImage2] = useState([]);
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
    const [titleValue, setTitleValue] = useState("");
    const [arTitleValue, setArTitleValue] = useState("");
    const [descriptionValue, setdescriptionValue] = useState("");
    const [arDescriptionValue, setArDescriptionValue] = useState("");
    const [linkValue, setlinkValue] = useState("");
    const [arLinkValue, setArLinkValue] = useState("");
    const [checkShowOnApp, setcheckShowOnApp] = useState(false);

    const handleChangeShowOnApp = useCallback(
      (newChecked) => setcheckShowOnApp(newChecked),
      []
    );
    
    const handleSaveTips = async () => {
        const bodyObj = {
            title: titleValue,
            ar_title: arTitleValue,
            description: descriptionValue,
            ar_description: arDescriptionValue,
            link: linkValue,
            ar_link: arLinkValue,
            thumbnail: imageKeyImage1,
            ar_thumbnail: imageKeyImage2,
            showOnApp: checkShowOnApp,
        }
        const response = await axios.post('/admin/v1/tipsAndNews/create', bodyObj, 
        {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          })
          history.push('/admin/mobile-app')
          console.log(response)
    }
    const handleChangeTitleValue = useCallback(
      (newValue) => setTitleValue(newValue),
      []
    );
    const handleChangeArTitleValue = useCallback(
      (newValue) => setArTitleValue(newValue),
      []
    );
    const handleChangeDescriptionValue = useCallback(
        (newValue) => setdescriptionValue(newValue),
        []
      );
    const handleChangeArDescriptionValue = useCallback(
      (newValue) => setArDescriptionValue(newValue),
      []
    );
      const handleChangeLinkValue = useCallback(
        (newValue) => setlinkValue(newValue),
        []
      );
      const handleChangeArLinkValue = (newValue) => {
        setArLinkValue(newValue)
      }
  
      const sendImage1 = useCallback(
        (filesImage1) => {
          imageType = filesImage1[filesImage1.length - 1].type;
          let headersVar = null;
          let url2 = "";
          let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
          const form_data = new FormData();
          form_data.append("content_type", imageType);
          axios
            .post("/admin/v1/images/s3", form_data, {
              headers: {
                Authorization: "Bearer " + Cookie.get("token"),
              },
            })
            .then((res) => {
              setImageKeyImage1(res.data.key);
              console.log(res.data.key)
              url2 = res.data.url;
              headersVar = res.data.headers;
              axios
                .put(url2, filesImage1[filesImage1.length - 1], {
                  headers: {
                    "x-amz-acl": "public-read-write",
                    "Content-Type": imageType,
                  },
                })
                .then((res) => {
                })
                .catch((err) => {
                  console.log('err', err);
                });
            })
            .catch((err) => {
              console.log('err', err);
            });
        },
    
        [filesImage1]
      );
      
      const sendImage2 = useCallback(
        (filesImage2) => {
          imageType = filesImage2[filesImage2.length - 1].type;
          let headersVar = null;
          let url2 = "";
          const form_data = new FormData();
          form_data.append("content_type", imageType);
          axios
            .post("/admin/v1/images/s3", form_data, {
              headers: {
                Authorization: "Bearer " + Cookie.get("token"),
              },
            })
            .then((res) => {
              setImageKeyImage2(res.data.key);
              url2 = res.data.url;
              headersVar = res.data.headers;
              axios
                .put(url2, filesImage2[filesImage2.length - 1], {
                  headers: {
                    "x-amz-acl": "public-read-write",
                    "Content-Type": imageType,
                  },
                })
                .then((res) => {
                })
                .catch((err) => {
                  console.log('err', err);
                });
            })
            .catch((err) => {
              console.log('err', err);
            });
        },
    
        [filesImage2]
      );
  const handleDropZoneImage1 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesImage1((filesImage1) => [...filesImage1, ...acceptedFiles]),
    []
  );
  const handleDropZoneImage2 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesImage2((filesImage2) => [...filesImage2, ...acceptedFiles]),
    []
  );
  const fileUploadImage1 = !filesImage1.length && <DropZone.FileUpload />;
  let uploadedFilesImage1 = filesImage1.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesImage1[filesImage1.length - 1].name}
        source={
          validImageTypes.indexOf(filesImage1[filesImage1.length - 1].type) > 0
            ? window.URL.createObjectURL(filesImage1[filesImage1.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesImage1[filesImage1.length - 1].name}{" "}
        <Caption>{filesImage1[filesImage1.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );

      const fileUploadImage2 = !filesImage2.length && <DropZone.FileUpload />;
      let uploadedFilesImage2 = filesImage2.length > 0 && (
        <Stack alignment="center">
          <Thumbnail
            size="small"
            alt={filesImage2[filesImage2.length - 1].name}
            source={
              validImageTypes.indexOf(filesImage2[filesImage2.length - 1].type) > 0
                ? window.URL.createObjectURL(filesImage2[filesImage2.length - 1])
                : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
            }
          />
          <div>
            {filesImage2[filesImage2.length - 1].name}{" "}
            <Caption>{filesImage2[filesImage2.length - 1].type} bytes</Caption>
          </div>
        </Stack>
      );

      return(
        <Page title="Tips And News" breadcrumbs={[
            {
              content: "Dashboard",
              url: "/admin/mobile-app" ,
            },
          ]}>
            <Card title="Create">
            <div style={{padding: '60px', display:"grid", gap:"15px", position: "relative"}}>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'column', width: '45%', gap: 15}}>
                  <TextField value={titleValue} placeholder="Title En" onChange={handleChangeTitleValue}/>
                  <TextField value={descriptionValue} placeholder="Description En" onChange={handleChangeDescriptionValue}/>
                  <TextField value={linkValue} placeholder="Link" onChange={handleChangeLinkValue}/>
                  <DropZone onDrop={handleDropZoneImage1} onDropAccepted={sendImage1}>
                      {uploadedFilesImage1}
                      {fileUploadImage1}
                  </DropZone>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: '45%', gap: 15}}>
                  <TextField value={arTitleValue} placeholder="Title Ar" onChange={handleChangeArTitleValue}/>
                  <TextField value={arDescriptionValue} placeholder="Description Ar" onChange={handleChangeArDescriptionValue}/>
                  <TextField value={arLinkValue} placeholder="Link Ar" onChange={handleChangeArLinkValue}/>
                  <DropZone onDrop={handleDropZoneImage2} onDropAccepted={sendImage2}>
                      {uploadedFilesImage2}
                      {fileUploadImage2}
                  </DropZone>
                </div>
              </div>
              <Checkbox
                label="Show On App"
                checked={checkShowOnApp}
                onChange={handleChangeShowOnApp}
              />
            <button onClick={handleSaveTips} style={{position:"absolute", right:"6.5%", bottom :"2%", padding:'10px 15px', fontWeight:"bold", backgroundColor:"rgb(0 123 92)", color:"white", border:"none", borderRadius:"5px", cursor: 'pointer'}}>Save</button>
            </div>
            </Card>
        </Page>   
      )
}
export default TipsAndNews;
