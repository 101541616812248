import React, { useState, useEffect } from "react";
import {
  Checkbox,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Card,
  PageActions,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";
import { Editor } from "@tinymce/tinymce-react";

import { useParams } from "react-router-dom";

//import { Select } from '@shopify/app-bridge/actions/ResourcePicker';

function EditNotification() {
  var { userId, notificationId } = useParams();
  const [valueTitleEn, setValueTitleEn] = useState();
  const [valueMessageEn, setValueMessageEn] = useState();
  const [valueButtonTextEn, setValueButtonTextEn] = useState();
  const [valueTitleAr, setValueTitleAr] = useState();
  const [valueMessageAr, setValueMessageAr] = useState();
  const [valueButtonTextAr, setValueButtonTextAr] = useState();
  const [valueButtonURL, setValueButtonURL] = useState();
  const [checked, setChecked] = useState();

  const handleChangeMessageEn = (e) => {
    setValueMessageEn(e.target.getContent());
  };
  const handleChangeMessageAr = (e) => {
    setValueMessageAr(e.target.getContent());
  };

  useEffect(() => {
    axios
      .get(`admin/v1/users/${userId}/notifications/${notificationId}/show`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        result.data.data.title &&
          result.data.data.title.en &&
          setValueTitleEn(result.data.data.title.en);
        result.data.data.title &&
          result.data.data.title.ar &&
          setValueTitleAr(result.data.data.title.ar);
        result.data.data.message &&
          result.data.data.message.en &&
          setValueMessageEn(result.data.data.message.en);
        result.data.data.message &&
          result.data.data.message.ar &&
          setValueMessageAr(result.data.data.message.ar);
        result.data.data.button_text &&
          result.data.data.button_text.en &&
          setValueButtonTextEn(result.data.data.button_text.en);
        result.data.data.button_text &&
          result.data.data.button_text.ar &&
          setValueButtonTextAr(result.data.data.button_text.ar);
        result.data.data.button_url &&
          setValueButtonURL(result.data.data.button_url);
        setChecked(result.data.data.closed_by_admin);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Page
      title="Add Notification"
      breadcrumbs={[
        {
          content: "Dashboard",
          url: "/admin/users/" + userId + "/dashboard",
        },
      ]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Title En</TextStyle>
          <TextField value={valueTitleEn} onChange={handleChangeTitleEn} />

          <TextStyle variation="strong">Title Ar</TextStyle>
          <TextField value={valueTitleEn} onChange={handleChangeTitleAr} />
          <TextStyle variation="strong">Message En</TextStyle>

          <Editor
            apiKey="z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve"
            value={valueMessageEn}
            init={{
              branding: false,
              height: 200,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
            }}
            onChange={handleChangeMessageEn}
          />
          <TextStyle variation="strong">Message Ar</TextStyle>

          <Editor
            apiKey="z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve"
            value={valueMessageAr}
            init={{
              branding: false,
              height: 200,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic backcolor | \
 alignleft aligncenter alignright alignjustify | \
 bullist numlist outdent indent | removeformat | help",
            }}
            onChange={handleChangeMessageAr}
          />
          <TextStyle variation="strong">Button Text En</TextStyle>
          <TextField
            value={valueButtonTextEn}
            onChange={handleChangeButtonTextEn}
          />
          <TextStyle variation="strong">Button Text Ar</TextStyle>
          <TextField
            value={valueButtonTextAr}
            onChange={handleChangeButtonTextAr}
          />
          <TextStyle variation="strong">Button URL</TextStyle>
          <TextField value={valueButtonURL} onChange={handleChangeButtonURL} />
          <Checkbox
            label="Close Notification"
            checked={checked}
            onChange={handleChangeCb}
          />
        </FormLayout>
      </Card>

      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
        secondaryActions={[
          {
            content: "Delete",
            onClick: () =>
              axios
                .delete(
                  "admin/v1/users/" +
                    userId +
                    "/notifications/" +
                    notificationId +
                    "/delete",
                  {
                    headers: {
                      Authorization: "Bearer " + Cookie.get("token"),
                    },
                  }
                )
                .then((result) => {
                  history.push("/admin/users/" + userId + "/dashboard");
                })
                .catch((err) => console.log(err)),
          },
        ]}
      />
    </Page>
  );
  function handleChangeCb(checked) {
    setChecked(checked);
  }
  function handleChangeTitleEn(valueTitleEn) {
    setValueTitleEn(valueTitleEn);
  }

  function handleChangeButtonTextEn(valueButtonTextEn) {
    setValueButtonTextEn(valueButtonTextEn);
  }
  function handleChangeTitleAr(valueTitleAr) {
    setValueTitleAr(valueTitleAr);
  }

  function handleChangeButtonTextAr(valueButtonTextAr) {
    setValueButtonTextAr(valueButtonTextAr);
  }
  function handleChangeButtonURL(valueButtonURL) {
    setValueButtonURL(valueButtonURL);
  }

  function handleSave() {
    const bodyObj = {
      title: {
        en: valueTitleEn,
        ar: valueTitleAr,
      },
      message: {
        en: valueMessageEn,
        ar: valueMessageAr,
      },
      button_text: {
        en: valueButtonTextEn,
        ar: valueButtonTextAr,
      },
      button_url: valueButtonURL,
      closed_by_admin: checked,
    };

    axios
      .patch(
        "admin/v1/users/" +
          userId +
          "/notifications/" +
          notificationId +
          "/update",
        bodyObj,
        {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      )
      .then((res) => {
        history.push("/admin/users/" + userId + "/dashboard");
      })
      .catch((err) => console.log(err));
  }
}
export default EditNotification;
