import React from "react";
import {
  Image,
  DisplayText,
  Card,
} from "@shopify/polaris";
import { Button as ButtonMui,Typography } from "@material-ui/core";
import emptyIcon from "../img/emptyList.svg";
import { CardActions } from "@mui/material";
import ExistingProductCurrency from "./ExistingProductCurrency";

function ProductCurrency({product_id, items, setItems, isEmpty, setIsEmpty, setSuccess}) {
    return (
        <Card sectioned title="Prices per country">
            <CardActions style={{ float: "right" }}>
                <ButtonMui variant="contained" style={{backgroundColor: "rgb(0 128 96)", color: "white", padding: "1.5em 2em"}}
                    onClick={() => {
                        setItems((prev) => [...prev, {
                            id: null,
                            product_id: product_id,
                            local_price: 0,
                            country: '',
                            currency: '',
                            editable: true,
                        }]);
                        setIsEmpty(false);
                    }}>
                    <Typography variant="h6" >Add New</Typography>
                </ButtonMui>
            </CardActions>
            <table style={{display: "grid", width: "100%", overflowX: "auto", overflowY: "hidden", padding: "1em 0"}}>
                <tr style={{display: "flex", gap: '10px'}}>
                <th className="plan-header" >Country</th>
                <th className="plan-header">Currency</th>
                <th className="plan-header">Local Price</th>
                <th className="plan-header"></th>
                </tr>
                {items.map((item) => (
                    <ExistingProductCurrency product_id={product_id} id={item.id} currency={item.currency} country={item.country} local_price={item.local_price} editable={item.editable} setSuccess={setSuccess} />
                ))}
            </table>
            {isEmpty ? (
                <div className="message-no-result">
                    <div className="message-no-result-img">
                        <Image src={emptyIcon}></Image>{" "}
                    </div>
                    <DisplayText size="small">No results found</DisplayText>
                    <br />
                </div>
            ) : null}
        </Card>
    )
}

export default ProductCurrency