import React, { useEffect, useRef, useState } from "react";
import NavigationLayout from "../../components/NavigationLayout";
import "./job.css";
import { Page, Card } from "@shopify/polaris";
import axios from "../../axios";
import { useParams ,Router,useLocation} from "react-router-dom";
import history from "../../history";
import Cookies from "js-cookie";
import AddQuestions from "./AddQuestions";
import { ToastContainer, toast } from "react-toastify";
import ThinLoading from "../../components/Loading/thin-loading.component";
import RichTextEditor from "react-rte";

function AddNewJob() {
  const { id } = useParams();
  const [departmentsArray, setDepartmentsArray] = useState([]);
  const [show, setShow] = useState(false);
  const myDivRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [referrerurl, setReferrerUrl] = useState('');
  const scrollToDiv = () => {
    myDivRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const [formData, setFormData] = useState({
    title: "",
    department_id: "",
    status: "",
    location: "",
    workplace_type: "",
    employment_type: "",
    job_description: RichTextEditor.createEmptyValue(),
    required_skills: RichTextEditor.createEmptyValue(),
    cover_image: "",
    linkedin_link: "",
  });
  const [formErrors, setFormErrors] = useState({
    title: "",
    department_id: "",
    location: "",
    workplace_type: "",
    employment_type: "",
    status: "",
    job_description: "",
    required_skills:"",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };
  const validateForm = () => {
    const errors = {};
  
    // Check if required fields are empty
    Object.entries(formData).forEach(([key, value]) => {
      if (
        value === "" &&
        [
          "title",
          "department_id",
          "location",
          "workplace_type",
          "employment_type",
          "status",
        ].includes(key)
      ) {
        errors[key] = "This field is required";
      }
    });
    if (
      !formData.required_skills.toString('html').trim() ||
      formData.required_skills.toString('html') === "<p><br></p>"
    ){
      errors.required_skills = "Required skills is required";
    }
  
    if (
      !formData.job_description.toString('html').trim() ||
      formData.job_description.toString('html') === "<p><br></p>"
    ) {
      errors.job_description = "Job description is required";
    }
      setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  
  const location = [
    { id: 0, name: "Select location", value: "" },
    { id: 1, name: "Bahrain", value: "Bahrain" },
    { id: 2, name: "Kuwait", value: "Kuwait" },
    { id: 3, name: "Lebanon", value: "Lebanon" },
    { id: 4, name: "Qatar", value: "Qatar" },
    { id: 5, name: "Saudi Arabia", value: "Saudi Arabia" },
    { id: 6, name: "United Arab Emirates", value: "United Arab Emirates" },
    { id: 7, name: "United Kingdom", value: "United Kingdom" },
    { id: 8, name: "Other", value: "Other" }
  ];
  
  const workplacetype = [
    { id: 0, name: "Select Workplace Type", value: "" },
    { id: 1, name: "On-site", value: "On-site" },
    { id: 2, name: "Remote", value: "Remote" },
    { id: 3, name: "Hybrid", value: "Hybrid" },
  ];
  const employmenttype = [
    { id: 0, name: "Select Employment Type ", value: "" },

    { id: 1, name: "Full-time", value: "Full-time" },
    { id: 2, name: "Part-time", value: "Part-time" },
    { id: 3, name: "Internship", value: "Internship" },
    { id: 4, name: "Contract", value: "Contract" },
    { id: 5, name: "Freelance/Project Based", value: "Freelance/Project Based" },

  ];
  const status = [
    { id: 0, name: "Select Status", value: "" },
    { id: 1, name: "Open", value: "Open" },
    { id: 2, name: "Closed", value: "Closed" },
    { id: 3, name: "Draft", value: "Draft" },
  ];

  useEffect(() => {
    const Departments = async () => {
      try {
        const response = await axios.get(`admin/v1/career?id=${id}`, {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        });
        setDepartmentsArray(response.data.department);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    Departments();
  }, []);

  useEffect(() => {
    const fetchCareer = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`admin/v1/career?id=${id}`, {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        });
        setFormData({
          title: response.data.data.title ?? "",
          department_id: response.data.data.department_id,
          status: response.data.data.status,
          location: response.data.data.location,
          workplace_type: response.data.data.workplace_type,
          employment_type: response.data.data.employment_type,
          job_description: RichTextEditor.createValueFromString(
            response.data.data.job_description,
            "html"
          ),
          required_skills: RichTextEditor.createValueFromString(
            response.data.data.required_skills,
            "html"
          ),
          cover_image: response.data.data.cover_image,
          linkedin_link: response.data.data.linkedin_link ?? "",
        });
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCareer();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const requiredSkillsHtml = formData.required_skills.toString("html");
        const jobDescriptionHtml = formData.job_description.toString("html");

        const updatedFormData = {
          ...formData,
          required_skills: requiredSkillsHtml,
          job_description: jobDescriptionHtml,
        };
        const response = await axios.post(
          `admin/v1/career/${id}/create`,
          updatedFormData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + Cookies.get("token"),
            },
          }
        );
        if (response.status == 200) {
          if (response.data.data.id != id) {
            history.push({
              pathname: `/admin/jobs/${response.data.data.id}`,
              state: { referrer: referrerurl },
            })
          }
          toast.success(response.data.message, 3000);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  useEffect(() => {
    if (departmentsArray.length > 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        department_id: departmentsArray[0].id,
      }));
    }
  }, [departmentsArray]);
  const deletejob = async () => {
    setLoading(true);
    try {
      const response = await axios.delete(`admin/v1/career/delete`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("token"),
        },
        data: {
          id: id,
          status: formData.status,
        },
      });

      if (response) {
        history.push(`/admin/careers`);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleJobDescriptionChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      job_description: value,
    }));
  };
  const handleSkillsChange = (value) => {
   
      setFormData((prevFormData) => ({
        ...prevFormData,
        required_skills: value,
      }));
    
  };
  const location_url = useLocation();
  const savedReferrer = localStorage.getItem('referrer');

  useEffect(() => {
    const ref = location_url.state && location_url.state.referrer;
    if(ref)
    {
      localStorage.setItem('referrer', ref);
    if (savedReferrer) {
        setReferrerUrl(savedReferrer);
    }}
}, [location_url,savedReferrer]);

const handleGoBack = () => {
    localStorage.setItem('referrer', '/admin/carrers');
    history.push(referrerurl);
};
useEffect(()=>{

  if(!referrerurl)
  {
    if(savedReferrer)
    {
      setReferrerUrl(savedReferrer)
    }
    else{
      localStorage.setItem('referrer', '/admin/careers');
      setReferrerUrl(savedReferrer)
    }
  }
},[referrerurl,savedReferrer])

  return (
    <NavigationLayout>
    <Router history={history} >
      <Page 
      title="Add Job"
      fullWidth>
        <ToastContainer />

        <Card sectioned>
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className="form-row-question"
              style={{
                display: "flex",
                justifyContent: "start",
                paddingBottom: "30px",
              }}
            >
              {referrerurl&&<button onClick={() => handleGoBack()}>Go Back</button>}
            </div>
          </div>
          {loading ? (
            <div
              style={{
                textAlign: "center",
                paddingTop: "5vh",
                paddingBottom: "5vh",
              }}
            >
              <ThinLoading />
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="career-form-group" style={{ display: "flex" }}>
                <div className="w50">
                  <label>Title:</label>
                  <input
                    type="text"
                    name="title"
                    className="input_form"
                    value={formData.title}
                    onChange={handleChange}
                    required
                  />
                  {formErrors["title"] && (
                    <div className="error-message" style={{ color: "red" }}>
                      {formErrors["title"]}
                    </div>
                  )}
                </div>
                <div>
                  <label>Department:</label>
                  <select
                    name="department_id"
                    value={formData.department_id}
                    onChange={handleChange}
                    required
                  >
                    {departmentsArray.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  {formErrors["department_id"] && (
                    <div className="error-message" style={{ color: "red" }}>
                      {formErrors["department_id"]}
                    </div>
                  )}
                </div>
                <div>
                  <label>Location:</label>
                  <select
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                    required
                  >
                    {location.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  {formErrors["location"] && (
                    <div className="error-message" style={{ color: "red" }}>
                      {formErrors["location"]}
                    </div>
                  )}
                </div>
                <div>
                  <label>Workplace Type:</label>
                  <select
                    name="workplace_type"
                    value={formData.workplace_type}
                    onChange={handleChange}
                    required
                  >
                    {workplacetype.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  {formErrors["workplace_type"] && (
                    <div className="error-message" style={{ color: "red" }}>
                      {formErrors["workplace_type"]}
                    </div>
                  )}
                </div>
                <div>
                  <label>Employment Type:</label>
                  <select
                    name="employment_type"
                    value={formData.employment_type}
                    onChange={handleChange}
                    required
                  >
                    {employmenttype.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  {formErrors["employment_type"] && (
                    <div className="error-message" style={{ color: "red" }}>
                      {formErrors["employment_type"]}
                    </div>
                  )}
                </div>

                <div>
                  <div>
                    <label>Status:</label>
                    <select
                      name="status"
                      value={formData.status}
                      onChange={handleChange}
                      required
                    >
                      {status.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    {formErrors["status"] && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formErrors["status"]}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <label>Job Description:</label>
                  <div>
                    <RichTextEditor
                        rootStyle={{
                          fontFamily:
                            "Eina03-Regular"
                        }}
                      className="rich-text-editor RichTextEditor_job "
                      style={{ height: "200px" }}
                      value={formData.job_description}
                      onChange={handleJobDescriptionChange}
                    />
                  </div>
                  {formErrors["job_description"] && (
                    <div className="error-message" style={{ color: "red" }}>
                      {formErrors["job_description"]}
                    </div>
                  )}
                </div>

                <div>
                  <label>Required Skills:</label>
                  <RichTextEditor
                    rootStyle={{
                      fontFamily:
                        "Eina03-Regular"
                    }}
                    className="rich-text-editor RichTextEditor_job "
                    style={{ height: "200px" }}
                    value={formData.required_skills}
                    onChange={handleSkillsChange}
                  />

                  {formErrors["required_skills"] && (
                    <div className="error-message" style={{ color: "red" }}>
                      {formErrors["required_skills"]}
                    </div>
                  )}
                </div>

                <div>
                  <label>LinkedIn job:</label>
                  <input
                    type="text"
                    name="linkedin_link"
                    value={formData.linkedin_link}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex ",
                  justifyContent: "end",
                  padding: "2rem 0",
                }}
              >
                {id !== "0" && (
                  <button
                    type="button"
                    onClick={() => deletejob()}
                    className="addnewjob"
                    style={{
                      cursor: "pointer",
                      marginInline: "10px",
                      backgroundColor: "red",
                    }}
                  >
                    Delete
                  </button>
                )}

                <button
                  type="submit"
                  className="addnewjob"
                  style={{ cursor: "pointer" }}
                  onClick={handleSubmit}
                >
                  Save
                </button>
                {id !== "0" && (
                  <button
                    type="button"
                    className="addnewjob"
                    style={{ cursor: "pointer", marginInline: "10px" }}
                    onClick={() => setShow(true) & scrollToDiv()}
                  >
                    Questions
                  </button>
                )}
              </div>
            </form>
          )}
        </Card>
      </Page>
      <div ref={myDivRef}>
        <AddQuestions id={id} show={show} myDivRef={myDivRef} referrerurl={referrerurl} />
      </div>
      </Router>
    </NavigationLayout>
  );
}
export default AddNewJob;
