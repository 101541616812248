import { useEffect, useState } from 'react';
import {
  Paper,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  styled,
  List,
  Typography, Divider,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import ClinicsReportsLoadingSpinner from '../../../../Reports/ClinicReports/clinics-reports-loading-spinner.component';
import SubListTemplates from './sub-list-templates.component';

const CategoriesListItem = styled(ListItem)(({ isSelected }) => ({
  border: `1px solid ${isSelected ? '#FFF' : '#008060'}`,
  backgroundColor: isSelected ? '#008060' : '#FFF',
  color: isSelected ? '#FFF' : '#000',
  borderRadius: '16px',
  flexBasis: 'max-content',
  cursor: 'pointer',
  '& span': {
    fontSize: '14px',
    fontWeight: '600',
  },
}));

const CategoriesList = styled(List)(() => ({
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  gap: '5px',
  justifyContent: 'center',
  padding: '1rem',
}));

const CollapsibleSmilePlanCategories = ({
  smilePlanCategoriesAndTemplates,
  getSmilePlanCategoriesWithTemplates,
  loadingSmilePlanCategoriesAndTemplates,
  valueTreatmentDescriptionEn,
  setvalueTreatmentDescriptionEn,
  valueTreatmentDescriptionAr,
  setvalueTreatmentDescriptionAr,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedBubble, setSelectedBubble] = useState(null);

  useEffect(() => {
    void getSmilePlanCategoriesWithTemplates();
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleBubbleClick = (bubble) => {
    setSelectedBubble(bubble);
  };

  if (loadingSmilePlanCategoriesAndTemplates) {
    return <ClinicsReportsLoadingSpinner />;
  }

  return (
    <Paper>
      <ListItem onClick={handleExpandClick}>
        <ListItemIcon>
          <ExpandMore />
        </ListItemIcon>
        <Typography sx={{ fontSize: '18px' }}>Description Templates</Typography>
      </ListItem>
      <Collapse in={expanded}>
        <CategoriesList>
          {smilePlanCategoriesAndTemplates.map((bubble, index) => (
            <CategoriesListItem
              key={index}
              isSelected={selectedBubble === bubble}
              onClick={() => handleBubbleClick(bubble)}
            >
              <ListItemText primary={bubble.name} />
            </CategoriesListItem>
          ))}
        </CategoriesList>
        <Divider/>
        {selectedBubble !== null &&
          selectedBubble?.templates &&
          selectedBubble?.templates.length > 0 && (
            <SubListTemplates
              templates={selectedBubble.templates}
              valueTreatmentDescriptionEn={valueTreatmentDescriptionEn}
              setvalueTreatmentDescriptionEn={setvalueTreatmentDescriptionEn}
              valueTreatmentDescriptionAr={valueTreatmentDescriptionAr}
              setvalueTreatmentDescriptionAr={setvalueTreatmentDescriptionAr}
            />
          )}
      </Collapse>
    </Paper>
  );
};

export default CollapsibleSmilePlanCategories;
