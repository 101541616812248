import React, { useCallback, useState, useEffect } from "react";
import {
  TextStyle,
  Page,
  FormLayout,
  TextField,
  Card,
  Modal,
  PageActions,
  Checkbox,
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";
import history from "../history";

import {useLocation, useParams} from "react-router-dom";
import CommercialInvoice from "./Commercial Invoice/commercial-invoice.component";
function EditTracking() {
  const { orderId, trackingId, type } = useParams();
  const [valueTrackingNumber, setValueTrackingNumber] = useState();
  const [tracking, setTracking] = useState(null);
  const [shipping, setShipping] = useState([]);
  const location = useLocation();
  const {userId} = location.state;
  const [popupActive, setPopupActive] = useState(false);
  const [errorTrackingNumber, setErrorTrackingNumber] = useState("");
  const [checkedSendEmail, setCheckedSendEmail] = useState(false);
  const handleChangeSendEmail = useCallback(
    (newChecked) => setCheckedSendEmail(newChecked),
    []
  );
  useEffect(() => {
    setPopupActive(true);
    axios
      .get(`admin/v1/orders/${orderId}/trackings/${trackingId}`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setValueTrackingNumber(result.data.data.tracking.tracking_number);
        setTracking(result.data.data.tracking);
        setShipping(result.data.data.shipping);
      })
      .catch((err) => console.log(err)).finally(() => setPopupActive(false));
  }, []);

  const handleBackEditTracking = () => {
    history.push(
        `/admin/orders/${orderId}/trackings/${type === "dispatch" ? "dispatch" : ""}`,
        { userId }
    );
  }

  return (
    <Page
      title="Edit Tracking Number"
      breadcrumbs={[
        {
          content: "List Of trackings",
          onAction: handleBackEditTracking,
        },
      ]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Tracking Number</TextStyle>
          <TextField
            value={valueTrackingNumber}
            onChange={handleChangeTrackingNumber}
            error={errorTrackingNumber}
          />
          <Checkbox
            label="Send Email"
            checked={checkedSendEmail}
            onChange={handleChangeSendEmail}
          />
        </FormLayout>
      </Card>
      <CommercialInvoice tracking={tracking} shipping={shipping} />
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            onClick: () => {
              setPopupActive(true);
              axios
                  .delete(`admin/v1/orders/${orderId}/trackings/${trackingId}`, {
                    headers: {
                      Authorization: "Bearer " + Cookie.get("token"),
                    },
                  })
                  .then((result) => {
                    history.push(`/admin/orders/${orderId}/trackings/${type === 'dispatch' ? 'dispatch' : ''}`, {userId});
                  })
                  .catch((err) => console.log(err)).finally(() => setPopupActive(false));
              }
            },
        ]}
      />
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );
  function handleChangeTrackingNumber(valueTrackingNumber) {
    setValueTrackingNumber(valueTrackingNumber);
  }

  function handleSave() {
    setErrorTrackingNumber("");
    setPopupActive(true);
    const bodyObj = {
      tracking_number: valueTrackingNumber.replace(/\s/g, ""),
      sending_email: checkedSendEmail,
    };
    axios
      .put(`admin/v1/orders/${orderId}/trackings/${trackingId}`, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        setPopupActive(false);
        if (res.data.success === false) {
          setErrorTrackingNumber("Invalid tracking number");
        } else {
          history.push(`/admin/orders/${orderId}/trackings/${type === 'dispatch' ? 'dispatch' : ''}`, {userId});
        }
      })

      .catch(function (error) {
        setErrorTrackingNumber("Invalid tracking number");
      }).finally(() => setPopupActive(false));
  }
}
export default EditTracking;
