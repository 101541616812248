import React from "react";
import { Switch, Route, Router, withRouter } from "react-router-dom";
import history from "./history";
import { AppProvider } from "@shopify/polaris";
import Settings from "./Settings";
import AddCurrency from "./AddCurrency";
import EditCurrency from "./EditCurrency";
import NavigationLayout from "./components/NavigationLayout";

function MainSettings() {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/settings" component={Settings} />
          <Route path="/admin/settings/addcurrency" component={AddCurrency} />
          <Route path="/admin/settings/editcurrency" component={EditCurrency} />
        </Switch>
      </Router>
    </NavigationLayout>
  );
}
export default MainSettings;
