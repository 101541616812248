import { CircularProgress } from '@material-ui/core';
import { Badge, Button, Card, TextField } from '@shopify/polaris'
import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import axios from '../../axios';
import Cookies from 'js-cookie';
import { getUserStatus, switchUserStatusColors, userStatuses } from '../../util/helpers';

const WhatsNewList = () => {
    const [versionDetails, setVersionDetails] = useState({
        loading: false,
        os: '',
        version: '',
        userStatuses: [],
    });
    const [appData, setAppData] = useState({
        loading: false,
        versions: [],
    });
    const osOptions = [
        { value: 'ios', label: 'iOS' },
        { value: 'android', label: 'Android' },
    ];

    const handleChange = (property, selectedOption) => {
        setVersionDetails(prev => ({
            ...prev,
            [property]: selectedOption,
        }));
    }

    const saveVersion = () => {
        if(!versionDetails.os || !versionDetails.version) {
            return;
        }

        setVersionDetails(prev => ({
            ...prev,
            loading: true,
        }));

        let body = {
            os: versionDetails.os.value,
            version: versionDetails.version,
            user_statuses: versionDetails.userStatuses.map(status => status.value),
        };

        axios.post('/admin/v1/mobile/store-whats-new-version', body, {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
        }).then((res) => {
            if(res.data.success) {
                setVersionDetails({
                    os: '',
                    version: '',
                    userStatuses: [],
                });
                fetchVersions();
            }
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setVersionDetails(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const fetchVersions = () => {
        setAppData(prev => ({
            ...prev,
            loading: true,
        }));
        axios.get('/admin/v1/mobile/versions', {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
        }).then((res) => {
            setAppData(prev => ({
                ...prev,
                versions: res.data.app_versions,
            }));
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setAppData(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const publishAppVersion = (versionId) => {
        let body = {
            id: versionId,
        };
        axios.post(`/admin/v1/mobile/publish-app-version`, body, {
            headers: {
                Authorization: "Bearer " + Cookies.get("token"),
              },
        }).then((res) => {
            fetchVersions();
        }
        ).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        fetchVersions();
    }, [])

    return (
        <Card
            sectioned
            title="What's New?"
        >
            <div className='whats-new-options-container'>
                <div className='whats-new-option-container'>
                    <Select
                        className='whats-new-option-os'
                        placeholder="Select OS"
                        name="select-os"
                        options={osOptions}
                        value={versionDetails.os}
                        onChange={(e) => handleChange('os', e)}
                    />
                    <div className='whats-new-option-version'>
                        <TextField
                            placeholder='Version'
                            value={versionDetails.version}
                            onChange={(e) => handleChange('version', e)}
                        />
                    </div>
                    <Select
                        isMulti
                        className='whats-new-option-status'
                        placeholder="Select User Statuses"
                        name="select-user-statuses"
                        options={userStatuses}
                        value={versionDetails.userStatuses}
                        onChange={(e) => handleChange('userStatuses', e)}
                        
                    />
                    <div className='whats-new-option-btn'>
                        <Button primary onClick={saveVersion} disabled={versionDetails.loading} loading={versionDetails.loading}>Save</Button>
                    </div>
                </div>
                <div className='whats-new-list'>
                {
                    appData.loading ?
                    <CircularProgress color='primary' />
                    :
                    appData.versions.map((version, index) => (
                        <Card
                            key={index}
                            sectioned
                            actions={[
                                {
                                    content: 'Publish',
                                    onAction: () => publishAppVersion(version.id),
                                    disabled: version.published_at,
                                },
                                {
                                    content: 'Edit',
                                    url: `/admin/mobile-app/${version.id}/whats-new`,
                                },
                            ]}
                        >
                            <div className='whats-new-item-container'>
                                <p><b>OS:</b> {osOptions.find(os => os.value == version.os).label}</p>
                                <p><b>version:</b> {version.version}</p>
                                <div className='whats-new-item-statuses'>
                                    {
                                        version.mobile_app_version_statuses.map((status, index) => (
                                            <Badge
                                                key={index}
                                                status={switchUserStatusColors(getUserStatus(status.user_status))}
                                            >
                                                {getUserStatus(status.user_status)}
                                            </Badge>
                                        ))
                                    }
                                </div>
                            </div>
                        </Card>
                    ))
                }
                </div>
            </div>
        </Card>
    )
}

export default WhatsNewList