import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, Typography } from '@mui/material';
import { useStyles } from './user-freshsales-contacts-accordin.styles';
import React, { useEffect } from 'react';
import UserFreshsalesContactsListItem from './user-freshsales-contacts-list-item.component';
import AccordionDetails from '@mui/material/AccordionDetails';
import ClinicsReportsLoadingSpinner from '../../../Reports/ClinicReports/clinics-reports-loading-spinner.component';
import emptyIcon from '../../../img/emptyList.svg';
import { DisplayText } from '@shopify/polaris';
import UserFreshsalesContactsGoogleAnalytics from './user-freshsales-contacts-google-analytics.component';
const UserFreshsalesContactsList = ({
  fetchUserFreshsalesContacts,
  freshsalesContacts,
  loading,
}) => {
  const classes = useStyles();

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    void fetchUserFreshsalesContacts(signal);
    return () => {
      controller.abort();
    };
  }, []);

  if (loading) {
    return <ClinicsReportsLoadingSpinner />;
  }

  return (
    <>
      {Object.keys(freshsalesContacts).map((freshsalesContact, index) => (
        <Accordion key={index} TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fontSize: '2em' }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.title}>
              {freshsalesContact}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            {Object.keys(freshsalesContacts[freshsalesContact]).length > 0 ? (
              freshsalesContact === 'Google Analytics' ? (
                <UserFreshsalesContactsGoogleAnalytics
                  data={freshsalesContacts[freshsalesContact]}
                />
              ) : (
                Object.keys(freshsalesContacts[freshsalesContact]).map(
                  (contactField, index) => (
                    <UserFreshsalesContactsListItem
                      key={index}
                      contactField={contactField}
                      value={
                        freshsalesContacts[freshsalesContact][contactField]
                      }
                    />
                  )
                )
              )
            ) : (
              <div className={`message-no-result ${classes.empty}`}>
                <img
                  src={emptyIcon}
                  className="empty-state-image"
                  alt="empty state image"
                ></img>
                <DisplayText size="small">No results found</DisplayText>
                <br />
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default UserFreshsalesContactsList;
