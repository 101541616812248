import { TextField, Select, MenuItem } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { Button } from '@shopify/polaris';

const ShopProductTable = ({ orderItem, index, updateItem, products, removeItem, order }) => {

    return (
        <tr style={{ display: 'flex', padding: '1em 0', gap: '10px' }} key={index}>
            <td className='shop-product-column' >
                <Select
                    style={{ maxWidth: "100%" }}
                    className="plan-field"
                    value={orderItem.product?.id ?? ''}
                    onChange={(e)=>{
                        let productIndex = products.findIndex(product => product.id == e.target.value)
                        updateItem(index,'product',products[productIndex])
                    }}

                >
                    {
                        products.map((product, index) => {
                            return (
                                <MenuItem key={index} value={product.id} disabled={order.findIndex(orderItem => orderItem.product.id == product.id)>=0}>{product.title.en} - {product.price} {product.currency}</MenuItem>
                            )
                        })
                    }
                </Select>
            </td>
            <td className='shop-product-column'>
                <TextField
                    type='number'
                    className='plan-field'
                    value={orderItem.quantity}
                    onChange={(e)=>{updateItem(index,'quantity',e.target.value)}}
                />
            </td>
            <td className='shop-product-column'>
                {orderItem.product?.id?<p>{orderItem.product.currency} {orderItem.product.price * orderItem.quantity}</p>:<p>0</p>}
            </td>
            <td className='shop-product-column'>
                <Button primary onClick={()=>{removeItem(index)}}>Remove</Button>
            </td>
        </tr>

    )
}

export default ShopProductTable
