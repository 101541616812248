import React, { useState, useEffect } from "react";
import {
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  Card,
  Modal,
  PageActions,
} from "@shopify/polaris";
import axios from "../../axios";
import Cookie from "js-cookie";
import history from "../../history";

import { useParams } from "react-router-dom";
import Select from "react-dropdown-select";

function Edit() {
  let { id } = useParams();
  const [popupActive, setPopupActive] = useState(true);
  const [optionsUsers, setOptionsUsers] = useState([]);
  let listUsers = [];
  const [selectedUser, setSelectedUser] = useState("");

  useEffect(() => {
    axios
      .get("admin/v1/users?per_page=1000000&page=1", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setPopupActive(false);
        //setSelectedUser(String(result.data.data.data[0].id));
        for (var i = 0; i < result.data.data.data.length; i++) {
          listUsers.push({
            label:
              result.data.data.data[i].first_name +
              " " +
              result.data.data.data[i].last_name +
              " ( ID : " +
              result.data.data.data[i].id +
              " )",
            value: String(result.data.data.data[i].id),
          });
        }
        setOptionsUsers(listUsers);
      })
      .catch((err) => console.log(err));
  }, []);
  function handleSelectUser(selectedUser) {
    setSelectedUser(selectedUser[0].value);
  }
  return (
    <Page
      title="Add Patient"
      breadcrumbs={[
        { content: "List Of Patienys", url: `/admin/doctors/${id}/patients` },
      ]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Select User</TextStyle>
          <Select
            placeholder="Search user by name"
            onChange={handleSelectUser}
            values={[]}
            options={optionsUsers}
          />
        </FormLayout>
        <br />
        <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
        <br /> <br /> <br /> <br /> <br /> <br />
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );

  function handleSave() {
    setPopupActive(true);
    const bodyObj = {
      patient_id: selectedUser,
      attach: true,
    };
    axios
      .post(`/admin/v1/doctors/${id}/patients`, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        setPopupActive(false);
        history.push(`/admin/doctors/${id}/patients`);
        //  history.push('/admin/faqsupport/viewCategory')
      })
      .catch((err) => setPopupActive(false));
  }
}
export default Edit;
