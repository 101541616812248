import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Page,
  Image,
  DisplayText,
  Loading,
  Card,
  TextStyle,
  Spinner,
} from "@shopify/polaris";
import Cookie from "js-cookie";
import axios from "../axios";
import emptyIcon from "../img/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import NavigationLayout from "../components/NavigationLayout";

const ListOfInfluences = () => {
  const perPage = 15;
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [availability, setAvailability] = useState("");
  const [page, setPage] = useState(1);
  const appliedFilters = [];
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    axios.get(`/admin/v1/influencers?per_page=${perPage}&page=${page}`, {
      headers: {
        Authorization: "Bearer " + Cookie.get("token"),
      },
    }).then((result) => {
      setIsLoading(false);
      setIsListEmpty(!result.data.data.data.length ? true : false);
      setTotalPages(Math.ceil(result.data.data.total / perPage));
      setItems(result.data.data.data.map((item) => [
          item.id,
          item.first_name,
          item.last_name,
          item.email,
          item.pref_platform,
          item.user_name,
          item.country,
          item.language.includes('en') ? 'English' : 'Arabic',
        ])
      );
    }).catch((err) => {
      console.log(err);
      setIsLoading(false);
    });
  }, [page, availability]);

  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);

  if(!isEmpty(availability)){
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const loadingMarkup = isLoading ? <Loading /> : null;

  const emtyState = isListEmpty ? (
    <div className="message-no-result"
    >
      <div className="message-no-result-img">
        <Image src={emptyIcon}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );

  return (
    <NavigationLayout>
      <Page
        fullWidth
        title="Influencers"
      >
        <Card>
          {loadingMarkup}
          <DataTable
            columnContentTypes={["text", "text", "text", "text", "text", "text", "text", "text"]}
            headings={[
              <TextStyle variation="strong">ID</TextStyle>,
              <TextStyle variation="strong">First Name</TextStyle>,
              <TextStyle variation="strong">Last Name</TextStyle>,
              <TextStyle variation="strong">Email</TextStyle>,
              <TextStyle variation="strong">Preferred Platform</TextStyle>,
              <TextStyle variation="strong">Username</TextStyle>,
              <TextStyle variation="strong">Country</TextStyle>,
              <TextStyle variation="strong">Language</TextStyle>,
            ]}
            rows={items}
            sortable={[false, false, false, false]}
            defaultSortDirection="descending"
            initialSortColumnIndex={0}
            // onSort={handleSort}
            verticalAlign="middle"
          />

          {emtyState}
          {totalPages >= 2 && (
            <div
              style={{
                paddingTop: "10px",
                textAlign: "center",
                paddingBottom: "10px",
              }}
            >
              <Pagination
                showFirstButton
                showLastButton
                count={totalPages}
                page={page}
                boundaryCount={2}
                onChange={handleChangePage}
                size="large"
              />
            </div>
          )}
        </Card>
      </Page>
    </NavigationLayout>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};

export default ListOfInfluences;