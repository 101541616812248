import React, { useCallback, useState, useEffect } from "react";
import {
  Modal,
  Thumbnail,
  Toast,
  Caption,
  DropZone,
  Stack,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Select,
  Card,
  PageActions,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";

import { useParams } from "react-router-dom";

function EditPreScreening() {
  let { id } = useParams();
  let imageType = "";
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [imageKeyImage1, setImageKeyImage1] = useState("");
  const [imagePreviewImage1, setImagePreviewImage1] = useState(
    Cookie.get("step4aUrl")
  );
  const [filesImage1, setFilesImage1] = useState([]);
  const handleDropZoneImage1 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesImage1((filesImage1) => [...filesImage1, ...acceptedFiles]),
    []
  );
  const fileUploadImage1 = !filesImage1.length && <DropZone.FileUpload />;
  let uploadedFilesImage1 = filesImage1.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesImage1[filesImage1.length - 1].name}
        source={
          validImageTypes.indexOf(filesImage1[filesImage1.length - 1].type) > 0
            ? window.URL.createObjectURL(filesImage1[filesImage1.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesImage1[filesImage1.length - 1].name}{" "}
        <Caption>{filesImage1[filesImage1.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage1 = useCallback(
    (filesImage1) => {
      setPopupActive(true);
      setImagePreviewImage1(
        validImageTypes.indexOf(filesImage1[filesImage1.length - 1].type) > 0
          ? window.URL.createObjectURL(filesImage1[filesImage1.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesImage1[filesImage1.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeyImage1(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesImage1[filesImage1.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesImage1]
  );

  const [imageKeyImage2, setImageKeyImage2] = useState("");
  const [imagePreviewImage2, setImagePreviewImage2] = useState(
    Cookie.get("step4bUrl")
  );
  const [filesImage2, setFilesImage2] = useState([]);
  const handleDropZoneImage2 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesImage2((filesImage2) => [...filesImage2, ...acceptedFiles]),
    []
  );
  const fileUploadImage2 = !filesImage2.length && <DropZone.FileUpload />;
  let uploadedFilesImage2 = filesImage2.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesImage2[filesImage2.length - 1].name}
        source={
          validImageTypes.indexOf(filesImage2[filesImage2.length - 1].type) > 0
            ? window.URL.createObjectURL(filesImage2[filesImage2.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesImage2[filesImage2.length - 1].name}{" "}
        <Caption>{filesImage2[filesImage2.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage2 = useCallback(
    (filesImage2) => {
      setPopupActive(true);
      setImagePreviewImage2(
        validImageTypes.indexOf(filesImage2[filesImage2.length - 1].type) > 0
          ? window.URL.createObjectURL(filesImage2[filesImage2.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesImage2[filesImage2.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeyImage2(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesImage2[filesImage2.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesImage2]
  );

  const [imageKeyImage3, setImageKeyImage3] = useState("");
  const [imagePreviewImage3, setImagePreviewImage3] = useState(
    Cookie.get("step4bUrl")
  );
  const [filesImage3, setFilesImage3] = useState([]);
  const handleDropZoneImage3 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesImage3((filesImage3) => [...filesImage3, ...acceptedFiles]),
    []
  );
  const fileUploadImage3 = !filesImage3.length && <DropZone.FileUpload />;
  let uploadedFilesImage3 = filesImage3.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesImage3[filesImage3.length - 1].name}
        source={
          validImageTypes.indexOf(filesImage3[filesImage3.length - 1].type) > 0
            ? window.URL.createObjectURL(filesImage3[filesImage3.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesImage3[filesImage3.length - 1].name}{" "}
        <Caption>{filesImage3[filesImage3.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage3 = useCallback(
    (filesImage3) => {
      setPopupActive(true);
      setImagePreviewImage3(
        validImageTypes.indexOf(filesImage3[filesImage3.length - 1].type) > 0
          ? window.URL.createObjectURL(filesImage3[filesImage3.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesImage3[filesImage3.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeyImage3(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesImage3[filesImage3.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesImage3]
  );

  useEffect(() => {
    axios
      .get(`admin/v1/users/${id}/prepurchase`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setSelectedReason(result?.data?.data?.unqualified_reason)
        result.data.data.image_1 &&
          setImagePreviewImage1(result.data.url+"/"+result.data.data.image_1);
        result.data.data.image_2 &&
          setImagePreviewImage2(result.data.url+"/"+result.data.data.image_2);
        result.data.data.image_3 &&
          setImagePreviewImage3(result.data.url+"/"+result.data.data.image_3);
        isDefined(result.data.data.date_of_birth) &&
          result.data.data.date_of_birth &&
          setDateValue(result.data.data.date_of_birth);
        result.data.data.problem_question &&
          setProblemValue(result.data.data.problem_question);

        isDefined(result.data.data.visited_dentist) &&
          setSelectedDentistVisited(
            result.data.data.visited_dentist === true ? "Yes" : "No"
          );

        isDefined(result.data.data.teeth_healthy) &&
          setSelectedHealthy(
            result.data.data.teeth_healthy === true ? "Yes" : "No"
          );

        isDefined(result.data.data.wisdom_teeth) &&
          setSelectedWisdomTeeth(String(result.data.data.wisdom_teeth));
        isDefined(result.data.data.wisdom_need_removal) &&
          setSelectedWisdomTeethRemoval(
            result.data.data.wisdom_need_removal === true ? "Yes" : "No"
          );
        setDentalHistoryValue(result.data.data.dental_history);
        isDefined(result.data.data.is_qualified) &&
          getQualifiedStatus(result.data.data.is_qualified);
        isDefined(result.data.data.explain_issue) &&
          result.data.data.explain_issue &&
          setIssueExplanationValue(result.data.data.explain_issue);
        isDefined(result.data.data.arches_to_treat) &&
          result.data.data.arches_to_treat &&
          setSelectedArches(String(result.data.data.arches_to_treat));
        result?.data?.data?.unqualified_reason &&
          setUnqualifiedReasonValue(result.data.data.unqualified_reason);
      })
      .catch((err) => console.log(err));
  }, []);

  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content="Image Deleted" onDismiss={toggleActive} />
  ) : null;
  const [isUploadingAtive, setIsUploadingAtive] = useState(false);
  const [popupActive, setPopupActive] = useState(false);
  const [dateValue, setDateValue] = useState("");
  const handleChangeDateValue = useCallback(
    (newValue) => setDateValue(newValue),
    []
  );
  const [problemValue, setProblemValue] = useState("");
  const handleChangeProblemValue = useCallback(
    (newValue) => setProblemValue(newValue),
    []
  );
  const [selectedDentistVisited, setSelectedDentistVisited] = useState("");
  const handleSelectChangeDentistVisited = useCallback(
    (value) => setSelectedDentistVisited(value),
    []
  );
  const optionsDentistVisited = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  const [selectedHealthy, setSelectedHealthy] = useState("");
  const handleSelectChangeHealthy = useCallback(
    (value) => setSelectedHealthy(value),
    []
  );
  const optionsHealthy = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  const [selectedWisdomTeeth, setSelectedWisdomTeeth] = useState("");
  const handleSelectChangeWisdomTeeth = useCallback(
    (value) => setSelectedWisdomTeeth(value),
    []
  );
  const optionsWisdomTeeth = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "No" },
    { label: "i don't know", value: "i don't know" },
  ];
  const [selectedWisdomTeethRemoval, setSelectedWisdomTeethRemoval] =
    useState("");
  const handleSelectChangeWisdomTeethRemoval = useCallback(
    (value) => setSelectedWisdomTeethRemoval(value),
    []
  );
  const optionsWisdomTeethRemoval = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  const [dentalHistoryValue, setDentalHistoryValue] = useState("");
  const handleChangeDentalHistoryValue = useCallback(
    (newValue) => setDentalHistoryValue(newValue),
    []
  );
  const [issueExplanationValue, setIssueExplanationValue] = useState("");
  const handleChangeIssueExplanationValue = useCallback(
    (newValue) => setIssueExplanationValue(newValue),
    []
  );
  const [selectedArches, setSelectedArches] = useState("");
  const handleSelectChangeArches = useCallback(
    (value) => setSelectedArches(value),
    []
  );
  const optionsArches = [
    { label: "Top", value: "1" },
    { label: "Bottom", value: "2" },
    { label: "Both", value: "3" },
  ];
  const [value, setValue] = useState("");

  const [selected, setSelected] = useState("");
  function handleSelectChange(value) {
    setSelected(value);
  }
  const [selectedReason, setSelectedReason] = useState('Difficult case');
  function handleSelectChangeReasons(value) {
    setSelectedReason(value);
  }
  const options = [
    { label: "Qualified Top", value: "2" },
    { label: "Qualified Bottom", value: "3" },
    { label: "Qualified Both", value: "1" },
    { label: "Not Qualified", value: "0" },
  ];
  const reasonOptions = [
    { label: "Difficult Case", value: "Difficult case" },
    { label: "Country not supported", value: "Country not supported" },
  ];
  const [unqualifiedReasonValue, setUnqualifiedReasonValue] = useState("");
  const handleChangeUnqualifiedReason = useCallback(
    (newValue) => setUnqualifiedReasonValue(newValue),
    []
  );
  return (
    <Page
      title=" Pre-Screening"
      breadcrumbs={[
        {
          content: "Dashboard",
          url: "/admin/leads/" + id ,
        },
      ]}
    >
      <Card title="Image 1" sectioned>
        <FormLayout>
          <Thumbnail
            size="large"
            source={imagePreviewImage1 ?  imagePreviewImage1 : ""}
          />
          <DropZone onDrop={handleDropZoneImage1} onDropAccepted={sendImage1}>
            {uploadedFilesImage1}
            {fileUploadImage1}
          </DropZone>
        </FormLayout>
      </Card>

      <Card title="Image2" sectioned>
        <FormLayout>
          <Thumbnail
            size="large"
            source={imagePreviewImage2 ? imagePreviewImage2 : ""}
          />
          <DropZone onDrop={handleDropZoneImage2} onDropAccepted={sendImage2}>
            {uploadedFilesImage2}
            {fileUploadImage2}
          </DropZone>
        </FormLayout>
      </Card>
      <Card title="Image3" sectioned>
        <FormLayout>
          <Thumbnail
            size="large"
            source={imagePreviewImage3 ? imagePreviewImage3 : ""}
          />
          <DropZone onDrop={handleDropZoneImage3} onDropAccepted={sendImage3}>
            {uploadedFilesImage3}
            {fileUploadImage3}
          </DropZone>
        </FormLayout>
      </Card>
      <Card title="Questions" sectioned>
        <FormLayout>
          <TextStyle variation="strong">Date Birth</TextStyle>
          <TextField
            type="date"
            value={dateValue}
            onChange={handleChangeDateValue}
          />
          <TextStyle variation="strong">
            What problem are you looking to get fixed?/Chief complaint
          </TextStyle>
          <TextField value={problemValue} onChange={handleChangeProblemValue} />
          <TextStyle variation="strong">
            Have you visited your dentist in the past 6 months?
          </TextStyle>
          <Select
            options={optionsDentistVisited}
            onChange={handleSelectChangeDentistVisited}
            value={selectedDentistVisited}
            placeholder="Choose One"
          />
          {selectedDentistVisited === "Yes" ? (
            <div style={{ marginTop: "30px" }}>
              <FormLayout>
                <TextStyle variation="strong">
                  According to your dentist, are your teeth and gums healthy?
                </TextStyle>
                <Select
                  options={optionsHealthy}
                  onChange={handleSelectChangeHealthy}
                  value={selectedHealthy}
                  placeholder="Choose One"
                />
              </FormLayout>
            </div>
          ) : null}

          <TextStyle variation="strong">
            Do you have any wisdom teeth?
          </TextStyle>
          <Select
            options={optionsWisdomTeeth}
            onChange={handleSelectChangeWisdomTeeth}
            value={selectedWisdomTeeth}
            placeholder="Choose One"
          />
          {selectedWisdomTeeth === "yes" ? (
            <div style={{ marginTop: "30px" }}>
              {" "}
              <FormLayout>
                {" "}
                <TextStyle variation="strong">
                  According to your dentist, do your wisdom teeth need removal?
                </TextStyle>
                <Select
                  options={optionsWisdomTeethRemoval}
                  onChange={handleSelectChangeWisdomTeethRemoval}
                  value={selectedWisdomTeethRemoval}
                  placeholder="Choose One"
                />
              </FormLayout>
            </div>
          ) : null}
          <TextStyle variation="strong">Any other dental history we should know about (implants, bridges, fillings, etc.)?/Clinical findings</TextStyle>
          <TextField
            value={dentalHistoryValue}
            onChange={handleChangeDentalHistoryValue}
          />
          <TextStyle variation="strong">Please explain the issue</TextStyle>
          <TextField
            value={issueExplanationValue}
            onChange={handleChangeIssueExplanationValue}
          />
          <TextStyle variation="strong">
            Which arches do you want to treat?
          </TextStyle>
          <Select
            options={optionsArches}
            onChange={handleSelectChangeArches}
            value={selectedArches}
            placeholder="Choose One"
          />
        </FormLayout>
      </Card>
      <Card sectioned title="Treatment Qualification Status">
        <FormLayout>
          <Select
            options={options}
            onChange={handleSelectChange}
            value={selected}
            placeholder="Please Choose"
          />
          {/* {selected == "0" && (
            <TextField
              label="Please type the reason"
              value={unqualifiedReasonValue}
              onChange={handleChangeUnqualifiedReason}
            ></TextField>
          )} */}
        </FormLayout>
      </Card>
      {selected === '0' && <Card sectioned title="Discontinued reasons">
        <FormLayout>
          <Select
            options={reasonOptions}
            onChange={handleSelectChangeReasons}
            value={selectedReason}
            placeholder="Please Choose"
          />
        </FormLayout>
      </Card>}

      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
      {toastMarkup}
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );
  function getQualifiedStatus(param) {
    switch (param) {
      case 0:
        setSelected("0");
        break;
      case 1:
        setSelected("1");
        break;
      case 2:
        setSelected("2");
        break;
      case 3:
        setSelected("3");
        break;
    }
  }
  function isDefined(value) {
    if (value !== undefined && value !== null) return true;
  }

  function handleSave() {
    setPopupActive(true);

    const bodyObj = {
      ...(imageKeyImage1 && { image_1: imageKeyImage1 }),
      ...(imageKeyImage2 && { image_2: imageKeyImage2 }),
      ...(imageKeyImage3 && { image_3: imageKeyImage3 }),
      ...(dateValue && { date_of_birth: dateValue }),
      ...(problemValue && { problem_question: problemValue }),
      ...(selectedDentistVisited && {
        visited_dentist: selectedDentistVisited === "Yes" ? true : false,
      }),
      ...(selectedHealthy && {
        teeth_healthy: selectedHealthy === "Yes" ? true : false,
      }),
      ...(selectedWisdomTeeth && { wisdom_teeth: selectedWisdomTeeth }),
      ...(selectedWisdomTeethRemoval && {
        wisdom_need_removal:
          selectedWisdomTeethRemoval === "Yes" ? true : false,
      }),
      ...(dentalHistoryValue && { dental_history: dentalHistoryValue }),
      ...(issueExplanationValue && { explain_issue: issueExplanationValue }),
      ...(selectedArches && { arches_to_treat: selectedArches }),
      ...(selectedReason && {unqualified_reason: selectedReason}),
    };
    !imageKeyImage1 && delete bodyObj.image_1;
    !imageKeyImage2 && delete bodyObj.image_2;
    !imageKeyImage3 && delete bodyObj.image_3;
    // axios
    //   .post(`v1/prepurchase`, bodyObj, {
    //     headers: {
    //       Authorization: "Bearer " + Cookie.get("token"),
    //     },
    //   })
    //   .then((res) => {
        if (selected) {
          const bodyObj = {
            is_qualified: parseInt(selected),
            ...(unqualifiedReasonValue && {
              unqualified_reason: unqualifiedReasonValue,
        }),
        ...(selectedReason && {unqualified_reason: selectedReason}),
      };
          axios
            .post(`admin/v1/users/${id}/prepurchase/qualified `, bodyObj, {
              headers: {
                Authorization: "Bearer " + Cookie.get("token"),
              },
            })
            .then((res) => {
              setPopupActive(false);
              history.push("/admin/leads/" + id );
            })
            .catch((err) => console.log(err));
        } else {
          setPopupActive(false);
          history.push("/admin/leads/" + id );
        }
      // })
      // .catch((err) => console.log(err));
  }
}
export default EditPreScreening;
