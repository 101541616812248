import React, { useCallback, useState, useEffect } from "react";
import {
  Thumbnail,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Select,
  Card,
  PageActions,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";
import { Editor } from "@tinymce/tinymce-react";

import { useParams } from "react-router-dom";

export default function EditSevereStatus() {
  let { id } = useParams();
  // Declare a new state variable, which we'll call "count"
  const [selected, setSelected] = useState("today");
  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const options = [
    { label: "Pending", value: "Severe case pending" },
    { label: "Rejected", value: "Severe case rejected" },
    { label: "Approved", value: "Severe case approved" },
  ];
  const [valueTitle, setValueTitle] = useState("");
  const [valueMessage, setValueMessage] = useState("");
  const [valueButtonText, setValueButtonText] = useState("");
  const [valueButtonURL, setValueButtonURL] = useState("");
  const [firstImage, setFirstImage] = useState("");
  const [secondImage, setSecondImage] = useState("");

  const handleChangeMessage = (e) => {
    setValueMessage(e.target.getContent());
  };

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    axios
      .get("admin/v1/users/" + id + "/severe-images", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setFirstImage(result.data.data.first_image_url);
        setSecondImage(result.data.data.second_image_url);
        setSelected(result.data.data.eligible);
      })
      .catch((err) => console.log(err));
  }

  return (
    <Page
      title="Severe Status"
      breadcrumbs={[
        {
          content: "Dashboard",
          url: `/admin/users/${id}/dashboard`,
        },
      ]}
    >
      <Card sectioned title="Images">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Thumbnail source={firstImage} size="large" />
          <div style={{ marginInlineStart: 20 }}>
            <Thumbnail source={secondImage} size="large" />
          </div>
        </div>
      </Card>
      <Card sectioned title="Status">
        <FormLayout>
          <Select
            options={options}
            onChange={handleSelectChange}
            value={selected}
          />
        </FormLayout>
      </Card>
      <Card sectioned title="Send Notification">
        <FormLayout>
          <TextStyle variation="strong">Title</TextStyle>
          <TextField value={valueTitle} onChange={handleChangeTitle} />

          <TextStyle variation="strong">Message</TextStyle>

          <Editor
            apiKey="z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve"
            value={valueMessage}
            init={{
              branding: false,
              height: 200,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
            }}
            onChange={handleChangeMessage}
          />
          <TextStyle variation="strong">Button Text</TextStyle>
          <TextField
            value={valueButtonText}
            onChange={handleChangeButtonText}
          />
          <TextStyle variation="strong">Button URL</TextStyle>
          <TextField value={valueButtonURL} onChange={handleChangeButtonURL} />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
    </Page>
  );

  function handleChangeTitle(valueTitle) {
    setValueTitle(valueTitle);
  }

  function handleChangeButtonText(valueButtonText) {
    setValueButtonText(valueButtonText);
  }
  function handleChangeButtonURL(valueButtonURL) {
    setValueButtonURL(valueButtonURL);
  }

  function handleSave() {
    const bodyObj = {
      status: selected,
      title: valueTitle,
      message: valueMessage,
      button_text: valueButtonText,
      button_url: valueButtonURL,
    };
    axios
      .post(`admin/v1/users/${id}/severe-images`, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        history.push(`/admin/users/${id}/dashboard`);
      })
      .catch((err) => console.log(err));
  }
}
