import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  Button,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  Select,
  Card,
  PageActions,
  DataTable,
  ButtonGroup,
  Toast,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";

import {useLocation, useParams} from "react-router-dom";
import { Timeline, TimelineEvent } from "react-event-timeline";
import UserHeader from "../../../components/UserLayout/user-header";

export default function EditStatus() {
  let { id } = useParams();
  const [selected, setSelected] = useState("");
  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const options = [
    {
      label: "Choose one",
      value: "",
    },
    { value: "Severe Case Pending", label: "Review Severe Cases" },
    {
      value: "Purchase Impression Kit",
      label: "Pending Purchase Impression Kit",
    },
    {
      value: "Taking Impressions and Photos",
      label: "Uploading Impressions & Photos",
    },
    {
      value: "Pending Taking Impressions and Photos",
      label: "Review Impressions",
    },
    { value: "Your Smile Plan", label: "Qualified" },
    { value: "Aligner Kit Purchased", label: "Smile Journey" },
    { value: "Completed", label: "Completed" },
    {
      label: "Screening",
      value: "Your Photos",
    },
    {
      label: "Pending purchase book a scan",
      value: "Purchase Book A Scan",
    },
    {
      label: "Customer booked appointment",
      value: "Book A Scan Purchased",
    },
    {
      label: "Account Created",
      value: "Account Created",
    },
    {
      label: "User Not Qualified",
      value: "User Not Qualified",
    },
    {
      label: "Free Book A Scan",
      value: "Free Book A Scan",
    },
    {
      label: "OTP Confirmed",
      value: "OTP Confirmed",
    },
    {
      label: "Qualified Both",
      value: "Qualified Both",
    },
    {
      label: "Pending Taking Impressions and Photos",
      value: "Pending Taking Impressions and Photos",
    },
    {
      label: "Qualified Top",
      value: "Qualified Top",
    },
    {
      label: "Qualified Bottom",
      value: "Qualified Bottom",
    },
  ];
  const [items, setItems] = useState([]);
  const [arrayEvents, setArrayEvents] = useState([]);
  const [state, setstate] = useState("");
  const [selectedSubStatus, setSelectedSubStatus] = useState("Choose one");
  const optionsSubStatus = [
    {
      label: "Choose one",
      value: "Choose one",
    },
    {
      label: "Client purchased Book A Scan",
      value: "Client purchased Book A Scan",
    },
    {
      label: "Client purchased Impression Kit",
      value: "Client purchased Impression Kit",
    },
    {
      label: "Lab sent Impression Kit",
      value: "Lab sent Impression Kit",
    },
    {
      label: "Client received Impression kit",
      value: "Client received Impression kit",
    },
    {
      label: "Client sent Impression kit",
      value: "Client sent Impression kit",
    },
    {
      label: "Lab received Impression Kit",
      value: "Lab received Impression Kit",
    },
    {
      label: "Lab sent Aligner Kit",
      value: "Lab sent Aligner Kit",
    },
    {
      label: "Client received Aligner kit",
      value: "Client received Aligner kit",
    },
    {
      label: "Client received Refinement Aligner",
      value: "Client received Refinement Aligner",
    },
    {
      label: "Client received Replacement Aligner",
      value: "Client received Replacement Aligner",
    },
    {
      label: "Redo Kit Needed",
      value: "Redo Kit Needed",
    },
    {
      label: "Redo Scan Needed",
      value: "Redo Scan Needed",
    },
    {
      label: "Redo Scan Appointment Scheduled",
      value: "Redo Scan Appointment Scheduled",
    },
    {
      label: "Lab sent Redo Impression",
      value: "Lab sent Redo Impression",
    },
    {
      label: "Client received Redo Impression",
      value: "Client received Redo Impression",
    },
    {
      label: "Client sent Redo Impression",
      value: "Client sent Redo Impression",
    },
    {
      label: "Lab received Redo Impression",
      value: "Lab received Redo Impression",
    },
    {
      label: "Client replaced Retainer",
      value: "Client replaced Retainer",
    },
    {
      label: "Client received Retainer",
      value: "Client received Retainer",
    },
    {
      label: "Client Received MCAaligner",
      value: "Client Received MCAaligner",
    },
    {
      label: "Undecided Booking",
      value: "Undecided Booking",
    },
    {
      label: "Appointment Completed",
      value: "Appointment Completed",
    },
    {
      label: "Confirmed Booking",
      value: "Confirmed Booking",
    },
    {
      label: "Cancelled Booking",
      value: "Cancelled Booking",
    },
    {
      label: "No Answer Booking",
      value: "No Answer Booking",
    },
    {
      label: "Appointment No show",
      value: "Appointment No show",
    },
    {
      label: "AK from Elite to Client",
      value: "AK from Elite to Client",
    },
    {
      label: "Return IK from UAE to LEB",
      value: "Return IK from UAE to LEB",
    },
    {
      label: "Return IK from KSA client to Elite",
      value: "Return IK from KSA client to Elite",
    },
    {
      label: "AK from UAE to ELITE",
      value: "AK from UAE to ELITE",
    },
    {
      label: "AK from LEB to UAE",
      value: "AK from LEB to UAE",
    },
    {
      label: "Refinement AK from UAE to ELITE",
      value: "Refinement AK from UAE to ELITE",
    },
    {
      label: "Return redo IK from UAE to LEB",
      value: "Return redo IK from UAE to LEB",
    },
    {
      label: "Return IK from KSA Elite to UAE",
      value: "Return IK from KSA Elite to UAE",
    },
    {
      label: "Unscheduled Booking",
      value: "Unscheduled Booking",
    },
  ];
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content="Substatus Changed" onDismiss={toggleActive} />
  ) : null;
  useEffect(() => {
    getData();
  }, []);

  function getData() {
    Promise.all([
      axios.get(`admin/v1/users/${id}/status`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }),
      axios.get(`admin/v1/users/${id}/sub-status-history`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }),
    ]).then(function (responses) {
      const responseStatus = responses[0];
      const responseSubstatusHistory = responses[1];
      const responseEvents = responses[2];
      setSelected(responseStatus.data.data);
      responseStatus.data.sub_status &&
        setSelectedSubStatus(responseStatus.data.sub_status);
      setItems(
        responseSubstatusHistory.data.data.map((item) => [
          item.id,
          item.created_at,
          item.sub_status,
          item.order_tracking && (
            <ButtonGroup>
              <Button
                id={
                  String(item.order_tracking.order_id) +
                  "_" +
                  String(item.order_tracking.id)
                }
                onClick={(e) => handleViewDetails(e)}
              >
                View Details
              </Button>
            </ButtonGroup>
          ),
        ])
      );
    });
  }

  const location = useLocation();
  let linkState =
    {planType: "", clinicStatus: "", clinicName: "", status: "", userId: id, pageTitle: ""};
  if (location.state !== undefined) {
    const {planType, clinicStatus, clinicName, status, userId, pageTitle} = location.state;
    linkState = {planType, clinicStatus, clinicName, status, userId, pageTitle}
  }
  const { planType, clinicStatus, clinicName, status, userId, pageTitle} = linkState;
  return (
    <Page>
      <UserHeader pageTitle={pageTitle} status={status} userId={userId} clinicStatus={clinicStatus} clinicName={clinicName} planType={planType}/>
      <br/>
      <h1 className="page-header">Edit Status</h1>
      <Card sectioned title="Status">
        <FormLayout>
          <Select
            options={options}
            onChange={handleSelectChange}
            value={selected}
          />
        </FormLayout>
      </Card>
      <Card sectioned title="Substatus">
        <FormLayout>
          <Select
            options={optionsSubStatus}
            onChange={handleSelectChangeSubStatus}
            value={selectedSubStatus}
          />
        </FormLayout>
      </Card>
      {items.length !== 0 && (
        <Card sectioned title="Substatus History">
          {" "}
          <DataTable
            columnContentTypes={["text", "text", "text", "text"]}
            headings={[
              <TextStyle variation="strong">ID</TextStyle>,
              <TextStyle variation="strong">Created At</TextStyle>,
              <TextStyle variation="strong">Substatus</TextStyle>,
              <TextStyle variation="strong"></TextStyle>,
            ]}
            rows={items}
          />
        </Card>
      )}

      {arrayEvents.length !== 0 && (
        <Card title="Shipment Events" sectioned>
          <Timeline lineStyle={{ height: "93%" }}>{arrayEvents}</Timeline>
        </Card>
      )}

      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
      {toastMarkup}
    </Page>
  );
  function handleSelectChangeSubStatus(value) {
    const bodyObj = { sub_status: value };
    axios
      .post(`admin/v1/users/${id}/sub-status`, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        toggleActive();
      })
      .catch((err) => console.log(err));
    setSelectedSubStatus(value);
  }
  function handleViewDetails(e) {
    axios
      .get(
        `admin/v1/orders/${e.currentTarget.id.split("_")[0]}/trackings/${
          e.currentTarget.id.split("_")[1]
        }`,
        {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      )
      .then((result) => {
        result.data.data.carrier_tracking.ShipmentInfo &&
          setArrayEvents(
            result.data.data.carrier_tracking.ShipmentInfo.ShipmentEvent.map(
              (item, index, elements) => (
                <div key={index}>
                  <TimelineEvent
                    title={item.ServiceEvent.Description}
                    createdAt={item.Date + "   " + item.Time}
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="16"
                        viewBox="0 0 24 24"
                        width="16"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z" />
                      </svg>
                    }
                  ></TimelineEvent>
                </div>
              )
            )
          );
      })
      .catch((err) => console.log(err));
  }
  function handleSave() {
    const bodyObj = {
      status: selected,
    };
    axios
      .post("admin/v1/users/" + id + "/status", bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        history.push("/admin/users/" + id + "/dashboard");
      })
      .catch((err) => console.log(err));
  }
}
