import { DropZone, Icon, Thumbnail } from '@shopify/polaris';
import React, { useCallback, useEffect } from 'react'
import {
    CircleCancelMajor
  } from '@shopify/polaris-icons';
import axios from "../../../axios";
import Cookie from "js-cookie";

function MultipleUploadPhotos({images,setImages, filesImage,setFilesImage,setImageKey,imagesKey}) {
let imageType = "";
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
    const handleDropZoneImage1 = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) =>
            setFilesImage((filesImage) => [...filesImage, ...acceptedFiles]),
        []
    );
    const removeImageKey = (key,isBackendImage)=>{
        setImageKey(imagesKey=> imagesKey.filter((image,ind)=>(ind != key)))
        if(isBackendImage){
            setImages(images=> images.filter((image,ind)=>(ind != key)))
        }else{
            setFilesImage(filesImage=> filesImage.filter((file,ind)=>(ind != key)))
        }
    }
    const sendImage = useCallback(
        (filesImage) => {
          if(validImageTypes.indexOf(filesImage[filesImage.length - 1].type) < 0){
            return;
          }
          imageType = filesImage[filesImage.length - 1].type;
          let url2 = "";
          const form_data = new FormData();
          form_data.append("content_type", imageType);
          axios
            .post("/admin/v1/images/s3", form_data, {
              headers: {
                Authorization: "Bearer " + Cookie.get("token"),
              },
            })
            .then((res) => {
              setImageKey(prev=>[...prev,res.data.key]);
              url2 = res.data.url;
              axios
                .put(url2, filesImage[filesImage.length - 1], {
                  headers: {
                    "x-amz-acl": "public-read-write",
                    "Content-Type": imageType,
                  },
                })
                .then((res) => {
                })
                .catch((err) =>{} );
            })
            .catch((err) => {});
        },
    
        [filesImage]
    );
    useEffect(()=>{
        if(filesImage.length){
            console.log(window.URL.createObjectURL(filesImage[0]) )
        }
    },[filesImage])
  return (
    <>
    <br/>
    <div style={{display:"flex", gap: "10px", flexWrap:"wrap"}}>
    {images && images.map((file,key)=>
        imagesKey.length>key ?<div key={key} style={{position:"relative"}}>
          <div style={{position:"absolute",zIndex:1,right:0}} onClick={()=>removeImageKey(key,true)}>
            <Icon
            source={CircleCancelMajor}
            color="base"
          />
        </div>
        <Thumbnail
            size="large"
            source={file.image}
          />
          </div>
         :<div key={key} style={{position:"relative"}}>
         <Thumbnail
         size="large"
         source="https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
       />
       </div>)}
        {filesImage && filesImage.map((file,key)=>
        imagesKey.length>(images?images.length:0) + key ?<div key={key} style={{position:"relative"}}>
          <div style={{position:"absolute",zIndex:1,right:0}} onClick={()=>removeImageKey(key,false)}>
            <Icon
            source={CircleCancelMajor}
            color="base"
          />
        </div>
        <Thumbnail
            size="large"
            source={window.URL.createObjectURL(file) }
          />
          </div>
         :<div key={key} style={{position:"relative"}}>
         <Thumbnail
         size="large"
         source="https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
       />
       </div>)}
       </div> 
       <div>
       <br/>
       <DropZone onDrop={handleDropZoneImage1} onDropAccepted={sendImage}>
            <DropZone.FileUpload/>
          </DropZone>
          </div>
    </>
  )
}

export default MultipleUploadPhotos