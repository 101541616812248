import { Grid, Paper, Typography } from '@material-ui/core'
import { Close } from '@mui/icons-material'
import React from 'react'
import { useStyles } from './CaseDiscussionNoChatStyles';

function CaseDiscussionNoChat({createChat, setOpenChat, loading}) {
    const classes = useStyles();
  return (
    <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.noCaseBodyRow}>
          <Close style={{ position: "absolute", left: "5px", width: "1.5em", height: "1.5em", cursor: "pointer" }} onClick={() => { setOpenChat({ firstOpen: false, status: false }) }} />
          <Paper className={classes.noCasePaper} square elevation={2}>
            <div className={classes.noCaseDiv}>
              <Typography color="textPrimary" variant="h5" className={classes.noCaseTitle}>
                No discussion here - yet
              </Typography>
              <button className={classes.btn} onClick={createChat} disabled={loading}>Start a Discussion</button>
            </div>
          </Paper>
        </Grid>
      </Grid>
  )
}

export default CaseDiscussionNoChat