import React from 'react'
import { Tooltip, Typography } from '@material-ui/core'
import InfoIcon from '@mui/icons-material/Info';
import { Checkbox } from '@shopify/polaris';
import TeethComponent from './TeethComponent';

const AdditionalInstructions = ({ ids, handleClick, onTagClick }) => {

    return (
        <div>
            <div className='additional-instructions-title-container'>
                <Typography className='additional-instructions-title'>Avoid Attachments on teeth that are crowns or bridges</Typography>
                <Tooltip
                    arrow
                    title={<Typography>Select teeth to avoid attachments on</Typography>}
                    placement='top'
                >
                    <InfoIcon className='additional-instructions-info' />
                </Tooltip>
            </div>
            <div className='additional-instructions-teeth-container'>
                <TeethComponent
                    type='upper'
                    handleClick={handleClick}
                    ids={ids.upper}
                    onTagClick={onTagClick}
                    title='Upper Teeth'
                />
                <TeethComponent
                    type='lower'
                    handleClick={handleClick}
                    ids={ids.lower}
                    onTagClick={onTagClick}
                    title='Lower Teeth'
                />
            </div>
            <Checkbox
                label="Patient doesn't want attachments on anterior teeth (limited movements and will affect final teeth position)"
                checked={ids.no_anterior_attachments}
                onChange={(e) => handleClick(e, 'no_anterior_attachments')}
            />
            <br />
            <Checkbox
                label="Patient doesn't want attachments on any teeth"
                checked={ids.no_attachments}
                onChange={(e) => handleClick(e, 'no_attachments')}
            />
        </div>
    )
}

export default AdditionalInstructions