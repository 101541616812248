import { Card, FormLayout, Select, TextStyle } from '@shopify/polaris'
import React from 'react'
import { Select as Select2, MenuItem} from "@material-ui/core";

const RtpMca = ({
    handleSelectTpType,
    tpType,
    handleCalendarStepChange,
    calendarId,
    prevTpSteps,
    lastStepBeforeMcaRequired,
    tpOptions,
}) => {
    return (
        <div>
            <Select
                options={tpOptions}
                onChange={handleSelectTpType}
                value={tpType}
            />
            {tpType == 'mca' &&
                <div style={{ marginTop: '10px' }}>
                    <Card sectioned>
                        <FormLayout>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
                                <TextStyle>Last step worn in previous treatment plan</TextStyle>
                                <Select2
                                    className='plan-field'
                                    onChange={(e) => handleCalendarStepChange(e.target.value)}
                                    value={calendarId}
                                >
                                    {
                                        prevTpSteps.map((step, key) => {
                                            return <MenuItem key={key} value={step.id}>{key + 1}</MenuItem>
                                        })
                                    }
                                </Select2>
                            </div>
                            {lastStepBeforeMcaRequired}
                        </FormLayout>
                    </Card>
                </div>
            }
        </div>
    )
}

export default RtpMca