import React, { useEffect } from 'react';
import { FormLayout, TextField, TextStyle } from '@shopify/polaris';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

const CreateProductRequestForm = ({
  getProductRequestParameters,
  loading,
  products,
  urgencyStatues,
  getUserByName,
  loadingUser,
  setFormData,
  formData,
}) => {
  const { quantity, reason } = formData;

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    void getProductRequestParameters(signal);
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <form>
      <FormLayout>
        <FormLayout.Group>
          <div>
            <TextStyle variation="strong">Products</TextStyle>
            <Select
              id="products"
              cacheOptions
              menuPosition="fixed"
              menuPlacement="auto"
              options={products}
              isLoading={loading}
              onChange={(selectedOption) =>
                handleChange('product', selectedOption.value)
              }
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
            />
          </div>
          <div>
            <TextStyle variation="strong">User</TextStyle>
            <AsyncSelect
              id="user"
              menuPosition="fixed"
              menuPlacement="auto"
              loadOptions={getUserByName}
              isLoading={loadingUser}
              onChange={(selectedOption) =>
                handleChange('userId', selectedOption.value)
              }
              placeholder="User by name (has a disaptch)"
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
            />
          </div>
          <div>
            <TextStyle variation="strong">Urgency</TextStyle>
            <Select
              menuPosition="fixed"
              menuPlacement="auto"
              id="urgency"
              options={urgencyStatues}
              isLoading={loading}
              onChange={(selectedOption) =>
                handleChange('urgency', selectedOption.value)
              }
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
            />
          </div>
          <div>
            <TextStyle variation="strong">Quantity</TextStyle>
            <TextField
              label="quanity"
              type="number"
              labelHidden
              value={quantity}
              onChange={(value) => handleChange('quantity', value)}
            />
          </div>
        </FormLayout.Group>
        <div>
          <TextStyle variation="strong">Reason</TextStyle>
          <TextField
            value={reason}
            label="reason"
            labelHidden
            rows={8}
            multiline={4}
            autoComplete="off"
            onChange={(value) => handleChange('reason', value)}
          />
        </div>
      </FormLayout>
    </form>
  );
};

export default CreateProductRequestForm;
