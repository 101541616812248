import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Button,
  Page,
  AppProvider,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Card,
  TextStyle,
  ButtonGroup,
  Pagination,
  Modal
} from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../axios";
import history from "../history";
import emptyIcon from "../img/emptyList.svg";
import {useLocation, useParams} from "react-router-dom";

export default function ListOfTrackings() {
  let { id, type } = useParams()
  const location = useLocation();
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [popupActive, setPopupActive] = useState(false);
  const [items, setItems] = useState([]);
  let arrayOfObjects = [];
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(2);
  const [perPage, setPerPage] = useState(13);
  const [sortState, setSortState] = useState("-id");
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [isButtonVisible, setButtonVisible] = useState("none");
  const [isButtonCreateVisible, setButtonCreateVisible] = useState("block");

  let userId = "";
  if (location.state) {
    userId = location.state.userId
  }
  let valueFilter = "";
  let statusColors = ["default", "success", "info", "attention", "warning"];
  useEffect(() => {
    getData();
  }, []);
  function getData() {
    setPopupActive(true);
    axios
      .get(`admin/v1/orders/${id}/trackings/all/${type ? 'dispatch' : ''}`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        let orderTrackingData;
        if (type === 'dispatch') {
          orderTrackingData = result.data.dispatchTracking;
          if (userId === "" && orderTrackingData.length > 0 && orderTrackingData[0]?.dispatch) {
            userId = orderTrackingData[0]?.dispatch.user_id;
          }
        } else {
          orderTrackingData = result.data.order;
        }
        if (orderTrackingData.length === 0) {
          setIsListEmpty(true);
        }
        setItems(
            orderTrackingData.map((item) => [
            item.id,
            item.dispatch ? item?.dispatch?.delivery : '-',
            item.tracking_number,
            item.status,
            item.type,
            item.shipment_event_description,
            item.shipment_event_date_time,
            item.carrier,
            <ButtonGroup>
              <Button id={String(item.id)} onClick={(e) => handleEdit(e)}>
                Edit
              </Button>
            </ButtonGroup>,
          ])
        );
      })
      .catch((err) => console.log(err)).finally(() => setPopupActive(false));
  }

  function handleEdit(e) {
    history.push(`/admin/orders/${id}/trackings/${e.currentTarget.id}/edit${type === 'dispatch' ? '/dispatch' : ''}`, {userId});
  }
  function handleFiltersQueryChange(queryValue) {
    setQueryValue(queryValue);
    getData();
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
    getData();
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "Title", value: "title" },
            { label: "Description", value: "description" },
            { label: "Dimension", value: "dimension" },
            { label: "Weight", value: "weight" },
            { label: "Price", value: "price" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
          // allowMultiple
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }
  const emtyState = isListEmpty ? (
    <div className="message-no-result"
    >
      <div className="message-no-result-img">
        <Image src={emptyIcon}></Image>{" "}
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : null;
  
  const handleBack = () => {
    history.push(`/admin/users/${userId}/dashboard/`);
  }

  const handleAddTracking = () => {
    history.push(`/admin/orders/${id}/trackings/${type === 'dispatch' ? 'dispatch' : 'order'}/add`, {userId});
  }
  
  return (
    <Page
      fullWidth
      primaryAction={{
        content: "Add Tracking",
        onAction: handleAddTracking,
      }}
      title="List of trackings"
      breadcrumbs={[{ onAction: handleBack }]}
    >
      <Card sectioned>
        <Card.Subsection>
          <DataTable
            columnContentTypes={[
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
            ]}
            headings={[
              <TextStyle variation="strong">ID</TextStyle>,
              <TextStyle variation="strong">Delivery</TextStyle>,
              <TextStyle variation="strong">Tracking Number</TextStyle>,
              <TextStyle variation="strong">Status</TextStyle>,
              <TextStyle variation="strong">Type</TextStyle>,
              <TextStyle variation="strong">Shipment Description</TextStyle>,
              <TextStyle variation="strong">Shipment Date</TextStyle>,
              <TextStyle variation="strong">Carrier</TextStyle>,
              <TextStyle variation="strong"></TextStyle>,
            ]}
            rows={items}
            sortable={[false, false, false, false, false, false, false, false]}
            defaultSortDirection="descending"
            initialSortColumnIndex={0}
            onSort={handleSort}
          />
          {/* <div id="pagination" style={{ marginTop: 20, display: "none" }}>
              <Pagination
                totalPages={totalPages}
                pagePadding={3}
                currentPage={currentPage}
                // onChange={currentPage => setCurrentPage({ currentPage }),console.log(currentPage)}
                onChange={handlePageChange}
              />
            </div> */}
          {emtyState}
        </Card.Subsection>
      </Card>
      <Modal open={popupActive} loading={true}></Modal>      
    </Page>
  );
  function handleAddUser(e) {
    history.push("/admin/adminusers/add");
  }

  function handleSort() {
    getData();
  }

  function handleAssignPatients(e) {
    let currentID = e.currentTarget.id;
    var json_str = JSON.stringify(
      findArrayElementById(arrayOfObjects, currentID)
    );
    Cookie.set("clickedItem", json_str);
    history.push("/admin/doctors/" + currentID + "/patients");
  }

  function handleViewDetails(e) {
    let currentID = e.currentTarget.id;
    var json_str = JSON.stringify(
      findArrayElementById(arrayOfObjects, currentID)
    );
    Cookie.set("clickedItem", json_str);
    history.push("/admin/orders/" + id + "/trackings/" + e.currentTarget.id, {userId});
  }

  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element.id) === parseInt(id);
    });
  }
  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return value.map((val) => `Filter by ${val}`).join(", ");
      case "productType":
        return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }

  function handlePageChange(currentPage) {
    setCurrentPage(currentPage);
    getData();
  }
}
