import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import Lightbox from "react-awesome-lightbox";

const useStyles = makeStyles(() => {
    return {
        relative:{
            position:'relative',
        },
        imgMargBorder:{
            borderRadius: '2.8px',
            cursor:'pointer',
            height: '69.7px',
            width: '70.19px',
        },
        backgroundColorImg:{ 
            backgroundColor: '#DFE3E8',
            cursor: 'default',
        },
        dropZoneDesign:{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position:'absolute',
            width: '100%',
            height: '15px',
            bottom: 10,
        },
        imagePlaceholder:{
            position: 'absolute',
            width: '93%',
            fontSize: '9px !important',
            lineHeight: '13px',
            letterSpacing: 0,
            textAlign: 'center',
            color: '#FFFFFF',
            backgroundColor:'rgba(0,0,0,0.6)',
        },
        dropZoneInput: {
            display: 'none',
        },
    }
})

export default function SinglePhoto({
    idx = null,
    handleImages,
    id,
    value,
    fileType = '',
    disable,
    isOpen,
    setIsOpen,
    handleIndexChange,
    index,
    imagesArray,
    identifier,
    fileName = null,
}) {
    const classes = useStyles();

    return (
        <Box className={classes.relative}>
            {value ?
                <label htmlFor={`${identifier}-${id}`}>
                    {(fileType && fileType.includes('video')) ?
                        <video className={classes.imgMargBorder} src={value} controls /> :
                        <Box component='img' className={classes.imgMargBorder} src={value} onClick={() => handleIndexChange(id)}/>
                    }
                </label>
            :
                <label htmlFor={`${identifier}-${id}`}>
                    <Box className={`${classes.imgMargBorder} ${classes.backgroundColorImg}`} />
                </label>
            }
            <div className={classes.dropZoneDesign}>
                <input type='file' id={`${identifier}-files-${id}`} disabled={disable} className={classes.dropZoneInput} onChange={(file) => {(idx !== null || fileName !== null) ? handleImages(file, id, idx !== null ? idx : fileName) : handleImages(file, id)}} />
                <label htmlFor={`${identifier}-files-${id}`} style={{cursor: disable ? 'not-allowed' : 'pointer'}} className={classes.imagePlaceholder}>{value ? 'Replace' : 'Upload image'}</label>
            </div>
            {(isOpen && imagesArray?.length) ? <Lightbox startIndex={index} onClose={() => setIsOpen(false)} images={imagesArray} /> : ''}
        </Box>
  )
}
