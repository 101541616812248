import { Card, DisplayText, FormLayout, Image, Layout, TextStyle } from '@shopify/polaris'
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import axios from "../../../axios";
import { CircularProgress, Tooltip } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import { iconColors } from '../../../config';
import emptyIcon from "../../../img/emptyList.svg";

function CaseRecords({planType, pageTitle, status, clinicName, clinicStatus, userId}) {
  const { id } = useParams();
  const [caseRecords, setCaseRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [emptyState, setEmptyState] = useState(null);
  const history = useHistory();

  const getCaseRecords = () => {
    setLoading(true);
    axios.get(`admin/v2/users/${id}/case-records`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then(response => {
      setCaseRecords(response.data.data);
      if (response.data.data.length == 0) {
        setEmptyState(
          <div className="message-no-result">
            <div className="message-no-result-img">
              <Image style={{ display: "inline-block", width: "50px" }} src={emptyIcon}></Image>{" "}
            </div>
            <DisplayText size="small">No results found</DisplayText>
            <br />
          </div>
        );
      }
    }).catch(err => {
      console.log('getCaseRecords error', err);
    }).finally(f => {
      setLoading(false);
    })
  }

  const push = (item, type) => {
    let path = `/admin/users/${id}/dashboard/case-records`;
    if(item.appointment_number == '-'){
      switch(item.type[0]){
        case 'Photo ID':
          path = `/admin/users/${id}/dashboard/editprofile`;
          break;
        case 'Other files':
          path = `/admin/users/${id}/dashboard/files/${item.id}`;
          break;
        case 'Membership ID':
          path = `/admin/users/${id}/dashboard/editprofile`;
          break;
        case 'IK Screening':
        case 'BAS Screening':
          path = `/admin/users/${id}/dashboard/editscreening`;
          break;
      }
    }
    history.push(path, {
      appointmentNumber: item.appointment_number,
      type,
      clinicName: clinicName,
      planType: planType,
      status: status,
      pageTitle: pageTitle,
      clinicStatus: clinicStatus,
      userId: userId,
      backUrl: `/admin/users/${id}/dashboard`,
    });
  }

  const Status = (status, color) => {
    if (status == 1) {
      return <CheckIcon style={{ color: color ? color : 'green', fontSize: color ? '25px' : '32px' }} />;
    }
    if (status == 0) {
      return <CloseIcon style={{ color: color ? color : 'red', fontSize: color ? '25px' : '32px' }} />;
    }
    if (status == -1) {
      return <WarningIcon style={{ color: color ? color : '#FCBD17', fontSize: color ? '25px' : '32px' }} />;
    } else {
      return status;
    }
  }

  const ToolTipped = (item, caseRecord, type) => {
    if (item.status == 1 || item.status == -1) {
      return (
        <Tooltip
          arrow
            title={(item?.files ? 'Uploaded files: ' : '') + item?.files}
          placement="top"
        >
          <div className='files-info-wrapper' onClick={() => push(caseRecord, type)}>
            <div>{Status(item.status, 'gray')}</div>
            <p>{item?.uploaded}</p>
          </div>
        </Tooltip>
      )
    } else {
      return (
        <div className='files-info-wrapper' onClick={() => push(caseRecord, type)}>
          <div>{Status(item.status, 'gray')}</div>
          <p>{item?.uploaded}</p>
        </div>
      );
    }
  }

  useEffect(() => {
    getCaseRecords();
  }, [])

  return (
    <Layout.Section>
      <Card 
        title='CASE RECORDS'
        actions={[
          {
            content: 'Refresh',
            onClick: getCaseRecords,
            disabled: loading,
          }
        ]}
      >
        <Card.Section>
          <FormLayout>
            <div className="payment-wrapper">
              {loading ?
                <div style={{ textAlign: "center" }}>
                  <CircularProgress color='primary' />
                </div>
                :
                <>
                  <table className='case-records-table'>
                    <tbody>
                      <tr style={{ display: "flex" }}>
                        <th className='case-records-th'><TextStyle variation="strong">Treatment Stage</TextStyle></th>
                        <th className='case-records-th-m'><TextStyle variation="strong">Appointment</TextStyle></th>
                        <th className='case-records-th-s'><TextStyle variation="strong">Before Step</TextStyle></th>
                        <th className='case-records-th-l'><TextStyle variation="strong">Type</TextStyle></th>
                        <th className='case-records-th'><TextStyle variation="strong">Appointment Completed</TextStyle></th>
                        <th className='case-records-th'><TextStyle variation="strong">Submission Status</TextStyle></th>
                        <th className='case-records-th'><TextStyle variation="strong">Photos</TextStyle></th>
                        <th className='case-records-th'><TextStyle variation="strong">Scans</TextStyle></th>
                        <th className='case-records-th'><TextStyle variation="strong">Information</TextStyle></th>
                        <th className='case-records-th'><TextStyle variation="strong">X-rays</TextStyle></th>
                        <th className='case-records-th-l'><TextStyle variation="strong">Records Status</TextStyle></th>
                        <th className='case-records-th'><TextStyle variation="strong">Clinic</TextStyle></th>
                        <th className='case-records-th'><TextStyle variation="strong">Last Updated On</TextStyle></th>
                        <th className='case-records-th'><TextStyle variation="strong">ID</TextStyle></th>
                      </tr>
                      {caseRecords.map((item, key) => (
                        <tr style={{ display: "flex" }} key={key}>
                          <td className='case-records-td'>
                            <div>{item.treatment_stage}</div>
                          </td>
                          <td className='case-records-td-m'>
                            <div>{item.appointment_number}</div>
                          </td>
                          <td className='case-records-td-s'>
                            <div>{item.before_step}</div>
                          </td>
                          <td className='case-records-td-l'>
                            <div className="badge-wrapper-flex-wrap-gap">
                              {item.type && item.type.map((res, key) =>
                                <div key={key} style={{
                                  padding: '0 1rem',
                                  backgroundColor: iconColors[res]?.background ?? '#fff',
                                  color: iconColors[res]?.color ?? 'black',
                                  borderRadius: '10rem',
                                }}
                                >
                                  {res}
                                </div>)}
                            </div>
                          </td>
                          <td className='case-records-td'>
                            <div>{item.appointment_completed_at}</div>
                          </td>
                          <td className='case-records-td'>
                            <div>
                              {Status(item.submission_status)}
                            </div>
                          </td>
                          <td className='case-records-td'>
                            {ToolTipped(item.photos, item, 'photos')}
                          </td>
                          <td className='case-records-td'>
                            {ToolTipped(item.scans, item, 'scans')}
                          </td>
                          <td className='case-records-td'>
                            <div className='files-info-wrapper' onClick={() => push(item, 'information')}>
                              <div>{Status(item.info.status, 'gray')}</div>
                            </div>
                          </td>
                          <td className='case-records-td'>
                            <div className='files-info-wrapper' onClick={() => push(item, 'xray')}>
                              <div>{Status(item.x_rays.status, 'gray')}</div>
                            </div>
                          </td>
                          <td className='case-records-td-l'>
                            <div>
                              {item.records_status.length > 0 ? 
                                item.records_status.map((status, key) => <span key={key} style={{color: status?.color}}>{status?.msg + (key < item.records_status.length - 1 ? ', ' : '')}</span>) :
                                '-'
                              }
                            </div>
                          </td>
                          <td className='case-records-td'>
                            <div>{item.clinic}</div>
                          </td>
                          <td className='case-records-td'>
                            <div>{item.last_updated_on}</div>
                          </td>
                          <td className='case-records-td'>
                            <div>{item.id}</div>
                          </td>
                        </tr>
                      )
                      )}
                    </tbody>
                  </table>
                  {emptyState}
                </>
              }
            </div>
          </FormLayout>
        </Card.Section>
      </Card>
    </Layout.Section>
  )
}

export default CaseRecords