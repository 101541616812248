import { Button } from '@shopify/polaris'
import React from 'react'

const TpActionButtons = ({ createMode, addTp, handleAddRtp, typeTp }) => {
    return (
        !createMode ?
            <div className="tp-banner">
                {typeTp ?
                    <Button primary onClick={addTp}>
                        Add New Treatment Plan Option
                    </Button> :
                    null
                }
                <Button primary onClick={handleAddRtp}>
                    Add Refinement Plan or MCA Plan for this Treatment plan
                </Button>
            </div> :
            null
    )
}

export default TpActionButtons