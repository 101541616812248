import React, { createContext, useState } from "react";

export const UserContext = createContext();

// This context provider is passed to any component requiring the context
export const UserProvider = ({ children }) => {
  const [basmaCurrentID, setBasmaCurrentID] = useState(0);
  const [basmaAdminID, setBasmaAdminID] = useState(0);
  const [usersAvailability, setUsersAvailability] = useState("");
  const [paymentUserID, setPaymentUserID] = useState("");
  const [paymentUserName, setPaymentUserName] = useState("");
  const [paymentObject, setPaymentObject] = useState("");
  const [hasHistoricalData, setHasHistoricalData] = useState(true);
  const [bookAScanInfo, setBookAScanInfo] = useState(null);

  return (
    <UserContext.Provider
      value={{
        basmaCurrentID,
        setBasmaCurrentID,
        usersAvailability,
        setUsersAvailability,
        paymentUserID,
        setPaymentUserID,
        paymentUserName,
        setPaymentUserName,
        paymentObject,
        setPaymentObject,
        basmaAdminID,
        setBasmaAdminID,
        hasHistoricalData,
        setHasHistoricalData,
        bookAScanInfo,
        setBookAScanInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
