import React, { useCallback, useRef, useState, useEffect, useContext } from "react";
import {
  Layout,
  Card,
  FormLayout,
  Badge,
  Checkbox,
  Toast,
  PageActions,
  Modal,
  TextContainer,
  Banner,
  Button,
  Spinner,
  Collapsible
} from "@shopify/polaris";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../axios";
import Cookie from "js-cookie";
import "@zendeskgarden/react-pagination/dist/styles.css";
import { useParams } from "react-router-dom";
import "react-image-lightbox/style.css";
import Trackings from "./DashboardComponents/Trackings";
import Shipping from "./DashboardComponents/Shipping/Shipping";
import History from "./DashboardComponents/History";
import Orders from "./DashboardComponents/Orders";
import UserLayout from "../components/UserLayout/UserLayout";
import TicketsUser from "../CMSTicketing/TicketsPerUser/tickets-user.component";
import GuestLayout from "../components/UserLayout/GuestLayout";

export default function GuestDashboard({pageTitle}) {
  let { id } = useParams();
  let roleId = [];
  if (Cookie.get("roleId"))
    roleId = JSON.parse(Cookie.get("roleId"));
  const [status, setStatus] = useState("");
  const [planType, setPlanType] = useState("");
  const [profileClinic, setProfileClinic] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const [active, setActive] = useState(false);
  const [toastMessage, setToastMessage] = useState("Account Disactivated");

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const toastMarkup = active ? (
    <Toast content={toastMessage} onDismiss={toggleActive} />
  ) : null;

  const [activeId, setActiveId] = useState('')
  const scrollSmoothly = (id) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
      setActiveId(id)

    }
  }

  const [openSideNav, setOpenSideNav] = useState(true)
  const ontoggleNav = () => {
    setOpenSideNav(!openSideNav);
  };
  const [toggle, setToggle] = useState(true)
  const renderSection = (anchor, title = false) => {
    if (openSideNav) {
      if (title) {
        return <span><strong>{anchor}</strong></span>
      }
      return anchor
    } else {
      return ''
    }

  }



  return (
    <GuestLayout
      pageTitle={pageTitle}
      scrollSmoothly={scrollSmoothly}
      activeId={activeId}
      renderSection={renderSection}
      ontoggleNav={ontoggleNav}
      openSideNav={openSideNav}
      setOpenSideNav={setOpenSideNav}
    >
      <FormLayout>
        <ToastContainer />
        <Layout>
          {String(Cookie.get("userType")) !== "50" && (
              <div id="shipping" style={{ width: "100%" }}>
                <Shipping
                    planType={planType}
                    pageTitle={pageTitle}
                    status={status}
                    userId={id}
                />
              </div>
          )}
          <div id="trackings" style={{ width: "100%" }}>
            {String(Cookie.get("userType")) !== "50" && <Trackings />}
          </div>
          <div id="payments" style={{ width: "100%" }}>
            {String(Cookie.get("userType")) !== "50" && <Orders userId={id} />}
          </div>

          <div id="tickets" style={{ width: "100%" }}>
            <TicketsUser />
          </div>
          <div id="history" style={{ width: "100%" }}>
            {String(Cookie.get("userType")) !== "50" && <History />}
          </div>
        </Layout>
      </FormLayout>
    </GuestLayout>
  );

}
