import { Typography, makeStyles } from '@material-ui/core';
import React from 'react'

const useStyles = makeStyles(() => {
    return {
        reviewTxt: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: 2,
            margin: "1rem 0",
            fontSize: '11px',
        },
        reviewBoldTxt: {
            fontWeight: 'bold',
        }
    };
});

const ReviewedBy = ({ reviewer, reviewedAt, status = null }) => {
    const classes = useStyles();

    return (
        (reviewer && reviewedAt) ?
        <Typography className={classes.reviewTxt}>
            <span><span className={classes.reviewBoldTxt}>{status ? `${status}` : 'Reviewed'} By:</span> <span>{reviewer}</span></span>
            <span><span className={classes.reviewBoldTxt}>{status ? `${status}` : 'Reviewed'} At:</span> <span>{reviewedAt}</span></span>
        </Typography> :
        null
    )
}

export default ReviewedBy