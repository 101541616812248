import { Typography,TextField,Select,MenuItem } from "@material-ui/core";
import axios from "../axios";
import Cookies from "js-cookie";
import React, { useCallback, useState, useEffect, useContext } from "react";



export function ExistingPlan({id,payment,plan,titlen,titlar,descen,descar,country,down_payment,monthly, monthly_pay,total,editable,disablePayment,success,setSuccess}) {
    const[titleEn,setTitleEn]=useState(titlen)
    const[titleAr,setTitleAr]=useState(titlar)
    const[descEn,setDescEn]=useState(descen)
    const[DescAr,setDescAr]=useState(descar)
    const[Country,setCountry]=useState(country)
    const[downPayment,setDownPayment]=useState(down_payment)
    const[month,setMonth]=useState(monthly)
    const[monthlyPay,setMonthlyPay]=useState(monthly_pay)
    const[totalPay,setTotalPay]=useState(total)
    const[edit,setEdit]=useState(editable)
    const[disabledPayment,setDisabledPayment]=useState(disablePayment);

    const save=async()=>{
    const bodyObj={
      id:id,
      title: {
        en: titleEn,
        ar: titleAr,
      },
      sub_title: {
        en: "",
        ar: "",
      },
      description: {
        en: descEn,
        ar: DescAr,
      },
      payment_interval: 1,
      active: true,
      others: {
        total_price: null,
      },
      price_in_local_currency: null,
      product_country: Country,
      treatment_type:plan,
      type:payment,
      month:parseInt(month),
      monthlyPay:parseInt(monthlyPay),
      downPayment:parseInt(downPayment)
    }
    const res=await  axios
          .post(`/admin/v1/add_active_plans`, bodyObj,{
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
          })
          .then((result) => {

          })
          .catch((e)=>{
            console.log(e)
          })
          .finally((f)=>{
            setEdit((prev)=>!prev)
            setDisabledPayment(true)
            setSuccess((prev)=>prev+1)
          })
    }

  return(
       <tr style={{display:"flex",padding:"1em 0",gap:"10px"}}>
          <td  className="plan-column" >  
          <Select
            disabled={!edit}
            style={{maxWidth:"100%"}}
            className="plan-field"
            onChange={(e)=>{setCountry(e.target.value)}}
            value={Country}
          >
            <MenuItem value={"AE"}>UAE</MenuItem>
            <MenuItem value={"SA"}>KSA</MenuItem>
            <MenuItem value={"QA"}>QAT</MenuItem>
            <MenuItem value={"LB"}>LEB</MenuItem>
            <MenuItem value={"KW"}>KWT</MenuItem>
            <MenuItem value={"BH"}>BAH</MenuItem>
            <MenuItem value={"AE + SA + KW"}>UAE or KSA or KWT</MenuItem>
            <MenuItem value={"AE + SA + KW + BH + QA + LB"}>UAE or KSA or KWT or QAT or BAH or LEB</MenuItem>
            <MenuItem value={"AE + SA"}>UAE or KSA</MenuItem>
            <MenuItem value={"worldwide"}>Worldwide</MenuItem>
            <MenuItem value={"Worldwide except GULF"}>Worldwide except UAE or KSA</MenuItem>
          </Select>
          </td>
         <td className="plan-column"> <TextField
            disabled={!edit}
            className="plan-field"
            value={titleEn}
            onChange={(e)=>{setTitleEn(e.target.value)}}
          /></td>
        <td className="plan-column"><TextField
            disabled={!edit}
            className="plan-field"
            value={titleAr}
            onChange={(e)=>{setTitleAr(e.target.value)}}
          /></td>
          <td className="plan-column"><TextField
            disabled={!edit}
            className="plan-field"
            value={descEn}
            onChange={(e)=>{setDescEn(e.target.value)}}
          /></td>
          <td className="plan-column"> <TextField
            disabled={!edit}
            className="plan-field"
            value={DescAr}
            onChange={(e)=>{setDescAr(e.target.value)}}
          /></td>
         <td className="plan-column"> <TextField
            disabled={!edit ||disabledPayment}
            type="number"
            className="plan-field"
            value={downPayment}
            onChange={(e)=>{setDownPayment(e.target.value)}}
          /></td>
         <td className="plan-column"> <TextField
            disabled={!edit || disabledPayment}
            type="number"
            className="plan-field"
            value={month}
            onChange={(e)=>{setMonth(e.target.value)}}
          /></td>
           <td className="plan-column"><TextField 
            disabled={!edit || disabledPayment}
            type="number"
            className="plan-field"
            value={monthlyPay}
            onChange={(e)=>{setMonthlyPay(e.target.value)}}
          /></td>
          <td className="plan-column"><Typography
          >USD {total}</Typography></td>
          {edit?
            <td  className="plan-button">
            <button
            style={{ backgroundColor:"rgba(0, 128, 96, 1)",padding:"1rem 3rem"}} 
            textAlign="bottom" 
            primary
            onClick={()=>{
                save();
            }}
            >
              Save
            </button>
             </td>
             :<td className="plan-button">
            <button
                style={{backgroundColor:"rgba(0, 128, 96, 1)",}} 
                textAlign="bottom" 
                primary 
                onClick={()=>{
                    setEdit((prev)=>(!prev))
                }}
            >
              Edit
            </button>
            <button
            style={{ backgroundColor:"red"}}
             destructive onClick={()=>{ window.open("/admin/plans/" + id + "/payments")}} >Edit Items</button>
             </td>}
            </tr>
         
  )
}

export default ExistingPlan