import { Card, Modal } from '@shopify/polaris';
import React, { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import CommercialInvoiceForm from './commercial-invoice-form.component';
import CommercialInvoicePdf from './commerical-invoice-pdf.component';
import { CommercialInvoiceShippingAddresses } from '../../util/constants';
import { formatShippingAddress } from './helpers';

function CommercialInvoice({ tracking, shipping }) {
  const [popupActive, setPopupActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shippingFrom, setShippingFrom] = useState(
    CommercialInvoiceShippingAddresses.lebanon
  );
  const [shippingTo, setShippingTo] = useState(
    CommercialInvoiceShippingAddresses.dubai
  );
  const [formData, setFormData] = useState({
    descriptionOfGood: 'Plastic made materials',
    quantity: '1',
    unitValue: '10.0',
    subTotalValue: '10',
    unitNetWeight: '0.5',
    countryOfManufacture: 'Lebanon',
    commCode: '39.09.50.10',
    totalDeclaredValue: '10.0',
    totalNetWeight: '1',
    totalUnit: '1',
    totalGrossWeight: '0.5',
  });

  const pdfRef = useRef(null);

  const generatePDF = async () => {
    if (!pdfRef.current) return;
    try {
      setLoading(true);
      const pdfCurrent = pdfRef.current;
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
      });
      const canvas = await html2canvas(pdfCurrent, { scale: 2 });
      const imgData = canvas.toDataURL('image/png', 1);
      const imgWidth = 190;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
      window.open(doc.output('bloburl'));
    } catch (error) {
      console.log('Error while generating pdf', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModal = () => {
    setPopupActive(true);
  };

  const handleCloseModal = () => {
    setPopupActive(false);
  };

  useEffect(() => {
    if (shipping && shipping.length > 0) {
      switch (shipping[0]) {
        case 'noClient':
          setShippingFrom(CommercialInvoiceShippingAddresses.lebanon);
          setShippingTo(CommercialInvoiceShippingAddresses.dubai);
          break;
        case 'dubai':
          setShippingFrom(CommercialInvoiceShippingAddresses.dubai);
          formatShippingAddress(tracking, setShippingTo);
          setFormData((prev) => ({
            ...prev,
            countryOfManufacture: 'United Arab Emirates',
          }));
          break;
        default:
          setShippingFrom(CommercialInvoiceShippingAddresses.lebanon);
          formatShippingAddress(tracking, setShippingTo);
      }
    }
  }, [shipping]);

  return (
    <Card
      sectioned
      title="Generate Commercial Invoice"
      primaryFooterAction={{
        content: 'Inspect',
        onAction: handleOpenModal,
        disabled: !tracking,
      }}
    >
      <CommercialInvoiceForm
        formData={formData}
        setFormData={setFormData}
        shippingFrom={shippingTo}
        setShippingFrom={setShippingTo}
      />
      <Modal
        open={popupActive}
        large
        primaryAction={{
          content: 'Generate Pdf',
          onAction: generatePDF,
          loading,
        }}
        secondaryActions={[
          {
            content: 'Close',
            onAction: handleCloseModal,
            disabled: loading,
          },
        ]}
      >
        <Modal.Section>
          <CommercialInvoicePdf
            carrier={tracking?.carrier}
            pdfRef={pdfRef}
            formData={formData}
            shippingTo={shippingTo}
            shippingFrom={shippingFrom}
          />
        </Modal.Section>
      </Modal>
    </Card>
  );
}

export default CommercialInvoice;
