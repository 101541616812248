import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../history";
import { AppProvider } from "@shopify/polaris";
import AddNotification from "./DashboardComponents/Notifications/AddNotification";
import AddScan from "./DashboardComponents/Scans/AddScan";
import EditNotification from "./DashboardComponents/Notifications/EditNotification";
import EditStatus from "./DashboardComponents/Status/EditStatus";
import EditSevereStatus from "./DashboardComponents/Status/EditSevereStatus";
import EditShipping from "./DashboardComponents/Shipping/EditShipping";
import EditImpressionsAndPhotosStep3 from "./DashboardComponents/ImpressionsAndPhotos/EditImpressionsAndPhotosStep3";
import EditImpressionsAndPhotosStep2 from "./DashboardComponents/ImpressionsAndPhotos/EditImpressionsAndPhotosStep2";
import EditImpressionsAndPhotosStep4 from "./DashboardComponents/ImpressionsAndPhotos/EditImpressionsAndPhotosStep4";
import EditAlignerKit from "./DashboardComponents/EditAlignerKit";
import EditBilling from "./DashboardComponents/LegacyFiles/Billing/EditBilling";
import EditZipFile from "./DashboardComponents/ImpressionsAndPhotos/EditZipFile";
import SmilePlan from "./DashboardComponents/YourSmilePlan/SmilePlan";
import Waiver from "./DashboardComponents/LegacyFiles/Waiver/Waiver";
import EditProfile from "./DashboardComponents/Profile/EditProfile";
import Payments from "./DashboardComponents/Payments";
import EditSmileJourney from "./DashboardComponents/SmileJourney/EditSmileJourney";
import EditSmileJourneyDate from "./DashboardComponents/SmileJourney/EditSmileJourneyDate";
import AddComment from "./DashboardComponents/Comments/Add";
import EditComment from "./DashboardComponents/Comments/Edit";
import MainComments from "./DashboardComponents/Comments/Main";
import EditLabelPatient from "./DashboardComponents/EditPatientLabel";
import EditQuestions from "./DashboardComponents/ImpressionsAndPhotos/EditQuestions";
import EditSmileJourneyStartingDate from "./DashboardComponents/FirstTimeWearingAligner/EditSmileJourneyStartingDate";
import EditScreening from "./DashboardComponents/Screening/EditScreening";
import ListOverduesUpcomings from "../Payments/ListOverduesUpcomings";
import AddFile from "./DashboardComponents/Files/AddFile";
import EditFile from "./DashboardComponents/Files/EditFile";
import ScansNote from "./DashboardComponents/Scans/Note";
import AddRedoScan from "./DashboardComponents/RedoScans/AddRedoScan";
import RedoScanNote from "./DashboardComponents/RedoScans/Note";
import ImpressionsNote from "./DashboardComponents/LegacyFiles/Impressions/Note";
import EditScan from "./DashboardComponents/Scans/EditScan";
import FreshDeskTicketCreation from "../FreshdeskTicketCreation";
import useZoomDragAndDrop from "../CustomHooks/useZoomDragAndDrop";
import CaseRecordFiles from "../components/CaseRecordFiles/CaseRecordFiles";
import IKBASFiles from "./DashboardComponents/IKBASFiles/IKBASFiles";
import EditDispatches from "../Payments/EditDeliveryBatches/edit-dispatches.component";
import UserFreshsalesContacts from "./DashboardComponents/UserFreshsalesContacts/user-freshsales-contacts.component";
import RedoImpressionsNote from "./DashboardComponents/LegacyFiles/Redo Impressions/Note";
import Legacy from "./DashboardComponents/LegacyFiles/Legacy";
import UserDashboard from "./UserDashboard";

function MainDashboard() {
   //zoom drag and drop test
 const zoomedId = document.getElementsByClassName('lb-img');
 useZoomDragAndDrop({ element : zoomedId })
//end of zoom
  return (
    <AppProvider>
      <Router history={history}>
        <Switch>
          <Route
            exact
            path="/admin/users/:id/dashboard"
            component={UserDashboard}
          />
          <Route
            path="/admin/users/:id/dashboard/addnotification"
            component={AddNotification}
          />
          <Route
            path="/admin/users/:userId/dashboard/editnotification/:notificationId"
            component={EditNotification}
          />
          <Route
            path="/admin/users/:id/dashboard/editstatus"
            component={EditStatus}
          />
          <Route
            path="/admin/users/:id/dashboard/editseverestatus"
            component={EditSevereStatus}
          />
          <Route
            path="/admin/users/:id/dashboard/editshipping"
            component={EditShipping}
          />
          <Route
            path="/admin/users/:id/dashboard/editbilling"
            component={EditBilling}
          />
          <Route
            path="/admin/users/:id/dashboard/editimpressionsandphotosstep2"
            component={EditImpressionsAndPhotosStep2}
          />
          <Route
            path="/admin/users/:id/dashboard/editimpressionsandphotosstep3"
            component={EditImpressionsAndPhotosStep3}
          />
          <Route
            path="/admin/users/:id/dashboard/editimpressionsandphotosstep4"
            component={EditImpressionsAndPhotosStep4}
          />
          <Route
            path="/admin/users/:id/dashboard/editalignerkit"
            component={EditAlignerKit}
          />
          <Route
            path="/admin/users/:id/dashboard/editzipfile"
            component={EditZipFile}
          />
          <Route
            path="/admin/users/:id/dashboard/yoursmileplan/create"
            component={SmilePlan}
          />
          <Route
            path="/admin/users/:id/dashboard/yoursmileplan/:plan_id/update"
            component={SmilePlan}
          />
          <Route
            path="/admin/users/:id/dashboard/yoursmileplan/:plan_id/refinement/create"
            component={SmilePlan}
          />
          <Route
            path="/admin/users/:id/dashboard/yoursmileplan/:plan_id/refinement/edit"
            component={SmilePlan}
          />
          <Route path="/admin/users/:id/dashboard/waiver" component={Waiver} />
          <Route path="/admin/users/:id/dashboard/legacy-files" component={Legacy} />
          <Route
            path="/admin/users/:id/dashboard/editprofile"
            component={EditProfile}
          />
          <Route path="/admin/users/:id/dashboard/edit-dispatches" component={EditDispatches}/>
          <Route
            path="/admin/users/:id/dashboard/payments"
            component={Payments}
          />
          <Route
            path="/admin/users/:userId/dashboard/editsmilejourney/:stepId/images/:imagesId"
            component={EditSmileJourney}
            // search="?stepId=:id&userId=id"
          />

          <Route
            path="/admin/users/:id/dashboard/editsmilejourneydate/:id"
            component={EditSmileJourneyDate}
          />

          <Route
            path="/admin/users/:id/dashboard/addcomment"
            component={AddComment}
          />
          <Route
            path="/admin/users/:id/dashboard/comments"
            component={MainComments}
          />
          <Route
            path="/admin/users/:userId/dashboard/comments/commentId"
            component={EditComment}
          />
          <Route
            path="/admin/users/:id/dashboard/editlabel"
            component={EditLabelPatient}
          />
          <Route
            path="/admin/users/:id/dashboard/editquestions"
            component={EditQuestions}
          />
          <Route
            path="/admin/users/:id/dashboard/smilejourneystartingdate"
            component={EditSmileJourneyStartingDate}
          />
          <Route
            path="/admin/users/:id/dashboard/editscreening"
            component={EditScreening}
          />
          <Route
            path="/admin/payments/:id/overduesandupcomings"
            component={ListOverduesUpcomings}
          />
          <Route
            path="/admin/payments/:id/files"
            component={ListOverduesUpcomings}
          />
          <Route
            path="/admin/users/:id/dashboard/new-file"
            component={AddFile}
          />
          <Route
            path="/admin/users/:userId/dashboard/files/:fileId"
            component={EditFile}
          />
          <Route
            path="/admin/users/:userId/dashboard/files"
            component={IKBASFiles}
          />
          <Route
            path="/admin/users/:id/dashboard/add-scan"
            component={AddScan}
          />
          <Route
            path="/admin/users/:id/dashboard/edit-scan/:documentId"
            component={EditScan}
          />
          <Route
            path="/admin/users/:id/dashboard/scans-note"
            component={ScansNote}
          />
          <Route
            path="/admin/users/:id/dashboard/add-redo-scan"
            component={AddRedoScan}
          />
          <Route
            path="/admin/users/:id/dashboard/redo-scans-note"
            component={RedoScanNote}
          />
          <Route
            path="/admin/users/:id/dashboard/impressions-note"
            component={ImpressionsNote}
          />
          <Route
            path="/admin/users/:id/dashboard/redo-impressions-note"
            component={RedoImpressionsNote}
          />
          <Route path="/admin/users/:id/freshdesk" component={FreshDeskTicketCreation} />
          <Route path="/admin/users/:id/dashboard/case-records" component={CaseRecordFiles} />
          <Route path="/admin/users/:id/dashboard/freshsales-contacts" component={UserFreshsalesContacts}/>
        </Switch>
      </Router>
    </AppProvider>
  );
}
export default MainDashboard;
