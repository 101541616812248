import { Typography,TextField,Select,MenuItem } from "@material-ui/core";
import axios from "../axios";
import Cookies from "js-cookie";
import React, { useCallback, useState, useEffect, useContext } from "react";



export function ExistingPaymentCurrency({plan_payment_id,id,country,currency,local_price,editable,success,setSuccess}) {
    const[Country,setCountry]=useState(country)
    const[Currency,setCurrency]=useState(currency)
    const[localPrice,setLocalPrice]=useState(local_price)
    const[edit,setEdit]=useState(editable)

    const save=async()=>{
    const bodyObj={
      id:id,
      country: Country,
      currency:Currency,
      local_price:localPrice,
      plan_payment_id:plan_payment_id
    }
    const res=await axios
          .post(`/admin/v1/plan_payments_currency`, bodyObj,{
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
          })
          .then((result) => {

          })
          .catch((e)=>{
            console.log(e)
          })
          .finally((f)=>{
            setEdit((prev)=>!prev)
            setSuccess((prev)=>prev+1)
          })
    }

  return(
       <tr style={{display:"flex",padding:"1em 0",gap:"10px"}}>
          <td  className="plan-column" >  
          <Select
            disabled={!edit}
            style={{maxWidth:"100%"}}
            className="plan-field"
            onChange={(e)=>{setCountry(e.target.value)}}
            value={Country}
          >
            <MenuItem value={"AE"}>UAE</MenuItem>
            <MenuItem value={"SA"}>KSA</MenuItem>
            <MenuItem value={"QA"}>QAT</MenuItem>
            <MenuItem value={"LB"}>LEB</MenuItem>
            <MenuItem value={"BH"}>BAH</MenuItem>
            <MenuItem value={"KW"}>KWT</MenuItem>
            <MenuItem value={"OM"}>OMN</MenuItem>
          </Select>
          </td>
          <td  className="plan-column" >  
          <Select
            disabled={!edit}
            style={{maxWidth:"100%"}}
            className="plan-field"
            onChange={(e)=>{setCurrency(e.target.value)}}
            value={Currency}
          >
            <MenuItem value={"AED"}>AED</MenuItem>
            <MenuItem value={"SAR"}>SAR</MenuItem>
            <MenuItem value={"QAR"}>QAR</MenuItem>
            <MenuItem value={"USD"}>USD</MenuItem>
            <MenuItem value={"BHD"}>BHD</MenuItem>
            <MenuItem value={"KWD"}>KWD</MenuItem>
            <MenuItem value={"OMR"}>OMR</MenuItem>
          </Select>
          </td>
         <td className="plan-column"> <TextField
            disabled={!edit}
            type="number"
            className="plan-field"
            value={localPrice}
            onChange={(e)=>{setLocalPrice(e.target.value)}}
          /></td>
          <td className="plan-column"><Typography
          >{currency} {local_price}</Typography></td>
          {edit?
            <td  className="plan-button">
            <button
            style={{ backgroundColor:"rgba(0, 128, 96, 1)",padding:"1rem 3rem"}} 
            textAlign="bottom" 
            primary
            onClick={()=>{
                save();
            }}
            >
              Save
            </button>
             </td>
             :<td className="plan-button">
            <button
                style={{backgroundColor:"rgba(0, 128, 96, 1)",cursor:"pointer"}} 
                textAlign="bottom" 
                primary 
                onClick={()=>{
                    setEdit((prev)=>(!prev))
                }}
            >
              Edit
            </button>
             </td>}
            </tr>
         
  )
}

export default ExistingPaymentCurrency