import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../history";
import Edit from "./Edit";
import Clinics from "./Clinics/Main";

function MainEdit() {
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/admin/book-a-scan-countries/:id/cities/:idCity"
          component={Edit}
        />
        <Route
          path="/admin/book-a-scan-countries/:id/cities/:idCity/clinics"
          component={Clinics}
        />
        {/* <Route
          path="/admin/book-a-scan-countries/:id/cities/:idCity"
          component={Edit}
        /> */}
      </Switch>
    </Router>
  );
}
export default MainEdit;
