import React, { useCallback, useState, useEffect } from "react";
import {
    Thumbnail,
    Caption,
    DropZone,
    Stack,
    TextStyle,
    FormLayout,
    TextField,
    Select,
    Card,
    PageActions,
    Button,
    Checkbox,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookies from 'js-cookie';
import { useHistory, useParams } from "react-router-dom";

const Questions = ({ title = '', type = '' }) => {
    const { userId } = useParams();
    const history = useHistory();
    const [dentalHistoryNaValue, setDentalHistoryNaValue] = useState(false);
    const [listOfProcedures, setListOfProcedures] = useState(['']);
    const [errorMsg, setErrorMsg] = useState({
        problem_question: '',
        dental_history: '',
        list_of_procedures: '',
    });
    const [popupActive, setPopupActive] = useState(false);
    const [bookProceduresArray, setBookProceduresArray] = useState([]);
    const [viewProceduresOptions, setViewProcedureOptions] = useState(false);
    const [loading, setLoading] = useState(false);
    const [uploadedBy, setUploadedBy] = useState("");
    const [isMandatory, setIsMandatory] = useState(true);
    const [unqualifiedReasonDetails, setUnqualifiedReasonDetails] = useState('');
    const [unqualifiedReasonDetailsRequiredMsg, setUnqualifiedReasonDetailsRequiredMsg] = useState('');
    const [isMallCollaboration, setIsMallCollaboration] = useState(false);
    const [dateValue, setDateValue] = useState("");
    const handleChangeDateValue = useCallback(
        (newValue) => setDateValue(newValue),
        []
    );
    const [problemValue, setProblemValue] = useState("");
    const [painSymptoms, setPainSymptoms] = useState("");
    const handleChangeProblemValue = useCallback(
        (newValue) => setProblemValue(newValue),
        []
    );
    const handleChangePainSymptoms = useCallback(
        (newValue) => setPainSymptoms(newValue),
        []
    );
    const [selectedDentistVisited, setSelectedDentistVisited] = useState("");
    const handleSelectChangeDentistVisited = useCallback(
        (value) => setSelectedDentistVisited(value),
        []
    );
    const optionsDentistVisited = [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
    ];
    const [selectedHealthy, setSelectedHealthy] = useState("");
    const handleSelectChangeHealthy = useCallback(
        (value) => setSelectedHealthy(value),
        []
    );
    const optionsHealthy = [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
    ];
    const [selectedWisdomTeeth, setSelectedWisdomTeeth] = useState("");
    const handleSelectChangeWisdomTeeth = useCallback(
        (value) => setSelectedWisdomTeeth(value),
        []
    );
    const optionsWisdomTeeth = [
        { label: "Yes", value: "yes" },
        { label: "No", value: "No" },
        { label: "i don't know", value: "i don't know" },
    ];
    const [selectedWisdomTeethRemoval, setSelectedWisdomTeethRemoval] =
        useState("");
    const handleSelectChangeWisdomTeethRemoval = useCallback(
        (value) => setSelectedWisdomTeethRemoval(value),
        []
    );
    const optionsWisdomTeethRemoval = [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
    ];
    const [dentalHistoryValue, setDentalHistoryValue] = useState("");
    const handleChangeDentalHistoryValue = useCallback(
        (newValue) => setDentalHistoryValue(newValue),
        []
    );
    const handleChangeDentalHistoryNaValue = (newValue) => {
        if (errorMsg) {
            setErrorMsg('');
        }
        setDentalHistoryNaValue(newValue);
    }
    const [issueExplanationValue, setIssueExplanationValue] = useState("");
    const handleChangeIssueExplanationValue = useCallback(
        (newValue) => setIssueExplanationValue(newValue),
        []
    );
    const [selectedArches, setSelectedArches] = useState("");
    const handleSelectChangeArches = useCallback(
        (value) => setSelectedArches(value),
        []
    );
    const optionsArches = [
        { label: "Top", value: "1" },
        { label: "Bottom", value: "2" },
        { label: "Both", value: "3" },
    ];
    const [selected, setSelected] = useState("");
    function handleSelectChange(value) {
        setSelected(value);
    }
    const [selectedReason, setSelectedReason] = useState('');
    function handleSelectChangeReasons(value) {
        setSelectedReason(value);
    }
    const options = [
        { label: "Qualified Top", value: "2" },
        { label: "Qualified Bottom", value: "3" },
        { label: "Qualified Both", value: "1" },
        { label: "Not Qualified", value: "0" },
    ];
    const reasonOptions = [
        { label: "Lead Stage - Underage", value: "Lead Stage - Underage" },
        { label: "Lead Stage - Difficult Case", value: "Lead Stage - Difficult Case" },
        { label: "Lead Stage - Unhealthy Teeth/Gums", value: "Lead Stage - Unhealthy Teeth/Gums" },
        { label: "Lead Stage - Country Not Covered", value: "Lead Stage - Country Not Covered" },
        { label: "Data Stage - Difficult Case", value: "Data Stage - Difficult Casee" },
        { label: "Data Stage - Incomplete Info For TP", value: "Data Stage - Incomplete Info For TP" },
        { label: "TP Stage - Difficult Case", value: "TP Stage - Difficult Case" },
        { label: "TP Stage - Incomplete Info For TP", value: "TP Stage - Incomplete Info For TP" },

    ];
    const procedures = {
        CLEANING: 'Cleaning',
    }
    let imageType = '';
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    const [imageKeyImage1, setImageKeyImage1] = useState('');
    const [imagePreviewImage1, setImagePreviewImage1] = useState('');
    const [filesImage1, setFilesImage1] = useState([]);
    const handleDropZoneImage1 = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) =>
            setFilesImage1((filesImage1) => [...filesImage1, ...acceptedFiles]),
        []
    );
    const fileUploadImage1 = !filesImage1.length && <DropZone.FileUpload />;
    let uploadedFilesImage1 = filesImage1.length > 0 && (
        <Stack alignment='center'>
            <Thumbnail
                size='small'
                alt={filesImage1[filesImage1.length - 1].name}
                source={
                    validImageTypes.indexOf(filesImage1[filesImage1.length - 1].type) > 0
                        ? window.URL.createObjectURL(filesImage1[filesImage1.length - 1])
                        : 'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304'
                }
            />
            <div>
                {filesImage1[filesImage1.length - 1].name}{' '}
                <Caption>{filesImage1[filesImage1.length - 1].type} bytes</Caption>
            </div>
        </Stack>
    );
    const sendImage1 = useCallback(
        (filesImage1) => {
            setPopupActive(true);
            setImagePreviewImage1(
                validImageTypes.indexOf(filesImage1[filesImage1.length - 1].type) > 0
                    ? window.URL.createObjectURL(filesImage1[filesImage1.length - 1])
                    : 'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304'
            );
            imageType = filesImage1[filesImage1.length - 1].type;
            let headersVar = null;
            let url2 = '';
            let urlAmazon = 'https://basma.mintlearn.a2hosted.com/admin/v1/images/s3';
            const form_data = new FormData();
            form_data.append('content_type', imageType);
            axios
                .post('/admin/v1/images/s3', form_data, {
                    headers: {
                        Authorization: 'Bearer ' + Cookies.get('token'),
                    },
                })
                .then((res) => {
                    setImageKeyImage1(res.data.key);
                    url2 = res.data.url;
                    headersVar = res.data.headers;
                    axios
                        .put(url2, filesImage1[filesImage1.length - 1], {
                            headers: {
                                'x-amz-acl': 'public-read-write',
                                'Content-Type': imageType,
                            },
                        })
                        .then((res) => {
                            setPopupActive(false);
                        })
                        .catch((err) => setPopupActive(false));
                })
                .catch((err) => setPopupActive(false));
        },

        [filesImage1]
    );

    const [imageKeyImage2, setImageKeyImage2] = useState('');
    const [imagePreviewImage2, setImagePreviewImage2] = useState('');
    const [filesImage2, setFilesImage2] = useState([]);
    const handleDropZoneImage2 = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) =>
            setFilesImage2((filesImage2) => [...filesImage2, ...acceptedFiles]),
        []
    );
    const fileUploadImage2 = !filesImage2.length && <DropZone.FileUpload />;
    let uploadedFilesImage2 = filesImage2.length > 0 && (
        <Stack alignment='center'>
            <Thumbnail
                size='small'
                alt={filesImage2[filesImage2.length - 1].name}
                source={
                    validImageTypes.indexOf(filesImage2[filesImage2.length - 1].type) > 0
                        ? window.URL.createObjectURL(filesImage2[filesImage2.length - 1])
                        : 'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304'
                }
            />
            <div>
                {filesImage2[filesImage2.length - 1].name}{' '}
                <Caption>{filesImage2[filesImage2.length - 1].type} bytes</Caption>
            </div>
        </Stack>
    );
    const sendImage2 = useCallback(
        (filesImage2) => {
            setPopupActive(true);
            setImagePreviewImage2(
                validImageTypes.indexOf(filesImage2[filesImage2.length - 1].type) > 0
                    ? window.URL.createObjectURL(filesImage2[filesImage2.length - 1])
                    : 'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304'
            );
            imageType = filesImage2[filesImage2.length - 1].type;
            let headersVar = null;
            let url2 = '';
            let urlAmazon = 'https://basma.mintlearn.a2hosted.com/admin/v1/images/s3';
            const form_data = new FormData();
            form_data.append('content_type', imageType);
            axios
                .post('/admin/v1/images/s3', form_data, {
                    headers: {
                        Authorization: 'Bearer ' + Cookies.get('token'),
                    },
                })
                .then((res) => {
                    setImageKeyImage2(res.data.key);
                    url2 = res.data.url;
                    headersVar = res.data.headers;
                    axios
                        .put(url2, filesImage2[filesImage2.length - 1], {
                            headers: {
                                'x-amz-acl': 'public-read-write',
                                'Content-Type': imageType,
                            },
                        })
                        .then((res) => {
                            setPopupActive(false);
                        })
                        .catch((err) => setPopupActive(false));
                })
                .catch((err) => setPopupActive(false));
        },

        [filesImage2]
    );

    const [imageKeyImage3, setImageKeyImage3] = useState('');
    const [imagePreviewImage3, setImagePreviewImage3] = useState('');
    const [filesImage3, setFilesImage3] = useState([]);
    const handleDropZoneImage3 = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) =>
            setFilesImage3((filesImage3) => [...filesImage3, ...acceptedFiles]),
        []
    );
    const fileUploadImage3 = !filesImage3.length && <DropZone.FileUpload />;
    let uploadedFilesImage3 = filesImage3.length > 0 && (
        <Stack alignment='center'>
            <Thumbnail
                size='small'
                alt={filesImage3[filesImage3.length - 1].name}
                source={
                    validImageTypes.indexOf(filesImage3[filesImage3.length - 1].type) > 0
                        ? window.URL.createObjectURL(filesImage3[filesImage3.length - 1])
                        : 'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304'
                }
            />
            <div>
                {filesImage3[filesImage3.length - 1].name}{' '}
                <Caption>{filesImage3[filesImage3.length - 1].type} bytes</Caption>
            </div>
        </Stack>
    );
    const sendImage3 = useCallback(
        (filesImage3) => {
            setPopupActive(true);
            setImagePreviewImage3(
                validImageTypes.indexOf(filesImage3[filesImage3.length - 1].type) > 0
                    ? window.URL.createObjectURL(filesImage3[filesImage3.length - 1])
                    : 'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304'
            );
            imageType = filesImage3[filesImage3.length - 1].type;
            let headersVar = null;
            let url2 = '';
            let urlAmazon = 'https://basma.mintlearn.a2hosted.com/admin/v1/images/s3';
            const form_data = new FormData();
            form_data.append('content_type', imageType);
            axios
                .post('/admin/v1/images/s3', form_data, {
                    headers: {
                        Authorization: 'Bearer ' + Cookies.get('token'),
                    },
                })
                .then((res) => {
                    setImageKeyImage3(res.data.key);
                    url2 = res.data.url;
                    headersVar = res.data.headers;
                    axios
                        .put(url2, filesImage3[filesImage3.length - 1], {
                            headers: {
                                'x-amz-acl': 'public-read-write',
                                'Content-Type': imageType,
                            },
                        })
                        .then((res) => {
                            setPopupActive(false);
                        })
                        .catch((err) => setPopupActive(false));
                })
                .catch((err) => setPopupActive(false));
        },

        [filesImage3]
    );

    const handleChangeProc = (value, i) => {
        setErrorMsg(prev => ({
            ...prev,
            list_of_procedures: '',
        }));
        setListOfProcedures(prev => {
            prev[i] = value;
            return prev;
        })
    };

    const addRow = () => {
        setListOfProcedures(prev => [...prev, '']);
    }

    function isDefined(value) {
        if (value !== undefined && value !== null) return true;
    }

    function getQualifiedStatus(param) {
        switch (param) {
            case 0:
                setSelected('0');
                break;
            case 1:
                setSelected('1');
                break;
            case 2:
                setSelected('2');
                break;
            case 3:
                setSelected('3');
                break;
        }
    }

    const hanldeBookProceduresArray = (e, value) => {
        if (bookProceduresArray.includes(value)) {
            let array = bookProceduresArray.filter((item) => item != value);
            setBookProceduresArray(array);
        } else {
            setBookProceduresArray((prev) => [
                ...prev,
                value
            ])
        }
    }

    function handleSaveInfo() {
        let errMsg = {
            problem_question: '',
            dental_history: '',
            list_of_procedures: '',
        }
        setErrorMsg(errMsg);
        let listOfProceduresArr = listOfProcedures.filter(proc => {
            if (proc) {
                return proc;
            }
        });
        if (isMallCollaboration ? (isMandatory && !problemValue) : ((isMandatory && (!problemValue || !dentalHistoryValue)) || (dentalHistoryNaValue && listOfProceduresArr.length == 0))) {
            if (isMandatory && !problemValue) {
                errMsg = {
                    ...errMsg,
                    problem_question: 'Required fields',
                }
            }
            if(!isMallCollaboration) {
                if (isMandatory && !dentalHistoryValue) {
                    errMsg = {
                        ...errMsg,
                        dental_history: 'Required fields',
                    }
                }
                if (dentalHistoryNaValue && listOfProceduresArr.length == 0) {
                    errMsg = {
                        ...errMsg,
                        list_of_procedures: 'Please add a procedure',
                    }
                }
            }
            setErrorMsg(errMsg);
        } else {
            setLoading(true);
            const bodyObj = {
                ...(imageKeyImage1 && { image_1: imageKeyImage1 }),
                ...(imageKeyImage2 && { image_2: imageKeyImage2 }),
                ...(imageKeyImage3 && { image_3: imageKeyImage3 }),
                ...(dateValue && { date_of_birth: dateValue }),
                ...(problemValue && { problem_question: problemValue }),
                ...(selectedDentistVisited && {
                    visited_dentist: selectedDentistVisited === "Yes" ? true : false,
                }),
                ...(selectedHealthy && {
                    teeth_healthy: selectedHealthy === "Yes" ? true : false,
                }),
                ...(selectedWisdomTeeth && { wisdom_teeth: selectedWisdomTeeth }),
                ...(selectedWisdomTeethRemoval && {
                    wisdom_need_removal:
                        selectedWisdomTeethRemoval === "Yes" ? true : false,
                }),
                ...({ dental_history_na: dentalHistoryNaValue }),
                ...({ list_of_procedures: listOfProceduresArr }),
                ...(dentalHistoryValue && { dental_history: dentalHistoryValue }),
                ...(issueExplanationValue && { explain_issue: issueExplanationValue }),
                ...(selectedArches && { arches_to_treat: selectedArches }),
                ...(selectedReason && { disqualified_reason: selectedReason }),
                ...(painSymptoms && { pain_symptoms: painSymptoms }),
                procedures_array: bookProceduresArray
            };
            !imageKeyImage1 && delete bodyObj.image_1;
            !imageKeyImage2 && delete bodyObj.image_2;
            !imageKeyImage3 && delete bodyObj.image_3;
            axios
                .post(`admin/v1/users/${userId}/your-photos`, bodyObj, {
                    headers: {
                        Authorization: "Bearer " + Cookies.get("token"),
                    },
                })
                .then((res) => {
                })
                .catch((err) => console.log(err))
                .finally(f => {
                    setLoading(false);
                });
        }
    }

    function handleSaveTreatmentQualificationStatus() {
        setPopupActive(true);
        setUnqualifiedReasonDetailsRequiredMsg('');
        const bodyObj = {
            is_qualified: parseInt(selected),
            ...(unqualifiedReasonDetails && {
                disqualified_reason_details: unqualifiedReasonDetails,
            }),
            ...(selectedReason && { disqualified_reason: selectedReason }),
        };
        if (selected) {
            axios
                .post(`admin/v1/users/${userId}/your-photos/qualified `, bodyObj, {
                    headers: {
                        Authorization: "Bearer " + Cookies.get("token"),
                    },
                })
                .then((res) => {
                    setPopupActive(false);
                    if (res.data?.success && res.data.success == 'false') {
                        setUnqualifiedReasonDetailsRequiredMsg('Unqualified reason details are required')
                    } else {
                        history.push(`/admin/users/${userId}/dashboard`);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    useEffect(() => {
        axios.get(`admin/v1/users/${userId}/your-photos`, {
            headers: {
                Authorization: 'Bearer ' + Cookies.get('token'),
            },
        }).then(response => {
            const responseQuestions = response.data;
            setIsMallCollaboration(responseQuestions.is_mall_collaboration);
            responseQuestions.procedures_array && setBookProceduresArray(responseQuestions.procedures_array)
            responseQuestions.procedures_array && setViewProcedureOptions(responseQuestions.procedures_array.length > 0)
            if (responseQuestions?.data) {
                if(type == 'ik-screening' || type == 'bas-screening'){
                    responseQuestions.data.image_1_url && setImagePreviewImage1(responseQuestions.data.image_1_url);
                    responseQuestions.data.image_2_url && setImagePreviewImage2(responseQuestions.data.image_2_url);
                    responseQuestions.data.image_3_url && setImagePreviewImage3(responseQuestions.data.image_3_url);
                }
                responseQuestions.data.unqualified_reason && setSelectedReason(responseQuestions.data?.unqualified_reason)
                responseQuestions.data.unqualified_reason_details && setUnqualifiedReasonDetails(responseQuestions.data?.unqualified_reason_details)
                isDefined(responseQuestions.data.date_of_birth) && responseQuestions.data.date_of_birth && setDateValue(responseQuestions.data.date_of_birth);
                responseQuestions.data.problem_question && setProblemValue(responseQuestions.data.problem_question);
                responseQuestions.data.pain_symptoms && setPainSymptoms(responseQuestions.data.pain_symptoms);
                responseQuestions.data.owner_name && setUploadedBy(responseQuestions.data.owner_name);
                setIsMandatory(responseQuestions.mandatory);
                isDefined(responseQuestions.data.visited_dentist) && setSelectedDentistVisited(responseQuestions.data.visited_dentist === true ? 'Yes' : 'No');
                isDefined(responseQuestions.data.teeth_healthy) && setSelectedHealthy(responseQuestions.data.teeth_healthy === true ? 'Yes' : 'No');
                isDefined(responseQuestions.data.wisdom_teeth) && setSelectedWisdomTeeth(String(responseQuestions.data.wisdom_teeth));
                isDefined(responseQuestions.data.wisdom_need_removal) && setSelectedWisdomTeethRemoval(responseQuestions.data.wisdom_need_removal === true ? 'Yes' : 'No');
                setDentalHistoryValue(responseQuestions.data.dental_history);
                setDentalHistoryNaValue(responseQuestions.data.dental_history_na == 1 ? true : false);
                isDefined(responseQuestions.data.is_qualified) && getQualifiedStatus(responseQuestions.data.is_qualified);
                isDefined(responseQuestions.data.explain_issue) && responseQuestions.data.explain_issue && setIssueExplanationValue(responseQuestions.data.explain_issue);
                isDefined(responseQuestions.data.arches_to_treat) && responseQuestions.data.arches_to_treat && setSelectedArches(String(responseQuestions.data.arches_to_treat));
                setListOfProcedures(responseQuestions.data?.list_of_procedures?.length ? responseQuestions.data?.list_of_procedures : ['']);
            }
        });
    }, []);

    return (
        <Card title={title}>
            {(type == 'ik-screening' || type == 'bas-screening') &&
                <Card.Section sectioned>
                    <div className='screening-wrapper'>
                        <div className='questions-img-wrapper'>
                            <Thumbnail size="large" source={imagePreviewImage1 ? imagePreviewImage1 : ""} />
                            <TextStyle>Image1</TextStyle>
                            <DropZone onDrop={handleDropZoneImage1} onDropAccepted={sendImage1}>
                                {uploadedFilesImage1}
                                {fileUploadImage1}
                            </DropZone>
                        </div>
                        <div className='questions-img-wrapper'>
                            <Thumbnail size="large" source={imagePreviewImage2 ? imagePreviewImage2 : ""} />
                            <TextStyle>Image2</TextStyle>
                            <DropZone onDrop={handleDropZoneImage2} onDropAccepted={sendImage2}>
                                {uploadedFilesImage2}
                                {fileUploadImage2}
                            </DropZone>
                        </div>
                        <div className='questions-img-wrapper'>
                            <Thumbnail size="large" source={imagePreviewImage3 ? imagePreviewImage3 : ""} />
                            <TextStyle>Image3</TextStyle>
                            <DropZone onDrop={handleDropZoneImage3} onDropAccepted={sendImage3}>
                                {uploadedFilesImage3}
                                {fileUploadImage3}
                            </DropZone>
                        </div>
                    </div>
                </Card.Section>
            }
            {(type == 'ik-screening' || type == 'fv') &&
                <>
                    <Card.Section title='Questions' sectioned>
                        {
                            !isMallCollaboration &&
                            <>
                                <TextStyle variation='strong'>Date Birth</TextStyle>
                                <TextField
                                    type='date'
                                    value={dateValue}
                                    onChange={handleChangeDateValue}
                                />
                                <br />
                            </>
                        }
                        <TextStyle variation='strong'> What problem are you looking to get fixed?/Chief complaint </TextStyle>
                        <TextField value={problemValue} onChange={handleChangeProblemValue} />
                        <TextStyle variation='negative'>{errorMsg.problem_question}</TextStyle>
                        {
                            !isMallCollaboration &&
                            <>
                                <br />
                                <TextStyle variation='strong'>Any other dental history we should know about (implants, bridges, fillings, etc.)?/Clinical findings</TextStyle>
                                <TextField placeholder='Add comment...' value={dentalHistoryValue} onChange={handleChangeDentalHistoryValue} />
                                <TextStyle variation='negative'>{errorMsg.dental_history}</TextStyle>
                                <br />
                                <div className='checkbox-wrapper'>
                                    <Checkbox
                                        label='Patient needs to do pre-treatment procedures before starting aligner treatment.'
                                        checked={dentalHistoryNaValue}
                                        onChange={handleChangeDentalHistoryNaValue}
                                    />
                                    {dentalHistoryNaValue && <div>
                                        <TextStyle variation='strong'>Add the required procedures before the orthodontic treatment</TextStyle>
                                        {listOfProcedures.map((proc, key) => (
                                            <div key={key} style={{ display: 'flex', columnGap: '1rem', margin: '10px 0' }}>
                                                <div style={{ width: '85%' }}>
                                                    <TextField
                                                        placeholder='Add procedure'
                                                        value={proc}
                                                        onChange={(e) => handleChangeProc(e, key)}
                                                    />
                                                </div>
                                                {key == listOfProcedures.length - 1 && <Button onClick={addRow}>Add</Button>}
                                            </div>
                                        ))}
                                    </div>}
                                    <TextStyle variation='negative'>{errorMsg.list_of_procedures}</TextStyle>
                                </div>
                                <br />
                                <TextStyle variation='strong'>Pain/Symptoms</TextStyle>
                                <TextField value={painSymptoms} onChange={handleChangePainSymptoms} />
                                <br />
                                <TextStyle variation='strong'> Have you visited your dentist in the past 6 months? </TextStyle>
                                <Select
                                    options={optionsDentistVisited}
                                    onChange={handleSelectChangeDentistVisited}
                                    value={selectedDentistVisited}
                                    placeholder='Choose One'
                                />
                                {
                                    selectedDentistVisited === 'Yes' ? (
                                        <div style={{ marginTop: '30px' }}>
                                            <FormLayout>
                                                <TextStyle variation='strong'>
                                                    According to your dentist, are your teeth and gums healthy?
                                                </TextStyle>
                                                <Select
                                                    options={optionsHealthy}
                                                    onChange={handleSelectChangeHealthy}
                                                    value={selectedHealthy}
                                                    placeholder='Choose One'
                                                />
                                            </FormLayout>
                                        </div>
                                    ) : null
                                }
                                <br />
                                <TextStyle variation='strong'> Do you have any wisdom teeth? </TextStyle>
                                <Select
                                    options={optionsWisdomTeeth}
                                    onChange={handleSelectChangeWisdomTeeth}
                                    value={selectedWisdomTeeth}
                                    placeholder='Choose One'
                                />
                                {
                                    selectedWisdomTeeth === 'yes' ? (
                                        <div style={{ marginTop: '30px' }}>
                                            {' '}
                                            <FormLayout>
                                                {' '}
                                                <TextStyle variation='strong'>
                                                    According to your dentist, do your wisdom teeth need removal?
                                                </TextStyle>
                                                <Select
                                                    options={optionsWisdomTeethRemoval}
                                                    onChange={handleSelectChangeWisdomTeethRemoval}
                                                    value={selectedWisdomTeethRemoval}
                                                    placeholder='Choose One'
                                                />
                                            </FormLayout>
                                        </div>
                                    ) : null
                                }
                                <br />
                                <TextStyle variation='strong'>Please explain the issue</TextStyle>
                                <TextField
                                    value={issueExplanationValue}
                                    onChange={handleChangeIssueExplanationValue}
                                />
                                <br />
                                <TextStyle variation='strong'> Which arches do you want to treat? </TextStyle>
                                <Select
                                    options={optionsArches}
                                    onChange={handleSelectChangeArches}
                                    value={selectedArches}
                                    placeholder='Choose One'
                                />
                                <br />
                                <TextStyle variation='strong'>Uploaded By:</TextStyle> <TextStyle>{uploadedBy}</TextStyle>
                                <br />
                                <br />
                                <FormLayout>
                                    <Button style={{ marginTop: '1rem' }} onClick={() => setViewProcedureOptions((prev) => !prev)} primary>
                                        {viewProceduresOptions ? 'Hide' : 'Show'} Procedures Options
                                    </Button>
                                    {viewProceduresOptions &&
                                        <>
                                            <Checkbox
                                                label={procedures.CLEANING}
                                                checked={bookProceduresArray?.includes(procedures.CLEANING)}
                                                onChange={(e) => hanldeBookProceduresArray(e, procedures.CLEANING)}
                                            />
                                        </>
                                    }
                                </FormLayout>
                            </>
                        }
                    </Card.Section>
                    <div className='info-btn-wrapper'>
                        <PageActions
                            primaryAction={{
                                content: 'Save Info',
                                onClick: handleSaveInfo,
                                loading,
                                disabled: loading,
                            }}
                        />
                    </div>
                    <Card sectioned primaryFooterAction={{
                        content: 'Save Treatment Qualification Status',
                        onClick: handleSaveTreatmentQualificationStatus,
                    }}>
                        <Card sectioned title='Treatment Qualification Status'>
                            <FormLayout>
                                <Select
                                    options={options}
                                    onChange={handleSelectChange}
                                    value={selected}
                                    placeholder='Please Choose'
                                />
                            </FormLayout>
                        </Card>
                        {selected === '0' && <Card sectioned title='Discontinued reasons'>
                            <FormLayout>
                                <Select
                                    options={reasonOptions}
                                    onChange={handleSelectChangeReasons}
                                    value={selectedReason}
                                    placeholder='Please Choose'
                                />
                            </FormLayout>
                            <br />
                            <TextField
                                label='Unqualified reason details'
                                value={unqualifiedReasonDetails}
                                onChange={setUnqualifiedReasonDetails}
                            />
                            {<TextStyle variation='negative'>{unqualifiedReasonDetailsRequiredMsg}</TextStyle>}
                        </Card>}
                    </Card>
                </>
            }
        </Card>
    )
}

export default Questions