import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  Card,
  Layout,
  TextStyle,
  FormLayout,
  Banner,
  Toast,
} from "@shopify/polaris";
import Cookie from "js-cookie";
import axios from "../../../axios";
import "@zendeskgarden/react-pagination/dist/styles.css";
import {useHistory, useParams} from "react-router-dom";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import copy from "../../../assets/images/copy.png";
import { UserContext } from '../../../UserContext';
function Profile(props) {
  let {profileResponse, setTotalCost,setProfileResponse, setUserCosts} = props
  const { setBookAScanInfo } = useContext(UserContext);
  const { id } = useParams();
  const [active, setActive] = useState(false);
  const [copiedText, setCopiedText] = useState({
    text1: false,
    text2: false,
    text3: false,
    text4: false,
    text5: false,
  });
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const[error,setError]=useState({"userError":false,"profileError":false}) //[1]=user [2] =userprofile
  const [errorMessage,setErrorMessage]=useState({message:"",email:"",phone:""})
  const [firstClickCompain,setFirstClickCompain]=useState("Show first click campaign")
  const toastMarkup = active ? (
    <Toast content="Appointment Confirmed" onDismiss={toggleActive} />
  ) : null;
  useEffect(() => {
    props.setPopupActive(true);
    axios
      .get("admin/v1/users/" + id + "/profile", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })  
      .then((result) => {
        props.setIsUserFound(true);
        result.data.data && setProfileResponse (result.data);
        props.setIsLocalResident(result.data.data.is_local_resident !== 0);
        props.setProfileId(result.data.data.id);
        setBookAScanInfo(result.data.book_scan_info);
        props.setClinicStatus(result.data.appointment_details?.basma_managed_clinic);
        Cookie.set(
          "basma-profile-user",
          result.data.data.user.first_name +
            "-" +
            result.data.data.user.last_name
        );
        props.setFreshSalesEmail(result.data.data.user.email)
        Cookie.set(
          "profile-user-email",
          result.data.data.user.email);
        Cookie.set(
          "profile-user-name",
          result.data.data.user.first_name +
            " " +
            result.data.data.user.last_name
        );
        props.onGetAccountActivation(
          result.data.data.user.activated === 0 ? true : false
        );
        result.data.assigned_to &&
          props.onGetAssignedDoctor(result.data.assigned_to);
          result?.data?.appointment_details &&
          props.handleChangeProfileClinic(result?.data?.appointment_details?.clinic)
        props.onGetSalesOwner(
          result.data.data.sales_owner?.full_name
            ? result.data.data.sales_owner.full_name
            : "None"
        );

        props.onGetPreSalesOwner(
          result.data.data.presales_owner?.full_name
            ? result.data.data.presales_owner.full_name
            : "None"
        );

        props.onGetSalesSupportOwner(
            result.data.data.sales_support_owner?.full_name
                ? result.data.data.sales_support_owner.full_name
                : "None"
        );

        props.onGetAfterSalesOwner(
          result.data.data.after_sales_owner?.full_name
            ? result.data.data.after_sales_owner.full_name
            : "None"
        );
        result.data.data.user &&
          props.onGetCheckedCrystalign(
            result.data.data.user.is_crystalign === 1 ? true : false
          );
        result.data.data.user &&
          props.onGetCheckedIsLost(
            result.data.data.user.is_lost === 1 ? true : false
          );
          result.data.data.user &&
          props.setWrittenOff({writtenOffData: result.data.data.writtenOffUser, writtenOffLoading: false});
        result.data.data.user &&
          props.onGetCheckedIsInfluencer(
            result.data.data.user.is_influencer === 1
          );
        result.data.data.user &&
          props.onGetCheckedOnlyRetainer(
            result.data.data.user.only_retainer === 1 ? true : false
          );
        result.data.data.user &&
          result.data.data.user.phone &&
          props.onGetPhoneNumberValue(result.data.data.user.phone);
        props.onGetCheckedPurchaseImpressionKit(
          result.data.assigned_to?.length
            ? true
            : result.data.data.user.impression_kit_purchased
        );
        result.data.data.user &&
          result.data.data.user.aligner_kit_purchased &&
          props.onGetCheckedPurchaseAlignerKit(
            result.data.data.user.aligner_kit_purchased
          );
        result.data.data.user &&
          result.data.data.user.first_name &&
          result.data.data.user.last_name &&
          props.onGetPageTitle({id:result.data.data.user_id,
            fullname:result.data.data.user.first_name +
            " " +
            result.data.data.user.last_name,
            
            name : result.data.data.user.first_name +
            " " +
            result.data.data.user.last_name +
            "    " +
            ` (ID:  ${result.data.data.user_id})`, country: result.data.appointment_details?.country, city: result.data.appointment_details?.city,  email: result?.data?.data?.user?.email});
          if(result.data.data.user&&result.data.data.user.client_status){
            if(result.data.data.user.client_status=="Good"){
              props.setStatus({status:"Good",flag:'success'})

            }else if(result.data.data.user.client_status=="Overdue"){
              props.setStatus({status:"Overdue",flag:'attention'})

            }else if(result.data.data.user.client_status=="Doubtful"){
              props.setStatus({status:"Doubtful",flag:'warning'})

            }else if(result.data.data.user.client_status=="Delinquent"){
              props.setStatus({status:"Delinquent",flag:'critical'})

            }else if(result.data.data.user.client_status=="Settled"){
              props.setStatus({status:"Settled",flag:"info"})

            } else if (result.data.data.user.client_status=="Written Off") {
              props.setStatus({status:"Written Off",flag:""})
            }
          }
          setTotalCost(result.data.total);
          let headerStatuses = {
            outsourcedProduction: false,
            outsourcedDesigner: false,
            hasLinkedUser: null
          }
          if (result.data && result.data.data.outsourcedProduction) {
              headerStatuses.outsourcedProduction = true
          }
          if (result.data && result.data.data.outsourcedDesigner) {
            headerStatuses.outsourcedDesigner = true
          }
          if (result.data && result.data.data.hasLinkedUser) {
            headerStatuses.hasLinkedUser = result.data.data.hasLinkedUser;
          }
          props.setStatus((prev)=>({...prev,...headerStatuses}))
          
          if (result.data && result.data.costs) {
            setUserCosts(result.data.costs);
          }
          if (result.data && result.data.freelance_designers) {
            props.setFreelanceDesigners(result.data.freelance_designers.map((designer) => ({
              label: designer.full_name,
              value: designer.id
            })));
          }
          if (result.data?.data && result.data.data?.assigned_freelance_designers?.length) {
            let assignedFreelanceDesigners = result.data.data.assigned_freelance_designers;
            props.handleSelectFreelanceDesigner({
              label: assignedFreelanceDesigners[0].designer.full_name,
              value: assignedFreelanceDesigners[0].id
            });
          }
          if(result.data.data && result.data.data.operations_plan_type){
            let flag = 'info';
            if(result.data.data.operations_plan_type === 'Light'){
              flag = 'success';
            }else if(result.data.data.operations_plan_type === 'Medium'){
              flag = 'attention';
            }else if(result.data.data.operations_plan_type === 'Medium+'){
              flag = 'attention';
            }else if(result.data.data.operations_plan_type === 'Complex'){
              flag = 'warning';
            }else if(result.data.data.operations_plan_type === 'Complex+'){
              flag = 'critical';
            }
            props.setPlanType({status: result.data.data.operations_plan_type, flag})
          }
          props.setPopupActive(true)
      })
      .catch((err) => {
          props.setIsUserFound(false);
          console.log(`Error in profile ${err}`)
          setError((prevState) => ({
            ...prevState,
            "userError": false,
            "profileError": true,
          }));
         if(err && err.length > 0 && err[0].startsWith("Oops")){
            setError((prevState) => ({
              ...prevState,
              "userError": true,
              "profileError": true,
            }));
          }
          setErrorMessage({message:err[0],email:err[2],phone:err[3]});
          console.log(`normal error ${err[1] ?? null}`); // Handle other types of errors
          props.onGetPageTitle({name : err[1] ?? null});
      })
      .finally(() => props.setPopupActive(false));
  }, [props.profileId]);
  const fetchFirstClickCanpain = async () => {
    await axios.get("admin/v1/users/" + id + "/profile/first_click",
      {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        }
      })
      .then((res) => {
        setFirstClickCompain(`First Click Campaign:${res?.data?.data}`)
        console.log("First Click Campaign", res?.data?.data)
      })
      .catch((err) => {
        console.log("error on fetching first click campain data", err)
      })

  }
  const history = useHistory();
  const handleEdit = (all = false) => {
    let path = 'editprofile';
    if (all) {
      path = 'freshsales-contacts';
    }
    history.push(
        `/admin/users/${id}/dashboard/${path}`,
        {
          clinicName: props.clinicName,
          planType: props.planType,
          status: props.status,
          pageTitle: props.pageTitle,
          clinicStatus: props.clinicStatus,
          userId: props.userId
        }
    );
  }
  const handleCopy = (textKey, text) => {
    setCopiedText((prevState) => ({
      ...prevState,
      [textKey]: true,
    }));
    setTimeout(() => {
      setCopiedText((prevState) => ({
        ...prevState,
        [textKey]: false,
      }));
    }, 1000); 
  };
  const copyimage = {
    width: '16px',
    heigth: '16px',
    cursor: 'pointer',
    marginLeft:'7px'
  
  };
  const copiedtext = {
    fontWeight: '400',
    fontSize: '15px',
    marginLeft:'7px'
  };
  return (
    <Layout.Section>
    {error["userError"] ? ( 
      <Banner status="critical">
        <p>{errorMessage.message}</p>
      </Banner>
    ) : (
      <>
       {error["profileError"] && <Banner status="warning"> 
       <p>{errorMessage.message}</p>
        <p>{errorMessage.email}</p>
        <p>{errorMessage.phone}</p></Banner>} 
      {profileResponse.data && (
        <Card
          title="PROFILE"
          actions={[
            {
              content: "View All Data",
              onClick: () => handleEdit(true),
              disabled: String(Cookie.get("userType")) === "50" && true,
            },
            {
              content: "Edit",
              onClick: () => handleEdit(false),
              disabled: String(Cookie.get("userType")) === "50" && true,
            },
          ]}
        >
          <Card.Section>
            <FormLayout>
              {profileResponse.data?.user?.id && (
                <div style={{ display: "flex" }}>
                  <TextStyle>
                    ID: {profileResponse.data.user.id}
                  </TextStyle>
                  <CopyToClipboard
                    text={profileResponse.data.user.id}
                    onCopy={() =>
                      handleCopy(
                        "text4",
                        profileResponse.data.user.id,
                      )
                    }
                    options={{ format: "text/plain" }}
                  >
                    <div>
                      {copiedText.text4 ? (
                        <div style={copiedtext}>Copied!</div>
                      ) : (
                        <img src={copy} alt="copy" style={copyimage} />
                      )}
                    </div>
                  </CopyToClipboard>
                  <br />
                </div>
              )}
              {profileResponse.data?.user?.full_name && (
                <div style={{ display: "flex" }}>
                  <TextStyle>
                    Full Name: {profileResponse.data.user.full_name}
                  </TextStyle>
                  <CopyToClipboard
                    text={profileResponse.data.user.full_name}
                    onCopy={() =>
                      handleCopy(
                        "text3",
                        profileResponse.data.user.full_name,
                      )
                    }
                    options={{ format: "text/plain" }}
                  >
                    <div>
                      {copiedText.text3 ? (
                        <div style={copiedtext}>Copied!</div>
                      ) : (
                        <img src={copy} alt="copy" style={copyimage} />
                      )}
                    </div>
                  </CopyToClipboard>
                  <br />
                </div>
              )}
              {profileResponse.data?.user?.email && (
                  <div style={{display:'flex'}}>
                    <TextStyle> Email: {profileResponse.data.user.email}</TextStyle>
                    <CopyToClipboard 
                      text={profileResponse.data.user.email}
                      onCopy={() => handleCopy('text1', profileResponse.data.user.email)}
                      options={{ format: "text/plain" }}
                    >
                      <div>
                        {copiedText.text1 ? (
                          <div style={copiedtext}>
                        Copied!
                        </div>
                        ) : (
                          <img src={copy} alt="copy" style={copyimage} />
                        )}
                      </div>
                    </CopyToClipboard>
                    <br />
                  </div>
              )}
              {profileResponse.data?.user?.phone && (
                  <div style={{display:'flex'}}>
                    <TextStyle>Phone: {profileResponse.data.user.phone}</TextStyle>
                    <CopyToClipboard
                      text={profileResponse.data.user.phone}
                      onCopy={() => handleCopy('text2', profileResponse.data.user.phone)}
                      options={{ format: "text/plain" }}
                    >
                      <div>
                        {copiedText.text2 ? (
                          <div style={copiedtext}>
                        Copied!
                        </div>
                        ) : (
                          <img src={copy} alt="copy" style={copyimage} />
                        )}
                      </div>
                    </CopyToClipboard>
                    <br />
                  </div>
              )}
              {profileResponse.data?.user?.whatsapp_number && (
                  <div style={{display:'flex'}}>
                    <TextStyle>WhatsApp Number: {profileResponse.data.user.whatsapp_number}</TextStyle>
                    <CopyToClipboard
                      text={profileResponse.data.user.whatsapp_number}
                      onCopy={() => handleCopy('text5', profileResponse.data.user.whatsapp_number)}
                      options={{ format: "text/plain" }}
                    >
                      <div>
                        {copiedText.text5 ? (
                          <div style={copiedtext}>
                        Copied!
                        </div>
                        ) : (
                          <img src={copy} alt="copy" style={copyimage} />
                        )}
                      </div>
                    </CopyToClipboard>
                    <br />
                  </div>
              )}
              {profileResponse.data.age_id && (
                <div>
                  <TextStyle>Age: </TextStyle>
                  <TextStyle>
                    {switchAge(profileResponse.data.age_id)}
                  </TextStyle>
                  <br />
                </div>
              )}
              <TextStyle>Language: {profileResponse.data?.options?.language
                  ? profileResponse.data.options.language == "en"
                      ? "English"
                      : "Arabic"
                  : "Not Set (Arabic is default)"}
              </TextStyle>
              <div>
                {props?.bookingSurvey?.length > 0 ? (
                  <div>
                    <TextStyle>Booking Survey question: </TextStyle>
                    <br />
                    <TextStyle>
                      <TextStyle variation="strong">
                        Treatment Interested In:
                      </TextStyle>{" "}
                      {props?.bookingSurvey?.question1?.replace(",", ", ")}
                      <br />
                      <TextStyle variation="strong">
                        Last Dentist Visit:{" "}
                      </TextStyle>
                      {props?.bookingSurvey?.question2}
                      <br />
                      <TextStyle variation="strong">
                        Existing Conditions:{" "}
                      </TextStyle>
                      {props?.bookingSurvey?.question3?.replace(",", ", ")}
                      <br />
                      <TextStyle variation="strong">
                        Knows About Aligner:{" "}
                      </TextStyle>
                      {props?.bookingSurvey?.question4}
                      <br />
                      <TextStyle variation="strong">
                        Aligned Teeth Before:{" "}
                      </TextStyle>
                      {props?.bookingSurvey?.question5?.replace(",", ", ")}
                      <br />
                      <TextStyle variation="strong">Smile Goals: </TextStyle>
                      {props?.bookingSurvey?.question6}
                    </TextStyle>
                    <br />
                  </div>
                )&&<br />:<></>}
                
                {profileResponse?.data?.user?.variant ? (
                    <div>
                      <TextStyle>Flow: </TextStyle>
                      <TextStyle>{profileResponse.data.user.variant}</TextStyle>
                      <br />
                    </div>
                )&&<br />:<></>}
              
                {profileResponse.data.user?.app_os ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        columnGap: 5,
                        alignItems: "center",
                      }}
                    >
                      <TextStyle>First App login: </TextStyle>
                      <TextStyle>
                        {moment(profileResponse.data.user.app_logged_in_at).format("DD-MM-YYYY hh:mm A")}
                      </TextStyle>
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={
                          profileResponse.data.user.app_os == "android"
                            ? "/android.png"
                            : "/ios.png"
                        }
                        alt="OS"
                      />
                    </div>
                    <br />
                  </>
                ):<></>}
                <a 
                        onClick={fetchFirstClickCanpain}
                        style={{
                          color: "(internal value)",
                          textDecoration: [firstClickCompain === "Show first click campaign" ? "underline" : "unset"],
                          cursor: "pointer"
                        }}>
                        {firstClickCompain}
                </a> 
  
              </div>
            </FormLayout>
          </Card.Section>
          {toastMarkup}
        </Card>
          )
                       } </>)}
      
    </Layout.Section>
  );
  function switchAge(param) {
    switch (parseInt(param)) {
      case 1:
        return "Under 18";
        break;
      case 2:
        return "18-25";
        break;
      case 3:
        return "26-35";
        break;
      case 4:
        return "35+";
        break;
      case 5:
        return "46+";
        break;
      case 6:
        return "Under 18";
        break;
      case 7:
        return "18-20";
        break;
      case 8:
        return "21-25";
        break;
      case 9:
        return "26-30";
        break;
      case 10:
        return "31-35";
        break;
      case 11:
        return "36-40";
        break;
      case 12:
        return "41-50";
        break;
      case 13:
        return "50+";
        break;
    }
  }
  function switchReasonOfTeethStraigntening(param) {
    switch (parseInt(param)) {
      case 1:
        return "I’m starting a new job";
        break;
      case 2:
        return "I’m getting married soon";
        break;
      case 3:
        return "I’m looking to have generally straighter teeth";
        break;
      case 4:
        return "I want to have more confidence";
        break;
    }
  }
  function switchStartTime(param) {
    switch (parseInt(param)) {
      case 1:
        return "Immediatly";
        break;
      case 2:
        return "In a few weeks";
        break;
      case 3:
        return "In a few months";
        break;
      case 4:
        return "Not sure";
        break;
    }
  }
  function switchHowDidYouHear(param) {
    switch (parseInt(param)) {
      case 1:
        return "Twitter";
        break;
      case 2:
        return "Facebook";
        break;
      case 3:
        return "Snapchat";
        break;
      case 4:
        return "Tiktok";
        break;
      case 5:
        return "Google";
        break;
      case 6:
        return "Email";
        break;
      case 7:
        return "Instagram";
        break;
      case 8:
        return "Friend";
        break;
      case 9:
        return "Youtube";
        break;
      case 11:
        return "Linkedin";
        break;
      case 10:
        return "Other";
        break;
      case 12:
        return "Ad on other website";
        break;
      case 13:
        return "Influencer";
        break;
      case 14:
        return "Skipped";
      case 15:
        return "Eyewa Store";
    }
  }
  function switchProblem(param) {
    switch (parseInt(param)) {
      case 1:
        return "Crowding";
        break;
      case 3:
        return "Spacing";
        break;
      case 5:
        return "I don't know";
        break;
    }
  }
  function switchTeethDescription(param) {
    switch (parseInt(param)) {
      case 1:
        return "Mild";
        break;
      case 2:
        return "Moderate";
        break;
      case 3:
        return "Moderate+";
        break;
    }
  }
}
export default React.memo(Profile);
