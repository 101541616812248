import { useStyles } from './user-freshsales-contacts-accordin.styles';

const UserFreshsalesContactsListItem = ({ contactField, value }) => {
  const classes = useStyles();
  let valueData = value;
  if (Array.isArray(value) && value.length > 0) {
    valueData = JSON.stringify(value);
  } else if (!value || Array.isArray(value)) {
    valueData = '-';
  }
  return (
    <div className={classes.item}>
      <h6>{contactField}</h6>
      <span>{valueData}</span>
    </div>
  );
};

export default UserFreshsalesContactsListItem;
