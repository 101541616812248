import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import axios from "../axios";
import Cookies from "js-cookie";
import NavigationLayout from "../components/NavigationLayout";
import { Page } from "@shopify/polaris";
import ThinLoading from "../components/Loading/thin-loading.component";

function ViewClinics() {
  const [allCountry, setAllCountry] = useState([]);
  const [loading, setLoading] = useState(false);

  const getClinics = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        "/admin/v1/clinics",
        {},
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      setAllCountry(response.data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleOnClinic = (value) => {
    if (Cookies.get("token") && value?.id && value?.name_identification?.en) {
      window.open(
        `${
          process.env.REACT_APP_CLINIC_BASE_URL
        }/impersonate?token=${Cookies.get("token")}&clinicId=${
          value.id
        }&isScanAtHome=${value.is_scan_at_home}&name=${
          value?.name_identification?.en
        }`
      );
    }
  };

  useEffect(() => {
    getClinics();
  }, []);

  return (
    <NavigationLayout>
      <Page title="All Clinics">
        {loading ? (
          <ThinLoading />
        ) : (
          allCountry.length > 0 &&
          allCountry.map((res) => (
            <div style={{ paddingTop: "50px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "1.8rem",
                  paddingBottom: "20px",
                }}
              >
                {res.name.en}
              </div>

              <Box className="clinic-flex-styling">
                {res?.cities &&
                  res?.cities?.map((city) =>
                    city?.clinics.map((clinic) => (
                      <Box className="clinic-card-styling">
                        <Box className="top-line"></Box>
                        <Typography
                          className="clinic-typo-styling clinic-title"
                          style={{ marginBottom: "1rem" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              paddingBottom: "7px",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                className={`${
                                  clinic.is_offline === 0 ? "online" : "offline"
                                }`}
                              ></div>
                              <div style={{ marginLeft: "5px" }}>
                                {clinic.is_offline ? "Offline" : "Online"}
                              </div>
                            </div>
                            <div>
                              <div className="role">
                                {clinic?.clinic_admin_accesses &&
                                  clinic?.clinic_admin_accesses?.length !== 0 &&
                                  "Admin"}{" "}
                                {clinic?.clinic_admin_accesses?.length !== 0 &&
                                clinic?.clinic_moderator_accesses?.length !== 0
                                  ? "/"
                                  : ""}{" "}
                                {clinic.clinic_moderator_accesses &&
                                  clinic.clinic_moderator_accesses.length !==
                                    0 &&
                                  "Moderator"}
                              </div>
                            </div>
                          </div>
                          {clinic.name_identification.en}
                          <div className="clinic_id">{clinic.clinic_id}</div>
                        </Typography>
                        <Typography
                          className="clinic-typo-styling clinic-address clinic-margin-bottom"
                          style={{ marginBottom: "1rem" }}
                        >
                          {clinic?.address?.en}
                        </Typography>

                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <Button
                              className="clinic-typo-styling"
                              style={{
                                width: "fit-content",
                                backgroundColor: "#fff",
                                border: "0.1rem solid #C7C7C7",
                                boxShadow: "0px 0px 5px #8888",
                                marginRight: "5px",
                              }}
                              onClick={() => handleOnClinic(clinic)}
                            >
                              View
                            </Button>

                            {/* {isAdmin &&
                                                        <Button className='clinic-typo-styling' style={{ width: 'fit-content', backgroundColor: '#fff', border: '0.1rem solid #C7C7C7', boxShadow: '0px 0px 5px #8888' }}
                                                            onClick={() => history.push(`/admin/book-a-scan-countries/${res.id}/cities/${clinic.city_id}/clinics/${clinic.id}`)}>Edit</Button>


                                                    } */}
                          </div>
                          {clinic.basma_managed_clinic === 1 && (
                            <div className="basic_clinic">Basic Clinic</div>
                          )}
                        </div>
                      </Box>
                    ))
                  )}
              </Box>
            </div>
          ))
        )}
      </Page>
    </NavigationLayout>
  );
}

export default ViewClinics;
