import React from 'react';
import { ExportMinor } from "@shopify/polaris-icons";
import { Card, FormLayout, Page, TextStyle } from '@shopify/polaris';

function CompletedAlignerCases() {

    const exportCompletedCases = () => {
        window.open(
            `${process.env.REACT_APP_BASE_URL}/admin/cms_completed_cases_report`
        );
    }
    return (
        <Page
        fullWidth
        title="Completed Aligner Cases"
        breadcrumbs={[{ content: "List Of Reports", url: "/admin/reports" }]}
        secondaryActions={[
            {
            content: "Export",
            icon: ExportMinor,
            onAction: exportCompletedCases,
            },
        ]}
        >
        <Card sectioned>
            <FormLayout>
            <TextStyle>
                Export list of all completed aligner cases
            </TextStyle>
            </FormLayout>
        </Card>
        </Page>
    );
}

export default CompletedAlignerCases