import React, { useCallback, useState, useEffect } from "react";
import {
  Modal,
  Thumbnail,
  Toast,
  Caption,
  DropZone,
  Stack,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Select,
  Card,
  PageActions,
  Button,
  Checkbox,
  TextContainer,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";
import { RadioGroup, Radio } from "react-radio-group";

import {useLocation, useParams} from "react-router-dom";
import UserHeader from "../../../components/UserLayout/user-header";

function EditScreening() {
  let { id } = useParams();
  let imageType = "";
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const procedures = {
    CLEANING : 'Cleaning',
  }
  const [dentalHistoryNaValue, setDentalHistoryNaValue] = useState(false);
  const [listOfProcedures, setListOfProcedures] = useState(['']);
  const [errorMsg, setErrorMsg] = useState({
    problem_question: '',
    dental_history: '',
    list_of_procedures: '',
  });
  const [bookProceduresArray, setBookProceduresArray] = useState([]);
  const [viewProceduresOptions, setViewProcedureOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadedBy, setUploadedBy] = useState("");
  const [isMandatory, setIsMandatory] = useState(true);
  const [unqualifiedReasonDetails, setUnqualifiedReasonDetails] = useState('');
  const [unqualifiedReasonDetailsRequiredMsg, setUnqualifiedReasonDetailsRequiredMsg] = useState('');
  const [imageKeyImage1, setImageKeyImage1] = useState("");
  const [imagePreviewImage1, setImagePreviewImage1] = useState(
    Cookie.get("step4aUrl")
  );
  const [filesImage1, setFilesImage1] = useState([]);
  const handleDropZoneImage1 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesImage1((filesImage1) => [...filesImage1, ...acceptedFiles]),
    []
  );
  const fileUploadImage1 = !filesImage1.length && <DropZone.FileUpload />;
  let uploadedFilesImage1 = filesImage1.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesImage1[filesImage1.length - 1].name}
        source={
          validImageTypes.indexOf(filesImage1[filesImage1.length - 1].type) > 0
            ? window.URL.createObjectURL(filesImage1[filesImage1.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesImage1[filesImage1.length - 1].name}{" "}
        <Caption>{filesImage1[filesImage1.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage1 = useCallback(
    (filesImage1) => {
      setPopupActive(true);
      setImagePreviewImage1(
        validImageTypes.indexOf(filesImage1[filesImage1.length - 1].type) > 0
          ? window.URL.createObjectURL(filesImage1[filesImage1.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesImage1[filesImage1.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeyImage1(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesImage1[filesImage1.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesImage1]
  );

  const [imageKeyImage2, setImageKeyImage2] = useState("");
  const [imagePreviewImage2, setImagePreviewImage2] = useState(
    Cookie.get("step4bUrl")
  );
  const [filesImage2, setFilesImage2] = useState([]);
  const handleDropZoneImage2 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesImage2((filesImage2) => [...filesImage2, ...acceptedFiles]),
    []
  );
  const fileUploadImage2 = !filesImage2.length && <DropZone.FileUpload />;
  let uploadedFilesImage2 = filesImage2.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesImage2[filesImage2.length - 1].name}
        source={
          validImageTypes.indexOf(filesImage2[filesImage2.length - 1].type) > 0
            ? window.URL.createObjectURL(filesImage2[filesImage2.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesImage2[filesImage2.length - 1].name}{" "}
        <Caption>{filesImage2[filesImage2.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage2 = useCallback(
    (filesImage2) => {
      setPopupActive(true);
      setImagePreviewImage2(
        validImageTypes.indexOf(filesImage2[filesImage2.length - 1].type) > 0
          ? window.URL.createObjectURL(filesImage2[filesImage2.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesImage2[filesImage2.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeyImage2(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesImage2[filesImage2.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesImage2]
  );

  const [imageKeyImage3, setImageKeyImage3] = useState("");
  const [imagePreviewImage3, setImagePreviewImage3] = useState(
    Cookie.get("step4bUrl")
  );
  const [filesImage3, setFilesImage3] = useState([]);
  const handleDropZoneImage3 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesImage3((filesImage3) => [...filesImage3, ...acceptedFiles]),
    []
  );
  const fileUploadImage3 = !filesImage3.length && <DropZone.FileUpload />;
  let uploadedFilesImage3 = filesImage3.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesImage3[filesImage3.length - 1].name}
        source={
          validImageTypes.indexOf(filesImage3[filesImage3.length - 1].type) > 0
            ? window.URL.createObjectURL(filesImage3[filesImage3.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesImage3[filesImage3.length - 1].name}{" "}
        <Caption>{filesImage3[filesImage3.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage3 = useCallback(
    (filesImage3) => {
      setPopupActive(true);
      setImagePreviewImage3(
        validImageTypes.indexOf(filesImage3[filesImage3.length - 1].type) > 0
          ? window.URL.createObjectURL(filesImage3[filesImage3.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesImage3[filesImage3.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKeyImage3(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesImage3[filesImage3.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesImage3]
  );

  const hanldeBookProceduresArray = (e, value)=>{
    if(bookProceduresArray.includes(value)){
      let array = bookProceduresArray.filter((item)=> item != value);
      setBookProceduresArray(array);
    }else{
      setBookProceduresArray((prev) => [
        ...prev,
        value
      ])
    }
  }

  const addRow = () => {
    setListOfProcedures(prev => [...prev, '']);
  }

  const handleChangeProc = (value, i) => {
    setErrorMsg(prev => ({
        ...prev,
        list_of_procedures: '',
    }));
    setListOfProcedures(prev => {
        prev[i] = value;
        return prev;
    })
  };

  useEffect(() => {
    axios
      .get(`admin/v1/users/${id}/your-photos`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        result?.data?.data?.unqualified_reason && setSelectedReason(result?.data?.data?.unqualified_reason)
        result?.data?.data?.unqualified_reason_details && setUnqualifiedReasonDetails(result?.data?.data?.unqualified_reason_details)
        result?.data?.procedures_array && setBookProceduresArray(result?.data?.procedures_array)
        result?.data?.procedures_array && setViewProcedureOptions(result?.data?.procedures_array.length > 0)
        result.data.data.image_1_url &&
          setImagePreviewImage1(result.data.data.image_1_url);
        result.data.data.image_2_url &&
          setImagePreviewImage2(result.data.data.image_2_url);
        result.data.data.image_3_url &&
          setImagePreviewImage3(result.data.data.image_3_url);
        isDefined(result.data.data.date_of_birth) &&
          result.data.data.date_of_birth &&
          setDateValue(result.data.data.date_of_birth);
        result.data.data.problem_question &&
          setProblemValue(result.data.data.problem_question);
        result.data.data.pain_symptoms && setPainSymptoms(result.data.data.pain_symptoms);
        result.data.data.owner_name &&
          setUploadedBy(result.data.data.owner_name);
        setIsMandatory(result.data.mandatory);

        isDefined(result.data.data.visited_dentist) &&
          setSelectedDentistVisited(
            result.data.data.visited_dentist === true ? "Yes" : "No"
          );

        isDefined(result.data.data.teeth_healthy) &&
          setSelectedHealthy(
            result.data.data.teeth_healthy === true ? "Yes" : "No"
          );

        isDefined(result.data.data.wisdom_teeth) &&
          setSelectedWisdomTeeth(String(result.data.data.wisdom_teeth));
        isDefined(result.data.data.wisdom_need_removal) &&
          setSelectedWisdomTeethRemoval(
            result.data.data.wisdom_need_removal === true ? "Yes" : "No"
          );
        setDentalHistoryValue(result.data.data.dental_history);
        setDentalHistoryNaValue(result.data.data.dental_history_na == 1 ? true : false);
        setListOfProcedures(result.data.data?.list_of_procedures ? result.data.data?.list_of_procedures : ['']);
        isDefined(result.data.data.is_qualified) &&
          getQualifiedStatus(result.data.data.is_qualified);
        isDefined(result.data.data.explain_issue) &&
          result.data.data.explain_issue &&
          setIssueExplanationValue(result.data.data.explain_issue);
        isDefined(result.data.data.arches_to_treat) &&
          result.data.data.arches_to_treat &&
          setSelectedArches(String(result.data.data.arches_to_treat));
        // result?.data?.data?.unqualified_reason &&
        //   setUnqualifiedReasonValue(result.data.data.unqualified_reason);
      })
      .catch((err) => console.log(err));
  }, []);

  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content="Image Deleted" onDismiss={toggleActive} />
  ) : null;
  const [isUploadingAtive, setIsUploadingAtive] = useState(false);
  const [popupActive, setPopupActive] = useState(false);
  const [dateValue, setDateValue] = useState("");
  const handleChangeDateValue = useCallback(
    (newValue) => setDateValue(newValue),
    []
  );
  const [problemValue, setProblemValue] = useState("");
  const [painSymptoms, setPainSymptoms] = useState("");
  const handleChangeProblemValue = useCallback(
    (newValue) => setProblemValue(newValue),
    []
  );
  const handleChangePainSymptoms = useCallback(
    (newValue) => setPainSymptoms(newValue),
    []
  );
  const [selectedDentistVisited, setSelectedDentistVisited] = useState("");
  const handleSelectChangeDentistVisited = useCallback(
    (value) => setSelectedDentistVisited(value),
    []
  );
  const optionsDentistVisited = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  const [selectedHealthy, setSelectedHealthy] = useState("");
  const handleSelectChangeHealthy = useCallback(
    (value) => setSelectedHealthy(value),
    []
  );
  const optionsHealthy = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  const [selectedWisdomTeeth, setSelectedWisdomTeeth] = useState("");
  const handleSelectChangeWisdomTeeth = useCallback(
    (value) => setSelectedWisdomTeeth(value),
    []
  );
  const optionsWisdomTeeth = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "No" },
    { label: "i don't know", value: "i don't know" },
  ];
  const [selectedWisdomTeethRemoval, setSelectedWisdomTeethRemoval] =
    useState("");
  const handleSelectChangeWisdomTeethRemoval = useCallback(
    (value) => setSelectedWisdomTeethRemoval(value),
    []
  );
  const optionsWisdomTeethRemoval = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  const [dentalHistoryValue, setDentalHistoryValue] = useState("");
  const handleChangeDentalHistoryValue = useCallback(
    (newValue) => setDentalHistoryValue(newValue),
    []
  );
  const handleChangeDentalHistoryNaValue = (newValue) => {
    if(errorMsg){
      setErrorMsg('');
    }
    setDentalHistoryNaValue(newValue);
  }

  const [issueExplanationValue, setIssueExplanationValue] = useState("");
  const handleChangeIssueExplanationValue = useCallback(
    (newValue) => setIssueExplanationValue(newValue),
    []
  );
  const [selectedArches, setSelectedArches] = useState("");
  const handleSelectChangeArches = useCallback(
    (value) => setSelectedArches(value),
    []
  );
  const optionsArches = [
    { label: "Top", value: "1" },
    { label: "Bottom", value: "2" },
    { label: "Both", value: "3" },
  ];
  const [value, setValue] = useState("");

  const [selected, setSelected] = useState("");
  function handleSelectChange(value) {
    setSelected(value);
  }
  const [selectedReason, setSelectedReason] = useState('');
  function handleSelectChangeReasons(value) {
    setSelectedReason(value);
  }
  const options = [
    { label: "Qualified Top", value: "2" },
    { label: "Qualified Bottom", value: "3" },
    { label: "Qualified Both", value: "1" },
    { label: "Not Qualified", value: "0" },
  ];
  const reasonOptions = [
    {label: "Lead Stage - Underage", value:"Lead Stage - Underage"},
    {label: "Lead Stage - Difficult Case", value: "Lead Stage - Difficult Case" },
    {label: "Lead Stage - Unhealthy Teeth/Gums", value: "Lead Stage - Unhealthy Teeth/Gums" },
    {label: "Lead Stage - Country Not Covered", value: "Lead Stage - Country Not Covered" },
    {label: "Data Stage - Difficult Case", value:"Data Stage - Difficult Casee"},
    {label: "Data Stage - Incomplete Info For TP", value:"Data Stage - Incomplete Info For TP"},
    {label: "TP Stage - Difficult Case", value:"TP Stage - Difficult Case"},
    {label: "TP Stage - Incomplete Info For TP", value:"TP Stage - Incomplete Info For TP"},
    
  ];
  const location = useLocation();
  let linkState =
    {planType: "", clinicStatus: "", clinicName: "", status: "", userId: id, pageTitle: ""};
  if (location.state !== undefined) {
    const {planType, clinicStatus, clinicName, status, userId, pageTitle} = location.state;
    linkState = {planType, clinicStatus, clinicName, status, userId, pageTitle}
  }
  const { planType, clinicStatus, clinicName, status, userId, pageTitle} = linkState;

  return (
    <Page>
      <UserHeader pageTitle={pageTitle} status={status} userId={userId} clinicStatus={clinicStatus} clinicName={clinicName} planType={planType}/>
      <br/>
      <h1 className="page-header">Screening</h1>
      <Card title="Image 1" sectioned>
        <FormLayout>
          <Thumbnail
            size="large"
            source={imagePreviewImage1 ? imagePreviewImage1 : ""}
          />
          <DropZone onDrop={handleDropZoneImage1} onDropAccepted={sendImage1}>
            {uploadedFilesImage1}
            {fileUploadImage1}
          </DropZone>
        </FormLayout>
      </Card>

      <Card title="Image2" sectioned>
        <FormLayout>
          <Thumbnail
            size="large"
            source={imagePreviewImage2 ? imagePreviewImage2 : ""}
          />
          <DropZone onDrop={handleDropZoneImage2} onDropAccepted={sendImage2}>
            {uploadedFilesImage2}
            {fileUploadImage2}
          </DropZone>
        </FormLayout>
      </Card>
      <Card title="Image3" sectioned>
        <FormLayout>
          <Thumbnail
            size="large"
            source={imagePreviewImage3 ? imagePreviewImage3 : ""}
          />
          <DropZone onDrop={handleDropZoneImage3} onDropAccepted={sendImage3}>
            {uploadedFilesImage3}
            {fileUploadImage3}
          </DropZone>
        </FormLayout>
      </Card>
      <Card title="Questions" sectioned>
        <FormLayout>
          <TextStyle variation="strong">Date Birth</TextStyle>
          <TextField
            type="date"
            value={dateValue}
            onChange={handleChangeDateValue}
          />
          <TextStyle variation="strong">
            What problem are you looking to get fixed?/Chief complaint
          </TextStyle>
          <TextField multiline={3} value={problemValue} onChange={handleChangeProblemValue} />
          <TextStyle variation="negative">{errorMsg.problem_question}</TextStyle>

            {/* <RadioGroup
              name="radio-check"
              selectedValue={dentalHistoryNaValue}
              onChange={handleChangeDentalHistoryNaValue}
            >
              <div style={{ display: "flex", flexDirection: "column", rowGap: '5px'}}>
                <label><Radio value="optional" />Patient can proceed with aligner treatment without prior dental work.</label>
                <label><Radio value="mandatory" />Patient cannot proceed with aligner treatment without prior dental work.</label>
              </div>
            </RadioGroup> */}
            
            <TextStyle variation="strong">Any other dental history we should know about (implants, bridges, fillings, etc.)?/Clinical findings</TextStyle>
            <TextField
                multiline={3}
              placeholder='Add comment...'
              value={dentalHistoryValue}
              onChange={handleChangeDentalHistoryValue}
            />
            <TextStyle variation="negative">{errorMsg.dental_history}</TextStyle>
          <div className="checkbox-wrapper">  
            <Checkbox
              label='Patient needs to do pre-treatment procedures before starting aligner treatment.'
              checked={dentalHistoryNaValue}
              onChange={handleChangeDentalHistoryNaValue}
            />
            {dentalHistoryNaValue && <div>
              <TextStyle variation="strong">Add the required procedures before the orthodontic treatment</TextStyle>
              {listOfProcedures && listOfProcedures.map((proc, key) => (
                <div key={key} style={{display: 'flex', columnGap: '1rem', margin: '10px 0', alignItems: 'end'}}>
                  <div style={{width: '85%'}}>
                    <TextField
                      placeholder='Add procedure'
                      multiline={2}
                      value={proc}
                      onChange={(e) => handleChangeProc(e, key)}
                    />
                  </div>
                  {key == listOfProcedures.length - 1 && <Button onClick={addRow}>Add</Button>}
                </div>
              ))}
            </div>}
            <TextStyle variation="negative">{errorMsg.list_of_procedures}</TextStyle>
          </div>
          <TextStyle variation="strong">Pain/Symptoms</TextStyle>
          <TextField multiline={3} value={painSymptoms} onChange={handleChangePainSymptoms} />
          <TextStyle variation="strong">
            Have you visited your dentist in the past 6 months?
          </TextStyle>
          <Select
            options={optionsDentistVisited}
            onChange={handleSelectChangeDentistVisited}
            value={selectedDentistVisited}
            placeholder="Choose One"
          />
          {selectedDentistVisited === "Yes" ? (
            <div style={{ marginTop: "30px" }}>
              <FormLayout>
                <TextStyle variation="strong">
                  According to your dentist, are your teeth and gums healthy?
                </TextStyle>
                <Select
                  options={optionsHealthy}
                  onChange={handleSelectChangeHealthy}
                  value={selectedHealthy}
                  placeholder="Choose One"
                />
              </FormLayout>
            </div>
          ) : null}

          <TextStyle variation="strong">
            Do you have any wisdom teeth?
          </TextStyle>
          <Select
            options={optionsWisdomTeeth}
            onChange={handleSelectChangeWisdomTeeth}
            value={selectedWisdomTeeth}
            placeholder="Choose One"
          />
          {selectedWisdomTeeth === "yes" ? (
            <div style={{ marginTop: "30px" }}>
              {" "}
              <FormLayout>
                {" "}
                <TextStyle variation="strong">
                  According to your dentist, do your wisdom teeth need removal?
                </TextStyle>
                <Select
                  options={optionsWisdomTeethRemoval}
                  onChange={handleSelectChangeWisdomTeethRemoval}
                  value={selectedWisdomTeethRemoval}
                  placeholder="Choose One"
                />
              </FormLayout>
            </div>
          ) : null}
          <TextStyle variation="strong">Please explain the issue</TextStyle>
          <TextField
              multiline={3}
            value={issueExplanationValue}
            onChange={handleChangeIssueExplanationValue}
          />
          <TextStyle variation="strong">
            Which arches do you want to treat?
          </TextStyle>
          <Select
            options={optionsArches}
            onChange={handleSelectChangeArches}
            value={selectedArches}
            placeholder="Choose One"
          />
          <TextStyle variation="strong">Uploaded By:</TextStyle>
          <TextStyle>{uploadedBy}</TextStyle>
        </FormLayout>
      </Card>
      <Card sectioned title="">
          <FormLayout>
            <Button style={{marginTop: '1rem'}} onClick={() => setViewProcedureOptions((prev) => !prev)} primary>
                {viewProceduresOptions ? 'Hide' : 'Show'} Procedures Options
            </Button>
            {viewProceduresOptions && 
              <>
                <Checkbox
                  label={procedures.CLEANING}
                  checked={bookProceduresArray?.includes(procedures.CLEANING)}
                  onChange={(e) => hanldeBookProceduresArray(e, procedures.CLEANING)}
                />
              </>
            }
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save Info",
          onClick: handleSaveInfo,
          loading,
          disabled: loading,
        }}
      />
      <Card sectioned title="" primaryFooterAction={{
        content: "Save Treatment Qualification Status",
        onClick: handleSaveTreatmentQualificationStatus,
      }}>
      <Card sectioned title="Treatment Qualification Status">
        <FormLayout>
          <Select
            options={options}
            onChange={handleSelectChange}
            value={selected}
            placeholder="Please Choose"
          />
          {/* {selected == "0" && (
            <TextField
              label="Please type the reason"
              value={unqualifiedReasonValue}
              onChange={handleChangeUnqualifiedReason}
            ></TextField>
          )} */}
        </FormLayout>
      </Card>
      {selected === '0' && <Card sectioned title="Discontinued reasons">
        <FormLayout>
          <Select
            options={reasonOptions}
            onChange={handleSelectChangeReasons}
            value={selectedReason}
            placeholder="Please Choose"
          />
        </FormLayout>
        <br />
        <TextField
            multiline={3}
          label="Unqualified reason details"
          value={unqualifiedReasonDetails}
          onChange={setUnqualifiedReasonDetails}
        />
        {<TextStyle variation="negative">{unqualifiedReasonDetailsRequiredMsg}</TextStyle>}
      </Card>}
      </Card>

      {toastMarkup}
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );
  function getQualifiedStatus(param) {
    switch (param) {
      case 0:
        setSelected("0");
        break;
      case 1:
        setSelected("1");
        break;
      case 2:
        setSelected("2");
        break;
      case 3:
        setSelected("3");
        break;
    }
  }
  function isDefined(value) {
    if (value !== undefined && value !== null) return true;
  }

  function handleSaveInfo() {
    let errMsg = {
      problem_question: '',
      dental_history: '',
      list_of_procedures: '',
    }
    setErrorMsg(errMsg);
    let listOfProceduresArr = listOfProcedures.filter(proc => {
      if(proc){
        return proc;
      }
    });
    if((isMandatory && (!problemValue || !dentalHistoryValue)) || (dentalHistoryNaValue && listOfProceduresArr.length == 0)){
        if(isMandatory && !problemValue){
          errMsg = {
            ...errMsg,
            problem_question: 'Required fields',
          }
        }
        if(isMandatory && !dentalHistoryValue){
          errMsg = {
            ...errMsg,
            dental_history: 'Required fields',
          }
        }
        if(dentalHistoryNaValue && listOfProceduresArr.length == 0){
          errMsg = {
            ...errMsg,
            list_of_procedures: 'Please add a procedure',
          }
        }
        setErrorMsg(errMsg);
    } else {
      setLoading(true);
      const bodyObj = {
        ...(imageKeyImage1 && { image_1: imageKeyImage1 }),
        ...(imageKeyImage2 && { image_2: imageKeyImage2 }),
        ...(imageKeyImage3 && { image_3: imageKeyImage3 }),
        ...(dateValue && { date_of_birth: dateValue }),
        ...(problemValue && { problem_question: problemValue }),
        ...(selectedDentistVisited && {
          visited_dentist: selectedDentistVisited === "Yes" ? true : false,
        }),
        ...(selectedHealthy && {
          teeth_healthy: selectedHealthy === "Yes" ? true : false,
        }),
        ...(selectedWisdomTeeth && { wisdom_teeth: selectedWisdomTeeth }),
        ...(selectedWisdomTeethRemoval && {
          wisdom_need_removal:
            selectedWisdomTeethRemoval === "Yes" ? true : false,
        }),
        ...({ dental_history_na: dentalHistoryNaValue }),
        ...({ list_of_procedures: listOfProceduresArr }),
        ...(dentalHistoryValue && { dental_history: dentalHistoryValue }),
        ...(issueExplanationValue && { explain_issue: issueExplanationValue }),
        ...(selectedArches && { arches_to_treat: selectedArches }),
        ...(selectedReason && {disqualified_reason: selectedReason}),
        ...(painSymptoms && {pain_symptoms: painSymptoms}),
        procedures_array: bookProceduresArray
      };
      !imageKeyImage1 && delete bodyObj.image_1;
      !imageKeyImage2 && delete bodyObj.image_2;
      !imageKeyImage3 && delete bodyObj.image_3;
      axios
        .post(`admin/v1/users/${id}/your-photos`, bodyObj, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
        })
        .catch((err) => console.log(err))
        .finally(f => {
          setLoading(false);
        });
    }
  }
  
  function handleSaveTreatmentQualificationStatus() {
    setPopupActive(true);
    setUnqualifiedReasonDetailsRequiredMsg('');
    const bodyObj = {
        is_qualified: parseInt(selected),
        ...(unqualifiedReasonDetails && {
          disqualified_reason_details: unqualifiedReasonDetails,
      }),
      ...(selectedReason && {disqualified_reason: selectedReason}),
    };
        if (selected) {
          axios
            .post(`admin/v1/users/${id}/your-photos/qualified `, bodyObj, {
              headers: {
                Authorization: "Bearer " + Cookie.get("token"),
              },
            })
            .then((res) => {
              setPopupActive(false);
              if(res.data?.success && res.data.success == 'false'){
                setUnqualifiedReasonDetailsRequiredMsg('Unqualified reason details are required')
              } else {
                history.push("/admin/users/" + id + "/dashboard");
              }
            })
            .catch((err) => console.log(err));
        }
  }
}
export default EditScreening;
