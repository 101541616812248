import { forwardRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide,
} from '@mui/material';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AlertRemoveBatchDialog = ({
  open = false,
  setOpen,
  selectedIndex,
  setFormRows,
}) => {
  const handleRemoveRow = () => {
    setFormRows((prevRows) => {
      let newRows = [...prevRows];
      newRows.splice(selectedIndex, 1);
      let counter = 1;
      newRows = newRows.map((row) => {
        if (row.combined === undefined) {
          return { ...row, delivery: counter++ };
        }
        return row;
      });

      // Update the child deliveries
      newRows = newRows.map((item) => {
        const findIndex = newRows.findIndex(
          (value) =>
            value.delivery === item.delivery - 1 &&
            value.number !== item.number &&
            item.number === '-'
        );
        if (findIndex !== -1 && item.combined) {
          return { ...item, delivery: newRows[findIndex].delivery };
        }
        return item;
      });
      return newRows;
    });
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ minWidth: '600px', fontSize: '16px' }}>
        {'Are you sure you want remove this batch?'}
      </DialogTitle>
      <DialogActions>
        <Button sx={{ fontSize: '12px' }} onClick={handleClose}>
          No
        </Button>
        <Button sx={{ fontSize: '12px' }} onClick={handleRemoveRow}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertRemoveBatchDialog;
