import React, {  useState, useEffect } from "react";
import {
  Image,
  DisplayText,
  Card,

} from "@shopify/polaris";
import { Button as ButtonMui,Typography } from "@material-ui/core";

import emptyIcon from "../../../img/emptyList.svg";
import { CardActions, TextField } from "@mui/material";
import ExistingCorporateDeals from "./ExistingCorporateDeals";
import axios from "../../../axios";
import Cookies from "js-cookie";

function CorporateDeals({clinic_id, items, setItems,isEmpty, setIsEmpty, success, setSuccess}) {

    const[couponOptions,setCouponOptions]=useState([]);

    useEffect(()=>{
      let bodyObj={};
      axios
          .get(`/admin/v1/get_all_corporate_deals`,{
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
            },
          })
          .then((result) => {
            setCouponOptions(result.data.coupons)
          })
          .catch((e)=>{
            console.log(e)
          })
    },[])
 
  return (
    <Card sectioned title="Corporate Deals">
      <CardActions style={{ float: "right" }}>
        <ButtonMui variant="contained" style={{backgroundColor:"rgb(0 128 96)",color:"white","padding":"1.5em 2em",}}
         onClick={()=>{console.log('clicked');setItems((prev)=>[...prev,{email:"", coupon_id:"",editable:true,id:null}]); setIsEmpty(false);}}>
            <Typography variant="h6" >Add New</Typography></ButtonMui>
          </CardActions>
          <table style={{display:"grid",width: "100%",overflowX:"auto",overflowY: "hidden" ,padding:"1em 0"}}>
              <tr style={{display: "flex",gap:'10px'}}>
              <th  className="plan-header" >Email</th>
              <th className="plan-header">Coupon</th>
              </tr>
           { items.map((item) => (
               <ExistingCorporateDeals clinic_id={clinic_id} id={item.id} email={item.email} coupon={item.coupon_id} couponOptions={couponOptions}  editable={item.editable} success={success} setSuccess={setSuccess} />
           ))
      }
            </table>
        {isEmpty ? (
        <div className="message-no-result">
          <div className="message-no-result-img">
            <Image src={emptyIcon}></Image>{" "}
          </div>
          <DisplayText size="small">No results found</DisplayText>
          <br />
        </div>
      ) : null}
      </Card>
  )
}

export default CorporateDeals