import React, { useCallback, useEffect, useState } from "react";
import {
  Modal,
  Thumbnail,
  Caption,
  DropZone,
  Stack,
  TextStyle,
  FormLayout,
  Icon,
  Button,
} from "@shopify/polaris";
import {
  MobileCancelMajor,
} from '@shopify/polaris-icons';
import axios from "../../../axios";
import Cookie from "js-cookie";
import Popup from "../Popup";
import ThreeDfilesViewer from "./ThreeDfilesViewer";
import { Collapse } from '@mui/material';

import JSZip from "jszip";
const ThreeDfilesDropZone = (props) => {
  const { setThreeDViewerZipFileKey } = props;
  const [popupActive, setPopupActive] = useState(false);
  let imageType = "";
  const [filesFile, setFilesFile] = useState([]);
  const [errorFiles, setErrorFiles] = useState(false);
  let fileKey = "";
  let size = 0;
  const [imageKeyFile, setImageKeyFile] = useState("");
  let arrayOfElements = [];
  const [filesFull, setFilesFull] = useState([]);
  const fileUploadFull = !filesFull.length && <DropZone.FileUpload />;
  const [loading, setLoading] = useState(false);
  const [activeDeleteMessage, setActiveDeleteMessage] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [expanded, setExpanded] = useState(false);
  useEffect(()=>{
    if(props.displayable3dFiles && props.displayable3dFiles[0] && props.displayable3dFiles[0].length > 0){
      setDisabled(false);
    }
  }, [props.displayable3dFiles]);
  const uploadedFilesFull = filesFull.length > 0 && (
    <Stack horizontal>
      {filesFull.map((file, index) => (
        <Stack alignment="center" key={index}>
          <Thumbnail
            size="small"
            alt={file.name}
            source={window.URL.createObjectURL(file)}
          />
          <div>
            {file.name} <Caption>{file.size} bytes</Caption>
          </div>
        </Stack>
      ))}
    </Stack>
  );
  const handleDropZoneDropFull = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesFull((filesFull) => [...filesFull, ...acceptedFiles]),
    []
  );
  const sendImage = useCallback(
    (filesFull) => {
      let indexFile = 0;
      if(arrayOfElements.length > 0){
        arrayOfElements = [];
        props.handleChangeArrayElements([]);
      }
      let arrayFilesNames = [];
      let error = false;
      let pos;
      for (var i = 0; i < filesFull.length; i++) {
        arrayFilesNames.push(filesFull[i].name.split(".")[0]);
      }
      // TODO: check for multiples of two & check for names (mtl and obj under the same arch)
      // & index check & check for consecutive indeces & start from 0 (check for upper0 or lower0)
      // TODO: in this loop, get how many steps are uploading (unique indeces)
      if(multipleOf4(filesFull.length) || multipleOf2(filesFull.length)){
        if(filesFull.length > 2 && ((arrayFilesNames.includes('0_upper_obj') && arrayFilesNames.includes('0_upper_mtl') && !(arrayFilesNames.includes('0_lower_obj') && arrayFilesNames.includes('0_lower_mtl'))) || (arrayFilesNames.includes('0_lower_obj') && arrayFilesNames.includes('0_lower_mtl') && !(arrayFilesNames.includes('0_upper_obj') && arrayFilesNames.includes('0_upper_mtl'))))){
          pos = filesFull[0].name.split(".")[0].split('_')[1] == filesFull[1].name.split(".")[0].split('_')[1] ? filesFull[0].name.split(".")[0].split('_')[1] : '';
          for (var i = 1; i < filesFull.length / 2; i++) {
            if((pos == 'upper' && (!arrayFilesNames.includes(`${i}_upper_obj`) || !arrayFilesNames.includes(`${i}_upper_mtl`)) || (pos == 'lower' && (!arrayFilesNames.includes(`${i}_lower_obj`) || !arrayFilesNames.includes(`${i}_lower_mtl`))))){
                size = 0;
                error = true;
                setErrorFiles(true);
                setFilesFull([]);
                break;
            }
          }
          if(error == false){
            size = 2;
          }
        }
        else if(filesFull.length > 4){
          for (var i = 0; i < filesFull.length / 4; i++) {
            if(!arrayFilesNames.includes(`${i}_upper_obj`) || !arrayFilesNames.includes(`${i}_upper_mtl`) || !arrayFilesNames.includes(`${i}_lower_obj`) || !arrayFilesNames.includes(`${i}_lower_mtl`)){
              size = 0;
              error = true;
              setErrorFiles(true);
              setFilesFull([]);
              break;
            }
          }
          if(error == false){
            size = 4;
          }
        }
        else {
          size = 0;
          setErrorFiles(true);
          setFilesFull([]);
        }
      }
      else {
        size = 0;
        setErrorFiles(true);
        setFilesFull([]);
      }
      if(size > 0 && arrayOfElements.length == 0){
        setDisabled(false);
        setErrorFiles(false);
        setPopupActive(true);
        setPopupActive(true);
        // for (var i = 0; i < filesFull.length / 4; i++) {
        // TODO: loop over unique indeces
        for (var i = 0; i < filesFull.length / size; i++) {
          arrayOfElements.push({
            id: i,
            imagekey: {
              upperObj: "",
              upperMtl: "",
              lowerObj: "",
              lowerMtl: "",
            },
          });
        }

        imageType = filesFull[0].type;
        let headersVar = null;
        let url2 = "";
        const form_data = new FormData();
        form_data.append("content_type", "text/plain");
        axios
          .post("/admin/v1/images/s3", form_data, {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          })
          .then((res) => {
            setImageKeyFile(res.data.key);
            fileKey = res.data.key;
            url2 = res.data.url;
            headersVar = res.data.headers;
            axios
              .put(url2, findArrayElementByName(filesFull, "0_upper_obj"), {
                headers: {
                  "x-amz-acl": "public-read-write",
                  "Content-Type": "text/plain",
                },
              })
              .then((res) => {
                arrayOfElements[indexFile].imagekey.upperObj = fileKey;

                //UPPERMTL
                axios
                  .post("/admin/v1/images/s3", form_data, {
                    headers: {
                      Authorization: "Bearer " + Cookie.get("token"),
                    },
                  })
                  .then((res) => {
                    setImageKeyFile(res.data.key);
                    fileKey = res.data.key;
                    url2 = res.data.url;
                    headersVar = res.data.headers;
                    axios
                      .put(
                        url2,
                        findArrayElementByName(filesFull, "0_upper_mtl"),
                        {
                          headers: {
                            "x-amz-acl": "public-read-write",
                            "Content-Type": "text/plain",
                          },
                        }
                      )
                      .then((res) => {
                        arrayOfElements[indexFile].imagekey.upperMtl = fileKey;
                        //LOWEROBJ
                        axios
                          .post("/admin/v1/images/s3", form_data, {
                            headers: {
                              Authorization: "Bearer " + Cookie.get("token"),
                            },
                          })
                          .then((res) => {
                            setImageKeyFile(res.data.key);
                            fileKey = res.data.key;
                            url2 = res.data.url;
                            headersVar = res.data.headers;
                            axios
                              .put(
                                url2,
                                findArrayElementByName(
                                  filesFull,
                                  "0_lower_obj"
                                ),
                                {
                                  headers: {
                                    "x-amz-acl": "public-read-write",
                                    "Content-Type": "text/plain",
                                  },
                                }
                              )
                              .then((res) => {
                                arrayOfElements[
                                  indexFile
                                ].imagekey.lowerObj = fileKey;
                                //LOWERMTL
                                axios
                                  .post("/admin/v1/images/s3", form_data, {
                                    headers: {
                                      Authorization:
                                        "Bearer " + Cookie.get("token"),
                                    },
                                  })
                                  .then((res) => {
                                    setImageKeyFile(res.data.key);
                                    fileKey = res.data.key;
                                    url2 = res.data.url;
                                    headersVar = res.data.headers;
                                    axios
                                      .put(
                                        url2,
                                        findArrayElementByName(
                                          filesFull,
                                          "0_lower_mtl"
                                        ),
                                        {
                                          headers: {
                                            "x-amz-acl": "public-read-write",
                                            "Content-Type": "text/plain",
                                          },
                                        }
                                      )
                                      .then((res) => {
                                        arrayOfElements[
                                          indexFile
                                        ].imagekey.lowerMtl = fileKey;
                                        // TODO: loop over unique indeces - 1
                                        if (filesFull.length / size > 1) {
                                          indexFile++;

                                          sendToS3(indexFile++, filesFull);
                                        } else {
                                          setPopupActive(false);
                                        }
                                      })
                                      .catch((err) => console.log(err));
                                  })
                                  .catch((err) => console.log(err));

                                // indexFile++;
                                // sendToS3(indexFile++);
                              })
                              .catch((err) => console.log(err));
                          })
                          .catch((err) => console.log(err));

                        // indexFile++;
                        // sendToS3(indexFile++);
                      })
                      .catch((err) => console.log(err));
                  })
                  .catch((err) => console.log(err));

                // indexFile++;
                // sendToS3(indexFile++);
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      }
    },

    [filesFile]
  );
  
  const zipThreeDFiles = async (arrayFiles) => {
    const zip = new JSZip();
    // Function to read a File and add it to the zip.
    const addFileToZip = (file) => {
      return new Promise(async (resolve) => {
        // Read the file as an ArrayBuffer.
        const fileData = await new Promise((resolveFile, rejectFile) => {
          const reader = new FileReader();
          reader.onload = () => resolveFile(reader.result);
          reader.onerror = rejectFile;
          reader.readAsArrayBuffer(file);
        });

        // Add the file to the zip archive with its original name.
        zip.file(file.name, fileData, { compression: 'DEFLATE', compressionOptions: { level: 9 } });
        resolve();
      });
    };
    const promises = arrayFiles.map(addFileToZip);
    try {
      await Promise.all(promises);
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      let url2 = '';
      const form_data = new FormData();
      form_data.append('content_type', zipBlob.type);
      try {
        const response = await axios.post('/admin/v1/images/s3', form_data, {
          headers: {
            Authorization: `Bearer ${Cookie.get('token')}`,
          },
        });
        if (response.status === 200) {
          const { key, url } = response.data;
          setThreeDViewerZipFileKey(key);
          url2 = url;
          await axios.put(url2, zipBlob, {
            headers: {
              'x-amz-acl': 'public-read-write',
              'Content-Type': zipBlob.type,
            },
          });
        }
      } catch (error) {
        console.log("Error in saving zip file on aws", error);
      }
    } catch (error) {
      console.error('Error zipping files:', error);
    }
  }

  async function sendToS3(index, arrayFiles) {
    if (index === 1) {
      await zipThreeDFiles(arrayFiles);
    }
    let headersVar = null;
    let url2 = "";
    const form_data = new FormData();
    form_data.append("content_type", "text/plain");

    axios
      .post("/admin/v1/images/s3", form_data, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        setImageKeyFile(res.data.key);
        fileKey = res.data.key;
        url2 = res.data.url;
        headersVar = res.data.headers;

        axios
          .put(
            url2,
            findArrayElementByName(arrayFiles, String(index) + "_upper_obj"),
            {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": "text/plain",
              },
            }
          )
          .then((res) => {
            arrayOfElements[index].imagekey.upperObj = fileKey;

            //UPPERMTL
            axios
              .post("/admin/v1/images/s3", form_data, {
                headers: {
                  Authorization: "Bearer " + Cookie.get("token"),
                },
              })
              .then((res) => {
                setImageKeyFile(res.data.key);
                fileKey = res.data.key;
                url2 = res.data.url;
                headersVar = res.data.headers;

                axios
                  .put(
                    url2,
                    findArrayElementByName(
                      arrayFiles,
                      String(index) + "_upper_mtl"
                    ),
                    {
                      headers: {
                        "x-amz-acl": "public-read-write",
                        "Content-Type": "text/plain",
                      },
                    }
                  )
                  .then((res) => {
                    arrayOfElements[index].imagekey.upperMtl = fileKey;
                    //LOWEROBJ
                    axios
                      .post("/admin/v1/images/s3", form_data, {
                        headers: {
                          Authorization: "Bearer " + Cookie.get("token"),
                        },
                      })
                      .then((res) => {
                        setImageKeyFile(res.data.key);
                        fileKey = res.data.key;
                        url2 = res.data.url;
                        headersVar = res.data.headers;

                        axios
                          .put(
                            url2,
                            findArrayElementByName(
                              arrayFiles,
                              String(index) + "_lower_obj"
                            )
                          )
                          .then((res) => {
                            arrayOfElements[index].imagekey.lowerObj = fileKey;
                            //LOWERMTL
                            axios
                              .post("/admin/v1/images/s3", form_data, {
                                headers: {
                                  Authorization:
                                    "Bearer " + Cookie.get("token"),
                                },
                              })
                              .then((res) => {
                                setImageKeyFile(res.data.key);
                                fileKey = res.data.key;
                                url2 = res.data.url;
                                headersVar = res.data.headers;

                                axios
                                  .put(
                                    url2,
                                    findArrayElementByName(
                                      arrayFiles,
                                      String(index) + "_lower_mtl"
                                    )
                                  )
                                  .then((res) => {
                                    arrayOfElements[
                                      index
                                    ].imagekey.lowerMtl = fileKey;
                                    props.handleChangeArrayElements(
                                      arrayOfElements
                                    );

                                    if (index < arrayFiles.length / size - 1) {
                                      index++;
                                      sendToS3(index++, arrayFiles);
                                    } else {
                                      props.autoSave();
                                      setPopupActive(false);
                                    }
                                  })
                                  .catch((err) => console.log(err));
                              })
                              .catch((err) => console.log(err));

                            // indexFile++;
                            // sendToS3(indexFile++);
                          })
                          .catch((err) => console.log(err));
                      })
                      .catch((err) => console.log(err));

                    // indexFile++;
                    // sendToS3(indexFile++);
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => console.log(err));

            // indexFile++;
            // sendToS3(indexFile++);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }

  const delete3DFiles = () => {
    setActiveDeleteMessage(false);
    setLoading(true);
    axios.delete(`/admin/v1/users/${props.id}/smile-plan/${props.plan_id}/3d-Files/delete`, {
      data: {
        files: props.files3D?.three_dimension_smile,
      },
      headers: {
        Authorization: "Bearer " + Cookie.get("token"),
      },
    }).then((res) => {
      if(res.data.status){
        setLoading(false);
        setFilesFull([]);
        props.setNumberOf3dFiles(0);
        props.setDisplayable3dFiles([]);
        setDisabled(true);
      }
    })
  }
  return (
    <div className="threeD-files-container">
      <Modal open={props.handleLearnMore}>
        <div className="threeD-help-modal">
          <button style={{ position: "absolute", top: 10, right: 10, height: "35px", width: "35px", padding: 10, backgroundColor: "transparent", border: "none", color: "gray", cursor: "pointer", fontWeight: "bold" }} onClick={() => props.setHandleLearnMore(false)}>
            <Icon source={MobileCancelMajor} />
          </button>
          <TextStyle variation="positive">
            The files should be named in this format:
            index_upper_obj, index_upper_mtl, index_lower_obj, index_lower_mtl
          </TextStyle>
          <TextStyle variation="positive">
            Example:
            0_upper_obj, 0_upper_mtl, 0_lower_obj, 0_lower_mtl, 1_upper_obj, 1_upper_mtl, 1_lower_obj, 1_lower_mtl, etc...
          </TextStyle>
        </div>
      </Modal>
      <Popup 
        activeDeleteMessage={activeDeleteMessage}
        handleNoDelete={() => {setActiveDeleteMessage(false)}}
        handleYesDelete={delete3DFiles}
        onClose={() => setActiveDeleteMessage(false)}
        text="The files will be permanently deleted. Are you sure you want to continue?"
        title="Delete Files"
      />
        <FormLayout>
          <DropZone disabled={props.disabled} onDrop={handleDropZoneDropFull} onDropAccepted={sendImage} variableHeight={true}>
            {uploadedFilesFull}
            {fileUploadFull}
          </DropZone>{" "}
          {props.numberOf3dFiles && props.numberOf3dFiles !== 0 ? (
            <>
              <span className="threeD-count-p" onClick={() => setExpanded(!expanded)}>
                {props.numberOf3dFiles + " Files are uploaded" + " "}
              </span>
              <br/>
              <br/>
              {props.displayable3dFiles && props.displayable3dFiles[0] && props.displayable3dFiles[0].length > 0 &&
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <div style={{display: "flex", flexWrap: "wrap"}}>
                  {props.displayable3dFiles[0].map((file, key) => (
                      <div key={key} style={{border: "1.5px solid #C9CCCF", borderRadius: "3px", background: "#FAFBFB", padding: "0 2px", margin: " 0 2px 2px 0"}}>
                        <span>
                          {file}
                        </span>
                      </div>
                      )
                    )}
                  </div>
                </Collapse>
              }
            </>
          ) : ''}
          {errorFiles && (
            <TextStyle variation="negative">Invalid Files</TextStyle>
          )}
          <div style={{display: "flex", justifyContent: "right"}}>
            <Button disabled={disabled} loading={loading} onClick={()=>{
              if(props.displayable3dFiles && props.displayable3dFiles[0] && props.displayable3dFiles[0].length > 0){
                setActiveDeleteMessage(true);
              }
              else{
                setLoading(true);
                arrayOfElements = [];
                setTimeout(() => {
                  props.handleChangeArrayElements([]);
                  setFilesFull([]);
                  setLoading(false);
                  setDisabled(true);
                }, 500);
              }
            }}>
              Delete
            </Button>
          </div>
        {!props.createMode && props.files3D?.three_dimension_smile?.length ? <ThreeDfilesViewer
          files={props.files3D?.three_dimension_smile_url}
        /> : null}
        </FormLayout>
      <Modal open={popupActive} loading={true}></Modal>
    </div>
  );
  function findArrayElementByName(array, name) {
    return array.find((element) => {
      return element.name.split(".")[0] === name;
    });
  }
  function multipleOf4(x) {
    if (x % 4 == 0) {
      return true;
    } else {
      return false;
    }
  }
  function multipleOf2(x) {
    if (x % 2 == 0) {
      return true;
    } else {
      return false;
    }
  }
};

export default React.memo(ThreeDfilesDropZone);
