import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Button,
  Page,
  Image,
  DisplayText,
  AppProvider,
  Loading,
  Card,
  TextStyle,
  ButtonGroup,
  Filters,
  Pagination,
  Spinner,
} from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../axios";
import history from "../history";
import emptyIcon from "../img/emptyList.svg";
import moment from "moment";

export default function List() {
  // var json_str = Cookie.get('clickedItem');
  // var cookieResult = JSON.parse(json_str);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sortState, setSortState] = useState("ascending");
  const [items, setItems] = useState([]);
  let arrayOfObjects = [];
  let newArray = [];
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  let listOfProducts = [];
  let listOfPlans = [];
  const [perPage, setPerPage] = useState(15);
  const [queryValue, setQueryValue] = useState("");
  function handleFiltersQueryChange(queryValue) {
    setQueryValue(queryValue);
  }
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const filters = [];
  const [isListEmpty, setIsListEmpty] = useState(false);

  useEffect(() => {
    getData();
  }, [currentPage, queryValue]);
  function getData() {
    axios
      .get("admin/v1/products", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        // listOptionsProducts.push();
        result.data.data.data.length === 0 && setIsEmpty(true);
        for (var i = 0; i < result.data.data.data.length; i++) {
          listOfProducts.push({
            id: result.data.data.data[i].id,
            title: result.data.data.data[i].title.en,
          });
        }
        axios
          .get("admin/v1/plans", {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          })
          .then((result) => {
            for (var i = 0; i < result.data.data.length; i++) {
              listOfPlans.push({
                id: result.data.data[i].id,
                title: result.data.data[i].title.en,
              });
            }

            axios
              .get(
                `admin/v1/coupons?per_page=${perPage}&page=${currentPage}&filter[code]=${queryValue}`,
                {
                  headers: {
                    Authorization: "Bearer " + Cookie.get("token"),
                  },
                }
              )
              .then((result) => {
                console.log("resultCoupons=", result);
                setTotalPages(Math.ceil(result.data.data.total / perPage));
                if (result.data.data.data.length === 0) {
                  setIsEmpty(true);
                }
                setIsLoading(false);
                for (var i = 0; i < result.data.data.data.length; i++) {
                  if (result.data.data.data[i]["product_id"] !== null) {
                    arrayOfObjects.push([
                      result.data.data.data[i].id,
                      result.data.data.data[i].code,
                      result.data.data.data[i].count,
                      result.data.data.data[i].amount,
                      result.data.data.data[i].start_date,
                      result.data.data.data[i].end_date,
                      result.data.data.data[i].product_id,
                      result.data.data.data[i].active,
                      "_product",
                      result.data.data.data[i].type,
                    ]);
                  } else if (result.data.data.data[i]["plan_id"] !== null) {
                    arrayOfObjects.push([
                      result.data.data.data[i].id,
                      result.data.data.data[i].code,
                      result.data.data.data[i].count,
                      result.data.data.data[i].amount,
                      result.data.data.data[i].start_date,
                      result.data.data.data[i].end_date,
                      result.data.data.data[i].plan_id,
                      result.data.data.data[i].active,
                      "_plan",
                      result.data.data.data[i].type,
                    ]);
                  } else {
                    arrayOfObjects.push([
                      result.data.data.data[i].id,
                      result.data.data.data[i].code,
                      result.data.data.data[i].count,
                      result.data.data.data[i].amount,
                      result.data.data.data[i].start_date,
                      result.data.data.data[i].end_date,
                      result.data.data.data[i].plan_id,
                      result.data.data.data[i].active,
                      "",
                      result.data.data.data[i].type,
                    ]);
                  }
                }
                //
                setItems(
                  result.data.data.data.map((item) => [
                    <div
                      className="itemClick"
                      id={String(item.id)}
                      onClick={(e) => handleRowClick(e)}
                    >
                      {item.id}
                    </div>,
                    <div
                      className="itemClick"
                      id={String(item.id)}
                      onClick={(e) => handleRowClick(e)}
                    >
                      {item.code}
                    </div>,
                    <div
                      className="itemClick"
                      id={String(item.id)}
                      onClick={(e) => handleRowClick(e)}
                    >
                      {item.count}
                    </div>,
                    <div
                      className="itemClick"
                      id={String(item.id)}
                      onClick={(e) => handleRowClick(e)}
                    >
                      {item.times_used}
                    </div>,

                    <div
                      className="itemClick"
                      id={String(item.id)}
                      onClick={(e) => handleRowClick(e)}
                    >
                      {item.type
                        ? "All aligners"
                        : findById(listOfProducts, item.product_id)
                        ? findById(listOfProducts, item.product_id).title
                        : findById(listOfPlans, item.plan_id)
                        ? findById(listOfPlans, item.plan_id).title
                        : ""}
                    </div>,
                    <div
                      className="itemClick"
                      id={String(item.id)}
                      onClick={(e) => handleRowClick(e)}
                    >
                      {item.amount + " %"}
                    </div>,
                    <div
                      className="itemClick"
                      id={String(item.id)}
                      onClick={(e) => handleRowClick(e)}
                    >
                      {item.start_date}
                    </div>,
                    <div
                      className="itemClick"
                      id={String(item.id)}
                      onClick={(e) => handleRowClick(e)}
                    >
                      {item.end_date}
                    </div>,
                    item.active === 1 ? "Active" : item.active === 0 && moment(item.end_date).isBefore(moment()) ? "Expired" : "Inactive",
                    item.early_bird?item.early_bird==1?"First 5 days":"5 to 10 days":"",
                    <ButtonGroup>
                      <Button
                        id={String(item.id)}
                        onClick={(e) => handleEdit(e)}
                      >
                        Edit
                      </Button>
                    </ButtonGroup>,
                  ])
                );
              })
              .catch((err) => console.log(err));
          })

          .catch((err) => console.log(err));
      })

      .catch((err) => console.log(err));
  }

  function handlePageChange(currentPage) {
    setCurrentPage(currentPage);
  }
  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    []
  );
  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div className="message-no-result"
    >
      <div className="message-no-result-img">
        <Image src={emptyIcon}></Image>{" "}
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner accessibilityLabel="Spinner example" size="large" />
        </div>
      </div>
    )
  );

  return (
    <Page
      fullWidth
      title="List Of Coupons"
      primaryAction={{
        content: "Create Coupon",
        url: "/admin/coupons/create",
      }}
    >
      {loadingMarkup}
      <Card>
        <Card.Section>
          <Filters
            queryValue={queryValue}
            filters={filters}
            // appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            // onClearAll={handleFiltersClearAll}
          />
        </Card.Section>
        <DataTable
          columnContentTypes={[
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
          ]}
          headings={[
            <TextStyle variation="strong">ID</TextStyle>,
            <TextStyle variation="strong">Code</TextStyle>,
            <TextStyle variation="strong">Remaining Uses</TextStyle>,
            <TextStyle variation="strong">Times Used</TextStyle>,
            <TextStyle variation="strong">Product</TextStyle>,
            <TextStyle variation="strong">Discount Amount</TextStyle>,
            <TextStyle variation="strong">Start Date</TextStyle>,
            <TextStyle variation="strong">End Date</TextStyle>,
            <TextStyle variation="strong">Status</TextStyle>,
            <TextStyle variation="strong">Early Bird</TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
          ]}
          rows={items}
          sortable={[true, false, false, false, false,false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={1}
          verticalAlign="center"
        />
        {emtyState}
        {totalPages >= 2 && (
          <div style={{ marginTop: "20px" }}>
            <Pagination
              hasPrevious={currentPage > 1 && true}
              onPrevious={() => {
                setCurrentPage(currentPage - 1);
              }}
              hasNext={currentPage < totalPages && true}
              onNext={() => {
                setCurrentPage(currentPage + 1);
              }}
              label={`${currentPage}/${totalPages}`}
            />
          </div>
        )}
      </Card>
    </Page>
  );

  function handleEdit(e) {
    let currentID = e.currentTarget.id;
    var json_str = JSON.stringify(
      findArrayElementById(arrayOfObjects, currentID)
    );
    Cookie.set("clickedItem", json_str);
    history.push("/admin/coupons/" + currentID);
  }
  function handleRowClick(e) {
    history.push("/admin/coupons/" + e.currentTarget.id + "/users");
  }

  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element[0]) === parseInt(id);
    });
  }
  function findById(array, id) {
    return array.find((element) => {
      return element.id === id;
    });
  }
}
