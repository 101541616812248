import Select from 'react-select';
import { useStyles } from '../Reports/ClinicReports/clinic-reports-invoices-table.styles';
import React from 'react';

const DispatchFilter = ({
  treatmentPlanTypes,
  setSelectedPlan,
  dispatchCodes,
  setSelectedType,
  loading,
  productTypes,
  setSelectedProductType,
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.inputContainer}>
        <label htmlFor="treatement-stage">Treatment Types:</label>
        <Select
          id="treatement-stage"
          options={treatmentPlanTypes}
          isClearable
          isLoading={loading}
          onChange={(selectedOption) => setSelectedPlan(selectedOption)}
        />
      </div>
      <div className={classes.inputContainer}>
        <label htmlFor="types">Product Types:</label>
        <Select
          id="types"
          options={productTypes}
          isClearable
          isLoading={loading}
          onChange={(selectedOption) => setSelectedProductType(selectedOption)}
        />
      </div>
      <div className={classes.inputContainer}>
        <label htmlFor="disaptch-types">Dispatch Types:</label>
        <Select
          id="disaptch-types"
          options={dispatchCodes}
          isClearable
          isLoading={loading}
          onChange={(selectedOption) => setSelectedType(selectedOption)}
        />
      </div>
    </>
  );
};

export default DispatchFilter;
