import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  dialogTitle: {
    minWidth: '600px',
    fontSize: '18px !important'
  },
  labelSize: {
    fontSize: '18px !important'
  },
  input: {
    '& input': {
      fontSize: '14px !important'
    }
  },
  button: {
    '& button': {
      fontSize: '12px !important'
    }
  }
}));
