import {
  TableCell,
  TableRow,
  ButtonGroup,
  Button,
  TextareaAutosize,
} from '@mui/material';
import { useStyles } from '../../../../Reports/ClinicReports/clinic-reports-invoices-table.styles';
import { useStyles as customStyle } from './smile-plan-templates-table.styles';
import { Save, Delete, AddCircle } from '@mui/icons-material';
import DeleteSmilePlanTemplateDialog from './delete-smile-plan-template-dialog.component';
import { useState } from 'react';

const SmilePlanTemplatesRow = ({
  smilePlanTemplate,
  handleRowInputChange,
  handleAddRow,
  handleRemoveRow,
  index,
  saveSingleSmilePlanTemplate,
  deleteSingleSmilePlanTemplate,
}) => {
  const classes = useStyles();
  const customClasses = customStyle();
  const [open, setOpen] = useState(false);
  const handleSave = (smilePlanTemplate) => {
    if (smilePlanTemplate.id === '#') {
      saveSingleSmilePlanTemplate({
        english: smilePlanTemplate.english,
        arabic: smilePlanTemplate.arabic,
      });
    } else {
      saveSingleSmilePlanTemplate(smilePlanTemplate);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell className={classes.tableCell}>
        {smilePlanTemplate.id}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <TextareaAutosize
          value={smilePlanTemplate.english}
          className={customClasses.inputSize}
          onChange={(e) =>
            handleRowInputChange(index, 'english', e.target.value)
          }
          aria-label="minimum height"
          minRows={3}
          placeholder="English Description"
        />
      </TableCell>
      <TableCell className={classes.tableCell}>
        <TextareaAutosize
          value={smilePlanTemplate.arabic}
          className={customClasses.inputSize}
          onChange={(e) =>
            handleRowInputChange(index, 'arabic', e.target.value)
          }
          aria-label="minimum height"
          minRows={3}
          placeholder="Arabic Description"
        />
      </TableCell>
      <TableCell className={classes.tableCell}>
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <Button
            onClick={() => handleSave(smilePlanTemplate)}
            startIcon={<Save />}
          >
            Save
          </Button>
          <Button onClick={() => handleAddRow(index)} startIcon={<AddCircle />}>
            Add
          </Button>
          <Button onClick={handleClickOpen} startIcon={<Delete />}>
            Delete
          </Button>
        </ButtonGroup>
      </TableCell>
      <DeleteSmilePlanTemplateDialog
        id={smilePlanTemplate.id}
        deleteSingleSmilePlanTemplate={deleteSingleSmilePlanTemplate}
        handleRemoveRow={handleRemoveRow}
        index={index}
        setOpen={setOpen}
        open={open}
      />
    </TableRow>
  );
};

export default SmilePlanTemplatesRow;
