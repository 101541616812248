import { Card, Collapsible, Icon, Modal, Page, Button, DataTable, Badge } from '@shopify/polaris'
import React, { useEffect, useState } from 'react'
import { Router, useLocation, useParams } from 'react-router-dom'
import NavigationLayout from './components/NavigationLayout'
import history from './history'
import axios from './axios'
import Cookies from 'js-cookie'
import {
  FolderDownMajor
} from '@shopify/polaris-icons';
import {
  ChevronUpMinor,
  ChevronDownMinor
} from '@shopify/polaris-icons';
import { Dialog, DialogActions } from '@material-ui/core'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserHeader from "./components/UserLayout/user-header";
import moment from 'moment'
import { GeneralFormats } from './util/constants'
import { getUserStatus, switchUserStatusColors } from './util/helpers'

const CarePlus = () => {
  const { id } = useParams();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const section = params.get('section');
  const dateFormat = GeneralFormats.dateFormat;
  const [carePlus, setCareplus] = useState({})
  const [loading, setLoading] = useState({
    start: false,
    mid: false,
    end: false,
  })
  const [items, setItems] = useState([]);
  const [popupActive, setPopupActive] = useState(false)
  const [approveStatus, setApproveStatus] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [confirm, setConfirm] = useState()
  const handleClickOpen = (status, id, type) => {
    setOpenDialog(true);
    setConfirm({ status, id, type })
  };


  const handleConfirm = () => {
    const { status, id, type } = confirm
    approveCarePlus(status, id, type)
  }

  const handleClose = () => {
    setOpenDialog(false);
  };
  const [open, setOpen] = useState({
    testimonials: false,
    beforeAndAfters: false,
    referral: false,
    answers: false,
  })

  const sendNotification = (key) => {
    let type = key == 0 ? 'start' : key == 1 ? 'mid' : 'end';
    setLoading(prev => ({...prev, [type]: true}));
    let body = {
      user_id: id,
      type
    };
    axios.post(`/admin/v1/users/create/pending-care-plus`, body, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then((res) => {
      if(res.data.success){
        toast.success(res.data.message, 3000);
      } else {
        toast.error(res.data.message, 3000);
      }
      fetchCareplus();
    }).catch((err) => {
      console.log(err)
    }).finally(f => {
      setLoading(prev => ({...prev, [type]: false}));
    });
  }

  const getSurveyAnsweredAtDate = (key) => {
    return carePlus?.survey[key == 0 ? 'start' : key == 1 ? 'mid' : 'end']?.find(quest => quest.type == key == 0 ? 'start' : key == 1 ? 'mid' : 'end')?.answers[0]?.created_at;
  }

  const fetchCareplus = async() => {
    setPopupActive(true);
    try{
      const response = await axios.get(`/admin/v1/users/careplus/${id}`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });
      
      if (response) {
        setCareplus({
          testimonials: response?.data?.testimonials,
          referral: response?.data?.referral,
          beforeAndAfters: response?.data?.beforeAndAfters,
          survey: response?.data?.questions,
          pendingCarePlus: response.data.pendingCarePlus,
          user: response?.data?.user,
        })
      }
    } catch ($err) {
      console.log($err);
    } finally {
      setPopupActive(false);
    }
  }

  useEffect(() => {
    if (section) {
      if (section == 'Testimonials') {
        setOpen({
          ...open,
          testimonials: true
        })
      } else if (section == 'BeforeAndAfter') {
        setOpen({
          ...open,
          beforeAndAfters: true
        })
      } else if (section == 'Referral') {
        setOpen({
          ...open,
          referra: true
        })
      }
    }
  }, [])

  const downloadFile = async (source, name) => {
    const url = window.URL.createObjectURL(new Blob([source]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    fetchCareplus();
  }, [approveStatus])

  const approveCarePlus = (status, elementID, type) => {
    let body
    if (type == 'Before') {
      body = {
        before_status: status,
        id: elementID
      }
    } else if (type == 'After') {
      body = {
        after_status: status,
        id: elementID
      }
    } else {
      body = {
        status: status,
        id: elementID
      }
    }
    try {
      axios.post(`/admin/v1/users/careplus/status/${id}/${type}`, body, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
        .then((response) => {
          setApproveStatus(!approveStatus)
          setOpenDialog(false)
        })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if(JSON.stringify(carePlus) !== '{}'){
      let tableRows = Array(3).fill().map((item, key) => [
        <p><strong>{key == 0 ? 'Start' : key == 1 ? 'Mid' : 'End'} of treatment Survey</strong></p>,
        <Button size='slim' loading={key == 0 ? loading.start : key == 1 ? loading.mid : loading.end} disabled={getSurveyAnsweredAtDate(key)} onClick={() => sendNotification(key)} primary>Send</Button>,
        <p>{key == 0 ? carePlus?.pendingCarePlus?.find(pc => pc.type == 'start')?.notification_sent_at : key == 1 ? carePlus?.pendingCarePlus?.find(pc => pc.type == 'mid')?.notification_sent_at : carePlus?.pendingCarePlus?.find(pc => pc.type == 'end')?.notification_sent_at}</p>,
        <p>{getSurveyAnsweredAtDate(key)}</p>,
      ]);
      setItems(tableRows);
    }
  }, [carePlus, loading])

  const location = useLocation();
  let linkState =
    {planType: "", clinicStatus: "", clinicName: "", status: "", userId: id, pageTitle: ""};
  if (location.state !== undefined) {
    const {planType, clinicStatus, clinicName, status, userId, pageTitle} = location.state;
    linkState = {planType, clinicStatus, clinicName, status, userId, pageTitle}
  }
  const { planType, clinicStatus, clinicName, status, userId, pageTitle} = linkState;

  return (
    <NavigationLayout>
      <Router history={history}>
        <Page>
        <ToastContainer/>
          <UserHeader pageTitle={pageTitle} status={status} userId={userId} clinicStatus={clinicStatus} clinicName={clinicName} planType={planType}/>
          <br/>
          <h1 className="page-header">Care Plus section</h1>
          <Card>
            <Card.Section>
              <p><strong>User ID:</strong> {carePlus?.user?.id}</p>
              <p><strong>Name:</strong> {carePlus?.user?.full_name}</p>
              <p><strong>Email:</strong> {carePlus?.user?.email}</p>
            </Card.Section>
          </Card>
          <Card>
            <Card.Section title='Survey notifications'>
              <DataTable
                columnContentTypes={["text", "text", "text", "text"]}
                headings={[
                  <p style={{width: '140px'}}></p>,
                  <p></p>,
                  <p><strong>Notification sent at</strong></p>,
                  <p><strong>Survey answered at</strong></p>,
                ]}
                rows={items}
                verticalAlign="center"
              />
            </Card.Section>
          </Card>
          <Card>
            <Card.Section>
              <div style={{ display: "flex", justifyContent: "space-between", cursor: 'pointer' }} onClick={() => {
                setOpen({
                  testimonials: !open.testimonials
                })
              }}><span style={{ color: open.testimonials ? 'red' : 'black' }}><strong>Testimonials</strong></span><span><Icon
                source={open.testimonials ? ChevronUpMinor : ChevronDownMinor}
                color="base"
              /></span></div>
              <Collapsible open={open.testimonials} id="basic-collapsible"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint>
                <div style={{ display: 'grid', gridTemplateColumns: "1fr 1fr 1fr", gap: '20px', borderRadius: "15px", padding: '20px 0' }}>
                  {carePlus?.testimonials?.map((value, key) => {
                    return <div key={key}>
                      <video
                          style={{ width: "100%", height: "170px" }}
                          key={key}
                          src={value.testimonials_file_url}
                          controls
                      ></video>
                      <div style={{ display: "flex", justifyContent: "space-between" }}><p><strong>Date</strong> : {value.created_at}</p><span style={{ cursor: "pointer" }} onClick={() => downloadFile(value.testimonials_file_url, `Testimonial-${value.user_id}-${value.id}.mp4`)}><Icon
                        source={FolderDownMajor}
                        color="base"
                      /></span></div>
                      {value.status && <p style={{ color: value.status == 'Approved' ? 'green' : 'red' }}><strong>Status</strong> : {value.status}</p>}
                      {!value.status && <div style={{ display: "flex", gap: '15px', marginTop: "10px", justifyContent: 'space-between' }}>
                      <Button size='slim' disabled={value.status} onClick={() => handleClickOpen('true', value.id, 'Testimonials')} primary>{value.status == 'Approved' ? 'Approved' : 'Approve'}</Button>
                      <div style={{color: '#bf0711'}}>
                        <Button size='slim' monochrome outline disabled={value.status} onClick={() => handleClickOpen('false', value.id, 'Testimonials')}>{value.status == 'Disapproved' ? 'Disapproved' : 'Disapprove'}</Button>
                      </div>
                      </div>}
                    </div>
                  })}
                </div>
              </Collapsible>
            </Card.Section>
            <Card.Section>
              <div style={{ display: "flex", justifyContent: "space-between", cursor: 'pointer' }} onClick={() => {
                setOpen({
                  referral: !open.referral
                })
              }}>
                <span style={{ color: open.referral ? 'red' : 'black' }}><strong>Referrals</strong></span>
                <span>
                  <Icon
                    source={open.referral ? ChevronUpMinor : ChevronDownMinor}
                    color="base"
                  />
                </span>
              </div>
              <Collapsible open={open.referral} id="basic-collapsible"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint>
                <div>
                  <DataTable
                    hideScrollIndicator
                    columnContentTypes={["text", "text", "text", "text", "text", "text", "text", "text", "text", "text", "text"]}
                    headings={["ID", "Friend's Email", "Friend's Phone Number", "Friend's status", "Approval Status", "Action done by", "Date of Referral", "Reward Granted", "Date of Reward Granted", null, null]}
                    rows={
                      carePlus?.referral?.length ?
                      carePlus?.referral?.map((item) => {
                        let friendStatus = getUserStatus(item.referred?.status);
                        let voucherType = item.user_voucher?.type == 'manual' ? '(Manual)' : '';
                        let referralsTo = item.referred?.referrals_to;
                        let referralsToLength = referralsTo?.length;
                        let disabled = item.status !== null || !item.referred?.first_purchase_order || (referralsToLength && referralsTo[referralsToLength - 1].id != item.id ? true : false);
                        return (
                          [
                            <div>
                              {item.id}
                            </div>,
                            <div>
                              {item.friend_email ? item.friend_email : item.referred.email}
                            </div>,
                            <div>
                              {item.friend_number ? item.friend_number : item.referred?.phone}
                            </div>,
                            <div>
                              {
                                friendStatus &&
                                <Badge status={switchUserStatusColors(friendStatus)}>
                                  {friendStatus}
                                </Badge>
                              }
                            </div>,
                            <div style={{ color: item.status == 'Approved' ? 'green' : '#bf0711' }}>
                              {item?.status}
                            </div>,
                            <div>
                              {item.action_done_by?.full_name}
                            </div>,
                            <div>
                              {moment(item.created_at).format(dateFormat)}
                            </div>,
                            <div>
                              {item.user_voucher?.voucher ? `${item.user_voucher?.voucher?.currency} ${item.user_voucher?.voucher?.value} ${voucherType}` : null}
                            </div>,
                            <div>
                              {item.status == 'Approved' && item.action_done_at ? moment(item.action_done_at).format(dateFormat) : ''}
                            </div>,
                            <div>
                              {
                                !item.status &&
                                <Button size='slim' disabled={disabled} onClick={() => handleClickOpen('true', item.id, 'Referral')} primary>Approve</Button>
                              }
                            </div>,
                            <div>
                              <div style={{ color: '#bf0711' }}>
                                {
                                  !item.status &&
                                  <Button size='slim' monochrome outline disabled={disabled} onClick={() => handleClickOpen('false', item.id, 'Referral')}>Disapprove</Button>
                                }
                              </div>
                            </div>,
                          ]
                        )
                      }) :
                      []
                    }
                    initialSortColumnIndex={0}
                    defaultSortDirection="descending"
                    verticalAlign="middle"
                  />
                </div>
              </Collapsible>
            </Card.Section>
            <Card.Section>
              <div style={{ display: "flex", justifyContent: "space-between", cursor: 'pointer' }} onClick={() => {
                setOpen({
                  beforeAndAfters: !open.beforeAndAfters
                })
              }}><span style={{ color: open.beforeAndAfters ? 'red' : 'black' }}><strong>Before and Afters</strong></span><span><Icon
                source={open.beforeAndAfters ? ChevronUpMinor : ChevronDownMinor}
                color="base"
              /></span></div>
              <Collapsible open={open.beforeAndAfters} id="basic-collapsible"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint>
                <div style={{ display: 'grid', gridTemplateColumns: "1fr 1fr", gap: '50px', borderRadius: "15px", padding: '20px 0' }}>
                  {carePlus?.beforeAndAfters?.map((value, key) => {
                    return <div key={key}>
                      <div style={{ display: "grid", gap: "5px", gridTemplateColumns: "1fr 1fr" }}>
                        {value.before_image_url && <div style={{ display: "grid" }}>
                          <p><strong>Before</strong></p>
                          <div style={{ display: "grid" }}>
                            <span><img style={{ width: "100%", height: '120px' }} key={key} src={value.before_image_url} /></span>
                            <div>
                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <p><strong>Date</strong> : {value.created_at}</p><span style={{ cursor: "pointer" }} onClick={() => downloadFile(value.before_image_url, `Before-image-user-${value.user_id}-id-${value.id}.png`)}><Icon
                                  source={FolderDownMajor}
                                  color="base"
                                /></span></div>
                              {value.before_status && <p style={{ color: value.before_status == 'Approved' ? 'green' : 'red' }}><strong>Status</strong> : {value.before_status}</p>}
                            </div>
                            {!value.before_status && <div style={{ display: "flex", gap: '15px', height: '20px', marginTop: "10px", justifyContent: 'space-between' }}>
                              <Button size='slim' disabled={value.before_status} onClick={() => handleClickOpen('true', value.id, 'Before')} primary>{value.before_status == 'Approved' ? 'Approved' : 'Approve'}</Button>
                              <div style={{color: '#bf0711'}}>
                                <Button size='slim' monochrome outline disabled={value.before_status} onClick={() => handleClickOpen('false', value.id, 'Before')}>{value.before_status == 'Disapproved' ? 'Disapproved' : 'Disapprove'}</Button>
                              </div>
                            </div>}
                          </div>
                        </div>}
                        {value.after_image_url && <div style={{ display: "grid" }}>
                          <p><strong>after</strong></p>
                          <div style={{ display: "grid" }}>
                            <span><img style={{ width: "100%", height: '120px' }} key={key} src={value.after_image_url} /></span>
                            <div>
                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <p><strong>Date</strong> : {value.created_at}</p>
                                <span style={{ cursor: "pointer" }} onClick={() => downloadFile(value.after_image_url, `After-image-user-${value.user_id}-id-${value.id}.png`)}><Icon
                                  source={FolderDownMajor}
                                  color="base"
                                /></span>
                              </div>
                              {value.after_status && <p style={{ color: value.after_status == 'Approved' ? 'green' : 'red' }}><strong>Status</strong> : {value.after_status}</p>}
                            </div>
                            {!value.after_status && <div style={{ display: "flex", gap: '15px', height: '20px', marginTop: "10px", justifyContent: 'space-between' }}>
                              <Button size='slim' disabled={value.after_status} onClick={() => handleClickOpen('true', value.id, 'After')} primary>{value.after_status == 'Approved' ? 'Approved' : 'Approve'}</Button>
                              <div style={{color: '#bf0711'}}>
                                <Button size='slim' monochrome outline disabled={value.after_status} onClick={() => handleClickOpen('false', value.id, 'After')}>{value.after_status == 'Disapproved' ? 'Disapproved' : 'Disapprove'}</Button>
                              </div>
                            </div>}
                          </div>
                        </div>}
                      </div>
                    </div>
                  })}
                </div>
              </Collapsible>
            </Card.Section>
            <Card.Section>
              <div style={{ display: "flex", justifyContent: "space-between", cursor: 'pointer' }} onClick={() => {
                setOpen({
                  answers: !open.answers
                })
              }}><span style={{ color: open.answers ? 'red' : 'black' }}><strong>Survey Answers</strong></span><span><Icon
                source={open.answers ? ChevronUpMinor : ChevronDownMinor}
                color="base"
              /></span></div>
              <Collapsible open={open.answers} id="basic-collapsible"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint>
                <div style={{display: 'flex', flexDirection: 'column', gap: '20px', borderRadius: "15px", padding: '20px 0' }}>
                  {carePlus?.survey && Object.entries(carePlus.survey).map(([key, value]) => {
                    return (
                      <Card.Section key={key}>
                        <p><strong>{key.charAt(0).toUpperCase() + key.slice(1)} of treatment questions:</strong></p>
                        {value.map((val, key) => {
                          return (
                            <div key={key} style={{display: 'flex', flexDirection: 'column', marginTop: 15}}>
                              <p><strong>Question (English):</strong> {val.survey_question?.quest_en}</p>
                              <p><strong>Question (Ararbic):</strong> {val.survey_question?.quest_ar}</p>
                              <p><strong>Is mandatory:</strong> {val.is_mandatory ? 'yes' : 'no'}</p>
                              <p><strong>Answer:</strong> {val.answers[0].survey_answer ?? '-'}</p>
                            </div>
                          )}
                        )}
                      </Card.Section>
                    )
                  })}
                </div>
              </Collapsible>
            </Card.Section>
          </Card>
        </Page>
        <Dialog
          open={openDialog}
          onClose={handleClose}
        >
          <div style={{ padding: "30px 50px 20px 50px", position:"relative", height:"150px" }}>
            <h3 style={{fontSize:"20px"}}>Are you sure you want to make this action ?</h3>
           <div style={{position:"absolute", right:"10px", bottom:"10px"}}>
           <DialogActions>
              <div style={{color: '#bf0711'}}>
                <Button size='slim' monochrome outline onClick={handleClose}>Cancel</Button>
              </div>
              <Button size='slim' onClick={handleConfirm} primary>Confirm</Button>
            </DialogActions>
           </div>
          </div>
        </Dialog>
        <Modal open={popupActive} loading={true}></Modal>
      </Router>
    </NavigationLayout>
  )
}

export default CarePlus