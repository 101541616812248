import { Button, Card, Checkbox, DataTable, TextField } from '@shopify/polaris'
import React from 'react'
import { Select, MenuItem } from '@material-ui/core'

const QuestionsComponent = ({ title, questionsArray, handleIncCounter, handleChange, handleSelectedTypes, loading, handleSave, type }) => {
  let titleStyle = {textAlign: 'center', fontWeight: 'bold', whiteSpace: 'nowrap'};

  return (
    <Card title={title} sectioned actions={[
        {
          content: 'Add',
          onAction: () => handleIncCounter(type),
        },
      ]}
    >
      <DataTable
        columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text']}
        headings={[
          <p style={titleStyle}>Question (English)</p>,
          <p style={titleStyle}>Question (Arabic)</p>,
          <p style={titleStyle}>Answer Type</p>,
          <p style={titleStyle}>Low score label (English)</p>,
          <p style={titleStyle}>high score label (English)</p>,
          <p style={titleStyle}>Low score label (Arabic)</p>,
          <p style={titleStyle}>High score label (Arabic)</p>,
          <p style={titleStyle}>Placeholder (English)</p>,
          <p style={titleStyle}>Placeholder (Arabic)</p>,
          <p style={titleStyle}>Is Mandatory</p>,
          // <p style={titleStyle}></p>,
          <p style={titleStyle}></p>,
        ]}
        rows={questionsArray.map((q, i) => [
          <TextField
            type='text'
            value={q?.survey_question?.quest_en}
            onChange={e => handleChange(e, i, type, 'quest_en')}
          />,
          <TextField
            type='text'
            value={q?.survey_question?.quest_ar}
            onChange={e => handleChange(e, i, type, 'quest_ar')}
          />,
          <Select
            required
            style={{width: '100%'}}
            className='plan-field'
            placeholder='Select Answer Type'
            onChange={(e) => handleSelectedTypes(e.target.value, i, type, 'answer_type')}
            value={q?.answer_type}
          >
            <MenuItem value='rating'>Rating</MenuItem>
            <MenuItem value='text-area'>Text Area</MenuItem>
          </Select>,
          <TextField
            type='text'
            disabled={q?.answer_type == 'text-area'}
            value={q?.survey_question?.lowest_score_en}
            onChange={e => handleChange(e, i, type, 'lowest_score_en')}
          />,
          <TextField
            type='text'
            disabled={q?.answer_type == 'text-area'}
            value={q?.survey_question?.highest_score_en}
            onChange={e => handleChange(e, i, type, 'highest_score_en')}
          />,
          <TextField
            type='text'
            disabled={q?.answer_type == 'text-area'}
            value={q?.survey_question?.lowest_score_ar}
            onChange={e => handleChange(e, i, type, 'lowest_score_ar')}
          />,
          <TextField
            type='text'
            disabled={q?.answer_type == 'text-area'}
            value={q?.survey_question?.highest_score_ar}
            onChange={e => handleChange(e, i, type, 'highest_score_ar')}
          />,
          <TextField
            type='text'
            disabled={q?.answer_type == 'rating'}
            value={q?.survey_question?.placeholder_en}
            onChange={e => handleChange(e, i, type, 'placeholder_en')}
          />,
          <TextField
            type='text'
            disabled={q?.answer_type == 'rating'}
            value={q?.survey_question?.placeholder_ar}
            onChange={e => handleChange(e, i, type, 'placeholder_ar')}
          />,
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Checkbox
              checked={q?.is_mandatory}
              onChange={(e) => handleSelectedTypes(e, i, type, 'is_mandatory')}
            />
          </div>,
          // <Button destructive onClick={() => handleDecCounter(i, type)}>Remove</Button>,
          <Button primary loading={loading.type == type && loading.id == i} disabled={loading.type == type && loading.id == i} onClick={() => handleSave(type, i)}>Save</Button>
        ])}
        verticalAlign='middle'
        stickyHeader={true}
      />
    </Card>
  )
}

export default QuestionsComponent