import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { Badge, Card, DataTable, Icon, Layout, TextStyle } from "@shopify/polaris";
import axios from "../../axios";
import Cookies from "js-cookie";

import { MobileAcceptMajor } from "@shopify/polaris-icons";
import { useHistory, useParams } from "react-router-dom";
import Cookie from "js-cookie";
import { getUserStatus, switchUserStatusColors } from "../../util/helpers";
import moment from "moment";
import { GeneralFormats } from "../../util/constants";
import CopyToClipboard from "react-copy-to-clipboard";
import copy from "../../assets/images/copy.png";

const CarePlus = (props) => {
  const { id } = useParams();
  const dateFormat = GeneralFormats.dateFormat;
  const [carePlus, setCareplus] = useState({});
  const [loading, setLoading] = useState(false);
  const [referral, setReferral] = useState({});
  const [copiedText, setCopiedText] = useState(false);
  const [referralCode, setReferralCode] = useState('');

  const copyimage = {
    width: '16px',
    heigth: '16px',
    cursor: 'pointer',
  
  };

  const copiedtext = {
    fontWeight: '400',
    fontSize: '15px',
  };

  const handleCopy = () => {
    setCopiedText(true);
    setTimeout(() => {
      setCopiedText(false);
    }, 1000); 
  };

  const generateReferralCode = async() => {
    try{
      const response = await axios.post(`/admin/v1/users/create/referral-code`, {
        user_id: id,
      }, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });
      if(response.data.referral_code){
        setReferralCode(response.data.referral_link);
      }
    } catch ($err) {
      console.log($err);
    }
  }

  const getReferralFriend = async () => {
    try {
      const response = await axios.post(
        `admin/v1/users/${id}/referral-friend`,
        {
          email: props.email,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        }
      );
      if (response) {
        setReferral(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCarePlus = () => {
    setLoading(true);
    axios
      .get(`/admin/v1/users/careplus/${id}`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        if (res) {
          setCareplus({
            ...carePlus,
            testimonials: res?.data?.testimonials,
            referral: res?.data?.referral,
            beforeAndAfters: res?.data?.beforeAndAfters,
          });
          if(res?.data?.user?.referral_code){
            setReferralCode(`https://basma.com/friend/${res.data.user.referral_code.referral_code}`);
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getCarePlus();
    void getReferralFriend();
  }, []);
  const history = useHistory();

  const handleEdit = () => {
    history.push(`/admin/user/${id}/careplus`, {
      clinicName: props.clinicName,
      planType: props.planType,
      status: props.status,
      pageTitle: props.pageTitle,
      clinicStatus: props.clinicStatus,
      userId: props.userId,
      backUrl: `/admin/users/${id}/dashboard`,
    });
  };
  return (
    <Layout.Section>
      <Card
        title="CARE+"
        actions={[
          {
            content: "Generate referral link",
            onClick: generateReferralCode,
            disabled: referralCode,
          },
          {
            content: "Edit",
            onClick: handleEdit,
          },
        ]}
      >
        {loading ? (
          <Card.Section>
            <div style={{ textAlign: "center" }}>
              {" "}
              <CircularProgress color="primary" />{" "}
            </div>
          </Card.Section>
        ) : (
          <Card.Section>
            {carePlus?.testimonials?.length > 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <span>Testimonials:</span>{" "}
                <span style={{ transform: "scale(0.7)" }}>
                  <Icon source={MobileAcceptMajor} color="success" />
                </span>
              </div>
            )}
            {carePlus?.beforeAndAfters?.length > 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <span>Before and Afters:</span>{" "}
                <span style={{ transform: "scale(0.7)" }}>
                  <Icon source={MobileAcceptMajor} color="success" />
                </span>
              </div>
            )}
            {carePlus?.referral?.length > 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <span>Referrals:</span>{" "}
                <span style={{ transform: "scale(0.7)" }}>
                  <Icon source={MobileAcceptMajor} color="success" />
                </span>
              </div>
            )}
            {
              referralCode &&
              <div style={{display: 'flex', gap: '10px'}}>
                <span>Unique referral link: </span>
                <p style={{color: '#404040'}}>
                  {referralCode}
                </p>
                <CopyToClipboard text={referralCode} onCopy={handleCopy}>
                  <div>
                    {
                      copiedText ?
                      <div style={copiedtext}>
                        Copied!
                      </div>
                      :
                      <img src={copy} alt="copy" style={copyimage} />
                    }
                  </div>
                </CopyToClipboard>
              </div>
            }
            {((referral && referral?.referrer?.length) || (referral && referral?.referred?.length)) && (
              <Card.Section title="Referral">
                {
                  referral.referred?.length ?
                  <>
                    <div style={{ display: "grid", gap: "10px" }}>
                      <TextStyle>Referred by:</TextStyle>
                    </div>
                    <DataTable
                      hideScrollIndicator
                      columnContentTypes={["text", "text", "text", "text", "text", "text", "text", "text"]}
                      headings={["ID", "Email", "Phone Number", "Approval Status", "Action done by", "Date of Referral", "Reward Granted", "Date of Reward Granted"]}
                      rows={
                        referral.referred?.map((item) => {
                          let voucherType = item.user_voucher?.type == 'manual' ? '(Manual)' : '';
                          return (
                            [
                              <div>
                                {item.id}
                              </div>,
                              <div>
                                {item.email ? item.email : item.referee.email}
                              </div>,
                              <div>
                                {item.number ? item.number : item.referee?.phone}
                              </div>,
                              <div style={{ color: item.status == 'Approved' ? 'green' : '#bf0711' }}>
                                {item?.status}
                              </div>,
                              <div>
                                {item.action_done_by?.full_name}
                              </div>,
                              <div>
                                {moment(item.created_at).format(dateFormat)}
                              </div>,
                              <div>
                                {item.user_voucher?.voucher ? `${item.user_voucher?.voucher?.currency} ${item.user_voucher?.voucher?.value} ${voucherType}` : null}
                              </div>,
                              <div>
                                {item.status == 'Approved' && item.action_done_at ? moment(item.action_done_at).format(dateFormat) : ''}
                              </div>,
                            ]
                          )
                        })
                      }
                      initialSortColumnIndex={0}
                      defaultSortDirection="descending"
                      verticalAlign="middle"
                    />
                    <br />
                    <br />
                  </> :
                  null
                }
                {
                  referral.referrer?.length ?
                  <>
                    <div style={{ display: "grid", gap: "10px" }}>
                      <TextStyle>List of friends referred by {referral.referrer[0].referee.full_name}:</TextStyle>
                    </div>
                    <DataTable
                      hideScrollIndicator
                      columnContentTypes={["text", "text", "text", "text", "text", "text", "text", "text", "text"]}
                      headings={["ID", "Friend's Email", "Friend's Phone Number", "Friend's status", "Approval Status", "Action done by", "Date of Referral", "Reward Granted", "Date of Reward Granted"]}
                      rows={
                        referral.referrer?.map((item) => {
                          let friendStatus = getUserStatus(item.referred?.status);
                          let voucherType = item.user_voucher?.type == 'manual' ? '(Manual)' : '';
                          return (
                            [
                              <div>
                                {item.id}
                              </div>,
                              <div>
                                {item.friend_email ? item.friend_email : item.referred.email}
                              </div>,
                              <div>
                                {item.friend_number ? item.friend_number : item.referred?.phone}
                              </div>,
                              <div>
                                {
                                  friendStatus &&
                                  <Badge status={switchUserStatusColors(friendStatus)}>
                                    {friendStatus}
                                  </Badge>
                                }
                              </div>,
                              <div style={{ color: item.status == 'Approved' ? 'green' : '#bf0711' }}>
                                {item?.status}
                              </div>,
                              <div>
                                {item.action_done_by?.full_name}
                              </div>,
                              <div>
                                {moment(item.created_at).format(dateFormat)}
                              </div>,
                              <div>
                                {item.user_voucher?.voucher ? `${item.user_voucher?.voucher?.currency} ${item.user_voucher?.voucher?.value} ${voucherType}` : null}
                              </div>,
                              <div>
                                {item.status == 'Approved' && item.action_done_at ? moment(item.action_done_at).format(dateFormat) : ''}
                              </div>,
                            ]
                          )
                        })
                      }
                      initialSortColumnIndex={0}
                      defaultSortDirection="descending"
                      verticalAlign="middle"
                    />
                  </> :
                  null
                }
              </Card.Section>
            )}
          </Card.Section>
        )}
      </Card>
    </Layout.Section>
  );
};

export default CarePlus;
