import CustomTabPanel from '../../../Reports/ClinicReports/custom-tab-panel';
import { Button, FormLayout, TextStyle } from '@shopify/polaris';
import { capitalizeWords } from '../../../util/helpers';
import { iconColors } from '../../../config';
import moment from 'moment/moment';
import DownloadSelectIprSheet from './download-select-ipr-sheet.component';
import React, { useState } from 'react';

const SmilePlanDashboardTab = ({ data, value, index, loading }) => {
  const [showMoreProcedures, setShowMoreProcedures] = useState(false);
  const {
    name,
    status,
    tp_count,
    position,
    preferred,
    number_of_aligner_upper,
    number_of_aligner_lower,
    treatment_length,
    plan_type,
    designer_name,
    old_designer_name,
    requires_redo,
    redo_type,
    child_items,
    tp_name,
    guide_created_at,
    guide_updated_at,
    number_of_aligner,
    video_id,
    video_id_2,
    ipr_before_step_number,
    guide_url,
    ipr_url,
    outsourced_support_designer,
  } = data.data;

  const filteredProceduresIprSheet = [];

  if (data.iprInfo) {
    if (ipr_url) {
      filteredProceduresIprSheet.push({ previous: true, url: ipr_url });
    }
    if (data.iprInfo?.procedures) {
      filteredProceduresIprSheet.push(...data.iprInfo?.procedures);
    }
  }

  const openFile = (e) => {
    const win = window.open(e.currentTarget.id, '_blank');
    win.focus();
  };

  return (
    <CustomTabPanel value={value} index={index}>
      <FormLayout.Group>
        <FormLayout>
          <TextStyle>
            <b>{`${name} - ${status}`}</b><br />
            {tp_count > 1 && <span style={{color: 'gray'}}>{`Option ${position}`}</span>}
          </TextStyle>
          {preferred ? (
            <TextStyle>
              <b>Preferred Plan</b>
            </TextStyle>
          ) : (
            <TextStyle>
              <b>Available Plan</b>
            </TextStyle>
          )}
          {number_of_aligner_upper !== null &&
            number_of_aligner_upper !== undefined && (
              <TextStyle>Steps - Top: {number_of_aligner_upper}</TextStyle>
            )}
          {number_of_aligner_lower !== null &&
            number_of_aligner_lower !== undefined && (
              <TextStyle>Steps - Bottom: {number_of_aligner_lower}</TextStyle>
            )}
          {treatment_length && (
            <TextStyle>{treatment_length} Month Treatment</TextStyle>
          )}
          {plan_type && (
            <div style={{ margin: '5px 0' }}>
              <TextStyle>Case Type: {capitalizeWords(plan_type)}</TextStyle>
            </div>
          )}
          {outsourced_support_designer && (
            <TextStyle>Outsourced Treatment Support Designer: {outsourced_support_designer.full_name}</TextStyle>
          )}
          {designer_name && (
            <TextStyle>Treatment Plan Designer: {designer_name}</TextStyle>
          )}
          {old_designer_name && (
              <TextStyle>Treatment Plan Old Designer: {old_designer_name}</TextStyle>
          )}
          <TextStyle>{`Requires Redo: ${String(
            requires_redo == '0'
              ? 'No'
              : redo_type == 'Redo Impression'
              ? 'Yes - Redo Impression Kit Required'
              : 'Yes - Redo Scan Required'
          )}`}</TextStyle>
          <div>
            <span>
              Procedures: {data.iprInfo?.ipr_steps}
              <button
                onClick={() => setShowMoreProcedures(!showMoreProcedures)}
                style={{
                  fontSize: '12px',
                  textDecoration: 'underline',
                  border: 'none',
                  backgroundColor: 'transparent',
                  cursor: 'pointer',
                }}
              >
                show more
              </button>
            </span>
            {showMoreProcedures && (
              <div style={{ display: 'grid', gap: '10px', marginTop: '10px' }}>
                {data.iprInfo &&
                  data.iprInfo?.procedures?.map((val, key) => {
                    return (
                      <div key={key} style={{ display: 'flex', gap: '10px' }}>
                        <span>Before Step {val.step}: </span>
                        <div className="badge-wrapper-flex-wrap-gap" key={key}>
                          {val?.procedure_type?.map((el) => {
                            return (
                              <div
                                style={{
                                  padding: '0 1rem',
                                  backgroundColor:
                                    iconColors[el]?.background ?? '#3CC7D3',
                                  color: iconColors[el]?.color ?? 'black',
                                  borderRadius: '10rem',
                                }}
                              >
                                {el}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          <div>
            <div>
              <span>Completed Procedures: </span>
              {data.iprInfo &&
                data.iprInfo?.proceduresDetails.map((val, key) => {
                  if (val.completed === 1 && val.ipr === 1) {
                    return <TextStyle key={key}>{val.step}, </TextStyle>;
                  }
                  return <></>;
                })}
            </div>
          </div>
          <TextStyle>
            Related Plans: {child_items && child_items.length}
          </TextStyle>
          {tp_name && <TextStyle>TP Name: {tp_name}</TextStyle>}
          {guide_created_at && (
            <TextStyle>
              TP Created at:{' '}
              {moment(guide_created_at).format('DD-MM-YYYY hh:mm A')}
            </TextStyle>
          )}
          {guide_updated_at && (
            <TextStyle>
              TP Updated at:{' '}
              {moment(guide_updated_at).format('DD-MM-YYYY hh:mm A')}
            </TextStyle>
          )}
          {number_of_aligner && (
            <TextStyle>Number of Steps: {number_of_aligner}</TextStyle>
          )}
          {video_id && <TextStyle>Video ID 1: {video_id}</TextStyle>}
          {video_id_2 && <TextStyle>Video ID 2: {video_id_2}</TextStyle>}
          {ipr_before_step_number != null && (
            <TextStyle>
              Before Step Number: {String(ipr_before_step_number)}
            </TextStyle>
          )}
        </FormLayout>
        <FormLayout>
          <Button
            primary
            id={guide_url}
            onClick={openFile}
            disabled={!guide_url}
          >
            View Treatment Plan
          </Button>
          <div style={{ width: 'max-content' }}>
            <DownloadSelectIprSheet
              procedures={filteredProceduresIprSheet}
              loading={loading}
            />
          </div>
        </FormLayout>
      </FormLayout.Group>
    </CustomTabPanel>
  );
};

export default SmilePlanDashboardTab;
