import React from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { default as tooltipImage } from "../img/tooltip.png";
const CustomTooltip = (props) => {
  const theme = createMuiTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "1em",
          color: "white",
          backgroundColor: "#333333",
        },
      },
    },
  });
  return (
    <MuiThemeProvider theme={theme}>
      <Tooltip title={props.title}>
        <img src={tooltipImage} className="tooltip"></img>
      </Tooltip>
    </MuiThemeProvider>
  );
};

export default CustomTooltip;
