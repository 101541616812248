import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  Avatar,
  Button,
  ResourceItem,
  ResourceList,
  Thumbnail,
  Caption,
  DropZone,
  Checkbox,
  Stack,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  ActionList,
  Popover,
  Select,
  Card,
  Tabs,
  PageActions,
} from "@shopify/polaris";
import axios from "../../axios";
import Cookie from "js-cookie";
import history from "../../history";
import Helmet from "react-helmet";
import { Editor } from "@tinymce/tinymce-react";

import { useParams } from "react-router-dom";

export default function EditLabelPatient() {
  let { id } = useParams();

  // Declare a new state variable, which we'll call "count"
  const [selected, setSelected] = useState(Cookie.get("patientLabel"));
  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const options = [
    { value: "Red", label: "Red" },
    { value: "Green", label: "Green" },
    {
      value: "Blue",
      label: "Blue",
    },
  ];
  useEffect(() => {
    // getData();
  }, []);

  function getData() {
    // axios
    //   .get("admin/v1/users/" + id + "/status", {
    //     headers: {
    //       Authorization: "Bearer " + Cookie.get("token"),
    //     },
    //   })
    //   .then((result) => {
    //     console.log("resultStatus=", result.data.data);
    //     setSelected(result.data.data);
    //   })
    //   .catch((err) => console.log(err));
  }
  return (
    <Page
      title="Edit Patient Label"
      breadcrumbs={[
        {
          content: "Dashboard",
          url: "/admin/users/" + id + "/dashboard",
        },
      ]}
    >
      <Card sectioned title="Status">
        <FormLayout>
          <Select
            options={options}
            onChange={handleSelectChange}
            value={selected}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
    </Page>
  );
  function handleSave() {
    const bodyObj = {
      status: selected,
    };
    axios
      .post(`admin/v1/patients/${id}/status`, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        history.push("/admin/users/" + id + "/dashboard");
        Cookie.set("patientLabel", selected);
      })
      .catch((err) => console.log(err));
  }
}
