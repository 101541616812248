import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Button,
  Page,
  AppProvider,
  Image,
  DisplayText,
  ChoiceList,
  Filters,
  Loading,
  Avatar,
  Card,
  TextStyle,
  ButtonGroup,
  Pagination,
  Spinner,
} from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../axios";
import history from "../history";
import emptyIcon from "../img/emptyList.svg";

export default function List() {
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);

  const [items, setItems] = useState([]);
  let arrayOfObjects = [];
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(13);
  const [sortState, setSortState] = useState("-id");
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  let valueFilter = "";

  useEffect(() => {
    getData(1, "", "title", sortState);
  }, []);
  function getData(currentPage, queryValue, availability, sortState) {
    valueFilter = availability;
    if (sortState === "-id") {
      setSortState("id");
    } else {
      setSortState("-id");
    }
    if (valueFilter === "") {
      valueFilter = "title";
    }

    axios
      .get(
        "/admin/v1/products?per_page=" +
          perPage +
          "&page=" +
          currentPage +
          "&filter[" +
          valueFilter +
          "]=" +
          queryValue +
          "&sort=" +
          sortState,
        {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      )
      .then((result) => {
        setIsLoading(false);
        if (result.data.data.data.length === 0) {
          setIsListEmpty(true);
        }
        setItems(
          result.data.data.data.map((item) => [
            item.id,
            <Avatar source={item.image_url}></Avatar>,
            item.title.en,
            item.weight,
            item.price,
            item.quantity,
            item.type,
            <ButtonGroup>
              <Button id={String(item.id)} onClick={(e) => handleEdit(e)}>
                Edit
              </Button>
            </ButtonGroup>,
          ])
        );
        setTotalPages(Math.ceil(result.data.data.total / perPage));
      })
      .catch((err) => console.log(err));
  }

  function handleFiltersQueryChange(queryValue) {
    setQueryValue(queryValue);
    getData(currentPage, queryValue, availability, sortState);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
    getData(currentPage, queryValue, availability, sortState);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue(null);
    getData(currentPage, queryValue, availability, sortState);
  }, []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "Title", value: "title" },
            { label: "Description", value: "description" },
            { label: "Dimension", value: "dimension" },
            { label: "Weight", value: "weight" },
            { label: "Price", value: "price" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
          // allowMultiple
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }
  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    []
  );
  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div className="message-no-result"
    >
      <div className="message-no-result-img">
        <Image src={emptyIcon}></Image>{" "}
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner accessibilityLabel="Spinner example" size="large" />
        </div>
      </div>
    )
  );

  return (
    <Page
      fullWidth
      title="List Of Products"
      // primaryAction={{
      //   content: "Create Product",
      //   url: "/admin/products/add",
      // }}
    >
      {loadingMarkup}
      <Card>
        <Card.Section>
          <Filters
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
        </Card.Section>
        <DataTable
          columnContentTypes={[
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text"
          ]}
          headings={[
            <TextStyle variation="strong">ID</TextStyle>,
            <TextStyle variation="strong">Thumbnail</TextStyle>,
            <TextStyle variation="strong">Title</TextStyle>,
            <TextStyle variation="strong">Weight</TextStyle>,
            <TextStyle variation="strong">Price</TextStyle>,
            <TextStyle variation="strong">Quantity</TextStyle>,
            <TextStyle variation="strong">Type</TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
          ]}
          rows={items}
          sortable={[true, false, false, false, false, false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={0}
          onSort={handleSort}
          verticalAlign="center"
        />
        {totalPages >= 2 && (
          <div style={{ marginTop: "20px" }}>
            <Pagination
              hasPrevious={currentPage > 1 && true}
              onPrevious={() => {
                setCurrentPage(currentPage - 1);
              }}
              hasNext={currentPage < totalPages && true}
              onNext={() => {
                setCurrentPage(currentPage + 1);
              }}
              label={`${currentPage}/${totalPages}`}
            />
          </div>
        )}
        {emtyState}
      </Card>
    </Page>
  );
  function handleSort() {
    getData(currentPage, queryValue, availability, sortState);
  }

  function handleEdit(e) {
    let currentID = e.currentTarget.id;
    var json_str = JSON.stringify(
      findArrayElementById(arrayOfObjects, currentID)
    );
    Cookie.set("clickedItem", json_str);
    history.push("/admin/products/" + currentID);
  }

  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element[0]) === parseInt(id);
    });
  }
  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return value.map((val) => `Filter by ${val}`).join(", ");
      case "productType":
        return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }

  function handlePageChange(currentPage) {
    setCurrentPage(currentPage);
    getData(currentPage, queryValue, availability, sortState);
  }
}
