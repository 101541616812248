import { forwardRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@mui/material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function UnlinkingWarningDialog({ open, setOpen, postDispatchData }) {
  const handleClose = () => {
    setOpen(false);
  };
  const handleProceed = async () => {
    handleClose();
    await postDispatchData();
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Unlinking a merged batch</DialogTitle>
      <DialogContent sx={{ minWidth: "600px" }}>
        Warning! This customer had chosen to merge their shop order with their
        upcoming batch (i.e they did not pay shipping). You are unlinking the
        batches. Please coordinate with their Patient Care Agent so that the
        customer pays for separate shipping.
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleProceed}>Proceed</Button>
      </DialogActions>
    </Dialog>
  );
}

export default UnlinkingWarningDialog;
