import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from '../axios';
import Cookie from 'js-cookie';
import {
    Card,
} from '@shopify/polaris';
import Loading from '../components/Loading/loading';

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 8,
    borderRadius: '8px',
    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'white',
    // styles we need to apply on draggables
    ...draggableStyle
});

const TipsAndNewsList = () => {
    const [tips, setTips] = useState([]);
    const [loading, setLoading] = useState({
        fetch: false,
        reorder: false,
    });

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(
            tips,
            result.source.index,
            result.destination.index
        );

        setTips(reorderedItems);
    };

    const fetchTips = async () => {
        setLoading(prev => ({
            ...prev,
            fetch: true,
        }));
        await axios.get(`admin/v1/tipsAndNews`, {
            headers: {
                Authorization: 'Bearer ' + Cookie.get('token'),
            },
        }).then((result) => {
            setTips(result.data.tipsAndNews);
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(prev => ({
                ...prev,
                fetch: false,
            }));
        });
    }

    const reorderTipsAndNews = async () => {
        setLoading(prev => ({
            ...prev,
            reorder: true,
        }));
        let body = {
            ids: tips.map(tip => tip.id),
        };
        await axios.post(`admin/v1/tipsAndNews/update-order`, body, {
            headers: {
                Authorization: 'Bearer ' + Cookie.get('token'),
            },
        }).then((result) => {
            if(result.data.success) {
                fetchTips();
            }
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(prev => ({
                ...prev,
                reorder: false,
            }));
        });
    }

    useEffect(() => {
        fetchTips();
    }, [])

    return (
        <Card
            sectioned
            title='Tips And News'
            actions={[
                {
                    content: 'Add',
                    url: '/admin/tipsAndNews',
                },
            ]}
            primaryFooterAction={
                {
                    content: 'Save',
                    onClick: reorderTipsAndNews,
                    loading: loading.reorder,
                    disabled: !tips.length || loading.reorder,
                }
            }
        >
            {
                loading.fetch ?
                <Loading /> :
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId='droppable'>
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {
                                    tips.map((tip, key) => (
                                        <Draggable key={tip.id} draggableId={`tip-${tip.id}`} index={key}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <Card actions={[
                                                        {
                                                            content: 'Edit',
                                                            url: `/admin/tipsAndNews/${tip.id}`,
                                                        },
                                                    ]}>
                                                        <div style={{ padding: '0 15px' }}>
                                                            <span style={{ fontWeight: 'bold' }}>Visible on the App:</span> {(tip.show_on_app == 0) ? <span>No</span> : <span>Yes</span>}
                                                        </div>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <div style={{ padding: '15px', display: 'flex', columnGap: 20, alignItems: 'center', width: '50%' }}>
                                                                <div>
                                                                    <span style={{ fontWeight: 'bold' }}>Title: </span><span>{tip.title.length < 10 ? tip.title : tip.title.replace(tip.title.substr(10), '...')}</span><br />
                                                                    <span style={{ fontWeight: 'bold' }}>Description: </span><span>{tip.description.length < 15 ? tip.description : tip.description.replace(tip.description.substr(15), '...')}</span><br />
                                                                    <span style={{ fontWeight: 'bold' }}>Link: </span><a href={tip.link} target='blank'>{tip.link.length < 15 ? tip.link : tip.link.replace(tip.link.substr(15), '...')}</a>
                                                                </div>
                                                                <div>
                                                                    <img style={{ width: '80px', height: '80px' }} src={tip.thumbnail_url} alt='thumbnail' />
                                                                </div>
                                                            </div>
                                                            {
                                                                tip.ar_title &&
                                                                <div style={{ padding: '15px', display: 'flex', columnGap: 20, alignItems: 'center', width: '50%' }}>
                                                                    <div>
                                                                        <span style={{ fontWeight: 'bold' }}>Title AR: </span><span>{tip.ar_title.length < 10 ? tip.ar_title : tip.ar_title.replace(tip.ar_title.substr(10), '...')}</span><br />
                                                                        <span style={{ fontWeight: 'bold' }}>Description AR: </span><span>{tip.ar_description.length < 15 ? tip.ar_description : tip.ar_description.replace(tip.ar_description.substr(15), '...')}</span><br />
                                                                        <span style={{ fontWeight: 'bold' }}>Link AR: </span><a href={tip.ar_link} target='blank'>{tip.ar_link.length < 15 ? tip.ar_link : tip.ar_link.replace(tip.ar_link.substr(15), '...')}</a>
                                                                    </div>
                                                                    <div>
                                                                        <img style={{ width: '80px', height: '80px' }} src={tip.ar_thumbnail_url} alt='thumbnail' />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </Card>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            }
        </Card>
    );
};

export default TipsAndNewsList;
