import React, { useCallback, useState } from "react";
import {
  Thumbnail,
  Caption,
  InlineError,
  DropZone,
  Checkbox,
  Stack,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Select,
  Card,
  PageActions,
  Modal,
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";
import history from "../history";

function Add() {
  const [valueTitleEn, setvalueTitleEn] = useState("");
  const [valueDescriptionEn, setvalueDescriptionEn] = useState("");
  const [valueTitleAr, setvalueTitleAr] = useState("");
  const [valueDescriptionAr, setvalueDescriptionAr] = useState("");
  const [valueWeight, setValueWeight] = useState("");
  const [valuePrice, setValuePrice] = useState("");
  const [valueQuantity, setValueQuantity] = useState("");
  const [valueDimension, setValueDimension] = useState("");
  const [valueAlias, setValueAlias] = useState("accessories");
  const [checkedActive, setCheckedActive] = useState(false);
  const [checkedFeatured, setCheckedFeatured] = useState(false);
  const [valueSubTitleEn, setValueSubTitleEn] = useState("");
  const [valueSubTitleAr, setValueSubTitleAr] = useState("");
  const [valueOptionsEn, setValueOptionsEn] = useState("");
  const [valueOptionsAr, setValueOptionsAr] = useState("");
  const [valueNumberOfPayment, setValueNumberOfPayment] = useState("");
  const [valueDownPayment, setValueDownPayment] = useState("");
  const [valueMonthlyPayment, setValueMonthlyPayment] = useState("");
  const [valuePaymentInterval, setValuePaymentInterval] = useState("");
  const [totalPriceValue, setTotalPriceValue] = useState(null);
  const [localPriceCurrency, setLocalPriceCurrency] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("AE");
  const [optionsCountries, setOptionsCountries] = useState([
    { label: "United Arab Emirates", value: "AE" },
    { label: "Saudi Arabia", value: "SA" },
    { label: "Worldwide ", value: "worldwide" },
  ]);
  let imageType = "";
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [imagePreview, setImagePreview] = useState("");
  const [imageKey, setImageKey] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = useCallback(
    (files) => {
      setPopupActive(true);
      setImagePreview(
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = files[files.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKey(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, files[files.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              console.log("res2==", res);
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [files]
  );

  const [fieldRequiredTE, setFieldRequiredTE] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredTA, setFieldRequiredTA] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredDE, setFieldRequiredDE] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredDA, setFieldRequiredDA] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredW, setFieldRequiredW] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredP, setFieldRequiredP] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredQ, setFieldRequiredQ] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredD, setFieldRequiredD] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredA, setFieldRequiredA] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredI, setFieldRequiredI] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredSTE, setFieldRequiredSTE] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredSTA, setFieldRequiredSTA] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredOE, setFieldRequiredOE] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredOA, setFieldRequiredOA] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredNOP, setFieldRequiredNOP] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredDP, setFieldRequiredDP] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredMP, setFieldRequiredMP] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [
    fieldRequiredLocalPriceCurrency,
    setFieldRequiredLocalPriceCurrency,
  ] = useState(<InlineError message="" fieldID="myFieldID" />);
  const [isUploadingAtive, setIsUploadingAtive] = useState(false);
  const [selectedAlias, setSelectedAlias] = useState("impression-kit");
  const handleSelectChangeAlias = useCallback(
    (value) => setSelectedAlias(value),
    []
  );
  const optionsAlias = [
    { label: "Impression Kit", value: "impression-kit" },
    { label: "Accessories", value: "accessories" },
    { label: "Redo Kit", value: "redo-kit" },
  ];
  const [popupActive, setPopupActive] = useState(false);

  return (
    <Page
      title="Add Product"
      breadcrumbs={[{ content: "List Of Products", url: "/admin/products" }]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Title En</TextStyle>
          <TextField value={valueTitleEn} onChange={handleChangeTitleEn} />
          {fieldRequiredTE}
          <TextStyle variation="strong">Title Ar</TextStyle>
          <TextField value={valueTitleAr} onChange={handleChangeTitleAr} />
          {fieldRequiredTA}

          <TextStyle variation="strong">Subtitle En</TextStyle>
          <TextField
            value={valueSubTitleEn}
            onChange={handleChangeSubTitleEn}
          />
          {fieldRequiredSTE}
          <TextStyle variation="strong">Subtitle Ar</TextStyle>
          <TextField
            value={valueSubTitleAr}
            onChange={handleChangeSubTitleAr}
          />
          {fieldRequiredSTA}

          <TextStyle variation="strong">Options En</TextStyle>
          <TextField value={valueOptionsEn} onChange={handleChangeOptionsEn} />
          {fieldRequiredOE}
          <TextStyle variation="strong">Options Ar</TextStyle>
          <TextField value={valueOptionsAr} onChange={handleChangeOptionsAr} />
          {fieldRequiredOA}

          <TextStyle variation="strong">Description En</TextStyle>
          <TextField
            value={valueDescriptionEn}
            onChange={handleChangeDescriptionEn}
          />
          {fieldRequiredDE}
          <TextStyle variation="strong">Description Ar</TextStyle>
          <TextField
            value={valueDescriptionAr}
            onChange={handleChangeDescriptionAr}
          />
          {fieldRequiredDA}
          <TextStyle variation="strong">Weight</TextStyle>
          <TextField
            value={valueWeight}
            onChange={handleChangeWeight}
            type="number"
          />
          {fieldRequiredW}
          <TextStyle variation="strong">Price (USD)</TextStyle>
          <TextField
            value={valuePrice}
            onChange={handleChangePrice}
            type="number"
          />
          {fieldRequiredP}
          <TextStyle variation="strong">Price in local currency</TextStyle>
          <TextField
            value={localPriceCurrency}
            onChange={handleChangeLocalPriceCurrency}
            type="number"
          />
          {fieldRequiredLocalPriceCurrency}
          <TextStyle variation="strong">Product Country</TextStyle>
          <Select
            options={optionsCountries}
            onChange={handleSelectCountry}
            value={selectedCountry}
          />
          <TextStyle variation="strong">Quantity</TextStyle>
          <TextField
            value={valueQuantity}
            onChange={handleChangeQuantity}
            type="number"
          />
          {fieldRequiredQ}
          <TextStyle variation="strong">Dimension</TextStyle>
          <TextField
            value={valueDimension}
            onChange={handleChangeDimension}
            type="number"
          />
          {fieldRequiredD}
          <TextStyle variation="strong">Alias</TextStyle>
          <Select
            options={optionsAlias}
            onChange={handleSelectChangeAlias}
            value={selectedAlias}
          />
          {/* <TextField
                            value={valueAlias}
                            onChange={handleChangeAlias}
                        /> */}
          {fieldRequiredA}
          <TextStyle variation="strong">Number of payment</TextStyle>
          <TextField
            value={valueNumberOfPayment}
            onChange={handleChangeNumberOfPayment}
            type="number"
          />
          {fieldRequiredNOP}

          {/*<TextStyle variation="strong">Down payment</TextStyle>
            <TextField
              value={valueDownPayment}
              onChange={handleChangeDownPayment}
              type="number"
            />
            {fieldRequiredDP}

            <TextStyle variation="strong">Monthly payment</TextStyle>
            <TextField
              value={valueMonthlyPayment}
              onChange={handleChangeMonthlyPayment}
              type="number"
            />
            {fieldRequiredMP}
            <TextStyle variation="strong">Payment interval</TextStyle>
            <TextField
              value={valuePaymentInterval}
              onChange={handleChangePaymentInterval}
              type="number"
            /> */}

          <TextStyle variation="strong">Image</TextStyle>
          <Thumbnail size="large" source={imagePreview} />
          <DropZone onDrop={handleDropZoneDrop} onDropAccepted={sendImage}>
            {uploadedFiles}
            {fileUpload}
          </DropZone>
          {fieldRequiredI}
          <Checkbox
            label="Is Featured"
            checked={checkedFeatured}
            onChange={handleChangeCbFeatured}
          />
          <Checkbox
            label="Is Active"
            checked={checkedActive}
            onChange={handleChangeCbActive}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );
  function handleSelectCountry(selectedCountry) {
    setSelectedCountry(selectedCountry);
  }
  function handleChangeLocalPriceCurrency(localPriceCurrency) {
    setLocalPriceCurrency(localPriceCurrency);
  }
  function handleChangeTitleEn(valueTitleEn) {
    setvalueTitleEn(valueTitleEn);
  }
  function handleChangeDescriptionEn(valueDescriptionEn) {
    setvalueDescriptionEn(valueDescriptionEn);
  }
  function handleChangeTitleAr(valueTitleAr) {
    setvalueTitleAr(valueTitleAr);
  }
  function handleChangeDescriptionAr(valueDescriptionAr) {
    setvalueDescriptionAr(valueDescriptionAr);
  }
  function handleChangeWeight(valueWeight) {
    setValueWeight(valueWeight);
  }
  function handleChangePrice(valuePrice) {
    setValuePrice(valuePrice);
  }
  function handleChangeQuantity(valueQuantity) {
    setValueQuantity(valueQuantity);
  }
  function handleChangeDimension(valueDimension) {
    setValueDimension(valueDimension);
  }
  function handleChangeAlias(valueAlias) {
    setValueAlias(valueAlias);
  }
  function handleChangeCbActive(checkedActive) {
    setCheckedActive(checkedActive);
  }
  function handleChangeCbFeatured(checkedFeatured) {
    setCheckedFeatured(checkedFeatured);
  }
  function handleChangeSubTitleEn(valueSubTitleEn) {
    setValueSubTitleEn(valueSubTitleEn);
  }
  function handleChangeSubTitleAr(valueSubTitleAr) {
    setValueSubTitleAr(valueSubTitleAr);
  }
  function handleChangeOptionsEn(valueOptionsEn) {
    setValueOptionsEn(valueOptionsEn);
  }
  function handleChangeOptionsAr(valueOptionsAr) {
    setValueOptionsAr(valueOptionsAr);
  }
  function handleChangeNumberOfPayment(valueNumberOfPayment) {
    setValueNumberOfPayment(valueNumberOfPayment);
  }
  function handleChangeDownPayment(valueDownPayment) {
    setValueDownPayment(valueDownPayment);
  }
  function handleChangeMonthlyPayment(valueMonthlyPayment) {
    setValueMonthlyPayment(valueMonthlyPayment);
  }
  function handleChangePaymentInterval(valuePaymentInterval) {
    setValuePaymentInterval(valuePaymentInterval);
  }
  function handleSave() {
    if (
      valueTitleEn === "" ||
      valueTitleAr === "" ||
      valueDescriptionEn === "" ||
      valueWeight === "" ||
      valuePrice === "" ||
      valueQuantity === "" ||
      valueDescriptionAr === "" ||
      localPriceCurrency === ""
    ) {
      if (valueTitleEn === "")
        setFieldRequiredTE(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueTitleAr === "")
        setFieldRequiredTA(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueDescriptionEn === "")
        setFieldRequiredDE(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueDescriptionAr === "")
        setFieldRequiredDA(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueWeight === "")
        setFieldRequiredW(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valuePrice === "")
        setFieldRequiredP(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueQuantity === "")
        setFieldRequiredQ(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (localPriceCurrency === "")
        setFieldRequiredLocalPriceCurrency(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      // if (valueDimension === '')
      //     setFieldRequiredD(<InlineError message='This field is required' fieldID="myFieldID" />)
      // if (imageKey === '')
      //     setFieldRequiredI(<InlineError message='Please upload an image' fieldID="myFieldID" />)
      // if (valueAlias === '')
      //     setFieldRequiredA(<InlineError message='This field is required' fieldID="myFieldID" />)
    } else {
      const bodyObj = {
        title: {
          en: valueTitleEn,
          ar: valueTitleAr,
        },
        description: {
          en: valueDescriptionEn,
          ar: valueDescriptionAr,
        },
        sub_title: {
          en: valueSubTitleEn,
          ar: valueSubTitleAr,
        },
        options: {
          en: valueOptionsEn,
          ar: valueOptionsAr,
        },
        number_of_payment: valueNumberOfPayment,
        // down_payment: valueDownPayment,
        // monthly_payments: valueMonthlyPayment,
        // payment_interval: valuePaymentInterval,
        weight: valueWeight,
        price: valuePrice,
        quantity: valueQuantity,
        dimension: valueDimension,
        alias: selectedAlias,
        active: checkedActive,
        featured: checkedFeatured,
        image: imageKey,
        price_in_local_currency: localPriceCurrency,
        product_country: selectedCountry,
      };
      axios
        .post("admin/v1/products", bodyObj, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          console.log("result=", res);
          history.push("/admin/products");
          //  history.push('/admin/faqsupport/viewCategory')
        })
        .catch((err) => console.log(err));
    }
  }
}
export default Add;
