import { Grid, Paper, Typography } from '@material-ui/core'
import { Close } from "@mui/icons-material";
import React from 'react'
import { useStyles } from './CaseDiscussionHeaderStyles';

function CaseDiscussionHeader({userId, userName, setOpenChat}) {
    const classes = useStyles()
  return (
    <Grid item xs={12} className={classes.headerRow}>
          <Close style={{ position: "absolute", left: "5px", width: "1.5em", height: "1.5em" }} onClick={() => { setOpenChat({ firstOpen: false, status: false }) }} />
          <Paper className={classes.paper} square elevation={2}>
            <Typography color="textPrimary" variant="h5">
              {userName} {userId ? `(ID: ${userId})` : ''}
            </Typography>
          </Paper>
        </Grid>
  )
}

export default CaseDiscussionHeader