import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Button,
  Page,
  Image,
  DisplayText,
  AppProvider,
  Loading,
  Card,
  TextStyle,
  ButtonGroup,
} from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../axios";
import history from "../history";
import Helmet from "react-helmet";

import emptyIcon from "../img/emptyList.svg";
import { useParams } from "react-router-dom";

export default function ListSubPlans() {
  // var json_str = Cookie.get('clickedItem');
  // var cookieResult = JSON.parse(json_str);
  let { id } = useParams();

  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sortState, setSortState] = useState("ascending");
  const [items, setItems] = useState([]);
  let arrayOfObjects = [];
  let newArray = [];

  useEffect(() => {
    getData();
  }, []);
  function getData() {
    console.log("url=");
    axios
      .get("/admin/v1/plans/" + id + "/payments", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        if (result.data.data.length === 0) {
          console.log("zeroo");
          setIsEmpty(true);
          // toggleIsEmpty()
        }
        setIsLoading(false);
        console.log("resultGet=", result);
        console.log("resultGetData=", result.data.data);
        arrayOfObjects = result.data.data.map((item) => [
          item.id,
          item.price,
          item.down_payment,
          item.active,
        ]);
        setItems(
          result.data.data.map((item) => [
            item.id,
            item.price,
            String(item.down_payment),
            <ButtonGroup>
              <Button id={String(item.id)} onClick={(e) => handleEdit(e)}>
                Edit
              </Button>
            </ButtonGroup>,
          ])
        );
      })
      .catch((err) => console.log(err));
  }

  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    []
  );
  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isEmpty ? (
    <div className="message-no-result">
      <div className="message-no-result-img">
        <Image style={{display: "inline-block", width: "50px"}} src={emptyIcon}></Image>{" "}
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : null;

  return (
    <Page
      fullWidth
      title="List Of Items"
      primaryAction={{
        content: "Create Item",
        url: "/admin/plans/" + id + "/payments/create",
      }}
      breadcrumbs={[{ content: "List Of Plans", url: "/admin/plans" }]}
    >
      {loadingMarkup}
      <Card>
        <DataTable
          columnContentTypes={["text", "text", "text", "text"]}
          headings={[
            <TextStyle variation="strong">ID</TextStyle>,
            <TextStyle variation="strong">Price</TextStyle>,
            <TextStyle variation="strong">Down Payment</TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
          ]}
          rows={items}
          sortable={[true, false, false, false, false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={1}
          onSort={handleSort}
        />
        {emtyState}
      </Card>

      {/* <Button id='12333' onClick={(e)=>handleEdit(e)}>aaa</Button> */}
    </Page>
  );
  function handleSort() {
    console.log(sortState);

    if (sortState === "ascending") {
      setSortState("descending");
      axios
        .get("/admin/v1/pages?sort=id", {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((result) => {
          console.log("resultGet=", result);
          console.log("resultGetData=", result.data.data);

          arrayOfObjects = result.data.data.map((item) => [
            item.id,
            item.alias,
            item.title.en,
            item.title.ar,
            item.created_at,
            item.updated_at,
          ]);
          setItems(
            result.data.data.map((item) => [
              item.id,
              item.alias,
              item.created_at,
              item.updated_at,
              <ButtonGroup>
                <Button id={String(item.id)} onClick={(e) => handleEdit(e)}>
                  Edit
                </Button>
              </ButtonGroup>,
            ])
          );
        })
        .catch((err) => console.log(err));
    } else {
      setSortState("descending");
      axios
        .get("/admin/v1/pages?sort=-id", {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((result) => {
          console.log("resultGet=", result);
          console.log("resultGetData=", result.data.data);

          arrayOfObjects = result.data.data.map((item) => [
            item.id,
            item.alias,
            item.title.en,
            item.title.ar,
            item.created_at,
            item.updated_at,
          ]);
          setItems(
            result.data.data.map((item) => [
              item.id,
              item.alias,
              item.created_at,
              item.updated_at,
              <ButtonGroup>
                <Button id={String(item.id)} onClick={(e) => handleEdit(e)}>
                  Edit
                </Button>
              </ButtonGroup>,
            ])
          );
        })
        .catch((err) => console.log(err));
    }
  }
  function handleEdit(e) {
    let currentID = e.currentTarget.id;
    var json_str = JSON.stringify(
      findArrayElementById(arrayOfObjects, currentID)
    );
    //Cookie.set('id',findArrayElementById(arrayOfObjects, currentID)[0]);
    Cookie.set("clickedItem", json_str);
    history.push("/admin/plans/" + id + "/payments/" + currentID);
  }

  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element[0]) === parseInt(id);
    });
  }
  function swapArrayElements(a, x, y) {
    if (a.length === 1) return a;
    a.splice(y, 1, a.splice(x, 1, a[y])[0]);
    return a;
  }
}
