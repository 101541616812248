import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../history";
import { AppProvider } from "@shopify/polaris";
import MainPatients from "./Patients/Main";
import Edit from "./Edit";

function MainEdit() {
  return (
    <AppProvider>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/doctors/:id" component={Edit} />
          <Route path="/admin/doctors/:id/patients" component={MainPatients} />
          {/* <Route path="/admin/doctors/:id" component={Edit} /> */}
        </Switch>
      </Router>
    </AppProvider>
  );
}
export default MainEdit;
